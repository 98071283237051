import { compose } from 'recompose';
import Promotion from './PromotionDetail';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, TOGGLE_STATE, CREATE_PROMOTION, UPDATE_PROMOTION
} from './API';

const ComposedPromotion = compose(
  CREATE_PROMOTION,
  TOGGLE_STATE,
  UPDATE_PROMOTION,
  withRequestTracker({
    query,
    mutation,
  }),
)(Promotion);

export default ComposedPromotion;
