import React from 'react';
import PropTypes from 'prop-types';
import { Input, CustomSelect } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { Form, MonthPicker } from '../../../v4/components';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  type: '',
};

const DistributorSalesTargetForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, handleDropDownChange, handleDateChange, enableErrorDisplay, type, distributorList } = props;

  return (
    <Form>
      <CustomSelect
        labelContent='Sub D'
        placeholder='Select'
        name='distributor'
        getOptionLabel={({ title }) => title}
        getOptionValue={({ id }) => id}
        options={distributorList}
        onChange={(e) => handleDropDownChange(e.id, 'distributorId')}
        value={distributorList.filter((d) => d.id === data.distributorId)}
        enableValidation
        enableErrorDisplay={enableErrorDisplay}
        ref={(ref) => (refsObj.distributor = ref)}
        disabled={type === EVENT_OPERATION.READ}
      />
      <div className={type === EVENT_OPERATION.READ ? 'month-wrap disabled' : 'month-wrap'}>
        <label>Month</label>
        <MonthPicker value={data.duration} onSelect={(event) => handleDateChange(event)}/>
      </div>
      <Input
        ref={(ref) => (refsObj.target = ref)}
        value={data.target}
        name='target'
        type='number'
        argument={{ min: 0 }}
        placeholder='Monthly Target'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Monthly Target'
        disabled={type === EVENT_OPERATION.READ}
        decimalWithPrecedingZero
      />
    </Form>
  );
};

DistributorSalesTargetForm.propTypes = propTypes;

DistributorSalesTargetForm.defaultProps = defaultProps;

const DistributorSalesTargetFormWithErrorAndLoading = withLoading(DistributorSalesTargetForm);

export default DistributorSalesTargetFormWithErrorAndLoading;
