import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { formConfig, breadCrumb as breadCrumbConfig, crudSuccess as crudRequestConfig } from './config';
import View from './View';
import Form from './Form';
import UploadForm from '../UploadForm';
// import UserWrapper from './UserWrapper';
import { DialogFormWrapper } from '../../../common';
import { Icon } from '../../../../v4/components';
import { DOMAIN, FORM_CONFIG } from '../../../../data/enums/config';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import { TARGET_COVERAGE_DETAILS, USER_TARGET_DETAILS } from '../../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { crudResponseProcessor, handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import UserWrapper from './UserWrapper';
import { MESSAGE_EVENT_OPERATION } from '../../../../v4/constants/successMessage';
import * as queryService from '../../../base/query.service';
import { getOffsetFromPagination } from '../../../../utils/api';
import { fetchFromLocalStorage, isError, storeInLocalStorage } from '../../../common/HelperFunctions';
import { storageKeys } from '../../../sales/orderProcessing/config';
import { getPermissionForTargets } from '../../../base/permission';

const propTypes = {
  history: PropTypes.instanceOf(Object),
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func,
  upload: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  createFundamentalUserTarget: PropTypes.func.isRequired,
  updateOutletCoverageTarget: PropTypes.func.isRequired,
  getUserFundamentalTargets: PropTypes.func.isRequired,
};

const defaultProps = {
  history: {},
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class UserTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      dialog: {
        type: '',
        element: '',
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },      
    };
    this.permission = getPermissionForTargets();

    const { displayAlert } = props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      // downloadReport,
      displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
      [EVENT_OPERATION.CREATE]: props.createFundamentalUserTarget,
      [EVENT_OPERATION.UPDATE]: props.updateOutletCoverageTarget,
    };

    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        let message = crudRequestConfig[type].message;
        if (type === EVENT_OPERATION.UPLOAD) {
          message = data;
        } else {
          this.setState(data);
        }
        this.loadTableData();
        displayAlert(ALERT_TYPE.SUCCESS, message);
      },
      this.getData,
      ['target'],
    );

    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleButtonSubmit = (type, dialogData) => {
    const { createFundamentalUserTarget } = this.props;
    if (type === EVENT_OPERATION.CREATE) {
      createFundamentalUserTarget(
        {
          input: {
            start_date: dialogData.startDate || getStartOfCurrentMonth(),
            end_date: dialogData.endDate,
            title: dialogData.title,
          },
        },
        {
          handleSuccess: () => {
            const { displayAlert } = this.props;
            displayAlert(ALERT_TYPE.SUCCESS, ` ${dialogData.title} ${MESSAGE_EVENT_OPERATION.CREATE}`);
            this.loadTableData();
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );

      return;
    }
    this.onFormSubmit(type, dialogData);
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidMount() {
    // this.loadTableData();
    this.getFilterStateFromStorage();
  }
  setFilterStateInStorage = () => {
    const {
      filterMenu,
      queryParameters: { filter, date, search },
    } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.USER_TARGET,
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.USER_TARGET);
    const newFilterConfig = {
      ...(localData || {
        searchText: '',
      }),
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  loadTableData = () => {
    const { getUserFundamentalTargets } = this.props;
    const { queryParameters } = this.state;

    const { data } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();

    getUserFundamentalTargets(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.listUserFundamentalTarget && response.data.listUserFundamentalTarget.rows) || [];
          data.total = (response.data.listUserFundamentalTarget && response.data.listUserFundamentalTarget.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ data });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleIconClick = (type, element) => {
    this.updateDialog(type, element);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  updateDialog = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  onTableBodyClick = data => {
    const { history } = this.props;
    history.push(`/${USER_TARGET_DETAILS}/${data.id}`, { title: data.title, details: data });
  };

  handleCSVUpload = type => {
    this.updateDialog(EVENT_OPERATION.UPLOAD, { name: type });
  };

  isConfirmationType = type =>
    type === EVENT_OPERATION.DELETE || type === EVENT_OPERATION.BULK_DELETE || type === EVENT_OPERATION.UPDATE_STATUS;

  onSampleDownload = entity => {
    const { downloadReport, displayAlert } = this.props;
    downloadReport(
      {
        input: {
          domain: entity,
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          const { downloadReport } = response.data;
          window.open(`${window.location.origin}/${downloadReport.file.name}`);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  render() {
    const { data, dialog, queryParameters } = this.state;

    const { type, element } = dialog;

    const { serverResponseWaiting } = this.props;

    const sampleUrl = checkCase => (
      <a onClick={() => this.onSampleDownload(checkCase)}>
        <span className="sample-csv">
          {' '}
          <Icon iconName="download" />
          Sample.csv
        </span>
      </a>
    );

    return (
      <UserWrapper>
        {type && (
          <div className="targets-wrapper">
            <DialogFormWrapper
              type={type}
              dialogElement={element}
              formConfig={formConfig[type]}
              onDialogCancel={this.resetDialog}
              onDialogSubmit={this.handleButtonSubmit}
              fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
              formTitle={element.title}
              renderDialog={({
                dialogData,
                enableErrorDisplay,
                handleInputChange,
                refsObj,
                fileUploadRefs,
                handleFileUpload,
              }) => (
                <Fragment>
                  {(type === EVENT_OPERATION.CREATE ||
                    type === EVENT_OPERATION.UPDATE ||
                    type === EVENT_OPERATION.READ) && (
                    <>
                      <Form
                        show
                        type={type}
                        refsObj={refsObj}
                        data={dialogData}
                        enableErrorDisplay={enableErrorDisplay}
                        handleInputChange={handleInputChange}
                      />
                    </>
                  )}
                  {type === EVENT_OPERATION.UPLOAD && (
                    <>
                      <UploadForm
                        show
                        refsObj={fileUploadRefs}
                        data={dialogData}
                        loading={serverResponseWaiting}
                        handleFileUpload={handleFileUpload}
                        enableErrorDisplay={enableErrorDisplay}
                      />
                      {sampleUrl(DOWNLOAD_DOMAIN.OUTLET_COVERAGE_USER_TARGET_SAMPLE)}
                    </>
                  )}
                </Fragment>
              )}
            />
          </div>
        )}
        <View
          data={data}
          breadCrumb={breadCrumbConfig}
          loading={serverResponseWaiting}
          onTableBodyClick={this.onTableBodyClick}
          onCSVUpload={this.handleCSVUpload}
          handleIconClick={this.handleIconClick}
          queryParameters={queryParameters}
          basePaginationService={this.basePaginationService}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          permission={this.permission}
          // handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
        />
      </UserWrapper>
    );
  }
}

UserTarget.propTypes = propTypes;

UserTarget.defaultProps = defaultProps;

export default withAlert()(UserTarget);
