import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PanelStyled, PanelHeader } from '../../common/configuration/index';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withLoading from '../../../utils/composition/withLoading';
import { BreadCrumb } from '../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onIconClick: PropTypes.func,
  breadCrumb: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onIconClick: () => null,
  breadCrumb: [],
};

const View = ({ ...props }) => {
  const { data, breadCrumb } = props;
  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <h2>Catalog</h2>
            <div>
              {/* <Button
                 outlined
                 iconName="plus"
                 onClick={() => onIconClick(EVENT_OPERATION.CREATE)}
                 >
                 <span>Add Catalog</span>
                 </Button> */}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content'>
        <div className='table-wrap prn'>
          <table>
            {TableHeader()}
            <tbody>{data.list.map((catalog) => TableBody({ data: catalog, parentList: data }))}</tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
