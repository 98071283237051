export const LIMIT = 50;
export const OFFSET = 0;
export const PAGE = 1;
export const PRIMARY = 'Primary';
export const SECONDARY = 'Secondary';
export const INSTOCK = 'INSTOCK';
export const OUT_OF_STOCK = 'OUT_OF_STOCK';
export const STOCK_INSUFFICIENT = 'STOCK_INSUFFICIENT';
export const VAT_AMOUNT = 0.13;
export const FILE_TYPE = {
  CSV: 'CSV',
  XLS: 'XLS',
};
export const VIEW_TYPE = {
  DETAIL: 'DETAIL',
  SUMMARY: 'SUMMARY',
};
