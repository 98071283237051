import { compose } from 'recompose';
import UserTarget from './UserTarget';

import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  UPLOAD,
  DOWNLOAD_REPORT,
  CREATE_FUNDAMENTAL_USER_TARGET,
  UPDATE_OUTLET_COVERAGE_TARGET,
} from './API';

const ComposedUserTarget = compose(
  UPLOAD,
  DOWNLOAD_REPORT,
  CREATE_FUNDAMENTAL_USER_TARGET,
  UPDATE_OUTLET_COVERAGE_TARGET,
  withRequestTracker({
    query,
    mutation,
  }),
)(UserTarget);

export default ComposedUserTarget;
