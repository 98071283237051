import { compose } from 'recompose';
import Outlets from './Outlets';
import {
  query, mutation, CREATE_OUTLET_SAVE_PLAN
} from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const ComposeOutlets = compose(
  CREATE_OUTLET_SAVE_PLAN,
  withRequestTracker({
    query,
    mutation,
  }),
)(Outlets);

export default ComposeOutlets;
