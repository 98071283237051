import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  levels: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  data: {
    title: '',
  },
  enableErrorDisplay: false,
  handleInputChange: () => null,
  title: '',
  type: '',
};

const LocationHierarchyForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, title, type, levels } = props;
  return (
    <Form>
      {title !== levels[0].title && (
        <Input
          ref={(ref) => (refsObj.title = ref)}
          value={data.parent ? data.parent.title : ''}
          name='title'
          type='text'
          placeholder='Enter Location'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event, 'parent')}
          labelContent='Parent'
          disabled={type === EVENT_OPERATION.READ || title !== levels[0].title}
        />
      )}
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Enter Title'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Title'
        disabled={type === EVENT_OPERATION.READ}
      />
    </Form>
  );
};

LocationHierarchyForm.propTypes = propTypes;

LocationHierarchyForm.defaultProps = defaultProps;

const ComposedLocationHierarchyForm = withLoading(LocationHierarchyForm);

export default ComposedLocationHierarchyForm;
