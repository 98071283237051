import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { Pagination } from '../../../v4/components';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import ReportTableStyled from '../../reports/ReportTableStyled';
import { PanelHeader } from '../../common/configuration';
import { ico } from '../../common/DomainConfig';
import { Download } from '../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';
import { filter as filterConfig } from './config';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import { Filter } from '../../../v4/components';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  onHeaderClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  onPageChange: PropTypes.func,
  distributor: PropTypes.instanceOf(Object),
  filter: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  onDownloadClick: () => null,
  onHeaderClick: () => null,
  onFilterChange: () => null,
  onPageChange: () => null,
  distributor: {},
  filter: {},
};

const View = ({ ...props }) => {
  const {
    data,
    filter,
    distributor,
    onDownloadClick,
    onHeaderClick,
    onFilterChange,
    onPageChange,
    pagination,
  } = props;
  return (
    <>
      <PanelHeader className='subd-sec-header'>
        <h3>{`${ico.title} for ${distributor.title}.`}</h3>
        <div className='subd-btns'>
          <Download
            handleDownloadClick={() => onDownloadClick(DOWNLOAD_DOMAIN.ICO_REPORT)}
          />
          <div>
            <Filter
              metaData={filter}
              menuList={filterConfig.menu}
              onFilterChange={onFilterChange}
            />
          </div>
        </div>
      </PanelHeader>
      <ReportTableStyled>
        <div className={`table-wrap ${distributor.title === '' ? 'no-select' : ''} `}>
          <table>
            {
              TableHeader({ onHeaderClick })
            }
            <tbody>
              {data.list.map((list) => (
                TableBody({ data: list })
              ))}
            </tbody>
          </table>
        </div>
      </ReportTableStyled>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
        className='page-default'
      />
    </>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
