import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button } from '../../../v4/components';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import TableHeader from '../../base/TableHeader';
import Popover from '../../../components/Popover';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const labelMappings = {
  subD: 'subD',
  route: 'route',
  outlet:'outlet',
  channel:'channel',
  category:'category',
  contactVerfication:'contact_verification',
  lastInvoiced:'last_invoiced',
  lastCalled:'last_called',
  p3mAverage:'p3m_average',
  pendingBadge:'badge',
  contactNumber:'contactInfo',
  contactVerified:'contact_verified',
  scheduledTime:'scheduled_time',
  callTarget:'call_target',
  plannedBadge:'planned_badge',
  callResult:'call_result',
  call_time:'call_time',
  duration:'duration',
  grossAmount:'grossAmount',
  unsuccessfullReason:'unsuccessfull_reason',
  notes:'notes',
  fullName:'fullName'
};

const labelConfig = (tabStatus = 0) =>{
  let config;

  if(tabStatus === 0 ) {
    config = [
      {
        id: 1,
        label: labelMappings.subD,
        title: 'Sub D',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 2,
        label: labelMappings.route,
        title: 'Route',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 3,
        label: labelMappings.outlet,
        title: 'Outlet',
        show: true,
      },
      {
        id: 4,
        label: labelMappings.channel,
        title: 'Channel',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 5,
        label: labelMappings.category,
        title: 'Category',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 6,
        label: labelMappings.contactVerfication,
        title: 'Contact Verification',
        show: true,
        sortable: true,
        className: 'sorting text-center',
      },
      {
        id: 7,
        label: labelMappings.fullName,
        title: 'TAS',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 8,
        label: labelMappings.lastInvoiced,
        title: 'Last Invoiced',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 9,
        label: labelMappings.lastCalled,
        title: 'Last Called',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 10,
        label: labelMappings.p3mAverage,
        title: 'P3M Average',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 11,
        label: labelMappings.pendingBadge,
        title: '',
        show: true,
      },
    ]
  }

  if(tabStatus === 1) {
    config = [
      {
        id: 1,
        label: labelMappings.subD,
        title: 'Sub D',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 2,
        label: labelMappings.route,
        title: 'Route',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 3,
        label: labelMappings.outlet,
        title: 'Outlet',
        show: true,
      },
      {
        id: 4,
        label: labelMappings.channel,
        title: 'Channel',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 5,
        label: labelMappings.category,
        title: 'Category',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 6,
        label: labelMappings.contactNumber,
        title: 'Contact No.',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 7,
        label: labelMappings.contactVerified,
        title: 'Contact Verification',
        show: true,
        className: 'sorting text-center',
      },
      {
        id: 8,
        label: labelMappings.fullName,
        title: 'TAS',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 9,
        label: labelMappings.scheduledTime,
        title: 'Scheduled Time',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 10,
        label: labelMappings.callTarget,
        title: 'Call Target',
        show: true,
      },
      {
        id: 11,
        label: labelMappings.plannedBadge,
        title: '',
        show: true,
      },


    ]
  }

  if(tabStatus === 2) {
    config = [
      {
        id: 1,
        label: labelMappings.subD,
        title: 'Sub D',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 2,
        label: labelMappings.route,
        title: 'Route',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 3,
        label: labelMappings.outlet,
        title: 'Outlet',
        show: true,
      },
      {
        id: 4,
        label: labelMappings.fullName,
        title: 'TAS',
        show: true,
        sortable: true,
        className: 'sorting',
      },
      {
        id: 5,
        label: labelMappings.callResult,
        title: 'Call Results',
        show: true,
      },
      {
        id: 6,
        label: labelMappings.call_time,
        title: 'Called Time',
        show: true,
      },
      {
        id: 7,
        label: labelMappings.duration,
        title: 'Call Duration',
        show: true,
      },
      {
        id: 8,
        label: labelMappings.grossAmount,
        title: 'Order Amount',
        show: true,
      },
      {
        id: 9,
        label: labelMappings.unsuccessfullReason,
        title: 'Reason',
        show: true,
      },
      {
        id:10,
        label: labelMappings.notes,
        title: 'Call Notes',
        show: true,
        className: 'sorting text-center',
      },
    ]
  }

  return config;
};


const TableHeaderConfig = ({ tableConfig,onHeaderClick }) => (
  <TableHeader
    headerDetails={tableConfig}
    onHeaderClick={onHeaderClick}
  />
);

const returnTableBody = (
  data,onTableRowClick
) => {
  const table = {};

  table[labelMappings.subD] =
   <td key={`callPlan-${data.id}-town-title`}>{data.distributor}</td>;
  table[labelMappings.route] = <td key={`callPlan-${data.id}-route-title`}>{data.route}</td>;
  table[labelMappings.outlet] = <td key={`callPlan-${data.id}-outlet-title`}>{data.outlet}</td>;
  table[labelMappings.channel] = <td key={`callPlan-${data.id}-channel-title`}>{data.channel}</td>;
  table[labelMappings.category] = <td key={`callPlan-${data.id}-category-title`}>{data.category}</td>;
  table[labelMappings.contactVerfication] = <td  className='contact-verification' key={`callPlan-${data.id}-contact-verification`}>
    {data.contact_verification && (
      <span>
        <img src='/image/icons/verifiedContact.svg' alt='' />
      </span>
    )}
    {data.contact_verification===false && data.contact_info?.[0]?.phoneNumber.length>0&&(
      <span>
        <img src='/image/icons/contact.svg' alt='' />
      </span>
    )}
  </td>;
  table[labelMappings.fullName] = <td key={`callPlan-${data.id}-category-title`}>{data.full_name}</td>;
  table[labelMappings.lastInvoiced] = <td key={`callPlan-${data.id}-last-invoiced`}>{data.last_invoiced?(data.last_invoiced.months?`${data.last_invoiced.months}m`:null):null} {data.last_invoiced?(data.last_invoiced.days?`${data.last_invoiced.days}d ago`:null):null}</td>;
  table[labelMappings.lastCalled] = <td key={`callPlan-${data.id}-last-called`}>{data.last_called?.months?`${data.last_called?.months}m`:null} {data.last_called?.days?`${data.last_called?.days}d ago`:null}</td>;
  table[labelMappings.p3mAverage] = <td key={`callPlan-${data.id}-p3m-average`}>{fixedFloatAndCommas(data.p3m_average||0)}</td>;
  table[labelMappings.pendingBadge] = <td  className='contact-verification' key={`callPlan-${data.id}-contact-verification`}>
    <span>
      <img src='/image/icons/target.svg' alt='' />
    </span>
  </td>;
  table[labelMappings.contactNumber] = <td key={`callPlan-${data.id}-contact-number`}>{data.contact_info?.[0]?.phoneNumber}</td>;
  table[labelMappings.contactVerified] = <td key={`callPlan-${data.id}-contact-verified`} className ='text-center'>
    {data.contact_verification && (
      <span>
        <img src='/image/icons/verifiedContact.svg' alt='' />
      </span>
    )}
    {data.contact_verification===false && data.contact_info?.[0]?.phoneNumber.length>0 &&(
      <span>
        <img src='/image/icons/contact.svg' alt='' />
      </span>
    )}
  </td>;
  table[labelMappings.scheduledTime] = <td key={`callPlan-${data.id}-scheduled-time`}>{data.scheduled_time}</td>;
  table[labelMappings.callTarget] = <td key={`callPlan-${data.id}-call-target`}>{fixedFloatAndCommas(data.call_target)}</td>;
  table[labelMappings.plannedBadge] = <td key={`callPlan-${data.id}-call-target`}>
    <span>
      <img src='/image/icons/phone.svg' alt='' />
    </span>
  </td>;
  table[labelMappings.callResult] = (
    <td key={`callPlan-${data.id}-result`}>
      <span className={data.call_result?.toLowerCase()}>
        <span className='badge_border'>
          <Badge light>{data.call_result}</Badge>
        </span>
      </span>
    </td>
  );
  table[labelMappings.call_time] = <td key={`callPlan-${data.id}-call-time`}>{data.call_time}</td>;
  table[labelMappings.duration] = <td key={`callPlan-${data.id}-duration`}>{data.call_duration?(data.call_duration.minutes?`${data.call_duration.minutes}m`:null):null} {data.call_duration?(data.call_duration.seconds?`${data.call_duration.seconds}s `:null):null}</td>;
  table[labelMappings.grossAmount] = <td key={`callPlan-${data.id}-gross-amount`}>
    {fixedFloatAndCommas(data.gross_amount|| 0)}
  </td>;
  table[labelMappings.unsuccessfullReason] = <td key={`callPlan-${data.id}-reason`}>{data.unsuccessfull_reason}</td>;
  table[labelMappings.notes] = (
    <td key={`pop-over-${data.id}`} onClick={(event) => event.stopPropagation()} className='text-center'>
      <div className='simple-popup'>
        <Popover
          popoverIcon={<Button iconName='comments' disabled={!data.notes} />}
          disabled={!data.notes}
          headerTitle='Note'
        >
          <span className='arrow-right' />
          {<span>{data.notes}</span>}
        </Popover>
      </div>
    </td>
  );
  return table;
};

const TableBody = ({
  data,
  onIconClick,
  tableConfig,
  onTableRowClick
}) => {

  const tableBody = returnTableBody(
    data,
    onIconClick
  );

  return (

    <tr onClick={() => onTableRowClick(data.id)}  style={{ cursor: 'pointer' }} key={`callPlan-${data.id}`}>
      {tableConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableHeaderConfig.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
};
TableHeaderConfig.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  permission: PERMISSION_OBJ,
};

export {
  TableHeaderConfig as TableHeader, TableBody, labelConfig
};

