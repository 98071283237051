import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import withAlert from '../../../../../utils/composition/withAlert';
import sampleData from '../../details/data';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { DialogWrapper } from '../../../../common';
import View from './View';
import CoverageWrapper from '../CoverageWrapper';
import { Input, SingleDatePicker } from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { getPermissionForTargets } from '../../../../base/permission';
import { isError } from '../../../../common/HelperFunctions';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getoutletCoverageUserTargets: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  createOrEditOutletCoverageUserTarget: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
};

const defaultProps = {
  serverResponseWaiting: false,
  location: {},
};

class CoverageDetail extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      data: sampleData,
      titleWithouttype: false,
      detailId: parseInt(match.params.id, 10),
      brandDialogData: {
        id: 0,
        startDate: 'xxxxx',
        target: 0,
        endDate: 'xxxxx',
        name: '',
        role: '',
      },
    };
    this.permission = getPermissionForTargets();
  }

  static getDerivedStateFromError(error) {
    return { hasError: false };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const { getoutletCoverageUserTargets, displayAlert } = this.props;
    const { detailId } = this.state;
    getoutletCoverageUserTargets(
      {
        outletCoverageTargetId: detailId,
      },
      {
        handleSuccess: response => {
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({
            data: response.data.outletCoverageUserTargets || [],
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleIconClick = (id, targetData = {}, type) => {
    const { data } = this.state;
    this.setState(
      {
        brandDialogData: {
          id: targetData.id,
          name: targetData.name,
          startDate: data[0].startDate,
          endDate: data[0].endDate,
          target: targetData.target,
          role: targetData.role,
        },
      },
      () => this.changeTitleFlag(type),
    );
  };

  handleInputChange = event => {
    const { brandDialogData } = this.state;
    brandDialogData.target = event.target.value;
    this.setState(brandDialogData);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onFormSubmit = () => {
    const { createOrEditOutletCoverageUserTarget } = this.props;
    const { brandDialogData, detailId } = this.state;
    createOrEditOutletCoverageUserTarget(
      {
        input: {
          outletCoverageTargetId: detailId,
          userId: brandDialogData.id,
          target: Number(brandDialogData.target),
        },
      },
      {
        handleSuccess: () => {
          const { displayAlert } = this.props;
          displayAlert(ALERT_TYPE.SUCCESS, ` Target ${MESSAGE_EVENT_OPERATION.UPDATE}`);
          this.getList();
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  changeTitleFlag = type => {
    if (type === EVENT_OPERATION.READ) {
      this.setState({ titleWithouttype: true });
    } else {
      this.setState({ titleWithouttype: false });
    }
  };

  render() {
    const { data, brandDialogData, titleWithouttype } = this.state;
    const { serverResponseWaiting, location } = this.props;
    const { state = {} } = location;
    const title = state.title;
    return (
      <CoverageWrapper>
        <DialogWrapper
          title={brandDialogData.name}
          subTitle={brandDialogData.role}
          onDialogSubmit={this.onFormSubmit}
          titleWithouttype={titleWithouttype}
          renderDialog={({ type }) => (
            <Fragment>
              {(type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.READ) && (
                <>
                  <div className="coverage-detail-wrap">
                    <div className="single-date-picker">
                      <label>Start Date</label>
                      <SingleDatePicker
                        date={brandDialogData.startDate}
                        name="startDate"
                        placeholder="Start Date"
                        labelContent="Start Date"
                        disabled
                      />
                    </div>

                    <div className="single-date-picker">
                      <label>End Date</label>
                      <SingleDatePicker
                        date={brandDialogData.endDate}
                        name="endDate"
                        placeholder="End Date"
                        labelContent="End Date"
                        disabled
                      />
                    </div>
                  </div>

                  <Input
                    disabled={type === EVENT_OPERATION.READ}
                    type="number"
                    decimalWithPrecedingZero
                    argument={{ min: 0 }}
                    name="target"
                    labelContent="Coverage Target"
                    placeholder="Coverage Target"
                    value={brandDialogData.target}
                    onChange={event => this.handleInputChange(event)}
                  />
                </>
              )}
            </Fragment>
          )}
          render={({ onDialogItemClick }) => (
            <View
              data={data}
              onIconClick={onDialogItemClick}
              loading={serverResponseWaiting}
              handleIconClick={this.handleIconClick}
              detailTitle={title}
              permission={this.permission}
          />
          )}
        />
      </CoverageWrapper>
    );
  }
}

CoverageDetail.propTypes = propTypes;

CoverageDetail.defaultProps = defaultProps;

export default withAlert()(CoverageDetail);
