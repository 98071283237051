import React from 'react';
import { IMAGE_STATUS_MAPPER } from './config';
import {
  Icon, Button, CSVUploadForm
} from '../../../v4/components';
import { isObjectEmpty } from '../../../utils/objectPrototypes';
import { convertFileSizeWithSuffix } from '../../../utils/conversion';
import withErrorAndLoading from '../../../utils/composition/withLoading';
import {
  DialogBody, DialogFooter, DialogHeader
} from '../../../components/Dialog';
import {
  UploadStyled, ProgressBarStyled, ProgressCardStyled
} from './UploadStyled';

const propTypes = {};

const getImageSubText = (imageObj, uploadProgress) => {
  if (imageObj.status === IMAGE_STATUS_MAPPER.PENDING) {
    return (
      <div className='img-progress-info'>
        <span>{convertFileSizeWithSuffix(imageObj.file.size)}</span>
        <span>{uploadProgress}%</span>
      </div>
    );
  }
  if (imageObj.status === IMAGE_STATUS_MAPPER.SUCCESS) {
    return (
      <div className='sucess'>
        {' '}
        <Icon iconName='check-circle-full' />
        Success: Upload completed!
      </div>
    );
  }
  if (imageObj.status === IMAGE_STATUS_MAPPER.DANGER) {
    return (
      <div className='failed'>
        <Icon iconName='exclamation-circle' /> Error: Upload failed!
      </div>
    );
  }
};
const ImageUploadView = ({
  onDialogCancel,
  onDialogSubmit,
  onImageSelection,
  imagetitle,
  imageObj,
  uploadProgress,
  imageServerUrl,
}) => {
  const disableDone = imageObj.status === IMAGE_STATUS_MAPPER.PENDING;
  const disableCross = disableDone || imageObj.status !== IMAGE_STATUS_MAPPER.EMPTY;
  return (
    <UploadStyled>
      <div className='modal-wrapper'>
        <div className='modal-inner image'>
          <DialogHeader>
            <h2>{imagetitle}</h2>
            <Icon
              iconName='times'
              onClick={(e) => onDialogCancel(e)}
              className={disableDone ? 'disable-btn' : ''}
              style={{
                cursor: 'pointer',
                pointerEvents: disableCross ? 'none' : 'unset',
                opacity: disableCross ? 0.3 : 1,
              }}
            />
          </DialogHeader>
          <DialogBody pad0>
            {!isObjectEmpty(imageObj.file) ? (
              <div className='img-upload-wrap'>
                <ProgressCardStyled
                  status={imageObj.status}
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    padding: '0 24px',
                    height: '80px',
                    marginBottom: '167px',
                  }}
                >
                  <div className='img-icon'>
                    <img
                      src={imageObj.status === IMAGE_STATUS_MAPPER.SUCCESS ? imageServerUrl : ' /image/icons/user.svg'}
                      alt='img-icon'
                    />
                  </div>
                  <div className='progress-bar-wrap'>
                    <h3 className='img-name'>{imageObj.file.name}</h3>
                    {imageObj.status === IMAGE_STATUS_MAPPER.PENDING ? (
                      <ProgressBarStyled status={imageObj.status}>
                        <div className='progress-bar' style={{ display: 'flex', borderRadius: '4px' }}>
                          <div
                            style={{
                              height: '8px',
                              width: `${uploadProgress}%`,
                              borderRadius: '4px',
                              backgroundColor: '#0B5FFF',
                            }}
                          />
                        </div>
                      </ProgressBarStyled>
                    ) : null}
                    {getImageSubText(imageObj, uploadProgress)}
                  </div>
                </ProgressCardStyled>
              </div>
            ) : (
              <>
                <CSVUploadForm data={imageObj} handleFileUpload={onImageSelection} accept='image/*' />
              </>
            )}
          </DialogBody>
          <DialogFooter>
            <Button
              primary
              className={disableDone ? 'disabled-btn' : ''}
              disabled={disableDone}
              onClick={(e) => {
                onDialogSubmit(e);
              }}
            >
              Done
            </Button>
          </DialogFooter>
        </div>
        <div className='overlay' />
      </div>
    </UploadStyled>
  );
};

ImageUploadView.propTypes = propTypes;

const ImageUploadViewWithLoading = withErrorAndLoading(ImageUploadView);

export default ImageUploadViewWithLoading;
