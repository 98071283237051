import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../../../../v4/components';

const BrandEnrollment = ({ brandEnrollment }) => {
  return <BrandEnrollmentContainer>
    <div className='brand-enrollment'>
      <h3>Brand Enrollment</h3>
      <div className='brands'>
        {brandEnrollment && brandEnrollment.length > 0 && brandEnrollment.map((brand) => (
          <div key={brand.id} className='brand-item with-border'>
            <Icon className={`${brand.is_enrolled ? 'enrolled' : 'not-enrolled'}`} iconName={`${brand.is_enrolled ? 'check-circle-full' : 'times-circle-full'}`} />
            <div className='brand-name'>{brand.title}</div>
          </div>
        ))}
      </div>
    </div>
  </BrandEnrollmentContainer>;
};


const BrandEnrollmentContainer = styled.div`
  .brands {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(30%, 1fr));
    row-gap: .5rem;
    column-gap: 1rem;
    justify-content: center;
    
    .brand-item {
      text-align: center;

      .enrolled {
        color: #287D3C;
      }

      .not-enrolled {
        color: #DA1414;
      }

      .brand-name {
        display: block;
        font-weight: 500;
        font-size: 14px;
        padding: .5rem 0;
      }
    }
  }
`

export default BrandEnrollment;
