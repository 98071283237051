import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const MASK_INVOICES = graphql(
  gql`
  mutation MaskInvoices($invoiceId: [Int]!) {
    maskInvoices(invoiceId: $invoiceId)
  }
`,
  {
    props: ({ mutate }) => ({
      maskInvoices: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const mutation = {
  maskInvoices: MASK_INVOICES,
}

export { mutation, MASK_INVOICES };

