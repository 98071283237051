import { compose } from 'recompose';
import Designation from './Designation';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_DESIGNATION, UPDATE_DESINATION
} from './API';
import { TOGGLE_STATE } from '../../../common/GqlAPI';

const ComposedDesignation = compose(
  CREATE_DESIGNATION,
  UPDATE_DESINATION,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Designation);

export default ComposedDesignation;
