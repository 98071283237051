import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../v4/components';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { headerLabelConfig, getUpdatedLabel } from '../../common/HelperFunctions';
import RoundedLetter from '../../components/RoundedLetter';

const labelMappings = {
  zone: 'zone',
  subD: 'subD',
  orderValue: 'orderValue',
  pending: 'pending',
  oneDay: 'oneDay',
  twoDay: 'twoDay',
  threeDay: 'threeday',
  threedayAbove: 'threedayAbove',
  totalInvoiced: 'totalInvoiced',
  cancelled: 'Cancelled',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.zone,
    title: 'Zone',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.subD,
    title: 'Sub D',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.orderValue,
    title: 'Order Value',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.pending,
    title: 'Pending',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.oneDay,
    title: '0-1 Day',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.twoDay,
    title: '2 Days',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.threeDay,
    title: '3 Days',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.threedayAbove,
    title: '3+ Days',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.totalInvoiced,
    title: 'Total Invoiced',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.cancelled,
    title: 'Cancelled',
    show: true,
    isDynamic: true,
  },
];

const TableHeader = (dynamicHeader = false) => (
  <thead className=''>
    <tr>
      {(dynamicHeader ? getUpdatedLabel(labelConfig) : labelConfig).map((label) =>
        label.show ? tableData(label) : null,
      )}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.zone] = <td key={`${data.distributor}-zone`}>{data.zone}</td>;
  table[labelMappings.subD] = <td key={`${data.distributor}-subD`}>{data.distributor}</td>;
  table[labelMappings.orderValue] = <td key={`${data.distributor}-orderValue`}>{fixedFloatAndCommas(data.order_value)}</td>;
  table[labelMappings.pending] = <td key={`${data.distributor}-pending`}>{fixedFloatAndCommas(data.pending)}</td>;
  table[labelMappings.oneDay] = <td key={`${data.distributor}-oneDay`}>{fixedFloatAndCommas(data.one_day_pending)}</td>;
  table[labelMappings.twoDay] = <td key={`${data.distributor}-twoDay`}>{fixedFloatAndCommas(data.two_day_pending)}</td>;
  table[labelMappings.threeDay] = <td key={`${data.distributor}-threeDay`}>{fixedFloatAndCommas(data.three_day_pending)}</td>;
  table[labelMappings.threedayAbove] = <td key={`${data.distributor}-brand`}>{fixedFloatAndCommas(data.three_day_above_pending)}</td>;
  table[labelMappings.totalInvoiced] = <td key={`${data.distributor}-totalInvoiced`}>{fixedFloatAndCommas(data.total_invoiced)}</td>;
  table[labelMappings.cancelled] = <td key={`${data.distributor}-cancelled`}>{fixedFloatAndCommas(data.cancelled)}</td>;

  return table;
};

const TableBody = ({ data, index }) => {
  const tableBody = returnTableBody(data);

  return <tr key={`psr-${index}`}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  index: PropTypes.number,
};

TableBody.defaultProps = {
  data: {},
  index: null,
};

export { TableHeader, TableBody };
