import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../components';
import { Icon } from '../../v4/components';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
};

const defaultProps = {
  value: '',
  onInputChange: () => null,
  enableErrorDisplay: false,
};

class AccessField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showText: false,
    };
  }

  toggleAccessVisibility = () => {
    const { showText } = this.state;
    this.setState({ showText: !showText });
  };

  render() {
    const { value, label, placeholder, name, handleInputChange } =
      this.props;
    const { showText } = this.state;

    return (
      <>
        <div className="access-dbs">
        <Input
          name={name}
          type={showText ? 'text ' : 'password'}
          placeholder={placeholder}
          labelContent={label}
          value={value}
          onChange={(event) => handleInputChange(event,'access',[name])}
        />
          <div className="eye">
            <Icon
              iconName={showText ? 'view' : 'hidden'}
              onClick={() => this.toggleAccessVisibility()}
            />
          </div>
        </div>
      </>
    );
  }
}

AccessField.propTypes = propTypes;

AccessField.defaultProps = defaultProps;

export default AccessField;
