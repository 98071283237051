import { compose } from 'recompose';
import CreateReturn from './CreateReturn';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_RETURN
} from './API';

const  ComposedCreateReturn = compose(
  CREATE_RETURN,
  withRequestTracker({
    query,
    mutation,
  }),
)(CreateReturn);

export default ComposedCreateReturn;
