import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { normalPresentor } from '../../../../utils/date';

const detailLabelMappings = {
  batch: 'batch',
  sku: 'sku',
  mfgDate: 'mfgDate',
  expDate: 'expDate',
  openingBalance: 'openingBalance',
  inward: 'inward',
  outward: 'outward',
  adjustment: 'adjustment',
  closingBalance: 'closingBalance',
  damages: 'damages',
  expiry: 'expiry',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.batch,
    sortable: true,
    classNames: 'fixed-width one first',
    title: 'Batch',
    show: true,
  },
  {
    id: 2,
    label: detailLabelMappings.sku,
    title: 'Item',
    sortable: true,
    classNames: 'fixed-width one second',
    show: true,
  },
  {
    id: 3,
    label: detailLabelMappings.mfgDate,
    title: 'MFG Date',
    sortable: true,
    classNames: 'fixed-width one third',
    show: true,
  },
  {
    id: 4,
    label: detailLabelMappings.openingBalance,
    title: 'Saleable',
    align: true,
    classNames: 'border-right border-left sellable',
    show: true,
  },
  {
    id: 5,
    label: detailLabelMappings.inward,
    title: 'Inward',
    align: true,
    show: true,
    classNames: 'three',
  },
  {
    id: 6,
    label: detailLabelMappings.outward,
    title: 'Outward',
    align: true,
    show: true,
    classNames: 'three',
  },
  {
    id: 7,
    label: detailLabelMappings.damages,
    title: 'Damage',
    align: true,
    show: true,
    classNames: 'three',
  },
  {
    id: 8,
    label: detailLabelMappings.expiry,
    title: 'Expiry',
    align: true,
    show: true,
    classNames: 'three',
  },
  {
    id: 9,
    label: detailLabelMappings.adjustment,
    title: 'Adjustment',
    align: true,
    show: true,
    classNames: 'three',
  },
  {
    id: 10,
    label: detailLabelMappings.closingBalance,
    title: 'Saleable',
    align: true,
    show: true,
    classNames: 'border-left four',
  },
];

const returnDetailTableBody = (data) => {
  const table = {};
  table[detailLabelMappings.batch] = <td key={`${data.batch}-id`}>{data?.batch_name}</td>;
  table[detailLabelMappings.sku] = <td key={`${data.sku}-id`}><span className="skuTitle">{data?.sku}</span></td>;
  table[detailLabelMappings.mfgDate] = (
    <td key={`${data.batch}-mfgDate`}>{data?.manufacture_date ? normalPresentor(data.manufacture_date) : 'N/A'}</td>
  );
  table[detailLabelMappings.openingBalance] = (
    <td key={`${data.sku_batch_id}-openingBalance`} className='text-right border-right border-left sellable'>
      {data?.opening_sellable}
    </td>
  );
  table[detailLabelMappings.inward] = (
    <td key={`${data.sku_batch_id}-inward`} className='text-right'>
      {data?.period_inward}
    </td>
  );

  table[detailLabelMappings.outward] = (
    <td key={`${data.sku_batch_id}-outward`} className='text-right'>
      {data?.period_outward}
    </td>
  );
  table[detailLabelMappings.damages] = (
    <td key={`${data.sku_batch_id}-damages`} className='text-right'>
      {data?.period_damages}
    </td>
  );
  table[detailLabelMappings.expiry] = (
    <td key={`${data.sku_batch_id}-expiry`} className='text-right'>
      {data?.period_expiry}
    </td>
  );
  table[detailLabelMappings.adjustment] = (
    <td key={`${data.sku_batch_id}-adjustment`} className='text-right'>
      <span className={!data.negative ? (data?.period_adjustment !== 0 ? 'positive' : '') : 'negative'}>{data?.period_adjustment}</span>
    </td>
  );
  table[detailLabelMappings.closingBalance] = <td key={`${data.sku_batch_id}-closingBalance`} className='text-right border-left'>{data?.closing_sellable}</td>;
  return table;
};

const DetailTableBody = ({ data }) => {
  const tableBody = returnDetailTableBody(data);

  return <Fragment>{detailsLabelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

DetailTableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

DetailTableBody.defaultProps = {
  data: {},
};

export { DetailTableBody, detailsLabelConfig };
