import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const SYSTEM_ALERT_FRAGMENT = gql`
  fragment SystemAlertFragment on SystemAlert {
    id
    title
    attribute
    threshold {
      value
      operator
      dimension
    }
    status
    messageSchedule: MessageSchedule {
      isRepeated
      repeatTimes
      repeatDays
      startDate
      endDate
      time
    }
    messageDetail: MessageDetail {
      message {
        title
        description
        link
        message
      }
    }
    active
  }
`;

const GET_SYSTEM_ALERT = gql`
  query getSystemAlerts($limit: Int, $offset: Int, $filter: FilterInput) {
    systemAlerts(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        ...SystemAlertFragment
      }
      count
    }
  }
  ${SYSTEM_ALERT_FRAGMENT}
`;

const query = {
  getSystemAlerts: GET_SYSTEM_ALERT,
};

const mutation = {
  toggleState: TOGGLE_STATE,
};

export { query, mutation };
