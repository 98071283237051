import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { numberPresentor } from '../../../utils/conversion';
import TargetDetailWrapper from '../../salesForce/target-Achievement/details/TargetDetailWrapper';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.instanceOf(Object),
};
const defaultProps = {
  data: {},
};

const View = ({ ...props }) => {
  const { data, settings, targetDimensionMetric } = props;
  return (
    <Fragment>
      <div className="table-wrap table-wrap-second">
        <table>
          <thead>
            <tr>
              <th>User</th>
              <th>Role</th>
              <th className="text-right width-200">
                {'Target (in '}
                <span>{targetDimensionMetric}</span>
                {')'}
              </th>
              <th className="text-right width-200">
                {'Achievement (in '}
                <span>{targetDimensionMetric}</span>
                {')'}
              </th>
              <th className="text-right">Achievement %</th>
            </tr>
          </thead>
          <tbody>
            <RecursiveComponent user={data} setting={settings} />
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;

const RecursiveComponent = ({ user, settings }) => {
  const tableData = user => {
    return (
      <>
        <td>
          <>
            {user?.ChildUsers?.length > 0 ? <span>{user?.name}</span> : <span className="active-td">{user?.name}</span>}
            {user?.active === false && (
              <b>
                <span className={'status-label passive'}>{'INACTIVE'}</span>
              </b>
            )}
          </>
        </td>
        <td>{user?.role}</td>
        <td className="text-right">{numberPresentor(user?.target)}</td>
        <td className="text-right">{numberPresentor(user?.achievement)}</td>
        <td className="text-right">
          {user?.target === 0 ? 0 : numberPresentor((user?.achievement * 100) / user?.target)}%
        </td>
      </>
    );
  };
  return (
    <AccordionTable>
      {user?.map?.(userData => (
        <tr
          key={userData?.id}
          trigger={tableData(userData)}
          isChildPresent={!userData?.ChildUsers?.length > 0}
          simpleChild={
            <tr className="active" key={userData?.id}>
              {tableData(userData)}
            </tr>
          }
        >
          {userData?.ChildUsers?.length > 0 && <RecursiveComponent user={userData?.ChildUsers} settings={settings} />}
        </tr>
      ))}
    </AccordionTable>
  );
};
