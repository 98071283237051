import { compose } from 'recompose';
import Details from './Details';
import {
  query, mutation , CREATE_GROUP_SKUS,  DELETE_GROUP_DETAILS_SKU,
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedDetails = compose(
  CREATE_GROUP_SKUS,
  DELETE_GROUP_DETAILS_SKU,
  withRequestTracker({
    query,
    mutation,
  }),
)(Details);

export default composedDetails;
