import { hyphenPresentor } from "../../../../../utils/date"

export const usersListServerToClientMapper = (data) => {
    const mappedData = data.map((item, index) => {
        return {
            id: item?.id,
            key: index,
            name: item?.name || '-',
            email: item?.email || '-',
            account_activated_date: item?.account_activated_date ? hyphenPresentor(item?.account_activated_date) : '-',
            last_active_date: item?.last_active_date ? hyphenPresentor(item?.last_active_date) : '-',
            active_days: typeof item?.active_days === 'number' && item?.active_days >= 0 ? item?.active_days : '-',
            active: item?.active,
        }
    })
    return mappedData
}