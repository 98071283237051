import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const crudRequestConfig = {
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: ({ distributorGroupId, skuIdList, status }) => ({ distributorGroupId, skuIdList, status }),
    responseName: 'toggleSkuInRetailerAssortment',
    message: 'Retailer SKU Assortment updated successfully',
  },
};
export { crudRequestConfig };
