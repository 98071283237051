import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ChannelView from './View';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { channelCategory } from '../../common/DomainConfig';
import { CHANNEL_DETAILS } from '../../../data/enums/Route';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { getPermissionForChannelCategory } from '../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { crudSuccess as crudRequestConfig, breadCrumbConfig, filterConfig, formConfig } from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';

const propTypes = {
  toggleState: PropTypes.func.isRequired,
  getChannelList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Channel extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ... queryService.baseQueryParameters.date },
      },
    };
    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.toggleState,
      [EVENT_OPERATION.UPDATE_STATUS]: props.toggleState,
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.permission = getPermissionForChannelCategory();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
    );
    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      this.setState({ data });
      this.loadTableData();
    }, this.getData);

    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getChannelList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getChannelList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.channels && response.data.channels.rows) || [];
          data.total = (response.data.channels && response.data.channels.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleEvents = (type, id) => {
    const { history } = this.props;
    const dynamicId = type === EVENT_OPERATION.CREATE ? 'create' : id;
    history.push(`/${CHANNEL_DETAILS}/${dynamicId}`);
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const { queryParameters, data } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <DialogWrapper
          onDialogSubmit={this.onFormSubmit}
          formConfig={formConfig}
          render={({ onDialogItemClick }) => (
            <Fragment>
              <div className="section-header">
                <PanelStyled>
                  <PageHeader
                    breadCrumb={breadCrumbConfig}
                    config={{
                      title: channelCategory.title,
                      create: this.permission.create,
                      download: false,
                      filter: true,
                      search: true,
                    }}
                    filter={{
                      date: queryParameters.date,
                      menuList: filterConfig.menu,
                      onFilterChange: this.basePaginationService.handleFilterChange,
                    }}
                    queryParameters={queryParameters}
                    handleCreateClick={this.handleEvents}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={this.basePaginationService.handleSearchInputChange}
                    handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                    handleDownloadClick={this.basePaginationService.handleDownloadClick}
                  />
                </PanelStyled>
              </div>
              <div className="section-content">
                <ChannelView
                  data={data}
                  permission={this.permission}
                  onIconClick={onDialogItemClick}
                  loading={serverResponseWaiting}
                  onRowClick={this.handleEvents}
                  pagination={queryParameters.pagination}
                  onBulkDelete={this.handleBulkDelete}
                  onPageChange={this.basePaginationService.onPageSelect}
                />
              </div>
            </Fragment>
          )}
        />
      </Fragment>
    );
  }
}

Channel.propTypes = propTypes;

Channel.defaultProps = defaultProps;

export default withAlert()(Channel);
