import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  crudSuccess, formConfig, breadCrumbConfig,USER_ROLES,
} from './config';
import { refValidator } from '../../../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION} from '../../../../../data/enums/EventOperation';
import { dropdownChange } from '../../../../../utils/formHandlers';
import View from './View';
import {
  BreadCrumb, Icon ,Button
} from '../../../../../v4/components';
import * as queryService from '../../../../base/query.service';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../../utils/api';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import { handleFormSubmit, responseInterpreter } from '../../../../../utils/crudResponseProcessor';
import { crudRequestConfig } from './config';
import { getUser } from '../../../../../data/dao';
import { isError } from '../../../../common/HelperFunctions';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { clone } from '../../../../../utils/arrayProcessor';
import { getUserRoleFromServer } from '../../../../../data/services/userLabel';
import { getPermissionForUserRole } from '../../../../base/permission';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class UserRoleDetails extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    const id  =this.props.match.params ? parseInt(this.props.match.params.id) : null;
    this.userInfo = getUser();
    this.state = {
      id,
      formReference: formConfig.refsObj,
      userGroupDetails:formConfig[FORM_CONFIG.MAPPER]({}),
      userGroupDetailsCloned:{},
      userGroupRoles:[],
      selectedSkus:[],
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      activeTab: 0,
      data:{
        list:[],
        total:0
      },
      enableFormValidation: false,
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      userGroupList:[],
    };
    const serverCall = {
      [EVENT_OPERATION.UPDATE_STATUS]: props.updatePermission,
    };
    this.onCRUDSuccess = responseInterpreter(this.onMutationSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.handleAPIFailure, crudRequestConfig, serverCall);
    this.permission = getPermissionForUserRole();
    // this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    const {id,update} =this.state;
    getUserRoleFromServer().then(response => {
      this.setState({
        userGroupList: (response.data.userGroups && response.data.userGroups.rows) || [],
      });
    });
    if(id){
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState(
        {
          update,
        },
        () => {
          this.getDetailsData();
          this.getPermissionData();
        },
      );
    }
  }
  getDetailsData = () => {
    const { queryParameters ,id,userGroupDetails} = this.state;
    const { getUserRoleList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    // all urls for search, filter and pagination
    getUserRoleList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          const filteredUserGroup =response.data.roles.rows.filter(a => a.id === id)[0];
          const {UserGroup,...newData} = filteredUserGroup;
        const userGroupData ={...newData,userGrpId:filteredUserGroup.UserGroup.id || 2}
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({userGroupDetails:userGroupData,userGroupDetailsCloned:clone(userGroupData),userGroupRoles:filteredUserGroup})
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };
  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getPermissionData = () => {
    const {id} =this.state;
    const { fetchPermission} = this.props;
    const { data} = this.state;
    fetchPermission(
      { roleId:id },
      {
        handleSuccess: response => {
          const responseData = response.data.fetchPermission;
          data.list=responseData;
          data.total=responseData.length;
          this.setState({data})
        },
      },
    );
  };
  onMutationSuccess = (type, response, payload) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    const entityFiltered =data.list.filter(a =>a.module === response.module);
    const operationUpdateIndex = entityFiltered[0].child.findIndex((obj => obj.program == response.program));
    entityFiltered[0].child[operationUpdateIndex].operations =response.operations;
    const newData ={...data,...{list:[...data.list,...entityFiltered]}};
    const newUniqueDataList =[...new Map(newData.list.map(item => [item.module, item])).values()]
    this.setState(
      {data:{...data,list:newUniqueDataList}}, () =>{
        displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      }
    );
  };
  handleAPIFailure = err => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, err);
  };

  handleSkuSubmit = (status, program,param,data) => {
    const { id} = this.state;
    const inputData ={
      program,
      operations:{
        ...data,[param]:status,
      }
    }
    this.onFormSubmit(EVENT_OPERATION.UPDATE_STATUS, {
      roleId:id || 0,
      input:inputData || {},
    });
  };
  handleButtonCancel = () => {
    const {update,userGroupDetailsCloned,id} =this.state;
    if(id){
      update.type = EVENT_OPERATION.READ;
      update.status = false;
      this.setState({ update ,enableFormValidation:false,userGroupDetails:userGroupDetailsCloned});
    }
    else{
      this.setState({ enableFormValidation:false,userGroupDetails:formConfig[FORM_CONFIG.MAPPER]({}) })
    }
  };

  handleDropDownChange = (value, parameterRef = []) => {
    const { userGroupDetails } = this.state;
    const updatedData = dropdownChange(userGroupDetails, parameterRef, value);
    this.setState({ userGroupDetails: updatedData });
  };

  onInputChange = (event, param) => {
    const { userGroupDetails } = this.state;
    userGroupDetails[param] = event.formattedValue;
    this.setState({ userGroupDetails });
  };

  handleButtonSubmit = () => {
    const { userGroupDetails, update, id,formReference} = this.state;
    const {type,status} = update;
    const { updateRole, displayAlert,createRole } = this.props;
    const formValidation = refValidator(formReference);
    if (!formValidation) {
      this.setState({ enableFormValidation: true });
    } else {
      const {id,...newUserGroupDetails} =userGroupDetails;
      const formattedData = ({...newUserGroupDetails});
      if (type === EVENT_OPERATION.UPDATE) {
        updateRole(
          {
           id,...formattedData
          },
          {
            handleSuccess: response => {
              displayAlert(ALERT_TYPE.SUCCESS, `User Role Details Updated`);
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
      if (type === EVENT_OPERATION.CREATE) {
      createRole(
          {
            ...formattedData
          },
          {
            handleSuccess: response => {
              displayAlert(ALERT_TYPE.SUCCESS, `User Role Details Created`);
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
    }
  };
  handleTabChange = tabIndex => {
    this.setState({ activeTab: tabIndex });
  };

  twoFaCheckboxHandler = (e) => {
    const checkboxValue = e.currentTarget.checked
    this.setState((state)=>({userGroupDetails:{...state.userGroupDetails,enableGoogleAuth:checkboxValue}}))

  }

  render() {
    const {
      data,
      userGroupDetails,
      update,
      enableFormValidation,
      formReference,
      activeTab,
      userGroupList,
      userGroupRoles,
    } = this.state;
    const { serverResponseWaiting,selectedSkus } = this.props;
    const permissionEnable  =Object.values(USER_ROLES).includes( this.userInfo.roleId);
    const tabOne = activeTab ===1;
    return (
      <Fragment>
        <div className='section-header'>
          <PanelStyled className='padding-0'>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>Details</h2>
              <div className="flex m-0">
                {update.status && (
                  <div>
                    <Button small secondary disabled={ tabOne ? false: serverResponseWaiting} onClick={() => this.handleButtonCancel()}>
                      <span>{tabOne ? 'Done' : 'Cancel'}</span>
                    </Button>
                    { tabOne ||
                    <Button small primary disabled={  tabOne ===1 ? false : serverResponseWaiting} onClick={() => this.handleButtonSubmit()}>
                      <span>Save</span>
                    </Button>
                  }
                  </div>
                )}
                {!update.status &&  this.permission.update &&(
                  <Button
                    secondary
                    iconBtnSmall
                    disabled={update.type === EVENT_OPERATION.UPDATE && update.status}
                    onClick={() => {
                      this.setState({
                        update: {
                          type: EVENT_OPERATION.UPDATE,
                          status: true,
                        },
                      });
                    }}
                    className="ml-16"
                  >
                    <Icon iconName="pencil" />
                  </Button>
                )}
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <View
          userGroupDetails ={userGroupDetails}
          onSwitchChange={this.handleSkuSubmit}
          selectedSkus={selectedSkus}
          update ={update}
          onDropDownChange={this.handleDropDownChange}
          onInputChange ={this.onInputChange}
          enableErrorDisplay={enableFormValidation}
          refsObj={formReference}
          data={data}
          onTabChange={this.handleTabChange}
          activeTab={activeTab}
          userGroupList={userGroupList}
          permissionEnable={permissionEnable}
          twoFaCheckboxHandler={this.twoFaCheckboxHandler}
        />
      </Fragment>
    );
  }
}

UserRoleDetails.propTypes = propTypes;

UserRoleDetails.defaultProps = defaultProps;

export { UserRoleDetails };

export default withAlert()(UserRoleDetails);
