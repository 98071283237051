import { compose } from 'recompose';
import SystemAlert from './SystemAlert';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { query, mutation } from './API';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const ComposedSystemAlert = compose(
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(SystemAlert);

export default ComposedSystemAlert;
