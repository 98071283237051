
import { compose } from 'recompose';
import { query } from './API';
import CallDashboard from './CallDashboard';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';


const composedCallDashboard= compose(
  withRequestTracker({
    query
  }),
)(CallDashboard);

export default composedCallDashboard;
