import styled from 'styled-components';

export const PerfectCallListStyled = styled.div`
  .form-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #272833;
    margin: 0 0 4px 0;
  }
  .criteria {
    display: flex;
    > div {
      padding: 0px;
    }
    .form-select-input {
      margin-bottom: 0px;
      label {
        display: inline;
      }
      input {
        height: auto;
      }
      .zindex-2__control {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      }
    }
    .form-input {
      input {
        height: auto;
        padding: 6.5px 16px;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
      }
    }
  }
`;
