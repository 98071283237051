import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DetailForm from './Form';
import withLoading from '../../../../../utils/composition/withLoading';

const propTypes = {
  getDetails: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  update: PropTypes.instanceOf(Object),
};

const defaultProps = {
  update: {
    type: '',
    status: false,
  },
  getDetails: () => null,
  enableErrorDisplay: false,
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
};

class DeliveryDetails extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    this.state = {
      loadingInvoiceStatus: false,
    };
  }

  render() {
    const {
      update,
      enableErrorDisplay,
      campaignDataObj,
      refsObj,
      handleInputChange,
      handleDropDownChange,
      data,
      dropDownCallBack,
    } = this.props;
    return (
      <Fragment>
        <DetailForm
          show
          update={update}
          data={data}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={handleInputChange}
          handleDropDownChange={handleDropDownChange}
          refsObj={refsObj}
          campaignDataObj={campaignDataObj}
          dropDownCallBack={dropDownCallBack}
        />
      </Fragment>
    );
  }
}

DeliveryDetails.propTypes = propTypes;

DeliveryDetails.defaultProps = defaultProps;

export default withLoading(DeliveryDetails);
