import styled from 'styled-components';

const WarehouseLevelsStyled = styled.div`
  .evolve-dialog__body > span {
    display: block;
  }
  .update {
    .form-upper {
      padding-bottom: 24px;
    }
  }
  form {
    padding: 24px 0 0 0;
    max-height: 500px;
    overflow: auto;
    .form-upper,
    .form-lower {
      padding: 0 24px;
      &.pad-b-24 {
        padding-bottom: 24px;
      }
    }
    .form-lower {
      margin: 0;
      border: 1px solid #e7e7ed;
      padding-top: 24px;
    }
    .placeholder {
      position: relative;
      &::after {
        position: absolute;
        right: 10px;
        top: 35px;
        content: attr(data-placeholder);
        opacity: 0.4;
      }
    }
  }
`;

export default WarehouseLevelsStyled;
