import { compose } from 'recompose';
import SubDGroup from './SubDGroup';
import {
  query, mutation, TOGGLE_SUBD_IN_SUBD_GROUP
} from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedSubDGroup = compose(TOGGLE_SUBD_IN_SUBD_GROUP, withRequestTracker({ query, mutation }))(SubDGroup);

export default composedSubDGroup;
