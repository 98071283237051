import React, { Fragment } from 'react';
import FormContentStyled from '../../../../../components/global-css/FormContentStyled';
import {
  Input,
  CustomSelect,
  AutoComplete,
} from '../../../../../components';
import { Col, Row } from '../../../../../components/Grid';
import { weekDays } from '../config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MessageStyled } from '../../../../../components/Input/TextIndex';
import GeneralStyled from './GeneralStyled';
import { MESSAGE_PLATFORM } from '../enums';
import { Switch, SingleDatePicker, Icon, Button, CheckBox } from '../../../../../v4/components';

function General({ ...props }) {
  const {
    data,
    handleInputChange,
    menu,
    handleDropDownChange,
    radioButtonChanged,
    handleAutoCompleteChange,
    onDropDownChange,
    onIconClick,
    enableErrorDisplay,
    refsObj,
    crudMode,
    dataDictionaryList,
  } = props;

  const zoneType = [
    {
      id: 1,
      title: 'Zone',
      value: 'ZONE',
    },
  ];
  const townType = [
    {
      id: 1,
      title: 'Town',
      value: 'TOWN',
    },
  ];
  const routeType = [
    {
      id: 1,
      title: 'Route',
      value: 'ROUTE',
    },
  ];

  const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const selectedChannelIdList = data.channelList.map((channelCategory) => channelCategory.channelId);

  const getDropDownForChannelList = (channelId) =>
    menu.channelList.filter((channel) => channel.id === channelId || !selectedChannelIdList.includes(channel.id));

  const getCategoryListUnderChannel = (channelId) =>
    menu.categoryList.filter((category) => category.Channel.id === channelId);

  const handleCategoryAutoCompleteChange = (index) => (value, fieldName) =>
    handleAutoCompleteChange(value, fieldName, index);

  return (
    <GeneralStyled>
      <div className='create-ui tab-less-height'>
        <div className={`create-ui-inner ${crudMode === EVENT_OPERATION.READ ? 'disabled' : ''}`}>
          <FormContentStyled>
            <h3>General</h3>
            <div className='form-input-wrap'>
              <Input
                name='title'
                type='text'
                enableValidation
                value={data.title}
                labelContent='Push Message Title'
                placeholder='Push Message Title'
                ref={(ref) => (refsObj.title = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleInputChange(event)}
              />
              <Input
                labelContent='Campaign'
                type='text'
                name='campaign'
                placeholder='Campaign'
                onChange={(event) => handleInputChange(event)}
                value={data.campaign}
              />
              <Input
                labelContent='Message Category'
                type='text'
                name='category'
                placeholder='Category'
                className='custom-select'
                value={data.category}
                onChange={(event) => handleInputChange(event)}
              />
            </div>
          </FormContentStyled>
          <FormContentStyled>
            <h3>Target Audience</h3>
            <div className='form-input-wrap'>
              <CustomSelect
                ref={(ref) => (refsObj.targetAudience = ref)}
                enableErrorDisplay={enableErrorDisplay}
                labelContent='Audience'
                name='targetAudience'
                placeholder='Select audience'
                className='custom-select'
                options={menu.targetAudience}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                onChange={(event) => handleDropDownChange(event.id, ['targetAudience'], 'targetAudience')}
                value={menu.targetAudience.filter(({ id }) => id === data.targetAudience)}
                isSearchable={false}
              />
              {data.targetAudience === 1 && (
                <CustomSelect
                  ref={(ref) => (refsObj.userGroup = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  labelContent='User Group'
                  name='userGroup'
                  placeholder='Select user'
                  className='custom-select'
                  options={menu.userGroup}
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ name }) => name}
                  onChange={(event) => handleDropDownChange(event, ['userGroup'], 'userGroup')}
                  value={menu.userGroup.filter(({ id }) => data.userGroup.includes(id))}
                  isMulti
                />
              )}
              {data.targetAudience === 2 && (
                <>
                  <div className='permission-choose'>
                    <label>Outlets</label>
                    <div>
                      <label className='radio-list'>
                        <input
                          type='radio'
                          name='outlet'
                          value='Active'
                          onChange={(e) => radioButtonChanged(e)}
                          checked={data.outlet ? data.outlet.active : false}
                        />
                        <span className='text'>Active</span>
                      </label>
                      <label className='radio-list'>
                        <input
                          type='radio'
                          name='outlet'
                          value='Verified'
                          onChange={(e) => radioButtonChanged(e)}
                          checked={data.outlet ? data.outlet.verified : false}
                        />
                        <span className='text'>Verified</span>
                      </label>
                      <label className='radio-list'>
                        <input
                          type='radio'
                          name='outlet'
                          value='Both'
                          onChange={(e) => radioButtonChanged(e)}
                          checked={data.outlet ? data.outlet.both : false}
                        />
                        <span className='text'>Both</span>
                      </label>
                    </div>
                  </div>
                  <Input
                    name='invoiced'
                    type='number'
                    enableValidation
                    value={data.invoiced}
                    labelContent='Last Invoiced (in days)'
                    argument={{ min: 0 }}
                    placeholder='Last Invoiced (in days)'
                    onChange={(event) => handleInputChange(event)}
                  />
                  <div className='location'>
                    <FormContentStyled>
                      <h3 className='w-100'>Location</h3>
                      <div className='form-input-wrap'>
                        <Row>
                          <Col md={4}>
                            <CustomSelect
                              name='zone'
                              placeholder='Select Zone'
                              className='custom-select'
                              options={zoneType}
                              getOptionValue={({ id }) => id}
                              getOptionLabel={({ title }) => title}
                              value={zoneType.filter(({ value }) => value === data.location.zone)}
                            />
                          </Col>
                          <Col md={8}>
                            <AutoComplete
                              name='territoryList'
                              dropDownList={menu.territoryList}
                              placeholder='Search Zone'
                              selectedDropDowns={data.territoryList}
                              onDropDownSelection={handleAutoCompleteChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <CustomSelect
                              name='town'
                              placeholder='Select Town'
                              className='custom-select'
                              options={townType}
                              getOptionValue={({ id }) => id}
                              getOptionLabel={({ title }) => title}
                              value={townType.filter(({ value }) => value === data.location.town)}
                            />
                          </Col>
                          <Col md={8}>
                            <AutoComplete
                              name='townList'
                              dropDownList={menu.townList}
                              placeholder='Search Town'
                              selectedDropDowns={data.townList}
                              onDropDownSelection={handleAutoCompleteChange}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={4}>
                            <CustomSelect
                              name='route'
                              placeholder='Select Route'
                              className='custom-select'
                              options={routeType}
                              getOptionValue={({ id }) => id}
                              getOptionLabel={({ title }) => title}
                              value={routeType.filter(({ value }) => value === data.location.route)}
                            />
                          </Col>
                          <Col md={8}>
                            <AutoComplete
                              name='routeList'
                              dropDownList={menu.routeList}
                              selectedDropDowns={data.routeList}
                              onDropDownSelection={handleAutoCompleteChange}
                              placeholder='Search Route'
                            />
                          </Col>
                        </Row>
                      </div>
                    </FormContentStyled>
                  </div>
                  <div className='channel_category'>
                    <FormContentStyled>
                      <h3 className='w-100'>Channel / Category</h3>
                      <div className='form-input-wrap'>
                        {data.channelList.map((channelCategory, index) => (
                          <Fragment key={index}>
                            <Row>
                              <Col md={4}>
                                <CustomSelect
                                  placeholder='Select Channel'
                                  className='custom-select'
                                  options={getDropDownForChannelList(channelCategory.channelId)}
                                  getOptionValue={({ id }) => id}
                                  onChange={(event) => onDropDownChange(event.id, index, 'channelList')}
                                  getOptionLabel={({ title }) => title}
                                  value={menu.channelList.filter(({ id }) => id === channelCategory.channelId)}
                                />
                              </Col>
                              <Col md={8} className='auto__cancel'>
                                <AutoComplete
                                  name='categoryList'
                                  dropDownList={getCategoryListUnderChannel(channelCategory.channelId)}
                                  placeholder='Search'
                                  selectedDropDowns={[...channelCategory.categoryIds]}
                                  onDropDownSelection={handleCategoryAutoCompleteChange(index)}
                                />
                                {index > 0 && (
                                  <div className='cross__icon'>
                                    <Icon
                                      iconName='times'
                                      primary
                                      onClick={() => onIconClick('remove', 'channelList', index)}
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Fragment>
                        ))}
                        <div className='filter-btn add '>
                          <Button
                            iconBtnSmall
                            secondary
                            iconName='plus'
                            onClick={() => onIconClick('add', 'channelList')}
                          />
                        </div>
                      </div>
                    </FormContentStyled>
                  </div>
                </>
              )}
            </div>
          </FormContentStyled>
          <FormContentStyled className='push-message-compose'>
            <h3>Compose</h3>
            <div className='form-input-wrap'>
              <CustomSelect
                labelContent='Message Platform'
                name='message-platform'
                placeholder='Select message type'
                className='custom-select message-platform'
                options={menu.messagePlatform}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                ref={(ref) => (refsObj.platform = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleDropDownChange(event.id, ['platform'])}
                value={menu.messagePlatform.filter(({ id }) => id === data.platform)}
                isSearchable={false}
              />
              {data.platform && (
                <Fragment>
                  {data.platform === MESSAGE_PLATFORM.NOTIFICATION ? (
                    <>
                      <Input
                        name='title'
                        type='text'
                        enableValidation
                        value={data.message.title}
                        labelContent='Title'
                        placeholder='Title'
                        ref={(ref) => (refsObj.messageTitle = ref)}
                        enableErrorDisplay={enableErrorDisplay}
                        onChange={(event) => handleInputChange(event, 'message')}
                      />
                      <div className='form-input text-area'>
                        <label htmlFor=''>Description</label>
                        <textarea
                          name='description'
                          type='text'
                          id='description'
                          ref={(ref) => (refsObj.description = ref)}
                          value={data.message.description}
                          onChange={(event) => handleInputChange(event, 'message')}
                          required
                        />
                        {enableErrorDisplay && data.message.description === '' ? (
                          <MessageStyled className='form-error'>
                            <span className='error-message'>
                              <Icon iconName='exclamation-full' />
                              {'Must not be empty'}
                            </span>
                          </MessageStyled>
                        ) : (
                          ''
                        )}
                      </div>
                      <Input
                        name='link'
                        type='text'
                        value={data.message.link}
                        labelContent='Link'
                        placeholder='Eg: https://bit.ly/uK590k3'
                        onChange={(event) => handleInputChange(event, 'message')}
                      />
                    </>
                  ) : data.platform === MESSAGE_PLATFORM.SMS ? (
                    <div className='form-input text-area'>
                      <label htmlFor=''>SMS Message</label>
                      <textarea
                        name='message'
                        type='text'
                        id='message'
                        ref={(ref) => (refsObj.message = ref)}
                        value={data.message.message}
                        onChange={(event) => handleInputChange(event, 'message')}
                        required
                      />
                      {enableErrorDisplay && data.message.message === '' ? (
                        <MessageStyled className='form-error'>
                          <span className='error-message'>
                            <Icon iconName='exclamation-full' />
                            {'Must not be empty'}
                          </span>
                        </MessageStyled>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    <div className='form-input text-area'>
                      <label htmlFor=''>Viber Message</label>
                      <textarea
                        name='message'
                        type='text'
                        id='message'
                        ref={(ref) => (refsObj.message = ref)}
                        value={data.message.message}
                        onChange={(event) => handleInputChange(event, 'message')}
                        required
                      />
                      {enableErrorDisplay && data.message.message === '' ? (
                        <MessageStyled className='form-error'>
                          <span className='error-message'>
                            <Icon iconName='exclamation-full' />
                            {'Must not be empty'}
                          </span>
                        </MessageStyled>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </Fragment>
              )}
            </div>
            <>
              {dataDictionaryList.length ? (
                <span className={data.platform === MESSAGE_PLATFORM.NOTIFICATION && 'notification-span'}>
                  <p>
                    <Icon iconName='info-circle-open' />
                    <span>
                      <b>
                        Use {'{attribute}'} to add attributes to your message. Some of the attributes are listed below:
                      </b>
                      <strong>{dataDictionaryList.map((dictData) => dictData.attribute).join(', ')}</strong>
                    </span>
                  </p>
                </span>
              ) : null}
            </>
          </FormContentStyled>
          <FormContentStyled>
            <h3>Schedule Time</h3>
            <div className='form-input-wrap'>
              <Switch
                onToggle={(checked, event) => {
                  handleInputChange({
                    formattedValue: !!checked,
                    target: {
                      name: 'isScheduled',
                      type: 'boolean',
                      value: !!checked,
                    },
                  });
                }}
                checked={data.isScheduled}
                name='isScheduled'
                labelContent='Enable Schedule'
              />
              {data.isScheduled && (
                <>
                  <Switch
                    onToggle={(checked, event) => {
                      handleInputChange({
                        formattedValue: !!checked,
                        target: {
                          name: 'isRepeated',
                          type: 'boolean',
                          value: !!checked,
                        },
                      });
                    }}
                    checked={data.isRepeated}
                    name='isRepeated'
                    labelContent='Repeat'
                  />
                  {data.isRepeated && (
                    <>
                      <div className='permission-choose'>
                        <label>Repeat Times</label>
                        <div>
                          <label className='radio-list'>
                            <input
                              type='radio'
                              name='repeatedTimes'
                              value='Daily'
                              onChange={(e) => radioButtonChanged(e)}
                              checked={data.repeatedTimes ? data.repeatedTimes.daily : false}
                            />
                            <span className='text'>Daily</span>
                          </label>
                          <label className='radio-list'>
                            <input
                              type='radio'
                              name='repeatedTimes'
                              value='Weekly'
                              onChange={(e) => radioButtonChanged(e)}
                              checked={data.repeatedTimes ? data.repeatedTimes.weekly : false}
                            />
                            <span className='text'>Weekly</span>
                          </label>
                        </div>
                      </div>
                      {data.repeatedTimes && !data.repeatedTimes.daily && (
                        <div className='reapeat__days'>
                          <label>Repeat Days</label>
                          <div className='checkbox-list'>
                            {week.map((d, index) => (
                              <CheckBox
                                key={d}
                                checked={data.repeatDays.includes(weekDays[d])}
                                name={d}
                                className='checkbox'
                                labelText={d.slice(0, 3).toUpperCase()}
                                onCheckBoxClick={(e) => {
                                  handleInputChange(
                                    {
                                      formattedValue: weekDays[d],
                                      target: {
                                        name: parseInt(data.repeatDays.length, 10),
                                        value: weekDays[d],
                                        checked: !!e.target.checked,
                                      },
                                    },
                                    'repeatDays',
                                    [],
                                  );
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {data.isRepeated ? (
                    <Fragment>
                      <div className='form-input custom-date'>
                        <label>Start Date</label>
                        <SingleDatePicker
                          date={data.startDate}
                          name='startDate'
                          onChange={(name, date) =>
                            handleInputChange({
                              target: {
                                name: 'startDate',
                                value: date,
                              },
                              value: date,
                            })
                          }
                          labelContent='Start Date'
                          minDate={new Date()}
                        />
                      </div>
                      <div className='form-input custom-date'>
                        <label>End Date</label>
                        <SingleDatePicker
                          date={data.endDate}
                          name='endDate'
                          placeholder='End Date'
                          onChange={(name, date) =>
                            handleInputChange({
                              target: {
                                name: 'endDate',
                                value: date,
                              },
                              value: date,
                            })
                          }
                          labelContent='End Date'
                          minDate={new Date()}
                        />
                      </div>
                    </Fragment>
                  ) : (
                    <div className='form-input custom-date'>
                      <label>Date</label>
                      <SingleDatePicker
                        date={data.endDate}
                        name='date'
                        placeholder='End Date'
                        onChange={(name, date) => {
                          handleInputChange({
                            target: {
                              name: 'endDate',
                              value: date,
                            },
                            value: date,
                          });
                          handleInputChange({
                            target: {
                              name: 'startDate',
                              value: date,
                            },
                            value: date,
                          });
                        }}
                        labelContent='Date'
                        minDate={new Date()}
                      />
                    </div>
                  )}
                  <div className='time'>
                    <>
                      <label>Time</label>
                      <div className='time__hours'>
                        <Input
                          value={data.times.hours}
                          type='number'
                          onChange={(event) => handleInputChange(event, 'times', ['times', 'hours'])}
                          argument={{
                            max: 12,
                          }}
                        />
                        <label>HH</label>
                      </div>
                      <div className='time__hours'>
                        <Input
                          value={data.times.minutes}
                          type='number'
                          onChange={(event) => handleInputChange(event, 'times', ['times', 'minutes'])}
                          allowMultiZeroBeforeNumber
                          argument={{
                            max: 60,
                          }}
                        />
                        <label>MM</label>
                      </div>
                      <div className='time__hours'>
                        <CustomSelect
                          name='period'
                          className='custom-select'
                          options={menu.periodType}
                          getOptionValue={({ id }) => id}
                          getOptionLabel={({ title }) => title}
                          value={menu.periodType.filter(({ title }) => title === data.times.period)}
                          onChange={(event) => handleDropDownChange(event.title, ['times', 'period'])}
                        />
                        <label>AM/PM</label>
                      </div>
                    </>
                  </div>
                </>
              )}
            </div>
          </FormContentStyled>
        </div>
      </div>
    </GeneralStyled>
  );
}

export default General;
