import React from 'react';
import { refGenerator } from '../../../utils';
import { fileParser } from '../../../utils/parser';
import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { getFilterConfig } from '../../../utils/filter';
import { salesForce as salesForceRoute } from '../../../data/enums/Route';
import { FILTER } from '../../../data/enums/filter';

const breadCrumb = [salesForceRoute];

const Title = 'ROUTERETAILMAPPING';
const successTitle = 'route retail mapping';

const formConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    file: element.file || {},
  }),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${Title}`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({
      title: data.title,
      channelType: data.channelType,
    }),
    responseName: 'createChannel',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
      channelType: data.channelType,
    }),
    responseName: 'updateChannel',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: 'Channel',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: 'Route',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: Title,
        },
      }));

      return fileData;
    },
    responseName: 'importCSV',
    message: `${successTitle} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

const RouteContext = React.createContext({});

const filter = getFilterConfig([FILTER.TOWN, FILTER.DSE]);

export {
  crudSuccess,
  breadCrumb as breadCrumbConfig,
  formConfig,
  RouteContext,
  filter as filterConfig,
  Title as routeTitle,
};
