// import { element } from 'prop-types';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { FORM_CONFIG } from '../../../data/enums/config';
import { refGenerator } from '../../../utils/refGenerator';

const title = 'Classification';

const breadCrumb = [configurationRoute];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['title']),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['title']),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Delete',
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.TITLE]: 'View',
  },
};

export {
  breadCrumb as breadCrumbConfig, formConfig, title
};
