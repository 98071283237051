import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { CustomSelect, Input } from '../../../../../../components';
import { Icon,Button } from '../../../../../../v4/components';
import { budgetMessage } from '../config';
import { EMPTY, INVALID } from '../../../../../../data/enums/ErrorMessage';
import withLoading from '../../../../../../utils/composition/withLoading';
import withFormWrapper from '../../../../../../utils/composition/withFormWrapper';
import { fixedFloat } from '../../../../../../utils/conversion';

const propTypes = {
  disableAdd: PropTypes.bool,
};
const defaultProps = {
  disableAdd: false,
};
const BudgetSelect = ({ ...props }) => {
  const {
    data,
    title,
    onHandleInputChange,
    enableErrorDisplay,
    refsObj,
    handleAdd,
    handleCancel,
    subCampaignTypeList,
    handleDropDownChange,
    budgetOverflowFlag,
    campaignDataObj,
    handleDimensionChange,
    dimensionList,
    disableAdd,
  } = props;
  const subCampaginListed = data.subCampaignBudget.map((item) => item.budgetType);
  const budgetTypeList = subCampaignTypeList.map((item) => item.budgetType).flat();
  const budgetListFiltered = budgetTypeList.filter((val) => !subCampaginListed.includes(val));
  const DimensionFiltered = dimensionList.filter((i) => i.value !== 'VALUE');
  return (
    <Fragment>
      <label htmlFor=''>Budget</label>
      <div className='form-input-wrap'>
        {data.subCampaignBudget.map((subdata, index) => (
          <div className='select-wrap' key={index}>
            <div className='list-content'>
              <label className='large custom-control-label-text budget-select'>
                <CustomSelect
                  labelContent='Sub Campaign Budget'
                  className='custom-select'
                  options={budgetListFiltered}
                  placeholder='Select'
                  getOptionValue={(item) => item}
                  getOptionLabel={(item) => item}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  ref={(ref) => (ref === null ? (refsObj.budgetType = ' ') : (refsObj.budgetType = ref))}
                  onChange={(event) => {
                    handleDropDownChange(event, ['subCampaignBudget', [index], 'budgetType']);
                  }}
                  value={budgetTypeList.filter((item) => item === subdata.budgetType)}
                />
              </label>
              <label className='large custom-control-label-text select-value'>
                <CustomSelect
                  enableValidation
                  options={DimensionFiltered}
                  labelContent='Budget Value Type'
                  className='custom-select'
                  placeholder='Select'
                  getOptionValue={({ value }) => value}
                  getOptionLabel={({ label }) => label}
                  ref={(ref) => (ref === null ? (refsObj.dimension = ' ') : (refsObj.dimension = ref))}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) =>
                    handleDimensionChange(data, event.value, ['subCampaignBudget', [index], 'dimension'])
                  }
                  value={DimensionFiltered.filter(({ value }) => value === subdata.dimension)}
                />
              </label>
              <label className={`custom-control-label-number ${subdata.dimension === 'PERCENT' ? 'flex-wrap' : ''}`}>
                <Input
                  type='number'
                  name='budgetValue'
                  rule='isFloat'
                  placeholder={subdata.dimension === 'PERCENT' ? 'Percent' : 'Amount'}
                  value={subdata.value > 0 ? subdata.value : ''}
                  ref={(ref) => (ref === null ? (refsObj.budgetValue = ' ') : (refsObj.budgetValue = ref))}
                  enableValidation
                  decimalWithPrecedingZero
                  externalValidation={!budgetOverflowFlag}
                  enableErrorDisplay={enableErrorDisplay || budgetOverflowFlag}
                  errorMessage={
                    subdata.value
                      ? budgetOverflowFlag
                        ? `${budgetMessage} ${campaignDataObj.budget}`
                        : INVALID
                      : EMPTY
                  }
                  onChange={(event) =>
                    onHandleInputChange(data, event, 'subCampaignBudget', ['subCampaignBudget', [index], 'value'])
                  }
                />
                {subdata.dimension === 'PERCENT' && (
                  <Input
                    type='number'
                    rule='isFloat'
                    decimalWithPrecedingZero
                    name='calculatedValue'
                    value={fixedFloat(subdata.calculatedValue)}
                    disabled
                    placeholder='Amount'
                  />
                )}
              </label>
              <Icon iconName='times' onClick={() => handleCancel(subdata.budgetType, 'budgetType', data)} />
            </div>
          </div>
        ))}
        {budgetListFiltered.length > 0 && (
          <Button small secondary onClick={() => handleAdd('Budget', data)} className={disableAdd ? 'disabled' : ''}>
            <Icon iconName='plus' />
          </Button>
        )}
      </div>
    </Fragment>
  );
};

BudgetSelect.propTypes = propTypes;

BudgetSelect.defaultProps = defaultProps;

export default withLoading(withFormWrapper(BudgetSelect));
