import styled from 'styled-components';

const CoverageStyled = styled.div`
  .channel {
    table {
      tr {
        cursor: pointer;
      }
    }
  }

  .route,
  .distributor .category {
    table {
      tbody {
        tr {
          cursor: default;
        }
      }
    }
  }
`;
export default CoverageStyled;
