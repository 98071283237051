import styled from 'styled-components';

const BulkSelectStyle = styled.div`
  .button-section {
    .primary {
      background: #5aca75;
      border: #5aca75;
    }
    .secondary {
      background: #ff5f5f;
      border: #ff5f5f;
      color: #ffffff;
    }
  }
`;

export default BulkSelectStyle;
