import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { SingleDatePicker } from '../../../../../components/DatePicker';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { Input } from '../../../../../components';
import { INVALID } from '../../../../../data/enums/ErrorMessage';
import { Switch, CheckBox } from '../../../../../v4/components';

const propTypes = {
  info: PropTypes.shape({
    title: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    active: PropTypes.bool,
    promotionId: PropTypes.number,
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func,
  onDateRangeChange: PropTypes.func.isRequired,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  enableErrorDisplay: false,
  refsObj: {},
  onInputChange: () => null,
};

const View = ({ ...props }) => {
  const { info, refsObj, onInputChange, onDateRangeChange, enableErrorDisplay } = props;

  const displayPromotionId = !Number.isNaN(info.promotionId) && info.promotionId !== 0;

  return (
    <Row>
      <Col md={8}>
        <Row className='mb-24'>
          <Col md={6}>
            <Input
              name='title'
              type='text'
              enableValidation
              value={info.title}
              labelContent='Promotion Title'
              placeholder='Add Title'
              ref={(ref) => (refsObj.title = ref)}
              enableErrorDisplay={enableErrorDisplay}
              onChange={(event) => onInputChange(event, 'info', ['title'])}
            />
          </Col>
          <Col md={6}>
            {displayPromotionId && (
              <Input
                disabled
                name='promotionId'
                type='text'
                enableValidation
                value={info.promotionId}
                labelContent='Promotion ID'
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => onInputChange(event, 'info', ['promotionId'])}
              />
            )}
          </Col>
        </Row>
        <Row className='mb-24'>
          <Col md={6} className='mb-24'>
            <Input
              name='shortDescription'
              type='text'
              enableValidation
              value={info.shortDescription}
              labelContent='Short Description'
              placeholder='Add short description'
              ref={(ref) => (refsObj.shotDescription = ref)}
              enableErrorDisplay={enableErrorDisplay}
              onChange={(event) => onInputChange(event, 'info', ['shortDescription'])}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} className='mb-24'>
            <Input
              name='budget'
              type='number'
              rule='matches'
              enableValidation
              value={info.budget}
              labelContent='Budget'
              placeholder='Add budget'
              ref={(ref) => (refsObj.budget = ref)}
              argument={/^(?:[1-9]\d*|0)?(?:\.\d+)?$/} /* matches positive float digits and empty string */
              enableErrorDisplay={enableErrorDisplay}
              errorMessage={INVALID}
              onChange={(event) => onInputChange(event, 'info', ['budget'])}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} className='mb-24'>
            <div className='single-date-picker'>
              <label>Start Date</label>
              <SingleDatePicker name='startDate' date={info.startDate} onChange={onDateRangeChange} />
            </div>
          </Col>
          <Col md={6}>
            <div className='single-date-picker'>
              <label>End Date</label>
              <SingleDatePicker name='endDate' date={info.endDate} onChange={onDateRangeChange} />
            </div>
          </Col>
        </Row>
        <div className='mb-24 promotion-valid-count'>
          <label htmlFor=''>Promotion Valid Count</label>
          <div className='form-input-checkbox'>
            <CheckBox
              onCheckBoxClick={(event) => {
                onInputChange(
                  {
                    formattedValue: !!event.target.checked,
                    target: {
                      name: 'status',
                      type: 'boolean',
                      value: !!event.target.checked,
                    },
                  },
                  'validCount',
                  ['validCount', 'status'],
                );
              }}
              checked={info.validCount.status}
              labelText='Enable'
            />
            {info.validCount.status && (
              <Input
                type='number'
                value={info.validCount.count}
                onChange={(event) => onInputChange(event, 'info', ['validCount', 'count'])}
              />
            )}
          </div>
        </div>
        <div className='status-switch mb-24'>
          <Switch
            onToggle={(checked, event) => {
              onInputChange({
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              });
            }}
            checked={info.active}
            name='active'
            labelContent='Status'
          />
        </div>
        <div className='status-switch'>
          <Switch
            onToggle={(checked, event) => {
              onInputChange({
                formattedValue: !!checked,
                target: {
                  name: 'notification',
                  type: 'boolean',
                  value: !!checked,
                },
              });
            }}
            checked={info.notification}
            name='notification'
            labelContent='Notify Users'
          />
        </div>
      </Col>
    </Row>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const PromotionDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default PromotionDetailViewWithErrorAndLoading;
