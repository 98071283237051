const redMarkerColor = '#FF5F5F';
const greenMarkerColor = '#50D2A0';
const googleMapPoint1 = 30;
const googleMapPoint2 = 50;
const fillOpacity = 0.8;
const strokeWeight = 2;
const markerIconPath =
  'M24,4 C16.26,4 10,10.26 10,18 C10,28.5 24,44 24,44 C24,44 38,28.5 38,18 C38,10.26 31.74,4 24,4 Z M24,23 C21.24,23 19,20.76 19,18 C19,15.24 21.24,13 24,13 C26.76,13 29,15.24 29,18 C29,20.76 26.76,23 24,23 Z';
export {
  redMarkerColor,
  greenMarkerColor,
  googleMapPoint1,
  googleMapPoint2,
  markerIconPath,
  fillOpacity,
  strokeWeight,
};
