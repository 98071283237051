import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import  TableCardStyled from './tableCardStyled';

const propTypes = {
  dimensions: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {};

class TableCard extends Component {
  static getDerivedStateFromProps(props, state) {
  return null;
  }

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }


  loadData = () => {
    // this.setState({ dataList: dataListSet }, () => this.calculateScheduleData());
  };

  render() {
    const { dataList,title,userSpecificHeaderTitle} = this.props;
   
    let dynamicHeaderData = Object.keys((dataList&& dataList.length && dataList[0]) || []);

    dynamicHeaderData.pop()

    if(dynamicHeaderData?.[0]==='brand_id') {
      dynamicHeaderData.shift()
    }

    return (
      <TableCardStyled>
        <h3 className='table-title'>{title}</h3>
        <div className="table-wrap">
       <table>
        <thead>
          <tr>
            {dynamicHeaderData?.map(a =>(<th>{userSpecificHeaderTitle(a)}</th>))}
          </tr>
        </thead>
        <tbody>
        {dataList?.map(d=>(
        <tr>
          {dynamicHeaderData?.map( a =>(<td>{d[a]}</td>))}
          </tr>
        ))}
        </tbody>
       </table>
       </div>
      </TableCardStyled>
    );
  }
}

TableCard.propTypes = propTypes;

TableCard.defaultProps = defaultProps;

export default TableCard;
