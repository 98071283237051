import PropTypes from 'prop-types';
import React from 'react';
import SubDGroup from './subDGroup';
import SkuAssortment from './skuAssortment';
import RetailerSkuAssortment from './retailerAssortment';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import SubDStyled from './SubDStyled';
import {
  Tab, TabList, TabPanel, Tabs, BreadCrumb, Button
} from '../../../../v4/components';

const propTypes = {
  activeTab: PropTypes.number,
  data: PropTypes.instanceOf(Object),
  dialog: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

const defaultProps = {
  activeTab: 0,
  data: {},
  dialog: { type: '', element: '' },
  permission: PERMISSION_OBJ,
};

const View = ({ dialog, data, permission, onIconClick, activeTab, onTabChange, breadCrumbConfig, onDownloadClick }) => (
  <SubDStyled>
    <div className='section-header'>
      <PanelStyled className='padding-0'>
        <BreadCrumb list={breadCrumbConfig} />
        <PanelHeader>
          <div className='flex items-center justify-between m-0 flex-1'>
            <h2>{dialog.type === EVENT_OPERATION.UPDATE ? `Edit ${data.title}` : `${data.title}`}</h2>
            <div className='flex m-0'>
              {permission.delete && (
                <Button
                  secondary
                  iconBtnSmall
                  onClick={() => onIconClick(EVENT_OPERATION.DELETE, data)}
                  iconName='trash'
                />
              )}
              {permission.update && (
                <Button
                  secondary
                  iconBtnSmall
                  iconName='pencil'
                  onClick={() => onIconClick(EVENT_OPERATION.UPDATE, data)}
                />
              )}
            </div>
          </div>
        </PanelHeader>
      </PanelStyled>
    </div>
    <div className='section-content custom-height section-tab'>
      <Tabs>
        <TabList>
          <Tab>
            <span className='tab-label'>Sub D</span>
          </Tab>
          <Tab>
            <span className='tab-label'>SKU Assortment</span>
          </Tab>
          <Tab>
            <span className='tab-label'>Retailer SKU Assortment</span>
          </Tab>
        </TabList>
        <div className='tab-content-wrap'>
          <TabPanel>
            <section id='sub-d-tab'>
              <SubDGroup distributorGroupId={data.id} />
            </section>
          </TabPanel>
          <TabPanel>
            <section id='sku-assortment-tab'>
              <SkuAssortment distributorGroupId={data.id} />
            </section>
          </TabPanel>
          <TabPanel>
            <section id='retailer-sku-assortment-tab'>
              <RetailerSkuAssortment distributorGroupId={data.id} />
            </section>
          </TabPanel>
        </div>
      </Tabs>
    </div>
  </SubDStyled>
);

View.prototype = propTypes;

View.defaultProps = defaultProps;

const SubDAssortmentDetailView = withErrorAndLoadingScreen(View);

export default SubDAssortmentDetailView;
