import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_STOCK_COUNT_LIST } from '../List/API';


const GET_LOGISTIC_USER = gql`query Query {
    getLogisticUser
  }
`;


const GET_MT_BASIC_DETAIL = gql`
    query getMTStockList($offset: Int, $limit: Int, $filter: FilterInput){
        getMTStockList(offset: $offset, limit: $limit, filter: $filter) {
            count
            rows {
                id
                title
                count_days
                countskus
                assigned_to
            }
        }
    }
`;

const GET_STOCK_SKU_STATUS = gql`
query getStockSkuStatus($stockId: Int) {
  getStockSkuStatus(stockId: $stockId) {
    rows {
      sku_family_id
      sku_id
      sku_status
      stock_count_id
    }
  }
}
`;

const GET_STOCK_COUNT_DETAILS = gql`
query getStockCountDetails($stockId: Int!, $buId: Int, $categoryId: Int, $brandId: Int) {
    getStockCountDetails(stockId: $stockId, buId: $buId, categoryId: $categoryId, brandId: $brandId) {
      count
      rows {
        Brand {
          id
          count
        }
        Category {
          id
          count
        }
        Sku {
          skus
          count
        }
        SkuFamily {
          skufamilies
          count
        }
      }
    }
  }
`;

const GET_BRAND_DETAILS = gql`
    query getBrandDetails {
        catalogDetails(catalogId: 3, limit:1000){
            rows {
                id
                title
                parentCatalogDetailId
            }
        }
    }
`;

const GET_SKU_DETAILS = gql`
    query getBrandsSkus($value: Int) {
            catalogLevelDetails(
                catalogId: 3
                catalogDetailId: $value
            ){
                count
                rows{
                    id
                    title
                    SKUs{
                        id
                        title
                    }
                    SkuFamilies{
                        id: catalogDetailId
                        title
                    }

                }
            }
    }
`;

const CREATE_MT_SKUS = graphql(gql`
    mutation createMTStockDetails($input: [MTStockDetailInput]!) {
        createMTStockDetails(input:$input){
            id
            stock_audit_id
            principal_id
            brand_id
            product_category_id
            sku_family_id
            sku_id
        }
    }`, {
  props: ({ mutate }) => ({
    createMTStockDetails: (variables) => mutate({
      variables,
    }),
  }),
});

const DELETE_MT_SKU = graphql(gql`
    mutation deleteMTStockDetail($skuId: [Int]!, $stockAuditId: Int!) {
        deleteMTStockDetail(skuId:$skuId, stockAuditId:$stockAuditId){
            id
            stock_audit_id
            principal_id
            brand_id
            product_category_id
            sku_family_id
            sku_id
            active
        }
    }`, {
  props: ({ mutate }) => ({
    deleteMTStockDetail: (variables) => mutate({
      variables,
    }),
  }),
});

const GET_PRODUCT_GROUP_DETAILS = gql`
    query getProductGroupDetails($parentCatalogDetailId:Int) {
        catalogDetails(catalogId:2,parentCatalogDetailId:$parentCatalogDetailId){
            rows {
                id
                title
                catalogId
            }
        }
    }
`;

const GET_SKU_FAMILY_DETAILS = gql`
    query getSKUFamilyDetails($parentCatalogDetailId: Int) {
        catalogDetails(offset:0, limit:1000, catalogId: 7, parentCatalogDetailId:$parentCatalogDetailId){
            rows {
                id
                title
                skus: SKUs{
                    id
                    title
                }
            }
        }
    }
`;

const GET_ASSORTMENT_CATEGORY_COUNT = gql`
    query getAssortmentCategoryCount($channelId: Int){
        categoriesAssortmentCount(channelId: $channelId) {
            rows {
                categoryId
                count
            }
        }
    }
`;

const GET_PRODUCT_HIERARCHY_ASSORTMENT_COUNT = gql`
    query getAssortmentCountByProductHierarchy($categoryId: Int!){
        productHierarchyAssortmentCount(categoryId: $categoryId) {
            brands {
                id
                count
            }
            skuFamilies : productPacks {
                id
                count
            }
            productCategories {
                id
                count
            }
        }
    }
`;

const TOGGLE_CHANNEL_CATEGORY_SKUS = graphql(gql`
    mutation ($categoryId: Int!,
        $skuIds: [Int!],  $status: Boolean!) {
        createCategorySKUAssortment(categoryId: $categoryId, skuIds: $skuIds,
            status: $status
        ){
            rows
            count {
                categories {
                    categoryId
                    count
                }
                brands {
                    id
                    count
                }
                skuFamilies : productPacks {
                    id
                    count
                }
                productCategories {
                    id
                    count
                }
            }
        }
    }`, {
  props: ({ mutate }) => ({
    toggleCategorySKUAssortment: (variables) => mutate({
      variables,
    }),
  }),
});
const CREATE_STOCK_COUNT = graphql(gql`
mutation CreateStockCount($input: stockCountInput!) {
    createStockCount(input: $input) {
      assigned_to
      assigned_to_code
      frequency_type
      title
    }
  }`, {
  props: ({ mutate }) => ({
    createStockCount: (variables) => mutate({
      variables,
    }),
  }),
});

const UPDATE_STOCK_COUNT = graphql(gql`
mutation UpdateStockCount($input: stockCountInput!) {
  updateStockCount(input: $input) {
    assigned_to
    assigned_to_code
    frequency_type
    title
  }
}`, {
  props: ({ mutate }) => ({
    updateStockCount: (variables) => mutate({
      variables,
    }),
  }),
});


const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getSkuDetails: GET_SKU_DETAILS,
  getMTBasicDetail: GET_MT_BASIC_DETAIL,
  getProductGroupDetails: GET_PRODUCT_GROUP_DETAILS,
  getSKUFamilyDetails: GET_SKU_FAMILY_DETAILS,
  getAssortmentCategoryCount: GET_ASSORTMENT_CATEGORY_COUNT,
  getAssortmentCountByProductHierarchy: GET_PRODUCT_HIERARCHY_ASSORTMENT_COUNT,
  getLogisticUser: GET_LOGISTIC_USER,
  getStockCountDetails: GET_STOCK_COUNT_DETAILS,
  getStockSkuStatus: GET_STOCK_SKU_STATUS,
  getStockCountList: GET_STOCK_COUNT_LIST,
};

const mutation = {
  createStockCount: CREATE_STOCK_COUNT,
  updateStockCount: UPDATE_STOCK_COUNT,
  createMTStockDetails: CREATE_MT_SKUS,
  deleteMTStockDetail: DELETE_MT_SKU,
  toggleCategorySKUAssortment: TOGGLE_CHANNEL_CATEGORY_SKUS, UPDATE_STOCK_COUNT,
};

export {
  query, mutation, CREATE_MT_SKUS, DELETE_MT_SKU, UPDATE_STOCK_COUNT,
  GET_BRAND_DETAILS, GET_PRODUCT_GROUP_DETAILS,
  TOGGLE_CHANNEL_CATEGORY_SKUS, CREATE_STOCK_COUNT,

};
