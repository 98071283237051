import React from 'react';
import PropTypes from 'prop-types';
import withAlert from '../../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../../common';
import { formConfig, breadCrumbConfig } from '../config';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import usePurchaseOrderList from '../Controller/usePurchaseOrderList.controller';
import { PurchaseOrderListStyled } from './PurchaseOrderListStyled';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import PurchaseOrderCreateSection from '../components/PurchaseOrderCreateSection/PurchaseOrderCreateSection';
import { Badge, Loading, Pagination } from '../../../../v4/components';
import PurchaseOrderTable from '../components/PurchaseOrderTable/PurchaseOrderTable';
import SuccessPurchaseOrderTable from '../components/SuccessPurchaseOrderTable/SuccessPurchaseOrderTable';
import FailedPurchaseOrderTable from '../components/FailedPurchaseOrderTable/FailedPurchaseOrderTable';
import RowItemDetailDialog from '../components/RowItemDetailDialog/RowItemDetailDialog';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import { SELECT_OPTIONS } from '../../../../v4/constants/pagination';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const PurchaseOrderList = props => {
  const {
    dialog,
    data,
    loading,
    permission,
    activeTab,
    count,
    formClicked,
    queryParameters,
    basePaginationService,
    filterMenu,
    onTabChange,
    handleDialogSubmit,
    resetDialog,
    handleIconClick,
    handleDownloadClick,
    handleRowClick,
  } = usePurchaseOrderList(props);

  const dynamicTableHeight = () => {
    if (count.pendingCount > SELECT_OPTIONS[0].value - 1) {
      return 'can-create-po has-pagination';
    }
    if (count.pendingCount > 0) {
      return 'can-create-po';
    }
    return '';
  };
    
  return (
    <PurchaseOrderListStyled>
      {loading && <Loading title={'Loading... Please wait.'} />}
      {formClicked && <Loading title={'Creating Purchase Order... Please wait.'} />}
      {dialog.type && (
        <DialogFormWrapper
          formConfig={formConfig[dialog.type]}
          formTitle={'Purchase Order'}
          dialogElement={dialog.element}
          onDialogSubmit={handleDialogSubmit}
          onDialogCancel={resetDialog}
          type={dialog.type}
          disableDialogClose
          disableOk={formClicked}
          withOutPadding={dialog.type === EVENT_OPERATION.READ}
          withOutFooter={dialog.type === EVENT_OPERATION.READ}
          renderDialog={({ refsObj, enableErrorDisplay }) => (
            <>
              {dialog.type === EVENT_OPERATION.CREATE && (
                <span>Are you sure you want to send a request for a Purchase Order?</span>
              )}
              {dialog.type === EVENT_OPERATION.READ && <RowItemDetailDialog dialogData={dialog.element} />}
            </>
          )}
        />
      )}
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title: 'Purchase Order',
              create: false,
              filter: permission?.read,
              date: activeTab !== 0,
              search: permission?.read,
              download: permission?.read,
            }}
            queryParameters={queryParameters}
            handleSearchChange={basePaginationService.handleSearchInputChange}
            downloadConfig={{
              domain: DOWNLOAD_DOMAIN.PENDING_PURCHASE_ORDER,
            }}
            filter={{
              date: queryParameters.date,
              menuList: filterMenu,
              onFilterChange: basePaginationService.handleFilterChange,
            }}
            resetFilter={basePaginationService.resetFilter}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
            handleDownloadClick={handleDownloadClick}
          />
        </PanelStyled>
      </div>
      <div className="section-content section-tab">
        <Tabs selectedIndex={activeTab} onSelect={tabIndex => onTabChange(tabIndex)}>
          <TabList>
            <Tab>
              <span className="tab-label">Pending</span>
              <Badge light label={count.pendingCount} />
            </Tab>
            <Tab>
              <span className="tab-label">Success</span>
              <Badge light label={count.successCount} />
            </Tab>
            <Tab>
              <span className="tab-label">Failed</span>
              <Badge light label={count.failedCount} />
            </Tab>
          </TabList>
          <div className="route-tab-content">
            <TabPanel>
              <section
                id="pending-tab"
                className={dynamicTableHeight()}
              >
                <PurchaseOrderTable data={data} permission={permission} handleRowClick={handleRowClick} />
                <Pagination
                  currentPage={queryParameters.pagination.page}
                  limit={queryParameters.pagination.limit}
                  totalItems={data.total}
                  onPageChange={basePaginationService.onPageSelect}
                />
                <PurchaseOrderCreateSection
                  skuAmount={data.total}
                  onCreateClick={() => handleIconClick(EVENT_OPERATION.CREATE, {})}
                  loading = {loading}
                />
              </section>
            </TabPanel>
            <TabPanel>
              <section id='success-tab' className={count?.successCount > 0 ? 'can-create-po' : ''}>
                <SuccessPurchaseOrderTable data={data} permission={permission} />
                <Pagination
                  currentPage={queryParameters.pagination.page}
                  limit={queryParameters.pagination.limit}
                  totalItems={data.total}
                  onPageChange={basePaginationService.onPageSelect}
                />
              </section>
            </TabPanel>
            <TabPanel>
              <section id="failed-tab" className={count.failedCount > 0 ? 'pagination-enable' : ''}>
                <FailedPurchaseOrderTable data={data} permission={permission} />
                <Pagination
                  currentPage={queryParameters.pagination.page}
                  limit={queryParameters.pagination.limit}
                  totalItems={data.total}
                  onPageChange={basePaginationService.onPageSelect}
                />
              </section>
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </PurchaseOrderListStyled>
  );
};

PurchaseOrderList.propTypes = propTypes;

PurchaseOrderList.defaultProps = defaultProps;

export default withAlert()(PurchaseOrderList);
