import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const productHierarchyCountMapper = (element = {}) => ({
  brands: element.brands || [],
  skuFamilies: element.skuFamilies || [],
  productGroups: element.productGroups || [],
});

const crudRequestConfig = {
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: ({ distributorGroupId, skuIdList, status }) => ({ distributorGroupId, skuIdList, status }),
    responseName: 'toggleSkuInDistributorAssortment',
    message: 'SKU Assortment updated successfully',
  },
};
export { crudRequestConfig };

export { productHierarchyCountMapper };
