import React from 'react';
import PropTypes from 'prop-types';
import PendingStyled from './PendingStyled';
import { Pagination } from '../../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';
import withAlert from '../../../../utils/composition/withAlert';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  onRowClick: PropTypes.func,
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
  handleSliderOpen: PropTypes.func.isRequired,
};
const defaultProps = {
  data: dataProps.defaultValue,
  onRowClick: () => null,
  onPageChange: () => null,
  pagination: paginationProps.defaultValue,
};

const Pending = ({ ...props }) => {
  const { data, onPageChange, pagination, onRowClick, handleSliderOpen } = props;

  return (
    <PendingStyled>
      <div className='table-wrap'>
        <table>
          {TableHeader()}
          <tbody>{data.list.map((srn) => TableBody(srn, onRowClick, handleSliderOpen))}</tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </PendingStyled>
  );
};

Pending.propTypes = propTypes;

Pending.defaultProps = defaultProps;

const PendingWithErrorAndLoading = withErrorAndLoadingScreen(Pending);

const PendingWithImageSlider = withImageSlider(PendingWithErrorAndLoading);

export default PendingWithImageSlider;
