import { useState, useEffect } from 'react';
import * as queryService from '../../../../base/query.service';
import { crudSuccess, filterConfig } from '../config';
import { EVENT_OPERATION } from '../../../../../v4/constants/EventOperation';
import { ICO_GROUP } from '../../../../../data/enums/Route';
import history from '../../../../../utils/history';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../../../utils/api';
import { fetchFromLocalStorage, isError, storeInLocalStorage } from '../../../../common/HelperFunctions';
import { clone } from '../../../../../utils/objectProcessor';
import { storageKeys } from '../../../../sales/orderProcessing/config';
import { DOMAIN } from '../../../../../data/enums/config';
import { getPermissionForICOGroup } from '../../../../base/permission';
import { MESSAGE_EVENT_OPERATION } from '../../../../../v4/constants/successMessage';

const initialDialogState = {
  id: '',
  title: '',
  alias: '',
  active: false,
};

const useICOGroupMaster = props => {
  const filterConfigMenu = clone(filterConfig.menu);
  const {
    displayAlert,
    serverResponseWaiting,
    getICOGroupApi,
    createICOGroupMaster,
    updateICOGroupMaster,
    toggleState,
  } = props;
  const permission = getPermissionForICOGroup();

  const [data, setData] = useState({ list: [], total: 0 });
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    filter: queryService.baseQueryParameters.filter,
    date: { ...queryService.baseQueryParameters.date },
    search: queryService.baseQueryParameters.search,
  });
  const [filterMenu, setFilterMenu] = useState(filterConfigMenu);
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [dialogData, setDialogData] = useState({
    ...initialDialogState,
  });

  const loadTableData = () => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    setFilterStateInStorage();
    getICOGroupApi(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          // filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: res => {
          if (isError(res)) onAPIRequestFailure(res.errors[0]);
          const response = res.data.icoGroups;
          setData({
            list: response ? response.rows : [],
            total: response ? response.count : 0,
          });
        },
        handleError: err => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const setParameters = queryParams => setQueryParameters({ ...queryParams });

  const basePaginationService = new queryService.QueryClass(setParameters, () => queryParameters, loadTableData);

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
    setDialogData(initialDialogState);
  };

  const handleIconClick = (type, element = {}) => {
    setDialog({
      type,
      element,
    });
    if (type !== EVENT_OPERATION.CREATE) {
      setDialogData({
        id: element.id,
        title: element.title,
        alias: element.alias,
        active: element.active,
      });
    }
  };

  const handleInputChange = e => {
    setDialogData({ ...dialogData, [e.target.name]: e.target.value });
  };

  const handleDialogSubmit = type => {
    if (type === EVENT_OPERATION.DELETE) {
      toggleState({ ids: [dialogData?.id], active: false, type: 'ICOGroup' });
      displayAlert(ALERT_TYPE.SUCCESS, `ICO Group ${MESSAGE_EVENT_OPERATION.DELETE}`);
      loadTableData();
      return;
    }
    if (type === EVENT_OPERATION.UPDATE) {
      updateICOGroupMaster(
        {
          input: {
            title: dialogData.title,
            alias: dialogData.alias,
            active: dialogData.active,
          },
          id: dialogData.id,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `ICO Group ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            loadTableData();
            resetDialog();
          },
          handleError: err => {
            onAPIRequestFailure(err);
          },
        },
      );
    } else {
      createICOGroupMaster(
        {
          input: {
            title: dialogData.title,
            alias: dialogData.alias,
            active: true,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `ICO Group ${MESSAGE_EVENT_OPERATION.CREATE}`);
            loadTableData();
            resetDialog();
          },
          handleError: err => {
            onAPIRequestFailure(err);
          },
        },
      );
    }
  };

  const handleTableRowClick = ({ id, title }) => {
    history.push(`/${ICO_GROUP}/details/${id}`, { groupMasterTitle: title });
  };

  const setFilterStateInStorage = () => {
    const { filter, date, search } = queryParameters;
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.ICO_GROUP,
    );
  };

  const getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.ICO_GROUP);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      }),
    };
    if (!localData) {
      return loadTableData();
    }
    setFilterMenu(newFilterConfig.filterMenu);
    setQueryParameters({
      ...queryParameters,
      filter: newFilterConfig.filter,
      searchText: newFilterConfig.searchText,
      date: newFilterConfig.date,
    });
    loadTableData();
  };

  const handleBulkDelete = (type, response) => {
    displayAlert(ALERT_TYPE.SUCCESS, crudSuccess[type].message);
    loadTableData();
  };

  useEffect(() => {
    setData({
      list: [],
      total: 0,
    });

    loadTableData();
  }, [queryParameters]);

  useEffect(() => {
    getFilterStateFromStorage();
  }, []);

  return {
    dialog,
    data,
    dialogData,
    serverResponseWaiting,
    permission,
    queryParameters,
    filterMenu,
    basePaginationService,
    toggleState,
    handleDialogSubmit,
    resetDialog,
    handleInputChange,
    handleIconClick,
    handleTableRowClick,
    handleBulkDelete,
  };
};

export default useICOGroupMaster;
