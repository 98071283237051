import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { LOG_OUT } from '../navigation/API';

const GENERATE_QR_CODE = gql`
  query Query($data: String!) {
    generateQrCode(data: $data)
  }
`;

const VERIFY_QR_CODE = graphql(
  gql`
    mutation VerifyQrCode($token: Int!) {
      verifyQrCode(token: $token)
    }
  `,
  {
    props: ({ mutate }) => ({
      verifyQrCode: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  generateQrCode: GENERATE_QR_CODE,
};

const mutation = {
  logout: LOG_OUT,
  verifyQrCode: VERIFY_QR_CODE,
};

export { LOG_OUT, VERIFY_QR_CODE };

export { mutation, query };
