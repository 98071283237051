import { compose } from 'recompose';
import ProductForm from './Products';
import { mutation, TOGGLE_STATE } from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedProductForm = compose(
  TOGGLE_STATE,
  withRequestTracker({
    mutation,
  }),
)(ProductForm);

export default ComposedProductForm;
