import styled from 'styled-components';

const PushMessageWrapper = styled.div`

  .size-m {
    font-size: 16px;
    padding-left: 20px;
  }

  .table-wrap{
      td{
        .light{
          min-width: 72px;
          padding: 3px 4px;
          text-align:center;
          line-height:10px;
          font-size:10px;
          font-weight:600;
          white-space:nowrap;
          border-radius: 2px;
        }
        .complete{
        .light{
           border: 1px solid #47C464;
          color: #287D3C
        }
      }
      .active{
        .light{
          border: 1px solid rgba(46,90,172,0.4);
          color: #2E5AAC;
        }
      }
      .restore_status .icon{
        color : #2E5AAC;
        margin-left: 5px;
        height:12px;
        width:12px;
      }
  }
}
  .form-input-wrap{
    .form-input, .form-select-input { 
     > label {
            width: calc(33.57% - 10px);
        }
     }
  }
  .form-input {
    &.custom-date{
      > div { 
        width: 283px;
        .datepicker-wrapper.date-picker-single { 
            .filter-item {
                width: 283px;
            }
        }
      }
      }
    }
     .permission-choose{
      display: flex;
       > label{
             font-size: 14px;
             line-height: 21px;
             color: #272833;
             font-weight: 500;
             width: calc(33.57% - 10px);
             margin: 0;
             margin-right: 10px;
        }
        > div{
               margin-bottom: 24px;
          }
    }
    .reapeat__days{
      display:flex;
      width: 125%;
      > label {
            width: calc(28.57% - 10px);
            color: #272833;
            font-size: 14px;
            font-weight: 500;
            margin-top: 10px;
      }
      .checkbox-list {
        margin-bottom: 24px;
        > div {
          > label{
            margin-right: 10px;
            font-weight : 600;
          }
        }
      }
    }
    .time {
      display : flex;
      width: 465px;
      > label {
       width: calc(192.57% - 10px);
        color: #272833;
        font-size: 14px;
        font-weight: 500;
        margin-top: 13px;
      }
      .form-input{
        width:50%
        margin-bottom: 0px;
      }
      .form-select-input{
        > label {
          width: 0%;
        }
        > div {
              width: 100%;
              margin-left: -10px;
              margin-bottom: 0px;
        }
        }
        .time__hours{
            display:flex;
            flex-direction: column;
             width:100%;
           .form-input{
              width:calc(100% - 10px);
              margin-right: 10px;
              margin-bottom: 0px;
            }
            .form-select-input{
               margin-bottom: 0px;
            }
            > label {
              font-size: 10px;
              color: #A7A9BC;
              margin-top: 6px;
              margin-left: 16px;
              line-height: 10px;
            }
        }
    }
  .location, .channel_category{
    position: relative;
   > div{ 
     > span {
          position: absolute;
          right: 50px;
          top: 11px;
          z-index: 999;
          color: #2E5AAC;
          font-size: 14px;
          line-height: 21px;
    }}
    .form-input-wrap{
      width: 100%;
      .form-select-input{
        display:block;
        margin-right: 8px;
        > div {
              width: 100%;
        }
        }
    }
    .auto__cancel{
        position:relative; 
    }
    .cross__icon{
      position:absolute;
      right: -24px;
      top: 25px;
      bottom:63px;
      cursor: pointer;
    }
    .filter-dropdown-card {
      left: 0;
      right: auto;
      min-width: 350px;
      padding: 16px;
      top: 74px;
    }
    .suggested-autocomplete-list-wrap{
  max-height:400px;
  overflow-y: auto;
  padding-bottom:24px;
  }
    .autocomplete-list {
        > div {
          margin-bottom: 5px;
          cursor: pointer;
          font-weight: 600;
          color: #6b6c7e;
          font-size: 14px;
          transition: 0.2s linear;
          
          &:hover, &:focus {
            color: #272833;
          }
          
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      
      .suggested-autocomplete-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 4px;
        .text {
          padding: 8px 16px;
          border-radius: 4px;
          background: #e7e7ed;
          border: 1px solid #e7e7ed;
          font-size: 14px;
          flex: 1;
        }   
        .ic-close {
          cursor: pointer;
          display: inline-block;
          width: 12px;
          height: 12px;
          overflow: hidden;
          text-indent: -999px;
          background: url("/image/icons/ic_close.svg");
          background-size: 12px;
          margin-left: 18px;
        }
      }
  }
  `;

export default PushMessageWrapper;
