import { configuration } from '../../../data/enums/Route';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';

const breadCrumb = [configuration];
const title = 'Campaigns';
const crudRequest = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.CAMPAIGN,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.CAMPAIGN,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};
export {
  crudRequest, breadCrumb as breadCrumbConfig, title
};
