import { getFilterMenu } from '../../../utils/filter';
import { fileParser } from '../../../utils/parser';
import { FILTER } from '../../../data/enums/filter';
import { refGenerator } from '../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { CONFIRMATION_CONFIG, FORM_CONFIG } from '../../../data/enums/config';
import { UPLOADABLES } from '../../../data/enums/GraphQL';
import { inventory as inventoryRoute } from '../../../data/enums/Route';

const title = 'GRN';

const breadCrumb = [inventoryRoute];

const GRN_TABS = {
  PENDING: 'pending',
  GRN: 'grn',
};
const GRN_TABS_VALUE = {
  PENDING: 0,
  GRN: 1,
};

const tabMapper = {
  0: GRN_TABS.PENDING,
  1: GRN_TABS.GRN,
};

const formConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      file: element.file || {},
    }),
    [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.CANCEL]: {
    [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.CANCEL} PENDING ${title}`,
  },
  ...CONFIRMATION_CONFIG,
};

// filter.subd holds label and value

// label -> here to sort out label
// value => filter.sub_d.value

const getFilterConfig = (filterList) => {
  const labelList = filterList.map((element) => element.LABEL);

  const menu = getFilterMenu(labelList);

  const instance = {};

  filterList.forEach((element) => (instance[element.VALUE] = {}));

  return {
    menu,
    instance,
  };
};

const filter = getFilterConfig([FILTER.SUB_D]);

const crudSuccess = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: title,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },

  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: data.ids,
      type: title,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },

  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.GRN,
        },
      }));

      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
  [EVENT_OPERATION.CANCEL]: {
    [FORM_CONFIG.TITLE]: 'Cancel',
    objectMapper: (data) => ({  id: data.id, }),
    responseName: 'cancelGRN',
    message: ' GRN Pending Cancelled.',
  },
};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig, filter as filterConfig, title, tabMapper, GRN_TABS,GRN_TABS_VALUE
};
