import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../v4/components';
import { defaultMenuConfigList, menuActionWithTitle } from '../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import CardStyled from './CardStyled';
import { isObjectEmpty } from '../../../utils/objectPrototypes';
import { SCOPE_LEVEL } from '../../configuration/subCampaignDetails/config';

const propTypes = {
  onItemClick: PropTypes.func,
  title: PropTypes.string,
  selectedId: PropTypes.number,
  handleIconClick: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {
  onItemClick: () => null,
  title: '',
  selectedId: 0,
};

const menuConfigList = [
  {
    title: 'View',
    icon: '',
    permissionDerivedBy: 'read',
    type: EVENT_OPERATION.READ,
  },
  defaultMenuConfigList[EVENT_OPERATION.UPDATE],
  defaultMenuConfigList[EVENT_OPERATION.DELETE],
];

const actionConfigList = [
  {
    title: 'Remove Scope',
    icon: '',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.REMOVE_SCOPE,
  },
];

const SubCampaignCard = ({ ...props }) => {
  const {
    data,
    onItemClick,
    title,
    selectedId,
    handleIconClick,
    parentList,
    onActionClick,
    cardIndex,
    selectedColumn,
    scopeHierarcy,
    permission
  } = props;
  const addClick = () => {
    if (
      scopeHierarcy[1] === SCOPE_LEVEL.CATALOG &&
      cardIndex === 0 &&
      isObjectEmpty(parentList.parentList) &&
      parentList.column !== SCOPE_LEVEL.CLASSIFICATION
    ) {
      handleIconClick(EVENT_OPERATION.CREATE, {}, title, parentList, cardIndex);
    }
    if (
      scopeHierarcy[1] === SCOPE_LEVEL.CLASSIFICATION &&
      cardIndex === 0 &&
      isObjectEmpty(parentList.parentList) &&
      parentList.column !== SCOPE_LEVEL.CATALOG
    ) {
      handleIconClick(EVENT_OPERATION.CREATE, {}, title, parentList, cardIndex);
    }
    if (cardIndex !== 0 && !isObjectEmpty(parentList.parentList)) {
      handleIconClick(EVENT_OPERATION.CREATE, {}, title, parentList, cardIndex);
    }
    if (cardIndex === 0 && !isObjectEmpty(parentList.parentList)) {
      handleIconClick(EVENT_OPERATION.CREATE, {}, title, parentList, cardIndex);
    }
  };

  const budgetValue = (subCampaignBudgetDetail) => {
    const newsubCampaignBudgetDetail = subCampaignBudgetDetail.map((obj) => {
      if (obj.dimension === 'PERCENT') {
        return { ...obj, value: obj.calculatedValue };
      }
      return obj;
    });
    return newsubCampaignBudgetDetail.reduce((acc, cur) => acc + cur.value, 0);
  };

  return (
    <CardStyled>
      <div className='card-pannel'>
        <div className='title-section'>
          <label>
            {permission.delete&&menuActionWithTitle(actionConfigList, { menuIcon: 'ellipsis-v' }, onActionClick, parentList, title, permission)}
            {title}
          </label>
          {permission.create&&<Icon iconName='plus' className='cursor-pointer' onClick={() => addClick()} />}
        </div>
        <div className='body-section'>
          <div className='list-section'>
            <ul>
              {data.map(
                (d) =>
                  d && (
                    <li key={d.id} className={`${d.id === selectedId ? 'active' : 'inActive'}`}>
                      {(permission.read||permission.update||permission.delete)&&menuActionWithTitle(menuConfigList, { menuIcon: 'ellipsis-v' }, onActionClick, d, title,permission)}
                      <span
                        key={d.id}
                        onClick={() => (onItemClick ? onItemClick(d, parentList.label.toUpperCase()) : '')}
                      >
                        <span>
                          {d.typeDetail && d.typeDetail.title}
                          <p>{`Budget: ${budgetValue(d.subCampaignBudgetDetail)}`}</p>
                        </span>
                      </span>
                    </li>
                  ),
              )}
            </ul>
          </div>
        </div>
      </div>
    </CardStyled>
  );
};

SubCampaignCard.propTypes = propTypes;

SubCampaignCard.defaultProps = defaultProps;

export default SubCampaignCard;
