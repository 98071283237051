import { Form } from '../../../../../../v4/components';
import { Input } from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../../v4/constants/EventOperation';

const SkuGroupCreateUpdateDialog = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, dialogType } = props;

  return (
    <Form>
      {/* <div className={type === EVENT_OPERATION.READ ? 'month-wrap disabled' : 'month-wrap'}>
        <label>Month</label>
        <MonthPicker value={data.duration} onSelect={(event) => handleDateChange(event)}/>
      </div> */}

      <Input
        ref={ref => (refsObj.title = ref)}
        value={data.title}
        name="title"
        type="text"
        argument={{ min: 0 }}
        placeholder="Title"
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event)}
        labelContent="Title"
      />
      <Input
        ref={ref => (refsObj.alias = ref)}
        value={data.alias}
        name="alias"
        type="text"
        argument={{ min: 0 }}
        placeholder="Alias"
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event)}
        labelContent="Alias"
        disabled={dialogType === EVENT_OPERATION.UPDATE}
      />
    </Form>
  );
};

const SkuGroupCreateUpdateDialogWithErrorAndLoading = withLoading(SkuGroupCreateUpdateDialog);

export default SkuGroupCreateUpdateDialogWithErrorAndLoading;
