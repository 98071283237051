import { compose } from 'recompose';
import CreateSku from './CreateSku';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_SKU, UPDATE_SKU
} from './API';

const ComposedCreateSku = compose(
  CREATE_SKU,
  UPDATE_SKU,
  withRequestTracker({
    query,
    mutation,
  }),
)(CreateSku);

export default ComposedCreateSku;
