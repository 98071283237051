import { compose } from 'recompose';
import BinLocation from './BinLocation';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';
import { query } from './API';

const ComposedBinLocation = compose(
  withRequestTracker({
    query,
  }),
)(BinLocation);

export default ComposedBinLocation;
