import { Component } from 'react';
import PropTypes from 'prop-types';
import { breadCrumb, detailDataMapper } from './config';
import View from './View';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import ReturnStyled from '../ReturnStyled';
import withLoading from '../../../../utils/composition/withLoading';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  getSrnDetails: PropTypes.func.isRequired,
};
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: detailDataMapper({}),
    };
  }

  componentDidMount() {
    this.getList();
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  getList = () => {
    const { getSrnDetails, displayAlert, match } = this.props;
    /** after api supports details fetch from prnInvoiceNumber  * */
    // prnInvoiceNumber: voucherDecoder(match.params.id),
    getSrnDetails(
      {
        srnId: parseInt(match.params.id || '', 10),
      },
      {
        handleSuccess: response => {
          const { srnDetails = {} } = response.data;
          const modifiedData = detailDataMapper(srnDetails);
          this.setState({ data: modifiedData });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  render() {
    const { data } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <ReturnStyled>
        <View
          data={data}
          title={data.srnInvoiceNumber}
          breadCrumb={breadCrumb}
          loading={serverResponseWaiting}
        />
      </ReturnStyled>
    );
  }
}

Detail.propTypes = propTypes;

export default withLoading(withAlert()(Detail));
