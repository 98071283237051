import PropTypes from 'prop-types';
import React from 'react';
import Popover from '../../../../../components/Popover';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { Icon, tableData } from '../../../../../v4/components';
import { OPERATION } from '../config';

const labelMappings = {
  sku: 'SKU',
  rate: 'Rate',
  orderQuantity: 'Order Quantity',
  totalDiscount: 'Total Discount',
  netAmount: 'Net Amount',
  edit: 'editOrder',
  remove: 'removeOrder'
};

const isPromotionAvailable = (isPromotionAvailable) => {
  return isPromotionAvailable ? <img src='/image/icons/Promotion.svg' style={{ cursor: 'pointer' }} /> : null;
}

const getAvailablePromotions = (promotions = []) => {
  return (
    <ul className='available-promotions'>
      {promotions.length > 0 && promotions?.map((promotion) => (
        <li key={promotion.id} className='item-value'>{promotion.title}</li>
      ))}
    </ul>
  )
}
const returnTableBody = (data, handleEditOrder, handleRemoveOrder, isCompleted, handlePromotionClick, editGroupPromotion, orderPadDetail) => {
  const table = {};
  const skutitle = (title, showTag,stockavailable) => (
    <>
      {title}
      {showTag ?
        <span className='sbd-sku'>SBD</span>
        : null
      }
      {stockavailable?
        <span className='sbd-sku in-stock'>{`IN STOCK ${data.balance}`}</span>
        : null
      }
      <div className='simple-popup promotion'>
        <Popover
          popoverIcon={isPromotionAvailable(orderPadDetail?.find((a)=>a.skuId===data?.id)?.promotions?.length > 0)}
          headerTitle='Promotions Applied'
        >
          <span className='arrow-up' />
          {getAvailablePromotions(orderPadDetail?.find((a)=>a.skuId===data?.id)?.promotions || [])}
        </Popover>
      </div>
    </>
  )
  table[labelMappings.sku] = <td key={`${data.id}- sku`} >{skutitle(data.title, data?.isSBDSku,data?.stockavailable)}</td>;
  table[labelMappings.rate] = <td key={`${data.id}- rate`} className='text-right'>{data?.Rates[0]?.priceDetails?.rlp}</td>;
  table[labelMappings.orderQuantity] = (
    <td key={`${data.id}- orderQuantity`} className='text-right' onClick={(e) => e.stopPropagation()}>
      {data.quantity}
    </td>
  );
  table[labelMappings.totalDiscount] = (
    <td key={`${data.id}- totalDiscount`} onClick={(e) => e.stopPropagation()} className='text-right'>
      {isCompleted ? fixedFloatAndCommas(data?.amount_details?.discountAmount) : fixedFloatAndCommas(data?.totalDiscount)}
    </td>
  );
  table[labelMappings.netAmount] = (
    <td key={`${data.id}- orderQuantity`} onClick={(e) => e.stopPropagation()} className='text-right'>
      {isCompleted ? fixedFloatAndCommas(data?.amount_details?.netAmount) : fixedFloatAndCommas(data?.netAmount)}
    </td>
  );
  table[labelMappings.edit] = (
    <td key={`${data.id}- edit`} className='text-right edit'>
      {!isCompleted &&
        (
          <>
            {editGroupPromotion.filter((b)=>b.promotionId===data?.promotion?.find((a)=>(a.promotionScope==='GROUP'&& a.status && a.promotionType==='NORMAL'))?.id)?.length>0?
              <Icon iconName='pencil' onClick={() => handlePromotionClick(OPERATION.EDIT_PROMOTION,editGroupPromotion, data?.promotion, data?.promotion?.filter((a)=>(a.promotionScope==='GROUP'&& a.promotionType==='NORMAL'))?.[0].id)} />:<Icon iconName='pencil' onClick={() => handleEditOrder(OPERATION.EDIT, data)} />
            }
          </>
        )
      }
    </td>
  )
  table[labelMappings.remove] = (
    <td key={`${data.id}- remove`} className='text-right remove' >
      {!isCompleted &&
      (
        <>
          {editGroupPromotion.filter((b)=>b.promotionId===data?.promotion?.find((a)=>(a.promotionScope==='GROUP'&& a.status && a.promotionType==='NORMAL'))?.id)?.length>0 ?
            <Icon iconName='trash' onClick={() => handlePromotionClick(OPERATION.DELETE_PROMOTION,editGroupPromotion, data?.promotion, data?.promotion?.filter((a)=>(a.promotionScope==='GROUP'&& a.promotionType==='NORMAL'))?.[0].id)} />:<Icon iconName='trash' onClick={() => handleEditOrder(OPERATION.DELETE, data)} />
          }
        </>
      )}
    </td>
  )

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
    classNames: 'left',
  },
  {
    id: 2,
    label: labelMappings.rate,
    title: 'Rate',
    show: true,
    classNames: 'text-right'
  },
  {
    id: 3,
    label: labelMappings.orderQuantity,
    title: 'Order Quantity',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 4,
    label: labelMappings.totalDiscount,
    title: 'Total Discount',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.netAmount,
    title: 'Net Amount',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 6,
    label: labelMappings.edit,
    title: '',
    show: true
  },
  {
    id: 7,
    label: labelMappings.remove,
    title: '',
    show: true
  }
];

const TableBody = ({ data, handleEditOrder, handleRemoveOrder, isCompleted, handlePromotionClick ,editGroupPromotion, orderPadDetail }) => {
  const tableBody = returnTableBody(data, handleEditOrder, handleRemoveOrder, isCompleted, handlePromotionClick,editGroupPromotion, orderPadDetail);

  return (
    <tr key={data.id} style={{ cursor: 'pointer' }}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

TableHeader.propTypes = {
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
};

TableHeader.defaultProps = {
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
