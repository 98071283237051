import { compose } from 'recompose';
import ChannelAssortment from './ChannelAssortment';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, DOWNLOAD_REPORT
} from './API';

const ComposedChannelAssortment = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(ChannelAssortment);

export default ComposedChannelAssortment;
