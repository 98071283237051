import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const GET_LOCATION_HIERARCHY_LIST = gql`
  query {
    getLocationHierarchy {
      rows {
        id
        title
        parentId
        parent {
          id
          title
          parentId
        }
        active
      }
      count
    }
  }
`;

const CREATE_LOCATION_HIERARCHY = graphql(
  gql`
    mutation ($input: LocationHierarchyInput!) {
      createLocationHierarchy(input: $input) {
        id
        title
        parentId
        active
        parent {
          id
          title
        }
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createLocationHierarchy: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_LOCATION_HIERARCHY = graphql(
  gql`
    mutation ($id: Int!, $input: LocationHierarchyInput!) {
      editLocationHierarchy(id: $id, input: $input) {
        id
        title
        parentId
        active
        parent {
          id
          title
        }
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      editLocationHierarchy: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DELETE_LOCATION_HIERARCHY_DETAIL = graphql(
  gql`
    mutation ($ids: [Int]!) {
      deleteLocationHierarchies(ids: $ids) {
        ids
        message
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteLocationHierarchies: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getLocationHierarchyList: GET_LOCATION_HIERARCHY_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  createLocationHierarchy: CREATE_LOCATION_HIERARCHY,
  editLocationHierarchy: UPDATE_LOCATION_HIERARCHY,
  deleteLocationHierarchies: DELETE_LOCATION_HIERARCHY_DETAIL,
};

export {
  TOGGLE_STATE,
  GET_LOCATION_HIERARCHY_LIST,
  CREATE_LOCATION_HIERARCHY,
  UPDATE_LOCATION_HIERARCHY,
  DELETE_LOCATION_HIERARCHY_DETAIL,
};

export { query, mutation };
