import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Channel';

const breadCrumb = [configurationRoute];

const channelTypeList = [
  {
    label: 'GT',
  },
  {
    label: 'MT',
  },
  {
    label: 'B2B',
  },
];

const filter = getFilterConfig([FILTER.BUSINESS_TYPE, FILTER.STATUS]);

const formConfig = {
  mapper: (element) => element,
  validationField: ['title'],
  validationRequired: true,
};

const crudSuccess = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.CHANNEL,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.CHANNEL,
      active: !data.status,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE_STATUS}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.CHANNEL,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, filter as filterConfig, channelTypeList, title, formConfig
};
