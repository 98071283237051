import React from 'react';
import { Button, Icon, Menu } from '..';
import DownloadStyled from './DownloadStyled';

const Download = ({ ...props }) => {
  const { downloadConfig, handleDownloadClick } = props;
  const downloadClick = (type: string, domain: string) => {
    const downloadDomain = type === 'multipleDownload' ? domain : downloadConfig.domain;
    handleDownloadClick(downloadConfig.type, downloadDomain, downloadConfig.apiTitle);
  };
  return !Array.isArray(downloadConfig.domain) ? (
    <div>
      <Button secondary iconName='download' iconBtnSmall onClick={() => downloadClick('download', '')} />
    </div>
  ) : (
    <DownloadStyled>
      <Menu header={<Button secondary iconName='download' iconBtnSmall />}>
        <ul>
          {downloadConfig.domain.map((domain: any) => (
            <li key={domain} onClick={() => downloadClick('multipleDownload', domain)}>
              <Icon iconName='download' />
              {domain.replace(/_/g, ' ').toLowerCase()}
            </li>
          ))}
        </ul>
      </Menu>
    </DownloadStyled>
  );
};

Download.defaultProps = {
  downloadConfig: {},
  handleDownloadClick: () => null,
};

export default Download;
