import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  CustomSelect, Input, SingleDatePicker
} from '../../../../../components';
import SubCampaignStyled from './SubCampaignStyled';
import { BudgetSelect, TargetSelect } from './BudgetSelect';
import withLoading from '../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const propTypes = {
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
  getState: PropTypes.func,
  updateState: PropTypes.func,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  getState: () => null,
  updateState: () => null,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const SubCampaignForm = ({ ...props }) => {
  const {
    data,
    totalBudget,
    update,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleCancel,
    subCampaignTypeList,
    dimensionList,
    onDropDownChangeTarget,
    handleAdd,
    budgetOverflowFlag,
    onHandleInputChange,
    campaignDataObj,
    handleDimensionChange,
  } = props;
  return (
    <SubCampaignStyled>
      <div className='body_wrap'>
        <div className='row-custom r1'>
          <Row>
            <Col md={12}>
              <Input
                type='text'
                enableValidation
                labelContent='Title'
                name='title'
                value={data.title}
                placeholder='Title'
                labelContent='Title'
                ref={(ref) => (refsObj.title = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleInputChange(event)}
              />
              <CustomSelect
                enableValidation
                options={subCampaignTypeList}
                labelContent='Sub Campaign Type'
                className='custom-select'
                placeholder='Select Sub Campaign Type'
                getOptionValue={({ type }) => type}
                getOptionLabel={({ type }) => type}
                ref={(ref) => (refsObj.subCampaignType = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleDropDownChange(event.type, ['subCampaignType'])}
                value={subCampaignTypeList.filter(({ type }) => type === data.subCampaignType)}
              />
            </Col>
            <Col md={6}>
              <div className='single-date-picker'>
                <label>Start Date</label>
                <SingleDatePicker
                  name='startDate'
                  date={data.startDate}
                  onChange={(name, date) =>
                    handleInputChange({
                      target: {
                        name: 'startDate',
                      },
                      formattedValue: date,
                    })
                  }
                  minDate={campaignDataObj.startDate}
                  maxDate={campaignDataObj.endDate}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className='single-date-picker end-date'>
                <label>End Date</label>
                <SingleDatePicker
                  name='endDate'
                  date={data.endDate}
                  onChange={(name, date) =>
                    handleInputChange({
                      target: {
                        name: 'endDate',
                      },
                      formattedValue: date,
                    })
                  }
                  minDate={campaignDataObj.startDate}
                  maxDate={campaignDataObj.endDate}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className='r2 row-custom'>
          <BudgetSelect
            data={data}
            title='Budget'
            onHandleInputChange={onHandleInputChange}
            handleDropDownChange={handleDropDownChange}
            refsObj={refsObj}
            campaignDataObj={campaignDataObj}
            enableErrorDisplay={enableErrorDisplay}
            totalBudget={totalBudget}
            handleAdd={handleAdd}
            handleCancel={handleCancel}
            subCampaignTypeList={subCampaignTypeList}
            budgetOverflowFlag={budgetOverflowFlag}
            handleDimensionChange={handleDimensionChange}
            dimensionList={dimensionList}
          />
        </div>
        <div className='r3 row-custom'>
          <TargetSelect
            data={data}
            title='Target'
            onHandleInputChange={onHandleInputChange}
            handleDropDownChange={handleDropDownChange}
            refsObj={refsObj}
            campaignDataObj={campaignDataObj}
            enableErrorDisplay={enableErrorDisplay}
            onDropDownChangeTarget={onDropDownChangeTarget}
            handleAdd={handleAdd}
            handleCancel={handleCancel}
            subCampaignTypeList={subCampaignTypeList}
            handleDimensionChange={handleDimensionChange}
            dimensionList={dimensionList}
          />
        </div>
      </div>
    </SubCampaignStyled>
  );
};

SubCampaignForm.propTypes = propTypes;

SubCampaignForm.defaultProps = defaultProps;

const SubCampaignFormWithErrorAndLoading = withLoading(SubCampaignForm);

export default SubCampaignFormWithErrorAndLoading;
