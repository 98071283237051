import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  data: {
    name: '',
    description: '',
  },
};

const TerritoryForm = ({ ...props }) => {
  const { data, enableErrorDisplay, handleInputChange } = props;
  return (
    <Form>
      <Input
        value={data.title}
        name='title'
        type='text'
        placeholder='Name'
        enableErrorDisplay={enableErrorDisplay}
        onChange={(event) => handleInputChange(event)}
      />
      <Input
        value={data.title}
        name='title'
        type='text'
        placeholder='Description'
        enableErrorDisplay={enableErrorDisplay}
        onChange={(event) => handleInputChange(event)}
      />
    </Form>
  );
};

TerritoryForm.propTypes = propTypes;

TerritoryForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(TerritoryForm);

export default LoginViewWithErrorAndLoading;
