import styled from 'styled-components';

const LocationHierarchyDetailsStyled = styled.div`
  .section-content .form-select-input {
    width: 19.38%;
    margin-bottom: 32px;
  }
  .modal-wrapper {
    .modal-inner {
      .pad-24 {
        overflow: auto;
        max-height: 400px;
      }
      form {
        .form-input {
          > input {
            color: black;
          }
        }
        .levels-label {
          margin-bottom: 4px;
          color: #272833;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
        }
        .levels {
          display: flex;
          flex-wrap: wrap;
          .form-input {
            margin-bottom: 0;
            &:first-child {
              width: 66px;
              margin-right: 6px;
            }
            &:last-child {
              flex: 1;
            }
            label {
              display: none;
            }
          }
        }
      }
    }
  }
  .form-select-input {
    margin-top: 8px;
    margin-bottom: 0;
    width: 22%;
    /* width:242px; */
    label {
      display: none;
    }
  }
  .card-wrapper {
    display: flex;
  }
  .search-wrapper button.search:hover:after {
    left: -158%;
  }
`;
export default LocationHierarchyDetailsStyled;
