import styled from 'styled-components';

const DatePickerStyled = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 16px;
  .filter-background {
    background: rgb(247, 248, 249);
    border-radius: 4px;
    .datepicker-content {
      background-color: #f7f8f9;
      color: #272833;
      transition: all 0.3 ease-in-out;
    }
  }

  .datepicker-content {
    background: transparent;
    padding: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #cdced9;
    &:hover {
      background-color: #f7f8f9;
      color: #272833;
      transition: all 0.3 ease-in-out;
    }
    .title,
    .date-actual {
      margin-right: 8px;
      color: #272833;
      font-weight: 500;
      font-size: 13.5px;
    }

    .psr-icons {
      background-image: url(/image/icons/ic_calendar.png);
      background-size: 16px;
      width: 16px;
      height: 16px;
      text-indent: -999px;
      display: inline-block;
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center center;
      overflow: hidden;
    }

    .ic-dropdown {
      display: none;
    }
  }

  .d-flex {
    display: flex;
  }

  .filter-dropdown-card {
    left: auto;
    right: 0;
    min-width: 450px;
    padding: 24px;
    .filter-dropdown-card {
      padding: 15px;
    }
  }

  .date-input-wrap {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 24px;
    align-items: center;
    border-top: 1px solid #e7e7ed;
    padding-top: 16px;
    & > span {
      margin-right: 16px;
      align-self: flex-end;
      &:last-child {
        margin-right: 0;
      }
    }
    > div {
      width: 48%;
    }
  }

  .filter-button-wrap {
    text-align: right;
    margin-top: 16px;
    border-top: 1px solid #e7e7ed;
    padding-top: 16px;
  }

  .date-input-wrap .filter-dropdown-card {
    min-width: auto;
    top: 60px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 10px 1px;
    background: #fff;
    right: 0;
    border: none;
  }

  .date-input-wrap .rdr-Calendar {
    margin-left: 0 !important;
    right: 0;
  }

  .date-input-wrap .rdr-Calendar .is-selected {
    border-radius: 50%;
  }

  .rdr-Day:hover {
    background: #0b5fff !important;
    border-radius: 50%;
    color: #fff;
  }

  .is-inRange,
  .is-selected {
    background: #0b5fff !important;
    color: #fff !important;
  }

  .date-input-block {
    display: flex;
    flex-wrap: wrap;
  }

  .rel {
    position: relative;
  }

  .date-input .text {
    font-size: 14px;
    margin-bottom: 4px;
    display: inline-block;
    font-weight: 500;
    white-space: nowrap;
    color: #272833;
  }

  .moment .ic-date-icon {
    position: absolute;
    top: 10px;
    right: 12px;
    background-size: 16px;
    width: 16px;
    height: 16px;
  }

  .moment {
    border: 1px solid #cdced9;
    border-radius: 4px;
    padding: 6px 46px 6px 12px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #6b6c7e;
    font-weight: 500;
    .date-to,
    .date-from {
      white-space: nowrap;
    }
  }

  .moment .ic-date-icon {
    text-indent: -9999px;
    display: inline-block;
    overflow: hidden;
    background: url(/image/icons/ic_calendar.png) center center no-repeat;
    position: absolute;
    top: 7px;
    right: 12px;
    background-size: 16px;
    width: 16px;
    height: 16px;
  }
`;

export default DatePickerStyled;
