import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  onFilterChange: PropTypes.func,
};

const defaultProps = {
  onRowClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  onFilterChange: () => null,
  pagination: paginationProps.defaultValue,
};

const PrnView = ({ ...props }) => {
  const { data, onPageChange, pagination, onRowClick } = props;

  return (
    <Fragment>
      <div className='table-wrap prn'>
        <table>
          {TableHeader()}

          <tbody>{data.list.map((prn) => TableBody(prn, onRowClick))}</tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

PrnView.propTypes = propTypes;

PrnView.defaultProps = defaultProps;

const PrnViewWithErrorAndLoading = withErrorAndLoadingScreen(PrnView);

export default PrnViewWithErrorAndLoading;
