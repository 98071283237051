import gql from 'graphql-tag';
import { GET_PAYMENTS_LIST } from '../cash/API';

const GET_BANK_LIST = gql`
  query banks {
    banks {
      id
      title
    }
  }
`;

const query = {
  payments: GET_PAYMENTS_LIST,
};

export { GET_BANK_LIST };

export { query };
