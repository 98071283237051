import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { breadCrumbConfig, filterConfig, title } from './config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { imageSliderListFormatter, isError } from '../../../common/HelperFunctions';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { getPermissionForPayment } from '../../../base/permission';
import * as queryService from '../../../base/query.service';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import View from './View';
import withAlert from '../../../../utils/composition/withAlert';
import { RECEIPTS } from '../../../../data/enums/Route';
import { labelConfig, TableHeader } from './tableConfig';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import SplitPayment from '../../../components/SplitPayment';
import { clone } from '../../../../utils/objectProcessor';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../../common/HelperFunctions';
import { storageKeys } from '../../../sales/orderProcessing/config';
import { DOMAIN } from '../../../../data/enums/config';

const propTypes = {
  downloadReport: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
  payments: PropTypes.func.isRequired,
};

const defaultProps = {
  downloadReport: () => null,
  serverResponseWaiting: false,
};

class Receipts extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      tableInfo: labelConfig,
      dialog: {
        type: '',
        element: '',
      },
    };
    this.permission = getPermissionForPayment();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
    TableHeader({ tableConfig: labelConfig, dynamicHeader: true });
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.RECEIPT
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.RECEIPT);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { payments } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    payments(
      {
        limit: queryParameters.pagination.limit,
        offset,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          const paymentsList = (response.data.payments && response.data.payments.rows) || [];
          data.total = (response.data.payments && response.data.payments.count) || 0;
          data.list = paymentsList.map(payment => {
            if (payment.details.image) {
              payment.details.image = [
                ...(imageSliderListFormatter(payment.details.image, '', { w: 2400, h: 1100 }) || []),
              ];
            }
            return payment;
          });
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  onReceiptsClick = type => {
    const { history } = this.props;
    if (type === EVENT_OPERATION.CREATE) {
      history.push(`/${RECEIPTS}/create`);
    }
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  columnSelectChange = data => {
    this.setState({
      tableInfo: data,
    });
  };

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  getStatus = (status, statusType) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE[statusType === 'err' ? 'INFO' : 'SUCCESS'], status);
  };

  render() {
    const { data, queryParameters, tableInfo, dialog,filterMenu} = this.state;
    const { type } = dialog;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="return-create">
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                breadCrumb={breadCrumbConfig}
                config={{
                  title,
                  create: this.permission.create,
                  download: true,
                  filter: true,
                  upload: false,
                  date: true,
                  search: true,
                  columSelect: true,
                }}
                downloadConfig={{
                  domain: DOWNLOAD_DOMAIN.RECEIPT,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList: filterMenu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                labelConfig={tableInfo}
                queryParameters={queryParameters}
                columnSelectChange={this.columnSelectChange}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.onReceiptsClick}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
              />
            </PanelStyled>
          </div>
          <Fragment>
            {type && (
              <SplitPayment
                data={dialog.element}
                type={type}
                resetDialog={this.resetDialog}
                fetchAllData={this.loadTableData}
                getStatus={this.getStatus}
              />
            )}
            <div className="section-content table-present">
              <View
                data={data}
                pagination={queryParameters.pagination}
                loading={serverResponseWaiting}
                onPageChange={this.basePaginationService.onPageSelect}
                tableConfig={tableInfo}
                onIconClick={this.handleIconClick}
                permission={this.permission}
              />
            </div>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}

Receipts.propTypes = propTypes;

Receipts.defaultProps = defaultProps;

export default withAlert()(Receipts);
