import React from 'react';
import PropTypes from 'prop-types';
import { Button, CheckBox } from '../../../v4/components';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  checkedListLength: PropTypes.number,
  dataLength: PropTypes.number,
  onIconClick: PropTypes.func.isRequired,
};
const defaultProps = {
  checkedListLength: 0,
  dataLength: 0,
};

const BulkDeleteView = ({ dataLength, onIconClick, checkedListLength, Buttons }) =>
  checkedListLength > 0 && (
    <div className='selected-status'>
      <div className='content-center'>
        <div>
          <CheckBox checked className='disabled-btn' />
          <p className='text'>
            <span>{checkedListLength}</span>
            of
            <span>{dataLength}</span>
            items selected
          </p>
        </div>
        <div className='button-section'>
          {Buttons.map((button) => (
            <Button
              key={button.label}
              small
              secondary={button.label === 'Remove'}
              primary={button.label !== 'Remove'}
              onClick={() => onIconClick(button.status)}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );

BulkDeleteView.propTypes = propTypes;

BulkDeleteView.defaultProps = defaultProps;

const BulkDeleteWithErrorAndLoading = withErrorAndLoadingScreen(BulkDeleteView);

export default BulkDeleteWithErrorAndLoading;
