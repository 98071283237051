import { compose } from 'recompose';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, SEND_MESSAGE, EDIT_MESSAGE
} from './API';
import Detail from './Detail';

const ComposedPushDetail = compose(
  SEND_MESSAGE,
  EDIT_MESSAGE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Detail);

export default ComposedPushDetail;
