import { compose } from 'recompose';
import SKUGroupMaster from './SKUGroupMaster';
import {
  query, mutation, TOGGLE_STATE, createSKUGroupMaster, updateSKUGroupMaster
} from '../Repository/API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedSKUGroup = compose(
  TOGGLE_STATE,
  createSKUGroupMaster,
  updateSKUGroupMaster,
  withRequestTracker({
    query,
    mutation,
  }),
)(SKUGroupMaster);

export default composedSKUGroup;
