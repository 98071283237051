import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { sales as salesRoute } from '../../../data/enums/Route';

const breadCrumb = [salesRoute];

const filter = getFilterConfig([FILTER.SUB_D]);

const SRN_TABS = {
  PENDING: 'pending',
  SRN: 'srn',
};

const tabMapper = {
  0: SRN_TABS.PENDING,
  1: SRN_TABS.SRN,
};

export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, SRN_TABS, tabMapper
};
