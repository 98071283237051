export const pendingServerToClientDataMapper = data => {
  return data?.map(item => {
    return {
      id: item.id,
      distributor: item.distributor_title,
      sku: item.sku_title,
      orderQuantity: item.order_quantity,
      inStock: item.in_stock,
      gap: item.gap,
      purchaseOrderDetail: item.po_detail?.map(poDetailItem => {
        return {
          orderId: poDetailItem.order_id,
          outletTitle: poDetailItem.outlet_title,
          orderQuantity: poDetailItem.order_quantity,
        };
      }),
    };
  });
};
export const successServerToClientDataMapper = data => {
  return data?.map(item => {
    return {
      date: item.requested_date,
      distributor: item.distributor_title,
      sku: item.sku,
      orderQuantity: item.quantity,
      distributor: item.distributor,
      documentNumber: item.document_number
    };
  });
};
export const failedServerToClientDataMapper = data => {
  return data?.map(item => {
    return {
      date: item.requested_date,
      distributor: item.distributor_title,
      sku: item.sku,
      orderQuantity: item.quantity,
      distributor: item.distributor,
      documentNumber: item.document_number,
      reason: item.message
    };
  });
};
