import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const GET_TRIPWISE_COLLECTION = gql`
query TripWiseCollections($filter: FilterInput) {
  tripWiseCollections(filter: $filter) {
    rows {
      trip_id
      trip_date
      trip_name
      amount
      collector_id
      collector_name
    }
    count
  }
}
`;

const GET_PAYMENT_LIST_BY_TRIP_ID = gql`
query PaymentListByTripId($tripId: String!) {
  paymentListByTripId(tripId: $tripId) {
    payments {
      id
      RetailOutlet {
        id
        title
      }
      Route {
        id
        title
      }
      collectionType
      details {
        valuedDate
        chequeNumber
        image {
          src
          thumbnail
        }
      }
      amount
    }
    total
  }
}
`;

const RECEIVE_COLLECTION_BY_TRIP_ID = graphql(
  gql`
  mutation ReceiveCollectionByTripId($tripId: String) {
    receiveCollectionByTripId(tripId: $tripId)
  }
  `,
  {
    props: ({ mutate }) => ({
      receiveCollectionByTripId: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getTripWiseList: GET_TRIPWISE_COLLECTION,
  getPaymentListByTripId: GET_PAYMENT_LIST_BY_TRIP_ID,
};

const mutation = {
  receiveCollectionByTripId:RECEIVE_COLLECTION_BY_TRIP_ID,
  downloadReport:DOWNLOAD_REPORT,
};

export { query, mutation };

export { RECEIVE_COLLECTION_BY_TRIP_ID, };
