import { compose } from 'recompose';
import WarehouseLevels from './WarehouseLevels';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  CREATE_WAREHOUSE_LEVELS,
  UPDATE_WAREHOUSE_LEVELS,
  query,
  mutation,
  DELETE_WAREHOUSE_LEVEL_DETAIL,
} from './API';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const ComposedWarehouseLevels = compose(
  TOGGLE_STATE,
  CREATE_WAREHOUSE_LEVELS,
  UPDATE_WAREHOUSE_LEVELS,
  DELETE_WAREHOUSE_LEVEL_DETAIL,
  withRequestTracker({
    query,
    mutation,
  }),
)(WarehouseLevels);

export default ComposedWarehouseLevels;
