import { compose } from 'recompose';
import SbdDialog from './SbdDialog';
import {
  mutation, CREATE_SBD, UPDATE_SBD, REPLICATE_SBD
} from './API';
import { TOGGLE_STATE } from '../../../../common/GqlAPI';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedSbdDialog = compose(
  CREATE_SBD,
  UPDATE_SBD,
  TOGGLE_STATE,
  REPLICATE_SBD,
  withRequestTracker({
    mutation,
  }),
)(SbdDialog);

export default ComposedSbdDialog;
