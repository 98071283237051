import React from 'react';
import PropTypes from 'prop-types';
import DetailView from './View';
import withAlert from '../../../../utils/composition/withAlert';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: [],
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
};

const Detail = ({ ...props }) => {
  const { data, onRowClick, onIconClick, permission, stockAdjDetail } = props;

  return (
    <DetailView
      data={data}
      onRowClick={onRowClick}
      onIconClick={onIconClick}
      permission={permission}
      stockAdjDetail={stockAdjDetail}
    />
  );
};

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
