import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const GET_WAREHOUSE_LEVELS_LIST = gql`
  query getWarehouseLevels($offset: Int, $limit: Int, $filter: FilterInput) {
    getWarehouseLevels(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        parentId
        levelIndication
        dimensionLength
        dimensionHeight
        dimensionWidth
        active
        parent {
          id
          title
        }
      }
      count
    }
  }
`;

const CREATE_WAREHOUSE_LEVELS = graphql(
  gql`
    mutation ($input: WarehouseLevelInput!) {
      createWarehouseLevel(input: $input) {
        id
        title
        parentId
        levelIndication
        dimensionLength
        dimensionHeight
        dimensionWidth
        active
        parent {
          id
          title
        }
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createWarehouseLevel: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_WAREHOUSE_LEVELS = graphql(
  gql`
    mutation ($id: Int!, $input: WarehouseLevelInput!) {
      editWarehouseLevel(id: $id, input: $input) {
        id
        title
        parentId
        levelIndication
        dimensionLength
        dimensionHeight
        dimensionWidth
        active
        parent {
          id
          title
        }
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      editWarehouseLevel: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DELETE_WAREHOUSE_LEVEL_DETAIL = graphql(
  gql`
    mutation ($ids: [Int]!) {
      deleteWarehouseLevels(ids: $ids) {
        ids
        message
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteWarehouseLevels: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getWarehouseLevelsList: GET_WAREHOUSE_LEVELS_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  createWarehouseLevel: CREATE_WAREHOUSE_LEVELS,
  editWarehouseLevel: UPDATE_WAREHOUSE_LEVELS,
  deleteWarehouseLevels: DELETE_WAREHOUSE_LEVEL_DETAIL,
};

export {
  TOGGLE_STATE,
  GET_WAREHOUSE_LEVELS_LIST,
  CREATE_WAREHOUSE_LEVELS,
  UPDATE_WAREHOUSE_LEVELS,
  DELETE_WAREHOUSE_LEVEL_DETAIL,
};

export { query, mutation };
