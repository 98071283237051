import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { refValidator ,refGenerator} from '../../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import history from '../../../../utils/history';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION,EVENT_OPERATION_MAPPER} from '../../../../data/enums/EventOperation';
import { dropdownChange } from '../../../../utils/formHandlers';
import View from './View';
import {
  BreadCrumb, Icon ,Button
} from '../../../../v4/components';
import * as queryService from '../../../base/query.service';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import {  crudSuccess as crudRequestConfig ,formMapper,title,breadCrumbConfig} from './config';
import { getUser } from '../../../../data/dao';
import { clone } from '../../../../utils/arrayProcessor';
import DetailsStyled from './DetailStyled';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../data/services';
import { MERCHANDISE_DETAILS } from '../../../../data/enums/Route';
import { getPermissionForMerchandisingDevices } from '../../../base/permission';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class MerchandiseDetails extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props) {
    super(props);
    const id  =this.props.match.params ? parseInt(this.props.match.params.id) : null;
    this.userInfo = getUser();
    this.state = {
      id,
      data: formMapper({}),
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      enableFormValidation: false,
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      menu: {
        categoryList: [],
        brandList: [],
      },
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createMerchandiseDevice,
      [EVENT_OPERATION.UPDATE]: props.updateMerchandiseDevice,
    };
    this.onCRUDSuccess =  this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.handleAPIFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator(['title','categoryId',]);
    this.permission = getPermissionForMerchandisingDevices();
  }
  handleAPIFailure = err => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, err);
  };
  componentDidMount() {
    const {id,update} =this.state;
    this.loadDataForDropDown();
    if(id){
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState(
        {
          update,
        },
        () => {
          this.getPOSMsDetail();
        },
      );
    }
  }
  responseProcessor = () => {
    const onAPIRequestSuccess = type => response => {
      const { displayAlert } = this.props;
      const { update, id } = this.state;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage, response);
      if (type === 'CREATE') {
        setTimeout(() => {
          history.push(`/${MERCHANDISE_DETAILS}/${response.id}`);
          update.type = 'UPDATE';
          update.status = false;
        this.setState({ update,id:response.id ,enableFormValidation:false });
          this.getPOSMsDetail(response.id);
        }, 2000);
      }
      if (type === 'UPDATE') {
        update.status = false;
        this.setState({ update ,id});
        this.getPOSMsDetail(id);
      }
    };
    return onAPIRequestSuccess;
  };
  getPOSMsDetail = () => {
    const {id} =this.state;
    const { getPOSMsDetails, displayAlert } = this.props;
      getPOSMsDetails(
        { merchandiseDeviceId:id },
      {
        handleSuccess: response => {
          const dataDetails = response.data.merchandiseDeviceDetails;
          this.setState(
            {
              data: dataDetails,
              backUpData: clone(dataDetails),
            }
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };
 
    handleButtonCancel = () => {
      const { update, id } = this.state;
      if (update.type === EVENT_OPERATION.CREATE) {
        this.setState({
          data: formMapper({}),
          enableFormValidation:false,
        },);
      } else {
        update.type = EVENT_OPERATION.UPDATE;
        update.status=false;
        this.setState({ update,enableFormValidation:false, }, () => {
          this.getPOSMsDetail(id);
        });
      }
    };

  handleDropDownChange = (value, parameterRef = []) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    this.setState({ data: updatedData });
  };

  onInputChange = (event, param) => {
    const { data } = this.state;
    data[param] = event.formattedValue;
    this.setState({ data });
  };
  handleButtonSubmit = () => {
    const {update,data} =this.state;
    const valid = refValidator(this.formReference);
    if (!valid) {
      this.setState({ enableFormValidation: true });
    } else {
      if(update.type ==EVENT_OPERATION.CREATE){
      this.createPOSMs();
      }
      else{
        this.updatePOSMs();
      }
    }
  };
  getActionType = () => {
    const { update } = this.state;
    return update.type !== EVENT_OPERATION.CREATE
      ? update.type === EVENT_OPERATION.UPDATE
        ? EVENT_OPERATION.UPDATE
        : EVENT_OPERATION.APPROVE
      : EVENT_OPERATION.CREATE;
  };

createPOSMs = () => {
  const { data } = this.state;
  const type = this.getActionType();
  this.onFormSubmit(type, data);
  this.setState({ data });
};
updatePOSMs =() =>{
  const { data } = this.state;
  const type = this.getActionType();
  this.onFormSubmit(type, data);
  this.setState({ data })
}

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY)
      .then((res) => {
        menu.categoryList = res;
        this.setState({ menu });
      });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.BRAND)
      .then((res) => {
        menu.brandList = res;
        this.setState({ menu });
      });
  };
  handleAddRemoveClick = (action, field, index = 0) => {
    const {data} =this.state;
    const categoryBrandBase = {
      categoryId:'',
        brandIds: []
    };
    if (action === 'remove') {
      data[field].splice(index, 1);
    }
    if (action === 'add') {
      const baseObj = categoryBrandBase;
      data[field].push({ ...baseObj });
    }
    this.setState({ data });
  };
  handleAutoCompleteChange=(value,index, fieldName) => {
    const { data } = this.state;
    data.brandsCategoryInfo[index][fieldName] = value;
    this.setState(data);
  }
  getHeader = () => {
    const { update } = this.state;
    const crudMode = update.status ? EVENT_OPERATION_MAPPER[update.type].toLowerCase() : '';
    return (
      <>
        <span>{crudMode} </span>
        {title}
      </>
    );
  };
  render() {
    const {
      data,
      update,
      enableFormValidation,
      menu,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const header = this.getHeader();
    return (
      <DetailsStyled>
        <div className='section-header'>
          <PanelStyled className='padding-0'>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{header}</h2>
              <div className="flex m-0">
                {update.status && (
                  <div>
                    <Button small secondary disabled={serverResponseWaiting} onClick={() => this.handleButtonCancel()}>
                      <span> Cancel</span>
                    </Button>
                    <Button small primary disabled={serverResponseWaiting} onClick={() => this.handleButtonSubmit()}>
                      <span>Save</span>
                    </Button>
                  </div>
                )}
                {(!update.status && this.permission.update) && (
                  <Button
                    secondary
                    iconBtnSmall
                    disabled={update.type === EVENT_OPERATION.UPDATE && update.status}
                    onClick={() => {
                      this.setState({
                        update: {
                          type: EVENT_OPERATION.UPDATE,
                          status: true,
                        },
                      });
                    }}
                    className="ml-16"
                  >
                    <Icon iconName="pencil" />
                  </Button>
                )}
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className={`section-content pad-48 ${update.type === 'UPDATE' && 'update'}  ${update.status || 'disable'}`}>
         <View
          refsObj={this.formReference}
          enableErrorDisplay={enableFormValidation}
          data={data}
          update={update}
          handleAddRemoveClick={this.handleAddRemoveClick}
          menu ={menu}
          onAutoCompleteChange={this.handleAutoCompleteChange}
          onDropDownChange={this.handleDropDownChange}
          handleInputChange={this.onInputChange}
        />
        </div>
      </DetailsStyled>
    );
  }
}

MerchandiseDetails.propTypes = propTypes;

MerchandiseDetails.defaultProps = defaultProps;

export { MerchandiseDetails };

export default withAlert()(MerchandiseDetails);
