const errorMessageParser = (err = {}) => {
  // can be array
  // "graphQLErrors", "networkError", "message", "extraInfo"

  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    return err.graphQLErrors[0].message;
  }

  if (err.networkError) {
    return 'Error in loading requested result';
    // return err.networkError.message;
  }

  if (err.message) {
    return err.message;
  }

  return 'Error in operation. Please try again later';
};

export { errorMessageParser };
