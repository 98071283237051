import styled from 'styled-components';

export const OutletStyled = styled.div`
.outlet-wrap{
  display: flex;
  justify-content: space-between;
  padding: 24px 48px;
  .filtered-outlet,.selected-outlet{
    background: #ffffff;
    border: 1px solid #E7E7ED;
    border-radius: 8px;
    .title{
      color: #6B6C7E;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      padding: 16px;
      border-bottom: 1px solid #E7E7ED;
      .light,.success,.primary{
        margin-left: 8px;
        line-height: 8px;
      }
    }  
  }
  .filtered-outlet{
    width: 58%;
    border-bottom-right-radius: 0px;
    .filter-outlet-wrap{
      display: flex;
      width: 100%;
      height: 100%;
      .filters{
        width: 35%;
        border-right: 1px solid #E7E7ED;
      }
      .outlets{
        width:65%;
        .outlet-content{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px;
          border-bottom: 1px solid #E7E7ED;
        }
      }
    }
  }
  .selected-outlet{
    width: 40%;
    border: 1px solid #5ACA75;
    .title{
      background: #EDF9F0;
      display: flex;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .view-btns{
        margin-left: auto;
      }
    }
    .outlet-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid #E7E7ED;
    }
    .call-plan{
      padding: 16px;
      display: flex;
      border-top: 1px solid #5ACA75;
        button{
          margin-left: auto;
        }
     }
    }
  }
  .select-section,.button-section{
    padding: 16px;
    border-top: 1px solid #E7E7ED;
  }
  .filter-dropdown-card {
    min-width: 257px;
    left:0;
  }
  .suggested-autocomplete-list-wrap {
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 24px;
    display: flex;
    flex-wrap:wrap;
    .suggested-autocomplete-list{
    margin-top: 4px;
    border: 1px solid #E7E7ED;
    min-height: 24px;
    background-color: #FFF;
    margin-right: 4px;
    .text{
      border:0;
      background:unset;
      color: #6B6C7E;
      font-weight: 500;
    }
    .ic-close{
    margin-left:0;
    margin-right: 18px;
    }
    }
  }
  .autocomplete-list {
    > div {
      margin-bottom: 5px;
      cursor: pointer;
      font-weight: 600;
      color: #6b6c7e;
      font-size: 14px;
      transition: 0.2s linear;

      &:hover,
      &:focus {
        color: #272833;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .suggested-autocomplete-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 4px;
    .text {
      padding: 2px 16px;
      border-radius: 4px;
      background: #e7e7ed;
      border: 1px solid #e7e7ed;
      font-size: 12px;
      flex: 1;
    }
    .ic-close {
      cursor: pointer;
      display: inline-block;
      width: 9px;
      height: 9px;
      overflow: hidden;
      text-indent: -999px;
      background: url('/image/icons/ic_close.svg');
      background-size: 9px;
      margin-left: 18px;
    }
  }
  .outlet-section{
    height: calc(100vh - 287px);
    overflow-y: scroll;
    .outlet-content{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid #E7E7ED;
      .outlet-title{
        display: flex;
        align-items: center;
        .title-content{
          display: flex;
          flex-flow: column;
          margin-left: 16px;
          p{
            margin: 0;
            img{
              height: 14px;
              width: 14px;
              margin-left: 8px;
            }
          }
          .channel-category{
            color: #6B6C7E;
            font-size: 12px;
            line-height: 14px;
            margin-top: 5px;
            .light {
              margin-left: 8px;
              padding: 1.5px 3px;
              text-align: center;
              line-height: 7.5px;
              font-size: 7.5px;
              font-weight: 700;
              white-space: nowrap;
              border-radius: 2px;
              border: 1px solid #47C464;
              color: #287D3C;
              background: #ffffff !important;
            }
          }
          .reason{
            color: #A7A9BC;
            font-size: 12px;
            line-height: 14px;
            margin-top: 5px;
          }
        }
      }
    }
    .disabled{
      background: #F7F8F9;
    }
    .plus{
      height: 12px;
      width: 12px;
      color: #0B5FFF;
      line-height: 8px;
    }
    .add{
      &:hover {
                background-color: #F1F2F5;
              }
    }
  }

  //Form
  .modal-inner{
    max-width: 600px;
    .select-team{
      display: flex;
      .form-select-input,.single-date-picker{
        width: 50%;
      }
      .single-date-picker{
        padding-left: 24px;
        margin: 0;
      }
    }
  }
`;
