import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as queryService from '../../../base/query.service';
import withAlert from '../../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../../common';
import View from './View';
import DistributorSalesStyled from './DistributorSalesStyled';
import { title, formConfig, filterConfig, breadCrumbConfig, set, get } from './config';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import {
  DISTRIBUTOR_SALES_TARGET_DETAILS,
  OUTLET_TARGET_SUBD,
  OUTLET_TARGET_SUBD_DETAILS,
} from '../../../../data/enums/Route';
import history from '../../../../utils/history';
import { ALERT_TYPE } from '../../../../v4/constants/AlertType';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../../utils/api';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../../common/HelperFunctions';
import { clone } from '../../../../utils/objectProcessor';
import { storageKeys } from '../../../sales/orderProcessing/config';
import { DOMAIN } from '../../../../data/enums/config';
import TargetAchievementFormWithErrorAndLoading from '../UploadForm';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import { Icon } from '../../../../v4/components';
import { fileParser } from '../../../../utils/parser';
import { useHistory, useLocation } from 'react-router-dom';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getDistributors: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const initialDialogState = {
  id: '',
  distributorId: '',
  duration: {
    year: 2022,
    month: 1,
  },
  target: '',
};
const DistributorSalesTarget = props => {
  const filterConfigMenu = clone(filterConfig.menu);
  const {
    displayAlert,
    serverResponseWaiting,
    getDistributors,
    getDistributorTarget,
    createDistributorIcoTarget,
    editDistributorIcoTarget,
  } = props;

  const [data, setData] = useState({ isDistributorTargetDerived: false, list: [], total: 0 });
  const [distributorList, setDistributorList] = useState([]);
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    filter: queryService.baseQueryParameters.filter,
    date: { ...queryService.baseQueryParameters.date },
  });
  const [filterMenu, setFilterMenu] = useState(filterConfigMenu);
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [dialogData, setDialogData] = useState({
    ...initialDialogState,
  });

  const [csvUploadData, setCsvUploadData] = useState();
  // const [outletId, setOutletId] = useState();

  const location = useLocation();
  const history = useHistory();
  // const [outletTargetId, setOutletTargetId] = useState(null);
  const outletTargetId = get('outletTarget');


  const loadTableData = () => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    setFilterStateInStorage();
    getDistributorTarget(
      {
        limit: queryParameters.pagination.limit,
        offset,
        filter: {
          queryString: queryParameters.search,
          // filters: apiFilterProcessor(queryParameters.filter),
        },
      },
      {
        handleSuccess: res => {
          const response = res?.data?.distributors;

          setData({
            list: response ? response.rows : [],
            total: response ? response.count : 0,
          });
        },
        handleError: err => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const setParameters = queryParams => setQueryParameters({ ...queryParams });

  const basePaginationService = new queryService.QueryClass(setParameters, () => queryParameters, loadTableData);

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const getDistributorsList = () => {
    getDistributors(
      {},
      {
        handleSuccess: response => {
          setDistributorList(response.data.distributors.rows);
        },
        handleError: error => {
          onAPIRequestFailure(error);
        },
      },
    );
  };

  // useEffect(() => {
  //   setOutletTargetId(props.location.state.id);
  // }, [props.locationtion]);

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
    setDialogData(initialDialogState);
  };

  const handleIconClick = (type, element = {}) => {
    // getDistributorsList();
    setDialog({
      type,
      element,
    });
  };

  const handleDownloadClick = () => {
    const { downloadReport, displayAlert } = props;
    downloadReport(
      {
        input: {
          domain: 'OUTLET_FUNDAMENTAL_TARGET',
          reportType: 'csv',
          filter: {
            filters: [
              {
                column: 'target_id',
                value: `${outletTargetId?.id}`,
              },
            ],
            queryString: queryParameters.search,
          },
        },
      },
      {
        handleSuccess: response => {
          const { downloadReport } = response.data;
          window.open(`${window.location.origin}/${downloadReport.file.name}`);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const onTableBodyClick = data => {
    history.push({
      pathname: `/${OUTLET_TARGET_SUBD_DETAILS}/${data?.id}`,
      state: { subD_name: data?.title, outletTarget_id: location?.state?.outletTarget_id },
    });
  };

  const handleInputChange = e => {
    setDialogData({ ...dialogData, [e.target.name]: e.target.value });
  };

  const handleDropDownChange = (value, label) => {
    const dialogDataClone = { ...dialogData };
    dialogDataClone[label] = value;
    setDialogData({ ...dialogDataClone });
  };

  const handleDateChange = date => {
    setDialogData({ ...dialogData, duration: date });
  };

  const getFileData = async data => {
    const fileData = await fileParser(data.file).then(file => ({
      input: {
        file,
        type: 'OUTLETFUNDAMENTALTARGET',
        references: [
          {
            type: 'target_id',
            id: outletTargetId.id,
          },
        ],
      },
    }));
    return fileData;
  };

  const handleDialogSubmit = async (type, data) => {
    if (type === EVENT_OPERATION.UPLOAD) {
      const { upload, displayAlert } = props;
      const {
        file: { name },
      } = data;
      const inputData = await getFileData(data);
      upload(
        {
          ...inputData,
        },
        {
          handleSuccess: response => {
            displayAlert(ALERT_TYPE.SUCCESS, 'File uploaded successfully');
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  const onSampleDownload = entity => {
    const { downloadReport, displayAlert } = props;
    downloadReport(
      {
        input: {
          domain: entity,
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          const { downloadReport } = response.data;
          window.open(`${window.location.origin}/${downloadReport.file.name}`);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const sampleUrl = checkCase => (
    <a onClick={() => onSampleDownload(checkCase)}>
      <span className="sample-csv">
        {' '}
        <Icon iconName="download" />
        Sample.csv
      </span>
    </a>
  );

  const setFilterStateInStorage = () => {
    const { filter, date, search } = queryParameters;
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.DST,
    );
  };
  const getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.DST);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      }),
    };
    if (!localData) {
      return loadTableData();
    }
    setFilterMenu(newFilterConfig.filterMenu);
    setQueryParameters({
      ...queryParameters,
      filter: newFilterConfig.filter,
      searchText: newFilterConfig.searchText,
      date: newFilterConfig.date,
    });
    loadTableData();
  };

  useEffect(() => {
    getFilterStateFromStorage();
  }, []);
  useEffect(() => {
    setData({
      list: [],
    });
    loadTableData();
  }, [queryParameters]);
  return (
    <DistributorSalesStyled>
      {dialog.type && (
        <DialogFormWrapper
          formConfig={formConfig[dialog.type]}
          dialogElement={dialog.element}
          onDialogSubmit={handleDialogSubmit}
          onDialogCancel={resetDialog}
          type={dialog.type}
          renderDialog={({ dialogData, refsObj, enableErrorDisplay, handleFileUpload }) => (
            <>
              {dialog.type === EVENT_OPERATION.UPLOAD && (
                <>
                  <TargetAchievementFormWithErrorAndLoading
                    show
                    // refsObj={fileUploadRefs}
                    data={dialogData}
                    loading={serverResponseWaiting}
                    handleFileUpload={handleFileUpload}
                    enableErrorDisplay={enableErrorDisplay}
                  />
                  {sampleUrl(DOWNLOAD_DOMAIN.OUTLET_FUNDAMENTAL_TARGET_SAMPLE)}
                </>
              )}
            </>
          )}
        />
      )}
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              create: false,
              filter: false,
              date: false,
              upload: true,
              search: true,
              download: true,
            }}
            queryParameters={queryParameters}
            handleCreateClick={() => handleIconClick(EVENT_OPERATION.CREATE, {})}
            filter={{
              date: queryParameters.date,
              menuList: filterMenu,
              onFilterChange: basePaginationService.handleFilterChange,
            }}
            resetFilter={basePaginationService.resetFilter}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
            handleUploadClick={handleIconClick}
            handleDownloadClick={handleDownloadClick}
            handleSearchChange={basePaginationService.handleSearchInputChange}
          />
        </PanelStyled>
      </div>
      <div className="section-content table-present">
        <View
          data={data}
          loading={serverResponseWaiting}
          handleIconClick={handleIconClick}
          onTableBodyClick={onTableBodyClick}
          pagination={queryParameters.pagination}
          onPageChange={basePaginationService.onPageSelect}
        />
      </div>
    </DistributorSalesStyled>
  );
};

DistributorSalesTarget.propTypes = propTypes;

DistributorSalesTarget.defaultProps = defaultProps;

export default withAlert()(DistributorSalesTarget);
