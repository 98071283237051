import React, { ChangeEvent } from 'react';
import { CheckBox, Menu } from '..';
import ColumnSelectStyled from './ColumnSelectStyled';

const columnSelect = ({ ...props }) => {

  const { labelConfig, onColumnSelectChange } = props;

  const onCheckBoxClick = (item: any, index: number) => {
    const label = item;
    label.show = !label.show;
    labelConfig[index] = label;
    onColumnSelectChange(labelConfig);
  };

  const onAllSelect = (e: ChangeEvent<HTMLInputElement>) => {
    labelConfig.forEach((d: any) => {
      d.show = e.target.checked;
    });
    return onColumnSelectChange(labelConfig);
  };

  const checked = !labelConfig.map((d: any) => d.show).includes(false);

  return (
    <ColumnSelectStyled>
      <Menu header='' label='column'>
        <div className='title-col'>
          <CheckBox onCheckBoxClick={(e) => onAllSelect(e)} checked={checked} />
          <span className='title'> Select Columns</span>
        </div>
        <div className='select-li-wrap'>
          {labelConfig.map((data: any, index: number) =>
            data.label === 'checkbox' || data.label === 'actionHolders' ? (
              ''
            ) : (
              <div key={data.title} className='select-li'>
                <CheckBox onCheckBoxClick={() => onCheckBoxClick(data, index)} checked={data.show} />
                <span className='title'>{data.title}</span>
              </div>
            ),
          )}
        </div>
      </Menu>
    </ColumnSelectStyled>
  );
};

export default columnSelect;
