import { compose } from 'recompose';
import SubDAssortmentDialog from './SubDAssortmentDialog';
import { TOGGLE_STATE } from '../../../../common/GqlAPI';
import {
  mutation, UPDATE_SUB_D_ASSORTMENT, CREATE_SUB_D_ASSORTMENT
} from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedSubDAssortmentDialog = compose(
  CREATE_SUB_D_ASSORTMENT,
  UPDATE_SUB_D_ASSORTMENT,
  TOGGLE_STATE,
  withRequestTracker({ mutation }),
)(SubDAssortmentDialog);

export default composedSubDAssortmentDialog;
