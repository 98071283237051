import restClient from '../apiClient/rest';
import {
  IMAGE_SERVER, IMAGE_SERVER_TOKEN, BU
} from '../environment';

const GRN_IMAGE_DIRECTORY = `${BU}/inventory/grn`;

const getImageServerDetails = () => ({
  name: IMAGE_SERVER,
  token: `Bearer ${IMAGE_SERVER_TOKEN}`,
});

const imageServer = getImageServerDetails();

const handleRestError = (response) => {
  if (!response.ok) {
    throw Error(response.status);
  }

  return response;
};

const grnImageUploader = async (image, { successCallBack, failureCallBack }) => {
  const formData = new FormData();
  formData.append('destination', GRN_IMAGE_DIRECTORY);
  formData.append('file', image);

  return restClient(`${imageServer.name}/resize`, {
    headers: {
      Authorization: imageServer.token,
    },
    method: 'POST',
    body: formData,
  })
    .then(handleRestError)
    .then((response) => response.json())
    .then((data) => {
      const imageURL = `${imageServer.name}/${GRN_IMAGE_DIRECTORY}/thumbnail/${data.data.name}`;
      const dataWithFullImageUrl = {
        imageURL,
        data: { ...data.data },
      };
      successCallBack(dataWithFullImageUrl);
    })
    .catch((err) => failureCallBack(err));
};

export {
  getImageServerDetails, grnImageUploader, handleRestError
};
