import styled from 'styled-components';

const NavigationStyled = styled.div`
  .sidebar-inner {
    .loading-main {
      left: 64px;
    }
  }
  .menu-item-link {
    position: relative;
    z-index: 1;
    padding-bottom: 18px;
    & > .img-holder {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      margin: auto;
      img {
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
      }
    }
    > p {
      font-size: 7px;
      line-height: 9px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      display: block;
      margin: 0;
      margin-top: 4px;
      opacity: 0.5;
      cursor: pointer;
    }
    &.active {
      &:hover .img-holder {
        background: #0b5fff;
      }
      .img-holder {
        background: #0b5fff;
        transition: all 0.3s ease-in-out;
        opacity: 1;
        img {
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }
      }
      p {
        opacity: 1;
        transition: all 0.3s ease-in-out;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 18px;
      height: 18px;
      margin: 0 auto;
      display: block;
    }
    &:hover img,
    &:focus img,
    &:hover p,
    &:focus p {
      opacity: 1;
      transition: all 0.3s ease-in-out;
    }
    &:hover .img-holder {
      background: rgb(255, 255, 255, 0.1);
    }
    &:focus .img-holder {
      background: #0b5fff;
      transition: all 0.3s ease-in-out;
    }
  }
  .logo-wrap {
    background: #073681;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    a {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .other-nav .nav-list,
  .primary-nav .menu-item-link {
    position: relative;
    &:after {
      content: attr(data-text);
      position: absolute;
      opacity: 0;
      pointer-events: none;
      top: 4px;
      left: 0;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      background: #333;
      height: 24px;
      padding: 0 16px;
      border-radius: 4px;
      z-index: 1;
      white-space: nowrap;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
    }
    &:hover,
    &:focus {
      &:after {
        opacity: 1;
        pointer-events: auto;
        transition: all 0.3s ease-in-out;
        left: 60px;
      }
    }
  }
  .other-nav.text-center .nav-list {
    &:first-child {
      &:after {
        content: 'Notification';
      }
    }
    &:nth-child(2) {
      &:after {
        content: 'Profile';
      }
    }
    &:last-child {
      &:after {
        content: 'Log Out';
      }
    }
    &:hover,
    &:focus {
      &:after {
        left: 50px;
      }
    }
  }

  .sidebar-navigation {
    width: 64px;
    height: 100vh;
    background: #002766;
    z-index: 9999;
    color: #fff;
    @media print {
      display: none;
    }
  }
  .styleguide-menu {
    a {
      text-decoration: none;
      padding: 8px 16px;
      background: #458fff;
      border-radius: 4px;
      display: block;
      margin: 0 0 15px;
      text-align: left;
      color: #fff;
    }
  }
  .sidebar-inner {
    max-height: 100%;
    height: 100vh;
    .loading-main {
      left: 64px !important;
    }
  }
  .sidebar-navigation .menu-item-link a {
    display: inline-block;
    padding: 0;
    background: transparent;
    color: #458fff;
    font-size: 12px;
  }
  .sidebar-navigation a:last-child {
    margin-bottom: 0;
  }
  .menu-wrap {
    padding: 16px 0 0 0;
    height: calc(100vh - 64px);
    position: relative;
    z-index: 101;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    .primary-nav {
      align-self: center;
    }
    .other-nav {
      align-self: center;
      .nav-list {
        margin-bottom: 16px;
        color: #fff;
        height: 32px;
        width: 32px;
        border-radius: 4px;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &.profile {
          padding: 0;
          border-radius: 50%;
          border: 2px solid #fff;
          background-color: #ff73c3;
          opacity: 1;
          line-height: 27px;
          &:hover {
            background: unset;
            background-color: #ff73c3;
          }
          span {
            font-size: 10px;
            font-weight: 600;
            line-height: 12px;
            color: #fff;
          }
        }
        &:hover {
          background: rgb(255, 255, 255, 0.1);
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          opacity: 1;
        }
        svg {
          margin-top: 0;
          border: 1px solid transparent;
          width: 18px;
          height: 18px;
        }
        &:last-child {
        }
        img {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  /* side nav pop out */
  .off-canvas-sec-nav {
    position: fixed;
    left: 64px;
    bottom: 0;
    top: 0;
    z-index: 100;
    width: 214px;
    background-color: #fff;
    &:after {
      content: '';
      background: #fff;
      position: absolute;
      width: 214px;
      height: 100%;
      bottom: 0;
      left: 0;
    }

    .title {
      color: #272833;
      position: relative;
      z-index: 1;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      height: 64px;
      display: inline-block;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
      padding: 20px 24px;
      width: 100%;
      margin: 0;
    }
    .no-title-nav-wrap {
      height: calc(100vh - 64px);
      overflow: auto;
      padding: 24px 0;
      position: relative;
      z-index: 1;
    }

    .sec-title {
      color: rgba(0, 0, 0, 1);
      position: relative;
      z-index: 1;
      font-size: 10px;
      font-weight: 600;
      margin: 0 0 16px;
    }

    .sec-nav-link-title {
      color: rgba(0, 0, 0, 0.5);
      position: relative;
      z-index: 1;
      font-size: 10px;
      font-weight: 600;
      margin: 0 0 16px;
    }
  }

  .sec-nav-sub-menu {
    background: #f9f9f9;
    padding: 10px;
    margin-top: 16px;
    border-radius: 4px;
    margin-left: 16px;
  }

  .sec-nav-content {
    padding: 24px 0;
    width: calc(214px - 48px);
    position: relative;
    z-index: 1;
    margin-left: 24px;
    &:last-child {
      padding-bottom: 0;
      border: none;
      margin-bottom: 0;
    }
  }
  /* sidenav general for drop and menu */
  .sec-nav-content,
  .no-title-nav {
    margin: 0 16px 24px 16px;
    .sec-title,
    .sec-nav-link {
      font-size: 14px;
      line-height: 16px;

      font-weight: 400;
      a {
        
        color: #000000;
        text-decoration: none;
        padding: 4px 8px;
        width: 100%;
        display: inline-block;
      }
    }
    .sec-nav-link {
      &.active {
        a {
          color: #0b5fff;
          font-weight: 600;
        }
      }
    }
  }

  /* dropdown only */
  .sec-nav-content {
    padding: 0;
    .sec-title {
      padding: 4px 8px;
      margin-bottom: 8px;
    }
    .sec-nav-inner {
      .sec-nav-link {
        cursor: pointer;
        a {
          color: #6b6c7e;
          font-size: 12px;
          line-height: 16px;
          padding: 0 0 0 16px;
          font-weight: 400;
          height: 16px;
          cursor: pointer;
        }
        &:hover {
          a {
            color: #000;
          }
        }
        &.active {
          a {
            color: #0b5fff;
          }
        }
      }
    }
  }
  .sec-nav-link {
    position: relative;
    z-index: 1;
    margin-bottom: 8px;
    &.active {
      font-weight: bold;
    }
  }
  /* navigation overlay */
  .navigation-overlay {
    position: fixed;
    background: rgba(205, 206, 217, 0.4);
    top: 0;
    bottom: 0;
    left: 64px;
    width: calc(100% - 64px);
    z-index: 99;
    &:before {
      content: url('/image/icons/angle-left.svg');
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 240px;
      width: 32px;
      height: 32px;
      border: 1px solid #f1f2f5;
      border-radius: 50%;
      cursor: pointer;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.32s ease-in-out;
    }
    &:hover,
    &:focus {
      &:before {
        box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.2);
        transition: all 0.32s ease-in-out;
        left: 235px;
      }
    }
  }
  @media screen and (max-height: 669px) {
    .menu-wrap .other-nav {
      margin-top: 20px;
    }
    .menu-wrap .primary-nav {
      overflow: hidden;
    }
    .primary-nav .menu-item-link:hover:after {
      opacity: 0;
    }
    /* scrollbar custom */
    .ps__thumb-y {
      background-color: #0b5fff;
    }
    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
      width: 6px;
      background-color: #0b5fff;
    }
    .ps__rail-y,
    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
      background-color: transparent;
      width: 10px !important;
    }
    .ps__rail-x {
      display: none;
    }
  }
  @media screen and (min-height: 670px) {
    .ps {
      overflow: unset !important;
    }
    .ps__rail-y,
    .ps__rail-x {
      display: none;
    }
  }
`;

export default NavigationStyled;
