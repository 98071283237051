import gql from 'graphql-tag';

const GET_CALL_PLAN_DASHBOARD = gql`
query GetCallPlanDashboard {
  getCallPlanDashboard {
    total_call_planned
    total_call_plan_target
    total_call_completed
    productivity
    sbdCount
    countTotalCAll
    total_call_duration {
      minutes
      seconds
    }
    received_order_value
  }
}
`
const GET_SBD_FSKU= gql`
query GetSbdFsku {
  getSbdFsku {
    sbd_golden_points
    fsku
  }
}
`

const query = {
  getCallPlanDashboard:GET_CALL_PLAN_DASHBOARD,
  getSbdFsku:GET_SBD_FSKU
};

export { };

export { query };
