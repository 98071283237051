import React from 'react';
import PropTypes from 'prop-types';

import {
  Input, Form, SingleDatePicker
} from '../../../components';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
};

const PromotionForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, checkedList } = props;
  const selectedList = checkedList.length;
  return (
    <Form>
      {data.id ? (
        <Input
          ref={(ref) => (refsObj.title = ref)}
          value={data.title}
          name='title'
          type='text'
          placeholder='Title'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event)}
          labelContent='Title'
        />
      ) : (
        <span>
          {'Are you sure you want to replicate '}
          <b>{selectedList}</b>
          {' of the selected promotions?'}
        </span>
      )}

      <div className='single-date-picker'>
        <label>Start Date</label>
        <SingleDatePicker
          date={data.startDate}
          // maxDate={data.endDate}
          name='startDate'
          placeholder='Start Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name: 'startDate',
                  value: date,
                },
                formattedValue: date,
              },
              '',
            )
          }
          labelContent='Start Date'
        />
      </div>

      <div className='single-date-picker'>
        <label>End Date</label>
        <SingleDatePicker
          date={data.endDate}
          minDate={data.startDate}
          name='endDate'
          placeholder='End Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name: 'endDate',
                  value: date,
                },
                formattedValue: date,
              },
              '',
            )
          }
          labelContent='End Date'
        />
      </div>
    </Form>
  );
};

PromotionForm.propTypes = propTypes;

PromotionForm.defaultProps = defaultProps;

export default PromotionForm;
