import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'react-date-range';
import { Menu } from '../../v4/components';
import * as dateUtil from '../../utils/date';
import SingleDatePickerStyled from './DatePickerStyled';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const propTypes = {
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)]),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object)]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

const defaultProps = {
  date: dateUtil.getStartOfCurrentMonth(),
  maxDate: '2050-01-01',
  minDate: '2000-01-01',
  disabled: false,
  name: '',
};

class SingleDatePicker extends Component {
  // maintain separate state for user interaction;
  constructor(props) {
    super(props);

    this.reference = {
      date: React.createRef(),
    };
  }

  handleSelect = selectedDate => {
    const { onChange, name } = this.props;
    const date = dateUtil.normalPresentor(selectedDate);

    onChange(name, date);
    // callback on date close
  };

  render() {
    const { date, maxDate, minDate, disabled } = this.props;

    const dateHeader = (
      <div className="date-input">
        <div className="moment">
          {dateUtil.presentor(date)}
          <span className="ic-date-icon" />
        </div>
      </div>
    );

    return (
      <SingleDatePickerStyled>
        <div className="datepicker-wrapper date-picker-single">
          <Menu header={dateHeader} hideOnClick disabled={disabled} ref={this.reference.date}>
            <div className="datepicker-block">
              <Calendar
                showMonthAndYearPickers
                date={dateUtil.formatter(date).toDate()}
                maxDate={dateUtil.formatter(maxDate).toDate()}
                minDate={dateUtil.formatter(minDate).toDate()}
                onChange={selectedDate => this.handleSelect(selectedDate)}
              />
            </div>
          </Menu>
        </div>
      </SingleDatePickerStyled>
    );
  }
}

SingleDatePicker.propTypes = propTypes;

SingleDatePicker.defaultProps = defaultProps;

export default SingleDatePicker;
