import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableHeader } from '../tableConfig';
import { Pagination } from '../../../../../v4/components';
import SplitPayment from '../../../../components/SplitPayment';
import { dataProps, paginationProps } from '../../../../../data/enums/Component';

const propTypes = {
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  receiveds: dataProps.value,
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
};

const defaultProps = {
  receiveds: dataProps.defaultValue,
  onPageChange: () => null,
  pagination: paginationProps.defaultValue,
};

class Received extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  render() {
    const { tableConfig, receiveds, onPageChange, pagination, permission } = this.props;
    const { dialog } = this.state;
    return (
      <Fragment>
        {dialog.type && <SplitPayment data={dialog.element} type={dialog.type} resetDialog={this.resetDialog} />}
        <div className="table-wrap">
          <table>
            {TableHeader({
              tableConfig,
            })}

            <tbody>
              {receiveds.list.map((received, key) =>
                TableBody({
                  data: received,
                  handleIconClick: this.handleIconClick,
                  tableConfig,
                  section: 'received',
                  index: key,
                  permission,
                }),
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={pagination.page}
          limit={pagination.limit}
          totalItems={receiveds.total}
          onPageChange={onPageChange}
        />
      </Fragment>
    );
  }
}

Received.propTypes = propTypes;

Received.defaultProps = defaultProps;

export default Received;
