import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const TOWN_FRAGMENT = gql`
  fragment TownFragment on Town {
    id
    title
  }
`;

const CATEGORY_SUMMARY_FRAGMENT = gql`
  fragment CategorySummaryFragment on Category {
    id
    title
  }
`;

const GEO_LOCATION_FRAGMENT = gql`
  fragment GeoLocationFragment on Location {
    latitude
    longitude
  }
`;

const OUTLET_SUMMARY_FRAGMENT = gql`
  fragment OutletSummaryFragment on RetailOutlet {
    id
    title
    geoLocation {
      ...GeoLocationFragment
    }
    Town {
      ...TownFragment
    }
    Category {
      ...CategorySummaryFragment
      Channel {
        id
        title
      }
    }
    lastCallDate
  }
  ${TOWN_FRAGMENT}
  ${CATEGORY_SUMMARY_FRAGMENT}
  ${GEO_LOCATION_FRAGMENT}
`;

const GET_OUTLETS_ARC = gql`
  query getRetailOutlet($offset: Int, $limit: Int, $filter: FilterInput) {
    retailOutlets(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...OutletSummaryFragment
      }
      count
    }
  }
  ${OUTLET_SUMMARY_FRAGMENT}
`;

const GET_INVOICE_OUTLETS = gql`
  query retailOutletDetails($filter: FilterInput) {
    retailOutletDetails(filter: $filter) {
      rows {
        id
        title
        townId
        townTitle
        channelId
        channelTitle
        categoryId
        categoryTitle
        lastTransactionDate
        distributorId
        distributorTitle
        phoneNumberVerified
        userId
        userName
        routeId
        routeTitle
        contactInfo {
          name
          phoneNumber
        }
        geoLocation {
          latitude
          longitude
        }
        lastCallDate
        callType
        unsuccessfullReason
      }
    }
  }
`;

const GET_USER_CLUSTERS = gql`
  query userClusters($filter: FilterInput) {
    userClusters(filter: $filter) {
      rows {
        id
        userId
        User {
          id
          fullName
        }
        RetailOutlet {
          id
          title
        }
        date
      }
    }
  }
`;

const SET_USER_CLUSTERS = graphql(
  gql`
    mutation ($input: UserClusterInput!) {
      createUserCluster(input: $input) {
        id
        userId
        outletId
        RetailOutlet {
          id
          title
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      setUserClusters: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_USER_CLUSTERS = graphql(
  gql`
    mutation ($input: UserClusterUpdateInput!, $id: Int!) {
      updateUserCluster(id: $id, input: $input) {
        id
        userId
        outletId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateUserClusters: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

export {
  GET_OUTLETS_ARC, GET_INVOICE_OUTLETS, GET_USER_CLUSTERS, SET_USER_CLUSTERS, UPDATE_USER_CLUSTERS
};
