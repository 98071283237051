import gql from 'graphql-tag';
import { DOWNLOAD_REPORT, TOGGLE_STATE } from '../../common/GqlAPI';

const GET_MESSAGE_LIST = gql`
  query messagesList($offset: Int, $limit: Int, $filter: FilterInput) {
    messages(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        campaign
        category
        lastSent
        sentTime
        status
        MessageDetail {
          platform
        }
      }
      count
    }
  }
`;

const query = {
  messagesList: GET_MESSAGE_LIST,
};
const mutation = {
  downloadReport: DOWNLOAD_REPORT,
  toggleState: TOGGLE_STATE,
};
export {
  query, mutation, TOGGLE_STATE, GET_MESSAGE_LIST
};
