import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  SubCamTitle,
  breadCrumb,
  CATALOG_LEVELS,
  CLASSIFICATION_LEVELS,
  formConfig,
  formMapper,
  SCOPE_LEVEL,
  classificationLevelTitle,
  subCampaignType,
  BUDGET_TYPE,
  TYPES_SUB_CAMPAIGN,
  errorMessage,
} from './config';
import { DialogFormWrapper, DialogWrapper } from '../../common';
import { PanelHeader, PanelStyled } from '../../common/configuration';
import { BreadCrumb, Icon,Button } from '../../../v4/components';
import withAlert from '../../../utils/composition/withAlert';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import history from '../../../utils/history';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import View from './View';
import Form from './Form';
import MASTER_DATA_TYPES from '../../../data/enums/MasterData';
import { isConfirmationType } from '../../common/HelperFunctions';
import Confirmation from '../../common/DialogConfirmation';
import SubCampaignStyle from './SubCampaignStyle';
import { debouncer, dropdownChange } from '../../../utils/formHandlers';
import TopForm from './TopForm';
import { CAMPAIGNS_DETAILS } from '../../../data/enums/Route';
import { refGenerator } from '../../../utils/refGenerator';
import { clone } from '../../../utils/arrayProcessor';
import { isObjectEmpty } from '../../../utils/objectPrototypes';
import { getPermissionForCampaigns } from '../../base/permission';

const propTypes = {
  hideOnClick: PropTypes.bool,
  hideOnClickOutside: PropTypes.bool,
  serverResponseWaiting: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
  toggleState: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  subCampaign: PropTypes.func.isRequired,
  getMasterData: PropTypes.func.isRequired,
  getTradeList: PropTypes.func.isRequired,
  getChannelList: PropTypes.func.isRequired,
  getCategoryList: PropTypes.func.isRequired,
  subCampaignDetails: PropTypes.func.isRequired,
  getCatalogDetailList: PropTypes.func.isRequired,
  editSubCampaignDetail: PropTypes.func.isRequired,
  createSubCampaignDetail: PropTypes.func.isRequired,
  selectedSubCampaignType: PropTypes.func.isRequired,
  getSubCampaignDetailById: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  hideOnClickOutside: true,
  hideOnClick: false,
};

class SubCampaign extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = props;
    this.state = {
      id,
      crudMode: props.match.params.id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      showBody: false,
      data: formMapper({}),
      detailData: {},
      topData: {},
      typeList: [],
      showLevel: false,
      selectedList: [],
      selectedColumn: {
        id: null,
        title: '',
      },
      columnList: {
        CATALOG: [],
        CLASSIFICATION: [],
        ids: [],
      },
      dialog: {
        type: '',
        element: '',
        cardTitle: '',
        childScope: '',
      },
      subCampaignTypeList: [],
      catalogDetailList: [],
      budgetOverflowFlag: false,
      targetOverflowFlag: false,
      cardData: [],
      selectedCardId: null,
      binId: {},
      scopeHierarcy: { 1: '', 2: '' },
      scopeFlag: false,
      addedScopeDetail: [],
      tradeList: [],
      parentValue: null,
    };
    this.permission = getPermissionForCampaigns();
    this.formReference = refGenerator(['title', 'budgetValue']);
  }

  componentDidMount() {
    const { id } = this.state;
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
    if (id) {
      this.getSubCampaign(id);
      this.getSubCampaignType();
      this.selectedSubCampaignType();
      this.getTradeList();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  handleClickOutside = event => {
    const { hideOnClickOutside, hideOnClick } = this.props;
    const dropDownEvent = event.target.id.includes('react-select');
    if (hideOnClick) {
      // this.setState({ showBody: false })
    }
    if (hideOnClickOutside && this.wrapperRef && !this.wrapperRef.contains(event.target) && !dropDownEvent) {
      this.setState({ showBody: false });
    }
  };

  setWrapperRef = node => {
    if (node) {
      this.wrapperRef = node;
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getSubCampaign = id => {
    const { subCampaign } = this.props;

    subCampaign(
      {
        id: Number(id),
      },
      {
        handleSuccess: response => {
          this.setState({
            detailData: response.data.subCampaign ? response.data.subCampaign : {},
          });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  getTradeList = (list = []) => {
    const { getTradeList } = this.props;
    const { dialog } = this.state;
    const { type } = dialog;
    getTradeList(
      {
        offset: 0,
        filter: {},
      },
      {
        handleSuccess: response => {
          const tradeDetails = (response.data.trades && response.data.trades.rows) || [];
          const scopeList = list.map(b => b.typeId);
          const filteredChannelDetails = tradeDetails.filter(a => !scopeList.includes(a.id));
          this.setState({
            catalogDetailList: type === EVENT_OPERATION.CREATE ? filteredChannelDetails : tradeDetails,
            tradeList: tradeDetails,
          });
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  getSubCampaignType = () => {
    const { getMasterData } = this.props;
    getMasterData(
      {},
      {
        handleSuccess: res => {
          const { listMasterData = [] } = res.data;
          const dataObj = {
            [MASTER_DATA_TYPES.SUB_CAMPAIGN_DETAIL_TYPES]: [],
            [MASTER_DATA_TYPES.SUB_CAMPAIGN_TYPES]: [],
          };
          if (listMasterData.length > 0) {
            listMasterData.forEach(item => {
              dataObj[item.type] = [...(item.list || [])];
            });
            this.setState({
              typeList: dataObj[MASTER_DATA_TYPES.SUB_CAMPAIGN_DETAIL_TYPES],
              subCampaignTypeList: dataObj[MASTER_DATA_TYPES.SUB_CAMPAIGN_TYPES],
            });
          }
        },
        handleError: err => {
          this.onAPIRequestFailure();
        },
      },
    );
  };

  selectedSubCampaignType = () => {
    const { columnList, id } = this.state;
    const { selectedSubCampaignType } = this.props;
    selectedSubCampaignType(
      { subCampaignId: Number(id) },
      {
        handleSuccess: res => {
          const detail = res.data.addedTypesForSubCampaignDetail || [];
          if (detail.length === 0) {
            this.setState({ scopeFlag: true });
          }
          if (detail.length > 0) {
            const parentList = detail.filter(a => a.isTypeParent === true);
            const childList = detail.filter(a => a.isTypeParent !== true);
            const type = parentList[0].type;
            this.checkScopeHierarcy(type);
            const selectedList = type === SCOPE_LEVEL.CATALOG ? CATALOG_LEVELS : CLASSIFICATION_LEVELS;
            const unselectedList = type !== SCOPE_LEVEL.CATALOG ? CATALOG_LEVELS : CLASSIFICATION_LEVELS;
            columnList.ids = [...parentList[0].typeLevel];
            columnList[type] = selectedList.filter(a => parentList[0].typeLevel.includes(a.label));
            if (detail.length > 1) {
              columnList.ids = [...parentList[0].typeLevel, ...childList[0].typeLevel];
              columnList[childList[0].type] = unselectedList.filter(a => childList[0].typeLevel.includes(a.label));
            }
            this.setState(
              {
                columnList,
                addedScopeDetail: detail,
              },
              () => {
                this.getSubCampaignDetails();
              },
            );
          }
        },
        handleError: err => {
          this.onAPIRequestFailure();
        },
      },
    );
  };

  getSubCampaignDetails = (parentId, cardTitle) => {
    const { subCampaignDetails } = this.props;
    const { id, columnList, selectedColumn, scopeHierarcy } = this.state;
    const { title } = selectedColumn;
    subCampaignDetails(
      {
        subCampaignId: Number(id),
        parentId,
        offset: 0,
        filter: {},
      },
      {
        handleSuccess: response => {
          const columnSubCampaignDetail = response.data.subCampaignDetails.rows || [];
          if (scopeHierarcy[1] === SCOPE_LEVEL.CATALOG) {
            if (columnSubCampaignDetail.length === 0 && selectedColumn.title === SCOPE_LEVEL.CATALOG) {
              columnList.CLASSIFICATION.map(a => (a.list = []));
              this.setState(columnList);
            }
          }
          if (scopeHierarcy[1] === SCOPE_LEVEL.CLASSIFICATION) {
            if (columnSubCampaignDetail.length === 0 && selectedColumn.title === SCOPE_LEVEL.CLASSIFICATION) {
              columnList.CATALOG.map(a => (a.list = []));
              this.setState(columnList);
            }
          }

          if (columnSubCampaignDetail.length > 0) {
            const columnType = columnSubCampaignDetail[0].type === title ? title : columnSubCampaignDetail[0].type;
            const index = columnList[columnType].findIndex(list => list.label === columnSubCampaignDetail[0].typeLevel);
            if (index > -1) {
              columnList[columnType][index] = { ...columnList[columnType][index], list: columnSubCampaignDetail };
              this.setState({
                columnList,
              });
            }
          }
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  addedScopeList = () => {
    const { id } = this.state;
    const { selectedSubCampaignType } = this.props;
    selectedSubCampaignType(
      { subCampaignId: Number(id) },
      {
        handleSuccess: res => {
          const detail = res.data.addedTypesForSubCampaignDetail || [];
          if (detail.length === 0) {
            this.setState({ addedScopeDetail: detail || [], scopeFlag: true });
          } else {
            this.setState({
              addedScopeDetail: detail || [],
              scopeFlag: false,
            });
          }
        },
        handleError: err => {
          this.onAPIRequestFailure();
        },
      },
    );
  };

  loadDropDownData = (catalogId, parentCatalogDetailId, cardTitle, list, parent = {}) => {
    const { getCatalogDetailList, displayAlert, getTradeList, getChannelList, getCategoryList } = this.props;
    const { dialog, tradeList } = this.state;
    const { type } = dialog;
    if (
      cardTitle !== classificationLevelTitle.TRADE &&
      cardTitle !== classificationLevelTitle.CHANNEL &&
      cardTitle !== classificationLevelTitle.CATEGORY
    ) {
      getCatalogDetailList(
        {
          offset: 0,
          catalogId,
          parentCatalogDetailId,
          filter: {
            filters: {},
            queryString: '',
          },
        },
        {
          handleSuccess: response => {
            const catalogDetails = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
            const scopeList = list.map(b => b.typeId);
            const filteredCatalogDetails = catalogDetails.filter(a => !scopeList.includes(a.id));
            this.setState({
              catalogDetailList: type === EVENT_OPERATION.CREATE ? filteredCatalogDetails : catalogDetails,
            });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
    if (cardTitle === classificationLevelTitle.TRADE) {
      this.getTradeList(list);
    }
    if (cardTitle === classificationLevelTitle.CHANNEL) {
      getChannelList(
        {
          offset: 0,
          filter: {
            filters: [
              parentCatalogDetailId
                ? {
                    column: 'trade_id',
                    value: [parentCatalogDetailId ? parentCatalogDetailId.toString() : 0],
                  }
                : {},
            ],
          },
        },
        {
          handleSuccess: response => {
            const channelDetails = (response.data.channels && response.data.channels.rows) || [];
            const scopeList = list.map(b => b.typeId);
            const filteredChannelDetails = channelDetails.filter(a => !scopeList.includes(a.id));
            this.setState({
              catalogDetailList: type === EVENT_OPERATION.CREATE ? filteredChannelDetails : channelDetails,
            });
          },
          handleError: error => this.onAPIRequestFailure(error),
        },
      );
    }
    if (cardTitle === classificationLevelTitle.CATEGORY) {
      const channelIds =
        parent.typeLevel === classificationLevelTitle.TRADE.toUpperCase()
          ? tradeList
              .filter(a => a.id === parent.typeId)
              .map(a => a.channel.map(b => b.id.toString()))
              .flat()
          : [parentCatalogDetailId && parentCatalogDetailId.toString()];
      getCategoryList(
        {
          offset: 0,
          filter: {
            filters: [
              parentCatalogDetailId
                ? {
                    column: 'channel_id',
                    value: channelIds,
                  }
                : {},
            ],
          },
        },
        {
          handleSuccess: response => {
            const categoryDetails = (response.data.categories && response.data.categories.rows) || [];
            const scopeList = list.map(b => b.typeId);
            const filteredCategoryDetails = categoryDetails.filter(a => !scopeList.includes(a.id));
            this.setState({
              catalogDetailList: type === EVENT_OPERATION.CREATE ? filteredCategoryDetails : categoryDetails,
            });
          },
          handleError: error => this.onAPIRequestFailure(error),
        },
      );
    }
  };

  getLevelValue = (id, title) => {
    const selectedList = id === 0 ? CATALOG_LEVELS : CLASSIFICATION_LEVELS;
    this.setState({ selectedList, showLevel: true, selectedColumn: { id, title } });
  };

  handleDeleteIconClick = () => {
    const { id, detailData } = this.state;
    const { toggleState, displayAlert } = this.props;
    toggleState(
      {
        ids: [Number(id)],
        type: STATEFUL_ENTITIES.SUB_CAMPAIGN,
        active: false,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `${SubCamTitle}${MESSAGE_EVENT_OPERATION.DELETE}`);
          setTimeout(() => {
            history.push(`/${CAMPAIGNS_DETAILS}/${detailData.campaignId}`);
          }, 1200);
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  handleButtonCancel = () => {
    const { id } = this.state;
    this.setState({ crudMode: EVENT_OPERATION.READ }, () => {
      this.getSubCampaign(id);
    });
  };

  handleButtonClick = () => {
    const { showBody, scopeHierarcy, columnList } = this.state;
    this.setState({ showBody: !showBody, binId: {} });
    if (scopeHierarcy[1] !== '') {
      columnList[SCOPE_LEVEL.CLASSIFICATION].map(a => (a.list = []));
      columnList[SCOPE_LEVEL.CLASSIFICATION].map(a => (a.parentList = {}));
      columnList[SCOPE_LEVEL.CATALOG].map(a => (a.list = []));
      columnList[SCOPE_LEVEL.CATALOG].map(a => (a.parentList = {}));
      this.getSubCampaignDetails();
    }
  };

  checkScopeHierarcy = scopeLabel => {
    this.setState({
      scopeHierarcy:
        scopeLabel === SCOPE_LEVEL.CATALOG
          ? { 1: SCOPE_LEVEL.CATALOG, 2: SCOPE_LEVEL.CLASSIFICATION }
          : { 1: SCOPE_LEVEL.CLASSIFICATION, 2: SCOPE_LEVEL.CATALOG },
    });
  };

  removeSpecificScope = element => {
    const { columnList } = this.state;
    columnList[element.column] = columnList[element.column].filter(a => a.id !== element.id);
    columnList.ids = columnList.ids.filter(a => a !== element.label);
    this.setState(columnList);
  };

  addSpecificScope = (label, element) => {
    const { columnList, selectedColumn } = this.state;
    const { title } = selectedColumn;
    columnList.ids.push(label);
    columnList[title].push(element);
    if (columnList[title].length > 0 || columnList.ids.length > 0) {
      columnList[title].sort((a, b) => a.id - b.id);
      columnList.ids.sort((a, b) => a - b);
    }

    this.setState({ columnList });
  };

  handleCheckBoxClick = (label, element) => {
    const { columnList, selectedColumn, scopeFlag, addedScopeDetail } = this.state;
    const { title } = selectedColumn;
    const index = columnList.ids.indexOf(label);

    if (columnList[SCOPE_LEVEL.CATALOG].length === 0 && columnList[SCOPE_LEVEL.CLASSIFICATION].length === 0) {
      this.checkScopeHierarcy(element.column);
    }
    if (index > -1) {
      if (addedScopeDetail.length > 0) {
        const typeLevel = addedScopeDetail.map(a => a.typeLevel).flat();
        !typeLevel.includes(element.label)
          ? this.removeSpecificScope(element)
          : this.removeScope(EVENT_OPERATION.REMOVE_SCOPE, element);
      } else {
        scopeFlag ? this.removeSpecificScope(element) : this.removeScope(EVENT_OPERATION.REMOVE_SCOPE, element);
      }

      if (columnList[element.column].length === 0) {
        this.checkScopeHierarcy(
          element.column === SCOPE_LEVEL.CLASSIFICATION ? SCOPE_LEVEL.CATALOG : SCOPE_LEVEL.CLASSIFICATION,
        );
      }
    }
    if (index === -1) {
      if (scopeFlag) {
        this.addSpecificScope(label, element);
      } else {
        const valid = this.addScopeCondition(element, label);
        if (valid) {
          this.addSpecificScope(label, element);
        }
      }
    }
    this.getSubCampaignDetails();
  };

  addScopeCondition = element => {
    const { columnList, selectedColumn, scopeHierarcy, addedScopeDetail } = this.state;
    const { title } = selectedColumn;
    const typeLevel = addedScopeDetail.map(a => a.typeLevel).flat();
    if (scopeHierarcy[1] === SCOPE_LEVEL.CATALOG) {
      const selectedScope = columnList[title].filter(a => element.id < a.id);
      const includedScope = selectedScope.map(a => a.label).some(v => typeLevel.includes(v));
      if (selectedScope.length && includedScope) {
        this.removeScope(EVENT_OPERATION.ADD_SCOPE, element, selectedScope);
        return false;
      }
      if (columnList.CLASSIFICATION.length > 0 && element.column === SCOPE_LEVEL.CATALOG) {
        const includeScope = columnList.CLASSIFICATION.map(a => a.label).some(v => typeLevel.includes(v));
        if (includeScope) {
          this.removeScope(EVENT_OPERATION.ADD_SCOPE, element, columnList.CLASSIFICATION);
          return false;
        }
      }
    }
    if (scopeHierarcy[1] === SCOPE_LEVEL.CLASSIFICATION) {
      const selectedScope = columnList[title].filter(a => element.id < a.id);
      const includedScope = selectedScope.map(a => a.label).some(v => typeLevel.includes(v));

      if (title === SCOPE_LEVEL.CLASSIFICATION && columnList.CATALOG.length) {
        const includeScope = columnList.CATALOG.map(a => a.label).some(v => typeLevel.includes(v));
        if (includeScope) {
          this.removeScope(
            EVENT_OPERATION.ADD_SCOPE,
            element,
            columnList.CATALOG.length > 0 ? [...selectedScope, ...columnList.CATALOG] : selectedScope,
          );
          return false;
        }
      }

      if (selectedScope.length && includedScope) {
        this.removeScope(EVENT_OPERATION.ADD_SCOPE, element, selectedScope);
        return false;
      }
    }
    return true;
  };

  checkIfparent = (cardIndex, list) => {
    if (cardIndex === 0 && isObjectEmpty(list.parentList)) {
      return true;
    }
    return false;
  };

  handleIconClick = (type, element, cardTitle, list, cardIndex) => {
    const { detailData, data, id, selectedCardId } = this.state;

    const parentCatalogId = cardIndex === 0 ? null : list.parentList ? list.parentList.typeId : selectedCardId;

    this.setState(
      {
        dialog: {
          type,
          element,
          cardTitle,
        },
        data: {
          ...data,
          campaignId: list.id,
          type: list.column,
          typeLevel: list.label,
          subCampaignId: Number(id),
          parentId: isObjectEmpty(list.parentList) ? null : list.parentList.id,
          isTypeParent: this.checkIfparent(cardIndex, list),
          startDate: detailData.startDate,
          endDate: detailData.endDate,
        },
        selectedCardId: list.typeId,
        selectedColumn: {
          id: list.column === SCOPE_LEVEL.CATALOG ? 0 : 1,
          title: list.column,
        },
        selectedList: list.column === SCOPE_LEVEL.CATALOG ? CATALOG_LEVELS : CLASSIFICATION_LEVELS,
        showLevel: true,
      },
      () => {
        this.loadDropDownData(list.id, parentCatalogId, cardTitle, list.list, list.parentList);
      },
    );
    this.getSubCampaignDetails();
  };

  handleEditClick = (type, element, cardTitle) => {
    const { detailData } = this.state;
    const topData = clone(detailData);
    this.setState({
      dialog: {
        type,
        element,
        cardTitle,
      },
      topData,
      crudMode: EVENT_OPERATION.EDIT,
    });
  };

  removeScope = (type, element, childScope) => {
    this.setState({
      dialog: {
        type,
        element,
        cardTitle: element.title,
        childScope,
      },
    });
  };

  handleColumnDetailsCardClick = (list, cardTitle) => {
    const { data, columnList, binId, scopeHierarcy } = this.state;
    const index = columnList[list.type].findIndex(a => a.label === cardTitle);
    const lastElement = columnList[list.type].length - 1;

    if (scopeHierarcy[1] === SCOPE_LEVEL.CATALOG) {
      if (index > -1) {
        if (index + 1 < columnList[list.type].length) {
          columnList[list.type][index + 1] = { ...columnList[list.type][index + 1], list: [] };
        }
        if (list.type !== SCOPE_LEVEL.CLASSIFICATION && columnList[SCOPE_LEVEL.CLASSIFICATION].length) {
          columnList[SCOPE_LEVEL.CLASSIFICATION].map(a => (a.list = []));
          columnList[SCOPE_LEVEL.CLASSIFICATION].map(a => (a.parentList = {}));
          this.setState({ columnList });
        }
        let i = index + 1;
        for (i; i <= lastElement; i += 1) {
          columnList[list.type][i] = { ...columnList[list.type][i], list: [], parentList: {} };
        }
        if (columnList[SCOPE_LEVEL.CLASSIFICATION].length) {
          if (index === lastElement && list.typeLevel) {
            columnList[SCOPE_LEVEL.CLASSIFICATION][0] = {
              ...columnList[SCOPE_LEVEL.CLASSIFICATION][0],
              parentList: list,
            };
          }
        }
        if (index !== lastElement) {
          columnList[list.type][index + 1] = { ...columnList[list.type][index + 1], parentList: list };
        }
      }
    }
    if (scopeHierarcy[1] === SCOPE_LEVEL.CLASSIFICATION) {
      if (index > -1) {
        if (index + 1 < columnList[list.type].length) {
          columnList[list.type][index + 1] = { ...columnList[list.type][index + 1], list: [] };
        }
        if (list.type !== SCOPE_LEVEL.CATALOG && columnList[SCOPE_LEVEL.CATALOG].length) {
          columnList[SCOPE_LEVEL.CATALOG].map(a => (a.list = []));
          columnList[SCOPE_LEVEL.CATALOG].map(a => (a.parentList = {}));
          this.setState({ columnList });
        }
        let i = index + 1;
        for (i; i <= lastElement; i += 1) {
          columnList[list.type][i] = { ...columnList[list.type][i], list: [], parentList: {} };
        }
        if (columnList[SCOPE_LEVEL.CATALOG].length) {
          if (index === lastElement && list.typeLevel) {
            columnList[SCOPE_LEVEL.CATALOG][0] = { ...columnList[SCOPE_LEVEL.CATALOG][0], parentList: list };
          }
        }
        if (index !== lastElement) {
          columnList[list.type][index + 1] = { ...columnList[list.type][index + 1], parentList: list };
        }
      }
    }
    const obj = Object.fromEntries([[list.typeLevel, list.id]]);
    this.setState(
      {
        data: {
          ...data,
          parentId: list.id,
        },
        selectedCardId: list.typeId,
        selectedColumn: {
          id: list.type === SCOPE_LEVEL.CATALOG ? 0 : 1,
          title: list.type,
        },
        selectedList: list.type === SCOPE_LEVEL.CATALOG ? CATALOG_LEVELS : CLASSIFICATION_LEVELS,
      },
      () => {
        this.updatedBinId({ ...binId, ...obj }, list.typeLevel);
        this.getSubCampaignDetails(list.id, cardTitle);
      },
    );
  };

  updatedBinId = (bin, typeLevel) => {
    let a = 0;
    let b = false;
    const i = Object.keys(bin).length;
    const newBin = { ...bin };
    for (a; a < i; a += 1) {
      const element = Object.keys(bin)[a];
      if (b === true) {
        delete newBin[element];
      }
      if (Object.keys(bin)[a] === typeLevel) {
        b = true;
      }
    }
    this.setState({ binId: newBin });
  };

  resetDialog = () => {
    const {
      id,
      dialog: { type },
    } = this.state;
    if (type === EVENT_OPERATION.EDIT) {
      this.getSubCampaign(id);
    }
    this.setState({
      dialog: {
        type: '',
        element: '',
        cardTitle: '',
      },
      data: formMapper({}),
    });
  };

  getTotalValue = (list, listLable, typeLabel, type, isParent) => {
    const getTotal = list.reduce((acc, v) => {
      const lineSum = v[listLable].reduce((s, i) => {
        if (isParent === 'parent') {
          if (i[type] === typeLabel) {
            const parentValue = i.calculatedValue;
            this.setState({ parentValue });
          }
        }
        if (i[type] === typeLabel) {
          if (i.dimension === 'PERCENT') {
            s += i.calculatedValue;
          } else {
            s += i.value;
          }
        }
        return s;
      }, 0);
      return acc + lineSum;
    }, 0);
    return getTotal;
  };

  getdataTotalValueandUpdatedList = (list, listLable, typeLabel, type, dimensionType) => {
    const updatedList = [...list];
    const { parentValue } = this.state;
    const getTotal = list.reduce((acc, v, pi) => {
      const lineSum = v[listLable].reduce((s, i, index) => {
        if (i[type] === typeLabel) {
          if (dimensionType === 'PERCENT') {
            updatedList[pi][listLable][index].calculatedValue = (i.value / 100) * parentValue;
            s += (i.value / 100) * parentValue;
          } else {
            s += i.value;
          }
        }
        return s;
      }, 0);
      return acc + lineSum;
    }, 0);

    return { getTotal, updatedList };
  };

  validation = (columnList, data, detailData, dimensionType, budgetType) => {
    const { displayAlert } = this.props;
    const {
      dialog: { type },
    } = this.state;
    const filteredList = columnList[data.type].filter(a => a.label === data.typeLevel);
    const silblingList = filteredList.map(a => a.list).flat();
    const parentList = filteredList.map(a => a.parentList).flat();
    const filteredParentList = isObjectEmpty(parentList[0]) ? [detailData] : parentList;
    if (budgetType === BUDGET_TYPE.LINE_DISCOUNT) {
      const siblingLineDiscount = this.getTotalValue(
        silblingList,
        TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET_DETAIL,
        BUDGET_TYPE.LINE_DISCOUNT,
        subCampaignType.budget.type,
      );

      const parentLineDiscount = this.getTotalValue(
        filteredParentList,
        isObjectEmpty(parentList[0])
          ? TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET
          : TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET_DETAIL,
        BUDGET_TYPE.LINE_DISCOUNT,
        subCampaignType.budget.type,
        'parent',
      );

      const { getTotal: dataLineDiscount, updatedList: lineData } = this.getdataTotalValueandUpdatedList(
        [data],
        TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET,
        BUDGET_TYPE.LINE_DISCOUNT,
        subCampaignType.budget.type,
        dimensionType,
      );
      this.setState({ data: lineData.length && lineData[0] });

      const remainingLineDiscount = parentLineDiscount - siblingLineDiscount;

      if (type === EVENT_OPERATION.UPDATE) {
        const editSiblingList = silblingList.filter(a => a.id !== data.id);
        const editLineDiscount = this.getTotalValue(
          editSiblingList,
          TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET_DETAIL,
          BUDGET_TYPE.LINE_DISCOUNT,
          subCampaignType.budget.type,
        );

        const editSiblingLineDiscount = parentLineDiscount - editLineDiscount;

        if (dataLineDiscount > editSiblingLineDiscount) {
          displayAlert(
            ALERT_TYPE.CUSTOM_DANGER,
            `${BUDGET_TYPE.LINE_DISCOUNT} amount must not exceed ${editSiblingLineDiscount}`,
          );
        }
      } else if (siblingLineDiscount + dataLineDiscount > parentLineDiscount) {
        displayAlert(
          ALERT_TYPE.CUSTOM_DANGER,
          `${BUDGET_TYPE.LINE_DISCOUNT} amount must not exceed ${remainingLineDiscount}`,
        );
      }
    } else {
      const siblingBillDiscount = this.getTotalValue(
        silblingList,
        TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET_DETAIL,
        BUDGET_TYPE.BILL_DISCOUNT,
        subCampaignType.budget.type,
      );

      const parentBillDiscount = this.getTotalValue(
        filteredParentList,
        isObjectEmpty(parentList[0])
          ? TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET
          : TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET_DETAIL,
        BUDGET_TYPE.BILL_DISCOUNT,
        subCampaignType.budget.type,
        'parent',
      );

      const { getTotal: dataBillDiscount, updatedList: billData } = this.getdataTotalValueandUpdatedList(
        [data],
        TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET,
        BUDGET_TYPE.BILL_DISCOUNT,
        subCampaignType.budget.type,
        dimensionType,
      );
      this.setState({ data: billData.length && billData[0] });

      const remainingBillDiscount = parentBillDiscount - siblingBillDiscount;

      if (type === EVENT_OPERATION.UPDATE) {
        const editSiblingList = silblingList.filter(a => a.id !== data.id);
        const editBillDiscount = this.getTotalValue(
          editSiblingList,
          TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET_DETAIL,
          BUDGET_TYPE.BILL_DISCOUNT,
          subCampaignType.budget.type,
        );

        const editSiblingBillDiscount = parentBillDiscount - editBillDiscount;

        if (dataBillDiscount > editSiblingBillDiscount) {
          displayAlert(
            ALERT_TYPE.CUSTOM_DANGER,
            `${BUDGET_TYPE.BILL_DISCOUNT} amount must not exceed ${editSiblingBillDiscount}`,
          );
        }
      } else if (siblingBillDiscount + dataBillDiscount > parentBillDiscount) {
        displayAlert(
          ALERT_TYPE.CUSTOM_DANGER,
          `${BUDGET_TYPE.BILL_DISCOUNT} amount must not exceed ${remainingBillDiscount}`,
        );
      }
    }
  };

  checkForValidation = (newdata, label, dimensionType) => {
    const { topData, columnList, scopeHierarcy } = this.state;
    const subCampaignBillDiscount = this.getTotalValue(
      [topData],
      TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET,
      BUDGET_TYPE.BILL_DISCOUNT,
      subCampaignType.budget.type,
    );
    const subCampaignLineDiscount = this.getTotalValue(
      [topData],
      TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET,
      BUDGET_TYPE.LINE_DISCOUNT,
      subCampaignType.budget.type,
    );
    const budget = topData.campaign.unAllocated + subCampaignBillDiscount + subCampaignLineDiscount;
    const totalBudget = topData.campaign.unAllocated + budget || 0;
    const parentValue = newdata.campaign.budget;
    this.setState({ parentValue });
    if (scopeHierarcy[1] !== '') {
      const level = scopeHierarcy[1] === SCOPE_LEVEL.CATALOG ? SCOPE_LEVEL.CATALOG : SCOPE_LEVEL.CLASSIFICATION;

      this.childValidation(newdata, columnList[level][0].list, dimensionType);
    }
    const sum = newdata[label].map(a => a.value).reduce((acc, cur) => acc + cur, 0);

    if (sum > totalBudget) {
      this.setState({ budgetOverflowFlag: true });
    } else {
      this.setState({ budgetOverflowFlag: false });
    }
  };

  childValidation = (data, list, dimensionType) => {
    const { displayAlert } = this.props;
    const childBillDiscount = this.getTotalValue(
      list,
      TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET_DETAIL,
      BUDGET_TYPE.BILL_DISCOUNT,
      subCampaignType.budget.type,
    );
    const childLineDiscount = this.getTotalValue(
      list,
      TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET_DETAIL,
      BUDGET_TYPE.LINE_DISCOUNT,
      subCampaignType.budget.type,
    );

    const { getTotal: dataBillDiscount, updatedList: lineData } = this.getdataTotalValueandUpdatedList(
      [data],
      TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET,
      BUDGET_TYPE.BILL_DISCOUNT,
      subCampaignType.budget.type,
      dimensionType,
    );
    this.setState({ data: lineData.length && lineData[0] });

    const { getTotal: dataLineDiscount, updatedList: billData } = this.getdataTotalValueandUpdatedList(
      [data],
      TYPES_SUB_CAMPAIGN.SUB_CAMPAIGN_BUDGET,
      BUDGET_TYPE.LINE_DISCOUNT,
      subCampaignType.budget.type,
      dimensionType,
    );
    this.setState({ data: billData.length && billData[0] });

    if (dataBillDiscount < childBillDiscount) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, errorMessage.childMessage);
    }
    if (dataLineDiscount < childLineDiscount) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, errorMessage.childMessage);
    }
  };

  scopeManupulation = (element, childScope) => {
    const { columnList, scopeHierarcy, selectedColumn } = this.state;
    const { title } = selectedColumn;

    columnList[title] = columnList[title].filter(ar => !childScope.find(rm => rm.id === ar.id));
    const label = childScope.map(a => a.label);
    columnList.ids = columnList.ids.filter(a => !label.includes(a));
    if (scopeHierarcy[1] === SCOPE_LEVEL.CATALOG) {
      if (title === SCOPE_LEVEL.CATALOG && columnList.CLASSIFICATION.length) {
        columnList.CLASSIFICATION = [];
      }
      columnList[title].push(element);
      columnList.ids.push(element.label);
    }
    if (scopeHierarcy[1] === SCOPE_LEVEL.CLASSIFICATION) {
      columnList[title].push(element);
      columnList.ids.push(element.label);
      if (title === SCOPE_LEVEL.CLASSIFICATION && columnList.CATALOG.length) {
        columnList.CATALOG = [];
      }
    }
    this.setState(columnList);
  };

  handleFormSubmit = () => {
    const { data, id, detailData, dialog, columnList } = this.state;

    const {
      createSubCampaignDetail,
      editSubCampaignDetail,
      toggleState,
      displayAlert,
      editSubCampaign,
      removeSubCampaignScope,
    } = this.props;
    const { type, cardTitle, element } = dialog;

    if (type === EVENT_OPERATION.ADD_SCOPE) {
      removeSubCampaignScope(
        {
          subCampaignId: Number(id),
          typeLevel: dialog.childScope[0].label,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${element.title} ${MESSAGE_EVENT_OPERATION.ADD}`);
            this.scopeManupulation(element, dialog.childScope);
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    }

    if (type === EVENT_OPERATION.EDIT) {
      editSubCampaign(
        {
          input: {
            id: detailData.id,
            title: detailData.title,
            subCampaignType: detailData.subCampaignType,
            startDate: detailData.startDate,
            endDate: detailData.endDate,
            subCampaignBudget: detailData.subCampaignBudget.map(a => {
              delete a.subCampaignBudgetId;
              delete a.calculatedValue;
              return a;
            }),
            subCampaignTarget: detailData.subCampaignTarget.map(a => {
              delete a.subCampaignTargetId;
              return a;
            }),
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${SubCamTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            this.getSubCampaign(id);
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    }
    if (type === EVENT_OPERATION.CREATE) {
      createSubCampaignDetail(
        {
          input: {
            campaignId: detailData.campaignId,
            subCampaignId: data.subCampaignId,
            type: data.type,
            typeLevel: data.typeLevel,
            isTypeParent: data.isTypeParent,
            typeId: data.typeId,
            parentId: data.parentId,
            subCampaignBudgetDetail: data.subCampaignBudget.map(a => {
              delete a.calculatedValue;
              return a;
            }),
            subCampaignTargetDetail: data.subCampaignTarget,
          },
        },
        {
          handleSuccess: response => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.CREATE}`);
            this.getSubCampaignDetails(response.data.createSubCampaignDetail.parentId);
            this.addedScopeList();
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    }
    if (type === EVENT_OPERATION.UPDATE) {
      editSubCampaignDetail(
        {
          input: {
            id: element.id,
            campaignId: detailData.campaignId,
            subCampaignId: Number(id),
            type: data.type,
            typeLevel: data.typeLevel,
            isTypeParent: data.isTypeParent,
            typeId: data.typeId,
            subCampaignBudgetDetail: data.subCampaignBudget.map(a => {
              delete a.calculatedValue;
              return a;
            }),
            subCampaignTargetDetail: data.subCampaignTarget,
            parentId: element.parentId,
          },
        },
        {
          handleSuccess: response => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            this.getSubCampaignDetails(response.data.editSubCampaignDetail.parentId);
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
    if (type === EVENT_OPERATION.DELETE) {
      toggleState(
        {
          ids: [element.id],
          type: STATEFUL_ENTITIES.SUB_CAMPAIGN_DETAIL,
          active: false,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`);
            this.clearData(element, element.typeLevel);
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
    if (type === EVENT_OPERATION.REMOVE_SCOPE) {
      removeSubCampaignScope(
        {
          subCampaignId: Number(id),
          typeLevel: element.label,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.REMOVE}`);
            columnList.CATALOG = [];
            columnList.CLASSIFICATION = [];
            columnList.ids = [];
            this.setState(columnList);
            this.selectedSubCampaignType();
            this.addedScopeList();
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    }
  };

  clearData = (list, cardTitle) => {
    const { columnList, scopeHierarcy } = this.state;
    const index = columnList[list.type].findIndex(a => a.label === cardTitle);
    const lastElement = columnList[list.type].length - 1;
    if (index > -1) {
      columnList[list.type][index].list = columnList[list.type][index].list.filter(item => item.typeId !== list.typeId);
      let i = index + 1;
      for (i; i <= lastElement; i += 1) {
        columnList[list.type][i] = { ...columnList[list.type][i], list: [], parentList: {} };
      }
      if (scopeHierarcy[1] === SCOPE_LEVEL.CATALOG) {
        if (list.type === SCOPE_LEVEL.CATALOG) {
          columnList.CLASSIFICATION.map(a => (a.list = []));
          columnList.CLASSIFICATION.map(a => (a.parentList = {}));
        }
      }
      if (scopeHierarcy[1] === SCOPE_LEVEL.CLASSIFICATION) {
        if (list.type === SCOPE_LEVEL.CLASSIFICATION) {
          columnList.CATALOG.map(a => (a.list = []));
          columnList.CATALOG.map(a => (a.parentList = {}));
        }
      }
      this.setState({ columnList });
    }
  };

  handleAdd = (title, data) => {
    if (title === subCampaignType.budget.name) {
      const newData = {
        ...data,
        subCampaignBudget: [...data.subCampaignBudget, { budgetType: null, dimension: 'AMOUNT', value: null }],
      };
      data.subCampaignBudget = newData.subCampaignBudget;
    } else if (title === subCampaignType.target.name) {
      const newData = {
        ...data,
        subCampaignTarget: [...data.subCampaignTarget, { targetType: null, dimension: 'VALUE', value: null }],
      };
      data.subCampaignTarget = newData.subCampaignTarget;
    }
    this.setState({ data });
  };

  handleCancel = (name, label, data) => {
    const { subCampaignBudget, subCampaignTarget } = data;
    if (label === 'budgetType') {
      const filterData = [...subCampaignBudget].filter(d => d.budgetType !== name);
      const newData = {
        ...data,
        ...{
          subCampaignBudget: filterData,
        },
      };
      data.subCampaignBudget = newData.subCampaignBudget;
      this.setState({ data });
    } else if (label === 'targetType') {
      const filterData = [...subCampaignTarget].filter(d => d.targetType !== name);
      const newData = {
        ...data,
        ...{
          subCampaignTarget: filterData,
        },
      };
      data.subCampaignTarget = newData.subCampaignTarget;
      this.setState({ data });
    }
  };

  onHandleInputChange = (data, event, firstParam = '', paramList = []) => {
    const {
      columnList,
      detailData,
      dialog: { type },
    } = this.state;
    const budgetValueTypeStatus = data[paramList[0]][paramList[1]].dimension === 'PERCENT';
    if (budgetValueTypeStatus) {
      const formattedValueModified = event.formattedValue <= 100 ? Number(event.formattedValue) : '';
      data[paramList[0]][paramList[1]].value = formattedValueModified;
    } else {
      data[paramList[0]][paramList[1]].value = Number(event.formattedValue);
    }
    this.setState({ data }, () =>
      type !== EVENT_OPERATION.EDIT
        ? firstParam === 'subCampaignBudget'
          ? debouncer(this.validation, 500)(
              columnList,
              data,
              detailData,
              budgetValueTypeStatus ? 'PERCENT' : 'AMOUNT',
              data[paramList[0]][paramList[1]].budgetType,
            )
          : null
        : debouncer(this.checkForValidation, 500)(data, firstParam, budgetValueTypeStatus ? 'PERCENT' : 'AMOUNT'),
    );
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data, detailData, dialog } = this.state;
    const { type } = dialog;
    if (type === EVENT_OPERATION.EDIT) {
      detailData[event.target.name] = event.formattedValue;
      this.setState({ detailData });
    } else {
      if (firstParam) {
        if (paramList.length > 1) {
          paramList.reduce((acc, value, index, list) => {
            if (index === list.length - 1) {
              return (acc[value] = event.formattedValue);
            }
            return acc[value];
          }, data);
        } else {
          data[firstParam][event.target.name] = event.formattedValue;
        }
      } else {
        data[event.target.name] = event.formattedValue;
      }
      this.setState({ data });
    }
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const {
      dialog: { type },
    } = this.state;
    if (type === EVENT_OPERATION.EDIT) {
      const { detailData } = this.state;
      const updatedData = dropdownChange(detailData, parameterRef, value);
      this.setState({ detailData: updatedData });
    } else {
      const { data, detailData } = this.state;
      const updatedData = dropdownChange(data, parameterRef, value);
      if (parameterRef[0] !== 'typeId') {
        const selectArray = detailData[parameterRef[0]].filter(a => a[parameterRef[2]] === value);
        const id = parameterRef[0] === 'subCampaignBudget' ? 'subCampaignBudgetId' : 'subCampaignTargetId';
        updatedData[parameterRef[0]][parameterRef[1]][id] = selectArray[0][id];
        if (value !== 'COVERAGE') {
          updatedData[parameterRef[0]][parameterRef[1]].dimension = selectArray[0].dimension;
        }
      }
      this.setState({ data: updatedData });
    }
  };

  handleDimensionChange = (data, value, parameterRef = []) => {
    const updatedDetails = dropdownChange(data, parameterRef, value);
    data[parameterRef[0]] = updatedDetails[parameterRef[0]];
    this.setState({ data: updatedDetails }, () => {
      this.resetSubCampaignAmount(data, parameterRef);
    });
  };

  resetSubCampaignAmount = (data, parameterRef = []) => {
    data[parameterRef[0]][parameterRef[1]].value = null;
    this.setState({ data });
  };

  getparentCatalogDetailId = (cardTitle, element) => {
    switch (cardTitle) {
      case classificationLevelTitle.CHANNEL:
        return element.typeDetail.trade_id;
      case classificationLevelTitle.CATEGORY:
        return element.typeDetail.channel_id;
      default:
        return element.typeId;
    }
  };

  handleActionClick = (type, element, cardTitle, list) => {
    const { scopeFlag, addedScopeDetail } = this.state;
    if (type === EVENT_OPERATION.REMOVE_SCOPE) {
      if (scopeFlag) {
        this.removeSpecificScope(element);
      }
      if (addedScopeDetail.length > 0) {
        const typeLevel = addedScopeDetail.map(a => a.typeLevel).flat();
        !typeLevel.includes(element.label)
          ? this.removeSpecificScope(element)
          : this.removeScope(EVENT_OPERATION.REMOVE_SCOPE, element);
      }
    } else {
      this.setState({
        dialog: {
          type,
          element,
          cardTitle,
        },
      });
      const parentId =
        element.type === SCOPE_LEVEL.CATALOG ? CATALOG_LEVELS.filter(a => a.label === element.typeLevel)[0].id : null;
      if (type !== EVENT_OPERATION.REMOVE_SCOPE) {
        this.loadDropDownData(parentId, this.getparentCatalogDetailId(cardTitle, element), cardTitle, list);
        this.getSubCampaignDetailById(list[0].id);
      }
    }
  };

  getSubCampaignDetailById = id => {
    const { getSubCampaignDetailById } = this.props;
    getSubCampaignDetailById(
      { id },
      {
        handleSuccess: response => {
          const responseData = response.data.getSubCampaignDetailById || {};
          this.setState({
            data: responseData,
          });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  render() {
    const {
      typeList,
      showBody,
      showLevel,
      selectedList,
      selectedColumn,
      columnList,
      dialog,
      budgetOverflowFlag,
      targetOverflowFlag,
      subCampaignTypeList,
      detailData,
      data,
      catalogDetailList,
      cardData,
      binId,
      scopeHierarcy,
      topData,
    } = this.state;
    const { type, cardTitle, element } = dialog;
    const { serverResponseWaiting } = this.props;

    return (
      <SubCampaignStyle>
        {type && (
          <DialogFormWrapper
            formTitle={cardTitle}
            type={type}
            dialogElement={element}
            formConfig={formConfig[type]}
            onDialogCancel={this.resetDialog}
            onDialogSubmit={this.handleFormSubmit}
            refsObj={this.formReference}
            withOutPadding={
              type !== EVENT_OPERATION.DELETE &&
              type !== EVENT_OPERATION.REMOVE_SCOPE &&
              type !== EVENT_OPERATION.ADD_SCOPE
            }
            renderDialog={({ refsObj, enableErrorDisplay }) => (
              <>
                {type !== EVENT_OPERATION.DELETE &&
                  type !== EVENT_OPERATION.EDIT &&
                  type !== EVENT_OPERATION.REMOVE_SCOPE &&
                  type !== EVENT_OPERATION.ADD_SCOPE && (
                    <Form
                      show
                      refsObj={refsObj}
                      data={data}
                      loading={serverResponseWaiting}
                      handleInputChange={this.handleInputChange}
                      onHandleInputChange={this.onHandleInputChange}
                      handleDropDownChange={this.handleDropDownChange}
                      type={type}
                      detailData={detailData}
                      cardTitle={cardTitle}
                      subCampaignTypeList={subCampaignTypeList}
                      catalogDetailList={catalogDetailList}
                      handleCancel={this.handleCancel}
                      handleAdd={this.handleAdd}
                      handleTargetCancel={this.handleTargetCancel}
                      budgetOverflowFlag={budgetOverflowFlag}
                      targetOverflowFlag={targetOverflowFlag}
                      enableErrorDisplay={enableErrorDisplay}
                      columnList={columnList}
                      campaignDataObj={data}
                      handleDimensionChange={this.handleDimensionChange}
                    />
                  )}
                {type === EVENT_OPERATION.DELETE && isConfirmationType(type) && Confirmation(type)}
                {type === EVENT_OPERATION.EDIT && (
                  <TopForm
                    refsObj={refsObj}
                    data={detailData}
                    loading={serverResponseWaiting}
                    handleInputChange={this.handleInputChange}
                    onHandleInputChange={this.onHandleInputChange}
                    handleDropDownChange={this.handleDropDownChange}
                    type={type}
                    subCampaignTypeList={subCampaignTypeList}
                    handleCancel={this.handleCancel}
                    handleAdd={this.handleAdd}
                    handleTargetCancel={this.handleTargetCancel}
                    budgetOverflowFlag={budgetOverflowFlag}
                    targetOverflowFlag={targetOverflowFlag}
                    enableErrorDisplay={enableErrorDisplay}
                    campaignDataObj={detailData.campaign}
                    topData={topData}
                    handleDimensionChange={this.handleDimensionChange}
                  />
                )}
                {type === EVENT_OPERATION.REMOVE_SCOPE &&
                  `Are you sure you want to remove ${cardTitle}?  
                Removing this scope will also remove the scope and delete child's data.`}
                {type === EVENT_OPERATION.ADD_SCOPE &&
                  `Are you sure you want to add ${cardTitle}?  
                Adding this scope will also remove child's scope and its data.`}
              </>
            )}
          />
        )}
        <DialogWrapper
          onDialogSubmit={this.handleDeleteIconClick}
          render={({ onDialogItemClick }) => (
            <>
              <div className="section-header">
                <PanelStyled>
                  <BreadCrumb list={breadCrumb} />
                  <PanelHeader>
                    <h2>{detailData.title}</h2>
                    <div className="flex m-0">
                      {
                        <>
                          {
                            <div>
                              {this.permission.delete&&<Button secondary iconBtnSmall onClick={() => onDialogItemClick(EVENT_OPERATION.DELETE)}>
                                <Icon iconName="trash" />
                              </Button>}
                              {this.permission.update&&<Button
                                secondary
                                iconBtnSmall
                                onClick={() => this.handleEditClick(EVENT_OPERATION.EDIT, {})}
                              >
                                <Icon iconName="pencil" />
                              </Button>}
                            </div>
                          }
                        </>
                      }
                    </div>
                  </PanelHeader>
                </PanelStyled>
              </div>
              <div className="section-content">
                <div className="create-ui">
                  <div className="create-ui-inner tab-less-height">
                    <View
                      binId={binId}
                      data={detailData}
                      cardData={cardData}
                      typeList={typeList}
                      showLevel={showLevel}
                      selectedColumn={selectedColumn}
                      columnList={columnList}
                      selectedList={selectedList}
                      dropdownToggle={showBody}
                      setWrapperRef={this.setWrapperRef}
                      getLevelValue={this.getLevelValue}
                      onIconClick={this.handleIconClick}
                      onButtonClick={this.handleButtonClick}
                      onCheckboxChange={this.handleCheckBoxClick}
                      onColumnDetailsCardClick={this.handleColumnDetailsCardClick}
                      onActionClick={this.handleActionClick}
                      scopeHierarcy={scopeHierarcy}
                      permission={this.permission}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        />
      </SubCampaignStyle>
    );
  }
}

SubCampaign.propTypes = propTypes;
SubCampaign.defaultProps = defaultProps;

export default withAlert()(SubCampaign);
