import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import SITUploadForm from '../../common/CSVUploadForm';
import { Icon } from '../../../v4/components';

const propTypes = {
  type: PropTypes.string.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  dialogData: PropTypes.objectOf(Object),
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.objectOf(Object),
};

const defaultProps = {
  dialogData: {},
  enableErrorDisplay: false,
  refsObj: {},
};

const DialogEntities = (props) => {
  const {
    type, dialogData,  handleFileUpload, enableErrorDisplay,
    refsObj, onDownloadClick,
  } = props;
  return (
    <Fragment>
      {
        type === EVENT_OPERATION.UPLOAD && (
          <>
            <SITUploadForm
              show
              data={dialogData}
              handleFileUpload={handleFileUpload}
              enableErrorDisplay={enableErrorDisplay}
              refsObj={refsObj}
            />
            <a onClick={onDownloadClick}>
              <span className='sample-csv'>
                <Icon iconName='download' />
                Sample CSV
              </span>
            </a>
          </>
        )
      }
      {
        type === EVENT_OPERATION.REQUEST_ORDER && (
          'Are you sure you want to Request Order?'
        )
      }
    </Fragment>
  );
};

DialogEntities.propTypes = propTypes;

DialogEntities.defaultProps = defaultProps;

export default DialogEntities;
