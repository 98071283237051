import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import Thumbnail from '../../../../components/ImageViewer/Thumbnail';
import { normalPresentor } from '../../../../utils/date';

const tableHeaderConfig = [
  { title: 'S.No.' },
  { title: 'SKU' },
  { title: 'Batch' },
  { title: 'Stock Type' },
  {
    title: 'MFG Date',
    classNames: 'text-center',
  },
  {
    title: 'EXP Date',
    classNames: 'text-center',
  },
  {
    title: 'Quantity',
    classNames: 'text-right',
  },
  {
    title: 'Rate',
    classNames: 'text-right',
  },
  {
    title: 'Amount',
    classNames: 'text-right',
  },
  {
    title: 'Discount',
    classNames: 'text-right',
  },
  {
    title: 'Net Amount',
    classNames: 'text-right',
  },
  // { title: 'Images' },
];

const propTypes = {
  handleSliderOpen: PropTypes.func.isRequired,
  skuLines: PropTypes.arrayOf(Object),
};

const defaultProps = {
  skuLines: [],
};

const TableView = ({ skuLines, handleSliderOpen }) => (
  <Fragment>
    <div className='table-wrap srn-details'>
      <table>
        <thead>
          <tr>
            {tableHeaderConfig.map((header, key) => (
              <th key={key} className={header.classNames || ''}>
                {header.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {skuLines.map((order, index) => (
            <tr key={order.id || index}>
              <td>{index + 1}</td>
              <td>{order.SKU.title}</td>
              <td>{order.SKUBatch ? order.SKUBatch.batchName : ''}</td>
              <td>{order.stockType}</td>
              <td className='text-center'>
                {order.SKUBatch
                  ? order.SKUBatch.manufactureDate
                    ? normalPresentor(order.SKUBatch.manufactureDate) === 'Invalid date'
                      ? '-'
                      : normalPresentor(order.SKUBatch.manufactureDate)
                    : null
                  : null}
              </td>
              <td className='text-center'>
                {order.SKUBatch
                  ? order.SKUBatch.expiryDate
                    ? normalPresentor(order.SKUBatch.expiryDate) === 'Invalid date'
                      ? '-'
                      : normalPresentor(order.SKUBatch.expiryDate)
                    : null
                  : null}
              </td>
              <td className='text-right'>{order.quantity}</td>
              <td className='text-right'>{order.amountDetails ? order.amountDetails.rate : 0}</td>
              <td className='text-right'>
                {fixedFloatAndCommas(order.amountDetails ? order.amountDetails.rate * order.quantity : 0)}
              </td>
              <td className='text-right'>
                {fixedFloatAndCommas(
                  order.amountDetails
                    ? order.amountDetails.promotionDiscount + (order.amountDetails.topUpDiscount || 0)
                    : 0,
                )}
              </td>
              <td className='text-right'>
                {fixedFloatAndCommas(order.amountDetails ? order.amountDetails.subTotal : 0)}
              </td>
              {order.images && order.images.length > 0 && (
                <td>
                  {
                    <span style={{ display: 'flex' }}>
                      {order.images.map((image, index, array) => {
                        if (index === 0) {
                          return (
                            <span
                              onClick={() => {
                                handleSliderOpen(index, array);
                              }}
                            >
                              <Thumbnail image={image.thumbnail} />
                            </span>
                          );
                        }
                        if (index === 1) {
                          return (
                            <span className='relative'>
                              <span
                                className='thumbnail-overlay'
                                onClick={() => {
                                  handleSliderOpen(index, array);
                                }}
                              >
                                {array.length - 1}
                              </span>
                              <Thumbnail image={image.thumbnail} />
                            </span>
                          );
                        }
                        return null;
                      })}
                    </span>
                  }
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Fragment>
);

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithImageSlider = withImageSlider(TableView);
export default TableViewWithImageSlider;
