import styled from 'styled-components';

const PendingStyled = styled.div`
  .table-wrap {
    tbody {
      tr {
        cursor: pointer;
      }
    }
    td {
      .light {
        min-width: 80px;
        padding: 3px 4px;
        text-align: center;
        line-height: 10px;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;
        border-radius: 2px;
      }
      .pending {
        border: 0;
        padding: 0;
        .light {
          border: 1px solid rgba(218, 20, 20, 0.4);
          color: #da1414;
        }
      }
      .approved {
        .light {
          border: 1px solid #47c464;
          color: #287d3c;
        }
      }
    }
  }
`;

export default PendingStyled;
