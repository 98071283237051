import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import {
  UPDATE_ROLE,GET_ROLE_LIST,CREATE_ROLE
} from '../API';

const FETCH_PERMISSION  = gql`
  query fetchPermission($roleId: Int!) {
    fetchPermission(roleId: $roleId) {
      module
      child{
        feature
        program
        program_alias
        operations{
          menu 
          read
          create
          delete
          update
        }
      }
    }
  }
`;

const UPDATE_PERMISSION = graphql(
  gql`
  mutation UpdatePermission($roleId: Int!, $input: PermissionUpdateInput!) {
    updatePermission(roleId: $roleId, input: $input) {
      module
      feature
      program
      program_alias
      operations {
        menu
        read
        create
        delete
        update
      }
    }
  }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updatePermission: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  fetchPermission:FETCH_PERMISSION,
  getUserRoleList:GET_ROLE_LIST,
};
const mutation = {
  updatePermission:UPDATE_PERMISSION,
  updateRole:UPDATE_ROLE,
  createRole:CREATE_ROLE,
};

export {
  query, mutation,FETCH_PERMISSION,UPDATE_PERMISSION,UPDATE_ROLE,CREATE_ROLE,
};
