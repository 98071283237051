import { compose } from 'recompose';
import Create from './Create';
import {
  query, mutation, CREATE_PAYMENT
} from '../API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedReceipt = compose(CREATE_PAYMENT, withRequestTracker({ query, mutation }))(Create);

export default composedReceipt;
