import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SamplePagination extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: 'Bibek',
        password: 'Shah',
      },
      pagination: {
        page: 1,
        limit: 50,
        offset: 50,
      },
    };
  }

  handleClick = () => {
    const { makeRequest } = this.props;
    makeRequest('superadmin@rosia.one', 'superadmin123');
  };

  handleInputChange = event => {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    const { data, error, loading } = this.props;
    return (
      <div>
        <div>
          Sample Pagination Should go over here
          <div>
            Data:
            {JSON.stringify(data)}
          </div>
          <div>
            Error:
            {JSON.stringify(error)}
          </div>
          <div>
            Loading:
            {JSON.stringify(loading)}
          </div>
          <span onClick={() => this.handleClick()}>I am indicating loading and error status</span>
        </div>
      </div>
    );
  }
}

const propTypes = {
  makeRequest: PropTypes.func.isRequired,
};

SamplePagination.propTypes = propTypes;

export default SamplePagination;
