import EVENT_OPERATION from '../../../../data/enums/EventOperation';
import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';
import { inventory as inventoryRoute } from '../../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { CONFIRMATION_CONFIG } from '../../../../data/enums/config';

const breadCrumb = [inventoryRoute];
const title = 'Stock Count';

const crudRequestConfig = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.STOCK_COUNT,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      ids: data.id,
      // title: data.title,
      type: STATEFUL_ENTITIES.STOCK_COUNT,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

const formConfig = {
  ...CONFIRMATION_CONFIG,
};

export { breadCrumb as breadCrumbConfig, crudRequestConfig, formConfig };
