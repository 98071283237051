import styled from 'styled-components';

const CallHistoryWrap = styled.div`
  .table-wrap {
    td {
      .light {
        min-width: 90px;
        padding: 3px 4px;
        text-align: center;
        line-height: 10px;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;
        border-radius: 2px;
      }
      .successful,
      .offroute {
        border: 0;
        padding: 0;
        .light {
          border: 1px solid #47c464;
          color: #287d3c;
        }
      }
      .unsuccessful {
        border: 0;
        padding: 0;
        .light {
          border: 1px solid rgba(218, 20, 20, 0.4);
          color: #da1414;
        }
      }
    }
  }
`;

export default CallHistoryWrap;
