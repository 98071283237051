import React from 'react'
import CatalogtPanel from '../Common/CatalogtPanel.styled';
import { Badge, Switch } from '../../../../v4/components';
import { Collapsible, Search } from '../../../../components';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';

const DetailView = (props) => {
  const {
    brandList,
    data,
    productCategoryList,
    onProductCategoryClick,
    onBrandClick,
    SKUFamilyList,
    count,
    onToggle,
    onSKUFamilyToggle,
    onHandleSearchInput,
    searchText,
    loading,
    alreadySelectedSku,
    read,
  } = props;
  const { categoryId, productCategoryId, brandId } = data;
  const {  productCategories, brands, } = count;

  const getCount = (skus)=>{
    const sku = skus?.filter((a)=>a.status)?.length;

    if(skus?.filter((a)=>a.status)?.length>0){
      return <Badge light badge label={sku} className='badge' />
    }else{
      return ''
    }}
  const getCategoryBrandCount = (countItems, id, key, type) => {
    const countItem = countItems.find((item) => item[key] === id) || {};
    if (type === 'badge') {
      return countItem.count ? (
        <Badge light badge label={countItem.count} className='badge' />
      ) : (
        ''
      );
    }
    return countItem.count ? countItem.count : 0;
  };

  return (
    <div className='create-ui-inner tab-less-height'>
      <CatalogtPanel>
        <div className='panel-section'>
          <div className='panel one'>
            <p className='p-title'>Product Category</p>
            <ul>
              {productCategoryList.map((group) => (
                <li
                  key={group.id}
                  onClick={() => onProductCategoryClick(group.id)}
                  className={
                    parseInt(productCategoryId, 10) === group.id
                      ? 'active'
                      : 'inactive'
                  }
                  title={group.title}
                >
                  {/* <p>{`${group.id} - ${group.title}`}</p> */}
                  <p>{group.title}</p>
                  {getCategoryBrandCount(
                    productCategories,
                    group.id,
                    'id',
                    'badge',
                  )}
                </li>
              ))}
            </ul>
          </div>
          {productCategoryId && (
            <div className='panel two'>
              <p className='p-title'>Brands</p>
              <ul>
                {brandList.map((brand) => (
                  <li
                    key={brand.id}
                    onClick={() => onBrandClick(brand.id)}
                    className={
                      parseInt(data.brandId, 10) === brand.id
                        ? 'active'
                        : 'inactive'
                    }
                    title={brand.title}
                  >
                    {/* <p>{`${brand.id} - ${brand.title}`}</p> */}
                    <p>{brand.title}</p>
                    {getCategoryBrandCount(brands, brand.id, 'id', 'badge')}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {brandId && (
            <div className='panel three'>
              <div className='panel-title'>
                <p className='p-title'>SKU Family / SKUs</p>
                <Search
                  searchText={searchText}
                  clearText={!searchText}
                  handleSearchInput={onHandleSearchInput}
                />
              </div>
              <div className='div-thead'>
                <span>SKU</span>
              </div>
              <div className={`div-tbody ${(loading||read)?'disabled':''}`}>
                {SKUFamilyList.map((skuFamily,familyIndx) => (
                  <Collapsible
                    key={skuFamily.id}
                    trigger={
                      <>
                        <span>
                          <span className='text'>
                            {/* {`${skuFamily.id} - ${skuFamily.title}`} */}
                            {skuFamily.title}
                            {getCount(skuFamily.skus)}
                          </span>
                        </span>
                        <div onClick={(e) => e.stopPropagation()}>
                          <Switch
                            disabled={alreadySelectedSku?.map((a)=>a.sku_family_id)?.includes(skuFamily.id)}
                            checked={
                              skuFamily.skus?.filter((a)=>a.status===true)?.length === skuFamily.skus.length
                            }
                            onToggle={(e) => {
                              onSKUFamilyToggle(
                                e,
                                skuFamily.skus.map((sku) => sku.id),
                                ['skuFamily', familyIndx ],
                                { skuFamilyId:skuFamily.id,brandId:data.brandId,productCategoryId:data.productCategoryId ,editActive:skuFamily.skus.map((sku) => sku.editActive) },
                              );
                            }}
                          />
                        </div>
                      </>
                    }
                  >
                    {skuFamily.skus.map((sku,skuIndx) => (
                      <div className='collapsible-inner' key={sku.id}>
                        {/* <span>{`${sku.id} - ${sku.title}`}</span> */}
                        <span>{sku.title}</span>
                        <Switch
                          checked={sku.status}
                          disabled={alreadySelectedSku?.map((a)=>a.sku_id).includes(sku.id)}
                          onToggle={(e) => {
                            onToggle(e, [sku.id], ['sku', familyIndx, skuIndx], { skuFamilyId:skuFamily.id,brandId:data.brandId,productCategoryId:data.productCategoryId, editActive:sku.editActive });
                          }}
                        />
                      </div>
                    ))}
                  </Collapsible>
                ))}
              </div>
            </div>
          )}
        </div>
      </CatalogtPanel>
    </div>
  )
}

export default withErrorAndLoadingScreen(DetailView);
