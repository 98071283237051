import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
};

const SubDAssortmentForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay } = props;
  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Title'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Title'
      />
    </Form>
  );
};

SubDAssortmentForm.propTypes = propTypes;

SubDAssortmentForm.defaultProps = defaultProps;

const ComposedSubDAssortmentForm = withLoading(SubDAssortmentForm);

export default ComposedSubDAssortmentForm;
