import React, { Component } from 'react';
import withAlert from '../../../../utils/composition/withAlert'
import PropTypes from 'prop-types';
import * as queryService from '../../../base/query.service';
import {getMiscellaneousList, getMainData } from './config';
import { getOffsetFromPagination } from '../../../../utils/api';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { Icon ,Loading} from '../../../../v4/components';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { JWT } from '../../../../environment';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class CallDashboard extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.singleDate },
        sort: queryService.baseQueryParameters.sort,
      },
      dashboardOpen: false,
      dashboardData: {
        miscellaneousDashboardList: [],
        mainDashboardData: []
      },
      sbdFskuList:{
        sbdGoldenPoints:0,
        fsku:0,
      }
    };
    const { displayAlert } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      displayAlert,
    );
  }

  componentDidMount() {
  }

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onClickDashboard = () => {
    const { dashboardOpen } = this.state;
    dashboardOpen || (this.getCallDashboard(), this.getSbdFsku());
    this.setState({ dashboardOpen: !dashboardOpen });
  }

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };
  getCallDashboard = tabStatus => {
    const { getCallPlanDashboard } = this.props;
    const { queryParameters, dashboardData } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    const buSettings = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.BU_SETTINGS));
    getCallPlanDashboard(
      {
        limit: queryParameters.pagination.limit,
        offset,
      },
      {
        handleSuccess: response => {
          const callPlanDashboardData = response?.data?.getCallPlanDashboard;
          const { received_order_value = 0, total_call_duration = {}, ...miscellaneousData } = callPlanDashboardData;
          const miscellaneousDashboardList = getMiscellaneousList(miscellaneousData,buSettings.sbd);
          const mainDashboardData = getMainData(received_order_value, total_call_duration);
          dashboardData.miscellaneousDashboardList = miscellaneousDashboardList
          dashboardData.mainDashboardData = mainDashboardData;
          this.setState({ dashboardData });
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  }
  getSbdFsku = () => {
    const { getSbdFsku } = this.props;
    const { queryParameters, sbdFskuList } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getSbdFsku(
      {
        limit: queryParameters.pagination.limit,
        offset,
      },
      {
        handleSuccess: response => {
          const sbdFsku=response?.data?.getSbdFsku;
          sbdFskuList.sbdGoldenPoints=sbdFsku.sbd_golden_points;
          sbdFskuList.fksu=sbdFsku.fksu;
          this.setState({sbdFskuList});
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  }

  render() {
    const { dashboardData, dashboardOpen,sbdFskuList } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <div className='dashboard-wrapper'>
      <div className={`menu ${dashboardOpen ?'active' : 'inActive'}`}>
        <header>
          <h2>Call Dashboard [Today]</h2>
          <Icon iconName='times'  onClick={this.onClickDashboard} style={{ cursor: 'pointer' }}/>
        </header>
        <main>
          <div className='row'>
            {dashboardData.miscellaneousDashboardList.map((subData,index) =>(
              <div className='col-lg-6'>
                <div className={`card c2 ${subData.class}`}>
                  <h5>{subData.title}</h5>
                    <p><span>{index ==1 &&  'NPR '}</span>{fixedFloatAndCommas(subData.value) || 0}</p>
                </div>
              </div>
            ))}
            {/* <div className='col-lg-6'>
                <div className={`card c2`}>
                  <h5>SBD Golden Points</h5>
                  <p>{fixedFloatAndCommas(sbdFskuList?.sbdGoldenPoints) || 0}</p>
                </div>
              </div> */}
              {/* <div className='col-lg-6'>
                <div className={`card c2 `}>
                  <h5>FSKU</h5>
                  <p>{fixedFloatAndCommas(sbdFskuList?.fsku) || 0}</p>
                </div>
              </div> */}
          </div>
          <div className='row'>
            {dashboardData.mainDashboardData.map((subData) =>(
              <div className='col-lg-12'>
                <div className={`card c1 ${subData.class}`}>
                  <h5>{subData.title}</h5>
                  <p>{subData.value}</p>
                </div>
              </div>
            )
            )}
          {serverResponseWaiting && (
          <Loading title={'Loading... Please wait.'}/>
            )}
          </div>
        </main>
      </div>
      <div className='dashboard-btn' onClick={this.onClickDashboard} style={{ cursor: 'pointer' }}>
        <Icon iconName='angle-left'  />
      </div>
    </div>

    );
  }
}

CallDashboard.propTypes = propTypes;

CallDashboard.defaultProps = defaultProps;


export default withAlert()(CallDashboard)
