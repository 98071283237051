import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Row, Col, AutoComplete,CustomSelect,Button,Icon,
} from '../../../../../components';

const propTypes = {
  townList: PropTypes.arrayOf(Object),
  subDList: PropTypes.arrayOf(Object),
  onAutoCompleteChange: PropTypes.func,
  promotionAreas: PropTypes.shape({
    townId: PropTypes.number,
    subDId: PropTypes.number,
  }),
};

const defaultProps = {
  subDList: [],
  townList: [],
  promotionAreas: {
    townId: null,
    subDId: null,
  },
  onAutoCompleteChange: () => null,
};


const PromotionArea = ({ ...props }) => {
  const { onAutoCompleteChange, promotionAreas ,scope,territories ,promotionId,subDList ,onIconClick } = props;
  const getUserListUnderRole = (territoriesId,selectedList) => {
    const subDLists=territories.filter((a)=>a.id ===territoriesId).map((a)=>a?.Towns?.map((b)=>b?.Distributor.length > 0 ?b?.Distributor?.filter((a)=>a.active).flat():[]).flat()).flat();
    if (selectedList.length !== subDLists.length) {
      const subDListWithAll = { id: 0,title:'All Sub D' };
      subDLists.splice(0, 0, subDListWithAll);
    }
    return subDLists
  };
  const handleScopeAutoCompleteChange = (index) => (value, fieldName) => onAutoCompleteChange(value, fieldName, index);
  const territoriesIds =scope?.territorySubDInfo.map((a) =>a?.territoriesId);
  const  filteredList = territories.filter(function(item) {
    return territoriesIds.indexOf(item.id) === -1
  });
  return (
    <Fragment>
      { !promotionId ?(
        <div className='territory-subd'>
          <section className='territory-subD-wrap'>
            {scope?.territorySubDInfo?.map((cb, index) => (
              <Fragment key={index}>
                {index > 0 && (
                  <div className='config-promotion-panel-header'>
                    <div className='flex m-0'>
                      <Icon
                        iconName='times'
                        primary
                        onClick={() => onIconClick('remove', 'territorySubDInfo', index)}
                      />
                    </div>
                  </div>
                )}
                <div className='config-catalog-wrap'>
                  <Row>
                    <Col md={4} className='mb-24'>
                      <CustomSelect
                        labelContent='Territories'
                        className='custom-select'
                        options={filteredList}
                        placeholder='Select Territories'
                        getOptionValue={({ id }) => id}
                        getOptionLabel={({ title }) => title}
                        onChange={(event) => onAutoCompleteChange(event.id,'territoriesId', index)}
                        value={territories.filter(({ id }) => id === cb.territoriesId)}
                      />
                    </Col>
                    <Col md={4} className='mb-24'>
                      <div className='auto-complete'>
                        <AutoComplete
                          name='subDIdList'
                          labelContent='Sub D'
                          dropDownList={promotionId ? subDList: getUserListUnderRole(cb.territoriesId,[...cb.subDIdList])}
                          placeholder='Search'
                          selectedDropDowns={[...cb.subDIdList]}
                          onDropDownSelection={handleScopeAutoCompleteChange(index)}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Fragment>
            ))}
          </section>
          <Row>
            <Col md={4} className='mt-24'>
              <div className='add'>
                <Button
                  iconBtnSmall
                  secondary
                  iconName='plus'
                  onClick={() => onIconClick('add', 'territorySubDInfo')}
                />
              </div>
            </Col>
          </Row>
        </div>
      ):null}
      { promotionId ?(
        <Row>
          <Col md={4}>
            <AutoComplete
              name='subDIdList'
              labelContent='Sub D'
              dropDownList={subDList}
              placeholder='Search'
              selectedDropDowns={[...promotionAreas.subDIdList]}
              onDropDownSelection={onAutoCompleteChange}
            />
          </Col>
        </Row>
      ):null}
    </Fragment>
  );
};

PromotionArea.defaultProps = defaultProps;

PromotionArea.propTypes = propTypes;

export default PromotionArea;
