import styled from 'styled-components';

const OrderHistoryStyled = styled.div`
.total-view-wrap{
      padding: 16px 48px 25px;
    padding-bottom: 16px;
    display: flex;
    margin: 0;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.filter-wrap{
  display: flex;
  .date-picker{
    margin-left:16px;
    >div{
      margin-right:0px;
    }
  }
}
.table-wrap{
 &>table {
   thead{
     th{
       width:15%;
       &:last-child{
         width: 50%;
         padding-left: 10%;
       }
     }
   }
  tbody{
  td,th{
    width:15%;
    >p{
      width:205px;
    }
    >div{
          width: 180px;
    }
    &:nth-last-child(3),
    &:last-child,
    &:nth-child(2),
    &:first-child{
      width:auto;
      white-space:nowrap;
    }
  }
  }
  }
}
.tab-section{
  table{
 .collapsible-body-tr {
      &:hover, &:focus {
        background: transparent;
      } thead{
     th{
       width:15%;
       &:last-child{
         width: 50%;
         padding-left: 10%;
       }
     }
   }
       td,th{
    white-space:nowrap;
>p{
  width: 145px;
}
    }
    tr{
   &:last-child{
          height:48px;
        }
    }
  }
      table {
        box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
        border: none;
        tbody {
                 thead{
          tr{
            th{
              background-color:unset;
              width:10%;
            }
            &:first-child{
            &:hover{
              background:unset;
            }
          }
        }
     
        }
          tr:last-child {
            border-top: 1px solid #e7e7ed;
          }

            tr {
          cursor:default;
              &:hover{
             background-color:#F7F8F9;
             transition: all 0 ease-in-out;
          }
             &:last-child,
            &:first-child{
              &:hover{
                background-color:#F7F8F9;
              }
             
            }
          th, td {
            border-bottom: none;
          }
        }
        } 
      
    }
  }
}
}
   


  table tbody {
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004AD7;
        }
      }
      td:first-child {
        > span {
          position: relative;
          padding-left: 26px;
          
          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
`;

export default OrderHistoryStyled;
