import gql from 'graphql-tag';
import { GET_DISTRIBUTORS, DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_STOCK_REPLACEMENT_LIST = gql`
  query getStockReplacementList($offset: Int, $limit: Int, $filter: FilterInput) {
    getStockReplacementReport(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        date
        distributor
        brand
        sku
        quantity
        outlet: retail_outlet
        replacementSKU: replacement_sku
        replacementQTY: replacement_sku_quantity
        replacedBy: replaced_user
        replacedDate: replaced_date
        reason
        status
        route
        requestedUser: requested_user
      }
      count
    }
  }
`;

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getStockReplacementList: GET_STOCK_REPLACEMENT_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, DOWNLOAD_REPORT
};
