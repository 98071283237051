import styled from 'styled-components';

const WarehouseReportStyled = styled.div`
  .section-content.section-select .table-wrap {
    &.no-select {
      height: calc(100vh - 120px) !important;
      &.pagination {
        height: calc(100vh - 120px - 56px) !important;
      }
    }
    &.select {
      height: calc(100vh - 210px);
      &.pagination {
        height: calc(100vh - 120px - 56px) !important;
      }
    }
  }

  .form-input input {
    color: #272833;
  }
  .form-select-input,
  .form-input {
    margin-bottom: 0;
    & + label {
      font-size: 14px;
      color: #6b6c7e;
    }
  }
  .label-wrap {
    margin-top: 4px;
    label {
      margin-top: 4px;
      font-size: 14px;
      color: #6b6c7e;
    }
  }
  .width_300 {
    width: 300px;
  }
  .collapsible-body-tr .td-wrapper table tbody th {
    background: #f1f2f5;
    color: #6b6c7e;
    font-weight: 600;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .table-wrap {
    & > table {
      & > tbody,
      & > thead {
        th {
          cursor: unset;
        }
        .first-th {
          padding-left: 74px;
        }
        & > tr > .width_300 {
          width: 16% !important;
        }
      }
    }
  }
  .collapsible-body-tr {
    tbody {
      tr {
        height: 40px !important;
        td {
          padding-top: 0;
          padding-bottom: 0;
        }
        th:first-child,
        td:first-child {
          padding-left: 76px !important;
        }
      }
    }
    .width_300 {
      width: 16% !important;
    }
  }
  .width_82 {
    width: 5% !important;
  }
  .width_30 {
    width: 10%;
  }
  .btn-wrap {
    img {
      cursor: pointer !important;
    }
  }
  tr {
    &.active:not(.no-collaspse) {
      background: #f0f5ff;
      height: 52px;
      transition: all 0.4s ease-out;
      td {
        border-bottom: 1px solid #0c5fff;
      }
    }
    &.active.no-collaspse {
      height: 56px;
      transition: unset;
      span:before {
        content: unset;
      }
    }
    &.no-collaspse {
      cursor: unset;
      height: 52px;
      span:before {
        border: 0 !important;
      }
    }
    &.no-collaspse + .collapsible-body-tr {
      display: none;
    }
  }
  tbody {
    tr {
      &.active {
        td:first-child > .caret:before {
          transform: rotate(180deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004ad7;
        }
      }
      td:first-child {
        > .caret {
          position: relative;
          padding-left: 26px;
          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
  .modal-inner.update {
    max-width: 428px;
    .evolve-dialog__body,
    .zindex-2__menu-list {
      scrollbar-color: #0b5fff transparent;
      scrollbar-width: thin;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        width: 4px;
        height: 2px;
      }
      &:hover {
        &::-webkit-scrollbar-track {
          background: transparent;
          width: 4px;
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          background: #0b5fff;
          height: 25vh;
        }
      }
    }
    .evolve-dialog__body {
      max-height: 75vh;
      overflow: auto;
      label:not(.lab) {
        margin-bottom: 6px;
        line-height: 125%;
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
      }
      .form-input input {
        border-radius: 4px;
        border: 1px solid var(--secondary-secondary-l-3, #e7e7ed);
        background: var(--light-white, #fff);
      }
      .quantity {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid var(--secondary-secondary-l-3, #e7e7ed);
        margin-bottom: 24px;
      }
      .quantity .zindex-2__control,
      .quantity .form-input input {
        border-radius: 4px;
        border: 1px solid var(--secondary-secondary-l-3, #e7e7ed);
        background: var(--light-light, #f1f2f5);
        .zindex-2__placeholder {
          font-weight: 400;
        }
      }
      .sku-wrap {
        .sku-title {
          margin: 0;
          white-space: nowrap;
          border-radius: 4px;
          border: 1px solid var(--secondary-secondary-l-3, #e7e7ed);
          background: var(--light-white, #fff);
          color: #272833;
          height: 40px;
          min-width: 0;
          padding: 8px 16px;
          display: block;
          width: 100%;
          font-size: 16px;
          line-height: 1.5;
          color: #030913;
          border-radius: 4px;
          box-shadow: none;
          overflow-x: auto;
          overflow-y: hidden;
          -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          scrollbar-color: #0b5fff transparent;
          scrollbar-width: thin;
          scroll-behavior: smooth;
          &::-webkit-scrollbar {
            width: 4px;
            height: 2px;
          }
          &:hover {
            &::-webkit-scrollbar-track {
              background: transparent;
              width: 4px;
              height: 1px;
            }

            &::-webkit-scrollbar-thumb {
              background: #0b5fff;
              height: 25vh;
            }
          }
        }
      }
    }
  }
`;

export default WarehouseReportStyled;
