const getEnumFromObject = (object) => Object.freeze({ ...object });

const PROMOTION_RELATED_TYPE = getEnumFromObject({
  SBD: 'SBD',
  FOCUSED_SKU: 'FOCUSED_SKU',
});

const PROMOTION_TYPE = getEnumFromObject({
  SBD: 'SBD',
  FOCUSED_SKU: 'FOCUSED_SKU',
});

const PROMOTION_AREAS = getEnumFromObject({
  TOWN: 'TOWN',
  SUB_D: 'DISTRIBUTOR',
  ROUTE: 'ROUTE',
});

export {
  PROMOTION_RELATED_TYPE, PROMOTION_TYPE, PROMOTION_AREAS
};
