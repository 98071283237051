import { fileParser } from '../../../utils/parser';
import { refGenerator } from '../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { CONFIRMATION_CONFIG, FORM_CONFIG } from '../../../data/enums/config';
import { UPLOADABLES } from '../../../data/enums/GraphQL';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'SKU';

const breadCrumb = [configurationRoute];

const formConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      file: element.file || {},
    }),
    [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  ...CONFIRMATION_CONFIG,
};

const filter = getFilterConfig([FILTER.BRAND, FILTER.SUB_BRAND_FORM, FILTER.STATUS,FILTER.PRINCIPAL]);

const crudSuccess = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: title,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: data.ids,
      type: title,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.SKU,
        },
      }));

      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: title,
      active: !data.active,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig, filter as filterConfig, title
};
