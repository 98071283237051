import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_MASTER_DATA, TOGGLE_STATE } from '../../common/GqlAPI';
import { GET_CATALOG_DETAIL } from '../sku/createSku/API';
import {
  CATEGORIES_LIST, CHANNEL_LIST, GET_TRADE_LIST
} from '../classification/API';

const GET_SUB_CAMPAIGN = gql`
  query subCampaign($id: Int!) {
    subCampaign(id: $id) {
      id
      campaignId
      title
      startDate
      endDate
      subCampaignType
      campaign: Campaign {
        budget
        startDate
        endDate
        SubCampaign {
          id
        }
        allocated
        unAllocated
      }
      subCampaignBudget: SubCampaignBudget {
        subCampaignBudgetId: id
        id
        budgetType
        value
        dimension
        calculatedValue
      }
      subCampaignTarget: SubCampaignTarget {
        subCampaignTargetId: id
        id
        targetType
        value
        dimension
      }
    }
  }
`;

const EDIT_SUB_CAMPAIGN = graphql(
  gql`
    mutation editSubCampaign($input: SubCampaignEditInput!) {
      editSubCampaign(input: $input) {
        id
        title
        campaignId
        subCampaignType
        startDate
        endDate
        subCampaignBudget: SubCampaignBudget {
          id
          budgetType
          value
          dimension
          calculatedValue
        }
        subCampaignTarget: SubCampaignTarget {
          id
          targetType
          value
          dimension
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      editSubCampaign: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_SUB_CAMPAIGN_DETAIL = graphql(
  gql`
    mutation createSubCampaignDetail($input: SubCampaignDetailInput!) {
      createSubCampaignDetail(input: $input) {
        id
        type
        typeLevel
        isTypeParent
        typeId
        typeDetail
        parentId
        active
        SubCampaignBudgetDetail {
          id
          budgetType
          value
          dimension
          calculatedValue
        }
        SubCampaignTargetDetail {
          id
          targetType
          value
          dimension
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createSubCampaignDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const EDIT_SUB_CAMPAIGN_DETAIL = graphql(
  gql`
    mutation editSubCampaignDetail($input: SubCampaignDetailEditInput!) {
      editSubCampaignDetail(input: $input) {
        id
        type
        typeLevel
        isTypeParent
        typeId
        parentId
        active
        SubCampaignBudgetDetail {
          id
          budgetType
          value
          dimension
          calculatedValue
        }
        SubCampaignTargetDetail {
          id
          targetType
          value
          dimension
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      editSubCampaignDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_SUB_CAMPAIGN_DETAILS = gql`
  query subCampaignDetails($subCampaignId: Int!, $parentId: Int, $limit: Int, $offset: Int, $filter: FilterInput) {
    subCampaignDetails(
      subCampaignId: $subCampaignId
      parentId: $parentId
      limit: $limit
      offset: $offset
      filter: $filter
    ) {
      rows {
        id
        campaignId
        subCampaignId
        type
        typeLevel
        isTypeParent
        typeId
        typeDetail
        parentId
        active
        subCampaignBudgetDetail: SubCampaignBudgetDetail {
          id
          subCampaignBudgetId
          subCampaignDetailId
          budgetType
          value
          dimension
          calculatedValue
        }
        subCampaignTargetDetail: SubCampaignTargetDetail {
          id
          subCampaignTargetId
          subCampaignDetailId
          targetType
          value
          dimension
        }
      }
      count
    }
  }
`;

const GET_SUB_CAMPAIGN_DETAILS_BY_IDS = gql`
  query getSubCampaignDetailById($id: Int!) {
    getSubCampaignDetailById(id: $id) {
      id
      isTypeParent
      type
      typeLevel
      typeId
      typeDetail
      subCampaignBudget: SubCampaignBudgetDetail {
        budgetType
        value
        id
        subCampaignBudgetId
        dimension
        calculatedValue
      }
      subCampaignTarget: SubCampaignTargetDetail {
        targetType
        value
        id
        subCampaignTargetId
        dimension
      }
    }
  }
`;

const TYPES_FOR_SUB_CAMPAIGN_DETAIL = gql`
  query addedTypesForSubCampaignDetail($subCampaignId: Int!) {
    addedTypesForSubCampaignDetail(subCampaignId: $subCampaignId) {
      type
      typeLevel
      isTypeParent
    }
  }
`;

const REMOVE_SCOPE = graphql(
  gql`
    mutation removeSubCampaignScope($subCampaignId: Int!, $typeLevel: String!) {
      removeSubCampaignScope(subCampaignId: $subCampaignId, typeLevel: $typeLevel) {
        ids
        message
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      removeSubCampaignScope: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  subCampaign: GET_SUB_CAMPAIGN,
  subCampaignDetails: GET_SUB_CAMPAIGN_DETAILS,
  getSubCampaignDetailById: GET_SUB_CAMPAIGN_DETAILS_BY_IDS,
  getMasterData: GET_MASTER_DATA,
  getCatalogDetailList: GET_CATALOG_DETAIL,
  selectedSubCampaignType: TYPES_FOR_SUB_CAMPAIGN_DETAIL,
  getChannelList: CHANNEL_LIST,
  getCategoryList: CATEGORIES_LIST,
  getTradeList: GET_TRADE_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  editSubCampaign: EDIT_SUB_CAMPAIGN,
  createSubCampaignDetail: CREATE_SUB_CAMPAIGN_DETAIL,
  editSubCampaignDetail: EDIT_SUB_CAMPAIGN_DETAIL,
  removeSubCampaignScope: REMOVE_SCOPE,
};

export {
  query,
  mutation,
  TOGGLE_STATE,
  EDIT_SUB_CAMPAIGN,
  CREATE_SUB_CAMPAIGN_DETAIL,
  EDIT_SUB_CAMPAIGN_DETAIL,
  REMOVE_SCOPE,
};
