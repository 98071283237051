import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_PRODUCTIVITY_LIST = gql`
  query getProductivityReportTAS($offset: Int, $limit: Int, $filter: FilterInput) {
    getProductivityReportTAS(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        route_id
        route
        town_id
        town
        zone_id
        zone
        distributor_id
        distributor
        dse_id
        dse
        stl
        asm
        scheduled
        successful
        unsuccessful
        calls_made
        calls_not_performed
        productivity
      }
      count
    }
  }
`;

const query = {
  getProductivityReportTAS: GET_PRODUCTIVITY_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
