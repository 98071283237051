import React, { useState } from 'react';
import styled from 'styled-components';
import { CustomSelect, Input } from '../../../../../components';
import { periodType } from '../../../../../data/enums/Notification';
import { setDateTime } from '../../../../../utils/date';
import { Button } from '../../../../../v4/components';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';

const ScheduleCall = ({ handleCallScheduleChange, displayAlert }) => {
  const [callPlanTime, setCallPlanTime] = useState({
    hours: '',
    minutes: '',
    AM_PM: '',
  });

  const [scheduled, setScheduled] = useState(false);

  const handleCallTimeChange = (value, label) => {
    if (value < 0)
      return
    if (label === 'hours') {
      callPlanTime.hours = Number(value) < 10 && Number(value) >= 1 ? `0${Number(value)}` : Number(value) > 12 ? 12 : Number(value);
    }

    if (label === 'minutes') {
      callPlanTime.minutes = Number(value) < 10 && Number(value) >= 0 ? `0${Number(value)}` : Number(value) > 59 ? 0 : Number(value);
    }

    if (label === 'AM_PM') {
      callPlanTime.AM_PM = value;
    }

    setCallPlanTime({ ...callPlanTime });
  }

  const setCallScheduleTime = (event) => {
    event.preventDefault();
    let hours;
    if (callPlanTime.hours > 0 && callPlanTime.minutes >= 0 && callPlanTime.minutes < 60 && callPlanTime.AM_PM) {
      if (callPlanTime.AM_PM === 'PM') {
        hours = callPlanTime.hours > 12 ? callPlanTime.hours : 12 + Number(callPlanTime.hours);
      } else {
        hours = callPlanTime.hours >12 ? Number(callPlanTime.hours) - 12 : callPlanTime.hours;
      }
      const dateTime = (setDateTime(new Date(), { hours, minutes: callPlanTime.minutes || 0 }).format());
      handleCallScheduleChange(dateTime);
      displayAlert(ALERT_TYPE.INFO, 'Call Time has been scheduled');
      setScheduled(true);
    } else {
      displayAlert(ALERT_TYPE.WARNING, 'Please select valid call time.')
    }
  }

  return <ScheduleCallContainer>
    <span>Schedule Call Time</span>
    <div className='time-selector'>
      <div className='time'>
        <Input
          value={callPlanTime.hours}
          type='number'
          placeholder='HH'
          onChange={(event) => handleCallTimeChange(event.target.value, 'hours')}
          argument={{
            max: 12,
          }}
        />
        <label>HH</label>
      </div>
      <div className='time'>
        <Input
          value={callPlanTime.minutes}
          type='number'
          placeholder='MM'
          onChange={(event) => handleCallTimeChange(event.target.value, 'minutes')}
          allowMultiZeroBeforeNumber
          argument={{
            max: 60,
          }}
        />
        <label>MM</label>
      </div>
      <div className='time'>
        <CustomSelect
          name='period'
          className='custom-select'
          placeholder='AM/PM'
          options={periodType}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ title }) => title}
          value={periodType.filter(({ title }) => title === callPlanTime.AM_PM)}
          onChange={(event) => handleCallTimeChange(event.value, 'AM_PM')}
        />
        <label>AM/PM</label>
      </div>
      <Button className={scheduled ? 'focused' : ''} outlined secondary iconName='check' onClick={setCallScheduleTime} />
    </div>
  </ScheduleCallContainer>;
};


const ScheduleCallContainer = styled.div`
  .time-selector{
    margin: .5rem 0;
    flex-wrap: wrap;
    display: flex;
    gap: 2rem;

    .time{
      flex: 1;
      min-width: 55px;
      .form-input, .form-select-input {
        margin-bottom: 0;
        flex: 1;
        min-width: 80px;

        input {
          border: 1px solid rgb(231,231, 237);
          background-color: rgb(241, 242,245);
          border-radius: 4px;
        }
      }

      .form-select-input{
        .select-css {
          min-width: 80px;
          
          .zindex-2__control {
            border: 1px solid rgb(231,231, 237);
            background-color: rgb(241, 242,245);
            border-radius: 4px;
          }
        }

          label {
          display: none;
        }
      }
      label {
        color: #A7A9BC;
        font-size: 10px;
        font-weight: 500;
        margin-top: 6px;
      }
    }
    
    button {
      height: 40px;
      border-color: #80ACFF;

      &.focused {
        background-color: #0056E0;
        .icon {
          color: #fff
        }
      }

      &:hover, &:focus {
        .icon {
          color: #fff;
        }
      }

      .icon {
        color: #0B5FFF;
      }
    }
  }
`;

export default ScheduleCall;
