import React from 'react';
import PropTypes from 'prop-types';
import { CustomSelect, Input } from '../../../../components';
import { WEEK_DAYS_TITLE, frequency } from './config';

const propTypes = {
  basicDetail: PropTypes.objectOf(Object).isRequired,
};

const BasicDetail = (props) => {
  const {
    basicDetail,assignedTo,handleInputChange,handleDropDownChange,
    refsObj,enableErrorDisplay,read,stockId,
  }=props;

  const monthDaysValidation = (e) => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
    const lastDate = lastMonth.getDate()
    const num = Number(e.target.value)
    let events = e
    if(e.target.value) {
      if(num <1 ) {
        events = { ...e,target: { ...e.target, value: 1 } }
      } else if(num > Number(lastDate)) {
        events = { ...e,target: { ...e.target, value: Number(lastDate) } }
      }
    }
    return events
  }
  return(
    <section className={`sbd-top-wrap ${read?'disabled':''}`}>
      <div className='flex'>
        <Input
          name='title'
          type='text'
          value={basicDetail.title}
          labelContent='Title'
          placeholder='Title'
          onChange={(e) => handleInputChange(e)}
          ref={(ref) => (refsObj.title = ref)}
          enableValidation
          enableErrorDisplay={enableErrorDisplay}
        />
        <div className='custom-date'>
          <label>Frequency</label>
          <CustomSelect
            onChange={(e) => handleDropDownChange(e.value,'frequency_type')}
            value={frequency?.filter((freq) => freq.value === basicDetail.frequency_type)}
            getOptionValue={({ value }) => value}
            getOptionLabel={({ label }) => label}
            name='frequency'
            placeholder='Select'
            options={frequency}
            ref={(ref) => (refsObj.frequency_type = ref)}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
          />
        </div>
        {basicDetail.frequency_type==='WEEKLY'&&<div className='custom-date'>
          <label>Day of week</label>
          <CustomSelect
            onChange={(e) => handleDropDownChange(e.title,'days')}
            value={WEEK_DAYS_TITLE?.filter((freq) => freq.title === basicDetail.days)}
            getOptionValue={({ title }) => title}
            getOptionLabel={({ label }) => label}
            name='frequency'
            placeholder='Select'
            options={WEEK_DAYS_TITLE}
            ref={(ref) => basicDetail.frequency_type==='WEEKLY'?(refsObj.days = ref):null}
            enableValidation
            enableErrorDisplay={basicDetail.frequency_type==='WEEKLY'?enableErrorDisplay:false}
          />
        </div>}
        {basicDetail.frequency_type==='MONTHLY'&&
          <div className='custom-date'>
            <Input
              className='month-input'
              value={basicDetail.dayOfTheMonth}
              name='dayOfTheMonth'
              type='number'
              rule='matches'
              argument={/^([1-9]|[0-2][0-9]|[3][0-1])$/}
              labelContent='Day of the Month'
              placeholder='0'
              enableValidation
              onChange={(e) => {
                const event = monthDaysValidation(e)
                handleInputChange(event, 'dayOfTheMonth')}}
              ref={(ref) => basicDetail.frequency_type==='MONTHLY'?(refsObj.dayOfTheMonth = ref):null}
              enableErrorDisplay={basicDetail.frequency_type==='MONTHLY'?enableErrorDisplay:false}
            />
          </div>}
          {basicDetail.frequency_type === 'FORTNIGHTLY' && (
          <div className='custom-date'>
            <Input
              className="month-input"
              value={basicDetail.dayOfTheMonth?.[0]}
              argument={/^(1[0-5]|[1-9])$/}
              name="dayOfTheMonth"
              type="number"
              labelContent="Day of the Month"
              placeholder="0"
              enableValidation
              onChange={e => {
                const inputValue = e.target.value;
                if (inputValue<=15) {
                  const event = monthDaysValidation(e);
                  handleInputChange(event, 'fortnightly', inputValue);
                }
              }}
              ref={ref => (basicDetail.frequency_type === 'FORTNIGHTLY' ? (refsObj.dayOfTheMonth = ref) : null)}
              enableErrorDisplay={basicDetail.frequency_type === 'FORTNIGHTLY' ? enableErrorDisplay : false}
            />
            
              <label>{basicDetail.dayOfTheMonth?.[1] && `The next occurrence: ${basicDetail.dayOfTheMonth?.[1]}`}</label>
            
          </div>
        )}


        <div className='custom-date'>
          <label>{stockId?'Assigned To':'Assign To'}</label>
          <CustomSelect
            onChange={(e) => handleDropDownChange(e,'assigned')}
            value={assignedTo && assignedTo.filter((ass) => ass.id === basicDetail?.assigned?.id)}
            getOptionValue={({ id }) => id}
            getOptionLabel={({ name }) => name}
            name='assignedTo'
            placeholder='Select'
            options={assignedTo}
            ref={(ref) => (refsObj.assignedTo = ref)}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
          />
        </div>
      </div>
    </section>
  )};

BasicDetail.propTypes = propTypes;

export default BasicDetail;
