import styled from 'styled-components';

const ModalStyled = styled.div`
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 111;
    .modal-main {
      position: fixed;
      background: white;
      width: 80%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 395px;
      background-color: #ffffff;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      .m-header {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        h2 {
          margin: 0;
          padding: 20px 24px;
          font-size: 20px;
          line-height: 25px;
          color: #272833;
          font-weight: 600;
        }
      }
      .m-body {
        padding: 24px;
        border-top: 1px solid #e7e7ed;
        border-bottom: 1px solid #e7e7ed;
        font-size: 14px;
        color: #272833;
        line-height: 21px;
        p {
          margin: 0;
          font-weight: 400;
          margin-bottom: 24px;
        }
        .form-input {
          width: 100%;
          display: flex;
          flex-direction: column;
          label {
            margin-bottom: 4px;
            color: #272833;
            font-size: 14px;
            font-weight: 600;
          }
          input {
            background-clip: border-box;
            border: 1px solid #c1c7d0;
            height: 40px;
            min-width: 0;
            padding: 8px 16px;
            display: block;
            width: 100%;
            font-size: 1rem;
            line-height: 1.5;
            color: #030913;
            background: #fafbfc;
            border-radius: 4px;
            box-shadow: none;
            overflow: visible;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            -webkit-appearance: none;
            outline: none;
            &:focus {
              outline: none;
            }
          }
          .basic-multi-select {
            margin-top: 4px;
          }
        }
      }
      .m-footer {
        display: flex;
        justify-content: flex-end;
        padding: 15px 24px;
        button {
          letter-spacing: 0;
          padding: 8px 16px;
          font-size: 16px;
          line-height: 22px;
          border-radius: 4px;
          outline: none;
          margin-right: 16px;
          text-transform: capitalize;
          background: white;
          background-color: #0e69ff;
          color: #ffffff;
          border-color: #0e69ff;
          font-weight: 600;
          cursor: pointer;
          transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
          &:hover {
            background-color: #0053f0;
          }
          &.disabled-btn {
            background: #b3cdff;
            border-color: #b3cdff;
            color: #fff;
          }
          &.m-close {
            background-color: #ffffff;
            border-color: #cdced9;
            color: #6b6c7e;
            &:hover {
              background-color: #f7f8f9;
              color: #272833;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .display-block {
    display: block;
  }
  .display-none {
    display: none;
  }
`;
export default ModalStyled;
