import React from 'react';
import PropTypes from 'prop-types';
import OpeningBalanceChildView from './childView';
import { TableBody } from '../tableConfig';
import { AccordionTable } from '../../../../components/CollapsibleTable';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  openingBlncDetail: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func.isRequired,
};

const defaultProps = {
  data: [],
  openingBlncDetail: [],
};

const StockLedgerView = ({ ...props }) => {
  const { data, onRowClick, openingBlncDetail } = props;

  return (
    <tbody>
      {
        <AccordionTable>
          {data.map((openingBalance) => (
            <tr
              key={openingBalance.SKU.id}
              trigger={TableBody({
                data: openingBalance,
                onRowClick,
              })}
            >
              {OpeningBalanceChildView({ data: openingBlncDetail || [] })}
            </tr>
          ))}
        </AccordionTable>
      }
    </tbody>
  );
};

StockLedgerView.propTypes = propTypes;

StockLedgerView.defaultProps = defaultProps;

export default StockLedgerView;
