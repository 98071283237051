import React, { Component } from 'react';
import AttendanceDialogStyled from './AttendanceDialogStyled';
import { CheckBox } from '../../../../v4/components';

class Agendas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { data } = this.props;
    return (
      <AttendanceDialogStyled className='agendas_section'>
        <h5>Agendas</h5>
        {data.map((data) => (
          <ul>
            <li>
              <CheckBox checked checked={data.isCompleted} onCheckBoxClick={() => null} />
              <span className={data.isCompleted && 'middle-underline'}>{data.agenda}</span>
            </li>
          </ul>
        ))}
      </AttendanceDialogStyled>
    );
  }
}

export default Agendas;
