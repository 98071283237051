import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../base/TableHeader';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { reports as reportsRoute } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { getUpdatedLabel, headerLabelConfig } from '../../../common/HelperFunctions';

const breadCrumbConfig = [reportsRoute];

const filterConfig = getFilterConfig([FILTER.ASM, FILTER.STL, FILTER.SUB_D]);

const labelMappings = {
  name: 'name',
  pfdTarget: 'pfd_target',
  sbdCount: 'sbd_count',
  fskuCount: 'fsku_count',
  lpc: 'lpc',
  bpc: 'bpc',
  onRouteSales: 'onroute_sales',
  achPercent: 'achievement',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.name,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: true,
    sortable: true,
    className: 'sorting',
    isDynamic: true,
  },
  {
    id: 2,
    label: labelMappings.pfdTarget,
    title: 'PFD Target',
    className: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 3,
    label: labelMappings.fskuCount,
    title: 'Focus SKU Average Count',
    className: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 4,
    label: labelMappings.sbdCount,
    title: 'SBD SKU Average Count',
    className: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 5,
    label: labelMappings.onRouteSales,
    title: 'Received Orders',
    className: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 6,
    label: labelMappings.achPercent,
    title: 'Ach %',
    className: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 7,
    label: labelMappings.lpc,
    title: 'LPC',
    className: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 8,
    label: labelMappings.bpc,
    title: 'BPC',
    className: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
];

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.name] = <td key={`pfd-target-${data.id}-name`}>{data.name}</td>;
  table[labelMappings.pfdTarget] = (
    <td key={`pfd-target-${data.id}-target`} className='text-right'>
      {fixedFloatAndCommas(data.pfd_target)}
    </td>
  );
  table[labelMappings.fskuCount] = (
    <td key={`pfd-target-${data.id}-fskuCount`} className='text-right'>
      {data.fsku_count}
    </td>
  );
  table[labelMappings.sbdCount] = (
    <td key={`pfd-target-${data.id}-sbdCount`} className='text-right'>
      {data.sbd_count}
    </td>
  );
  table[labelMappings.onRouteSales] = (
    <td key={`pfd-target-${data.id}-onrouteSales`} className='text-right'>
      {fixedFloatAndCommas(data.onroute_sales)}
    </td>
  );

  table[labelMappings.achPercent] = (
    <td key={`pfd-target-${data.id}-achPercent`} className='text-right'>
      {fixedFloatAndCommas(data.achievement)}%
    </td>
  );
  table[labelMappings.lpc] = (
    <td key={`pfd-target-${data.id}-lpc`} className='text-right'>
      {fixedFloatAndCommas(data.lpc)}
    </td>
  );
  table[labelMappings.bpc] = <td key={`pfd-target-${data.id}-bpc`} className='text-right'>{fixedFloatAndCommas(data.bpc)}</td>;
  return table;
};

const checkStatus = (config) => {
  const { sbd, focusedSKU } = config;
  labelConfig.forEach((d) => {
    if (d.label === labelMappings.sbdCount) {
      d.show = sbd;
    }
    if (d.label === labelMappings.fskuCount) {
      d.show = focusedSKU;
    }
  });
};

const TableBody = ({ data, config }) => {
  const tableBody = returnTableBody(data);
  checkStatus(config);
  return <tr key={data.id}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

const TableHeaderConfig = ({ config, onHeaderClick, dynamicHeader }) => {
  checkStatus(config);
  return (
    <TableHeader
      headerDetails={dynamicHeader ? getUpdatedLabel(labelConfig) : labelConfig}
      onHeaderClick={onHeaderClick}
    />
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
};

TableHeaderConfig.propTypes = {
  config: PropTypes.instanceOf(Object).isRequired,
  onHeaderClick: PropTypes.func,
  dynamicHeader: PropTypes.bool,
};

TableHeaderConfig.defaultProps = {
  onHeaderClick: () => null,
  dynamicHeader: false,
};

export {
  TableHeaderConfig as TableHeader, TableBody, filterConfig, breadCrumbConfig
};
