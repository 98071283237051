import React from 'react';
import PropTypes from 'prop-types';
import { Form, CustomSelect } from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { RETURN_TYPE } from '../../../../../common/DomainConfig';
import CancelStyled from './CancelStyled';
import { reasonsList } from '../../../invoiced/detail/config';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleDropDownChange: () => null,
};
const CancelView = ({ ...props }) => {
  const { data, refsObj, handleDropDownChange } = props;
  return (
    <CancelStyled>
      <Form>
        <CustomSelect
          ref={(ref) => (refsObj.reason = ref)}
          labelContent='Reason'
          name='reason'
          placeholder='Reason'
          className='custom-select'
          options={reasonsList}
          getOptionValue={({ reason }) => reason}
          getOptionLabel={({ reason }) => reason}
          onChange={(event) => handleDropDownChange(event.reason, ['reason'])}
          value={reasonsList.filter(({ reason }) => reason === data.reason)}
          isSearchable={false}
        />
      </Form>
      {data.type === RETURN_TYPE.COMPLETE && (
        <span>
          All orders will be
          <b> cancelled</b>, Proceed ?
        </span>
      )}
    </CancelStyled>
  );
};

CancelView.propTypes = propTypes;

CancelView.defaultProps = defaultProps;

const ReceivedFormWithErrorAndLoading = withLoading(CancelView);

export default ReceivedFormWithErrorAndLoading;
