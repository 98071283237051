import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import MonthlyDetail from './monthyDetail';
import TableCard from '../../dashboard/tableCard';
import CallageChart from '../../dashboard/callageChart';
import SalesChart from '../../dashboard/salesChart';
import UserDetails from '../../dashboard/userDetails';
import DseDetails from '../../dashboard/dseDetails';
import UserStatusWrapper from '../../dashboard/userStatusWrapper/UserStatusWrapper';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import DashWrapper from './DashWrapper';
import { userSpecificHeaderTitle } from './config';
import { JWT } from '../../../environment';


const propTypes = {
  userWiseView: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  dimensions: PropTypes.instanceOf(Object),
  handleModuleClick: PropTypes.func.isRequired,
  syncTime: PropTypes.string,
  displayGoldenPoint: PropTypes.bool,
};

const defaultProps = {
  dimensions: {},
  syncTime: '',
  displayGoldenPoint: false,
};

const View = ({ data, dimensions, handleModuleClick, syncTime ,userWiseView,sbdPoints ,displayGoldenPoint,userStateDetail }) => {

  const { tableDetail, userStatus, callage, sales,userStateDetails } = userWiseView;

  const buSettings = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.BU_SETTINGS));
  return (
    <DashWrapper className='tas'>
      <div className='row'>
        <div className='col-lg-4'>
          <UserDetails syncTime={syncTime} title={`${data?.user?.fullName || ''}`} />
        </div>
        <div className='col-lg-8'>
          <MonthlyDetail data={data?.monthlyDetail}/>
        </div>
      </div>
      <div className='row chart'>
        <div className='col-lg-6 callage'>{<CallageChart dimensions={dimensions} data={data?.callage}  tasUser/>}</div>
        <div className='col-lg-6 pointer'>
          <SalesChart
            dimensions={dimensions}
            data={data.sales}
            unmodifiedSales={data.unmodifiedSales}
            TASSalesChart
            onModuleClick={handleModuleClick}
          />
        </div>

      </div>
      <div className='row'>
        <div className='col-lg-12'>
          { userStateDetails ||<DseDetails data={userStateDetail} displayGoldenPoint={displayGoldenPoint} title ='TAS Leaderboard (MTD)' tasUser/>}
        </div>
      </div>
      {userStatus || <UserStatusWrapper dimensions={dimensions} data={data?.userStatus} withoutUserLabel linkStatus/>}
      <div className='row'>
        <div className='col-lg-6'>
          <TableCard title='Brand Distribution' dataList={data?.brandDistributor} userSpecificHeaderTitle={userSpecificHeaderTitle}/>
        </div>
        {buSettings.sbd &&<div className='col-lg-6'>
          <TableCard title='SBD By Brand (MTD)' dataList={sbdPoints?.sbdRows|| []} userSpecificHeaderTitle={userSpecificHeaderTitle}/>
        </div>}
      </div>
    </DashWrapper>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;
const LoadingView = withErrorAndLoadingScreen(View);

export default LoadingView;
