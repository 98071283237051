import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { configuration } from '../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';

const title = 'System Alert';

const breadCrumb = [configuration];

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
    title: element.title || '',
    territoryId: (element.Territory ? element.Territory.id : element.territoryId) || '',
  }),
};

const crudRequest = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.MESSAGE,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.MESSAGE,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export {
  title, breadCrumb as breadCrumbConfig, crudRequest as crudRequestConfig, formConfig
};
