import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import classNames from 'classnames';
import { MessageStyled } from '../Input/TextIndex';
import { ENABLE_SWITCH } from '../../data/enums/ErrorMessage';
import { Icon } from '../../v4/components';

const propTypes = {
  /**
   * @default ''
   * children passed along button
   */
  id: PropTypes.string,
  /**
   * @default ''
   * children passed along button
   */
  className: PropTypes.string,
  /**
   * @default false
   * active state for checkbox.
   */
  disabled: PropTypes.bool,
  /**
   * @default null
   * children passed within button
   */
  children: PropTypes.node,
  /**
   * weather swtich is checked
   */
  checked: PropTypes.bool,
  /**
   * @default ''
   * children passed along button
   */
  labelText: PropTypes.string,
  /**
   * @default null
   * on Toggle Switch Change
   */
  onToggle: PropTypes.func,
  errorMessage: PropTypes.string,
  /**
   * @default ''
   * rule for validation: match , isEmail, ...
   */
};

const defaultProps = {
  id: '',
  className: '',
  labelText: '',
  disabled: false,
  checked: false,
  children: undefined,
  onToggle: () => null,
  name: undefined,
  errorMessage: ENABLE_SWITCH,
};

const ToggleSwitch = ({
  id,
  checked,
  className,
  disabled,
  labelContent,
  onToggle,
  name,
  errorMessage,
  enableErrorDisplay,
}) => {
  const classes = classNames(
    {
      disabled,
    },
    className,
  );
  return (
    <Fragment>
      <div className='form-input switch'>
        <label className='custom-control-label-text'>
          {labelContent !== undefined ? <span>{labelContent}</span> : ''}
        </label>
        <Switch
          onChange={(checkStatus, event) => onToggle(checkStatus, event)}
          checked={checked}
          uncheckedIcon={false}
          checkedIcon={false}
          id={id}
          className={className}
          name={name}
          onColor='#0E69FF'
          offColor='#A7A9BC'
          handleDiameter={20}
          disabled={disabled}
          height={24}
          width={40}
        />
        {enableErrorDisplay && (
          <MessageStyled className='form-error'>
            <span className='error-message'>
              {errorMessage && <Icon iconName='exclamation-full' />}
              {errorMessage}
            </span>
          </MessageStyled>
        )}
      </div>
    </Fragment>
  );
};

ToggleSwitch.propTypes = propTypes;

ToggleSwitch.defaultProps = defaultProps;

export default ToggleSwitch;
