import PropTypes from 'prop-types';
import { PanelStyled, PanelHeader } from '../../common/configuration/index';
import { dataProps } from '../../../data/enums/Component';
import withLoading from '../../../utils/composition/withLoading';
import BuStyled from './BuStyled';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { BreadCrumb } from '../../../v4/components';
import { TabsComponent } from './component/Tabs';

const propTypes = {
  data: dataProps.value,
  onInputChange: PropTypes.func.isRequired,
  onFundamentalChange: PropTypes.func.isRequired,
  handleDropDownChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  onTargetChange: PropTypes.func.isRequired,
  onSalesChange: PropTypes.func.isRequired,
  breadCrumb: PropTypes.arrayOf(Object),
  crudMode: PropTypes.string.isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  handleButtonCancel: PropTypes.func.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  handleMeasurementAdd: PropTypes.func.isRequired,
  handleMeasurementCancel: PropTypes.func.isRequired,
  DSESequenceData: PropTypes.arrayOf(Object).isRequired,
  orderTakingProcessSequenceData: PropTypes.arrayOf(Object).isRequired,
  enableOrderTakingProcess: PropTypes.bool,
  MerchandisingSequenceData: PropTypes.arrayOf(Object).isRequired,
  procedures: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  params: PropTypes.shape({
    params: {
      id: PropTypes.string,
    },
  }).isRequired,
  callHolidaySection: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  breadCrumb: [],
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const {
    breadCrumb,
    crudMode,
    handleEditIconClick,
    handleButtonCancel,
    handleButtonSubmit,
    permission,
    loading,
    handleTabChange,
    activeTab,
    tabsList,
    areEqual,
  } = props;
  const disableEditButton = crudMode === EVENT_OPERATION.UPDATE;
  const hideEditButton = crudMode !== EVENT_OPERATION.UPDATE;
  return (
    <BuStyled className="bu">
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <h2>BU Settings</h2>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className={`section-content `}>
        <TabsComponent
          tabs={tabsList}
          handleTabChange={handleTabChange}
          activeTab={activeTab}
          handleButtonCancel={handleButtonCancel}
          handleButtonSubmit={handleButtonSubmit}
          crudMode={crudMode}
          loading={loading}
          hideEditButton={hideEditButton}
          disableEditButton={disableEditButton}
          permission={permission}
          handleEditIconClick={handleEditIconClick}
          areEqual={areEqual}
        />
      </div>
    </BuStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
