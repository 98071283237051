import styled from 'styled-components';

const DSETableStyled = styled.div`
  .dse-table-wrap {
    overflow: auto;
    table {
      width: 100%;
      border: 0;
      border-spacing: 0 8px;
      border-collapse: separate;
      margin-top: -4px;

      td,
      th {
        text-align: center;
        white-space: nowrap;
        &:first-child,
        &:nth-child(2) {
          text-align: left;
        }

        &:nth-last-child(2),
        &:last-child {
          text-align: right;
        }
      }

      thead {
        tr {
          height: 17px;

          th {
            background-color: transparent;
            border: 0;
            font-size: 12px;
            line-height: 15px;
            color: #6b6c7e;
            font-weight: 400;
            padding: 0 16px 0 16px;

            &:first-child {
              padding: 0 16px 0 0;
            }
          }

          &:hover {
            background-color: transparent;
          }
        }
      }

      tbody {
        tr {
          height: unset;
          /* -moz-box-shadow: 0 1px 4px 0 rgba(39, 40, 51, 0.12);
          -webkit-box-shadow: 0 1px 4px 0 rgba(39, 40, 51, 0.12);
          box-shadow: 0 1px 4px 0 rgba(39, 40, 51, 0.12); */
          border-radius: 4px;
          &:hover {
            background-color: transparent;
          }
          td {
            border: 0;
            padding: 8px;
            color: #272833;
            font-weight: 400;
            padding: 4px 16px;
            border-top: 1px solid rgba(46, 91, 255, 0.08);
            border-bottom: 1px solid rgba(46, 91, 255, 0.08);
            &:last-child {
              border-right: 1px solid rgba(46, 91, 255, 0.08);
              border-top: 1px solid rgba(46, 91, 255, 0.08);
              border-bottom: 1px solid rgba(46, 91, 255, 0.08);
              border-radius: 0 4px 4px 0;
            }
            &:first-child {
              font-weight: 600;
              border-radius: 4px 0 0 4px;
              padding: 4px 8px 4px 14px;
              white-space: nowrap;
              width: auto;
              border-top: 1px solid rgba(46, 91, 255, 0.08);
              border-bottom: 1px solid rgba(46, 91, 255, 0.08);

              .user-name {
                padding-top: 4px;
              }
              .user-image {
                height: 24px;
                width: 24px;
                padding: 5px;
              }
              img {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                margin-right: 8px;
                border: 1px solid #cdced9;
              }
            }
            .light {
              border-radius: 2px;
              font-size: 9px;
              line-height: 8px;
              min-width: 70px;
            }
            .active {
              border: 0;
              padding-left: 8px;
              .light {
                border-color: #47c464;
                color: #287d3c;
              }
            }
            .inactive {
              border: 0;
              padding-left: 8px;
              .light {
                border-color: #ff8f39;
                color: #b95000;
              }
            }

            .onleave {
              border: 0;
              padding-left: 8px;
              .light {
                color: rgb(218, 20, 20);
                border-color: rgba(218, 20, 20, 0.4);
              }
            }

            &.dse-disabled {
              color: #aeb0c1;

              p {
                background-color: #aeb0c1 !important;
              }
            }
          }
        }
      }

      tfoot {
        tr {
          border: 0;

          &:hover {
            background-color: transparent;
          }

          td {
            border: 0;
            color: #272833;
            font-weight: 600;
            padding: 9px 16px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
`;

export default DSETableStyled;
