import { sales as salesRoute, collection as collectionRoute } from '../../../../data/enums/Route';
import { FILTER } from '../../../../data/enums/filter';
import { getFilterConfig } from '../../../../utils/filter';

const title = 'Post Dated Cheques';

const breadCrumbConfig = [salesRoute, collectionRoute];

const POST_DATED_CHEQUES_TABS = {
  PENDING: 'pending',
  RECEIVED: 'cheque_received',
  RECONCILED: 'reconciled',
  DISHONORED: 'dishonored',
};

const tabMapper = {
  0: POST_DATED_CHEQUES_TABS.PENDING,
  1: POST_DATED_CHEQUES_TABS.RECEIVED,
  2: POST_DATED_CHEQUES_TABS.RECONCILED,
  3: POST_DATED_CHEQUES_TABS.DISHONORED,
};

const filterConfig = getFilterConfig([FILTER.SUB_D, FILTER.ROUTE, FILTER.DSE, FILTER.AMOUNT, FILTER.BANK]);

export {
  title, breadCrumbConfig, tabMapper, POST_DATED_CHEQUES_TABS, filterConfig
};
