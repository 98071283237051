import { compose } from 'recompose';
import Dashboard from './Dashboard';
import withRequestTracker from '../../utils/composition/withRequestTracker';
import { query, mutation } from './API';

const composedDashboard = compose(
  withRequestTracker({
    query,
    mutation,
  }),
)(Dashboard);

export default composedDashboard;
