import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { FILTER } from '../../../data/enums/filter';
import { configuration as configurationRoute } from '../../../data/enums/Route';
import { refGenerator } from '../../../utils';
import { getFilterConfig } from '../../../utils/filter';

const title = 'Presentation';

const breadCrumbConfig = [configurationRoute];

const filterConfig = getFilterConfig([FILTER.BRAND]);

const formConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      file: element.file || {},
      title: element.title || '',
      brandId: element.brandId || 0,
    }),
    [FORM_CONFIG.TITLE]: `Upload ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity', 'brand', 'title']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'DELETE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({ id: element.id || '' }),
  },
};

export {
  title, breadCrumbConfig, filterConfig, formConfig
};
