import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE, DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const GET_APPROVAL_REQUESTS = gql`
  query approvalRequests($type: approvalProcess!, $limit: Int, $offset: Int, $filter: FilterInput) {
    approvalRequests(type: $type, limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        processId
        createdBy
        requestedBy
        requestedTo
        active
        status
        lastUpdatedDate
        updateCount
        unsuccessfulOutletImage {
          src: image
          thumbnail
        }
        geolocationChangeData {
          outletId
          routeId
          townId
          image {
            src: image
            thumbnail
          }
          correctLocation {
            latitude
            longitude
          }
          proposedLocation {
            latitude
            longitude
          }
          remarks
          RetailOutlet {
            title
            Route {
              title
            }
            Town {
              title
            }
            imageUrl {
              src: image
              thumbnail
            }
          }
        }
      }
      count
    }
  }
`;

const GEO_LOCATION_UPDATE_HISTORY = gql`
  query geolocationUpdateHistory($outletId: Int!) {
    geolocationUpdateHistory(outletId: $outletId) {
      updatedDate
      updatedTime
      requestedBy
    }
  }
`;

const BULK_UPDATE_APPROVAL_REQUEST = graphql(
  gql`
    mutation bulkUpdateApprovalRequest($ids: [Int]!, $processId: Int!, $status: approvalStatus!) {
      bulkUpdateApprovalRequest(ids: $ids, processId: $processId, status: $status) {
        success
        failed
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      bulkUpdateApprovalRequest: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);
const query = {
  getApprovalRequests: GET_APPROVAL_REQUESTS,
  geolocationUpdateHistory: GEO_LOCATION_UPDATE_HISTORY,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  bulkUpdateApprovalRequest: BULK_UPDATE_APPROVAL_REQUEST,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  TOGGLE_STATE, GET_APPROVAL_REQUESTS, BULK_UPDATE_APPROVAL_REQUEST, DOWNLOAD_REPORT
};

export { query, mutation };
