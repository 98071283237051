import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { support } from '../../../data/enums/Route';

const breadCrumbConfig = [support];
const title = 'Category Change Request';
const filter = getFilterConfig([FILTER.REQUESTS_STATUS, FILTER.STL, FILTER.ASM, FILTER.TOWN, FILTER.SUB_D]);

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
  }),
  title: 'Confirm',

};

export {
  breadCrumbConfig, title, filter as filterConfig, formConfig
};
