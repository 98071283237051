import { TableBody, TableHeader } from './tableConfig';
import { Pagination } from '../../../../../../v4/components';
import withLoading from '../../../../../../utils/composition/withLoading';
import BulkDelete from '../../../../../../v4/components/BulkDelete/BulkDelete';
import { STATEFUL_ENTITIES } from '../../../../../../data/enums/GraphQL';

const SkuGroupTableList = props => {
  const { data, onTableBodyClick, handleIconClick, pagination, onPageChange, permission, onBulkDelete , toggleState} = props;
  return (
    <>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.SKUGroupMaster}
        handler={onBulkDelete}
        toggleState = {toggleState}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: checkedList.length,
                primaryCheckboxHandler,
                permission,
              })}
              <tbody>
                {data.list.map(subD =>
                  TableBody({
                    data: subD,
                    checkedList: checkedList,
                    onViewClick: onTableBodyClick,
                    onIconClick: handleIconClick,
                    secondaryCheckboxHandler,
                    permission,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </>
  );
};

const SkuGroupTableListWithLoading = withLoading(SkuGroupTableList);

export default SkuGroupTableListWithLoading;
