import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  DOWNLOAD_REPORT, GET_DISTRIBUTORS, UPLOAD
} from '../../common/GqlAPI';

const STOCK_ADJUSTMENT_FRAGMENT = gql`
    fragment StockAdjustmentFragment on StockAdjustment {
        skuId: skuid
        distributorId: distributorid
        balance
        sku{
            title
            discontinue_sku
        }
        reason
        lastAdjustment: lastadjustment
        lastDate: lastdate
        negative
        skuBatchId: skubatchid
    }
`;

const GET_STOCK_ADJUSTMENT_LIST = gql`
    query getStockAdjustmentList($offset: Int, $limit: Int, $filter: FilterInput) {
        stockAdjustments(offset: $offset, limit: $limit, filter: $filter){
            rows{
                ...StockAdjustmentFragment
            }
            count
        }
    }
    ${STOCK_ADJUSTMENT_FRAGMENT}
`;

const GET_STOCK_ADJUSTMENT_BATCH_DETAILS = gql`
query getStockAdjustmentBatchDetail($distributorId: Int
$skuId: Int
$filter: FilterInput){
  getStockAdjustmentBatchDetail(distributorId:$distributorId, skuId:$skuId, filter:$filter){
    adjustment
    isBlocked
    damages
    shortages
    expiry
    reason
    balance
    damagedStock
    expiredStock
    shortageStock
    binWiseStock
    SKUBatch {
      id
      batchName
      manufactureDate
      expiryDate
    }
  }
}
`;

const ADJUST_STOCK = graphql(gql`
    mutation ($input: StockAdjustmentInput!) {
        createStockAdjustment(input: $input)
        {
            ...StockAdjustmentFragment
        }
    } ${STOCK_ADJUSTMENT_FRAGMENT}
`, {
  props: ({ mutate }) => ({
    createStockAdjustment: (variables) => mutate({
      variables,
    }),
  }),
});

const GET_LAST_LEVEL_WAREHOUSE_DETAILS = gql`
query getLastLevelWarehouseDetails($distributorId: Int!) {
  getLastLevelWarehouseDetails(distributorId: $distributorId) {
    id
    binLocationNumber
    levelId
    title
  }
}`

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getStockAdjustmentList: GET_STOCK_ADJUSTMENT_LIST,
  getStockAdjustmentBatchDetail: GET_STOCK_ADJUSTMENT_BATCH_DETAILS,
  getLastLevelWarehouseDetails: GET_LAST_LEVEL_WAREHOUSE_DETAILS,
};
const mutation = {
  createStockAdjustment: ADJUST_STOCK,
  upload: UPLOAD,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, ADJUST_STOCK
};
