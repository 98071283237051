import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';
import { ORDER_HISTORY_VIEW } from '../../../../../../data/enums/GraphQL';

const propTypes = {
  orders: PropTypes.instanceOf(Array),
  viewType: PropTypes.string,
};

const defaultProps = {
  orders: [],
  viewType: ORDER_HISTORY_VIEW.BRAND,
};

const CallOrderGroup = ({ ...props }) => {
  const { orderGroup, viewType } = props;
  return orderGroup.map((invoicedOrder) => (
    <tr>
      <td>{invoicedOrder.title}</td>
      <td className='text-right'>
        <p>{fixedFloatAndCommas(invoicedOrder.quantity)}</p>
      </td>
      {viewType === ORDER_HISTORY_VIEW.DATE && (
        <>
          <td className='text-right'>{fixedFloatAndCommas(invoicedOrder.Rates)}</td>
          <td className='text-right'>
            <p>{fixedFloatAndCommas(invoicedOrder.Rates * invoicedOrder.quantity)}</p>
          </td>
        </>
      )}
    </tr>
  ));
};

const CallDetails = ({ ...props }) => {
  const { orders, viewType } = props;
  return (
    <table>
      <thead>
        <tr>
          <th>SKU</th>
          <th className={viewType === ORDER_HISTORY_VIEW.DATE ? 'text-right' : ''}>Quantity</th>
          {viewType === ORDER_HISTORY_VIEW.DATE && (
            <>
              <th className='text-right'>Rate</th>
              <th>Value</th>
            </>
          )}
        </tr>
      </thead>
      <tbody className='cursor-pointer'>{<CallOrderGroup orderGroup={orders} viewType={viewType} />}</tbody>
    </table>
  );
};

CallDetails.propTypes = propTypes;

CallDetails.defaultProps = defaultProps;

export default CallDetails;
