import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as queryService from '../../base/query.service';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { isError } from '../../common/HelperFunctions';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { PanelStyled } from '../../common/configuration';
import PageHeader from '../../base/PageHeader';
import { breadCrumbConfig } from '../config';
import { skuCoverage } from '../../common/DomainConfig';
import { filterConfig } from '../sku-coverage-old/config';
import SKUCoverageView from './View';
import withAlert from '../../../utils/composition/withAlert';
import { SKU_DISTRIBUTION } from '../../../data/enums/Route';
import { TableBody, TableHeader } from './tableConfig';
import SkuCoverageStyled from './SkuCoverageStyled';

const propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  downloadReport: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getSKUCoverageReport: PropTypes.func.isRequired,
};

const defaultProps = {
  downloadReport: () => null,
  serverResponseWaiting: false,
};

class SKUCoverage extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
    };

    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getSKUCoverageReport } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getSKUCoverageReport(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.getSkuCoverage && response.data.getSkuCoverage.rows) || [];
          data.total = (response.data.getSkuCoverage && response.data.getSkuCoverage.count) || 0;
          // if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleRowClick = row => {
    const { history } = this.props;

    history.push(`/${SKU_DISTRIBUTION}/${row.id}`, { title: row.name || 'Sku name', skuId: row.id });
  };

  render() {
    const { data, queryParameters, display } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <SkuCoverageStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: skuCoverage.title,
                create: false,
                download: true,
                filter: false,
                search: true,
                date: true,
              }}
              downloadConfig={{
                domain: skuCoverage.domain,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              resetFilter={this.basePaginationService.resetFilter}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <SKUCoverageView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            onIconClick={() => {}}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
            handleRowClick={this.handleRowClick}
            TableHeader={TableHeader}
            TableBody={TableBody}
          />
        </div>
      </SkuCoverageStyled>
    );
  }
}

SKUCoverage.propTypes = propTypes;
SKUCoverage.defaultProps = defaultProps;

export default withAlert()(SKUCoverage);
