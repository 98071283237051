import React, { Component, Fragment } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Icon, Button } from '../../../../../../v4/components';
import { CustomSelect, Input } from '../../../../../../components';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import { EMPTY } from '../../../../../../data/enums/ErrorMessage';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import withAlert from '../../../../../../utils/composition/withAlert';
import { debouncer } from '../../../../../../utils/formHandlers';

class BinLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouseDetails: {},
      binLocationLevelDetails: {
        1: [],
      },
      binLocationNumber: '',
      hideMenu: true,
      binLocationData: {},
      warehouseLevelDetails: props?.data?.warehouseLevelDetails ?? [
        {
          binLocationData: [],
          binLocationLevelDetails: [],
          warehouseLevelId: 0,
          quantity: 0,
          binLocationNumber: 0,
        },
      ],
      binLocationEdit: false,
      binLocationEditId: 0,
    };
  }

  validateBinTransfer = () => {
    const { getValidateBinTransfer, displayAlert, data } = this.props;
    const { binLocationData } = this.state;
    const BinId = Object.values(binLocationData);
    const lastBinId = BinId[BinId?.length - 1];
    const payload = {
      skuBatchId: data?.skuBatchId,
      toBinLocationId: lastBinId,
    };
    return new Promise((resolve, reject) => {
      getValidateBinTransfer(
        { ...payload },
        {
          handleSuccess: response => {
            if (response?.data?.validateBinTransfer) {
              resolve(true);
            } else {
              displayAlert(ALERT_TYPE.DANGER, response?.errors?.[0]);
              resolve(false);
            }
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
            resolve(false);
          },
        },
      );
    });
  };

  componentDidMount = () => {
    const { getWarehouseLevels, getWarehouseLevelDetails, distId } = this.props;
    getWarehouseLevels(
      {
        filter: {
          sort: [
            {
              sortBy: 'id',
              order: 'ASC',
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          const warehouseLevels = response.data.getWarehouseLevels.rows;
          this.setState(
            {
              warehouseDetails: warehouseLevels,
            },
            () => {
              getWarehouseLevelDetails(
                {
                  distId,
                  levelId: warehouseLevels.length > 0 ? warehouseLevels[0].id : null,
                  parentId: null,
                },
                {
                  handleSuccess: responseLevel => {
                    this.setState({
                      binLocationLevelDetails: {
                        [warehouseLevels[0].id]: responseLevel.data.getWarehouseLevelDetails.rows || [],
                      },
                    });
                  },
                  handleError: err => {
                    console.log(err);
                  },
                },
              );
            },
          );
        },
        handleError: err => {
          console.log(err);
        },
      },
    );
  };

  getWarehouseLevelDetails = id => {
    const { getWarehouseLevelDetails, distId } = this.props;
    const { binLocationLevelDetails, binLocationData, warehouseDetails } = this.state;

    this.setState({
      binLocationNumber:
        binLocationLevelDetails[id].filter(d => d.id === binLocationData[id])[0].binLocationNumber || '',
    });

    getWarehouseLevelDetails(
      {
        distId,
        levelId: id + 1,
        parentId: binLocationData[id],
      },
      {
        handleSuccess: response => {
          const resetIds = attr =>
            warehouseDetails.reduce((a, b) => {
              if (b.id !== id) {
                return {
                  ...a,
                  [b.id]: attr === 'empty' ? '' : [],
                };
              }
            }, {});

          this.setState({
            binLocationData: {
              ...binLocationData,
              ...resetIds('empty'),
            },
            binLocationLevelDetails: {
              ...binLocationLevelDetails,
              ...resetIds('array'),
              [id + 1]: response.data.getWarehouseLevelDetails.rows,
            },
          });

          const { warehouseLevelDetails, binLocationEditId, binLocationEdit } = this.state;
          if (binLocationEdit) {
            warehouseLevelDetails[binLocationEditId].binLocationData = {
              ...binLocationData,
              ...resetIds('empty'),
            };
            warehouseLevelDetails[binLocationEditId].binLocationLevelDetails = {
              ...binLocationLevelDetails,
              ...resetIds('array'),
              [id + 1]: response.data.getWarehouseLevelDetails.rows,
            };
            this.setState({
              warehouseLevelDetails,
            });
          }
        },
        handleError: err => {
          console.log(err);
        },
      },
    );
  };

  handleDropDownChange = (id, levelId, attr) => {
    const { binLocationData } = this.state;
    this.setState(
      {
        binLocationData: {
          ...binLocationData,
          [levelId]: id,
        },
      },
      () => {
        this.getWarehouseLevelDetails(levelId);
      },
    );
  };

  mappedAndUpdateWarehouseLevelDetails = details => {
    const { updateState, data } = this.props;
    updateState({
      ...data,
      warehouseLevelDetails: details,
    });
  };

  onButtonClick = async (e, click) => {
    const { displayAlert } = this.props;
    e.preventDefault();
    const {
      binLocationData,
      binLocationNumber,
      warehouseLevelDetails,
      binLocationLevelDetails,
      binLocationEdit,
      binLocationEditId,
    } = this.state;
    if (click === 'ok' || binLocationEdit) {
      const validationMsg = 'All BIN Location must be inserted';
      if (Object.keys(binLocationData).length === 0) {
        displayAlert(ALERT_TYPE.WARNING, validationMsg);
        return;
      }

      for (const key in binLocationData) {
        if (binLocationData[key] === null || binLocationData[key] === '') {
          displayAlert(ALERT_TYPE.WARNING, validationMsg);
          return;
        }
      }
      // await this.validateBinTransfer().then(valid => {
      //   if (valid) {
      if (binLocationEdit) {
        warehouseLevelDetails[binLocationEditId].binLocationNumber = binLocationNumber;
        this.setState(
          {
            warehouseLevelDetails,
          },
          () => this.resetBinModal(),
        );
        return;
      }
      this.setState(
        {
          warehouseLevelDetails: Object.keys(binLocationData).pop()
            ? [
                ...warehouseLevelDetails,
                {
                  binLocationData,
                  binLocationLevelDetails,
                  warehouseLevelId: Object.keys(binLocationData).pop(),
                  binLocationNumber,
                },
              ]
            : [],
        },
        () => {
          const { warehouseLevelDetails } = this.state;
          this.resetBinModal();
          this.mappedAndUpdateWarehouseLevelDetails(warehouseLevelDetails);
        },
      );
    }
    //   });
    //   return;
    // }
    this.resetBinModal();
  };

  resetBinModal = () => {
    this.setState({
      hideMenu: true,
      binLocationData: {},
      binLocationNumber: '',
    });
  };

  deleteLocation = number => {
    const { warehouseLevelDetails } = this.state;
    this.setState(
      {
        warehouseLevelDetails: warehouseLevelDetails.filter(d => d.binLocationNumber !== number),
      },
      () => {
        const { warehouseLevelDetails } = this.state;
        this.mappedAndUpdateWarehouseLevelDetails(warehouseLevelDetails);
      },
    );
  };

  handleInputChange = (event, index) => {
    const { warehouseLevelDetails } = this.state;
    const { displayAlert, data } = this.props;
    warehouseLevelDetails[index].quantity = Number(event?.target?.value) ?? 0;

    const totalQuantity = warehouseLevelDetails.map(d => d.quantity).reduce((a, b) => a + b);

    if (totalQuantity > data.quantity) {
      displayAlert(ALERT_TYPE.WARNING, `Must not be greater than main quantity ${data.quantity}`);
    }

    this.setState(
      {
        warehouseLevelDetails,
      },
      () => {
        this.mappedAndUpdateWarehouseLevelDetails(warehouseLevelDetails);
      },
    );
  };

  editBinLocation = index => {
    const { warehouseLevelDetails } = this.state;
    this.setState({
      hideMenu: false,
      binLocationData: warehouseLevelDetails[index].binLocationData,
      binLocationNumber: warehouseLevelDetails[index].binLocationNumber,
      binLocationLevelDetails: warehouseLevelDetails[index].binLocationLevelDetails,
      binLocationEditId: index,
      binLocationEdit: true,
    });
  };

  render() {
    const { update, data, enableErrorDisplay, type, refsObj } = this.props;
    const {
      warehouseDetails,
      binLocationNumber,
      hideMenu,
      binLocationData,
      binLocationLevelDetails,
      warehouseLevelDetails,
    } = this.state;

    const totalQuantity =
      warehouseLevelDetails.map(d => d.quantity).length > 0 &&
      warehouseLevelDetails.map(d => d.quantity).reduce((a, b) => a + b);
    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <span className="bin_location_title">BIN location</span>
          </Col>
        </Row>
        {warehouseLevelDetails.length > 0 &&
          warehouseLevelDetails.map((d, i) => (
            <div key={`${d.binLocationNumber}-${i}`} className={`bin_location_li ${i === 0 ? 'first-li' : ''}`}>
              <Input
                type="number"
                labelContent="Quantity"
                value={d.quantity || 0}
                placeholder="Quantity"
                className="quantity"
                ref={ref => (refsObj.binLocationQuantity = ref)}
                onChange={event => this.handleInputChange(event, i)}
                enableValidation
                enableErrorDisplay={
                  totalQuantity < data.quantity ||
                  d.quantity > data.quantity ||
                  totalQuantity > data.quantity ||
                  enableErrorDisplay
                }
                errorMessage={
                  data.quantity && !isNaN(data.quantity) && d.quantity !== undefined
                    ? 'Must not be greater than main quantity'
                    : EMPTY
                }
                rule="isInt"
                argument={{
                  max: data.quantity,
                  min: 0,
                }}
              />
              <Input
                type="text"
                labelContent="Location"
                value={d.binLocationNumber}
                placeholder="Location"
                className="location"
              />
              <div className="edit_close">
                <Icon
                  iconName="pencil"
                  disabled={type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.APPROVE}
                  onClick={() => this.editBinLocation(i)}
                />
                <Icon iconName="times" onClick={() => this.deleteLocation(d.binLocationNumber)} />
              </div>
            </div>
          ))}
        <Row>
          <Col md={3} className="mt-24">
            <Button
              type="button"
              disabled={data.quantity === 0 || update.type === EVENT_OPERATION.APPROVE}
              iconName="plus"
              iconBtnSmall
              secondary
              onClick={e => {
                e.preventDefault();
                this.setState({ hideMenu: false, binLocationEdit: false });
              }}
            />
          </Col>
          {!hideMenu && (
            <div className="bin__location__menu">
              <header className="dialog-header">
                <h2>Add Bin location</h2>
                <span style={{ cursor: 'pointer' }}>
                  <Icon iconName="times" onClick={this.resetBinModal} />
                </span>
              </header>
              <div className="bin__location__type">
                {warehouseDetails.map(d => (
                  <CustomSelect
                    key={d.title}
                    enableValidation
                    options={binLocationLevelDetails[d.id]}
                    labelContent={d.title}
                    className="custom-select"
                    placeholder="Select"
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    onChange={event => this.handleDropDownChange(event.id, d.id, event.title)}
                    value={
                      binLocationLevelDetails[d.id]
                        ? binLocationLevelDetails[d.id].filter(level => level.id === binLocationData[d.id])
                        : []
                    }
                  />
                ))}
                <div className="bin__location__number">
                  <span className="bin_location">
                    {' '}
                    <Icon iconName="info-circle" />
                    <span className="num">{binLocationNumber}</span>
                  </span>
                  <span className="bin_no"> : BIN Location Number</span>
                </div>
              </div>
              <footer className="bin__location_buttons">
                <Button primary onClick={e => this.onButtonClick(e, 'ok')} className="ok-btn">
                  Ok
                </Button>
                <Button secondary onClick={this.resetBinModal} className="cancel-btn">
                  Cancel
                </Button>
              </footer>
            </div>
          )}
        </Row>
      </Fragment>
    );
  }
}

export default withAlert()(BinLocation);
