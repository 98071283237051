import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { normalPresentor } from '../../../../utils/date';
import { Button, Icon } from '../../../../v4/components';

const detailLabelMappings = {
  sku: 'sku',
  batch: 'batch',
  mfgDate: 'mfgDate',
  selable: 'selable',
  damages: 'damages',
  expiry: 'expiry',
  options: 'options',
};

const detailsLabelConfig = [
  {
    id: 2,
    label: detailLabelMappings.sku,
    title: 'Item',
    sortable: true,
    classNames: 'fixed-width one second',
    show: true,
  },
  {
    id: 1,
    label: detailLabelMappings.batch,
    sortable: true,
    classNames: 'fixed-width one first',
    title: 'Batch',
    show: true,
  },
  {
    id: 3,
    label: detailLabelMappings.mfgDate,
    title: 'MFG Date',
    sortable: true,
    classNames: 'fixed-width one third',
    show: true,
  },
  {
    id: 4,
    label: detailLabelMappings.openingBalance,
    title: 'Saleable',
    align: true,
    classNames: ' sellable  width_300',
    show: true,
  },
  {
    id: 7,
    label: detailLabelMappings.damages,
    title: 'Damage',
    align: true,
    show: true,
    classNames: 'three  width_300',
  },
  {
    id: 8,
    label: detailLabelMappings.expiry,
    title: 'Expiry',
    align: true,
    show: true,
    classNames: 'three  width_300',
  },
  {
    id: 11,
    label: detailLabelMappings.options,
    title: '',
    align: true,
    show: true,
    classNames: ' four width_30',
  },
];

const returnDetailTableBody = (data, handleTransferButton) => {
  const table = {};
  table[detailLabelMappings.batch] = <td key={`${data.batch}-id`}>{data?.batch_name}</td>;
  table[detailLabelMappings.sku] = <td key={`${data.sku}-id`}>{data?.sku}</td>;
  table[detailLabelMappings.mfgDate] = (
    <td key={`${data.batch}-mfgDate`}>{data?.manufacture_date ? normalPresentor(data.manufacture_date) : 'N/A'}</td>
  );
  table[detailLabelMappings.openingBalance] = (
    <td key={`${data.sku_batch_id}-openingBalance`} className="text-right sellable width_300">
      {data?.closing_sellable}
    </td>
  );
  table[detailLabelMappings.damages] = (
    <td key={`${data.sku_batch_id}-damages`} className="text-right width_300">
      {data?.closing_damages}
    </td>
  );
  table[detailLabelMappings.expiry] = (
    <td key={`${data.sku_batch_id}-expiry`} className="text-right width_300">
      {data?.closing_expiry}
    </td>
  );

  table[detailLabelMappings.options] = (
    <td key={`${data.sku_batch_id}-options`} className="text-right width_30">
      <div className="btn-wrap">
        <img alt="transfer" src="/image/transfer.svg" onClick={() => handleTransferButton?.(data)} />
      </div>
    </td>
  );
  return table;
};
const DetailTableBody = ({ data, handleTransferButton }) => {
  const tableBody = returnDetailTableBody(data, handleTransferButton);

  return <Fragment>{detailsLabelConfig.map(label => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

DetailTableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

DetailTableBody.defaultProps = {
  data: {},
};

export { DetailTableBody, detailsLabelConfig };
