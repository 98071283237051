import { compose } from 'recompose';
import ReplenishmentDialog from './ReplenishmentDialog';
import { TOGGLE_STATE } from '../../../../../common/GqlAPI';
import {
  mutation, UPDATE_REPLENISHMENT_GROUP, CREATE_REPLENISHMENT_GROUP
} from './API';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const composedReplenishmentDialog = compose(
  CREATE_REPLENISHMENT_GROUP,
  UPDATE_REPLENISHMENT_GROUP,
  TOGGLE_STATE,
  withRequestTracker({ mutation }),
)(ReplenishmentDialog);

export default composedReplenishmentDialog;
