import { title } from '../config';
import { breadCrumbConfig } from '../../config';
import { TARGET_BRAND_COVERAGE } from '../../../../../data/enums/Route';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title,
    path: `/${TARGET_BRAND_COVERAGE}`,
    active: true,
  },
];

export { breadCrumb };
