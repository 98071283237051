import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Badge, Button } from '../index';
import { addMouseClickListener, removeMouseClickListener } from '../../utils/mouseEvents';
import FilterMenuStyled from './MenuStyled';
import Icon from '../Icon';

interface MenuProps {
  disabled: boolean;
  label: string;
  header: any;
  value: string | number;
  disableBody: boolean;
  body?: any;
  children: any;
  hideOnClickOutside: boolean;
  show?: boolean;
  titleWithIcon?: boolean,
  onPopoverClick: (a: boolean) => void;
}

const getMenuConfig = (label: string, value: number | string) => {
  switch (label) {
    case 'filter':
      return (
        <div className={value > 0 ? 'filter-icon active' : 'filter-icon'}>
          <Button iconBtnSmall secondary iconName="filter" onClick={() => { }} />
          {value > 0 ? <Badge className="primary" label={value} /> : null}
        </div>
      );
    case 'sort':
      return (
        <div className={value !== '' ? 'filter-icon active' : 'filter-icon'}>
          <Button iconBtnSmall secondary iconName="filter" onClick={() => { }} />
          {value !== '' ? <Badge small primary label={value} /> : null}
        </div>
      );
    case 'column':
      return (
        <div className="filter-icon">
          <Button iconBtnSmall secondary iconName="column" onClick={() => { }} />
        </div>
      );
    default:
      break;
  }
};

const Menu = ({ disabled, label, header, value, disableBody, body, children, hideOnClickOutside, show, titleWithIcon, onPopoverClick }: MenuProps) => {
  const ref = useRef(null);
  const [showBody, setShowBody] = useState(false);

  useEffect(() => {
    addMouseClickListener(event => {
      const dropDownEvent = event.target.id.includes('react-select'); // todo quick fix
      const reference: any = ref && ref.current;
      if (hideOnClickOutside && reference && !reference.contains(event.target) && !dropDownEvent) {
        setShowBody(false);
      }

    });
    return () => {
      removeMouseClickListener(() => { });
    };
  }, [hideOnClickOutside]);

  useEffect(() => {
    if (!show) {
      setShowBody(false);
    }
  }, [show])


  const headerDom = label === '' ? header : getMenuConfig(label, value);

  const handleHeaderClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setShowBody(!showBody);
    onPopoverClick(true);
  };

  return (
    <FilterMenuStyled>
      <div className={`filter-item ${disabled ? 'disabled-btn' : ''}`}>
        <span className={showBody ? 'filter-background' : ''}>
          <span className="header-dom" onClick={e => handleHeaderClick(e)}>
            {headerDom}
            {titleWithIcon && (<Icon iconName={`${!showBody ? 'angle-down' : 'angle-up'}`} />)}
          </span>
        </span>
      </div>
      {showBody && !disableBody && (
        <div className={`filter-dropdown-card ${label}`} ref={ref}>
          {body}
          {children}
        </div>
      )}
    </FilterMenuStyled>
  );
};

Menu.defaultProps = {
  label: '',
  value: 0,
  disabled: false,
  disableBody: false,
  hideOnClick: false,
  hideOnClickOutside: true,
  show: true,
  titleWithIcon: false,
  onPopoverClick: () => null,
};

export default Menu;
