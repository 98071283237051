/* global google */
class InfoWindow {
  generateInfoWindow(doOffset, name) {
    const pixelOff = doOffset ? new google.maps.Size(0, 0) : new google.maps.Size(0, 20);
    if (name) {
      if (doOffset) {
        return new google.maps.InfoWindow({
          pixelOffset: pixelOff,
          content: `<div id="infoWindow">The length of this route is: ${name / 1000}km</div>`,
        });
      } else {
        return new google.maps.InfoWindow({
          pixelOffset: pixelOff,
          content: `<div id="infoWindow">Name: ${name}</div>`,
        });
      }
    }
  }
}

const infoWindow = new InfoWindow();
export default infoWindow;
