import { getTodaysDate } from '../../../../../utils/date';
import { refGenerator } from '../../../../../utils/refGenerator';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';

const requiredList = ['product', 'quantity', 'discount'];
const formMapper = (element = {}) => ({
  id: element?.id ?? null,
  idx: element?.idx ?? null,
  product: { id: element?.product?.id, title: element?.product?.title, Rates: element?.product?.Rates },
  quantity: element.quantity ?? null,
  rate: element?.rate ?? 0,
  discount: element.discount ?? null,
  gross: element.gross ?? 0,
  grossWithVat: element?.grossWithVat ?? 0,
  taxableAmount: element?.taxableAmount ?? 0,
  netAmount: element?.netAmount ?? 0,
  taxAmount: element?.taxAmount ?? 0,
});

const crudRequest = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => {
      return {
        ids: [data.id],
        type: 'Product',
        active: false,
      };
    },
    responseName: 'toggleState',
    message: `Product ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};
const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add Product',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update Product',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: element => formMapper(element),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'DELETE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({ id: element.id || '' }),
  },
};
const totalViewRefs = refGenerator(['billDiscount', 'tradeDiscount']);
const totalPriceMapper = (element) => ({
  billDiscount: element.billDiscount || 0,
  tradeDiscount: element.tradeDiscount || 0,
  promotionDiscount: element.promotionDiscount || 0,
  discountAmount: element.discountAmount || 0,
  topUpDiscount: element.topUpDiscount || 0,
  grossAmount: element.grossAmount || 0,
  subTotal: element.subTotal || 0,
  taxAmount: element.taxAmount || 0,
  taxableAmount: element.taxableAmount || 0,
  netAmount: element.netAmount || 0,
});
export {
  formConfig,
  formMapper,
  formMapper as listMapper,
  crudRequest,
  totalViewRefs,
  totalPriceMapper,
};
