import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../common/GqlAPI';
import { query as userQuery } from '../../configuration/user/userAccount/API';
import { query as routeQuery } from '../route/API';
import { LEAVE_REQUEST_SUMMARY_FRAGMENT } from '../leaveManagement/API';

const CALL_ROSTER_LIST_FRAGMENT = gql`
  fragment CallRosterFragment on UserCallRoster {
    userId
    data {
      date
      routeList {
        id
        routeId
        routeTitle
        lineId
        outletCount
        visitFrequencyId
        creationIdentifier
      }
    }
  }
`;

const GET_SALES_FORCE_ROSTER_LIST = gql`
  query SalesForceRoster($filter: FilterInput) {
    salesForceRoster(filter: $filter) {
      count
      rows {
        ...CallRosterFragment
      }
      leaves {
        ...LeaveRequestSummaryFragment
      }
      holidays {
        weekEnds
        calendarHolidays {
          id
          startDate
          endDate
          title
        }
      }
    }
  }
  ${CALL_ROSTER_LIST_FRAGMENT}
  ${LEAVE_REQUEST_SUMMARY_FRAGMENT}
`;

const ROSTER_FRAGMENT = gql`
  fragment CallRosterFragment on CallRoster {
    date
    routes {
      id
      title
      retailOutlets
      line {
        id
      }
    }
    leveInfo
  }
`;

const CREATE_SALES_FORCE_ROSTER = graphql(
  gql`
  mutation CreateSalesForceRoster ($input: SalesForceRosterInput) {
    createSalesForceRoster (input: $input) {
      id
      userId
      lineId
      routeId
      rosterDate
      active
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      create: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_ROSTER = graphql(
  gql`
    mutation ($id: Int!, $input: UpdateSalesForceRosterInput!) {
      updateSalesForceRoster(id: $id, input: $input) {
        id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      update: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const REPLICATE_ROSTER = graphql(
  gql`
    mutation ($input: SalesForceRosterReplicationInput!) {
      replicateSalesForceRoster(input: $input)
    }
  `,
  {
    props: ({ mutate }) => ({
      replicate: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);
const BULK_REPLICATE_ROSTER = graphql(
  gql`
  mutation BulkReplicateCallRoster($sourceDateRange: CallRosterDateRange!, $destinationDateRange: CallRosterDateRange!) {
    bulkReplicateCallRoster(sourceDateRange: $sourceDateRange, destinationDateRange: $destinationDateRange)
  }
  `,
  {
    props: ({ mutate }) => ({
      bulkReplicateCallRoster: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const SUB_D_HOLIDAY_FRAGMENT = gql`
  fragment SubDHolidayFragment on HolidayDetail {
    id
    startDate
    endDate
    title
    active
  }
`;

const GET_SUB_D_HOLIDAYS = gql`
  query holidayDetails($filter: FilterInput) {
    holidayDetails(filter: $filter) {
      weekEnds
      calendarHolidays {
        ...SubDHolidayFragment
      }
    }
  }
  ${SUB_D_HOLIDAY_FRAGMENT}
`;

const GET_ROLE_LIST = gql`
  query getUserRoleList ($limit:Int, $offset:Int, $filter:FilterInput){
    roles(limit:$limit, offset:$offset, filter: $filter) {
      rows {
        id
        name
        displayName
        users:Users {
          id
          fullName
          active
          distributor: Distributor{
            id
            title
          }
        }
      }
      count
    }
  }
`;


const GET_ROUTE_LIST =gql`
query getAllAssignedRoutes($userId: Int!, $distributorId: [Int], $assignedUserId: [Int]) {
  getAllAssignedRoutes(userId: $userId, distributorId: $distributorId, assignedUserId: $assignedUserId) {
    id
    title
    RouteLines {
      id
      lineId
      assignedTo
      distributorId
      Line {
        id
        title
      }
      VisitFrequency {
        id
        title
      }
      User {
        id
        fullName
      }
      Distributor {
        id
        title
      }
      NextRosterInfo {
        rosterDate
      }
    }
  }
}
`;

const GET_DSE_LIST = gql`
query associatedChildUsers ($userId: Int!, $roleId: [Int], $distributorId: [Int]) {
  getAssociatedChildUsers(userId: $userId, roleId: $roleId, distributorId: $distributorId) {
    id
    firstName
    lastName
    Role {
      id
      name
    }
    Distributor {
      id
      title
    }
  }
}
`;

const query = {
  getList: GET_SALES_FORCE_ROSTER_LIST,
  getRouteList: GET_ROUTE_LIST,
  getRouteVisitFrequencyList: routeQuery.getRouteVisitFrequencyList,
  getUserList: userQuery.getUserList,
  getUserRoleList: GET_ROLE_LIST,
  getSubDHolidays: GET_SUB_D_HOLIDAYS,
  getDSEList: GET_DSE_LIST,
};

const mutation = {
  replicate: REPLICATE_ROSTER,
  create: CREATE_SALES_FORCE_ROSTER,
  update: UPDATE_ROSTER,
  toggleState: TOGGLE_STATE,
  bulkReplicateCallRoster:BULK_REPLICATE_ROSTER,
};

export {
  ROSTER_FRAGMENT, CREATE_SALES_FORCE_ROSTER, TOGGLE_STATE, UPDATE_ROSTER, REPLICATE_ROSTER,BULK_REPLICATE_ROSTER
};

export { query, mutation };
