import { compose } from 'recompose';
import BulkDelete from './BulkDelete';
import { TOGGLE_STATE } from '../../../views/common/GqlAPI';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const ComposedBulkDelete = compose(
  TOGGLE_STATE,
  withRequestTracker({
    mutation: {
      toggleState: TOGGLE_STATE,
    },
  }),
)(BulkDelete);

export default ComposedBulkDelete;
