import { sales as salesRoute, collection as collectionRoute } from '../../../../data/enums/Route';
import { FILTER } from '../../../../data/enums/filter';
import { getFilterConfig } from '../../../../utils/filter';

const title = 'Outstandings';

const breadCrumbConfig = [salesRoute, collectionRoute];

const filter = getFilterConfig([FILTER.SUB_D, FILTER.ROUTE, FILTER.OUTSTANDINGS_STATUS, FILTER.AMOUNT]);

export {
  title, breadCrumbConfig, filter as filterConfig
};
