import React from 'react';
import PropTypes from 'prop-types';
import { circle } from 'd3-shape/src/symbol/circle';
import LegendStyled from './LegendStyled';
import { fixedFloatAndComma } from '../../../utils/conversion';

const propTypes = {
  data: PropTypes.arrayOf(Object),
  dimensions: PropTypes.instanceOf(Object),
  colorSet: PropTypes.instanceOf(Object),
  id: PropTypes.string,
};

const defaultProps = {
  data: [],
  dimensions: {},
  colorSet: {},
  id: '',
};

const Legend = ({ data, colorSet, dimensions, id }) => (
  <LegendStyled>
    <svg id={`${id}-legend`} width={dimensions.width + 10} height={dimensions.height / 2 - 0}>
      <g transform={`translate(${-3}, ${10})`}>
        {data.map((item, index) => (
          <g key={index} transform={`translate(${0}, ${(index * 10) / 5})`}>
            <circle r={5} cx='10' cy={index * 20} fill={colorSet[item.label]} />

            <text
              className='value'
              dominantBaseline='middle'
              x='30'
              y={index * 20}
              dy='1'
              textAnchor='left'
              color='#6B6C7E'
              style={{ fontSize: '12px' }}
            >
              {item.label}
            </text>
            <text
              className='value'
              dominantBaseline='middle'
              x='150'
              y={index * 20}
              dy='1'
              textAnchor='right'
              color='#272833'
              style={{ fontSize: '12px' }}
            >
              {fixedFloatAndComma(item.value)}
            </text>
          </g>
        ))}
      </g>
    </svg>
  </LegendStyled>
);
Legend.propTypes = propTypes;

Legend.defaultProps = defaultProps;

export default Legend;
