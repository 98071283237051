import { compose } from 'recompose';
import Attendance from './Attendance';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  DOWNLOAD_REPORT, query, mutation
} from './API';

const ComposedAttendance = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(Attendance);

export default ComposedAttendance;
