//Primary
const P900 = '#002766';
const P800 = '#00378F';
const P700 = '#0046B8';
const P600 = '#0056E0';
const P500 = '#0E69FF';
const P400 = '#3381FF';
const P300 = '#5C9AFF';
const P200 = '#85B4FF';
const P100 = '#D6E6FF';
const P050 = '#EBF2FF';

//Secondary-Red
const SR900 = '#590D12';
const SR800 = '#7C131A';
const SR700 = '#A01821';
const SR600 = '#C31D28';
const SR500 = '#DF2A36';
const SR400 = '#E44E58';
const SR300 = '#EA7179';
const SR200 = '#F4B8BC';
const SR100 = '#FADCDE';
const SR050 = '#FCEDEE';

//Secondary-Magenta
const SM900 = '#A30036';
const SM800 = '#CC0044';
const SM700 = '#F50052';
const SM600 = '#FF1F69';
const SM500 = '#FF4081';
const SM400 = '#FF70A0';
const SM300 = '#FF99BB';
const SM200 = '#FFADC9';
const SM100 = '#FFD6E4';
const SM050 = '#FFEBF1';

//Secondary-Yellow
const SY900 = '#663800';
const SY800 = '#8F4F00';
const SY700 = '#B86500';
const SY600 = '#E07B00';
const SY500 = '#F08400';
const SY400 = '#FFA333';
const SY300 = '#FFB65C';
const SY200 = '#FFC885';
const SY100 = '#FFD199';
const SY050 = '#FFEDD6';

//Secondary-Green
const SG900 = '#012819';
const SG800 = '#023B25';
const SG700 = '#03633E';
const SG600 = '#06B773';
const SG500 = '#048B57';
const SG400 = '#07DA89';
const SG300 = '#12F89F';
const SG200 = '#39F9AF';
const SG100 = '#4DF9B7';
const SG050 = '#EBFEF7';

//Grey
const G1900 = '#030913';
const G1800 = '#091E42';
const G1700 = '#172B4D';
const G1600 = '#253858';
const G1500 = '#344563';
const G1400 = '#42526E';
const G1300 = '#505F79';
const G1200 = '#5E6C84';
const G1100 = '#6B778C';
const G1000 = '#7A869A';
const G900 = '#8993A4';
const G800 = '#97A0AF';
const G700 = '#A5ADBA';
const G600 = '#B3BAC5';
const G500 = '#C1C7D0';
const G400 = '#DFE1E5';
const G300 = '#EBECF0';
const G200 = '#F4F5F7';
const G100 = '#FAFBFC';
const G050 = '#FFFFFF';

//System
const SG500Success = '#06B773';
const P300Info = '#5D9AFF';
const SR500Danger = '#DF2A36';
const SY500Warning = '#F08400';

const Colors = {
  primary: {
    P900,
    P800,
    P700,
    P600,
    P500,
    P400,
    P300,
    P200,
    P100,
    P050,
  },

  secRed: {
    SR900,
    SR800,
    SR700,
    SR600,
    SR500,
    SR400,
    SR300,
    SR200,
    SR100,
    SR050,
  },

  secMagenta: {
    SM900,
    SM800,
    SM700,
    SM600,
    SM500,
    SM400,
    SM300,
    SM200,
    SM100,
    SM050,
  },

  secYellow: {
    SY900,
    SY800,
    SY700,
    SY600,
    SY500,
    SY400,
    SY300,
    SY200,
    SY100,
    SY050,
  },

  secGreen: {
    SG900,
    SG800,
    SG700,
    SG600,
    SG500,
    SG400,
    SG300,
    SG200,
    SG100,
    SG050,
  },

  grey: {
    G1900,
    G1800,
    G1700,
    G1600,
    G1500,
    G1400,
    G1300,
    G1200,
    G1100,
    G1000,
    G900,
    G800,
    G700,
    G600,
    G500,
    G400,
    G300,
    G200,
    G100,
    G050,
  },
  system: {
    SG500Success,
    P300Info,
    SR500Danger,
    SY500Warning,
  },
};

export default Colors;
