const CALLAGE = {
  successful: 'successful',
  unsuccessful: 'unsuccessful',
  remaining: 'remaining',
  offroute: 'off route',
  scheduled: 'scheduled',
  productivity: 'productivity',
};

const SALES = {
  target: 'target',
  achievement: 'achievement',
  achievementpercent: 'achievementpercent',
};

export { CALLAGE, SALES };
