import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { PanelStyled } from '../../../common/configuration';
import { TableView, TotalView } from '../create/table/View';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { PanelCard, SummaryView } from '../../../../v4/components';
import { colSize } from '../../../../data/enums/Styles';

const propTypes = {
  title: PropTypes.string,
  data: PropTypes.instanceOf(Object).isRequired,
  breadCrumb: PropTypes.instanceOf(Array),
  getActionConfig: PropTypes.func,
  actionWidgets: PropTypes.instanceOf(Object),
};

const defaultProps = {
  title: '',
  breadCrumb: [],
  getActionConfig: () => null,
  actionWidgets: {},
};

const PrnView = ({ data, title, breadCrumb, actionWidgets, getActionConfig, exciseInLine }) => (
  <Fragment>
    <div className='section-header'>
      <PanelStyled>
        <PageHeader breadCrumb={breadCrumb} title={title}
          getActionConfig={getActionConfig}    actions={{ ...actionWidgets }}  printEnableStatus={data?.Distributor?.servicesUsed?.billing?.status}/>
      </PanelStyled>
    </div>
    <div className='section-content pad-48'>
      <PanelCard cardTitle='details'>
        <SummaryView data={data.summary || []} colType={colSize.l} />
      </PanelCard>
      <PanelCard cardTitle='sku' skuClassStatus>
        <TableView skuLines={data.lines} viewType={EVENT_OPERATION.DETAILS} exciseInLine={exciseInLine} />
        <TotalView priceDetails={data.prnAmount} refsObj={{}} viewType={EVENT_OPERATION.DETAILS} />
      </PanelCard>
    </div>
  </Fragment>
);

PrnView.propTypes = propTypes;

PrnView.defaultProps = defaultProps;

export default PrnView;
