import React from 'react';
import { PropTypes } from 'prop-types';

const propTypes = {
  leaveInfo: PropTypes.shape({
    status: PropTypes.string,
  }),
};

const defaultProps = {
  leaveInfo: '',
};

const LeaveView = ({ leaveInfo }) => (
  <span className={`leave-status-text ${leaveInfo.status}`}>{`${leaveInfo.leaveType} LEAVE`}</span>
);

LeaveView.propTypes = propTypes;

LeaveView.defaultProps = defaultProps;

export default LeaveView;
