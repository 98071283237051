import { compose } from 'recompose';
import Confirmed from './Confirmed';
import { GET_PROMOTION_VALIDATION } from '../API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedConfirmed = compose(
  withRequestTracker({
    query: { getPromotionValidation: GET_PROMOTION_VALIDATION },
  }),
)(Confirmed);

export default composedConfirmed;
