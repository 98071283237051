import { compose } from 'recompose';
import RetailerSkuAssortment from './RetailerSkuAssortment';
import {
  query, mutation, TOGGLE_SKU_IN_RETAILER_ASSORTMENT
} from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedRetailerSkuAssortment = compose(
  TOGGLE_SKU_IN_RETAILER_ASSORTMENT,
  withRequestTracker({
    query,
    mutation,
  }),
)(RetailerSkuAssortment);

export default composedRetailerSkuAssortment;
