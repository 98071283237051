import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TotalView } from '../create/table/View';
import { PanelStyled } from '../../../common/configuration';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import PageHeader from '../../../common/detailViews/pageHeader/PageHeader';
import { colSize } from '../../../../data/enums/Styles';
import TableView from './TableView';
import { PanelCard, CheckBox, SummaryView } from '../../../../v4/components';
import LEDGERS from '../../../../data/enums/Ledgers';
import { paymentTypeList } from '../../orderProcessing/received/salesInvoice/customerDetail/config';

const propTypes = {
  // handleSliderOpen: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  breadCrumb: PropTypes.instanceOf(Object),
  actionWidgets: PropTypes.instanceOf(Object),
  getActionConfig: PropTypes.func,
};

const defaultProps = {
  data: {},
  title: '',
  breadCrumb: {},
  actionWidgets: {},
  getActionConfig: () => null,
};

const SrnView = ({ data, title, breadCrumb, actionWidgets, getActionConfig }) => (
  <Fragment>
    <div className="section-header">
      <PanelStyled>
        <PageHeader
          breadCrumb={breadCrumb}
          title={title}
          actions={{ ...actionWidgets }}
          getActionConfig={getActionConfig}
          printEnableStatus={data?.Distributor?.servicesUsed?.billing?.status}
        />
      </PanelStyled>
    </div>
    <div className="section-content pad-48">
      <PanelCard cardTitle="details">
        <SummaryView data={data.summary || []} colType={12} />
        {data?.Distributor?.servicesUsed?.billing?.status || (
          <div className="info payment-mode">
            <label htmlFor="" style={{ display: 'block', marginBottom: 12 }}>
              Payment Mode
            </label>
            <CheckBox
              className="ledger-info"
              labelText="Cash"
              checked={data.paymentMode === LEDGERS.CASH.title}
              onCheckBoxClick={() => null}
            />
          </div>
        )}
      </PanelCard>
      <PanelCard cardTitle="sku" skuClassStatus>
        <TableView skuLines={data.lines} />
        <TotalView priceDetails={data.srnAmount} refsObj={{}} viewType={EVENT_OPERATION.DETAILS} invoiceNumberStatus />
      </PanelCard>
    </div>
  </Fragment>
);

SrnView.propTypes = propTypes;

SrnView.defaultProps = defaultProps;

export default SrnView;
