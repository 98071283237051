import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_ROUTE_SEGMENTATION_LIST } from '../API';

const GET_ROUTE_SEGMENTATION_DETAILS = gql`
  query getRouteSegmentationDetails($id: Int!) {
    lineDetails(id: $id) {
      id
      title
      type
      active
      brands: lineBrands {
        id
        title
        status
        lineSkuFamily {
          id
          title
          status
          skus: lineSkus {
            id
            title
            status
          }
        }
      }
    }
  }
`;

const CREATE_LINE = graphql(
  gql`
    mutation ($input: RouteLineInput!) {
      createLine(input: $input) {
        id
        title
        lineBrands {
          id
          title
          status
          lineSkuFamily {
            id
            title
            status
            skus: lineSkus {
              id
              title
              status
            }
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createLine: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_LINE = graphql(
  gql`
    mutation ($input: RouteLineUpdateInput!) {
      updateLine(input: $input) {
        id
        title
        lineBrands {
          id
          title
          status
          lineSkuFamily {
            id
            title
            status
            skus: lineSkus {
              id
              title
              status
            }
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateLine: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getRouteSegmentationDetails: GET_ROUTE_SEGMENTATION_DETAILS,
  getRouteSegmentation: GET_ROUTE_SEGMENTATION_LIST,
};
const mutation = {
  createLine: CREATE_LINE,
  updateLine: UPDATE_LINE,
};

export {
  query, mutation, CREATE_LINE, UPDATE_LINE
};
