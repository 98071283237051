import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { Pagination } from '../../../v4/components';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import ReportTableStyled from '../ReportTableStyled';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
};

const View = ({ ...props }) => {
  const { data, onPageChange, pagination } = props;

  return (
    /*      callId: 108923,
        callType: 'UNSUCCESSFUL',
        CallOrderValue: {
      subTotal: 0,
          netAmount: 0,
          taxAmount: 0,
          grossAmount: 0,
          billDiscount: 0,
          taxableAmount: 0,
          topUpDiscount: 0,
          tradeDiscount: 0,
          discountAmount: 0,
          promotionDiscount: 0
    },
    callRemarks: 'Credit Limit Issue', */

    <ReportTableStyled>
      <div className='table-wrap'>
        <table>
          {TableHeader()}
          <tbody>{data.list.map((userCluster) => TableBody({ data: userCluster }))}</tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </ReportTableStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
