import styled from 'styled-components';

const DataMaskStyled = styled.div`
background: rgba(231,231,237,0.2);
    padding: 40px 48px;

    textarea{
      border: 1px solid #e7e7ed;
      border-radius: 4px;
      background-color: #f1f2f5;
      color: #272833;
      font-size: 16px;
      line-height: 24px;
      height: 435px;
      overflow: auto;
      resize: none;
      font-family: inherit;
      padding: 8px 16px;
      width: 100% ;
      margin-top: 24px;
      &:focus {
          border: 1px solid #e7e7ed;
          outline: none;
      }
    }
`;

export default DataMaskStyled;
