import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_GRN_LIST = gql`
  query getGRNReport($offset: Int, $limit: Int, $filter: FilterInput) {
    getGRNReport(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        grn_date
        grn_id
        grn_number
        vendor_invoice_number
        receipt_number
        distributor_id
        distributor
        business_partner_id
        sku_id
        sku
        erp_code
        brand_id
        brand
        batch_id
        batch_number
        dlp
        quantity
        damages
        shortages
        gross_amount
        promotion_discount
        bill_discount
        trade_discount
        remarks
        status
        invoiced_date
        invoiced_time
        approved_date
        remarks_images {
          thumbnail
          src: image
        }
        damage_images {
          thumbnail
          src: image
        }
      }
      count
    }
  }
`;

const query = {
  getGRNList: GET_GRN_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
