import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { formConfig } from '../../config';
import history from '../../../../../utils/history';
import { refGenerator } from '../../../../../utils';
import { has } from '../../../../../utils/objectPrototypes';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { refValidator } from '../../../../../utils/refGenerator';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../../data/enums/EventOperation';
import { getPermissionForCatalog } from '../../../../base/permission';
import { dropdownChange, inputChange } from '../../../../../utils/formHandlers';
import { stateToRequestMapper, getBaseUserData, breadCrumbConfig, responseToStateMapper, hierarchy } from './config';
import { CATALOG } from '../../../../../data/enums/Route';
import { getDataFromLocalStorage, CLIENT_STORAGE_TABLE } from '../../../../../data/services';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { DialogWrapper } from '../../../../common';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getCatalogListDetail: PropTypes.func.isRequired,
  getCatalogDetailInfo: PropTypes.func.isRequired,
  createCatalogListDetail: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  updateCatalogListDetail: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class CatalogDetails extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    const id = has.call(match.params, 'id') ? parseInt(match.params.id, 10) : 0;

    const catalogId = has.call(match.params, 'catalogId') ? parseInt(match.params.catalogId, 10) : 0;

    this.state = {
      catalogId,
      data: getBaseUserData(catalogId),
      enableFormValidation: false,
      crudMode: id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      id,
      menu: {
        catalog: [],
        catalogChildrens: [],
      },
    };

    this.formReference = refGenerator(formConfig.validationField);
    this.permission = getPermissionForCatalog();
  }

  componentDidMount() {
    const { crudMode, catalogId, id } = this.state;
    if (crudMode !== EVENT_OPERATION.CREATE) {
      this.getDetail(catalogId, id);
    }
    this.loadDataForDropDown();
  }

  getHierarchyDetail = id => {
    const { getCatalogDetailInfo } = this.props;
    const { data } = this.state;
    getCatalogDetailInfo(
      {
        catalogDetailId: id,
      },
      {
        handleSuccess: response => {
          const responseData = response.data.getCatalogDetailInfo || [];
          this.setState({
            data: responseToStateMapper(data, responseData),
          });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  getDetail = (catalogId, catalogDetailId) => {
    const { getCatalogListDetail } = this.props;
    const { data } = this.state;
    getCatalogListDetail(
      {
        catalogId,
        catalogDetailId,
      },
      {
        handleSuccess: response => {
          const responseData = response.data.catalogLevelDetails.rows[0] || [];
          this.setState(
            {
              data: { ...data, ...responseData },
            },
            () => {
              this.getHierarchyDetail(responseData.parentCatalogDetailId);
            },
          );
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  getHeader = () => {
    const { crudMode, data } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? `  ${data.title} ` : 'CATALOG DETAIL';
    if (crudMode === EVENT_OPERATION.READ) return ` ${data.title}`;
    return (
      <span>
        {EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}
        {header}
      </span>
    );
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedDetails = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedDetails });
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    this.setState({ data: updatedData });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleImagesFetch = images => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        ...{ images },
      },
    });
  };

  handleButtonSubmit = () => {
    const { data, crudMode, id } = this.state;

    const { createCatalogListDetail, updateCatalogListDetail, displayAlert } = this.props;

    const formValidation = refValidator(this.formReference);

    if (!formValidation) {
      this.setState({ enableFormValidation: true });
    } else {
      const formattedData = stateToRequestMapper(data);

      if (crudMode === EVENT_OPERATION.CREATE) {
        createCatalogListDetail(
          { input: formattedData },
          {
            handleSuccess: () => {
              displayAlert(ALERT_TYPE.SUCCESS, `${hierarchy[data.catalogId].label}${MESSAGE_EVENT_OPERATION.CREATE}`);
              setTimeout(() => {
                history.push(`/${CATALOG}/${data.catalogId}`);
              }, 1200);
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }

      if (crudMode === EVENT_OPERATION.UPDATE) {
        updateCatalogListDetail(
          {
            input: { ...formattedData },
            id,
          },
          {
            handleSuccess: response => {
              const {
                data: { updateCatalog },
              } = response;
              this.setState({
                crudMode: EVENT_OPERATION.READ,
              });
              displayAlert(ALERT_TYPE.SUCCESS, `${updateCatalog.title}${MESSAGE_EVENT_OPERATION.UPDATE}`);
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
    }
  };

  handleButtonCancel = () => {
    const { crudMode, data } = this.state;
    const { id } = data;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: getBaseUserData(id),
      });
    } else {
      this.setState({ crudMode: EVENT_OPERATION.READ });
    }
  };

  handleEditIconClick = () => {
    this.setState({ crudMode: EVENT_OPERATION.UPDATE });
  };

  handleDeleteIconClick = () => {
    const { id, catalogId } = this.state;
    const { toggleState, displayAlert } = this.props;

    toggleState(
      {
        ids: [parseInt(id, 10)],
        type: 'CatalogDetail',
        active: false,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, `${hierarchy[catalogId].label}${MESSAGE_EVENT_OPERATION.DELETE}`);
          setTimeout(() => {
            history.push(`/${CATALOG}/${catalogId}`);
          }, 1200);
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG).then(response => {
      menu.catalog = response;
      this.setState({ menu });
    });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN).then(response => {
      menu.catalogChildrens = [...response];
      this.setState({ menu });
    });
  };

  render() {
    const { serverResponseWaiting } = this.props;

    const { data, crudMode, enableFormValidation, menu, catalogId } = this.state;
    return (
      <DialogWrapper
        onDialogSubmit={this.handleDeleteIconClick}
        render={({ onDialogItemClick }) => (
          <View
            catalogId={catalogId}
            data={data}
            menu={menu}
            crudMode={crudMode}
            refsObj={this.formReference}
            permission={this.permission}
            breadCrumb={breadCrumbConfig}
            loading={serverResponseWaiting}
            enableErrorDisplay={enableFormValidation}
            onInputChange={this.handleInputChange}
            handleButtonCancel={this.handleButtonCancel}
            handleButtonSubmit={this.handleButtonSubmit}
            handleDropDownChange={this.handleDropDownChange}
            handleEditIconClick={this.handleEditIconClick}
            getHeader={this.getHeader}
            handleImagesFetch={this.handleImagesFetch}
            onDeleteClick={onDialogItemClick}
          />
        )}
      />
    );
  }
}

CatalogDetails.propTypes = propTypes;

CatalogDetails.defaultProps = defaultProps;

export { CatalogDetails };

export default withAlert()(CatalogDetails);
