import { FORM_CONFIG } from '../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { refGenerator } from '../../../../utils';
import { fileParser } from '../../../../utils/parser';
import { salesForce as salesForceRoute } from '../../../../data/enums/Route';
import { getEndOfCurrentMonth, getStartOfCurrentMonth } from '../../../../utils/date';
import { UPLOADABLES } from '../../../../data/enums/GraphQL';

const breadCrumb = [salesForceRoute];

const title = 'Coverage Target';

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      target: element.target,
      startDate: element.startDate || getStartOfCurrentMonth(),
      endDate: element.endDate || getEndOfCurrentMonth(),
    }),
    [FORM_CONFIG.TITLE]: `Create Active ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['target', 'startDate', 'endDate']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    buttonName: 'Create',
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id,
      target: element.target,
      startDate: element.startDate,
      endDate: element.endDate,
    }),
    [FORM_CONFIG.TITLE]: '',
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id,
      target: element.target,
      startDate: element.startDate,
      endDate: element.endDate,
    }),
    [FORM_CONFIG.TITLE]: 'Update',
    [EVENT_OPERATION.UPLOAD]: `${EVENT_OPERATION.UPDATE} ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['target', 'duration']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    buttonName: 'Update',
  },
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.TITLE]: 'File Upload',
    [FORM_CONFIG.MAPPER]: (element) => ({
      name: element.name,
      file: element.file,
    }),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.REPLACE]: {
    [FORM_CONFIG.TITLE]: 'Replace Active Coverage Target',
  },
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({
      input: {
        target: Number(data.target),
        startDate: data.startDate || getStartOfCurrentMonth(),
        endDate: data.endDate,
      },
    }),
    responseName: 'createOutletCoverageTarget',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      input: {
        target: Number(data.target),
        startDate: data.startDate,
        endDate: data.endDate,
      },
    }),
    responseName: 'updateOutletCoverageTarget',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.OUTLETCOVERAGETARGET,
        },
      }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

export {
  formConfig, crudSuccess, breadCrumb
};
