import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LeaveManagementView from './View';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { breadCrumbConfig, filter as filterConfig } from './config';
import { clone } from '../../../utils/objectProcessor';
import { getPermissionForLeaveRequest } from '../../base/permission';
import LeaveMgmtStyled from './LeaveMgmtStyled';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getLeaveRequest: PropTypes.func.isRequired,
  approveLeaveRequest: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class LeaveManagement extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        date: { ...queryService.baseQueryParameters.date },
        search: queryService.baseQueryParameters.search,
        filter: queryService.baseQueryParameters.filter,
        pagination: queryService.baseQueryParameters.pagination,
      },
      filterMenu: this.filterConfigMenu || {},
    };

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
    );
    this.permission = getPermissionForLeaveRequest();
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.LEAVE_MANAGEMENT
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.LEAVE_MANAGEMENT);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  loadTableData = () => {
    const { getLeaveRequest, displayAlert } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getLeaveRequest(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.leaveRequests && response.data.leaveRequests.rows) || [];
          data.total = (response.data.leaveRequests && response.data.leaveRequests.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  updateLeaveRequest = (leaveRequest, status) => {
    // call the api to update the leave request
    const { approveLeaveRequest, displayAlert } = this.props;

    const dataForAPIRequest = {
      id: leaveRequest.id,
      requestedTo: leaveRequest.RequestedTo.id,
    };

    approveLeaveRequest(
      {
        status,
        input: [dataForAPIRequest],
      },
      {
        handleSuccess: response => {
          const { leaveRequests } = response.data.approveLeaveRequest;
          const approvedLeaveRequest = leaveRequests.approvedFor[0];
          const rejectedLeaveRequest = leaveRequests.approvalNotPermittedFor[0];

          if (rejectedLeaveRequest) {
            displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Unable To Update');
          } else {
            const { data } = this.state;
            data.list = data.list.map(request =>
              request.id === approvedLeaveRequest
                ? {
                    ...request,
                    status,
                  }
                : { ...request },
            );

            this.setState({ data });
            displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Updated');
          }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  render() {
    const { data, queryParameters, display,filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <LeaveMgmtStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              config={{
                title: 'Leave Management',
                date: true,
                filter: true,
                search: true,
              }}
              filter={{
                date: queryParameters.date,
                menuList:filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              breadCrumb={breadCrumbConfig}
              display={display}
              queryParameters={queryParameters}
              handleUploadClick={this.onDialogItemClick}
              handleCreateClick={this.handleAddButtonClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
              controlDisplay={this.controlDisplay}
            />
          </PanelStyled>
        </div>
        <div className="section-content table-present">
          <LeaveManagementView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            loading={serverResponseWaiting}
            onRowButtonClick={this.updateLeaveRequest}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </LeaveMgmtStyled>
    );
  }
}

LeaveManagement.propTypes = propTypes;

LeaveManagement.defaultProps = defaultProps;

export default withAlert()(LeaveManagement);
