import { compose } from 'recompose';
import UserAccount from './UserAccount';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_USER, UPDATE_USER, TOGGLE_STATE, TOGGLE_USER_STATUS,DOWNLOAD_REPORT
} from './API';

const ComposedUserAccount = compose(
  CREATE_USER,
  UPDATE_USER,
  TOGGLE_STATE,
  TOGGLE_USER_STATUS,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(UserAccount);

export default ComposedUserAccount;
