import styled from 'styled-components';

const WeeklyStyled = styled.div`
  .week-view {
    display: flex;
    align-items: center;
    justify-content: center;

    h4 {
      border: 1px solid #cdced9;
      font-size: 14px;
      padding: 5px 12px;
      line-height: 21px;
      color: #6b6c7e;
      margin: 0 8px;
      border-radius: 4px;
      font-weight: 600;
    }

    .icon {
      color: #6b6c7e;
    }
  }

  .roster-calendar-wrap {
    background: #fff;
    border: 1px solid #e7e7ed;
    border-radius: 4px;
  }

  .calendar-top-header {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e7e7ed;
    position: sticky;
    top: 0;
    z-index: 1;

    .title {
      width: 190px;
      padding: 14px 16px;
      border-right: 1px solid #e7e7ed;

      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      .label {
        span {
          color: #6b6c7e;
          font-size: 10px;
          line-height: 10px;
          display: block;
          font-weight: normal;
        }
      }
    }

    .calendar-week-header {
      width: calc(100% - 190px);
    }
  }

  .calendar-week-header {
    > div {
      display: flex;
      height: 100%;
    }

    .calendar-week-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      flex-wrap: wrap;
      border-right: 1px solid #e7e7ed;
      text-align: center;

      .holiday-title {
        font-size: 6px;
        font-weight: bold;
        color: #ff5f5f;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .date {
        font-size: 12px;
        color: #272833;
        font-weight: 600;
        margin-top: 2px;
        width: 20px;
        display: inline-block;
        height: 20px;
        border-radius: 50%;
        background: transparent;
        line-height: 20px;
        vertical-align: middle;

        &.active {
          color: #fff !important;
          background: #4b9bff;
        }
      }

      &.weekend {
        background: rgba(167, 169, 188, 0.1);
      }

      &.prev-next {
        background: rgba(167, 169, 188, 0.05);
      }

      &.holiday {
        background: #fff;

        .normal {
          color: #ff5f5f;
        }

        .date {
          color: #ff5f5f;

          &.active {
            margin-bottom: 0;
          }
        }
      }

      &:last-child {
        border-right: 0;
      }

      span {
        color: #a7a9bc;
        font-size: 10px;
        line-height: 10px;
        display: inline-block;
        width: 100%;
        margin-bottom: 4px;
      }

      span.normal {
        margin-bottom: 0;
        color: #272833;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
      }
    }
  }

  .calendar-bottom-content {
    height: 60vh;
    overflow-y: overlay;
    > div {
      display: flex;
      flex-wrap: wrap;
    }

    .title {
      width: 190px;
      padding: 25px;
      border-right: 1px solid #e7e7ed;
      height: 90px;
      font-size: 12px;
      font-weight: 600;

      span {
        width: 100%;
      }
    }

    .calendar-days {
      width: calc(100% - 190px);

      svg {
        opacity: 1;
      }
    }
  }

  .calendar-days-block {
    display: flex;
    border-bottom: 1px solid #e7e7ed;
  }

  .calendar-day {
    flex: 1;
    padding: 7px 12px;
    border-right: 1px solid #e7e7ed;
    min-height: 90px;
    position: relative;

    &.active-border {
      border: 1px solid #80acff;
      border-radius: 4px;
    }

    &:hover,
    &:focus {
      button.primary {
        transition: 0.23s;
        opacity: 1;
        pointer-events: auto;

        .icon {
          color: #0e68fe;
        }
      }

      .roster-popup {
        button.primary {
          transition: 0.23s;
          opacity: 1;
          pointer-events: auto;
          position: relative;
          top: 0;
          width: 100%;
        }
      }
    }

    &.weekend {
      background: rgba(167, 169, 188, 0.05);
    }

    &.holiday {
      background: rgba(255, 95, 95, 0.1);
    }

    &.prev-next {
      background: rgba(167, 169, 188, 0.1);
    }

    &:last-child {
      border-right: 0;
    }

    .calendar-date {
      position: absolute;
      top: 7px;
      right: 12px;
      line-height: 14px;
      font-size: 12px;
      font-weight: 600;
    }

    .leave-status-text {
      display: inline-block;
      position: absolute;
      bottom: 7px;
      font-size: 6px;
      line-height: 7px;
      text-align: center;
      font-weight: bold;
      padding: 0 23px 1px;
      border-radius: 3px;
      min-width: 112px;
      margin: 0 auto;
      color: #fff;
      background: transparent;

      &:empty {
        display: none;
      }

      &.PENDING {
        background: #a7a9bc;
      }

      &.APPROVED {
        background: #ff5f5f;
      }

      &.REJECTED {
        background: #a7a9bc;
      }

      &.sick-leave {
        background: #ff5f5f;
      }
    }

    button.primary {
      background: #fff;
      padding: 4px 12px;
      border: 1px dashed #0e68fe;
      color: #0e68fe;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 27px;
      width: 90%;
      margin: 0 auto;
      left: 0;
      right: 0;

      .icon {
        width: 10px;
        height: 10px;
      }

      &:hover,
      &:focus,
      &:active {
        border: 1px solid #0e68fe;
        background: #0e68fe;

        .icon {
          color: #fff;
        }
      }
    }

    .roster-popup {
      button.primary {
        position: relative;
        top: 0;
        opacity: 1;
        width: 100% !important;
        border-style: solid;
        background: #0e68fe;
        color: #fff;
      }
    }
  }

  .roster-route-view {
    border: 1px solid #e7e7ed;
    border-radius: 2px;
    font-size: 10px;
    line-height: 10px;
    padding: 5px 13px;
    color: #6b6c7e;
    width: 100%;
    position: relative;
    background: #fff;
    margin-top: 18px;

    + .roster-route-view {
      margin-top: 3px;
    }

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 4px;
      border-radius: 3px;
      height: calc(100% - 4px);
      left: 2px;
      top: 2px;
      background: transparent;
    }

    &.status_1 {
      &:before {
        background: #5fc8ff;
      }
    }

    &.status_2 {
      &:before {
        background-color: #ffb46e;
      }
    }

    &.status_3 {
      &:before {
        background-color: #ff73c3;
      }
    }

    &.status_4 {
      &:before {
        background-color: #9be169;
      }
    }

    &.status_5 {
      &:before {
        background-color: #e7e7ed;
      }
    }

    &.status_once {
      &:before {
        background-color: #e7e7ed;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .action-btn {
      .actions {
        opacity: 0;
        pointer-events: none;
        transition: 0.2s;
        background: #fff;
        border-radius: 4px;
        position: absolute;
        top: 28px;
        right: -1px;
        font-size: 14px;
        min-width: 125px;
        transform: translateY(-1px);
        padding: 20px 16px;
        box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
        border: 1px solid #e7e7ed;
        z-index: 111;
      }

      .dot-wrap {
        background: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        outline: none;

        > .dot {
          display: inline-block;
          border-radius: 50%;
          width: 3px;
          height: 3px;
          background: #6b6c7e;
          margin-bottom: 2px;
        }
      }

      .actions {
        > div {
          cursor: pointer;

          &:first-child {
            margin-bottom: 16px;
          }
        }

        .text {
          display: inline-block;
          margin-left: 16px;
          color: #272833;
        }
      }

      .dot-wrap + .actions:active,
      .dot-wrap:focus + .actions {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(10px);
      }

      .icon {
        cursor: pointer;

        &:hover,
        &:focus {
          color: #0b5fff;
        }
      }
    }

    svg {
      width: 14px;
      height: 16px;
      color: #6b6c7e;
    }

    .name {
      font-weight: 600;
      display: block;
      color: #272833;
      margin-bottom: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 70px;
    }

    + button {
      padding: 0 !important;
      height: 14px;
      position: absolute;
      bottom: 4px;
      width: 93% !important;
      left: 4px;
      line-height: 9px;
      right: 4px;
      display: inline-block;
      top: auto !important;
    }

    + div + button {
      display: none;
    }
  }

  @media print {
    .calendar-top-header .title,
    .calendar-bottom-content .title {
      width: 20%;
      text-align: justify;
      padding: 24px 16px;

      span {
        hyphens: auto;
      }
    }

    .calendar-top-header .calendar-week-header,
    .calendar-bottom-content .calendar-days {
      width: 80%;
    }

    .calendar-week-header .calendar-week-title {
      width: 14.28%;
    }

    .calendar-day {
      width: 14.28%;
    }
  }
`;

export { WeeklyStyled };
