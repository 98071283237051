import { refGenerator } from '../../../utils/refGenerator';
import { FORM_CONFIG } from '../../../data/enums/config';
import { inventory as inventoryRoute } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const title = 'Warehouse Management';
const breadCrumb = [inventoryRoute];
const formConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    distributorId: element.distributorId || '',
  }),
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['title']),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['title']),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Delete',
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.TITLE]: 'View',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['title', 'levelIndication']),
  },
  [EVENT_OPERATION.PRINT]: {
    [FORM_CONFIG.TITLE]: 'Print',
    buttonName:'Print'
  },
  [EVENT_OPERATION.BULK_PRINT]: {
    [FORM_CONFIG.TITLE]: 'Print All',
    buttonName:'Print'
  },
};

export {
  breadCrumb as breadCrumbConfig, formConfig, title
};
