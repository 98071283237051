import { refGenerator } from '../../../../utils';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';
import { FORM_CONFIG } from '../../../../v4/constants/formConfig';
import { OUTLET_TARGET } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const Title = 'Sub D';
const requiredList = ['distributorId', 'month', 'target'];
const filter = getFilterConfig([FILTER.SUB_D]);

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Outlet Targets',
    path: `/${OUTLET_TARGET}`,
    active: true,
  },
];

const get = key => {
  const value = localStorage.getItem(key);

  if (!value) {
    return;
  }

  return JSON.parse(value);
};

const set = (key, value) => {
  if (value) {
    localStorage?.setItem(key, JSON?.stringify(value));
  }
};

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create Target',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.TITLE]: 'UPLOAD',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.TITLE]: 'View Target',
  },
};

export { breadCrumb as breadCrumbConfig, formConfig, Title as title, filter as filterConfig, set, get };
