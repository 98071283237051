import React from 'react';
import styled from 'styled-components';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { parseDateByFormat } from '../../../../../utils/date';
import { Icon } from '../../../../../v4/components';

const OutletDetails = ({ callPlan, outletBasic, outletAchievement, APGP, p3mAverage, avgSalesPerBill }) => {
  // const handleViewDSEDetails = (dseId) => {
  //   const win = window.open(`/${USER_ACCOUNT}/${dseId}`);
  //   win.focus();
  // }

  return <OutletDetailsContainer>
    <div className='outlet-core-details'>
      <div className='core-detail primary'>
        <div className='core-item'>
          <label>Frequency</label>
          <div className='item-value'>{outletBasic?.frequency.toLowerCase()}</div>
        </div>
        {/* <div className='core-item'>
          <label>Channel</label>
          <div className='item-value'>{outletBasic?.channel}</div>
        </div> */}
        <div className='core-item'>
          <label>Category</label>
          <div className='item-value'>{outletBasic?.category}</div>
        </div>
      </div>
      <div className='core-detail secondary'>
        <div className='core-item'>
          <label>Monthly Target</label>
          <div className='item-value'>{outletAchievement?.target ? fixedFloatAndCommas(outletAchievement.target) : '-'}</div>
        </div>
        <div className='core-item'>
          <label>Achievement</label>
          <div className='item-value'>{outletAchievement?.achievement ? `${Math.round((outletAchievement?.achievement/outletAchievement.target) *100)}%` : '0%'}</div>
        </div>
      </div>
      <div className='core-detail secondary'>
        <div className='core-item'>
          <label>P3M Average Sales</label>
          <div className='item-value'>{p3mAverage ? fixedFloatAndCommas(p3mAverage) : '-'}</div>
        </div>
        <div className='core-item'>
          <label>Average Sales per Bill</label>
          <div className='item-value'>{avgSalesPerBill ? fixedFloatAndCommas(avgSalesPerBill) : '-'}</div>
        </div>
      </div>
      {APGP ?
        <div className='core-detail secondary'>
          <div className='core-item '>
            <label>SBD</label>
            <div className='item-value'>{APGP?.sbd && `${fixedFloatAndCommas(APGP.sbd)}%`}</div>
          </div>
          <div className='core-item'>
            <label>Allocated Points</label>
            <div className='item-value'>{APGP?.ap}</div>
          </div>
          <div className='core-item'>
            <label>Golden Points</label>
            <div className='item-value'>{APGP?.gp}</div>
          </div>
        </div>
        : null
      }
    </div>
    {outletBasic ?
      <div className='outlet-owner-details with-border'>
        {outletBasic?.contact_info?.[0]?.name ?
          <div className='owner-detail row'>
            <div className='left_div'>
              <Icon iconName='user' />
              <div className='core-item'>
                <label>Owner Name</label>
                <div className='item-value'>{outletBasic.contact_info[0].name}</div>
              </div>
            </div>
          </div> : null
        }
        {outletBasic?.contact_info?.[0]?.phoneNumber ?
          <div className='owner-detail row'>
            <div className='left_div'>
              <Icon iconName='phone' />
              <div className='core-item'>
                <label>Contact Number</label>
                <div className='item-value'>{outletBasic.contact_info[0].phoneNumber}</div>
              </div>
            </div>
            {/* <Button outlined title='Verify Number' /> */}
          </div> : null
        }
        {outletBasic?.contact_info?.[0]?.phoneNumber ?
          <div className='owner-detail row'>
            <div className='left_div'>
              <Icon iconName='blogs' />
              <div className='core-item'>
                <label>PAN</label>
                <div className='item-value'>{outletBasic?.pan_number || 'XXXXXXXXX'}</div>
              </div>
            </div>
          </div> : null
        }
        {outletBasic?.dseId ?
          <div className='owner-detail row'>
            <div className='left_div'>
              <Icon iconName='users' />
              <div className='core-item'>
                <label>DSE</label>
                <div className='item-value'>{outletBasic?.dseName}</div>
              </div>
            </div>
            <div className='right_div'>
              <div className='core-item'>
                <label>Next Scheduled Call</label>
                <div className='item-value'>{outletBasic?.call_roster?parseDateByFormat(outletBasic?.call_roster, 'MMM DD, YYYY'):'N/A'}</div>
              </div>
            </div>
          </div>
          : null
        }
        {callPlan?.distributorId ?
          <div className='owner-detail row'>
            <div className='left_div'>
              <Icon iconName='truck' />
              <div className='core-item'>
                <label>Sub D</label>
                <div className='item-value'>{callPlan?.distributor}</div>
              </div>
            </div>
          </div>
          : null
        }
      </div> : null
    }
  </OutletDetailsContainer>;
};

const OutletDetailsContainer = styled.div`

    .core-detail:last-of-type {
      margin-bottom: 1rem;
    }

    .core-detail {
      margin-bottom: .5rem;
      /* display: grid; */
      border-radius: 8px;
      /* gap: 1rem; */
      border: 1px solid #E7E7ED;
      /* grid-template-columns: repeat(auto-fill, minmax(30%, 1fr)); */
      /* grid-auto-flow: row; */
      padding: 1rem;
      display:flex;
      flex-wrap:wrap;

      &.row{
        margin-left: 0;
        margin-right: 0;
      }
       
      &.primary {
        border: 1px solid rgba(46, 90, 172,0.5);
        background-color:rgba(238, 242, 250,0.5);
        .core-item label{
          color:#6B6C7E;
        }
        
      }

      &.secondary {
        border: 1px solid rgba(255, 143, 57,0.5);
        background-color:rgba(255, 244, 236,0.5);
        .core-item label{
          color:#6B6C7E;
        }
      }

      .core-item {
        flex:1;
        label {
          text-transform: capitalize;
          display: block;
          color: #A7A9BC;
          font-size: 12px;
          margin-top: 6px;
        }

        .item-value {
          text-transform: capitalize;
          display: block;
          font-weight: 500;
          font-size: 14px;
          padding: .5rem 0;
        }
      }
    }

    .owner-detail{
      display: flex;
      /* gap: 1rem; */
      align-items: center;
      margin-bottom: 1rem;
      flex-wrap: wrap;
      .left_div{
        flex:1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        span{
        display: inline-block;
        height: 42px;
        width: 42px;
        padding: 12px;
        color: #CDCED9;
        border: 1px solid #E7E7ED;
        border-radius: 50%;
        line-height: 13px;
        }
        .core-item{
          flex:1;
          margin:0 12px;
          label{
            color:#6B6C7E;
            margin:0;
          }
          .item-value{
            padding: .1rem 0;
          }
        }
      }
      .right_div{
        .core-item{
          .item-value{
              padding: .1rem 0;
            }
        }
      }
      &.row {
        margin-left: 0;
        margin-right: 0;
      }

      .col-xs {
        margin-top: 8px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

        button {
          margin-left: auto;
          height: 40px;
          span{
            border: none;
          }
        }

        span {
          .icon {
            color: #CDCED9;
          }
        }
      .core-item {
        label {
          text-transform: capitalize;
          display: block;
          color: #A7A9BC;
          font-size: 12px;
          margin-top: 6px;
        }

        .item-value {
          display: block;
          font-weight: 500;
          font-size: 14px;
          padding: .5rem 0;
        }
      }
    }
`;


export default OutletDetails;
