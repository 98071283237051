import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_PROMOTION_LIST } from '../../promotion/API';
import { GET_ROLE_LIST } from '../../../configuration/user/userRole/API';
import { GET_OUTLET_COUNT, GET_TERRITORIES } from '../../../common/GqlAPI';
import { GET_TOWN_LIST } from '../../../configuration/town/API';
import { GET_ROUTES_ARC } from '../../../configuration/arc/services/ApiGql';

const SEND_MESSAGE = graphql(
  gql`
    mutation ($input: MessageInput!) {
      sendMessage(input: $input) {
        id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      sendMessage: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const EDIT_MESSAGE = graphql(
  gql`
    mutation ($id: Int!, $input: MessageInput!) {
      editMessage(id: $id, input: $input) {
        id
        title
        campaign
        category
        lastSent
        sentTime
        status
        messageDetail: MessageDetail {
          platform
          message {
            title
            description
            link
            message
          }
          audience
          audienceFilter {
            userRole
            outletStatus
            lastInvoiced
            zoneId
            townId
            distributorId
            routeId
            channelId
            categoryId
            channelCategory {
              channelId
              categoryIds
            }
          }
          isScheduled
        }
        messageSchedule: MessageSchedule {
          isRepeated
          repeatTimes
          repeatDays
          startDate
          endDate
          time
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      editMessage: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_MESSAGE_LIST = gql`
  query messagesList($offset: Int, $limit: Int, $filter: FilterInput) {
    messages(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        campaign
        category
        lastSent
        sentTime
        status
        messageDetail: MessageDetail {
          platform
          message {
            title
            description
            link
            message
          }
          audience
          audienceFilter {
            userRole
            outletStatus
            lastInvoiced
            zoneId
            townId
            distributorId
            routeId
            channelId
            categoryId
            channelCategory {
              channelId
              categoryIds
            }
          }
          isScheduled
        }
        messageSchedule: MessageSchedule {
          isRepeated
          repeatTimes
          repeatDays
          startDate
          endDate
          time
        }
      }
      count
    }
  }
`;

const GET_DICTIONARY_LIST = gql`
  query getDataDictionaryList($type: AudienceType!) {
    getDataDictionaryList(type: $type) {
      attribute
      type
    }
  }
`;

const query = {
  getPromotionList: GET_PROMOTION_LIST,
  getUserRoleList: GET_ROLE_LIST,
  getTerritory: GET_TERRITORIES,
  getTownList: GET_TOWN_LIST,
  getRouteList: GET_ROUTES_ARC,
  getRetailOutletCount: GET_OUTLET_COUNT,
  getMessageList: GET_MESSAGE_LIST,
  getDataDictionaryList: GET_DICTIONARY_LIST,
};

const mutation = {
  sendMessage: SEND_MESSAGE,
  editMessage: EDIT_MESSAGE,
};

export {
  query, mutation, SEND_MESSAGE, EDIT_MESSAGE
};
