import gql from 'graphql-tag';

const BILLING_PRINT_URL = gql`
  query GenerateBillingPrintURL($type: PrintType!, $distributorId: Int!, $invoiceNumber: String!) {
    generateBillingPrintURL(type: $type, distributorId: $distributorId, invoiceNumber: $invoiceNumber) {
      url
    }
  }
`;
const GENERATE_BILLING_PRINT_Blob = gql`
  query GenerateBillingPrint($type: PrintType!, $distributorId: Int!, $invoiceNumber: String!, $directPrint: Boolean) {
    generateBillingPrint(
      type: $type
      distributorId: $distributorId
      invoiceNumber: $invoiceNumber
      directPrint: $directPrint
    ) {
      url
    }
  }
`;

const query = {
  getBillingPrintUrl: BILLING_PRINT_URL,
  getBillingPrintBlob: GENERATE_BILLING_PRINT_Blob,
};

export { query };
