import { SBD_TARGET, salesForce as salesForceRoute } from '../../../../data/enums/Route';

export const breadCrumb = [
  salesForceRoute,
  {
    id: 1,
    title: 'SBD Target',
    path: `/${SBD_TARGET}`,
    active: true,
  },
];

export const AVERAGE_BASE = {
  SAME_QUARTER:'Same Quarter',
  PREVIOUS_QUARTER: 'Previous Quarter',
}
