import PropTypes from 'prop-types';
import React from 'react';
import { Collapsible, Search, } from '../../../../../components';
import { Switch,Badge } from '../../../../../v4/components';

const propTypes = {
  assortmentCounts: PropTypes.instanceOf(Object),
  onSearchInputChange: PropTypes.func.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  skuFamilies: PropTypes.instanceOf(Array),
  searchText: PropTypes.string,
  selectedSkus: PropTypes.instanceOf(Array),
};

const defaultProps = {
  assortmentCounts: { skuFamilies: [] },
  skuFamilies: [],
  searchText: '',
  selectedSkus: [],
};

const SkuFamily = ({
  assortmentCounts,
  selectedSkus,
  onSwitchChange,
  skuFamilies,
  searchText,
  onSearchInputChange,
}) => {
  const totalSkuIds = skuFamilies.map((item) => item.skus.map((sku) => sku.id)).flat() || [];
  const onSKUFamilySwitchChange = (checked, skus) => {
    const skuIds = skus.map((sku) => sku.id) || [];
    onSwitchChange(checked, skuIds);
  };
  const onPrimarySwitchChange = (checked) => {
    onSwitchChange(checked, totalSkuIds);
  };
  const getSKUFamilySwitchStatus = (skus) => skus.every((sku) => selectedSkus.includes(sku.id));
  const getSKUFamilyCount = (skuFamily) => {
    const countItem = assortmentCounts.skuFamilies.find((item) => item.id === skuFamily.id) || {};
    return countItem.count ? <Badge light label={countItem.count} className='badge' /> : '';
  };

  return (
    <div className='panel three'>
      <div className='panel-list'>
        <div className='panel-title'>
          <p className='p-title'>SKU Family / SKUs</p>
          <Search clearText={!searchText} searchText={searchText} handleSearchInput={onSearchInputChange} />
        </div>
        <div className='div-thead'>
          <span>SKU</span>
          <div className='status'>
            <span>Status</span>{' '}
            <Switch
              checked={totalSkuIds.length === selectedSkus.length && totalSkuIds.length !== 0}
              onToggle={(e) => onPrimarySwitchChange(e)}
            />
          </div>
        </div>
        <div className='div-tbody'>
          {skuFamilies.map((skuFamily) => (
            <Collapsible
              trigger={
                <>
                  <span>
                    <span className='text'>{skuFamily.title}</span>
                    {getSKUFamilyCount(skuFamily)}
                  </span>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Switch
                      checked={getSKUFamilySwitchStatus(skuFamily.skus)}
                      onToggle={(e) => onSKUFamilySwitchChange(e, skuFamily.skus || [])}
                    />
                  </div>
                </>
              }
            >
              {skuFamily.skus.map((sku) => (
                <div className='collapsible-inner' key={sku.id} onClick={(e) => e.stopPropagation()}>
                  <span>{sku.title}</span>
                  <span>
                    <Switch checked={selectedSkus.includes(sku.id)} onToggle={(e) => onSwitchChange(e, [sku.id])} />
                  </span>
                </div>
              ))}
            </Collapsible>
          ))}
        </div>
      </div>
    </div>
  );
};

SkuFamily.propTypes = propTypes;

SkuFamily.defaultProps = defaultProps;

export default SkuFamily;
