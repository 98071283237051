
import { compose } from 'recompose';
import MerchandiseDetails from './Detail';
import {
  query, mutation,CREATE_MERCHANDISE_DEVICE,UPDATE_MERCHANDISE_DEVICE
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedMerchandiseDetails = compose(
  CREATE_MERCHANDISE_DEVICE,
  UPDATE_MERCHANDISE_DEVICE,
  withRequestTracker({
    query,
    mutation,
  }),
)(MerchandiseDetails);

export default composedMerchandiseDetails;

