import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT, UPLOAD } from '../../common/GqlAPI';
import { DISTRIBUTOR_FRAGMENT, VENDOR_FRAGMENT } from '../../common/GqlFragments';
// TODO include vat in grn amount fragment
import { query as buQuery } from '../../configuration/bu/API';

const OUTLET_TARGET_FRAGMENT = gql`
  fragment OutletTargetFragment on OutletTarget {
    id
    title
    start_date
    end_date
  }
`;

const USER_TARGET_FRAGMENT = gql`
  fragment GrnFragment on GoodsReceivedNote {
    id
    invoiceNumber
    Distributor {
      ...DistributorFragment
    }
    Vendor {
      ...VendorFragment
    }
    invoiceDate
    amount {
      ...GrnAmountFragment
    }
    remarks
  }
  ${DISTRIBUTOR_FRAGMENT}
  ${VENDOR_FRAGMENT}
  ${OUTLET_TARGET_FRAGMENT}
`;

const CREATE_OUTLET_TARGET = graphql(
  gql`
    mutation ($input: FundamentalTargetInput) {
      createOutletFundamentalTarget(input: $input) {
        title
        start_date
        end_date
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createOutletTarget: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_BU_TARGET = graphql(
  gql`
    mutation ($id: Int!, $target: Float!, $year: Int!, $month: Int!) {
      updateBuTarget(id: $id, target: $target, year: $year, month: $month) {
        ...BuTargetFragment
      }
    }
    ${OUTLET_TARGET_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      updateBUTarget: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

// query getBuTarget($offset: Int, $limit: Int, $filter: [FilterInput]) {
//   buTargets(offset: $offset, limit: $limit, filter: $filter) {

const GET_OUTLET_TARGET = gql`
  query ListOutletFundamentalTarget {
    listOutletFundamentalTarget {
      rows {
        id
        title
        start_date
        end_date
      }
      count
    }
  }
`;

const GET_USER_BRAND_TARGET = gql`
  query getUserBrandTarget($buTargetId: Int!) {
    userBrandTargetsPerMonth(buTargetId: $buTargetId) {
      id
      brandName
      target
      monthName
      year
      total
      role
    }
  }
`;

const GET_SUBD_BRAND_TARGET = gql`
  query getSubDBrandTarget($buTargetId: Int!) {
    subdBrandTargetsPerMonth(buTargetId: $buTargetId) {
      id
      target
      monthName
      brandName
      year
      total
    }
  }
`;

const query = {
  getOutletTarget: GET_OUTLET_TARGET,
  getBUSettings: buQuery.getBuData,
  getUserBrandTarget: GET_USER_BRAND_TARGET,
  getSubDBrandTarget: GET_SUBD_BRAND_TARGET,
};

const mutation = {
  createOutletTarget: CREATE_OUTLET_TARGET,
  updateBUTarget: UPDATE_BU_TARGET,
  upload: UPLOAD,
  downloadReport: DOWNLOAD_REPORT,
};

export { OUTLET_TARGET_FRAGMENT, CREATE_OUTLET_TARGET, UPDATE_BU_TARGET, UPLOAD, DOWNLOAD_REPORT };

export { query, mutation };
