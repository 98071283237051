import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const DISTRIBUTOR_GROUP_FRAGMENT = gql`
  fragment DistributorGroupFragment on DistributorGroup {
    id
    title
    active
  }
`;

const GET_SUB_D_ASSORTMENT = gql`
  query subdAssortment($offset: Int, $limit: Int, $filter: FilterInput) {
    distributorGroups(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...DistributorGroupFragment
      }
      count
    }
  }
  ${DISTRIBUTOR_GROUP_FRAGMENT}
`;

const query = {
  getSubDAssortmentList: GET_SUB_D_ASSORTMENT,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, GET_SUB_D_ASSORTMENT, DOWNLOAD_REPORT
};
