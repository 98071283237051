import styled from 'styled-components';

const TimeSpendOnRouteStyled = styled.div`
  .table-wrap {
    td {
      white-space: nowrap;
    }
  }
`;
export default TimeSpendOnRouteStyled;
