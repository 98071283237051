import React from 'react';
import PropTypes from 'prop-types';
import ReportTableStyled from '../../ReportTableStyled';
import { COVERAGE_TYPE } from '../../../../data/enums/GraphQL';
import withLoading from '../../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../../data/enums/Component';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  handleRowClick: PropTypes.func,
  viewType: PropTypes.string,
  TableBody: PropTypes.func.isRequired,
  TableHeader: PropTypes.func.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  handleRowClick: () => null,
  viewType: COVERAGE_TYPE.CHANNEL,
};

const CoverageView = ({ ...props }) => {
  const { data, viewType, handleRowClick, TableHeader, TableBody } = props;
  return (
    <ReportTableStyled>
      <div className={`table-wrap ${viewType.toLowerCase()}`}>
        <table>
          <TableHeader viewType={viewType} />
          <tbody>
            {data.list.map((coverage) => (
              <TableBody key={coverage.id} data={coverage} viewType={viewType} onRowClick={handleRowClick} />
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      /> */}
    </ReportTableStyled>
  );
};

CoverageView.propTypes = propTypes;

CoverageView.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(CoverageView);

export default LoginViewWithErrorAndLoading;
