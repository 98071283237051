import { FORM_CONFIG } from '../../../../../v4/constants/formConfig';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';

const breadCrumbConfig = 'Linked Devices';

const localStorageKey = 'LINKED_DEVICES';

const formConfig = {
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Delete Linked Device',
    buttonName: 'Delete',
  },
  [EVENT_OPERATION.BLOCK_DEVICE]: {
    [FORM_CONFIG.TITLE]: 'Block Device',
    buttonName: 'Block',
  },
  [EVENT_OPERATION.UNBLOCK_DEVICE]: {
    [FORM_CONFIG.TITLE]: 'Unblock Device',
    buttonName: 'UnBlock',
  },
};

const IV = 'EReLzo2nzJQHTCKh';

const DEVICE_STATUS ={
  ACTIVE:'ACTIVE',
  INACTIVE:'INACTIVE',
  BLOCKED:'BLOCKED',
}


export {
  breadCrumbConfig, localStorageKey, formConfig,IV,DEVICE_STATUS
};
