


import { compose } from 'recompose';
import UserRoleDetails from './Detail';
import {
  query, mutation,UPDATE_PERMISSION,UPDATE_ROLE,CREATE_ROLE,
} from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedUserRoleDetails = compose(
  UPDATE_PERMISSION,UPDATE_ROLE,CREATE_ROLE,
  withRequestTracker({
    query,
    mutation,
  }),
)(UserRoleDetails);

export default composedUserRoleDetails;

