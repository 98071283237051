import { CustomSelect } from '../../../../components';
import { Switch, CheckBox } from '../../../../v4/components';

export const Target = ({ ...props }) => {
  const { data, handleDropDownChange, onFundamentalChange, onStockChange, onTargetChange, onInputChange } = props;
  return (
    <>
      <>
        <div className="permission-choose card flex-start mt-0 ">
          <div className="checkbox-horizontal">
            <div className="status-content">
              <label className="radio-list">
                <input
                  type="radio"
                  name="automatedSalesTarget"
                  value="automatedSalesTarget"
                  checked={data?.targetsData?.targets?.automatedSalesTarget}
                  onChange={e => onTargetChange(e, 'target')}
                />
                <span>Auto</span>
              </label>
              <label className="radio-list">
                <input
                  type="radio"
                  name="automatedSalesTarget"
                  value="manualSalesTarget"
                  checked={data?.targetsData?.targets?.manualSalesTarget}
                  onChange={e => onTargetChange(e, 'target')}
                />
                <span>Manual</span>
              </label>
            </div>
          </div>
        </div>
        <div className="form-input-wrap">
          <CustomSelect
            clearable={false}
            className="custom-select"
            placeholder="Select"
            labelContent="Target Dimension"
            getOptionLabel={type => type}
            getOptionValue={type => type}
            options={data?.targetsData?.target?.targetDimension}
            enableErrorDisplay={false}
            enableValidation
            value={data?.targetsData?.target?.targetDimension?.filter?.(
              type => type === data?.targetsData?.targetDimension,
            )}
            onChange={e => handleDropDownChange(e, ['targetsData', 'targetDimension'], handleDropDownChange)}
            customClass={'card'}
          />
          <CustomSelect
            clearable={false}
            className="custom-select"
            placeholder="Select"
            labelContent="Target Dimension Metric"
            getOptionLabel={type => type}
            getOptionValue={type => type}
            options={data?.targetsData?.target?.targetDimensionMetricList}
            enableErrorDisplay={false}
            enableValidation
            value={data?.targetsData?.target?.targetDimensionMetricList?.filter?.(
              type => type === data?.targetsData?.targetDimensionMetric,
            )}
            onChange={e => handleDropDownChange(e, ['targetsData', 'targetDimensionMetric'], handleDropDownChange)}
            customClass={'card'}
          />
          <div className="permission-choose card flex-start">
            <div className="checkbox-horizontal">
              <div className="status-content">
                <label className="radio-list">
                  <input
                    type="radio"
                    name="target"
                    value="salesTeamWise"
                    checked={data?.targetsData?.targets?.salesTeamWise}
                    onChange={e => onTargetChange(e, 'targetTwo')}
                  />
                  <span>Sales Team Wise</span>
                </label>
                <label className="radio-list">
                  <input
                    type="radio"
                    name="target"
                    value="subDWise"
                    checked={data?.targetsData?.targets?.subDWise}
                    onChange={e => onTargetChange(e, 'targetTwo')}
                  />
                  <span>Sub D Wise</span>
                </label>
              </div>
            </div>
          </div>
          <div className="brand-wise-wrap card">
            <Switch
              checked={data?.targetsData?.targets?.brandWise}
              labelContent={
                <>
                  <span>Brand Wise Target</span>
                  <span className="text">Enable brand wise target setting?</span>
                </>
              }
              onToggle={(checked, event) => {
                onInputChange(
                  {
                    formattedValue: !!checked,
                    target: {
                      name: 'active',
                      type: 'boolean',
                      value: !!checked,
                    },
                  },
                  'brandWise',
                  ['targetsData', 'targets', 'brandWise'],
                );
              }}
            />
          </div>
        </div>
      </>
      <>
        <h3 className="cardTitle">Fundamental Targets</h3>
        <div>
          {data?.targetsData?.fundamental_targets?.map((stock, index) => (
            <div key={index}>
              {stock.targetType === 'OUTLETTARGET' && (
                <div>
                  <div className="form-input-wrap card">
                    <Switch
                      checked={stock.status}
                      labelContent="Outlet Wise Target"
                      onToggle={e => onFundamentalChange(e, index, 'outletTarget')}
                    />
                  </div>
                  {stock.status && (
                    <div className="checkbox-list target-list card flex-start">
                      {stock.parameters.map((type, stockIndex) => (
                        <div key={stockIndex}>
                          <CheckBox
                            checked={type.status}
                            labelText={type.alias}
                            onCheckBoxClick={e => onStockChange(e, index, stockIndex, 'outletTarget')}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {stock.targetType === 'USERTARGET' && (
                <div>
                  <div className="form-input-wrap card">
                    <Switch
                      checked={stock.status}
                      labelContent="User Wise Target"
                      onToggle={e => onFundamentalChange(e, index, 'outletTarget')}
                    />
                  </div>
                  {stock.status && (
                    <div className="checkbox-list target-list card flex-start">
                      {stock.parameters.map((type, stockIndex) => (
                        <div key={stockIndex}>
                          <CheckBox
                            checked={type.status}
                            labelText={type.alias}
                            onCheckBoxClick={e => onStockChange(e, index, stockIndex, 'outletTarget')}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    </>
  );
};
