import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT, GET_DISTRIBUTORS } from '../../common/GqlAPI';

const GET_PRIMARY_STOCK_FROM_SAP = gql`
  query getPrimaryStockFromSAP {
    getPrimaryStockFromSAP
  }
`;

const ICOUPLOAD = graphql(
  gql`
    mutation ($input: ImportFileInput!) {
      importIcoCsv(input: $input)
    }
  `,
  {
    props: ({ mutate }) => ({
      upload: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const automatedIcoGeneration = graphql(
  gql`
    mutation AutomatedIcoGeneration($filter: FilterInput) {
      automatedIcoGeneration(filter: $filter)
    }
  `,
  {
    props: ({ mutate }) => ({
      automatedIcoGeneration: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_REQUEST_ORDER = graphql(
  gql`
    mutation ($input: distributorIdInput!) {
      createRequestOrder(input: $input)
    }
  `,
  {
    props: ({ mutate }) => ({
      createRequestOrder: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_ICO_LIST = gql`
  query getIco($offset: Int, $limit: Int, $filter: FilterInput) {
    getIco(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        distributor_id
        distributor
        sku_id
        sku
        brand
        sku_family_id
        sku_family
        upc
        reorder_moq
        current_stock
        sit
        pso
        total_stock
        doh
        avg_p3m_sales
        heavy_ups
        crr
        order_in_pcs
        order_in_cs
        diff_in_ico_and_fi
        icod
        ico
        stock_holding_days
      }
      count
    }
  }
`;

const GET_BRAND_DETAILS = gql`
  query getBrandDetails {
    catalogDetails(catalogId: 3) {
      rows {
        id
        title
        parentCatalogDetailId
      }
    }
  }
`;

const query = {
  getIco: GET_ICO_LIST,
  getDistributors: GET_DISTRIBUTORS,
  getBrandDetails: GET_BRAND_DETAILS,
  getPrimaryStockFromSAP: GET_PRIMARY_STOCK_FROM_SAP,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
  upload: ICOUPLOAD,
  createRequestOrder: CREATE_REQUEST_ORDER,
  automatedIcoGeneration
};

export {
  query, mutation, ICOUPLOAD, CREATE_REQUEST_ORDER , automatedIcoGeneration, GET_PRIMARY_STOCK_FROM_SAP
};
