import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../v4/components';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import CustomSelect from '../../../components/CustomSelect';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { PanelStyled } from '../../common/configuration';
import { StockAdjusStyled } from '../../inventory/stockAdjustment/StockAdjusStyled';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  distributorList: PropTypes.instanceOf(Array),
  componentDisplay: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  onDropDownChange: PropTypes.func.isRequired,
  distributorId: PropTypes.number,
};
const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  componentDisplay: {},
  distributorList: [],
  enableErrorDisplay: false,
  distributorId: 0,
};

const StockAdjustmentView = ({
  data,
  distributorId,
  pagination,
  onPageChange,
  distributorList,
  onDropDownChange,
  enableErrorDisplay,
  componentDisplay,
}) => (
  <StockAdjusStyled>
    {componentDisplay.distributorSelection && (
      <PanelStyled>
        <div>
          <CustomSelect
            small
            enableValidation
            options={distributorList}
            className='custom-select'
            placeholder='Select Distributor'
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            enableErrorDisplay={enableErrorDisplay}
            onChange={(event) => onDropDownChange(event.id)}
            value={distributorList.filter(({ id }) => id === distributorId) || []}
          />
        </div>
      </PanelStyled>
    )}
    <Fragment>
      {data.list.length > 0 ? (
        <div className='table-wrap'>
          <table>
            {TableHeader()}
            <tbody>{data.list.map((stockAvailability) => TableBody({ data: stockAvailability }))}</tbody>
          </table>
        </div>
      ) : null}
    </Fragment>
    {data.list.length > 1 && (
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    )}
  </StockAdjusStyled>
);

StockAdjustmentView.propTypes = propTypes;

StockAdjustmentView.defaultProps = defaultProps;

const StockAdjustmentViewWithErrorAndLoading = withErrorAndLoadingScreen(StockAdjustmentView);

export default StockAdjustmentViewWithErrorAndLoading;
