import { FORM_CONFIG } from '../../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { VAT_AMOUNT } from '../../../../../data/enums/GeneralConstants';
import { getTodaysDate } from '../../../../../utils/date';
import { refGenerator } from '../../../../../utils/refGenerator';
import { calculateRelativeDiscount } from '../../../../inventory/purchaseReturn/create/table/config';
import { calculatePromotionItemTotal } from '../../../orderProcessing/received/detail/config';

const requiredList = ['skuId', 'skuBatchId', 'returnQuantity', 'rate', 'discount', 'stockType'];

const STOCK_TYPE = {
  SALEABLE: { title: 'Sellable', value: 'SELLABLE' },
  DAMAGED: { title: 'Damaged', value: 'DAMAGED' },
  EXPIRED: { title: 'Expired', value: 'EXPIRED' },
};

const totalViewRefs = refGenerator(['billDiscount', 'tradeDiscount']);

const formMapper = (element) => ({
  id: element.id || '',
  skuId: element.SKU.id || null,
  skuBatchId: element.skuBatchId || null,
  batchName: element.SKUBatch ? element.SKUBatch.batchName : '',
  expiryDate: element.SKUBatch ? element.SKUBatch.expiryDate : getTodaysDate(),
  manufactureDate: element.SKUBatch ? element.SKUBatch.manufactureDate : getTodaysDate(),
  returnQuantity: element.returnQuantity || 0,
  stockType: element.stockType || STOCK_TYPE.SALEABLE.value,
  amountDetails: element.amountDetails || {},
  quantity: element.quantity || 0,
  promotionIds: element.promotionIds || null,
  rateDetails: element.rateDetails || {},
  distributorId: element.distributorId || null,
  orderId: element.orderId,
});

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: (element) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.MAPPER]: (data) => {
      const total = calculatePromotionItemTotal(data.Lines || []) || {};

      return { ...data, total };
    },
    [FORM_CONFIG.TITLE]: 'Promotion Details',
  },
};

const prepareSkuLinesForValidation = (currentOrder) => ({
  orderId: currentOrder.orderId,
  skuId: currentOrder.skuId,
  quantity: currentOrder.quantity,
  returnQuantity: currentOrder.returnQuantity,
});

const getUpdatedLineAmountDetails = (element, totalAmountObj, field = 'amountDetails') => {
  const subTotal =
    (element.returnQuantity || 0) * (element[field].rate || 0) -
    (element[field].promotionDiscount || 0) -
    (element[field].topUpDiscount || 0);

  const billDiscount = calculateRelativeDiscount(totalAmountObj.subTotal, totalAmountObj.billDiscount, subTotal) || 0;
  const tradeDiscount = calculateRelativeDiscount(totalAmountObj.subTotal, totalAmountObj.tradeDiscount, subTotal) || 0;
  const discountAmount =
    (element[field].promotionDiscount || 0 + element[field].topUpDiscount) + billDiscount + tradeDiscount;
  const taxableAmount = subTotal - billDiscount - tradeDiscount;
  const taxAmount = taxableAmount * VAT_AMOUNT;
  const netAmount = taxableAmount + taxAmount;

  return {
    netAmount,
    billDiscount,
    tradeDiscount,
    taxableAmount,
    taxAmount,
    discountAmount,
    subTotal,
  };
};

const updateSkuLine = (element, sku, skuBatch, totalAmountObj) => {
  const lineAmountDetails = getUpdatedLineAmountDetails(element, totalAmountObj);
  const amount = element.returnQuantity * element.amountDetails.rate;

  return {
    ...element,
    amountDetails: {
      ...element.amountDetails,
      amount,
      netAmount: lineAmountDetails.netAmount,
      billDiscount: lineAmountDetails.billDiscount,
      tradeDiscount: lineAmountDetails.tradeDiscount,
      grossAmount: amount * (1 + (VAT_AMOUNT || 0)),
      subTotal: lineAmountDetails.subTotal,
      taxableAmount: lineAmountDetails.taxableAmount,
      taxAmount: lineAmountDetails.taxAmount,
      discountAmount: lineAmountDetails.discountAmount,
    },
    SKU: {
      id: sku.id,
      title: sku.title,
    },
    SKUBatch: {
      id: skuBatch.id,
      batchName: skuBatch.batchName,
      manufactureDate: element.manufactureDate,
      expiryDate: element.expiryDate,
    },
  };
};

export {
  formConfig, totalViewRefs, prepareSkuLinesForValidation, updateSkuLine
};
