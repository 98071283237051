import VerticalDragSwitch from '../../../components/VerticalDragSwitch';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragList from './DragList';
import useDragAndDrop from '../../../components/DragAndDropTable/useDragAndDropTable.hook';

export const Sequence = ({ ...props }) => {
  const { sequenceData, onInputChange, onSequenceChange, sequenceTitle } = props;
  const { items, onDragEnd, getListStyle, getItemStyle } = useDragAndDrop(sequenceData, onSequenceChange);
  return (
    <>
      <h3 class="cardTitle mt-0">{sequenceTitle ?? 'Sequence'}</h3>
      <h4 className="subCardTitle">Drag to arrange the order of the sequence.</h4>
      <div className="sequence">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="Table">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="outlet-list"
              >
                {items?.map((item, index) => (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {provided => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.draggingOver, provided.draggableProps.style)}
                        >
                          <DragList key={index} item={item} index={index} onInputChange={onInputChange} />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};
