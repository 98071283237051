import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import SrnView from '../../sales/srn/View';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onIconClick: PropTypes.func,
  onHeaderClick: PropTypes.func.isRequired,
};

const defaultProps = {
  onIconClick: () => null,
  onPageChange: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    onIconClick,
    pagination,
    onHeaderClick,
    sapEnabled,
    billingEnabled,
    lndEnabled,
    approvedGrn,
    permission,
  } = props;
  return (
    <Fragment>
      <div className="table-wrap prn">
        <table>
          {TableHeader(onHeaderClick, approvedGrn)}

          <tbody>
            {data.list.map(grn =>
              TableBody(grn, onIconClick, sapEnabled, approvedGrn, billingEnabled, lndEnabled, permission),
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
