import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../utils/composition/withAlert';
import * as queryService from '../../../../../base/query.service';
import { getCurrentDay, getP3MStartDay } from '../../../../../../utils/date';
import { TableBody, TableHeader } from './tableConfig';
import { ORDER_HISTORY_VIEW } from '../../../../../../data/enums/GraphQL';
import { newList } from './config';

const propTypes = {
  id: PropTypes.number.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getOrderHistory: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      id: props.id || 0,
      data: {
        list: [],
        total: 0,
      },
      order: [],
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: {},
        date: { ...queryService.baseQueryParameters.date },
        filter: {
          ...queryService.baseQueryParameters.filter,
          retail_outlet_id: [props.id || 0],
        },
        type: ORDER_HISTORY_VIEW.BRAND,
      },
      date: {
        start: getP3MStartDay(),
        end: getCurrentDay(),
      },
    };

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getList,
    );
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this.getList();
  }

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  getList = () => {
    const { getOrderHistory } = this.props;
    const { data, id, date } = this.state;
    this.setState({ order: [] });
    getOrderHistory(
      {
        offset: 0,
        filter: {
          filters: [{ column: 'retail_outlet_id', value: [id.toString()] }],
          dateRange: date,
        },
      },
      {
        handleSuccess: response => {
          data.list = response.data.invoiceHistory.rows;
          const dateSortedList = data.list.sort((a, b) => {
            const dateA = new Date(a.invoiceDate);
            const dateB = new Date(b.invoiceDate);
            return dateB - dateA;
          });

          const invoicedOrdersData = dateSortedList
            .map(item =>
              item.invoicedOrders.map(invoicedData => ({
                invoiceDate: item.invoiceDate,
                amountDetails: item.amountDetails,
                ...invoicedData,
              })),
            )
            .flat();
          const groupedArray = this.groupBy(invoicedOrdersData, this.locationName);
          const keys = ['group', 'list'];
          const order = groupedArray.map(r => keys.reduce((o, k, i) => ((o[k] = r[i]), o), {}));
          this.setState({
            data,
            order,
          });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  groupBy = (list, callback) => {
    const group = list.reduce((acc, x) => {
      const key = callback(x);
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(x);
      return acc;
    }, {});

    return Object.entries(group);
  };

  locationName = obj => {
    const { queryParameters } = this.state;
    if (queryParameters.type === ORDER_HISTORY_VIEW.BRAND) {
      return obj.SKU.brand.title;
    }
    return obj.invoiceDate;
  };

  render() {
    const { serverResponseWaiting } = this.props;

    const { queryParameters, date, order } = this.state;
    const groupedSku = order.map(received =>
      received.list
        .map(SKU => ({
          quantity: SKU.quantity,
          title: SKU.SKU.title,
          Rates: SKU.rateDetails.rlp,
          group: received.group,
        }))
        .reduce((accumulator, cur) => {
          const sku = accumulator.find(elem => elem.title === cur.title);
          if (sku) sku.quantity += cur.quantity;
          else accumulator.push(cur);
          return accumulator;
        }, []),
    );

    return (
      <Fragment>
        <View
          loading={serverResponseWaiting}
          handleViewTypeChange={this.basePaginationService.handleViewTypeChange}
          viewTypeConfig={{
            value: queryParameters.type,
            list: newList,
          }}
          handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
          viewType={queryParameters.type}
          TableHeader={TableHeader}
          TableBody={TableBody}
          date={date}
          order={groupedSku}
        />
      </Fragment>
    );
  }
}

OrderHistory.propTypes = propTypes;

OrderHistory.defaultProps = defaultProps;

export default withAlert()(OrderHistory);
