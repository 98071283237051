import { Switch } from '../../../../v4/components';
import { Button, Input } from '../../../../components';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';

export const Service = ({ ...props }) => {
  const { data, onInputChange, headerLabelConfig, disableGrnJobButton, onGrnJobClick } = props;
  return (
    <>
      <div className="form-input-wrap">
        <div className="placeholder card" data-placeholder="(in M)">
          <Input
            value={data?.serviceData?.geoFencing || ''}
            name="geoFencing"
            type="number"
            rule="matches"
            labelContent={`${headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'}'s Order Radius`}
            argument={/^[0-9]*$/} /* matches alphanumeric, underscore ,'-' & ' ' */
            placeholder="Order Radius"
            enableErrorDisplay={false}
            enableValidation
            onChange={event => onInputChange(event, 'serviceData')}
          />
        </div>
        <Switch
          checked={data?.serviceData?.web_order}
          labelContent="Enable create order "
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'web_order',
              ['serviceData', 'web_order'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.orderForMultipleDistributors}
          labelContent="Order fulfilment by multiple SubDs "
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'orderForMultipleDistributors',
              ['serviceData', 'orderForMultipleDistributors'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.brandDistributionCoverageTarget}
          labelContent="Brand Distribution Coverage Target"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'brandDistributionCoverageTarget',
              ['serviceData', 'brandDistributionCoverageTarget'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.outletCoverageTarget}
          labelContent="Active Outlet Coverage Target"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'outletCoverageTarget',
              ['serviceData', 'outletCoverageTarget'],
            );
          }}
          classes="card"
        />
        {/* <Switch */}
        {/*  checked={data.multipleDseInRoute} */}
        {/*  labelContent="Support for multiple routes" */}
        {/*  onToggle={(checked, event) => { */}
        {/*    onInputChange( */}
        {/*      { */}
        {/*        formattedValue: !!checked, */}
        {/*        target: { */}
        {/*          name: 'active', */}
        {/*          type: 'boolean', */}
        {/*          value: !!checked, */}
        {/*        }, */}
        {/*      }, */}
        {/*      'multipleDseInRoute', */}
        {/*      ['multipleDseInRoute'], */}
        {/*    ); */}
        {/*  }} */}
        {/* /> */}
        <Switch
          checked={data?.serviceData?.pfd}
          labelContent="Enable Plan For the Day"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'pfd',
              ['serviceData', 'pfd'],
            );
          }}
          classes="card"
        />
        {/* <Switch */}
        {/*  checked={data.payment} */}
        {/*  labelContent="Enable Payment Collection" */}
        {/*  onToggle={(checked, event) => { */}
        {/*    onInputChange( */}
        {/*      { */}
        {/*        formattedValue: !!checked, */}
        {/*        target: { */}
        {/*          name: 'active', */}
        {/*          type: 'boolean', */}
        {/*          value: !!checked, */}
        {/*        }, */}
        {/*      }, */}
        {/*      'payment', */}
        {/*      ['payment'], */}
        {/*    ); */}
        {/*  }} */}
        {/* /> */}
        <Switch
          checked={data?.serviceData?.srn}
          labelContent="Enable Sales Returns"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'srn',
              ['serviceData', 'srn'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.sbd}
          labelContent="SBD"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'sbd',
              ['serviceData', 'sbd'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.focusedSKU}
          labelContent="Focused SKU"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'focusedSKU',
              ['serviceData', 'focusedSKU'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.routeSegmentation}
          labelContent="Enable Route Segmentation"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'routeSegmentation',
              ['serviceData', 'routeSegmentation'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.sapIntegration}
          labelContent="Enable SAP Integration"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'sapIntegration',
              ['serviceData', 'sapIntegration'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.callRoster}
          labelContent="Enable Call Roster "
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'callRoster',
              ['serviceData', 'callRoster'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.brand_wise_order?.status}
          labelContent="Enable Brand Wise Order"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'brand_wise_order',
              ['serviceData', 'brand_wise_order', 'status'],
            );
          }}
          classes="card"
        />
        <Switch
          checked={data?.serviceData?.rigoIntegration}
          labelContent="Enable RIGO Integration"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'inactive',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'rigoIntegration',
              ['serviceData', 'rigoIntegration'],
            );
          }}
          classes="card"
        />

        {/* { data.srn && ( */}
        {/*  <> */}
        {/*    <div className="permission-choose"> */}
        {/*      <label>Choose one</label> */}
        {/*      <div className="checkbox-horizontal"> */}
        {/*        <div className="status-content"> */}
        {/*          <label className="radio-list"> */}
        {/*            <input */}
        {/*              type="radio" */}
        {/*              name="sales" */}
        {/*              value="full" */}
        {/*              checked={data.sales && data.sales.full} */}
        {/*              onChange={e => onSalesChange(e)} */}
        {/*            /> */}
        {/*            <span>Full</span> */}
        {/*          </label> */}
        {/*          <label className="radio-list"> */}
        {/*            <input */}
        {/*              type="radio" */}
        {/*              name="sales" */}
        {/*              value="partial" */}
        {/*              checked={data.sales && data.sales.partial} */}
        {/*              onChange={e => onSalesChange(e)} */}
        {/*            /> */}
        {/*            <span>Partial</span> */}
        {/*          </label> */}
        {/*          <label className="radio-list"> */}
        {/*            <input */}
        {/*              type="radio" */}
        {/*              name="sales" */}
        {/*              value="both" */}
        {/*              checked={data.sales && data.sales.both} */}
        {/*              onChange={e => onSalesChange(e)} */}
        {/*            /> */}
        {/*            <span>Both</span> */}
        {/*          </label> */}
        {/*        </div> */}
        {/*      </div> */}
        {/*    </div> */}
        {/* </>)} */}
        {/* <Switch */}
        {/*  checked={data.segmentation} */}
        {/*  labelContent="Enable Route Segmentation" */}
        {/*  onToggle={(checked, event) => { */}
        {/*    onInputChange( */}
        {/*      { */}
        {/*        formattedValue: !!checked, */}
        {/*        target: { */}
        {/*          name: 'active', */}
        {/*          type: 'boolean', */}
        {/*          value: !!checked, */}
        {/*        }, */}
        {/*      }, */}
        {/*      'segmentation', */}
        {/*      ['segmentation'], */}
        {/*    ); */}
        {/*  }} */}
        {/* /> */}
      </div>
      <>
        <h3 className="cardTitle">Enable SMS</h3>
        <div className="form-input-wrap">
          <>
            <Switch
              checked={data?.serviceData?.smsIntegration}
              labelContent="Payment "
              onToggle={(checked, event) => {
                onInputChange(
                  {
                    formattedValue: !!checked,
                    target: {
                      name: 'active',
                      type: 'boolean',
                      value: !!checked,
                    },
                  },
                  'smsIntegration',
                  ['serviceData', 'smsIntegration'],
                );
              }}
              classes="card"
            />
          </>
          <>
            <Switch
              checked={data?.serviceData?.smsforOrderIntegration}
              labelContent="Invoice Orders "
              onToggle={(checked, event) => {
                onInputChange(
                  {
                    formattedValue: !!checked,
                    target: {
                      name: 'active',
                      type: 'boolean',
                      value: !!checked,
                    },
                  },
                  'smsforOrderIntegration',
                  ['serviceData', 'smsforOrderIntegration'],
                );
              }}
              classes="card"
            />
          </>
        </div>

        <h3 className="cardTitle">Adhoc Job Scheduling</h3>
        <div>
          <div className="placeholder card adhoc-card">
            <p>Pending GRN</p>
            <Button primary onClick={() => onGrnJobClick()} title={'Run'} disabled={disableGrnJobButton} />
          </div>
        </div>
      </>
    </>
  );
};
