import styled from 'styled-components';


export const BillingDeliveryPanelContainer = styled.div`
  --overlay-width: calc(75% - (4rem + 56px));

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1040;
    transition: opacity 0.3s ease 0s;
    background-color: rgba(57, 58, 74, .8);
  }

  .container-fluid {
    padding: 1rem 2rem;
  }

  .drawer-container {
    position: fixed;
    border-top: 1px solid rgb(231, 231, 237); 
    right: calc(-75% + (4rem + 56px));
    bottom: 0;
    width: calc(75% - (4rem + 56px));
    transition: right 0.5s ease-in-out;
    top: 0;
    &.active {
      box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
      z-index: 1045;
      background: #fff;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

 .billing-delivery-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 1rem;
    border-bottom: 1px solid #E7E7ED;

    /* .icon {
      color: #0B4FFF;
    } */

    h3 {
      margin: 0;
      font-weight: 400;
      margin-left: 1rem;
    }

    .time-counter {
      border: 1px solid rgba(218,20,20,0.4);
      border-radius: 4px;
      color: #DA1414;
      margin-left: auto;
      padding: 8px 12px;
    }

    .icon.cross {
      margin-right: -2rem;
    }
  }

  .billing-delivery-form {
    height: calc(100% - 10rem);
    width: var(--overlay-width);
    width:100%;
    overflow-y: auto;
    border-bottom: 1px solid #E7E7ED;

    & > div {
      padding: 1rem 3rem;
    }

    label {
      display: block;
      font-weight: 500;
      margin-bottom: 8px;
    }

    textarea {
      border-radius: 4px;
      width: 100%;
      padding: 1rem;
      &:focus-visible {
        border: 1px solid #0B5FFF !important;
        background-color: #F7F8F9 !important;
        outline: none;
      }
    }

    .delivery-date > div:nth-child(2) {
      width: 100%;
      line-height: 2rem;
      background-color: #F1F2F5;

      .moment {
        span {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .delivery-time {
    .time{
      .form-input, .form-select-input {
        margin-bottom: 0;

        input {
          border: 1px solid rgb(231,231, 237);
          background-color: rgb(241, 242,245);
          border-radius: 4px;
        }
      }

      .form-select-input{
        .select-css {
          min-width: 80px;
          
          .zindex-2__control {
            border: 1px solid rgb(231,231, 237);
            background-color: rgb(241, 242,245);
            border-radius: 4px;
          }
        }

          label {
          display: none;
        }
      }
      label {
        color: #A7A9BC;
        font-size: 10px;
        font-weight: 500;
        margin-top: 6px;
      }
    }
  }

  .order-summary-action-btns {
    display: flex;
    position: fixed;
    align-items: center;
    bottom: 1rem;
    right: 1rem;
    width: calc(var(--overlay-width) - 5rem);
    justify-content: space-between;
  }

  .modal-inner {
    max-width: 70%;
  }

  .dialog-wrap { 
    max-width: 70%;
    .evolve-dialog__body {
      height: 65vh;
      overflow-y: auto;
    }
  }

`
