import {
  CheckBox, defaultMenuConfigList, menuAction, tableHeaderWithCheckBox
} from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';

const labelMappings = {
  checkbox:'checkbox',
  id: 'id',
  designation: 'designation',
  actionHolders: 'actionHolders'
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.designation,
    title: 'Designation',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const menuConfigList = [defaultMenuConfigList[EVENT_OPERATION.UPDATE], defaultMenuConfigList[EVENT_OPERATION.DELETE]];

export const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

const returnTableBody = (data, checkedList, secondaryCheckboxHandler, permission,onIconClick) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => secondaryCheckboxHandler(data.id)} />
    </td>
  );
  table[labelMappings.id] = (
    <td key={`${data.id}-id`}>
      {data.id}
    </td>
  );
  table[labelMappings.designation] = (
    <td key={`${data.id}-designation`}>
      {data.title}
    </td>
  );
  table[labelMappings.actionHolders] = (
    <td key={`${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

export const TableBody = ({ data, checkedList, secondaryCheckboxHandler, permission, onIconClick }) => {
  const tableBody = returnTableBody(data, checkedList, secondaryCheckboxHandler, permission, onIconClick);

  return (
    <tr key={`user-${data.id}`}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};
