import React from 'react';
import PropTypes from 'prop-types';
import LedgerAdd from '../../../../../components/LedgerAdd';
import TableView from './Table';
import { Button } from '../../../../../../v4/components';
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
import LedgerStyled from './LedgerStyled';
import { PanelStyled } from '../../../../../common/configuration';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
  billingUser: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCreateClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  townId: PropTypes.number.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  handleTableIconClick: PropTypes.func.isRequired,
};
const View = (props) => {
  const {
    onCancel,
    onSubmit,
    show,
    handleCreateClick,
    data,
    loading,
    id,
    billingUser,
    townId,
    permission,
    handleTableIconClick,
    update,
    ledgerInfo,
  } = props;

  return (
    <LedgerStyled>
      <div className='tab-section ledger'>
        <PanelStyled>
          <div className='ledger-top-wrap'>
            <div className='absolute'>
              {data.length}
              <span> Select Customer Ledger</span>
            </div>
            {permission.create && (
              <Button iconName='plus' className='primary small ' onClick={handleCreateClick}>
                <span>Create</span>
              </Button>
            )}
          </div>
        </PanelStyled>

        {show && (
          <LedgerAdd
            onCancel={onCancel}
            onSubmit={onSubmit}
            id={id}
            billingUser={billingUser}
            townId={townId}
            ledgerInfo={ledgerInfo}
            search
            update={update}
          />
        )}
        <TableView
          data={data}
          loading={loading}
          billingUser={billingUser}
          permission={permission}
          handleTableIconClick={handleTableIconClick}
        />
      </div>
    </LedgerStyled>
  );
};

View.propTypes = propTypes;

export default withErrorAndLoadingScreen(View);
