import { compose } from 'recompose';
import DataMask from './DataMask';
import withRequestTracker from '../../utils/composition/withRequestTracker';
import { mutation, MASK_INVOICES } from './API';

const ComposeDataMask = compose(
  MASK_INVOICES,
  withRequestTracker({
    mutation,
  }),
)(DataMask);

export default ComposeDataMask;
