import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Col, Row, CustomSelect
} from '../../../components';
import withAlert from '../../../utils/composition/withAlert';
import withLoading from '../../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../../data/enums/ErrorMessage';

const propTypes = {
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
};

const StockAdjustmentForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, adjustmentTypeList,handleDropDownChange, binList,dropDownCallBack } = props;
  return (
    <Form>
      <div className='scrollable-body-vertical'>
        <Row>
          <Col className='mb-24' md={6}>
            <Input
              name='skuTitle'
              type='text'
              disabled
              labelContent='SKU'
              placeholder='SKU'
              enableValidation
              value={data.skuTitle}
              enableErrorDisplay={enableErrorDisplay}
              ref={(ref) => (refsObj.skuTitle = ref)}
              errorMessage={data.skuTitle ? INVALID : EMPTY}
            />
          </Col>
          <Col className='mb-24' md={3}>
            <Input
              name='batch'
              type='text'
              disabled
              labelContent='Batch/Lot ID'
              placeholder='Batch'
              value={data.skuBatchId}
            />
          </Col>
          {data?.has_warehouse&&<Col className='mb-24' md={3}>
            <CustomSelect
              className='custom-select'
              placeholder='BIN Location'
              getOptionLabel={({ bin_location_number }) => bin_location_number}
              getOptionValue={({ bin_location_id }) => bin_location_id}
              options={data?.binWiseStock}
              value={data?.binWiseStock.filter(({ bin_location_id }) => bin_location_id === data.bin_location_id)}
              onChange={(e) => {
                handleDropDownChange(e?.bin_location_id, ['bin_location_id'],dropDownCallBack);
              }}
              labelContent='BIN Location'
            />
          </Col>}
          <Col className='mb-24' md={4}>
            <CustomSelect
              enableValidation
              options={adjustmentTypeList}
              className='custom-select'
              placeholder='Select'
              labelContent='Adjustment Type'
              getOptionValue={({ type }) => type}
              getOptionLabel={({ title }) => title}
              enableErrorDisplay={enableErrorDisplay}
              ref={(ref) => (refsObj.adjustment_type = ref)}
              onChange={(event) => handleDropDownChange(event?.type,['adjustment_type'])}
              value={adjustmentTypeList.filter((a) => a.type === data?.adjustment_type) || []}
            />
          </Col>
          <Col className='mb-24' md={4}>
            <Input
              name='per_unit_rate'
              type='number'
              rule='isInt'
              labelContent='Per Unit Rate'
              enableValidation
              value={data.per_unit_rate}
              onChange={(event) => handleInputChange(event)}
            />
          </Col>
          <Col className='' md={4}>
            <Input
              name='shortages'
              type='number'
              rule='isInt'
              labelContent='Shortage Quantity'
              enableValidation
              value={data.shortages}
              onChange={(event) => handleInputChange(event)}
            />
            <span className='available-qty'>{`Shortage Stock: ${data.shortageStock}`}</span>
          </Col>
        </Row>
        {data?.has_warehouse ?(
          <Row className='border-style'>
            <Col className='mb-24 qty-bin' md={3.5}>
              <Input
                name='quantity'
                type='number'
                rule='isInt'
                labelContent='Sellable Quantity'
                value={data.quantity}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleInputChange(event)}
              />
              <span className='available-qty'>{`Available Quantity: ${data.balance}`}</span>
              <CustomSelect
                className='custom-select'
                placeholder='New BIN Location'
                getOptionLabel={({ binLocationNumber }) => binLocationNumber}
                getOptionValue={({ id }) => id}
                options={binList}
                value={binList.filter(({ id }) => id === data.sellableBinId)}
                onChange={(e) => {
                  handleDropDownChange(e?.id, ['sellableBinId']);
                }}
                labelContent='New BIN Location'
              />
            </Col>
            <Col className='mb-24 qty-bin' md={3.5}>
              <Input
                name='damages'
                type='number'
                rule='isInt'
                labelContent='Damage Quantity'
                enableValidation
                value={data.damages}
                onChange={(event) => handleInputChange(event)}
              />
              <span className='available-qty'>{`Damage Stock: ${data.damagedStock}`}</span>
              <CustomSelect
                className='custom-select'
                placeholder='New BIN Location'
                getOptionLabel={({ binLocationNumber }) => binLocationNumber}
                getOptionValue={({ id }) => id}
                options={binList}
                value={binList.filter(({ id }) => id === data.damagedBinId)}
                onChange={(e) => {
                  handleDropDownChange(e?.id, ['damagedBinId']);
                }}
                labelContent='New BIN Location'
              />
            </Col>
            <Col className='mb-24 qty-bin' md={3.5}>
              <Input
                name='expiry'
                type='number'
                rule='isInt'
                labelContent='Expire Quantity'
                enableValidation
                value={data.expiry}
                onChange={(event) => handleInputChange(event)}
              />
              <span className='available-qty'>{`Expire Stock: ${data.expiredStock}`}</span>
              <CustomSelect
                className='custom-select'
                placeholder='New BIN Location'
                getOptionLabel={({ binLocationNumber }) => binLocationNumber}
                getOptionValue={({ id }) => id}
                options={binList}
                value={binList.filter(({ id }) => id === data.expiredBinId)}
                onChange={(e) => {
                  handleDropDownChange(e?.id, ['expiredBinId']);
                }}
                labelContent='New BIN Location'
              />
            </Col>
          </Row>
        ):(
          <Row>
            <Col className='mb-24' md={4}>
              <Input
                name='quantity'
                type='number'
                rule='isInt'
                labelContent='Sellable Quantity'
                enableValidation
                value={data.quantity}
                argument={{
                  min: 1,
                  allow_leading_zeroes: false,
                }}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleInputChange(event)}
                errorMessage={data.returnQuantity ? INVALID : EMPTY}
              />
              <span className='available-qty'>{`Available Quantity: ${data.balance}`}</span>
            </Col>
            <Col className='mb-24' md={4}>
              <Input
                name='damages'
                type='number'
                rule='isInt'
                labelContent='Damage Quantity'
                enableValidation
                value={data.damages}
                onChange={(event) => handleInputChange(event)}
              />
              <span className='available-qty'>{`Damage Stock: ${data.damagedStock}`}</span>
            </Col>
            <Col className='mb-24' md={4}>
              <Input
                name='expiry'
                type='number'
                rule='isInt'
                labelContent='Expire Quantity'
                enableValidation
                value={data.expiry}
                onChange={(event) => handleInputChange(event)}
              />
              <span className='available-qty'>{`Expire Stock: ${data.expiredStock}`}</span>
            </Col>
          </Row>
        )
        }
        <Row>
          <Col className='mb-24' md={12}>
            <Input
              name='reason'
              type='text'
              labelContent='Reason'
              placeholder='Reason'
              enableValidation
              value={data.reason}
              enableErrorDisplay={enableErrorDisplay}
              ref={(ref) => (refsObj.reason = ref)}
              onChange={(event) => handleInputChange(event)}
              errorMessage={data.reason ? INVALID : EMPTY}
            />
          </Col>
        </Row>
      </div>
    </Form>
  );
};

StockAdjustmentForm.propTypes = propTypes;

StockAdjustmentForm.defaultProps = defaultProps;

const StockAdjustmentFormWithErrorAndLoading = withLoading(StockAdjustmentForm);
const StockAdjustmentFormWithAlert = withAlert()(StockAdjustmentFormWithErrorAndLoading);

export default StockAdjustmentFormWithAlert;
