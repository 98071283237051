import styled from 'styled-components';

const FormStyled = styled.div`
.form-input {
    margin-bottom: 0px;
}
.single-date-picker{
    margin-top: 0px;
}

.audit-blocked {
    &:after {
        content: "Audit is still pending for this SKU.";
        top: 85%;
        left: 4%;
        width: 120%;
    }
}
`;

export default FormStyled;
