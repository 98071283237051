import gql from 'graphql-tag';
import { OUTLET_FRAGMENT_WITHOUT_OUTSTANDING_TRANSACTION_ID, } from '../../common/GqlFragments';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const SRN_AMOUNT_FRAGMENT = gql`
fragment SrnAmountFragment on SRNAmount {
  billDiscount
  subTotal
  taxableAmount
  tradeDiscount
  topUpDiscount
  taxAmount
  netAmount
}
`;

const DISTRIBUTOR_FRAGMENT = gql`
fragment DistributorFragment on Distributor {
  id
  title
}
`;

const CUSTOMER_FRAGMENT = gql`
fragment OutletCustomerFragment on RetailOutlet {
  id
  title
}
`;

export const SALES_RETURN_FRAGMENT = gql`
  fragment SalesReturnFragment on SalesReturn {
    id
    invoiceNumber
    date: returnDate
    SRNInvoiceNumber
    srnDate
    RetailOutlet {
      ...OutletCustomerFragment
    }
    type
    reason
    Distributor {
      ...DistributorFragment
    }
    srnAmount {
      ...SrnAmountFragment
    } 
    image {
      src
      thumbnail
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${DISTRIBUTOR_FRAGMENT}
  ${SRN_AMOUNT_FRAGMENT}
`;

const GET_RETURN_LIST = gql`
  query getReturnRequestsList($offset: Int, $limit: Int, $filter: FilterInput) {
    salesReturnNotes(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...SalesReturnFragment
      }
      count
    }
  }
  ${SALES_RETURN_FRAGMENT}
`;

const query = {
  getReturnRequestsList: GET_RETURN_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { query, mutation };
