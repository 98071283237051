import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CoverageMapStyled from './CoverageMapStyled';
import { mapStyles } from '../../data';
import initFullscreenControl from './services/initFullScreenControl';
import DseListView from './components/DseListView/DseListView';
import successfulCallMarker from './assets/markers/successful-call.svg';
import unsuccessfulCallMarker from './assets/markers/unsuccessful-call.svg';
import remainingCallMarker from './assets/markers/remaining-call.svg';
import Legend from './components/Legend/Legend';
import setMarkerImage from '../../../configuration/arc/services/setMarkerImage';

const propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
  zoom: PropTypes.number,
};

const defaultProps = {
  zoom: 11,
};

class Map extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const outletKey = data[0].outlet[0];
    const position = outletKey
      ? [parseFloat(outletKey.location.latitude), parseFloat(outletKey.location.longitude)]
      : [27.700769, 85.30014];

    this.state = {
      center: {
        lat: position[0],
        lng: position[1],
      },
      infoWindow: new window.google.maps.InfoWindow(),
      google: window.google,
      isMapFullScreen: false,
      allDseMarkers: [],
      selectedDseId: null,
      map: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data !== this.props.data ||
      prevState.center.lat !== this.state.center.lat ||
      prevState.center.lng !== this.state.center.lng
    ) {
      this.loadMap();
    }
  }

  handleMarkerClick = (marker, name, route) => {
    const { infoWindow, map } = this.state;
    infoWindow.close();
    const content = `<div>Outlet: ${name}</div><div>Route: ${route}</div>`;
    infoWindow.setContent(content);
    infoWindow.open(map, marker);
  };

  loadMarkerToMap = map => {
    const { google } = this.state;
    const { data } = this.props;
    const allDseMarkers = [];
    if (data.length !== 0 && data.length > 0) {
      const filterData = data.filter(d => d.outlet !== null);
      if (filterData.length !== 0) {
        const { outlet } = filterData[0];
        const position = outlet.length > 1 ? outlet[parseInt(outlet.length / 2, 10)].location : outlet[0].location;
        this.setState({
          center: {
            lat: parseFloat(position.latitude),
            lng: parseFloat(position.longitude),
          },
        });
      }
      data.forEach(dse => {
        dse.outlet &&
          dse.outlet.forEach(outlet => {
            const marker = new google.maps.Marker({
              position: {
                lat: parseFloat(outlet.location.latitude),
                lng: parseFloat(outlet.location.longitude),
              },
              map,
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 6,
                fillColor: dse.color,
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: '#fff',
                strokeOpacity: 0.6,
              },
              class: 'marker',
              iconSize: (100, 100),
              dseId: dse.id,
              type: outlet.type,
              originalColor: dse.color,
            });
            marker.addListener('click', () => this.handleMarkerClick(marker, outlet.title, dse.route));
            allDseMarkers.push(marker);
          });
      });
      this.setState({ allDseMarkers });
      this.allowFullScreen(map);
    }
  };

  loadMap = () => {
    const { google, center } = this.state;
    const { zoom } = this.props;

    const map = new google.maps.Map(document.getElementById('map'), {
      center: {
        lat: center.lat,
        lng: center.lng,
      },
      zoom,
      styles: mapStyles,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
    });
    this.loadMarkerToMap(map);
  };

  allowFullScreen = map => {
    initFullscreenControl(map, this.refFullScreen, isMapFullScreen => {
      document.onwebkitfullscreenchange =
        document.onmsfullscreenchange =
        document.onmozfullscreenchange =
        document.onfullscreenchange =
          () => {
            if (!isMapFullScreen) {
              map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].pop(this.dseListRef);
              map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].pop(this.legendRef);
              this.resetMap();
            } else {
              this.initDseDiv(map);
              this.initLegendDiv(map);
            }
            this.setState({ isMapFullScreen, map });
          };
    });
  };

  resetMap = () => {
    const { allDseMarkers, map } = this.state;
    allDseMarkers.forEach(marker => {
      marker.setVisible(true);
      marker.set('icon', {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 6,
        fillColor: marker.originalColor,
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#fff',
        strokeOpacity: 0.6,
      });
    });
    this.setState({ selectedDseId: null });
    map.setZoom(11);
  };

  initDseDiv = map => {
    this.dseListRef.style = { display: 'block' };
    this.dseListRef.classList.add('dseList');
    map.controls[window.google.maps.ControlPosition.RIGHT_CENTER].push(this.dseListRef);
  };

  initLegendDiv = map => {
    this.legendRef.style = { display: 'block' };
    this.legendRef.classList.add('legend');
    map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(this.legendRef);
  };

  onDseCardClick = dseId => {
    const { allDseMarkers, map, selectedDseId } = this.state;
    if (selectedDseId !== dseId) {
      this.setState({ selectedDseId: dseId });
      const currentDseMarkers = allDseMarkers.filter(marker => marker.dseId === dseId);
      map.setZoom(15);
      if (currentDseMarkers.length) {
        map.setCenter({ lat: currentDseMarkers[0].position.lat(), lng: currentDseMarkers[0].position.lng() });
      }
      allDseMarkers.forEach(marker => {
        if (marker.dseId !== dseId) {
          marker.setVisible(false);
        } else {
          if (marker.type === 'SUCCESSFUL') {
            marker.set('icon', setMarkerImage(successfulCallMarker, null, null, 25, 25));
          } else if (marker.type === 'UNSUCCESSFUL') {
            marker.set('icon', setMarkerImage(unsuccessfulCallMarker, null, null, 25, 25));
          } else if (marker.type === 'SCHEDULED') {
            marker.set('icon', setMarkerImage(remainingCallMarker, null, null, 25, 25));
          }
          marker.setVisible(true);
        }
      });
    } else {
      this.resetMap();
    }
  };

  render() {
    const { isMapFullScreen, selectedDseId } = this.state;
    const { data } = this.props;
    return (
      <CoverageMapStyled>
        <div className="map-wrapper-inner relative">
          <div id="map" />
          <div className="controls">
            {data.length !== 0 && data.length > 0 && (
              <div ref={ctx => (this.refFullScreen = ctx)}>
                {
                  <img
                    src={`/image/icons/${isMapFullScreen ? 'exit-fullscreen' : 'enter-fullscreen'}.svg`}
                    className="full-screen"
                    title="Toggle Fullscreen"
                    style={{
                      height: '24px',
                      width: '24px',
                      cursor: 'pointer',
                    }}
                    alt="image"
                  />
                }
              </div>
            )}

            <div
              ref={ctx => (this.dseListRef = ctx)}
              id="dse-list"
              style={{
                display: 'none',
              }}
            >
              {data.length !== 0 && data.length > 1 && (
                <DseListView onDseCardClick={this.onDseCardClick} data={data} selectedDseId={selectedDseId} />
              )}
            </div>
            <div
              ref={ctx => (this.legendRef = ctx)}
              id="legend"
              style={{
                display: 'none',
              }}
            >
              {data.length !== 0 && data.length > 1 && selectedDseId && <Legend />}
            </div>
          </div>
        </div>
      </CoverageMapStyled>
    );
  }
}

Map.propTypes = propTypes;

Map.defaultProps = defaultProps;

export default Map;
