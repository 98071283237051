import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../../common/GqlAPI';

const GET_DESIGNATION_LIST = gql`
query getDesignations($limit: Int, $offset: Int, $filter: FilterInput) {
  getDesignations(limit: $limit, offset: $offset, filter: $filter) {
    rows {
      id
      title
      active
    }
    count
  }
}
`;

const CREATE_DESIGNATION = graphql(
  gql`
    mutation CreateDesignation($input: DesignationInput!) {
      createDesignation(input: $input) {
        id
        title
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      create: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_DESINATION = graphql(
  gql`
    mutation UpdateDesignation($id: Int!, $input: DesignationInput!) {
      updateDesignation(id: $id, input: $input) {
        id
        title
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      update: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getDesignationList: GET_DESIGNATION_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  create: CREATE_DESIGNATION,
  update: UPDATE_DESINATION,
};

export {
  query, mutation, CREATE_DESIGNATION, UPDATE_DESINATION, GET_DESIGNATION_LIST
};
