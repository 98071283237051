import React,{Component} from 'react';
import PropTypes from 'prop-types';
import withAlert from '../../../../utils/composition/withAlert';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../data/services';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import CreateDialog from './components/CreateDialog';
import Dialog from './components/Dialog';
import { getPermissionForSKUGroup } from '../../../base/permission';
import { JWT } from '../../../../environment';
import View from './View';
import { SKU_GROUP } from '../../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../../v4/constants/successMessage';

const propTypes = {
  getBasicDetail: PropTypes.func.isRequired,
  getSkuDetails: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  insertSKUGroupDetails: PropTypes.func.isRequired,
  getSKUFilteredByFamilyId: PropTypes.func.isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class Details extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const buSettings = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.BU_SETTINGS));
    const routeSegmentation = buSettings?.routeSegmentation;

    this.state = {
      menu: {
        channelList: [],
        categoryList: [],
        lineList: [],
      },
      channelId: 0,
      categoryList: [],
      categoryId: 0,
      brandList: [],
      brandId: 0,
      skuList: [],
      skuGroupList: [],
      skuId: 0,
      masterId: parseInt(props.match.params.masterId, 10),
      detailId: parseInt(props.match.params.id, 10),
      showBrands: false,
      skus: [],
      dialog: {
        type: '',
        element: '',
      },
      basicDetail: {},
      checkedSkuFamilies: [],
      sbdDetails: {
        Channel: [],
        Category: [],
        Brand: [],
        SkuGroup: [],
      },
      totalSkuFamilies: [],
      sbdSKUCountList:[],
      enableRouteSegmentation: routeSegmentation,
    };
    this.permission = getPermissionForSKUGroup();
  }

  componentDidMount() {
    this.loadDataForDropDown();
    this.getBrandDetails();
    this.getBasicDetails();
    this.getSKUsCount({});
    const { enableRouteSegmentation } = this.state;
    if (enableRouteSegmentation) {
      this.loadLineList();
    }
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then(response => {
      menu.channelList = response;
      this.setState({ menu });
    });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then(response => {
      menu.categoryList = response;
      this.setState({ menu });
    });
  };

  getBasicDetails = () => {
    const { getBasicDetail,  } = this.props;
    const { masterId,detailId } = this.state;

    getBasicDetail(
      {
        masterId,
        value: detailId?.toString(),
      },
      {
        handleSuccess: response => {
          const { skuGroups } = response.data;
          this.setState({
            basicDetail: skuGroups.rows[0],
          });
        },
      },
    );
  };

  loadLineList = () => {
    const { getLineDetails } = this.props;
    getLineDetails(
      { offset: 0, filter: { filters: [], queryString: '' } },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.lineList = res.data.lines.rows.filter(rowItem => rowItem.active === true) || [];
          this.setState({ menu });
        },
        handleError: err => this.handleApiFailure(err),
      },
    );
  };

  getSKUsCount = data => {
    const { getCount } = this.props;
    const {detailId} =this.state;

    getCount(
      {
        skuGroupId: parseInt(detailId, 10),
        ...data,
      },
      {
        handleSuccess: response => {
          const { getSkuGroupDetails } = response.data;
          const details = getSkuGroupDetails.rows[0];
          const groupedSkus = details ? details.SkuFamily.filter(sku => sku.skus.length > 1) : [];
          const sbdSKUCountList = details?.SkuGroup;
          this.setState(
            {
              sbdDetails: details || [],
              skuGroupList: groupedSkus,
              sbdSKUCountList,
              // skuList: skuFamilyy.filter
            },
            () => {
              if (data.brandId) {
                const skuFamilyy = details?.SkuFamily;
                this.getSkuDetails(data.brandId,skuFamilyy);
              }
            },
          );
        },
        handleError: err => {
          this.handleApiFailure(err);
        },
      },
    );
  };

  getBrandDetails = (id = 3) => {
    const { getBrandDetails, displayAlert } = this.props;
    const { basicDetail } = this.state;

    getBrandDetails(
      {
        catalogId: id,
        lineId: basicDetail.lineId,
      },
      {
        handleSuccess: response => {
          this.setState({
            brandList: response.data.catalogDetails.rows.filter(list => list.SKUs.length > 0),
          });
          displayAlert(ALERT_TYPE.SUCCESS, 'Fetched');
        },
      },
    );
  };

  onBrandSelect = id => {
    const { channelId, categoryId } = this.state;
    this.setState({
      brandId: id,
      skuList: [],
    });
    this.getSKUsCount({
      channelId,
      categoryId,
      brandId: id,
    });
  };

  getSkuDetails = (id,skuFamilyList) => {
    const { skuGroupList } = this.state;
    const { getSkuDetails } = this.props;

    getSkuDetails(
      {
        value: id,
      },
      {
        handleSuccess: response => {
          const { catalogLevelDetails } = response.data;
          const catalogSkus = catalogLevelDetails.rows[0].SkuFamilies;

          const groupedSkus = skuGroupList;
          const filterSku = catalogSkus.filter(sku => groupedSkus.find(grouped => grouped.skus.includes(sku.id)));
          const nonFilteredSku = catalogSkus.filter(sku => !groupedSkus.find(grouped => grouped.skus.includes(sku.id)));
          const skuFamilywithoutSKUS = nonFilteredSku.filter(a=>a.skuCount!==0) // filter SkuFamilies having skus
          this.setState({
            skuList: skuFamilywithoutSKUS,
            skus: [],
            checkedSkuFamilies: [],
            totalSkuFamilies: catalogSkus,
            skuGroupList: filterSku.length !== 0 ? skuGroupList : [],
          });

        },
      },
    );
  };

  onChannelSelect = id => {
    const { menu } = this.state;
    this.setState({
      categoryList: menu.categoryList.filter(d => d.Channel.id === id),
      channelId: id,
      skus: [],
      totalSkuFamilies:[],
      checkedSkuFamilies: [],
      categoryId: null,
      showBrands: false,
      skuList: [],
      brandId: null,
      sbdDetails: {
        Channel: [],
        Category: [],
        Brand: [],
        SkuGroup: [],
      },
    });
    this.getSKUsCount({
      channelId: id,
    });
  };

  onCategorySelect = categoryId => {
    const { channelId } = this.state;
    this.setState({
      showBrands: true,
      categoryId,
      brandId: null,
    });
    this.getSKUsCount({
      categoryId,
      channelId,
      brandId: null,
    });
  };

  handleSubmitSKU = (id, toggleStatus) => {
    const { insertSKUGroupDetails, displayAlert, deleteSKUGroupDetails } = this.props;
    const { detailId, channelId, categoryId, brandId, skus, sbdDetails, skuGroupList, totalSkuFamilies, masterId } = this.state;
    const submittingData = {
      masterId,
      skuGroupId:detailId,
      channelId,
      categoryId,
      brandId,
      skuFamilies: id,
    };
    if (toggleStatus) {
      skus.push(parseInt(id.toString(), 10));
      insertSKUGroupDetails(
        {
          input: submittingData,
        },
        {
          handleSuccess: () => {
            this.getSKUsCount({
              ...submittingData,
            });
            displayAlert(ALERT_TYPE.INFO, 'Update Successfully');
          },
          handleError: err => {
            this.handleApiFailure(err);
          },
        },
      );
    } else {
      const findGroup = sbdDetails.SkuFamily.filter(group => group.skus.includes(parseInt(id.toString(), 10)))[0].id;
      deleteSKUGroupDetails(
        {
          id: findGroup,
        },
        {
          handleSuccess: () => {
            this.getSKUsCount({
              ...submittingData,
            });
            this.setState({
              skuGroupList: skuGroupList.filter(d => d.id !== findGroup),
              skuList: totalSkuFamilies,
            });
            displayAlert(ALERT_TYPE.INFO, 'Disabled Successfully');
          },
        },
      );
    }
  };

  handleIconClick = action => {
    const { basicDetail } = this.state;
    this.setState({
      dialog: {
        type: action,
        element: basicDetail,
      },
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: {},
      },
    });
  };

  handleDialogSubmit = (type,res) => {
    const { history, displayAlert } = this.props;
    const {masterId} =this.state;
    if (type === EVENT_OPERATION.REPLICATE || type === EVENT_OPERATION.DELETE) {
      history.push(`/${SKU_GROUP}/${masterId}`);
    }
    displayAlert(ALERT_TYPE.SUCCESS, `${res?.title} ${MESSAGE_EVENT_OPERATION[type]}`);
    this.getBasicDetails();
    this.handleDialogClose();
  };

  handleApiFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  setCheckedSkuFamilies = skuFamily => {
    const { checkedSkuFamilies } = this.state;
    if (!checkedSkuFamilies.includes(skuFamily)) {
      this.setState({
        checkedSkuFamilies: [...checkedSkuFamilies, skuFamily],
      });
    } else {
      this.setState({
        checkedSkuFamilies: checkedSkuFamilies.filter(d => d !== skuFamily),
      });
    }
  };

  onCreateGroupClick = () => {
    this.setState({
      dialog: {
        type: EVENT_OPERATION.CREATE_GROUP,
      },
    });
  };

  handleGroupCreate = () => {
    const { insertSKUGroupDetails, displayAlert } = this.props;
      
      const { masterId, detailId, channelId, categoryId, brandId, checkedSkuFamilies, skuGroupList, } = this.state;

    const createGroup = {
      masterId,
      skuGroupId:detailId,
      channelId,
      categoryId,
      brandId,
      skuFamilies: checkedSkuFamilies,
    };

    insertSKUGroupDetails(
      {
        input: createGroup,
      },
      {
        handleSuccess: response => {
          const { insertSKUGroupDetails: details } = response.data;
          this.setState(
            {
              checkedSkuFamilies: [],
              skuGroupList: [
                ...skuGroupList,
                {
                  id: details.id,
                  count: details.sku_family.length,
                  skus: details.sku_family,
                },
              ],
            },
            () => {
              this.getSKUsCount({
                channelId,
                categoryId,
                brandId,
              });
            },
          );
          displayAlert(ALERT_TYPE.INFO, 'Created Successfully');
        },
        handleError: err => {
          this.handleApiFailure(err);
        },
      },
    );
  };

  render() {
    const { dialog } = this.state;
    const { serverResponseWaiting, getSKUFilteredByFamilyId } = this.props;
    return (
      <>
        <View
          {...this.state}
          onIconClick={this.handleIconClick}
          onChannelSelect={this.onChannelSelect}
          loading={serverResponseWaiting}
          onCategorySelect={this.onCategorySelect}
          getSkuDetails={this.onBrandSelect}
          handleSubmit={this.handleSubmitSKU}
          setCheckedSkuFamilies={this.setCheckedSkuFamilies}
          onCreateGroup={this.onCreateGroupClick}
          getSKUFilteredByFamilyId={getSKUFilteredByFamilyId}
          permission={this.permission}
        />
        {dialog.type && (
          <div className="sbd-modal">
            <Dialog
              type={dialog.type}
              element={dialog.element}
              onSubmit={this.handleDialogSubmit}
              onClose={this.handleDialogClose}
              onApiFailure={this.handleApiFailure}
            />
          </div>
        )}
        {dialog.type === EVENT_OPERATION.CREATE_GROUP && (
          <div className="sbd-modal">
            <CreateDialog
              type={dialog.type}
              element={dialog.element}
              onSubmit={this.handleGroupCreate}
              onClose={this.handleDialogClose}
              onApiFailure={this.handleApiFailure}
            />
          </div>
        )}
      </>
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
