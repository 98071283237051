import { FORM_CONFIG } from "../../../../../data/enums/config";
import EVENT_OPERATION from "../../../../../data/enums/EventOperation";

export const initialDialog = {
    element: {
        active: null,
        id: null,
        name: ''
    },
    type: ''
}

export const defaultStatusFilterData = [
    {
        column: 'active',
        value: ["true"],
    },
];

export const formConfig = {
    [FORM_CONFIG.MAPPER]: (element) => ({
        id: element.id || '',
        active: element.active || null
    }),
    [EVENT_OPERATION.ACTIVATE]: {
        [FORM_CONFIG.TITLE]: 'Activate User',
        validationRequired: false,
    },
    [EVENT_OPERATION.SUSPEND]: {
        [FORM_CONFIG.MAPPER]: (element) => ({
            id: element.id || '',
            active: element.active || null
        }),
        [FORM_CONFIG.TITLE]: 'Suspend User',
        validationRequired: false,
    },
}