/* global google */
import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import Modal from '../../components/Modal/Modal';
import SingleRouteStyled from './SingleRouteStyled';
import backButton from '../../assets/back-button.svg';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import DropDown from '../../components/DropDown/DropDown';
import BulkTransfer from './components/BulkTransfer';
import setMarkerImage from '../../services/setMarkerImage';
import { Icon, Button, CheckBox } from '../../../../../v4/components';

class SingleRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableEdit: false,
      newOrderList: props.newOrderList,
      routeLines: props.routeLines,
      routesList: props.routesList,
      show: false,
      transferToRoute: '',
      transferOutletId: '',
      originalOutletOrder: [],
      editSuccess: false,
      routeDeleteSuccess: false,
      currentOutlets: [],
      currentOpenTransfer: {},
      checkedList: [],
    };
  }

  componentDidMount() {
    this.state.newOrderList.forEach(marker => {
      marker.oldLabel =
        marker.distance === 'First' || marker.distance === 'Last' ? marker.label.text : Number(marker.label);
    });
  }

  polylineFromMarker = () => {
    const { routeLines, newOrderList } = this.state;
    const { clickedRouteId } = this.props;

    // draws polyline according to the markers' order
    routeLines[clickedRouteId].setMap(null);

    // gets current polygon path
    const path = routeLines[clickedRouteId].getPath();

    // sets the path(of routeLines) array to empty for new ordered marker path add
    const tempRouteLines = routeLines.slice();
    tempRouteLines[clickedRouteId].getPath().clear();

    this.setState({ routeLines: tempRouteLines });
    newOrderList.forEach(marker => {
      path.push(new google.maps.LatLng(marker.position.lat(), marker.position.lng()));
    });
    routeLines[clickedRouteId].setMap(window.globalMap);
  };

  sortRoutesList = (array, order, key) => {
    array.sort((a, b) => {
      const A = a[key];
      const B = b[key];
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      }
      return -1;
    });
    return array;
  };

  onDragStart = (e, id) => {
    this.draggedItem = this.state.newOrderList.find(outlet => outlet.id === id);
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onDragOver = (id) => {
    const draggedOverItem = this.state.newOrderList.find(outlet => outlet.id == id);
    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    const items = this.state.newOrderList.filter(item => item !== this.draggedItem);

    // add the dragged item after the dragged over item
    items.splice(this.state.newOrderList.indexOf(outlet => outlet.id == id), 0, this.draggedItem);
    this.setState({ newOrderList: items }, () => {
      // set appropriate 'sequence' labels for each markers
      this.state.newOrderList.forEach((marker, i) => {
        if (!(marker.distance === 'First') && !(marker.distance === 'Last')) {
          // marker.set('label', i + 1);
          marker.setMap(window.globalMap);
        }
      });
      this.polylineFromMarker();
    });
  };

  onDragEnd = e => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'none';
    this.draggedIdx = null;
  };

  transferToRoute = e => {
    this.setState({ transferToRoute: e.dse });
  };

  showModal = outletId => {
    this.setState({ show: true, transferOutletId: outletId });
  };

  hideModal = () => {
    this.setState({ show: false, currentOpenTransfer: {} });
  };

  handleRouteTransfer = () => {
    if (this.state.transferToRoute === this.props.clickedRouteId) {
      this.props.displayAlert(ALERT_TYPE.INFO, 'This is the same route!');
    } else {
      this.setState({ show: false });

      // remove outlet with transferOutletId from current "state.newOrderList"
      const currentRoute = this.state.newOrderList;
      const outletToRemoveInst = currentRoute.find(outlet => outlet.id === this.state.transferOutletId);
      const outletToRemoveIndex = currentRoute.indexOf(outletToRemoveInst);
      currentRoute.splice(outletToRemoveIndex, 1);

      // remove the outlet from state.routesList[this.props.clickedRouteId].travel
      const removedOutletToTransfer = this.state.routesList.find(route => route.dse === this.props.clickedRouteId).travel.filter(
        outlet => outlet.id === this.state.transferOutletId,
      );
      this.state.routesList.find(route => route.dse === this.props.clickedRouteId).travel.forEach((outlet, i) => {
        if (outlet.id === removedOutletToTransfer[0].id) {
          this.state.routesList.find(route => route.dse === this.props.clickedRouteId).travel.splice(i, 1);
        }
      });

      outletToRemoveInst.setVisible(false);
      this.setState({ newOrderList: currentRoute }, () => {
        // set appropriate 'sequence' labels for each markers
        this.state.newOrderList.forEach((marker, i) => {
          if (!(marker.distance === 'First') && !(marker.distance === 'Last')) {
            // marker.set('label', null);
            // marker.set('label', i + 1);
            marker.setMap(window.globalMap);
          }
        });

        // draws polyline according to the markers' order
        this.state.routeLines[this.props.clickedRouteId].setMap(null);

        // gets current polygon path
        const path = this.state.routeLines[this.props.clickedRouteId].getPath();

        const tempRouteLines = this.state.routeLines.slice();
        const tempRoutesList = this.state.routesList.slice();
        const removedOutletToTransferInst = tempRouteLines[this.props.clickedRouteId].getPath().getAt(
          outletToRemoveIndex
        );
        tempRouteLines[this.props.clickedRouteId].getPath().clear();

        this.state.newOrderList.forEach(marker => {
          path.push(new google.maps.LatLng(marker.position.lat(), marker.position.lng()));
        });
        this.state.routeLines[this.props.clickedRouteId].setMap(window.globalMap);

        // transfer the outlet to state.routesList[this.state.transferToRoute]'s at last index
        // and transfer to polyline instance as well
        const outletIndexToRemove = _.findIndex(tempRoutesList, { dse: this.state.transferToRoute });
        tempRoutesList[outletIndexToRemove].travel.splice(
          tempRoutesList[outletIndexToRemove].travel.length,
          0,
          removedOutletToTransfer[0],
        );
        tempRouteLines[outletIndexToRemove].getPath().push(
          removedOutletToTransferInst
        );

        // change old properties- icon color, label and "order" according to new route:
        tempRoutesList.forEach(route => {
          route.travel.forEach((marker, i) => {
            marker.order = i + 1;
          });
        });
        // outletToRemoveInst.label = tempRoutesList[outletIndexToRemove].travel.length;
        // marker from the same index as polyline index
        outletToRemoveInst.icon = this.state.routesList.find(route => route.dse === this.state.transferToRoute).travel[0].iconUrl;
        outletToRemoveInst.details.order = this.state.routesList.find(route => route.dse === this.state.transferToRoute).travel.length;
        outletToRemoveInst.details.dse = this.state.transferToRoute;
        this.setState({ routeLines: tempRouteLines, routesList: tempRoutesList });
      });
    }
  };

  handleMultipleOutletTransfer = () => {
    const { transferToRoute, routesList, routeLines, newOrderList, transferOutletId } = this.state;
    const { displayAlert, clickedRouteId, markerColor } = this.props;
    if (transferToRoute === clickedRouteId) {
      displayAlert(ALERT_TYPE.INFO, 'This is the same route!');
    } else {
      this.setState({ show: false, checkedList: [] });

      // remove the outlets from current route and update the route
      const removedMarkers = _.remove(newOrderList, outlet => transferOutletId.includes(outlet.id));
      removedMarkers.forEach(outlet => outlet.setVisible(false));

      const outletsToRemoveIndices = [];
      routesList[clickedRouteId].travel.forEach((outlet, i) => {
        if (transferOutletId.includes(outlet.id)) {
          outletsToRemoveIndices.push(i);
        }
      });

      // remove the outlets from routesList
      const removedOutlets = _.remove(routesList[clickedRouteId].travel, outlet =>
        transferOutletId.includes(outlet.id),
      );

      // update marker sequence of current route
      for (let i = 0; i < newOrderList.length; i++) {
        const marker = newOrderList[i];
        // marker.set('label', null);
        // marker.set('label', i + 1);
        marker.setMap(window.globalMap);
      }

      // update polyline of currentroute
      routeLines[clickedRouteId].setMap(null);
      const path = routeLines[clickedRouteId].getPath();

      routeLines[clickedRouteId].getPath().clear();

      newOrderList.forEach(marker => {
        path.push(new google.maps.LatLng(marker.position.lat(), marker.position.lng()));
      });

      if (routesList.find(route => route.dse === clickedRouteId).travel.length > 0) {
        routeLines[clickedRouteId].setMap(window.globalMap);
      }

      // transfer the outlets to target routesList at last index
      const transferRouteIndex = _.findIndex(routesList, { dse: transferToRoute });
      routesList[transferRouteIndex].travel.splice(routesList[transferRouteIndex].travel.length, 0, ...removedOutlets);

      // and transfer to polyline instance as well
      const transferToPath = routeLines[transferRouteIndex].getPath();
      removedOutlets.forEach(outlet => {
        transferToPath.insertAt(transferToPath.length, new google.maps.LatLng(outlet.lat, outlet.lng));
      });

      // change transfered markers' properties according to new route:
      for (let i = 0; i < routesList[transferRouteIndex].travel.length; i++) {
        routesList[transferRouteIndex].travel[i].order = i + 1;
      }

      // const transferLabel = (routesList[transferRouteIndex].travel.length) - (removedMarkers.length); // for 1st marker among transferred markers
      removedMarkers.forEach((marker, i) => {
        // marker.set('label', i); 
        marker.details.order = routesList[transferRouteIndex].travel.length - 1 + i;
        marker.details.dse = transferToRoute;
        marker.set('icon', setMarkerImage(markerColor[transferToRoute], null, null, 21, 33.6));
      });

      this.setState({ routeLines, routesList });
    }
  };

  editOutlets() {
    this.setState({ enableEdit: !this.state.enableEdit, checkedList: [] });
  }

  cancelEdit = () => {
    this.setState({ enableEdit: false, checkedList: [], transferOutletId: '' });

    this.state.newOrderList.forEach(marker => {
      marker.setMap(null);
    });

    const toSort = this.state.newOrderList;
    const sorted = _.sortBy(toSort, marker => marker.oldLabel);
    this.setState({ newOrderList: sorted }, () => {
      for (let i = 1; i < this.state.newOrderList.length - 1; i++) {
        // this.state.newOrderList[i].set('label', this.state.newOrderList[i].oldLabel);
        this.state.newOrderList[i].setMap(window.globalMap);
      }
      this.state.newOrderList[0].setMap(window.globalMap);
      this.state.newOrderList[this.state.newOrderList.length - 1].setMap(window.globalMap);
      this.polylineFromMarker();
    });
  };

  saveOrderEdit = () => {
    this.props.displayAlert(ALERT_TYPE.SUCCESS, `Route ${this.props.clickedRouteId + 1} edited successfully.`);
    this.setState({
      editSuccess: true,
      enableEdit: false,
      checkedList: [],
      transferOutletId: '',
    });

    // find the index order of newOrderList and according to that
    // reorder routesList[this.props.clickedRouteId]
    const idsOrder = [];
    this.state.newOrderList.forEach(outlet => {
      idsOrder.push(outlet.id);
    });

    const sortedOutletsList = this.sortRoutesList(this.state.routesList.find(route => route.dse === this.props.clickedRouteId).travel, idsOrder, 'id');
    if (sortedOutletsList.length > 1) {
      if (sortedOutletsList[0].id === sortedOutletsList[1].id) {
        sortedOutletsList.push(sortedOutletsList[0]);
        sortedOutletsList.splice(1, 1);
      }
    }

    const orderedRoutesList = this.state.routesList.slice();
    orderedRoutesList[this.props.clickedRouteId].travel = sortedOutletsList;
    this.setState({ routesList: orderedRoutesList });
  };

  showDropDown = i => {
    if (Object.keys(this.state.currentOpenTransfer).length > 0) {
      this.state.currentOpenTransfer.classList.toggle('show');
      this.setState({ currentOpenTransfer: {} });
    }
    document.getElementById(`myDropdown${i}`).classList.toggle('show');
    this.setState({ currentOpenTransfer: document.getElementById(`myDropdown${i}`) });
  };

  render() {
    const { enableEdit, newOrderList, show, transferOutletId, routesList, checkedList } = this.state;

    const { clickedRouteId, showDeleteFromInside, goBack, showAll, handleShowAllClick } = this.props;
    return (
      <SingleRouteStyled>
        <div className="routesDisplay">
          <div className="header-route">
            <div className="left-sec">
              {!enableEdit ? (
                <>
                  <button className="back borderless" onClick={() => goBack()}>
                    <img src={backButton} alt="back-btn" />
                  </button>
                  <span className="title">
                    {this.state.routesList.find(route => route.dse === clickedRouteId).name}
                  </span>
                </>
              ) : (
                <span className="title">
                  Edit {this.state.routesList.find(route => route.dse === clickedRouteId).name}
                </span>
              )}
            </div>
            {!enableEdit ? (
              <div className="right-sec">
                <CheckBox labelText="Draw all routes" checked={showAll} onCheckBoxClick={handleShowAllClick} />
                <button className="trash-btn borderless" onClick={() => showDeleteFromInside(clickedRouteId)}>
                  <Icon iconName="trash " />
                </button>
                <button className="edit-btn borderless" onClick={() => this.editOutlets()}>
                  <Icon iconName="pencil" />
                </button>
              </div>
            ) : (
              <div className="right-sec">
                <Button
                  secondary
                  iconBtnSmall
                  className="times-btn bordered"
                  iconName="times"
                  onClick={() => this.cancelEdit()}
                />
                <Button
                  primary
                  iconBtnSmall
                  iconName="check"
                  className="check-btn bordered"
                  onClick={() => this.saveOrderEdit()}
                />
              </div>
            )}
          </div>
          <>
            <BulkTransfer
              checkedList={checkedList}
              onTransferClick={this.showModal}
              render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (<div className="outlets-aside" style={{ alignItems: 'flex-end' }}>
                <ul className="draggableUl">
                  {newOrderList
                    .map((outlet, index) => (
                      <li
                        key={outlet.id}
                        onDragOver={() => this.onDragOver(outlet.id)}
                        className="draggableLi"
                      >
                        <div className="left">
                          <div
                            className="drag"
                            draggable={enableEdit === true}
                            id={outlet.id}
                            key={outlet.id}
                            onDragStart={e => this.onDragStart(e, outlet.id)}
                            onDragEnd={e => this.onDragEnd(e)}
                          >
                            <Icon className="dragIcon" iconName="drag" />
                          </div>
                          <div className="content">
                            <div className="wrap-lft">
                              {enableEdit && (
                                <CheckBox
                                  checked={state.checkedList.includes(outlet.id)}
                                  onCheckBoxClick={() => secondaryCheckboxHandler(outlet.id)}
                                />
                              )}
                              <span className="drag-number">{index + 1}</span>
                              <span className="outlet-name">{outlet.name}</span>
                            </div>
                            <DropDown showModal={this.showModal} outletName={outlet.name} outletId={outlet.id} />
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
              )}
            />
          </>
        </div>

        <Modal
          show={show}
          handleClose={this.hideModal}
          handler={typeof transferOutletId === 'object' ? this.handleMultipleOutletTransfer : this.handleRouteTransfer}
        >
          <div className="m-header">
            <h2>Transfer Outlet</h2>
          </div>
          <div className="m-body">
            <p>Please select route to transfer selected outlet(s).</p>
            <div className="form-input">
              <label>Route</label>
              <Select
                name="routes"
                options={routesList.filter(item => item.dse !== clickedRouteId)}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={e => this.transferToRoute(e)}
              />
            </div>
          </div>
        </Modal>
      </SingleRouteStyled>
    );
  }
}

export default withAlert()(SingleRoute);
