import { compose } from 'recompose';
import Received from './Received';
import {
  GET_PROMOTION_VALIDATION,CONFIRMED_RECEIVED_ORDER,mutation
} from '../API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedReceived = compose(
  CONFIRMED_RECEIVED_ORDER,
  withRequestTracker(
    {
      query: { getPromotionValidation: GET_PROMOTION_VALIDATION },
      mutation,
    }),
)(Received);

export default composedReceived;
