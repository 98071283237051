/* global google */
import React, { Component } from 'react';
import {
  redMarkerColor,
  greenMarkerColor,
  googleMapPoint1,
  googleMapPoint2,
  markerIconPath,
  fillOpacity,
  strokeWeight,
} from './config';
import marker from '../../../configuration/arc/services/marker';
import AttendanceDialogStyled from './AttendanceDialogStyled';
import { createScriptLoadMap } from '../../../configuration/arc/services/googleMapScript';

class AttendanceMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    createScriptLoadMap().then(() => {
      this.renderMarkers();
    });
  }

  renderMarkers = () => {
    const { data } = this.props;
    const checkIn = data.checkinLocation;
    const checkOut = data.checkoutLocation;
    checkIn &&
      marker.generateMarker(
        { lat: checkIn.latitude, lng: checkIn.longitude },
        null,
        null,
        this.getIconAttributes(greenMarkerColor),
      );
    checkOut &&
      marker.generateMarker(
        { lat: checkOut.latitude, lng: checkOut.longitude },
        null,
        null,
        this.getIconAttributes(redMarkerColor),
      );
  };

  getIconAttributes = iconColor => ({
    path: markerIconPath,
    fillColor: iconColor,
    fillOpacity,
    strokeWeight,
    anchor: new google.maps.Point(googleMapPoint1, googleMapPoint2),
  });

  render() {
    return (
      <AttendanceDialogStyled className="map_section">
        <div className="map-main">
          <div id="map" />
        </div>
        <ul className="custom_legend">
          <li className="check_in">
            <span /> <p>Check In</p>
          </li>
          <li className="check_out">
            <span /> <p>Check Out</p>
          </li>
        </ul>
      </AttendanceDialogStyled>
    );
  }
}

export default AttendanceMap;
