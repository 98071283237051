import { compose } from 'recompose';
import ICOGroupDetail from './ICOGroupDetail';
import {
  query, mutation, TOGGLE_SKU_IN_SUBD_ASSORTMENT
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';


const composedICOGroupDetail = compose(
  TOGGLE_SKU_IN_SUBD_ASSORTMENT,
  withRequestTracker({
    query,
    mutation,
  }),
)(ICOGroupDetail);

export default composedICOGroupDetail;
