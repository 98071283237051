import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckBox, menuAction, tableHeaderWithCheckBox
} from '../../../v4/components';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  levelIndication: 'levelIndication',
  parentTitle: 'parent.title',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.levelIndication,
    title: 'Level Indication',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.parentTitle,
    title: 'Parents Level',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`levels-${data.id}-check`} onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.title] = <td key={`levels-${data.id}-title`}>{data.title}</td>;
  table[labelMappings.levelIndication] = <td key={`levels-${data.id}-levelIndication`}>{data.levelIndication}</td>;
  table[labelMappings.parentTitle] = (
    <td key={`levels-${data.id}-parentTitle`}>{data.parent === null ? '-' : data.parent.title} </td>
  );
  table[labelMappings.actionHolders] = (
    <td key={`levels-${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {(permission.update||permission.delete)&&menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);
  return <tr key={data.id}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
