import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserStatus from './UserStatus';
import { USER_LABEL, USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import SectionWithTitle from '../components/SectionWithTitle';
import UserStatusStyled from './UserStatusStyle';
import { userSpecificTitle } from '../../common/HelperFunctions';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  dimensions: PropTypes.instanceOf(Object),
  linkStatus:PropTypes.bool,
  withoutUserLabel:PropTypes.bool,
};

const defaultProps = {
  dimensions: {},
  linkStatus:false,
  withoutUserLabel:false,
};

class UserStatusWrapper extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { data, dimensions,linkStatus,withoutUserLabel} = this.props;
    return (
      <div className='row user-status' >
        {Object.keys(data || {}).map((user, key, arr) => (
          <div className='col-lg-4' key={`${key}-key`}>
            <UserStatusStyled listLength={arr.length || 0}>
              <Link to={user==='tasUsers'?`/tas/user-detail`:linkStatus|| `/reports/${USER_LABEL[user]}`||linkStatus}>
                <SectionWithTitle key={user} title={userSpecificTitle(user)}>
                  <UserStatus
                    id={USER_LABEL[user]}
                    dimensions={{ ...dimensions, width: 100 }}
                    data={withoutUserLabel ? data[user]:data[USER_LABEL[user]]}
                    label={user}
                    withoutUserLabel={withoutUserLabel}
                  />
                </SectionWithTitle>
              </Link>
            </UserStatusStyled>
          </div>
        ))}
      </div>
    );
  }
}

UserStatusWrapper.propTypes = propTypes;

UserStatusWrapper.defaultProps = defaultProps;

export default UserStatusWrapper;
