import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../components';
import { Form } from '../../../../v4/components';
import withLoading from '../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    title: '',
  },
  refsObj: {
    designation: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
};

const DesignationForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay } = props;

  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Designation'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Designation'
      />
    </Form>
  );
};

DesignationForm.propTypes = propTypes;

DesignationForm.defaultProps = defaultProps;

export default DesignationForm;
