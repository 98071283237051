import styled from 'styled-components';

const CoverageMapStyled = styled.div`
  width: 100%;
  #map .gmnoprint > .gm-style-cc span,
  #map .gmnoprint.gm-style-cc,
  #map div.gm-style > button[title='Toggle fullscreen view'] {
    display: none;
  }
  #map .gmnoprint {
    left: 10px !important;
    bottom: 85px !important;
  }
  #map .gmnoprint > div {
    background-color: unset !important;
    box-shadow: unset !important;
    border-radius: unset !important;
    width: unset !important;
    height: unset !important;
    cursor: unset !important;
  }
  #map .gmnoprint button[title='Zoom out'],
  #map .gmnoprint button[title='Zoom in'] {
    height: 32px !important;
    width: 32px !important;
    line-height: 32px !important;
    background-color: #ffffff !important;
    text-align: center;
    margin-bottom: 12px !important;
    img {
      height: 16px !important;
      width: 16px !important;
    }
  }
  .dseList {
    top: unset !important;
    bottom: 48px;
    right: 30px !important;
    max-height: 445px;
    overflow: auto;
  }
  .full-screen {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .opacity {
    opacity: 0.6;
  }

  #map {
    height: 238px;
    width: 100%;
    box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
    border-radius: 5px;
    z-index: 1;
    position: relative;
  }

  .leaflet-control {
    z-index: 700;
  }

  .leaflet-bar {
    z-index: 9999;
  }

  .map-wrap {
    .sub-heading {
      font-size: 28px;
      margin-bottom: 10px;
      font-weight: 400;
    }

    .profile {
      display: inline-block;
      margin-right: 10px;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background: #cccccc;

      img {
        height: 32px;
        width: 32px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    h2 {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      @media only screen and (min-width: 1500px) {
        font-size: 22px;
      }
    }

    .item-list {
      background: #fff;
      border: 1px solid rgba(46, 91, 255, 0.08);
      border-radius: 5px 0 0 5px;
      margin-bottom: 4px;
      padding: 8px 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .legend {
    display: flex;
    flex-direction: column;
    left: 83px !important;
    bottom: 25px !important;
    p {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 12px;
      line-height: 20px;
      color: #505d6f;
      font-weight: 400;
      margin: 0;
      img {
        margin-right: 8px;
        height: 10px;
        width: 10px;
      }
    }
  }
`;

export default CoverageMapStyled;
