import gql from "graphql-tag";
import { graphql } from "react-apollo";

const GET_USER_LIST = gql`
query ManageUserStatus($filter: FilterInput, $offset: Int, $limit: Int) {
  manageUserStatus(filter: $filter, offset: $offset, limit: $limit) {
    count
    rows {
      active
      active_days
      email
      last_active_date
      account_activated_date
      name
      id
    }
  }
}
`
const UPDATE_USERS = graphql(
  gql`
   mutation Mutation($input: UserStatusInput) {
  updateUserStatus(input: $input) {
    active
    id
  }
}
    `,
  {
    props: ({ mutate }: any) => ({
      updateUserStatus: (variables: any) =>
        mutate({
          variables,
        }),
    }),
  }
);

const DOWNLOAD_USER_REPORT = graphql(
  gql`
mutation DownloadReport($input: fileDownloadInput!) {
  downloadReport(input: $input) {
    file {
      path
      name
    }
  }
}
`,
  {
    props: ({ mutate }) => ({
      downloadReport: (variables: any) =>
        mutate({
          variables,
        }),
    }),
  }
);

const query = {
  getUserList: GET_USER_LIST
}

const mutation = {
  updateUserStatus: UPDATE_USERS,
  downloadReport: DOWNLOAD_USER_REPORT
}

export {
  GET_USER_LIST,
  UPDATE_USERS,
  DOWNLOAD_USER_REPORT
}

export { query, mutation }