import React from 'react';
import { EVENT_OPERATION } from '../../data/enums/EventOperation';

const confirmationGenerator = (type, number) => {
  const starter = 'Are you sure you want to ';
  switch (type) {
  case EVENT_OPERATION.UPDATE:
    return (
      <span>
        {starter}
        {number ? `update ${number} records` : 'update this record'}?
      </span>
    );
  case EVENT_OPERATION.APPROVE:
    return (
      <span>
        {starter}
        {number ? `approve ${number} requests` : 'approve this request'}?
      </span>
    );
  case EVENT_OPERATION.REJECTED:
    return (
      <span>
        {starter}
        {number ? `reject ${number} requests` : 'reject this request'}?
      </span>
    );
  case EVENT_OPERATION.DELETE:
    return <span>{`${starter} delete this record?`}</span>;
  case EVENT_OPERATION.CANCEL:
    return <span>{`${starter} cancel this record?`}</span>;
  case EVENT_OPERATION.REMOVE:
    return (
      <span>
        {`${starter} ${EVENT_OPERATION.REMOVE.toLowerCase()} this record?`}
      </span>
    );
  case EVENT_OPERATION.BULK_DELETE:
    return <span>{`${starter} delete ${number || ' all these'} record(s)?`}</span>;
  case EVENT_OPERATION.CREATE_GROUP:
    return <span>{`${starter} create a SKU group?`}</span>;
  case EVENT_OPERATION.SAVE:
    return <span>{`${starter} save this report?`}</span>;
  case EVENT_OPERATION.DISCARDED:
    return (
      <span>
        {starter}
        <span>{`remove ${number} record(s)?`}</span>
      </span>
    );
  default:
    return (
      <span>
        {starter}
          change the status&nbsp;
        {type.toLowerCase() !== 'update_status' && (
          <>
              to&nbsp;
            <b>{type.replace('_', ' ').toLowerCase()}</b>
              &nbsp;
          </>
        )}
          ?
      </span>
    );
  }
};

export default confirmationGenerator;
