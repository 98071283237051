import classNames from 'classnames';
import { Icon } from '../index';
import AlertStyled from './AlertStyled';
interface AlertProps {
  children?: any;
  title?: string;
  type: string;
  className?: string;
  primary?: boolean;
  small?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  active?: boolean;
  iconName?: string;
  onChange: () => void;
}

const Alert = ({
  children,
  title,
  type,
  className,
  primary,
  secondary,
  small,
  active,
  disabled,
  iconName,
  onChange,
  ...otherProps
}: AlertProps) => {
  const classes = classNames(
    {
      primary,
      secondary,
      small,
      active,
      disabled,
      iconName,
    },
    className,
  );
  return (
    <>
      <AlertStyled type={type} {...otherProps}>
        <div className={`alert-container ${classes}`}>
          <div className={`alert alert-${type || 'info'}`}>
            <Icon iconName={iconName} />
            <strong className="alert-lead">{title}: </strong>
            <span className="alert-message">{children}</span>
            <span tabIndex={0} role="button" className="alert-dismiss" onKeyDown={() => { }}>
              <Icon iconName="times" iconWidth="12px" iconHeight="12px" onClick={() => onChange()} />
            </span>
          </div>
        </div>
      </AlertStyled>
    </>
  );
};

Alert.defaultProps = {
  children: undefined,
  className: '',
  primary: false,
  secondary: false,
  small: false,
  active: false,
  disabled: false,
  type: false,
};

export default Alert;
