import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../v4/components';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { ImageColumn } from '../../../utils/tableUtils';

const labelMappings = {
  grnId: 'grnId',
  grnDate: 'grnDate',
  grnNumber: 'grnNumber',
  invoicedDate: 'invoicedDate',
  invoicedTime: 'invoicedTime',
  receiptNumber: 'receiptNumber',
  skuId: 'skuId',
  skuName: 'skuName',
  dlp: 'dlp',
  approvedDate: 'approvedDate',
  brandName: 'brandName',
  grossAmount: 'grossAmount',
  promotionDiscount: 'promotionDiscount',
  billDiscount: 'billDiscount',
  tradeDiscount: 'tradeDiscount',
  quantity: 'quantity',
  damages: 'damages',
  shortages: 'shortages',
  remarks: 'remarks',
  batchId: 'batchId',
  batchNumber: 'batchNumber',
  erpCode: 'erpCode',
  businessPartnerId: 'businessPartnerId',
  damageImage: 'damageImage',
  remarksImage: 'remarksImage',
  distributorId: 'distributorId',
  distributorName: 'distributorName',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.invoicedDate,
    title: 'Vendor Invoiced Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.invoicedTime,
    title: 'Vendor Invoiced Time',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.vendorInvoiceNumber,
    title: 'Vendor Invoice Number',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.receiptNumber,
    title: 'Shipment Receipt Number',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.remarksImage,
    title: 'Shipment Receipt Image',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.grnId,
    title: 'GRN ID',
    show: false,
  },
  {
    id: 7,
    label: labelMappings.grnDate,
    title: 'GRN Date',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.grnNumber,
    title: 'GRN Number',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.distributorName,
    title: 'Distributor',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.skuName,
    title: 'SKU Name',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.erpCode,
    title: 'ERP Code',
    show: true,
  },
  {
    id: 12,
    label: labelMappings.brandName,
    title: 'Brand',
    show: true,
  },
  {
    id: 13,
    label: labelMappings.batchNumber,
    title: 'Batch Number',
    show: true,
  },
  {
    id: 14,
    label: labelMappings.dlp,
    title: 'DLP',
    show: true,
  },
  {
    id: 15,
    label: labelMappings.quantity,
    title: 'Quantity',
    show: true,
  },
  {
    id: 16,
    label: labelMappings.damages,
    title: 'Damages',
    show: true,
  },
  {
    id: 17,
    label: labelMappings.shortages,
    title: 'Shortages',
    show: true,
  },
  {
    id: 18,
    label: labelMappings.grossAmount,
    title: 'Gross Amount',
    show: true,
  },
  {
    id: 19,
    label: labelMappings.promotionDiscount,
    title: 'Promotion Discount',
    show: true,
  },
  {
    id: 20,
    label: labelMappings.billDiscount,
    title: 'Bill Discount',
    show: true,
  },
  {
    id: 21,
    label: labelMappings.tradeDiscount,
    title: 'Trade Discount',
    show: true,
  },
  {
    id: 22,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: true,
  },
  {
    id: 23,
    label: labelMappings.approvedDate,
    title: 'Approved Date',
    show: true,
  },
  {
    id: 24,
    label: labelMappings.damageImage,
    title: 'Damage Image',
    show: true,
  },
  {
    id: 25,
    label: labelMappings.skuId,
    title: 'SKU Id',
    show: false,
  },
  {
    id: 26,
    label: labelMappings.batchId,
    title: 'Batch Id',
    show: false,
  },
  {
    id: 27,
    label: labelMappings.businessPartnerId,
    title: 'Business Partner Id',
    show: false,
  },
  {
    id: 28,
    label: labelMappings.distributorId,
    title: 'Distributor Id',
    show: false,
  },
];

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data, handleSliderOpen) => {
  const table = {};

  table[labelMappings.grnId] = (
    <td key={`${data.id}-grnId`} className='text-right'>
      {data.grn_id}
    </td>
  );
  table[labelMappings.grnDate] = <td key={`${data.id}-grnDate`}>{data.grn_date}</td>;
  table[labelMappings.grnNumber] = <td key={`${data.id}-grnNumber`}>{data.grn_number}</td>;
  table[labelMappings.invoicedDate] = <td key={`${data.id}-invoicedDate`}>{data.invoiced_date}</td>;
  table[labelMappings.invoicedTime] = <td key={`${data.id}-invoicedTime`}>{data.invoiced_time}</td>;
  table[labelMappings.vendorInvoiceNumber] = (
    <td key={`${data.id}-vendorInvoiceNumber`}>{data.vendor_invoice_number}</td>
  );
  table[labelMappings.receiptNumber] = <td key={`${data.id}-receiptNumber`}>{data.receipt_number}</td>;
  table[labelMappings.skuId] = (
    <td key={`${data.id}-skuId`} className='text-right'>
      {data.sku_id}
    </td>
  );
  table[labelMappings.skuName] = <td key={`${data.id}-skuName`}>{data.sku}</td>;
  table[labelMappings.dlp] = (
    <td key={`${data.id}-dlp`} className='text-right'>
      {data.dlp}
    </td>
  );
  table[labelMappings.approvedDate] = <td key={`${data.id}-approvedDate`}>{data.approved_date}</td>;
  table[labelMappings.brandName] = <td key={`${data.id}-brandName`}>{data.brand}</td>;
  table[labelMappings.grossAmount] = (
    <td key={`${data.id}-grossAmount`} className='text-right'>
      {fixedFloatAndCommas(data.gross_amount)}
    </td>
  );
  table[labelMappings.promotionDiscount] = (
    <td key={`${data.id}-promotionDiscount`} className='text-right'>
      {fixedFloatAndCommas(data.promotion_discount)}
    </td>
  );
  table[labelMappings.billDiscount] = (
    <td key={`${data.id}-billDiscount`} className='text-right'>
      {fixedFloatAndCommas(data.bill_discount)}
    </td>
  );
  table[labelMappings.tradeDiscount] = (
    <td key={`${data.id}-tradeDiscount`} className='text-right' title={data.trade_discount}>
      {fixedFloatAndCommas(data.trade_discount)}
    </td>
  );
  table[labelMappings.quantity] = (
    <td key={`${data.id}-quantity`} className='text-right'>
      {data.quantity}
    </td>
  );
  table[labelMappings.damages] = (
    <td key={`${data.id}-damages`} className='text-right'>
      {data.damages}
    </td>
  );
  table[labelMappings.shortages] = (
    <td key={`${data.id}-shortages`} className='text-right'>
      {data.shortages}
    </td>
  );
  table[labelMappings.remarks] = <td key={`${data.id}-remarks`}>{data.remarks}</td>;
  table[labelMappings.batchId] = <td key={`${data.id}-batchId`}>{data.batch_id}</td>;
  table[labelMappings.batchNumber] = <td key={`${data.id}-batchNumber`}>{data.batch_number}</td>;
  table[labelMappings.erpCode] = <td key={`${data.id}-erpCode`}>{data.erp_code}</td>;
  table[labelMappings.businessPartnerId] = (
    <td key={`${data.id}-businessPartnerId`} className='text-right'>
      {data.business_partner_id}
    </td>
  );
  table[labelMappings.damageImage] = (
    <ImageColumn
      keyTitle={`grn-images-${data.id}`}
      imageList={data.damage_images || []}
      onImageClick={handleSliderOpen}
      key={`${data.id}-damage-images`}
    />
  );
  table[labelMappings.remarksImage] = (
    <ImageColumn
      keyTitle={`remarks-images-${data.id}`}
      imageList={data.remarks_images || []}
      onImageClick={handleSliderOpen}
      key={`${data.id}-remarks-images`}
    />
  );
  table[labelMappings.distributorId] = (
    <td key={`${data.id}-distributorId`} className='text-right'>
      {data.distributor_id}
    </td>
  );
  table[labelMappings.distributorName] = <td key={`${data.id}-distributorName`}>{data.distributor}</td>;

  return table;
};

const TableBody = ({ data, index, handleSliderOpen }) => {
  const tableBody = returnTableBody(data, handleSliderOpen);

  return <tr key={`grn-${index}`}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  handleSliderOpen: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  index: null,
};

export { TableHeader, TableBody };
