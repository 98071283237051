import gql from 'graphql-tag';

const GET_WAREHOUSE_LEVELS = gql`
  query getWarehouseLevels($filter: FilterInput) {
    getWarehouseLevels(filter: $filter) {
      rows {
        id
        title
        parent {
          id
          title
        }
        levelIndication
        dimensionLength
        dimensionHeight
        dimensionWidth
        active
      }
      count
    }
  }
`;

const GET_WAREHOUSE_LEVELS_DETAILS = gql`
  query getWarehouseLevelDetails($distId: Int!, $levelId: Int, $parentId: Int) {
    getWarehouseLevelDetails(distributorId: $distId, levelId: $levelId, parentId: $parentId) {
      rows {
        id
        title
        parent {
          id
          title
        }
        levelIndication
        binLocationNumber
        active
      }
      count
    }
  }
`;
const VALIDATE_BIN_TRANSFER = gql`
query validateBinTransfer($skuBatchId: Int!, $toBinLocationId: Int!) {
  validateBinTransfer(skuBatchId: $skuBatchId, toBinLocationId: $toBinLocationId)
}
`;

const query = {
  getWarehouseLevels: GET_WAREHOUSE_LEVELS,
  getWarehouseLevelDetails: GET_WAREHOUSE_LEVELS_DETAILS,
  getValidateBinTransfer: VALIDATE_BIN_TRANSFER,
};

export { query };
