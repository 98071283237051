import React from 'react';
import PropTypes from 'prop-types';
import { Form } from '../../../../components';
import GeolocationMap from './Map/GeolocationMap';
import withLoading from '../../../../utils/composition/withLoading';

const propTypes = {
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  handleDropDownChange: () => null,
};

const MapDialog = ({ ...props }) => {
  const { currentData, outlets } = props;
  const reqOutlet = outlets.find((outlet) => outlet.id === currentData.id);
  const { correctLocation, proposedLocation } = reqOutlet.geolocationChangeData;

  return (
    <Form>
      <GeolocationMap correctLocation={correctLocation} proposedLocation={proposedLocation} currentData={currentData} />
    </Form>
  );
};

MapDialog.propTypes = propTypes;

MapDialog.defaultProps = defaultProps;

const MapDialogViewWithErrorAndLoading = withLoading(MapDialog);

export default MapDialogViewWithErrorAndLoading;
