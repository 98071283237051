import { compose } from 'recompose';
import Outlet from './Detail';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

import {
  query, mutation, UPDATE_OUTLET, CREATE_OUTLET
} from './API';

const ComposedOutlet = compose(
  UPDATE_OUTLET,
  CREATE_OUTLET,
  withRequestTracker({
    query,
    mutation,
  }),
)(Outlet);

export default ComposedOutlet;
