import styled from 'styled-components';

const GRNFormStyled = styled.div`
  .evolve-dialog__body {
    max-height: 70vh;
    overflow: auto;
    .body_wrap {
      padding: 24px;
      border-bottom: 1px solid #e7e7ed;
      &:last-child {
        border-bottom: 0;
      }
      .single-date-picker {
        margin: 0;
      }
    }
    .single-date-picker {
      &.one {
        .datepicker-wrapper .filter-dropdown-card {
          left: -78px;
          top: 105%;
        }
      }
      &.two {
        .datepicker-wrapper .filter-dropdown-card {
          left: -78px;
          top: 105%;
        }
      }
    }
    .audit-blocked {
      &:after {
        content: "Audit is still pending for this SKU.";
        top: 85%;
        left: 4%;
        width: 120%;
      }
    }
  }
  .bin__location {
    position: relative;
    .bin_location_title {
      font-size: 14px;
      line-height: 21px;
      color: #a7a9bc;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .bin_location_li.first-li {
      .form-input {
        label {
          display: block;
        }
        &.error-msg ~ .edit_close {
          bottom: 4px;
        }
      }

      .edit_close {
        bottom: -7px;
      }
    }
    & > .bin_location_li {
      display: flex;
      align-items: center;
      .form-input-quantity {
        order: 2;
      }
      .form-input-location {
        order: 1;
      }
      .error-msg + .error-input {
        position: relative;
        top: -13px;
      }
      .error-msg ~ .edit_close {
        position: relative;
        right: 115px;
        bottom: 18px;
      }
      .form-input {
        margin-bottom: 8px;
        label {
          display: none;
          font-weight: 400;
          margin-bottom: 8px;
        }
        .location {
          width: 252px;
          border-radius: 4px 0 0 4px;
          border: 1px solid #e7e7ed;
        }
        .quantity {
          width: 145px;
          border-radius: 0 4px 4px 0;
          border: 1px solid #e7e7ed;
          border-left: 0;
          margin-right: 18px;
        }
      }
      .edit_close {
        position: relative;
        bottom: 6px;
        order: 3;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 20px;
          color: #6b6c7e;
        }
      }
    }
    /* Dialog of Bin Location start css */
    .bin__location__menu {
      position: absolute;
      background: white;
      /* top: -332px; */
      bottom: 0;
      left: 8%;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      z-index: 99;
      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0 7px;
        margin: 0 12px;
        border-bottom: 1px solid #8080802b;
        h2 {
          margin: 0;
          font-size: 14px;
          line-height: 21px;
        }
        span {
          .icon {
            height: 12px;
            width: 12px;
          }
        }
      }
      .bin__location__type {
        padding: 16px 12px;
        border-bottom: 1px solid #8080802b;
        min-width: 350px;
        .form-select-input {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          .select-css {
            width: calc(100% - 40%);
            align-self: end;
          }
        }
        .bin__location__number {
          border: 1px solid rgba(46, 90, 172, 0.4);
          border-radius: 4px;
          background-color: #eef2fa;
          padding: 16px 18px;
          font-size: 14px;
          line-height: 21px;
          color: #2e5aac;
          display: flex;
          align-items: center;
          .bin_location {
            font-weight: 500;
            .icon {
              margin-right: 9px;
              position: relative;
              top: -1px;
            }
            span.num {
              display: inline-block;
              min-width: 20px;
              white-space: nowrap;
            }
          }
          .bin_num {
            font-weight: 400;
          }
        }
      }
      footer {
        padding: 16px;
        display: flex;
        flex-direction: row-reverse;
        .cancel-btn {
          margin: 0;
          margin-right: 16px;
        }
      }
    }
  }
  .return-create .modal-inner .single-date-picker .datepicker-wrapper .filter-dropdown-card {
    left: -33%;
    top: 110%;
  }
`;

export default GRNFormStyled;
