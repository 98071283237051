import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import NumberCard from '../../components/NumberCard';
import Number from '../../components/Number';
import MonthlyWrap from '../../monthyDetail/MonthlyWrap';
import SectionStyled from '../../components/Section/SectionStyled';
import { TableBody, TableHeader } from './tableConfig';
import withImageSlider from '../../../../utils/composition/withImageSlider';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  dateRange: PropTypes.string,
};
const defaultProps = {
  data: {},
  dateRange: '',
};

const DseDetailView = ({ data, dateRange, handleSliderOpen }) => (
  <Fragment>
    <div className='dse-user-stat'>
      <h3 className='title-detail-dse'>{dateRange}</h3>
      <MonthlyWrap>
        <SectionStyled className='custom-display'>
          <NumberCard title='Scheduled' subTitle=''>
            <Number number={data.scheduled} size={32} />
          </NumberCard>
          <NumberCard title='Successful' subTitle=''>
            <Number number={data.successful} size={32} />
          </NumberCard>
          <NumberCard title='Unsuccessful' subTitle=''>
            <Number number={data.unsuccessful} size={32} />
          </NumberCard>
          <NumberCard title='Remaining' subTitle=''>
            <Number number={data.remaining} size={32} />
          </NumberCard>
          {/* <NumberCard title="Golden Points" subTitle="">
            <Number number={data.goldenPoints} size={32} numberPostfix />
          </NumberCard> */}
          <NumberCard title='Productivity' subTitle=''>
            <Number label='%' number={data.productivity} size={32} numberPostfix />
          </NumberCard>
          <NumberCard title='Target' subTitle='(NPR)'>
            <Number number={data.target} size={24} numberPostfix />
          </NumberCard>
          <NumberCard title='Received' subTitle='(NPR)'>
            <Number number={data.achievementValue} size={24} numberPostfix />
          </NumberCard>
        </SectionStyled>
      </MonthlyWrap>
    </div>
    {dateRange.split('').length < 13 && data.outletDetails ? (
      <div className='dse-user-routedetail'>
        <h3 className='title-detail-dse'>{data.route}</h3>
        <div className='table-wrap'>
          <table>
            <TableHeader />
            <tbody>
              {data.outletDetails.map((outlet) => (
                <TableBody data={outlet} handleSliderOpen={handleSliderOpen} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    ) : null}
  </Fragment>
);

DseDetailView.propTypes = propTypes;

DseDetailView.defaultProps = defaultProps;

const DseDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(DseDetailView);

const DseDetailViewWithImageSlider = withImageSlider(DseDetailViewWithErrorAndLoading);

export default DseDetailViewWithImageSlider;
