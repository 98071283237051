import React, { Fragment } from 'react';
import { getDisplayName } from './component';
import { Loading } from '../../v4/components';
import PropTypes from 'prop-types';

export default function withLoading(WrappedComponent) {
  const propTypes = {
    title: PropTypes.string,
  };

  const defaultProps = {
    title:'Loading data... Please wait!' ,
  };

  function withLoadingScreen({ loading, children,title, ...props }) {
    const newProps = {
      loading,
      ...props,
    };

    return (
      <Fragment>
        {loading && (
          <Loading title={title}/>
        )}
        <WrappedComponent {...newProps}>{children}</WrappedComponent>
      </Fragment>
    );
  }

  withLoadingScreen.propTypes = propTypes;
  withLoadingScreen.defaultProps = defaultProps;
  withLoadingScreen.displayName = `LoadingAndError(${getDisplayName(WrappedComponent)})`;

  return withLoadingScreen;
}
