import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  cardTitle: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    title: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  cardTitle: '',
  type: '',
};

const TradeForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, cardTitle, type } = props;
  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Trade'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Trade'
        disabled={type === EVENT_OPERATION.READ || cardTitle !== 'Trade'}
      />
      {(cardTitle === 'Channel' || cardTitle === 'Category') && (
        <Input
          ref={(ref) => (refsObj.title = ref)}
          value={data.Channels ? data.Channels.title : ''}
          name='title'
          type='text'
          placeholder='Channel'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event, 'Channels')}
          labelContent='Channel'
          disabled={type === EVENT_OPERATION.READ || cardTitle === 'Category'}
        />
      )}
      {cardTitle === 'Category' && (
        <Input
          ref={(ref) => (refsObj.title = ref)}
          value={data.Categories.title}
          name='title'
          type='text'
          placeholder='Category'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event, 'Categories')}
          labelContent='Category'
          disabled={type === EVENT_OPERATION.READ}
        />
      )}
    </Form>
  );
};

TradeForm.propTypes = propTypes;

TradeForm.defaultProps = defaultProps;

const ComposedTradeForm = withLoading(TradeForm);

export default ComposedTradeForm;
