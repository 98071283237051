import { useEffect, useState } from 'react';

import * as downloadService from '../../../../../../src/views/base/download.service';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { DOMAIN } from '../../../../../data/enums/config';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { SELECT_OPTIONS } from '../../../../../v4/constants/pagination';
import { getPermissionForManageUsers } from '../../../../base/permission';
import { usersListServerToClientMapper } from '../DataMapper/ManageUsersDataMapper';
import { initialDialog } from '../View/config';
import { useFilterController } from './useFilterController';

export const useManageUsersController = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [dialog, setDialog] = useState(initialDialog);
  const { filters, handleSearchInputChange, handlePageChange, handleTableSorting, handleFilterChange } = useFilterController({ domain: DOMAIN.MANAGE_USERS })
  const { filter, filterMenu, queryString, pagination, sort } = filters || {}
  const [totalCount, setTotalCount] = useState(0)
  const permission = getPermissionForManageUsers()

  const handleIconClick = (type, element) => {
    setDialog({ type, element });
  };

  const onCloseDialog = () => {
    setDialog(initialDialog);
  };

  const onDialogSubmit = async (type: any, data: any) => {
    const { updateUserStatus, displayAlert } = props;
    setIsLoading(true);

    updateUserStatus(
      {
        input: {
          ids: [data?.id],
          active: type === EVENT_OPERATION.SUSPEND ? false : true,
        },
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'User Updated');
          getUsersList(filter, pagination, queryString, sort);
          setIsLoading(false);
        },
        handleError: (err: any) => {
          displayAlert(ALERT_TYPE.DANGER, err);
          setIsLoading(false);
        }
      }
    );
  };


  const getUsersList = async (filterParams?: any, pagination?: any, queryString?: any, sort: any) => {
    const { getUserList, displayAlert } = props
    setIsLoading(true)
    getUserList({
      filter: {
        filters: filterParams,
        queryString,
        sort
      },
      offset: pagination.offset,
      limit: pagination.limit,
    }, {
      handleSuccess: (response) => {
        const data = response?.data?.manageUserStatus
        const mappedData = usersListServerToClientMapper(data?.rows)
        setTotalCount(data?.count)
        setUsers(mappedData)
        setIsLoading(false)
      },
      handleError: (err: any) => {
        displayAlert(ALERT_TYPE.DANGER, err);
        setIsLoading(false)
      }
    })
  };

  const handleDownloadClick = async (type: string, domain: string) => {
    const { displayAlert, downloadReport } = props

    setIsDownloading(true)
    downloadReport(
      {
        input: {
          domain,
          reportType: 'csv',
          filter: {
            filters: filter,
            queryString,
            sort
          },
        },
      },
      {
        handleSuccess: response => {
          const name = response?.data?.downloadReport?.file?.name
          const path = response?.data?.downloadReport?.file?.path
          const file = { name, path }
          downloadService.resolver(file)
          displayAlert(ALERT_TYPE.SUCCESS, 'Downloaded Successfully');
          setIsDownloading(false)
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
          setIsDownloading(false)
        },
      },
    );
  };

  useEffect(() => {
    getUsersList(filter, pagination, queryString, sort);
  }, [filter, queryString, sort, pagination]);

  const nameClass = totalCount > SELECT_OPTIONS[0].value - 1 && 'pagination_exist';

  return {
    filterMenu,
    handleSearchInputChange,
    handleFilterChange,
    handleTableSorting,
    isLoading,
    handleDownloadClick,
    handleIconClick,
    dialog,
    onCloseDialog,
    onDialogSubmit,
    isDownloading,
    handlePageChange,
    users,
    nameClass,
    permission,
    queryString,
    sort,
    pagination,
    totalCount,
  };
};
