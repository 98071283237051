import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { labelConfig } from './tableConfig';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import CashWraper from './CashWrapper';
import * as queryService from '../../../base/query.service';
import { isError } from '../../../common/HelperFunctions';
import { CASH_TABS, tabMapper ,filterConfig} from './config';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { getPermissionForPayment } from '../../../base/permission';
import { clone } from '../../../../utils/objectProcessor';
import { DOMAIN } from '../../../../data/enums/config';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../../common/HelperFunctions';
import { storageKeys } from '../../../sales/orderProcessing/config';

const propTypes = {
  payments: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};
class Cash extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      pending: {
        list: [],
        total: 0,
      },

      reconciled: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      activeTab: 0,
      tableInfo: labelConfig(),
    };
    this.permission = getPermissionForPayment();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getInitialData,
      props.downloadReport,
      props.displayAlert,
    );
    this.getData = () => {
      const { pending } = this.state;
      return pending;
    };

    this.tabListGetterMapper = {
      [CASH_TABS.PENDING]: this.getPaymentsList,
      [CASH_TABS.RECONCILED]: this.getReceivedList,
    };
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search },activeTab} = this.state;
    storeInLocalStorage(
      'TABINDEX',
      activeTab,
      DOMAIN.CASH
    );
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.CASH
    );
  };
  getFilterStateFromStorage = () => {
    const { activeTab } = this.state;
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.CASH);
    const localTab = fetchFromLocalStorage('TABINDEX', DOMAIN.CASH);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.getInitialData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date
        },
        filterMenu: newFilterConfig.filterMenu,
        activeTab: localTab || activeTab,
      },
      () => {
        this.getInitialData();
      },
    );
  };

  getInitialData = () => {
    this.getReceivedList();
    const { activeTab } = this.state;
    this.fetchActiveTabPaginatedList(activeTab);
  };

  getPaymentsList = tabStatus => {
    const { pending, queryParameters, activeTab } = this.state;
    const { payments } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    const status = tabStatus || tabMapper[activeTab];
    payments(
      {
        limit: queryParameters.pagination.limit,
        offset,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            ...[
              {
                column: 'collection_type',
                value: ['CASH'],
              },
              {
                column: 'status',
                value: [status.toUpperCase()],
              },
            ],
          ],
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          const payment = (response.data.payments && response.data.payments.rows) || [];
          this.state[status] = {
            total: (response.data.payments && response.data.payments.count) || 0,
            list: payment,
          };
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(pending);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  getReceivedList = () => {
    this.getPaymentsList(CASH_TABS.RECONCILED);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  fetchActiveTabPaginatedList = tab => {
    const type = tabMapper[tab];
    this.setFilterStateInStorage();
    this.tabListGetterMapper[type]();
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleTabChange = tab => {
    const { tableInfo } = this.state;
    this.setState(
      {
        activeTab: tab,
        tableInfo,
      },
      () => this.fetchActiveTabPaginatedList(tab),
    );
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  columnSelectChange = data => {
    this.setState({
      tableInfo: data,
    });
  };

  onButtonClick = () => {
    this.getInitialData();
  };

  handleDownloadClick = (reportType, domain) => {
    const { queryParameters } = this.state;

    const filter = {
      filters: [
        ...apiFilterProcessor(queryParameters.filter),
        {
          column: 'collection_type',
          value: ['CASH'],
        },
      ],
      queryString: queryParameters.search,
      dateRange: queryParameters.date,
    };

    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
  };

  render() {
    const { activeTab, tableInfo, pending, reconciled, queryParameters,filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <CashWraper>
        <View
          pending={pending}
          receive={reconciled}
          activeTab={activeTab}
          onTabChange={this.handleTabChange}
          tableConfig={tableInfo}
          columnSelectChange={this.columnSelectChange}
          queryParameters={queryParameters}
          basePaginationService={this.basePaginationService}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          onButtonClick={this.onButtonClick}
          filterMenu={filterMenu}
          handleDownloadClick={this.handleDownloadClick}
          loading={serverResponseWaiting}
          permission={this.permission}
          pagination={queryParameters.pagination}
        />
      </CashWraper>
    );
  }
}

Cash.propTypes = propTypes;

Cash.defaultProps = defaultProps;

export default withAlert()(Cash);
