import React from 'react';
import { PropTypes } from 'prop-types';

const propTypes = {
  holiday: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

const defaultProps = {
  holiday: {
    id: null,
    title: null,
    date: '',
  },
};

const HolidayView = ({ holiday }) => <span className='holiday-title'>{holiday.title}</span>;

HolidayView.propTypes = propTypes;

HolidayView.defaultProps = defaultProps;

export default HolidayView;
