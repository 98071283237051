export const ICO = ({ ...props }) => {
  const { data, onIcoChange } = props;
  return (
    <>
      <div className="form-input-wrap card">
        <div className="permission-choose m-0">
          <div className="checkbox-horizontal">
            <div className="status-content">
              <label className="radio-list">
                <input
                  type="radio"
                  name="ico"
                  value="sku"
                  checked={data.ico && data.ico.sku}
                  onChange={e => onIcoChange(e)}
                />
                <span>SKU</span>
              </label>
              <label className="radio-list">
                <input
                  type="radio"
                  name="ico"
                  value="skuFamily"
                  checked={data.ico && data.ico.skuFamily}
                  onChange={e => onIcoChange(e)}
                />
                <span>SKU Family</span>
              </label>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};
