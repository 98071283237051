import { compose } from 'recompose';
import CategoryRequest from './CategoryRequest';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, BULK_UPDATE_APPROVAL_REQUEST, DOWNLOAD_REPORT
} from './API';

const ComposedCategoryRequest = compose(
  BULK_UPDATE_APPROVAL_REQUEST,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(CategoryRequest);

export default ComposedCategoryRequest;
