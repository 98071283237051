import styled from 'styled-components';

const PromtionDetailStyled = styled.div`
  .form-input {
    display: block;
    margin-bottom: 24px;
    label {
      margin: 0 0 5px;
    }
    > input {
      background: white;
    }
  }

  .filter-dropdown-card {
    left: 0;
    right: auto;
    min-width: 320px;
    box-shadow:unset;
    border:0;
    top: 74px;
    .autocomplete-list{
    padding:16px;
    box-shadow: rgb(0 0 0 / 10%) 1px 2px 10px 1px;
    border:1px solid rgb(231,231,237);
  }
  }
  .suggested-autocomplete-list-wrap {
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 24px;
  }
  .autocomplete-list {
    > div {
      margin-bottom: 5px;
      cursor: pointer;
      font-weight: 600;
      color: #6b6c7e;
      font-size: 14px;
      transition: 0.2s linear;

      &:hover,
      &:focus {
        color: #272833;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .suggested-autocomplete-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 4px;
    .text {
      padding: 8px 16px;
      border-radius: 4px;
      background: #e7e7ed;
      border: 1px solid #e7e7ed;
      font-size: 14px;
      flex: 1;
    }
    .ic-close {
      cursor: pointer;
      display: inline-block;
      width: 12px;
      height: 12px;
      overflow: hidden;
      text-indent: -999px;
      background: url('/image/icons/ic_close.svg');
      background-size: 12px;
      margin-left: 18px;
    }
  }

  .scope {
    .form-input {
      margin: 0;
    }
  }
  label {
    font-size: 14px;
    line-height: 21px;
    color: #272833;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .catalog-wrap {
    & > .mb-24 {
      margin-top: 13px;
      .radio-list {
        margin-right: 24px;
        input {
          margin-right: 8px;
        }
      }
    }
  }
  .condition {
    .form-input {
      display: block;
    }
    label {
      margin: 0 0 5px;
    }
  }
  .disbursement {
    .form-select-input {
      margin-top: 6px;
      label {
        display: none;
      }
    }
  }

  .radio-list {
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }

  .catalog {
    width: 420px;
  }
  .select-catogory {
    width: 464px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .catalog-placeholder {
    min-width: 420px;
    background: #e7e7ed;
    padding: 10px;
    border-radius: 4px 0 0 4px;
    height: 40px;
  }

  .moq-placeholder {
    position: relative;
    width: 170px;
    font-size: 8px;
    > div {
      > input {
        padding: 8px 16px 8px 40px;
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .moq-placeholder::after {
    position: absolute;
    left: 10px;
    bottom: 12px;
    content: attr(data-placeholder);
    opacity: 0.4;
  }

  .config-panel-header {
    display: flex;
    margin: 24px 0 0 0;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #272833;
    border-bottom: 1px solid #cdced9;
    justify-content: space-between;
    svg {
      height: 12px;
      width: 12px;
      cursor: pointer;
    }
  }

  .config-catalog-wrap {
    padding: 34px 24px;
    background: rgba(231, 231, 237, 0.2);
    border-bottom: 1px solid #cdced9;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .config-promotion-panel-header {
    display: flex;
    margin: 24px 0 0 0;
    padding-bottom: 4px;
    justify-content: flex-end;
    border-bottom: 1px solid #cdced9;
    svg {
      height: 12px;
      width: 12px;
      cursor: pointer;
    }
  }
  /* extra */

  /* .promotion-config-inner {
    padding: 48px;
    &:last-child {
      padding-bottom: 0;
    }

    h3 {
      margin: 0;
      background: #f1f2f5;
      font-size: 14px;
      font-weight: 600;
      padding: 6px 24px;
      line-height: 21px
    }
    
    .grn-form {
      padding: 24px;
      box-shadow: 1px 1px 10px 1px #e7e7e7;
    }
    
    &.sku {
     
      .table-wrap {
        padding: 24px 0;
      }
      
      table th {
        background: transparent;
        border: none;
      }
      
      table tr {
        transition: all 0.3s ease-in-out;
        
        &:hover, &:focus {
          background: #eeeeee;
        }
      }
    }
    
  } */

  .section-content .config-panel-contents {
    .promotion-valid-count {
      .form-input-checkbox {
        display: flex;
        align-items: center;
        margin-top: 5px;
        label {
          min-width: 73px;
        }
        .form-input {
          margin-left: 8px;
          input {
            min-width: 168px;
            font-size: 16px;
            color: #6b6c7e;
            font-weight: 500;
          }
        }
      }
    }
    .promotion-banner {
      .upload-img-add {
        .big {
          width: 100px;
          height: 64px;
        }
        .attachment-item {
          height: 64px;
          width: 100px;
        }
      }
      & > span {
        display: flex;
        align-items: center;
        margin-top: 16px;
        color: #6b6c7e;
        font-size: 10px;
        line-height: 10px;
        svg {
          height: 10px;
          width: 10px;
          fill: #a7a9bc;
          margin-right: 4px;
        }
      }
    }

    .scope {
      .enable-promotions {
        .enable-promotion__checkbox {
          margin-top: 16px;
          label {
            margin-right: 24px;
            margin-bottom: 0;
          }
        }
        & > span {
          display: flex;
          align-items: center;
          margin-top: 48px;
          padding: 18px;
          border: 1px solid rgba(46, 90, 172, 0.4);
          border-radius: 4px;
          background-color: #eef2fa;
          color: #2e5aac;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          b {
            font-weight: 600;
            margin-right: 4px;
          }
          svg {
            height: 14px;
            width: 14px;
            fill: #2e5aac;
            margin-right: 8px;
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }
`;

export default PromtionDetailStyled;
