import styled from 'styled-components';

const SalesInvoiceStyled = styled.div`
.config-panel-card.table{
  .config-panel-contents{
    padding-top:0;
  }
}
.config-panel-card .config-panel-contents{
padding-bottom:0;
.invoice-table-info .total-bottom{
  padding-bottom:24px
}
.time-wrap{
  display:flex;
  flex-wrap: wrap;
  label{
    flex-basis:100%;
  }
  >span{
    flex:1;
    flex-basis: 69%;
  }
  .date{
    border-radius: 4px 0 0 4px;
  }
  .time{
border-radius:0;
border-left:0;
border-right:0;
  }
  .amPm{
    border-radius: 0 4px 4px 0;
  }
  .amPm,
  .time{
    flex-basis:15%;
  }
}
.table-wrap table{
  th,tr {
    height:40px;
    padding-top:0;
    padding-bottom:0;
  }
    th{
  background: #F7F8F9 !important;
  }
}
}
  .customer-info {
    padding-bottom: 24px;
    /* border-bottom: 1px solid #e7e7ed; */
    .info {
      margin-bottom: 24px;
      label {
        font-size: 14px;
        line-height: 21px;
        color: #272833;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
      }
      span {
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: #6b6c7e;
        font-weight: 400;
        border: 1px solid #E7E7ED;
        height: 40px;
        padding: 8px 16px;
        width: 100%;
        border-radius: 4px;
      }
    }
  }
  .invoice-form {
    .info {
      label {
        font-size: 14px;
        color: #272833;
        font-weight: 600;
      }
    }
    .info.payment-mode {
      display: flex;
      flex-direction: column;
      .ledger-info {
        label {
          font-weight: 400;
        }
      }
    }
  }

  .pad-top-8 {
    padding-top: 8px;
  }
  .invoice-view-wrap {
    margin-top: 20px;
  }
  .section-content.invoice .invoice-form {
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 0;
  }
  .ledger-info {
    margin-top: 11px;
    color: #6b6c7e;
  }
  .table-wrap {
    table {
      border: 0;
      thead {
        th {
          border-right: 0;
          border-left: 0;
        }
      }
      tbody {
        td {
          border-right: 0;
          border-left: 0;
        }
      }
    }
  }
  .pad-24.ledger-modal-body {
    max-height: 350px;
    overflow: auto;
  }
  .modal-inner {
    &.update {
      max-width: 435px;
    }
  }
`;

export default SalesInvoiceStyled;
