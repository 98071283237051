import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import { tableData, menuAction } from '../../../../v4/components';

const labelMappings = {
  id: 'id',
  name: 'name',
  users:'users',
  parentRole: 'parentRole',
  displayName: 'displayName',
};

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.name,
    title: 'Roles',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.users,
    title: 'Users',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.parentRole,
    title: 'User Group',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.displayName,
    title: 'Alias',
    show: true,
  },
];

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data, onIconClick, permission,options) => {
  const table = {};
  const nameFiltered =options.filter((a) =>a.parentGroupId === data.UserGroup.parentGroupId)[0] || [];
  table[labelMappings.id] = <td key={`user-role-${data.id}-id`}>{data && data.id}</td>;
  table[labelMappings.name] = <td key={`user-role-${data.id}-name`}>{data && data.name}</td>;
  table[labelMappings.users] = <td key={`user-role-${data.id}-users`}>{data?.users.filter((e) => e.active === true)?.length || ''}</td>;
  table[labelMappings.parentRole] = <td key={`user-role-${data.id}-parentRole`}>{nameFiltered && nameFiltered.name}</td>;
  table[labelMappings.displayName] = <td key={`user-role-${data.id}-displayName`}>{data && data.displayName}</td>;
  return table;
};

const TableBody = ({ data, onIconClick ,onRowClick ,options,permission }) => {
  const tableBody = returnTableBody(data, onIconClick, onRowClick,options,permission);
  return (
    <tr key={`user-role-${data.id}-details`} onClick={() => onRowClick(data)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
