import styled from 'styled-components';

const TableCardStyled = styled.div`
    position: relative;
    box-shadow: rgb(39 40 51 / 12%) 0px 4px 8px 0px;
    border: 1px solid rgba(39,40,51,0.12);
    border-radius: 4px;
    margin-bottom: 24px;
    width: 100%;
    background-color: #fff;
    padding-bottom:16px;
    .table-title{
        margin:0;
        color:#272833;
        text-align:left;
        margin-bottom:24px;
        font-size: 14px;
      font-weight: bold;
       line-height: 21px;
        text-transform: uppercase;
        padding: 24px 24px 0 24px;
      }
  .table-wrap{
    min-height:100px;
    max-height:400px;
    overflow:auto;
    table{
      border:0;
      overflow:auto;
      tbody,
      thead{
        text-transform: capitalize;
        tr{
          height:unset;
          th{
            /* position: sticky;
            top: 0;
            z-index: 1; */
          }
        td,th{
          font-size:12px;
          line-height:15px;
          background:unset;
          border:0;
          font-weight:400;
          text-align:right;
          &:last-child,
          &:first-child{
            padding:0;
          }
          &:first-child{
            padding-left:24px;
          }
          &:last-child{
            padding-right:24px;
          }
          &:nth-child(2),
          &:first-child{
            text-align:left;
          }
        }
        th{
          color:#6B6C7E;
          padding:0;
          padding-bottom:8px !important;
          }
          td{
            color:#272833;
            padding:8px 0;
          }
        &:hover{
            background:unset;
          }
        }
      }
    }
      }
`;

export default TableCardStyled;
