import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../v4/components';
import ErrorStyled from './ErrorStyled';
import ErrorPageData from './data';
import history from '../../utils/history';

const propTypes = {
  value: PropTypes.number,
};

const defaultProps = {
  value: '',
};

class Error extends Component {
  render() {
    const { value } = this.props;
    const error = ErrorPageData.filter((index) => index.value === value).map((index) => (
      <div key={index.value}>
        <div className='error-type'>{index.value}</div>
        <div className='error-message'>{index.name}</div>
        <div className='message'>
          <div>{index.message1}</div>
          <div>{index.message2}</div>
        </div>
      </div>
    ));
    const image = value === 404 ? value : 500;
    return (
      <ErrorStyled>
        <div className='error-wrap'>
          <div className='error-container'>
            <img src={`/image/error_${image}.svg`} alt='Error Illustrations' />
            {error}
            <Button secondary small onClick={() => history.goBack()}>
              Go Back
            </Button>
          </div>
        </div>
      </ErrorStyled>
    );
  }
}

Error.propTypes = propTypes;
Error.defaultProps = defaultProps;

export default Error;
