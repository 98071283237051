const customerMapper = (element) => ({
  id: element.id ? element.id || null : element.customerId || null,
  name: element.name ? element.name || '' : element.title || '',
  address: element.address || '',
  panNumber: element.panNumber ? element.panNumber || null : element.panNo || null,
  distributorId: element.distributorId || null,
});

const remarksList = [
  { remark: 'Damaged' },
  { remark: 'Expired' },
];

export { customerMapper, remarksList };
