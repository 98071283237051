import React, { Component } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { Badge } from '../../v4/components';

class DemoBadge extends Component {
  render() {
    return (
      <div className='demo-section button-wrap'>
        <div className='demo-section-inner'>
          <div className='demo-header'>
            <h2>Badge</h2>
            <p>
              Badges provide a method to highlight a count of an element either next to it or inside the element itself.
            </p>
          </div>
          <div className='demo-body'>
            <div className='demo-content'>
              <div className='demo-content-list'>
                <div className='demo-sample'>
                  <Row>
                    <Col xs={3}>
                      <div className='bg-light-grey'>
                        <div className='demo-badge-container'>
                          <Badge className='primary' label='2' />
                        </div>
                      </div>
                      <h6 style={{ marginBottom: 30, textAlign: 'center' }}>Primary</h6>
                    </Col>
                    <Col xs={3}>
                      <div className='bg-light-grey'>
                        <div className='demo-badge-container'>
                          <Badge className='light' label='123456' />
                        </div>
                      </div>
                      <h6 style={{ marginBottom: 30, textAlign: 'center' }}>Light</h6>
                    </Col>
                    <Col xs={3}>
                      <div className='bg-light-grey'>
                        <div className='demo-badge-container'>
                          <Badge className='success' label='123' />
                        </div>
                      </div>
                      <h6 style={{ marginBottom: 30, textAlign: 'center' }}>Success</h6>
                    </Col>
                    <Col xs={3}>
                      <div className='bg-light-grey'>
                        <div className='demo-badge-container'>
                          <Badge className='warning' label='16789' />
                        </div>
                      </div>
                      <h6 style={{ marginBottom: 30, textAlign: 'center' }}>Warning</h6>
                    </Col>
                    <Col xs={3}>
                      <div className='bg-light-grey'>
                        <div className='demo-badge-container'>
                          <Badge className='info' label='1256789' />
                        </div>
                      </div>
                      <h6 style={{ marginBottom: 30, textAlign: 'center' }}>Info</h6>
                    </Col>
                    <Col xs={3}>
                      <div className='bg-light-grey'>
                        <div className='demo-badge-container'>
                          <Badge className='danger' label='13' />
                        </div>
                      </div>
                      <h6 style={{ marginBottom: 30, textAlign: 'center' }}>Danger</h6>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DemoBadge;
