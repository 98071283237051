import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../../../common/GqlAPI';

const getICOGroupApi = gql`
  query IcoGroups($limit: Int, $offset: Int, $filter: FilterInput) {
    icoGroups(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        title
        alias
        active
      }
      count
    }
  }
`;

const createICOGroupMaster = graphql(
  gql`
    mutation createICOGroups($input: ICOGroupInput!) {
      createICOGroups(input: $input) {
        id
        title
        alias
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createICOGroupMaster: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const updateICOGroupMaster = graphql(
  gql`
    mutation updateICOGroups($input: ICOGroupInput!, $id: Int) {
      updateICOGroups(input: $input, id: $id) {
        id
        title
        alias
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateICOGroupMaster: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getICOGroupApi,
};

const mutation = {
  createICOGroupMaster,
  updateICOGroupMaster,
  toggleState: TOGGLE_STATE,
};

export {
  query, mutation, TOGGLE_STATE, createICOGroupMaster, updateICOGroupMaster,getICOGroupApi
};
