import React from 'react';
import { Button } from '..';
import history from '../../../utils/history';
import ErrorPageData from '../../constants/ErrorPages';
import ErrorStyled from './ErrorStyled';

function Error({ ...props }) {
  const { value } = props;
  const error = ErrorPageData
    .filter(index => index.value === value)
    .map(index => (
      <div key={index.value}>
        <div className="error-type">{index.value}</div>
        <div className="error-message">{index.name}</div>
        <div className="message">
          <div>{index.message1}</div>
          <div>{index.message2}</div>
        </div>
      </div>
    ));
  const image = (value === 404) ? value : 500;
  return (
    <ErrorStyled>
      <div className="error-wrap">
        <div className="error-container">
          <img src={`/image/error_${image}.svg`} alt="Error Illustrations" />
          {error}
          <Button secondary small onClick={() => history.goBack()}>Go Back</Button>
        </div>
      </div>
    </ErrorStyled>
  );
};

export default Error;
