import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { title, breadCrumbConfig, formMapper, crudSuccess as crudRequestConfig, dimensionListConfig } from './config';
import DetailsStyled from './DetailsStyled';
import SubCampaign from './subCampaign';
import history from '../../../../utils/history';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import SummaryDetails from './summaryDetails';
import { has } from '../../../../utils/objectPrototypes';
import { clone } from '../../../../utils/arrayProcessor';
import * as queryService from '../../../base/query.service';
import { refGenerator } from '../../../../utils/refGenerator';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { PanelCard ,BreadCrumb, Icon,Button } from '../../../../v4/components';
import { CAMPAIGNS_DETAILS } from '../../../../data/enums/Route';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { inputChange, dropdownChange } from '../../../../utils/formHandlers';
import MASTER_DATA_TYPES from '../../../../data/enums/MasterData';
import { getPermissionForCampaigns } from '../../../base/permission';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  createCampaign: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class CampaignsDetails extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: formMapper({}),
      campaignId: has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0,
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      enableErrorDisplay: false,
      subCampaignTypeList: [],
      dimensionList: [],
      fiscalYearList: [],
      backUpData: formMapper({}),
      editable: false,
      approvable: false,
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      fiscalYearDate: { start: '', end: '' },
    };
    this.permission = getPermissionForCampaigns();
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createCampaign,
      [EVENT_OPERATION.UPDATE]: props.createCampaign,
    };
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator(['title', 'budget', 'fiscalYearId']);
  }

  componentDidMount() {
    const { campaignId, update } = this.state;
    if (campaignId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState(
        {
          campaignId,
          update,
        },
        () => this.getCampaignDetail(campaignId),
      );
    }
    this.subCampaignTypeData();
    this.getFiscalYearData();
  }

  getDetailsValidationStatus = () => !Object.values(this.formReference).find(item => item.getValidState() === false);

  responseProcessor = () => {
    const onAPIRequestSuccess = type => response => {
      const { displayAlert } = this.props;
      const { update, campaignId } = this.state;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage, response);
      if (type === 'CREATE') {
        history.push(`/${CAMPAIGNS_DETAILS}/${response.id}`);
        update.type = 'UPDATE';
        update.status = false;
        this.setState({ update });
        this.getCampaignDetail(response.id);
        this.setState({ campaignId: response.id });
      }
      if (type === 'UPDATE') {
        update.status = false;
        this.setState({ update });
        this.getCampaignDetail(campaignId);
        this.setState({ campaignId });
      }
    };
    return onAPIRequestSuccess;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getActionType = () => {
    const { update } = this.state;
    return update.type !== EVENT_OPERATION.CREATE
      ? update.type === EVENT_OPERATION.UPDATE
        ? EVENT_OPERATION.UPDATE
        : EVENT_OPERATION.APPROVE
      : EVENT_OPERATION.CREATE;
  };

  createCampaign = () => {
    const { data } = this.state;
    const updatedData = clone(data);
    const type = this.getActionType();
    const tableData = this.getTableDetails();
    updatedData.SubCampaign = tableData.SubCampaign;
    updatedData.temp = tableData.temp;
    this.onFormSubmit(type, updatedData);
    this.setState({ data: updatedData });
  };

  handleButtonSubmit = () => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    const valid = this.getValidationStatus();
    if (valid) {
      this.createCampaign();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  subCampaignTypeData = () => {
    const { getListMasterData } = this.props;
    getListMasterData(
      {},
      {
        handleSuccess: res => {
          const { listMasterData = [] } = res.data;
          const dataObj = {
            [MASTER_DATA_TYPES.SUB_CAMPAIGN_DIMENSIONS]: [],
            [MASTER_DATA_TYPES.SUB_CAMPAIGN_TYPES]: [],
          };
          if (listMasterData.length > 0) {
            listMasterData.forEach(item => {
              dataObj[item.type] = [...(item.list || [])];
            });
            this.setState({
              dimensionList: dataObj[MASTER_DATA_TYPES.SUB_CAMPAIGN_DIMENSIONS],
              subCampaignTypeList: dataObj[MASTER_DATA_TYPES.SUB_CAMPAIGN_TYPES],
            });
          }
        },
        handleError: err => {
          this.onAPIRequestFailure();
        },
      },
    );
  };

  getFiscalYearData = () => {
    const { getlistFiscalYear, displayAlert } = this.props;
    getlistFiscalYear(
      {},
      {
        handleSuccess: response => {
          this.setState({
            fiscalYearList: response.data.listFiscalYear || [],
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getCampaignDetail = id => {
    const { queryParameters } = this.state;
    const { getCampaignDetail, displayAlert } = this.props;
    getCampaignDetail(
      {
        filter: {
          filters: [
            {
              column: 'id',
              value: [id.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          const dataDetails = response.data.campaigns.rows[0];
          this.setState(
            {
              data: dataDetails,
              backUpData: clone(dataDetails),
            },
            () => {
              this.setDate();
            },
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getValidationStatus = () => {
    const detailsStatus = this.getDetailsValidationStatus();
    const tableStatus = this.getTableValidationStatus();
    return detailsStatus && tableStatus;
  };

  handleButtonCancel = () => {
    const tableData = this.getTableDetails();
    const { backUpData, campaignId, update, data } = this.state;
    const SubCampaign = backUpData.SubCampaign.filter(item => item.id !== tableData.delInfo.id);
    const backUpDataUpdate = { ...backUpData, SubCampaign };
    if (campaignId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState({ data: clone(backUpDataUpdate), update });
    } else this.setState({ data: formMapper({}) });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedData = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedData });
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedDetails = dropdownChange(data, parameterRef, value);
    this.setState({ data: updatedDetails }, () => {
      callBack(value);
    });
  };

  getHeader = () => {
    const { update } = this.state;
    const crudMode = update.status ? EVENT_OPERATION_MAPPER[update.type].toLowerCase() : '';
    return (
      <>
        <span>{crudMode} </span>
        {title}
      </>
    );
  };

  setDate = id => {
    const { fiscalYearList, data } = this.state;
    const fiscalYear = fiscalYearList.find(a => a.id === data.fiscalYearId) || {};
    const fiscalYearDate = {
      start: fiscalYear.startDate,
      end: fiscalYear.endDate,
    };
    data.startDate = data.startDate || fiscalYear.startDate;
    data.endDate = data.endDate || fiscalYear.endDate;
    this.setState({ data, fiscalYearDate });
  };

  render() {
    const {
      update,
      data,
      enableErrorDisplay,
      subCampaignTypeList,
      dimensionList,
      campaignId,
      fiscalYearList,
      fiscalYearDate,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const header = this.getHeader();
    return (
      <DetailsStyled>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{header}</h2>
              <div className="flex m-0">
                {update.status && (
                  <div>
                    <Button small secondary disabled={serverResponseWaiting} onClick={() => this.handleButtonCancel()}>
                      <span>Cancel</span>
                    </Button>
                    <Button small primary disabled={serverResponseWaiting} onClick={() => this.handleButtonSubmit()}>
                      <span>Save</span>
                    </Button>
                  </div>
                )}
                {(!update.status && this.permission.update )&&(
                  <Button
                    secondary
                    iconBtnSmall
                    disabled={update.type === EVENT_OPERATION.UPDATE && update.status}
                    onClick={() => {
                      this.setState({
                        update: {
                          type: EVENT_OPERATION.UPDATE,
                          status: true,
                        },
                      });
                    }}
                    className="ml-16"
                  >
                    <Icon iconName="pencil" />
                  </Button>
                )}
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className={`section-content pad-48 ${update.type === 'UPDATE' && 'update'}`}>
          <PanelCard cardTitle="Details" className={!update.status ? 'disabled' : null}>
            <SummaryDetails
              update={update}
              loading={serverResponseWaiting}
              enableErrorDisplay={enableErrorDisplay}
              fiscalYearList={fiscalYearList}
              fiscalYear={fiscalYearDate}
              data={data}
              handleInputChange={this.handleInputChange}
              handleDropDownChange={this.handleDropDownChange}
              dropDownCallBack={this.setDate}
              refsObj={this.formReference}
            />
          </PanelCard>
          <PanelCard cardTitle="Sub Campaigns" className="sub-campaigns">
            <SubCampaign
              data={data}
              campaignId={campaignId}
              update={update}
              subCampaignTypeList={subCampaignTypeList}
              getStatus={childMethod => (this.getTableValidationStatus = childMethod)}
              getDetails={childMethod => (this.getTableDetails = childMethod)}
              dimensionList={dimensionListConfig}
            />
          </PanelCard>
        </div>
      </DetailsStyled>
    );
  }
}
CampaignsDetails.propTypes = propTypes;
CampaignsDetails.defaultProps = defaultProps;
export default withAlert()(CampaignsDetails);
