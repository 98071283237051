import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import styled from 'styled-components';
import {
  getCurrentDay,
  getP3MStartDay,
  getP6MStartDay,
  getStartOfCurrentMonth,
  getStartOfCurrentYear,
  presentor,
} from '../../../utils/date';

interface DateStringPickerProps {
  date: {
    start: string;
    end: string;
  };
  onClick: (date: { start: string; end: string }, title: string) => void;
}

const DateStringPicker = (props: DateStringPickerProps) => {
  const { date: dateProps, onClick } = props;
  const [dataChange, setDateChange] = useState(false);
  const dateConfiguration = [
    {
      id: 1,
      title: 'Today',
      date: {
        start: moment(new Date()).tz('Asia/Kathmandu', true).toString(),
        end: moment(new Date()).tz('Asia/Kathmandu', true).toString(),
      },
    },
    {
      id: 2,
      title: 'MTD',
      date: {
        start: getStartOfCurrentMonth(),
        end: getCurrentDay(),
      },
    },
    {
      id: 3,
      title: 'P3M',
      date: {
        start: getP3MStartDay(),
        end: getCurrentDay(),
      },
    },
    {
      id: 4,
      title: 'P6M',
      date: {
        start: getP6MStartDay(),
        end: getCurrentDay(),
      },
    },
    {
      id: 5,
      title: 'YTD',
      date: {
        start: getStartOfCurrentYear(),
        end: getCurrentDay(),
      },
    },
  ];

  useEffect(() => {}, [dataChange]);

  const onDateComponentClick = (item: { date: { start: string; end: string }; title: string; id: number }) => {
    switch (item.id) {
      case 1:
        item.date.start = getCurrentDay();
        item.date.end = getCurrentDay();
        break;
      case 2:
        item.date.start = getStartOfCurrentMonth();
        item.date.end = getCurrentDay();
        break;
      case 3:
        item.date.start = getP3MStartDay();
        item.date.end = getCurrentDay();
        break;
      case 4:
        item.date.start = getP6MStartDay();
        item.date.end = getCurrentDay();
        break;
      case 5:
        item.date.start = getStartOfCurrentYear();
        item.date.end = getCurrentDay();
        break;
    }

    const newObject = Object.assign({}, item);
    onClick(newObject.date, newObject.title);
  };

  const checkIfActive = (item: { date: { start: string; end: string }; title: string }) => {
    return presentor(dateProps.start) === presentor(item.date.start) && presentor(dateProps.end) === presentor(item.date.end);
  };

  return (
    <DateStringPickerStyled>
      <div className="d-flex-space-between">
        {dateConfiguration.map(item => (
          <div
            key={item.id}
            className={checkIfActive(item) ? 'date-item is-active' : 'date-item'}
            onClick={() => onDateComponentClick(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </DateStringPickerStyled>
  );
};

const DateStringPickerStyled = styled.div`
  .d-flex-space-between {
    display: flex;
    justify-content: space-between;
  }

  .date-item {
    margin: 0 !important;
    flex: 1;
    padding: 6px 12px;
    border: 1px solid #cdced9;
    border-left-color: transparent;
    color: #a7a9bc;
    text-align: center;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    font-weight: 500;
    font-size: 14px;

    &:hover,
    &:focus {
      background: #ececec;
      transition: all 0.25s ease-in-out;
    }
  }
  .date-item:first-child {
    border-left-color: #cdced9;
    border-radius: 4px 0 0 4px;
  }

  .date-item:last-child {
    border-radius: 0 4px 4px 0;
  }

  .date-item.is-active {
    color: #272833;
    background: #cdced9;
  }
`;

export default DateStringPicker;
