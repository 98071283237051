import { FORM_CONFIG } from '../../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import LEDGERS from '../../../../../data/enums/Ledgers';
import { ORDER_FULFILMENT } from '../../../../../data/enums/Route';
import { breadCrumb as invoiceRoutes } from '../../config';
import moment from 'moment';
import { getTodaysDate } from '../../../../../utils/date';

const breadCrumb = [
  ...invoiceRoutes,
  {
    id: '',
    title: 'Order Fulfilment',
    path: `/${ORDER_FULFILMENT}`,
    active: true,
  },
  {
    id: '',
    title: 'Sales Invoice',
    path: '#',
    active: true,
  },
];

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: '',
    [FORM_CONFIG.MAPPER]: (element) => ({
      status: element.status || '',
      reason: element.reason || '',
    }),
  },
};

const defaultBillingObj = {
  status: false,
  url: '',
};
const detailMapper = (element) => {
  const momentObj = moment(element?.deliveryTo);
  return {
    Distributor: {
      id: element?.Distributor ? element?.Distributor.id : 0,
      title: element?.Distributor ? element?.Distributor?.title : '',
      servicesUsed: element?.Distributor
        ? {
          billing: element?.Distributor?.servicesUsed
            ? element?.Distributor?.servicesUsed?.billing || defaultBillingObj
            : defaultBillingObj,
        }
        : { billing: defaultBillingObj },
    },
    amPm: momentObj?.format?.('A') == 'AM' ? 'AM' : 'PM' ?? '',
    deliveryDate: momentObj?.format?.('YYYY-MM-DD') || getTodaysDate(),
    time: momentObj?.format?.('h:mm') ?? '00:00',
    RetailOutlet: {
      id: element?.RetailOutlet ? element?.RetailOutlet.id : 0,
      title: element?.RetailOutlet ? element?.RetailOutlet?.title : '',
      address: element?.RetailOutlet ? element?.RetailOutlet?.address : '',
      panNumber: element?.RetailOutlet ? element?.RetailOutlet?.panNumber : '',
      contactInfo: element?.RetailOutlet?.contactInfo || [],
      Category: {
        title: element?.RetailOutlet ? (element?.RetailOutlet?.Category ? element?.RetailOutlet?.Category?.title : '') : '',
      },
      townId: element?.RetailOutlet ? element?.RetailOutlet?.townId : 0,
    },
    selectedOrders: element?.selectedOrders || [],
    selectedIds: element?.selectedIds || [],
    User: { fullName: element?.User ? element?.User?.fullName : '' },
    callOrderValue: element?.callOrderValue ? element?.callOrderValue : {},
    remarks: element?.notes,
    billingAddress: element?.remarks,

  }
};

const customerMapper = (element) => ({
  id: element.id ? element.id || '' : element.customerId || '',
  name: element.name ? element.name || '' : element.title || '',
  address: element.address || '',
  panNumber: element.panNumber ? element.panNumber || null : element.panNo || null,
  creditLimit: element.creditLimit ? element.creditLimit : 0,
  creditDay: element.creditDay ? element.creditDay : 0,
  outstandingAmount: element.outstandingAmount ? element.outstandingAmount : 0,
  billingLedgerId: element.billingLedgerId ? element.billingLedgerId : 0,
  distributorId: element.distributorId || null,
  openingBalanceType: element.openingBalanceType || '',
});

const customerDetailMapper = (element) => ({
  Customer: element.Customer ? element.Customer : customerMapper({}),
  invoiceNumber: element.invoiceNumber ? element.invoiceNumber : null,
  remarks: element.remarks ? element.remarks : '',
  isCash: element.isCash ? element.isCash : false,
  isAbbreviatedInvoice: element.isAbbreviatedInvoice ? element.isAbbreviatedInvoice : false,
  billName: element.billName ? element.billName : '',
});

const getPayload = (data) => {
  /** todo create payload details accrding to api payload requirement */
  const details = {
    orderIds: data.selectedIds,
    invoiceNumber: data.customerDetails.invoiceNumber,
    customerId:
      data.customerDetails.isCash && data.customerDetails.Customer.id === ''
        ? LEDGERS.CASH.ledger_id
        : data.customerDetails.Customer.id || null,
    tradeDiscountValue: data.amountDetails.tradeDiscountValue,
    remarks: data.customerDetails.remarks,
    isCash: data.customerDetails.isCash,
    isAbbreviatedInvoice: data.customerDetails.isAbbreviatedInvoice,
    customerName: data.customerDetails.billName,
  };

  return details;
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => getPayload(data),
    responseName: 'invoiceOrders',
    message: 'Orders Invoiced Successfully',
  },
};

export {
  breadCrumb, detailMapper, crudSuccess, customerMapper, customerDetailMapper, formConfig
};
