import React from 'react';
import { Switch } from '../../../../components';
import styled from 'styled-components';
import dragDots from '../../../../assets/images/dragDots.svg';

const DragList = ({ item, index, onInputChange }) => {
  return (
    <DragListStyled>
      <div className="list-item">
        <div className="list-left">
          <span>
            <img src={dragDots} alt="" />
          </span>
          <span className="list-counter">{index + 1}</span>
          <span className="info">{item?.CallProcedure?.title}</span>
        </div>
        <Switch
          checked={item?.active}
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'active',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              item,
              index,
            );
          }}
          classes="card"
        />
      </div>
    </DragListStyled>
  );
};

export default DragList;

const DragListStyled = styled.div`
  .list-item {
    display: flex;
    align-items: center;
    border: 1px solid #eee;
    padding: 16px 24px;
    border-radius: 4px;
    .list-left {
      display: flex;
      align-items: center;
      gap: 12px;
      .list-counter {
        color: #6b6c7e;
        border: 1px solid #cdced9;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        min-width: 30px;
        text-align: center;
        padding: 0 4px;
        border-radius: 2px;
      }
      .info {
        color: #272833;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
    }
  }
`;
