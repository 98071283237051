import styled from 'styled-components';

const LocationHierarchyStyled = styled.div`
  .evolve-dialog__body {
    & > span {
      display: block;
    }
  }
  .create {
    .evolve-dialog__body {
      form {
        overflow: unset;
      }
    }
  }
  form {
    max-height: 500px;
    overflow: auto;
    .form-upper {
      & > div {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .form-lower {
      margin: 0;
      border: 1px solid #e7e7ed;
      padding-top: 24px;
    }
    .placeholder {
      position: relative;
      &::after {
        position: absolute;
        right: 10px;
        top: 35px;
        content: attr(data-placeholder);
        opacity: 0.4;
      }
    }
  }
`;

export default LocationHierarchyStyled;
