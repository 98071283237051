import { compose } from 'recompose';
import MerchandiseDevice from './MerchandiseDevice';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  CREATE_MERCHANDISE_DEVICE, UPDATE_MERCHANDISE_DEVICE, TOGGLE_STATE, query, mutation
} from './API';

const ComposedMerchandiseDevice = compose(
  CREATE_MERCHANDISE_DEVICE,
  UPDATE_MERCHANDISE_DEVICE,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(MerchandiseDevice);

export default ComposedMerchandiseDevice;
