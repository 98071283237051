const invalid_error_message = 'Please Enter valid 2FA code';
const empty_error_message = 'Please Enter 2FA code';

const twoFaList = [
  { id: 0, title: 'Install the Google Authenticator app on your Android or iOS device.' },
  { id: 1, title: 'Launch the app and click Begin and Select Scan a QR Code.' },
  { id: 2, title: 'Scan the code shown below to Get Started.' },
  { id: 3, title: 'Enter the code that you see on the App.' },
];

export {
  twoFaList,
  invalid_error_message,
  empty_error_message,
};
