import gql from 'graphql-tag';

const GET_TAS_DETAIL = gql`
  query GetTasuserStateDetail($userId: Int!, $filter: FilterInput) {
    getTasuserStateDetail(userId: $userId,filter: $filter) {
      user_id
      name
      status
      scheduled
      successful
      unsuccessful
      remaining
      productivity
      target
      achievementValue:sales_order_received
      outletDetails: outlet_details {
        title:outlet
        target:pfd_target
        order_time  
        pfd_target
        category
        status
        reason
        sync_time:order_time
        notes
        achievementValue:received
        reason
        callImage: call_images {
          src: images
          thumbnail: images
        }
      }
      
    }
  }
`;

const query = {
  getUserDetailReport: GET_TAS_DETAIL,
};

export { query };
