import styled from 'styled-components';

const OutletsStyled = styled.div`
  .route-wrap {
    .popover-content {
      h3 {
        margin: 0 0 8px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        color: #272833;
        border-bottom: 1px solid #e7e7ed;
        padding-bottom: 7px;
        cursor: pointer;

        &:hover {
          color: #0b5fff;
        }
      }

      .popover-btn {
        display: block;
        background: #0b5fff;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        padding: 6px 20px;
        border-radius: 4px;
        margin-top: 14px;
        cursor: pointer;
      }
    }

    .gm-ui-hover-effect {
      width: auto !important;
      height: auto !important;
      top: 8px !important;
      right: 8px !important;

      img {
        margin: 0 !important;
        width: 16px !important;
        height: 16px !important;
        transform: scale(1.2);
      }
    }

    .route-manage-wrap {
      padding: 24px 48px;

      .route-manage-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
      }

      .action-content {
        display: flex;
        align-items: center;

        input[type='text'] {
          height: 32px;
          line-height: 22px;
        }
      }

      /* .form-input {
        margin: 0 16px 0 0;
      } */

      label:empty {
        display: none;
      }

      h2 {
        color: #272833;
        font-weight: 600;
        border: none;
        margin: 0;
        padding: 0;
        line-height: 21px;
        font-size: 20px;
      }
    }

    .route-manage-content {
      .manage-outlet-content {
        display: flex;
      }

      .columns {
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .column {
        /* background: #fff; */
        /* width: 357px; */
        width: 32%;
        &:last-child {
          margin-right: 0px;
        }

        .icons {
          padding: 16px;
          background: #f0f5ff;
          margin: -8px 12px 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          > span {
            display: inline-block;
            margin-right: 16px;
            color: #6b6c7e;
            cursor: pointer;

            &:first-child {
              transform: rotate(90deg);
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }

        .route-content-selected {
          .icons {
            display: flex;
            justify-content: space-between;
            margin: -8px 1px 8px 0px;
            /* width: calc(100% - 32px); */
            /* border-radius: 0 0 4px 4px; */
            box-shadow: 0 0 4px 0 rgba(39, 40, 51, 0.12);
          }

          h6 {
            display: inline-block;
            margin: 0 0 0 8px;
            font-size: 12px;
            font-weight: 600;
          }

          .rotate {
            transform: rotate(90deg);
            margin-right: 16px;
          }

          .icon {
            color: #6b6c7e;
            cursor: pointer;
          }
        }

        .item {
          box-shadow: 0 2px 4px 0 rgba(39, 40, 51, 0.12);
          padding: 16px;
          border-radius: 4px;
          font-size: 12px;
          font-weight: 600;
          color: #272833;
          outline: none;
          width: calc(100% - 16px) !important;

          > div {
            margin-right: 16px;
            top: -8px;
          }

          .route-drag-title {
            margin-right: 0;
            top: 0;
          }

          &.active {
            background: #f0f5ff;
          }
        }

        .column-title {
          font-size: 12px;
          font-weight: 600;
          color: #6b6c7e;
          line-height: 15px;

          .label {
            height: 18px;
            display: inline-block;
            background: #eef2fa;
            border-radius: 30px;
            margin-left: 8px;
            font-size: 10px;
            padding: 0 8px;
            line-height: 17px;
          }
        }
      }

      .route-drag-title {
        h6 {
          margin: 0 0 8px;
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          a {
            text-decoration: none;
            color: #6b6c7e;
          }
        }

        span {
          font-size: 10px;
          line-height: 10px;
          font-weight: normal;
        }

        .number {
          color: #6b6c7e;
          margin-right: 8px;
        }

        .sub {
          color: #a7a9bc;
          position: relative;

          &:empty {
            &:before {
              display: none;
            }
          }

          &:before {
            content: '';
            display: inline-block;
            background: currentColor;
            margin-right: 4px;
            width: 2px;
            height: 2px;
            border-radius: 50%;
            position: relative;
            top: -2px;
          }
        }
      }

      .route-status {
        margin-bottom: 8px;
        padding: 16px;
        border-bottom: 1px solid #e7e7ed;
        display: flex;
        align-items: center;
        background: white;
        border-radius: 4px 4px 0 0px;

        > span {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: 16px;
        }
      }
      [data-rbd-draggable-id='assigned'] {
        .route-status {
          > span {
            background: rgba(255, 95, 95, 0.1);
            color: #ff5f5f;
          }
        }
      }

      [data-rbd-draggable-id='other'] {
        .route-status {
          > span {
            background: rgba(175, 120, 255, 0.1);
            color: #af78ff;
          }
        }
      }

      [data-rbd-draggable-id='unassigned'] {
        .route-status {
          > span {
            background: rgba(95, 200, 255, 0.1);
            color: #5fc8ff;
          }
        }
      }

      .modal-inner {
        max-width: 395px;

        .small-dialog {
          .title {
            font-size: 14px;
            line-height: 21px;
            color: #272833;
            display: block;
            margin-bottom: 24px;
          }

          label {
            color: #272833;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 4px;
            display: block;
          }
        }
      }
    }
  }
  .route-manage-wrap {
    .action-content {
      & > div {
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`;
export default OutletsStyled;
