import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_PSR_LIST = gql`
  query getPsrReport($offset: Int, $limit: Int, $filter: FilterInput) {
    getPsrReport(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        outlet_id
        outlet
        channel
        category
        distributor
        route
        town
        brand
        sku
        dse
        quantity
        gross_amount
        net_amount
        promotion_amount
        promotion_title
        discount_amount
        bill_discount_titles
        isRetailerOrder
      }
      count
    }
  }
`;

const query = {
  getPSRList: GET_PSR_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
