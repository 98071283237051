import gql from 'graphql-tag';

const DSE_USER_FRAGMENT = gql`
  fragment DSEUserFragment on userDetailReport {
    id
    target
    route
    achievementVolume: achievement_volume
    achievementValue: achievement_value
    goldenPoints: golden_points
  }
`;

const GET_DSE_DETAIL = gql`
  query getUserDetailReport($userId: Int!, $routeId: Int, $filter: FilterInput) {
    getUserDetailReport(userId: $userId, routeId: $routeId, filter: $filter) {
      name
      date_details
      route_id
      outletDetails: outlet_details {
        id
        target
        achievementVolume: achievement_volume
        achievementValue: achievement_value
        goldenPoints: golden_points
        title
        category
        status
        reason
        sync_time
        notes
        route_sequence
        callImage: call_images {
          src: images
          thumbnail: images
        }
      }
      scheduled
      successful
      unsuccessful
      remaining
      productivity
      ...DSEUserFragment
    }
  }
  ${DSE_USER_FRAGMENT}
`;

const query = {
  getUserDetailReport: GET_DSE_DETAIL,
};

export { query };
