import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_PRODUCTIVE_COVERAGE = gql`
  query getCoverageDetails($type: coverageType!, $filter: FilterInput) {
    getCoverageDetails(type: $type, filter: $filter) {
      ##rows {
      coverage
      details {
        id
        name
        parent_id
        parent_name
        coverage
        active
        active_percent
      }
      ##  }
      ## count
    }
  }
`;

const query = {
  getProductiveCoverage: GET_PRODUCTIVE_COVERAGE,
};
const mutation = {
  downloadReport: DOWNLOAD_REPORT
}

export {
  query, mutation, DOWNLOAD_REPORT
};
