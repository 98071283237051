import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import {
  CustomSelect, Input
} from '../../../../../../components';
import { CheckBox } from '../../../../../../v4/components'
import withLoading from '../../../../../../utils/composition/withLoading';
import withImageSlider from '../../../../../../utils/composition/withImageSlider';
import { ImageColumn } from '../../../../../../utils/tableUtils';
import { SKU_STATUS } from '../../../config';
import { getCheckboxStatus } from '../../../../../common/HelperFunctions';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  stockList: PropTypes.instanceOf(Array),
  onPrimaryCheckBoxClick: PropTypes.func,
  onSecondaryCheckBoxClick: PropTypes.func,
  note: PropTypes.string,
  handleSliderOpen: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  selectedIds: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  stockList: [],
  note: '',
  onPrimaryCheckBoxClick: () => null,
  onSecondaryCheckBoxClick: () => null,
  onInputChange: () => null,
  selectedIds: [],
};

const ReplacementStockForm = ({
  data,
  stockList,
  note,
  onPrimaryCheckBoxClick,
  onSecondaryCheckBoxClick,
  handleSliderOpen,
  onInputChange,
  selectedIds,
}) => (
  <div className='split-form-container'>
    <div className='split-header'>
      <div className='stock'>
        <Row>
          <Col md={3}>
            <div>
              <label>Outlet</label>
              <span>{data.outlet}</span>
            </div>
          </Col>
          <Col md={3}>
            <div>
              <label>Route</label>
              <span>{data.route}</span>
            </div>
          </Col>
          <Col md={3}>
            <div>
              <label>DSE</label>
              <span>{data.dse}</span>
            </div>
          </Col>
          <Col md={3}>
            <div>
              <label>Note</label>
              <span>{note}</span>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div className='split-body'>
      <div className='table-wrap'>
        <table>
          <thead>
            <tr>
              {stockList.length > 0 && (
                <th>
                  <CheckBox
                    checked={stockList.length === selectedIds.length}
                    onCheckBoxClick={(e) => onPrimaryCheckBoxClick(e.target.checked)}
                  />
                </th>
              )}
              <th>SKU</th>
              <th className='text-right'>Quantity</th>
              <th>Type</th>
              <th>Images</th>
              <th>Status</th>
              <th className='text-right'>Replace Quantity</th>
              <th>Replace With</th>
            </tr>
          </thead>
          <tbody>
            {stockList.map((stock, index) => {
              const filteredSkus = stock.skus.filter(
                (sku) => sku.stockBalance && sku.stockBalance >= stock.replacedQuantity,
              );
              return (
                <tr key={`sr-${stock.id}`}>
                  <td key={`sr-checkbox-${stock.id}`} className='checks'>
                    <CheckBox
                      checked={getCheckboxStatus(selectedIds, stock.id)}
                      onCheckBoxClick={(e) => onSecondaryCheckBoxClick(stock.id)}
                    />
                  </td>
                  <td key={`sr-title-${stock.id}`}>{stock.skuTitle}</td>
                  <td key={`sr-quantity-${stock.id}`} className='text-right'>
                    {stock.quantity}
                  </td>
                  <td key={`sr-type-${stock.id}`}>{stock.reason.toLowerCase()}</td>
                  {stock.images.length > 0 ? (
                    <ImageColumn
                      keyTitle={`sr-image-${stock.id}`}
                      imageList={stock.images}
                      onImageClick={handleSliderOpen}
                    />
                  ) : (
                    <td />
                  )}
                  <td key={`sr-stock-status-${stock.id}`}>
                    <span className={`${SKU_STATUS[stock.stockStatus]} stock-label`}>
                      {SKU_STATUS[stock.stockStatus]}
                    </span>
                  </td>
                  <td key={`sr-replaced-quantity-${stock.id}`} className='text-right'>
                    <Input
                      name='replacedQuantity'
                      type='number'
                      rule='isInt'
                      enableValidation
                      value={stock.replacedQuantity}
                      enableErrorDisplay
                      argument={{
                        min: 1,
                        max: stock.quantity,
                        allow_leading_zeroes: false,
                      }}
                      ref={(ref) => (stock.refsObj.replacedQuantity = ref)}
                      displayMessage={false}
                      onChange={(event) => onInputChange(event, index)}
                    />
                  </td>
                  <td key={`sr-replaced-sku-${stock.id}`}>
                    <CustomSelect
                      small
                      enableValidation
                      options={filteredSkus}
                      className='custom-select'
                      placeholder='Select SKU'
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      ref={(ref) => (stock.refsObj.replacedSkuId = ref)}
                      enableErrorDisplay
                      onChange={(event) => {
                        const formattedEvent = {
                          formattedValue: event.id,
                          target: {
                            name: 'replacedSkuId',
                          },
                        };
                        onInputChange(formattedEvent, index, event);
                      }}
                      displayMessage={false}
                      value={filteredSkus.filter(({ id }) => id === stock.replacedSkuId)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

ReplacementStockForm.propTypes = propTypes;

ReplacementStockForm.defaultProps = defaultProps;

const ReplaceStockFormWithLoading = withLoading(ReplacementStockForm);

export default withImageSlider(ReplaceStockFormWithLoading);
