import styled from 'styled-components';

const CoverageStyled = styled.div`
  .distributor,
  .channel {
    table {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
`;
export default CoverageStyled;
