import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getDisplayName } from './component';
import { PhotoSwipeGallery } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';

// assumed each mapper is mounted on every change.
// it mapper config change directly w/o mounting use derive state from props.

export default function withImageSlider(SourceComponent) {
  class ImageSlider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        imageList: [],
        openSlider: false,
      };
      this.options = {
        index: 0,
        escKey: false,
        showHideOpacity: true,
        timeToIdle: 4000,
        shareEl: false,
        // ui option
      };
    }

    handlePhotoSwipeOpen = (currentIndex, list = []) => {
      this.options = this.getOptions(currentIndex);
      this.setState({
        openSlider: true,
        imageList: list,
      });
    };

    handlePhotoSwipeClose = () => {
      const { openSlider } = this.state;
      this.setState({
        openSlider: !openSlider,
        imageList: [],
      });
    };

    getOptions = currentIndex => {
      return { ...this.options, index: currentIndex };
    };

    render() {
      const { imageList, openSlider } = this.state;

      const newProps = {
        handleSliderOpen: this.handlePhotoSwipeOpen,
        ...this.props,
      };

      return (
        <Fragment>
          <SourceComponent {...newProps} />
          {imageList.length > 0 && (
            <PhotoSwipeGallery
              items={imageList}
              isOpen={openSlider}
              options={this.options}
              close={this.handlePhotoSwipeClose}
            />
          )}
        </Fragment>
      );
    }
  }

  ImageSlider.displayName = `withImageSlider(${getDisplayName(SourceComponent)})`;

  return ImageSlider;
}
