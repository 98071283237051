import React from 'react'
import styled from 'styled-components';
import { Input } from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';
import { groupBy } from '../../../../common/HelperFunctions';

const MultiplePromotionForm = ({ data, onInputChange, handleMultipleFreeSKU, inputCallBack }) => {
  const  groupedArray = groupBy(data?.promotionSkus,'brandTitle');
  const groupedKeyArr =Object.keys(groupedArray);
  return (
    <MultiplePromotionWrap>
      <h3>{data?.promotionTitle}</h3>
      <div>
        {
          groupedKeyArr.map((key,index) => (
            <div className ={`sku sku${index}`}>
              {groupedArray[key].map((sku,i) =>(
                <>
                  <div className='promotion-sku'>
                    <div className='sku-image'>
                      {sku.images?.[0] && (<img src={sku.images?.[0]?.image}  className='thumbnail-img' />)}
                    </div>
                    <div className='sku-detail'>
                      <h4>{sku.title}
                      {sku?.stockavailable?
                        <span className='in-stock-sku'>IN STOCK {sku?.balance}</span>
                        : null
                      }
                      </h4>
                      <div className='price-detail'>MRP: <span className='promotion-mrp'>{`Rs. ${sku.Rates?.[0].priceDetails?.mrp}`}</span>
          RLP: <span className='promotion-rlp'>{`Rs. ${sku.Rates?.[0].priceDetails?.rlp}`}</span>
                      </div>
                    </div>
                    <div className='sku-quantity'>
                      <Input
                        value={sku.quantity}
                        placeholder=''
                        name='quantity'
                        type='number'
                        onChange={(event) => onInputChange(event,'quantity', ['promotionSkus',[i],'quantity'],inputCallBack,sku.id)}
                      />
                    </div>

                  </div>
                </>
              ))}
            </div>
          ))
        }
      </div>
      <div className='free-sku'>
        {data?.freeSku?.length > 0 && (<h3>Free SKU</h3>)}
        <ul>
          {data?.freeSku?.map((a,i) => (
            <li className=''>
              {a.title}
              <Input
                value={a.quantity}
                name='quantity'
                type='number'
                onChange={(e) => onInputChange(e,'quantity', ['freeSku',`${i}`,'quantity'],handleMultipleFreeSKU)}
              />
            </li>
          )
          )
          }
        </ul>
      </div>
    </MultiplePromotionWrap>
  )
}

const MultiplePromotionWrap = styled.div`
  h3{
    margin: 0px;
    border-bottom: 1px solid #E7E7ED;
    padding-bottom: 24px;
  }
  .sku{
    padding:0 10px;
    border-radius:4px;
    background-color:#F7F8F9;
    margin-top:24px;
    border:1px solid #E7E7ED;
  }

  .in-stock-sku {
    text-transform: uppercase;
    background-color: rgb(80,210,160);
    white-space: nowrap;
    margin-bottom: 8px;
    margin-left: 8px;
    color: rgb(255, 255, 255);
    font-size: 11px;
    line-height: 15px;
    padding: 3px 8px;
    border-radius: 3px;
    font-weight: 400;
    margin-left:8px;
  }

  .promotion-sku{
    display: flex;
    padding: 15px;
    padding-bottom: 6px;
    .sku-image{
      width:10%;
      border: 1px solid #CDCED9;
      border-radius: 8px;
      margin-right: 24px;
      overflow: hidden;
       height:77px;
       width: 72px;
      .thumbnail-img{
        width: 100%;
        height:77px;
        width: 72px;
        border-top-width:77px;
        border-radius: 4px;
        object-fit: cover;
        max-width: 100%;
        display: block;
      }
    }
    .sku-detail{
      width:80%;
      padding-right: 10px;
      h4{
        margin: 11px 0px 9px 0;
        line-height:19px;
      }
      .price-detail{
        font-size: 12px;
        color: #6B6C7E;
        line-height: 14px;
        .promotion-mrp{
          color: #393A4A;
          margin-right: 20px;
          font-weight: bold;
        }
        .promotion-rlp{
          color: #0B5FFF;
          font-weight: bold;
        }
      }
    }
    .sku-quantity{
      width: 10%;
      .form-input{
         padding: 14px 0;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
         margin: 0;
      }
      .form-input input{
         border:1px solid #80ACFF;
         border-radius:4px;
         background-color:#F0F5FF;
         height:24px;
         min-width:60px;
         width:60px;
         padding:5px;
         font-size:12px;
         color:#272833;
         text-align:right;
       }
    }
    }
    .free-sku{
        padding:30px 0 30px 0;
        h3{
        margin: 0;
        font-size: 16px;
        line-height: 19px;
        color: #272833;
        margin-bottom: 16px;
        }
        ul{
          li{
            border:1px solid #E7E7ED;
            border-radius:4px;
            padding:18px;
            font-size:14px;
            line-height:21px ;
            color:#272833;
            display:flex;
            justify-content:space-between;
            align-items:center;
            position:relative;
            margin-bottom:8px;
            &:last-child{
              margin-bottom:0;
            }
            &.active{
              border:1px solid #5ACA75;
              .check-circle{
                display:block;
              position:absolute;
              color:#287D3C;
              top: -6px;
              right: -8px;
            }
            }
            .multi-promo-icon{
                display: unset;
                transform: rotate(90deg);
                height: 12px;
                cursor: pointer;
              }
            .check-circle{
              display:none;
            }
            .promo{
              width:80%;
            }
            .promo-cost{
              color:#272833;
              font-weight:500;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            .form-input input{
              border:1px solid #80ACFF;
              border-radius:4px;
              background-color:#F0F5FF;
              height:24px;
              min-width:60px;
              width:60px;
              padding:5px;
              font-size:12px;
              color:#272833;
              text-align:right;
            }
          }
        }
      }
`;
const LoadingMultiplePromotionForm = withLoading(MultiplePromotionForm);

export default LoadingMultiplePromotionForm;
