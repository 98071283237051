import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { breadCrumbConfig, filterConfig, title } from './config';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import {isError } from '../../../common/HelperFunctions';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { getPermissionForTripWiseCollection } from '../../../base/permission';
import * as queryService from '../../../base/query.service';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import View from './View';
import withAlert from '../../../../utils/composition/withAlert';
import { labelConfig, TableHeader } from './tableConfig';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import { clone } from '../../../../utils/objectProcessor';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../../common/HelperFunctions';
import { storageKeys } from '../../../sales/orderProcessing/config';
import { DOMAIN } from '../../../../data/enums/config';
import {dataMapper,TRIP_WISE_COLLECTION_ENUM,formConfig,paymentListMapper} from './config'
import DialogFormWrapper from '../../../common/DialogFormWrapper';
import {DialogStyled} from './TripWise.Styled'

const propTypes = {
  downloadReport: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
  payments: PropTypes.func.isRequired,
};

const defaultProps = {
  downloadReport: () => null,
  serverResponseWaiting: false,
};

class TripWiseCollection extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      tableInfo: labelConfig,
      dialog: {
        type: '',
        element: '',
      },
      tableRowId:[],
    };
    this.permission = getPermissionForTripWiseCollection();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
    TableHeader({ tableConfig: labelConfig, dynamicHeader: true });
  }


  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.TRIP_WISE_COLLECTION
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.TRIP_WISE_COLLECTION);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  resetTableData = () => {
    const { data, tableRowId } = this.state;

    if(tableRowId.length > 0){
      this.setState({tableRowId: []});
    }

    if(data.list.length > 0){
      this.setState({data: {
        list: [],
        total: 1,
      }})
    }
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getTripWiseList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    this.resetTableData();

    try {
      getTripWiseList(
        {
          limit: queryParameters.pagination.limit,
          offset,
          filter: {
            filters: apiFilterProcessor(queryParameters.filter),
            dateRange: queryParameters.date,
            queryString: queryParameters.search,
          },
        },
        {
          handleSuccess: response => {
            const tripWiseCollections = (response.data.tripWiseCollections && response.data.tripWiseCollections.rows) || [];
            const mappedData = dataMapper(tripWiseCollections);
            data.list = mappedData;
            data.total = (response.data.tripWiseCollections && response.data.tripWiseCollections.count) || 0;
            if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
            this.setState({data});
          },
          handleError: error => this.onAPIRequestFailure(error),
        },
      );

    } catch (error) {
    }   
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  columnSelectChange = data => {
    this.setState({
      tableInfo: data,
    });
  };

  handleFormSubmit = () => {
    const {dialog:{type,element}} = this.state
    if(type == TRIP_WISE_COLLECTION_ENUM.RECEIVE){
    const tripId = element?.tripId;
    const { receiveCollectionByTripId,displayAlert } = this.props;

    receiveCollectionByTripId(
      {
        tripId,
      },
      {
        handleSuccess: response => {
          if (isError(response)) {
            this.onAPIRequestFailure(response.errors[0]);
          }
          displayAlert(ALERT_TYPE.SUCCESS, 'Trip Received Successfully');
          this.resetDialog();
          this.loadTableData();
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  }
  };

  handleRowClick = (rowData) => {
    const { getPaymentListByTripId } = this.props;
    const { tableRowId,data, } = this.state;
   
    const tripId = rowData?.tripId;

    if(!tableRowId.includes(tripId)){

      this.setState((prevState) => ({
        tableRowId: [...prevState.tableRowId, tripId]
      }));

      getPaymentListByTripId(
        {
          tripId,
        },
        {
          handleSuccess: response => {
            const paymentListData = paymentListMapper(response?.data);
            const dataCopy = [...data.list];
            let dataWithCurrentTripId = dataCopy?.find(((item)=> item?.tripId === tripId));

            if(dataWithCurrentTripId){
              dataWithCurrentTripId['total'] = paymentListData?.total;
              dataWithCurrentTripId['details'] = paymentListData?.details;         
            }

            const updatedData = dataCopy?.map(item => (item?.tripId === tripId ? dataWithCurrentTripId : item));
            if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
            this.setState({data});

            this.setState((prevState) => ({
              data: {...prevState.data, list:updatedData}
            }));
          },
          handleError: error => this.onAPIRequestFailure(error),
        },
      );

    }

  }

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };


  render() {
    const { data, queryParameters, tableInfo,filterMenu,dialog} = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="return-create">
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                breadCrumb={breadCrumbConfig}
                config={{
                  title,
                  download: false,
                  filter: true,
                  upload: false,
                  date: true,
                  search: true,
                  columSelect: true,
                }}
                downloadConfig={{
                  domain: DOWNLOAD_DOMAIN.TRIP_WISE_COLLECTION,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList: filterMenu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                labelConfig={tableInfo}
                queryParameters={queryParameters}
                columnSelectChange={this.columnSelectChange}
                controlDisplay={this.controlDisplay}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
              />
            </PanelStyled>
          </div>
          <DialogStyled>
                {
        dialog.type &&
          <DialogFormWrapper
              formConfig={formConfig[dialog.type]}
              handleFormSubmit={this.handleFormSubmit}
              onDialogCancel={this.resetDialog}
              disableDialogClose={false}
              disableOk={false}
              type={dialog.type}
              withOutPadding={true}
              renderDialog={({ }) => (
                <>
                {(dialog.type === TRIP_WISE_COLLECTION_ENUM.RECEIVE && (
                  <p className='dialog-text'>Are you sure you want to receive collection of trip
                  {' '}
                  <span className='trip-id'>
                  {dialog?.element?.tripName}
                  </span>
                  {' '}
                  ?
                  </p>)
                )}
                </>
              )}
              />
            }
          </DialogStyled>

          <Fragment>
            <div className="section-content table-present">
              <View
                data={data}
                pagination={queryParameters.pagination}
                loading={serverResponseWaiting}
                onPageChange={this.basePaginationService.onPageSelect}
                tableConfig={tableInfo}
                handleIconClick={this.handleIconClick}
                permission={this.permission}
                handleRowClick={this.handleRowClick}
              />
            </div>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}

TripWiseCollection.propTypes = propTypes;

TripWiseCollection.defaultProps = defaultProps;

export default withAlert()(TripWiseCollection);
