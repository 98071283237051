import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndComma, fixedFloatAndCommas } from '../../../../utils/conversion';

const propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.number.isRequired,
  label: PropTypes.string,
  numberPostfix: PropTypes.bool,
};

const defaultProps = {
  number: null,
  label: '',
  numberPostfix: false,
};

const check = (number) => (number === null || isNaN(number) ? 0 : fixedFloatAndComma(number));

const Number = ({ number, label, size, numberPostfix }) => (
  <span style={{ fontSize: `${size}px` }}>
    {numberPostfix ? (isNaN(parseInt(number, 10)) ? 0 : fixedFloatAndCommas(number)) : check(number)}
    <span style={{ fontSize: `${size - 10}px` }}>{label}</span>
  </span>
);

Number.propTypes = propTypes;

Number.defaultProps = defaultProps;

export default Number;
