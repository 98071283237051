import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckBox,defaultMenuConfigList, menuAction, tableHeaderWithCheckBox
} from '../../../../../../v4/components';
import { EVENT_OPERATION } from '../../../../../../v4/constants/EventOperation';
import { PERMISSION_OBJ } from '../../../../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  doh:'doh',
  frequency:'frequency',
  actionHolders: 'actionHolders',
};

const menuConfigList = [defaultMenuConfigList[EVENT_OPERATION.UPDATE], defaultMenuConfigList[EVENT_OPERATION.DELETE]];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'ICO Group',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.doh,
    title: 'DOH (Days of Holding)',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.frequency,
    title: 'Frequency',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const weekDays = {
  7:'Sun',
  1:'Mon',
  2:'Tue',
  3:'Wed',
  4:'Thu',
  5:'Fri',
  6:'Sat',
};

const getFrequency=(frequency)=>{
  switch (frequency?.type) {
  case 'DAILY':
    return `${frequency?.type} / ${frequency?.time}`;
  case 'WEEKLY':
    return `${frequency?.type}/ ${frequency?.day?.map((a)=>weekDays[a])} / ${frequency?.time}`;
  default:
    return `${frequency?.type} / ${frequency?.date} / ${frequency?.time}`;
  }
}

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`${data.id}-check`} onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data?.icoGroups?.title}</td>;
  table[labelMappings.doh] = <td key={`${data.id}-doh`}>{data?.doh}</td>;
  table[labelMappings.frequency] = <td key={`${data.id}-frequency`}>{getFrequency(data?.frequency,data?.frequency?.type)}</td>;
  table[labelMappings.actionHolders] = (
    <td key={`${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {(permission.update||permission.delete)&&menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr key={data.id}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  checkedList: PropTypes.instanceOf(Array),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
