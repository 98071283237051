import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { OUTLET } from '../../../../../data/enums/Route';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils';
import { fileParser } from '../../../../../utils/parser';
import { breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Outlet',
    path: `/${OUTLET}`,
    active: true,
  },
];

const title = 'Outlet';

const getContactInfo = (contact) => {
  if (Array.isArray(contact)) return contact[0];

  return contact;
};

const formConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    file: element.file || {},
  }),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator([
    'title',
    'channel',
    'category',
    'contactName',
    'town',
    'latitude',
    'longitude',
    'panNumber',
    'landline',
    'whatsapp',
    'viber',
  ]),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const crudSuccess = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      ...data,
      Channel: data?.Category ? data?.Category?.Channel : { id: '', title: '' },
      contactInfo: data?.contactInfo ? getContactInfo(data?.contactInfo) : { name: '', phoneNumber: '' },
    }),
    responseName: 'updateRetailOutlet',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data?.file).then((file) => ({
        file,
        type: title,
      }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

// eslint-disable-next-line no-unused-vars
const apiTransformer = () => {};

const redMarkerColor = '#FF5F5F';
const greenMarkerColor = '#50D2A0';
const googleMapPoint1 = 30;
const googleMapPoint2 = 50;
const fillOpacity = 0.8;
const strokeWeight = 2;
const markerIconPath =
  'M24,4 C16.26,4 10,10.26 10,18 C10,28.5 24,44 24,44 C24,44 38,28.5 38,18 C38,10.26 31.74,4 24,4 Z M24,23 C21.24,23 19,20.76 19,18 C19,15.24 21.24,13 24,13 C26.76,13 29,15.24 29,18 C29,20.76 26.76,23 24,23 Z';
export {
};


export {
  crudSuccess, breadCrumb as breadCrumbConfig, formConfig,
  redMarkerColor,
  greenMarkerColor,
  googleMapPoint1,
  googleMapPoint2,
  markerIconPath,
  fillOpacity,
  strokeWeight,
};
