import styled from 'styled-components';

export default styled.section`
  border-top: 1px solid #cdced9;
  .search-wrapper {
    display: flex;
    flex-wrap: wrap;
    min-width: 32px;
    max-width: 250px;
    height: 32px;
    border: 1px solid #cdced9;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.5 s ease-in-out;
    margin: 1rem;
    &:hover {
      background-color: #f7f8f9;
      color: #272833;
      transition: all 0.3s ease-in-out;
    }
    &.bg-color {
    background-color: #f0f5ff;
    border: 1px solid #80acff;
    }
    .form-input {
      width: calc(100% - 32px);
      label {
        display: none;
      }
      input {
        height: 29px;
        border: 0;
        padding-right: 0;
        background-color: transparent;
      }
    }
    button.search {
      border: 0;
      background-color: transparent;
    }
    .icon {
      position: relative;
      top: -2px;
    }
  }
  
  table{
  tbody{
    tr.order-added {
        background-color: #EDF9F0;
        border-bottom: 1.1px solid #5ACA75;
      }

      tr > td > .form-input {
        input {
          max-width: 6rem;
          padding: 8px;
          height: 2rem;
        }
      }

      tr > td.green {
        color: #287D3C;
      }

      tr > td.red {
        color: #DA1414;
      }
    }
    .text-center{
      .simple-popup {
      display: inline-block;
      text-align: center;
      width: 100%;
      }
    }
    .simple-popup {
      width: fit-content;   
      .sbd-sku {
        margin-left: 8px;
        padding: 2px 8px;
        line-height: 18px;
        text-transform: uppercase;
        background-color: rgb(255, 115, 195);
        border-radius: 4px;
        font-size: 12;
        color: rgba(255,255,255);
        &.in-stock-sku {
          background-color: #50D2A0;
          white-space: nowrap;
        }
      }

      .filter-background {
        color: #0B5FFF;
        font-weight: 500;

        .header-dom {
          padding: 1rem 0;
        }
      }
      &.promotion {
        .filter-dropdown-card{
          right: -16px;
          top: 180%;
          left: unset;
          padding:0;
          .filter-label{
            padding:14.5px 12.5px;
          }
          .filter-content {
            padding:0;
            .available-promotions{
              padding: 16px 12.5px;
              padding-bottom: 24px;
              li{
                text-align: left;
                list-style: disc;
                margin-bottom:16px;
                font-size:11px;
                color:#2E384D;
                list-style-position: inside;
                &:last-child{
                  margin-bottom:0;
                }
              }
            }
            .arrow-up {
              top: -6px;
              right: 7%;
              &::before{
                content: '';
                height: 8px;
                width: 8px;
                top: 2.5px;
                right: -4px;
                position: absolute;
                transform: rotate(45deg);
                border-left: 1px solid rgb(231,231,237);
                border-top: 1px solid rgb(231,231,237);
              }
            }
          }
        }
      }
      .filter-dropdown-card {
        top:  -24px;
        left: 105%;
        right: 0;

        .filter-content {
          .arrow-left {
              top: 20%;
          }

          .purchase-history {
            .heading, .purchase-detail-row {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 1rem;

              .item-value {
                display: block;
                font-size: 12px;
                padding: .2rem 0;
                color: #000;
              }
            }

            .heading label {
              text-transform: uppercase;
              display: inline-block;
              color: #A7A9BC;
              font-size: 12px;
              margin-bottom: .5rem;
            }
          }
        }
      }
    }
  }

`;

