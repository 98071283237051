import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../v4/constants/successMessage';
import { salesForce as salesForceRoute } from '../../../../data/enums/Route';

export const title = 'Target Distribution';

export const breadCrumbConfig = [salesForceRoute];

export const crudRequestConfig = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.TARGET_DISTRIBUTION,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.CAMPAIGN,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};
