import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form,
} from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';
import { SingleDatePicker } from '../../../../../v4/components';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
};

const SbdForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay } = props;

  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Description'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Description'
      />
      <div className='single-date-picker'>
        <label>Start Date</label>
        <SingleDatePicker
          date={data.start}
          name='start'
          placeholder='Start Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name: 'start',
                },
                formattedValue: date,
              },
              '',
            )
          }
          labelContent='Start Date'
          maxDate={data.end}
        />
      </div>
      <div className='single-date-picker'>
        <label>End Date</label>
        <SingleDatePicker
          date={data.end}
          name='end'
          placeholder='End Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name,
                },
                formattedValue: date,
              },
              '',
            )
          }
          labelContent='End Date'
          minDate={data.start}
        />
      </div>
    </Form>
  );
};

SbdForm.propTypes = propTypes;

SbdForm.defaultProps = defaultProps;

const ComposedSbdForm = withLoading(SbdForm);

export default ComposedSbdForm;
