import { compose } from 'recompose';
import {
  query, mutation, DOWNLOAD_REPORT, CREATE_WAREHOUSE_LEVEL_DETAIL, EDIT_WAREHOUSE_LEVEL_DETAIL
} from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { TOGGLE_STATE } from '../../common/GqlAPI';
import WareHouseMgmt from './WarehouseMgmt';

const ComposedWareHouse = compose(
  DOWNLOAD_REPORT,
  CREATE_WAREHOUSE_LEVEL_DETAIL,
  EDIT_WAREHOUSE_LEVEL_DETAIL,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(WareHouseMgmt);

export default ComposedWareHouse;
