import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CoverageView from './View';
import PageHeader from '../../base/PageHeader';
import { computeFilterConfig, breadCrumbConfig } from './config';
import { TableBody, TableHeader } from './tableConfig';
import { apiFilterProcessor } from '../../../utils/api';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { COVERAGE_TYPE, DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import withAlert from '../../../utils/composition/withAlert';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { PRODUCTIVE_COVERAGE_DETAIL } from '../../../data/enums/Route';
import CoverageStyled from './CoverageStyled';
import { getUser } from '../../../data/dao';
import { controlFilterDisplay, getDistributorFilteredViewList, isError } from '../../common/HelperFunctions';
import * as downloadService from '../../base/download.service';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getProductiveCoverage: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class ProductiveCoverage extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        coverage: 0,
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
        type: COVERAGE_TYPE.SUB_D,
      },
      filterConfig: computeFilterConfig(COVERAGE_TYPE.SUB_D),
    };
    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.userInfo = getUser();
    this.viewList = getDistributorFilteredViewList(this.userInfo);
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { queryParameters } = this.state;
    if (prevState.queryParameters.type !== queryParameters.type) {
      queryParameters.filter = queryService.baseQueryParameters.filter;
      this.setState({ filterConfig: computeFilterConfig(queryParameters.type), queryParameters });
    }
  }

  loadTableData = () => {
    const { queryParameters, data } = this.state;
    const { getProductiveCoverage } = this.props;
    getProductiveCoverage(
      {
        type: queryParameters.type,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          const { getCoverageDetails = {} } = response.data;
          data.list = (getCoverageDetails && getCoverageDetails.details) || [];
          data.total = (getCoverageDetails && getCoverageDetails.details.length) || 0;
          data.coverage = getCoverageDetails?.coverage || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ data });
        },
        handleError: err => {
          console.log(err);
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleTableRowClick = (type, row) => {
    const { history } = this.props;
    switch (type) {
      case COVERAGE_TYPE.CHANNEL:
      case COVERAGE_TYPE.SUB_D:
        row.id && history.push(`/${PRODUCTIVE_COVERAGE_DETAIL}/${row.id}`, { title: row.name, type, id: row.id });
        break;
      default:
        break;
    }
  };

  handleDownloadClick = () => {
    const { downloadReport, displayAlert } = this.props;
    const { queryParameters } = this.state;
    downloadReport(
      {
        input: {
          domain: DOWNLOAD_DOMAIN.OUTLET_COVERAGE,
          reportType: 'csv',
          filter: {
            filters: apiFilterProcessor(queryParameters.filter),
            queryString: queryParameters.search,
            dateRange: queryParameters.date,
          },
        },
      },
      {
        handleSuccess: response => {
          downloadService.resolver(response.data.downloadReport.file);
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  render() {
    const { data, queryParameters, display, filterConfig } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <CoverageStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: `Productive Coverage ${data.coverage ? `: ${fixedFloatAndCommas(data.coverage)}%` : ''}`,
                create: false,
                download: true,
                filter: controlFilterDisplay(filterConfig),
                search: true,
                date: true,
                singleDate: false,
                viewType: true,
              }}
              filter={{
                updateMenu: true,
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              viewTypeConfig={{
                value: queryParameters.type,
                list: this.viewList,
              }}
              handleViewTypeChange={this.basePaginationService.handleViewTypeChange}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDownloadClick={this.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <CoverageView
            data={data}
            pagination={queryParameters.pagination}
            handleRowClick={this.handleTableRowClick}
            loading={serverResponseWaiting}
            viewType={queryParameters.type}
            onPageChange={this.basePaginationService.onPageSelect}
            TableHeader={TableHeader}
            TableBody={TableBody}
          />
        </div>
      </CoverageStyled>
    );
  }
}

ProductiveCoverage.propTypes = propTypes;

ProductiveCoverage.defaultProps = defaultProps;

export default withAlert()(ProductiveCoverage);
