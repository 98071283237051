import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Confirmation from './DialogConfirmation';
import { EVENT_OPERATION } from '../../data/enums/EventOperation';
import { updateObjectAttribute, clone } from '../../utils/objectProcessor';
import { DialogBody, DialogFooter, DialogHeader } from '../../components';
import { Badge, Icon ,Button} from '../../v4/components';

const propTypes = {
  render: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  onDialogOpen: PropTypes.func,
  onDialogSubmit: PropTypes.func,
  onDialogCancel: PropTypes.func,
  renderDialog: PropTypes.func,
  replicateRedirectUrl: PropTypes.func,
  checkedListLength: PropTypes.number,
  refsObj: PropTypes.instanceOf(Object),
  fileUploadRefs: PropTypes.instanceOf(Object),
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    validationField: PropTypes.arrayOf(PropTypes.string),
    validationRequired: PropTypes.bool,
  }),
  fileConfig: PropTypes.shape({
    mapper: PropTypes.func,
    validationField: PropTypes.arrayOf(PropTypes.string),
    validationRequired: PropTypes.bool,
  }),
  footer: PropTypes.bool,
  replicateRedirect: PropTypes.bool,
  withOutPadding: PropTypes.bool,
  subTitle: PropTypes.string,
};

const defaultProps = {
  title: '',
  refsObj: {},
  children: null,
  fileUploadRefs: {},
  render: () => null,
  checkedListLength: 0,
  onDialogOpen: () => null,
  replicateRedirectUrl: () => null,
  renderDialog: () => null,
  onDialogSubmit: () => null,
  onDialogCancel: () => null,
  footer: false,
  withOutPadding: false,
  replicateRedirect: false,
  subTitle: '',
  formConfig: {
    mapper: () => ({}),
    validationFieldList: [],
    validationRequired: true,
  },
  fileConfig: {
    mapper: () => ({}),
    validationFieldList: [],
    validationRequired: true,
  },
};

class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    const {
      formConfig: { mapper: formMapper },
    } = this.props;

    this.state = {
      type: '',
      details: formMapper({}),
      show: false,
      enableErrorDisplay: false,
    };
  }

  getFormValidationStatus = () => {
    const { refsObj, fileUploadRefs } = this.props;
    const { type } = this.state;
    const validationField = type === EVENT_OPERATION.UPLOADED || type === EVENT_OPERATION.UPLOAD || type === EVENT_OPERATION.DEBOARD_OUTLET
      ? Object.values(fileUploadRefs)
      : Object.values(refsObj);


    return !validationField.find(item => {
      if (typeof item !== 'string') return item ? item.getValidState() === false : true;
    });
  };

  // event: event received from dom
  // firstParam: first Level Parameters
  // paramList: hierarchy in nestedObject : ['name', 'children', 'value']
  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { details } = this.state;
    if (!firstParam) {
      details[event.target.name] = event.formattedValue;
      this.setState(details);
    } else if (paramList.length === 0) {
      details[firstParam][event.target.name] = event.formattedValue;
      this.setState(details);
    } else {
      const detailsReplica = clone(details);
      const updatedDetails = updateObjectAttribute(detailsReplica, paramList, event.formattedValue);
      this.setState({ details: updatedDetails });
    }
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { details } = this.state;
    const detailsReplica = clone(details);
    const updatedDetails = updateObjectAttribute(detailsReplica, parameterRef, value);
    this.setState({ details: updatedDetails }, () => callBack(parameterRef[parameterRef.length - 1], value));
  };

  handleFileUpload = e => {
    const { details } = this.state;
    details.file = e.target.files[0];
    this.setState({ details });
  };

  handleAutoCompleteChange = (value, fieldName) => {
    const { details } = this.state;
    details[fieldName] = value;
    this.setState({ details });
    // this.setState({ [key]: keyValue });
  };

  onSubmit = () => {
    const { details, type } = this.state;
    const valid = !this.isConfirmationType(type) && type !== '' ? this.getFormValidationStatus() : true;
    if (valid) {
      const { onDialogSubmit } = this.props;
      onDialogSubmit(type, details);
      this.resetDialog();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  onCancel = () => {
    this.resetDialog();
  };

  onItemClick = (type = '', element = {}, callBack = () => null) => {
    const {
      formConfig: { mapper: formMapper },
      replicateRedirect,
      replicateRedirectUrl,
    } = this.props;
    const {
      fileConfig: { mapper: fileMapper },
    } = this.props;
    const details = type === EVENT_OPERATION.UPLOADED || type === EVENT_OPERATION.UPLOAD || type === EVENT_OPERATION.DEBOARD_OUTLET
      ? fileMapper(element)
      : type === EVENT_OPERATION.DELETE
        ? element
        : formMapper(element);

    if (replicateRedirect && type === EVENT_OPERATION.REPLICATE) {
      replicateRedirectUrl(element.id);
    } else {
      this.setState(
        {
          details,
          type,
          show: true,
          enableErrorDisplay: false,
        },
        () => callBack(details),
      );
    }
  };

  getViewType = newProps => {
    const { type } = this.state;
    const { renderDialog, checkedListLength } = this.props;
    if (this.isConfirmationType(type)) {
      return Confirmation(type, checkedListLength);
    }

    return renderDialog(newProps);
  };

  isConfirmationType = type =>
    type === EVENT_OPERATION.DELETE ||
    type === EVENT_OPERATION.BULK_DELETE ||
    type === EVENT_OPERATION.CANCEL ||
    type === EVENT_OPERATION.UPDATE_STATUS ||
    type === EVENT_OPERATION.REMOVE;

  resetDialog = () => {
    const {
      formConfig: { mapper: formMapper },
    } = this.props;
    const details = formMapper({});
    this.setState({
      details,
      show: false,
    });
  };

  render() {
    const {
      render: renderFn,
      children: child,
      renderDialog,
      title,
      footer,
      subTitle,
      type: typeForm,
      withOutPadding,
      titleWithouttype,
      ...props
    } = this.props;

    const { details, show, type, enableErrorDisplay } = this.state;
    const {
      handleInputChange,
      onCancel,
      onSubmit,
      onItemClick,
      handleDropDownChange,
      handleFileUpload,
      handleAutoCompleteChange,
    } = this;
    const newProps = {
      show,
      handleDialogInputChange: handleInputChange,
      handleDialogDropDownChange: handleDropDownChange,
      handleFileUploadChange: handleFileUpload,
      handleDialogAutoCompleteChange: handleAutoCompleteChange,
      onDialogCancel: onCancel,
      onDialogSubmit: onSubmit,
      onDialogItemClick: onItemClick,
      dialogData: details,
      enableErrorDisplay,
      type,
      ...props,
    };

    const transformTypeToModelInnerClass = (modalType) => {
      if (modalType) {
        if (modalType === EVENT_OPERATION.UPLOADED
        || modalType === EVENT_OPERATION.UPLOAD || type === EVENT_OPERATION.DEBOARD_OUTLET) {
          return 'upload';
        }
        return modalType.toLowerCase();
      }
      return '';
    };
    
    const transformTypeToTitle = type => {
      if (type && (type === EVENT_OPERATION.UPLOADED || type === EVENT_OPERATION.UPLOAD || type === EVENT_OPERATION.DEBOARD_OUTLET)) {
        return 'Upload';
      } else {
        return type.toLowerCase();
      }
    };

    return (
      <Fragment>
        {show && (
          <Fragment>
            <div className="modal-wrapper">
              <div className={`modal-inner ${transformTypeToModelInnerClass(type)}`}>
                <DialogHeader>
                  <div>
                    <h2>
                      <span>
                        {titleWithouttype
                          ? title
                          : this.isConfirmationType(type)
                            ? 'Confirm'
                            : `${transformTypeToTitle(type)} ${title}`}
                      </span>
                    </h2>
                    {subTitle && (
                      <span className="badge_border">
                        <Badge light>{subTitle || 'badge'}</Badge>
                      </span>
                    )}
                  </div>
                  <Icon iconName="times" onClick={e => onCancel(e)} style={{ cursor: 'pointer' }} />
                </DialogHeader>
                <DialogBody
                  type={
                    type === EVENT_OPERATION.UPLOADED
                    || type === EVENT_OPERATION.UPLOAD || type === EVENT_OPERATION.DEBOARD_OUTLET
                      ? 'dialog'
                      : withOutPadding
                        ? 'with-out-padding'
                        : ''
                  }
                >
                  {this.getViewType(newProps)}
                </DialogBody>

                {!footer && (
                  <DialogFooter>
                    <Button secondary onClick={e => onCancel(e)}>
                      <span>Cancel</span>
                    </Button>
                    {type !== EVENT_OPERATION.READ ? (
                      <Button primary onClick={e => onSubmit(e)}>
                        <span>Ok</span>
                      </Button>
                    ) : (
                      <Fragment />
                    )}
                  </DialogFooter>
                )}
              </div>
              <div className="overlay" />
            </div>
          </Fragment>
        )
        }
        <Fragment>
          {renderFn(newProps)}
        </Fragment>
      </Fragment>
    );
  }
}

ModalWrapper.propTypes = propTypes;

ModalWrapper.defaultProps = defaultProps;

export default ModalWrapper;
