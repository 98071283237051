import React from 'react';
import PropTypes from 'prop-types';
import { Form, SingleDatePicker } from '../../../components';

const propTypes = {
  handleDateChange: PropTypes.func,
};

const defaultProps = {
  handleDateChange: () => null,
};

const ExtendPromotionForm = ({ ...props }) => {
  const { data, handleDateChange, totalCount } = props;

  return (
    <Form>
      <span>
        {'Are you sure you want to extend '}
        <b>{totalCount}</b>
        {' promotions?'}
      </span>
      <div className='single-date-picker'>
        <label>End Date</label>
        <SingleDatePicker
          date={data.endDate}
          minDate={data.startDate}
          name='endDate'
          placeholder='End Date'
          onChange={(name, date) =>
            handleDateChange(
              date,
              'endDate',
            )
          }
          labelContent='End Date'
        />
      </div>
    </Form>
  );
};

ExtendPromotionForm.propTypes = propTypes;

ExtendPromotionForm.defaultProps = defaultProps;

export default ExtendPromotionForm;
