import { compose } from 'recompose';
import OrderAnalysis from './OrderAnalysis';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedOrderAnalysis = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(OrderAnalysis);

export default ComposedOrderAnalysis;
