import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Icon, CSVUploadForm, Form } from '../../../v4/components';
import withLoading from '../../../utils/composition/withLoading';
import { isObjectEmpty } from '../../../utils/objectPrototypes';
import UploadWithInputStyled from './UploadWithInputStyled';
import { getFileObj, FILE_STATUS_MAPPER } from './config';
import { ProgressBarStyled, ProgressCardStyled } from '../ImageUpload/UploadStyled';
import { convertFileSizeWithSuffix } from '../../../utils/conversion';
import { getFileType } from '../../common/HelperFunctions';

const propTypes = {
  data: PropTypes.shape({
    file: PropTypes.instanceOf(Object),
  }),
  getDetails: PropTypes.func,
  handleFileUpload: PropTypes.func,
};

const defaultProps = {
  data: {
    file: {},
  },
  getDetails: () => null,
  handleFileUpload: () => null,
};

class UploadWithInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFile: getFileObj(),
      fileServerUrl: '',
      uploadProgress: 0,
    };
  }

  componentDidMount() {
    const { getDetails } = this.props;
    getDetails(this.exportData);
  }

  exportData = () => {
    const {
      uploadFile: { file },
      fileServerUrl,
    } = this.state;
    const fileType = fileServerUrl.split('.').pop();
    return {
      file,
      fileServerUrl,
      fileType,
    };
  };

  handleFileSelection = e => {
    const { uploadFile } = this.state;
    const fileObj = { ...uploadFile };
    fileObj.file = e.target.files[0];
    fileObj.status = FILE_STATUS_MAPPER.PENDING;

    this.setState({ uploadFile: { ...uploadFile, ...fileObj } }, () => {
      this.handleImageUpload();
    });
  };

  monitorImageUploadProgress = progressEvent => {
    const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);

    this.setState({ uploadProgress });
  };

  handleImageUpload = () => {
    const { uploadFile } = this.state;
    const options = {
      params: {
        Key: uploadFile.file.name,
        ContentType: uploadFile.file.type,
      },
      headers: {
        'Content-Type': uploadFile.file.type,
      },
      onUploadProgress: this.monitorImageUploadProgress,
    };

    this.getImageUploadUrl(options).then(async url => {
      let fileServerUrl = '';
      let status = FILE_STATUS_MAPPER.DANGER;
      if (url) {
        fileServerUrl = await this.uploadImageToImageServer(url, uploadFile.file, options);
        if (fileServerUrl) status = FILE_STATUS_MAPPER.SUCCESS;
      }
      this.setState({ fileServerUrl, uploadFile: { ...uploadFile, status } });
    });
  };

  uploadImageToImageServer = (fileUrl, file, options) =>
    axios
      .put(fileUrl, file, options)
      .then(async res => {
        const url = await this.getImageUrl(options.params.Key);
        /** todo: replace url with response from server once server starts sending url * */
        const configuredUrl = url.split('?').slice(0, -1).join('?');

        return configuredUrl || '';
      })
      .catch(err => {
        console.log(err);
        return '';
      });

  getImageUploadUrl = async options => {
    const { getStorageImageUrl, domain } = this.props;
    return new Promise((resolve, reject) => {
      getStorageImageUrl(
        {
          key: options.params.Key,
          contentType: options.params.ContentType,
          domain,
        },
        {
          handleSuccess: res => resolve(res.data.getStoragePutURL || ''),
          handleError: err => {
            console.log(err);
            resolve('');
          },
        },
      );
    });
  };

  getImageUrl = async key => {
    const { getStorageUrl, domain } = this.props;

    return new Promise((resolve, reject) => {
      getStorageUrl(
        {
          key,
          domain,
        },
        {
          handleSuccess: res => {
            resolve(res.data.getStorageGetURL || '');
          },
          handleError: err => {
            resolve('');
          },
        },
      );
    });
  };

  getImageSubText = (fileObj, uploadProgress) => {
    if (fileObj.status === FILE_STATUS_MAPPER.PENDING) {
      return (
        <div className="img-progress-info">
          <span>{convertFileSizeWithSuffix(fileObj.file.size)}</span>
          <span>{uploadProgress} %</span>
        </div>
      );
    }
    if (fileObj.status === FILE_STATUS_MAPPER.SUCCESS) {
      return (
        <div className="sucess">
          <Icon iconName="check-circle-full" />
          Success: Upload completed!
        </div>
      );
    }
    if (fileObj.status === FILE_STATUS_MAPPER.DANGER) {
      return (
        <div className="failed">
          <Icon iconName="exclamation-circle" />
          Error: Upload failed!
        </div>
      );
    }
  };

  render() {
    const { handleFileUpload, inputSection } = this.props;
    const { uploadFile, uploadProgress, fileServerUrl } = this.state;
    const fileType = fileServerUrl && fileServerUrl.split('.').pop();

    return (
      <UploadWithInputStyled>
        <div className="top-section">{inputSection}</div>
        <div className="upload-section">
          <Form>
            {!isObjectEmpty(uploadFile.file) ? (
              <div className="img-upload-wrap">
                <ProgressCardStyled
                  status={uploadFile.status}
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    padding: '0 24px',
                    height: '80px',
                    marginBottom: '167px',
                  }}
                >
                  <div className="img-icon">
                    <img
                      src={
                        uploadFile.status === FILE_STATUS_MAPPER.SUCCESS
                          ? getFileType(fileType.toLowerCase())
                          : '/image/icons/user.svg'
                      }
                      alt="img-icon"
                    />
                  </div>
                  <div className="progress-bar-wrap">
                    <h3 className="img-name">{uploadFile.file.name}</h3>
                    {uploadFile.status === FILE_STATUS_MAPPER.PENDING ? (
                      <ProgressBarStyled status={uploadFile.status}>
                        <div className="progress-bar" style={{ display: 'flex', borderRadius: '4px' }}>
                          <div
                            style={{
                              height: '8px',
                              width: `${uploadProgress}%`,
                              borderRadius: '4px',
                              backgroundColor: '#0B5FFF',
                            }}
                          />
                        </div>
                      </ProgressBarStyled>
                    ) : null}
                    {this.getImageSubText(uploadFile, uploadProgress)}
                  </div>
                </ProgressCardStyled>
              </div>
            ) : (
              <>
                <CSVUploadForm
                  data={uploadFile}
                  handleFileUpload={e => {
                    handleFileUpload(e);
                    this.handleFileSelection(e);
                  }}
                  accept=".pdf, .xls, .csv, text/csv, video/mp4, image/*"
                />
              </>
            )}
          </Form>
        </div>
      </UploadWithInputStyled>
    );
  }
}

UploadWithInput.propTypes = propTypes;

UploadWithInput.defaultProps = defaultProps;

const UploadViewWithErrorAndLoading = withLoading(UploadWithInput);

export default UploadViewWithErrorAndLoading;
