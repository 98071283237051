import { compose } from 'recompose';
import Detail from './Detail';
import {
  query, mutation, CREATE_LINE, UPDATE_LINE
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedRouteSegmentationDetail = compose(
  CREATE_LINE,
  UPDATE_LINE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Detail);

export default composedRouteSegmentationDetail;
