import styled from 'styled-components';

const LoginStyled = styled.div`
  .login__wrapper {
    display: flex;
    height: 100%;
    @media only screen and (min-height: 560px) {
      height: 100vh;
    }
    @media only screen and (min-width: 992px) {
      height: 100vh;
    }
  }

  .error-message {
    margin-top: 10px;
  }

  .login__body {
    .login__form-content {
      .form-input:last-child {
        margin-bottom: 0;
      }
    }
    .error-text {
      color: #ff4f46;
      display: inline-block;
      margin-bottom: 24px;
    }
  }

  .login__form {
    background: #fff;
    position: relative;
    flex: 1;

    @media only screen and (min-width: 992px) {
      width: 420px;
      padding: 48px 0;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px 20px;
      height: calc(100% - 80px);
      max-width: 400px;
      margin: 0 auto;
      @media only screen and (min-device-width: 320px) and (max-device-width: 991px) {
        height: 100vh;
      }
      @media only screen and (min-width: 992px) {
        max-width: 100%;
        padding: 0 48px;
        height: calc(100vh - 48px - 48px);
        margin-bottom: 48px;
      }
    }
  }

  .login__progress {
    margin-bottom: 20px;
  }

  .login__info {
    width: calc(100% - 420px);
    float: left;
  }

  .login__logo {
    img {
      height: 17px;
    }
  }

  .login__title {
    margin-bottom: 20px;
    h2 {
      font-size: 18px;
      color: #272833;
    }
  }

  .input-wrapper {
    position: relative;
    &:not(:first-child) {
      margin-top: 24px;
    }
    label {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 4px;
      display: block;
      font-weight: 500;
      color: #272833;
      cursor: auto;
      opacity: 1;
    }

    input {
      border: 1px solid #ccc;
      background: #e7e7ed;
      font-size: 16px;
      line-height: 24px;
      border-radius: 4px;
      padding: 8px 16px;
      height: 40px;
      width: 100%;
      box-sizing: border-box;
      outline: none;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(107, 108, 126, 0.57);
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(107, 108, 126, 0.57);
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(107, 108, 126, 0.57);
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(107, 108, 126, 0.57);
      }

      &::placeholder {
        color: rgba(107, 108, 126, 0.57);
      }
    }
  }

  button.primary {
    width: 100%;
    text-transform: uppercase;
  }

  .login__info {
    background: #f1f2f5;
    display: none;

    @media only screen and (min-width: 992px) {
      display: block;
    }
  }

  .login__text {
    width: 60%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    color: #7b7b7b;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
      width: 100%;
    }
  }

  .login-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .copyright {
    color: #272833;
    font-size: 12px;
    line-height: 1.5;
    display: flex;
    p {
      margin: 0;
      margin: 3px 0 0 15px;
    }
  }
  .password_input{
    position:relative;
    .password__icon{
      cursor:pointer;
      position: absolute;
      right: 10px;
      top: 32px;
    }
  }

  .forgot-password {
    color: #0b5fff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: right;
    padding-bottom: 24px;
    span{
      cursor: pointer;
    }
  }
`;

export default LoginStyled;
