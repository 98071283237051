import { EVENT_OPERATION } from './EventOperation';

const APPROVAL_PROCESS = {
  RETAIL_OUTLET_APPROVAL: { processId: 1, type: 'RETAIL_OUTLET_APPROVAL' },
  OUTLET_GEOLOCATION_CHANGE_APPROVAL: {
    processId: 2,
    type: 'OUTLET_GEOLOCATION_CHANGE_APPROVAL',
  },
  OUTLET_TRANSFER_REQUEST: { processId: 3, type: 'OUTLET_TRANSFER_APPROVAL' },
  OUTLET_CATEGORY_CHANGE_APPROVAL: {
    processId: 4,
    type: 'OUTLET_CATEGORY_CHANGE_APPROVAL',
  },
  OUTLET_DELETE_APPROVAL: { processId: 5, type: 'OUTLET_DELETE_APPROVAL' },
};
const menuConfigList = [
  {
    title: 'Approve',
    type: EVENT_OPERATION.APPROVED,
  },
  {
    title: 'Reject',
    type: EVENT_OPERATION.REJECTED,
  },
];
export { APPROVAL_PROCESS, menuConfigList };
