import React from 'react';
import { Icon } from '../../../../../components';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { DEVICE_STATUS } from '../config/config';
import { StatusButton } from './LinkedDevicesStyled.jsx';
import TableHeader from '../../../../base/TableHeader';

const labelMappings = {
  phoneModel: 'phoneModel',
  deviceName: 'deviceName',
  userName: 'userName',
  createdDate: 'createdDate',
  status: 'status',
  isDeviceLocked: 'isDeviceLocked',
  removeDevice: 'removeDevice',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.phoneModel,
    title: 'Phone Model',
    show: true,
    className: 'text-left',
    sortable: true,
    apiDataKey:'phone_model',
  },
  {
    id: 2,
    label: labelMappings.deviceName,
    title: 'Device Name',
    show: true,
    className: 'text-left',
    sortable: true,
    apiDataKey:'phone_name',
  },
  {
    id: 3,
    label: labelMappings.userName,
    title: 'User',
    show: true,
    className: 'text-left',
    sortable: true,
    apiDataKey:'userName',
  },
  {
    id: 4,
    label: labelMappings.createdDate,
    title: 'Created Date',
    show: true,
    className: 'text-left',
    sortable: true,
    apiDataKey:'created_at',
  },
  {
    id: 5,
    label: labelMappings.status,
    title: 'Status',
    show: true,
    className: 'text-center',
    sortable: true,
    apiDataKey:'status',
  },
  {
    id: 6,
    label: labelMappings.isDeviceLocked,
    title: '',
    show: true,
    className: '',
  },
  {
    id: 7,
    label: labelMappings.removeDevice,
    title: '',
    show: true,
    className: '',
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
);

const returnTableBody = (data, handleIconClick, permission) => {
  const table = {};
  table[labelMappings.phoneModel] = <td key={`${data?.id}-${labelMappings.phoneModel}`}>{data?.phoneModel}</td>;
  table[labelMappings.deviceName] = <td key={`${data?.id}-${labelMappings.deviceName}`}>{data?.deviceName}</td>;
  table[labelMappings.userName] = <td key={`${data?.id}-${labelMappings.userName}`}>{data?.userName}</td>;
  table[labelMappings.createdDate] = <td key={`${data?.id}-${labelMappings.createdDate}`}>{data?.createdDate}</td>;
  table[labelMappings.status] = (
    <td key={`${data?.id}-${labelMappings.status}`} className='text-center'>
      <StatusButton type={data?.status}>{data?.status}</StatusButton>
    </td>
  );
  table[labelMappings.isDeviceLocked] = (
    <td
      key={`${data?.id}-${labelMappings.status}`}
      className={`${data?.status === DEVICE_STATUS.INACTIVE ? 'disabled' : ''} lock-unlock-buttons`}
    >
      {permission?.update && data?.status === DEVICE_STATUS.ACTIVE && (
        <Icon
          iconName='lock'
          className='lock-button'
          onClick={() => handleIconClick(EVENT_OPERATION.BLOCK_DEVICE, data)}
        />
      )}

      {permission?.update && data?.status === DEVICE_STATUS.BLOCKED && (
        <Icon
          iconName='unlock'
          className='unlock-button'
          onClick={() => handleIconClick(EVENT_OPERATION.UNBLOCK_DEVICE, data)}
        />
      )}

      {data?.status === DEVICE_STATUS.INACTIVE && (
        <Icon iconName='lock' className='inactive-button' onClick={() => {}} />
      )}
    </td>
  );
  table[labelMappings.removeDevice] = (
    <td key={`${data?.id}-${labelMappings.status}`} className='delete-button'>
      {permission?.delete && <Icon iconName='trash' className='lock-button' onClick={() => handleIconClick(EVENT_OPERATION.DELETE, data)} />}
    </td>
  );

  return table;
};

const TableBody = (props) => {
  const { data, handleIconClick, permission } = props;
  const tableBody = returnTableBody(data, handleIconClick, permission);

  return (
    <tr key={`linked-device-list-${data.id}`}>
      {labelConfig?.map?.((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export { TableHeaderConfig as TableHeader, TableBody, labelConfig };
