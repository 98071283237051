import PropTypes from 'prop-types';
import React from 'react';
import { Input } from '../../../../../components';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { Icon, tableData } from '../../../../../v4/components';

const labelMappings = {
  sku: 'SKU',
  rate: 'Rate',
  target: 'Target',
  remove: 'Remove'
};

const returnTableBody = (data, label, handleCallTargetChange) => {
  const removeCallTarget = (event) => {
    event.preventDefault();
    handleCallTargetChange(data.id, label, null)
  }

  const table = {};
  table[labelMappings.sku] = <td key={`${data.id}- sku`} >{data.title}</td>;
  table[labelMappings.rate] = <td className='text-right' key={`${data.id}- rate`}>{data?.Rates[0]?.priceDetails?.rlp}</td>;
  table[labelMappings.target] = (
    <td key={`${data.id}- target`} onClick={(e) => e.stopPropagation()}>
      <Input style={{ margin: '0 auto' }} value={data.callTarget} min='0' type='number' onChange={(event) => handleCallTargetChange(data.id, event.target.value, false)} placeholder='0' />
    </td>
  );
  table[labelMappings.remove] = (
    <td key={`${data.id}- remove`} className='text-right remove' onClick={(e) => removeCallTarget(e)}>
      <Icon iconName='trash' />
    </td>
  )

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
    classNames: 'left'
  },
  {
    id: 2,
    label: labelMappings.rate,
    title: 'Rate',
    show: true,
    classNames: 'text-right'
  },
  {
    id: 3,
    label: labelMappings.target,
    title: 'Target',
    show: true,
    classNames: 'text-center'
  },
  {
    id: 4,
    label: labelMappings.remove,
    title: '',
    show: true,
    classNames: 'text-right'
  }
];

const TableBody = ({ data, label, handleCallTargetChange }) => {
  const tableBody = returnTableBody(data, label, handleCallTargetChange);

  return (
    <tr key={data.id} style={{ cursor: 'pointer' }}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

TableHeader.propTypes = {
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
};

TableHeader.defaultProps = {
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
