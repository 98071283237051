import styled from 'styled-components';
const LegendStyled = styled.div`
  svg {
    g {
      text {
        text-transform: uppercase;
        fill: #6b6c7e;
        &:last-child {
          fill: #272833;
        }
      }
    }
  }
`;
export default LegendStyled;
