import gql from 'graphql-tag';

const GET_TAS_REPORT_LIST = gql`
  query getReport($filter: FilterInput) {
    getTasUserStateDetailList(filter: $filter) {
      rows {
        user_id
        name
        status
        distributor
        scheduled
        successful
        unsuccessful
        remaining
        productivity
        target
        sales_order_received
      }
      count
    }
  }
`;

const query = {
  getReport: GET_TAS_REPORT_LIST,
};

export { query };
