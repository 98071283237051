import React from 'react';
import PropTypes from 'prop-types';
import { IMAGE_UPLOAD_DOMAIN } from '../../../../../data/enums/GraphQL';
import ImageUpload from '../../../../components/ImageUploadSection';
import { Icon } from '../../../../../v4/components';

const propTypes = {
  handleImagesFetch: PropTypes.func,
  banner: PropTypes.instanceOf(Object),
};

const defaultProps = {
  handleImagesFetch: () => null,
  banner: {},
};

const Banner = ({ handleImagesFetch, banner }) => (
  <div className='promotion-banner'>
    <ImageUpload
      key={`${banner.images}-images`}
      getImages={(images) => {
        handleImagesFetch(images);
      }}
      images={banner.images}
      domain={IMAGE_UPLOAD_DOMAIN.PROMOTIONS}
      checkPromotionBannerImage
    />
    <span>
      <Icon iconName='info-circle-open' />
      Please upload the images of ratio w:1000px h:430px for better banner preview
    </span>
  </div>
);

Banner.propTypes = propTypes;

Banner.defaultProps = defaultProps;

export default Banner;
