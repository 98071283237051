import React from 'react';
import withLoading from '../../../../utils/composition/withLoading';
import VerticalListWithCount from '../../../components/VerticalListWithCount';
import { PermissionContext } from '../../subDAssortment/details/config';
import SkuFamily from './Components/skuFamily';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { BreadCrumb } from '../../../../v4/components';
import { breadCrumbConfig } from '../../bu/config';
import ICODetailStyled from './ICODetailStyled';
import { useLocation } from 'react-router-dom';

const ICOGroupDetailsView = ({
  productGroups,
  brandId,
  productGroupId,
  brands,
  assortmentCounts,
  onBrandLineClick,
  selectedSkus,
  onProductGroupLineClick,
  onSwitchChange,
  skuFamilies,
  onSearchInputChange,
  searchText,
  checkedSkuFamilies,
  setCheckedSkuFamilies,
  permission,
  alreadySelectedSku,
  loading
}) => {
  const location = useLocation();
  const headerTitle = location.state?.groupMasterTitle

  return (
    <>
      <div className='section-header'>
        <PanelStyled>
          <BreadCrumb list={breadCrumbConfig} />
          <PanelHeader>
            <div className='flex items-center justify-between m-0 flex-1'>
              <h2>{headerTitle}</h2>
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content darkshade-bg create-ui-inner'>
        <PermissionContext.Consumer>
          {() => (
            <ICODetailStyled>
              <div className='panel-section'>
                <VerticalListWithCount
                  title='Product Category'
                  list={productGroups}
                  activeLine={productGroupId}
                  onItemClick={onProductGroupLineClick}
                  countList={assortmentCounts.productGroups}
                  loading = {loading}
                />
                {productGroupId && (
                  <VerticalListWithCount
                    title='Brands'
                    list={brands || []}
                    activeLine={brandId}
                    onItemClick={onBrandLineClick}
                    countList={assortmentCounts.brands}
                    loading = {loading}
                  />
                )}
                {brandId && (
                  <SkuFamily
                    searchText={searchText}
                    skuFamilies={skuFamilies}
                    assortmentCounts={assortmentCounts}
                    onSearchInputChange={onSearchInputChange}
                    onSwitchChange={onSwitchChange}
                    selectedSkus={selectedSkus}
                    checkedSkuFamilies={checkedSkuFamilies}
                    setCheckedSkuFamilies={setCheckedSkuFamilies}
                    alreadySelectedSku={alreadySelectedSku}
                    loading = {loading}
                    permission = {permission}
                  />
                )}
              </div>
            </ICODetailStyled>
          )}
        </PermissionContext.Consumer>
      </div>
    </>
  );
};

const ICOGroupDetailsViewWithLoading = withLoading(ICOGroupDetailsView);

export default ICOGroupDetailsViewWithLoading;
