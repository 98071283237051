import styled from 'styled-components';

const RoundedLetterStyled = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  line-height: 16px;
  margin-right: 5px;
  text-align: center;
  width: 16px;
  font-size: 10px;
`;

export default RoundedLetterStyled;
