import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogBody, DialogFooter, DialogHeader
} from '../../../../components/Dialog';
import { Button } from '../../../../v4/components';

const propTypes = {
  userTitle: PropTypes.string,
  routeTitle: PropTypes.string,
  onDialogSubmit: PropTypes.func,
  onDialogCancel: PropTypes.func,
};

const defaultProps = {
  userTitle: '',
  routeTitle: '',
  onDialogSubmit: () => null,
  onDialogCancel: () => null,
};

const ConfirmationView = ({ ...props }) => {
  const { onDialogCancel, onDialogSubmit, routeTitle, userTitle } = props;

  return (
    <div className='modal-wrapper'>
      <div className='modal-inner'>
        <DialogHeader>
          <h2>
            <span>Remove Assigned Route</span>
          </h2>
        </DialogHeader>
        <DialogBody>
          Are you sure you want to remove
          <b>{` ${routeTitle} `}</b>
          assigned to
          <b>{` ${userTitle} `}</b>?
        </DialogBody>
        <DialogFooter>
          <Button secondary onClick={() => onDialogCancel()}>
            <span>Cancel</span>
          </Button>
          <Button primary onClick={() => onDialogSubmit()}>
            <span>Ok</span>
          </Button>
        </DialogFooter>
      </div>
      <div className='overlay' />
    </div>
  );
};

ConfirmationView.propTypes = propTypes;

ConfirmationView.defaultProps = defaultProps;

export default ConfirmationView;
