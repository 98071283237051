
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { FILE_FORMAT_FRAGMENT } from '../../common/GqlFragments';
import { DOWNLOAD_REPORT, GET_DISTRIBUTORS } from '../../common/GqlAPI';

const STOCK_LEDGER_FRAGMENT = gql`
  fragment StockLedgerFragment on StockLedger {
    skuId: skuid
    skuBatchId: skuBatchId
    distrdistributorId: distributorid
    inward
    outward
    adjustment
    damages
    shortages
    date
    sku_status
    discontinue_sku
    openingBalance: opening
    closingBalance: closing
    SKU: sku {
      id
      title
    }
    Distributor: distributor {
      id
      title
    }
    expiry
  }
`;

const GET_STOCK_LEDGER_LIST = gql`
  query getStockLedgers($offset: Int, $limit: Int, $filter: FilterInput) {
    stockLedgers(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...StockLedgerFragment
      }
      count
    }
  }
  ${STOCK_LEDGER_FRAGMENT}
`;

const GET_STOCK_LEDGER_BATCH_DETAILS = gql`
  query getStockLedgerBatchDetail($distributorId: Int, $skuId: Int, $filter: FilterInput) {
    getStockLedgerBatchDetail(distributorId: $distributorId, skuId: $skuId, filter: $filter) {
      opening
      closing
      adjustment
      inward
      outward
      damages
      expiry
      shortages
      SKUBatch {
        id
        batchName
        manufactureDate
        expiryDate
        shortages
        damages
      }
    }
  }
`;

const DOWNLOAD_STOCK_LEDGER = graphql(
  gql`
    mutation ($input: fileDownloadInput!) {
      downloadStockLedgers(input: $input) {
        file {
          ...fileFormatFragment
        }
      }
    }
    ${FILE_FORMAT_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      downloadStockLedger: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getStockLedgerList: GET_STOCK_LEDGER_LIST,
  getDistributors: GET_DISTRIBUTORS,
  getStockLedgerBatchDetail: GET_STOCK_LEDGER_BATCH_DETAILS,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, DOWNLOAD_REPORT
};
