import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import withAlert from '../../../../utils/composition/withAlert';
import {
  breadCrumbConfig,
  formConfig as form,
  title,
  channelCategoryBase,
  messagePlatform,
  targetAudience,
  periodType,
  dialogTitle,
  checkOutletStatus,
  transformData,
  reponseToStateTransformer,
} from './config';
import { DialogWrapper } from '../../../common';
import { dropdownChange } from '../../../../utils/formHandlers';
import { refGenerator } from '../../../../utils';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import PushMessageWrapper from '../PushMessageWrapper';
import General from './General';
import { getDataFromLocalStorage, CLIENT_STORAGE_TABLE } from '../../../../data/services';
import { refValidator } from '../../../../utils/refGenerator';
import history from '../../../../utils/history';
import { TARGET_AUDIENCE, TARGET_AUDIENCE_BY_NAME } from './enums';
import {  Tab, TabList, TabPanel, Tabs, BreadCrumb, Button } from '../../../../v4/components';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getPromotionList: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  getUserRoleList: PropTypes.func.isRequired,
  getTownList: PropTypes.func.isRequired,
  getTerritory: PropTypes.func.isRequired,
  getRouteList: PropTypes.func.isRequired,
  getRetailOutletCount: PropTypes.func.isRequired,
  getMessageList: PropTypes.func.isRequired,
  getDataDictionaryList: PropTypes.func.isRequired,
  editMessage: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Detail extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { id },
      },
    } = props;
    const checkDetails = !!window.location.href.includes('details');
    this.state = {
      id,
      activeTab: 0,
      data: form.mapper({}),
      dataDictionaryList: [],
      menu: {
        campaign: [],
        messageCategory: [],
        targetAudience: [],
        userGroup: [],
        territoryList: [],
        townList: [],
        routeList: [],
        channelList: [],
        categoryList: [],
        messagePlatform: [],
        periodType: [],
      },
      outletCount: 0,
      enableFormValidation: false,
      crudMode:
        id && !checkDetails
          ? EVENT_OPERATION.REPLICATE
          : id && checkDetails
          ? EVENT_OPERATION.READ
          : EVENT_OPERATION.CREATE,
    };

    this.checkDetails = checkDetails;

    this.formReference = refGenerator(form.validationField);
  }

  componentDidMount() {
    const { id } = this.state;

    if (id) {
      this.getDetail(id);
    }

    this.loadDataForDropDown();
  }

  getDetail = id => {
    const { getMessageList } = this.props;

    getMessageList(
      {
        filter: {
          filters: [{ column: 'id', value: [id] }],
        },
      },
      {
        handleSuccess: response => {
          const message = reponseToStateTransformer(response.data.messages.rows[0]);
          this.setState(
            {
              data: {
                ...message,
              },
            },
            () => {
              this.loadTownList();
              this.loadRouteList();
              this.loadUserGroup();
              this.state.data.targetAudience === 2 && this.getRetailOutletCount();
            },
          );
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  onTabChange = tabIndex => {
    this.setState({ activeTab: tabIndex });
  };

  loadDataForDropDown = () => {
    const { menu } = this.state;

    menu.targetAudience = targetAudience;
    menu.messagePlatform = messagePlatform;
    menu.periodType = periodType;
    // this.loadPromotionList();
    this.loadTerritoryList();
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then(response => {
      menu.channelList = response || [];
      this.setState({ menu });
    });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then(response => {
      menu.categoryList = response || [];
      this.setState({ menu });
    });
    this.setState({ menu });
  };

  getDataDictionaryList = () => {
    let { data, dataDictionaryList } = this.state;
    const { getDataDictionaryList, displayAlert } = this.props;

    getDataDictionaryList(
      {
        type: TARGET_AUDIENCE[`${data.targetAudience}`],
      },
      {
        handleSuccess: response => {
          dataDictionaryList = response.data.getDataDictionaryList || [];
          this.setState({ dataDictionaryList });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleChannelDropDown = (value, index = 0, field) => {
    const { data } = this.state;
    if (field === 'channelList') {
      data.channelList[index].channelId = value;
      data.channelList[index].categoryIds = [];
    }
    this.setState({ data }, () => this.getRetailOutletCount());
  };

  handleAddIconClick = (action, field, index = 0) => {
    const { data } = this.state;
    if (action === 'remove') {
      data[field].splice(index, 1);
      this.getRetailOutletCount();
    }
    if (action === 'add') {
      const baseObj = channelCategoryBase[field];
      data[field].push({ ...baseObj });
    }
    this.setState({ data });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const { checked, name, value } = event.target;
    const { displayAlert } = this.props;

    if (!checked && firstParam === 'repeatDays' && paramList.length === 0) {
      this.setState({
        data: {
          ...data,
          ...{
            repeatDays: data.repeatDays.filter(d => d !== value).filter(d => d !== null),
          },
        },
      });
    } else {
      if (firstParam) {
        if (paramList.length > 1) {
          paramList.reduce((acc, crValue, index, list) => {
            if (index === list.length - 1) {
              return (acc[crValue] = event.formattedValue);
            }
            return acc[crValue];
          }, data);
        } else {
          data[firstParam][name] = value;
        }
      } else if (name === 'startDate') {
        if (new Date(value).getTime() > new Date(data.endDate)) {
          displayAlert(ALERT_TYPE.WARNING, 'Start date must be earlier than end date');
        } else {
          data[name] = value;
        }
      } else {
        data[name] = value;
      }
      this.setState({ data }, () => {
        if (name === 'endDate') {
          if (new Date(this.state.data.endDate) < new Date(data.startDate)) {
            displayAlert(ALERT_TYPE.WARNING, 'End date must be greater than start date');
          }
        }
        name === 'invoiced' && this.getRetailOutletCount();
      });
    }
  };

  handleDropDownChange = (value, parameterRef = [], name) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    switch (name) {
      case 'targetAudience':
        this.setState({ data: updatedData }, () => {
          updatedData.targetAudience === TARGET_AUDIENCE_BY_NAME.USER
            ? this.loadUserGroup()
            : this.getRetailOutletCount();
          this.getDataDictionaryList();
        });
        break;
      case 'userGroup':
        this.setState({
          data: {
            ...data,
            ...{ userGroup: value.map(d => d.id) },
          },
        });
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  // loadPromotionList = () => {
  //   const { getPromotionList } = this.props;

  //   getPromotionList({
  //     offset: 0,
  //     limit: 5000,
  //     filter: {
  //       dateRange: { start: '01 Oct 2020', end: '15 Oct 2020' },
  //       filters: [],
  //       queryString: '',
  //     },
  //   }, {
  //     handleSuccess: (res) => {
  //       const { menu } = this.state;
  //       menu.campaign = [...(res.data.promotions && (res.data.promotions.rows || []))] || [];
  //       this.setState({ menu });
  //     },
  //     handleError: err => this.onAPIRequestFailure(err),
  //   });
  // };

  loadUserGroup = () => {
    const { getUserRoleList } = this.props;
    getUserRoleList(
      {
        offset: 0,
        filter: { filters: [], queryString: '' },
      },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.userGroup = [...(res.data.roles && (res.data.roles.rows || []))] || [];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  loadTerritoryList = () => {
    const { getTerritory } = this.props;
    getTerritory(
      {
        offset: 0,
        filter: { filters: [], queryString: '' },
      },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.territoryList = [...(res.data.territories && (res.data.territories.rows || []))] || [];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  loadTownList = () => {
    const { getTownList } = this.props;
    const { data } = this.state;
    getTownList(
      {
        offset: 0,
        filter: {
          filters: [
            {
              column: 'territory_id',
              value: data.territoryList.map(String),
            },
          ],
          queryString: '',
        },
      },
      {
        handleSuccess: response => {
          const { menu } = this.state;
          menu.townList = (response.data.towns && response.data.towns.rows) || [];
          this.setState(menu);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  loadRouteList = () => {
    const { getRouteList } = this.props;
    const { data } = this.state;
    getRouteList(
      {
        offset: 0,
        filter: {
          filters: [
            {
              column: 'town_id',
              value: data.townList.map(String),
            },
          ],
          queryString: '',
        },
      },
      {
        handleSuccess: response => {
          const { menu } = this.state;
          menu.routeList = (response.data.routes && response.data.routes.rows) || [];
          this.setState(menu);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleButtonCancel = () => {
    const { crudMode, id } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: form.mapper({}),
        outletCount: 0,
      });
    } else {
      this.setState({ crudMode: EVENT_OPERATION.REPLICATE }, () => {
        this.getDetail(id);
      });
    }
  };

  check12AMPM = (hours, period) => {
    if (period === 'AM') {
      if (hours === 12) {
        return '00';
      }
      return hours;
    }
    if (period === 'PM') {
      if (hours === 12) {
        return '12';
      }
      return hours + 12;
    }
  };

  handleButtonSubmit = () => {
    const { data, outletCount, crudMode, id } = this.state;

    const { sendMessage, displayAlert, editMessage } = this.props;
    const { formReference } = this;
    const formValidation = refValidator(formReference);
    const submissiveData = transformData(form.mapper(data));

    const validTextArea =
      data.platform === 1 || data.platform === 3 ? data.message.message === '' : data.message.description === '';
    if (validTextArea || !formValidation) {
      this.setState({ enableFormValidation: true });
      return;
    }

    if (data.targetAudience === 2 && outletCount === 0) {
      displayAlert(ALERT_TYPE.WARNING, 'Select at least one outlet to send a SMS.');
      return;
    }
    const { daily, monthly, weekly } = data.repeatedTimes;

    if (data.isRepeated && !(daily || monthly || weekly)) {
      displayAlert(ALERT_TYPE.WARNING, 'Select at least one repeat times to send a SMS.');
      return;
    }

    if (data.isRepeated && (monthly || weekly) && data.repeatDays.length === 0) {
      displayAlert(ALERT_TYPE.WARNING, 'Select at least one repeat days to send a SMS.');
      return;
    }

    if (new Date(data.endDate) < new Date(data.startDate)) {
      displayAlert(ALERT_TYPE.WARNING, 'End date must be greater than start date');
      return;
    }

    if (new Date(data.startDate) > new Date(data.endDate)) {
      displayAlert(ALERT_TYPE.WARNING, 'Start date must be earlier than end date');
      return;
    }

    const scheduleTimes = `${data.endDate} 
    ${
      data.times.period === 'AM' ? this.check12AMPM(data.times.hours, 'AM') : this.check12AMPM(data.times.hours, 'PM')
    }:${data.times.minutes}`;

    if (data.isScheduled && new Date(scheduleTimes).getTime() < new Date().getTime()) {
      displayAlert(ALERT_TYPE.WARNING, "You've selected a previous time");
      return;
    }

    if (crudMode !== EVENT_OPERATION.UPDATE) {
      sendMessage(
        {
          input: submissiveData,
        },
        {
          handleSuccess: response => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Message Sent');
            setTimeout(() => {
              history.push('/sales-force/push-message');
            }, 2000);
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    } else {
      editMessage(
        {
          id: parseInt(id, 10),
          input: submissiveData,
        },
        {
          handleSuccess: response => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Edited Successfully');
            setTimeout(() => {
              history.push('/sales-force/push-message');
            }, 2000);
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    }
  };

  getRetailOutletCount = () => {
    const { territoryList, townList, routeList, outlet, channelList, invoiced } = this.state.data;
    const { getRetailOutletCount } = this.props;

    getRetailOutletCount(
      {
        input: {
          outletStatus: checkOutletStatus(outlet),
          lastInvoiced: Number(invoiced),
          zoneId: territoryList,
          townId: townList,
          routeId: routeList,
          channelCategory: (channelList.length && channelList[0].channelId === 0 ? [] : channelList) || [],
        },
      },
      {
        handleSuccess: response => {
          this.setState({
            outletCount: response.data.retailOutletCount.count,
          });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  handleAutoCompleteChange = key => (value, fieldName, index) => {
    const { [key]: keyValue, data } = this.state;

    if (fieldName === 'territoryList') {
      this.loadTownList();
      data.townList.splice(0);
      data.routeList.splice(0);
    }

    if (fieldName === 'townList') {
      this.loadRouteList();
      data.routeList.splice(0);
    }

    if (fieldName === 'categoryList') {
      const newValue = value.map(va => va);
      keyValue.channelList[index].categoryIds = [...newValue];
    }

    [keyValue][fieldName] = value;

    this.setState({ [key]: keyValue }, () => {
      this.getRetailOutletCount();
    });
  };

  getHeader = () => {
    const { crudMode, data } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? data.title : title;
    if (crudMode === EVENT_OPERATION.READ || this.checkDetails) return data.title;

    return (
      <>
        <span>{EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}</span> {header}
      </>
    );
  };

  radioButtonChanged = e => {
    const { data } = this.state;
    const { name, value } = e.target;
    this.setState(
      {
        data: {
          ...data,
          ...{
            [name]: {
              ...{ [value.toLowerCase()]: e.target.checked },
            },
          },
        },
      },
      () => {
        name === 'outlet' && this.getRetailOutletCount();
      },
    );
  };

  handleEditButton = () => {
    this.setState({
      crudMode: EVENT_OPERATION.UPDATE,
    });
  };

  handleDetailButtonCancel = () => {
    const { id } = this.state;
    this.setState(
      {
        crudMode: EVENT_OPERATION.READ,
      },
      () => this.getDetail(id),
    );
  };

  render() {
    const { data, menu, enableFormValidation, outletCount, crudMode, activeTab, id, dataDictionaryList } = this.state;
    const { serverResponseWaiting } = this.props;
    const headerTitle = this.getHeader();

    return (
      <PushMessageWrapper>
        <DialogWrapper
          onDialogSubmit={this.handleButtonSubmit}
          refsObj={this.formReference}
          title={dialogTitle}
          renderDialog={() => (
            <span>
              {'Are you sure you want to send SMS to'}
              {data.targetAudience === 2 ? (
                <b>{` ${outletCount.toLocaleString()} outlets`}</b>
              ) : (
                <b> respective users</b>
              )}
              {'?'}
            </span>
          )}
          render={({ onDialogItemClick }) => (
            <Fragment>
              <div className="section-header">
                <PanelStyled>
                  <BreadCrumb list={breadCrumbConfig} />
                  <PanelHeader>
                    <h2>
                      {headerTitle}
                      {data.targetAudience === 2 && outletCount > 0 && (
                        <span className="size-m">{`Total Outlets: ${outletCount.toLocaleString()}`}</span>
                      )}
                    </h2>
                    <div className="flex m-0">
                      {!this.checkDetails ? (
                        <>
                          <div>
                            <Button small secondary onClick={() => this.handleButtonCancel()}>
                              <span> Cancel </span>
                            </Button>
                            <Button small primary onClick={() => onDialogItemClick()} disabled={serverResponseWaiting}>
                              <span>Send</span>
                            </Button>
                          </div>
                        </>
                      ) : (
                        <>
                          {crudMode === EVENT_OPERATION.UPDATE ? (
                            <>
                              <Button small secondary onClick={() => this.handleDetailButtonCancel()}>
                                <span> Cancel </span>
                              </Button>
                              <Button small primary onClick={() => this.handleButtonSubmit()}>
                                <span> Save </span>
                              </Button>
                            </>
                          ) : (
                            <>
                              <div>
                                <Button
                                  small
                                  secondary
                                  iconBtnSmall
                                  iconName="copy"
                                  onClick={() => (window.location.href = `/sales-force/push-message/create/${id}`)}
                                />
                                {data.status === 'ACTIVE' && (
                                  <Button
                                    small
                                    secondary
                                    iconBtnSmall
                                    iconName="pencil"
                                    onClick={() => this.handleEditButton()}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </PanelHeader>
                </PanelStyled>
              </div>
              <div className="section-content section-tab">
                {this.checkDetails ? (
                  <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.onTabChange(tabIndex)}>
                    <TabList>
                      <Tab>
                        <span className="tab-label">Details</span>
                      </Tab>
                    </TabList>
                    <div className="tab-content-wrap">
                      <TabPanel>
                        <section id="detail-tab">
                          <General
                            data={data}
                            menu={menu}
                            loading={serverResponseWaiting}
                            handleDropDownChange={this.handleDropDownChange}
                            handleInputChange={this.handleInputChange}
                            radioButtonChanged={this.radioButtonChanged}
                            handleAutoCompleteChange={this.handleAutoCompleteChange('data')}
                            onDropDownChange={this.handleChannelDropDown}
                            onIconClick={this.handleAddIconClick}
                            enableErrorDisplay={enableFormValidation}
                            refsObj={this.formReference}
                            crudMode={crudMode}
                            dataDictionaryList={dataDictionaryList}
                          />
                        </section>
                      </TabPanel>
                    </div>
                  </Tabs>
                ) : (
                  <General
                    data={data}
                    menu={menu}
                    loading={serverResponseWaiting}
                    handleDropDownChange={this.handleDropDownChange}
                    handleInputChange={this.handleInputChange}
                    radioButtonChanged={this.radioButtonChanged}
                    handleAutoCompleteChange={this.handleAutoCompleteChange('data')}
                    onDropDownChange={this.handleChannelDropDown}
                    onIconClick={this.handleAddIconClick}
                    enableErrorDisplay={enableFormValidation}
                    refsObj={this.formReference}
                    crudMode={crudMode}
                    dataDictionaryList={dataDictionaryList}
                  />
                )}
              </div>
            </Fragment>
          )}
        />
      </PushMessageWrapper>
    );
  }
}

Detail.defaultProps = defaultProps;

Detail.propTypes = propTypes;

export default withAlert()(Detail);
