import styled from 'styled-components';

const CardStyled = styled.div`
  .card-pannel {
    margin-right: 8px;
    background: #fff;
    border-radius: 4px;
    width: 215px;
    /* height: calc(100vh - 272px); */
    border: 1px solid #e7e7ed;
    box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);

    .title-section {
      text-transform: uppercase;
      padding: 24px;
      height: 58px;
      border-bottom: 1px solid #e7e7ed;
      font-size: 10px;
      line-height: 10px;
      color: #6b6c7e;
      font-weight: normal;
      display: flex;
      justify-content: space-between;
      svg {
        height: 12px;
        width: 12px;
      }
      label {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          margin-right: 4px;
          cursor: pointer;
        }
        .filter-dropdown-card {
          left: 0;
          min-width: 150px;
          cursor: pointer;
          text-transform: none;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .body-section {
      padding: 12px;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 600px;
      min-height: 550px;
      .list-section {
        margin-top: 16px;
        li {
          display: flex;
          cursor: pointer;
          margin-bottom: 12px;
          height: 32px;
          color: #6b6c7e;
          font-size: 12px;
          font-weight: normal;
          line-height: 15px;
          padding: 8px 0px;
          &.active {
            background: #f7f8f9;
            border-radius: 4px;
            color: #272833;
            position: relative;
            height: auto;
            span {
              min-width: 155px;
              max-width: 155px;
              span {
                font-weight: 600;
                p {
                  margin: 0px;
                  font-size: 10px;
                  line-height: 10px;
                  font-weight: normal;
                }
              }
            }
            .filter-item {
              margin-right: 6px;
              margin-left: 4px;
              svg {
                width: 11px;
                height: 11px;
              }
            }

            &:after {
              content: '';
              position: absolute;
              top: -18px;
              right: 12px;
              display: block;
              margin: 30px auto;
              width: 8px;
              height: 8px;
              border-top: 2px solid #0b5fff;
              border-left: 2px solid #0b5fff;
              transform: rotate(135deg);
              border-radius: 2px 2px 0 2px;
            }
          }
          &.inActive {
            span {
              min-width: 165px;
              p {
                margin: 0px;
                font-size: 10px;
                line-height: 10px;
                font-weight: normal;
              }
            }
          }
          .filter-item {
            margin-right: 6px;
            margin-left: 4px;
            svg {
              width: 11px;
              height: 11px;
            }
          }
          .filter-dropdown-card {
            min-width: 96px;
            left: -7px;
            height: 136px;
            .filter-content {
              padding: 18px 0px;

              li {
                margin-bottom: 0px;
                font-size: 14px;
                line-height: 21px;
                color: #272833;
                padding-left: 16px;
                svg {
                  width: 0px;
                  height: 0px;
                }
                &:hover {
                  background-color: #deebff;
                  color: rgb(39, 40, 51);
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CardStyled;
