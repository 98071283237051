import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SubDStockAvailabilityView from './View';
import { getUser } from '../../../data/services';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { stockReplacement } from '../../common/DomainConfig';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { breadCrumbConfig, filterConfig } from './config';
import { checkAndResetPaginatedData } from '../../../utils/paginatedListUtils';
import { labelConfig } from './tableConfig';
import { PAGINATION } from '../../../data/enums';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { clone } from '../../../utils/objectProcessor';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  getStockReplacementList: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class StockReplacement extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.userInfo = getUser();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
        upload: true,
      },
      distributorList: [],
      distributorId: this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0,
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = data => {
      this.setState({ data });
    };

    const { displayAlert, downloadReport } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { distributorId } = this.state;
    if (
      ADMIN_ROLE_GROUP.includes(this.userInfo.roleId) ||
      DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId)
    ) {
      this.getDistributorsList();
      this.getFilterStateFromStorage();
    }
    if (distributorId) this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.STOCK_REPLACEMENT
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.STOCK_REPLACEMENT);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          this.setState({ distributorList: response.data.distributors.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  loadTableData = () => {
    const { data, queryParameters, distributorId } = this.state;
    const { displayAlert, getStockReplacementList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getStockReplacementList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            { column: 'distributor_id', value: [distributorId.toString() || ''] },
          ],
          dateRange: queryParameters.date,
          // queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = response.data.getStockReplacementReport ? response.data.getStockReplacementReport.rows : [];
          data.total = response.data.getStockReplacementReport ? response.data.getStockReplacementReport.count : 1;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          checkAndResetPaginatedData(data, this.setData);
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleDistributorSelect = distributorId => {
    const { queryParameters } = this.state;
    queryParameters.pagination = {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    };
    this.setState({ distributorId, queryParameters }, () => this.loadTableData());
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const { data, queryParameters, display, distributorList, distributorId,filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    const componentDisplay = this.userInfo.Distributor.length === 0;
    const filterTableConfig = labelConfig.filter(d => d.label !== 'distributor');

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: stockReplacement.title,
                filter: !!distributorId,
                search: false,
                download: false,
                date: !!distributorId,
              }}
              downloadConfig={{
                domain: stockReplacement.domain,
              }}
              filter={{
                date: queryParameters.date,
                menuList:filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content section-select table-present">
          <SubDStockAvailabilityView
            data={data}
            componentDisplay={componentDisplay}
            distributorId={distributorId}
            loading={serverResponseWaiting}
            distributorList={distributorList}
            pagination={queryParameters.pagination}
            permission={this.permission}
            onDropDownChange={this.handleDistributorSelect}
            onPageChange={this.basePaginationService.onPageSelect}
            filterTableConfig={!componentDisplay ? labelConfig : filterTableConfig}
          />
        </div>
      </Fragment>
    );
  }
}

StockReplacement.propTypes = propTypes;

StockReplacement.defaultProps = defaultProps;

export default withAlert()(StockReplacement);
