import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { FILE_FORMAT_FRAGMENT, VENDOR_FRAGMENT } from '../../common/GqlFragments';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const PRN_AMOUNT_FRAGMENT = gql`
  fragment PrnAmountFragment on PRNAmount {
    total
    subTotal
    taxAmount
    billDiscount
    taxableAmount
    tradeDiscount
  }
`;

const PURCHASE_RETURN_FRAGMENT = gql`
  fragment PurchaseReturnFragment on PurchaseReturn {
    id
    date
    reason
    grnInvoiceNumber
    prnInvoiceNumber
    prnDate
    Vendor {
      ...VendorFragment
    }
    prnAmount {
      ...PrnAmountFragment
    }
  }
  ${VENDOR_FRAGMENT}
  ${PRN_AMOUNT_FRAGMENT}
`;

const GET_PRN_LIST = gql`
  query getPrnList($offset: Int, $limit: Int, $filter: FilterInput) {
    purchaseReturns(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...PurchaseReturnFragment
      }
      count
    }
  }
  ${PURCHASE_RETURN_FRAGMENT}
`;

const DOWNLOAD_PRN = graphql(
  gql`
    mutation ($input: fileDownloadInput!) {
      downloadPurchaseReturnDetail(input: $input) {
        file {
          ...fileFormatFragment
        }
      }
    }
    ${FILE_FORMAT_FRAGMENT}
  `,
  {
    props: ({ mutate }) => ({
      downloadPrn: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getPrnList: GET_PRN_LIST,
};
const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_PRN, PURCHASE_RETURN_FRAGMENT };

export { query, mutation };
