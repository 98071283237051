import styled from 'styled-components';

const ChequeWrapper = styled.div`
  .section-tab {
    height: 100%;
    border-top: 1px solid #e7e7ed;
    overflow: auto;
    &.r-detail-content {
      overflow: unset;
      margin-top: -9px;
    }
    &.no-border {
      border-top: 0;
    }
    &.section-tab {
      border-top: 0;
      height: calc(100vh - 118px);
      .react-tabs__tab-list {
        border-bottom: 1px solid #e7e7ed;
      }
    }
    #pending-tab {
      .modal-wrapper {
        .modal-inner.change_status .evolve-dialog__body {
          b {
            text-transform: capitalize;
          }
        }
      }
    }
    #received-tab {
      .modal-wrapper {
        .modal-inner.change_status .evolve-dialog__body {
          b {
            text-transform: capitalize;
          }
        }
      }
    }
  }
  .table-wrap {
    overflow: auto;
    .active {
      background: #f0f5ff;
      td {
        border-bottom: 1px solid #0c5fff;
      }
    }
    .filter-dropdown-card {
      .icon {
        display: none;
      }
    }
  }

  .alias-td {
    display: flex;
    & > div {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      line-height: 27px;
      border: 1px solid #e7e7ed;
      text-align: center;
      background-color: #ffffff;
      &.add {
        position: relative;
        z-index: 1;
        cursor: pointer;
      }
      &.count {
        background-color: #4b9bff;
        color: #ffffff;
        font-size: 14px;
        line-height: 29px;
        border: 1px solid #4b9bff;
        position: relative;
        left: -4px;
        cursor: pointer;
      }
    }
  }
`;

export default ChequeWrapper;
