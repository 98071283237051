import gql from 'graphql-tag';

const RECEIVED_CALLS = gql`
  query ReceiveCalls($offset: Int, $limit: Int, $filter: FilterInput) {
    calls(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        callDate
        type
        remarks
        notes
        callOrderValue {
          netAmount
        }
        User {
          id
          fullName
        }
        images {
          type
          src: images
        }
      }
      count
    }
  }
`;

const query = {
  receivedCalls: RECEIVED_CALLS,
};

export { query };
