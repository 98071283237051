import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DonutChartWithPercent from '../../../components/Chart/DonutChartWithPercentage';
import {
  defaultSalesData, getDataItem, salesColorSet
} from '../config';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import TitleText from '../../../components/Chart/TitleText';
import { compareList } from '../../../utils/arrayProcessor';
import { SALES } from '../../../data/enums/Dashboard';
import { convertObjToArray } from '../../../utils/objectProcessor';
import ChartWrap from '../callageChart/ChartWrap';
import SectionWithTitle from '../components/SectionWithTitle';

const propTypes = {
  dimensions: PropTypes.instanceOf(Object),
  onModuleClick: PropTypes.func.isRequired,
  TASSalesChart:PropTypes.bool,
};

const defaultProps = {
  dimensions: {},
  TASSalesChart:false,
};

class SalesChart extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(props, state) {
    const { unmodifiedSales } = props;
    const percentValue = unmodifiedSales && unmodifiedSales.received / unmodifiedSales.target;
    const percent = (isNaN(percentValue) ? 0 : percentValue) * 100; // hot-fix
    if (!compareList(props.data, state.serverData)) {
      let percentData = { ...state.percentData };
      const formattedData = props.data.filter((item) => {
        if (item.label === SALES.achievementpercent) {
          percentData = { label: '', value: percent };
        } else {
          return item;
        }
      });
      const filteredSalesData = formattedData.filter((d) => d.label !== 'target' && d.label !== 'achievement');
      return {
        salesData: filteredSalesData.filter((d) => d.label !== 'received'),
        splicePDFData: [...formattedData.filter((d) => d.label === 'target'), filteredSalesData[0]],
        serverData: [...props.data],
        mappedSalesData: formattedData
          .map((d) => ({
            label: d.label,
            value: d.label === 'target' ? 100 - percentData.value : percentData.value,
          }))
          .reverse(),
        percentData,
      };
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      salesData: convertObjToArray(defaultSalesData),
      splicePDFData: [],
      mappedSalesData: [],
      serverData: [],
      percentData: getDataItem(0),
    };
    this.title = 'Sales';
  }

  componentDidMount() {}

  render() {
    const { dimensions, onModuleClick,TASSalesChart } = this.props;
    const { salesData, percentData, splicePDFData, mappedSalesData } = this.state;

    // HOT FIX
    const filteredMappedSalesData = mappedSalesData.filter(
      (data) => data.label === 'target' || data.label === 'achievement',
    );
    return (
      <ChartWrap>
        <Link to={TASSalesChart?'/tas/pfd-reports':'/reports/pfd-sales-target'}>
          <SectionWithTitle title='Sales Order' id='sales'>
            <div className='chart-wrap'>
              <div className='donutchart'>
                <DonutChartWithPercent
                  id={this.title}
                  data={filteredMappedSalesData}
                  dimensions={dimensions}
                  percent={percentData}
                  colorSet={salesColorSet}
                />
              </div>
              {splicePDFData.length > 0 && (
                <Fragment>
                  <div className='donutlegend'>
                    {splicePDFData.map((item, key) => {
                      const textItem = { ...item };
                      textItem.value = fixedFloatAndCommas(textItem.value);
                      return (
                        <div
                          className={item.label === 'target' ? 'reference' : ''}
                          onClick={() => onModuleClick(item?.label)}
                          key={key}
                        >
                          <TitleText
                            id={`${this.title}-${key}`}
                            key={`${this.title}-${key}`}
                            data={textItem}
                            dimensions={dimensions}
                            TASSalesChart ={TASSalesChart}
                            styleAdjustment={key === 1 ? { marginTop: 30 } : { marginTop: 20 }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className='sales-order-li'>
                    {!TASSalesChart && salesData.map((d) => (
                      <TitleText
                        key={d.label}
                        id={d.label}
                        dimensions={dimensions}
                        styleAdjustment={{ marginTop: 5 }}
                        data={getDataItem(
                          d.value,
                          d.label === 'offroute_amount' ? 'off route' : d.label.replace('_amount', ' '),
                        )}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
            </div>
          </SectionWithTitle>
        </Link>
      </ChartWrap>
    );
  }
}

SalesChart.propTypes = propTypes;

SalesChart.defaultProps = defaultProps;

export default SalesChart;
