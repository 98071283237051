import gql from 'graphql-tag';


const GET_CALL_PLAN_LIST = gql`
  query GetCallPlanList($limit: Int, $offset: Int, $filter: FilterInput) {
    getCallPlanList(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        town
        route
        outlet
        channel
        category
        contact_verification
        call_target
        scheduled_time
        call_result
        call_time
        gross_amount
        unsuccessfull_reason
        notes
        p3m_average
        full_name
        distributor
        contact_info {
          name
          phoneNumber
        }
        last_invoiced {
          months
          days
        }
        last_called {
          months
          days
        }
        call_duration {
          minutes
          seconds
        }
      }
      count
    }
  }
`;

const GET_COUNT_CALL_PLAN_LIST = gql`
  query GetCountCallPlanList($filter: FilterInput) {
    getCountCallPlanList(filter: $filter) {
      count
    }
  }
`;

const GET_CALL_PLAN_DASHBOARD = gql`
query GetCallPlanDashboard {
  getCallPlanDashboard {
    sbdCount
    total_call_planned
    countTotalCAll
    total_call_plan_target
    total_call_completed
    productivity
    total_call_duration {
      minutes
      seconds
    }
    received_order_value
  }
}
`
const GET_SBD_FSKU= gql`
query GetSbdFsku {
  getSbdFsku {
    sbd_golden_points
    fsku
  }
}
`

const query = {
  getCallPlanList: GET_CALL_PLAN_LIST,
  getCallPlanDashboard:GET_CALL_PLAN_DASHBOARD,
  getCountCallPlanList:GET_COUNT_CALL_PLAN_LIST,
  getSbdFsku:GET_SBD_FSKU,
};

export { GET_CALL_PLAN_LIST };

export { query };
