import { fixedFloat, fixedFloatAndCommas } from '../../../../../utils/conversion';
import { Col, Row } from 'react-flexbox-grid';

const TotalView = ({ ...props }) => {
  const {
    viewType,
    refsObj,
    enableErrorDisplay,
    priceDetails,
    onInputChange,
  } = props;

  return (
    <>
      <div className='grn-sku-update'>
        <div className='grn-sku-update-inner'>
          <Row>
            <Col md={6}>
              <span>Sub Total</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails?.gross) ?? 0}
                {' '}
              </div>
            </Col>
            <Col md={6}>
              <span>Taxable Amount</span>
            </Col>
            <Col md={6}>
              <div>
                {' '}
                {fixedFloatAndCommas(priceDetails?.taxableAmount) ?? 0}
                {' '}
              </div>
            </Col>
            <Col md={6}>
              <span>VAT</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails?.taxAmount) ?? 0}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='grn-sku-update-total'>
        <span>Total</span>
        <div>
          {fixedFloatAndCommas(priceDetails?.netAmount) ?? 0}
        </div>
      </div>
    </>
  );
};

export default TotalView;