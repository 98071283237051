import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SectionWithTitle from '../components/SectionWithTitle';
import NumberCard from '../components/NumberCard';
import Number from '../components/Number';
import MonthlylWrap from '../monthyDetail/MonthlyWrap';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

const Delivery = ({ data }) => {
  const { delivered, dispatched, invoiced, salesReturn } = data;
  return (
    <MonthlylWrap id='delivery'>
      <SectionWithTitle title='delivery'>
        <Link
          to={{
            pathname: '/sales/order-fulfilment',
            state: { activeTab: 4 },
          }}
        >
          <NumberCard title='completed' subTitle='(Orders)'>
            <Number label='' number={delivered} size={24} numberPostfix />
          </NumberCard>
        </Link>
        <Link
          to={{
            pathname: '/sales/order-fulfilment',
            state: { activeTab: 1 },
          }}
        >
          <NumberCard title='pending' subTitle='(Orders)'>
            <Number label='' number={dispatched + invoiced} size={24} numberPostfix />
          </NumberCard>
        </Link>
        <Link to='/sales/srn'>
          <NumberCard title='sales return' subTitle='(NPR)'>
            <Number label='' number={salesReturn} size={24} />
          </NumberCard>
        </Link>
        {/* <NumberCard title="trips" subTitle=""> */}
        {/*  <Number label="" number={trip} size={24} /> */}
        {/* </NumberCard> */}
      </SectionWithTitle>
    </MonthlylWrap>
  );
};

Delivery.propTypes = propTypes;

export default Delivery;
