import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../../components';
import withLoading from '../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { SingleDatePicker } from '../../../../v4/components';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  type: '',
};

const TargetAchievementForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, type, handleDateChange } = props;

  return (
    <Form>
      <>
        <Input
          ref={ref => (refsObj.target = ref)}
          value={data.title}
          name="title"
          type="text"
          placeholder="title"
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={event => handleInputChange(event)}
          labelContent="Title"
          disabled={type === EVENT_OPERATION.READ}
          decimalWithPrecedingZero
        />
        <div className="single-date-picker one">
          <label>Start Date</label>
          <SingleDatePicker
            date={data.startDate}
            // maxDate={data.endDate}
            disabled={type === EVENT_OPERATION.READ}
            name="startDate"
            placeholder="Start Date"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={(name, date) =>
              handleInputChange(
                {
                  target: {
                    name: 'startDate',
                    value: date,
                  },
                  formattedValue: date,
                },
                '',
              )
            }
            labelContent="Start Date"
          />
        </div>

        <div className="single-date-picker two">
          <label>End Date</label>
          <SingleDatePicker
            date={data.endDate}
            // minDate={data.startDate}
            disabled={type === EVENT_OPERATION.READ}
            name="endDate"
            placeholder="End Date"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={(name, date) =>
              handleInputChange(
                {
                  target: {
                    name: 'endDate',
                    value: date,
                  },
                  formattedValue: date,
                },
                '',
              )
            }
            labelContent="End Date"
          />
        </div>
      </>
    </Form>
  );
};

TargetAchievementForm.propTypes = propTypes;

TargetAchievementForm.defaultProps = defaultProps;

const TargetAchievementFormWithErrorAndLoading = withLoading(TargetAchievementForm);

export default TargetAchievementFormWithErrorAndLoading;
