import styled from 'styled-components';

export const PurchaseOrderListStyled = styled.div`
  .single-date-picker {
    .filter-dropdown-card {
      top: 0;
      transform: translateY(calc(-100% - 8px));
    }
  }
  .react-tabs__tab-panel {
    .can-create-po {
      height: 100%;
      overflow: hidden;
      .table-wrap {
        height: calc(100% - 66px);
        overflow-y: auto;
      }
      &.has-pagination {
        .table-wrap {
          height: calc(100% - 125px);
        }
      }
    }
    .pagination-enable {
      height: 100%;
      overflow: hidden;
      .table-wrap {
        height: calc(100% - 61px);
        overflow-y: auto;
      }
    }
  }
  .react-tabs__tab-panel--selected {
    height: calc(100vh - 168px) !important;
  }
  .purchase-order-create-section {
    display: flex;
    padding: 12px 48px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: 1px solid #e7e7ed;
    background: #fff;
    p {
      color: #6b6c7e;
      font-size: 12px;
      font-weight: 600;
      line-height: 150%; /* 18px */
      margin: 0px;
    }
  }
  .modal-inner.read {
    max-width: 80%;
    height: 50vh;
    .info-section {
      padding: 24px;
      p {
        margin: 0px;
      }
      .bold-text {
        color: #272833;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 4px;
      }
      .lead-text {
        color: #6b6c7e;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .table-wrap {
      height: calc(50vh - 165px);
      overflow-y: auto;
    }
  }
`;
