import React, { ChangeEvent, useState } from 'react';
import BulkDeleteView from './BulkDeleteView';
import DialogConfirmation from '../DialogConfirmation';
import withAlert from '../../../utils/composition/withAlert';
import {
  handleFormSubmit,
  responseInterpreter,
} from '../../../utils/crudResponseProcessor';
import { extractObjectFromList } from '../../utils/arrayProcessor';
import { EVENT_OPERATION } from '../../constants/EventOperation';
import ModalWrapper from '../../../views/common/DialogFormWrapper';
import { CONFIRMATION_CONFIG } from '../../constants/formConfig';
import { MESSAGE_EVENT_OPERATION } from '../../constants/successMessage';
import { ALERT_TYPE } from '../../constants/AlertType';

const crudRequestConfig = {
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (element: any) => ({
      ids: [...element.data],
      type: element.type,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

const BulkDelete = ({ ...props }) => {
  const { data, type, render, replicate, onReplicateClick, onReplicateTypeClick, getCheckedListData, handler, displayAlert } = props;
  const [checkedList, setCheckedList] = useState<Array<Object>>([]);
  const initialdialog = {
    type: '',
    element: {},
  };
  const [dialog, setdialog] = useState(initialdialog);
  const handleClose = () => {
    setCheckedList([]);
    setdialog({ type: '', element: {} });
  };

  const handleApiFailure = (error: string) => {
    displayAlert(ALERT_TYPE.DANGER, error);
    handleClose();
  };

  const handleSuccessResponse = (type: string, response: any) => {
    if (!handler) displayAlert(ALERT_TYPE.SUCCESS, response.message);
    else handler(type, response);
  };

  const onCRUDSuccess = responseInterpreter(handleSuccessResponse);

  const serverCall = {
    [EVENT_OPERATION.BULK_DELETE]: props.useExternalAPI ? props.deleteServerCall : props.toggleState,
  };

  const onFormSubmit = handleFormSubmit(
    onCRUDSuccess,
    handleApiFailure,
    crudRequestConfig,
    serverCall,
  );

  const handlePrimaryCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const checked = extractObjectFromList(data);
      setCheckedList(checked);
      if (replicate) { getCheckedListData(checked); }
    } else {
      setCheckedList([]);
    }
  };

  const handleSecondaryCheckboxClick = (id: any) => {
    const index = checkedList.indexOf(id);
    if (index > -1) {
      checkedList.splice(index, 1);
      setCheckedList([...checkedList]);
    } else {
      checkedList.push(id);
      setCheckedList([...checkedList]);
    }
    if (replicate) {
      getCheckedListData(checkedList);
    }
  };

  const onDeleteClick = () => {
    setdialog({
      type: EVENT_OPERATION.BULK_DELETE,
      element: { data: checkedList, type },
    });
  };

  return (
    <>
      {dialog.type && (
        <ModalWrapper
          type={dialog.type}
          dialogData={dialog.element}
          formConfig={CONFIRMATION_CONFIG[dialog.type]}
          onDialogCancel={handleClose}
          onDialogSubmit={onFormSubmit}
          renderDialog={() => (
            <>
              {DialogConfirmation(
                EVENT_OPERATION.BULK_DELETE,
                checkedList.length,
              )}
            </>
          )}
        />
      )}
      <BulkDeleteView
        checkedListLength={checkedList.length}
        dataLength={data?.length}
        onIconClick={onDeleteClick}
        replicate={replicate}
        onReplicateClick={onReplicateClick}
        onReplicateTypeClick={onReplicateTypeClick}
      />
      {render(
        checkedList,
        handlePrimaryCheckboxClick,
        handleSecondaryCheckboxClick,
      )}
    </>
  );
};

export default withAlert()(BulkDelete);
