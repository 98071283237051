import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox, tableHeaderWithCheckBox } from '../../../v4/components';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { Link } from 'react-router-dom';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  outlet_count: 'outlet_count',
  town: 'town',
  assigned_dse: 'assigned_dse',
  visit_type: 'visit_type',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.outlet_count,
    title: 'Outlets',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.town,
    title: 'Town',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.assigned_dse,
    title: 'Assigned DSE',
    show: true,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

const returnTableBody = (data, checkedList, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={(e) => e.stopPropagation()} key={`${data.id}-checkbox`}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[labelMappings.title] = (
    <Link to={`route/details/${data.id}`}>
      <td key={`${data.id}-title`}>{data.title}</td>
    </Link>
  );
  table[labelMappings.outlet_count] = <td key={`${data.id}-outlet_count`}>{data.retailOutletCount}</td>;
  table[labelMappings.town] = <td key={`cash-${data.id}-town`}>{data.Town.title}</td>;
  table[labelMappings.assigned_dse] = (
    <td key={`cash-${data.id}-assigned_dse`}>
      {Array.from(new Set(data.assignedUsers.map((d) => d.fullName))).join(', ')}
    </td>
  );

  return table;
};

const TableBody = ({ data, checkedList, secondaryCheckboxHandler, permission, onTableBodyClick }) => {
  const tableBody = returnTableBody(data, checkedList, secondaryCheckboxHandler, permission, onTableBodyClick);

  return (
    <tr
      key={data.id}
      onClick={() => onTableBodyClick(EVENT_OPERATION.DETAILS, data.id, data.title)}
      style={{ cursor: 'pointer' }}
    >
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  checkedList: PropTypes.instanceOf(Array),
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  onTableBodyClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  checkedList: [],
  permission: PERMISSION_OBJ,
};
export { TableHeader, TableBody };
