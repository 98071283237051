import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import SITUploadForm from '../../../common/CSVUploadForm';
import { CustomSelect } from '../../../../components';
import { isConfirmationType } from '../../../common/HelperFunctions';
import Confirmation from '../../../common/DialogConfirmation';
import { Icon } from '../../../../v4/components';

const propTypes = {
  type: PropTypes.string.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  onUserSelect: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  dialogData: PropTypes.objectOf(Object),
  enableErrorDisplay: PropTypes.bool,
  userIds: PropTypes.arrayOf(Object),
  userList: PropTypes.arrayOf(Object),
  refsObj: PropTypes.objectOf(Object),
};

const defaultProps = {
  serverResponseWaiting: false,
  dialogData: {},
  enableErrorDisplay: false,
  userIds: [],
  userList: [],
  refsObj: {},
};

const DialogEntities = (props) => {
  const {
    type,
    dialogData,
    serverResponseWaiting,
    handleFileUpload,
    enableErrorDisplay,
    refsObj,
    userList,
    userIds,
    onUserSelect,
  } = props;
  return (
    <Fragment>
      {type === EVENT_OPERATION.UPLOAD && (
        <>
          <SITUploadForm
            show
            data={dialogData}
            loading={serverResponseWaiting}
            handleFileUpload={handleFileUpload}
            enableErrorDisplay={enableErrorDisplay}
            refsObj={refsObj}
          />
          <a href='https://rosia-sample-files.s3.ap-south-1.amazonaws.com/sampleSIT.csv'>
            <span className='sample-csv'>
              <Icon iconName='download' />
              Sample CSV
            </span>
          </a>
        </>
      )}
      {type === EVENT_OPERATION.SHARE && (
        <CustomSelect
          enableValidation
          options={userList}
          name='user-hare'
          className='custom-select'
          labelContent='Email'
          placeholder='Share with'
          getOptionValue={({ id }) => id}
          getOptionLabel={({ email }) => email}
          onChange={(event) => {
            onUserSelect(event);
          }}
          value={userList.filter(({ id }) => userIds.includes(id)) || []}
          isMulti
        />
      )}
      {isConfirmationType(type) && Confirmation(type)}
    </Fragment>
  );
};

DialogEntities.propTypes = propTypes;

DialogEntities.defaultProps = defaultProps;

export default DialogEntities;
