import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { normalPresentor } from '../../../utils/date';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import {
  Icon, Badge, CheckBox, tableHeaderWithCheckBox, menuAction
} from '../../../v4/components';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  campaign: 'campaign',
  category: 'category',
  messageDetail_platform: 'MessageDetail.platform',
  lastSent: 'lastSent',
  sentTime: 'sentTime',
  status: 'status',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: ' ',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.campaign,
    title: 'Campaign',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.category,
    title: 'Message Category',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.messageDetail_platform,
    title: 'Messaging Platform',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.lastSent,
    title: 'Last Sent',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.sentTime,
    title: 'Time',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'Replicate',
    icon: 'copy',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.REPLICATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

const returnTableBody = (data, checkedList, onSecondaryCheckBoxClick, permission, onIconClick) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`${data.id}-checkbox`} onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.title] = (
    <td key={`${data.id}-title`}>
      <Link to={`push-message/details/${data.id}`}>{data.title}</Link>
    </td>
  );
  table[labelMappings.campaign] = <td key={`${data.id}-campaign`}>{data.campaign}</td>;
  table[labelMappings.category] = <td key={`cash-${data.id}-messageCategory`}>{data.category}</td>;
  table[labelMappings.messageDetail_platform] = (
    <td key={`cash-${data.id}-messageDetail_platform`}>{data.MessageDetail.map((detail) => detail.platform)}</td>
  );
  table[labelMappings.lastSent] = (
    <td key={`cash-${data.id}-lastSent`}>{data.lastSent === null ? '' : normalPresentor(data.lastSent)}</td>
  );
  table[labelMappings.sentTime] = <td key={`cash-${data.id}-time`}>{data.sentTime}</td>;
  table[labelMappings.status] = (
    <td key={`${data.id}-status`}>
      {data.status && (
        <span className={data.status.toLowerCase()}>
          <span className='badge_border'>
            <Badge light>{data.status}</Badge>
            <span className='restore_status'>{data.restore && <Icon iconName='restore' />}</span>
          </span>
        </span>
      )}
    </td>
  );
  table[labelMappings.actionHolders] = (
    <td key={`${data.id}-actions`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {(permission.update||permission.delete)&&menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );
  return table;
};

const TableBody = ({ data, checkedList, secondaryCheckboxHandler, permission, onIconClick }) => {
  const tableBody = returnTableBody(data, checkedList, secondaryCheckboxHandler, permission, onIconClick);

  return <tr key={data.id}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  checkedList: PropTypes.instanceOf(Array),
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
