import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  Input
} from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { EMPTY, INVALID } from '../../../../../../data/enums/ErrorMessage';
import CustomSelectWithBtn from '../../../../../../components/CustomSelectWithBtn';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';
import { Switch, CheckBox } from '../../../../../../v4/components';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  vendorList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const DetailForm = ({ ...props }) => {
  const {
    data,
    permission,
    update,
    refsObj,
    vendorList,
    customerList,
    handleIconClick,
    distributorList,
    dropdownCallBack,
    checkboxStatus,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
  } = props;
  return (
    <>
      <Row>
        <Col md={12}>
          {/* <Row className='invoice-form'> */}
          {/* <Col md={3}>
              <div className='add-ledger-wrap'>
                <CustomSelectWithBtn
                  name='customerId'
                  enableValidation={data.isCash === false}
                  options={customerList}
                  placeholder='Select Customer Ledger'
                  labelContent='Select Customer Ledger'
                  enableErrorDisplay={data.isCash === false && permission.BILLING ? enableErrorDisplay : false}
                  onChange={(event) => handleDropDownChange(event.id, ['Customer', 'id'])}
                  value={customerList.filter(({ id }) => id === data.Customer.id)}
                  handleIconClick={handleIconClick}
                  data={data}
                  ref={data.isCash === false ? (ref) => (refsObj.customerId = ref) : null}
                  handleInputChange={handleInputChange}
                />
              </div>
            </Col>
            {data.Customer.id !== '' ? (
              <>
                {permission.BILLING && (
                  <>
                    <Col md={3}>
                      <div className='info'>
                        <Input
                          type='text'
                          name='billName'
                          enableValidation
                          value={data.billName}
                          placeholder='Bill Name'
                          labelContent='Bill Name'
                          disabled
                          onChange={(event) => handleInputChange(event)}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className='info'>
                        <label>Abbrevated Invoice</label>
                        <Switch
                          className='ledger-info'
                          checked={data.isAbbreviatedInvoice}
                          onToggle={(checked, event) => {
                            handleInputChange({
                              formattedValue: !!checked,
                              target: {
                                name: 'isAbbreviatedInvoice',
                                type: 'boolean',
                                value: !!checked,
                              },
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className='info payment-mode'>
                        <label>Payment Mode</label>
                        <CheckBox
                          className='ledger-info'
                          labelText='Cash'
                          checked={data.isCash}
                          onCheckBoxClick={() => {
                            handleInputChange({
                              formattedValue: !data.isCash,
                              target: {
                                name: 'isCash',
                                value: 'boolean',
                                checked: !data.isCash,
                              },
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </>
                )}
                <Col md={3} className={permission.BILLING ? 'mt-24 mb-24' : ''}>
                  <div className='info'>
                    <label>PAN</label>
                    <div className='ledger-info'>{data.Customer.panNumber}</div>
                  </div>
                </Col>
                <Col md={3} className={permission.BILLING ? 'mt-24' : ''}>
                  <div className='info'>
                    <label>Address</label>
                    <div className='ledger-info'>{data.Customer.address}</div>
                  </div>
                </Col>
                {permission.BILLING && (
                  <>
                    <Col md={3} className='mt-24'>
                      <div className='info'>
                        <label>Credit Limit</label>
                        <div className='ledger-info'>{fixedFloatAndCommas(data.Customer.creditLimit)}</div>
                      </div>
                    </Col>
                    <Col md={3} className='mt-24'>
                      <div className='info'>
                        <label>Credit Days</label>
                        <div className='ledger-info'>{data.Customer.creditDay}</div>
                      </div>
                    </Col>
                    <Col md={3} className='mt-24'>
                      <div className='info'>
                        <label>Outstanding Amount</label>
                        <div className='ledger-info'>
                          {data.Customer.outstandingAmount >= 0
                            ? `${fixedFloatAndCommas(data.Customer.outstandingAmount)}   Cr `
                            : `${fixedFloatAndCommas(data.Customer.outstandingAmount * -1)}  Dr `}
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </>
            ) : (
              <>
                {permission.BILLING && (
                  <>
                    <Col md={3}>
                      <div className='info'>
                        <Input
                          type='text'
                          name='billName'
                          enableValidation
                          value={data.billName}
                          placeholder='Bill Name'
                          labelContent='Bill Name'
                          onChange={(event) => handleInputChange(event)}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className='info'>
                        <label>Abbrevated Invoice</label>
                        <Switch
                          className='ledger-info'
                          checked={data.isAbbreviatedInvoice}
                          onToggle={(checked, event) => {
                            handleInputChange({
                              formattedValue: !!checked,
                              target: {
                                name: 'isAbbreviatedInvoice',
                                type: 'boolean',
                                value: !!checked,
                              },
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className='info payment-mode'>
                        <label>Payment Mode</label>
                        <CheckBox
                          className='ledger-info'
                          labelText='Cash'
                          checked={data.isCash}
                          onCheckBoxClick={() => {
                            handleInputChange({
                              formattedValue: !data.isCash,
                              target: {
                                name: 'isCash',
                                value: 'boolean',
                                checked: !data.isCash,
                              },
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </>
                )}{' '}
              </>
            )}
          </Row> */}
          <Row className='invoice-form'>
            <Col md={4}>
              <Input
                type='text'
                name='remarks'
                enableValidation
                value={data.remarks}
                placeholder='Remarks'
                labelContent='Remarks'
                ref={(ref) => (refsObj.remarks = ref)}
                enableErrorDisplay={enableErrorDisplay && permission.BILLING}
                onChange={(event) => handleInputChange(event)}
              />
            </Col>

            {!permission.BILLING && (
              <Col md={4}>
                <Input
                  type='text'
                  enableValidation
                  name='invoiceNumber'
                  placeholder='Invoice Number'
                  value={data.invoiceNumber}
                  rule='matches'
                  argument={/^[0-9]+$/g} /* matches only numbers */
                  labelContent='Invoice Number'
                  onChange={(event) => handleInputChange(event)}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

DetailForm.propTypes = propTypes;

DetailForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(DetailForm);

export default LoginViewWithErrorAndLoading;
