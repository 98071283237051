import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_MASTER_DATA, TOGGLE_STATE } from '../../../common/GqlAPI';
import { GET_USER_LIST } from '../../user/userAccount/API';
import { GET_ROLE_LIST } from '../../user/userRole/API';

const GET_SYSTEM_ALERT_DETAIL = gql`
  query getSystemAlertDetail($id: Int) {
    systemAlertDetail(id: $id) {
      id
      title
      sendNotifications
      attribute
      threshold {
        value
        operator
        dimension
      }
      audienceFilter {
        platform
        userRole
        userIds
      }
      messageDetail: message {
        title
        description
        link
        message
      }
      repeatTimes
      repeatDays
      startDate
      endDate
      time
    }
  }
`;

const GET_DICTIONARY_LIST = gql`
  query getDataDictionaryList($type: AudienceType!) {
    getDataDictionaryList(type: $type) {
      attribute
      type
    }
  }
`;

const SEND_SYSTEM_ALERT = graphql(
  gql`
    mutation ($input: SystemAlertInput!) {
      sendSystemAlert(input: $input) {
        id
        attribute
        threshold {
          value
          operator
          dimension
        }
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      sendSystemAlert: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const EDIT_SYSTEM_ALERT = graphql(
  gql`
    mutation ($id: Int!, $input: SystemAlertInput!) {
      editSystemAlert(id: $id, input: $input) {
        id
        attribute
        threshold {
          value
          operator
          dimension
        }
        message {
          title
          description
          link
          message
        }
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      editSystemAlert: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getSystemAlertDetail: GET_SYSTEM_ALERT_DETAIL,
  getMasterData: GET_MASTER_DATA,
  getUserRoleList: GET_ROLE_LIST,
  getUserList: GET_USER_LIST,
  getDataDictionaryList: GET_DICTIONARY_LIST,
};

const mutation = {
  sendSystemAlert: SEND_SYSTEM_ALERT,
  editSystemAlert: EDIT_SYSTEM_ALERT,
  toggleState: TOGGLE_STATE,
};

export {
  query, mutation, SEND_SYSTEM_ALERT, EDIT_SYSTEM_ALERT, TOGGLE_STATE
};
