import gql from 'graphql-tag';
import { GET_TOWN_LIST } from '../../views/configuration/town/API';
import { GET_TERRITORY_LIST } from '../../views/configuration/territory/API';
import { DEFAULT_QUERY_VARIABLES, errorProcessor } from './common';
import { persistentClient as clientResolver, client as apolloClient } from '../../apiClient/apollo';

const TABLE = {
  TOWN: 'town',
  ZONE: 'zone',
};

const getTownListFromServer = () =>
  apolloClient
    .query({
      query: GET_TOWN_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then((response) => response.data.towns.rows)
    .catch((err) => errorProcessor(err));

const getZoneListFromServer = () =>
  apolloClient
    .query({
      query: GET_TOWN_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })

    .then((response) => response.data.towns.rows)
    .catch((err) => errorProcessor(err));

const query = gql(`
    query getTownList {
        ${TABLE.TOWN} {
            id
            title
        }
    }
`);

const setTown = (data) => {
  clientResolver.then((client) =>
    client.writeQuery({
      query,
      data: {
        [TABLE.TOWN]: data,
      },
    }),
  );
};

const fetchAndSetTownList = async () => {
  const townList = await getTownListFromServer();
  setTown(townList);

  return townList;
};

const fetchAndSetTasList = async () => {
  const townList = await getTownListFromServer();
  setTown(townList);

  return townList;
};

const getTown = async () =>
  clientResolver.then((client) => client.readQuery({ query })).then((response) => response[TABLE.TOWN]);

const getTerritoryListFromServer = () =>
  apolloClient
    .query({
      query: GET_TERRITORY_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then((response) => response.data.territories.rows)
    .catch((err) => errorProcessor(err));

export {
  getTownListFromServer as getTown,
  getZoneListFromServer as getZone,
  fetchAndSetTownList,
  getTerritoryListFromServer as getTerritory,
};
