import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { getICOGroupApi } from '../../../../ICOGroup/ICOGroupMaster/Repository/API';
import { TOGGLE_STATE } from '../../../../../common/GqlAPI';

const ICO_STOCK_GROUPS_LIST =  gql`
query icoStockGroupsList($limit: Int, $offset: Int, $filter: FilterInput) {
  icoStockGroupsList(limit: $limit, offset: $offset, filter: $filter) {
    rows {
      id
      doh
      frequency {
        type
        day
        date
        time
        dayOfTheMonth
      }
      icoGroups {
        id
        title
        alias
        active
      }
    }
    count
  }
}
`;

const CREATE_ICO_STOCK_GROUPS = graphql(
  gql`
  mutation createICOStockGroups($input: stockIcoGroupInput!) {
    createICOStockGroups(input: $input) {
      id
      doh
      frequency {
        type
        day
        date
        time
        dayOfTheMonth
      }
      icoGroups {
        id
        title
        alias
        active
      }
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      createICOStockGroups: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_ICO_STOCK_GROUPS = graphql(
  gql`
  mutation updateICOStockGroups($input: stockIcoGroupInput!, $id: Int) {
    updateICOStockGroups(input: $input, id: $id) {
      id
      doh
      frequency {
        type
        day
        date
        time
        dayOfTheMonth
      }
      icoGroups {
        id
        title
        alias
        active
      }
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      updateICOStockGroups: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getICOGroupList: getICOGroupApi,
  icoStockGroupsList: ICO_STOCK_GROUPS_LIST,
};

const mutation = {
  createICOStockGroups: CREATE_ICO_STOCK_GROUPS,
  updateICOStockGroups: UPDATE_ICO_STOCK_GROUPS,
  toggleState: TOGGLE_STATE,
};

export {
  query, mutation, CREATE_ICO_STOCK_GROUPS, UPDATE_ICO_STOCK_GROUPS, TOGGLE_STATE
};
