import React from 'react';
import { Icon, CheckBox } from '../../../../../../v4/components';

let dataLength = 10;
const BulkTransferView = ({ checkedList, onIconClick, checkedListLength }) =>
  checkedListLength > 0 && (
    <div className='selected-status'>
      <div className='content-center'>
        <div className='total-select'>
          <CheckBox checked className='disabled-btn' />
          <p className='text'>
            <span>{checkedListLength} outlets selected</span>
          </p>
        </div>
        <div className='del-btn' onClick={() => onIconClick(checkedList)}>
          <Icon iconName='import-export' />
        </div>
      </div>
    </div>
  );

export default BulkTransferView;
