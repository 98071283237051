const FILE_STATUS_MAPPER = {
  EMPTY: 'empty',
  PENDING: 'pending',
  SUCCESS: 'success',
  DANGER: 'danger',
};

const FileObj = {
  url: '',
  size: 0,
  file: {},
  status: FILE_STATUS_MAPPER.EMPTY,
  title: '',
  thumbnail: '',
};

const getFileObj = (elm = {}) => FileObj;

export { FILE_STATUS_MAPPER, getFileObj };
