import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import { TableBody, TableHeader } from './tableConfig';
import { Pagination } from '../../../v4/components';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
  breadCrumb: PropTypes.instanceOf(Array),
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  onRowButtonClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  breadCrumb: [],
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const { data, loading, pagination, onPageChange, onRowButtonClick } = props;

  return (
    <Fragment>
      <div className='table-wrap'>
        <table>
          {TableHeader()}
          <tbody>{data.list.map((leaveRequest) => TableBody({ data: leaveRequest, loading, onRowButtonClick }))}</tbody>
        </table>
      </div>
      <Pagination
        totalItems={data.total}
        limit={pagination.limit}
        onPageChange={onPageChange}
        currentPage={pagination.page}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
