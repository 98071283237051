import React from 'react';
import PropTypes from 'prop-types';
import {
  BreadCrumb, Icon ,Button
} from '../../../../../v4/components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withLoading from '../../../../../utils/composition/withLoading';
import { PanelStyled, PanelHeader } from '../../../../common/configuration';
import DetailContent from './detailContent';
import { hierarchy } from './config';
import CatalogStyled from '../../CatalogStyled';
import { dataProps } from '../../../../../data/enums/Component';

const propTypes = {
  data: dataProps.value,
  onInputChange: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool.isRequired,
  handleDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.instanceOf(Object).isRequired,
  menu: PropTypes.instanceOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
  handleButtonCancel: PropTypes.func.isRequired,
  handleButtonSubmit: PropTypes.func.isRequired,
  crudMode: PropTypes.oneOf([EVENT_OPERATION.READ, EVENT_OPERATION.CREATE, EVENT_OPERATION.UPDATE]).isRequired,
  handleEditIconClick: PropTypes.func.isRequired,
  breadCrumb: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  getHeader: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  handleImagesFetch: PropTypes.func.isRequired,
  catalogId: PropTypes.number,
};

const defaultProps = {
  data: dataProps.defaultValue,
  catalogId: 0,
};

const DetailView = ({ ...props }) => {
  const {
    data,
    refsObj,
    onInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleButtonCancel,
    handleButtonSubmit,
    crudMode,
    handleEditIconClick,
    menu,
    breadCrumb,
    permission,
    getHeader,
    loading,
    catalogId,
    handleImagesFetch,
    onDeleteClick,
  } = props;

  const showEditButton = crudMode === EVENT_OPERATION.READ;

  const detailBreadCrumb = {
    id: 2,
    title: hierarchy[catalogId].label || 'Catalog Detail',
    path: `/configuration/catalog/${catalogId}`,
    active: true,
  };

  const header = getHeader();
  return (
    <CatalogStyled>
      <div className='section-header'>
        <PanelStyled>
          <BreadCrumb list={[...breadCrumb, detailBreadCrumb]} />
          <PanelHeader>
            <h2 className='user-title'>
              {crudMode === EVENT_OPERATION.CREATE ? `Create ${hierarchy[catalogId].label}` : header}
            </h2>
            <div className='flex m-0'>
              {crudMode !== EVENT_OPERATION.READ && (
                <div className='button-wrap'>
                  <Button secondary small disabled={loading} title='Cancel' onClick={() => handleButtonCancel()} />
                  <Button primary small disabled={loading} title='Save' onClick={() => handleButtonSubmit()} />
                </div>
              )}
              {showEditButton ? (
                <div>
                  {permission.delete && (
                    <Button
                      secondary
                      iconBtnSmall
                      onClick={() => onDeleteClick(EVENT_OPERATION.DELETE)}
                      className='ml-16'
                    >
                      <Icon iconName='trash' />
                    </Button>
                  )}
                  {permission.update && (
                    <Button secondary iconBtnSmall onClick={handleEditIconClick} className='ml-16'>
                      <Icon iconName='pencil' />
                    </Button>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content'>
        <DetailContent
          data={data}
          refsObj={refsObj}
          onInputChange={onInputChange}
          enableErrorDisplay={enableErrorDisplay}
          handleDropDownChange={handleDropDownChange}
          crudMode={crudMode}
          menu={menu}
          catalogId={catalogId}
          handleImagesFetch={handleImagesFetch}
        />
      </div>
    </CatalogStyled>
  );
};

DetailView.propTypes = propTypes;

DetailView.defaultProps = defaultProps;

const UserAccountDetailForm = withLoading(DetailView);

export default UserAccountDetailForm;
