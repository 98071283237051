export const ALERT_TYPE = {
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  CUSTOM_DANGER: 'custom danger',
};

export const ALERT_CONFIG = {
  [ALERT_TYPE.SUCCESS]: {
    timeout: 5000,
    title: 'Success',
    iconName: 'check-circle-full',
  },
  [ALERT_TYPE.DANGER]: {
    timeout: 5000,
    title: 'Danger',
    iconName: 'exclamation-full',
  },
  [ALERT_TYPE.CUSTOM_DANGER]: {
    timeout: 5000,
    title: 'Danger',
    iconName: 'exclamation-full',
  },
  [ALERT_TYPE.INFO]: {
    timeout: 4000,
    title: 'Info',
    iconName: 'info-circle',
  },
  [ALERT_TYPE.WARNING]: {
    timeout: 2000,
    title: 'Warning',
    iconName: 'warning-full',
  },
};