import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../v4/components';
import { DialogBody, DialogFooter } from '../../../components';
import LoginStyled from '../LoginStyled';

const propTypes = {
  onDialogCancel: PropTypes.func,
};

const defaultProps = {
  onDialogCancel: () => null,
};

const Confirmation = ({ ...props }) => {
  const { onDialogCancel, forgot } = props;

  return (
    <LoginStyled>
      <div className='modal-wrapper'>
        <div className='modal-inner'>
          <DialogBody>
            <p className='mb-24'>
              If an account exists for user <b>{forgot}</b>, you will get an email with a link to create new password.
            </p>
            <p>If it doesn’t arrive, be sure to check your spam folder.</p>
          </DialogBody>
          <DialogFooter>
            <Button small borderless onClick={(e) => onDialogCancel(e)}>
              <span>Back to Login</span>
            </Button>
          </DialogFooter>
        </div>
      </div>
    </LoginStyled>
  );
};

Confirmation.propTypes = propTypes;

Confirmation.defaultProps = defaultProps;

export default Confirmation;
