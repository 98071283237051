import { compose } from 'recompose';

import Details from './Details';
import { query, mutation } from './API';
import { DOWNLOAD_REPORT } from '../../../../common/GqlAPI';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedSubDAssortmentDetails = compose(DOWNLOAD_REPORT, withRequestTracker({ query, mutation }))(Details);

export default composedSubDAssortmentDetails;
