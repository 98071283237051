import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Merchandise Report';

const breadCrumb = [reportsRoute];

const filter = getFilterConfig([FILTER.SUB_D, FILTER.DSE, FILTER.MERCHANDISE_DEVICE]);

export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, title
};
