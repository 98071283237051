import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_SKU_COVERAGE_LIST = gql`
  query getSKUCoveragReport($filter: FilterInput) {
    getSkuCoverage(filter: $filter) {
      coverage
      rows {
        id
        name
        coverage
        active_coverage
        sku_coverage
        coverage_percent
      }
      count
    }
  }
`;

const query = {
  getSKUCoverageReport: GET_SKU_COVERAGE_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
