import React from 'react';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { Button, Icon } from '../../../../../v4/components';
import { CallPlanDrawerContainer } from './CallPlanDrawer.styled';
import { TableBody, TableHeader } from './tableConfig';

const CallPlanDrawer = ({ showSummaryOverlay, toggleOverlay, closeOverlay, overlaySummary, callTargetSummaryDetails, handleCallTarget, saveCallTarget, isCompleted }) => {
  const { totalLines, netAmount } = overlaySummary;

  return <CallPlanDrawerContainer>
    {showSummaryOverlay
      ? <div className='overlay' />
      : null
    }
    <div className={`drawer-container${showSummaryOverlay ? ' active' : ''}`}>
      <div className='call-target-title'>
        <Icon iconName={`${showSummaryOverlay ? 'angle-down' : 'angle-up'}`} onClick={toggleOverlay} />
        <h3>Call Target</h3>
        <div className='call-target-summary'>
          <div className='summary'>
            <label>Total Lines</label>
            <div className={`call-target-value${totalLines > 0 ? ' primary' : ''}`}>{totalLines}</div>
          </div>
          <div className='summary'>
            <label>Net Amount</label>
            <div className={`call-target-value${totalLines > 0 ? ' primary' : ''}`}>{netAmount ? `Rs. ${fixedFloatAndCommas(netAmount)}` : '-'}</div>
          </div>
        </div>
        {showSummaryOverlay
          ? <Button iconName='cross' onClick={closeOverlay} />
          : !isCompleted && <Button primary title='Save Call Plan' onClick={saveCallTarget} />}
      </div>
      {showSummaryOverlay ? <div className='call-plans-details-summary'>
        <table>
          {TableHeader()}
          <tbody>
            {callTargetSummaryDetails.length > 0 && callTargetSummaryDetails.map((skuCategory) => (
              skuCategory.skus.length > 0 ?
                <React.Fragment key={skuCategory.title}>
                  <tr>
                    <td id='sku-title' colSpan={4}>{skuCategory.title}</td>
                  </tr>
                  {skuCategory.skus.map((sku) => TableBody({
                    data: sku,
                    label: skuCategory.label,
                    handleCallTargetChange: handleCallTarget
                  }))}
                </React.Fragment>
                : null
            ))}
          </tbody>
        </table>
      </div> : null}
      <div className='save-call-plan-btn' >
        {showSummaryOverlay ? !isCompleted && <Button primary title='Save Call Plan' onClick={saveCallTarget} /> : null}
      </div>
    </div>
  </CallPlanDrawerContainer >
};

CallPlanDrawer.defaultProps = {
  callTargetSummaryDetails: [],
  showSummaryOverlay: false,
  toggleOverlay: () => { },
  overlaySummary: {
    totalLines: 0,
    netAmount: 0
  },
  handleCallTarget: () => { },
}
export default CallPlanDrawer;
