import PropTypes from 'prop-types';
import React from 'react';
import { Search } from '../../../../../../components';
import { Switch } from '../../../../../../v4/components';
import Popover from '../../../../../../components/Popover';

const propTypes = {
  onSearchInputChange: PropTypes.func.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  skuFamilies: PropTypes.instanceOf(Array),
  searchText: PropTypes.string,
  selectedSkus: PropTypes.instanceOf(Array),
};

const defaultProps = {
  skuFamilies: [],
  searchText: '',
  selectedSkus: [],
};

const SkuFamily = ({
  alreadySelectedSku,
  selectedSkus,
  onSwitchChange,
  skuFamilies,
  searchText,
  onSearchInputChange,
  permission,
  loading,
}) => {
  const checkIfAlreadyIncludedSkuIsPresent = (id) => {
    return alreadySelectedSku?.map((a) => a.sku_family_id)?.includes(id);
  };
  const totalSkuIds =
    skuFamilies
      ?.filter((item) => !checkIfAlreadyIncludedSkuIsPresent(item.id))
      ?.map((item) => item.skus.map((sku) => sku.id))
      .flat() || [];

  const onSKUFamilySwitchChange = (checked, skus) => {
    const skuIds = skus.map((sku) => sku.id) || [];
    onSwitchChange(checked, skuIds);
  };
  const onPrimarySwitchChange = (checked) => {
    onSwitchChange(checked, totalSkuIds);
  };
  const getSKUFamilySwitchStatus = (skus) => skus.every((sku) => selectedSkus.includes(sku.id));

  const getNumberOfSkus = (popUpSKUs) => {
    return (
      <div className='pop-up-sku'>
        {popUpSKUs.map((sku) => (
          <span>{sku?.title} </span>
        ))}
      </div>
    );
  };

  return (
    <div className='panel three'>
      <div className='panel-list'>
        <div className='panel-title'>
          <p className='p-title'>SKU Family / SKUs</p>
          <Search clearText={!searchText} searchText={searchText} handleSearchInput={onSearchInputChange} />
        </div>
        <div className='table-wrap'>
          <table>
            <thead>
              <tr>
                <th>SKU Family</th>
                <th className='text-right'>No. of SKU</th>
                <th className='text-right'>
                  <div className='status'>
                    <span>Status</span>
                    {!searchText && skuFamilies?.length > 0 && (
                      <Switch
                        checked={totalSkuIds.length === selectedSkus.length && totalSkuIds.length !== 0}
                        onToggle={(e) => onPrimarySwitchChange(e)}
                        disabled={!permission?.update || loading || totalSkuIds?.length === 0}
                      />
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {skuFamilies.map((skuFamily) => (
                <tr key={skuFamily.id}>
                  <td>{skuFamily.title}</td>
                  <td className='text-right popover_td'>
                    {/* {skuFamily.skus.length} */}

                    {skuFamily.skus.length ? (
                      <div className='simple-popup'>
                        <Popover popoverIcon={skuFamily.skus.length} headerTitle={skuFamily?.title}>
                          <span className='arrow-up' />
                          {getNumberOfSkus(skuFamily.skus)}
                        </Popover>
                      </div>
                    ) : (
                      0
                    )}
                  </td>
                  <td className='text-right'>
                    {
                      <Switch
                        checked={getSKUFamilySwitchStatus(skuFamily.skus)}
                        onToggle={(e) => onSKUFamilySwitchChange(e, skuFamily.skus || [])}
                        disabled={!permission?.update || loading || checkIfAlreadyIncludedSkuIsPresent(skuFamily.id)}
                      />
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

SkuFamily.propTypes = propTypes;

SkuFamily.defaultProps = defaultProps;

export default SkuFamily;
