import { compose } from 'recompose';
import { query } from './API';
import Cheques from './Cheques';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedCheques = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation: {
      downloadReport: DOWNLOAD_REPORT,
    },
  }),
)(Cheques);

export default composedCheques;
