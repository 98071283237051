import styled from 'styled-components';

const RouteSegmentationStyled = styled.div`
  .title-section {
    padding: 32px 48px;
    .form-input {
      width: 22%;
    }
  }

  .panel-section {
    display: flex;
    .panel {
      background-color: #ffffff;
      border-radius: 4px;

      &.three {
        flex: 1;
        .panel-title {
          padding: 24px 48px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > span {
            font-size: 14px;
            line-height: 21px;
          }
        }
        .div-tbody .collapsible-inner,
        .div-tbody .header-wrap,
        .div-thead {
          border-bottom: 1px solid #e7e7ed;
          display: flex;
          padding: 12px 48px;
          align-items: center;
          justify-content: space-between;
          background-color: #f1f2f5;
          font-size: 12px;
          line-height: 15px;
          color: #6b6c7e;
          font-weight: 500;
          .status {
            display: flex;
            align-items: center;
            width: 40%;
            .form-input {
              margin-left: 12px;
            }
          }
        }

        .div-tbody {
          height: calc(100vh - 375px);
          overflow-y: auto;
          background-color: #fbfbfc;
          .header {
            .panel-title {
              padding: 0px;
            }
            .collapsible-inner {
              width: 100%;
              .status {
                width: 35%;
              }
            }
            .header-wrap {
              background-color: #ffffff;
              padding: 15px 48px;
              .status {
                width: 40%;
              }
            }
            .header-wrap {
              cursor: pointer;
              &:hover {
                background: #f0f5ff;
              }
              & > span {
                position: relative;
                &::before {
                  content: '';
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: 6px solid #6b6c7e;
                  transform: rotate(0deg);
                  transition: all 0.23s ease-in-out;
                  position: absolute;
                  top: 6px;
                  left: 0;
                }
                .text {
                  margin-left: 30px;
                }
                .badge {
                  margin-left: 8px;
                }
              }
            }

            &.active {
              margin: 16px 0;
              transition: all 0.4s ease-out;
              box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
              .header-wrap {
                background-color: #f0f5ff;
                border-bottom: 1px solid #0b5fff;
                span::before {
                  transform: rotate(180deg);
                  transition: all 0.23s ease-in-out;
                  border-top: 6px solid #0b5fff;
                  top: 5px;
                }
              }
              .collapsible-inner {
                & > span {
                  margin-left: 30px;
                }
                /* &:hover{
                    background: #F0F5FF;
                  } */
              }
            }

            &.inactive {
              margin: 0;
              transition: all 0.4s ease-in;
              top: 28px;
            }
          }
        }
      }
    }
  }
`;

export default RouteSegmentationStyled;
