import React, { Component } from 'react';
import Modal from '../../components/Modal/Modal';
// import Select from 'react-select';
import SingleRoute from '../SingleRoute/SingleRoute';
import RoutesListStyled from './RoutesListStyled';
import zeroRoutesImage from '../../assets/zero_routes_image.svg';
import RouteCards from '../RouteCards/RouteCards';
import { CustomSelect } from '../../../../../components';
import { Icon } from '../../../../../v4/components';
import MultiDropDown from '../../components/MultiDropDown/MultiDropDown';
import RenameModal from '../../components/RenameModal/RenameModal';
import ARCLoading from '../../components/ARCLoading/ARCLoading';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';

class RoutesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickedRouteId: '',
      showSingleRoute: false,
      newOrderList: [],
      optimumMarkersBeingShown: [],
      enableEdit: false,
      routesList: props.routesList,
      showModal: false,
      townsList: [],
      selectedTowns: [],
      importedRoutesList: [],
      selectedRoutes: [],
      importedRouteOutlets: [],
      singleRoutesClonedMarkers: [],
      disableRender: 'true',
      showRenameModal: false,
      toRenameRouteIndex: null,
      toRemoveRouteIndex: null,
      showDeleteModal: false,
      closeDeleteModal: false,
      loadingTownOutlets: false,
      saveSuccess: false,
      buttonDisable: true,
      importedRoutes: [],
      townIds: [],
      routeIds: [],
      showAll: false,
    };
  }

  componentDidMount() {
    this.props.getTownList(
      {},
      {
        handleSuccess: res => {
          this.setState({ townsList: res.data.towns.rows });
        },
      },
    );
    this.props.deleteOutletsFromRoutes(this.deleteCurrentOutlets, this.handleMainReset, this.goBack);
  }

  routeClick = (routeIndex, i) => {
    this.setState({
      clickedRouteId: routeIndex,
    });
    this.setState({ enableEdit: false });

    const routesToHide = this.props.routeLines.filter((route, i) => route.dse !== routeIndex);
    routesToHide.map(routeToHide => routeToHide.setVisible(false));
    /* Hide Appropriate Polylines--end */

    // gets ids of only those markers that are in selected route
    let requiredRoute = this.props.routesList.find(route => route.dse === routeIndex);
    const requiredVisibleMarkersIds = requiredRoute.travel.map(outlet => outlet.id);
    // gets instances of markers that are not in selected route
    const fancyMarkersToHide = this.props.visibleFancyMarkersInst.filter(
      markerInst => !requiredVisibleMarkersIds.includes(markerInst.id),
    );
    fancyMarkersToHide.forEach(marker => {
      marker.setVisible(false);
    });
    // gets instances of markers that are in selected route for rendering after dragdrop reorder
    const fancyMarkersToShow = this.props.visibleFancyMarkersInst.filter(markerInst =>
      requiredVisibleMarkersIds.includes(markerInst.id),
    );

    // removes duplicates
    const unique = fancyMarkersToShow.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

    unique.sort((a, b) => {
      return requiredVisibleMarkersIds.indexOf(a.id) - requiredVisibleMarkersIds.indexOf(b.id);
    });

    if (fancyMarkersToShow.length > 0) {
      let currFirstMarker = fancyMarkersToShow[0];
      let currLastMarker = fancyMarkersToShow[fancyMarkersToShow.length - 1]

      if ((currFirstMarker.id === currLastMarker.id) && currLastMarker.distance === "Last") {
        unique.push(currLastMarker)
      }
    }

    this.setState({
      optimumMarkersBeingShown: unique,
      newOrderList: unique,
      showSingleRoute: true,
    });
  };

  handleShowAllClick = () => {
    const { showAll, clickedRouteId } = this.state;
    this.setState({ showAll: !showAll }, () => {
      if (!showAll) {
        //for loop relatively faster
        if (this.props.visibleFancyMarkersInst.length > 0) {
          for (let i = 0; i < this.props.visibleFancyMarkersInst.length; i++) {
            this.props.visibleFancyMarkersInst[i].setVisible(true);
          }
        }
        for (let i = 0; i < this.props.routeLines.length; i++) {
          this.props.routeLines[i].setVisible(true);
        }
      } else {
        const routesToHide = this.props.routeLines.filter((route, i) => route.dse !== clickedRouteId);
        routesToHide.map(routeToHide => routeToHide.setVisible(false));
        /* Hide Appropriate Polylines--end */

        // gets ids of only those markers that are in selected route
        let requiredRoute = this.props.routesList.find(route => route.dse === clickedRouteId);
        const requiredVisibleMarkersIds = requiredRoute.travel.map(outlet => outlet.id);
        // gets instances of markers that are not in selected route
        const fancyMarkersToHide = this.props.visibleFancyMarkersInst.filter(
          markerInst => !requiredVisibleMarkersIds.includes(markerInst.id),
        );
        fancyMarkersToHide.forEach(marker => {
          marker.setVisible(false);
        });
        // gets instances of markers that are in selected route for rendering after dragdrop reorder
        const fancyMarkersToShow = this.props.visibleFancyMarkersInst.filter(markerInst =>
          requiredVisibleMarkersIds.includes(markerInst.id),
        );

        // removes duplicates
        const unique = fancyMarkersToShow.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

        unique.sort((a, b) => {
          return requiredVisibleMarkersIds.indexOf(a.id) - requiredVisibleMarkersIds.indexOf(b.id);
        });
        let currFirstMarker = fancyMarkersToShow[0];
        let currLastMarker = fancyMarkersToShow[fancyMarkersToShow.length - 1];

        if (currFirstMarker.id === currLastMarker.id && currLastMarker.distance === 'Last') {
          unique.push(currLastMarker);
        }

        this.setState({
          optimumMarkersBeingShown: unique,
          newOrderList: unique,
        });
      }
    });
  };

  saveRoutes = () => {
    let formattedARCRoutes = [];
    this.props.routesList.forEach(route => {
      if (route.travel.length > 0) {
        let routeOutlets = [];
        let startRandomOutletPos;
        let endRandomOutletPos;
        let loopCounter = 0;
        let loopLength = route.travel.length;
        if (route.travel[0].town === 'random town') {
          loopCounter = 1;
          loopLength = loopLength - 1;
          startRandomOutletPos = {
            geoLocation: {
              latitude: Number(route.travel[0].lat),
              longitude: Number(route.travel[0].lng),
            },
          };
          endRandomOutletPos = {
            geoLocation: {
              latitude: Number(route.travel[route.travel.length - 1].lat),
              longitude: Number(route.travel[route.travel.length - 1].lng),
            },
          };
          for (let i = loopCounter; i < loopLength; i++) {
            routeOutlets.push({
              id: route.travel[i].id,
              routeSequence: route.travel[i].order - 1,
            });
          }
        } else {
          startRandomOutletPos = null;
          endRandomOutletPos = null;
          for (let i = loopCounter; i < loopLength; i++) {
            routeOutlets.push({
              id: route.travel[i].id,
              routeSequence: i + 1,
            });
          }
        }

        formattedARCRoutes.push({
          id: route.id,
          title: route.name,
          townId: route.townId,
          outlets: routeOutlets,
          visitType: 'ONE_DAY', //sales team changes this in app
          deliveryType: 'PRE_ORDER',
          visitCategory: 'OPEN',
          visitFrequency: 'DAILY',
          startPoint: startRandomOutletPos,
          endPoint: endRandomOutletPos,
        });
      }
    });
    let nonEmptyRoutes = formattedARCRoutes.filter(route => route.outlets.length > 0);
    this.props.saveRoutes(
      { input: nonEmptyRoutes },
      {
        handleSuccess: res => {
          if (res.data.createARCRoute.success === true) {
            this.props.displayAlert(ALERT_TYPE.SUCCESS, `Routes created successfully!`);
          }
        },
        handleError: errors => {
          this.props.displayAlert(ALERT_TYPE.DANGER, errors);
        },
      },
    );
  };

  goBack = () => {
    this.setState({ showSingleRoute: false });
    //for loop relatively faster
    if (this.props.visibleFancyMarkersInst.length > 0) {
      for (let i = 0; i < this.props.visibleFancyMarkersInst.length; i++) {
        this.props.visibleFancyMarkersInst[i].setVisible(true);
      }
    }
    for (let i = 0; i < this.props.routeLines.length; i++) {
      this.props.routeLines[i].setVisible(true);
    }
  };

  importRoutesClick = () => {
    this.setState({ buttonDisable: true });
    if (this.props.fetchType === 'filtered' || this.props.fetchType === 'imported') {
      this.props.displayAlert(ALERT_TYPE.CUSTOM_DANGER, `Please reset the map first!`);
    } else {
      this.setState({ showModal: true });
    }
  };

  deleteCurrentOutlets = type => {
    if (type === 'actions') {
      const routeIndex = this.props.routesList.findIndex(route => route.dse === this.state.toRemoveRouteIndex)
      // gets ids of only those markers that are in selected route
      const requiredVisibleMarkersIds = [...this.props.routesList[routeIndex].travel.map(outlet => outlet.id)];

      if (requiredVisibleMarkersIds.length > 0) {
        const fancyMarkersToShow = this.props.visibleFancyMarkersInst.filter(
          markerInst => requiredVisibleMarkersIds.includes(markerInst.id),
        );

        // removes duplicates
        const unique = fancyMarkersToShow.filter(
          (v, i, a) => a.findIndex(t => t.id === v.id) === i,
        );

        unique.sort((a, b) => {
          return requiredVisibleMarkersIds.indexOf(a.id) - requiredVisibleMarkersIds.indexOf(b.id);
        });

        unique.forEach(marker => { marker.setMap(null) })
      }
      if (routeIndex > -1) {
        this.props.routesList.splice(routeIndex, 1)
      }
      this.props.optimumRouteUpdate(this.props.routesList)

    } else {
      this.state.newOrderList.forEach(marker => {
        marker.setMap(null);
      });
      let routeRemovedRL = this.state.routesList;
      routeRemovedRL.splice(this.props.routesList.indexOf(route => route.dse === this.state.toRemoveRouteIndex), 1)
      this.setState({ routesList: routeRemovedRL })
      this.goBack();
      this.props.optimumRouteUpdate(this.props.routesList);
    }
  };

  onTownSelect = towns => {
    this.setState({ buttonDisable: true });
    this.setState({ loadingTownOutlets: true, selectedTowns: towns, importedRouteOutlets: [] });
    let townIds = [];
    towns.forEach(town => {
      let townId = town.id;
      townIds.push(`${townId}`);
    });
    this.setState({ townIds });
    this.props.getRoutes(
      {
        filter: {
          filters: [{ column: 'town_id', value: townIds }],
        },
        offset: 0,
      },
      {
        handleSuccess: res => {
          if (res.data.routes === null) {
            this.setState({ loadingTownOutlets: false });
          } else {
            this.setState({
              importedRoutesList: res.data.routes.rows,
              buttonDisable: false,
              loadingTownOutlets: false,
            });
          }
        },
      },
    );
  };

  onRouteSelect = routes => {
    this.setState({ selectedRoutes: routes });
    let routeIds = [];
    let townIds = [];
    routes.forEach(route => {
      let routeId = route.id;
      routeIds.push(`${routeId}`);
    });
    routes.forEach(route => {
      let townId = route.Town.id;
      townIds.push(`${townId}`);
    });
    this.setState({ routeIds });
  };

  getRosiaRoutes = () => {
    let { townIds, routeIds } = this.state;
    let townId = townIds.map(x => {
      return parseInt(x, 10);
    });
    let routeId = routeIds.map(x => {
      return parseInt(x, 10);
    });
    let params = routeIds.length ? { routeId } : { townId };
    this.setState({ loadingTownOutlets: true });
    this.props.getRosiaRoutes(params, {
      handleSuccess: res => {
        const importedRoutes = res.data.getARCRoutes;
        if (importedRoutes.length) {
          this.setState({ importedRoutes, loadingTownOutlets: false }, () => {
            this.renderImportedRoutes();
          });
        } else {
          this.props.displayAlert(ALERT_TYPE.CUSTOM_DANGER, `No Routes!`);
          this.setState({ loadingTownOutlets: false });
        }
      },
      handleError: err => {
        throw new Error(err);
      },
    });
  };

  renderImportedRoutes = () => {
    const { importedRoutes } = this.state;
    let isSequenceNull = importedRoutes.find(route => {
      return route.outlets.find(outlet => {
        return outlet.sequence === null;
      });
    });
    if (isSequenceNull) {
      this.props.displayAlert(ALERT_TYPE.WARNING, `One or more routes have no sequence!`);
    } else {
      this.closeModal();
      this.props.createExistingRoutes(importedRoutes);
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleMainReset = () => {
    this.setState({ selectedRoutes: [], selectedTowns: [], buttonDisable: true, importedRoutes: [] });
    this.closeModal();
  };

  showRenameModal = routeIndex => {
    this.setState({
      showRenameModal: true,
      toRenameRouteIndex: routeIndex,
      renameRouteName: this.props.routesList[routeIndex].name,
    });
  };

  closeRenameModal = () => {
    this.setState({ showRenameModal: false });
  };

  handleRouteNameChange = e => {
    this.setState({ renameRouteName: e.target.value });
  };

  renameRoute = () => {
    this.props.renameRouteName(this.state.renameRouteName, this.state.toRenameRouteIndex);
    this.closeRenameModal();
  };

  showDeleteModal = routeIndex => {
    this.setState({ showDeleteModal: true, toRemoveRouteIndex: routeIndex });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false, deleteInside: false });
  };

  deleteRoute = () => {
    this.props.deleteCurrentRoute(this.state.toRemoveRouteIndex, 'actions');
    this.closeDeleteModal();
  };

  deleteFromInside = () => {
    console.log(this.state.toRemoveRouteIndex)
    this.props.deleteCurrentRoute(this.state.toRemoveRouteIndex);
    this.closeDeleteFromInside();
  };

  showDeleteFromInside = routeIndex => {
    this.setState({ deleteInside: true, toRemoveRouteIndex: routeIndex });
  };

  closeDeleteFromInside = () => {
    this.setState({ deleteInside: false, showDeleteModal: false });
  };

  render() {
    const { clickedRouteId, newOrderList, showSingleRoute, showAll } = this.state;
    const { routesList, totalDistance, routeLines, deleteCurrentRoute, markerColor } = this.props;
    return (
      <RoutesListStyled>
        <div className="routesList">
          {!showSingleRoute && (
            <>
              <div className="routesDisplay-head">
                <p className="someText">Routes</p>
                <div className="right-icons">
                  <button className="borderless" type="button" onClick={() => this.importRoutesClick()}>
                    <Icon className="import-routes" iconName="order-arrow-down" />
                  </button>
                  {routesList.length > 0 && <MultiDropDown routesList={routesList} saveRoutes={this.saveRoutes} />}
                </div>
              </div>
              <div className="routeCreate-body">
                {routesList.length > 0 && (
                  <h3>
                    <span>Routes Created ({routesList.length})</span>
                    {totalDistance > 0 && (
                      <span className="someText">
                        {/* Total Distance: {" "} */}
                        {totalDistance.toFixed(2)} km
                      </span>
                    )}
                  </h3>
                )}
                {routesList.length > 0 ? (
                  <RouteCards
                    routesList={routesList}
                    routeClick={this.routeClick}
                    showRenameModal={this.showRenameModal}
                    showDeleteModal={this.showDeleteModal}
                  />
                ) : (
                  <div className="default-wrap">
                    <img src={zeroRoutesImage} />
                    <p className="someText">0 Routes Created.</p>
                  </div>
                )}
              </div>
            </>
          )}
          {showSingleRoute && (
            <SingleRoute
              clickedRouteId={clickedRouteId}
              goBack={this.goBack}
              newOrderList={newOrderList}
              routeLines={routeLines}
              routesList={routesList}
              markerColor={markerColor}
              deleteCurrentRoute={deleteCurrentRoute}
              getSingleRoutesMarkers={this.getSingleRoutesMarkers}
              showDeleteModal={this.showDeleteModal}
              showDeleteFromInside={this.showDeleteFromInside}
              showAll={showAll}
              handleShowAllClick={this.handleShowAllClick}
            />
          )}
          <Modal
            show={this.state.showModal}
            handler={this.getRosiaRoutes}
            handleClose={this.closeModal}
            disableRender={this.state.disableRender}
            disable={this.state.buttonDisable}
          >
            <div className="m-header">
              <h2>Import Routes</h2>
            </div>
            <div className="m-body">
              <p>Please select Town(s).</p>
              <div className="form-input select-route">
                <CustomSelect
                  name="towns"
                  options={this.state.townsList}
                  value={this.state.selectedTowns}
                  labelContent={'Select Town(s)'}
                  getOptionLabel={({ title }) => `${title}`}
                  getOptionValue={({ id }) => id}
                  onChange={e => this.onTownSelect(e)}
                  isSearchable={true}
                  isMulti={true}
                  placeholder={'Search for Town(s)'}
                />
              </div>

              {this.state.selectedTowns.length > 0 && (
                <div className="form-input select-route">
                  <CustomSelect
                    name="routes"
                    options={this.state.importedRoutesList}
                    value={this.state.selectedRoutes}
                    labelContent={'Select Route(s)'}
                    getOptionLabel={({ title }) => `${title}`}
                    getOptionValue={({ id }) => id}
                    onChange={e => this.onRouteSelect(e)}
                    isSearchable={true}
                    isMulti={true}
                    placeholder={'Search for Route(s)'}
                  />
                </div>
              )}
            </div>
          </Modal>

          {this.state.showRenameModal && (
            <RenameModal
              handleClose={this.closeRenameModal}
              handler={this.renameRoute}
              show={this.state.showRenameModal}
            >
              <div className="m-header">
                <h2>Rename Route</h2>
              </div>
              <div className="m-body">
                <div className="form-input">
                  <label>Route</label>
                  <input value={this.state.renameRouteName} onChange={e => this.handleRouteNameChange(e)} />
                </div>
              </div>
            </RenameModal>
          )}
          {this.state.showDeleteModal && (
            <RenameModal
              handleClose={this.closeDeleteModal}
              handler={this.deleteRoute}
              show={this.state.showDeleteModal}
            >
              <div className="m-header">
                <h2>Remove Route</h2>
              </div>
              <div className="m-body">
                <div className="form-input">
                  Are you sure you want to remove {routesList.find(route => route.dse === this.state.toRemoveRouteIndex).name}
                </div>
              </div>
            </RenameModal>
          )}

          {this.state.deleteInside && (
            <RenameModal
              handleClose={this.closeDeleteModal}
              handler={this.deleteFromInside}
              show={this.state.deleteInside}
            >
              <div className="m-header">
                <h2>Remove Route</h2>
              </div>
              <div className="m-body">
                <div className="form-input">
                  Are you sure you want to remove {routesList.find(route => route.dse === this.state.toRemoveRouteIndex).name}
                </div>
              </div>
            </RenameModal>
          )}
          {this.state.loadingTownOutlets && <ARCLoading title={'Fetching data...'} />}
        </div>
      </RoutesListStyled>
    );
  }
}

export default withAlert()(RoutesList);
