import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../../../common/GqlAPI';

const GET_CATALOG_LIST_DETAIL = gql`
  query catalogLevelDetails($catalogId: Int, $catalogDetailId: Int) {
    catalogLevelDetails(catalogId: $catalogId, catalogDetailId: $catalogDetailId) {
      rows {
        id
        title
        alias
        catalogId
        parentCatalogDetailId
        images {
          image
          thumbnail
        }
      }
    }
  }
`;

const GET_CATALOG_DETAIL_INFO = gql`
  query getCatalogDetailInfo($catalogDetailId: Int) {
    getCatalogDetailInfo(catalogDetailId: $catalogDetailId) {
      catalogId
      catalogDetailId
      title
    }
  }
`;

const CREATE_CATALOG_LIST_DETAIL = graphql(
  gql`
    mutation ($input: CreateCatalogInput!) {
      createCatalog(input: $input) {
        id
        title
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createCatalogListDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_CATALOG_LIST_DETAIL = graphql(
  gql`
    mutation ($id: Int!, $input: CreateCatalogInput!) {
      updateCatalog(id: $id, input: $input) {
        id
        title
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateCatalogListDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getCatalogListDetail: GET_CATALOG_LIST_DETAIL,
  getCatalogDetailInfo: GET_CATALOG_DETAIL_INFO,
};

const mutation = {
  createCatalogListDetail: CREATE_CATALOG_LIST_DETAIL,
  updateCatalogListDetail: UPDATE_CATALOG_LIST_DETAIL,
  toggleState: TOGGLE_STATE,
};

export { CREATE_CATALOG_LIST_DETAIL, UPDATE_CATALOG_LIST_DETAIL };

export {
  query, mutation, TOGGLE_STATE
};
