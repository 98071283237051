import styled from 'styled-components';

const PaginationStyled = styled.div`
  line-height: normal;
  background: rgb(247, 248, 249);
  padding: 12px 48px;
  border: 1px solid #e7e7ed;
  border-top: 0;

  .pagination-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .pagination-control {
    flex: 1;
  }

  .pagination-control-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .pagination-item {
    display: inline-block;

    > span {
      display: inline-block;
      padding: 6px 12px;

      &.active {
        background-color: #ebecf0;
      }
    }
  }

  .pagination-display-label {
    color: #6b6c7e;
    font-size: 14px;
  }

  .nav {
    color: #8993a4;
    font-weight: 600;
    font-size: 14px;

    span {
      cursor: pointer;

      &:hover,
      &:focus {
        color: #0056e0;
      }
    }
  }
`;

export default PaginationStyled;
