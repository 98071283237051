import { compose } from 'recompose';
import StockCountList from './StockCountList';
import { query } from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedStockCountList = compose(
  withRequestTracker({
    query,
  }),
)(StockCountList);

export default composedStockCountList;