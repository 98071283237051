//Primary
const addCell = 'add-cell';
const addColumn = 'add-column';
const addRole = 'add-role';
const addRow = 'add-row';
const adjust = 'adjust';
const alignCenter = 'align-center';
const alignImageCenter = 'align-image-center';
const alignImageLeft = 'align-image-left';
const alignImageRight = 'align-image-right';
const alignJustify = 'align-justify';
const alignLeft = 'align-left';
const alignRight = 'align-right';
const analytics = 'analytics';
const angleDown = 'angle-down';
const angleUp = 'angle-up';
const angleLeft = 'angle-left';
const angleRight = 'angle-right';
const announcement = 'announcement';
const apiLock = 'api-lock';
const apiWeb = 'api-web';
const archive = 'archive';
const arrowUpFull = 'arrow-up-full';
const asterisk = 'asterisk';
const audio = 'audio';
const autoSize = 'autosize';
const bars = 'bars';
const bellOff = 'bell-off';
const bellOn = 'bell-on';
const blogs = 'blogs';
const bold = 'bold';
const bookMarks = 'bookmarks';
const boxContainer = 'box-container';
const breadCrumb = 'breadcrumb';
const calendar = 'calendar';
const camera = 'camera';
const cards = 'cards';
const cards2 = 'cards2';
const caretBottomL = 'caret-bottom-l';
const caretBottom = 'caret-bottom';
const caretDoubleL = 'caret-double-l';
const caretDouble = 'caret-double';
const caretDown = 'caret-down';
const caretLeftL = 'caret-left-l';
const caretLeft = 'caret-left';
const caretRightL = 'caret-right-l';
const caretRight = 'caret-right';
const caretTopL = 'caret-top-l';
const caretTop = 'caret-top';
const categories = 'categories';
const chainBroken = 'chain-broken';
const change = 'change';
const checkCircleFull = 'check-circle-full';
const checkCircle = 'check-circle';
const checkSquare = 'check-square';
const check = 'check';
const chip = 'chip';
const code = 'code';
const cog = 'cog';
const columns = 'columns';
const comments = 'comments';
const community = 'community';
const compress = 'compress';
const controlPanel = 'control-panel';
const customField = 'custom-field';
const customSize = 'custom-size';
const cut = 'cut';
const date = 'date';
const decimal = 'decimal';
const desktop = 'desktop';
const devices = 'devices';
const diagram = 'diagram';
const diary = 'diary';
const document = 'document';
const documentsAndMedia = 'documents-and-media';
const download = 'download';
const drag = 'drag';
const dynamicDataList = 'dynamic-data-list';
const dynamicDataMapping = 'dynamic-data-mapping';
const editLayout = 'edit-layout';
const effects = 'effects';
const ellipsisH = 'ellipsis-h';
const ellipsisV = 'ellipsis-v';
const embed = 'embed';
const envelopeClosed = 'envelope-closed';
const envelopeOpen = 'envelope-open';
const environmentConnected = 'environment-connected';
const environmentDisconnected = 'environment-disconnected';
const environment = 'environment';
const exclamationCircle = 'exclamation-circle';
const exclamationFull = 'exclamation-full';
const expand = 'expand';
const fileScript = 'file-script';
const fileTemplate = 'file-template';
const fileXsl = 'file-xsl';
const filter = 'filter';
const flagEmpty = 'flag-empty';
const flagFull = 'flag-full';
const folder = 'folder';
const format = 'format';
const forms = 'forms';
const fullSize = 'full-size';
const geoLocation = 'geolocation';
const globe = 'globe';
const grid = 'grid';
const hashTag = 'hashtag';
const heart = 'heart';
const hidden = 'hidden';
const home = 'home';
const horizontalScroll = 'horizontal-scroll';
const hr = 'hr';
const importExport = 'import-export';
const indentLess = 'indent-less';
const indentMore = 'indent-more';
const infoBook = 'info-book';
const infoCircleOpen = 'info-circle-open';
const infoCircle = 'info-circle';
const infoPanelClosed = 'info-panel-closed';
const infoPanelOpen = 'info-panel-open';
const informationLive = 'information-live';
const integer = 'integer';
const italic = 'italic';
const link = 'link';
const list = 'list';
const live = 'live';
const lockDots = 'lock-dots';
const lock = 'lock';
const logout = 'logout';
const magic = 'magic';
const markAsAnswer = 'mark-as-answer';
const markAsQuestion = 'mark-as-question';
const merge = 'merge';
const messageBoards = 'message-boards';
const message = 'message';
const mobileDeviceRules = 'mobile-device-rules';
const mobileLandscape = 'mobile-landscape';
const mobilePortrait = 'mobile-portrait';
const moon = 'moon';
const move = 'move';
const mySpace = 'myspace';
const number = 'number';
const oauth = 'oauth';
const openId = 'open-id';
const orderArrowDown = 'order-arrow-down';
const orderArrowUp = 'order-arrow-up';
const orderArrow = 'order-arrow';
const organizations = 'organizations';
const pageTemplate = 'page-template';
const page = 'page';
const pagesTree = 'pages-tree';
const paperclip = 'paperclip';
const paragraph = 'paragraph';
const passwordPolicies = 'password-policies';
const paste = 'paste';
const pause = 'pause';
const pencil = 'pencil';
const phone = 'phone';
const picture = 'picture';
const play = 'play';
const plug = 'plug';
const plusSquares = 'plus-squares';
const plus = 'plus';
const polls = 'polls';
const print = 'print';
const productMenuClosed = 'product-menu-closed';
const productMenuOpen = 'product-menu-open';
const productMenu = 'product-menu';
const propagation = 'propagation';
const questionCircleFull = 'question-circle-full';
const questionCircle = 'question-circle';
const quoteLeft = 'quote-left';
const quoteRight = 'quote-right';
const radioButton = 'radio-button';
const redo = 'redo';
const reload = 'reload';
const removeRole = 'remove-role';
const removeStyle = 'remove-style';
const reply = 'reply';
const repository = 'repository';
const restore = 'restore';
const rssFull = 'rss-full';
const rss = 'rss';
const rules = 'rules';
const search = 'search';
const selectFromList = 'select-from-list';
const select = 'select';
const separator = 'separator';
const shareAlt = 'share-alt';
const share = 'share';
const sheets = 'sheets';
const shoppingCart = 'shopping-cart';
const shortcut = 'shortcut';
const simulationMenuClosed = 'simulation-menu-closed';
const simulationMenuOpen = 'simulation-menu-open';
const simulationMenu = 'simulation-menu';
const siteTemplate = 'site-template';
const sites = 'sites';
const socialFacebook = 'social-facebook';
const socialLinkedin = 'social-linkedin';
const staging = 'staging';
const starHalf = 'star-half';
const starO = 'star-o';
const star = 'star';
const sticky = 'sticky';
const strikeThrough = 'strikethrough';
const subscript = 'subscript';
const suitcase = 'suitcase';
const sun = 'sun';
const superscript = 'superscript';
const table = 'table';
const table2 = 'table2';
const tabletLandscape = 'tablet-landscape';
const tabletPortrait = 'tablet-portrait';
const tag = 'tag';
const textEditor = 'text-editor';
const text = 'text';
const textBox = 'textbox';
const thirdParty = 'third-party';
const thumbsDown = 'thumbs-down';
const thumbsUpArrow = 'thumbs-up-arrow';
const thumbsUp = 'thumbs-up';
const time = 'time';
const timesCircle = 'times-circle';
const times = 'times';
const transform = 'transform';
const trash = 'trash';
const twitter = 'twitter';
const underline = 'underline';
const undo = 'undo';
const unlock = 'unlock';
const upload = 'upload';
const urgent = 'urgent';
const user = 'user';
const users = 'users';
const verticalScroll = 'vertical-scroll';
const video = 'video';
const view = 'view';
const vocabulary = 'vocabulary';
const warningFull = 'warning-full';
const warning = 'warning';
const webContent = 'web-content';
const wikiPage = 'wiki-page';
const wiki = 'wiki';
const workFlow = 'workflow';

const Icons = {
  icons: {
    addCell,
    addColumn,
    addRole,
    addRow,
    adjust,
    alignCenter,
    alignImageCenter,
    alignImageLeft,
    alignImageRight,
    alignJustify,
    alignLeft,
    alignRight,
    analytics,
    angleDown,
    angleUp,
    angleLeft,
    angleRight,
    announcement,
    apiLock,
    apiWeb,
    archive,
    arrowUpFull,
    asterisk,
    audio,
    autoSize,
    bars,
    bellOff,
    bellOn,
    blogs,
    bold,
    bookMarks,
    boxContainer,
    breadCrumb,
    calendar,
    camera,
    cards,
    cards2,
    caretBottomL,
    caretBottom,
    caretDoubleL,
    caretDouble,
    caretDown,
    caretLeftL,
    caretLeft,
    caretRightL,
    caretRight,
    caretTopL,
    caretTop,
    categories,
    chainBroken,
    change,
    checkCircleFull,
    checkCircle,
    checkSquare,
    check,
    chip,
    code,
    cog,
    columns,
    comments,
    community,
    compress,
    controlPanel,
    customField,
    customSize,
    cut,
    date,
    decimal,
    desktop,
    devices,
    diagram,
    diary,
    document,
    documentsAndMedia,
    download,
    drag,
    dynamicDataList,
    dynamicDataMapping,
    editLayout,
    effects,
    ellipsisH,
    ellipsisV,
    embed,
    envelopeClosed,
    envelopeOpen,
    environmentConnected,
    environmentDisconnected,
    environment,
    exclamationCircle,
    exclamationFull,
    expand,
    fileScript,
    fileTemplate,
    fileXsl,
    filter,
    flagEmpty,
    flagFull,
    folder,
    format,
    forms,
    fullSize,
    geoLocation,
    globe,
    grid,
    hashTag,
    heart,
    hidden,
    home,
    horizontalScroll,
    hr,
    importExport,
    indentLess,
    indentMore,
    infoBook,
    infoCircleOpen,
    infoCircle,
    infoPanelClosed,
    infoPanelOpen,
    informationLive,
    integer,
    italic,
    link,
    list,
    live,
    lockDots,
    lock,
    logout,
    magic,
    markAsAnswer,
    markAsQuestion,
    merge,
    messageBoards,
    message,
    mobileDeviceRules,
    mobileLandscape,
    mobilePortrait,
    moon,
    move,
    mySpace,
    number,
    oauth,
    openId,
    orderArrowDown,
    orderArrowUp,
    orderArrow,
    organizations,
    pageTemplate,
    page,
    pagesTree,
    paperclip,
    paragraph,
    passwordPolicies,
    paste,
    pause,
    pencil,
    phone,
    picture,
    play,
    plug,
    plusSquares,
    plus,
    polls,
    print,
    productMenuClosed,
    productMenuOpen,
    productMenu,
    propagation,
    questionCircleFull,
    questionCircle,
    quoteLeft,
    quoteRight,
    radioButton,
    redo,
    reload,
    removeRole,
    removeStyle,
    reply,
    repository,
    restore,
    rssFull,
    rss,
    rules,
    search,
    selectFromList,
    select,
    separator,
    shareAlt,
    share,
    sheets,
    shoppingCart,
    shortcut,
    simulationMenuClosed,
    simulationMenuOpen,
    simulationMenu,
    siteTemplate,
    sites,
    socialFacebook,
    socialLinkedin,
    staging,
    starHalf,
    starO,
    star,
    sticky,
    strikeThrough,
    subscript,
    suitcase,
    sun,
    superscript,
    table,
    table2,
    tabletLandscape,
    tabletPortrait,
    tag,
    textEditor,
    text,
    textBox,
    thirdParty,
    thumbsDown,
    thumbsUpArrow,
    thumbsUp,
    time,
    timesCircle,
    times,
    transform,
    trash,
    twitter,
    underline,
    undo,
    unlock,
    upload,
    urgent,
    user,
    users,
    verticalScroll,
    video,
    view,
    vocabulary,
    warningFull,
    warning,
    webContent,
    wikiPage,
    wiki,
    workFlow,
  },
};

export default Icons;
