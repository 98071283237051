import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, CustomSelect
} from '../../../../components';
import withLoading from '../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    userGrpId: PropTypes.number,
  }),
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {
    name: '',
    userGrpId: 0,
    displayName: '',
  },
  refsObj: {
    title: '',
  },
  options: [],
  enableErrorDisplay: true,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
};

const UserRoleForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, handleDropDownChange, enableErrorDisplay, options } = props;

  return (
    <Form>
      <CustomSelect
        ref={(ref) => {
          refsObj.userGroupId = ref;
          return null;
        }}
        clearable={false}
        className='custom-select'
        placeholder='Select User Group'
        getOptionLabel={({ name }) => name}
        getOptionValue={({ id }) => id}
        options={options}
        enableValiation
        enableErrorDisplay={enableErrorDisplay}
        value={options.filter(({ id }) => id === data.UserGroup.id)}
        onChange={(e) => handleDropDownChange(e.id, ['UserGroup', 'id'])}
        labelContent='User Group'
      />
      <Input
        ref={(ref) => {
          refsObj.name = ref;
          return null;
        }}
        value={data.name}
        name='name'
        type='text'
        placeholder='Name'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Name'
      />
      <Input
        ref={(ref) => {
          refsObj.displayName = ref;
          return null;
        }}
        value={data.displayName}
        name='displayName'
        type='text'
        placeholder='Display Name'
        enableValidation
        enableErrorDisplay={enableErrorDisplay}
        onChange={(event) => handleInputChange(event)}
        labelContent='Display Name'
      />
    </Form>
  );
};

UserRoleForm.propTypes = propTypes;

UserRoleForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(UserRoleForm);

export default LoginViewWithErrorAndLoading;
