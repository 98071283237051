import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Icon, CheckBox } from '../../../../../../v4/components';
import ItemList from './ItemList';
import OutletContext from './OutletContext';

import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';

const Column = React.memo(({ column, index }) => (
  <Draggable draggableId={column.id} index={index}>
    {(provided) => (
      <div className='column' {...provided.draggableProps} ref={provided.innerRef}>
        <div className='route-status'>
          <Icon iconName='geolocation' />
          <div>
            <span className='column-title' {...provided.dragHandleProps}>
              {column.title} <span className='label'>{column.items.length}</span>
            </span>
          </div>
        </div>
        <OutletContext.Consumer>
          {({ icons, onTransferClick, outletState, onRemoveOutlets, checkedData }) =>
            icons &&
            outletState === column.id && (
              <div className='route-content-selected'>
                <div className='icons'>
                  <div>
                    <CheckBox checked onCheckBoxClick={() => { }} />
                    <h6>
                      {checkedData.length} {`Outlet${checkedData.length > 1 ? 's' : ''}`} Selected
                    </h6>
                  </div>
                  <div>
                    <Icon
                      className='mr-16 rotate'
                      iconName='import-export'
                      onClick={() => onTransferClick(column.id === 'assigned' ? 'Transfer Outlets' : 'Assign to Route')}
                    />
                    {column.id === 'assigned' && (
                      <Icon
                        iconName='trash'
                        onClick={() => {
                          onTransferClick(EVENT_OPERATION.REMOVE);
                          onRemoveOutlets();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          }
        </OutletContext.Consumer>
        <ItemList column={column} index={index} />
      </div>
    )}
  </Draggable>
));

export default Column;
