import gql from 'graphql-tag';

const GET_STORAGE_IMAGE_PUT_URL = gql`
  query getStoragePutURL($key: String!, $contentType: String, $domain: String) {
    getStoragePutURL(key: $key, contentType: $contentType, domain: $domain)
  }
`;

const GET_STORAGE_IMAGE_GET_URL = gql`
  query getStorageGetURL($key: String!, $domain: String) {
    getStorageGetURL(key: $key, domain: $domain)
  }
`;

const query = {
  getStorageImageUrl: GET_STORAGE_IMAGE_PUT_URL,
  getStorageUrl: GET_STORAGE_IMAGE_GET_URL,
};

export {
  query, GET_STORAGE_IMAGE_PUT_URL, GET_STORAGE_IMAGE_GET_URL
};
