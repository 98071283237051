import { compose } from 'recompose';
import LocationHierarchy from './LocationHierarchy';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  CREATE_LOCATION_HIERARCHY,
  UPDATE_LOCATION_HIERARCHY,
  query,
  mutation,
  DELETE_LOCATION_HIERARCHY_DETAIL,
} from './API';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const ComposedLocationHierarchy = compose(
  TOGGLE_STATE,
  CREATE_LOCATION_HIERARCHY,
  UPDATE_LOCATION_HIERARCHY,
  DELETE_LOCATION_HIERARCHY_DETAIL,
  withRequestTracker({
    query,
    mutation,
  }),
)(LocationHierarchy);

export default ComposedLocationHierarchy;
