import { DISTRIBUTOR_SALES_TARGET } from '../../../../data/enums/Route';
import { refGenerator } from '../../../../utils';
import { FORM_CONFIG } from '../../../../v4/constants/formConfig';
import { breadCrumbConfig } from '../config';
const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Distributor Sales Targets',
    path: `/${DISTRIBUTOR_SALES_TARGET}`,
    active: true,
  },
];

const formConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    file: element.file || {},
    name: element.name || '',
  }),
  [FORM_CONFIG.TITLE]: 'File Upload',
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

export { breadCrumb, formConfig };
