import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { salesForce } from '../../../data/enums/Route';

const title = 'Attendance';

const breadCrumb = [salesForce];

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
    user: element.User || {},
    checkinLocation: element.checkinLocation || 0,
    checkoutLocation: element.checkoutLocation || 0,
    agendas: element.Agendas || {},
  }),
};

const filter = getFilterConfig([FILTER.USER_ROLE]);

export {
  breadCrumb as breadCrumbConfig, filter, title, formConfig
};
