import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubDGroupView from './View';
import { crudRequestConfig } from './config';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import withAlert from '../../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { DEFAULT_QUERY_VARIABLES } from '../../../../../../data/services/common';
import { handleFormSubmit, responseInterpreter } from '../../../../../../utils/crudResponseProcessor';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getTerritoryList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  getCountForReplenishmentDistributor: PropTypes.func.isRequired,
  getReplenishmentGroupDetail: PropTypes.func.isRequired,
  toggleStateForReplenishmentGroup: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class SubDGroup extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      territories: [],
      groupCountTerritoryWise: [],
      serverSubDs: [],
      subDs: [],
      selectedSubDs: [],
      territoryId: null,
      selectableSubDs: [],
    };
    const serverCall = {
      [EVENT_OPERATION.UPDATE_STATUS]: props.toggleStateForReplenishmentGroup,
    };
    this.onCRUDSuccess = responseInterpreter(this.onMutationSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.handleAPIFailure, crudRequestConfig, serverCall);
  }

  componentDidMount() {
    this.fetchTerritoryList();
    this.fetchDistributorGroupCount();
  }

  fetchTerritoryList = () => {
    const { getTerritoryList } = this.props;

    getTerritoryList(
      { ...DEFAULT_QUERY_VARIABLES },
      {
        handleSuccess: response => {
          const { territories = {} } = response.data;
          this.setState({ territories: territories ? territories.rows : [] });
        },
        handleError: err => this.handleAPIFailure(err),
      },
    );
  };

  fetchDistributorGroupCount = () => {
    const { groupId: stockGroupId, getCountForReplenishmentDistributor } = this.props;
    if (stockGroupId) {
      getCountForReplenishmentDistributor(
        { stockGroupId },
        {
          handleSuccess: response => {
            const { getCountForReplenishmentDistributor = [] } = response.data;
            this.setState({ groupCountTerritoryWise: getCountForReplenishmentDistributor || [] });
          },
          handleError: err => this.handleAPIFailure(err),
        },
      );
    }
  };

  handleAPIFailure = err => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, err);
  };

  getDistributorDetails = territoryId => {
    const { getReplenishmentGroupDetail, groupId: id } = this.props;
    this.setState({ territoryId });
    getReplenishmentGroupDetail(
      {
        territoryId,
        id,
      },
      {
        handleSuccess: response => {
          const { getReplenishmentGroupDetail = [] } = response.data;
          this.setState({
            subDs: getReplenishmentGroupDetail.distributorDetails || [],
            serverSubDs: getReplenishmentGroupDetail.distributorDetails || [],
            selectedSubDs:
              getReplenishmentGroupDetail.distributorDetails.filter(d => d.active === true).map(d => d.distributorId) ||
              [],
            selectableSubDs: this.getSelectableSubDs(getReplenishmentGroupDetail.distributorDetails || []),
          });
        },
        handleError: err => {
          this.resetSubDsDetails();
          this.handleAPIFailure(err);
        },
      },
    );
  };

  handleSearchInput = text => {
    const { serverSubDs } = this.state;
    const search = d => d.title.replace(/\s+/g, '').toLowerCase().search(text) !== -1;
    const filteredSubDs = serverSubDs.filter(d => search(d));

    this.setState({ subDs: filteredSubDs });
  };

  handlePrimarySwitch = checked => {
    const { selectableSubDs } = this.state;
    if (checked) {
      this.setState({ selectedSubDs: [...selectableSubDs] });
    } else {
      this.setState({ selectedSubDs: [] });
    }
  };

  getSelectableSubDs = subDList =>
    subDList.reduce((acc, item) => {
      if (!item.used) {
        acc.push(item.distributorId);
      }
      return acc;
    }, []);

  handleSecondarySwitch = id => {
    const { selectedSubDs } = this.state;
    const index = selectedSubDs.indexOf(id);
    if (index > -1) {
      this.setState({ selectedSubDs: selectedSubDs.filter(subDId => subDId !== id) });
    } else {
      this.setState({ selectedSubDs: [...selectedSubDs, id] });
    }
  };

  onMutationSuccess = (type, response, payload) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    this.setState(
      {
        groupCountTerritoryWise: [...response],
      },
      () => {
        if (payload.distributorIds.length === 1) {
          this.handleSecondarySwitch(payload.distributorIds[0]);
        } else this.handlePrimarySwitch(payload.status);
      },
    );
  };

  handleSubDSubmit = (status, ids) => {
    const { territoryId } = this.state;
    const { groupId } = this.props;
    this.onFormSubmit(EVENT_OPERATION.UPDATE_STATUS, {
      distributorIds: ids,
      stockGroupId: groupId,
      territoryId,
      status,
    });
  };

  resetSubDsDetails = () => {
    this.setState({
      subDs: [],
      serverSubDs: [],
      selectedSubDs: [],
      selectableSubDs: [],
    });
  };

  render() {
    const { territories, groupCountTerritoryWise, subDs, selectedSubDs, territoryId, selectableSubDs } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <>
        <SubDGroupView
          getDistributorDetails={this.getDistributorDetails}
          groupCountTerritoryWise={groupCountTerritoryWise}
          handleSearchInput={this.handleSearchInput}
          onSwitchChange={this.handleSubDSubmit}
          secondarySwitchHandler={this.handleSecondarySwitch}
          subDs={subDs}
          selectedSubDs={selectedSubDs}
          selectableSubDs={selectableSubDs}
          territories={territories}
          territoryId={territoryId}
          loading={serverResponseWaiting}
        />
      </>
    );
  }
}

SubDGroup.propTypes = propTypes;

SubDGroup.defaultProps = defaultProps;

export default withAlert()(SubDGroup);
