import { compose } from 'recompose';
import Details from './Details';
import {
  query, mutation, CREATE_SBD_SKUS, DELETE_SBD_SKU
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedSBD = compose(
  CREATE_SBD_SKUS,
  DELETE_SBD_SKU,
  withRequestTracker({
    query,
    mutation,
  }),
)(Details);

export default composedSBD;
