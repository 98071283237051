import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../../../components';
import { Button, BulkDelete } from '../../../../../v4/components';
import { TableHeader, TableBody } from './tableConfig';
import { dataProps } from '../../../../../data/enums/Component';
import { STATEFUL_ENTITIES } from '../../../../../data/enums/GraphQL';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import TotalView from './TotalView';

const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  enableErrorDisplay: false,
  onInputChange: () => null,
  refsObj: {},
};

const View = ({ ...props }) => {
  const { update, data, onIconClick, onBulkDelete, permission, onTableRowClick, totalViewRefs, priceDetails, enableErrorDisplay, orderId } = props;
  return (
    <BulkDelete
      data={data}
      type={STATEFUL_ENTITIES.CAMPAIGNS}
      handler={onBulkDelete}
      render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
        <div className="table-wrap">
          <table>
            {TableHeader({
              dataListLength: data.length,
              checkedListLength: checkedList.length,
              primaryCheckboxHandler,
              permission,
            })}
            <tbody>
              {data?.map?.((product, index) =>
                TableBody({
                  index,
                  data: product,
                  checkedList: checkedList,
                  onIconClick,
                  secondaryCheckboxHandler,
                  permission,
                  update,
                  onTableRowClick,
                }),
              )}
            </tbody>
          </table>
          <div className="product-add">
            <Button
              iconBtnSmall
              primary
              iconName="plus"
              className={!update.status ? 'disabled' : null}
              onClick={() => onIconClick(EVENT_OPERATION.CREATE, {})}
            />
          </div>
          <TotalView
            data={data}
            update={update}
            refsObj={totalViewRefs}
            priceDetails={priceDetails}
            enableErrorDisplay={enableErrorDisplay}
            orderId={orderId}
          />
        </div>
      )}
    />
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export { ViewWithErrorAndLoading as View };

View.propTypes = propTypes;

View.defaultProps = defaultProps;

export default ViewWithErrorAndLoading;
