import { compose } from 'recompose';
import { query } from './API';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import PostDatedCheques from './PostDatedCheques';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedPostDatedCheques = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation: {
      downloadReport: DOWNLOAD_REPORT,
    },
  }),
)(PostDatedCheques);

export default composedPostDatedCheques;
