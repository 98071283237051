import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import General from './General';
import OutletDetailWrap from './OutletDetailStyled';
import { has } from '../../../../utils/objectPrototypes';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import Outlets from './Outlets';
import { getPermissionForRoute } from '../../../base/permission';
import { RouteContext, routeTitle } from '../config';
import { breadCrumbConfig } from './General/config';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { Tab, TabList, TabPanel, Tabs, BreadCrumb } from '../../../../v4/components';

const propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  getRouteDetail: PropTypes.func.isRequired,
};

const defaultProps = {};

class Detail extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      activeTab: 0,
      id: has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0,
      crudMode: props.match.params.id && props.match.params.id !== '0' ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
    };
    this.permission = getPermissionForRoute();
  }

  componentDidMount() {
    const { crudMode } = this.state;
    if (crudMode === EVENT_OPERATION.READ) {
      const {
        getRouteDetail,
        match: {
          params: { id },
        },
      } = this.props;
      getRouteDetail(
        {
          id: id.toString(),
        },
        {
          handleSuccess: response => {
            this.setState({ title: response.data.routes.rows[0].title });
          },
        },
      );
    }
  }

  onTabChange = tabIndex => {
    this.setState({ activeTab: tabIndex });
  };

  getTitle = (updateTitle, firstParam = '') => {
    const { crudMode, title } = this.state;
    if (firstParam) {
      this.setState({ title: updateTitle });
    }
    if (crudMode === EVENT_OPERATION.READ || crudMode === EVENT_OPERATION.UPDATE) {
      return title;
    }
    return (
      <Fragment>
        <span>{EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}</span>
        {` ${routeTitle}`}
      </Fragment>
    );
  };

  render() {
    const { activeTab, id, crudMode } = this.state;
    const title = crudMode===EVENT_OPERATION.CREATE?'Create Route':this.getTitle();
    return (
      <OutletDetailWrap>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{title}</h2>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className={`section-content ${crudMode === EVENT_OPERATION.CREATE ? '' : 'section-tab'} r-detail-content`}>
          <Fragment>
            {crudMode === EVENT_OPERATION.READ ? (
              <RouteContext.Provider value={{ permission: this.permission }}>
                <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.onTabChange(tabIndex)}>
                  <TabList>
                    <Tab>
                      <span className="tab-label">Detail</span>
                    </Tab>
                    <Tab>
                      <span className="tab-label">Outlets</span>
                    </Tab>
                  </TabList>
                  <div className="route-tab-content">
                    <TabPanel>
                      <section id="detail-tab">
                        <General id={id} getTitle={this.getTitle} />
                      </section>
                    </TabPanel>
                    <TabPanel>
                      <section id="order-history-tab">
                        <Outlets id={id} />
                      </section>
                    </TabPanel>
                  </div>
                </Tabs>
              </RouteContext.Provider>
            ) : (
              <General id={id} />
            )}
          </Fragment>
        </div>
      </OutletDetailWrap>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export { Detail };

export default Detail;
