import { compose } from 'recompose';
import PerfectCallList from './PerfectCallList';
import { query, mutation, deletePerfectCall, createPerfectCalls, updatePerfectCalls, updatePerfectCallSequence } from '../Repository/API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedSKUGroup = compose(
  createPerfectCalls,
  updatePerfectCalls,
  updatePerfectCallSequence,
  deletePerfectCall,
  withRequestTracker({
    query,
    mutation,
  }),
)(PerfectCallList);

export default composedSKUGroup;
