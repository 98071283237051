import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from '../../../components';

const propTypes = {
  onDialogSubmit: PropTypes.func,
  onDialogCancel: PropTypes.func,
  handleInputChange: PropTypes.func,
};

const defaultProps = {
  onDialogSubmit: () => null,
  onDialogCancel: () => null,
  handleInputChange: null,
};

const ForgetPasswordForm = ({ ...props }) => {
  const { data, handleInputChange, enableErrorDisplay, refsObj } = props;
  return (
    <div>
      <div className='mb-24'>
        Enter your email to <b> reset</b> your <b>password</b>.
      </div>
      <Form>
        <Input
          ref={(ref) => (refsObj.email = ref)}
          name='email'
          type='email'
          placeholder='Email'
          labelContent='Email'
          enableValidation
          value={data.email}
          onChange={(e) => handleInputChange(e)}
          enableErrorDisplay={enableErrorDisplay}
        />
      </Form>
    </div>
  );
};

ForgetPasswordForm.propTypes = propTypes;

ForgetPasswordForm.defaultProps = defaultProps;

export default ForgetPasswordForm;
