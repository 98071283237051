import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Net Sales Report';

const breadCrumb = [reportsRoute];

const filter = getFilterConfig([
  FILTER.SUB_D,
  FILTER.ROUTE,
  FILTER.CHANNEL,
  FILTER.CATEGORY,
  FILTER.DSE,
  FILTER.STL,
  FILTER.ASM,
  FILTER.PRINCIPAL,
  FILTER.BRAND,
]);

export { breadCrumb as breadCrumbConfig, filter as filterConfig, title };
