import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../v4/components';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { normalPresentor } from '../../../../utils/date';
import { getUpdatedLabel } from '../../../common/HelperFunctions';
import { dataProps } from '../../../../data/enums/Component';
import { ReceiveButton } from './TripWise.Styled';
import { labelMappingSecondaryHeader,TRIP_WISE_COLLECTION_ENUM } from './config';

const propTypes = {
  data: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
  index: PropTypes.number,
};

const defaultProps = {
  data: dataProps.defaultValue,
  index: null,
};

const labelMappings = {
  tripId: 'tripId',
  date: 'date',
  tripName: 'Trip Name',
  collector: 'collector',
  amount: 'amount',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  // {
  //   id: 1,
  //   label: labelMappings.tripId,
  //   title: 'Trip Id',
  //   show: false,
  // },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.tripName,
    title: 'Trip Name',
    show: true,
    isDynamic: true,
  },
  {
    id: 4,
    label: labelMappings.collector,
    title: 'Collector',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
    align: 'text-right',
  },
  {
    id: 6,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = ({ tableConfig, dynamicHeader }) => (
  <thead className=''>
    <tr>
      {(dynamicHeader ? getUpdatedLabel(tableConfig) : tableConfig)?.map((label) =>
        label.show ? tableData(label) : null,
      )}
    </tr>
  </thead>
);

const TableHeaderForAccordion = ({ tableConfig, dynamicHeader }) => (
  <tr>
    {(dynamicHeader ? getUpdatedLabel(tableConfig) : tableConfig)?.map((label) => (label.show ?
      <th key={label.id} className={`${label.align ? 'text-right' : ''} ${label.classNames ? label.classNames : ''}`}>
        {label.title}
      </th>
      : null))}
  </tr>
);

const returnTableBody = (data, handleIconClick,handleRowClick, permission) => {
  const table = {};
  // table[labelMappings.tripId] = (
  //   <td key={`tripwise-${data.id}-tripId`}
  //     onClick={()=>handleRowClick(data)}
  //   >
  //     {data?.tripId}
  //   </td>
  // );
  table[labelMappings.date] = <td key={`tripwise-${data.id}-date`}
    onClick={()=>handleRowClick(data)}
  >
    <span className='carrot-icon' />
    {data?.date}
  </td>;
  table[labelMappings.tripName] = <td key={`tripwise-${data.id}-tripName`}
    onClick={()=>handleRowClick(data)}
  >{data?.tripName}</td>;
  table[labelMappings.collector] = <td key={`tripwise-${data.id}-collector`}
    onClick={()=>handleRowClick(data)}
  >{data.collector}</td>;
  table[labelMappings.amount] = (
    <td key={`tripwise-${data.id}-amount`} className='text-right'
      onClick={()=>handleRowClick(data)}
    >
      {data.amount}
    </td>
  );

  table[labelMappings.actionHolders] = (
    <td key={`tripwise-${data.id}-receive-button`}>
      {
        permission.create &&
        <ReceiveButton onClick={(e) => {
          e.stopPropagation();
          handleIconClick(TRIP_WISE_COLLECTION_ENUM.RECEIVE,data)}}
        className='receive-button'
        >
          Receive
        </ReceiveButton>

      }
    </td>
  );

  return table;
};

const returnAccordionTableBody = (data, onImageClick, permission) => {
  const table = {};

  table[labelMappingSecondaryHeader.outlet] = <td key={`tripwise-${data.id}-outlet`}>{data?.outletName}</td>;

  table[labelMappingSecondaryHeader.route] = <td key={`tripwise-${data.id}-route`}>{data?.routeName}</td>;

  table[labelMappingSecondaryHeader.collectionType] = (
    <td key={`tripwise-${data.id}-collectionType`}>{data.collectionType}</td>
  );

  table[labelMappingSecondaryHeader.valuedDate] = <td key={`tripwise-${data.id}-valuedDate`}>{data.valuedDate}</td>;

  table[labelMappingSecondaryHeader.image] = (
    <td key={`tripwise-${data.id}-image`}>
      {data?.images?.length > 0 &&
       data.images.map((i)=> i.src)
         .filter((img)=> img !== '').length > 0 && (
        <div
          className='image-container'
          onClick={(e)=> {
            e.stopPropagation();
            onImageClick(0, data?.images);
          }}
        >
          <img src={data?.images[0]?.src} alt='images' className='image-class' />

          {data?.images?.length - 1 > 0 ? (
            <div className='images-wrapper'>
              <span className='image-text'>+{data?.images?.length - 1}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </td>
  );

  table[labelMappingSecondaryHeader.amount] = (
    <td key={`tripwise-${data.id}-amount`} className='text-right'>
      {data.amount}
    </td>
  );

  table[labelMappingSecondaryHeader.empty] = <td key={`tripwise-${data.id}-empty`} />;

  return table;
};

const TableBodyForAccordion = ({ data, tableConfig, index, onImageClick, permission }) => {
  const tableBody = returnAccordionTableBody(data, onImageClick, permission);
  return (
    <tr key={`tripwise-${index}-details`}>{tableConfig?.map((label) => (label.show ? tableBody[label.label] : null))}</tr>
  );
};

const TableBody = ({ data, tableConfig, handleIconClick,handleRowClick, permission }) => {
  const tableBody = returnTableBody(data, handleIconClick,handleRowClick, permission);
  return tableConfig?.map((label) => (label.show ? tableBody[label.label] : null));
};

TableBody.propTypes = propTypes;

TableBody.defaultProps = defaultProps;

export { TableHeader, TableBody, labelConfig, TableBodyForAccordion, TableHeaderForAccordion };
