import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import TownView from './View';
import TownForm from './Form';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { town } from '../../common/DomainConfig';
import { isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { refGenerator } from '../../../utils/refGenerator';
import withAlert from '../../../utils/composition/withAlert';
import { getPermissionForTown } from '../../base/permission';
import { MAX_LIMIT_OFFSET_FILTER } from '../../../data/enums/filter';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { crudRequest as crudRequestConfig, breadCrumbConfig, formConfig, filterConfig } from './config';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { clone } from '../../../utils/objectProcessor';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  getTerritoryList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  createTown: PropTypes.func.isRequired,
  updateTown: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  getTownList: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getLastLocationHierarchyList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Town extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      territories: [],
      locationHierarchyList: [],
      locationHierarchyTitleList: [],
    };

    this.permission = getPermissionForTown();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createTown,
      [EVENT_OPERATION.UPDATE]: props.updateTown,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        this.setState(data);
        displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
        this.loadTableData();
      },
      this.getData,
      ['title', 'territoryId'],
    );
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
    this.getLastLocationHierarchyList();
    this.getLocationHierarchyList();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.TOWN
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.TOWN);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };


  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getTownList, getTerritoryList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getTownList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.towns && response.data.towns.rows) || [];
          data.total = (response.data.towns && response.data.towns.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );

    getTerritoryList(
      {
        ...MAX_LIMIT_OFFSET_FILTER,
      },
      {
        handleSuccess: response => {
          this.setState({
            territories: (response.data.territories && response.data.territories.rows) || [] || [],
          });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getLastLocationHierarchyList = () => {
    const { queryParameters } = this.state;
    const { getLastLocationHierarchyList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getLastLocationHierarchyList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          this.setState({
            locationHierarchyList: response.data.getLeastLevelLocationHierarchyDetails
              ? response.data.getLeastLevelLocationHierarchyDetails.rows
              : [],
          });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getLocationHierarchyList = () => {
    const { queryParameters } = this.state;
    const { getLocationHierarchy } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getLocationHierarchy(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          sort: [{ sortBy: 'id', order: 'ASC' }],
        },
      },
      {
        handleSuccess: response => {
          this.setState({
            locationHierarchyTitleList:
              (response.data.getLocationHierarchy && response.data.getLocationHierarchy.rows) || [],
          });
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const { data, queryParameters, territories, display, locationHierarchyList, locationHierarchyTitleList,filterMenu} =
      this.state;
    const { serverResponseWaiting } = this.props;
    const lastLocationHierarchy = locationHierarchyTitleList[locationHierarchyTitleList.length - 1];

    return (
      <DialogWrapper
        onDialogSubmit={this.onFormSubmit}
        formConfig={formConfig}
        refsObj={this.formReference}
        renderDialog={({
          onDialogSubmit,
          handleDialogInputChange,
          handleDialogDropDownChange,
          dialogData,
          enableErrorDisplay,
        }) => (
          <Fragment>
            <TownForm
              show
              data={dialogData}
              territories={territories}
              refsObj={this.formReference}
              onFormSubmit={onDialogSubmit}
              loading={serverResponseWaiting}
              lastLocation={lastLocationHierarchy}
              enableErrorDisplay={enableErrorDisplay}
              locationHierarchyList={locationHierarchyList}
              handleInputChange={handleDialogInputChange}
              handleDropDownChange={handleDialogDropDownChange}
            />
          </Fragment>
        )}
        render={({ onDialogItemClick }) => (
          <Fragment>
            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  display={display}
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title: town.title,
                    create: this.permission.create,
                    download: false,
                    filter: true,
                    search: true,
                  }}
                  filter={{
                    date: queryParameters.date,
                    menuList: filterMenu,
                    onFilterChange: this.basePaginationService.handleFilterChange,
                  }}
                  queryParameters={queryParameters}
                  controlDisplay={this.controlDisplay}
                  handleCreateClick={onDialogItemClick}
                  resetFilter={this.basePaginationService.resetFilter}
                  clearSearchText={this.basePaginationService.clearSearchText}
                  handleSearchChange={this.basePaginationService.handleSearchInputChange}
                  handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                  handleDownloadClick={this.basePaginationService.handleDownloadClick}
                />
              </PanelStyled>
            </div>
            <div className="section-content table-present">
              <TownView
                data={data}
                permission={this.permission}
                pagination={queryParameters.pagination}
                onIconClick={onDialogItemClick}
                loading={serverResponseWaiting}
                onPageChange={this.basePaginationService.onPageSelect}
                onBulkDelete={this.handleBulkDelete}
                lastLocation={lastLocationHierarchy}
              />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

Town.propTypes = propTypes;

Town.defaultProps = defaultProps;

export default withAlert()(Town);
