import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_RETAILER_CATALOG_DETAIL } from '../../../sku/createSku/API';
import { ASSORTMENT_COUNT_BY_PRODUCT_HIERARCHY_FRAGMENT } from '../skuAssortment/API';

const GET_ASSORTMENT_COUNT_BYPRODUCT_HIERARCHY = gql`
  query getAssortmentCountByProductHierarchy($distributorGroupId: Int!) {
    getRetailerSkuAssortedCount(distributorGroupId: $distributorGroupId) {
      ...AssortmentCountByProductHierarchyFragment
    }
  }
  ${ASSORTMENT_COUNT_BY_PRODUCT_HIERARCHY_FRAGMENT}
`;

const GET_RETAILER_ASSORTMENT_SKUS = gql`
  query getRetaileSKUAssortmentSkus($distributorGroupId: Int!, $brandId: Int!) {
    retailerSkuAssortments(distributorGroupId: $distributorGroupId, brandId: $brandId) {
      rows
    }
  }
`;

const TOGGLE_SKU_IN_RETAILER_ASSORTMENT = graphql(
  gql`
    mutation ($distributorGroupId: Int!, $skuIdList: [Int!], $status: Boolean!) {
      toggleSkuInRetailerAssortment(distributorGroupId: $distributorGroupId, skuIdList: $skuIdList, status: $status) {
        count {
          brands {
            id
            count
          }
          skuFamilies: productPacks {
            id
            count
          }
          productGroups: productCategories {
            id
            count
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleSkuInRetailerAssortment: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getCatalogDetails: GET_RETAILER_CATALOG_DETAIL,
  getRetaileSKUAssortmentSkus: GET_RETAILER_ASSORTMENT_SKUS,
  getAssortmentCountByProductHierarchy: GET_ASSORTMENT_COUNT_BYPRODUCT_HIERARCHY,
};
const mutation = {
  toggleSkuInRetailerAssortment: TOGGLE_SKU_IN_RETAILER_ASSORTMENT,
};

export {
  query, mutation, TOGGLE_SKU_IN_RETAILER_ASSORTMENT
};
