import { compose } from 'recompose';
import LinkedDeviceView from './View/LinkedDevices.jsx';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, TOGGLE_LINKED_DEVICE_STATUS, DELETE_LINKED_DEVICE
} from './API';

const LinkedDevice = compose(
  TOGGLE_LINKED_DEVICE_STATUS,
  DELETE_LINKED_DEVICE,
  withRequestTracker({
    query,
    mutation,
  }),
)(LinkedDeviceView);

export default LinkedDevice;
