import styled from 'styled-components';

const ClassficationWrapper = styled.div`
  .create-ui-inner {
    padding: 48px;
    display: flex;
  }
`;

export default ClassficationWrapper;
