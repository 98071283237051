import { compose } from 'recompose';
import SUBD from './SubD';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, TOGGLE_STATE, DOWNLOAD_REPORT
} from './API';

const ComposedSUBD = compose(
  TOGGLE_STATE,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(SUBD);

export default ComposedSUBD;
