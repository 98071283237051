import styled from 'styled-components';

const RosterPrintViewStyled = styled.div`
  @media print {
    .roster-print-header {
      margin-bottom: 77px;

      .user {
        font-size: 36px;
        line-height: 45px;
        font-weight: bold;
        color: #272833;
        margin-bottom: 14px;
      }

      .weekly-title {
        font-size: 36px;
        line-height: 45px;
        font-weight: bold;
        color: #272833;
        margin-bottom: 0;
      }

      .monthly-title {
        font-size: 14px;
        line-height: 21px;
        font-weight: bold;
        color: #272833;
        margin-bottom: 0;
      }

      .address {
        font-size: 14px;
        line-height: 21px;
        color: #272833;
        font-weight: 600;
      }

      .monthly-address {
        font-size: 10px;
        line-height: 10px;
        color: #6b6c7e;
      }
    }

    .roster-print-date {
      h3 {
        border-radius: 4px;
        padding: 6px 16px;
        border: 1px solid #cdced9;
        font-size: 14px;
        line-height: 21px;
        color: #272833;
        font-weight: 600;
        text-align: center;
      }

      span {
        font-size: 10px;
        line-height: 10px;
        color: #6b6c7e;
        display: inline-block;
        position: relative;
        top: -43px;
        margin-left: 16px;
      }
    }

    .calendar-top-header {
      .title {
        display: none;
      }
    }
  }
`;

export default RosterPrintViewStyled;
