import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../../data/enums/Component';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onHeaderClick: PropTypes.func.isRequired,
};

const defaultProps = {
  onRowClick: () => null,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const Reconciled = ({ ...props }) => {
  const { data, onPageChange, pagination, onRowClick, onHeaderClick } = props;

  return (
    <Fragment>
      <div className='table-wrap prn'>
        <table>
          {TableHeader({ onHeaderClick })}
          <tbody>{data.list.map((srn) => TableBody(srn, onRowClick))}</tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

Reconciled.propTypes = propTypes;

Reconciled.defaultProps = defaultProps;

const ReconciledWithErrorAndLoading = withErrorAndLoadingScreen(Reconciled);

export default ReconciledWithErrorAndLoading;
