import React from 'react';
import moment from 'moment';
import { Icon } from '..';
import { ALERT_TYPE } from '../../constants/AlertType';
import { EMPTY } from '../../constants/inputErrors';
import { isObjectEmpty } from '../../utils/objectPrototypes';
import { MessageStyled } from '../Input/InputStyled';

interface FileInputProps {
  id: string;
  name?: string;
  accept?: string;
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
  onChange: (e: any) => void;
  file: any;
  min: number;
  max: number;
  displayAlert: (alertType: string, message: string) => void;
  disableOnInvalidCSV: () => void;
  enableValidation: boolean;
  enableErrorDisplay: boolean;
}

const defaultProps = {
  id: '',
  min: 1,
  file: {},
  accept: '*' /* all file types */,
  max: 8000000 /* 8 MB */,
  className: '',
  multiple: false,
  disabled: false,
  name: 'fileUpload',
  onChange: () => null,
  placeholder: 'Upload',
  enableValidation: true,
  errorMessage: EMPTY,
  enableErrorDisplay: false,
  iconName: '',
  disableOnInvalidCSV: false,
};

const errorMessage = '';
const iconName = undefined;
const FileInput = ({ id, name, accept, disabled, multiple, className, onChange, file, min, max,
  disableOnInvalidCSV, displayAlert, enableValidation, enableErrorDisplay }: FileInputProps) => {

  const getValidState = () => {
    const empty = isObjectEmpty(file);
    if (!empty) {
      return file.size > min && file.size <= max;
    }

    return false;
  };

  const readFile = (e: any) => {
    const reader = new FileReader();
    reader.onload = handleFileLoad;
    reader.readAsText(e.target.files[0]);
  };

  const handleFileLoad = (e: any) => {
    const lines = e.target.result.trim().split('\n');
    const validDatesExist = validateDates(lines);
    if (!validDatesExist) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Date field(s) in invalid format!');
      disableOnInvalidCSV();
    }
  };

  const findDatesColumnIndex = (lines: any) => {
    const fields = lines[0].trim().split(',');
    const manufactureColumn = fields.indexOf('manufacture');
    const expiryColumn = fields.indexOf('expiry');
    return { manufactureColumn, expiryColumn };
  };

  const validateDates = (lines: any) => {
    const { manufactureColumn, expiryColumn } = findDatesColumnIndex(lines);
    for (let i = 1; i < lines.length; i++) {
      const currentline = lines[i].trim().split(',');
      const possibleDateFormats = [
        'D/M/YY',
        'DD/MM/YY',
        'DD/M/YY',
        'YYYY-MM-DD',
        'DD-MM-YYYY',
        'YYYY/MM/DD',
        'DD/MM/YYYY',
      ];

      const areDatesValid =
        moment(currentline[manufactureColumn], possibleDateFormats, true).isValid() ||
        moment(currentline[expiryColumn], possibleDateFormats, true).isValid();
      if (!areDatesValid) {
        return false;
      }
    }
    return true;
  };

  const onUploadClick = () => {
    document.getElementById('uploadCSV')?.addEventListener('change', readFile, false);
  };

  const valid = getValidState();
  const isError = enableValidation && enableErrorDisplay && !valid;
  return (
    <div className={valid ? 'error-input mb-24' : 'mb-24'}>
      <input
        id={id}
        type="file"
        name={name}
        accept={accept}
        disabled={disabled}
        multiple={multiple}
        className={className}
        onClick={() => document.getElementById('uploadCSV') && onUploadClick()}
        onChange={onChange}
      />
      {isError && (
        <MessageStyled status="">
          {iconName !== undefined ? <Icon iconName={iconName} /> : ''} {errorMessage}
        </MessageStyled>
      )}
    </div>
  );
};

FileInput.defaultProps = defaultProps;

export default FileInput;
