import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SkuView from './View';
import SkuForm from '../../common/CSVUploadForm';
import { DialogFormWrapper } from '../../common';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { SKU_CREATE, SKU_DETAILS } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { crudSuccess as crudRequestConfig, breadCrumbConfig, formConfig, filterConfig, title } from './config';
import Confirmation from '../../common/DialogConfirmation';
import * as queryService from '../../base/query.service';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { fetchFromLocalStorage, isConfirmationType, isError, storeInLocalStorage } from '../../common/HelperFunctions';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import { getPermissionForSKU } from '../../base/permission';
import { Icon } from '../../../v4/components';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import { clone } from '../../../utils/objectProcessor';
import { DOMAIN } from '../../../data/enums/config';
import { storageKeys } from '../../sales/orderProcessing/config';

const propTypes = {
  upload: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getSkuList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  toggleState: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Sku extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
      filterMenu: this.filterConfigMenu || {},
    };
    this.permission = getPermissionForSKU();
    const { displayAlert, downloadReport } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
      [EVENT_OPERATION.UPDATE_STATUS]: props.toggleState,
    };
    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        let message = crudRequestConfig[type].message;
        if (type === EVENT_OPERATION.UPLOAD) {
          message = data;
        } else {
          this.setState(data);
        }
        this.loadTableData();
        displayAlert(ALERT_TYPE.SUCCESS, message);
      },
      this.getData,
      ['title'],
    );
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.SKU
    );
  };

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.SKU);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  loadTableData = () => {
    const { getSkuList } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    // all urls for search, filter and pagination
    this.setFilterStateInStorage();
    getSkuList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.skus && response.data.skus.rows) || [];
          data.total = (response.data.skus && response.data.skus.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  directToCreatePage = (type, id) => {
    const { history } = this.props;
    if (type === EVENT_OPERATION.UPDATE) {
      history.push(`/${SKU_DETAILS}/${id}`);
    } else {
      history.push(`/${SKU_CREATE}`);
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  updateDialog = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  render() {
    const { data, dialog, display, queryParameters, filterMenu } = this.state;
    const { type } = dialog;
    const { serverResponseWaiting, displayAlert } = this.props;

    return (
      <Fragment>
        {type && (
          <DialogFormWrapper
            onDialogSubmit={this.onFormSubmit}
            formConfig={formConfig[type]}
            dialogElement={dialog.element}
            onDialogCancel={this.resetDialog}
            activeStatus
            type={type}
            renderDialog={({ dialogData, enableErrorDisplay, handleFileUpload, refsObj, disableOnInvalidCSV }) => (
              <Fragment>
                {type === EVENT_OPERATION.UPLOAD && (
                  <>
                    <SkuForm
                      id="uploadCSV"
                      show
                      data={dialogData}
                      loading={serverResponseWaiting}
                      handleFileUpload={handleFileUpload}
                      enableErrorDisplay={enableErrorDisplay}
                      refsObj={refsObj}
                      displayAlert={displayAlert}
                      disableOnInvalidCSV={disableOnInvalidCSV}
                    />
                    <a href="https://rosia-sample-files.s3.ap-south-1.amazonaws.com/sampleSku.csv">
                      <span className="sample-csv">
                        <Icon iconName="download" />
                        Sample CSV
                      </span>
                    </a>
                  </>
                )}
                {isConfirmationType(type) && Confirmation(type)}
              </Fragment>
            )}
          />
        )}
        <Fragment>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title,
                  create: this.permission.create,
                  download: true,
                  filter: true,
                  upload: this.permission.create,
                  search: true,
                }}
                downloadConfig={{
                  domain: DOWNLOAD_DOMAIN.SKU_LIST_REPORT,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList: filterMenu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                queryParameters={queryParameters}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.directToCreatePage}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
                handleUploadClick={this.updateDialog}
              />
            </PanelStyled>
          </div>
          <div className="section-content table-present">
            <SkuView
              data={data}
              permission={this.permission}
              pagination={queryParameters.pagination}
              onIconClick={this.updateDialog}
              loading={serverResponseWaiting}
              onButtonClick={this.directToCreatePage}
              onPageChange={this.basePaginationService.onPageSelect}
            />
          </div>
        </Fragment>
      </Fragment>
    );
  }
}

Sku.propTypes = propTypes;

Sku.defaultProps = defaultProps;

export default withAlert()(Sku);
