import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CheckBox, tableHeaderWithCheckBox } from '../../../v4/components';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  brands: 'brands',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.brands,
    title: 'Brands',
    show: true,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

const returnTableBody = (data, checkedList, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data.title}</td>;
  table[labelMappings.brands] = <td key={`${data.id}-brands`}>{data.brandCount}</td>;

  return table;
};

const TableBody = ({ data, checkedList, secondaryCheckboxHandler, permission, onTableBodyClick }) => {
  const tableBody = returnTableBody(data, checkedList, secondaryCheckboxHandler, permission, onTableBodyClick);

  return (
    <tr key={data.id} onClick={() => onTableBodyClick(EVENT_OPERATION.DETAILS, data.id)} style={{ cursor: 'pointer' }}>
      <Link to={`route-segmentation/details/${data.id}`}>
        {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
      </Link>
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  checkedList: PropTypes.instanceOf(Array),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
  onTableBodyClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object).isRequired,
};

TableBody.defaultProps = {
  checkedList: [],
  onTableBodyClick: () => null,
};

export { TableHeader, TableBody };
