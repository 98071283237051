const resolver = (file) => {
  const { name, path } = file;

  const newWin = window.open(`${window.origin}/${name}`);
  if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
    alert('Please enable pop for this site');
  }
};

export { resolver };

export default resolver;
