import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import withAlert from '../../../../../utils/composition/withAlert';
// import sampleData from '../../details/data';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { DialogFormWrapper, DialogWrapper } from '../../../../common';
import View from './View';
import UserDetailsStyled from './UserDetailsStyled';
import { Input, SingleDatePicker } from '../../../../../components';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import sampleData from './data';
import { EVENT_OPERATION } from '../../../../../v4/constants/EventOperation';
import Form from './Form';
import { fileConfig, formConfig } from './config';
import TargetAchievementFormWithErrorAndLoading from '../../UploadForm';
import { DOWNLOAD_DOMAIN } from '../../../../../data/enums/GraphQL';
import { Icon } from '../../../../../v4/components';
import { fileParser } from '../../../../../utils/parser';
import { getPermissionForTargets } from '../../../../base/permission';
import { isError } from '../../../../common/HelperFunctions';
// import TargetAchievementFormWithErrorAndLoading from '../../../userFundamentalTargets/Form';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getUserTargetDetails: PropTypes.func.isRequired,

  displayAlert: PropTypes.func.isRequired,
  updateUserFundamentalTargetDetail: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,

  match: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object),
  dialogData: PropTypes.instanceOf(Object),
};

const defaultProps = {
  serverResponseWaiting: false,
  location: {},
  dialogData: {},
};

class UserDetails extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      data: sampleData,
      titleWithouttype: false,
      detailId: parseInt(match.params.id, 10),
      dialog: {
        type: '',
        element: '',
      },
    };
    this.permission = getPermissionForTargets();
  }

  static getDerivedStateFromError(error) {
    return { hasError: false };
  }

  componentDidMount() {
    this.getList();
  }

  handleDownloadClick = () => {
    const { downloadReport, displayAlert } = this.props;
    const { detailId } = this.state;

    downloadReport(
      {
        input: {
          domain: 'USER_FUNDAMENTAL_TARGET',
          reportType: 'csv',
          filter: {
            filters: [
              {
                column: 'target_id',
                value: `${detailId}`,
              },
            ],
          },
        },
      },
      {
        handleSuccess: response => {
          const { downloadReport } = response.data;
          window.open(`${window.location.origin}/${downloadReport.file.name}`);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  getList = () => {
    const { getUserTargetDetails, displayAlert } = this.props;
    const { detailId } = this.state;

    getUserTargetDetails(
      {
        targetId: detailId,
      },
      {
        handleSuccess: response => {
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({
            data: response.data.getUserTargetDetails || [],
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleIconClick = (id, targetData = {}, type) => {
    this.setState({
      dialog: {
        type,
        element: targetData,
      },
    });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onFormSubmit = (type, dialogData) => {
    const { updateUserFundamentalTargetDetail, displayAlert } = this.props;
    const { dialog, detailId } = this.state;

    updateUserFundamentalTargetDetail(
      {
        input: {
          ulpo: +dialogData?.ulpo,
          tls: +dialogData?.tls,
          sbd: +dialogData?.sbd,
          billCuts: +dialogData?.bill_cuts,
          dgp: +dialogData?.dgp,
          activeOutlets: +dialogData?.active_outlets,
          productivity: +dialogData?.productivity,
          targetId: dialog?.element?.userTargetId ? dialog?.element?.userTargetId : detailId,
          userId: dialog?.element?.id,
        },
      },
      {
        handleSuccess: res => {
          this.getList();
          displayAlert(ALERT_TYPE.SUCCESS, `${MESSAGE_EVENT_OPERATION.UPDATE}`);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  onDialogCancel = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleDialogSubmit = async (type, data) => {
    if (type === EVENT_OPERATION.UPLOAD) {
      const { upload, displayAlert } = this.props;
      const {
        file: { name },
      } = data;
      const inputData = await this.getFileData(data);
      upload(
        {
          ...inputData,
        },
        {
          handleSuccess: response => {
            this.getList();
            displayAlert(ALERT_TYPE.SUCCESS, 'File uploaded successfully');
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
    // alert('test dialog submit');
  };

  getFileData = async data => {
    const { dialog, detailId } = this.state;

    const fileData = await fileParser(data.file).then(file => ({
      input: {
        file,
        type: 'USERFUNDAMENTALTARGET',
        references: [
          {
            type: 'target_id',
            id: detailId,
          },
        ],
      },
    }));
    return fileData;
  };

  resetDialog = () => {
    // setDialog({
    //   type: '',
    //   element: '',
    // });
    // setDialogData(initialDialogState);
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  sampleUrl = checkCase => (
    <a
      onClick={() => {
        this.onSampleDownload(checkCase);
      }}
    >
      <span className="sample-csv">
        <Icon iconName="download" />
        Sample.csv
      </span>
    </a>
  );

  onSampleDownload = entity => {
    const { downloadReport, displayAlert } = this.props;
    downloadReport(
      {
        input: {
          domain: entity,
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          const { downloadReport } = response.data;
          window.open(`${window.location.origin}/${downloadReport.file.name}`);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  render() {
    const { data, brandDialogData, titleWithouttype, dialog } = this.state;
    const { serverResponseWaiting, location } = this.props;
    const { state = {} } = location;
    const title = state.title;
    const details = state.details;

    return (
      <UserDetailsStyled>
        {dialog?.type && (
          <div className="targets-wrapper">
            <DialogFormWrapper
              type={dialog?.type}
              dialogElement={dialog?.element}
              formConfig={formConfig[dialog?.type]}
              onDialogCancel={this.onDialogCancel}
              onDialogSubmit={dialog?.type === EVENT_OPERATION.UPLOAD ? this.handleDialogSubmit : this.onFormSubmit}
              formTitle={dialog?.element?.user}
              submitClickedControl
              renderDialog={({
                dialogData,
                enableErrorDisplay,
                handleInputChange,
                refsObj,
                handleDateChange,
                handleFileUpload,
              }) => (
                <>
                  {dialog?.type === EVENT_OPERATION.UPDATE || dialog?.type === EVENT_OPERATION.READ ? (
                    <>
                      <Form
                        show
                        type={dialog?.type}
                        refsObj={refsObj}
                        data={dialogData}
                        enableErrorDisplay={enableErrorDisplay}
                        handleInputChange={handleInputChange}
                        handleDateChange={handleDateChange}
                        details={details}
                      />
                    </>
                  ) : (
                    <div>
                      <>
                        <TargetAchievementFormWithErrorAndLoading
                          show
                          // refsObj={fileUploadRefs}
                          data={dialogData}
                          loading={serverResponseWaiting}
                          handleFileUpload={handleFileUpload}
                          enableErrorDisplay={enableErrorDisplay}
                        />
                        {this.sampleUrl(DOWNLOAD_DOMAIN.USER_FUNDAMENTAL_TARGET_SAMPLE)}
                      </>
                    </div>
                  )}
                </>
              )}
            />
          </div>
        )}

        {/* {dialog.type && (
          <DialogFormWrapper
            fileConfig={fileConfig[dialog.type]}
            dialogElement={dialog.element}
            onDialogSubmit={this.handleDialogSubmit}
            onDialogCancel={this.resetDialog}
            type={dialog.type}
            renderDialog={({ dialogData, refsObj, enableErrorDisplay, handleFileUpload }) => (
              <>
                {dialog.type === EVENT_OPERATION.UPLOAD && (
                  <>
                    <TargetAchievementFormWithErrorAndLoading
                      show
                      // refsObj={fileUploadRefs}
                      data={dialogData}
                      loading={serverResponseWaiting}
                      handleFileUpload={handleFileUpload}
                      enableErrorDisplay={enableErrorDisplay}
                    />
                    {this.sampleUrl(DOWNLOAD_DOMAIN.USER_FUNDAMENTAL_TARGET_SAMPLE)}
                  </>
                )}
              </>
            )}
          />
        )} */}

        <View
          data={data}
          // onIconClick={this.onDialogItemClick}
          loading={serverResponseWaiting}
          handleIconClick={this.handleIconClick}
          detailTitle={title}
          permission={this.permission}
          handleDownloadClick={this.handleDownloadClick}
        />
      </UserDetailsStyled>
    );
  }
}

UserDetails.propTypes = propTypes;

UserDetails.defaultProps = defaultProps;

export default withAlert()(UserDetails);
