import { compose } from 'recompose';
import FocusedSkuDialog from './FocusedSkuDialog';
import {
  query, mutation, CREATE_FOCUSED_SKU, UPDATE_FOCUSED_SKU, REPLICATE_FOCUSED_SKU
} from './API';
import { TOGGLE_STATE } from '../../../../common/GqlAPI';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedFocusedSkuDialog = compose(
  CREATE_FOCUSED_SKU,
  UPDATE_FOCUSED_SKU,
  TOGGLE_STATE,
  REPLICATE_FOCUSED_SKU,
  withRequestTracker({
    query,
    mutation,
  }),
)(FocusedSkuDialog);

export default ComposedFocusedSkuDialog;
