import React from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoading from '../../../../../utils/composition/withLoading';
import { CustomSelect, Input } from '../../../../../components';
import { Switch } from '../../../../../v4/components';
import Map from '../../../../common/Map';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import FormContentStyled from '../../../../../components/global-css/FormContentStyled';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    active: PropTypes.bool,
    address: PropTypes.string,
    Town: PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    servicesUsed: PropTypes.shape({
      billing: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
      delivery: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
      logistic: {
        status: PropTypes.bool,
        url: PropTypes.string,
      },
    }),
  }).isRequired,
  menu: PropTypes.shape({
    townList: PropTypes.instanceOf(Array),
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.shape(Object).isRequired,
  getLatLng: PropTypes.func.isRequired,
  crudMode: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(Object).isRequired,
};

const defaultProps = {
  enableErrorDisplay: false,
};

const General = ({ ...props }) => {
  const { menu, data, refsObj, onInputChange, onDropDownChange, enableErrorDisplay, getLatLng, crudMode, labels } =
    props;

  const displayIdField = data.id > 0;

  return (
    <div className='create-ui '>
      <div
        className={`create-ui-inner tab-less-height horizontal-form ${
          crudMode === EVENT_OPERATION.READ ? 'disabled' : ''
        }`}
      >
        <div className='flex'>
          <div className='w-half'>
            <FormContentStyled>
              <h3 className='w-100'>General Details</h3>
              <div className='form-input-wrap w-100'>
                {displayIdField && (
                  <Input
                    name='id'
                    type='number'
                    disabled
                    value={data.id.toString()}
                    labelContent='ID'
                    enableErrorDisplay={false}
                    onChange={(event) => onInputChange(event, 'id', ['id'])}
                  />
                )}
                <Input
                  name='title'
                  type='text'
                  enableValidation
                  value={data.title || ''}
                  placeholder='Name'
                  labelContent='Name'
                  ref={(ref) => (refsObj.title = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event, 'title', ['title'])}
                />
                <Switch
                  onToggle={(checked, event) => {
                    onInputChange(
                      {
                        formattedValue: !!checked,
                        target: {
                          name: 'active',
                          type: 'boolean',
                          value: !!checked,
                        },
                      },
                      'active',
                      ['active'],
                    );
                  }}
                  checked={data.active}
                  name='active'
                  labelContent='Active'
                />

                {/* <CustomSelect
             enableValidation
             labelContent="Town"
             className="custom-select"
             options={menu.townList}
             placeholder="Select Town"
             getOptionValue={({ id }) => id}
             getOptionLabel={({ title }) => title}
             enableErrorDisplay={enableErrorDisplay}
             onChange={event => onDropDownChange(event.id, ['Town', 'id'])}
             value={menu.townList.filter(({ id }) => id === data.Town.id)}
             ref={ref => (refsObj.town = ref)}
             /> */}

                <CustomSelect
                  // enableValidation
                  isMulti
                  labelContent='Towns'
                  className='custom-select'
                  options={menu.townList}
                  placeholder='Select Towns'
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => {
                    const linkedTownIds = event.map((d) => d.id);
                    onDropDownChange(linkedTownIds, ['townIds']);
                  }}
                  value={menu.townList.filter(({ id }) => (data.townIds ? data.townIds.includes(id) : false))}
                />

                <Input
                  name='address'
                  type='text'
                  enableValidation
                  value={data.address || ''}
                  labelContent='Address'
                  placeholder='Address'
                  ref={(ref) => (refsObj.address = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event, 'address', ['address'])}
                />

                <Input
                  name='phoneNumber'
                  type='number'
                  enableValidation
                  value={data.phoneNumber || ''}
                  labelContent='Phone No.'
                  placeholder='Phone No.'
                  ref={(ref) => (refsObj.phoneNumber = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event, 'phoneNumber', ['phoneNumber'])}
                  allowMultiZeroBeforeNumber
                />
                <Input
                  name='owner'
                  type='text'
                  enableValidation
                  value={data.owner || ''}
                  labelContent='Owner'
                  placeholder='Owner'
                  ref={(ref) => (refsObj.owner = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event, 'owner', ['owner'])}
                />
                <Input
                  name='ownerContact'
                  type='number'
                  enableValidation
                  value={data.ownerContact || ''}
                  labelContent='Contact'
                  placeholder='Owner contact'
                  ref={(ref) => (refsObj.ownerContact = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  allowMultiZeroBeforeNumber
                  onChange={(event) => onInputChange(event, 'ownerContact', ['ownerContact'])}
                />

                <Input
                  name='email'
                  type='email'
                  value={data.email || ''}
                  labelContent='Email'
                  placeholder='Email'
                  onChange={(event) => onInputChange(event, 'email', ['email'])}
                />

                <Input
                  name='name'
                  type='text'
                  value={data.secondaryContact && data.secondaryContact.name}
                  labelContent='Secondary Contact Person'
                  placeholder='Secondary Contact Person'
                  onChange={(event) => onInputChange(event, 'secondaryContact', ['secondaryContact', 'name'])}
                />
                <Input
                  name='mobileNumber'
                  type='number'
                  value={data.secondaryContact && data.secondaryContact.mobileNumber}
                  labelContent='Mobile No.'
                  placeholder='Mobile No.'
                  onChange={(event) => onInputChange(event, 'secondaryContact', ['secondaryContact', 'mobileNumber'])}
                />
                <Input
                  name='email'
                  type='email'
                  value={data.secondaryContact && data.secondaryContact.email}
                  labelContent='Email Address'
                  placeholder='Email Address'
                  onChange={(event) => onInputChange(event, 'secondaryContact', ['secondaryContact', 'email'])}
                />
                <CustomSelect
                  isMulti
                  labelContent='Sub-D Tag'
                  className='custom-select'
                  options={labels}
                  placeholder='Select Sub-D Tag'
                  getOptionValue={({ label }) => label}
                  getOptionLabel={({ title }) => title}
                  onChange={(event) => {
                    const labelsTags = event.map((d) => d.label);
                    onDropDownChange(labelsTags, ['labels']);
                  }}
                  value={labels.filter(({ label }) => (data.labels ? data.labels.includes(label) : false))}
                />

                {/* <Input */}
                {/*  name="panNumber" */}
                {/*  type="number" */}
                {/*  enableValidation */}
                {/*  value={data.panNumber || ''} */}
                {/*  labelContent="PAN" */}
                {/*  ref={ref => (refsObj.pan = ref)} */}
                {/*  enableErrorDisplay={enableErrorDisplay} */}
                {/*  onChange={event => onInputChange(event, 'panNumber', ['panNumber']) */}
                {/*  } */}
                {/* /> */}

                <Input
                  name='vatNumber'
                  type='number'
                  rule='matches'
                  enableValidation
                  argument='^((?!(0))[0-9]{9})$' /* matches digit of length 9 and doesn't start with 0 */
                  value={data.vatNumber || ''}
                  labelContent='VAT'
                  placeholder='VAT'
                  ref={(ref) => (refsObj.vat = ref)}
                  enableErrorDisplay={enableErrorDisplay}
                  errorMessage={data.vatNumber ? INVALID : EMPTY}
                  onChange={(event) => onInputChange(event, 'vatNumber', ['vatNumber'])}
                />

                <CustomSelect
                  // enableValidation
                  isMulti
                  labelContent='Linked Distributors'
                  className='custom-select'
                  options={menu.distributorList}
                  placeholder='Select Distributors'
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  // enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => {
                    const listLinkedSubDs = event.map((d) => d.id);
                    onDropDownChange(listLinkedSubDs, ['linkedSubDs']);
                  }}
                  value={menu.distributorList.filter(({ id }) =>
                    data.linkedSubDs ? data.linkedSubDs.includes(id) : false,
                  )}
                />
              </div>
              <h3 className='w-100'>ERP</h3>
              <div className='form-input-wrap w-100'>
                <Input
                  name='businessPartnerId'
                  type='text'
                  value={data.erpDetails && data.erpDetails.businessPartnerId}
                  labelContent='Business Partner ID'
                  placeholder='Business Partner ID'
                  onChange={(event) => onInputChange(event, 'erpDetails', ['erpDetails', 'businessPartnerId'])}
                />
                <Input
                  name='ledgerId'
                  type='text'
                  value={data.erpDetails && data.erpDetails.ledgerId}
                  labelContent='Ledger/Business ID'
                  placeholder='Ledger/Business ID'
                  onChange={(event) => onInputChange(event, 'erpDetails', ['erpDetails', 'ledgerId'])}
                />
              </div>
            </FormContentStyled>
          </div>

          <div className='w-half'>
            <FormContentStyled>
              <h3 className='w-100'>Map</h3>
              <div className='form-input-wrap w-100'>
                <Input
                  name='latitude'
                  type='number'
                  enableValidation
                  labelContent='Latitude'
                  value={data.location && data.location.latitude.toString()}
                  enableErrorDisplay={enableErrorDisplay}
                  ref={(ref) => (refsObj.latitude = ref)}
                  onChange={(event) => onInputChange(event, 'location', ['location', 'latitude'])}
                />
                <Input
                  type='number'
                  name='longitude'
                  enableValidation
                  labelContent='Longitude'
                  value={data.location && data.location.longitude.toString()}
                  enableErrorDisplay={enableErrorDisplay}
                  ref={(ref) => (refsObj.longitude = ref)}
                  onChange={(event) => onInputChange(event, 'location', ['location', 'longitude'])}
                />
                <Map
                  data={data.location ? data.location : { latitude: 0, longitude: 0 }}
                  draggable
                  showSearchInput
                  onDrag={getLatLng}
                />
              </div>
            </FormContentStyled>
          </div>
        </div>
      </div>
    </div>
  );
};

General.propTypes = propTypes;

General.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(General);

export default GeneralInfo;
