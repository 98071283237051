import { Tabs, Tab, TabList, TabPanel, Icon, Button } from '../../../../v4/components';
import styled from 'styled-components';

export const TabsComponent = ({
  tabs,
  activeTab,
  handleTabChange,
  handleButtonCancel,
  handleButtonSubmit,
  loading,
  permission,
  areEqual,
}) => {
  return (
    <TabStyled>
      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <TabList className="custom-scroll react-tabs__tab-list">
          {tabs?.map((tab, index) => (
            <Tab key={index}>
              <span className="tab-label">{tab.label}</span>
              {activeTab === index && <Icon iconName="angle-right" className="cursor-pointer" />}
            </Tab>
          ))}
        </TabList>
        <div className="tab-container">
          {tabs?.map((tab, index) => (
            <>
              <TabPanel key={index}>
                <div className="header">
                  <h2>{!areEqual ? `Editing ${tab?.label}` : ` ${tab?.label}`}</h2>
                  <div className="btn-wrapper">
                    <div className="flex m-0">
                      {permission.update && !areEqual && (
                        <div>
                          <Button secondary small disabled={loading} onClick={() => handleButtonCancel()}>
                            <span>Cancel</span>
                          </Button>
                          <Button primary small disabled={loading} onClick={() => handleButtonSubmit()}>
                            <span>Save</span>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={`custom-scroll body`}>{tab.content}</div>
              </TabPanel>
            </>
          ))}
        </div>
      </Tabs>
    </TabStyled>
  );
};

const TabStyled = styled.div`
  background: #fff;
  .react-tabs {
    display: flex;
    overflow: hidden;
    .react-tabs__tab-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      border-right: 1px solid #e7e7ed;
      margin-left: 48px;
      padding: 16px 24px 24px 0;
      height: calc(100vh - 118px);
      overflow-y: auto;
      overflow-x: hidden;
      .react-tabs__tab {
        min-width: 215px;
        text-align: left;
        margin-bottom: 4px;
        padding: 0 8px;
        span {
          margin-right: 0;
          font-size: 12px;
          line-height: 18px;
          color: #6b6c7e;
          font-weight: 600;
          padding: 8px 4px;
          text-transform: capitalize;
        }
        .icon {
          width: 14px;
          height: 14px;
          position: relative;
          top: -2px;
        }
        &.react-tabs__tab--selected {
          border: 0;
          background-color: #f0f5ff;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          span {
            color: #0b5fff;
          }
        }
      }
    }
    .tab-container {
      flex: 1;
      .react-tabs__tab-panel {
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 21px 48px;
          height: 64px;
          border-bottom: 1px solid #e7e7ed;
          h2 {
            margin: 0;
            text-transform: capitalize;
            font-size: 14px;
            line-height: 21px;
            color: #272833;
            font-weight: 600;
          }
        }
        .body {
          padding: 32px 48px;
          height: calc(100vh - 81px - 115px);
          overflow: auto;
          font-size: 14px;
          line-height: 21px;
          color: #272833;
          .bu-holidays {
            background: #ffffff;
          }
          .cardTitle {
            background-color: #f1f2f5;
            border: 1px solid #e7e7ed;
            border-radius: 4px 4px 0px 0px;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 16px;
            line-height: 18px;
            padding-left: 24px;
            &:not(first-child) {
              margin-top: 32px;
            }
          }
          .form-input-wrap {
            padding: 0;
          }
          .card {
            border: 1px solid #f1f2f5;
            border-radius: 4px;
            padding: 16px 24px !important;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            width: 546px;
            width: 55%;
            flex-wrap: wrap;
          }
          .subCardTitle {
            font-size: 10px;
            font-weight: 400;
            color: #6b6c7e;
            line-height: 15px;
            margin: 0;
            padding-bottom: 16px;
          }
          .sequence {
            width: 55%;
            .outlet-list > div {
              margin-bottom: 16px !important;
            }
          }
          .text {
            margin-top: 0;
          }
          .checkbox-horizontal {
            margin-bottom: 0;
          }
          .placeholder {
            &:after {
              top: 26px;
            }
          }
          .form-input {
            flex: 1;
            display: flex;
            label {
              flex: 1;
            }
            .datepicker-wrapper {
              width: 246px;
            }
            input {
              width: 246px;
              padding-right: 54px;
            }
          }
        }
      }
    }
  }
  .date > div {
    margin-right: 0;
    background-color: #f1f2f5;
    .moment {
      padding: 9px 46px 6px 12px;
      .ic-date-icon {
        top: 10px;
      }
    }
  }
  .subd-holiday-wrap .form-input-wrap {
    width: unset !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .flex-start {
    justify-content: flex-start !important;
  }
  input[type='radio']:checked:after {
    border: 4px solid rgb(11, 95, 255) !important;
  }

  .w-100per {
    width: 100% !important;
  }
  .measurement-wrap .list-content .form-input {
    flex-direction: column;
    text-align: left;
    align-items: baseline;
  }

  @media screen and (max-width: 1279px) {
    .react-tabs .tab-container .react-tabs__tab-panel .body {
      .sequence,
      .card {
        width: 100% !important;
      }
    }
  }
`;
