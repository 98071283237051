import { refGenerator } from '../../../../../../utils/refGenerator';

const discountOptions = [
  {
    value: 'per',
    label: 'in %',
  },
  {
    value: 'rs',
    label: 'in Rs',
  },
];

const totalViewRefs = refGenerator(['']); /** if bill discount is active add bill discount * */

export { discountOptions, totalViewRefs };
