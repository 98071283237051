import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import PrnView from './View';
import { filterConfig, breadCrumbConfig } from './config';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { getPermissionForPrn } from '../../base/permission';
import { PRN_CREATE, PRN_DETAILS } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { purchaseReturn } from '../../common/DomainConfig';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { clone } from '../../../utils/objectProcessor';
import { isError } from '../../common/HelperFunctions';
import { PROGRAM } from '../../../data/enums/Permission';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getPrnList: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Prn extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
    };

    this.permission = getPermissionForPrn();

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }
  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.PRN
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.PRN);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type, id) => {
    const { history } = this.props;
    switch (type) {
      case EVENT_OPERATION.CREATE:
        history.push(`/${PRN_CREATE}`);
        break;
      default:
        history.push(`/${PRN_DETAILS}/${id}`);
    }
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getPrnList } = this.props;

    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getPrnList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          const { purchaseReturns = {} } = response.data;
          data.list = purchaseReturns ? purchaseReturns.rows : [];
          data.total = purchaseReturns ? purchaseReturns.count : 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  render() {
    const { data, queryParameters, display ,filterMenu} = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: purchaseReturn.title,
                create: this.permission.create,
                download: true,
                filter: true,
                date: true,
                search: true,
              }}
              downloadConfig={{
                domain: PROGRAM.PURCHASE_RETURN,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.handleIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content table-present">
          <PrnView
            data={data}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            onRowClick={this.handleIconClick}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </Fragment>
    );
  }
}

Prn.propTypes = propTypes;

Prn.defaultProps = defaultProps;

export { Prn };

export default withAlert()(Prn);
