import { compose } from 'recompose';
import Main from './Main';
import {
  GET_OUTLETS_ARC,
  GET_INVOICE_OUTLETS,
  GET_USER_CLUSTERS,
  SET_USER_CLUSTERS,
  UPDATE_USER_CLUSTERS,
} from '../services/ApiGql';
import { query as userQuery } from '../../user/userAccount/API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedARCmap = compose(
  SET_USER_CLUSTERS,
  UPDATE_USER_CLUSTERS,
  withRequestTracker({
    query: {
      getOutlets: GET_OUTLETS_ARC,
      getInvoiceOutlets: GET_INVOICE_OUTLETS,
      getUserClusters: GET_USER_CLUSTERS,
      getUserList: userQuery.getUserList,
    },
    mutation: {
      setUserClusters: SET_USER_CLUSTERS,
      updateUserClusters: UPDATE_USER_CLUSTERS,
    },
  }),
)(Main);

export default ComposedARCmap;
