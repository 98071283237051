import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withAlert from '../../../../../utils/composition/withAlert';
import sampleData from '../../details/data';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { DialogWrapper } from '../../../../common';
import View from './View';
import BrandDialogWrapper from '../../BrandDialogWrapper';
import { Input, SingleDatePicker } from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { getPermissionForTargets } from '../../../../base/permission';
import { isError } from '../../../../common/HelperFunctions';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  location: PropTypes.instanceOf(Object),
  displayAlert: PropTypes.func.isRequired,
  getCatalogDetailList: PropTypes.func.isRequired,
  getbrandCoverageUserTargets: PropTypes.func.isRequired,
  getbrandCoverageUserTargetsList: PropTypes.func.isRequired,
  createOrEditBrandCoverageUserTarget: PropTypes.func.isRequired,
};

const defaultProps = {
  location: {},
  serverResponseWaiting: false,
};

class BrandCoverageTargetDetails extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const { state = {} } = location;
    this.state = {
      brandData: {
        startDate: state.startDate,
        endDate: state.endDate,
        title: state.title,
      },
      data: sampleData,
      titleWithouttype: false,
      brandDialogData: {
        name: '',
        role: '',
        userId: 0,
        brandTargets: [
          {
            brandId: 0,
            brandName: '',
            target: 0,
          },
        ],
      },
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.permission = getPermissionForTargets();
  }

  static getDerivedStateFromError(error) {
    return { hasError: false };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const { getbrandCoverageUserTargetsList, displayAlert } = this.props;
    const { brandData } = this.state;
    getbrandCoverageUserTargetsList(
      {
        startDate: brandData.startDate,
        endDate: brandData.endDate,
      },
      {
        handleSuccess: response => {
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({
            data: response.data.brandCoverageUserTargetsList || [],
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleBrandDialog = (id, targetData = {}, type) => {
    const { getbrandCoverageUserTargets, displayAlert, getCatalogDetailList } = this.props;
    const { brandData } = this.state;
    getbrandCoverageUserTargets(
      {
        userId: id,
        startDate: brandData.startDate,
        endDate: brandData.endDate,
      },
      {
        handleSuccess: response => {
          const brandTargets = response.data.brandCoverageUserTargets;
          if (brandTargets.length > 0) {
            this.setState(
              {
                brandDialogData: {
                  userId: targetData.id,
                  name: targetData.name,
                  role: targetData.role,
                  brandTargets,
                },
              },
              () => this.changeTitleFlag(type),
            );
          } else {
            getCatalogDetailList(
              {
                catalogId: 3,
                limit: 50,
              },
              {
                handleSuccess: response => {
                  const brandList = response.data.catalogDetails.rows;
                  const brandTargets = brandList.map(brand => ({
                    brandName: brand.title,
                    target: 0,
                    brandId: brand.id,
                  }));
                  this.setState(
                    {
                      brandDialogData: {
                        userId: targetData.id,
                        name: targetData.name,
                        role: targetData.role,
                        brandTargets,
                      },
                    },
                    () => this.changeTitleFlag(type),
                  );
                },
                handleError: error => {
                  displayAlert(ALERT_TYPE.DANGER, error);
                },
              },
            );
          }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleInputChange = (event, index, params) => {
    const { brandDialogData } = this.state;
    if (params === 'target') {
      brandDialogData.brandTargets[index].target = event.target.value;
      this.setState(brandDialogData);
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onFormSubmit = () => {
    const { createOrEditBrandCoverageUserTarget } = this.props;
    const { brandDialogData, brandData } = this.state;
    createOrEditBrandCoverageUserTarget(
      {
        startDate: brandData.startDate,
        endDate: brandData.endDate,
        input: {
          userId: brandDialogData.userId,
          brandTargets: brandDialogData.brandTargets.map(brand => ({ ...brand, target: Number(brand.target) })),
        },
      },
      {
        handleSuccess: () => {
          const { displayAlert } = this.props;
          displayAlert(ALERT_TYPE.SUCCESS, `Target ${MESSAGE_EVENT_OPERATION.UPDATE}`);
          this.getList();
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  changeTitleFlag = type => {
    if (type === EVENT_OPERATION.READ) {
      this.setState({ titleWithouttype: true });
    } else {
      this.setState({ titleWithouttype: false });
    }
  };

  render() {
    const { data, brandDialogData, brandData, titleWithouttype } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <BrandDialogWrapper>
        <DialogWrapper
          title={brandDialogData.name}
          subTitle={brandDialogData.role}
          onDialogSubmit={this.onFormSubmit}
          withOutPadding
          titleWithouttype={titleWithouttype}
          renderDialog={({ type }) => (
            <>
              <div className="detail-wrap">
                <div className="single-date-picker">
                  <label>Start Date</label>
                  <SingleDatePicker
                    date={brandData.startDate}
                    name="startDate"
                    placeholder="Start Date"
                    labelContent="Start Date"
                    disabled
                  />
                </div>
                <div className="single-date-picker">
                  <label>End Date</label>
                  <SingleDatePicker
                    date={brandData.endDate}
                    name="endDate"
                    placeholder="End Date"
                    labelContent="End Date"
                    disabled
                  />
                </div>
              </div>
              <div className="table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th>Brand</th>
                      <th>Brand Target</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brandDialogData.brandTargets.map((brand, i) => (
                      <tr style={{ height: 41 }}>
                        <td>{brand.brandName}</td>
                        <td>
                          <Input
                            value={brand.target}
                            name="target"
                            type="number"
                            rule="isFloat"
                            argument={{ min: 0 }}
                            disabled={type === EVENT_OPERATION.READ}
                            onChange={event => this.handleInputChange(event, i, 'target')}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          render={({ onDialogItemClick }) => (
            <View
              data={data}
              onIconClick={onDialogItemClick}
              handleBrandDialog={this.handleBrandDialog}
              loading={serverResponseWaiting}
              brandData={brandData}
              permission={this.permission}
            />
          )}
        />
      </BrandDialogWrapper>
    );
  }
}

BrandCoverageTargetDetails.propTypes = propTypes;

BrandCoverageTargetDetails.defaultProps = defaultProps;

export default withAlert()(BrandCoverageTargetDetails);
