import PropTypes from 'prop-types';
import React from 'react';
import { CustomSelect } from '../../../components';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import ViewTypeStyled from './ViewTypeStyled';

const ViewType = ({ value, list, displayLabel, label, permission, valueKey, labelKey, handleDropDownChange }) => (
  <ViewTypeStyled>
    <label htmlFor=''>{displayLabel ? `${label}` : null}</label>
    <CustomSelect
      disabled={!permission.read}
      options={list}
      className='custom-select'
      getOptionValue={(item) => item[valueKey].toLowerCase()}
      getOptionLabel={(item) => item[labelKey].toLowerCase()}
      onChange={(event) => handleDropDownChange('type', event[valueKey])}
      value={list.filter((item) => item[valueKey].toLowerCase() === value.toLowerCase())}
      isSearchable={false}
    />
  </ViewTypeStyled>
);

export default ViewType;

ViewType.propTypes = {
  displayLabel: PropTypes.bool,
  handleDropDownChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  list: PropTypes.instanceOf(Array),
  permission: PropTypes.instanceOf(Object),
  value: PropTypes.any,
  valueKey: PropTypes.string,
};

ViewType.defaultProps = {
  displayLabel: true,
  label: 'View By:',
  labelKey: 'label',
  list: [],
  permission: PERMISSION_OBJ,
  valueKey: 'label',
  value: '',
};
