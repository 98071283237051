import React from 'react';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { tableData } from '../../../v4/components';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { normalPresentor } from '../../../utils/date';

// todo update the configurations.
const labelMappings = {
  date: 'date',
  prnDate: 'prnDate',
  amount: 'amount',
  reason: 'reason',
  vendor: 'vendor',
  retailOutlet: 'retailOutlet',
  actionHolders: 'actionHolders',
  GRNInvoiceNumber: 'GRNInvoiceNumber',
  PRNInvoiceNumber: 'PRNInvoiceNumber',
};

const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.PRNInvoiceNumber] = <td key={`${data.id}-prnInvoice`}>{data.prnInvoiceNumber}</td>;
  table[labelMappings.date] = <td key={`${data.id}-date`}>{normalPresentor(data.date)}</td>;
  table[labelMappings.prnDate] = <td key={`${data.id}-prnDate`}>{normalPresentor(data.prnDate)}</td>;
  table[labelMappings.GRNInvoiceNumber] = <td key={`${data.id}-grnInvoice`}>{data.grnInvoiceNumber}</td>;
  table[labelMappings.vendor] = <td key={`${data.id}-vendoeName`}>{data.Vendor.name}</td>;
  table[labelMappings.amount] = (
    <td key={`${data.id}-amount`} className='text-right'>
      {fixedFloatAndCommas(data.prnAmount.total)}
    </td>
  );
  table[labelMappings.reason] = <td key={`${data.id}-reason`}>{data.reason}</td>;

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.PRNInvoiceNumber,
    title: 'Purchase Return Number',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Created Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.prnDate,
    title: 'PRN Date',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.GRNInvoiceNumber,
    title: 'GRN Invoice Number',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.vendor,
    title: 'Vendor',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.amount,
    title: 'PR Amount',
    align: true,
    show: true,
  },
  {
    id: 7,
    label: labelMappings.reason,
    title: 'Remarks',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.actionHolders,
    title: '',
    show: false,
  },
];

const TableBody = (data, onRowClick) => {
  const tableBody = returnTableBody(data);

  return (
    <tr key={`${data.id}-prn`} onClick={() => onRowClick(EVENT_OPERATION.UPDATE, data.id)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

export { TableHeader, TableBody };
