/* global google */
const LatMeter = 111.32 * 1000; // in m
let oneMeterDegreeLat = 1 / LatMeter; // / which gives 0.00000898311174991017 but we take Approx 0.00001
oneMeterDegreeLat = 0.00001;
const LngMeter = (40075 * 1000 * Math.cos(oneMeterDegreeLat)) / 360; // im M
const oneMeterDegreeLng = 1 / LngMeter;

let distance;
const noOfLines = 100;

const verticalLine = [];
const horizontalLine = [];

const latlng = new google.maps.LatLng(27.717, 85.324);
const myOptions = {
  zoom: 16,
  center: latlng,
  mapTypeId: google.maps.MapTypeId.ROADMAP,
};
// var map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);

let viewportBox;

const lineOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.9,
  strokeWeight: 1,
};

// window.state = {
//     show: true
// }

const drawLine = (map) => {
  const degreeMeterLat = oneMeterDegreeLat * distance;
  const degreeMeterLng = oneMeterDegreeLng * distance;
  const bounds = map.getBounds();
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  if (map.getZoom() > 13 && map.getZoom() < 21) {
    for (let i = 0; i <= noOfLines; i++) {
      viewportBox = new google.maps.Polyline({
        path: [
          { lat: ne.lat(), lng: sw.lng() + degreeMeterLat * i },
          { lat: sw.lat(), lng: sw.lng() + degreeMeterLat * i },
        ],
        ...lineOptions,
      });
      verticalLine.push(viewportBox);
    }

    for (let i = 0; i <= noOfLines; i++) {
      viewportBox = new google.maps.Polyline({
        path: [
          { lat: ne.lat() - degreeMeterLng * i, lng: sw.lng() },
          { lat: ne.lat() - degreeMeterLng * i, lng: ne.lng() },
        ],
        ...lineOptions,
      });
      horizontalLine.push(viewportBox);
    }
    setMapOnAll(map);
  } else {
    clearLines(horizontalLine);
    clearLines(verticalLine);
  }
};

const setMapOnAll = () => {
  setLineOnMap(verticalLine);
  setLineOnMap(horizontalLine);
};

const setLineOnMap = (lines) => {
  for (let i = 0; i < lines.length; i++) {
    lines[i].setMap(window.globalMap);
  }
};

const clearLines = (lines) => {
  for (let i = 0; i < lines.length; i++) {
    lines[i].setMap(null);
  }
  lines.length = 0;
};

const showHide = (state) => {
  if (state === true) {
    drawLine(window.globalMap);
    state = !state;
  } else {
    clearLines(horizontalLine);
    clearLines(verticalLine);
    state = !state;
  }
};

const clearAndDraw = (googleMap) => {
  if (verticalLine.length > 1 && horizontalLine.length > 1) {
    clearLines(verticalLine);
    clearLines(horizontalLine);
  }
  drawLine(googleMap);
};

const initializeGridLines = (googleMap, gridSize) => {
  distance = gridSize;
  clearAndDraw(googleMap);
  google.maps.event.addListener(window.globalMap, 'idle', () => {
    clearAndDraw(googleMap);
  });
};

export { initializeGridLines, showHide };
