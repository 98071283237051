import styled from 'styled-components';

const MultiDropDownStyled = styled.div`
  position: relative;
  .dropdown {
    .ellipse {
      cursor: pointer;
    }
    .menu {
      position: absolute;
      margin: 0;
      padding: 0;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 10px 1px;
      z-index: 1;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      border: 1px solid rgb(231, 231, 237);
      cursor: pointer;
      list-style: none;
      border-radius: 2px;
      min-width: 140px;
      padding: 8px 0;
      top: 120%;
      right: 0;
      height: auto;
      li {
        padding: 9px 16px;
        font-size: 14px;
        color: #272833;
        white-space: nowrap;
        img {
          margin-right: 16px;
          position: relative;
          top: 3px;
        }
        svg {
          margin-right: 16px;
          color: #6b6c7e;
        }
        &:hover {
          background: #f0f5ff;
        }
      }
    }
  }
`;
export default MultiDropDownStyled;
