import styled from 'styled-components';

const DragAndDropStyled = styled.div`
  table {
    border: 0;
    thead,
    tbody {
      td,
      tr {
        height: 40px;
        th,
        td {
          padding-top: 0;
          padding-bottom: 0;
          &:first-child {
            padding-left: 48px;
          }
          &:last-child {
            padding-right: 48px;
          }
          & > div.drag-box {
            margin: 0;
            padding: 0;
          }
        }
      }
      td {
        width: 1200px;
      }
    }
  }
  .filter-item {
    button,
    button:hover {
      background-color: transparent;
    }
  }
  .filter-dropdown-card {
    min-width: 130px;
    padding: 8px 0px;
    top: 115%;
    right: 0px;
    .filter-content {
      padding: 0;
      ul li {
        padding: 9px 18px;
        cursor: pointer;
        &:hover {
          background: #f0f5ff;
        }
      }
    }
  }
  .drag-box-td {
    width: 0px;
  }
  .drag-box {
    box-sizing: border-box;
    height: 40px;
    /* border: 1px solid #e7e7ed; */
    border-radius: 8px;
    /* border: 1px solid rgb(231, 231, 237); */
    overflow: hidden;
    margin: 4px 0px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 4px;

    .badge {
      height: 17px;
      width: 27px;
      border-radius: 8px;
      background-color: #eef2fa;
      /* padding: 12px; */
      display: block;
      text-align: center;
      line-height: 20px;
      font-size: 10px;
      color: #6b6c7e;
    }
    p {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .icon {
      color: #da1414;
    }
  }
`;

export default DragAndDropStyled;
