import { collection as collectionRoute ,sales as salesRoute } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';
import { normalPresentor } from '../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { imageSliderListFormatter } from '../../../common/HelperFunctions';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { FILTER } from '../../../../data/enums/filter';


const title = 'Trip Wise Collection';

const TRIP_WISE_COLLECTION_ENUM = {
  RECEIVE:'RECEIVE',
}

const labelMappingMainHeader= {
  total:'total',
  cash:'cash',
  cheque:'cheque',
  pdCheque:'pdCheque',
  online:'online',
  emptyOne:'emptyOne',
  emptyTwo:'emptyTwo',
}

const labelMappingSecondaryHeader= {
  outlet:'outlet',
  route:'route',
  collectionType:'collectionType',
  valuedDate:'valuedDate',
  image:'image',
  amount:'amount',
  empty:'empty',
}


const collapsibleMainHeader = (value) => ([
  { id:0,label: labelMappingMainHeader.total,title: 'Total', show: true,classNames:'total' },
  { id:1, label:labelMappingMainHeader.cash, title:`Cash: ${value?.totalCash}`,show:true ,classNames:'totalValues' },
  { id:2, label:labelMappingMainHeader.cheque, title:`Cheque: ${value?.totalCheque}`,show: true,align:'text-right',classNames:'totalValues' },
  { id:3, label:labelMappingMainHeader.pdCheque, title:`PD Cheque: ${value?.totalPdCheque}`,show: true,align:'text-right',classNames:'totalValues' },
  { id:4, label:labelMappingMainHeader.emptyOne, title:'',show: true, },
  // { id:5, label:labelMappingMainHeader.online, title:`Online: ${value?.totalOnline}`,show: true,align:'text-right',classNames:'totalValues online' },
  { id:6, label:labelMappingMainHeader.emptyTwo, title:'',show: true },
  { id:7, label:labelMappingMainHeader.emptyTwo, title:'',show: true },
]);

const collapsibleSecondaryHeader = [
  { id:0,label:labelMappingSecondaryHeader.outlet, title:'Outlet',show: true, },
  { id:1,label:labelMappingSecondaryHeader.route, title:'Route',show: true, },
  { id:2,label:labelMappingSecondaryHeader.collectionType, title:'Collection Type',show: true, },
  { id:3,label:labelMappingSecondaryHeader.valuedDate, title:'Valued Date',show: true, },
  { id:4,label:labelMappingSecondaryHeader.image, title:'Image',show: true, },
  { id:5,label:labelMappingSecondaryHeader.amount, title:'Amount',show: true,align:'text-right' },
  { id:6, label:labelMappingSecondaryHeader.empty, title:'',show: true, },
];

const dataMapper = (data) => {
  return data?.length > 0 && data?.map((trip)=>({
    tripId:trip?.trip_id ?? '',
    date:trip?.trip_date ? normalPresentor(trip.trip_date) : '',
    tripName:trip?.trip_name ?? '',
    collector:trip?.collector_name ?? '',
    amount: trip?.amount ? fixedFloatAndCommas(trip.amount) : 0,
    total : {
      totalCash:0,
      totalCheque:0,
      totalPdCheque:0,
      totalOnline:0,
    },
    details:{
      outletName:'',
      routeName:'',
      collectionType:'',
      valuedDate :'',
      images:[]
    },
  }))
}

const paymentListMapper = (data) => {

  const totalData = data?.paymentListByTripId?.total;
  const paymentData = data?.paymentListByTripId?.payments;

  const total = {
    totalCash: totalData?.cash ? fixedFloatAndCommas(totalData.cash) : 0,
    totalCheque: totalData?.cheque ? fixedFloatAndCommas(totalData.cheque) : 0,
    totalPdCheque: totalData?.pdc ? fixedFloatAndCommas(totalData.pdc) : 0,
    totalOnline: totalData?.online ? fixedFloatAndCommas(totalData.online) : 0,
  };

  const details = paymentData.map((detail)=> {

    const outletName =  detail?.RetailOutlet?.title ?? '';
    const routeName = detail?.Route?.title ?? '';
    const collectionType = detail?.collectionType ?
      detail?.collectionType.split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
      : '';
    const valuedDate = detail?.details?.valuedDate ? normalPresentor(detail.details.valuedDate) : '';
    const images = detail?.details?.image?.length > 0 ? detail?.details?.image : [];
    const amount = detail?.amount ?? 0;

    return {
      outletName,
      routeName,
      collectionType,
      valuedDate,
      images : [...(imageSliderListFormatter(images, '', { w: 2400, h: 1100 })) || []],
      amount,
    }
  });


  return {
    total,
    details,
  }

}

const formConfig = {
  [TRIP_WISE_COLLECTION_ENUM.RECEIVE]: {
    [FORM_CONFIG.TITLE]: 'Receive Collection',
    'buttonName': 'Receive',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
};


const breadCrumbConfig = [salesRoute, collectionRoute ];

const filter = getFilterConfig([FILTER.COLLECTOR]);

export {
  title, breadCrumbConfig, filter as filterConfig,dataMapper,
  collapsibleMainHeader,collapsibleSecondaryHeader,labelMappingSecondaryHeader,
  TRIP_WISE_COLLECTION_ENUM,formConfig,paymentListMapper,
};
