import styled from 'styled-components';

const CoverageWrapper = styled.div`
  table {
    td {
      width: auto;
      white-space: nowrap;
      &:first-child {
        width: 30%;
      }
      &:last-child {
        .view {
          margin-right: 40px;
        }
        span {
          cursor: pointer;
          color: #6b6c7e;
          font-size: 14px;
        }
        svg.icon {
          height: 14px;
          width: 14px;
          margin-right: 9px;
        }
      }
    }
    td,
    th {
      &:last-child {
        text-align: right;
      }
    }
  }
  .targets-wrapper {
    .modal-wrapper {
      .modal-inner {
        width: 400px;
        .evolve-dialog__body {
          .single-date-picker {
            margin-top: 0;
          }
          .form-input {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .modal-wrapper {
    .modal-inner {
      width: 580px;
      max-width: unset;
      .evolve-dialog__body {
        .form-input {
          margin: 24px 0px;
        }
        .coverage-detail-wrap {
          display: flex;
          .single-date-picker {
            pointer-events: none;
            width: 50%;
            margin: 0;
            margin-right: 24px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export default CoverageWrapper;
