import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Input } from '../../../../components';
import { Icon,Button } from '../../../../v4/components';
import { CH_CAT_ENUMS } from '../../channel/config';
import Switch from '../../../../components/Switch/Switch';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { channelTypeList } from '../config';
import { FORM_CONFIG } from '../../../../data/enums/config';
import FormContentStyled from '../../../../components/global-css/FormContentStyled';

const getCategoriesBar = (categoriesList) =>
  categoriesList.reduce((acc, item, index) => {
    item.status && acc.push({ ...item, index });
    return acc;
  }, []);

const ChannelDetailView = ({
  data,
  channelRefs,
  enableErrorDisplay,
  onInputChange,
  onCategoryChange,
  crudMode,
  onAddButtonClick,
}) => {
  const updatedCategoriesList =
    crudMode === EVENT_OPERATION.UPDATE ? getCategoriesBar(data.categories) : data.categories;
  const categoriesLength = updatedCategoriesList.length;
  return (
    <Fragment>
      <div className='config-wrap'>
        <FormContentStyled>
          <h3>General Details</h3>
          <div className='form-input-wrap'>
            <Input
              name='title'
              type='text'
              enableValidation
              value={data.title}
              labelContent='Title'
              placeholder='Channel'
              ref={(ref) => (channelRefs.title = ref)}
              enableErrorDisplay={enableErrorDisplay}
              onChange={(event) => onInputChange(event, CH_CAT_ENUMS.CHANNEL)}
            />
            <div className='mb-24 form-input'>
              <label>Type</label>
              <div className='checkbox'>
                {channelTypeList.map((item) => (
                  <label className='radio-list' key={item.label}>
                    <input
                      type='radio'
                      name='type'
                      value={item.label}
                      onChange={(e) => onInputChange(e)}
                      checked={data.type === item.label}
                    />
                    <span className='text'>{item.label}</span>
                  </label>
                ))}
              </div>
            </div>
            <Switch
              onToggle={(checked, event) => {
                onInputChange({
                  target: { name: 'status', type: 'boolean', value: checked },
                });
              }}
              checked={data.status}
              name='status'
              labelContent='Status'
            />
          </div>
        </FormContentStyled>

        <FormContentStyled>
          <h3>Categories</h3>
          <div className='form-input-wrap w-100'>
            {updatedCategoriesList.map((item, key) => (
              <Fragment>
                <div className='category-wrap'>
                  <Input
                    type='text'
                    name='title'
                    placeholder='Category'
                    value={item.title}
                    ref={(ref) => (item[FORM_CONFIG.REFS_OBJ].title = ref)}
                    enableErrorDisplay={item.displayError}
                    onChange={(event) => onCategoryChange(event, item.index ? item.index : key)}
                  />
                  <div className='cross-btn'>
                    {crudMode !== EVENT_OPERATION.READ && (
                      <Icon
                        iconName='times'
                        onClick={() =>
                          onCategoryChange({ target: { value: false, name: 'status' } }, item.index ? item.index : key)
                        }
                      />
                    )}
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
          {(categoriesLength === 0 || updatedCategoriesList[categoriesLength - 1].title) &&
            crudMode !== EVENT_OPERATION.READ && (
            <div className='select-btn'>
              <Button secondary small onClick={() => onAddButtonClick(EVENT_OPERATION.CREATE)}>
                <Icon iconName='plus' />
                <span>Add</span>
              </Button>
            </div>
          )}
        </FormContentStyled>
      </div>
    </Fragment>
  );
};

export default withErrorAndLoadingScreen(ChannelDetailView);

ChannelDetailView.propTypes = {
  channelRefs: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  onCategoryChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onAddButtonClick: PropTypes.func.isRequired,
  crudMode: PropTypes.string.isRequired,
};

ChannelDetailView.defaultProps = {
  channelRefs: {},
  data: {},
  enableErrorDisplay: false,
};
