import gql from 'graphql-tag';
import { DEFAULT_QUERY_VARIABLES } from './common';
import { SKU_CATALOG_LEVEL } from '../enums';
import { GET_SKU_LIST } from '../../views/configuration/sku/API';
import { persistentClient as clientResolver, client as apolloClient } from '../../apiClient/apollo';
import { GET_CATALOG_LIST, GET_CATALOG_DETAIL } from '../../views/configuration/sku/createSku/API';

const errorProcessorInFilterDataFetch = () => null;

/** ************* Catalog List: [Principal, Product Group, Brand ...] *************************** */

const getCatalogListFromServer = () =>
  apolloClient
    .query({
      query: GET_CATALOG_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then((response) => {
      const catalogList = response.data.catalogs.rows || [];

      const SKU = {
        id: SKU_CATALOG_LEVEL,
        title: 'SKU',
        parentCatalogId: SKU_CATALOG_LEVEL - 1,
        __typename: 'Catalog',
      };

      catalogList.push(SKU);

      return catalogList;
    })
    .catch((err) => errorProcessorInFilterDataFetch(err));

/** ************* Catalog Children **************************** */

const getCatalogChildrenFromServer = (catalogId, parentCatalogId = null) =>
  apolloClient
    .query({
      query: GET_CATALOG_DETAIL,
      variables: {
        catalogId,
        parentCatalogDetailId: parentCatalogId,
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then((response) => response.data.catalogDetails.rows)
    .catch((err) => errorProcessorInFilterDataFetch(err));

const query = gql`
  query getCatalog {
    catalog {
      id
      title
    }
  }
`;

// catalog_children is stored as : catalog_children.
const getCatalogChildrenQuery = (catalogLevel) => {
  const catalogChildrenQuery = gql(`
    query getCatalogChildren {
      catalog_children_${catalogLevel}
      {
        id,
        title,
      }
    }
  `);

  return catalogChildrenQuery;
};

const setCatalog = (data) => {
  clientResolver.then((client) =>
    client.writeQuery({
      query,
      data: {
        catalog: data,
      },
    }),
  );
};

const setCatalogChildren = (data, catalogLevel) => {
  const catalogChildrenQuery = getCatalogChildrenQuery(catalogLevel);
  clientResolver.then((client) =>
    client.writeQuery({
      query: catalogChildrenQuery,
      data: {
        [`catalog_children_${catalogLevel}`]: data,
      },
    }),
  );
};

const getCatalog = async () =>
  clientResolver.then((client) => client.readQuery({ query })).then((response) => response.catalog);

const getCatalogChildren = async (catalogLevel) => {
  if (!catalogLevel) {
    return [];
  }

  const catChildrenQuery = getCatalogChildrenQuery(catalogLevel);

  return clientResolver
    .then((client) => client.readQuery({ query: catChildrenQuery }))
    .then((response) => response[`catalog_children_${catalogLevel}`]);
};

const getSKUFromServer = () =>
  apolloClient
    .query({
      query: GET_SKU_LIST,
      variables: {
        offset: 0,
      },
    })
    .then((response) => response.data.skus.rows || [])
    .catch((err) => errorProcessorInFilterDataFetch(err));

const fetchAndSetCatalog = async () => {
  const catalogFromServer = await getCatalogListFromServer();
  const SKU = {
    id: SKU_CATALOG_LEVEL,
    title: 'SKU',
    parentCatalogId: SKU_CATALOG_LEVEL - 1,
    __typename: 'Catalog',
  };
  catalogFromServer.push(SKU);
  setCatalog(catalogFromServer);

  getSKUFromServer().then((skuList) => {
    setCatalogChildren(skuList, SKU.id);
  });

  catalogFromServer.forEach((catalog) => {
    if (catalog.id !== SKU.id) {
      getCatalogChildrenFromServer(catalog.id, null).then((response) => {
        setCatalogChildren(response, catalog.id);
      });
    }
  });
};

// Temporary Fix.
const newGetCatalogChildren = (catalogId, parentCatalogId = null) => {
  if (catalogId === SKU_CATALOG_LEVEL) {
    return getSKUFromServer();
  }

  return getCatalogChildrenFromServer(catalogId, parentCatalogId);
};

export {
  setCatalog,
  getCatalogListFromServer as getCatalog,
  newGetCatalogChildren as getCatalogChildren,
  fetchAndSetCatalog,
};
