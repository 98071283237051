import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import Form from './Form';
import { DialogWrapper } from '../../../common';
import PageHeader from '../../../base/PageHeader';
import { userRole } from '../../../common/DomainConfig';
import { PanelStyled } from '../../../common/configuration';
import * as queryService from '../../../base/query.service';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { refGenerator } from '../../../../utils/refGenerator';
import withAlert from '../../../../utils/composition/withAlert';
import { getPermissionForUserRole } from '../../../base/permission';
import { getUserRoleFromServer } from '../../../../data/services/userLabel';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { crudSuccess as crudRequestConfig, breadCrumbConfig, form as formConfig, title, filterConfig } from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { isError } from '../../../common/HelperFunctions';
import headerLabelService from '../../../base/headerLabel.service';
import { USER_ROLE_DETAILS,USER_ROLE_CREATE } from '../../../../data/enums/Route';
import history from '../../../../utils/history';
import RoleStyled from './RoleStyled';
const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getUserRoleList: PropTypes.func.isRequired,
  createUserRole: PropTypes.func.isRequired,
  updateUserRole: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Role extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 1000,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      userGroupList: [],
    };

    this.permission = getPermissionForUserRole();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createUserRole,
      [EVENT_OPERATION.UPDATE]: props.updateUserRole,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.successCallBack = (data, type) => {
      const { displayAlert } = this.props;
      this.setState(data);
      headerLabelService.setHeaderConfig();
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    };
    this.onCRUDSuccess = crudResponseProcessor(this.successCallBack, this.getData, ['displayName', 'name']);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    getUserRoleFromServer().then(response => {
      this.setState({
        userGroupList: (response.data.userGroups && response.data.userGroups.rows) || [],
      });
    });
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getUserRoleList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    // all urls for search, filter and pagination
    getUserRoleList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          const filteredArray = response.data.roles.rows.filter(item => {
            return item.name != USER_ROLE_TITLE.SUPER_ADMIN;
          });
          data.list = (response.data.roles && filteredArray) || [];
          data.total = (response.data.roles && response.data.roles.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  onTableRowClick =data => {
    history.push(`/${USER_ROLE_DETAILS}/${data.id}`);
  }
  handleCreate =data => {
    history.push(`/${USER_ROLE_CREATE}`);
  }
  render() {
    const { data, display, queryParameters, userGroupList } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <RoleStyled>
      <DialogWrapper
        onDialogSubmit={this.onFormSubmit}
        formConfig={formConfig}
        refsObj={this.formReference}
        title={title}
        renderDialog={({
          onDialogSubmit,
          handleDialogInputChange,
          handleDialogDropDownChange,
          dialogData,
          enableErrorDisplay,
        }) => (
          <Fragment>
            <Form
              show
              data={dialogData}
              options={userGroupList}
              onFormSubmit={onDialogSubmit}
              loading={serverResponseWaiting}
              enableErrorDisplay={enableErrorDisplay}
              handleInputChange={handleDialogInputChange}
              handleDropDownChange={handleDialogDropDownChange}
              refsObj={this.formReference}
            />
          </Fragment>
        )}
        render={({ onDialogItemClick }) => (
          <Fragment>
            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  display={display}
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title: userRole.title,
                    create: this.permission.create,
                    download: false,
                    date: false,
                  }}
                  filter={{
                    date: queryParameters.date,
                    menuList: filterConfig.menu,
                    onFilterChange: this.basePaginationService.handleFilterChange,
                  }}
                  queryParameters={queryParameters}
                  controlDisplay={this.controlDisplay}
                  handleCreateClick={this.handleCreate}
                  resetFilter={this.basePaginationService.resetFilter}
                  clearSearchText={this.basePaginationService.clearSearchText}
                  handleSearchChange={this.basePaginationService.handleSearchInputChange}
                  handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                  handleDownloadClick={this.basePaginationService.handleDownloadClick}
                />
              </PanelStyled>
            </div>
            <div className="section-content table-present">
              <View
                data={data}
                permission={this.permission}
                onIconClick={onDialogItemClick}
                loading={serverResponseWaiting}
                onBulkDelete={this.handleBulkDelete}
                pagination={queryParameters.pagination}
                onPageChange={this.basePaginationService.onPageSelect}
                onTableRowClick={this.onTableRowClick}
                options={userGroupList}
              />
            </div>
          </Fragment>
        )}
      />
      </RoleStyled>
    );
  }
}

Role.propTypes = propTypes;

Role.defaultProps = defaultProps;

export default withAlert()(Role);
