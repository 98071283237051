import styled from 'styled-components';

const ICOStyled = styled.div`
.section-content{
  .upload-section{
    padding: 24px 48px;
    .suggested-autocomplete-list-wrap.autocomplete_value{
      display: flex;
      width: 50%;
      float: right;
      flex-wrap: wrap;
      align-items: center;
    .suggested-autocomplete-list{
      display: flex;
      align-items: center;
      flex-wrap:nowrap;
      border: 1px solid #90b8fa;
      border-radius:2px;
      padding: 4px 4px 4px 4px;
      font-size: 12px;
      margin: 0 8px 18px 0;
      color: #8995a6;
      .text{
        white-space: nowrap;
        padding:0;
        background:unset;
        border:unset;
      }
      span:last-child{
        overflow: auto;
        margin-left: 6px;
        transform: scale(.7);
      }
      .ic-close {
          cursor: pointer;
          display: inline-block;
          width: 12px;
          height: 12px;
          overflow: hidden;
          text-indent: -999px;
          background: url("/image/icons/ic_close.svg");
          background-size: 12px;
          margin-left: 18px;
        }
      }
    }
    .form-input-wrap .form-input .upload-input .no-file{
      width:56%;
      .file-name{
        margin: 8px 8px 8px 0;
        width:94%;
        position:relative;
        &>div{
          width:100%;
          .tool-tip{
            flex:1;
          }
        }

      }
    }
  }
  &.section-select .table-wrap{
    height: calc(100vh - 270px) !important;
  }
  .table-wrap{
    overflow-y: auto;
  }
  .subd-sec-header{
    margin:0;
    padding:30px 48px;
    h3{
    margin:0;
    font-size:14px;
    line-height:21px;
    color:#272833;
  }
  .subd-btns{
    display:flex;
    &>div{
      margin-right:16px;
      &:last-child{
        margin-right:0;
      }
      button{
        margin:0;
        &.save-btn{
          img{ 
            height:16px;
          width:16px;
          position: relative;
          top:2px;
        }
        }
      }
    }
  }
}
   .zindex-2__control{
        background-color:#F1F2F5;
    }
    .filter-item {
      .header-dom{
        display: flex;
         justify-content: space-between;
         label{
          font-size: 14px;
          line-height: 21px;
          color: #272833;
          font-weight: 500;
          width: calc(92.57% - 10px);
          margin: 0;
          margin-right: 10px;
         }
     }
    }
    .filter-dropdown-card {
      min-width: 340px;
    }

    .tool-tip {
      display: inline-block;
      white-space: nowrap; 
      width: 100px; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }

    .tool-tip-text {
      border: 1px solid #cdced9;
      visibility: hidden;
      white-space: nowrap; 
      background-color: white;
      color: #6b6c7e;
      text-align: left;
      padding: 5px 5px;
      border-radius: 3px;
      left: 118%;
      top: 0%;
      position: absolute;
      z-index: 1;
    }

    .tool-tip:hover + .tool-tip-text {
      visibility: visible;
      
    }
}
`;

export default ICOStyled;