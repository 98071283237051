import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { CAMPAIGNS, configuration as configurationRoute } from '../../../data/enums/Route';
import { refGenerator } from '../../../utils';
import { getTodaysDate } from '../../../utils/date';

const title = 'Sub Campaign';

const errorMessage = {
  childMessage: 'Sub Campaigns budget must not be less than the Scope\'s budget',
};

const SCOPE_LEVEL = {
  CATALOG: 'CATALOG',
  CLASSIFICATION: 'CLASSIFICATION',
};

const classificationLevelTitle = {
  TRADE: 'Trade',
  CHANNEL: 'Channel',
  CATEGORY: 'Category',
};

const subCampaignType = {
  budget: {
    name: 'Budget',
    type: 'budgetType',
  },
  target: {
    name: 'Target',
    type: 'targetType',
  },
};

const BUDGET_TYPE = {
  BILL_DISCOUNT: 'BILL DISCOUNT',
  LINE_DISCOUNT: 'LINE DISCOUNT',
};

const TARGET_TYPE = {
  SALES: 'SALES',
  COVERAGE: 'COVERAGE',
};

const TYPES_SUB_CAMPAIGN = {
  SUB_CAMPAIGN_BUDGET: 'subCampaignBudget',
  SUB_CAMPAIGN_BUDGET_DETAIL: 'subCampaignBudgetDetail',
  SUB_CAMPAIGN_TARGET: 'subCampaignTarget',
  SUB_CAMPAIGN_TARGET_DETAIL: 'subCampaignTargetDetail',
};

const breadCrumb = [
  configurationRoute,
  {
    id: 1,
    title: 'Campaigns',
    path: `/${CAMPAIGNS}`,
    active: true,
  },
  {
    id: 2,
    title: 'Sub Campaigns',
    path: '#',
    active: true,
  },
];

const CATALOG_LEVELS = [
  {
    id: 1,
    label: 'PRINCIPAL',
    title: 'Principal',
    parentList: {},
    column: SCOPE_LEVEL.CATALOG,
    list: [],
  },
  {
    id: 2,
    label: 'PRODUCT_GROUP',
    parentList: {},
    column: SCOPE_LEVEL.CATALOG,
    title: 'Product Group',
    list: [],
  },
  {
    id: 3,
    label: 'BRAND',
    parentList: {},
    column: SCOPE_LEVEL.CATALOG,
    title: 'Brand',
    list: [],
  },
  {
    id: 4,
    label: 'MARKET_CATALOG',
    parentList: {},
    column: SCOPE_LEVEL.CATALOG,
    title: 'Market Catalog',
    list: [],
  },
  {
    id: 5,
    label: 'SUB_BRAND',
    parentList: {},
    column: SCOPE_LEVEL.CATALOG,
    title: 'Sub Brand',
    list: [],
  },
  {
    id: 6,
    label: 'SUB_BRAND_FORM',
    parentList: {},
    column: SCOPE_LEVEL.CATALOG,
    title: 'Sub Brand Form',
    list: [],
  },
  {
    id: 7,
    label: 'SKU_FAMILY',
    parentList: {},
    column: SCOPE_LEVEL.CATALOG,
    title: 'SKU Family',
    list: [],
  },
];

const CLASSIFICATION_LEVELS = [
  {
    id: 8,
    title: 'Trade',
    label: 'TRADE',
    column: SCOPE_LEVEL.CLASSIFICATION,
    parentList: {},
    list: [],
  },
  {
    id: 9,
    title: 'Channel',
    label: 'CHANNEL',
    column: SCOPE_LEVEL.CLASSIFICATION,
    parentList: {},
    list: [],
  },
  {
    id: 10,
    title: 'Category',
    label: 'CATEGORY',
    parentList: {},
    column: SCOPE_LEVEL.CLASSIFICATION,
    list: [],
  },
];

const dimensionListConfig = [
  {
    value: 'AMOUNT',
    label: 'in Rs',
  },
  {
    value: 'PERCENT',
    label: 'in %',
  },
  {
    value: 'VALUE',
    label: 'in Value',
  },
];

const formMapper = (element = {}) => ({
  id: element.id || 0,
  campaignId: element.campaignId || null,
  subCampaignId: element.subCampaignId || null,
  type: element.type,
  typeLevel: element.typeLevel,
  isTypeParent: element.isTypeParent,
  typeId: element.typeId || null,
  parentId: element.parentId || null,
  subCampaignType: element.subCampaignType || '',
  startDate: element.startDate || getTodaysDate(),
  endDate: element.endDate || getTodaysDate(),
  subCampaignBudget: element.subCampaignBudget || [],
  subCampaignTarget: element.subCampaginTarget || [],
});

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['title', 'budgetValue']),
    [FORM_CONFIG.MAPPER]: () => formMapper({}),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['title']),
    [FORM_CONFIG.MAPPER]: () => formMapper({}),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Delete',
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.TITLE]: 'View',
  },
  [EVENT_OPERATION.EDIT]: {
    [FORM_CONFIG.TITLE]: 'Update',
  },
  [EVENT_OPERATION.REMOVE_SCOPE]: {
    [FORM_CONFIG.TITLE]: 'Remove',
  },
  [EVENT_OPERATION.ADD_SCOPE]: {
    [FORM_CONFIG.TITLE]: 'Add',
  },
};

export {
  title as SubCamTitle,
  breadCrumb,
  CATALOG_LEVELS,
  CLASSIFICATION_LEVELS,
  formConfig,
  formMapper,
  SCOPE_LEVEL,
  classificationLevelTitle,
  subCampaignType,
  BUDGET_TYPE,
  TARGET_TYPE,
  TYPES_SUB_CAMPAIGN,
  errorMessage,
  dimensionListConfig,
};
