const sampleData = [
  {
    id: '',
    userTargetId: '',
    user: '',
    role: '',
    ulpo: '',
    tls: '',
    productivity: '',
    active_outlets: '',
    dgp: '',
    sbd: '',
    bill_cuts: '',

    isChildPresent: false,
    childUsers: [],
  },
];

export default sampleData;
