import gql from 'graphql-tag';
import { Catalogs } from '../../../data/enums/ApiFields';
import {
  TOGGLE_STATE, UPLOAD, DOWNLOAD_REPORT
} from '../../common/GqlAPI';

const CATALOG_FRAGMENT = gql`
  fragment CatalogFragment on ${Catalogs.Catalog} {
    id
    title
    parentCatalogId
  }
`;

const CATALOG_DETAIL_FRAGMENT = gql`
fragment CatalogDetailFragment on ${Catalogs.CatalogDetail} {
  id
  title
  catalogId
}`;

const SKU_RATE_FRAGMENT = gql`
  fragment SkuRateFragment on SKUBatchRate {
    priceDetails {
      rlp
      vatPercentage
      mrp
      srp
      mrpStatus
      b2bRate
    }
    updatedAt
    active
  }
`;

const SKU_BATCH_PRICE_FRAGMENT = gql`
  fragment SKUBatchPriceFragment on SKUPrice {
    rlp
    dlp
    rlpWithVAT
    dlpWithVAT
    vatPercentage
  }
`;

const SKU_BATCH_FRAGMENT = gql`
  fragment SKUBatchFragment on SKUBatch {
    id
    skuId
    distributorId
    batchName
    manufactureDate
    expiryDate
    quantity
    remainingQuantity
    shortages
    damages
    dlp
    dlpWithVat
  }
`;

const SKU_CATALOG_DETAIL_FRAGMENT = gql`
  fragment SkuCatalogDetailFragment on SKUCatalogDetail {
    Catalog {
      ...CatalogFragment
    }
    CatalogDetail {
      ...CatalogDetailFragment
    }
  }
  ${CATALOG_FRAGMENT}
  ${CATALOG_DETAIL_FRAGMENT}
`;
const SKU_FRAGMENT = gql`
  fragment SkuFragment on SKU {
    id
    title
    active
    discontinue_sku
    Rates {
      ...SkuRateFragment
    }
    uomDimension {
      length
      width
      height
    }
    advancedOptions {
      addToSubDAssortment
      addToChannelAssortment
      addToPromotions
      addToSbd
      addToFocusedSku
      addToLineSegmentation
    }
    origin
    reorderMOQ
  }
  ${SKU_RATE_FRAGMENT}
`;

const GET_SKU_LIST = gql`
  query getSku($offset: Int, $limit: Int, $filter: FilterInput) {
    skus(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...SkuFragment
      }
      count
    }
  }
  ${SKU_FRAGMENT}
`;

const query = {
  getSkuList: GET_SKU_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
  downloadReport: DOWNLOAD_REPORT,
  upload: UPLOAD,
};

export {
  SKU_BATCH_FRAGMENT,
  SKU_BATCH_PRICE_FRAGMENT,
  GET_SKU_LIST,
  SKU_FRAGMENT,
  CATALOG_FRAGMENT,
  SKU_RATE_FRAGMENT,
  CATALOG_DETAIL_FRAGMENT,
  SKU_CATALOG_DETAIL_FRAGMENT,
  DOWNLOAD_REPORT,
};

export { query, mutation };
