import PropTypes from 'prop-types';
import React from 'react';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import {
  Collapsible, Input, Search
} from '../../../../components';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { getAllIds } from '../../../common/HelperFunctions';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import RouteSegmentationStyled from './RouteSegmentationStyled';
import { addEdit } from '../../../../utils/utilities';
import { createTitle } from './config';
import {
  EMPTY, INVALID, UNIQUE
} from '../../../../data/enums/ErrorMessage';
import {
  Switch ,BreadCrumb,Button
} from '../../../../v4/components';

const propTypes = {
  brands: PropTypes.instanceOf(Array),
  breadCrumbConfig: PropTypes.instanceOf(Array),
  crudMode: PropTypes.string,
  handleCancelClick: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  handleSwitchToggle: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    create: PropTypes.bool,
    delete: PropTypes.bool,
    read: PropTypes.bool,
    update: PropTypes.bool,
  }),
  refsObj: PropTypes.instanceOf(Object),
  selectedSkus: PropTypes.instanceOf(Array),
  routeDetailTitle: PropTypes.string,
  id: PropTypes.string,
  onSearchInputChange: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  enableErrorDisplay: PropTypes.bool,
  titleAlreadyExist: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};
const defaultProps = {
  brands: [],
  breadCrumbConfig: [],
  crudMode: EVENT_OPERATION.CREATE,
  refsObj: {},
  routeDetailTitle: '',
  id: '',
  selectedSkus: [],
  permission: PERMISSION_OBJ,
  searchText: '',
  enableErrorDisplay: false,
  titleAlreadyExist: false,
};

const View = ({
  breadCrumbConfig,
  crudMode,
  permission,
  id,
  routeDetailTitle,
  refsObj,
  brands,
  onInputChange,
  handleCancelClick,
  handleSaveClick,
  handleEditClick,
  handleSwitchToggle,
  selectedSkus,
  enableErrorDisplay,
  loading,
  searchText,
  onSearchInputChange,
  titleAlreadyExist,
}) => {
  const getBrandSwitchStatus = (skus) => skus.every((sku) => selectedSkus.includes(sku.id));
  const updateMode = crudMode === EVENT_OPERATION.UPDATE;

  return (
    <RouteSegmentationStyled>
      <div className='section-header'>
        <PanelStyled>
          <BreadCrumb list={breadCrumbConfig} />
          <PanelHeader>
            <h2>{addEdit(updateMode, id ? routeDetailTitle : createTitle)}</h2>
            <div className='flex m-0'>
              {crudMode !== EVENT_OPERATION.READ && (
                <div>
                  <Button secondary disabled={loading} small title='Cancel' onClick={() => handleCancelClick()} />
                  <Button primary small disabled={loading} title='Save' onClick={() => handleSaveClick()} />
                </div>
              )}
              {permission.update && crudMode === EVENT_OPERATION.READ && (
                <div className='text-right'>
                  <Button
                    secondary
                    iconBtnSmall
                    iconName='pencil'
                    onClick={() => handleEditClick(EVENT_OPERATION.UPDATE)}
                  />
                </div>
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content'>
        <div className='create-ui'>
          <div className='title-section'>
            <Input
              name='title'
              type='text'
              enableValidation
              value={routeDetailTitle}
              labelContent='Title'
              placeholder='Title'
              ref={(ref) => (refsObj.title = ref)}
              externalValidation={!titleAlreadyExist}
              enableErrorDisplay={enableErrorDisplay || titleAlreadyExist}
              errorMessage={routeDetailTitle ? (titleAlreadyExist ? UNIQUE : INVALID) : EMPTY}
              onChange={(event) => onInputChange(event)}
              disabled={crudMode === EVENT_OPERATION.READ}
            />
          </div>
          <div className='panel-section'>
            <div className='panel three'>
              <div className='panel-title'>
                <span>Enable brands for this route segmentation</span>
                <Search clearText={!searchText} searchText={searchText} handleSearchInput={onSearchInputChange} />
              </div>
              <div className='div-thead'>
                <span>SKU</span>
                <div className='status'>
                  <span>Status</span>
                </div>
              </div>
              <div className={`div-tbody ${crudMode === EVENT_OPERATION.READ ? 'disabled' : ''}`}>
                {brands.map((brand) => (
                  <Collapsible
                    trigger={
                      <>
                        <span>
                          <span className='text'>{brand.title}</span>
                        </span>
                        <div className='status' onClick={(e) => e.stopPropagation()}>
                          <Switch
                            checked={brand.skus.length > 0 && getBrandSwitchStatus(brand.skus)}
                            onToggle={(e) => handleSwitchToggle(e, getAllIds(brand.skus || []) || [])}
                          />
                        </div>
                      </>
                    }
                  >
                    {brand.skus.map((sku) => (
                      <div className='panel-title'>
                        <div className='collapsible-inner' key={sku.id} onClick={(e) => e.stopPropagation()}>
                          <span>{sku.title}</span>
                          <div className='status'>
                            <Switch
                              checked={selectedSkus.includes(sku.id)}
                              onToggle={(e) => handleSwitchToggle(e, [sku.id])}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Collapsible>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </RouteSegmentationStyled>
  );
};

View.propTypes = propTypes;
View.defaultProps = defaultProps;

export default withErrorAndLoadingScreen(View);
