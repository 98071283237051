import React from 'react';
import PropTypes from 'prop-types';
import { menuAction, tableData } from '../../../../v4/components';
import { normalPresentor } from '../../../../utils/date';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  date: 'date',
  outlet: 'outlet',
  collector: 'collector',
  route: 'route',
  amount: 'amount',
  alias: 'alias',
  remarks: 'remarks',
  distributor: 'distributor',
  dse: 'dse',
  userRole: 'userRole',
  actionHolder: 'actionHolder',
};

const labelConfig = () => [
  {
    id: 1,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.collector,
    title: 'Collector',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.alias,
    title: 'Alias',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.remarks,
    title: 'Remarks',
    show: false,
  },
  {
    id: 8,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
  },
  {
    id: 9,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: false,
  },
  {
    id: 10,
    label: labelMappings.userRole,
    title: 'User Role',
    show: false,
  },
];

const menuConfigList = [
  {
    title: 'UPDATE',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'RECONCILE',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.RECONCILED,
  },
  {
    title: 'REMOVE',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.DISCARDED,
  },
];

const TableHeader = ({ tableConfig }) => (
  <thead className=''>
    <tr>{tableConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data, handleIconClick, section, permission) => {
  const table = {};
  table[labelMappings.date] = <td key={`cash-${data.id}-date`}>{normalPresentor(data.date)}</td>;
  table[labelMappings.outlet] = <td key={`cash-${data.id}-outlet`}>{data.outlet.title}</td>;
  table[labelMappings.collector] = <td key={`cash-${data.id}-collector`}>{data.collector.fullName}</td>;
  table[labelMappings.route] = <td key={`cash-${data.id}-route`}>{data.outlet.route && data.outlet.route.title}</td>;
  table[labelMappings.amount] = <td key={`cash-${data.id}-amount`}>{fixedFloatAndCommas(data.amount)}</td>;
  table[labelMappings.alias] = (
    <td key={`cash-${data.id}-alias`}>
      {section === 'pending' ? (
        <div className={`alias-td ${permission && permission.create ? 'alias' : 'disabled'}`}>
          {data.paymentDetails.length !== 0 ? (
            <div className='count' onClick={() => handleIconClick(EVENT_OPERATION.READ, data)}>
              {data.paymentDetails.length}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        <div className='alias-td'>
          {data.paymentDetails.length !== 0 && (
            <div className='count' onClick={() => handleIconClick(EVENT_OPERATION.READ, data)}>
              {data.paymentDetails.length}
            </div>
          )}
        </div>
      )}
    </td>
  );
  table[labelMappings.remarks] = (
    <td key={`cash-${data.id}-remarks`}>{data.billingRemarks ? data.billingRemarks : data.remarks}</td>
  );
  table[labelMappings.distributor] = <td key={`cash-${data.id}-distributor`}>{data.distributor.title}</td>;
  table[labelMappings.dse] = (
    <td key={`cash-${data.id}-dse`}>
      {data.outlet.route && data.outlet.route.user && data.outlet.route.user.fullName}
    </td>
  );
  table[labelMappings.userRole] = <td key={`cash-${data.id}-userRole`}>{data.collector.role.name}</td>;
  table[labelMappings.actionHolder] = (
    <td key={`${data.id}-action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {permission.update && menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, handleIconClick, data, permission)}
    </td>
  );
  return table;
};

const TableBody = ({ data, handleIconClick, tableConfig, section, permission, index }) => {
  const tableBody = returnTableBody(data, handleIconClick, section, permission);

  return <tr key={`cash-${index}`}>{tableConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableHeader.propTypes = {
  tableConfig: PropTypes.arrayOf(Object).isRequired,
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  permission: PropTypes.instanceOf(Object),
  handleIconClick: PropTypes.func,
  section: PropTypes.string,
  index: PropTypes.number,
};

TableBody.defaultProps = {
  permission: PERMISSION_OBJ,
  handleIconClick: () => null,
  section: '',
  index: null,
};

export {
  TableHeader, TableBody, labelConfig
};
