import { breadCrumbConfig } from '../config';
import { CAMPAIGNS } from '../../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';

const title = 'Campaign';
const maxDigitMessage = 'Please enter value up to 10 digit.';
const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Campaign',
    path: `/${CAMPAIGNS}`,
    active: true,
  },
];
const formMapper = (element) => ({
  id: element.id || '',
  title: element.title || '',
  budget: element.budget,
  fiscalYearId: element.fiscalYearId,
  startDate: element.startDate,
  endDate: element.endDate,
  SubCampaign: element.SubCampaign ? element.SubCampaign : [],
});
const dimensionListConfig = [
  {
    value: 'AMOUNT',
    label: 'in Rs',
  },
  {
    value: 'PERCENT',
    label: 'in %',
  },
  {
    value: 'VALUE',
    label: 'in Value',
  },
];

const budgetCalculatedValueTypeFunc = (item) => {
  if (item.subCampaignBudget) {
    const bcvtReturn = item.subCampaignBudget.map((d) => {
      const { calculatedValue, ...modifiedList } = d;
      modifiedList.value = Number(modifiedList.value);
      return modifiedList;
    });
    return bcvtReturn;
  }
};
const formattedInUpdate = (list, tempList = []) => {
  const formattedList = list.map((item, index) => {
    const budgetCalculatedValueType = budgetCalculatedValueTypeFunc(item);
    if (item.id > 10000) {
      const { id, ...modifiedItem } = item;
      return {
        ...modifiedItem,
        subCampaignBudget: budgetCalculatedValueType,
      };
    }
    return {
      ...item,
      subCampaignBudget: budgetCalculatedValueType,
    };
  });
  return formattedList;
};

const getPayload = (data, type = EVENT_OPERATION.CREATE) => ({
  id: data.id || '',
  title: data.title || '',
  budget: data.budget || '',
  fiscalYearId: data.fiscalYearId,
  startDate: data.startDate,
  endDate: data.endDate,
  subCampaign: formattedInUpdate(data.SubCampaign, data.temp),
});

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => {
      const formattedData = getPayload(data, EVENT_OPERATION.CREATE);
      const { id, ...structuredData } = formattedData;
      return { input: structuredData };
    },
    responseName: 'createCampaign',
    message: `Campaign ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      input: getPayload(data, EVENT_OPERATION.UPDATE),
    }),
    responseName: 'createSubCampaign',
    message: `Campaign ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};
export {
  crudSuccess, breadCrumb as breadCrumbConfig, title, formMapper, maxDigitMessage, dimensionListConfig
};
