import React from 'react';
import PropTypes from 'prop-types';
import WarehouseReportChildView from './childView';
import { TableBody } from '../tableConfig';
import { AccordionTable } from '../../../../components/CollapsibleTable';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func.isRequired,
  ledgerDetail: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: [],
  ledgerDetail: [],
};

const WarehouseReportView = ({ ...props }) => {
  const { data, onRowClick, ledgerDetail, closeAccordion } = props;
  return (
    <tbody>
      {
        <AccordionTable closeAccordion={closeAccordion}>
          {data?.length > 0 &&
            data?.map?.(warehouse => (
              <tr
                key={warehouse?.bin_location_id}
                trigger={TableBody({
                  data: warehouse,
                  ledgerDetail,
                  onRowClick,
                })}
                classes={ledgerDetail?.length > 0 ? 'collaspse' : 'no-collaspse'}
              >
                {ledgerDetail?.length > 0 && WarehouseReportChildView({ data: ledgerDetail || [] })}
              </tr>
            ))}
        </AccordionTable>
      }
    </tbody>
  );
};

WarehouseReportView.propTypes = propTypes;

WarehouseReportView.defaultProps = defaultProps;

export default WarehouseReportView;
