import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';
import { Badge, tableData } from '../../../../../../v4/components';
import { normalPresentor } from '../../../../../../utils/date';
import { ImageColumn } from '../../../../../../utils/tableUtils';
import { getUpdatedLabel, headerLabelConfig } from '../../../../../common/HelperFunctions';
import { USER_ROLE_TITLE } from '../../../../../../data/enums/UserRole';

const labelMappings = {
  date: 'date',
  callStatus: 'callStatus',
  orderValue: 'orderValue',
  images: 'images',
  reason: 'reason',
  dse: 'dse',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.callStatus,
    title: 'Call Status',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.orderValue,
    title: 'Order Value',
    className: 'text-right',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.images,
    title: 'Images',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.reason,
    title: 'Reason',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: true,
    isDynamic: true,
  },
];

const TableHeader = (dynamicHeader = false) => (
  <thead className=''>
    <tr>
      {(dynamicHeader ? getUpdatedLabel(labelConfig) : labelConfig).map((label) =>
        label.show ? tableData(label) : null,
      )}
    </tr>
  </thead>
);

const returnTableBody = (data, handleSliderOpen) => {
  const table = {};

  table[labelMappings.date] = <td key={`${data.id}-id`}>{normalPresentor(data.callDate)}</td>;
  table[labelMappings.callStatus] = (
    <td key={`${data.id}-callStatus`}>
      <span className={data.type.toLowerCase()}>
        <span className='badge_border'>
          <Badge light>{data.type}</Badge>
        </span>
      </span>
    </td>
  );
  table[labelMappings.orderValue] = (
    <td key={`${data.id}-orderValue`} className='text-right'>
      {fixedFloatAndCommas(data.callOrderValue.netAmount)}
    </td>
  );
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`callhistory-image-${data.id}`}
      imageList={data.images || []}
      onImageClick={handleSliderOpen}
      key={`callhistory-image-${data.id}`}
    />
  );
  table[labelMappings.reason] = <td key={`${data.id}-reason`}>{data.notes}</td>;
  table[labelMappings.dse] = <td key={`${data.id}-dse`}>{data.User ? data.User.fullName : ''}</td>;

  return table;
};

const TableBody = ({ data, handleSliderOpen }) => {
  const tableBody = returnTableBody(data, handleSliderOpen);

  return (
    <tr key={`callHistory-${data.id}`}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  handleSliderOpen: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

export { TableHeader, TableBody };
