import classNames from 'classnames';
import { ChangeEvent } from 'react';
import CheckboxContainer from './CheckBoxStyled';

interface CheckBoxProps {
  children?: any;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  checked?: boolean;
  labelText?: any;
  onCheckBoxClick: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox = ({
  checked,
  className,
  active,
  disabled,
  multiple,
  labelText,
  onCheckBoxClick,
  ...rest
}: CheckBoxProps) => {
  const classes = classNames(
    {
      active,
      disabled,
      multiple,
    },
    className,
  );
  return (
    <CheckboxContainer className={classes} {...rest}>
      <label>
        <input readOnly type="checkbox" checked={checked} onChange={e => onCheckBoxClick(e)} disabled={disabled} />
        <span className="control-label">
          {labelText !== undefined ? <span className="custom-control-label-text">{labelText}</span> : ''}
        </span>
      </label>
    </CheckboxContainer>
  );
};

export default CheckBox;
