import styled from 'styled-components';

const PresentationStyle = styled.div`
  .img-title {
    display: flex;
    align-items: center;
    padding: 12px;
    img {
      padding-right: 10px;
    }
  }
`;

export default PresentationStyle;
