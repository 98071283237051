import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { Form, Input } from '../../../../../components/index';
import withLoading from '../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import SingleDatePicker from '../../../../../components/DatePicker/SingleDatePicker';
import { getTodaysDate } from '../../../../../utils/date';
import Image from '../grnImage';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  getImages: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
  distributorExist: PropTypes.bool,
  updateDistributorServices: PropTypes.func,
  imageValidation: PropTypes.bool,
};

const defaultProps = {
  data: {},
  distributorExist: false,
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  imageValidation: false,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  vendorList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  getImages: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
  updateDistributorServices: () => null,
};

const DetailForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    vendorList,
    getImages,
    distributorList,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    distributorExist,
    updateDistributorServices,
    imageValidation,
    onImageClick,
    pendingStatus,
    isManual,
    lndEnabled,
  } = props;
  return (
    <Form className="grn-form">
      <Row>
        <Col className="mb-48" md={3}>
          <Input disabled name="id" type="number" value={data.id} labelContent="GRN ID" placeholder="GRN ID" />
        </Col>
        <Col className="m-0" md={3}>
          <div className="single-date-picker">
            <label>GRN Date</label>
            <SingleDatePicker
              name="grnDate"
              date={data.grnDate}
              maxDate={getTodaysDate()}
              onChange={(name, date) =>
                handleInputChange({
                  target: {
                    name: 'grnDate',
                  },
                  formattedValue: date,
                })
              }
            />
          </div>
        </Col>
        <Col className="mb-24" md={6}>
          <CustomSelect
            enableValidation
            options={vendorList}
            labelContent="Vendors"
            className="custom-select"
            placeholder="Select Vendors"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ name }) => name}
            ref={ref => (refsObj.vendorId = ref)}
            enableErrorDisplay={enableErrorDisplay}
            disabled={update.type === EVENT_OPERATION.APPROVE}
            onChange={event => handleDropDownChange(event.id, ['Vendor', 'id'])}
            value={vendorList.filter(({ id }) => id === data.Vendor.id)}
          />
        </Col>
        {!distributorExist && (
          <>
            <Col md={6}>
              <CustomSelect
                enableValidation
                labelContent="Distributor"
                options={distributorList}
                className="custom-select"
                placeholder="Select Distributors"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                enableErrorDisplay={enableErrorDisplay}
                ref={ref => (refsObj.distributorId = ref)}
                disabled={update.type === EVENT_OPERATION.APPROVE}
                onChange={event =>
                  handleDropDownChange(event.id, ['Distributor', 'id'], updateDistributorServices(event))
                }
                value={distributorList.filter(({ id }) => id === data.Distributor.id)}
              />
            </Col>
            <Col className="mb-24" md={6} />
          </>
        )}
        <Col md={3} className="mb-24">
          <Input
            type="text"
            enableValidation
            name="invoiceNumber"
            value={data.invoiceNumber}
            placeholder="Invoice Number"
            labelContent="Invoice Number"
            ref={ref => (refsObj.invoiceNumber = ref)}
            enableErrorDisplay={enableErrorDisplay}
            onChange={event => handleInputChange(event)}
            disabled={update.type === EVENT_OPERATION.APPROVE}
          />
        </Col>
        {pendingStatus && (
          <Col md={3} className="mb-24">
            <Input
              type="number"
              name="ricNumber"
              value={data.ricNumber}
              placeholder="RIC Number"
              labelContent="RIC Number"
              enableValidation={pendingStatus && !isManual}
              ref={pendingStatus && !isManual ? ref => (refsObj.ricNumber = ref) : null}
              enableErrorDisplay={pendingStatus && !isManual ? enableErrorDisplay : false}
              onChange={event => handleInputChange(event)}
              disabled={lndEnabled}
            />
          </Col>
        )}
        <Col md={3} className="mb-24">
          <div className="single-date-picker m-0">
            <label>Invoice Date</label>
            <SingleDatePicker
              name="invoiceDate"
              date={data.invoiceDate}
              onChange={(name, date) =>
                handleInputChange({
                  target: {
                    name: 'invoiceDate',
                  },
                  formattedValue: date,
                })
              }
              disabled={update.type === EVENT_OPERATION.APPROVE}
            />
          </div>
        </Col>
        {pendingStatus ? <Col className="mb-24" md={3} /> : <Col className="mb-24" md={6} />}
        <Col md={3} className="mb-24">
          <Input
            type="text"
            name="transporterName"
            value={data.transporterName}
            placeholder="Transporter Name"
            labelContent="Transporter Name"
            onChange={event => handleInputChange(event)}
            disabled={update.type === EVENT_OPERATION.APPROVE}
          />
        </Col>
        <Col md={3} className="mb-24">
          <Input
            type="text"
            name="vehicleNumber"
            value={data.vehicleNumber}
            placeholder="Vehicle Number"
            labelContent="Vehicle Number"
            onChange={event => handleInputChange(event)}
            disabled={update.type === EVENT_OPERATION.APPROVE}
          />
        </Col>
        <Col md={3} className="mb-24">
          <Input
            type="text"
            name="receiptNumber"
            value={data.receiptNumber}
            placeholder="Receipt Number"
            labelContent="Shipment Receipt Number"
            onChange={event => handleInputChange(event)}
            disabled={update.type === EVENT_OPERATION.APPROVE}
          />
        </Col>
        <Col md={3} className="mb-24" />
        <Col className="mb-24" md={6}>
          <Input
            type="text"
            name="remarks"
            value={data.remarks}
            placeholder="Add Remarks"
            labelContent="Remarks"
            onChange={event => handleInputChange(event)}
            enableValidation
            ref={ref => (refsObj.remarks = ref)}
            enableErrorDisplay={enableErrorDisplay}
          />
        </Col>
        <Col md={6} className="mb-24 grn__create--image">
          <Image
            crudMode={EVENT_OPERATION.CREATE}
            images={data.images}
            getImages={childMethod => getImages(childMethod)}
            disabled={update.type === EVENT_OPERATION.APPROVE}
            enableValidation={imageValidation}
            ref={imageValidation ? ref => (refsObj.receiptImage = ref) : null}
            onImageClick={onImageClick}
            enableErrorDisplay={imageValidation ? enableErrorDisplay : false}
          />
        </Col>
      </Row>
    </Form>
  );
};

DetailForm.propTypes = propTypes;

DetailForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(DetailForm);

export default LoginViewWithErrorAndLoading;
