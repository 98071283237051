import { compose } from 'recompose';
import SBD from './SBD';
import { query } from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const composedSbd = compose(
  withRequestTracker({
    query,
  }),
)(SBD);

export default composedSbd;
