const sampleData = [
  {
    id: 2,
    target: 0,
    buTargetId: 2,
    title: 'DECEMBER 2019',
    monthName: 'xxxxxxxx',
    year: 'xxxx',
    isChildPresent: false,
    childUsers: [],
  },
];

export default sampleData;
