import React from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { Pagination } from '../../../v4/components';
import SKUStyled from './SKUStyled';
import { TableBody, TableHeader } from './tableConfig';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  checkedList: PropTypes.arrayOf(Array),
  breadCrumb: PropTypes.arrayOf(Array),
  onButtonClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const { data, onButtonClick, onPageChange, onIconClick, pagination, permission } = props;
  return (
    <SKUStyled>
      <div className='table-wrap sku'>
        <table>
          {TableHeader()}
          <tbody>
            {data.list.map((user) =>
              TableBody({
                data: user,
                onButtonClick,
                permission,
                onIconClick,
              }),
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </SKUStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
