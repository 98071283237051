import React from 'react';
import { Button } from '../../../../../v4/components';

const PurchaseOrderCreateSection = ({skuAmount, onCreateClick, loading}) => {
  return (
    skuAmount> 0 && (
      <div className='purchase-order-create-section'>
        <p>
          {skuAmount} SKU{skuAmount > 1 ? 's' : ''}
        </p>
        <Button title = {'Create PO'} primary onClick={onCreateClick} disabled={loading}/>
      </div>
    )
  );
};

export default PurchaseOrderCreateSection;
