import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { configuration as configurationRoute } from '../../../data/enums/Route';

const breadCrumb = [configurationRoute];

const Title = 'Catalog';

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
    title: element.title || '',
    catalogId: (element.Catalog ? element.Catalog.id : '') || '',
  }),
  validationField: ['title'],
  validationRequired: true,
};

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({ title: data.title, territoryId: Number(data.territoryId) }),
    responseName: 'createCatalog',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
      territoryId: Number(data.territoryId),
    }),
    responseName: 'updateCatalog',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'deleteCatalog',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateCatalog',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export {
  crudRequest, breadCrumb as breadCrumbConfig, formConfig
};
