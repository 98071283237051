import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import Form from './Form';
import { getUser } from '../../../data/services';
import PageHeader from '../../base/PageHeader';
import { DialogFormWrapper } from '../../common';
import LocationHierarchyDetailsStyled from './LocationHierarchyDetailsStyled';
import * as queryService from '../../base/query.service';
import { refGenerator } from '../../../utils/refGenerator';
import { PanelStyled } from '../../common/configuration';
import withAlert from '../../../utils/composition/withAlert';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../utils/api';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { breadCrumbConfig, formConfig, title } from './config';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { getPermissionForLocationHierarchy } from '../../base/permission';
import { isError } from '../../common/HelperFunctions';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  toggleState: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getLocationHierarchy: PropTypes.func.isRequired,
  getLocationHierarchyDetails: PropTypes.func.isRequired,
  editLocationHierarchyDetail: PropTypes.func.isRequired,
  createLocationHierarchyDetail: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class LocationHierarchyDetails extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.userInfo = getUser();
    this.state = {
      baseId: {},
      selectedId: null,
      locationHierarchyDetailList: [],
      levels: {
        list: [],
        count: '',
      },
      dialog: {
        type: '',
        element: '',
        cardTitle: '',
      },
      createFormLocation: {
        id: null,
        title: '',
        parent: { id: null, title: '' },
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
      lastSearchData: {},
    };

    this.formReference = refGenerator(['title']);
    this.permission = getPermissionForLocationHierarchy();
  }

  componentDidMount() {
    this.getLocationHierarchyList();
  }
  
  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getLocationHierarchyList = () => {
    const { levels, queryParameters } = this.state;
    const { getLocationHierarchy, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getLocationHierarchy(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [...apiFilterProcessor(queryParameters.filter)],
          sort: [{ sortBy: 'id', order: 'ASC' }],
        },
      },
      {
        handleSuccess: response => {
          levels.list = response.data.getLocationHierarchy?.rows || [];
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(levels);
          this.loadTableData(levels.list[0] ? levels.list[0].id : null, 0);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  loadTableData = (locationHierarchyId, cardId, queryString) => {
    const { queryParameters } = this.state;
    const { getLocationHierarchyDetails, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getLocationHierarchyDetails(
      {
        locationHierarchyId,
        parentId: cardId,
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          queryString,
          filters: [...apiFilterProcessor(queryParameters.filter)],
        },
      },
      {
        handleSuccess: response => {
          const locationWithLocationHierarchyId = {
            locationHierarchyId,
            list: response.data?.getLocationHierarchyDetails?.rows || [],
            count: response.count,
          };

          const { locationHierarchyDetailList } = this.state;
          const baseIndex = locationHierarchyDetailList?.findIndex(el => el.locationHierarchyId === locationHierarchyId);
          if (baseIndex < 0) {
            locationHierarchyDetailList.push(locationWithLocationHierarchyId);
          } else {
            locationHierarchyDetailList[baseIndex] = { ...locationWithLocationHierarchyId };
            locationHierarchyDetailList.splice(baseIndex + 1);
          }
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ locationHierarchyDetailList, selectedId: locationHierarchyId }, () => {});
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
        cardTitle: '',
      },
    });
  };

  handleLocationDetailsCardClick = (list) => {
    const { createFormLocation, levels, baseId } = this.state;
    const obj = Object.fromEntries([[list.LocationHierarchy.id, list.id]]);
    this.setState({
      createFormLocation: {
        ...createFormLocation,
        parent: {
          id: list.id,
          title: list.title,
        },
      },
      baseId: { ...baseId, ...obj },
    });
    const index = levels.list.findIndex(level => level.id === list.LocationHierarchy.id);
    const levelList = levels.list[index + 1];
    this.loadTableData(levelList ? levelList.id : null, list.id);
  };

  handleIconClick = (type, element, cardTitle, list) => {
    const { selectedId, createFormLocation } = this.state;
    const locationHierarchyId = list.length > 0 ? list[0].LocationHierarchy.id : selectedId;
    const parentId = list.length > 0 ? list[0].parent : createFormLocation.parent;
    this.setState({
      selectedId: locationHierarchyId,
      dialog: {
        type,
        element,
        cardTitle,
      },
      createFormLocation: {
        id: element.id,
        title: element.title,
        parent: parentId,
      },
    });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { createFormLocation } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return (acc[value] = event.formattedValue);
          }
          return acc[value];
        }, createFormLocation);
      } else {
        createFormLocation[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      createFormLocation[event.target.name] = event.formattedValue;
    }
    this.setState({ createFormLocation });
  };

  handleSubmit = () => {
    const { createLocationHierarchyDetail, displayAlert, editLocationHierarchyDetail, toggleState } = this.props;

    const { dialog, createFormLocation, selectedId } = this.state;

    const parentId = createFormLocation.parent ? createFormLocation.parent.id : null;
    const { element, cardTitle, type } = dialog;

    if (type === 'CREATE') {
      createLocationHierarchyDetail(
        {
          input: {
            title: createFormLocation.title,
            parentId,
            locationHierarchyId: selectedId,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.CREATE}`);
            this.loadTableData(selectedId, parentId);
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
    if (type === 'UPDATE') {
      editLocationHierarchyDetail(
        {
          id: element.id,
          input: {
            title: createFormLocation.title,
            parentId,
            locationHierarchyId: selectedId,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            this.loadTableData(selectedId, parentId);
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
    if (type === 'DELETE') {
      toggleState(
        {
          ids: [element.id],
          type: STATEFUL_ENTITIES.LOCATION,
          active: false,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${cardTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`);
            this.loadTableData(selectedId, parentId);
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
  };

  handleSearchInput = (text, cardTitle, data) => {
    const searchData =
      data.length > 0 ? { locationHierarchyId: data[0].locationHierarchyId, parentId: data[0].parentId } : null;
    if (searchData) {
      this.loadTableData(searchData.locationHierarchyId, searchData.parentId, text);
    } else {
      const { lastSearchData } = this.state;
      this.loadTableData(lastSearchData.locationHierarchyId, lastSearchData.parentId, text);
    }
    this.setState({ lastSearchData: searchData });
  };

  render() {
    const { serverResponseWaiting } = this.props;
    const { dialog, levels, locationHierarchyDetailList, createFormLocation, baseId } = this.state;
    const { type, cardTitle, element } = dialog;
    return (
      <LocationHierarchyDetailsStyled>
        <div>
          {type && (
            <DialogFormWrapper
              formTitle={cardTitle}
              type={type}
              dialogElement={element}
              formConfig={formConfig[type]}
              onDialogCancel={this.resetDialog}
              onDialogSubmit={this.handleSubmit}
              refsObj={this.formReference}
              renderDialog={({ enableErrorDisplay, refsObj }) => (
                <Fragment>
                  {type !== EVENT_OPERATION.DELETE ? (
                    <Form
                      show
                      type={type}
                      refsObj={refsObj}
                      data={createFormLocation}
                      enableErrorDisplay={enableErrorDisplay}
                      handleInputChange={this.handleInputChange}
                      title={cardTitle}
                      levels={levels.list}
                    />
                  ) : (
                    <div>Are you sure you want to delete this record?</div>
                  )}
                </Fragment>
              )}
            />
          )}

          <Fragment>
            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title,
                    create: false,
                    download: false,
                    filter: false,
                    search: false,
                  }}
                />
              </PanelStyled>
            </div>
          </Fragment>
        </div>
        <div className="section-content">
          <View
            locationHierarchyList={levels.list}
            handleIconClick={this.handleIconClick}
            locationHierarchyDetailList={locationHierarchyDetailList}
            loading={serverResponseWaiting}
            levels={levels.list}
            onLocationDetailsCardClick={this.handleLocationDetailsCardClick}
            handleSearchInput={this.handleSearchInput}
            baseId={baseId}
            permission={this.permission}
          />
        </div>
      </LocationHierarchyDetailsStyled>
    );
  }
}

LocationHierarchyDetails.propTypes = propTypes;

LocationHierarchyDetails.defaultProps = defaultProps;

export default withAlert()(LocationHierarchyDetails);
