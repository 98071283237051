import { compose } from 'recompose';
import TargetDistributionDetail from './Detail';
import {
  query, mutation, CREATE_TARGET_DISTRIBUTION,UPDATE_TARGET_DISTRIBUTION, CALCULATE_AUTOMATED_SALES_TARGET, DOWNLOAD_REPORT
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedTargetDistributionDetail = compose(
  CREATE_TARGET_DISTRIBUTION,
  UPDATE_TARGET_DISTRIBUTION,
  CALCULATE_AUTOMATED_SALES_TARGET,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(TargetDistributionDetail);

export default ComposedTargetDistributionDetail;
