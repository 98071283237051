import React from 'react';
import PropTypes from 'prop-types';
import {
  Badge, menuAction, tableData, CheckBox
} from '../../../v4/components';
import { menuConfigList } from '../../../data/enums/Support';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  outletName: 'outletName',
  route: 'route',
  averageSales: 'averageSales',
  currentCategory: 'currentCategory',
  proposedCategory: 'proposedCategory',
  status: 'status',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.outletName,
    title: 'Outlet Name',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.averageSales,
    title: 'P3M Sales',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.currentCategory,
    title: 'Current Category',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.proposedCategory,
    title: 'Proposed Category',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission, withOutIcon) => {
  const table = {};
  table[labelMappings.checkbox] = permission.update && (
    <td onClick={(e) => e.stopPropagation()} key={`${data.id}- check`}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
        disabled={data.status !== 'PENDING'}
      />
    </td>
  );
  table[labelMappings.outletName] = <td key={`${data.id}- id`}>{data.categoryChangeData.RetailOutlet.title}</td>;
  table[labelMappings.route] = (
    <td key={`${data.id}- route`}>
      {data.categoryChangeData.RetailOutlet.Route && data.categoryChangeData.RetailOutlet.Route.title}
    </td>
  );
  table[labelMappings.averageSales] = (
    <td key={`${data.id}- averageSales`}>{fixedFloatAndCommas(data.categoryChangeData.RetailOutlet.averageSales)}</td>
  );
  table[labelMappings.currentCategory] = (
    <td key={`${data.id}- currentCategory`}>
      {data.categoryChangeData.CurrentCategory ? data.categoryChangeData.CurrentCategory.title : ''}
    </td>
  );
  table[labelMappings.proposedCategory] = (
    <td key={`${data.id}- proposedCategory`}>
      {data.categoryChangeData.ProposedCategory ? data.categoryChangeData.ProposedCategory.title : ''}
    </td>
  );
  table[labelMappings.status] = (
    <td key={`${data.id}- status`}>
      <span className={data.status.toLowerCase()}>
        <span className='badge_border'>
          <Badge light>{data.status.replace('_', ' ')}</Badge>
        </span>
      </span>
    </td>
  );
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.id}- action`}
      className={
        checkedList.includes(data.id) ? 'disabled text-right simple-popup-actions' : 'text-right simple-popup-actions'
      }
      onClick={(e) => e.stopPropagation()}
    >
      {data.status === 'PENDING' && permission.update &&
        menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data.id, permission, withOutIcon)}
    </td>
  );

  return table;
};

const TableHeader = ({ checkedListLength, primaryCheckboxHandler, permission, dataList }) => {
  const checkPending = dataList.filter((item) => item.status === 'PENDING');
  const checkPendingLength = checkPending.length;
  return (
    <thead>
      <tr>
        {labelConfig.map((label) =>
          label.show
            ? label.label === labelMappings.checkbox
              ? permission &&
              permission.update && (
                <th key={`cheque-${label.id}-checkboxheader`}>
                  <CheckBox
                    checked={checkPendingLength !== 0 && checkPendingLength === checkedListLength}
                    onCheckBoxClick={(e) => primaryCheckboxHandler(e)}
                  />
                </th>
              )
              : tableData(label)
            : null,
        )}
      </tr>
    </thead>
  );
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission, withOutIcon }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission, withOutIcon);

  return <tr key={data.id}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataList: PropTypes.instanceOf(Array),
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataList: [],
  permission: PERMISSION_OBJ,
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  permission: PropTypes.instanceOf(Object),
  checkedList: PropTypes.instanceOf(Array),
  withOutIcon: PropTypes.bool.isRequired,
  onIconClick: PropTypes.func.isRequired,
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
