import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getImageObj } from './config';
import { Button } from '../../../../../v4/components';
import ImageUpload from '../../../../components/ImageUpload';
import { Thumbnail } from '../../../../../components/ImageViewer';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { IMAGE_UPLOAD_DOMAIN } from '../../../../../data/enums/GraphQL';

const propTypes = {
  images: PropTypes.arrayOf(Object).isRequired,
  getImages: PropTypes.func.isRequired,
  crudMode: PropTypes.string.isRequired,
};
export class Image extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      serverImages: props.images,
      images: props.images || [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { serverImages, images } = prevState;

    if (nextProps.images.length !== serverImages.length) {
      return {
        images: [...nextProps.images],
        serverImages: [...nextProps.images],
      };
    }

    return { images, serverImages };
  }

  componentDidMount() {
    const { getImages } = this.props;
    getImages(this.exportData);
  }

  toggleShow = (state = false) => this.setState({ show: state });

  handleSubmit = url => {
    if (url) {
      const imageObj = getImageObj(url);
      this.setState(({ images }) => ({ images: [...images, imageObj] }));
    }

    this.toggleShow(false);
  };

  removeImage = index => {
    const { images } = this.state;
    this.setState({ images: images.filter((img, i) => i !== index) });
  };

  exportData = () => {
    const { images } = this.state;
    return images;
  };

  render() {
    const { show, images } = this.state;
    const { crudMode } = this.props;

    return (
      <div>
        <div className="config-panel-header">
          <label>Images</label>
        </div>
        {show && (
          <ImageUpload
            onSubmit={(type, url) => this.handleSubmit(url)}
            onClose={this.toggleShow}
            domain={IMAGE_UPLOAD_DOMAIN.SKU}
          />
        )}
        {
          <div className="skuImage">
            <Button secondary big iconName="plus" onClick={() => this.toggleShow(true)} />

            {images.map((imageObj, index) => (
              <div className="attachment-item">
                {crudMode !== EVENT_OPERATION.READ && (
                  <span onClick={() => this.removeImage(index)} className="del-icon">
                    x
                  </span>
                )}
                <Thumbnail image={imageObj.thumbnail} title="dummy" className="" />
              </div>
            ))}
          </div>
        }
      </div>
    );
  }
}

Image.propTypes = propTypes;

export default Image;
