import styled from 'styled-components';

const GeoLocationStyled = styled.div`
  .table-wrap {
    th {
      cursor: default;
    }
    td {
      .light {
        min-width: 90px;
        padding: 3px 4px;
        text-align: center;
        line-height: 10px;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;
        border-radius: 2px;
      }
      .pending {
        border: 0;
        padding: 0;
        .light {
          border: 1px solid #a7a9bc;
          color: #6b6c7e;
        }
      }
      .approved {
        .light {
          border: 1px solid #47c464;
          color: #287d3c;
        }
      }
      .rejected {
        .light {
          border: 1px solid rgba(218, 20, 20, 0.4);
          color: #da1414;
        }
      }
    }
  }
  .read {
    width: 742px;
    /* width: 59%; */
    max-width: unset;
    .evolve-dialog__body {
      position: relative;
      height: calc(507px - 64px - 70px);
      #map {
        height: 100%;
        position: absolute;
        width: 100%;
        > div:nth-child(2) {
          top: 50% !important;
          left: 50%;
          transform: translate(-50%, -50%) !important;
        }
      }
      .custom_legend {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: -41px;
        left: 24px;
        li {
          display: flex;
          align-items: center;
          margin-right: 24px;
          span {
            display: inline-block;
            width: 10px;
            height: 10px;
            line-height: 10px;
            margin-right: 4px;
            border-radius: 50%;
          }
          p {
            margin: 0;
            font-size: 10px;
            line-height: 12px;
            color: #2e384d;
          }
        }
        .correct_location {
          span {
            background-color: #d63649;
          }
        }
        .proposed_location {
          span {
            background-color: #1294f7;
          }
        }
      }
    }
  }

  .view {
    width: 600px;
    /* width: 59%; */
    max-width: unset;
    .evolve-dialog__body {
      position: relative;
      height: 200px;
      max-height: 400px;
      overflow-y: auto;
      padding: 0px;
    }
  }
  .table {
    table {
      border: none;
      thead {
        font-weight: 500;
      }
    }
  }
  @media only screen and (max-width: 980px) {
    .read {
      width: 59%;
      max-width: unset;
      height: 70vh;
    }
  }
`;
export default GeoLocationStyled;
