import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Switch, tableData } from '../../../v4/components';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const labelMappings = {
  id: 'id',
  name: 'name',
  rlp: 'rlp',
  status: 'status',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.name,
    title: 'Name',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.rlp,
    title: 'RLP',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
];

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data, permission, onIconClick) => {
  const table = {};

  table[labelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[labelMappings.name] = (
    <Link to={`sku/details/${data.id}`}>
      <td
        key={`${data.id}-name`}>
        <div className='name_div'>
          <span className='title'>{data.title}</span>
          {data.discontinue_sku && <span className='discontinue-sku'>DISCONTINUED</span>}
        </div>
      </td>
    </Link>
  );
  table[labelMappings.rlp] = (
    <td key={`${data.id}-rlp`}>
      {data.Rates && data.Rates.length > 0 ? (data.Rates[data.Rates.length - 1]?data.Rates[data.Rates.length - 1].priceDetails.rlp:null) : ''}
    </td>
  );
  table[labelMappings.status] = (
    <td key={`${data.id}-status`} onClick={(e) => e.stopPropagation()}>
      {
        <Switch
          onToggle={() => onIconClick(EVENT_OPERATION.UPDATE_STATUS, data)}
          checked={data.active}
          id={`switch-${data.id}`}
          disabled={!permission.update}
        />
      }
    </td>
  );

  return table;
};

const TableBody = ({ data, onButtonClick, permission, onIconClick }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, permission, onIconClick);

  return (
    <tr key={`sku-${data.id}`} onClick={() => onButtonClick(EVENT_OPERATION.UPDATE, data.id)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  onButtonClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
