import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withLoading from '../../../utils/composition/withLoading';
import { Pagination } from '../../../components';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import BulkDelete from '../../components/BulkDelete';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  checkedList: PropTypes.instanceOf(Array),
  breadCrumb: PropTypes.instanceOf(Array),
  getCategoryTitle: PropTypes.func.isRequired,
  getBrandTitle: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  onBulkDelete: PropTypes.func.isRequired,
};


const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
  checkedList: [],
  breadCrumb: [],
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    onIconClick,
    pagination,
    getCategoryTitle,
    getBrandTitle,
    onBulkDelete,
    permission,
    onTableRowClick,
  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.MERCHANDISE_DEVICE}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <Fragment>
            <div className='table-wrap'>
              <table>
                {
                  TableHeader({
                    dataListLength: data.list.length,
                    checkedListLength: state.checkedList.length,
                    primaryCheckboxHandler,
                    permission,
                  })
                }
                <tbody>
                  {data.list.map((device) => (
                    TableBody({
                      data: device,
                      checkedList: state.checkedList,
                      onIconClick,
                      secondaryCheckboxHandler,
                      permission,
                      getCategoryTitle,
                      getBrandTitle,
                      onTableRowClick,
                    })
                  ))
                  }
                </tbody>
              </table>
            </div>
          </Fragment>)}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
