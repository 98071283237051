import { compose } from 'recompose';
import ChannelDetail from './Detail';
import {
  query, mutation, CREATE_CHANNEL, UPDATE_CHANNEL
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedChannelDetail = compose(
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  withRequestTracker({
    query,
    mutation,
  }),
)(ChannelDetail);

export default composedChannelDetail;
