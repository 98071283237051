import { setContext } from 'apollo-link-context';
import { JWT } from '../../../environment';

const authMiddlewareLink = setContext(() => {
  const headers = {
    headers: {
      [JWT.HEADER.TOKEN.NAME]: localStorage.getItem(JWT.LOCAL_STORAGE.TOKEN.NAME) || null,
      [JWT.HEADER.REFRESH_TOKEN.NAME]: localStorage.getItem(JWT.LOCAL_STORAGE.REFRESH_TOKEN.NAME) || null, // eslint-disable-line
    },
  };

  /** if (headers.headers[JWT.HEADER.REFRESH_TOKEN.NAME]) {
    const currentTime = Date.now()
      .valueOf() / 1000;
    const tokenExpiration = decode(headers.headers[JWT.HEADER.REFRESH_TOKEN.NAME]).exp;
    if (currentTime > tokenExpiration) {
      history.push(LOGIN_URL);
    }
  } */

  if (!headers.headers[JWT.HEADER.TOKEN.NAME]) {
    delete headers.headers[JWT.HEADER.TOKEN.NAME];
  }

  return headers;
});

export default authMiddlewareLink;
