import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { maxDigitMessage } from '../config';
import { getTodaysDate } from '../../../../../utils/date';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import withLoading from '../../../../../utils/composition/withLoading';
import { Form, Input, CustomSelect } from '../../../../../components/index';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import SingleDatePicker from '../../../../../components/DatePicker/SingleDatePicker';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const DetailForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    enableErrorDisplay,
    handleInputChange,
    handleDropDownChange,
    dropDownCallBack,
    outletList,
    distributorList,
    subDUser,
  } = props;
  return (
    <Form className="client-form">
      <Row>
        {!subDUser && (
          <>
            <Col className="mb-24" md={8}>
              <CustomSelect
                options={distributorList}
                labelContent="Distributor *"
                className="custom-select"
                placeholder="Distributor "
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                onChange={event => handleDropDownChange(event, ['Distributor'], dropDownCallBack)}
                value={distributorList?.filter(({ id }) => id === data.Distributor?.id)}
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                errorMessage={!!data?.Distributor?.id || EMPTY}
              />
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col className="mb-16" md={4}>
          <CustomSelect
            enableValidation
            options={outletList}
            labelContent="Client Name *"
            className="custom-select"
            placeholder="Client Name"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            ref={ref => (refsObj.client = ref)}
            enableErrorDisplay={enableErrorDisplay}
            onChange={event => handleDropDownChange(event, ['client'], dropDownCallBack)}
            value={outletList?.filter(({ id }) => id === data.client?.id)}
          />
        </Col>
        <Col className="mb-16" md={4}>
          <Input
            // ref={ref => (refsObj.address = ref)}
            value={data?.client?.address}
            name="address"
            type="text"
            placeholder="Address"
            labelContent="Address"
            // enableErrorDisplay={enableErrorDisplay}
            // enableValidation
            onChange={event => handleInputChange(event, 'address', ['address'])}
            disabled
          />
        </Col>
        <Col className="mb-16" md={4}>
          <Input
            // ref={ref => (refsObj.phone = ref)}
            value={data?.client?.contactInfo?.[0]?.phoneNumber}
            name="phone"
            type="text"
            placeholder="Phone Number"
            labelContent="Phone Number"
            onChange={event => handleInputChange(event, 'phone', ['phone'])}
            // rule='matches'
            // argument={/\b(\d{10}$)\b|\b(\d{7}$)\b/} /* matches digit of length 10 or 7 */
            // enableErrorDisplay={enableErrorDisplay}
            // enableValidation
            errorMessage={data?.phone?.length > 0 ? INVALID : EMPTY}
            disabled
          />
        </Col>
        <Col className="mb-16" md={4}>
          <Input
            // ref={ref => (refsObj.pan = ref)}
            value={data?.client?.panNumber}
            name="pan"
            type="text"
            argument='^((?!(0))[0-9]{9})$'
            placeholder="PAN Number"
            labelContent="PAN Number"
            // rule='matches'
            // enableErrorDisplay={enableErrorDisplay}
            // enableValidation
            // errorMessage={data?.pan?.length > 0 ? INVALID : EMPTY}
            onChange={event => handleInputChange(event, 'pan', ['pan'])}
            disabled
          />
        </Col>
        <Col className="mb-16" md={4}>
          <Input
            value={data?.remarks}
            name="remarks"
            type="text"
            placeholder="Billing Address"
            labelContent="Billing Address"
            onChange={event => handleInputChange(event, 'remarks', ['remarks'])}
          />
        </Col>
      </Row>
    </Form>
  );
};

DetailForm.propTypes = propTypes;

DetailForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(DetailForm);

export default LoginViewWithErrorAndLoading;
