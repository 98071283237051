import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { formConfig, breadCrumb as breadCrumbConfig, crudSuccess as crudRequestConfig } from './config';
import View from './View';
import Form from './Form';
import UploadForm from '../UploadForm';
import CoverageWrapper from './CoverageWrapper';
import { DialogFormWrapper } from '../../../common';
import { Icon } from '../../../../v4/components';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import { TARGET_COVERAGE_DETAILS } from '../../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { crudResponseProcessor, handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { isError } from '../../../common/HelperFunctions';
import { getPermissionForTargets } from '../../../base/permission';
import { hyphenPresentor } from '../../../../utils/date';

const propTypes = {
  history: PropTypes.instanceOf(Object),
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func,
  upload: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  createOutletCoverageTarget: PropTypes.func.isRequired,
  updateOutletCoverageTarget: PropTypes.func.isRequired,
  getOutletCoverageTargets: PropTypes.func.isRequired,
};

const defaultProps = {
  history: {},
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class Coverage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      dialog: {
        type: '',
        element: '',
      },
      csvUploadData: [],
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.permission = getPermissionForTargets();

    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
      [EVENT_OPERATION.CREATE]: props.createOutletCoverageTarget,
      [EVENT_OPERATION.UPDATE]: props.updateOutletCoverageTarget,
    };

    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        let message = crudRequestConfig[type].message;
        if (type === EVENT_OPERATION.UPLOAD) {
          message = data;
          setTimeout(() => {
            this.resetDialog();
          }, 1000);
        } else {
          this.setState(data);
        }
        this.loadTableData();
        displayAlert(ALERT_TYPE.SUCCESS, message);
      },
      this.getData,
      ['target'],
    );

    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { getOutletCoverageTargets } = this.props;
    const { data } = this.state;
    getOutletCoverageTargets(
      {},
      {
        handleSuccess: response => {
          data.list = (response.data.outletCoverageTargets && response.data.outletCoverageTargets.rows) || [];
          data.total = (response.data.outletCoverageTargets && response.data.outletCoverageTargets.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ data });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleIconClick = (type, element) => {
    this.updateDialog(type, element);
  };

  handleButtonSubmit = (type, dialogData) => {
    const { displayAlert, createOutletTarget } = this.props;
    const { dialog, csvUploadData, data } = this.state;
    const dataListDate = data?.list.map(a => {
      const startEndDate = `${hyphenPresentor(a.startDate)}/${hyphenPresentor(a.endDate)}`;
      return startEndDate;
    });
    const csvUploadDate = `${hyphenPresentor(csvUploadData[0])}/${hyphenPresentor(csvUploadData[1])}`;
    const compareYear = dataListDate.some(b => b === csvUploadDate);
    if (type === EVENT_OPERATION.UPLOAD) {
      if (compareYear) {
        dialog.type = EVENT_OPERATION.REPLACE;
        this.setState(dialog);
        return;
      }
      dialog?.element?.file !== undefined
        ? this.onFormSubmit(type, dialog.element)
        : displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Please upload file.');
      return;
    }
    if (type === EVENT_OPERATION.REPLACE) {
      dialog.type = EVENT_OPERATION.UPLOAD;
      this.onFormSubmit(dialog.type, dialog.element);
      return;
    }
    this.onFormSubmit(type, dialogData);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    const { dialog } = this.state;
    if (dialog.type === EVENT_OPERATION.UPLOAD) {
      setTimeout(() => {
        this.resetDialog();
      }, 1000);
    }
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  updateDialog = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
      csvUploadData: [],
    });
  };

  onTableBodyClick = data => {
    const { history } = this.props;
    history.push(`/${TARGET_COVERAGE_DETAILS}/${data.id}`, { title: data.title });
  };

  handleCSVUpload = type => {
    this.updateDialog(EVENT_OPERATION.UPLOAD, { name: type });
  };

  isConfirmationType = type =>
    type === EVENT_OPERATION.DELETE || type === EVENT_OPERATION.BULK_DELETE || type === EVENT_OPERATION.UPDATE_STATUS;

  onSampleDownload = entity => {
    const { downloadReport, displayAlert } = this.props;
    downloadReport(
      {
        input: {
          domain: entity,
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          const { downloadReport } = response.data;
          window.open(`${window.location.origin}/${downloadReport.file.name}`);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getCSVHeader = async (event) => {
    return new Promise((resolve, reject) => {
      if (typeof FileReader != 'undefined') {
        const myReader = new FileReader();
        myReader.onload = (e) => {
          const content = myReader.result;
          const csvHeader = content.split(/[\r\n]+/)[1];
          const csvHeaderFiltered = csvHeader.replace(/&/g, '').replace(/</g, '').replace(/"/g, '').split(',');
          resolve({ data: csvHeaderFiltered, isError: false, message: '' });
        };
        myReader.onerror = (e) => {
          reject({
            data: [],
            isError: true,
            message: 'Error while processing CSV file',
          });
        };
        myReader.readAsText(event.files[0]);
      } else {
        reject({
          data: [],
          isError: true,
          message: 'This browser does not support HTML5.',
        });
      }
    });
  };
  processFile = async event => {
    const csvHeaderInfo = await this.getCSVHeader(event);
    this.setState({ csvUploadData: csvHeaderInfo.data, });
  };
  handleFileUpload = (event, type, dialogData) => {
    const { dialog } = this.state;
    dialog.element.file = event.target.files[0];
    const e = event.target;
    this.setState({ dialog }, () => {
      this.processFile(e);
    });
  };

  render() {
    const { data, dialog } = this.state;

    const { type, element } = dialog;

    const { serverResponseWaiting } = this.props;

    const sampleUrl = checkCase => (
      <a onClick={() => this.onSampleDownload(checkCase)}>
        <span className="sample-csv">
          {' '}
          <Icon iconName="download" />
          Sample.csv
        </span>
      </a>
    );

    return (
      <CoverageWrapper>
        {type && (
          <div className="targets-wrapper x">
            <DialogFormWrapper
               type={type}
               dialogElement={dialog.element}
               formConfig={formConfig[type]}
               onDialogCancel={this.resetDialog}
               onDialogSubmit={this.handleButtonSubmit}
               fileUploadRefs={formConfig[FORM_CONFIG.REFS_OBJ]}
               controlDialogClose={type === EVENT_OPERATION.UPLOAD}
               submitClickedControl
              renderDialog={({
                dialogData,
                enableErrorDisplay,
                handleInputChange,
                refsObj,
                fileUploadRefs,
              }) => (
                  <Fragment>
                    {(type === EVENT_OPERATION.CREATE ||
                      type === EVENT_OPERATION.UPDATE ||
                      type === EVENT_OPERATION.READ) && (
                        <>
                          <Form
                            show
                            type={type}
                            refsObj={refsObj}
                            data={dialogData}
                            enableErrorDisplay={enableErrorDisplay}
                            handleInputChange={handleInputChange}
                          />
                        </>
                      )}
                      {
                      type === EVENT_OPERATION.REPLACE && (
                        <>
                          <p>Are you sure you want to <b>replace</b> the month target according to uploaded data. </p>
                        </>
                      )
                    }
                    {type === EVENT_OPERATION.UPLOAD && (
                      <>
                        <UploadForm
                          show
                          refsObj={fileUploadRefs}
                          data={dialogData}
                          loading={serverResponseWaiting}
                          handleFileUpload={this.handleFileUpload}
                          enableErrorDisplay={enableErrorDisplay}
                        />
                        {sampleUrl(DOWNLOAD_DOMAIN.OUTLET_COVERAGE_USER_TARGET_SAMPLE)}
                      </>
                    )}         
                  </Fragment>
                )}
            />
          </div>
        )}
        <View
          data={data}
          breadCrumb={breadCrumbConfig}
          loading={serverResponseWaiting}
          onTableBodyClick={this.onTableBodyClick}
          onCSVUpload={this.handleCSVUpload}
          handleIconClick={this.handleIconClick}
          permission={this.permission}
        />
      </CoverageWrapper>
    );
  }
}

Coverage.propTypes = propTypes;

Coverage.defaultProps = defaultProps;

export default withAlert()(Coverage);
