import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../common/GqlAPI';
import { GET_LOCATION_HIERARCHY } from '../locationHierarchyDetails/API';

const TOWN_FRAGMENT = gql`
  fragment TownFragment on Town {
    id
    title
    territoryId
    territory: Territory {
      title
    }
    LocationHierarchyDetail {
      id
      title
      locationHierarchyId
    }
  }
`;

/** const GET_TERRITORY_LIST = gql`
 query {
    territories {
      rows {
        id
        title
      }
    }
  }
 `; */

const GET_TERRITORY_LIST = gql`
  query territories($offset: Int, $limit: Int) {
    territories(offset: $offset, limit: $limit) {
      rows {
        id
        title
      }
      count
    }
  }
`;

const GET_TOWN_LIST = gql`
  query getTown($offset: Int, $limit: Int, $filter: FilterInput) {
    towns(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...TownFragment
      }
      count
    }
  }
  ${TOWN_FRAGMENT}
`;

const CREATE_TOWN = graphql(
  gql`
    mutation ($title: String!, $territoryId: Int, $locationHierarchyDetailId: Int) {
      createTown(title: $title, territoryId: $territoryId, locationHierarchyDetailId: $locationHierarchyDetailId) {
        id
        title
        territoryId
        territory: Territory {
          id
          title
        }
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createTown: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_TOWN = graphql(
  gql`
    mutation ($id: Int!, $title: String!, $territoryId: Int, $locationHierarchyDetailId: Int) {
      updateTown(
        id: $id
        title: $title
        territoryId: $territoryId
        locationHierarchyDetailId: $locationHierarchyDetailId
      ) {
        id
        title
        territoryId
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateTown: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_LAST_LOCATION_HIERARCHY_DETAILS = gql`
  query getLeastLevelLocationHierarchyDetails($offset: Int, $limit: Int, $filter: FilterInput) {
    getLeastLevelLocationHierarchyDetails(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        locationHierarchyId
      }
    }
  }
`;

const query = {
  getTownList: GET_TOWN_LIST,
  getTerritoryList: GET_TERRITORY_LIST,
  getLocationHierarchy: GET_LOCATION_HIERARCHY,
  getLastLocationHierarchyList: GET_LAST_LOCATION_HIERARCHY_DETAILS,
};

const mutation = {
  createTown: CREATE_TOWN,
  updateTown: UPDATE_TOWN,
  toggleState: TOGGLE_STATE,
};

export {
  TOWN_FRAGMENT, CREATE_TOWN, UPDATE_TOWN, GET_TOWN_LIST, GET_TERRITORY_LIST, TOGGLE_STATE
};

export { query, mutation };
