import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  ERROR,
  formConfig,
  STOCK_TYPE_LIST,
  totalViewRefs,
  updateSkuLine,
  prepareSkuLinesForValidation,
  updateRate,
  DISCOUNT_ID,
  STOCK_TYPE,
} from './config';
import Form from './Form';
import { TableView, TotalView } from './View';
import { DialogFormWrapper } from '../../../../common';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  compareList,
  extractSingleItemFromList,
  filterItems,
  findAndReplaceItem,
} from '../../../../../utils/arrayProcessor';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { totalPriceMapper } from '../config';
import { refValidator } from '../../../../../utils/refGenerator';
import { RETURN_TYPE } from '../../../../common/DomainConfig';
import { VAT_AMOUNT } from '../../../../../data/enums/GeneralConstants';
import {
  getFlattenedOrders,
  isConfirmationType,
  isError,
} from '../../../../common/HelperFunctions';
import Confirmation from '../../../../common/DialogConfirmation';
import PromotionForm from '../../../orderProcessing/received/detail/promotion/PromotionForm';
import TableStyled from './TableStyled';
import { getTodaysDate } from '../../../../../utils/date';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  displayAlert: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool,
  getDetails: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  skuList: PropTypes.arrayOf(Object),
  invoiceNumberStatus: PropTypes.bool,
  distributorId: PropTypes.String,
  distributorBatchFlag: PropTypes.bool,
};

const defaultProps = {
  data: {
    orders: [],
    amount: {},
  },
  skuList: [],
  enableErrorDisplay: false,
  serverResponseWaiting: false,
  invoiceNumberStatus: false,
  distributorBatchFlag: false,
  distributorId: '',
};

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        orders: [],
        amount: {},
      },
      dialog: {
        type: '',
        element: '',
      },
      selectedOrders: [],
      skuBatchList: [],
      originalSkuBatchList: [],
      validatedOrders: [],
      totalAmount: totalPriceMapper({}),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.state;
    if (!compareList(data.orders, prevState.data.orders)) {
      this.updateState({
        totalAmount: totalPriceMapper({}),
        selectedOrders: [],
      });
    }
  }

  onAPIRequestFailure = () => {
    alert('API request failed');
  };

  getSKUBatchList = (skuId, distributorId) => {
    const { getSKUBatchDetail, displayAlert } = this.props;
    if (distributorId) {
      getSKUBatchDetail(
        {
          distributorId,
          skuId,
        },
        {
          handleSuccess: response => {
            this.setState({ skuBatchList: response.data.getSkuBatchDetails });
          },
          handleError: err => {
            // TODO call the api error function.
            // this.onAPIRequestFailure(err);
          },
        },
      );
    } else displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Distributor not selected');
  };

  onDropDownChange = (item, value, state, stateUpdater) => {
    const { skuList, distributorId, getSKUBatchDetail, distributorBatchFlag } = this.props;
    const { skuBatchList } = this.state;
    if (item === 'skuId') {
      const selectedSku = filterItems(skuList, value)[0] || {};
      getSKUBatchDetail(
        { distributorId, skuId: value },
        {
          handleSuccess: response => {
            const batchDetails = response.data.getSkuBatchDetails;
            if (!distributorBatchFlag) {
              const batch = batchDetails.length > 0 ? batchDetails[0] : null;
              state.batchName = batch ? batch.batchName : 'DEFAULT_BATCH';
              state.per_unit_rate = batch ? batch.dlp : 0;
              state.skuBatchId = batch ? batch.id : 0;
              state.updatedAmountDetails.rate = selectedSku.Rates[0].priceDetails.rlp;
              state.validRate = selectedSku.Rates[0].priceDetails.rlp;
              stateUpdater(state);
            } else {
              state.batchName = '';
              state.skuBatchId = 0;
              state.updatedAmountDetails.rate = selectedSku.Rates[0].priceDetails.rlp;
              state.validRate = selectedSku.Rates[0].priceDetails.rlp;
              state.per_unit_rate = 0;
              stateUpdater(state);
            }
            this.setState({
              skuBatchList: batchDetails,
              originalSkuBatchList: batchDetails,
            });
          },
          handleError: err => {
            state.batchName = 'DEFAULT_BATCH';
            state.skuBatchId = 0;
            state.amountDetails.rate = 0;
            state.per_unit_rate = 0;
            stateUpdater(state);
            console.log(err);
          },
        },
      );
    }
    if (item === 'skuBatchId') {
      state.batchName = value.batchName;
      state.skuBatchId = value.id;
      state.manufactureDate = value.manufactureDate || new Date().toISOString().slice(0, 10);
      state.expiryDate = value.expiryDate || new Date().toISOString().slice(0, 10);
      state.per_unit_rate = value.dlp || 0;
      state.stockType = value.expiryDate < getTodaysDate()?STOCK_TYPE.EXPIRED.value : '';
      stateUpdater(state);
    }
  };

  batchCallBack = (item, value, state, stateUpdater) => {
    const { originalSkuBatchList } = this.state;
    const checkBatchNameAndGetList = state.batchName
      ? originalSkuBatchList.filter(d => d.batchName.toLowerCase().startsWith(state.batchName.toLowerCase()))
      : originalSkuBatchList;
    const checkValueInSKUBatch = originalSkuBatchList.find(d => d.batchName.toLowerCase() === value.toLowerCase());
    if (state.batchName === '' || checkValueInSKUBatch === undefined) {
      state.skuBatchId = 0;
      state.stockType = '';
      state.manufactureDate =  new Date().toISOString().slice(0, 10);
      state.expiryDate = new Date().toISOString().slice(0, 10);
      stateUpdater(state);
    }
    this.setState({
      skuBatchList: checkBatchNameAndGetList,
    });
  };

  expiryCallBack= (item, value, state, stateUpdater) => {
      state.stockType = state.expiryDate < getTodaysDate()?STOCK_TYPE.EXPIRED.value : '';
      stateUpdater(state);
  };

  handleIconClick = (type, element = {}) => {
    const { distributorId } = this.props;
    this.setState(
      {
        dialog: {
          type,
          element,
        },
      },
      () => type === EVENT_OPERATION.UPDATE && this.getSKUBatchList(element.skuId, distributorId),
    );
  };
  inputDiscountCalc = (param, value, data, stateSetter, discounTypePercentStatus, total) => {
    if (discounTypePercentStatus) {
      const formattedValueModified = value <= 100 ? value : 0;
      data.updatedAmountDetails.discountValue = formattedValueModified;
      data.updatedAmountDetails.promotionDiscount = (formattedValueModified / 100) * total;
    } else {
      const formattedValueModified = value <= total ? value : 0;
      data.updatedAmountDetails.discountValue = formattedValueModified;
      data.updatedAmountDetails.promotionDiscount = formattedValueModified;
    }
    stateSetter(data);
  };
  handleQtyChange = async (param1, returnqty, currentOrder, stateSetter) => {
    const { data } = this.state;
    const { invoiceNumberStatus } = this.props;
    if (invoiceNumberStatus) {
      if ((currentOrder.promotionId || data.billDiscountDetails.length > 0) && currentOrder.returnQuantity) {
        await this.getDiscountValidation(currentOrder).then(order => stateSetter(order));
      } else {
        if (currentOrder.returnQuantity > currentOrder.updatedQuantity) {
          currentOrder.returnQuantity = currentOrder.updatedQuantity;
          return stateSetter(currentOrder);
        }
        return stateSetter(currentOrder);
      }
    } else {
      const discounTypePercentStatus = currentOrder.updatedAmountDetails.discountTypeId === DISCOUNT_ID.DISCOUNT_PER;
      const total = currentOrder.updatedAmountDetails.rate * currentOrder.returnQuantity;
      currentOrder.updatedAmountDetails.discountValue = 0;
      currentOrder.updatedAmountDetails.promotionDiscount = 0;
      stateSetter(currentOrder);
      param1 === 'discountValue' &&
        this.inputDiscountCalc(param1, returnqty, currentOrder, stateSetter, discounTypePercentStatus, total);
    }
  };

  getDiscountValidation = async (currentOrder, uncheck = false) =>
    await this.validatePromotion(currentOrder, uncheck).then(order => order);

  handlePrimaryCheckBoxClick = event => {
    const { data } = this.state;
    let selectedOrdersList = [];
    let flattenedOrderList = [];
    let validatedOrders = [];
    if (event.currentTarget.checked) {
      selectedOrdersList = (data.orders.map(order => extractSingleItemFromList(order.Lines)) || []).flat();
      flattenedOrderList = getFlattenedOrders(data.orders);
    } else {
      selectedOrdersList = [];
    }
    if (flattenedOrderList.length > 0) {
      validatedOrders = flattenedOrderList.map(order => prepareSkuLinesForValidation(order) || {});
    }
    this.setState(
      {
        selectedOrders: selectedOrdersList,
        validatedOrders,
      },
      () => {
        this.calculateLineTotal();
      },
    );
  };

  handleSecondaryCheckboxClick = async (order, checked = false) => {
    const { selectedOrders, data, validatedOrders } = this.state;
    const { invoiceNumberStatus } = this.props;
    const index = selectedOrders.indexOf(order.id);
    let clickSuccessful = false;
    let validatedOrder = order;
    if (index > -1) {
      const promotionOrder = data.billDiscountDetails.length > 0 || order.promotionId;
      if (promotionOrder || validatedOrders.length > 0) {
        await this.getDiscountValidation(order, true).then(modifiedOrder => {
          validatedOrder = { ...(modifiedOrder || {}) };
          if (modifiedOrder.valid) {
            !checked && selectedOrders.splice(index, 1);
            clickSuccessful = true;
          }
        });
      } else {
        !checked && selectedOrders.splice(index, 1);
        clickSuccessful = true;
      }
    } else if (data.billDiscountDetails.length > 0 || order.promotionId) {
      await this.getDiscountValidation(order).then(modifiedOrder => {
        validatedOrder = { ...(modifiedOrder || {}) };
        if (modifiedOrder.valid) {
          // this.updateValidatedOrder(modifiedOrder);
          selectedOrders.push(order.id);
          clickSuccessful = true;
        }
      });
    } else {
      clickSuccessful = true;
      selectedOrders.push(order.id);
    }
    this.setState({ selectedOrders }, () => {
      this.calculateLineTotal();
      if (invoiceNumberStatus && clickSuccessful) this.updateValidatedOrder(validatedOrder, checked);
    });
  };

  handleDiscountChange = e => {
    const { totalAmount } = this.state;
    totalAmount[e.target.name] = e.formattedValue;
    this.calculateTotal([], totalAmount);
  };

  handleFormSubmit = (type, dialogData) => {
    if (type === EVENT_OPERATION.DELETE) {
      this.handleRowDelete(dialogData);
    } else {
      this.handleDataUpdate(type, dialogData);
    }
  };

  handleRowDelete = dialogData => {
    const { data } = this.state;
    const orderIndex = data.orders.findIndex(item => item.promotionId === dialogData.promotionId);
    const index = data.orders[orderIndex].Lines.findIndex(item => item.id === dialogData.id);
    data.orders[orderIndex].Lines.splice(index, 1);
    this.setState({ data }, () => this.calculateLineTotal());
  };

  handleDataUpdate = (type, dialogData) => {
    const { data } = this.state;
    const modifiedDialogData = this.modifyData(dialogData);
    if (modifiedDialogData.id) {
      if (data.orders.length > 1 && modifiedDialogData.promotionId === null) {
        const index = data.orders.findIndex(order => order.promotionId === null);
        data.orders[index].Lines = findAndReplaceItem(data.orders[index].Lines, modifiedDialogData);
      } else {
        const index = data.orders.findIndex(order => order.promotionId === dialogData.promotionId);
        data.orders[index].Lines = findAndReplaceItem(data.orders[index].Lines, modifiedDialogData);
      }
    } else {
      modifiedDialogData.id =
        1000 + (data.orders.length > 0 ? (data.orders[0].Lines ? data.orders[0].Lines.length + 1 : 1) : 1);
      data.orders = this.encapsulateSkuLineToPromotionOrder([...data.orders], modifiedDialogData);
    }
    this.setState({ data }, () => this.handleSecondaryCheckboxClick(modifiedDialogData, true));
  };

  updateValidatedOrder = (currentOrder, checked) => {
    const { validatedOrders } = this.state;
    const newValidatedOrder = [...(validatedOrders || [])];
    // if (currentOrder.valid) {
    const index = newValidatedOrder.findIndex(item => item.orderId === currentOrder.id);
    const formattedOrder = prepareSkuLinesForValidation(currentOrder) || {};
    if (index > -1) {
      if (checked) {
        // when dialogData changes
        newValidatedOrder.splice(index, 1, formattedOrder);
      } else {
        newValidatedOrder.splice(index, 1);
      }
    } else {
      newValidatedOrder.push(formattedOrder);
    }
    //  }

    this.setState({ validatedOrders: newValidatedOrder });
  };

  encapsulateSkuLineToPromotionOrder = (orders, dialogData) => {
    if (orders.length === 0) {
      return [
        {
          id: null,
          promotionId: null,
          Lines: [
            {
              ...dialogData,
              promotionId: null,
            },
          ],
        },
      ];
    }
    orders[0].Lines.push({
      ...dialogData,
      promotionId: null,
    });

    return orders;
  };

  validatePromotion = async (currentOrder, uncheck = false) => {
    const { validateSrnData, displayAlert } = this.props;
    const { selectedOrders } = this.state;
    const validationObj = this.prepareDataForValidation(currentOrder, uncheck) || {};
    return new Promise((resolve, reject) => {
      validateSrnData(
        {
          input: { ...validationObj },
        },
        {
          handleSuccess: response => {
            const responseData = response.data.validateSrnData;
            if (responseData) {
              if (responseData.status) {
                resolve({
                  ...currentOrder,
                  updatedAmountDetails:
                    {
                      ...responseData.Data.amountDetails,
                      discountTypeId: currentOrder.updatedAmountDetails.discountTypeId,
                    } || {},
                  valid: true,
                });
              } else {
                displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Violates Discount Criteria');
                const removeorders = selectedOrders?.filter(order => order !== currentOrder.id);
                this.setState({ selectedOrders: removeorders });
                resolve({
                  ...currentOrder,
                  returnQuantity: currentOrder.updatedQuantity,
                  valid: false,
                });
              }
            } else {
              if (isError(response)) {
                displayAlert(ALERT_TYPE.CUSTOM_DANGER, response.errors[0].message);
              }
              resolve({
                ...currentOrder,
                returnQuantity: currentOrder.updatedQuantity,
                valid: false,
              });
            }
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
            resolve({
              ...currentOrder,
              returnQuantity: currentOrder.updatedQuantity,
              valid: false,
            });
          },
        },
      );
    });
  };

  prepareDataForValidation = (currentOrder, uncheck) => {
    const { data, validatedOrders, selectedOrders } = this.state;
    let preparedData = {};
    if (uncheck) {
      const filteredValidatedOrders = validatedOrders.filter(order => order.orderId !== currentOrder.id);
      preparedData = {
        triggeredObj: filteredValidatedOrders.shift() || prepareSkuLinesForValidation(currentOrder),
        invoiceNumber: data.salesInvoiceNumber,
        editedObj: filteredValidatedOrders || [],
      };
    } else {
      preparedData = {
        triggeredObj: prepareSkuLinesForValidation(currentOrder),
        invoiceNumber: data.salesInvoiceNumber,
        editedObj:
          selectedOrders?.length === 0 ? [] : validatedOrders.filter(order => order.orderId !== currentOrder.id) || [],
      };
    }
    if (currentOrder.promotionId) {
      const promotionOrderObj = data.orders.filter(order => order.promotionId === currentOrder.promotionId)[0] || {};
      const formattedLines = promotionOrderObj.Lines
        ? promotionOrderObj.Lines.map(order => prepareSkuLinesForValidation(order))
        : [];
      preparedData.promotionData = {
        promotionId: promotionOrderObj.promotionId,
        Lines: formattedLines,
      };
    }
    if (data.billDiscountDetails.length > 0) {
      const flattenedOrder = getFlattenedOrders(data.orders);
      preparedData.billDiscountData = {
        promotionId: data.billDiscountDetails.map(billDiscount => billDiscount.id) || [],
        Lines: flattenedOrder.map(order => prepareSkuLinesForValidation(order) || {}),
      };
    }

    return preparedData;
  };

  modifyData = dialogData => {
    const { skuList, invoiceNumberStatus } = this.props;
    const { skuBatchList, data, totalAmount } = this.state;
    const selectedSku = filterItems(skuList, dialogData.skuId)[0] || {};
    const selectedBatch = filterItems(skuBatchList, dialogData.skuBatchId)[0] || {};

    return updateSkuLine(dialogData, selectedSku, selectedBatch, invoiceNumberStatus ? data.amount : totalAmount);
  };

  calculateTotal = (filteredList, updatedTotalObj) => {
    const { invoiceNumberStatus } = this.props;
    const totalAmount = { ...updatedTotalObj };
    const discounts = {
      billDiscount: !invoiceNumberStatus ? totalAmount.billDiscount : 0,
      tradeDiscount: !invoiceNumberStatus ? totalAmount.tradeDiscount : 0,
    };
    if (invoiceNumberStatus) {
      filteredList.forEach(item => {
        discounts.billDiscount += item.updatedAmountDetails.billDiscount || 0;
        discounts.tradeDiscount += item.updatedAmountDetails.tradeDiscount || 0;
      });
    }
    const discountAmount =
      Number(updatedTotalObj.promotionDiscount) +
      Number(updatedTotalObj.topUpDiscount) +
      Number(discounts.billDiscount) +
      Number(discounts.tradeDiscount);
    const taxableAmount = totalAmount.subTotal - Number(discounts.billDiscount) - discounts.tradeDiscount || 0;
    const vat = VAT_AMOUNT * taxableAmount;

    totalAmount.billDiscount = discounts.billDiscount || 0;
    totalAmount.tradeDiscount = discounts.tradeDiscount || 0;
    totalAmount.discountAmount = discountAmount || 0;
    totalAmount.taxAmount = vat;
    totalAmount.taxableAmount = taxableAmount;
    totalAmount.netAmount = taxableAmount + vat;
    this.setState({ totalAmount });
  };

  calculateLineTotal = () => {
    const calculatedAmounts = {
      subTotal: 0,
      promotionDiscount: 0,
      topUpDiscount: 0,
      grossAmount: 0,
    };
    const { data, totalAmount } = this.state;
    const flattenedOrders = getFlattenedOrders(data.orders);
    const selectedOrderDetails = this.getSelectedOrders(flattenedOrders);

    selectedOrderDetails.forEach(sku => {
      calculatedAmounts.subTotal += sku.updatedAmountDetails.subTotal;
      calculatedAmounts.promotionDiscount += Number(sku.updatedAmountDetails.promotionDiscount);
      calculatedAmounts.topUpDiscount += sku.updatedAmountDetails.topUpDiscount;
      calculatedAmounts.grossAmount += sku.updatedAmountDetails.grossAmount;
    });

    totalAmount.subTotal = calculatedAmounts.subTotal;
    totalAmount.promotionDiscount = calculatedAmounts.promotionDiscount;
    totalAmount.topUpDiscount = calculatedAmounts.topUpDiscount;
    totalAmount.grossAmount = calculatedAmounts.grossAmount;
    this.calculateTotal(selectedOrderDetails, totalAmount);
  };

  getCheckboxStatus = orderId => {
    const { selectedOrders } = this.state;
    return selectedOrders.indexOf(orderId) > -1;
  };

  getValidationStatus = () => {
    const lineValidation = this.linesValidationStatus();

    return lineValidation && refValidator(totalViewRefs);
  };

  linesValidationStatus = () => {
    const { invoiceNumberStatus, displayAlert } = this.props;
    const { selectedOrders, data } = this.state;
    const status = invoiceNumberStatus ? selectedOrders.length > 0 : data.orders.length > 0;
    if (!status) {
      const message = invoiceNumberStatus ? ERROR.NO_ORDER_SELECTED : ERROR.NO_ORDER_AVAILABLE;
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, message);
    }

    return status;
  };

  determineReturnType = entireOrders => {
    const { selectedOrders } = this.state;
    const { invoiceNumberStatus } = this.props;
    if (invoiceNumberStatus) {
      if (selectedOrders.length === entireOrders.length) {
        const srnType = entireOrders.some(item => item.updatedQuantity !== item.returnQuantity)
          ? RETURN_TYPE.PARTIAL
          : RETURN_TYPE.COMPLETE;

        return srnType;
      }

      return RETURN_TYPE.PARTIAL;
    }

    return RETURN_TYPE.COMPLETE;
  };

  getSelectedOrders = flattenedOrders => {
    const { selectedOrders } = this.state;

    return flattenedOrders.filter(order => selectedOrders.indexOf(order.id) > -1) || [];
  };

  exportData = () => {
    const { data, totalAmount } = this.state;
    const flattenedOrders = getFlattenedOrders(data.orders);
    const returnOrders = this.getSelectedOrders(flattenedOrders);
    const srnType = this.determineReturnType(flattenedOrders);

    return {
      srnType,
      returnOrders,
      totalAmount,
      orders: data.orders,
    };
  };

  updateState = (states = {}) => {
    this.setState({ ...states });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  mergePromotionLists = jsonData => {
    const uniqueLines = {};
    jsonData.forEach(item => {
      const uniqueItemLines = [];
      item.Lines.forEach(line => {
        if (!uniqueLines[line.id]) {
          uniqueLines[line.id] = true;
          uniqueItemLines.push(line);
        }
      });
      item.Lines = uniqueItemLines;
    });

    return jsonData;
  };

  render() {
    const { data, dialog, skuBatchList, totalAmount, selectedOrders } = this.state;
    const { type } = dialog;
    const { skuList, enableErrorDisplay, serverResponseWaiting, invoiceNumberStatus, distributorBatchFlag } =
      this.props;

    const skuLines = data.orders || [];
    const priceDetails = totalAmount || {};
    const totalOrderListLength = data?.orders?.map?.(item => item?.Lines)?.flat()?.length;
    return (
      <TableStyled>
        <div className="return-create">
          {type && (
            <DialogFormWrapper
              formConfig={formConfig[type]}
              dialogElement={dialog.element}
              onDialogSubmit={this.handleFormSubmit}
              onDialogCancel={this.resetDialog}
              type={type}
              formTitle={[EVENT_OPERATION.DELETE, EVENT_OPERATION?.BULK_DELETE]?.includes?.(type) && 'Delete'}
              loading={serverResponseWaiting}
              renderDialog={({
                refsObj,
                dialogData,
                handleInputChange,
                enableErrorDisplay,
                handleMultipleUpload,
                handleDropDownChange,
                updateState,
                getState,
              }) => (
                <Fragment>
                  {(type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.CREATE) && (
                    <Form
                      show
                      type={type}
                      refsObj={refsObj}
                      skuList={skuList}
                      data={dialogData}
                      skuBatchList={skuBatchList}
                      stockTypeList={STOCK_TYPE_LIST}
                      loading={serverResponseWaiting}
                      getState={getState}
                      updateState={updateState}
                      handleInputChange={handleInputChange}
                      enableErrorDisplay={enableErrorDisplay}
                      dropDownCallBack={this.onDropDownChange}
                      inputCallBack={this.handleQtyChange}
                      batchCallBack={this.batchCallBack}
                      expiryCallBack={this.expiryCallBack}
                      handleDropDownChange={handleDropDownChange}
                      handleMultipleUpload={handleMultipleUpload}
                      invoiceNumberStatus={invoiceNumberStatus}
                      distributorBatchFlag={distributorBatchFlag}
                    />
                  )}
                  {type === EVENT_OPERATION.READ && <PromotionForm data={dialogData} loading={serverResponseWaiting} />}
                  {isConfirmationType(type) && Confirmation(type)}
                </Fragment>
              )}
            />
          )}
          <TableView
            skuLines={skuLines}
            checkedList={selectedOrders}
            totalOrders={totalOrderListLength ?? data.totalValidOrders}
            onIconClick={this.handleIconClick}
            checkBoxStatus={this.getCheckboxStatus}
            invoiceNumberStatus={invoiceNumberStatus}
            onPrimaryCheckBoxClick={this.handlePrimaryCheckBoxClick}
            onSecondaryCheckBoxClick={this.handleSecondaryCheckboxClick}
          />
          <TotalView
            refsObj={totalViewRefs}
            priceDetails={priceDetails}
            enableErrorDisplay={enableErrorDisplay}
            onInputChange={this.handleDiscountChange}
            invoiceNumberStatus={invoiceNumberStatus}
          />
        </div>
      </TableStyled>
    );
  }
}

Table.propTypes = propTypes;

Table.defaultProps = defaultProps;

export default withAlert()(Table);
