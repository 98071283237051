import { compose } from 'recompose';
import Campaigns from './Campaigns';
import { query, mutation } from './API';
import { TOGGLE_STATE } from '../../common/GqlAPI';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const ComposedCampaigns = compose(
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Campaigns);

export default ComposedCampaigns;
