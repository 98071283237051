import PropTypes from 'prop-types';
import React from 'react';
import Popover from '../../../../../components/Popover';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { tableData } from '../../../../../v4/components';

const labelMappings = {
  sku: 'SKU',
  rate: 'Rate',
  orderQuantity: 'Order Quantity',
  totalDiscount: 'Total Discount',
  netAmount: 'Net Amount',
};

const isPromotionAvailable = (isPromotionAvailable) => {
  return isPromotionAvailable ? <img src='/image/icons/Promotion.svg' style={{ cursor: 'pointer' }} /> : null;
}

const getAvailablePromotions = (promotions = []) => {
  return (
    <ul className='available-promotions'>
      {promotions.length > 0 && promotions.map((promotion) => (
        <li key={promotion.id} className='item-value'>{promotion.title}</li>
      ))}
    </ul>
  )
}
const returnTableBody = (data) => {
  const table = {};
  const skutitle = (title, showTag) => (
    <>
      {title}
      {showTag ?
        <span className='sbd-sku'>SBD</span>
        : null
      }
      <div className='simple-popup promotion'>
        <Popover
          popoverIcon={isPromotionAvailable(data?.promotion?.length > 0)}
          disabled={!data?.promotion?.length > 0}
          headerTitle='Promotions Applied'
        >
          <span className='arrow-up' />
          {getAvailablePromotions(data?.promotion || [])}
        </Popover>
      </div>
    </>
  )
  table[labelMappings.sku] = <td key={`${data.id}- sku`} >{skutitle(data.title, data?.isSBDSku)}</td>;
  table[labelMappings.rate] = <td key={`${data.id}- rate`} className='text-right'>{data?.Rates[0]?.priceDetails?.rlp}</td>;
  table[labelMappings.orderQuantity] = (
    <td key={`${data.id}- orderQuantity`} className='text-right' onClick={(e) => e.stopPropagation()}>
      {data.quantity}
    </td>
  );
  table[labelMappings.totalDiscount] = (
    <td key={`${data.id}- totalDiscount`} onClick={(e) => e.stopPropagation()} className='text-right'>
      {fixedFloatAndCommas(data?.amount_details?.discountAmount)}
    </td>
  );
  table[labelMappings.netAmount] = (
    <td key={`${data.id}- orderQuantity`} onClick={(e) => e.stopPropagation()} className='text-right'>
      {fixedFloatAndCommas(data?.amount_details?.netAmount)}
    </td>
  );

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
    classNames: 'left',
  },
  {
    id: 2,
    label: labelMappings.rate,
    title: 'Rate',
    show: true,
    classNames: 'text-right'
  },
  {
    id: 3,
    label: labelMappings.orderQuantity,
    title: 'Order Quantity',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 4,
    label: labelMappings.totalDiscount,
    title: 'Total Discount',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.netAmount,
    title: 'Net Amount',
    show: true,
    classNames: 'text-right',
  },
];

const TableBody = ({ data }) => {
  const tableBody = returnTableBody(data);

  return (
    <tr key={data.id} style={{ cursor: 'pointer' }}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

TableHeader.propTypes = {
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
};

TableHeader.defaultProps = {
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
