import styled from 'styled-components';

const DetailWrap = styled.div`
  min-height: 120px;
  .full-screen {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    fill: #a7a9bc;
  }

  .greet {
    h3 {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #272833;
    }

    h1 {
      margin: 0;
      margin: 6px 0 16px 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      color: #272833;
    }
  }

  .text-holder {
    > span {
      margin-right: 16px;
      font-size: 10px;
      line-height: 10px;
      color: #6b6c7e;

      svg {
        color: #6b6c7e;
        height: 10px;
        width: 10px;
        margin-right: 6px;
      }
    }
  }
`;

export default DetailWrap;
