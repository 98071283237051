import styled from 'styled-components';

const DialogStyled = styled.span`
  display: inline-block;
  font-weight: 600;
`;

const DialogTitleStyled = styled.span`
  display: inline-block;
  font-weight: 600;
`;

const DialogHeaderStyled = styled.div`
  padding: 20px 24px;
  border-bottom: 1px solid #e7e7ed;
  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: #272833;
    > span {
      text-transform: capitalize !important;
    }
  }
  .evolve-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      display: inline-block;
      margin-right: 8px;
    }
    svg {
      fill: #000000;
      height: 12px;
      width: 12px;
    }
    button{
      padding: 0px;
    }
  }
`;

const DialogFooterStyled = styled.div`
  text-align: right;
  padding: 15px 24px;
`;

const DialogBodyStyled = styled.div`
  border-bottom: 1px solid #e7e7ed;
  font-size: 14px;
  line-height: 21px;
  &.pad-24 {
    padding: 24px;
  }
  &.with-out-padding,
  &.pad0 {
    padding: 0;
  }
  .pad-24 {
    padding: 24px;
  }
  .with-out-padding,
  .pad0 {
    padding: 0;
  }
`;

export {
  DialogTitleStyled, DialogHeaderStyled, DialogBodyStyled, DialogFooterStyled, DialogStyled
};

export default DialogStyled;
