import { compose } from 'recompose';
import CreatePrn from './CreatePrn';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_PRN
} from './API';

const ComposedCreatePrn = compose(
  CREATE_PRN,
  withRequestTracker({
    query,
    mutation,
  }),
)(CreatePrn);

export default ComposedCreatePrn;
