import styled from 'styled-components';

const CustomSelectBtnStyle = styled.div`
  .dropdown {
    position: absolute;
    top: 34px;
    right: 9px;
  }
  .customselect-pop-up {
    .filter-dropdown-card {
      min-width: 140px;
      top: 100%;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 10px 1px;
      left: 0;
      .autocomplete {
        left: 0;
        box-shadow: none;
        overflow-y: auto;
        max-height: 120px;
        padding: 8px 0;

        > div {
          cursor: pointer;
          padding: 10px 16px;
          font-size: 16px;
          &:hover {
            background: #f0f5ff;
          }
        }
      }
      .create-btn {
        padding: 12px 16px;
      }
      button {
        width: 100%;
      }
    }
  }
`;

export default CustomSelectBtnStyle;
