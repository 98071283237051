import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { Icon, Menu } from '../../v4/components';

const propTypes = {
  headerTitle: PropTypes.string,
  footer: PropTypes.instanceOf(Object),
  children: PropTypes.instanceOf(Object),
  popoverIcon: PropTypes.instanceOf(Object),
  titleWithIcon: PropTypes.bool,
};

const defaultProps = {
  headerTitle: '',
  disabled: false,
  titleWithIcon: false,
};

class Popover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true
    }
  }

  onPopoverClick = (value)=> {
    this.setState({ show: value });
  }

  render() {
    const { children, headerTitle, popoverIcon, footer, disabled, titleWithIcon } = this.props;
    const { show } = this.state;

    return (
      <Fragment>
        <Menu show={show} label='' header={popoverIcon} ref={(ref) => (this.popover = ref)} disabled={disabled} titleWithIcon={titleWithIcon} onPopoverClick={this.onPopoverClick}>
          {headerTitle && (
            <div className='filter-label'>
              <span className=''>{headerTitle}</span>
              <Icon iconName='times' onClick={() => this.onPopoverClick(false)} />
            </div>
          )}
          <div className='filter-content'>{children}</div>
          {footer && <div className='popup-footer'>{footer}</div>}
        </Menu>
      </Fragment>
    );
  }
}

Popover.propTypes = propTypes;

Popover.defaultProps = defaultProps;

export default Popover;
