import React, { Component } from 'react';
import MultiDropDownStyled from '../MultiDropDown/MultiDropDownStyled';
import { Icon } from '../../../../../v4/components';

class RouteActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  setWrapperRef(node) {
    if (node) {
      this.wrapperRef = node;
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showMenu: false });
    }
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true });
  }

  // closeMenu() {
  //   this.setState({ showMenu: false }, () => {
  //     document.removeEventListener('click', this.closeMenu);
  //   });
  // }

  render() {
    return (
      <MultiDropDownStyled>
        <span className='dropdown' ref={node => this.setWrapperRef(node)}>
          <button type='button' className='borderless ellipse' onClick={this.showMenu}>
            <Icon className='simple-popup-actions' iconName='ellipsis-v' />
          </button>
          {this.state.showMenu ? (
            <ul className='menu'>
              <li onClick={() => this.props.showRenameModal(this.props.routeIndex)}>
                <Icon className='pencil' iconName='pencil' />
                <span>Rename</span>
              </li>
              <li onClick={() => this.props.showDeleteModal(this.props.routeIndex)}>
                <Icon className='trash' iconName='trash' />
                <span>Delete</span>
              </li>
            </ul>
          ) : null}
        </span>
      </MultiDropDownStyled>
    );
  }
}
export default RouteActions;
