import styled from 'styled-components';

const SectionStyled = styled.div`
  position: relative;
  box-shadow: rgba(39, 40, 51, 0.12) 0px 4px 8px 0px;
  border: 1px solid rgba(39, 40, 51, 0.12);
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  background-color: #fff;

  a {
    text-decoration: none;
    color: #272833;
    cursor: pointer;
  }
  &.user-detail {
    padding: 16px;
    .text-holder {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
      line-height: 10px;
      .sm-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 6px 12px;
        border-radius: 4px;
        font-weight: 400;
        min-width: 152px;
        border: 1px solid rgba(46, 90, 172, 0.4);
        color: #2e5aac;
        background-color: #eef2fa;
        &.sync {
          border: 1px solid #47c464;
          color: #287d3c;
          background-color: #edf9f0;
          .icon {
            fill: #287d3c;
          }
        }
        .icon {
          height: 9px;
          width: 9px;
          fill: #2e5aac;
          margin-right: 6px;
        }
        .lft {
          .icon {
            position: relative;
            top: -1px;
          }
        }
        .rt {
          font-weight: 500;
        }
      }
    }
  }
`;
export default SectionStyled;
