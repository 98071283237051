import styled from 'styled-components';

const ChannelStyled = styled.div`
  .config-wrap {
    padding: 48px 48px;
    .config-inner {
      margin-bottom: 56px;
    }

    .config-panel-content {
      .form-input {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .config-pannel-header {
      display: block;
      padding-bottom: 4px;
      font-size: 14px;
      color: #6b6c7e;
      border-bottom: 1px solid #cdced9;
      margin-bottom: 24px;
      label {
        font-weight: 400;
      }
    }

    .radio-list {
      min-width: 60px;
      cursor: pointer;
    }
    .catagory-wrap {
      display: flex;
      flex-wrap: wrap;

      .form-input {
        width: 556px;
        margin-bottom: 4px;
      }
    }
    .cross-btn {
      margin: 9px 16px;
      cursor: pointer;
    }
    .select-btn {
      margin-top: 8px;
    }
  }
`;

export default ChannelStyled;
