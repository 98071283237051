import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import WarehouseInventoryView from './View';
import PageHeader from '../../base/PageHeader';
import { getUser } from '../../../data/services';
import * as queryService from '../../base/query.service';
import { warehouseInventory, warehouseReport } from '../../common/DomainConfig';
import { filterConfig, breadCrumbConfig } from './config';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { getCurrentDay } from '../../../utils/date';
import { isError } from '../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { PAGINATION, USER_ROLE } from '../../../data/enums';
import { clone } from '../../../utils/objectProcessor';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';
import { formConfig as formConfigNew, crudRequest } from './config';
import TransferForm from './Form';
import WarehouseInventoryStyled from './WarehouseInventoryStyled';
import { DialogFormWrapper } from '../../common';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getWarehouseReportList: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class WarehouseInventory extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      dataDetails: [],
      display: {
        searchBox: false,
      },
      binList: [],
      queryParameters: {
        sort: {},
        date: { start: getCurrentDay(), end: getCurrentDay() },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
      filterMenu: this.filterConfigMenu || {},
      distributorList: [],
      activeAccordion: { parent: {}, child: {} },
      distributorId: this.userInfo.Distributor.length === 1 ? this.userInfo.Distributor[0].id : null,
      dialog: {
        type: '',
        element: {},
      },
      previousSearch: '',
      closeAccordion: false,
    };

    this.permission = { update: true, create: true, read: true };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    if (
      ADMIN_ROLE_GROUP.includes(this.userInfo.roleId) ||
      DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId) ||
      this.userInfo.roleId === USER_ROLE.TAS_TEAM_LEAD
    ) {
      this.getDistributorsList();
    } else {
      this.getFilterStateFromStorage();
      this.getBinList();
    }
  }
  componentWillUnmount() {
    this.setFilterStateInStorage();
  }

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          this.setState({ distributorList: response.data.distributors.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  handleDistributorSelect = distributorId => {
    const { dialog, queryParameters } = this.state;
    queryParameters.pagination = {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    };
    this.setState({ distributorId, queryParameters }, () => {
      this.loadTableData();
      this.getBinList();
    });
  };

  setFilterStateInStorage = () => {
    const { distributorId } = this.state;
    const {
      filterMenu,
      queryParameters: { filter, date, search },
    } = this.state;
    storeInLocalStorage('DISTRIBUTOR_ID', distributorId, DOMAIN.WAREHOUSE_INVENTORY);
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.WAREHOUSE_INVENTORY,
    );
  };
  resetCloseAccordion = () => {
    setTimeout(() => {
      this.setState({ closeAccordion: false });
    }, 500);
  };
  componentDidUpdate(prevProps, prevState) {
    const { queryParameters, previousSearch } = this.state;
    if (previousSearch !== queryParameters?.search) {
      this.setState({ closeAccordion: true, previousSearch: prevState?.queryParameters?.search }, () =>
        this.resetCloseAccordion(),
      );
    }
  }
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.WAREHOUSE_INVENTORY);
    const distributorId = fetchFromLocalStorage('DISTRIBUTOR_ID', DOMAIN.WAREHOUSE_INVENTORY);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        search: '',
      }),
    };

    if (!localData) {
      return this.loadTableData();
    }
    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          search: newFilterConfig.search,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
        distributorId,
      },
      () => {
        this.loadTableData();
      },
    );
  };
  getBinList = async () => {
    const { getWarehouseInventories, displayAlert } = this.props;
    const { queryParameters, distributorId } = this.state;
    if (distributorId) {
      getWarehouseInventories(
        {
          filter: {
            filters: [
              ...apiFilterProcessor(queryParameters.filter),
              {
                column: 'distributor_id',
                value: [distributorId?.toString() || ''],
              },
            ],
            dateRange: queryParameters.date,
            queryString: queryParameters.search,
          },
        },
        {
          handleSuccess: response => {
            this.setState({ binList: response?.data?.warehouseInventories?.rows ?? [] });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };
  loadTableData = () => {
    const { getWarehouseInventories, displayAlert } = this.props;
    const { queryParameters, data, distributorId } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    if (distributorId) {
      getWarehouseInventories(
        {
          offset,
          limit: queryParameters.pagination.limit,
          filter: {
            filters: [
              ...apiFilterProcessor(queryParameters.filter),
              {
                column: 'distributor_id',
                value: [distributorId.toString() || ''],
              },
            ],
            dateRange: queryParameters.date,
            queryString: queryParameters.search,
          },
        },
        {
          handleSuccess: response => {
            data.list = response?.data?.warehouseInventories?.rows || [];
            data.total = response?.data?.warehouseInventories?.count || 0;
            if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
            this.setState(data);
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  stateSetter = (stateField, value) => {
    this.setState({ [stateField]: value });
  };

  handleRowClick = (element, enableResetDataDetails = true) => {
    const { queryParameters, activeAccordion } = this.state;
    if (enableResetDataDetails) {
      this.setState({
        dataDetails: [],
      });
    }
    const { getWarehouseDetailsInventory, displayAlert } = this.props;
    getWarehouseDetailsInventory(
      {
        distributorId: element?.distributor_id,
        binLocationId: element?.bin_location_id,
        filter: {
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          this.setState(
            {
              dataDetails: response?.data?.getBinLocationStockDetail || [],
              activeAccordion: { ...activeAccordion, parent: element },
            },
            () => this.loadTableData(),
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  handleFormSubmit = async (type, dialogData) => {
    const { TransferSKUBatchToNewBinLocation, displayAlert } = this.props;
    const { activeAccordion, binList, dialog } = this.state;
    const { newBinIdSaleable = null, newBinIdDamaged = null, newBinIdExpired = null } = dialog?.element;
    if ([newBinIdSaleable, newBinIdDamaged, newBinIdExpired]?.every(a => a === null)) {
      displayAlert(ALERT_TYPE.WARNING, 'Select at least one new Bin location to transfer.');
      return;
    }
    const payload = crudRequest[EVENT_OPERATION?.UPDATE]?.objectMapper?.(dialogData);
    TransferSKUBatchToNewBinLocation(
      { ...payload },
      {
        handleSuccess: response => {
          const { parent, child } = activeAccordion || {};
          const newBinName = binList?.find?.(a => a?.bin_location_id === dialogData?.newBinId)?.bin_code;
          displayAlert(
            ALERT_TYPE.SUCCESS,
            response?.data?.transferSKUBatchToNewBinLocation ??
              `${child?.sku} Item of batch ${
                child?.batch_name
              } is successfully transferred  ${`from Bin ${parent?.bin_code?.toLowerCase()}`} to new BINs.`,
          );
          this.setState(() => this.handleRowClick(parent, false));
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  handleTransferButton = data => {
    const { dialog, activeAccordion } = this.state;
    dialog.element = {
      ...dialog?.element,
      sku: data?.sku,
      saleable: data?.closing_sellable,
      damaged: data?.closing_damages,
      expired: data?.closing_expiry,
      batchName: data?.batch_name,
      skuBatchId: data?.sku_batch_id,
      fromBinLocationId: data?.bin_location_id,
      newBinIdSaleable: null,
      newBinIdDamaged: null,
      newBinIdExpired: null,
    };
    dialog.type = EVENT_OPERATION.UPDATE;
    this.setState({ dialog, activeAccordion: { ...activeAccordion, child: { ...data } } });
  };
  handleDropDown = (value, params) => {
    const { dialog } = this.state;
    const { element } = dialog;
    element[params] = value ?? null;
    this.setState({
      dialog: {
        ...dialog,
        element: { ...dialog?.element, element },
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: {},
      },
    });
  };
  render() {
    const {
      data,
      dataDetails,
      queryParameters,
      display,
      filter,
      distributorList,
      dialog,
      filterMenu,
      activeAccordion,
      distributorId,
      binList,
      closeAccordion,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const { element, type } = dialog;
    const { newBinIdSaleable = null, newBinIdDamaged = null, newBinIdExpired = null } = dialog?.element;
    const checkNullOnBIN = [newBinIdSaleable, newBinIdDamaged, newBinIdExpired]?.every(a => a === null);
    return (
      <WarehouseInventoryStyled>
        {type && (
          <DialogFormWrapper
            formConfig={formConfigNew[type]}
            dialogElement={element}
            onDialogSubmit={this.handleFormSubmit}
            onDialogCancel={this.resetDialog}
            type={type}
            removeOnDialogCancel={checkNullOnBIN}
            renderDialog={({ refsObj, dialogData, enableErrorDisplay, handleDropDownChange, handleInputChange }) => (
              <>
                {type === EVENT_OPERATION.UPDATE && (
                  <TransferForm
                    data={dialogData}
                    element={dialog?.element}
                    binList={binList}
                    refsObj={refsObj}
                    activeAccordion={activeAccordion}
                    loading={serverResponseWaiting}
                    enableErrorDisplay={enableErrorDisplay}
                    handleInputChange={handleInputChange}
                    handleDropDownChange={handleDropDownChange}
                    handleDropDown={this.handleDropDown}
                  />
                )}
              </>
            )}
          />
        )}
        <>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title: warehouseInventory.title,
                  filter: false,
                  download: false,
                  date: false,
                  search: true,
                }}
                filter={{
                  date: queryParameters.date,
                  metaData: filter,
                  menuList: filterMenu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                downloadConfig={{
                  domain: warehouseReport?.domain,
                }}
                queryParameters={queryParameters}
                controlDisplay={this.controlDisplay}
                resetFilter={this.basePaginationService.resetFilter}
                onFilterChange={this.basePaginationService.handleFilterChange}
                handleDownloadClick={this.handleDownloadClick}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              />
            </PanelStyled>
          </div>
          <div className={`section-content section-select table-present`}>
            <WarehouseInventoryView
              data={data}
              inputData={distributorId}
              closeAccordion={closeAccordion}
              handleTransferButton={this.handleTransferButton}
              breadCrumb={breadCrumbConfig}
              loading={serverResponseWaiting}
              pagination={queryParameters.pagination}
              onPageChange={this.basePaginationService.onPageSelect}
              distributorList={distributorList}
              onHeaderClick={this.basePaginationService.handleTableSorting}
              onRowClick={this.handleRowClick}
              ledgerDetail={dataDetails}
              onDropDownChange={this.handleDistributorSelect}
            />
          </div>
        </>
      </WarehouseInventoryStyled>
    );
  }
}

WarehouseInventory.propTypes = propTypes;

export default withAlert()(WarehouseInventory);
