import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ICOGroupDetailsView from './View';
import { CATALOG_HIERARCHY } from '../../../common/DomainConfig';
import { productHierarchyCountMapper, crudRequestConfig } from './config';
import { handleFormSubmit, responseInterpreter } from '../../../../utils/crudResponseProcessor';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import EVENT_OPERATION from '../../../../data/enums/EventOperation';
import { MAX_LIMIT_OFFSET_FILTER } from '../../../../data/enums/filter';
import withAlert from '../../../../utils/composition/withAlert';
import history from '../../../../utils/history';
import { getPermissionForICOGroup } from '../../../base/permission';
import ICODetailStyled from './ICODetailStyled';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getCatalogDetails: PropTypes.func.isRequired,
  getDistributorAssortmentSkus: PropTypes.func.isRequired,
  toggleSkuInICOGroupDetail: PropTypes.func.isRequired,
  getAssortmentCountByProductHierarchy: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class ICOGroupDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const parts = history.location.pathname.split('/');
    const icoGroupId = Number(parts[parts.length - 1]) || '';
    this.state = {
      productGroups: [],
      productHierarchyAssortmentCount: productHierarchyCountMapper({}),
      brands: [],
      serverSKUFamilies: [],
      skuFamilies: [],
      selectedSkus: [],
      brandId: '',
      productGroupId: '',
      searchText: '',
      checkedSkuFamilies: [],
      icoGroupId: icoGroupId,
      alreadySelectedSku: [],
      activeSku:[],
    };
    const serverCall = {
      [EVENT_OPERATION.UPDATE_STATUS]: props.toggleSkuInICOGroupDetail,
    };
    this.onCRUDSuccess = responseInterpreter(this.onMutationSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.handleAPIFailure, crudRequestConfig, serverCall);
    this.permission = getPermissionForICOGroup();
  }

  componentDidMount() {
    this.fetchCatalogDetails({
      catalogId: CATALOG_HIERARCHY.PRODUCT_GROUP,
      callBack: this.setProductGroups,
    });
    this.fetchAssortmentCountByProductHierarchy();
    this.getICOGroupsStatus()
    // this.setState({
    //   icoGroupId,
    // });
  }

  setCheckedSkuFamilies = skuFamily => {
    const { checkedSkuFamilies } = this.state;
    if (!checkedSkuFamilies.includes(skuFamily)) {
      this.setState({
        checkedSkuFamilies: [...checkedSkuFamilies, skuFamily],
      });
    } else {
      this.setState({
        checkedSkuFamilies: checkedSkuFamilies.filter(d => d !== skuFamily),
      });
    }
  };

  fetchCatalogDetails = ({ catalogId, parentCatalogDetailId, includeSku, callBack }) => {
    const { getCatalogDetails } = this.props;

    getCatalogDetails(
      {
        ...MAX_LIMIT_OFFSET_FILTER,
        catalogId,
        parentCatalogDetailId,
        includeSku,
      },
      {
        handleSuccess: response =>
          callBack(response.data.catalogDetails ? response.data.catalogDetails.rows : [], parentCatalogDetailId),
        handleError: err => {
          callBack([], parentCatalogDetailId);
          this.handleAPIFailure(err);
        },
      },
    );
  };

  fetchAssortmentCountByProductHierarchy = () => {
    const { getAssortmentCountByProductHierarchy } = this.props;
    const { icoGroupId } = this.state;
    getAssortmentCountByProductHierarchy(
      { icoGroupId },
      {
        handleSuccess: response => {
          const { getICOProductHierarchyCount = {} } = response.data;
          this.setState({
            productHierarchyAssortmentCount: productHierarchyCountMapper(getICOProductHierarchyCount || {}),
          });
        },
      },
    );
  };

  fetchDistributorAssortmentSkus = brandId => {
    const { getDistributorAssortmentSkus } = this.props;
    const { icoGroupId } = this.state;
    getDistributorAssortmentSkus(
      { icoGroupId, brandId },
      {
        handleSuccess: response => {
          const { icoGroupDetailSkus = {} } = response.data;
          this.setState({
            selectedSkus: [...(icoGroupDetailSkus ? icoGroupDetailSkus.rows : [])],
          });
        },
        handleError: err => {
          this.setState({ selectedSkus: [] });
          console.log(err);
        },
      },
    );
  };

  getBrands = productGroupId => {
    this.fetchCatalogDetails({
      catalogId: CATALOG_HIERARCHY.BRAND,
      parentCatalogDetailId: productGroupId,
      callBack: this.setBrands,
    });
  };

  getSKUFamilies = brandId => {
    this.fetchDistributorAssortmentSkus(brandId);
    this.fetchCatalogDetails({
      catalogId: CATALOG_HIERARCHY.SKU_FAMILY,
      parentCatalogDetailId: brandId,
      includeSku: true,
      callBack: this.setSKUFamilies,
    });
  };

   getICOGroupsStatus = () => {
    const {icoGroupId}=this.state;
    const { getIcoGroupSkuStatus } = this.props;
    getIcoGroupSkuStatus(
      {
        icoGroupId: icoGroupId || null,
      },
      {
        handleSuccess: (response) => {
          const result =
            response?.data?.getIcoGroupSkuStatus?.rows?.filter((da)=>da.sku_status==='disable')?.map((re) => ({
              sku_id: re.sku_id,
              sku_family_id: re.sku_family_id,
            })) || [];
            this.setState({alreadySelectedSku:result});
          const res =
            response?.data?.getIcoGroupSkuStatus?.rows?.filter((da)=>da.sku_status==='active')?.map((re) => ({
              sku_id: re.sku_id,
              sku_family_id: re.sku_family_id,
            })) || [];
            this.setState({activeSku:res});
        },
        handleError: (error) => {
          this.handleAPIFailure(error);
        },
      },
    );
  };

  setProductGroups = (productGroups = []) => {
    this.setState({ productGroups });
  };

  setBrands = (brands, productGroupId) => {
    this.setState({
      brands,
      productGroupId,
      brandId: '',
      skuFamilies: [],
      serverSKUFamilies: [],
      searchText: '',
    });
  };

  setSKUFamilies = (skuFamilies, brandId) => {
    const filteredSKUFamilies = skuFamilies.filter(pp => pp.skus && pp.skus.length > 0);
    this.setState({
      skuFamilies: filteredSKUFamilies,
      brandId,
      serverSKUFamilies: filteredSKUFamilies,
      searchText: '',
    });
  };

  onMutationSuccess = (type, response, payload) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);

    this.setState(
      {
        productHierarchyAssortmentCount: productHierarchyCountMapper(response.count || {}),
      },
      () => this.handleSwitchChange(payload.status, payload.skuIdList),
    );
  };

  handleSwitchChange = (status, skuIdList) => {
    const { selectedSkus } = this.state;
    if (status) {
      const skusSet = new Set([...selectedSkus, ...skuIdList]);
      this.setState({
        selectedSkus: [...(Array.from(skusSet) || [])],
      });
    } else {
      this.setState({ selectedSkus: selectedSkus.filter(id => !skuIdList.includes(id)) });
    }
  };

  handleSkuSubmit = (status, ids) => {
    const { icoGroupId } = this.state;
    this.onFormSubmit(EVENT_OPERATION.UPDATE_STATUS, {
      skuIdList: [...ids],
      icoGroupId,
      status,
    });
  };

  handleAPIFailure = err => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, err);
  };

  handleSearchInput = text => {
    const { serverSKUFamilies } = this.state;
    const regex = new RegExp(text, 'i');
    const bySkus = serverSKUFamilies.filter(i => i.skus.filter(s => s.title.search(regex) > -1).length > 0);
    const bySKUFamilies = serverSKUFamilies.filter(p => p.title.search(regex) > -1);
    const searchSet = new Set([...bySKUFamilies, ...bySkus]);
    this.setState({ skuFamilies: [...(Array.from(searchSet) || [])], searchText: text });
  };

  render() {
    const {
      productGroups,
      brands,
      brandId,
      productGroupId,
      skuFamilies,
      selectedSkus,
      productHierarchyAssortmentCount,
      searchText,
      checkedSkuFamilies,
      alreadySelectedSku,
      activeSku,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <div className="no-border">
        <ICOGroupDetailsView
          brands={brands}
          brandId={brandId}
          searchText={searchText}
          selectedSkus={selectedSkus}
          skuFamilies={skuFamilies}
          productGroups={productGroups}
          productGroupId={productGroupId}
          loading={serverResponseWaiting}
          onSwitchChange={this.handleSkuSubmit}
          onSearchInputChange={this.handleSearchInput}
          onBrandLineClick={this.getSKUFamilies}
          onProductGroupLineClick={this.getBrands}
          assortmentCounts={productHierarchyAssortmentCount}
          checkedSkuFamilies={checkedSkuFamilies}
          setCheckedSkuFamilies={this.setCheckedSkuFamilies}
          permission={this.permission}
          alreadySelectedSku={alreadySelectedSku}
        />
      </div>
    );
  }
}

ICOGroupDetail.propTypes = propTypes;

ICOGroupDetail.defaultProps = defaultProps;

export default withAlert()(ICOGroupDetail);
