import { compose } from 'recompose';
import SbdTarget from './SbdTarget';
import { query, mutation } from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { TOGGLE_STATE } from '../../../common/GqlAPI';
const ComposedTargets = compose(
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(SbdTarget);

export default ComposedTargets;
