import { compose } from 'recompose';
import Town from './Town';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  CREATE_TOWN, UPDATE_TOWN, query, mutation
} from './API';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const ComposedTown = compose(
  CREATE_TOWN,
  UPDATE_TOWN,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Town);

export default ComposedTown;
