import { TARGET_DISTRIBUTION, salesForce as salesForceRoute } from '../../../../data/enums/Route';
import { getCurrentMonth, getCurrentYear } from '../../../../utils/date';

export const breadCrumb = [
  salesForceRoute,
  {
    id: 1,
    title: 'Target Distribution',
    path: `/${TARGET_DISTRIBUTION}`,
    active: true,
  },
];

export const AVERAGE_BASE = {
  SAME_QUARTER: 'Same Quarter',
  PREVIOUS_QUARTER: 'Previous Quarter',
};

export const getZoneDataPayloadIn = list => {
  const dataList = list?.map(data => {
    const { id, title } = data;
    const payload = {
      id,
      targetContribution: null,
      territory: title,
      zone: { id, title },
    };
    return payload;
  });
  return dataList;
};
export const getListData = (data, principalList) => {
  const dataList = [
    ...(principalList?.length > 1
      ? [{ id: 1, title: 'Principal', value: findTitleFromArr(principalList, data?.principalId, 'title') ?? '' }]
      : []),
    { id: 2, title: 'Month', value: data?.monthName ?? '' },
    { id: 3, title: 'National Target', value: data?.target ?? '-' },
    ...(dimensionList?.length > 1
      ? [{ id: 4, title: 'Dimension', value: findTitleFromArr(dimensionList, data?.dimensionId, 'title') ?? '' }]
      : []),
    { id: 5, title: 'Average Base (Last Year)', value: data?.averageBase ?? '' },
  ];
  return dataList;
};
export const getPayloadIn = result => {
  const resultFirstObj = result?.automated_sales_target_details?.[0];
  const locationEnabled = resultFirstObj?.base_metric?.dimension === 'location'; // value of location dimension
  const salesDetails = result?.automated_sales_target_details?.map(a => ({
    id: locationEnabled ? a?.territory?.id : a?.channel?.id,
    targetContribution: a?.outlet_target,
    channelId: a?.channel?.id,
    channel: a?.channel?.title,
    territory: a?.territory?.title,
    territoryId: a?.territory?.id,
  }));
  const formattedDetail = {
    duration: {
      year: result?.year,
      month: result?.month,
    },
    target: result?.national_target,
    averageBase:
      result?.average_base?.is_same_quarter === true ? AVERAGE_BASE.SAME_QUARTER : AVERAGE_BASE.PREVIOUS_QUARTER,
    monthName: result?.monthName,
    id: result?.id,
    dimensionId: locationEnabled ? 2 : 1, // id of location dimension
    principalId: resultFirstObj?.principalId,
    ...(locationEnabled || { list: getSummedResult(salesDetails, 'channelId', locationEnabled) }),
    ...(locationEnabled && { zoneList: getSummedResult(salesDetails, 'territoryId', locationEnabled) }),
  };
  return formattedDetail;
};
export const getCalculatedTargetPayloadOut = ({ details, create, firstTime, locationEnabled, principalList }) => {
  const { duration, target: national_target, averageBase, dimensionId, principalId, zoneList } = details ?? {};
  const { month, year } = duration ?? {};
  const formattedInput = {
    month,
    year,
    national_target,
    average_base: {
      is_same_quarter: averageBase === AVERAGE_BASE.SAME_QUARTER ?? false,
    },
    create_target: create && firstTime,
    dimension: findTitleFromArr(dimensionList, dimensionId, 'value'),
    principal: findTitleFromArr(principalList, principalId, 'title'),
    principal_id: principalId,
    ...(locationEnabled && {
      dimension_detail: {
        type: 'territory',
        detail: zoneList?.map(({ id, targetContribution }) => ({ id, target: targetContribution ?? 0 })),
      },
    }),
  };
  return formattedInput;
};
export const updatePayloadOut = (details, locationEnabled, principalList, create) => {
  const formattedInput = {
    month: details?.duration?.month,
    year: details?.duration?.year,
    national_target: Number(details?.target),
    average_base: {
      is_same_quarter: details?.averageBase === AVERAGE_BASE.SAME_QUARTER || false,
    },
    id: details?.id,
    create_target: create,
    dimension: findTitleFromArr(dimensionList, details?.dimensionId, 'value'),
    principal: findTitleFromArr(principalList, details?.principalId, 'title'),
    principal_id: details?.principalId,
    ...(locationEnabled && {
      dimension_detail: {
        type: 'territory',
        detail: details?.zoneList?.map(({ territoryId, targetContribution }) => ({
          id: territoryId,
          target: targetContribution ?? 0,
        })),
      },
    }),
  };
  return formattedInput;
};
export const getDownloadPayloadOut = (domain, id) => {
  const formattedInput = {
    domain,
    reportType: 'csv',
    filter: {
      filters: [
        {
          column: 'id',
          value: id?.toString(),
        },
      ],
    },
  };
  return formattedInput;
};
export const findTitleFromArr = (list, param, label) => list?.find(a => a?.id === param)?.[label];
export const getSummedResult = (list, param, locationEnabled) => {
  const summedArray = Object.values(
    list?.reduce((acc, item) => {
      const key = item[param];

      if (acc[key]) {
        acc[key].targetContribution += item.targetContribution;
      } else {
        acc[key] = { ...item };
      }

      return acc;
    }, {}),
  );
  const updatedList = summedArray?.map(a => ({ ...a, id: locationEnabled ? a?.territoryId : a?.channelId }));
  return updatedList;
};
export const dimensionList = [
  { id: 1, title: 'Channels', value: 'channel' },
  { id: 2, title: 'Location', value: 'location' },
];
export const MSG = {
  AVG_BASE: 'Select average base.',
  NO_EQUAL: 'National Target and sum of individual zone wise target is not equal.',
  UPDATE_SUCCESS: 'Target Distribution Updated Successfully.',
  CREATE_SUCCESS: 'Target Distribution Created Successfully.',
  DOWNLOAD_SUCCESS: 'Successfully downloaded file.',
  DOWNLOAD_FAILED: 'Cannot download file.',
};
export const pg = false;
export const ZONE_ID = 2;
export const initialDetails = {
  duration: {
    year: getCurrentYear(),
    month: getCurrentMonth() + 1,
  },
  targetContribution: null,
  averageBase: AVERAGE_BASE.SAME_QUARTER,
  monthName: '',
  id: null,
  list: [],
  zoneList: [],
  principalId: null,
  dimensionId: null,
};
