import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { configuration as configurationRoute } from '../../../data/enums/Route';

const breadCrumb = [configurationRoute];

const Title = 'Location Hierarchy';

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
    title: element.title || '',
  }),
  validationField: ['title', 'parentId'],
  validationRequired: true,
};

const filter = getFilterConfig([FILTER.STATUS]);
const getInput = (data, type = EVENT_OPERATION.CREATE) => ({
  title: data.title,
  parentId: data.parentId,
});

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({ input: getInput(data, EVENT_OPERATION.CREATE) }),
    responseName: 'createLocationHierarchy',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      input: getInput(data, EVENT_OPERATION.UPDATE),
    }),
    responseName: 'editLocationHierarchy',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
    }),
    responseName: 'deleteLocationHierarchies',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
    }),
    responseName: 'deleteLocationHierarchies',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

const crudBulkDeleteRequestConfig = {
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (element) => ({
      ids: [...element.data],
      type: element.type,
      active: false,
    }),
    responseName: 'deleteLocationHierarchies',
    message: `${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export {
  crudRequest,
  breadCrumb as breadCrumbConfig,
  formConfig,
  filter as filterConfig,
  Title as title,
  crudBulkDeleteRequestConfig,
};
