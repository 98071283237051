import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../../v4/components';
import { getTodaysDate } from '../../../../utils/date';

const propTypes = {
  routeList: PropTypes.instanceOf(Array),
  user: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
  onEditIconClick: PropTypes.func,
  date: PropTypes.string.isRequired,
};

const defaultProps = {
  routeList: [],
  user: {
    id: null,
    title: '',
  },
  onEditIconClick: () => null,
};

const getRouteViewClassName = (route) => (route.creationIdentifier
  ? `roster-route-view status_${route.visitFrequencyId}` : 'roster-route-view status_once');

const RoutesView = (
  {
    callRosterPermission,
    date,
    routeList = [],
    user,
    onEditIconClick,
  },
) => (
  <Fragment>
    {
      routeList.map((route) => (
        <div className={getRouteViewClassName(route)}>
          <div>
            <div>
              <span className='name'>{route.routeTitle}</span>
              {routeList.length > 1 ? null : (
                <span>
                  {`${route.outletCount} Outlets`}
                </span>
              )}
            </div>
            {(date >= getTodaysDate()&&(callRosterPermission.update||callRosterPermission.delete)) ? (
              <div className='action-btn'>
                <div>
                  <button className='dot-wrap'>
                    <span className='dot' />
                    <span className='dot' />
                    <span className='dot' />
                  </button>
                  <div className='actions'>
                    {callRosterPermission.update&&<div onClick={() => onEditIconClick('edit', {
                      ...route,
                      selectedRouteList: routeList,
                      date,
                      user,
                    })}
                    >
                      <Icon iconName='pencil' />
                      <span className='text'>Edit</span>
                    </div>}
                    {callRosterPermission.delete&&<div onClick={() => onEditIconClick('delete', {
                      ...route,
                      selectedRouteList: routeList,
                      date,
                      user,
                    })}
                    >
                      <Icon iconName='trash' />
                      <span className='text'>Delete</span>
                    </div>}
                  </div>
                </div>
              </div>
            ) : <div />}
          </div>
        </div>
      ))
    }
  </Fragment>
);

RoutesView.propTypes = propTypes;

RoutesView.defaultProps = defaultProps;

export default RoutesView;
