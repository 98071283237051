import React from 'react';
import FormContentStyled from '../../../components/global-css/FormContentStyled';

export const FormContentComponent = ({ title, children }) => (
  <FormContentStyled>
    <h3>{title}</h3>
    <div className='form-input-wrap'>{children}</div>
  </FormContentStyled>
);

export default FormContentComponent;
