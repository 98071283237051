import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import RequestBulkSelect from '../../../components/RequestBulkSelect/RequestBulkSelect';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  handleRequest: PropTypes.func,
  onIconClick: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  onIconClickRow: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
};

const defaultProps = {
  onPageChange: () => null,
  permission: PERMISSION_OBJ,
  handleRequest: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    onIconClickRow,
    onIconClick,
    handleRequest,
    permission,
    handleRowClick,
    handleSliderOpen,
  } = props;
  return (
    <Fragment>
      <RequestBulkSelect
        data={data.list}
        handleRequest={handleRequest}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap'>
            <table>
              {TableHeader({
                data,
                dataListLength: data.list.length,
                checkedListLength: state.checkedList.length,
                primaryCheckboxHandler,
                permission,
                dataList: data.list,
              })}
              <tbody>
                {data.list.map((geolocation) =>
                  TableBody({
                    data: geolocation,
                    checkedList: state.checkedList,
                    secondaryCheckboxHandler,
                    onIconClickRow,
                    onIconClick,
                    handleRowClick,
                    handleSliderOpen,
                    permission,
                    withOutIcon: true,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const GeolocationViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

const GeolocationViewWithImageSlider = withImageSlider(GeolocationViewWithErrorAndLoading);

export default GeolocationViewWithImageSlider;
