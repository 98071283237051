import React from 'react';
import {
  AutoComplete, CustomSelect, Input
} from '../../../../components';
import { getTodaysDate } from '../../../../utils/date';
import { Button, SingleDatePicker } from '../../../../v4/components';
import {
  CASE_TYPE, LAST_INVOICE_DAYS, LAST_UNSUCESSFULL_CALLS, NO_OF_DAYS
} from '../config';

const SelectFilter = (props) => {
  const { menu, onDropDownChange, onDateChange, outletFilter, onButtonReset, onButtonApply,handleAutoComplete,enableErrorDisplay,refsObj,onInputChange,onTASUserSelectChange } =props;
  return (
    <div className='filters'>
      <div className='title'>FILTERS</div>
      <div className='select-section'>
        <CustomSelect
          labelContent='Case Type'
          name='cast_type'
          placeholder='Select'
          className='custom-select'
          getOptionValue={({ value }) => value}
          getOptionLabel={({ title }) => title}
          options={CASE_TYPE}
          value={CASE_TYPE.filter((type)=>type.value===outletFilter.cast_type)}
          onChange={(e) => onDropDownChange(e.value, ['cast_type'])}
        />
        {outletFilter.cast_type==='COVERAGE'&&(
          <>
            <CustomSelect
              labelContent='TAS User'
              name='dse_id'
              placeholder='Select'
              className='custom-select'
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              options={menu.tasList}
              value={menu.tasList.filter((list)=>list.id===outletFilter.dse_id)}
              onChange={(e) => onTASUserSelectChange(e.id, ['dse_id'])}
            />
            <CustomSelect
              labelContent='Route'
              name='route_id'
              placeholder='Select'
              className='custom-select'
              isClearable
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              options={menu.routeList}
              value={menu.routeList.filter((list)=>list.id===outletFilter.route_id)}
              onChange={(e) => onDropDownChange(e?.id, ['route_id'])}
            />
          </>)}
        {outletFilter.cast_type==='ULPO'&&(
          <>
            <div className='auto-complete'>
              <AutoComplete
                name='distributor_id'
                labelContent='Sub D'
                dropDownList={menu.distributorList}
                placeholder='Select'
                selectedDropDowns={outletFilter.distributor_id}
                onDropDownSelection={handleAutoComplete}
              />
            </div>
            <CustomSelect
              labelContent='No. of Days'
              name='no_of_days'
              placeholder='Select'
              className='custom-select'
              isClearable
              getOptionValue={({ value }) => value}
              getOptionLabel={({ title }) => title}
              options={NO_OF_DAYS}
              value={NO_OF_DAYS.filter((list)=>list.value===outletFilter.no_of_days)}
              onChange={(e) => onDropDownChange(e?.value, ['no_of_days'])}
            />
            <Input
              name='line_count'
              type='number'
              placeholder='Line count'
              labelContent='Line count'
              value={outletFilter.line_count}
              onChange={(event) => onInputChange(event.formattedValue, 'line_count')}
            />
          </>
        )}
        {(outletFilter.cast_type==='ABSENTDSE' || outletFilter.cast_type==='UNSUCCESSFULCALL'|| outletFilter.cast_type==='NOTINVOICED')&&(
          <>
            {outletFilter.cast_type!=='NOTINVOICED' &&<div className='single-date-picker'>
              <label>Date</label>
              <SingleDatePicker
                name='current_date'
                date={outletFilter.current_date || getTodaysDate()}
                onChange={(name, date) => onDateChange(date, 'outlet')}
              />
            </div>}
            <div className='auto-complete'>
              <AutoComplete
                name='distributor_id'
                labelContent='Sub D'
                dropDownList={menu.distributorList}
                placeholder='Select'
                selectedDropDowns={outletFilter.distributor_id}
                onDropDownSelection={handleAutoComplete}
              />
            </div>
            <AutoComplete
              labelContent='DSE'
              name='dse_id'
              dropDownList={menu.dseList}
              placeholder='Select'
              selectedDropDowns={outletFilter.dse_id}
              onDropDownSelection={handleAutoComplete}
            />
            <AutoComplete
              labelContent='Route'
              name='route_id'
              dropDownList={menu.routeList.sort((a, b) => a.title.trim().toLowerCase() > b.title.trim().toLowerCase()? 1 : -1)}
              placeholder='Select'
              selectedDropDowns={outletFilter.route_id}
              onDropDownSelection={handleAutoComplete}
            />
          </>
        )}
        {outletFilter.cast_type==='UNSUCCESSFULCALL'&&(
          <>
            <CustomSelect
              labelContent='Last Unsuccessful'
              name='unsuccesful_days'
              placeholder='Select'
              className='custom-select'
              isClearable
              getOptionValue={({ value }) => value}
              getOptionLabel={({ title }) => title}
              options={LAST_UNSUCESSFULL_CALLS}
              value={LAST_UNSUCESSFULL_CALLS.filter((list)=>list.value===outletFilter.unsuccesful_days)}
              onChange={(e) => onDropDownChange(e?.value, ['unsuccesful_days'])}
            />
          </>
        )}
        {outletFilter.cast_type==='NOTINVOICED' && <CustomSelect
          labelContent='Last Invoiced Days'
          name='invoiced_days'
          placeholder='Select'
          className='custom-select'
          isClearable
          getOptionValue={({ value }) => value}
          getOptionLabel={({ title }) => title}
          options={LAST_INVOICE_DAYS}
          value={LAST_INVOICE_DAYS.filter((list)=>list.value===outletFilter.invoiced_days)}
          onChange={(e) => onDropDownChange(e?.value, ['invoiced_days'])}
        />}
      </div>
      <div className='button-section'>
        <Button secondary small onClick={() => onButtonReset()}>
          <span>Reset</span>
        </Button>
        <Button primary small onClick={() => onButtonApply()}>
          <span>Apply</span>
        </Button>
      </div>
    </div>
  );
};

export default SelectFilter;
