import styled from 'styled-components';

const SpiltPayStyled = styled.div`
  .modal-inner {
    max-width: 800px;
    &.read {
      .form-wrap {
        .disable {
          pointer-events: none;
        }
      }
    }
    &.discarded {
      max-width: 500px;
    }
    .evolve-dialog__body {
      .total-amount-section {
        display: flex;
        padding-bottom: 24px;
        border-bottom: 1px solid #e7e7ed;
        justify-content: space-between;
        .total {
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
        }
        .amount-value {
          font-size: 16px;
          line-height: 21px;
          font-weight: bold;
        }
      }
      .form-wrap {
        max-height: 410px;
        padding: 24px;
        overflow: auto;
        .type-section {
          border-bottom: 1px solid #e7e7ed;
          button {
            margin-bottom: 24px;
          }
        }
        .ledger-amount-wrap {
          .form-input {
            margin-bottom: 0;
          }
          > span {
            width: 310px;
            margin-bottom: 10px;
          }
        }
        .form-input {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 24px;
          label {
            flex: 1;
            line-height: 20px;
            font-weight: 500;
          }
          span {
            font-size: 16px;
            flex: 1;
            text-align: right;
            font-weight: normal;
          }
          .form-error {
            display: block;
            flex: auto;
          }
          .error-message {
            color: #da1414;
            font-weight: 500;
            font-size: 14px;
          }
        }
        .single-date-picker {
          margin: 0;
        }
        .cross-icon {
          margin: 30px 0;
        }
        .from-ledger {
          .form-select-input {
            margin-bottom: 6px;
          }
          .outstanding-due {
            font-size: 12px;
            line-height: 14px;
            /* .due-amt{
            color:#DA1414
            } */
          }
        }
      }
    }
  }
`;
export default SpiltPayStyled;
