import { compose } from 'recompose';
import NSR from './NSR';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedNSR = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(NSR);

export default ComposedNSR;
