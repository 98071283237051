import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import { BreadCrumb ,Button} from '../../../../../v4/components';
import { getUser } from '../../../../../data/services';
import withAlert from '../../../../../utils/composition/withAlert';
import { breadCrumbConfig, formConfig as form } from './config';
import { RECEIPTS } from '../../../../../data/enums/Route';
import { refValidator, refGenerator } from '../../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import View from './View';
import { dropdownChange } from '../../../../../utils/formHandlers';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import ReceiptWrapper from '../ReceiptWrapper';
import { OFFSET } from '../../../../../data/enums/GeneralConstants';
import history from '../../../../../utils/history';

const propTypes = {
  createPayment: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getOutlets: PropTypes.func.isRequired,
  getRouteList: PropTypes.func.isRequired,
  getRoles: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getBankList: PropTypes.func.isRequired,
  getDistributorList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class Create extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.distributorExist = this.userInfo.Distributor.length > 0;
    const distributorId = this.distributorExist ? this.userInfo.Distributor[0].id : null;
    this.state = {
      data: form.mapper({ Distributor: { id: distributorId } }),
      enableErrorDisplay: false,
      menu: {
        collectionType: [],
        routeList: [],
        outletList: [],
        roleList: [],
        userList: [],
        bankList: [],
        distributorList: [],
      },
      crudMode: EVENT_OPERATION.CREATE,
    };

    this.formReference = refGenerator(form.validationField);
  }

  componentDidMount() {
    this.loadDataForDropDown();
  }

  fetchOutlets = () => {
    const { getOutlets } = this.props;
    const { data } = this.state;
    getOutlets(
      {
        offset: OFFSET,
        filter: {
          filters: [
            {
              column: 'route_id',
              value: [data.routeId.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.outletList = [...((res.data.retailOutlets && (res.data.retailOutlets.rows || [])) || [])];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  loadRouteList = () => {
    const { getRouteList } = this.props;

    getRouteList(
      {
        offset: 0,
      },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.routeList = [...((res.data.routes && (res.data.routes.rows || [])) || [])];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  loadRoleList = () => {
    const { getRoles } = this.props;

    getRoles(
      {
        offset: 0,
      },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.roleList = [...((res.data.roles && (res.data.roles.rows || [])) || [])];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  fetchUsers = () => {
    const { getUsers } = this.props;
    const { data } = this.state;
    getUsers(
      {
        offset: OFFSET,
        filter: {
          filters: [
            {
              column: 'role_id',
              value: [data.Role.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.userList = [...((res.data.users && (res.data.users.rows || [])) || [])];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  loadDistributorList = () => {
    const { getDistributorList } = this.props;
    const { data, menu } = this.state;
    const filteredTownOutletList = menu.outletList.filter(outlet => outlet.id === data.outletId);

    getDistributorList(
      {
        offset: 0,
        filter: {
          filters: [
            {
              column: 'town_id',
              value: [filteredTownOutletList.map(town => town.townId).toString()],
            },
          ],
        },
      },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.distributorList = [...((res.data.distributors && (res.data.distributors.rows || [])) || [])];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  loadDataForDropDown = () => {
    const { menu } = this.state;
    const collectionListType = [
      { title: 'CASH', id: 1, value: 'CASH' },
      { title: 'CHEQUE', id: 2, value: 'CHEQUE' },
      { title: 'POST DATED CHEQUE', id: 3, value: 'POST_DATED_CHEQUE' },
    ];
    menu.collectionType = collectionListType;
    this.setState({ menu });
    this.loadRouteList();
    this.loadRoleList();
    this.fetchBank();
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    if (firstParam) {
      if (paramList.length > 1) {
        paramList.reduce((acc, value, index, list) => {
          if (index === list.length - 1) {
            return (acc[value] = event.formattedValue);
          }
          return acc[value];
        }, data);
      } else {
        data[firstParam][event.target.name] = event.formattedValue;
      }
    } else {
      data[event.target.name] = event.formattedValue;
    }
    this.setState({ data });
  };

  handleDropDownChange = (value, parameterRef = [], name, callBack = () => null) => {
    const { data } = this.state;

    const updatedData = dropdownChange(data, parameterRef, value);
    switch (name) {
      case 'route':
        this.setState({ data: updatedData }, () => this.fetchOutlets());
        break;
      case 'outlet':
        this.setState({ data: updatedData }, () => this.loadDistributorList());
        break;
      case 'role':
        this.setState({ data: updatedData }, () => this.fetchUsers());
        break;
      case 'bank':
        this.setState({ data: updatedData });
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  fetchBank = () => {
    const { getBankList } = this.props;
    getBankList(
      {},
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.bankList = [...(res.data.banks || [])];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  handleImagesFetch = images => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        details: {
          ...data.details,
          image: images,
        },
      },
    });
  };

  transformData = data => ({
    outletId: data.outletId,
    collectionType: data.collectionType,
    amount: data.amount,
    remarks: data.remarks,
    date: data.date,
    collectorId: data.User,
    distributorId: this.distributorExist ? data.Distributor.id : data.Distributor,
    routeId: data.routeId,
    details: {
      chequeNumber: data.details ? data.details.chequeNumber.toString() : 0,
      bank: data.details ? data.details.bank : '',
      image: data.details ? data.details.image : [],
      valuedDate: data.details ? data.details.valuedDate : '',
    },
  });

  handleButtonSubmit = () => {
    const { data, crudMode } = this.state;
    const { createPayment, displayAlert } = this.props;
    const formValidation = refValidator(this.formReference);
    if (!formValidation) {
      this.setState({ enableErrorDisplay: true });
    } else {
      const formattedData = this.transformData(data);

      if (crudMode === EVENT_OPERATION.CREATE) {
        createPayment(
          {
            input: formattedData,
          },
          {
            handleSuccess: () => {
              displayAlert(ALERT_TYPE.SUCCESS, 'Receipt Created');
              this.setState({ crudMode: EVENT_OPERATION.READ });
              setTimeout(() => {
                history.push(`/${RECEIPTS}`);
              }, 900);
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
    }
  };

  handleButtonCancel = () => {
    const { crudMode } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: form.mapper({}),
      });
    } else {
      this.setState({ crudMode: EVENT_OPERATION.READ });
    }
  };

  render() {
    const { data, enableErrorDisplay, menu } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <ReceiptWrapper>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>Create Receipt</h2>
              <div className="flex m-0">
                {
                  <div>
                    <Button small secondary onClick={() => this.handleButtonCancel()}>
                      <span>Cancel</span>
                    </Button>
                    <Button small primary onClick={() => this.handleButtonSubmit()} disabled={serverResponseWaiting}>
                      <span>Save</span>
                    </Button>
                  </div>
                }
              </div>
            </PanelHeader>
          </PanelStyled>
          <div className="section-content pad-48">
            <View
              data={data}
              menu={menu}
              loading={serverResponseWaiting}
              onDropDownChange={this.handleDropDownChange}
              onInputChange={this.handleInputChange}
              handleImagesFetch={this.handleImagesFetch}
              refsObj={this.formReference}
              enableErrorDisplay={enableErrorDisplay}
              distributorExist={this.distributorExist}
            />
          </div>
        </div>
      </ReceiptWrapper>
    );
  }
}

Create.propTypes = propTypes;

Create.defaultProps = defaultProps;

export default withAlert()(Create);
