const UPPERCASE_REGEX = /([A-Z])/g;

const concatStrings = (list, seperator = '') => {
  let computedString = '';
  for (const count in list) {
    computedString = computedString.concat(list[count], seperator);
  }
  return computedString;
};

const snakeToCamel = (str) => str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());

const camelToSnake = (str) => str.replace(UPPERCASE_REGEX, (match) => '_' + match.toLowerCase());

export {
  concatStrings, snakeToCamel, camelToSnake
};
