import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon } from '../../../../v4/components';
import { MonthlyStyled } from './MonthlyViewStyled';
import { getNextMonth, getPrevMonth, getMonthYearTitleFromDate } from '../date';

const propTypes = {
  month: PropTypes.instanceOf(Object).isRequired,
  onMonthChange: PropTypes.func,
};

const defaultProps = {
  onMonthChange: () => null,
};

const ICON_TYPE = {
  NEXT: 'NEXT',
  PREV: 'PREV',
};

class MonthTitle extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleHeaderClick = type => {
    const { onMonthChange, month } = this.props;

    const nextMonth = type === ICON_TYPE.NEXT ? getNextMonth(month.start) : getPrevMonth(month.start);

    onMonthChange(nextMonth.start, nextMonth.end);
  };

  render() {
    const { month } = this.props;

    return (
      <MonthlyStyled>
        <div className="month-view">
          <Icon
            iconName="angle-left"
            className="cursor-pointer"
            onClick={() => this.handleHeaderClick(ICON_TYPE.PREV)}
          />
          <h4>{getMonthYearTitleFromDate(month.start)}</h4>
          <Icon
            iconName="angle-right"
            className="cursor-pointer"
            onClick={() => this.handleHeaderClick(ICON_TYPE.NEXT)}
          />
        </div>
      </MonthlyStyled>
    );
  }
}

MonthTitle.propTypes = propTypes;

MonthTitle.defaultProps = defaultProps;

export default MonthTitle;
