import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_TSOR_LIST = gql`
  query getTimeOnRouteReport($limit: Int, $offset: Int, $filter: FilterInput) {
    getTimeOnRouteReport(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        dse_id
        dse
        distributor_id
        distributor
        stl_id
        stl
        asm_id
        asm
        total_outlet_visited
        total_time_spent
        average_call_time
        total_commute_time
        average_commute_time
      }
      count
    }
  }
`;

const query = {
  getTSORList: GET_TSOR_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
