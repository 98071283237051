import styled from 'styled-components';

const RoutesListStyled = styled.div`
  flex: 1;
  width: calc(100% - 68%);
  border-top: 0;
  .route-li-wrap {
    .routeRow {
      display: flex;
      background-color: #ffffff;
      padding: 16px 24px 16px 21px;
      align-items: center;
      border-radius: 4px;
      margin-bottom: 8px;
      box-shadow: 0 2px 4px 0 rgba(39, 40, 51, 0.12);
      .info-li {
        padding-left: 16px;
        width: 100%;
        .routeText {
          font-size: 12px;
          line-height: 15px;
          color: #272833;
          margin-bottom: 7px;
          font-weight: 600;
        }
        .outletText {
          font-size: 10px;
          line-height: 12px;
          color: #6b6c7e;
        }
      }
      .icon {
        fill: #6b6c7e;
      }
    }
  }
  .routesList {
    background-color: #f1f2f5;
    border: 1px solid #e7e7ed;
    border-top: 0;
    p {
      margin: 0;
    }
    .routesDisplay-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px 24px;
      background-color: #fff;
      border-bottom: 1px solid #e7e7ed;
      p {
        font-size: 18px;
        line-height: 21px;
        color: #272833;
        font-weight: 600;
      }
      img {
        height: 16px;
        width: 16px;
      }
      .right-icons {
        display: flex;
        align-items: center;
      }
    }
    button.borderless {
      height: 32px;
      width: 32px;
      background: transparent;
      border: 0;
      margin-right: 8px;
      cursor: pointer;
      outline: none;
      &:last-child {
        margin-right: 0;
      }
      &.ellipse {
        background-color: transparent;
        border-radius: 2px;
        border: 1px solid transparent;
        margin-right: 0;
        &:hover {
          background-color: #f1f2f5;
          border: 1px solid #f1f2f5;
        }
      }
      svg {
        fill: #6b6c7e;
      }
    }
    .routeCreate-body {
      padding: 20px;
      height: calc(100vh - 243px);
      overflow: auto;
      display: flex;
      flex-direction: column;
      h3.total {
        font-size: 14px;
        line-height: 21px;
        color: #6b6c7e;
        font-weight: 400;
        margin: 0;
        margin-bottom: 8px;
      }
      h3.info-header {
        margin: 0;
        color: #6b6c7e;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        border: 1px solid rgba(46, 90, 172, 0.4);
        border-radius: 4px;
        background-color: #eef2fa;
        position: relative;
        .info {
          padding: 18px 40px;
          display: flex;
          position: relative;
          width: 100%;
          span {
            position: absolute;
            top: 18px;
            left: 18px;
            .icon {
              fill: #2e5aac;
            }
          }
          p {
            font-size: 14px;
            color: #2e5aac;
            display: flex;
            b {
              font-weight: 600;
              flex: 1;
              margin-right: 4px;
            }
          }
        }
      }
      .route-li-wrap {
        label {
          width: 16px;
          height: 16px;
          .control-label {
            &::before {
              position: absolute;
              top: -1px;
            }
            &::after {
              top: -1px;
            }
          }
        }
      }
      .default-wrap {
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        p {
          color: #272833;
          font-size: 14px;
          line-height: 21px;
          margin-top: 30px;
        }
      }
    }
  }
  .select-route .zindex-2__control {
    width: 100%;
    min-height: 38px;
    max-height: 150px;
    overflow-y: scroll;
    border-radius: 4px;
    background: rgb(231, 231, 237);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(231, 231, 237);
    border-image: initial;
    padding: 5px;
    outline: none;
    .zindex-2__indicators {
      display: none;
    }
    .zindex-2__placeholder {
      font-size: 11px;
    }
    .zindex-2__multi-value {
      font-size: 12px;
      border-width: 1px;
      border-style: solid;
      border-color: rgb(128, 172, 255);
      border-radius: 4px;
      background: rgb(255, 255, 255);
    }
  }
`;
export default RoutesListStyled;
