export const StockMovementPolicy = ({ ...props }) => {
  const { data, onStockChange } = props;
  return (
    <>
      <div className="form-input-wrap">
        {data?.stockMovementRule?.map((stock, index) => (
          <>
            <div className="stock-content card" key={index}>
              <label>{stock.skuTag}</label>
              {stock.stockRule.map((type, stockIndex) => (
                <>
                  <label className="radio-list" key={stockIndex}>
                    <input
                      type="radio"
                      name={stock.skuTag}
                      value={stock.stockRule[stockIndex].status}
                      checked={stock.stockRule[stockIndex].status}
                      onChange={e => onStockChange(e, index, stockIndex)}
                    />
                    <span>{type.stockRuleType}</span>
                  </label>
                </>
              ))}
            </div>
          </>
        ))}
      </div>
    </>
  );
};
