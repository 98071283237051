import React from 'react';
import { Icon } from '../../../v4/components';

const labelWithDimension = (list) => {
  switch (list.dimension) {
  case 'PERCENT':
    return `${list.value}% (Rs ${list.calculatedValue})`;
  case 'AMOUNT':
    return `Rs ${list.value}`;
  case 'VALUE':
    return `${list.value} (In Value)`;
  default:
    return list.value;
  }
};

const TopDetail = ({ label, value, subType, icon }) => (
  <div>
    <label>
      {label}
      {icon && <Icon iconName='exclamation-circle' />}
    </label>
    {subType ? (
      value &&
      value.map((list) => (
        <p>
          <span>{labelWithDimension(list)}</span>
          <label>{list[subType]}</label>
        </p>
      ))
    ) : (
      <>
        <p>{value}</p>
        <label>{subType}</label>
      </>
    )}
  </div>
);

export default TopDetail;
