import { compose } from 'recompose';
import PRN from './PurchaseReturn';
import { mutation, query } from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const ComposedPRN = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(PRN);

export default ComposedPRN;
