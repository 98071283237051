import HolidaysSection from '../../../components/HolidaysSection';
import BuHolidayWrapper from '../BuHolidayWrapper';

export const Holidays = ({ ...props }) => {
  const { params, callHolidaySection, getHolidayData, resetDataHoliday } = props;
  return (
    <BuHolidayWrapper>
      <HolidaysSection
        params={params}
        callHolidaySection={callHolidaySection}
        className="bu-holidays"
        getHolidayData={getHolidayData}
        resetDataHoliday={resetDataHoliday}
      />
    </BuHolidayWrapper>
  );
};
