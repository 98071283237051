import React from 'react';
import PropTypes from 'prop-types';
import NumberWrap from './NumberWrap';

const propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  title: '',
  subTitle: '',
};

const NumberCard = ({ title, subTitle, children }) => (
  <NumberWrap>
    <div className='text-grey label'>{title}</div>
    <div className={`text-grey label subTitle ${!subTitle && 'pad-top'}`}>{subTitle}</div>
    {children}
  </NumberWrap>
);

NumberCard.propTypes = propTypes;

NumberCard.defaultProps = defaultProps;

export default NumberCard;
