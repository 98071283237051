import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import 'react-photoswipe/lib/photoswipe.css';
import { PhotoSwipe } from '../../../../../../v4/components';
import withAlert from '../../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';

const propTypes = {
  id: PropTypes.number.isRequired,
  getOutletImage: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  getOutletImage: () => null,
};

class OutletImageGallery extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: props.id || 0,
      },
      imageList: [],
    };
  }

  componentDidMount() {
    // this.resetActiveCall();
    // this.getOrderHistory();

    // get image for retail outlets
    this.getOutletImage();
  }

  getOutletImage = () => {
    const { data } = this.state;
    const { getOutletImage, displayAlert } = this.props;
    getOutletImage(
      {
        outletId: data.id,
      },
      {
        handleSuccess: response => {
          // eslint-disable-next-line max-len
          const imageList = (response?.data?.getOutletImageList) || [];
          const transformedImageList = this.outletImageTransformer(imageList);
          this.setState({ imageList: transformedImageList });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  outletImageTransformer = imageList =>
    imageList.map(image => ({
      src: `${image.image}`,
      thumbnail: `${image.image}`,
      w: 1200,
      h: 900,
      title: image.created_at,
    }));

  getThumbnailContent = item => (
    <Fragment>
      <img src={item.thumbnail} width={200} height={200} />
      <span>{item.src}</span>
    </Fragment>
  );

  render() {
    const { imageList } = this.state;

    const options = {
      index: 3,
      escKey: false,
      // ui option
      timeToIdle: 4000,
    };

    return (
      <div className="create-ui">
        <div className="tab-section">
          <PhotoSwipe items={imageList} options={options} thumbnailContent={this.getThumbnailContent} />
        </div>
      </div>
    );
  }
}

OutletImageGallery.propTypes = propTypes;

OutletImageGallery.defaultProps = defaultProps;

export default withAlert()(OutletImageGallery);
