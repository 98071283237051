import { USER_ACCOUNT } from '../../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const stateToRequestMapper = (request) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    roleId,
    parentUserId,
    billingCredentials,
    billingStatus,
    townIds,
    distributorIds,
    classificationTypeId,
    designationId,
    id,
    rigoDetails,
  } = request;
  return {
    firstName,
    lastName,
    email,
    phoneNumber: phoneNumber.toString(),
    password,
    roleId,
    parentUserId,
    billingCredentials: { ...billingCredentials, user_id: billingCredentials?.user_id || id },
    billingStatus,
    townIds: townIds?.length > 0 ? townIds : null,
    distributorIds: distributorIds.length > 0 ? distributorIds : null,
    classificationTypeId,
    designationId,
    rigoDetails: rigoDetails.rigoId && rigoDetails.deviceId ? {
      rigo_id: rigoDetails.rigoId,
      device_id: rigoDetails.deviceId,
    }: {
      rigo_id: null,
      device_id: null,
    },
  };
};

const responseToStateMapper = (response) => {
  const {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    roleId,
    parentUserId,
    billingCredentials,
    billingStatus,
    Distributor,
    Town,
    ClassificationType,
    designationId,
    role,
    rigoDetails,
  } = response;

  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    roleId,
    parentUserId,
    billingCredentials: billingCredentials || {},
    billingStatus,
    townIds: Town ? Town?.map((d) => d.id) : null,
    distributorIds: Distributor ? Distributor.map((d) => d.id) : null,
    Distributor,
    Town,
    classificationTypeId: ClassificationType?ClassificationType[0]?.id : null,
    designationId,
    enableGoogleAuth: role?.enableGoogleAuth,
    rigoDetails: {
      rigoId: rigoDetails?.rigo_id || null,
      deviceId: rigoDetails?.device_id || null,
    },
  };
};

const getBaseUserData = (id) =>
  ({
    id,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    roleId: 0,
    designationId: null,
    parentUserId: null,
    billingCredentials: {
      user_id: null,
    },
    townIds: [],
    distributorIds: [],
    billingStatus: false,
    Distributor: [],
    Town: [],
    classificationTypeId: 0,
    rigoDetails: {
      rigoId: null,
      deviceId: null,
    },
  });

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'User Account',
    path: `/${USER_ACCOUNT}`,
    active: true,
  },
];

const uniqueFields = {
  EMAIL: 'email',
  PHONE: 'phoneNumber',
};

export {
  uniqueFields, getBaseUserData, stateToRequestMapper, responseToStateMapper, breadCrumb as breadCrumbConfig
};
