import { compose } from 'recompose';
import ICO from './ICO';
import {
  query, mutation, ICOUPLOAD, CREATE_REQUEST_ORDER, automatedIcoGeneration,
} from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const composedSubDReplenishment = compose(
  DOWNLOAD_REPORT,
  ICOUPLOAD,
  automatedIcoGeneration,
  CREATE_REQUEST_ORDER,
  withRequestTracker({
    query,
    mutation,
  }),
)(ICO);

export default composedSubDReplenishment;
