import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'TAS Order Analysis Report';

const breadCrumb = [reportsRoute];

const filter = getFilterConfig([
  FILTER.SUB_D,
  FILTER.TERRITORY
]);

export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, title
};
