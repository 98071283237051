import { compose } from 'recompose';
import {
  query, mutation, CREATE_PRESENTATION
} from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { TOGGLE_STATE, DOWNLOAD_REPORT } from '../../common/GqlAPI';
import Presentation from './Presentation';

const ComposedPresentation = compose(
  TOGGLE_STATE,
  CREATE_PRESENTATION,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(Presentation);

export default ComposedPresentation;
