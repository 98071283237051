import React, { useEffect, useState } from 'react';
import DetailStyled from '../Common/CreateDetailStockCount.styled';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { BreadCrumb, Button } from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { WEEK_DAYS_TITLE, breadCrumb } from '../Common/config';
import BasicDetail from '../Common/BasicDetail';
import View from './View';
import { ALERT_TYPE } from '../../../../v4/constants/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import history from '../../../../utils/history';
import { STOCK_COUNT } from '../../../../data/enums/Route';
import { refGenerator, refValidator } from '../../../../utils/refGenerator';
import { getTodaysDay } from '../../../../utils/date';

const CreateDetailStockCount = (props) => {
  const {
    serverResponseWaiting,
    createStockCount,
    displayAlert,
    getStockSkuStatus,
    match,
    getSKUFamilyDetails,
    getProductGroupDetails,
    getBrandDetails,
    getLogisticUser,
    getStockCountDetails,
    getStockCountList,
  } = props;
  const [crudMode, setcrudMode] = useState(
    match.params.id && match.params.id !== '0' ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
  );
  const [basicDetail, setbasicDetail] = useState({});
  const [assignedTo, setassignedTo] = useState([]);
  const [data, setdata] = useState({
    categoryId: '',
    productCategoryId: '',
    brandId: '',
    skuId: '',
  });
  const [brandList, setbrandList] = useState([]);
  const [inputRes, setinputRes] = useState([]);
  const [detailData, setdetailData] = useState([]);
  const [skuGroupList, setskuGroupList] = useState([]);
  const [stockId, setstockId] = useState(parseInt(match.params.id, 10));
  const [alreadySelectedSku, setalreadySelectedSku] = useState([]);
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [filteredBrandList, setfilteredBrandList] = useState([]);
  const [skuFamilyList, setskuFamilyList] = useState([]);
  const [skuAssortedList, setskuAssortedList] = useState([]);
  const [enableValidation, setenableValidation] = useState(false);
  const [count, setcount] = useState({
    category: [],
    productCategories: [],
    brands: [],
    skuFamilies: [],
    skuFamiliesList: [],
  });
  const [searchText, setsearchText] = useState('');
  const [SKUFamilyList, setSKUFamilyList] = useState([]);
  const read = crudMode===EVENT_OPERATION.READ;
  const update = crudMode===EVENT_OPERATION.UPDATE;
  const create = crudMode===EVENT_OPERATION.CREATE;

  const onIconClick = (type) => {
    setcrudMode(type);
  };

  const handleInputChange = (e, params) => {
    if (params === 'fortnightly') {
      let repeatDates = [];
      const todayDate = new Date();
      let [year, month, day] = [todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate()];
      day = parseInt(e.target.value);
      const lastDayOfMonth = new Date(year, month, 0);

      if (day + 14 > lastDayOfMonth) {
        repeatDates.push(day);
      } else {
        repeatDates.push(day);
        if (day <= 15) {
          repeatDates.push(day + 14);
        }
      }
      setbasicDetail({ ...basicDetail, [e.target.name]: repeatDates });
    } else {
      setbasicDetail({ ...basicDetail, [e.target.name]: e.target.value });
    }
  };

  const handleDropDownChange = (value, params) => {
    if (params === 'frequency_type') {
      basicDetail[params] = value;
      basicDetail.dayOfTheMonth = '';
      basicDetail.days = '';
    } else {
      basicDetail[params] = value;
    }
    setbasicDetail({ ...basicDetail });
  };

  const handleSearchInput = (text) => {
    const regex = new RegExp(text, 'i');
    const bySkus = SKUFamilyList.filter((i) => i.skus.filter((s) => s.title.search(regex) > -1).length > 0);
    const bySKUFamilies = SKUFamilyList.filter((p) => p.title.search(regex) > -1);
    const union = [...bySKUFamilies, ...bySkus];
    const result = union.filter((item, index) => union.indexOf(item) === index);
    setsearchText(text);
    setskuFamilyList(result);
  };

  const getStockStatus = () => {
    getStockSkuStatus(
      {
        stockId: stockId || null,
      },
      {
        handleSuccess: (response) => {
          const result =
            response?.data?.getStockSkuStatus?.rows?.map((re) => ({
              sku_id: re.sku_id,
              sku_family_id: re.sku_family_id,
            })) || [];
          setalreadySelectedSku(result);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  const getSKUsCountDetail = () => {
    //TODO:: getstockCount
    const { id } = match.params;
    const { productCategoryId, brandId } = data;
    getStockCountDetails(
      {
        stockId: parseInt(id, 10) || 0,
        buId: null,
        categoryId: parseInt(productCategoryId, 0) || 0,
        brandId: parseInt(brandId, 10) || 0,
      },
      {
        handleSuccess: response => {
          const { getStockCountDetails } = response.data;
          count.category = getStockCountDetails.rows[0].bu;
          count.brands = getStockCountDetails.rows[0].Brand;
          count.productCategories = getStockCountDetails.rows[0].Category;
          count.skuFamilies = getStockCountDetails.rows[0].Sku;
          count.skuFamiliesList = getStockCountDetails.rows[0].SkuFamily;
          setskuAssortedList(count.skuFamilies.map(sku => sku.skus));
          setcount(count);
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  const getProductGroupList = () => {
    getProductGroupDetails(
      { parentCatalogDetailId: null },
      {
        handleSuccess: response => {
          const result = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          setproductCategoryList(result);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const getBrandList = () => {
    getBrandDetails(
      {},
      {
        handleSuccess: response => {
          const result = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          setbrandList(result);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const handleProductCategoryClick = groupId => {
    data.productCategoryId = groupId;
    data.brandId = '';
    const newBrandList = brandList.filter(brand => parseInt(brand.parentCatalogDetailId, 10) === groupId);
    setdata(data);
    setfilteredBrandList(newBrandList);
    if (stockId) {
      getSKUsCountDetail();
    }
  };

  const handleBrandClick = brandId => {
    data.brandId = brandId;
    getSKUFamilyList(brandId);
    setdata(data);
    setsearchText('');
    if (stockId) {
      getSKUsCountDetail();
    }
  };

  const getSKUFamilyList = id => {
    getSKUFamilyDetails(
      {
        parentCatalogDetailId: id,
      },
      {
        handleSuccess: response => {
          const skuList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          const filteredSkuList = skuList.filter(i => i.skus && i.skus.length > 0);
          setskuFamilyList(filteredSkuList);
          setSKUFamilyList(filteredSkuList);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const getStockCount = uniqueObjects => {
    const productCounts = {};
    const brandCounts = {};
    const skuFamilyCounts = {};
    const skuData = [...new Map(uniqueObjects.map(obj => [JSON.stringify(obj), obj])).values()];
    skuData.forEach(item => {
      const product_category_id = item.product_category_id;
      if (productCounts[product_category_id]) {
        productCounts[product_category_id]++;
      } else {
        productCounts[product_category_id] = 1;
      }
    });

    const productResult = [];

    for (const product_category_id in productCounts) {
      productResult.push({
        id: parseInt(product_category_id),
        count: productCounts[product_category_id],
      });
    }

    skuData.forEach(item => {
      const brand_id = item.brand_id;
      if (brandCounts[brand_id]) {
        brandCounts[brand_id]++;
      } else {
        brandCounts[brand_id] = 1;
      }
    });

    const brandResult = [];

    for (const brand_id in brandCounts) {
      brandResult.push({
        id: parseInt(brand_id),
        count: brandCounts[brand_id],
      });
    }

    skuData.forEach(item => {
      const sku_family_id = item.sku_family_id;
      if (skuFamilyCounts[sku_family_id]) {
        skuFamilyCounts[sku_family_id]++;
      } else {
        skuFamilyCounts[sku_family_id] = 1;
      }
    });

    const skuFamilyResult = [];

    for (const sku_family_id in skuFamilyCounts) {
      skuFamilyResult.push({
        skufamilies: parseInt(sku_family_id),
        count: skuFamilyCounts[sku_family_id],
      });
    }

    setinputRes(skuData);
    count.productCategories = productResult;
    count.brands = brandResult;
    count.skuFamiliesList = skuFamilyResult;
    setcount(count);
  };

  const handleToggleState = (toggleStatus, ids, params, remainingObj) => {
    const submittingDataArray = [];

    if (params === 'all') {
      for (let i = 0; i < ids.length; i++) {
        const skuFamily = skuFamilyList.find(el => {
          if (el.skus.find(sku => sku.id === ids[i])) {
            return true;
          }
        });
        const submittingData = {
          active: true,
          brand_id: remainingObj.brandId,
          principal_id: 1,
          product_category_id: remainingObj.productCategoryId,
          sku_family_id: skuFamily.id,
          sku_id: ids[i],
        };
        submittingDataArray.push(submittingData);
      }

      if (toggleStatus) {
        getStockCount(submittingDataArray);
        const test = new Set([...skuAssortedList, ...ids]);
        setskuAssortedList([...(Array.from(test) || [])]);
      } else {
        count.productCategories = [];
        count.brands = [];
        count.skuFamiliesList = [];
        setcount(count);
        setskuAssortedList(skuAssortedList.filter(id => !ids.includes(id)));
      }
    } else {
      for (let i = 0; i < ids.length; i++) {
        const submittingData = {
          active: true,
          brand_id: remainingObj.brandId,
          principal_id: 1,
          product_category_id: remainingObj.productCategoryId,
          sku_family_id: remainingObj.skuFamilyId,
          sku_id: ids[i],
        };
        if (toggleStatus) {
          inputRes.push(submittingData);
          const newTest = [...new Set(inputRes)];
          getStockCount(newTest);
          const test = new Set([...skuAssortedList, ...ids]);
          setskuAssortedList([...(Array.from(test) || [])]);
        } else {
          const x =
            params === 'sku'
              ? inputRes.filter(x => x.sku_id !== submittingData.sku_id)
              : inputRes.filter(x => x.sku_family_id !== submittingData.sku_family_id);
          getStockCount(x);
          setskuAssortedList(skuAssortedList.filter(id => !ids.includes(id)));
        }
      }
    }
  };

  const getAssignedUser = () => {
    getLogisticUser(
      {},
      {
        handleSuccess: response => {
          const result = response?.data?.getLogisticUser || [];
          setassignedTo(result);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const validation = refGenerator(['title', 'frequency_type', 'assignedTo']);

  const handleSaveClick = () => {
    const formValidation = refValidator(validation);
    const todaysDay = getTodaysDay();
    if (!formValidation) {
      setenableValidation(true);
    } else {      
      if (inputRes?.length > 0) {
        let dayOfMonth = null;

        if (basicDetail?.frequency_type === 'MONTHLY') {
          dayOfMonth = basicDetail?.dayOfTheMonth !== '' ? [Number(basicDetail?.dayOfTheMonth)] : null;
        } else if (basicDetail?.frequency_type === 'FORTNIGHTLY') {
          dayOfMonth = basicDetail?.dayOfTheMonth !== '' ? basicDetail.dayOfTheMonth : null;
        }
        const inputData = {
          assigned_to: basicDetail?.assigned?.name,
          assigned_to_code: basicDetail?.assigned?.id,
          frequency_details: {
            dayOfMonth: dayOfMonth,
            dayOfWeek:
              basicDetail?.frequency_type === 'DAILY'
                ? WEEK_DAYS_TITLE?.find(a => a.id === todaysDay)?.title
                : basicDetail?.days !== ''
                ? [basicDetail?.days]
                : [] || [],
          },
          frequency_type: basicDetail?.frequency_type,
          title: basicDetail.title,
          stock_Count_detail: inputRes,
        };
        createStockCount(
          {
            input: inputData,
          },
          {
            handleSuccess: response => {
              if (response) {
                displayAlert(ALERT_TYPE.SUCCESS, 'Stock Count created successfully!');
                setTimeout(() => {
                  history.push(`/${STOCK_COUNT}`);
                }, 1200);
              }
            },
            handleError: error => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      } else {
        displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'SKU Family / SKUS is not selected.');
      }
    }
  };
  const handleCancelClick = () => {
    if (create) {
      setbasicDetail({});
      setskuAssortedList([]);
      setcount({
        category: [],
        productCategories: [],
        brands: [],
        skuFamilies: [],
        skuFamiliesList: [],
      });
    } else {
      setcrudMode(EVENT_OPERATION.READ);
    }
  };

  useEffect(() => {
    getProductGroupList();
    getBrandList();
    getStockStatus();
    getAssignedUser();
  }, []);

  return (
    <DetailStyled>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <div className="flex items-center justify-between m-0 flex-1">
              <h2>{create ? 'Create Stock Count' : read ? basicDetail.title : `Edit ${basicDetail.title}` || ''}</h2>
              {crudMode === EVENT_OPERATION.CREATE && (
                <div>
                  <Button small disabled={serverResponseWaiting} secondary onClick={() => handleCancelClick()}>
                    <span>Cancel</span>
                  </Button>
                  <Button primary disabled={serverResponseWaiting} small onClick={() => handleSaveClick()}>
                    <span>Save</span>
                  </Button>
                </div>
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className="section-content">
        <div className="create-ui">
          <BasicDetail
            basicDetail={basicDetail}
            assignedTo={assignedTo}
            handleInputChange={handleInputChange}
            handleDropDownChange={handleDropDownChange}
            refsObj={validation}
            enableErrorDisplay={enableValidation}
            read={read}
            stockId={stockId}
          />
        </div>
        <View
          loading={serverResponseWaiting}
          brandList={filteredBrandList}
          data={data}
          productCategoryList={productCategoryList}
          onProductCategoryClick={handleProductCategoryClick}
          onBrandClick={handleBrandClick}
          SKUFamilyList={skuFamilyList}
          count={count}
          skuAssortedList={skuAssortedList}
          onToggle={handleToggleState}
          onHandleSearchInput={handleSearchInput}
          searchText={searchText}
          alreadySelectedSku={alreadySelectedSku}
          read={read}
/>
      </div>
    </DetailStyled>
  );
};

export default withAlert()(CreateDetailStockCount);
