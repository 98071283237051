import gql from 'graphql-tag';
import { DOWNLOAD_REPORT} from '../../common/GqlAPI';


const GET_ROUTE_SEGMENTATION_LIST = gql`
  query getRouteSegmentation($offset: Int, $limit: Int, $filter: FilterInput) {
    lines(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        brandCount
        active
        type
        lineBrands {
          id
          title
          status
          lineSkuFamily {
            id
            title
            status
            skus: lineSkus {
              id
              title
              status
            }
          }
        }
      }
      count
    }
  }
`;

const query = {
  getRouteSegmentation: GET_ROUTE_SEGMENTATION_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export {
  query, mutation, GET_ROUTE_SEGMENTATION_LIST
};
