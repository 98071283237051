import { refGenerator } from '../../../../utils';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { getFilterConfig } from '../../../../utils/filter';
import { FORM_CONFIG } from '../../../../v4/constants/formConfig';
import { configuration } from '../../../../data/enums/Route';
import { FILTER } from '../../../../data/enums/filter';
import { MESSAGE_EVENT_OPERATION } from '../../../../v4/constants/successMessage';

const breadCrumb = [configuration];
const Title = 'ICO Group';
const requiredList = ['title', 'alias'];
const filter = getFilterConfig([FILTER.SUB_D]);

const crudSuccess = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: 'Confirm',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: data.ids,
      type: 'Confirm',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};


const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: `Create ${Title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: `Edit ${Title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: `Delete ${Title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
  },
};

export {
  breadCrumb as breadCrumbConfig, formConfig, Title as title, filter as filterConfig, crudSuccess
};
