import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../../user/userRole/API';

const CREATE_SUB_D_ASSORTMENT = graphql(
  gql`
    mutation ($title: String!) {
      createDistributorGroup(title: $title) {
        id
        title
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createDistributorGroup: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_SUB_D_ASSORTMENT = graphql(
  gql`
    mutation ($title: String!, $id: Int!) {
      updateDistributorGroup(title: $title, id: $id) {
        id
        title
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateDistributorGroup: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const mutation = {
  createDistributorGroup: CREATE_SUB_D_ASSORTMENT,
  updateDistributorGroup: UPDATE_SUB_D_ASSORTMENT,
  toggleState: TOGGLE_STATE,
};

export {
  mutation, CREATE_SUB_D_ASSORTMENT, UPDATE_SUB_D_ASSORTMENT
};
