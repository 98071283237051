import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BreadCrumb, Icon } from '../../../../../v4/components';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { breadCrumb } from './config';
// import TargetDetailWrapper from '../../details/TargetDetailWrapper';
import { numberPresentor } from '../../../../../utils/conversion';
import AccordionTable from '../../../../../components/CollapsibleTable/AccordionTable';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import TargetDetailWrapper from '../../../target-Achievement/details/TargetDetailWrapper';
import PageHeader from '../../../../base/PageHeader';
// import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const propTypes = {
  detailTitle: PropTypes.string,
  data: PropTypes.instanceOf(Object),
  // onIconClick: PropTypes.func,
  handleIconClick: PropTypes.func,
};

const defaultProps = {
  detailTitle: '',
  data: {},
  // onIconClick: () => null,
  handleIconClick: () => null,
};

const View = ({ ...props }) => {
  const { data, onIconClick, handleIconClick, detailTitle, permission, handleDownloadClick } = props;

  const brandTarget = (id, targetData = {}) => (
    <td onClick={(e) => e.stopPropagation()}>
      {permission.read&&<span
        className='view'
        onClick={() => handleIconClick(id, targetData, EVENT_OPERATION.READ)}
      >
        <Icon iconName='view' />
        View
      </span>}
      {permission.update&&<span
        onClick={() =>  handleIconClick(id, targetData, EVENT_OPERATION.UPDATE)}
      >
        <Icon iconName='pencil' />
        Edit
      </span>}
    </td>
  );

  const tableData = (childUser, collapsible = false) => (
    <>
      <td>{collapsible ? <span>{childUser.user}</span> : childUser.user}</td>
      <td>{childUser.role}</td>
      {/* <td>{numberPresentor(childUser.target)}</td> */}
      {brandTarget(childUser.id, childUser)}
    </>
  );

  const getCollapsibleComponent = (dataList) => (
    <AccordionTable>
      {dataList?.map((user) => (
        <tr
          key={user.id}
          trigger={
            <>
              <td>
                <span>{user?.user}</span>
              </td>
              <td>{user.role}</td>
              {/* <td>{numberPresentor(user.target)}</td> */}
              <td onClick={(e) => e.stopPropagation()}>
                {permission.read&&<span
                  className='view'
                  onClick={() =>
                    handleIconClick(user.id, user, EVENT_OPERATION.READ)
                  }
                >
                  <Icon iconName='view' />
                  View
                </span>}
                {permission.update&&<span
                  onClick={() =>
                    handleIconClick(user.id, user, EVENT_OPERATION.UPDATE)
                  }
                >
                  <Icon iconName='pencil' />
                  Edit
                </span>}
              </td>
            </>
          }
          isChildPresent={!user?.isChildPresent}
          simpleChild={
            <tr className='active' key={user.id}>
              {tableData(user)}
            </tr>
          }
        >
          <AccordionTable>
            {user?.ChildUsers?.map((childUser1) => (
              <tr
                key={childUser1.id}
                trigger={tableData(childUser1, true)}
                isChildPresent={!childUser1.isChildPresent}
                simpleChild={
                  <tr className='active' key={childUser1.id}>
                    {tableData(childUser1)}
                  </tr>
                }
              >
                <AccordionTable>
                  {childUser1?.ChildUsers?.map((childUser2) => (
                    <tr
                      key={childUser2.id}
                      trigger={tableData(childUser2, true)}
                      isChildPresent={!childUser2.isChildPresent}
                      simpleChild={
                        <tr className='active' key={childUser2.id}>
                          {tableData(childUser2)}
                        </tr>
                      }
                    >
                      <AccordionTable>
                        {childUser2?.ChildUsers?.map((childUser3) => (
                          <tr
                            key={childUser3.id}
                            trigger={tableData(childUser3, true)}
                            isChildPresent={!childUser3.isChildPresent}
                            simpleChild={
                              <tr className='active' key={childUser3.id}>
                                {tableData(childUser3)}
                              </tr>
                            }
                          >
                            <AccordionTable>
                              {childUser3?.ChildUsers?.map((childUser4) => (
                                <tr
                                  key={childUser4?.id}
                                  trigger={tableData(childUser4, true)}
                                  isChildPresent={!childUser4?.isChildPresent}
                                  simpleChild={
                                    <tr className='active' key={childUser4.id}>
                                      {tableData(childUser4)}
                                    </tr>
                                  }
                                >
                                  <AccordionTable>
                                    {childUser4?.ChildUsers?.map((childUser5) => (
                                      <tr
                                        key={childUser5?.id}
                                        trigger={tableData(childUser5, true)}
                                        isChildPresent={!childUser5?.isChildPresent}
                                        simpleChild={
                                          <tr className='active' key={childUser5.id}>
                                            {tableData(childUser5)}
                                          </tr>
                                        }
                                      >
                                        <AccordionTable>
                                          {childUser5?.ChildUsers?.map((childUser6) => (
                                            <tr
                                              key={childUser6?.id}
                                              trigger={tableData(childUser6, true)}
                                              isChildPresent={!childUser6?.isChildPresent}
                                              simpleChild={
                                                <tr className='active' key={childUser6.id}>
                                                  {tableData(childUser6)}
                                                </tr>
                                              }
                                            >
                                              {childUser6?.isChildPresent &&
                                                childUser6?.ChildUsers?.length !== 0 &&
                                                childUser6?.ChildUsers?.map((childUser7) => (
                                                  <tr className='active' key={childUser7.id}>
                                                    {tableData(childUser7)}
                                                  </tr>
                                                ))}
                                            </tr>
                                          ))}
                                        </AccordionTable>
                                      </tr>
                                    ))}
                                  </AccordionTable>
                                </tr>
                              ))}
                            </AccordionTable>
                          </tr>
                        ))}
                      </AccordionTable>
                    </tr>
                  ))}
                </AccordionTable>
              </tr>
            ))}
          </AccordionTable>
        </tr>
      ))}
    </AccordionTable>
  );

  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          {/* <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <h2>{detailTitle}</h2>
          </PanelHeader> */}

          <PageHeader
            display={{}}
            breadCrumb={breadCrumb}
            config={{
              title: detailTitle,
              download: true,
              filter: false,
              upload: true,
              date: false,
            }}
            handleUploadClick={() => {
              handleIconClick(undefined, {}, EVENT_OPERATION.UPLOAD);
            }}
            handleDownloadClick={handleDownloadClick}
            handleCreateClick={handleIconClick}
          />
        </PanelStyled>
      </div>
      <div className='section-content'>
        <TargetDetailWrapper>
          <div className='table-wrap'>
            {
              <table>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Role</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{getCollapsibleComponent(data)}</tbody>
              </table>
            }
          </div>
        </TargetDetailWrapper>
      </div>
    </Fragment>
  );
};



const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
