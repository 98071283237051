import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, CustomSelect
} from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  parent: PropTypes.instanceOf(Array),
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  data: {
    name: '',
    parentId: 0,
  },
  refsObj: {},
  parent: [],
  handleDropDownChange: () => null,
};

const LocationHierarchiesForm = ({ ...props }) => {
  const { data, enableErrorDisplay, handleInputChange, refsObj, parent, handleDropDownChange, type, lastParent } =
    props;
  const firstCreate = lastParent[0] !== undefined;
  return (
    <Form>
      <div className={`${firstCreate ? ' ' : 'pad-b-24 '} form-upper`}>
        <Input
          ref={(ref) => (refsObj.title = ref)}
          value={data.title}
          name='title'
          type='text'
          placeholder='Title'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event)}
          labelContent='Title'
        />

        {type === EVENT_OPERATION.CREATE && firstCreate ? (
          <CustomSelect
            ref={(ref) => {
              refsObj.parentId = ref;
              return null;
            }}
            clearable={false}
            className='custom-select'
            placeholder='Select Parent Hierarchy'
            getOptionLabel={({ title }) => title}
            getOptionValue={({ id }) => id}
            options={lastParent}
            enableValiation
            enableErrorDisplay={enableErrorDisplay}
            value={parent.filter(({ id }) => id === data.parentId)}
            onChange={(e) => handleDropDownChange(e.id, ['parentId'])}
            labelContent='Parent Hierarchy'
          />
        ) : null}
      </div>
    </Form>
  );
};

LocationHierarchiesForm.propTypes = propTypes;

LocationHierarchiesForm.defaultProps = defaultProps;

const LocationHierarchiesFormViewWithErrorAndLoading = withLoading(LocationHierarchiesForm);

export default LocationHierarchiesFormViewWithErrorAndLoading;
