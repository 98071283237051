import styled from 'styled-components';

const AttendanceDialogStyled = styled.div`
  &.map_section {
    .map-main {
      height: 506px;
      height: 73vh;
      #map {
        height: calc(100% - 125px);
        position: absolute;
        width: 100%;
        border-bottom: 1px solid #e7e7ed;
        > div:nth-child(2) {
          top: 50% !important;
          left: 50%;
          transform: translate(-50%, -50%) !important;
        }
      }
    }
    .custom_legend {
      display: flex;
      align-items: center;
      padding: 24px 32px;
      /* border-top: 1px solid #e7e7ed; */
      li {
        display: flex;
        align-items: center;
        margin-right: 24px;
        span {
          display: inline-block;
          width: 10px;
          height: 10px;
          line-height: 10px;
          margin-right: 4px;
          border-radius: 50%;
        }
        p {
          margin: 0;
          font-size: 10px;
          line-height: 12px;
          color: #2e384d;
        }
      }
      .check_in {
        span {
          background-color: #50d2a0;
        }
      }
      .check_out {
        span {
          background-color: #ff5f5f;
        }
      }
    }
  }
  &.agendas_section {
    min-height: 20vh;
    max-height: 70vh;
    overflow: auto;
    padding: 24px;
    h5 {
      color: #6b6c7e;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      margin: 0;
      margin-bottom: 16px;
    }
    ul {
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 15px;
        label {
          cursor: unset;
        }
        span {
          color: #272833;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          margin-top: 2px;
          margin-left: 3px;
          text-transform: capitalize;
          &.middle-underline {
            text-decoration: line-through;
            text-decoration-color: #272833;
          }
        }
      }
    }
  }
`;
export default AttendanceDialogStyled;
