/**
 * Generates weekly Line for each of DSE
 * Loads the data through context API.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { WeeklyUnit } from '../Common';
import { DSEWeeklyViewStyled } from '../DSEWeeklyViewStyled';
import { DialogContext } from '../config';
import { CALENDAR_PREVIEW } from '../../../../data/enums';

const propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  dateList: PropTypes.instanceOf(Array).isRequired,
  weekEndList: PropTypes.instanceOf(Array),
  userList: PropTypes.arrayOf(Object),
  holidayList: PropTypes.instanceOf(Array),
  leaveList: PropTypes.arrayOf(Object),
};

const defaultProps = {
  weekEndList: [],
  userList: [],
  holidayList: [],
  leaveList: [],
};

const dsepropTypes = {
  dateList: PropTypes.instanceOf(Array).isRequired,
  dse: PropTypes.instanceOf(Object),
  rosterList: PropTypes.instanceOf(Array),
  weekEndList: PropTypes.instanceOf(Array),
  leaveList: PropTypes.arrayOf(Object),
  holidayList: PropTypes.instanceOf(Array),
};

const dsedefaultProps = {
  dse: {},
  rosterList: [],
  weekEndList: [],
  leaveList: [],
  holidayList: [],
};

const getDseEvent = (date, rosterList, leaveList, holidayList) => {
  const routeDetails = rosterList.find((e) => e.date === date) || {};
  const leave = leaveList.find((lv) => lv.date === date) || null;
  const holiday = holidayList.find((hld) => hld.date === date) || null;

  const routeList = routeDetails.routeList ? routeDetails.routeList : [];

  return {
    routeList,
    leave,
    holiday,
  };
};

// onClick={() => value.handleUserClick(dse)}

const DseDetails = ({ dse, rosterList = [], dateList, weekEndList = [], leaveList, holidayList,routeList, callRosterPermission }) => (
  <DialogContext.Consumer>
    {(value) => (
      <DSEWeeklyViewStyled>
        <div
          className={dse.id === value.activeUser ? 'active title' : 'title'}
          onClick={() => value.handleUserClick(dse, CALENDAR_PREVIEW.WEEKLY)}
        >
          <span>{dse.fullName}</span>
        </div>
        <div className={dse.id === value.activeUser ? 'active calendar-days' : 'calendar-days'}>
          <div className='calendar-days-block'>
            {dateList.map((date, index) => (
              <WeeklyUnit
                date={date}
                weekEndList={weekEndList}
                event={getDseEvent(date, rosterList, leaveList, holidayList)}
                user={dse}
                key={index}
                routeList={routeList}
                callRosterPermission={callRosterPermission}
              />
            ))}
          </div>
        </div>
      </DSEWeeklyViewStyled>
    )}
  </DialogContext.Consumer>
);

const DsePresenter = ({ ...props }) => {
  const { data, dateList, weekEndList, leaveList, holidayList, userList,routeList, callRosterPermission } = props;

  return (
    <div className='calendar-bottom-content'>
      {userList.map((dse) => {
        const rosterList = data.filter((dseRoster) => dseRoster.userId === dse.id);
        const selectedLeaveList = leaveList.filter((leave) => leave.requestedBy === dse.id);

        let selectedRoster = [];

        if (rosterList.length > 0) {
          selectedRoster = rosterList[0].data;
        }

        return (
          <DseDetails
            dse={dse}
            rosterList={selectedRoster}
            dateList={dateList}
            weekEndList={weekEndList}
            leaveList={selectedLeaveList}
            holidayList={holidayList}
            key={dse.id}
            routeList={routeList}
            callRosterPermission={callRosterPermission}
          />
        );
      })}
    </div>
  );
};

DseDetails.propTypes = dsepropTypes;

DseDetails.defaultProps = dsedefaultProps;

DsePresenter.propTypes = propTypes;

DsePresenter.defaultProps = defaultProps;

export default DsePresenter;
