import React from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import AccordionTable from '../../../../components/CollapsibleTable/AccordionTable';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

const propTypes = { data: PropTypes.arrayOf(Object) };

const defaultProps = {
  data: [],
};

const View = ({ ...props }) => {
  const { data } = props;
  const tableData = (childUser, collapsible = false) => (
    <>
      <td>
        <span>{collapsible ? <span>{childUser.name}</span> : childUser.name}</span>
      </td>
      <td>{childUser.role}</td>
      <td>{fixedFloatAndCommas(childUser.scheduled)}</td>
      <td>{fixedFloatAndCommas(childUser.successful)}</td>
      <td>{fixedFloatAndCommas(childUser.unsuccessful)}</td>
      <td>{fixedFloatAndCommas(childUser.remaining)}</td>
      <td>{`${fixedFloatAndCommas(childUser.productivity)}%`}</td>
    </>
  );

  const getCollapsibleComponent = (dataList) => (
    <AccordionTable>
      {dataList.map((user) => (
        <tr
          key={user.id}
          trigger={
            <>
              <td key={`${user.id}-name`}>
                <span>{user.name}</span>
              </td>
              <td key={`${user.id}-role`}>{user.role}</td>
              <td key={`${user.id}-scheduled`}>{fixedFloatAndCommas(user.scheduled)}</td>
              <td key={`${user.id}-successful`}>{fixedFloatAndCommas(user.successful)}</td>
              <td key={`${user.id}-unsuccessful`}>{fixedFloatAndCommas(user.unsuccessful)}</td>
              <td key={`${user.id}-remaining`}>{fixedFloatAndCommas(user.remaining)}</td>
              <td key={`${user.id}-productivity`}>{`${fixedFloatAndCommas(user.productivity)}%`}</td>
            </>
          }
          isChildPresent={!user.isChildPresent}
          simpleChild={
            <tr className='active' key={`${user.id}-children`}>
              {tableData(user)}
            </tr>
          }
        >
          <AccordionTable>
            {user.childUsers.map((childUser1) => (
              <tr
                key={childUser1.id}
                trigger={tableData(childUser1, true)}
                isChildPresent={!childUser1.isChildPresent}
                simpleChild={
                  <tr className='active' key={childUser1.id}>
                    {tableData(childUser1)}
                  </tr>
                }
              >
                <AccordionTable>
                  {childUser1.childUsers.map((childUser2) => (
                    <tr
                      key={childUser2.id}
                      trigger={tableData(childUser2, true)}
                      isChildPresent={!childUser2.isChildPresent}
                      simpleChild={
                        <tr className='active' key={childUser2.id}>
                          {tableData(childUser2)}
                        </tr>
                      }
                    >
                      <AccordionTable>
                        {childUser2.childUsers.map((childUser3) => (
                          <tr
                            key={childUser3.id}
                            trigger={tableData(childUser3, true)}
                            isChildPresent={!childUser3.isChildPresent}
                            simpleChild={
                              <tr className='active' key={childUser3.id}>
                                {tableData(childUser3)}
                              </tr>
                            }
                          >
                            <AccordionTable>
                              {childUser3.childUsers.map((childUser4) => (
                                <tr
                                  key={childUser4.id}
                                  trigger={tableData(childUser4, true)}
                                  isChildPresent={!childUser4.isChildPresent}
                                  simpleChild={
                                    <tr className='active' key={childUser4.id}>
                                      {tableData(childUser4)}
                                    </tr>
                                  }
                                >
                                  {childUser4.isChildPresent &&
                                    childUser4.childUsers.length !== 0 &&
                                    childUser4.childUsers.map((childUser5) => (
                                      <tr className='active' key={childUser5.id}>
                                        {tableData(childUser5)}
                                      </tr>
                                    ))}
                                </tr>
                              ))}
                            </AccordionTable>
                          </tr>
                        ))}
                      </AccordionTable>
                    </tr>
                  ))}
                </AccordionTable>
              </tr>
            ))}
          </AccordionTable>
        </tr>
      ))}
    </AccordionTable>
  );

  return (
    <div className='table-wrap'>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Scheduled</th>
            <th>Successful</th>
            <th>Unsuccessful</th>
            <th>Remaining</th>
            <th>Productivity</th>
          </tr>
        </thead>
        <tbody>{getCollapsibleComponent(data)}</tbody>
      </table>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
