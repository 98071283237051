import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_ROUTE_SEGMENTATION_LIST } from '../../../salesForce/routeSegmentation/API';

const GET_FOCUSED_BASIC_DETAIL = gql`
  query getBasicDetail($value: String) {
    focusedSKUs(
      filter: { filters: [{ column: "id", value: [$value] }, { column: "active", value: ["true", "false"] }] }
    ) {
      rows {
        id
        lineId
        title
        start
        end
        active
        FocusedSKUDetails {
          id
          channelId
          categoryId
          brandId
          skus
        }
      }
    }
  }
`;

const GET_BRAND_DETAILS = gql`
  query getBrandDetails($catalogId: Int, $lineId: Int) {
    catalogDetails(catalogId: $catalogId, lineId: $lineId) {
      rows {
        id
        title
        lineId
        SKUs {
          id
          title
        }
      }
    }
  }
`;

const GET_SKU_DETAILS = gql`
  query getBrandsSkus($value: Int, $lineId: Int) {
    catalogLevelDetails(catalogId: 3, catalogDetailId: $value, lineId: $lineId) {
      count
      rows {
        id
        title
        SKUs {
          id
          title
        }
        SkuFamilies {
          title
          id: catalogDetailId
          skuCount
        }
      }
    }
  }
`;

const GET_FOCUSED_SKUS_COUNT = gql`
query getFocusSkuCount($focusedSkuId: Int, $channelId: Int, $categoryId: Int, $brandId: Int) {
  getFocusedSkuDetails(focusedSkuId: $focusedSkuId, channelId: $channelId, categoryId: $categoryId, brandId: $brandId) {
    count
    rows {
      Brand {
        count
        id
      }
      Category {
        count
        id
      }
      Channel {
        count
        id
      }
      SkuFamily {
        count
        id
        skus: skuFamilies
        isPrimary
        isSecondary
      }
      SkuGroup {
        id
        skus
        count
        isPrimary
        isSecondary
      }
      focusedSkuId
    }
  }
}
`;

const GET_SKUS_List = gql`
query catalogLevelDetails($catalogId:Int, $catalogDetailId:Int){
  catalogLevelDetails(catalogId:$catalogId,catalogDetailId:$catalogDetailId){
    rows {
      title
      SKUs{
        id
        title
      }
    }
  }
  }
  `;

const CREATE_FOCUSED_SKUS = graphql(
  gql`
    mutation createFocusedSkus($input: FocusedSKUDetailsInput!) {
      insertFocusedSkuDetails(input: $input) {
        id
        channelId
        categoryId
        brandId
        skus
        sku_family
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      insertFocusedSkuDetails: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DELETE_FOCUSED_SKU = graphql(
  gql`
    mutation deleteFocusedSkuDetails($id: Int!) {
      deleteFocusedSkuDetails(id: $id) {
        id
        message
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      deleteFocusedSkuDetails: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getSkuDetails: GET_SKU_DETAILS,
  getBasicDetail: GET_FOCUSED_BASIC_DETAIL,
  getFocusSkuCount: GET_FOCUSED_SKUS_COUNT,
  getLineDetails: GET_ROUTE_SEGMENTATION_LIST,
  getSKUList: GET_SKUS_List,
};

const mutation = {
  insertFocusedSkuDetails: CREATE_FOCUSED_SKUS,
  deleteFocusedSkuDetails: DELETE_FOCUSED_SKU,
};

export {
  query, mutation, CREATE_FOCUSED_SKUS, DELETE_FOCUSED_SKU
};
