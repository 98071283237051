import React, { Component } from 'react';
import withAlert from '../../../utils/composition/withAlert'
import PropTypes from 'prop-types';
import View from './View';
import { labelConfig } from './tableConfig';
import * as queryService from '../../base/query.service';
import { CALLPLAN_TABS,tabMapper } from './config';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../utils/api';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { fetchFromLocalStorage, getSortValueAfterValueCheck, isError, storeInLocalStorage } from '../../common/HelperFunctions';
import { clone } from '../../../utils/objectProcessor';
import { TAS_OUTLET } from '../../../data/enums/Route';
import CallPlanWrapper from './CallPlanWrapStyled';
import CallDashboard from './CallDashboard/index';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';
import { USER_ROLE } from '../../../data/enums';
import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { getPermissionForCallPlan } from '../../base/permission';

const propTypes = {
  payments: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};



class CallPlan extends Component {
  
  constructor(props) {
      const {roleId} = JSON.parse(localStorage.getItem('rosia-detail'))||{roleId:2}
          
      const user = JSON.parse(localStorage.getItem('rosia-detail'))||{roleId:2};

      const isTasTeamLead = (roleId) => {
        return  USER_ROLE.TAS_TEAM_LEAD === roleId;
  }

    const filterConfig = isTasTeamLead(roleId)?getFilterConfig([ FILTER.ROUTE,FILTER.SUB_D,FILTER.TAS_USER]):getFilterConfig([ FILTER.ROUTE,FILTER.SUB_D]);

    super(props);
    this.filterConfigMenu = clone(filterConfig.menu);
    queryService.resetBaseQueryParameters();
    this.state = {
      pending: {
        list: [],
        total: 0,
      },
      planned: {
        list: [],
        total: 0,
      },
      completed: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.singleDate },
        sort: queryService.baseQueryParameters.sort,
      },
      filterIntance: filterConfig.instance||{},
      filterMenu: this.filterConfigMenu || {},
      activeTab: props.location.state?.plannedTab || props.location.state?.tab || 0,
      tableInfo: labelConfig(props.location.state?.plannedTab || props.location.state?.tab || 0),
    };
    const { displayAlert } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getInitialData,
      displayAlert,
    );

    this.permission = getPermissionForCallPlan()

    this.getData = () => {
      const { pending } = this.state;
      return pending;
    };

    this.tabListGetterMapper = {
      [CALLPLAN_TABS.PENDING]: this.getCallPlanList,
      [CALLPLAN_TABS.PLANNED]: this.getPlannedList,
      [CALLPLAN_TABS.COMPLETED]: this.getCompletedList,
    };
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search },activeTab } = this.state;
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.TAS_CALL_PLAN
    );
  };

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  getFilterStateFromStorage = () => {
    const { activeTab ,filterIntance,filterMenu} = this.state;
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.TAS_CALL_PLAN);
    if (!localData) {
      return this.getInitialData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: localData.filter,
          searchText: localData.searchText,
          date: localData.date
        },
        filterMenu: localData.filterMenu,
        activeTab: activeTab,
      },
      () => {
        this.getInitialData();
      },
    );
  };

  getInitialData = () => {
    this.getCallPlanList();
    this.getCountPlannedList();
    this.getCountCompletedList();
    const { activeTab } = this.state;
    this.fetchActiveTabPaginatedList(activeTab);
    this.getCountPendingList();
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleTabChange = tab => {
    const { tableInfo } = this.state;
    this.setState(
      {
        activeTab: tab,
        tableInfo: labelConfig(tab),
      },
      () => this.fetchActiveTabPaginatedList(tab),
    );
  };

  fetchActiveTabPaginatedList = tab => {
    this.setFilterStateInStorage();
    const type = tabMapper[tab];
    this.tabListGetterMapper[type]();
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  columnSelectChange = data => {
    this.setState({
      tableInfo: data,
    });
  };

  onButtonClick = () => {
    this.getInitialData();
  };

  handleCreateClick = (type, id) => {
    const { history } = this.props;
    history.push(`/${TAS_OUTLET}`);
  };

  getCallPlanList = tabStatus => {
    const { pending, queryParameters, activeTab, completed } = this.state;
    const { getCallPlanList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    const status = tabStatus || tabMapper[activeTab];
    getCallPlanList(
      {
        limit: queryParameters.pagination.limit,
        offset,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            ...[
              {
                column: 'call_status',
                value: [status.toUpperCase()],
              },
            ],
          ],
          dateRange: {
            ...queryParameters.date,
            filterBy: 'tas_user_call_plan.local_date',
          },
          queryString: queryParameters.search,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      },
      {
        handleSuccess: response => {
          this.setState({
            ...this.state, [status]: {
              total: (response.data.getCallPlanList && response.data.getCallPlanList.count) || 0,
              list: (response.data.getCallPlanList && response.data.getCallPlanList.rows) || [],
            }
          });
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };



  getCountCallPlanList = tabStatus => {
    const { pending, queryParameters, activeTab, completed } = this.state;
    const { getCountCallPlanList } = this.props;
    const status = tabStatus || tabMapper[activeTab];

    getCountCallPlanList(
      {
        filter: {
          dateRange: {
            ...queryParameters.date,
            filterBy: 'tas_user_call_plan.local_date',
          },
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            ...[
              {
                column: 'call_status',
                value: [status.toUpperCase()],
              },
            ],
          ],
        },
      },
      {
        handleSuccess: response => {
          this.setState({
            ...this.state, [status]: {
              total: (response?.data?.getCountCallPlanList?.count) || 0,
              list: [],
            }
          });
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };



  getPlannedList = () => {
    this.getCallPlanList(CALLPLAN_TABS.PLANNED);
  };

  getCompletedList = () => {
    this.getCallPlanList(CALLPLAN_TABS.COMPLETED);
  };

  getCountPlannedList = () => {
    this.getCountCallPlanList(CALLPLAN_TABS.PLANNED)
  }

  getCountCompletedList = () => {
    this.getCountCallPlanList(CALLPLAN_TABS.COMPLETED)
  }

  getCountPendingList = () => {
    this.getCountCallPlanList(CALLPLAN_TABS.PENDING)
  }



  render() {
    const { activeTab, tableInfo, pending, planned, completed, queryParameters, filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <CallPlanWrapper>
        <View
          pending={pending}
          planned={planned}
          completed={completed}
          activeTab={activeTab}
          onTabChange={this.handleTabChange}
          tableConfig={tableInfo}
          columnSelectChange={this.columnSelectChange}
          queryParameters={queryParameters}
          filterMenu={filterMenu}
          basePaginationService={this.basePaginationService}
          handleFilterChange={this.basePaginationService.handleFilterChange}
          onButtonClick={this.onButtonClick}
          loading={serverResponseWaiting}
          handleDownloadClick={this.handleDownloadClick}
          permission={this.permission}
          handleCreateClick={this.handleCreateClick}
          onHeaderClick={this.basePaginationService.handleTableSorting}
        />
        <CallDashboard />
      </CallPlanWrapper>
    );
  }
}

CallPlan.propTypes = propTypes;

CallPlan.defaultProps = defaultProps;


export default withAlert()(CallPlan)
