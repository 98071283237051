import React from 'react';
import { CheckBox } from '../../../../../v4/components';

const RouteCards = ({ ...props }) => {
  const { routesList, onRouteCheck, routeCardClick } = props;
  return (
    <div className='route-li-wrap'>
      {routesList.map((route, i) => {
        return (
          <div
            className='routeRow'
            key={i}
            style={{
              borderLeft: `4px solid ${route.color}`,
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            }}
          >
            <CheckBox key={route.id} onCheckBoxClick={(e) => onRouteCheck(e, route.id)} checked={route.check} />
            <div
              className='info-li'
              onClick={(e) => routeCardClick(null, route.id, route.title)}
              style={{ cursor: 'pointer' }}
            >
              <p className='routeText'>{`${route.title}`}</p>
              <p className='outletText'>{route.RetailOutlets.length} outlets</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RouteCards;
