import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { Badge } from '../../../v4/components';

const labelMappings = {
  binCode: 'binCode',
  openingBalance: 'openingBalance',
  inward: 'inward',
  outward: 'outward',
  adjustment: 'adjustment',
  closingBalance: 'closingBalance',
  damages: 'damages',
  shortages: 'shortages',
  expiry: 'expiry',
  options: 'options',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.binCode,
    title: 'Bin Code',
    show: true,
    sortable: false,
    className: 'sorting first-th',
  },
  {
    id: 4,
    label: labelMappings.openingBalance,
    title: 'Saleable',
    classNames: 'text-right',
    show: true,
    sortable: false,
    className: 'sorting text-right  second width_300',
  },
  {
    id: 7,
    label: labelMappings.damages,
    classNames: 'text-right',
    title: 'Damage',
    show: true,
    sortable: false,
    className: 'sorting text-right third   width_300',
  },
  {
    id: 8,
    label: labelMappings.expiry,
    classNames: 'text-right',
    title: 'Expiry',
    show: true,
    sortable: false,
    className: 'sorting text-right third   width_300',
  },
  {
    id: 9,
    label: labelMappings.options,
    classNames: 'text-right',
    title: '',
    show: true,
    sortable: false,
    className: 'sorting text-right third   width_30',
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <>
    <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
  </>
);

const returnTableBody = (data, onRowClick) => {
  const table = {};
  table[labelMappings.binCode] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-binCode`}>
      <span className="caret">
        {data?.bin_code} <Badge className="light" label={data?.count ?? 0} />
      </span>
    </td>
  );
  table[labelMappings.openingBalance] = (
    <td
      onClick={() => onRowClick(data)}
      key={`warehouseReport-${data.binCode}-openingBalance`}
      className="text-right width_300"
    >
      {data?.sellable}
    </td>
  );
  table[labelMappings.damages] = (
    <td
      onClick={() => onRowClick(data)}
      key={`warehouseReport-${data.binCode}-damages`}
      className="text-right width_300"
    >
      {data?.damaged}
    </td>
  );
  table[labelMappings.expiry] = (
    <td
      onClick={() => onRowClick(data)}
      key={`warehouseReport-${data.binCode}-expiry`}
      className="text-right width_300"
    >
      {data?.expired}
    </td>
  );
  table[labelMappings.options] = (
    <td
      onClick={() => onRowClick(data)}
      key={`warehouseReport-${data.binCode}-options`}
      className="text-right width_82"
    >
      {/* <Button small secondary title="Add" onClick={() => handleCreate?.(event, data)} /> */}
    </td>
  );

  return table;
};

const TableBody = ({ data, onRowClick }) => {
  const tableBody = returnTableBody(data, onRowClick);

  return labelConfig.map(label => (label.show ? tableBody[label.label] : null));
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
};
export { TableHeaderConfig as TableHeader, TableBody };
