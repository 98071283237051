import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_DISTRIBUTORS } from '../../common/GqlAPI';

const GET_DISTRIBUTOR_TARGET = gql`
query getDistributorTarget($limit: Int, $offset: Int, $filter: FilterInput) {
  getDistributorTarget(limit: $limit,offset: $offset,filter: $filter) {
    isDistributorTargetDerived
    rows {
      id
      distributorId: distributor_id
      month
      target
      title
      year
      date
    }
     total
  }
}
`;

const CREATE_DISTRIBUTION_TARGET = graphql(
  gql`
  mutation ($input: DistributorIcoTargetInput!) {
    createDistributorIcoTarget(input: $input) {
      id
      title
      year
      month
      target
      date
      distributor_id
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      createDistributorIcoTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  }
)

const EDIT_DISTRIBUTION_TARGET = graphql(
  gql`
  mutation ($id: Int!, $input: DistributorIcoTargetInput!) {
    editDistributorIcoTarget(id:$id, input: $input) {
      id
      title
      year
      month
      target
      date
      distributor_id
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      editDistributorIcoTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  }
)

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getDistributorTarget: GET_DISTRIBUTOR_TARGET,
};

const mutation = {
  createDistributorIcoTarget: CREATE_DISTRIBUTION_TARGET,
  editDistributorIcoTarget: EDIT_DISTRIBUTION_TARGET,
};

export {
  query, mutation, CREATE_DISTRIBUTION_TARGET, EDIT_DISTRIBUTION_TARGET
};
