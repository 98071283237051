import PropTypes from 'prop-types';
import React from 'react';
import VerticalListWithCount from '../../../../components/VerticalListWithCount';
import { Search } from '../../../../../components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { PermissionContext } from '../config';
import AssortmentPanelStyled from '../../../channelAssortment/details/AssortmentPanelStyled';
import { Switch } from '../../../../../v4/components';

const propTypes = {
  getDistributorDetails: PropTypes.func.isRequired,
  groupCountTerritoryWise: PropTypes.instanceOf(),
  handleSearchInput: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(),
  primarySwitchHandler: PropTypes.func.isRequired,
  secondarySwitchHandler: PropTypes.func,
  selectedSubDsLength: PropTypes.number,
  subDs: PropTypes.instanceOf(),
  territories: PropTypes.instanceOf(),
};

const defaultProps = {
  groupCountTerritoryWise: [],
  permission: PERMISSION_OBJ,
  selectedSubDsLength: 0,
  subDs: [],
  territories: [],
};
const SubDGroupView = ({
  territories,
  groupCountTerritoryWise,
  getDistributorDetails,
  subDs,
  territoryId,
  selectedSubDs,
  onSwitchChange,
  handleSearchInput,
  selectableSubDs,
}) => {
  const getPrimarySwitchStatus = () => selectableSubDs.length !== 0 && selectableSubDs.length === selectedSubDs.length;
  return (
    <PermissionContext.Consumer>
      {({ permission }) => (
        <AssortmentPanelStyled>
          <div className='panel-section darkshade-bg create-ui-inner'>
            <VerticalListWithCount
              list={territories}
              countList={groupCountTerritoryWise}
              onItemClick={getDistributorDetails}
              activeLine={territoryId}
              title='Zone'
            />
            {territoryId && (
              <>
                <div className='panel three'>
                  <div className='panel-title'>
                    <p className='p-title'>SUB D</p>
                    <Search handleSearchInput={handleSearchInput} />
                  </div>
                  <div className='table-wrap'>
                    <table>
                      <thead>
                        <tr>
                          <th>Sub D</th>
                          <th />
                          <th className='text-right switch'>
                            <span>Status</span>
                            <Switch
                              checked={getPrimarySwitchStatus()}
                              onToggle={(e) => onSwitchChange(e, selectableSubDs)}
                              disabled={selectableSubDs.length === 0}
                            />
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {subDs.map((subD) => (
                          <tr>
                            <td>{subD.title}</td>
                            <td />
                            <td className='text-right'>
                              <Switch
                                checked={selectedSubDs.includes(subD.distributorId)}
                                onToggle={(e) => onSwitchChange(e, [subD.distributorId])}
                                disabled={subD.used}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </AssortmentPanelStyled>
      )}
    </PermissionContext.Consumer>
  );
};

SubDGroupView.prototype = propTypes;

SubDGroupView.defaultProps = defaultProps;

const SubDGroupViewWithError = withErrorAndLoadingScreen(SubDGroupView);

export default SubDGroupViewWithError;
