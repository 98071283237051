import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Tab, TabList, Tabs, TabPanel } from '../../../v4/components';
import GrnView from './View';
import GrnForm from '../../common/CSVUploadForm';
import { DialogFormWrapper } from '../../common';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import {
  title,
  GRN_TABS,
  GRN_TABS_VALUE,
  tabMapper,
  formConfig,
  filterConfig,
  breadCrumbConfig,
  crudSuccess as crudRequestConfig,
} from './config';
import PageHeader from '../../base/PageHeader';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import Confirmation from '../../common/DialogConfirmation';
import { getPermissionForGrn } from '../../base/permission';
import withAlert from '../../../utils/composition/withAlert';
import { fetchAndSetSubDList } from '../../../data/services/subD';
import {
  fetchFromLocalStorage,
  getFromLocalStorage,
  getSortValueAfterValueCheck,
  isConfirmationType,
  isError,
  storeInLocalStorage,
} from '../../common/HelperFunctions';
import { GRN_CREATE, GRN_DETAILS } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { clone } from '../../../utils/objectProcessor';
import { PROGRAM } from '../../../data/enums/Permission';
import SrnView from '../../sales/srn/View';
import { JWT } from '../../../environment';
import { getUser } from '../../../data/services';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  upload: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getGrnList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class Grn extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    const sapEnabled =
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS) &&
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS).sapIntegration;
    const billingEnabled = getUser().Distributor.length ? getUser().Distributor[0].servicesUsed.billing.status : false;
    const manualGRNEnabled = getUser().Distributor.length ? getUser().Distributor[0].enableManualGRN : false;
    const lndEnabled = getUser().Distributor.length ? getUser().Distributor[0].servicesUsed.logistic.status : false;

    this.state = {
      data: {
        list: [],
        total: 0,
      },
      dialog: {
        type: '',
        element: '',
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: {},
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      sapEnabled,
      billingEnabled,
      manualGRNEnabled,
      lndEnabled,
      activeTab: sapEnabled || billingEnabled ? 0 : 1,
    };
    this.permission = getPermissionForGrn();
    this.permissionUpdated = { ...this.permission, cancel: true };

    const { downloadReport, displayAlert } = this.props;

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
      /* [EVENT_OPERATION.DELETE]: props.toggleState, */
      [EVENT_OPERATION.BULK_DELETE]: props.toggleState,
      [EVENT_OPERATION.CANCEL]: props.cancelGRN,
    };

    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        let message = crudRequestConfig[type].message;
        if (type === EVENT_OPERATION.UPLOAD) {
          message = data;
        } else {
          this.setState(data);
        }
        displayAlert(ALERT_TYPE.SUCCESS, message);
        this.loadTableData();
      },
      this.getData,
      ['title'],
    );
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.tabListGetterMapper = {
      [GRN_TABS.PENDING]: this.loadTableData,
      [GRN_TABS.GRN]: this.loadTableData,
    };
  }

  setFilterStateInStorage = () => {
    const {
      filterMenu,
      queryParameters: { filter, date, search },
      activeTab,
    } = this.state;
    storeInLocalStorage('TABINDEX', activeTab, DOMAIN.GRN);
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.GRN,
    );
  };

  componentDidMount() {
    this.getFilterStateFromStorage();
    // TODO, need to update in up comming sprint
    fetchAndSetSubDList();
  }

  getFilterStateFromStorage = () => {
    const { activeTab } = this.state;
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.GRN);
    const localTab = fetchFromLocalStorage('TABINDEX', DOMAIN.GRN);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      }),
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
        activeTab: localTab || activeTab,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  fetchActiveTabPaginatedList = tab => {
    const type = tabMapper[tab];
    this.setFilterStateInStorage();
    this.tabListGetterMapper[type]();
  };

  loadTableData = () => {
    const { data, queryParameters, activeTab } = this.state;
    const { getGrnList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    // all urls for search, filter and pagination
    this.setFilterStateInStorage();
    getGrnList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            {
              column: 'status',
              value: activeTab === 0 ? 'RECEIVED' : 'APPROVED',
            },
          ],
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      },
      {
        handleSuccess: response => {
          const { goodsReceivedNotes = {} } = response.data;
          data.list = goodsReceivedNotes ? goodsReceivedNotes.rows : [];
          data.total = goodsReceivedNotes ? goodsReceivedNotes.count : 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleIconClick = (type, element, attribute = 'id') => {
    const { history } = this.props;

    switch (type) {
      case EVENT_OPERATION.CREATE:
        history.push(`/${GRN_CREATE}`);
        break;
      case EVENT_OPERATION.APPROVE:
        // eslint-disable-next-line no-case-declarations
        const detailId = element ? element.id : '';
        history.push(`/${GRN_DETAILS}/${attribute}/${detailId}`);
        break;
      case EVENT_OPERATION.UPDATE:
        // eslint-disable-next-line no-case-declarations
        const id = element ? element.id : '';
        history.push(`/${GRN_DETAILS}/${id}`);
        break;
      case EVENT_OPERATION.UPLOAD:
        this.updateDialog(type, element);
        break;
      case EVENT_OPERATION.CANCEL:
        this.cancelPending(type, element);
        break;
      default:
        break;
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  updateDialog = (type = '', element = {}) => {
    // fetch all the necessary data for dialog here;
    this.setState({
      dialog: {
        type: typeof type === 'string' ? type : '',
        element,
      },
    });
  };
  cancelPending = (type = '', element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  handleTabChange = tab => {
    this.setState(
      {
        activeTab: tab,
      },
      () => this.fetchActiveTabPaginatedList(tab),
    );
  };

  handleDownloadClick = (reportType, domain) => {
    const { queryParameters, activeTab } = this.state;
    const tabDownloadFilterValue = activeTab === GRN_TABS_VALUE.PENDING ? ['RECEIVED', 'CANCELLED'] : ['APPROVED'];
    const filter = {
      filters: [
        ...apiFilterProcessor(queryParameters.filter),
        {
          column: 'status',
          value: tabDownloadFilterValue,
        },
      ],
      queryString: queryParameters.search,
      dateRange: queryParameters.date,
    };

    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
  };

  render() {
    const {
      data,
      dialog,
      display,
      sapEnabled,
      billingEnabled,
      manualGRNEnabled,
      lndEnabled,
      activeTab,
      queryParameters,
      filterMenu,
    } = this.state;
    const { type } = dialog;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          {type && (
            <DialogFormWrapper
              onDialogSubmit={this.onFormSubmit}
              formConfig={formConfig[type]}
              dialogElement={dialog.element}
              onDialogCancel={this.updateDialog}
              type={type}
              activeStatus={type === EVENT_OPERATION.CANCEL}
              renderDialog={({ dialogData, enableErrorDisplay, handleFileUpload, refsObj }) => (
                <Fragment>
                  {type === EVENT_OPERATION.UPLOAD && (
                    <GrnForm
                      show
                      data={dialogData}
                      loading={serverResponseWaiting}
                      handleFileUpload={handleFileUpload}
                      enableErrorDisplay={enableErrorDisplay}
                      refsObj={refsObj}
                    />
                  )}
                  {isConfirmationType(type) && Confirmation(type)}
                </Fragment>
              )}
            />
          )}
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create:
                  ((sapEnabled && manualGRNEnabled) ||
                    (!sapEnabled && manualGRNEnabled) ||
                    (!sapEnabled && !manualGRNEnabled)) &&
                  this.permission.create,
                download: true,
                filter: true,
                upload: false,
                date: true,
                search: true,
              }}
              downloadConfig={{
                domain: PROGRAM.GRN,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.handleIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className={`section-content table-present ${sapEnabled || billingEnabled ? 'section-tab' : ''}`}>
          <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.handleTabChange(tabIndex)}>
            {(sapEnabled || billingEnabled) && (
              <TabList>
                <Tab>
                  <span className="tab-label">Pending</span>
                </Tab>
                <Tab>
                  <span className="tab-label">GRN</span>
                </Tab>
              </TabList>
            )}
            <div className="tab-container">
              <TabPanel>
                <section id="pending-tab">
                  <div id="detail-tab">
                    <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                      <GrnView
                        data={data}
                        sapEnabled={sapEnabled}
                        billingEnabled={billingEnabled}
                        lndEnabled={lndEnabled}
                        pagination={queryParameters.pagination}
                        loading={serverResponseWaiting}
                        onIconClick={this.handleIconClick}
                        onPageChange={this.basePaginationService.onPageSelect}
                        onHeaderClick={this.basePaginationService.handleTableSorting}
                        permission={this.permissionUpdated}
                      />
                    </div>
                  </div>
                </section>
              </TabPanel>
              <TabPanel>
                <section id="received-tab">
                  <div id="detail-tab">
                    <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                      <GrnView
                        data={data}
                        pagination={queryParameters.pagination}
                        loading={serverResponseWaiting}
                        onIconClick={this.handleIconClick}
                        onPageChange={this.basePaginationService.onPageSelect}
                        onHeaderClick={this.basePaginationService.handleTableSorting}
                        approvedGrn
                        permission={this.permissionUpdated}
                      />
                    </div>
                  </div>
                </section>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

Grn.propTypes = propTypes;

Grn.defaultProps = defaultProps;

export default withAlert()(Grn);
