import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RosterView from './View';
import { getTodaysDate } from '../../../utils/date';
import RosterStyled from './RosterStyled';
import { BreadCrumb,Button } from '../../../v4/components';
import DialogProcessor from './RosterDialogProcessor';
import * as rosterService from './Common/roster.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { CALENDAR_PREVIEW, USER_ROLE } from '../../../data/enums';
import { LIMIT, OFFSET } from '../../../data/enums/GeneralConstants';
import { MAX_LIMIT_OFFSET_FILTER } from '../../../data/enums/filter';
import { PanelHeader, PanelStyled } from '../../common/configuration';
import { HEADER_ICONS, breadCrumbConfig as breadCrumb,title } from './config';
import { getUser } from '../../../data/services';
import {
  getDateList,
  getCurrentWeek,
  getCurrentMonth,
  getWeekStartDayFromWeekEnds,
  WEEK_DAYS,
  weekEndSundayResolver,
} from './date';
import { ADMIN_ROLE_GROUP,DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { USER_GROUP } from '../../../data/enums/UserGroup';
import { isError } from '../../common/HelperFunctions';

const propTypes = {
  create: PropTypes.func,
  replicate: PropTypes.func,
  update: PropTypes.func,
  getUserRoleList: PropTypes.func.isRequired,
  getSubDHolidays: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  getRouteList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getRouteVisitFrequencyList: PropTypes.func.isRequired,
  getList: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  create: () => null,
  replicate: () => null,
  update: () => null,
  serverResponseWaiting: false,
};

/**
 * Holiday: WeekEnd and Calendar Holiday
 * data: DSE Roster and LEave details
 */
class Roster extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    const weekStartDay = WEEK_DAYS.SUNDAY;
    const today = getTodaysDate();
    const week = getCurrentWeek(today, weekStartDay);
    const month = getCurrentMonth();

    this.state = {
      week: {
        start: week.start,
        end: week.end,
      },
      month: {
        start: month.start,
        end: month.end,
      },
      printMode: false,
      weekStartDay,
      calendarPreview: CALENDAR_PREVIEW.WEEKLY,
      roleId: 0,
      distributorId: null,
      subDId: null,
      dseId:null,
      subDDetails: {
        id: 3,
        title: '',
        address: '',
        callRosterLocked: false,
      },
      userId: null,
      weekEndList: [WEEK_DAYS.SATURDAY],
      holidayList: [],
      userRoleList: [],
      userList: [],
      routeList: [],
      leaveList: [],
      rosterList: [],
      dseList:[],
      routeVisitFrequencyList: [],
      displayReplicationDialog: false,
      callRosterPermission:{},
    };
  }

  componentDidMount() {
      this.loadRouteVisitFrequencyList();
      this.loadUserRoleAndUserList();
    const permissions= JSON.parse(localStorage.getItem('-rosia-permission'));
    const callRosterPermission =permissions.find(a=>a.program === title)?.permission;
    this.setState({callRosterPermission});
    window.onafterprint = this.handleAfterPrint;
  }

  handleAfterPrint = () => {
    this.setState({ printMode: false });
  };

  handleResetDialog = () =>{
    this.setState({
      subDId:null,
      dseId:null,
    })
  }

  loadUserRoleAndUserList = () => {
    const { getUserRoleList } = this.props;
    const { subDDetails } = this.state;
    getUserRoleList(
      {
        offset: 0,
        limit:50,
        filter: {
          filters: [{
            column: "user_group_id", 
            value: [USER_GROUP.MANAGER.toString(),USER_GROUP.SUPERVISOR.toString()]}
          ]
        }
      },
      {
        handleSuccess: res => {
          const userRoleList = res.data.roles.rows;
          this.setState({ userRoleList });
        },
      },
    );
  };

  loadFullDataAfterSubDTownSelection = () => {
    this.loadFullRosterDataFirstTime();
  };

  loadFullRosterDataFirstTime = () => {
    const { calendarPreview } = this.state;
    if (calendarPreview === CALENDAR_PREVIEW.WEEKLY) {
      this.loadHolidayForWeeklyView(this.loadRosterData, true);
    } else {
      this.loadHolidayListForMonthlyView();
      // this.loadRosterData();
    }
  };

  loadFullRosterData = () => {
    const { calendarPreview } = this.state;
    if (calendarPreview === CALENDAR_PREVIEW.WEEKLY) {
      this.loadHolidayForWeeklyView(this.loadRosterData);
    } else {
      this.loadHolidayListForMonthlyView();
      this.loadRosterData();
    }
  };

  loadHolidayForWeeklyView = (callBack = () => null, updateWeek = false) => {
    const { getSubDHolidays } = this.props;

    const { week, roleId } = this.state;
    const dateRange = updateWeek ? rosterService.weekExtension(week) : week;

    // load holidayList on the basis of weekly and Monthly View.
    getSubDHolidays(
      {
        filter: {
          filters: [
            {
              column: 'distributor_id',
              value: [roleId.toString()],
            },
          ],
          dateRange: {
            start: dateRange.start,
            end: dateRange.end,
            filterBy: 'date',
          },
        },
      },
      {
        handleSuccess: response => {
          const { weekEnds, calendarHolidays } = response.data.holidayDetails;

          const activeWeekEnds = weekEndSundayResolver(weekEnds[0] || []);

          const weekStartDay = getWeekStartDayFromWeekEnds(activeWeekEnds);

          // updateWeek based upon weekEnd
          const today = getTodaysDate();

          const currentWeek = updateWeek ? getCurrentWeek(today, weekStartDay) : week;

          // reset the week over here.

          const holidayEachDay = rosterService.holidayResolverPerDay(calendarHolidays);

          this.setState(
            {
              weekStartDay,
              week: currentWeek,
              weekEndList: activeWeekEnds,
              holidayList: holidayEachDay,
            },
            () => {
              callBack();
            },
          );
        },
        handleError: () => {},
      },
    );
  };

  loadHolidayListForMonthlyView = (callBack = () => null) => {
    const { getSubDHolidays } = this.props;

    const { month, roleId } = this.state;

    // load holidayList on the basis of weekly and Monthly View.
    getSubDHolidays(
      {
        filter: {
          filters: [
            {
              column: 'distributor_id',
              value: [roleId.toString()],
            },
          ],
          dateRange: {
            start: month.start,
            end: month.end,
            filterBy: 'date',
          },
        },
      },
      {
        handleSuccess: response => {
          const { weekEnds, calendarHolidays } = response.data.holidayDetails;

          const activeWeekEnds = weekEndSundayResolver(weekEnds[0] || []);

          const weekStartDay = getWeekStartDayFromWeekEnds(activeWeekEnds);

          const holidayEachDay = rosterService.holidayResolverPerDay(calendarHolidays);

          this.setState({
            weekStartDay,
            weekEndList: activeWeekEnds,
            holidayList: holidayEachDay,
          });
        },
        handleError: () => {},
      },
    );
    // Load holidays over here.
  };

  loadUserList = () => {
    // load all the DSE List.
    const { getUserList } = this.props;

    const { roleId } = this.state;

    getUserList(
      {
        offset: OFFSET,
        filter: {
          filters: [
            {
              column: 'distributor_id',
              value: [roleId ? roleId.toString() : '990909'],
            },
            {
              column: 'role_id',
              value: [USER_ROLE.DSE.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          const userList = response.data.users.rows;
          this.setState({ userList });
        },
        handleError: () => {},
      },
    );
  };

  loadRouteList = (userId, distributorId=null, assignedUserId=null) => {
    // Load Route List. on the basis of Town Filter
    const { getRouteList } = this.props;
    getRouteList(
      {
        userId,
        distributorIds:distributorId,
        assignedUserId
      },
      {
        handleSuccess: res => {
          const routeList = res.data.getAllAssignedRoutes;
          this.setState({ routeList });
        },
      },
    );
  };

  loadDSEList = (id,subDId=null) => {
    const {getDSEList} =this.props;
    const {userId} =this.state;
    getDSEList(
      {
        userId: id!==null?id:userId,
        roleId: USER_ROLE.DSE,
        distributorId: subDId
      },
      {
        handleSuccess: res => {
          const dseList = res.data.getAssociatedChildUsers;
          this.setState({ dseList });
        },
      },
    );
  }

  loadRouteVisitFrequencyList = () => {
    const { getRouteVisitFrequencyList } = this.props;

    getRouteVisitFrequencyList(
      {},
      {
        handleSuccess: res => {
          const routeVisitFrequencyList = res.data.routeVisitFrequencyList.rows;
          this.setState({ routeVisitFrequencyList });
        },
        handleFailure: () => {},
      },
    );
  };

  setCurrentWeekAndLoadData = () => {
    const { weekStartDay } = this.state;
    const today = getTodaysDate();
    const week = getCurrentWeek(today, weekStartDay);
    const month = getCurrentMonth();

    this.setState(
      {
        week,
        month,
      },
      () => this.loadFullRosterData(),
    );
  };

  onDseCalendarPick = (dseId, date) => {
    alert(dseId + date);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  loadRosterData = () => {
    const { getList } = this.props;

    const { roleId, calendarPreview, week, month, userId } = this.state;

    if (!roleId || (calendarPreview === CALENDAR_PREVIEW.MONTHLY && !userId)) {
      return;
    }

    const filter = {};

    if (calendarPreview === CALENDAR_PREVIEW.MONTHLY) {
      filter.filters = [
        {
          column: 'user_id',
          value: [userId.toString()],
        },
      ];
      filter.dateRange = {
        ...month,
        filterBy: 'roster_date',
      };
    } else {
      filter.dateRange = {
        ...week,
        filterBy: 'roster_date',
      };
      filter.filters = [];
    }

    getList(
      {
        filter,
      },
      {
        handleSuccess: response => {
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          const { rows, leaves } = response.data.salesForceRoster;
          const leaveListPerDay = rosterService.leaveResolverPerDay(leaves);

          this.setState({
            rosterList: rows,
            leaveList: leaveListPerDay,
          });
        },
      },
    );
  };

  handleHeaderIconClick = icon => {
    // type: weekly, monthly, today, print, save, lock
    switch (icon) {
      case HEADER_ICONS.TODAY:
        this.closeReplicationDialog();
        this.setCurrentWeekAndLoadData();
        break;
      case HEADER_ICONS.WEEKLY:
        this.closeChildrenDialog();
        this.setState({ calendarPreview: CALENDAR_PREVIEW.WEEKLY }, () => {
          this.resetRosterListAndLoadData();
        });
        break;
      case HEADER_ICONS.MONTHLY:
        this.closeChildrenDialog();
        this.setState({ calendarPreview: CALENDAR_PREVIEW.MONTHLY }, () => this.resetRosterListAndLoadData());
        break;
      case HEADER_ICONS.PRINT:
        this.closeChildrenDialog();
        this.setState({ printMode: true }, () => {
          window.print();
        });
        break;
      case HEADER_ICONS.LOCK:
        // read the current subD status;
        this.toggleRosterLockStatus();
        break;
      case HEADER_ICONS.REPLICATION:
        this.setState({ displayReplicationDialog: true });
        break;
      default:
        break;
    }
  };

  handleMonthChange = (start, end) => {
    const { month } = this.state;
    month.start = start;
    month.end = end;
    this.setState(
      {
        month,
        rosterList: [],
        leaveList: [],
      },
      () => this.loadRosterData(),
    );
  };

  handleWeekChange = (start, end) => {
    const { week } = this.state;
    week.start = start;
    week.end = end;
    this.setState(
      {
        week,
        rosterList: [],
        leaveList: [],
      },
      () => this.loadFullRosterData(),
    );
  };

  handleRoleSelect = role => {
    const roleId = role?.id;
    const users= _.sortBy(role?.users, 'fullName');
    const { subDDetails } = this.state; // userRoleList[0].id;
    subDDetails.title = role?.name;
    subDDetails.id = role?.id;
    subDDetails.address = role?.address || 'Katmandu';
    subDDetails.callRosterLocked = role?.callRosterLocked;

    this.setState(
      {
        roleId,
        subDDetails,
        userList:users,
        leaveList: [],
        rosterList: [],
        weekEndList: [],
        holidayList: [],
      },
      () => {
        this.loadFullDataAfterSubDTownSelection();
        this.closeChildrenDialog();
      },
    );
  };

  getCalendarHeader = () => {
    const { roleId, userRoleList } = this.state;
    const selectedSubD = userRoleList.find(subD => subD.id === roleId);

    const subDTitle = selectedSubD ? selectedSubD.title : '-';

    return <span>{subDTitle}</span>;
  };

  handleReplicationDialogSubmit = (sourceDateRange, destinationDateRange) => {
    const { userList } = this.state;
    const { replicate, displayAlert,bulkReplicateCallRoster } = this.props;
    const userIds = userList.map(a=> a.id);
    this.closeReplicationDialog();
    if(userId !== 0){
        replicate(
          {
            input: {
              userIds,
              sourceDateRange,
              destinationDateRange,
            },
          },
          {
            handleSuccess: () => {
              displayAlert(ALERT_TYPE.SUCCESS, 'Roster replicated');
            },
            handleError: () => {
              displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Operation Failed');
            },
          },   
        );
    }
    else{
      bulkReplicateCallRoster(
        {
            sourceDateRange,
            destinationDateRange,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Roster bulk replicated');
          },
          handleError: () => {
            displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Operation Failed');
          },
        },   
      );
      
    }
  };

  closeReplicationDialog = () => {
    this.setState({ displayReplicationDialog: false });
  };

  handleUserClick = (userId, loadData = false) => {
    this.setState({ userId }, () => {
      if (loadData) {
        this.loadRosterData();
      }
    });
  };

  resetRosterListAndLoadData = () => {
    this.setState(
      {
        userId: null,
        rosterList: [],
        leaveList: [],
      },
      () => {
        this.loadRosterData();
      },
    );
  };

  closeChildrenDialog = () => {};

  toggleRosterLockStatus = () => {
    // call the server
    // update the toggle state.
    const { toggleState, displayAlert } = this.props;
    const { subDDetails, userRoleList } = this.state;

    subDDetails.callRosterLocked = !subDDetails.callRosterLocked;

    const data = {
      ids: [subDDetails.id],
      type: 'Distributor',
      active: subDDetails.callRosterLocked,
      fieldName: 'call_roster_lock',
    };

    toggleState(
      { ...data },
      {
        handleSuccess: () => {
          displayAlert(
            ALERT_TYPE.SUCCESS,
            `Routes successfully ${subDDetails.callRosterLocked ? 'Locked' : 'Unlocked'}`,
          );

          const updatedSubDList = userRoleList.map(subD => {
            if (subD.id === subDDetails.id) {
              subD.callRosterLocked = subDDetails.callRosterLocked;
            }

            return subD;
          });

          this.setState({
            userRoleList: updatedSubDList,
            subDDetails,
          });
        },
        handleError: () => {
          displayAlert(ALERT_TYPE.DANGER, 'Failed Operation');
        },
      },
    );
  };

  handleDropDownClick = (e,params) =>{
    const {userId,subDId} =this.state;
    if(params==='subd'){
      this.setState({
        subDId:e.id
      })
      this.loadRouteList(userId,e.id);
      this.loadDSEList(null,e.id);
    }
    if(params==='dse'){
      this.setState({
        dseId:e.id
      })
      this.loadRouteList(userId, subDId, e.id);

    }
  }

  render() {
    const {
      routeVisitFrequencyList,
      calendarPreview,
      week,
      month,
      rosterList,
      weekStartDay,
      weekEndList,
      holidayList,
      userRoleList,
      roleId,
      userId,
      routeList,
      leaveList,
      userList,
      subDDetails,
      printMode,
      displayReplicationDialog,
      callRosterPermission,
      subDId,
      dseList,
      dseId,
    } = this.state;

    const { serverResponseWaiting, toggleState, create, update } = this.props;

    const dateArray = getDateList(week.start, week.end);

    const calendarHeader = this.getCalendarHeader();

    return (
      <DialogProcessor
        roleId={roleId}
        userId={userId}
        subDId={subDId}
        dseId={dseId}
        dseList={dseList}
        userList={userList}
        create={create}
        update={update}
        routeList={routeList}
        subDDetails={subDDetails}
        onUserClick={this.handleUserClick}
        loadRouteList={this.loadRouteList}
        loadDSEList={this.loadDSEList}
        loadData={this.loadRosterData}
        removeRosterLine={toggleState}
        calendarPreview={calendarPreview}
        routeVisitFrequencyList={routeVisitFrequencyList}
        displayReplicationDialog={displayReplicationDialog}
        onReplicationDialogSubmit={this.handleReplicationDialogSubmit}
        onReplicationDialogCancel={this.closeReplicationDialog}
        closeChildDialog={closeDialog => (this.closeChildrenDialog = closeDialog)}
        onDropDownClick={this.handleDropDownClick}
        onResetDialog={this.handleResetDialog}
      >
        <RosterStyled>
          <div className="section-header">
            <PanelStyled>
              <BreadCrumb list={breadCrumb} />
              <PanelHeader>
                <h2>Sales Force Roster</h2>
              </PanelHeader>
            </PanelStyled>
          </div>
          <div className="section-content">
            <div className="roster-container">
              <RosterView
                printMode={printMode}
                activeUserId={userId}
                week={week}
                month={month}
                subDDetails={subDDetails}
                userList={userList}
                activeRoleId={roleId}
                userRoleList={userRoleList}
                routeList={routeList}
                rosterData={rosterList}
                dateArray={dateArray}
                weekEndList={weekEndList}
                holidayList={holidayList}
                leaveList={leaveList}
                weekStartDay={weekStartDay}
                loading={serverResponseWaiting}
                calendarHeader={calendarHeader}
                calendarPreview={calendarPreview}
                onRoleSelect={this.handleRoleSelect}
                onMonthChange={this.handleMonthChange}
                onWeekChange={this.handleWeekChange}
                onCalendarClick={this.onDseCalendarPick}
                onHeaderIconClick={this.handleHeaderIconClick}
                callRosterPermission={callRosterPermission}
              />
              <div className="roster-legend-wrap">
                <div className="inner">
                  <ul>
                    <li>
                      <span className="daily">Daily</span>
                    </li>
                    <li>
                      <span className="weekly">Weekly</span>
                    </li>
                    <li>
                      <span className="fortnightly">Fortnightly</span>
                    </li>
                    <li>
                      <span className="monthly">Monthly</span>
                    </li>
                    <li>
                      <span className="once">Once</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span className="leave-pending">Leave pending</span>
                    </li>
                    <li>
                      <span className="on-leave">On Leave</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </RosterStyled>
      </DialogProcessor>
    );
  }
}

Roster.propTypes = propTypes;

Roster.defaultProps = defaultProps;

export default withAlert()(Roster);
