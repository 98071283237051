import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Tab, TabList, TabPanel, Tabs
} from '../../../../v4/components';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { PanelStyled } from '../../../common/configuration';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import PageHeader from '../../../base/PageHeader';
import {
  title, breadCrumbConfig, filterConfig
} from './config';
import { Row, Col } from '../../../../components/Grid';
import { Badge } from '../../../../v4/components';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import Pending from './pending';
import Received from './received';
import Reconciled from './reconciled';
import Dishonored from './dishonored';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const propTypes = {
  pending: dataProps.value,
  received: dataProps.value,
  reconcile: dataProps.value,
  dishonore: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  handleDownloadClick: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func,
  activeTab: PropTypes.number,
  onTabChange: PropTypes.func,
  columnSelectChange: PropTypes.func,
  handleFilterChange: PropTypes.func,
  queryParameters: PropTypes.instanceOf(Object),
  basePaginationService: PropTypes.instanceOf(Object),
  handleSliderOpen: PropTypes.func.isRequired,
};

const defaultProps = {
  activeTab: 0,
  pending: dataProps.defaultValue,
  reconcile: dataProps.defaultValue,
  dishonore: dataProps.defaultValue,
  onPageChange: () => null,
  received: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  permission: PERMISSION_OBJ,
  onButtonClick: () => null,
  onTabChange: () => null,
  columnSelectChange: () => null,
  handleFilterChange: () => null,
  queryParameters: {},
  basePaginationService: {},
};

const View = ({ ...props }) => {
  const {
    activeTab,
    onTabChange,
    tableConfig,
    columnSelectChange,
    pending,
    received,
    reconcile,
    dishonore,
    queryParameters,
    handleFilterChange,
    basePaginationService,
    handleSliderOpen,
    onButtonClick,
    handleDownloadClick,
    permission,
    filterMenu,
  } = props;
  const tablewithAction = [
    ...tableConfig,
    {
      id: 14,
      label: 'actionHolder',
      title: '',
      show: true,
    },
  ];
  return (
    <Fragment>
      <div className='section-header'>
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title,
              create: false,
              download: true,
              filter: true,
              upload: false,
              date: true,
              search: true,
              columSelect: true,
            }}
            downloadConfig={{
              domain: DOWNLOAD_DOMAIN.RECEIPT,
            }}
            filter={{
              date: queryParameters.date,
              menuList: filterMenu,
              onFilterChange: handleFilterChange,
            }}
            labelConfig={tableConfig}
            queryParameters={queryParameters}
            columnSelectChange={columnSelectChange}
            resetFilter={basePaginationService.resetFilter}
            clearSearchText={basePaginationService.clearSearchText}
            handleSearchChange={basePaginationService.handleSearchInputChange}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
            handleDownloadClick={handleDownloadClick}
          />
        </PanelStyled>
      </div>
      <div className='section-content section-tab table-present '>
        <Tabs selectedIndex={activeTab} onSelect={(tabIndex) => onTabChange(tabIndex)}>
          <TabList>
            <Tab>
              <span className='tab-label'>Pending</span>
              <Badge light label={pending.total} />
            </Tab>
            <Tab>
              <span className='tab-label'>Received</span>
              <Badge light label={received.total} />
            </Tab>
            <Tab>
              <span className='tab-label'>Reconciled</span>
              <Badge light label={reconcile.total} />
            </Tab>
            <Tab>
              <span className='tab-label'>Dishonored</span>
              <Badge light label={dishonore.total} />
            </Tab>
          </TabList>
          <div className='tab-container'>
            <Row>
              <Col sm={12}>
                <TabPanel>
                  <section id='pending-tab'>
                    <div id='detail-tab'>
                      <div className='create-ui-inner tab-less-height' style={{ padding: '0' }}>
                        <Pending
                          tableConfig={tablewithAction}
                          pending={pending}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageChange}
                          handleSliderOpen={handleSliderOpen}
                          fetchAllData={onButtonClick}
                          permission={permission}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id='received-tab'>
                    <div id='detail-tab'>
                      <div className='create-ui-inner tab-less-height' style={{ padding: '0' }}>
                        <Received
                          tableConfig={tablewithAction}
                          received={received}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageChange}
                          handleSliderOpen={handleSliderOpen}
                          fetchAllData={onButtonClick}
                          permission={permission}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id='reconciled-tab'>
                    <div id='detail-tab'>
                      <div className='create-ui-inner tab-less-height' style={{ padding: '0' }}>
                        <Reconciled
                          tableConfig={tableConfig}
                          reconcile={reconcile}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageChange}
                          handleSliderOpen={handleSliderOpen}
                          permission={permission}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
                <TabPanel>
                  <section id='dishonored-tab'>
                    <div id='detail-tab'>
                      <div className='create-ui-inner tab-less-height' style={{ padding: '0' }}>
                        <Dishonored
                          tableConfig={tableConfig}
                          dishonore={dishonore}
                          pagination={queryParameters.pagination}
                          onPageChange={basePaginationService.onPageChange}
                          handleSliderOpen={handleSliderOpen}
                          permission={permission}
                        />
                      </div>
                    </div>
                  </section>
                </TabPanel>
              </Col>
            </Row>
          </div>
        </Tabs>
      </div>
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const PostDatedChequeViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

const PostDatedChequeViewWithImageSlider = withImageSlider(PostDatedChequeViewWithErrorAndLoading);

export default PostDatedChequeViewWithImageSlider;
