import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import { crudRequest as crudRequestConfig, breadCrumbConfig, title } from './config';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import history from '../../../utils/history';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import * as queryService from '../../base/query.service';
import withAlert from '../../../utils/composition/withAlert';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { CAMPAIGNS_DETAILS, CAMPAIGNS } from '../../../data/enums/Route';
import { getPermissionForCampaigns } from '../../base/permission';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class Campaigns extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.permission = getPermissionForCampaigns();
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        this.setState(data);
        displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      },
      this.getData,
      ['title'],
    );
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    // this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getCampaignsList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    // all urls for search, filter and pagination
    getCampaignsList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.campaigns && response.data.campaigns.rows) || [];
          data.total = (response.data.campaigns && response.data.campaigns.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onIconClick = type => {
    const { update } = this.state;
    history.push(`/${CAMPAIGNS}/create`);
  };

  onTableRowClick = (id, type) => {
    history.push(`/${CAMPAIGNS_DETAILS}/${id}`);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const { data, queryParameters, display } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <DialogWrapper
        onDialogSubmit={this.onFormSubmit}
        // formConfig={formConfig}
        refsObj={this.formReference}
        render={({ onDialogItemClick }) => (
          <Fragment>
            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  display={display}
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title,
                    create: this.permission.create,
                    download: false,
                    filter: false,
                    search: true,
                  }}
                  queryParameters={queryParameters}
                  controlDisplay={this.controlDisplay}
                  handleCreateClick={this.onIconClick}
                  resetFilter={this.basePaginationService.resetFilter}
                  clearSearchText={this.basePaginationService.clearSearchText}
                  handleSearchChange={this.basePaginationService.handleSearchInputChange}
                  handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                  handleDownloadClick={this.basePaginationService.handleDownloadClick}
                />
              </PanelStyled>
            </div>
            <div className="section-content table-present">
              <View
                data={data}
                permission={this.permission}
                pagination={queryParameters.pagination}
                onIconClick={onDialogItemClick}
                loading={serverResponseWaiting}
                onPageChange={this.basePaginationService.onPageSelect}
                onBulkDelete={this.handleBulkDelete}
                onTableRowClick={this.onTableRowClick}
              />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

Campaigns.propTypes = propTypes;

Campaigns.defaultProps = defaultProps;

export default withAlert()(Campaigns);
