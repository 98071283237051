import { compose } from 'recompose';
import {
  query, mutation, CREATE_LOCATION_HIERARCHY_DETAIL, EDIT_LOCATION_HIERARCHY_DETAIL
} from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { TOGGLE_STATE } from '../../common/GqlAPI';
import LocationHierarchyDetails from './LocationHierarchyDetails';

const ComposedLocationHierarchyDetails = compose(
  CREATE_LOCATION_HIERARCHY_DETAIL,
  EDIT_LOCATION_HIERARCHY_DETAIL,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(LocationHierarchyDetails);

export default ComposedLocationHierarchyDetails;
