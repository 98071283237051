import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { refGenerator } from '../../../utils/refGenerator';

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    mapper: (element) => ({
      password: element.oldpassword || '',
      newPassword: element.newPassword || '',
      confirmPassword: element.confirmPassword || '',
    }),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['password', 'newPassword', 'confirmPassword']),
    [FORM_CONFIG.TITLE]: 'Change Password',
    buttonName: 'Change',
  },
};

export { formConfig };
