import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_TOWN_LIST } from '../../configuration/town/API';
import { GET_USER_LIST } from '../../configuration/user/userAccount/API';

const GET_OUTLET_LIST_PLAN = gql`
query getOutletListPlan($input: OutletPlanInput) {
  getlistOutletPlan(input: $input) {
    rows {
      title
      id
      dse_id
      channel
      category
      isverified
      reason
      isplanned
    }
  }
}
`;

const GET_ROUTE_LIST = gql`
  query getRoutes($offset: Int, $limit: Int, $filter: FilterInput) {
    routes(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
      }
      count
    }
  }
`;

const CREATE_OUTLET_SAVE_PLAN = graphql(
  gql`
  mutation ($input: OutletSavePlanInput!) {
    createOutletSavePlan(input: $input) {
      user_id
      outlet_id
    }
  }
`,
  {
    props: ({ mutate }) => ({
      createOutletSavePlan: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_ABSENT_DSE = gql`
query getAbsentDse($input: absentDseInput) {
  getAbsentDse(input: $input) {
    rows {
      id
      title:dse
    }
  }
}
`;

const query = {
  getTownList: GET_TOWN_LIST,
  getOutletListPlan: GET_OUTLET_LIST_PLAN,
  getRouteList: GET_ROUTE_LIST,
  getUserList: GET_USER_LIST,
  getAbsentDse: GET_ABSENT_DSE
};

const mutation = {
  createOutletSavePlan: CREATE_OUTLET_SAVE_PLAN,
};

export {
  query, mutation, CREATE_OUTLET_SAVE_PLAN
};
