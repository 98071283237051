import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const CREATE_CAMPAIGN = graphql(
  gql`
    mutation createCampaign($input: CampaignInput!) {
      createCampaign(input: $input) {
        id
        title
        budget
        startDate
        fiscalYearId
        FiscalYear {
          id
          title
          endDate
          startDate
        }
        endDate
        SubCampaign {
          id
          title
          startDate
          endDate
          subCampaignType
          SubCampaignBudget {
            id
            budgetType
            value
          }
          SubCampaignTarget {
            id
            targetType
            value
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createCampaign: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);
const GET_LIST_MASTER_DATA = gql`
  query listMasterData($type: String) {
    listMasterData(type: $type) {
      type
      list
    }
  }
`;
const GET_LIST_FISCAL_YEAR = gql`
  query listFiscalYear {
    listFiscalYear {
      id
      title
      startDate
      endDate
    }
  }
`;

const GET_CAMPAIGN_DETAIL = gql`
  query campaignDetail($filter: FilterInput) {
    campaigns(filter: $filter) {
      rows {
        id
        title
        budget
        startDate
        endDate
        fiscalYearId
        FiscalYear {
          id
          title
          endDate
          startDate
        }
        SubCampaign {
          id
          title
          startDate
          endDate
          subCampaignType
          subCampaignBudget: SubCampaignBudget {
            id
            budgetType
            value
            dimension
            calculatedValue
          }
          subCampaignTarget: SubCampaignTarget {
            id
            targetType
            value
            dimension
          }
        }
      }
    }
  }
`;

const query = {
  getCampaignDetail: GET_CAMPAIGN_DETAIL,
  getListMasterData: GET_LIST_MASTER_DATA,
  getlistFiscalYear: GET_LIST_FISCAL_YEAR,
};

const mutation = {
  createCampaign: CREATE_CAMPAIGN,
};

export {
  CREATE_CAMPAIGN, GET_LIST_MASTER_DATA, GET_CAMPAIGN_DETAIL, GET_LIST_FISCAL_YEAR
};

export { query, mutation };
