import React from 'react';
import { TableHeader, TableBody } from './tableConfig';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { BulkDelete, Pagination } from '../../../v4/components';
import withLoading from '../../../utils/composition/withLoading';

const View = ({ ...props }) => {
  const { data, pagination, permission, onRowClick, onIconClick, onPageChange, onBulkDelete } = props;
  return (
    <>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.MESSAGE}
        handler={onBulkDelete}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap'>
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: checkedList.length,
                primaryCheckboxHandler,
                permission,
              })}

              <tbody>
                {data.list.map((systemAlert) =>
                  TableBody({
                    data: systemAlert,
                    checkedList: checkedList,
                    secondaryCheckboxHandler,
                    permission,
                    onIconClick,
                    onRowClick,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />

      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </>
  );
};
const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
