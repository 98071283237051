import gql from 'graphql-tag';

const GET_SBD_LIST_WITH_TITLE = gql`
  query sbds($offset: Int, $limit: Int, $filter: FilterInput) {
    sbds(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        start
        end
        active
      }
      count
    }
  }
`;

const query = {
  getSBDListWithTitle: GET_SBD_LIST_WITH_TITLE,
};

export { query };
