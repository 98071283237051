import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../v4/components';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { normalPresentor } from '../../../utils/date';

const labelMappings = {
  requestedDate: 'requestedDate',
  replacedDate: 'replacedDate',
  brand: 'brand',
  sku: 'sku',
  requestedQty: 'requestedQty',
  reason: 'reason',
  replacedSku: 'replacedSku',
  replacedQty: 'replacedQty',
  status: 'status',
  distributor: 'distributor',
  replacedBy: 'replacedBy',
  route: 'route',
  outlet: 'outlet',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.requestedDate,
    title: 'Requested Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.replacedDate,
    title: 'Replaced Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.requestedQty,
    title: 'Requested QTY',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.reason,
    title: 'Reason',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.replacedSku,
    title: 'Replaced SKU',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.replacedQty,
    title: 'Replaced QTY',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.replacedBy,
    title: 'Replaced By',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 12,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 13,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
];

const TableHeader = ({ tableConfig }) => (
  <thead className=''>
    <tr>{tableConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data, componentDisplay) => {
  const table = {};

  table[labelMappings.requestedDate] = <td key={`${data.id}-requestedDate`}>{normalPresentor(data.date)}</td>;
  table[labelMappings.replacedDate] = (
    <td key={`${data.id}-replacedDate`}>{data.replacedDate ? normalPresentor(data.replacedDate) : '-'}</td>
  );
  table[labelMappings.brand] = <td key={`${data.id}-brand`}>{data.brand}</td>;
  table[labelMappings.sku] = <td key={`${data.id}-sku`}>{data.sku}</td>;
  table[labelMappings.requestedQty] = (
    <td key={`${data.id}-requestedQty`} className='text-right'>
      {fixedFloatAndCommas(data.quantity)}
    </td>
  );
  table[labelMappings.reason] = <td key={`${data.id}-reason`}>{data.reason}</td>;
  table[labelMappings.replacedSku] = <td key={`${data.id}-replacedSku`}>{data.replacementSKU}</td>;
  table[labelMappings.replacedQty] = (
    <td key={`${data.id}-replacedQty`} className='text-right'>
      {fixedFloatAndCommas(data.replacementQTY)}
    </td>
  );
  table[labelMappings.status] = <td key={`${data.id}-status`}>{data.status}</td>;
  table[labelMappings.replacedBy] = <td key={`${data.id}-replacedBy`}>{data.replacedBy}</td>;
  table[labelMappings.distributor] = !componentDisplay && <td key={`${data.id}-distributor`}>{data.distributor}</td>;
  table[labelMappings.route] = <td key={`${data.id}-route`}>{data.route}</td>;
  table[labelMappings.outlet] = <td key={`${data.id}-outlet`}>{data.outlet}</td>;

  return table;
};

const TableBody = ({ data, componentDisplay }) => {
  const tableBody = returnTableBody(data, componentDisplay);

  return (
    <tr key={`stock-replacement-${data.id}`}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  componentDisplay: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  componentDisplay: {},
};

TableHeader.propTypes = {
  tableConfig: PropTypes.arrayOf(Object).isRequired,
};

export {
  TableHeader, TableBody, labelConfig
};
