import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_DISTRIBUTORS, UPLOAD,DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const GET_DISTRIBUTOR_TARGET = gql`
  query distributors($offset: Int, $limit: Int, $filter: FilterInput) {
    distributors(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        title
        id
      }
      count
    }
  }
`;

const CREATE_DISTRIBUTION_TARGET = graphql(
  gql`
  mutation ($input: DistributorIcoTargetInput!) {
    createDistributorIcoTarget(input: $input) {
      id
      title
      year
      month
      target
      date
      distributor_id
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      createDistributorIcoTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  }
)

const EDIT_DISTRIBUTION_TARGET = graphql(
  gql`
  mutation ($id: Int!, $input: DistributorIcoTargetInput!) {
    editDistributorIcoTarget(id:$id, input: $input) {
      id
      title
      year
      month
      target
      date
      distributor_id
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      editDistributorIcoTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  }
)

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getDistributorTarget: GET_DISTRIBUTOR_TARGET,
};

const mutation = {
  upload: UPLOAD,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, CREATE_DISTRIBUTION_TARGET, EDIT_DISTRIBUTION_TARGET,UPLOAD,DOWNLOAD_REPORT
};
