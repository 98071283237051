import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import useDragAndDrop from './useDragAndDropTable.hook';
import BulkDelete from '../../../v4/components/BulkDelete/BulkDelete';
import DragAndDropStyled from './DragAndDropStyled';

const DragAndDropTable = props => {
  const {
    data,
    handleIconClick,
    permission,
    TableBody,
    TableHeader,
    handler,
    typeEntity,
    onSequenceChange
  } = props;
  const { items, onDragEnd, getListStyle, getItemStyle } = useDragAndDrop(data,onSequenceChange);

  return (
    <DragAndDropStyled>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="Table">
            {(provided, snapshot) => (
              <>
                <BulkDelete
                  data={items}
                  type={typeEntity || ''}
                  handler={handler && handler}
                  toggleState={() => {}}
                  render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
                    <div className="table-wrap">
                      <table
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {TableHeader({
                          dataListLength: items.length,
                          checkedListLength: checkedList.length,
                          primaryCheckboxHandler,
                          permission,
                        })}
                        <tbody>
                          {items?.map((item, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                              {provided => {
                                return (
                                  TableBody({
                                    data: item,
                                    index: index,
                                    checkedList: checkedList,
                                    onViewClick: () => {},
                                    onIconClick: handleIconClick,
                                    secondaryCheckboxHandler,
                                    permission,
                                    ref: provided.innerRef,
                                    draggableProps: { ...provided.draggableProps },
                                    dragHandleProps: { ...provided.dragHandleProps },
                                    style: getItemStyle(snapshot.isDraggingOver, provided.draggableProps.style),
                                  })
                                );
                              }}
                            </Draggable>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                />
              </>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </DragAndDropStyled>
  );
};

export default DragAndDropTable;
