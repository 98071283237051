import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_LINKED_DEVICES = gql`
  query Rows($limit: Int, $offset: Int, $filter: FilterInput) {
    getLinkedDevices(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        created_by
        device_id
        id
        is_deleted
        phone_model
        createdAt
        phone_name
        status
        updatedAt
        updated_by
        user_id
        User {
          email
          firstName
          fullName
          id
          lastName
          phoneNumber
        }
      }
      count
    }
  }
`;

const TOGGLE_LINKED_DEVICE_STATUS = graphql(
  gql`
    mutation ToggleLinkedDevice($status: String, $toggleLinkedDeviceId: Int) {
      toggleLinkedDevice(status: $status, id: $toggleLinkedDeviceId) {
        createdAt
        created_by
        device_id
        id
        is_deleted
        phone_model
        phone_name
        status
        updatedAt
        updated_by
        user_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleLinkedDevice: variables =>
        mutate({
          variables,
        }),
    }),
  },
);
const DELETE_LINKED_DEVICE = graphql(
  gql`
    mutation DestroyLinkedDevice($ids: [Int!]) {
      destroyLinkedDevice(ids: $ids) {
        createdAt
        created_by
        device_id
        id
        is_deleted
        phone_model
        phone_name
        status
        updatedAt
        updated_by
        user_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      destroyLinkedDevice: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getLinkedDevices: GET_LINKED_DEVICES,
};

const mutation = {
  toggleLinkedDevice: TOGGLE_LINKED_DEVICE_STATUS,
  destroyLinkedDevice: DELETE_LINKED_DEVICE,
};

export { TOGGLE_LINKED_DEVICE_STATUS, DELETE_LINKED_DEVICE };

export { query, mutation };
