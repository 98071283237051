import React from 'react';
import PropTypes from 'prop-types';
import { dataProps } from '../../../data/enums/Component';
import ClassficationWrapper from './ClassificationWrapper';
import { Card } from '../../../v4/components';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  channel: dataProps.value,
  category: dataProps.value,
  onItemClick: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  handleIconClick: PropTypes.func.isRequired,
  onChannelClick: PropTypes.func.isRequired,
  tradeId: PropTypes.number,
  channelId: PropTypes.number,
  cardTitle: PropTypes.string,
};

const defaultProps = {
  data: dataProps.defaultValue,
  channel: dataProps.defaultValue,
  category: dataProps.defaultValue,
  tradeId: null,
  channelId: null,
  cardTitle: '',
};

const View = ({ ...props }) => {
  const {
    data,
    onItemClick,
    channel,
    handleSearchInput,
    handleIconClick,
    tradeId,
    onChannelClick,
    channelId,
    category,
    cardTitle,
    permission
  } = props;
  return (
    <div className='create-ui'>
      <ClassficationWrapper>
        <div className='create-ui-inner tab-less-height'>
          <Card
            data={data.list}
            onItemClick={onItemClick}
            handleSearchInput={handleSearchInput}
            title='Trade'
            selectedId={tradeId}
            cardTitle={cardTitle}
            handleIconClick={handleIconClick}
            permission={permission}
          />
          {tradeId && (
            <Card
              data={channel.list}
              onItemClick={onChannelClick}
              handleSearchInput={handleSearchInput}
              selectedId={channelId}
              title='Channel'
              handleIconClick={handleIconClick}
              permission={permission}
            />
          )}
          {channelId && (
            <Card
              data={category.list}
              handleIconClick={handleIconClick}
              handleSearchInput={handleSearchInput}
              selectedId={channelId}
              title='Category'
              permission={permission}
            />
          )}
        </div>
      </ClassficationWrapper>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ClassificationViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default ClassificationViewWithErrorAndLoading;
