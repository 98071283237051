import React from 'react';
import RoutesListStyled from './RoutesListStyled';
import RouteCards from '../RouteCards/RouteCards';
import { Icon } from '../../../../../v4/components';

const RoutesList = ({ ...props }) => {
  const { data, onRouteCheck, routeCardClick } = props;
  return (
    <RoutesListStyled>
      <div className='routesList'>
        <div className='routesDisplay-head'>
          <p className='someText'>Routes</p>
        </div>
        <div className='routeCreate-body'>
          {data.total < 500 && data.list.length ? (
            <h3 className='total'>Total {data.list.length} Routes</h3>
          ) : (
            <h3 className='info-header'>
              <span className='info'>
                <Icon iconName='info-circle'></Icon>
                <p>
                  <b>Info: </b> Please apply filter to view routes.
                </p>
              </span>
            </h3>
          )}
          {data.total < 500 && data.list.length ? (
            <RouteCards routesList={data.list} onRouteCheck={onRouteCheck} routeCardClick={routeCardClick} />
          ) : (
            <div className='default-wrap'>
              <img alt='0 Routes' src='/image/zero_routes_image.svg' />
              <p className='someText'>0 Routes Created.</p>
            </div>
          )}
        </div>
      </div>
    </RoutesListStyled>
  );
};

export default RoutesList;
