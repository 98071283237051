import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import * as config from '../config';

const httpLink = new HttpLink({
  uri: config.url.http,
  ...config.httpOptions,
});

const wsLink = new WebSocketLink({
  uri: config.url.webSocket,
  ...config.webSocketOptions,
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const httpWsLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export default httpWsLink;
