import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';

const crudRequestConfig = {
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: ({ distributorIds, stockGroupId, territoryId, status }) => ({
      distributorIds,
      stockGroupId,
      territoryId,
      status,
    }),
    responseName: 'toggleStateForReplenishmentGroup',
    message: 'SubD group updated successfully',
  },
};
export { crudRequestConfig };
