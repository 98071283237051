import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, SingleDatePicker, CustomSelect
} from '../../../../../components';
import withLoading from '../../../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  enableRouteSegmentation: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
  options: {},
};

const SbdForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    options,
    enableRouteSegmentation,
  } = props;

  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Description'
        labelContent='Description'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
      />
      {enableRouteSegmentation && (
        <CustomSelect
          labelContent='Line'
          placeholder='Select'
          getOptionValue={({ id }) => id}
          getOptionLabel={({ title }) => title}
          options={options}
          name='Line'
          value={options.filter(({ id }) => id === data.lineId)}
          onChange={(event) => handleDropDownChange(event.id, ['lineId'])}
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          ref={(ref) => (refsObj.lineId = ref)}
        />
      )}
      <div className='single-date-picker'>
        <label>Start Date</label>
        <SingleDatePicker
          date={data.start}
          name='start'
          placeholder='Start Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name: 'start',
                },
                formattedValue: date,
              },
              '',
            )
          }
          labelContent='Start Date'
          maxDate={data.end}
        />
      </div>
      <div className='single-date-picker'>
        <label>End Date</label>
        <SingleDatePicker
          date={data.end}
          name='end'
          placeholder='End Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name,
                },
                formattedValue: date,
              },
              '',
            )
          }
          labelContent='End Date'
          minDate={data.start}
        />
      </div>
    </Form>
  );
};

SbdForm.propTypes = propTypes;

SbdForm.defaultProps = defaultProps;

const ComposedSbdForm = withLoading(SbdForm);

export default ComposedSbdForm;
