import React, { Component } from 'react';
import PropTypes from 'prop-types';
import history from '../../../utils/history';
import { DialogFormWrapper } from '../../common';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { formConfig } from './config';
import PasswordField from '../../login/resetPassword/PasswordField';
import withAlert from '../../../utils/composition/withAlert';
import { Form } from '../../../components';
import { getUser } from '../../../data/dao';
import { MATCH, CHARACTER_VALIDATION, EMPTY } from '../../../data/enums/ErrorMessage';
import {userRoleForTASDashboard} from '../../login/config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  resetDialog: PropTypes.func.isRequired,
  data: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  data: '',
  type: '',
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      incorrectMessage: {
        message: '',
      },
    };
  }

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  changePassword = async (type, dialogData) => {
    const { changePassword, getStatus, resetDialog, id, location } = this.props;

    const { incorrectMessage } = this.state;

    if (
      dialogData.newPassword !== dialogData.confirmPassword ||
      !dialogData.password ||
      !dialogData.newPassword ||
      !dialogData.confirmPassword
    ) {
      return;
    } else {
      changePassword(
        {
          userId: id,
          oldPassword: dialogData.password,
          newPassword: dialogData.newPassword,
        },
        {
          handleSuccess: response => {
            incorrectMessage.message = response.data.changePassword;
            this.setState(incorrectMessage);
            if (incorrectMessage.message === 'Incorrect Current Password.') {
              getStatus('Incorrect Current Password.', 'err');
            } else {
              resetDialog();
              getStatus('Password Changed Successfully', '');
              setTimeout(function () {
                if (location === '?challenge=changePassword') {
                  const userDetails=getUser();
                  const isTASUser  =Object.values(userRoleForTASDashboard).includes(userDetails.roleId);
                  history.push( isTASUser?'/tas-dashboard':'/dashboard');
                }
              }, 1000);
            }
          },
        },
      );
    }
  };
  render() {
    const { data, type, resetDialog, location } = this.props;
    return (
      <div>
        {type && (
          <DialogFormWrapper
            location={location}
            disableDialogClose={true}
            dialogElement={data}
            onDialogCancel={resetDialog}
            onDialogSubmit={this.changePassword}
            formConfig={formConfig[type]}
            refsObj={this.formReference}
            type={type}
            renderDialog={({ dialogData, handleInputChange, refsObj, enableErrorDisplay }) => {
              const isPasswordEqual = dialogData.newPassword === dialogData.confirmPassword;
              return (
                type && (
                  <Form>
                    <PasswordField
                      label="Old Password"
                      value={dialogData.password}
                      name="password"
                      onInputChange={event => handleInputChange(event)}
                      enableErrorDisplay={enableErrorDisplay}
                      enableValidation
                      errorMessage={!!dialogData.password ? '' : EMPTY}
                      refsObj={refsObj}
                      externalValidation={!!dialogData.password}
                    />
                    <PasswordField
                      label="New Password"
                      value={dialogData.newPassword}
                      name="newPassword"
                      onInputChange={event => handleInputChange(event)}
                      enableErrorDisplay={enableErrorDisplay}
                      enableValidation
                      refsObj={refsObj}
                      errorMessage={
                        !!dialogData.newPassword ? (!isPasswordEqual ? MATCH : CHARACTER_VALIDATION) : EMPTY
                      }
                      externalValidation={!!dialogData.newPassword && isPasswordEqual}
                    />
                    <PasswordField
                      label="Repeat New Password"
                      value={dialogData.confirmPassword}
                      onInputChange={event => handleInputChange(event)}
                      enableErrorDisplay={enableErrorDisplay}
                      name="confirmPassword"
                      enableValidation
                      refsObj={refsObj}
                      errorMessage={
                        !!dialogData.newPassword ? (!isPasswordEqual ? MATCH : CHARACTER_VALIDATION) : EMPTY
                      }
                      externalValidation={!!dialogData.confirmPassword && isPasswordEqual}
                    />
                  </Form>
                )
              );
            }}
          />
        )}
      </div>
    );
  }
}

ChangePassword.propTypes = propTypes;

ChangePassword.defaultProps = defaultProps;

export default withAlert()(ChangePassword);
