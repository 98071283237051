import { Switch } from '../../../../v4/components';
import { Sequence } from './Sequence';

export const OrderTakingSequence = ({ ...props }) => {
  const { onInputChange, sequenceData, changeDrag, onSequenceChange, sequenceTitle } = props;
  return (
    <>
      <Switch
        checked={sequenceData?.enableOrderTakingProcess}
        labelContent="Enable order taking process "
        onToggle={(checked, event) => {
          onInputChange(
            {
              formattedValue: !!checked,
              target: {
                name: 'enableOrderTakingProcess',
                type: 'boolean',
                value: !!checked,
              },
            },
            'enableOrderTakingProcess',
            ['enableOrderTakingProcess'],
          );
        }}
        classes="card"
      />
      {sequenceData?.enableOrderTakingProcess && (
        <>
          <Sequence
            sequenceData={sequenceData?.orderTakingProcessSequenceData}
            onInputChange={changeDrag}
            onSequenceChange={onSequenceChange}
            sequenceTitle={sequenceTitle}
          />
        </>
      )}
    </>
  );
};
