import styled from 'styled-components';

const DashWrapper = styled.div`
  padding: 48px;
  height: 100%;
  overflow: auto;
  .row {
    margin-left: -12px;
    margin-right: -12px;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    padding-left: 12px;
    padding-right: 12px;
  }
  #monthly-detail {
    .custom-display {
      min-height: 120px;
      & > div {
        &:nth-child(3),
        &:nth-child(2),
        &:first-child {
          cursor: pointer;
        }
      }
    }
  }
`;

export default DashWrapper;
