import { compose } from 'recompose';
import Outlet from './General';
// import View from './View';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_ROUTE, UPDATE_ROUTE
} from './API';

const ComposedRoute = compose(
  CREATE_ROUTE,
  UPDATE_ROUTE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Outlet);

export default ComposedRoute;
