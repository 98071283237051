import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LabelStyled from './LabelStyled';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  active: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular']),
};

const defaultProps = {
  children: undefined,
  className: '',
  active: false,
  size: 'regular',
};

const Label = ({ children, className, active, size, ...otherProps }: any) => {
  const classes = classNames(
    {
      active,
    },
    className,
    size,
  );
  return (
    <Fragment>
      <LabelStyled className={classes} {...otherProps}>
        {children}
      </LabelStyled>
    </Fragment>
  );
};

Label.propTypes = propTypes;

Label.defaultProps = defaultProps;

export default Label;
