import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import SubDReplenishmentView from './View';
import PageHeader from '../../base/PageHeader';
import { getSortValueAfterValueCheck, isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { breadCrumbConfig, formConfig, filterConfig } from './config';
import { subdStockReplenishment } from '../../common/DomainConfig';
import { DialogFormWrapper } from '../../common';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { fileParser } from '../../../utils/parser';
import { UPLOADABLES } from '../../../data/enums/GraphQL';
import DialogEntities from './page-components/DialogEntities';
import { clone } from '../../../utils/objectProcessor';
import SubDRepStyled from './SubDRepStyled';
import { CustomSelect } from '../../../components';
import { getUser } from '../../../data/services';
import { PAGINATION } from '../../../data/enums';
import { DOMAIN } from '../../../data/enums/config';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import {   getPermissionForSubDReplenishmentReport
 } from '../../base/permission';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getReplenishmentReport: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  saveOrderReport: PropTypes.func.isRequired,
  shareOrderReport: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getUsers: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class SubDReplenishment extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      distributorList: [],
      distributor: {
        id: this.userInfo.Distributor.length === 1 ? this.userInfo.Distributor[0].id : '',
        title: '',
      },
      dialog: {
        type: '',
        element: '',
      },
      fileName: '',
      showPaginatedList: false,
      disableGenerateButton: true,
      users: [],
      userIds: [],
    };

    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getReplenishmentReport,
      downloadReport,
      displayAlert,
    );

    this.setData = data => {
      this.setState({ data });
    };

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.permission =   getPermissionForSubDReplenishmentReport
();
  }

  componentDidMount() {
    const { distributor } = this.state;
    this.loadTableData();
    this.getFilterStateFromStorage();
    if (distributor.id !== '') {
      this.getReplenishmentReport();
    }
  }

  setFilterStateInStorage = () => {
    const {distributor} =this.state;
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    storeInLocalStorage(
      'DIALOG',
      distributor,
      DOMAIN.SUBD_REPLENISHMENT
    );
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.SUBD_REPLENISHMENT
    );
  };
  getFilterStateFromStorage = () => {
    const {distributor} =this.state;
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.SUBD_REPLENISHMENT);
    const localDialog = fetchFromLocalStorage('DIALOG', DOMAIN.SUBD_REPLENISHMENT);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
        distributor:localDialog || distributor,
      },
      () => {
        this.getReplenishmentReport();
      },
    );
  };


  loadTableData = () => {
    const { getDistributors, getUsers } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          this.setState({ distributorList: response.data.distributors.rows });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
    getUsers(
      {
        filter: {
          filters: [
            {
              column: 'role_id',
              value: ['5', '6'],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          this.setState({ users: response.data.users.rows },);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getReplenishmentReport = () => {
    const { data, queryParameters, distributor } = this.state;
    const { getReplenishmentReport } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    if (distributor.id !== '') {
      this.setState({
        showPaginatedList: true,
      });
      getReplenishmentReport(
        {
          offset,
          limit: queryParameters.pagination.limit,
          filter: {
            filters: [
              ...apiFilterProcessor(queryParameters.filter),
              {
                column: 'distributor_id',
                value: [distributor.id.toString()],
              },
            ],
            sort: getSortValueAfterValueCheck(queryParameters.sort),
          },
        },
        {
          handleSuccess: response => {
            const { getStockReplenishmentReport } = response.data;
            data.list = (getStockReplenishmentReport && getStockReplenishmentReport.rows) || [];
            data.total = (getStockReplenishmentReport && getStockReplenishmentReport.count) || 0;
            if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
            this.setState({
              data,
              disableGenerateButton: true,
            });
          },
          handleError: error => {
            this.onAPIRequestFailure(error);
          },
        },
      );
    }
  };

  handleDownloadClick = (reportType, domain) => {
    const {
      distributor: { id },
    } = this.state;

    const filter = {
      filters: [
        {
          column: 'distributor_id',
          value: [id.toString()],
        },
      ],
    };

    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
  };

  handleDownloadAllClick = (reportType, domain) => {
    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, {});
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  getFileData = async data => {
    const fileData = await fileParser(data.file).then(file => ({
      input: {
        file,
        type: UPLOADABLES.REPLENISHMENT,
      },
    }));
    return fileData;
  };

  onFormSubmit = async (Operation, data) => {
    const { upload, displayAlert } = this.props;
    const {
      file: { name },
    } = data;

    const inputData = await this.getFileData(data);
    upload(
      {
        ...inputData,
      },
      {
        handleSuccess: () => {
          this.setState({
            fileName: name,
            disableGenerateButton: false,
          });
          displayAlert(ALERT_TYPE.SUCCESS, 'File Uploaded Successfully');
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
          this.setState({
            fileName: '',
          });
        },
      },
    );
  };

  setStateDialog = (type, element) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  updateDialog = () => {
    this.setStateDialog(EVENT_OPERATION.UPLOAD, '');
  };

  resetDialog = () => {
    this.setStateDialog('', '');
  };

  saveButtonClick = () => {
    this.setStateDialog(EVENT_OPERATION.SAVE, '');
  };

  shareButtonClick = () => {
    this.setStateDialog(EVENT_OPERATION.SHARE, '');
  };

  handleDistributorSelect = event => {
    const { queryParameters } = this.state;
    queryParameters.pagination = {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    };
    this.setState(
      {
        distributor: {
          id: event.id,
          title: event.title,
        },
        queryParameters,
      },
      () => this.getReplenishmentReport(event.id),
    );
  };

  handleUserChange = event => {
    this.setState({
      userIds: event.map(d => parseInt(d.id, 10)),
    });
  };

  regenerateReport = () => {
    this.setState({
      showPaginatedList: false,
      fileName: '',
      disableGenerateButton: true,
      distributor: {
        id: '',
        title: '',
      },
    });
  };

  onShareReport = () => {
    const { shareOrderReport, displayAlert } = this.props;
    const { distributor, userIds } = this.state;

    if (userIds.length > 0) {
      shareOrderReport(
        {
          userIds,
          distributorIds: distributor.id,
        },
        {
          handleSuccess: response => {
            displayAlert(ALERT_TYPE.SUCCESS, response.data.shareOrderReport);
            this.setState({
              userIds: [],
            });
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    }
  };

  onSaveReport = () => {
    const { saveOrderReport, displayAlert } = this.props;
    const { distributor } = this.state;
    const { id } = distributor;

    saveOrderReport(
      {
        distributorIds: [parseInt(id, 10)],
      },
      {
        handleSuccess: response => {
          displayAlert(ALERT_TYPE.SUCCESS, response.data.saveOrderReport);
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  submitFunction = type => {
    switch (type) {
      case EVENT_OPERATION.SHARE:
        return this.onShareReport;
      case EVENT_OPERATION.SAVE:
        return this.onSaveReport;
      default:
        return this.onFormSubmit;
    }
  };

  render() {
    const {
      data,
      queryParameters,
      display,
      distributorList,
      distributor,
      dialog,
      fileName,
      showPaginatedList,
      disableGenerateButton,
      users,
      userIds,
      filterMenu,
    } = this.state;
    const { element, type } = dialog;
    const { serverResponseWaiting } = this.props;
    return (
      <SubDRepStyled>
        {type && (
          <DialogFormWrapper
            onDialogSubmit={this.submitFunction(type)}
            formConfig={formConfig[type]}
            dialogElement={element}
            onDialogCancel={this.resetDialog}
            activeStatus
            type={type}
            renderDialog={({ dialogData, enableErrorDisplay, handleFileUpload, refsObj }) => (
              <DialogEntities
                type={type}
                dialogData={dialogData}
                serverResponseWaiting={serverResponseWaiting}
                handleFileUpload={handleFileUpload}
                enableErrorDisplay={enableErrorDisplay}
                refsObj={refsObj}
                userList={users}
                userIds={userIds}
                onUserSelect={this.handleUserChange}
              />
            )}
          />
        )}
        <Fragment>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title: subdStockReplenishment.title,
                  create: false,
                  download: true,
                  filter: true,
                  search: false,
                  date: false,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList:filterMenu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                downloadConfig={{
                  domain: DOMAIN.STOCK_REPLENISHMENT_ALL,
                }}
                queryParameters={queryParameters}
                resetFilter={this.basePaginationService.resetFilter}
                handleDownloadClick={this.handleDownloadAllClick}
              />
            </PanelStyled>
          </div>
          <div className="section-content table-present section-select subd-replinishment-wrap">
            {distributorList.length > 1 ? (
              <CustomSelect
                enableValidation
                options={distributorList}
                className="custom-select"
                placeholder="Select Distributor"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                onChange={event => {
                  this.handleDistributorSelect(event);
                }}
                value={distributorList.filter(({ id }) => id === distributor.id) || []}
              />
            ) : null}
            {showPaginatedList && (
              <SubDReplenishmentView
                data={data}
                permission={this.permission}
                pagination={queryParameters.pagination}
                distributor={distributor}
                loading={serverResponseWaiting}
                onPageChange={this.basePaginationService.onPageSelect}
                saveButtonClick={this.saveButtonClick}
                shareButtonClick={this.shareButtonClick}
                onHandleClick={this.handleDownloadClick}
                onHeaderClick={this.basePaginationService.handleTableSorting}
              />
            )}
          </div>
        </Fragment>
      </SubDRepStyled>
    );
  }
}

SubDReplenishment.propTypes = propTypes;

SubDReplenishment.defaultProps = defaultProps;

export default withAlert()(SubDReplenishment);
