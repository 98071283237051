import { Switch, CheckBox } from '../../../../v4/components';

export const SKUAssortment = ({ ...props }) => {
  const { data, onInputChange } = props;
  return (
    <>
      <div className="form-input-wrap">
        <Switch
          checked={data.skuAssortmentMenu}
          labelContent="Enable SKU Assortment "
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'skuAssortmentMenu',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'skuAssortmentMenu',
              ['skuAssortmentData', 'skuAssortmentMenu'],
            );
          }}
          classes={'card'}
        />
        {data.skuAssortmentMenu ? (
          <div className="checkbox card flex-start ">
            <CheckBox
              checked={data.skuAssortment.channel}
              labelText="Channel"
              onCheckBoxClick={() => {
                onInputChange(
                  {
                    formattedValue: !data.skuAssortment.channel,
                    target: {
                      name: 'active',
                      value: 'boolean',
                      checked: !data.skuAssortment.channel,
                    },
                  },
                  'skuAssortment',
                  ['skuAssortmentData', 'skuAssortment', 'channel'],
                );
              }}
            />
            <CheckBox
              checked={data.skuAssortment.distributorGroup}
              labelText="Sub D"
              onCheckBoxClick={() => {
                onInputChange(
                  {
                    formattedValue: !data.skuAssortment.distributorGroup,
                    target: {
                      name: 'active',
                      value: 'boolean',
                      checked: !data.skuAssortment.distributorGroup,
                    },
                  },
                  'skuAssortment',
                  ['skuAssortmentData', 'skuAssortment', 'distributorGroup'],
                );
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};
