import { inventory as inventoryRoute, STOCK_COUNT } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';

const breadCrumb = [
  inventoryRoute,
  {
    id: 1,
    title: 'Stock Count',
    path: `/${STOCK_COUNT}`,
    active: true,
  },
];

const frequency = [
  {
    id: 1,
    label: 'Daily',
    title: 'DAILY',
    value: 'DAILY',
  },
  {
    id: 2,
    label: 'Weekly',
    title: 'week',
    value: 'WEEKLY',
  },
  {
    id: 3,
    label: 'Monthly',
    title: 'month',
    value: 'MONTHLY',
  }
  ,
  {
    id: 4,
    label: 'Fortnightly',
    title: 'fortnightly',
    value: 'FORTNIGHTLY',
  }
];

const WEEK_DAYS_TITLE = [
  { id:0,label:'SUN',title:'SUNDAY' },
  { id:1,label:'MON',title:'MONDAY' },
  { id:2,label:'TUE',title:'TUESDAY' },
  { id:3,label:'WED',title:'WEDNESDAY' },
  { id:4,label:'THUR',title:'THURSDAY' },
  { id:5,label:'FRI',title:'FRIDAY' },
  { id:6,label:'SAT',title:'SATURDAY' }
];

const filter = getFilterConfig([FILTER.STATUS]);

export {
  breadCrumb, filter as filterConfig, frequency, WEEK_DAYS_TITLE
};
