import { TARGET_COVERAGE } from '../../../../../data/enums/Route';
import { breadCrumbConfig } from '../../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Active Coverage Target',
    path: `/${TARGET_COVERAGE}`,
    active: true,
  },
];

export { breadCrumb };
