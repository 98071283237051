import styled from 'styled-components';

const CategoryRequestWrapper = styled.div`
  .table-wrap {
    th {
      cursor: default;
    }
    td {
      cursor: default;
      &.disabled {
        cursor: not-allowed;
      }
      .light {
        min-width: 90px;
        padding: 3px 4px;
        text-align: center;
        line-height: 10px;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;
        border-radius: 2px;
      }
      .pending {
        border: 0;
        padding: 0;
        .light {
          border: 1px solid #a7a9bc;
          color: #6b6c7e;
        }
      }
      .approved {
        .light {
          border: 1px solid #47c464;
          color: #287d3c;
        }
      }
      .rejected {
        .light {
          border: 1px solid rgba(218, 20, 20, 0.4);
          color: #da1414;
        }
      }
    }
  }
`;

export default CategoryRequestWrapper;
