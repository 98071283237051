/* global google */
/* eslint-disable */
import React, { Component } from 'react';
import Select from 'react-select';
import { createScriptLoadMap } from '../../services/googleMapScript';
import generateDrawingManager from '../../services/drawingManager';
import PageHeader from '../../../../base/PageHeader';
import { PanelStyled } from '../../../../common/configuration';
import arcApi from '../../services/ApiRest';
import marker from '../../services/marker';
import polyline from '../../services/polyline';
import infoWindow from '../../services/infoWindow';
import ARCFilter from '../../components/ARCFilter';
import { breadCrumb } from '../../config';
import ARCStyled from './ARCmapStyled';
import { getFilterConfig } from '../../../../../utils/filter';
import { FILTER } from '../../../../../data/enums/filter';
import RoutesList from '../RoutesList/RoutesList';
import ARCLoading from '../../components/ARCLoading/ARCLoading';
import colors from '../../enums/colors';
import iconUrls from '../../services/markerIcons';
// import setInitialMarkers from '../../services/setInitialMarkers'
import InputParameters from '../../components/InputParameters/InputParameters';
import setMarkerImage from '../../services/setMarkerImage';
import _ from 'lodash';
import MarkerClusterer from '@google/markerclustererplus';
// import generateImportedRoutes from '../../services/generateImportedRoutes';
import arrangeImportedRoutes from '../../services/arrangeImportedRoutes';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import nextChar from '../../utils/charIncrement';
import renderDom from '../../../../../utils/renderDom';
import Modal from '../../components/Modal/Modal';
import { BreadCrumb, Icon, Button } from '../../../../../v4/components';

class ARCmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routesNumber: '',
      maxDistance: '',
      maxOutlets: '',
      selectedPlaces: [],
      outlets: [],
      mapcenter: {},
      optimumRoute: [],
      routes_count: 0,
      max_distance: 0,
      max_outlet: 0,
      polylineRoutes: [],
      markersInitialInst: [],
      firstMarkers: [],
      routesMarkers: [],
      lastOutletSelected: false,
      lastMarkers: [],
      categoriesIds: [''],
      channelsIds: [''],
      townsIds: [''],
      drawingMode: false,
      totalDistance: '',
      outletFilter: [],
      disableMarkerSet: { allow: false, disableFirst: false, disableLast: true },
      loading: false,
      markerCluster: {},
      redunantMarkers: [],
      loadingInitialOutlets: false,
      fetchType: '',
      showDeleteConfirm: false,
      routeNullOutlets: [],
      routeTrueOutlets: [],
      startEndChar: 'A',
      hideRouteOutletsEnable: false,
      polygon: {},
      drawingManager: {},
      allowOutletsCSVDownload: false,
      showModal: false,
      showRemoveOutletModal: false,
      transferOutletId: '',
      removeOutletId: '',
      currentOpenTransfer: {},
      transferToRoute: null,
    };
    this.previousOverlay = null;
    this.unknownStartOutletCount = 1;
    this.unknownEndOutletCount = 1;
    this.selectOrder = 0;
    this.polylineInfoWin = null;
    this.markersVisibility = true;
    this.routeMarkersVisibile = true;
    this.filterConfig = getFilterConfig([FILTER.CHANNEL, FILTER.CATEGORY, FILTER.TOWN, FILTER.DSE, FILTER.SUB_D]);
    this.routesListRef = React.createRef();
    this.filterRef = React.createRef();
    this.deleteOutletsFromRoutes = () => { };
    this.handleMainResetRoutes = () => { };
    this.goBackRoutes = () => { };
    this.infoWin = null;
    this.currentIconRoute = null;
  }

  componentDidMount() {
    let drawingManager;
    createScriptLoadMap().then(res => {
      drawingManager = generateDrawingManager();
      this.setState({ drawingManager });
      window.google.maps.event.addListener(drawingManager, 'polygoncomplete', event => {
        this.setState({ polygon: event });
        this.handleOverlayComplete(event, true);
        google.maps.event.addListener(event.getPath(), 'insert_at', () => this.handleOverlayComplete(event, false));
        google.maps.event.addListener(event.getPath(), 'remove_at', () => this.handleOverlayComplete(event, false));
        google.maps.event.addListener(event.getPath(), 'set_at', () => this.handleOverlayComplete(event, false));
      });
      this.createSearchBox();
    });
  }

  createSearchBox = () => {
    // Create the search box and link it to the UI element.
    let input = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);

    // Bias the SearchBox results towards current map's viewport.
    window.globalMap.addListener('bounds_changed', () => {
      searchBox.setBounds(window.globalMap.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', () => {
      let places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // For each place, get the icon, name and location.
      let bounds = new google.maps.LatLngBounds();
      places.forEach(place => {
        if (!place.geometry) {
          console.log('Returned place contains no geometry');
          return;
        }

        let icon = {
          url: place.icon,
          size: new google.maps.Size(27, 27),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(27, 27),
        };

        // Create a marker for each place.
        let marker = new google.maps.Marker({
          map: window.globalMap,
          icon: icon,
          title: place.name,
          position: place.geometry.location,
        });

        const startOutletIcon = setMarkerImage(iconUrls.startFlag, 24, 24);
        const endOutletIcon = setMarkerImage(iconUrls.endFlag, 24, 24);

        marker.addListener('click', e => {
          if (this.state.disableMarkerSet.disableLast) {
            this.setState(
              prevState => ({
                disableMarkerSet: {
                  ...prevState.disableMarkerSet,
                  disableLast: false,
                  disableFirst: true,
                  allow: true,
                },
              }),
              () => {
                startOutletIcon.labelOrigin = new google.maps.Point(12, 7.5);
                marker.setIcon(startOutletIcon);
                google.maps.event.clearListeners(window.globalMap, 'click');

                let unknownFirstMarker = Object.assign(marker, {
                  id: Math.floor(1000 + Math.random() * 9000),
                  name: marker.title,
                  details: {
                    category: 'random category',
                    categoryId: 0,
                    channel: 'random channel',
                    channelId: 0,
                    town: 'random town',
                    townId: 0,
                  },
                  selectOrder: this.selectOrder,
                  searched: true,
                });

                this.setState(
                  prevState => ({
                    firstMarkers: [unknownFirstMarker, ...prevState.firstMarkers],
                  }),
                  () => {
                    this.handleFirstOutlet(unknownFirstMarker.id);
                  },
                );
              },
            );
          } else {
            this.setState(
              prevState => ({
                disableMarkerSet: {
                  ...prevState.disableMarkerSet,
                  disableFirst: false,
                  disableLast: true,
                  allow: true,
                },
              }),
              () => {
                endOutletIcon.labelOrigin = new google.maps.Point(12, 7.5);
                marker.setIcon(endOutletIcon);
                google.maps.event.clearListeners(window.globalMap, 'click');

                let unknownLastMarker = Object.assign(marker, {
                  id: Math.floor(1000 + Math.random() * 9000),
                  name: marker.title,
                  details: {
                    category: 'random category',
                    categoryId: 0,
                    channel: 'random channel',
                    channelId: 0,
                    town: 'random town',
                    townId: 0,
                  },
                  selectOrder: this.selectOrder++,
                  searched: true,
                });

                this.setState(
                  prevState => ({
                    lastMarkers: [unknownLastMarker, ...prevState.lastMarkers],
                  }),
                  () => {
                    this.handleLastOutlet(unknownLastMarker.id);
                  },
                );
              },
            );
          }
        });

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      window.globalMap.fitBounds(bounds);
    });
  };

  handleTransferButtonClick = outletId => {
    this.setState({ showModal: true, transferOutletId: outletId });
  };

  handleRemoveOutletButtonClick = (outletId) => {
    this.setState({ showRemoveOutletModal: true, removeOutletId: outletId });
  }

  hideModal = () => {
    this.setState({ showModal: false, showRemoveOutletModal: false, currentOpenTransfer: {} });
  }


  generateInfoWindowWithButton = markerDetails => (
    <div className="popover-content">
      <div className="popover-inner">
        <h3>
          {Number(markerDetails.details.order)}. {markerDetails.name}
        </h3>
        <ul>
          <li>{`Route: ${this.state.optimumRoute.find(route => route.dse === markerDetails.details.dse).label}`}</li>
          <li>{`ID: ${markerDetails.id}`}</li>
        </ul>
        <button className="popover-btn" onClick={() => this.handleTransferButtonClick(markerDetails.id)}>
          Transfer Route
        </button>
        <button className="popover-btn" onClick={() => this.handleRemoveOutletButtonClick(markerDetails.id)}>
          Remove From Route
        </button>
      </div>
    </div>
  );

  handleRouteTransfer = () => {
    const { displayAlert } = this.props;

    if (this.state.transferToRoute === null) {
      return displayAlert(ALERT_TYPE.INFO, 'Please select route to transfer!');
    }
    if (this.state.transferToRoute === this.currentIconRoute) {
      return displayAlert(ALERT_TYPE.INFO, 'This is the same route!');
    }

    this.setState({ showModal: false }, () => {
      if (this.infoWin) {
        this.infoWin.close();
      }
    });

    const copyOfOptimumRoute = [...this.state.optimumRoute];
    const copyOfMarkers = [...this.state.routesMarkers];
    const copyOfPolyLines = [...this.state.polylineRoutes];

    // Current Route API Data
    const currentRoute = copyOfOptimumRoute.find(route => route.dse === this.currentIconRoute);
    const currentRouteIdx = copyOfOptimumRoute.indexOf(currentRoute);

    // Get index of outlet to transfer
    const transferOutletIdx = currentRoute.travel.findIndex(outlet => outlet.id === this.state.transferOutletId);

    // Transfer To route API Data
    const transferToRoute = copyOfOptimumRoute.find(route => route.dse === this.state.transferToRoute);
    const transferToRouteIdx = copyOfOptimumRoute.indexOf(transferToRoute);

    // Add transfer outlet data to transfer Route travel list at the end,
    transferToRoute.travel.splice(transferToRoute.travel.length, 0, currentRoute.travel[transferOutletIdx]);

    // Remove outlet from current route travel list
    currentRoute.travel.splice(transferOutletIdx, 1);

    // // Update marker index of current route
    // for (let idx = transferOutletIdx; idx < currentRoute.travel.length; idx++) {
    //   const currentRouteOutlet = currentRoute.travel[idx];
    //   const currentRouteOutletMarker = copyOfMarkers.find(marker => marker.id === currentRouteOutlet.id)
    //   currentRouteOutletMarker.set('label', idx + 1);
    // }

    // gets id of only those outlets that are in selected outlet route
    const currentRouteOutletMarker = copyOfMarkers.find(marker => marker.id === this.state.transferOutletId);

    // Change marker labels/colors
    // currentRouteOutletMarker.set('label', transferToRoute.travel.length) // set index to last
    currentRouteOutletMarker.set('icon', setMarkerImage(iconUrls.markerColor[(transferToRouteIdx % (colors.length - 1))], null, null, 21, 33.6)) // change color
    currentRouteOutletMarker.details.dse = this.state.transferToRoute
    currentRouteOutletMarker.details.order = transferToRoute.travel.length

    // update poly line
    const currentRoutePolyLine = copyOfPolyLines[currentRouteIdx];
    const currentRoutePath = currentRoutePolyLine.getPath();
    currentRoutePath.removeAt(transferOutletIdx);
    currentRoutePolyLine.setPath(currentRoutePath);

    const transferToRoutePolyline = copyOfPolyLines[transferToRouteIdx];
    const transferToRoutePath = transferToRoutePolyline.getPath();

    transferToRoutePath.push(
      new google.maps.LatLng(currentRouteOutletMarker.position.lat(), currentRouteOutletMarker.position.lng()),
    );

    transferToRoutePolyline.setPath(transferToRoutePath);

    // update state
    this.setState({
      optimumRoute: copyOfOptimumRoute,
      polylineRoutes: copyOfPolyLines,
      routesMarkers: copyOfMarkers,
    })

  }


  removeOutletFromRoute = () => {
    const copyOfOptimumRoute = [...this.state.optimumRoute];
    const copyOfMarkers = [...this.state.routesMarkers];
    const copyOfPolyLines = [...this.state.polylineRoutes];

    const currentRoute = copyOfOptimumRoute.find(route => route.dse === this.currentIconRoute);
    const currentRouteIdx = copyOfOptimumRoute.indexOf(currentRoute);

    // Get index of outlet to remove
    const removeOutletIdx = currentRoute.travel.findIndex(outlet => outlet.id === this.state.removeOutletId);

    // Remove outlet from current route travel list
    currentRoute.travel.splice(removeOutletIdx, 1);
    // gets id of marker of removing outlet
    const currentRouteOutletMarkerIdx = copyOfMarkers.findIndex(marker => marker.id === this.state.removeOutletId);
    const currentRouteOutletMarker = copyOfMarkers.find(marker => marker.id === this.state.removeOutletId);
    currentRouteOutletMarker.setMap(null);
    copyOfMarkers.splice(currentRouteOutletMarkerIdx, 1);

    // update poly line
    const currentRoutePolyLine = copyOfPolyLines[currentRouteIdx];
    const currentRoutePath = currentRoutePolyLine.getPath();
    currentRoutePath.removeAt(removeOutletIdx);
    currentRoutePolyLine.setPath(currentRoutePath);

    this.setState({ showRemoveOutletModal: false }, () => {
      if (this.infoWin) {
        this.infoWin.close();
      }
    });

    // update state
    this.setState({
      optimumRoute: copyOfOptimumRoute,
      polylineRoutes: copyOfPolyLines,
      routesMarkers: copyOfMarkers,
    })
  }


  fetchOutlets = () => {
    this.setState({ loadingInitialOutlets: true }, () => {
      this.props.getOutlets(
        {
          filter: {
            filters: this.state.outletFilter,
          },
          offset: 0,
        },
        {
          handleSuccess: response => {
            // eslint-disable-next-line max-len
            if (response.errors) {
              this.props.displayAlert(ALERT_TYPE.CUSTOM_DANGER, `${response.errors[0].message}`);
            } else if (response.data.retailOutlets.count === 0) {
              this.props.displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'No outlets for this filter!');
            } else {
              // const center = averageGeolocation(response.data.retailOutlets.rows);
              this.createInitialMarkers(response);
            }
          },
        },
      );
    });

    this.setState({ loadingInitialOutlets: false });
  };

  createExistingRoutes = async routes => {
    //creates ROSIA routes from outlets information
    let arrangedRoutes = routes.arranged ? routes : arrangeImportedRoutes(routes);
    window.globalMap.setCenter({ lat: arrangedRoutes[0].travel[0].lat, lng: arrangedRoutes[0].travel[0].lng });
    let postMarkersContents = this.createRouteMarkers(routes.arranged ? routes : arrangedRoutes);
    this.setState({ routesMarkers: postMarkersContents.postMarkers });
    this.renderPolylines(postMarkersContents);
  };

  createInitialMarkers = response => {
    this.setState(
      {
        mapcenter: {
          lat: response.data.retailOutlets.rows[0].geoLocation.latitude,
          lng: response.data.retailOutlets.rows[0].geoLocation.longitude,
        },
        outlets: response.data.retailOutlets.rows,
        hideRouteOutletsEnable: true,
      },
      () => {
        this.setState({ markersInitialInst: [], markerCluster: {} }, () => {
          window.globalMap.setCenter(this.state.mapcenter);
          let initialMarker = [];
          let routeNullOutlets = [];
          let routeTrueOutlets = [];
          const redCircleMarker = setMarkerImage(iconUrls.circleRedMarker, null, null, 21, 33.6);
          const { Category, Town, Route, geoLocation, id, title, rMapId } = this.state.outlets[0];
          let details = {
            category: Category.title,
            categoryId: Category.id,
            channel: Category.Channel.title,
            channelId: Category.Channel.id,
            town: Town.title,
            townId: Town.id,
            route: Route ? true : false,
            rMapId: rMapId ? rMapId : '-',
          };
          initialMarker.push(
            new google.maps.Marker({
              position: { lat: geoLocation.latitude, lng: geoLocation.longitude },
              id: id,
              name: title,
              icon: redCircleMarker,
              details,
            }),
          );

          //for loop is faster
          for (let i = 1; i < this.state.outlets.length; i++) {
            const { id, title, Category, Town, Route, geoLocation, rMapId } = this.state.outlets[i];
            let currDetail = {
              category: Category.title,
              categoryId: Category.id,
              channel: Category.Channel.title,
              channelId: Category.Channel.id,
              town: Town.title,
              townId: Town.id,
              route: Route ? true : false,
              rMapId: rMapId ? rMapId : '-',
            };
            const currentOutlet = _.cloneDeep(initialMarker[0]);
            currentOutlet.position = new google.maps.LatLng(geoLocation.latitude, geoLocation.longitude);
            currentOutlet.id = id;
            currentOutlet.name = title;
            currentOutlet.details = currDetail;
            initialMarker.push(currentOutlet);
          }

          initialMarker.forEach(marker => {
            marker.addListener('click', e => {
              if (!this.state.disableMarkerSet.disableFirst || !this.state.disableMarkerSet.disableLast) {
                if (this.state.drawingMode) {
                  if (this.state.disableMarkerSet.disableLast) {
                    marker.selectOrder = this.selectOrder;
                    this.handleFirstOutlet(marker.id);
                  } else if (this.state.disableMarkerSet.disableFirst) {
                    marker.selectOrder = this.selectOrder++;
                    this.handleLastOutlet(marker.id);
                  }
                } else {
                  if (this.infoWin) {
                    this.infoWin.close();
                  }
                  this.infoWin = infoWindow.generateInfoWindow(null, marker.name);
                  this.infoWin.open(window.globalMap, marker);
                }
              }
            });
            !marker.details.route
              ? routeNullOutlets.push(_.cloneDeep(marker))
              : routeTrueOutlets.push(_.cloneDeep(marker));
          });

          let markerCluster = new MarkerClusterer(window.globalMap, initialMarker, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            maxZoom: 20, //at this zoom size all clusters become markers
            gridSize: 35,
          });

          this.setState({ markersInitialInst: initialMarker, markerCluster, routeNullOutlets, routeTrueOutlets });
        });
      },
    );
  };

  // handleAddStart = () => {
  //   document.querySelector('[title="Stop drawing"]').click();
  //   const startOutletIcon = setMarkerImage(iconUrls.startFlag, null, null, 24, 24)
  //   startOutletIcon.labelOrigin = new google.maps.Point(11.5, 7.5)
  //   this.state.polygon.addListener('click', e => {
  //     const marker = new google.maps.Marker({
  //       position: e.latLng,
  //       map: window.globalMap,
  //       icon: startOutletIcon,
  //       draggable: true,
  //       raiseOnDrag: true,
  //       label: {
  //         text: `${this.state.startEndChar}`,
  //         fontSize: "12px",
  //         fontWeight: 'bold',
  //         color: "black"
  //       }
  //     });

  //     marker.addListener('click', e => {
  //       marker.setMap(null);
  //       google.maps.event.clearListeners(this.state.polygon, 'click');
  //       this.setState(prevState => ({
  //         disableMarkerSet: {
  //           ...prevState.disableMarkerSet,
  //           disableFirst: false,
  //           disableLast: true,
  //           allow: true
  //         }
  //       }))
  //       this.setState({ firstMarkers: this.state.firstMarkers.slice(1) })
  //       this.unknownStartOutletCount--;
  //     })

  //     google.maps.event.clearListeners(this.state.polygon, 'click');
  //     this.setState(prevState => ({
  //       disableMarkerSet: {
  //         ...prevState.disableMarkerSet,
  //         disableLast: false,
  //         disableFirst: true,
  //         allow: true
  //       }
  //     }))

  //     let unknownFirstMarker = Object.assign(marker, {
  //       id: Math.floor(1000 + Math.random() * 9000),
  //       name: `Start Outlet ${this.unknownStartOutletCount++}`,
  //       details: {
  //         category: 'random category', categoryId: 0, channel: 'random channel', channelId: 0, town: 'random town', townId: 0
  //       },
  //       selectOrder: this.selectOrder
  //     });

  //     this.setState(prevState => ({
  //       firstMarkers: [unknownFirstMarker, ...prevState.firstMarkers],
  //     }));
  //   });
  // }

  // handleAddEnd = () => {
  //   document.querySelector('[title="Stop drawing"]').click();
  //   const endOutletIcon = setMarkerImage(iconUrls.endFlag, null, null, 24, 24)
  //   endOutletIcon.labelOrigin = new google.maps.Point(11.5, 7.5)

  //   this.state.polygon.addListener('click', e => {
  //     const marker = new google.maps.Marker({
  //       position: e.latLng,
  //       map: window.globalMap,
  //       icon: endOutletIcon,
  //       draggable: true,
  //       raiseOnDrag: true,
  //       label: {
  //         text: `${this.state.startEndChar}`,
  //         fontSize: "12px",
  //         fontWeight: 'bold',
  //         color: "black"
  //       }
  //     });
  //     this.setState({ startEndChar: nextChar(this.state.startEndChar) })
  //     marker.addListener('click', e => {
  //       marker.setMap(null)
  //       google.maps.event.clearListeners(this.state.polygon, 'click');
  //       this.setState(prevState => ({
  //         disableMarkerSet: {
  //           ...prevState.disableMarkerSet,
  //           disableLast: false,
  //           disableFirst: true,
  //           allow: true
  //         }
  //       }))
  //       this.setState({ lastMarkers: this.state.lastMarkers.slice(1) });
  //       this.unknownEndOutletCount--;
  //     })

  //     google.maps.event.clearListeners(this.state.polygon, 'click');
  //     this.setState(prevState => ({
  //       disableMarkerSet: {
  //         ...prevState.disableMarkerSet,
  //         disableFirst: false,
  //         disableLast: true,
  //         allow: true
  //       }
  //     }))

  //     let unknownLastMarker = Object.assign(marker, {
  //       id: Math.floor(1000 + Math.random() * 9000),
  //       name: `Last Outlet ${this.unknownEndOutletCount++}`,
  //       details: {
  //         category: 'random category', categoryId: 0, channel: 'random channel', channelId: 0, town: 'random town', townId: 0
  //       },
  //       selectOrder: this.selectOrder++
  //     })

  //     this.setState(prevState => ({
  //       lastMarkers: [unknownLastMarker, ...prevState.lastMarkers],
  //     }), () => {
  //       if (Number(this.state.routesNumber) === this.state.lastMarkers.length) {
  //         this.setState(prevState => ({
  //           disableMarkerSet: {
  //             ...prevState.disableMarkerSet,
  //             disableFirst: true,
  //             disableLast: true,
  //             allow: false
  //           }
  //         }))
  //       }

  //     });

  //   });
  // }

  handleFirstOutlet = id => {
    let alreadySelected = [];
    if (this.state.firstMarkers.length > 0) {
      alreadySelected = this.state.firstMarkers.filter(firstMarker => {
        return firstMarker.id === id;
      });
    }
    if (alreadySelected.length === 0) {
      // from original markers instances get the markers instance that matches with selected marker's id
      const firstMarker = this.state.markersInitialInst.filter(marker => marker.id === id);
      // push the multiple 'first time' selected markers (initial outlets)
      this.setState(prevState => ({
        firstMarkers: [...firstMarker, ...prevState.firstMarkers],
      }));

      // change the icon of the marker(s)

      firstMarker[0].set('icon', iconUrls.startFlag);
      let labelChangedIcon = {
        url: firstMarker[0].getIcon(),
        labelOrigin: new google.maps.Point(9, 7.5),
        color: 'black',
      };
      firstMarker[0].set('icon', labelChangedIcon);
      // let label = {
      //   text: `${this.state.startEndChar}`,
      //   fontSize: "12px",
      //   fontWeight: 'bold',
      //   color: "black"
      // };

      // firstMarker[0].set('label', label)
      // remove the selected initial outlet marker from initial markersInitialInst state
      this.state.markersInitialInst.splice(
        this.state.markersInitialInst.findIndex(marker => marker.id === firstMarker[0].id),
        1,
      );

      // add the start icon added initial firstMarker(s) to the 'GET markers' instances
      this.setState(prevState => ({
        markersInitialInst: [...firstMarker, ...prevState.markersInitialInst],
      }));

      this.props.setInitialOutlets(firstMarker[0]);

      //REDUNDANT CODE--CREATE FUNC

      this.setState(prevState => ({
        disableMarkerSet: {
          ...prevState.disableMarkerSet,
          disableLast: false,
          disableFirst: true,
        },
      }));
      google.maps.event.clearListeners(window.globalMap, 'click');
    } else if (!alreadySelected[0].searched) {
      const redCircleMarker = setMarkerImage(iconUrls.circleRedMarker, null, null, 21, 33.6);
      alreadySelected[0].setIcon(redCircleMarker);
      const firstMarkers = this.state.firstMarkers;
      _.remove(firstMarkers, marker => marker.id === alreadySelected[0].id);
      this.setState({ firstMarkers }, () => {
        this.setState(prevState => ({
          disableMarkerSet: {
            ...prevState.disableMarkerSet,
            disableLast: true,
            disableFirst: false,
          },
        }));
      });
      console.log('Already selected as first outlet or selected as random outlet');
    }
  };

  handleLastOutlet = id => {
    //check if handleLastOutlet and setInitialOutlets can be merged and same for last
    let alreadySelected = [];
    if (this.state.lastMarkers.length > 0) {
      alreadySelected = this.state.lastMarkers.filter(lastMarker => {
        return lastMarker.id === id;
      });
    }

    if (alreadySelected.length === 0) {
      // from original markers instances get the markers instance that matches with selected marker's id
      const lastMarker = this.state.markersInitialInst.filter(marker => marker.id === id);

      if (lastMarker.length > 0) {
        // push the multiple 'first time' selected markers (end outlets)
        this.setState(
          prevState => ({
            lastMarkers: [...lastMarker, ...prevState.lastMarkers],
          }),
          () => {
            console.log('Already selected as last marker or selected as random marker');
            if (Number(this.state.routesNumber) === this.state.lastMarkers.length) {
              this.setState(prevState => ({
                disableMarkerSet: {
                  ...prevState.disableMarkerSet,
                  disableFirst: true,
                  disableLast: true,
                  allow: false,
                },
              }));
            } else {
              this.setState(prevState => ({
                disableMarkerSet: {
                  ...prevState.disableMarkerSet,
                  disableLast: true,
                  disableFirst: false,
                },
              }));
            }

            // change the icon of the marker(s)
            lastMarker[0].set('icon', iconUrls.endFlag);
            let labelChangedIcon = {
              url: lastMarker[0].getIcon(),
              labelOrigin: new google.maps.Point(9, 7.5),
            };
            lastMarker[0].set('icon', labelChangedIcon);
            // let label = {
            //   text: `${this.state.startEndChar}`,
            //   fontSize: "12px",
            //   fontWeight: 'bold',
            //   color: "black"
            // }
            this.setState({ startEndChar: nextChar(this.state.startEndChar) });
            // lastMarker[0].set('label', label)
            // remove the selected initial outlet marker from initial markersInitialInst state
            this.state.markersInitialInst.splice(
              this.state.markersInitialInst.findIndex(marker => marker.id === lastMarker[0].id),
              1,
            );

            // add the start icon added initial lastMarker(s) to the 'GET markers' instances
            this.setState(prevState => ({
              markersInitialInst: [...lastMarker, ...prevState.markersInitialInst],
            }));

            this.props.setEndOutlets(lastMarker[0]);
          },
        );
      } else {
        this.props.setEndOutlets(lastMarker[0]);
      }
      google.maps.event.clearListeners(window.globalMap, 'click');
    } else if (!alreadySelected[0].searched) {
      const redCircleMarker = setMarkerImage(iconUrls.circleRedMarker, null, null, 21, 33.6);
      alreadySelected[0].setIcon(redCircleMarker);
      const lastMarkers = this.state.lastMarkers;
      _.remove(lastMarkers, marker => marker.id === alreadySelected[0].id);
      this.setState(
        { lastMarkers },
        () => {
          this.setState(prevState => ({
            disableMarkerSet: {
              ...prevState.disableMarkerSet,
              disableLast: true,
              disableFirst: false,
            },
          }));
        },
        () => {
          console.log('Already selected as last marker or selected as random marker');
          if (Number(this.state.routesNumber) === this.state.lastMarkers.length) {
            this.setState(prevState => ({
              disableMarkerSet: {
                ...prevState.disableMarkerSet,
                disableFirst: true,
                disableLast: true,
                allow: false,
              },
            }));
          }
        },
      );
    }
  };

  handleOverlayComplete = (polygon, redrawn) => {
    const { markersInitialInst, drawingManager } = this.state;
    this.setState({ drawingMode: true });
    if (redrawn && this.previousOverlay) {
      this.previousOverlay.setMap(null);
    }
    drawingManager.setDrawingMode(null);
    const placesSelected = [];
    this.setState({ selectedPlaces: [] });
    this.previousOverlay = polygon;

    markersInitialInst.forEach(place => {
      const latLng = new google.maps.LatLng(place.position.lat(), place.position.lng());
      const containsPlace = google.maps.geometry.poly.containsLocation(latLng, this.previousOverlay);

      if (containsPlace) {
        placesSelected.push(place);
      }
    });
    this.setState({ selectedPlaces: placesSelected, allowOutletsCSVDownload: true });
  };

  downloadSelectedOutlets = () => {
    const arrangedSelectedPlaces = this.arrangeSelectedPlaces();
    const { selectedPlaces } = this.state;

    arrangedSelectedPlaces.forEach(outlet => {
      selectedPlaces.forEach(place => {
        if (place.id === outlet.id) {
          outlet.rMapId = place.details.rMapId;
        }
      });
    });

    const array = [Object.keys(arrangedSelectedPlaces[0])].concat(arrangedSelectedPlaces);

    let csvData = array
      .map(it => {
        return Object.values(it).toString();
      })
      .join('\n');

    const file = new Blob([csvData], {
      type: 'text/csv',
    });
    const url = window.URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${Date.now()}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  onRoutesChange = e => {
    this.setState({ routesNumber: e.target.value }, () => {
      const noOfRoutes = Number(this.state.routesNumber);
      if (!(noOfRoutes === this.state.lastMarkers.length) && noOfRoutes > 0) {
        this.setState({ disableMarkerSet: { disableFirst: false, disableLast: true } });
      }
    });
  };

  onOutletsChange = e => {
    this.setState({ maxOutlets: e.target.value });
  };

  onDistanceChange = e => {
    this.setState({ maxDistance: e.target.value });
  };

  handleFilterChange = filterData => {
    this.setState({ outletFilter: [] });
    let categoriesIds = filterData.category.selectedIdList;
    let channelsIds = filterData.channel.selectedIdList;
    let townsIds = filterData.town.selectedIdList;
    let dseIds = filterData.dse.selectedIdList;
    let subDIds = filterData.sub_d.selectedIdList;

    if (channelsIds.length > 0) {
      this.setState(prevState => ({
        outletFilter: [...prevState.outletFilter, { column: 'channel_id', value: channelsIds.map(String) }],
      }));
    }
    if (categoriesIds.length > 0) {
      this.setState(prevState => ({
        outletFilter: [...prevState.outletFilter, { column: 'category_id', value: categoriesIds.map(String) }],
      }));
    }
    if (townsIds.length > 0) {
      this.setState(prevState => ({
        outletFilter: [...prevState.outletFilter, { column: 'town_id', value: townsIds.map(String) }],
      }));
    }
    if (dseIds.length > 0) {
      this.setState(prevState => ({
        outletFilter: [...prevState.outletFilter, { column: 'user_id', value: dseIds.map(String) }],
      }));
    }
    if (subDIds.length > 0) {
      this.setState(prevState => ({
        outletFilter: [...prevState.outletFilter, { column: 'distributor_id', value: subDIds.map(String) }],
      }));
    }
  };

  onGetOutletsClick = () => {
    if (this.props.fetchType === 'imported') {
      this.props.displayAlert(ALERT_TYPE.WARNING, `'Please reset the map first!'`);
    } else {
      if (Object.keys(this.state.markerCluster).length > 0) {
        this.state.markerCluster.clearMarkers();
      }
      this.fetchOutlets();
      let filterCard = document.getElementsByClassName('filter-dropdown-card');
      filterCard[0].style.display = 'none';
    }
  };

  toggleMarkerVisibility = () => {
    this.markersVisibility = !this.markersVisibility;
    if (this.markersVisibility) {
      this.state.markerCluster.addMarkers(this.state.markersInitialInst);
      this.state.markerCluster.removeMarkers(this.state.redunantMarkers);
      this.state.markerCluster.removeMarkers(this.state.routesMarkers);
    } else {
      this.state.markerCluster.clearMarkers();
    }
  };

  arrangeSelectedPlaces = () => {
    return this.state.selectedPlaces.map(selectedPlace => ({
      id: selectedPlace.id,
      lat: Number(selectedPlace.position.lat()),
      lng: Number(selectedPlace.position.lng()),
      name: selectedPlace.name,
      category: selectedPlace.details.category,
      categoryId: selectedPlace.details.categoryId,
      channel: selectedPlace.details.channel,
      channelId: selectedPlace.details.channelId,
      town: selectedPlace.details.town,
      townId: selectedPlace.details.townId,
    }));
  };

  arrangementsForGenerateRoutes = () => {
    const arrangedSelectedPlaces = this.arrangeSelectedPlaces();

    //arrange first and last outlets according to their respective indices in separate arrays--start
    const tracker = {};

    this.state.selectedPlaces.forEach((el, i) => {
      if (el.hasOwnProperty('selectOrder')) {
        if (!tracker[el.selectOrder]) {
          tracker[el.selectOrder] = {
            index1: i,
          };
        } else {
          tracker[el.selectOrder].index2 = i;
        }
      }
    });
    const startLocationsPositions = [];
    const endLocationsPositions = [];

    for (let selectOrder of Object.values(tracker)) {
      startLocationsPositions.push(selectOrder.index1);
      if (selectOrder.index2) {
        endLocationsPositions.push(selectOrder.index2);
      } else {
        endLocationsPositions.push(selectOrder.index1);
      }
    }
    //arrange... --end

    let totalStartLocations = startLocationsPositions.length;
    if (totalStartLocations < this.state.routesNumber) {
      for (var i = this.state.routesNumber - 1 - totalStartLocations; i >= 0; i--) {
        startLocationsPositions[i + totalStartLocations] = startLocationsPositions[i % totalStartLocations];
      }
    }

    let totalLastLocations = endLocationsPositions.length;
    if (totalLastLocations < this.state.routesNumber) {
      for (var i = this.state.routesNumber - 1 - totalLastLocations; i >= 0; i--) {
        endLocationsPositions[i + totalLastLocations] = endLocationsPositions[i % totalLastLocations];
      }
    }
    let arrangements = {
      arrangedSelectedPlaces,
      startLocationsPositions,
      endLocationsPositions,
    };
    return arrangements;
  };

  createRouteMarkers = res => {
    //remove randomly selected markers icons from map
    if (this.state.firstMarkers.length > 0) {
      this.state.firstMarkers.forEach(marker => {
        if (marker.icon !== undefined) {
          marker.setVisible(false);
        }
      });
    }
    if (this.state.lastMarkers.length > 0) {
      this.state.lastMarkers.forEach(marker => {
        if (marker.icon !== undefined) {
          marker.setVisible(false);
        }
      });
    }

    const allRoutes = JSON.parse(JSON.stringify(res));

    const unSelectedDseOutletsIds = [];
    let totalDseOutletsDistance = 0;

    // adds appropriate icons, order for selected outlets
    let outletsIconAdded = [];
    let iconColorIndex = 0;
    allRoutes.forEach((route, i) => {
      // const newStartIcon = setMarkerImage(iconUrls.startFlag, 3, 30, 30, 30)
      // const newEndIcon = setMarkerImage(iconUrls.endFlag, 3, 30, 30, 30)
      route.name = route.name ? route.name : `Route ${i + 1}`;
      route.townId = route.townId ? route.townId : route.travel[1].townId;
      // route.travel[0].iconUrl = newStartIcon;
      // route.travel[route.travel.length - 1].iconUrl = newEndIcon;
      // route.travel[0].distance = 'First';
      // route.travel[route.travel.length - 1].distance = 'Last';
      route.value = route.dse; //added for <Select/> for transfer Modal
      route.label = route.name;

      totalDseOutletsDistance = route.distance + totalDseOutletsDistance;
      if (iconColorIndex === iconUrls.markerColor.length - 1) {
        iconColorIndex = 0;
      }
      route.color = colors[iconColorIndex];
      route.travel.forEach((marker, j) => {
        // marker.assignedRoute = i;

        if (!marker.iconUrl) {
          const newIcon = setMarkerImage(iconUrls.markerColor[iconColorIndex], null, null, 21, 33.6);
          marker.iconUrl = newIcon;
        }
        if (!marker.order) {
          marker.order = marker.distance === 'First' ? 1 : marker.distance === 'Last' ? route.travel.length : j + 1;
        }
        if (!marker.dse) {
          marker.dse = route.dse;
        }
        unSelectedDseOutletsIds.push(marker.id);
        outletsIconAdded.push(marker);
      });
      iconColorIndex++;
    });

    const idsOfMarkersToRemove = outletsIconAdded.map(marker => marker.id);

    const redundantMarkersToRemovesInst = this.state.markersInitialInst.filter(marker =>
      idsOfMarkersToRemove.includes(marker.id),
    );

    redundantMarkersToRemovesInst.forEach(marker => {
      marker.setMap(null);
    });
    this.setState({ redunantMarkers: redundantMarkersToRemovesInst });
    const postMarkers = outletsIconAdded.map(outletIconAdded => {
      const icon = outletIconAdded.icon ? outletIconAdded.icon : outletIconAdded.iconUrl;
      const order = null;
      const position = !outletIconAdded.position
        ? outletIconAdded
        : {
          lat: outletIconAdded.position.lat(),
          lng: outletIconAdded.position.lng(),
        };
      const newMarker = marker.generateMarker(
        position,
        outletIconAdded.id,
        outletIconAdded.name,
        icon,
        order,
        outletIconAdded.distance,
        outletIconAdded.name,
        { dse: outletIconAdded.dse, order: outletIconAdded.order },
      );

      newMarker.addListener('click', () => {
        if (this.infoWin) {
          this.infoWin.close();
        }
        this.infoWin = new window.google.maps.InfoWindow();
        const div = document.createElement('div');
        this.currentIconRoute = newMarker.details && newMarker.details.dse;
        renderDom(this.generateInfoWindowWithButton(newMarker, this.handleTransferButtonClick), div);
        this.infoWin.setContent(div);
        this.infoWin.open(window.globalMap, newMarker);
      });
      return newMarker;
    });
    let routeStartEndFlags = postMarkers.filter(marker => marker.distance === 'First' || marker.distance === 'Last');
    this.setCharacterLabels(routeStartEndFlags);
    return { postMarkers, totalDseOutletsDistance, allRoutes };
  };

  setCharacterLabels = flags => {
    let uniqueFlags = _.uniqBy(flags, flag => {
      return flag.id;
    });
    this.setState({ startEndChar: 'A' }, () => {
      let { startEndChar } = this.state;
      uniqueFlags.forEach(marker => {
        let formatIcon = {
          url: marker.getIcon().url,
          labelOrigin: new google.maps.Point(16, 10),
          anchor: new google.maps.Point(3, 30),
          scaledSize: new google.maps.Size(30, 30),
        };
        marker.set('icon', formatIcon);
        let label = {
          text: `${startEndChar}`,
          fontSize: '12px',
          fontWeight: 'bold',
        };

        if (marker.distance === 'First') {
          marker.set('label', label);
        }
        if (marker.distance === 'Last') {
          marker.set('label', label);
          startEndChar = nextChar(startEndChar);
        }
      });
      this.setState({ startEndChar });
    });
  };

  getOptimumRoutes = () => {
    const dseCount = Number(this.state.routesNumber);
    const maxOutletsPerRoute = Number(this.state.maxOutlets);
    // let maxDistancePerRoute = Number(this.state.maxDistance);
    const maxDistancePerRoute = 150000;

    if ((this.state.routesNumber === 0 || this.state.routesNumber === '') && this.previousOverlay) {
      this.showError('Please fill in the parameters!');
    } else if (!this.previousOverlay) {
      this.showError('Please fill in the parameters and select area!');
    } else if (this.state.optimumRoute.length > 0) {
      this.showError('Please reset the map before creating new routes!');
    }
    // else if (!this.state.firstMarkers.length && !this.state.lastMarkers.length) {
    //   this.showError('Please select start and end points!')
    // }
    // else if ((dseCount * maxOutletsPerRoute) < (this.state.selectedPlaces.length)) {
    //   this.showError('Maximum outlets count too low!')
    // }
    else {
      this.setState({ loading: true });
      //add "randomly added" first/last markers to selectedPlaces
      let unknownFirstMarkers = this.state.firstMarkers.filter(marker => marker.details.town === 'random town');
      let unknownLastMarkers = this.state.lastMarkers.filter(marker => marker.details.town === 'random town');
      this.setState(
        prevState => ({
          selectedPlaces: [...unknownFirstMarkers, ...prevState.selectedPlaces, ...unknownLastMarkers],
        }),
        () => {
          const arrangements = this.arrangementsForGenerateRoutes();
          const demands = Array(arrangements.arrangedSelectedPlaces.length).fill(1);
          const capacity = Array(dseCount).fill(maxOutletsPerRoute);

          arcApi
            .generateRoutes({
              dse_count: dseCount,
              // max_distance: maxDistancePerRoute,
              outlets: arrangements.arrangedSelectedPlaces,
              max_outlet: maxOutletsPerRoute,
              // start_locations: arrangements.startLocationsPositions,
              // end_locations: arrangements.endLocationsPositions,
              // demands: demands,
              capacity: capacity,
            })
            .then(res => {
              if (res.data.message) {
                this.props.displayAlert(ALERT_TYPE.WARNING, `${res.data.message}`);
                this.setState({ loading: false });
              } else if (res.error) {
                this.setState({ loading: false });
                this.props.displayAlert(ALERT_TYPE.WARNING, `${res.error.message}`);
              } else {
                this.setState({ drawingMode: false, loading: false, hideRouteOutletsEnable: false });
                if (this.state.optimumRoute.length > 0) {
                  //CHECK IF THIS BLOCK IS NEEDED!
                  this.state.polylineRoutes.forEach(polylineRoute => {
                    polylineRoute.setMap(null);
                  });
                  this.state.routesMarkers.forEach(routesMarker => {
                    routesMarker.setMap(null);
                  });

                  if (this.state.firstMarkers.length !== 0) {
                    this.state.firstMarkers.forEach(marker => {
                      marker.set('icon', null);
                      marker.setMap(null);
                    });
                  }
                  this.state.markersInitialInst.forEach(outlet => {
                    outlet.set('icon', null);
                    outlet.setMap(window.globalMap);
                  });
                  this.setState({ lastOutletSelected: false });
                }

                const postMarkersContents = this.createRouteMarkers(res.data);
                this.setState({ routesMarkers: postMarkersContents.postMarkers });
                this.renderPolylines(postMarkersContents);
              }
            })
            .catch(err => {
              this.setState({ loading: false });
              this.showError('Something went wrong!');
            });
        },
      );
    }
  };

  showError = msg => {
    this.props.displayAlert(ALERT_TYPE.WARNING, `${msg}`);
  };

  renderPolylines = postMarkersContents => {
    this.props.loading(false);
    this.setState(
      {
        optimumRoute: postMarkersContents.allRoutes,
      },
      () => {
        this.setState({ totalDistance: postMarkersContents.totalDseOutletsDistance / 1000 });

        this.createAppropriatePolylines();
      },
    );
    if (!_.isEmpty(this.state.markerCluster)) {
      this.state.markerCluster.removeMarkers(this.state.redunantMarkers);
      this.state.markerCluster.removeMarkers(postMarkersContents.postMarkers);
    }
    if (this.previousOverlay) {
      this.previousOverlay.setMap(null);
    }
  };

  createAppropriatePolylines() {
    const routesLines = this.state.optimumRoute.map(route => {
      const routeLine = polyline.generatePolyline(route.travel, route.color, route.dse);
      routeLine.addListener('click', e => {
        this.handlePolylineClick(e, route.distance);
      });
      return routeLine;
    });
    this.setState({ polylineRoutes: routesLines });
  }

  handlePolylineClick(e, routeLength) {
    if (this.polylineInfoWin) {
      this.polylineInfoWin.close();
    }
    const polylineInfoWin = infoWindow.generateInfoWindow(true, routeLength);
    if (polylineInfoWin) {
      polylineInfoWin.setPosition({ lat: e.latLng.lat(), lng: e.latLng.lng() });
      polylineInfoWin.open(window.globalMap);
    }
  }

  handleRouteCancel = () => {
    this.setState({ drawingMode: false, selectedPlaces: [] });
    google.maps.event.clearListeners(window.globalMap, 'click');
    if (this.previousOverlay) {
      this.previousOverlay.setMap(null);
      this.previousOverlay = null;
    }
  };

  handleReset = () => {
    this.state.markersInitialInst.forEach(marker => {
      this.state.markerCluster.setMap(null);
      this.state.markerCluster.clearMarkers();
    });

    this.state.routesMarkers.forEach(routesMarker => {
      routesMarker.setMap(null);
    });

    this.setState({
      map: {},
      routesNumber: '',
      maxDistance: '',
      maxOutlets: '',
      selectedPlaces: [],
      outlets: [],
      mapcenter: {},
      routes_count: 0,
      max_distance: 0,
      max_outlet: 0,
      map: {},
      polylineRoutes: [],
      markersInitialInst: [],
      firstMarkers: [],
      lastMarkers: [],
      routesMarkers: [],
      lastOutletSelected: false,
      categoriesIds: [],
      channelsIds: [],
      townsIds: [],
      drawingMode: false,
      loading: false,
      totalDistance: '',
      outletFilter: [],
      fetchType: '',
      disableMarkerSet: { allow: false, disableFirst: false, disableLast: true },
      markerCluster: {},
      startEndChar: 'A',
    });
    this.unknownStartOutletCount = 1;
    this.unknownEndOutletCount = 1;
    this.selectOrder = 0;

    if (this.previousOverlay) {
      this.previousOverlay.setMap(null);
      this.previousOverlay = null;
    }

    this.state.polylineRoutes.forEach(polylineRoute => {
      polylineRoute.setMap(null);
    });

    if (this.state.firstMarkers.length !== 0) {
      this.state.firstMarkers.forEach(marker => {
        marker.setMap(null);
      });
    }
    if (this.state.lastMarkers.length !== 0) {
      this.state.lastMarkers.forEach(marker => {
        marker.setMap(null);
      });
    }

    this.state.markersInitialInst.forEach(outlet => {
      outlet.setMap(null);
    });
    this.setState({ lastOutletSelected: false });
    document.getElementById('pac-input').value = '';
    window.globalMap.setCenter({ lat: 27.6894, lng: 85.3227 });
    window.globalMap.setZoom(12);
    this.filterRef.current.handleFilterResetClick();
    if (this.state.optimumRoute.length > 0) {
      this.goBackRoutes();
      this.setState({ optimumRoute: [] });
    }
    this.handleMainResetRoutes();
  };

  deleteRoutePrompt = () => {
    this.setState({ showDeleteConfirm: true });
  };

  optimumRouteUpdate = routesList => {
    this.setState({ optimumRoute: routesList }, () => {
      let newDistance = 0;
      this.state.optimumRoute.forEach(route => {
        newDistance = route.distance + newDistance;
      });
      this.setState({ totalDistance: newDistance / 1000 });
    });
  };

  deleteCurrentRoute = (routeId, type) => {
    //delete current route or selected route
    if (type === 'actions') {
      this.state.polylineRoutes[routeId].setMap(null);
      this.setState(
        prevState => {
          const polyItems = [...prevState.polylineRoutes];
          polyItems.splice(routeId, 1);
          return { polylineRoutes: polyItems };
        },
        () => {
          this.deleteOutletsFromRoutes('actions');
        },
      );
    } else {
      this.state.polylineRoutes[routeId].setMap(null);
      this.setState(
        prevState => {
          const newItems = [...prevState.optimumRoute];
          newItems.splice(routeId, 1);
          const polyItems = [...prevState.polylineRoutes];
          polyItems.splice(routeId, 1);
          return { optimumRoute: newItems, polylineRoutes: polyItems };
        },
        () => {
          this.deleteOutletsFromRoutes();
        },
      );
    }
  };

  renameRouteName = (newRouteName, index) => {
    this.setState(prevState => {
      const newItems = [...prevState.optimumRoute];
      newItems[index].name = newRouteName;
      newItems[index].label = newRouteName;
      return { optimumRoute: newItems };
    });
  };

  getRoutesListDeleteOutlets = (deleteOutlets, handleMainReset, goBack) => {
    this.deleteOutletsFromRoutes = deleteOutlets;
    this.handleMainResetRoutes = handleMainReset;
    this.goBackRoutes = goBack;
  };

  hideRouteOutlets = isRoute => {
    let { markersInitialInst, markerCluster, routeNullOutlets, routeTrueOutlets } = this.state;
    if (isRoute) {
      routeNullOutlets = routeTrueOutlets;
    }
    if (this.routeMarkersVisibile) {
      //route true markers are visible, setMap(null), routeNullMarkers visible
      markerCluster.clearMarkers();
      if (markersInitialInst.length !== routeNullOutlets.length) {
        for (let i = 0; i < markersInitialInst.length; i++) {
          markersInitialInst[i].setMap(null);
        }
      }
      this.setState({ markersInitialInst: [] }, () => {
        routeNullOutlets.forEach(marker => {
          google.maps.event.clearListeners(marker, 'click');
          marker.addListener('click', () => {
            if (this.state.drawingMode) {
              if (this.state.disableMarkerSet.disableLast) {
                marker.selectOrder = this.selectOrder;
                this.handleFirstOutlet(marker.id);
              } else if (this.state.disableMarkerSet.disableFirst) {
                marker.selectOrder = this.selectOrder++;
                this.handleLastOutlet(marker.id);
              }
            } else {
              if (infoWin) {
                infoWin.close();
              }
              const infoWin = infoWindow.generateInfoWindow(null, marker.name);
              infoWin.open(window.globalMap, marker);
            }
          });
        });
        let markerCluster = new MarkerClusterer(window.globalMap, routeNullOutlets, {
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          maxZoom: 20, //at this zoom size all clusters become markers
          gridSize: 35,
        });
        this.setState({ markerCluster, markersInitialInst: routeNullOutlets });
      });
    } else {
      let initialMarkersInst = [];
      markerCluster.clearMarkers();
      if (markersInitialInst.length !== routeNullOutlets.length + routeTrueOutlets.length) {
        for (let i = 0; i < markersInitialInst.length; i++) {
          markersInitialInst[i].setMap(null);
        }
      }
      this.setState({ markersInitialInst: [] }, () => {
        initialMarkersInst.push(...routeTrueOutlets, ...routeNullOutlets);

        initialMarkersInst.forEach(marker => {
          google.maps.event.clearListeners(marker, 'click');
          marker.addListener('click', () => {
            if (this.state.drawingMode) {
              if (this.state.disableMarkerSet.disableLast) {
                marker.selectOrder = this.selectOrder;
                this.handleFirstOutlet(marker.id);
              } else if (this.state.disableMarkerSet.disableFirst) {
                marker.selectOrder = this.selectOrder++;
                this.handleLastOutlet(marker.id);
              }
            } else {
              if (infoWin) {
                infoWin.close();
              }
              const infoWin = infoWindow.generateInfoWindow(null, marker.name);
              infoWin.open(window.globalMap, marker);
            }
          });
        });
        let markerCluster = new MarkerClusterer(window.globalMap, initialMarkersInst, {
          imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          maxZoom: 20, //at this zoom size all clusters become markers
          gridSize: 35,
        });

        this.setState({ markerCluster, markersInitialInst: initialMarkersInst });
      });
    }
    if (!isRoute) {
      this.routeMarkersVisibile = !this.routeMarkersVisibile;
    }
  };

  hideRouteNullOutlets = () => {
    this.hideRouteOutlets(true);
  };

  render() {
    return (
      <ARCStyled>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumb} />
            <div className="arc-title-btn">
              <PageHeader
                config={{
                  title: 'ARC',
                }}
              />
              <Button secondary iconBtnSmall title="Reset" onClick={() => this.handleReset()} />
            </div>
          </PanelStyled>
        </div>

        <div className="section-content">
          <div className="flex-container">
            <div className="mapParam">
              <InputParameters
                routesNumber={this.state.routesNumber}
                maxDistance={this.state.maxDistance}
                maxOutlets={this.state.maxOutlets}
                onRoutesChange={this.onRoutesChange}
                onDistanceChange={this.onDistanceChange}
                onOutletsChange={this.onOutletsChange}
              />
              <div className="map-wrapper-inner relative">
                <div className="search-wrap absolute" style={{ top: '20px' }}>
                  <div id="demo-2">
                    <input type="search" id="pac-input" placeholder="Search Places" />
                  </div>
                </div>

                <div className="absolute" style={{ top: '70px', backgroundColor: 'white' }}>
                  <ARCFilter
                    ref={this.filterRef}
                    menuList={this.filterConfig.menu}
                    fetchType={this.state.fetchType}
                    onGetOutletsClick={this.onGetOutletsClick}
                    onFilterChange={e => this.handleFilterChange(e)}
                  />
                </div>

                {this.state.outlets.length > 0 && (
                  <div className="absolute-eyes" style={{ backgroundColor: 'white', left: '390px' }}>
                    <Icon
                      style={{ backgroundColor: 'white', height: '24px', width: '19px', cursor: 'pointer' }}
                      iconName="hidden"
                      onClick={() => this.toggleMarkerVisibility()}
                    />
                  </div>
                )}
                {this.state.hideRouteOutletsEnable && (
                  <div className="extra-ico" style={{ backgroundColor: 'white', left: '500px' }}>
                    <Icon
                      style={{ backgroundColor: 'white', height: '24px', width: '19px', cursor: 'pointer' }}
                      iconName="merge"
                      onClick={() => this.hideRouteOutlets()}
                    />
                  </div>
                )}
                {this.state.hideRouteOutletsEnable && (
                  <div className="merge-ico" style={{ backgroundColor: 'white', left: '610px' }}>
                    <Icon
                      style={{ backgroundColor: 'white', height: '24px', width: '19px', cursor: 'pointer' }}
                      iconName="merge"
                      onClick={() => this.hideRouteNullOutlets()}
                    />
                  </div>
                )}
                {this.state.selectedPlaces.length > 0 ? (
                  <div className="download-ico" style={{ backgroundColor: 'white', left: '710px' }}>
                    <Icon
                      style={{ backgroundColor: 'white', height: '24px', width: '19px', cursor: 'pointer' }}
                      iconName="download"
                      onClick={() => this.downloadSelectedOutlets()}
                    />
                  </div>
                ) : null}
                {this.state.loadingInitialOutlets && <ARCLoading title={'Fetching Outlets...'} />}

                {this.state.drawingMode ? (
                  <>
                    <div className="absolute-edit">EDIT MODE</div>

                    <div className="btns-wrapper">
                      {/* <div className="left-btns">
                        <Button secondary small onClick={() => this.handleAddStart()} disabled={this.state.disableMarkerSet.disableFirst} >
                          <img src={iconUrls.startFlag} alt="startFlag" /> Add Start
                        </Button>
                        <Button secondary small onClick={() => this.handleAddEnd()} disabled={this.state.disableMarkerSet.disableLast} >
                          <img src={iconUrls.endFlag} alt="endFlag" /> Add End </Button>
                      </div> */}
                      {this.state.selectedPlaces.length > 0 && (
                        <span>Selected Outlets: {this.state.selectedPlaces.length}</span>
                      )}
                      {this.state.loading && <ARCLoading title={'Creating Routes...'} />}
                      <div className="right-btns">
                        <Button secondary small title="Cancel" onClick={() => this.handleRouteCancel()} />
                        <Button primary small title="Create" onClick={() => this.getOptimumRoutes()} />
                      </div>
                    </div>
                  </>
                ) : null}
                <div id="map" />
              </div>
            </div>
            <RoutesList
              ref={this.routesListRef}
              routesList={this.state.optimumRoute}
              routeLines={this.state.polylineRoutes}
              allMarkers={this.state.markersInitialInst}
              visibleFancyMarkersInst={this.state.routesMarkers}
              markerColor={iconUrls.markerColor}
              totalDistance={this.state.totalDistance}
              deleteCurrentRoute={this.deleteCurrentRoute}
              markerCluster={this.state.markerCluster}
              getRoutes={this.props.getRoutes}
              getTownList={this.props.getTownList}
              getRoutesOutlets={this.props.getRoutesOutlets}
              createInitialMarkers={this.createInitialMarkers}
              fetchType={this.state.fetchType}
              renameRouteName={this.renameRouteName}
              optimumRouteUpdate={this.optimumRouteUpdate}
              saveRoutes={this.props.saveRoutes}
              deleteOutletsFromRoutes={this.getRoutesListDeleteOutlets}
              getRosiaRoutes={this.props.getRosiaRoutes}
              createExistingRoutes={this.createExistingRoutes}
            />
          </div>
        </div>

        <Modal show={this.state.showModal} handleClose={this.hideModal} handler={this.handleRouteTransfer}>
          <div className="m-header">
            <h2>Transfer Outlet</h2>
          </div>
          <div className="m-body">
            <p>Please select route to transfer selected outlet(s).</p>
            <div className="form-input">
              <label>Route</label>
              <Select
                name="routes"
                options={this.state.optimumRoute.filter(item => item.dse !== this.currentIconRoute)}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={e => this.setState({ transferToRoute: e.dse })}
              />
            </div>
          </div>
        </Modal>

        <Modal show={this.state.showRemoveOutletModal} handleClose={this.hideModal} handler={this.removeOutletFromRoute}>
          <div className="m-header">
            <h2>Remove Outlet</h2>
          </div>
          <div className="m-body">
            <p>This will remove outlet from current route. </p>
          </div>
        </Modal>

      </ARCStyled>
    );
  }
}
export default withAlert()(ARCmap);
