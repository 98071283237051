import { TableBody, TableHeader } from './tableConfig';
import withLoading from '../../../../../../utils/composition/withLoading';
import DragAndDropTable from '../../../../../components/DragAndDropTable/DragAndDropTable';

const PerfectCallTableList = props => {
  const { data, handleIconClick, permission, onSequenceChange } = props;

  return (
    <DragAndDropTable
      data={data.list}
      handleIconClick={handleIconClick}
      permission={permission}
      TableHeader={TableHeader}
      TableBody={TableBody}
      onSequenceChange={onSequenceChange}
    />
  );
};

const PerfectCallTableListWithLoading = withLoading(PerfectCallTableList);

export default PerfectCallTableListWithLoading;
