import { getTodaysDate } from '../../../../../utils/date';
import { refGenerator } from '../../../../../utils/refGenerator';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';

const budgetMessage = 'Aggregated Budget should not exceed:';
const errorMessageExceed = 'Sub Campaign\'s budget exceeds campaign\'s budget.';
const errorMessageInChange = 'Campaign Budget is changed and less than SubCampaign\'s budget .';
const requiredList = [
  'title',
  'subCampaignType',
  'targetValue',
  'budgetType',
  'dimension',
  'targetType',
  'budgetValue',
];
const formMapper = (element = {}) => ({
  id: element.id || 0,
  title: element.title || '',
  subCampaignType: element.subCampaignType || '',
  startDate: element.campaignStart ? element.campaignStart : element.startDate || getTodaysDate(),
  endDate: element.campaignEnd ? element.campaignEnd : element.endDate || getTodaysDate(),
  subCampaignBudget: element.subCampaignBudget || [],
  subCampaignTarget: element.subCampaignTarget || [],
});

const crudRequest = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => {
      return {
        ids: [data.id],
        type: 'SubCampaign',
        active: false,
      };
    },
    responseName: 'toggleState',
    message: `Sub Campaign ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};
const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add Sub Campaign',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: (element = {}) => formMapper(element),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update Sub Campaign',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: (element) => formMapper(element),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'DELETE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({ id: element.id || '' }),
  },
};

export {
  formConfig,
  formMapper,
  formMapper as listMapper,
  budgetMessage,
  crudRequest,
  errorMessageExceed,
  errorMessageInChange,
};
