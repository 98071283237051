import { compose } from 'recompose';
import ReplaceStock from './ReplaceStock';
import { GET_REPLACED_STOCK_DETAILS } from '../API';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const query = {
  getReplacedStockDetails: GET_REPLACED_STOCK_DETAILS,
};

const composedReplacedStock = compose(withRequestTracker({ query }))(ReplaceStock);

export default composedReplacedStock;
