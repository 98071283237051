import { compose } from 'recompose';
import DistributorSalesTarget from './DistributorSalesTarget';
import {
  query,
  mutation,
  CREATE_DISTRIBUTION_TARGET,
  EDIT_DISTRIBUTION_TARGET
} from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const ComposedDistributorSalesTarget = compose(
  CREATE_DISTRIBUTION_TARGET,
  EDIT_DISTRIBUTION_TARGET,
  withRequestTracker({
    query,
    mutation,
  })
)(DistributorSalesTarget);


export default ComposedDistributorSalesTarget;
