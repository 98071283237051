import styled from 'styled-components';

const GeneralStyled = styled.div`
  /* push message compose section only  */
  .push-message-compose {
    & > span,
    .form-input-wrap {
      display: inline-block;
      width: 48%;
    }
    & > span {
      margin-left: 15px;
      border: 1px solid #e7e7ed;
      border-radius: 4px;
      padding: 16px 12px;
      font-size: 10px;
      line-height: 16px;
      color: #272833;
      background-color: #ffffff;
      position: relative;
      top: 4px;
      &.notification-span {
        position: relative;
        top: -136px;
      }
      p {
        display: flex;
        margin: 0;
        .icon {
          height: 10px;
          width: 10px;
          color: #6b6c7e;
          margin-right: 8px;
          position: relative;
          top: -1.5px;
        }
        b {
          color: #6b6c7e;
          margin-bottom: 8px;
          font-weight: 400;
          display: block;
          line-height: 12px;
        }
        strong {
          display: block;
          width: 100%;
          font-weight: 400;
        }
      }
    }
  }
`;
export default GeneralStyled;
