import { compose } from 'recompose';
import SubCampaign from './SubCampaign';
import { mutation, TOGGLE_STATE } from './API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const ComposedSubCampaign = compose(
  TOGGLE_STATE,
  withRequestTracker({
    mutation,
  }),
)(SubCampaign);

export default ComposedSubCampaign;
