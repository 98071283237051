import { compose } from 'recompose';
import SubDStockAvailability from './SubDStockAvailability';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedSubDStockAvailability = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(SubDStockAvailability);

export default ComposedSubDStockAvailability;
