import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const APPROVAL_REQUEST = gql`
  query approvalRequests($type: approvalProcess!, $limit: Int, $offset: Int, $filter: FilterInput) {
    approvalRequests(type: $type, limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        createdBy
        requestedBy
        active
        status
        outletDeleteData {
          outletId
          remarks
          image {
            src: image
            thumbnail
          }
          RetailOutlet {
            id
            title
            Route {
              title
            }
            lastInvoicedDate
          }
        }
      }
      count
    }
  }
`;

const BULK_UPDATE_APPROVAL_REQUEST = graphql(
  gql`
    mutation bulkUpdateApprovalRequest($ids: [Int]!, $processId: Int!, $status: approvalStatus!) {
      bulkUpdateApprovalRequest(ids: $ids, processId: $processId, status: $status) {
        success
        failed
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      update: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getApprovalRequest: APPROVAL_REQUEST,
};

const mutation = {
  update: BULK_UPDATE_APPROVAL_REQUEST,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  APPROVAL_REQUEST, BULK_UPDATE_APPROVAL_REQUEST, DOWNLOAD_REPORT
};

export { query, mutation };
