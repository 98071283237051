import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RouteSegmentationView from './View';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { title, breadCrumbConfig, filterConfig } from './config';
import { isError } from '../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { ROUTE_SEGMENTATION_DETAILS } from '../../../data/enums/Route';
import { getPermissionForRouteSegmentation } from '../../base/permission';
import { crudResponseProcessor } from '../../../utils/crudResponseProcessor';
import { LINE_TYPE } from '../../../data/enums/Status';
import { PROGRAM } from '../../../data/enums/Permission';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getRouteSegmentation: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class RouteSegmentation extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: queryService.baseQueryParameters.date,
      },
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.permission = getPermissionForRouteSegmentation();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { getRouteSegmentation } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getRouteSegmentation(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            {
              column: 'active',
              value: ['true'],
            },
          ],
          dateRange: queryParameters.data,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.lines && response.data.lines.rows.filter(d => d.type !== LINE_TYPE.DEFAULT)) || [];
          data.total = (response.data.lines && response.data.lines.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleEvents = (type, id) => {
    const { history } = this.props;
    const detailId = type === EVENT_OPERATION.DETAILS ? id : 'create';

    history.push(`/${ROUTE_SEGMENTATION_DETAILS}/${detailId}`);
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    displayAlert(ALERT_TYPE.SUCCESS, 'Line successfully deactivated');
    crudResponseProcessor(
      modifiedData => {
        this.setState({ data: modifiedData });
      },
      () => data,
    )(type)(response);
  };

  render() {
    const { queryParameters, data } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-panel">
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                breadCrumb={breadCrumbConfig}
                config={{
                  title,
                  create: this.permission.create,
                  download: true,
                  date: false,
                  filter: false,
                  search: true,
                }}
                downloadConfig={{
                  domain: PROGRAM.ROUTE_SEGMENTATION,
                }}
                filter={{
                  date: queryParameters.date,
                  menuList: filterConfig.menu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                queryParameters={queryParameters}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.handleEvents}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleDownloadClick={this.basePaginationService.handleDownloadClick}
              />
            </PanelStyled>
          </div>
          <div className="section-content">
            <RouteSegmentationView
              data={data}
              permission={this.permission}
              pagination={queryParameters.pagination}
              loading={serverResponseWaiting}
              onEventFired={this.handleEvents}
              onBulkDelete={this.handleBulkDelete}
              onPageChange={this.basePaginationService.onPageSelect}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

RouteSegmentation.propTypes = propTypes;

RouteSegmentation.defaultProps = defaultProps;

export default withAlert()(RouteSegmentation);
