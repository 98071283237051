import { compose } from 'recompose';
import OpeningBalance from './OpeningBalance';
import { query, mutation } from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { DOWNLOAD_REPORT, UPLOAD } from '../../common/GqlAPI';

const ComposedOpeningBalance = compose(
  UPLOAD,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(OpeningBalance);

export default ComposedOpeningBalance;
