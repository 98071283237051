import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../utils/paginatedListUtils';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';

const propTypes = {
  data: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: [],
};

const WarehouseReportChildView = ({ data, handleTransferButton }) => (
  <Fragment>
    <tr>
      {detailsLabelConfig?.length > 0 &&
        detailsLabelConfig?.map?.(label => (label.show ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
    {data?.map?.(order => (
      <tr>
        {DetailTableBody({
          data: order,
          order,
          handleTransferButton,
        })}
      </tr>
    ))}
  </Fragment>
);

WarehouseReportChildView.propTypes = propTypes;

WarehouseReportChildView.defaultProps = defaultProps;

export default WarehouseReportChildView;
