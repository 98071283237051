import { ROUTE_SEGMENTATION, salesForce as salesForceRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Route Segmentation';

const breadCrumb = [
  salesForceRoute,
  {
    id: 1,
    title: 'Route Segmentation',
    path: `/${ROUTE_SEGMENTATION}`,
    active: false,
  },
];

const filter = getFilterConfig([FILTER.BRAND]);

export {
  title, breadCrumb as breadCrumbConfig, filter as filterConfig
};
