const generateDrawingManager = () => {
  const drawingManager = new window.google.maps.drawing.DrawingManager({
    defaultDrawingMode: window.google.maps.drawing.OverlayType.POLYGON,
    drawingControl: true,
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions: {
      editable: true,
    },
  });
  drawingManager.setMap(window.globalMap);
  return drawingManager;
};
export default generateDrawingManager;
