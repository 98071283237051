const IMAGE_STATUS_MAPPER = {
  EMPTY: 'empty',
  PENDING: 'pending',
  SUCCESS: 'success',
  DANGER: 'danger',
};

const imageObj = {
  url: '',
  size: 0,
  file: {},
  status: IMAGE_STATUS_MAPPER.EMPTY,
  title: '',
  thumbnail: '',
};

const getImageObj = (elm = {}) => imageObj;
const title = 'Add Image';
const imagePrefix = 'https://rosia-bu-assets.s3-ap-southeast-1.amazonaws.com/test-new/';

export {
  getImageObj, IMAGE_STATUS_MAPPER, title, imagePrefix
};
