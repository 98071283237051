import styled from 'styled-components';

const SubCampaignStyle = styled.div`
.detail-section{
  padding:24px;
  height:145px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  border-radius:4px;
  label{
    font-size: 12px;
    line-height: 15px;
    color: #6B6C7E;
    span{
      margin-left: 4px;
      svg{
        height: 10px;
        width: 10px;
      }
    }
  }
  p{
    margin:0px;
    margin-top:10px;
    font-size: 16px;
    line-height: 24px;
    color: #272833;
    display: flex;
    flex-direction: column;

    label{
      font-size: 8px;
      line-height: 10px;
      color: #272833;
      margin-bottom: 4px;
    }
    &:last-child {
      margin: 0;
    }
  }
}
.column-section{
  margin: 24px 0px;
  position: relative;
  .dropdown{
    margin: 8px;
    z-index:100;
    display:flex;

    .dropdown-menu,.type-level{
      background: #FFFFFF;
      width: 190px;
      padding: 8px 0px;
      margin-right: 4px;
      box-shadow: 0 4px 8px 0 rgb(39 40 51 / 12%);
      border-radius: 4px;
      z-index: 2;
    }
    .dropdown-menu{
      height:96px;
      li{
        height: 40px;
        padding: 10px 15px;
        width: 190px;
        text-transform: capitalize;

        &.active {
          background: #f7f8f9;
          border-radius: 4px;
          color: #272833;
          position: relative;

          .light {
            margin-right: 16px;
          }

          &:after {
            content: '';
            position: absolute;
            top: -15px;
            right: 12px;
            display: block;
            margin: 30px auto;
            width: 10px;
            height: 10px; 
            border-top: 2px solid #0b5fff;
            border-left: 2px solid #0b5fff;
            transform: rotate(135deg);
            border-radius: 2px 2px 0 2px;
          }
        }
      }
    }
    .type-level{
      li{
        height: 40px;
        padding: 10px 15px;
        width: 190px;
      }
    }
  }
}
.card-wrapper {
    display: flex;
    padding-top: 56px;
  }
  .top-column{
    position: absolute;
  }

  /* Form Styling */
.modal-wrapper{
  .modal-inner{
    max-width:750px
  }
}
.body_wrap{
  height: unset;
  overflow:auto;
  min-height: 10vh;
  max-height: 70vh;
}
.row-custom{
  padding:24px;
  border-bottom:1px solid #E7E7ED;
  padding-bottom:32px;
  &:last-child{
    border-bottom:0;
  }
  .single-date-picker{
    margin:0;
    &.end-date{
      .filter-dropdown-card{
        left:-70px;
      }
    }
  } 
}
.r3,.r2{
  &>label{
    font-size:16px;
    font-weight:500;
    color:#272833;
    line-height:24px;
    margin-bottom:10px
    display:block;
  }
  .form-input-wrap{
    .list-content{
      display: flex;
    align-items: flex-start;
    margin-bottom:10px;
    position:relative;
    .custom-control-label-text{
      width: 192px;
    margin-right: 8px;
    }
    .error-message{
      position:absolute;
      bottom:0;
      font-size:12px;
      width: 65%;
      svg{
        width:10px;
        height:10px;
      }
    }
    &>span{
      cursor:pointer;
      position:relative;
      top:8px;
    }
    .custom-control-label-number{
      flex:1;
      margin-right:18px;
    }
    }
}
  .form-select-input {
    margin-bottom:0;
    label{
      display:none;
    }
  }
}
`;

export default SubCampaignStyle;
