import { Calendar, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Menu } from '..';
import { formatter, getStartOfCurrentMonth, normalPresentor, presentor } from '../../../utils/date';
import DatePickerStyled from './DatePickerStyled';

interface SingleDatePickerProps {
  date: string;
  maxDate: string;
  minDate: string;
  disabled?: boolean;
  name: string;
  onChange: (name: string, date: string) => void;
}

const SingleDatePicker = (props: SingleDatePickerProps) => {
  const { date: dateProps, maxDate, minDate, disabled, name, onChange } = props;

  const handleSelect = (selectedDate: Range | Date) => {
    const date = normalPresentor(selectedDate);

    onChange(name, date);
  };

  const dateHeader = (
    <div className="date-input">
      <div className="moment">
        {presentor(dateProps)}
        <span className="ic-date-icon" />
      </div>
    </div>
  );

  return (
    <DatePickerStyled>
      <div className="datepicker-wrapper date-picker-single">
        <Menu header={dateHeader} hideOnClick disabled={disabled}>
          <div className="datepicker-block">
            <Calendar
              date={formatter(dateProps).toDate()}
              maxDate={formatter(maxDate).toDate()}
              minDate={formatter(minDate).toDate()}
              onChange={selectedDate => handleSelect(selectedDate)}
            />{' '}
          </div>
        </Menu>
      </div>
    </DatePickerStyled>
  );
};

const defaultProps = {
  date: getStartOfCurrentMonth(),
  maxDate: '2050-01-01',
  minDate: '2000-01-01',
  disabled: false,
  name: '',
};

SingleDatePicker.defaultProps = defaultProps;

export default SingleDatePicker;
