import { ORDER_FULFILMENT } from '../../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { getTodaysDate, getCurrentDay, getCurrentTime, getCurrentTimeMeridiem, normalPresentor } from '../../../../utils/date';
import moment from 'moment';

const title = 'Order Fullfilment';
const breadCrumb = [
  {
    id: 1,
    title: 'Sales',
    active: true,
  },
  {
    id: 2,
    title: 'Order Fullfilment',
    path: `/${ORDER_FULFILMENT}`,
    active: true,
  },
];
const formMapper = (element, distributorId = 0) => ({
  id: element?.id ?? '',
  phone: element?.phone ?? '',
  pan: element?.pan ?? '',
  address: element?.address ?? '',
  billingAddress: element?.billingAddress ?? '',
  client: element?.client ?? {},
  amPm: element?.amPm ?? getCurrentTimeMeridiem(),
  deliveryDate: element?.deliveryDate || getTodaysDate(),
  time: element?.time ?? getCurrentTime(),
  remarks: element?.remarks ?? '',
  notes: element?.notes ?? '',
  Distributor: { id: element.Distributor ? element?.Distributor?.id || 0 : distributorId },
  reason: element.reason || '',
  productArr: element.productArr ? element?.productArr : [],
  priceDetails: element?.priceDetails ?? {},
});

export const getPayloadIn = (element, outletList, skus) => {
  const momentObj = moment(element?.deliveryTo);
  return ({
    id: element?.id ?? '',
    phone: element?.phone ?? '',
    pan: element?.pan ?? '',
    address: element?.address ?? '',
    billingAddress: element?.billingAddress ?? element?.address ?? '',
    client: outletList?.find?.(a => a?.id === element?.retailOutletId) ?? {},
    amPm: momentObj?.format?.('A'),
    deliveryDate: momentObj?.format?.('YYYY-MM-DD') || getTodaysDate(),
    time: momentObj?.format?.('h:mm') ?? '',
    remarks: element?.remarks ?? '',
    productArr: formattedInUpdateIn?.(element?.orders, skus) ?? [],
  })
}
const formattedInUpdateIn = (data, skus) => {
  const formattedList = data?.map?.((item, index) => {
    const dataList = {
      quantity: item?.quantity,
      discount: item?.amountDetails?.discountAmount,
      product: skus?.find?.(a => a?.id === item?.skuId) ?? {},
      gross: item?.amountDetails?.grossAmount,
      taxableAmount: item?.amountDetails?.taxableAmount,
      taxAmount: item?.amountDetails?.taxAmount,
      netAmount: item?.amountDetails?.netAmount,
    }
    return dataList;
  });
  return formattedList;
};

const getPayload = (data, type = EVENT_OPERATION.CREATE) => {
  const { deliveryDate, time, amPm } = data;
  const deliveryTime = {
    deliveryDate: normalPresentor(deliveryDate) ?? getTodaysDate(),
    time: time ?? getCurrentTime(),
    amPm: amPm ?? getCurrentTimeMeridiem(),
  };
  const deliveryDateTime = moment(`${deliveryTime?.deliveryDate} ${deliveryTime?.time}:00 ${deliveryTime?.amPm}`, 'DD MMM, YYYY hh:mm a').format("YYYY-MM-DD HH:mm:ss");
  const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");
  const payloadOut = {
    distributorId: data?.Distributor?.id ?? null,
    retailOutletId: data?.client?.id ?? null,
    type: "SUCCESSFUL",
    notes: data?.notes ?? '',
    remarks: data?.remarks ?? '',
    deliveryFrom: deliveryDateTime ?? "",
    deliveryTo: deliveryDateTime ?? "",
    callTime: {
      callStart: currentTime ?? "",
      callEnd: currentTime ?? "",
    },
    location: {
      latitude: data?.client?.geoLocation?.latitude ?? 0,
      longitude: data?.client?.geoLocation?.longitude ?? 0,
    },
    orderCount: {
      lineCount: 1,
      brandCount: 1
    },
    callOrderValue: {
      grossAmount: data?.priceDetails?.grossWithVat ?? 0,
      taxableAmount: data?.priceDetails?.taxableAmount ?? 0,
      netAmount: data?.priceDetails?.netAmount ?? 0,
      taxAmount: data?.priceDetails?.taxAmount ?? 0,
      "discountAmount": 0,
      "promotionDiscount": 0,
      "billDiscount": 0,
      "topUpDiscount": 0,
      "subTotal": 0,
      "tradeDiscount": 0
    },
    images: [],
    orders: formattedInUpdate(data?.productArr, data?.Distributor?.id),

  };
  return payloadOut;
};
const formattedInUpdate = (data, distributorId) => {
  const formattedList = data?.map?.((item, index) => {
    const rate = item?.product?.Rates?.[0];
    const priceDetails = rate?.priceDetails;
    const dataList = {
      skuBatchRateId: rate?.id ?? 0,
      skuId: item?.product?.id ?? 0,
      familyId: item?.product?.skuFamily?.id ?? 0,
      distributorId: distributorId ?? null,
      promotionStatus: false,
      quantity: item?.quantity ?? null,
      rateDetails: {
        rlp: priceDetails?.rlp ?? null,
        rlpWithVat: (priceDetails?.rlp * priceDetails?.vatPercentage) + priceDetails?.rlp ?? 0,
        netPrice: priceDetails?.rlp ?? null,
        netPriceWithVat: (priceDetails?.rlp * priceDetails?.vatPercentage) + priceDetails?.rlp ?? 0,
      },
      amountDetails: {
        grossAmount: item?.grossWithVat ?? 0,
        taxableAmount: item?.taxableAmount ?? 0,
        netAmount: item?.netAmount ?? 0,
        taxAmount: item?.taxAmount ?? 0,
        discountAmount: item?.discount ?? 0,
        promotionDiscount: 0,
        topUpDiscount: 0,
        subTotal: item?.taxableAmount ?? 0,
        billDiscount: 0
      },
      promotions: [],

    }
    return dataList;
  });
  return formattedList;
};

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => {
      const formattedData = getPayload(data, EVENT_OPERATION.CREATE);
      return { input: formattedData };
    },
    responseName: 'createWebOrder',
    message: `Order ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      input: getPayload(data, EVENT_OPERATION.UPDATE),
    }),
    responseName: 'createWebOrder',
    message: `Order ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};
const meridiemList = [{ label: 'AM', value: 'am' }, { label: 'PM', value: 'pm' }]
export { crudSuccess, breadCrumb as breadCrumbConfig, title, formMapper, meridiemList };
