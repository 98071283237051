import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import {
  CheckBox, menuAction, tableHeaderWithCheckBox
} from '../../../v4/components';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const labelMappings = {
  checkbox: 'checkbox',
  id: 'id',
  title: 'title',
  categories: 'categories',
  brands: 'brands',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.id,
    title: 'ID',
    show: false,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  // {
  //   id: 4,
  //   label: labelMappings.brands,
  //   title: 'Brands',
  //   show: true,
  // },
  {
    id: 5,
    label: labelMappings.categories,
    title: 'Categories',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];


const menuConfigList = [
  // {
  //   title: 'Edit',
  //   icon: 'pencil',
  //   permissionDerivedBy: 'update',
  //   type: EVENT_OPERATION.UPDATE,
  // },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableHeader = ({
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
}) => tableHeaderWithCheckBox(
  dataListLength,
  checkedListLength,
  primaryCheckboxHandler,
  permission,
  labelConfig,
  labelMappings,
);

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

const returnTableBody = (
  data,
  checkedList,
  onIconClick,
  onSecondaryCheckBoxClick,
  permission,
  getCategoryTitle,
  getBrandTitle,
) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`${data.id}-checkbox`} onClick={(e) => e.stopPropagation()}>
      <CheckBox
        checked={checkedList.includes(data.id)}
        onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)}
      />
    </td>
  );
  table[labelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data.title}</td>;
  // table[labelMappings.brands] = (
  //   <td key={`${data.id}-brands`} className='brand-td'>
  //     {
  //       data?.Brands.map((brand, index) => (
  //         <div
  //           className='category-list-modified'
  //           key={`cat-id-${brand?.id}`}
  //         >
  //           {
  //             getBrandTitle(brand?.id, index, data?.Brands.length) || ''
  //           }
  //         </div>
  //       ))
  //     }
  //   </td>
  // );
  table[labelMappings.categories] = (
    <td key={`${data.id}-categories`} className='categories-td'>
      {
        data.Categories.length > 0 && data.Categories.map((category, index) => (
          <div
            className='category-list'
            key={`cat-id-${category.id}`}
          >
            {
              getCategoryTitle(category.id, index, data.Categories.length)
            }
          </div>
        ))
      }
    </td>
  );
  table[labelMappings.actionHolders] = (
    <td
      key={`${data.id}-action`}
      className='text-right simple-popup-actions'
      onClick={(e) => e.stopPropagation()}
    >
      {permission.delete && menuAction(
        menuConfigList,
        { menuIcon: 'ellipsis-v' },
        onIconClick,
        data,
        permission,
      )}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission, getCategoryTitle, getBrandTitle,onTableRowClick,
}) => {
  const tableBody = returnTableBody(
    data,
    checkedList,
    onIconClick,
    secondaryCheckboxHandler,
    permission,
    getCategoryTitle,
    getBrandTitle,
  );

  return (
    <tr
      key={data.id}
      onClick={() => onTableRowClick(data.id, EVENT_OPERATION.UPDATE)}
    >
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  getCategoryTitle: PropTypes.func.isRequired,
  getBrandTitle: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

export {
  TableHeader,
  TableBody,
};
