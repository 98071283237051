import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './App';
import apolloClient from './apiClient/apollo';
import GlobalCss from './components/global-css';

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <GlobalCss />
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.unregister();
