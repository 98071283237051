import styled from 'styled-components';

export const GeneralStyled = styled.div`

.checkbox-container{
    display: flex;
    flex-direction: column;
}

.form-input-wrap {
    padding: 24px 0px;
}

.security{
    width: 100%;
    padding-top: 8px;
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #6b6c7e;
    padding: 4px;
    border-bottom: 1px solid #cdced9;
}

.control-label{
    font-size: 14px;
    line-height: 26px;
}

.custom-control-label-text{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.checkbox-info{
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B6C7E;
    padding-left: 24px;
}
`;