import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
import AccordionTable from '../../../../../../components/CollapsibleTable/AccordionTable';
import { DatePicker } from '../../../../../../v4/components';
import CallDetail from './OrderDetail';
import OrderHistoryStyled from './OrderHistoryStyled';
import CTAccordionStyled from '../../../../../../components/CollapsibleTable/CTAccordionStyled';
import ViewType from '../../../../../components/ViewType';
import { ORDER_HISTORY_VIEW } from '../../../../../../data/enums/GraphQL';

const propTypes = {
  handleViewTypeChange: PropTypes.func.isRequired,
  handleDateRangeChange: PropTypes.func.isRequired,
  viewTypeConfig: PropTypes.shape({
    value: PropTypes.any,
    list: PropTypes.instanceOf(Array),
  }),
  viewType: PropTypes.string,
  TableBody: PropTypes.func.isRequired,
  TableHeader: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Array),
  date: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
};

const defaultProps = {
  viewTypeConfig: {
    value: '',
    list: [],
  },
  viewType: ORDER_HISTORY_VIEW.BRAND,
  order: [],
};

const ReceivedView = ({ ...props }) => {
  const { handleViewTypeChange, viewTypeConfig, handleDateRangeChange, viewType, TableBody, TableHeader, date, order } =
    props;

  return (
    <CTAccordionStyled>
      <OrderHistoryStyled>
        <div className='create-ui'>
          <div className='tab-section'>
            <div className='total-view-wrap'>
              <span />
              <div className='filter-wrap'>
                <ViewType
                  handleDropDownChange={handleViewTypeChange}
                  list={viewTypeConfig.list}
                  value={viewTypeConfig.value}
                />
                <div className='date-picker'>
                  <DatePicker date={date} onChange={handleDateRangeChange} />
                </div>
              </div>
            </div>

            <div className='table-wrap'>
              <table>
                <TableHeader viewType={viewType} />
                <Fragment>
                  <tbody>
                    {
                      <AccordionTable>
                        {order.map((received, index) => (
                          <tr
                            key={index}
                            trigger={TableBody({
                              data: received,
                              viewType,
                            })}
                          >
                            {<CallDetail orders={received} viewType={viewType} />}
                          </tr>
                        ))}
                      </AccordionTable>
                    }
                  </tbody>
                </Fragment>
              </table>
            </div>
          </div>
        </div>
      </OrderHistoryStyled>
    </CTAccordionStyled>
  );
};

ReceivedView.propTypes = propTypes;

ReceivedView.defaultProps = defaultProps;

const ReceivedViewWithErrorAndLoading = withErrorAndLoadingScreen(ReceivedView);

export default ReceivedViewWithErrorAndLoading;
