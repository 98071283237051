import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { CustomSelect, Input, SingleDatePicker } from '../../../../../components';
import ProductStyled from './ProductStyled';
import withLoading from '../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { EMPTY } from '../../../../../data/enums/ErrorMessage';

const propTypes = {
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
  getState: PropTypes.func,
  updateState: PropTypes.func,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  getState: () => null,
  updateState: () => null,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const ProductForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    skus,
  } = props;
  const discountValidation = data.discount > data.quantity * data?.product?.Rates?.[0]?.priceDetails?.rlp;
  return (
    <>
      <CustomSelect
        enableValidation
        options={skus}
        labelContent="Product Name *"
        className="custom-select"
        placeholder="Select Product"
        getOptionValue={({ id }) => id}
        getOptionLabel={({ title }) => title}
        ref={ref => (refsObj.product = ref)}
        enableErrorDisplay={enableErrorDisplay}
        onChange={event => handleDropDownChange(event, ['product'])}
        value={skus?.filter?.(({ id }) => id === data.product?.id)}
      />
      <Input
        type="number"
        enableValidation
        name="quantity"
        value={data?.quantity}
        placeholder="Quantity"
        labelContent="Quantity *"
        ref={ref => (refsObj.quantity = ref)}
        enableErrorDisplay={enableErrorDisplay || data?.quantity === 0}
        onChange={event => handleInputChange(event)}
        rule='isFloat'
        argument={{
          min: 1,
          allow_leading_zeroes: false,
        }}
        errorMessage={data?.quantity === 0 ? 'Quantity should be greater than zero.' : EMPTY}
      />
      {/* <Input
        type="number"
        enableValidation
        labelContent="Discount"
        name="discount"
        value={data.discount}
        placeholder="Discount"
        ref={ref => (refsObj.discount = ref)}
        argument={{
          // max: data.quantity * data?.product?.Rates?.priceDetails?.[0]?.rlp,
          min: 0,
          allow_leading_zeroes: false,
        }}
        enableValidation
        externalValidation={!discountValidation}
        enableErrorDisplay={enableErrorDisplay || discountValidation}
        errorMessage={discountValidation ? 'Discount should be less than net Amount' : EMPTY}
        onChange={event => handleInputChange(event)}
      /> */}
    </>
  );
};

ProductForm.propTypes = propTypes;

ProductForm.defaultProps = defaultProps;

const ProductFormWithErrorAndLoading = withLoading(ProductForm);

export default ProductFormWithErrorAndLoading;
