import React from 'react';
import { TableHeader, TableBody } from './tableConfig';
import { Pagination } from '../../../v4/components';
import RequestBulkSelect from '../../components/RequestBulkSelect';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const View = ({ ...props }) => {
  const { data, permission, handleRequest, pagination, onPageChange, onIconClick } = props;
  return (
    <>
      <RequestBulkSelect
        data={data.list}
        handleRequest={handleRequest}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap'>
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: state.checkedList.length,
                primaryCheckboxHandler,
                permission,
                dataList: data.list,
              })}
              <tbody>
                {data.list.map((category, key) =>
                  TableBody({
                    data: category,
                    checkedList: state.checkedList,
                    secondaryCheckboxHandler,
                    index: key,
                    onIconClick,
                    permission,
                    withOutIcon: true,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </>
  );
};

const ViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default ViewWithErrorAndLoading;
