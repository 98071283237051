import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BulkSelectView from './View';
import ModalWrapper from '../../common/DialogFormWrapper';
import Confirmation from '../../common/DialogConfirmation';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { formConfig, crudRequestConfig } from './config';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { extractSingleItemFromList } from '../../../utils/arrayProcessor';
import { handleFormSubmit, responseInterpreter } from '../../../utils/crudResponseProcessor';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  displayAlert: PropTypes.func.isRequired,
  handler: PropTypes.func,
  render: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  data: [],
  handler: () => null,
  serverResponseWaiting: false,
};

class BulkSelect extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps() {}

  constructor(props) {
    super(props);
    this.state = {
      checkedList: [],
      primarySelected: false,
      dialog: {
        type: '',
        element: {},
      },
    };
    const serverCall = {
      [EVENT_OPERATION.CHANGE_STATUS]: props.changePaymentStatus,
    };
    this.onCRUDSuccess = responseInterpreter(this.handleSuccessResponse);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.handleApiFailure, crudRequestConfig, serverCall);
  }

  componentDidMount() {}

  handlePrimaryCheckboxClick = e => {
    const { data } = this.props;
    if (e.target.checked) {
      const checkedList = extractSingleItemFromList(data);
      this.setState({ checkedList });
    } else {
      this.setState({ checkedList: [] });
    }
  };

  handleSecondaryCheckboxClick = id => {
    const { checkedList } = this.state;
    const index = checkedList.indexOf(id);
    if (index > -1) {
      checkedList.splice(index, 1);
    } else {
      checkedList.push(id);
    }
    this.setState({ checkedList });
  };

  handleApiFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ dialog: { type: '', element: '' }, checkedList: [] });
  };

  handleSuccessResponse = (type, response) => {
    const { handler, displayAlert, fetchAllData } = this.props;
    if (!handler) {
      displayAlert(ALERT_TYPE.SUCCESS, response.message);
      fetchAllData();
    } else {
      handler(type, response);
      fetchAllData();
      displayAlert(ALERT_TYPE.SUCCESS, response.message);
    }
  };

  onButtonClick = status => {
    const { checkedList } = this.state;
    this.setState({
      dialog: {
        type: EVENT_OPERATION.CHANGE_STATUS,
        element: { data: checkedList, status },
      },
    });
  };

  render() {
    const { data, serverResponseWaiting, render, Buttons } = this.props;
    const { checkedList } = this.state;
    const {
      dialog: { type },
    } = this.state;
    const {
      dialog: { element },
    } = this.state;

    return (
      <Fragment>
        {type && (
          <ModalWrapper
            type={type}
            dialogData={element}
            formConfig={formConfig[type]}
            onDialogCancel={this.handleClose}
            onDialogSubmit={this.onFormSubmit}
            formTitle={element.status !== EVENT_OPERATION.DISCARDED ? 'Change Status' : 'Remove Payment'}
            renderDialog={() => <Fragment>{Confirmation(element.status, checkedList.length)}</Fragment>}
          />
        )}
        <BulkSelectView
          checkedListLength={checkedList.length}
          dataLength={data.length}
          onIconClick={this.onButtonClick}
          loading={serverResponseWaiting}
          Buttons={Buttons}
        />
        {render(this.state, this.handlePrimaryCheckboxClick, this.handleSecondaryCheckboxClick)}
      </Fragment>
    );
  }
}

BulkSelect.propTypes = propTypes;

BulkSelect.defaultProps = defaultProps;

export default withAlert()(BulkSelect);
