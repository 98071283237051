import styled from 'styled-components';

const Wrapper = styled.div`
  .table-wrap {
    table {
      thead {
        tr {
          th {
            padding: 11px !important;
          }

          th:first-child {
            padding-left: 72px !important;
          }

          th:last-child {
            padding-right: 48px !important;
            width: 14% !important;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 9.5px !important;
          }

          td:first-child {
            padding-left: 72px !important;
            width: 20%;
          }

          td:last-child {
            padding-right: 48px !important;
          }

          td.text-right {
            text-align: right;
          }
        }

        tr.collapsible-body-tr > td {
          padding: unset !important;
        }
      }
    }
  }

  .td-wrapper {
    table {
      tbody {
        tr {
          th:last-child {
            width: 14% !important;
          }

          th {
            padding: 11px !important;
          }
          th.online{
            padding-right: 9.5px !important;
          }

          th.totalValues {            
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color:#272833;
          }

          th.total {            
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color:#272833;
            width: 20%;
          }

          th:first-child,
          td:first-child {
            padding-left: 60px !important;
          }
        }
      }
    }
  }

  table {
    td > span.carrot-icon {
      position: relative;
      &::before {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #6b6c7e;
        transform: rotate(0deg);
        transition: all 0.5s ease-in-out;
        position: absolute;
        top: 6px;
        right: 8px;
      }
    }
    .active {
      td {
        border-bottom: 1px solid #80acff;
      }

      td > span::before {
        transform: rotate(-180deg);
        transition: all 0.5s ease-in-out;
        border-top-color: var(--primary-d2);
        color: #0b5fff;
      }
    }
  }

  .image-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    cursor: pointer;

    .image-class {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #ffffff;
    }

    .images-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #f1f2f5;

      .image-text {
        width: 13px;
        height: 15px;
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        color: #6b6c7e;
      }
    }
  }
`;

export default Wrapper;

export const ReceiveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  width: 70px;
  height: 32px;
  padding: 8px;
  border-radius: 4px;
  background: #0b5fff;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;

export const DialogStyled = styled.div`
  .dialog-text {
    margin: 0;
    padding: 24px;

    .trip-id{
      font-weight: 600;
    }
  }
`;
