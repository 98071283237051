import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  UPLOAD, TOGGLE_STATE, DOWNLOAD_REPORT
} from '../../../common/GqlAPI';
import { TOWN_FRAGMENT } from '../../../configuration/town/API';
import { GEO_LOCATION_FRAGMENT } from '../../../common/GqlFragments';

import { CATEGORY_FRAGMENT, CATEGORY_SUMMARY_FRAGMENT } from '../../../configuration/channel/API';

const OUTLET_IMAGE_FRAGMENT = gql`
  fragment OutletImageFragment on Image {
    image
    thumbnail
    created_at
  }
`;

const OUTLET_SUMMARY_FRAGMENT = gql`
  fragment OutletSummaryFragment on RetailOutlet {
    id
    title
    rMapId
    active
    address
    panNumber
    Town {
      ...TownFragment
    }
    Category {
      ...CategorySummaryFragment
      Channel {
        id
        title
      }
    }
    contactInfo {
      name
      phoneNumber
    }
  }
  ${TOWN_FRAGMENT}
  ${CATEGORY_SUMMARY_FRAGMENT}
`;

const OUTLET_FRAGMENT = gql`
  fragment OutletFragment on RetailOutlet {
    id
    title
    rMapId
    active
    address
    panNumber
    imageUrl {
      ...OutletImageFragment
    }
    geoLocation {
      ...GeoLocationFragment
    }
    Town {
      ...TownFragment
    }
    Category {
      ...CategoryFragment
      Channel {
        id
        title
      }
    }
    contactInfo {
      name
      phoneNumber
    }
  }
  ${TOWN_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${OUTLET_IMAGE_FRAGMENT}
  ${GEO_LOCATION_FRAGMENT}
`;

const ROUTE_OUTLET_FRAGMENT = gql`
  fragment RouteOutletFragment on RetailOutlet {
    Route {
      id
      title
    }
    id
    title
    active
    address
    geoLocation {
      ...GeoLocationFragment
    }
    Category {
      id
      title
      Channel {
        id
        title
      }
    }
  }
  ${GEO_LOCATION_FRAGMENT}
`;

const GET_OUTLET_LIST = gql`
  query getRetailOutlet($offset: Int, $limit: Int, $filter: FilterInput) {
    retailOutlets(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...OutletSummaryFragment
      }
      count
    }
  }
  ${OUTLET_SUMMARY_FRAGMENT}
`;

const CREATE_OUTLET = graphql(
  gql`
    mutation ($title: String!, $channelType: channelType!) {
      createRetailOutlet(title: $title, type: $channelType) {
        ...OutletFragment
      }
    }
    ${OUTLET_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createOutlet: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_OUTLET = graphql(
  gql`
    mutation ($id: Int!, $title: String!, $channelType: channelType!) {
      updateRetailOutlet(id: $id, title: $title, type: $channelType) {
        ...ChannelCategoryFragment
      }
    }
    ${OUTLET_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateOutlet: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getOutletList: GET_OUTLET_LIST,
};

const mutation = {
  createOutlet: CREATE_OUTLET,
  updateOutlet: UPDATE_OUTLET,
  upload: UPLOAD,
  toggleState: TOGGLE_STATE,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  OUTLET_FRAGMENT,
  OUTLET_IMAGE_FRAGMENT,
  GET_OUTLET_LIST,
  CREATE_OUTLET,
  UPDATE_OUTLET,
  UPLOAD,
  ROUTE_OUTLET_FRAGMENT,
  TOGGLE_STATE,
  DOWNLOAD_REPORT,
};

export { query, mutation };
