const invoice = [
  { value: 1, label: '1 day' },
  { value: 2, label: '2 days' },
  { value: 3, label: '3 days' },
  { value: 4, label: '4 days' },
  { value: 5, label: '5 days' },
  { value: 7, label: '7 days' },
  { value: 9, label: '9 days' },
  { value: 11, label: '11 days' },
  { value: 13, label: '13 days' },
  { value: 15, label: '15 days' },
  { value: 17, label: '17 days' },
  { value: 19, label: '19 days' },
  { value: 21, label: '21 days' },
];

const callTypes = [
  { value: 'invoiced', label: 'Invoiced' },
  { value: 'unsuccessful', label: 'Unsuccessful' },
];

export { invoice, callTypes };
