import axios from 'axios';

const devUrl = 'https://v3.rosia.one/api';
const prodUrl = `${window.location.origin}/api`;
const envUrl = {
  http: process.env.NODE_ENV === 'development' ? devUrl : prodUrl,
};

const getBaseUrl = async () => {
  let baseUrl = '';
  await fetch(`${envUrl.http}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
    query {
      getARCUrl{
        url
    }
    }`,
    }),
  })
    .then((res) => res.json())
    .then((res) => baseUrl = res.data.getARCUrl.url);
  return baseUrl;
};


class ArcAPI {
  constructor() {
    this.baseUrl = getBaseUrl();
    this.config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }


  async getChannels() {
    try {
      const url = await this.baseUrl;
      const { data } = await axios.get(`${url}/channel/list?withCategory=1`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async getTown() {
    try {
      const url = await this.baseUrl;
      const { data } = await axios.get(`${url}/town/list`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async getOutlets(limit, channelsIds, categoriesIds, townsIds) {
    try {
      let channelIdsString = [];
      let categoriesIdsString = [];
      let townsIdsString = [];
      let channelIdsUrlString; let categoryIdsUrlString; let
        townIdsUrlString;

      if (channelsIds) {
        // for channelIdsUrlString
        channelIdsString = channelsIds.map((channelId) => `channelId[]=${channelId}&`);
        channelIdsUrlString = channelIdsString.join('');
      }

      if (categoriesIds) {
        // for categoryIdsUrlString
        categoriesIdsString = categoriesIds.map((categoryId) => `categoryId[]=${categoryId}&`);
        categoryIdsUrlString = categoriesIdsString.join('');
      }

      if (townsIds) {
        // for townIdsUrlString
        townsIdsString = townsIds.map((townId) => `townId[]=${townId}&`);
        townIdsUrlString = townsIdsString.join('');
      }

      const url = await this.baseUrl;
      const { data } = await axios.get(`
      ${url}/outlet/list?${channelIdsUrlString}${categoryIdsUrlString}${townIdsUrlString}page=0&limit=${limit}
      `);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async generateRoutes(postData) {
    try {
      const url = await this.baseUrl;
      const { data } = await axios.post(`${url}/routes/generate`, postData);
      return data;
    } catch (e) {
      throw e;
    }
  }

  async generateCSV(outlets) {
    try {
      const url = await this.baseUrl;
      const { data } = await axios({
        method: 'post',
        url: `${url}/routes/download`,
        data: outlets,
        responseType: 'blob',
      });
      return data;
    } catch (e) {
      throw e;
    }
  }
}

const arcApi = new ArcAPI();
export{ envUrl };
export default arcApi;
