import React from 'react';
import withLoading from '../../../../../utils/composition/withLoading';
import { Pagination } from '../../../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';

const View = (props) => {
  const { data, handleIconClick, loading, onPageChange, pagination,permission } = props;

  return (
    <>
      <div className='table-wrap'>
        <table>
          <TableHeader />
          <tbody>
            {data?.list?.map((targetData) => (
              <TableBody
                key={targetData.distributorId}
                data={targetData}
                // onTableBodyClick={onTableBodyClick}
                handleIconClick={handleIconClick}
                // displayEdit={!data.isDistributorTargetDerived || false}
                permission={permission}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </>
  );
};

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
