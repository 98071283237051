import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { Input } from '../../../../../components';
import { Col } from '../../../../../components/Grid';
import { dataProps } from '../../../../../data/enums/Component';
import { INVALID } from '../../../../../data/enums/ErrorMessage';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { menuAction } from '../../../../../utils/paginatedListUtils';
import { normalPresentor } from '../../../../../utils/date';
import TableStyled from './TableStyled';
import {
  Icon, Button, CheckBox
} from '../../../../../v4/components';

const propTypes = {
  data: dataProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onButtonClick: PropTypes.func,
  skuLines: PropTypes.instanceOf(Array),
  update: PropTypes.instanceOf(Object),
  breadCrumb: PropTypes.instanceOf(Array),
  checkedList: PropTypes.instanceOf(Array),
  checkBoxStatus: PropTypes.func,
  totalOrders: PropTypes.number,
  invoiceNumberStatus: PropTypes.bool,
  onPrimaryCheckBoxClick: PropTypes.func,
  onSecondaryCheckBoxClick: PropTypes.func,
  onInputChange: PropTypes.func,
  priceDetails: PropTypes.instanceOf(Object),
  viewType: PropTypes.string,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  skuLines: [],
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  checkBoxStatus: () => null,
  totalOrders: 0,
  invoiceNumberStatus: false,
  onPrimaryCheckBoxClick: () => null,
  onSecondaryCheckBoxClick: () => null,
  onInputChange: () => null,
  viewType: '',
  priceDetails: {},
  enableErrorDisplay: false,
  refsObj: {},
};

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableView = ({ ...props }) => {
  const {
    update,
    skuLines,
    onIconClick,
    checkedList,
    checkBoxStatus,
    totalOrders,
    invoiceNumberStatus,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
  } = props;
  let sn = 0;
  return (
    <TableStyled>
      {checkedList.length > 0 && invoiceNumberStatus && (
        <div className='selected-status'>
          <div className='content-center'>
            <div>
              <CheckBox checked disabled />
              <p className='text'>
                <span>{checkedList.length}</span>
                of
                <span>{totalOrders}</span>
                items selected
              </p>
            </div>
            <div className='del-btn' onClick={() => onIconClick(EVENT_OPERATION.BULK_DELETE, {})}>
              <Icon iconName='cross' />
            </div>
          </div>
        </div>
      )}
      <div className='table-wrap'>
        <table>
          <thead>
            <tr>
              {invoiceNumberStatus && skuLines.length
                && (
                  <th>
                    <CheckBox
                      checked={(totalOrders === checkedList.length) && totalOrders !== 0}
                      onCheckBoxClick={(e) => onPrimaryCheckBoxClick(e)}
                      disabled={skuLines?.map?.((a)=>a.Lines)?.flat()?.filter((a)=>a?.isBlocked)?.length>0}
                    />
                  </th>
                )}
              <th>S.No.</th>
              <th>SKU</th>
              <th>Batch</th>
              <th>Stock Type</th>
              <th>MFG Date</th>
              <th>EXP Date</th>
              {invoiceNumberStatus && <th className='text-right'>Quantity</th>}
              <th className='text-right'>Return Quantity</th>
              <th className='text-right'>Rate</th>
              <th className='text-right'>Amount</th>
              <th className='text-center'>Promotion</th>
              <th className='text-right'>Discount</th>
              <th className='text-right'>Net Amount</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              skuLines.map((sku, index) => (
                <Fragment>
                  {
                    sku.Lines.map((order, key, array) => {
                      sn += 1;
                      return (
                        <tr key={order.id} className={order?.isBlocked?'audit-blocked':''}>
                          {invoiceNumberStatus && (
                            <td>
                              <CheckBox
                                checked={checkBoxStatus(order.id)}
                                onCheckBoxClick={(e) => onSecondaryCheckBoxClick(order,
                                  e.currentTarget.checked)}
                                disabled={order?.isBlocked}
                              />
                            </td>
                          )}
                          <td>{sn}</td>
                          <td>{order.SKU && order.SKU.title}</td>
                          <td>
                            {order.batchName || order.SKUBatch.batchName}
                          </td>
                          <td>{order.stockType}</td>
                          <td className='text-center'>
                            {order.SKUBatch
                              ? order.SKUBatch.manufactureDate
                                ? (normalPresentor(order.SKUBatch.manufactureDate) === 'Invalid date'
                                  ? '-' : normalPresentor(order.SKUBatch.manufactureDate)) : null : null}
                          </td>
                          <td className='text-center'>
                            {order.SKUBatch
                              ? order.SKUBatch.expiryDate ? (normalPresentor(order.SKUBatch.expiryDate) === 'Invalid date'
                                ? '-' : normalPresentor(order.SKUBatch.expiryDate)) : null : null}
                          </td>

                          {invoiceNumberStatus && <td className='text-right'>{order.updatedQuantity}</td>}
                          <td className='text-right'>{order.returnQuantity}</td>
                          <td className='text-right'>{order.updatedAmountDetails && order.updatedAmountDetails.rate}</td>
                          <td className='text-right'>
                            {fixedFloatAndCommas((order.updatedAmountDetails
                              && (order.updatedAmountDetails.amount || order.updatedAmountDetails.rate
                                * order.returnQuantity || 0)) || 0)}
                          </td>
                          <td className='text-center'>
                            {
                              (key === 0 && sku.promotionId)
                                ? (
                                  <img
                                    src='/image/icons/Promotion.svg'
                                    alt='promotion-img'
                                    className='action-icon'
                                    onClick={() => onIconClick(EVENT_OPERATION.READ, sku)}
                                  />
                                ) : null
                            }
                          </td>
                          <td className='text-right'>
                            {fixedFloatAndCommas((order.updatedAmountDetails
                              && (Number(order.updatedAmountDetails.promotionDiscount)
                                + Number(order.updatedAmountDetails.topUpDiscount))) || 0)}
                          </td>
                          <td className='text-right'>{fixedFloatAndCommas(order.updatedAmountDetails.subTotal)}</td>
                          <td className={`text-right simple-popup-actions ${order?.isBlocked?'disabled':''}`} onClick={(e) => e.stopPropagation()}>
                            {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' },
                              onIconClick, order, { update: true, delete: !invoiceNumberStatus })}
                          </td>

                        </tr>
                      );
                    })
                  }
                </Fragment>

              ))}
          </tbody>
        </table>
        {!invoiceNumberStatus && (
          <div className='grn-sku-add'>
            <Button
              secondary
              iconBtnSmall
              iconName='plus'
              disabled={update.type === EVENT_OPERATION.APPROVE}
              onClick={() => onIconClick(EVENT_OPERATION.CREATE, {})}
            />
          </div>
        )
        }
      </div>
    </TableStyled>);
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithErrorAndLoading = withErrorAndLoadingScreen(TableView);


const TotalView = ({ ...props }) => {
  const {
    viewType,
    refsObj,
    enableErrorDisplay,
    priceDetails,
    onInputChange,
    invoiceNumberStatus,
  } = props;

  return (
    <Fragment>
      <div className='grn-sku-update'>
        <div className='grn-sku-update-inner'>
          <Row>
            <Col md={6}>
              <span>Sub Total</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.subTotal)}
                {' '}
              </div>
            </Col>
            <Col md={6} className='mt-5'>
              <span>Bill Discount</span>
            </Col>
            <Col md={6}>
              {!invoiceNumberStatus ? (
                <Input
                  name='billDiscount'
                  type='number'
                  enableValidation
                  rule='isFloat'
                  ref={(ref) => refsObj.billDiscount = ref}
                  argument={{
                    min: 0,
                    max: (priceDetails.subTotal || 0) - (priceDetails.tradeDiscount || 0),
                  }}
                  value={priceDetails.billDiscount || 0}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event)}
                  disabled={invoiceNumberStatus || (viewType === EVENT_OPERATION.DETAILS)}
                  errorMessage={INVALID}
                  decimalWithPrecedingZero
                />
              ) : (
                <div>
                  {fixedFloatAndCommas(priceDetails.billDiscount)}
                </div>
              )}
            </Col>
            <Col md={6} className='mt-5'>
              <span>Trade Discount</span>
            </Col>
            <Col md={6}>
              {!invoiceNumberStatus ? (
                <Input
                  name='tradeDiscount'
                  type='number'
                  enableValidation
                  rule='isFloat'
                  argument={{
                    min: 0,
                    max: (priceDetails.subTotal || 0) - (priceDetails.billDiscount || 0),
                  }}
                  ref={(ref) => refsObj.tradeDiscount = ref}
                  value={priceDetails.tradeDiscount || 0}
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) => onInputChange(event)}
                  disabled={invoiceNumberStatus || (viewType === EVENT_OPERATION.DETAILS)}
                  errorMessage={INVALID}
                  decimalWithPrecedingZero
                />
              ) : (
                <div>
                  {fixedFloatAndCommas(priceDetails.tradeDiscount)}
                </div>
              )}
            </Col>
            <Col md={6}>
              <span>Taxable Amount</span>
            </Col>
            <Col md={6}>
              <div>
                {' '}
                {fixedFloatAndCommas(priceDetails.taxableAmount)}
                {' '}
              </div>
            </Col>
            <Col md={6}>
              <span>VAT</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.taxAmount)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='grn-sku-update-total'>
        <span>Total</span>
        <div>
          {fixedFloatAndCommas(priceDetails.netAmount)}
        </div>
      </div>
    </Fragment>
  );
};

export { TableViewWithErrorAndLoading as TableView, TotalView };

TotalView.propTypes = propTypes;

TotalView.defaultProps = defaultProps;

export default TableViewWithErrorAndLoading;
