import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../base/TableHeader';
import { COVERAGE_TYPE } from '../../../../data/enums/GraphQL';
import { PRODUCTIVE_COVERAGE } from '../../../../data/enums/Route';
import {
  generalHeaderConfig, labelMappings, returnTableBody
} from '../tableConfig';

const COVERAGE_PARENT_DETAIL_MAPPER = {
  [COVERAGE_TYPE.CHANNEL]: COVERAGE_TYPE.CATEGORY,
  [COVERAGE_TYPE.SUB_D]: COVERAGE_TYPE.ROUTE,
};

const productiveCoverageLink = {
  id: 1,
  title: 'Productive Coverage',
  path: `/${PRODUCTIVE_COVERAGE}`,
  active: true,
};

const filterConfig = {};

const getFixedFilterParam = (type, id) => {
  if (type === COVERAGE_TYPE.CATEGORY) {
    return [{ column: 'channel_id', value: [id.toString()] }];
  }
  return [{ column: 'distributor_id', value: [id.toString()] }];
};

const viewBasedHeaderConfig = {
  [COVERAGE_TYPE.CATEGORY]: [
    {
      id: 1,
      label: labelMappings.category,
      title: 'Category',
      show: true,
    },
    ...generalHeaderConfig,
  ],
  [COVERAGE_TYPE.ROUTE]: [
    {
      id: 1,
      label: labelMappings.route,
      title: 'Route',
      show: true,
    },
    ...generalHeaderConfig,
  ],
};

const TableBody = ({ data, onRowClick, viewType }) => {
  const tableBody = returnTableBody(data, viewBasedHeaderConfig[viewType]);

  return <tr onClick={() => onRowClick(viewType)}>{tableBody.map((row) => row)}</tr>;
};

const TableHeaderConfig = ({ viewType }) => <TableHeader headerDetails={viewBasedHeaderConfig[viewType]} />;

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export {
  TableHeaderConfig as TableHeader,
  TableBody,
  getFixedFilterParam,
  COVERAGE_PARENT_DETAIL_MAPPER,
  productiveCoverageLink,
  filterConfig,
};
