/* eslint-disable arrow-parens */
import React from 'react'
import styled from 'styled-components'
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { getTimeDurationInSeconds } from '../../../../../utils/date';
import { TableBody, TableHeader } from './tableConfig';

const completedOrders = ({ overlaySummary, orderSummaryDetails, callDuration }) => {
  const { totalLines = 0, sbdSkuLines = 0 } = overlaySummary;

  const getCallDuration = () => {
    if (callDuration) {
      const duration = getTimeDurationInSeconds(callDuration.callStart, callDuration.callEnd)
      const minutes = parseInt(duration / 60);
      const seconds = duration % 60;

      return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
    }
  }

  return (
    <CompletedOrderContainer>
      <div className='completed-order-summary'>
        <h3>Orders</h3>
        <div className='order-pad-summary'>
          <div className='summary'>
            <label>Total Lines</label>
            <div className={`order-pad-value${totalLines > 0 ? ' primary' : ''}`}>{totalLines}</div>
          </div>
          <div className='summary'>
            <label>SBD SKUs</label>
            <div className={`order-pad-value${totalLines > 0 ? ' primary' : ''}`}>{sbdSkuLines}</div>
          </div>
          <div className='summary'>
            <label>Net Saving</label>
            <div className={`order-pad-value${totalLines > 0 ? ' primary' : ''}`}>{overlaySummary?.callOrderValue?.discountAmount ? `Rs. ${fixedFloatAndCommas(overlaySummary?.callOrderValue?.discountAmount)}` : '-'}</div>
          </div>
          <div className='summary'>
            <label>Net Amount</label>
            <div className={`order-pad-value${totalLines > 0 ? ' primary' : ''}`}>{overlaySummary?.callOrderValue?.netAmount ? `Rs. ${fixedFloatAndCommas(overlaySummary?.callOrderValue?.netAmount)}` : '-'}</div>
          </div>
        </div>
        {callDuration ?
          <div className='right'>
            <div className='time-counter'>{getCallDuration()}</div>
          </div>
          : null
        }
      </div>
      <div className='order-details-summary'>
        <table>
          {TableHeader()}
          <tbody>
            {orderSummaryDetails.length > 0 && orderSummaryDetails.map(skuCategory => (
              skuCategory.skus.length > 0 ?
                skuCategory.skus.map(sku => (
                  sku?.quantity ? TableBody({
                    data: sku
                  }) : null
                ))
                : null
            ))}
          </tbody>
        </table>
      </div>
    </CompletedOrderContainer>
  )
}

const CompletedOrderContainer = styled.section`
  border-left: 1px solid rgb(231, 231, 237); 
  height: 100%;
  .completed-order-summary {
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;

    h3 {
      margin: 0;
      font-weight: 400;
    }
    
    .order-pad-summary {
      margin-left: 3rem;
      gap: 3rem;
      display: flex;
      flex-direction: row;
        label {
          text-transform: capitalize;
          display: block;
          font-size: 12px;
          margin-bottom: 6px;
        }

        .order-pad-value {
          color: #A7A9BC;
          font-size: 16px;
          font-weight: 500;
          &.primary {
            color: #0B4FFF;
          }
        }
    }
    
    .right {
      margin-left: auto;
    }

    .time-counter {
      border: 1px solid rgba(218,20,20,0.4);
      border-radius: 4px;
      display: inline-block;
      color: #DA1414;
      padding: 8px 12px;
      margin-right: 1rem;
    }
  }

  
  .order-details-summary {
    height: calc(100% - 80px);
    overflow-y: auto;
    &>table{
      &>tbody tr,
      &>thead tr{
        td,
        th{
          &:first-child{
            padding-left:24px !important;
          }
          &:last-child{
            padding-right:24px !important;
          }
        }
      }

      tr > td > span.sbd-sku {
        margin-left: 8px;
        padding: 2px 8px;
        line-height: 18px;
        text-transform: uppercase;
        background-color: rgb(255, 115, 195);
        border-radius: 4px;
        font-size: 12;
        color: rgba(255,255,255);
      }

      .simple-popup {
        width: fit-content;
        display: inline-block;
        margin-left: .5rem;
        vertical-align: middle;

        .filter-background {
          color: #0B5FFF;
          font-weight: 500;

          .header-dom {
            padding: 1rem 0;
          }
        }
      &.promotion {
        .filter-dropdown-card{
          left: -16px;
          top: 180%;
          right: unset;
          padding:0;
          .filter-label{
            padding:14.5px 12.5px;
          }
          .filter-content {
            padding:0;
            .available-promotions{
              padding: 16px 12.5px;
              padding-bottom: 24px;
              li{
                text-align: left;
                list-style: disc;
                margin-bottom:16px;
                font-size:11px;
                color:#2E384D;
                list-style-position: inside;
                &:last-child{
                  margin-bottom:0;
                }
              }
            }
            .arrow-up {
              top: -6px;
              left: 15px;
              &::before{
                content: '';
                height: 8px;
                width: 8px;
                top: 2.5px;
                left: -4px;
                position: absolute;
                transform: rotate(45deg);
                border-left: 1px solid rgb(231,231,237);
                border-top: 1px solid rgb(231,231,237);
              }
            }
          }
        }
      }
      }
    }
  }
`

export default completedOrders
