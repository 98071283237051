import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import NumberCard from '../../../dashboard/components/NumberCard'
import Number from '../../../dashboard/components/Number';
import SectionStyled from '../../../dashboard/components/Section/SectionStyled';
import MonthlyWrap from './MonthlyWrap';
import { fixedFloatAndComma, fixedFloatAndCommas } from '../../../../utils/conversion';
import { MODULES } from '../config';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onModuleClick: PropTypes.func.isRequired,
};

const checkMoneyAbbr = (money) => {
  const moneyLength = money && fixedFloatAndComma(money).replace(/,/g, '').toString().length;
  if (moneyLength > 5 && moneyLength < 8) {
    return {
      number: fixedFloatAndCommas(parseFloat(money) / 100000),
      label: 'Lac',
    };
  }
  if (moneyLength > 7 && moneyLength < 12) {
    return {
      number: fixedFloatAndCommas(parseFloat(money) / 10000000),
      label: 'Cr',
    };
  }
  if (moneyLength > 11) {
    return {
      number: fixedFloatAndCommas(parseFloat(money) / 1000000000),
      label: 'Ar',
    };
  }
  if (moneyLength <= 5) {
    return {
      number: fixedFloatAndCommas(parseFloat(money)),
      label: '',
    };
  }
  return {
    number: 0,
    label: '',
  };
};

const MonthlyDetail = ({ data, onModuleClick }) => {
  const { target, achievement, coverage, ach,sbd,productivity } = data;
  const { number, label } = checkMoneyAbbr(target);
  return (
    <MonthlyWrap id='monthly-detail'>
      <SectionStyled className='custom-display'>
        <Link to ={'/reports/target-achievement'}>
          <div className='pointer'>
            <NumberCard title='target' subTitle='(npr)'>
              <Number number={number} label={label} size={32} numberPostfix />
            </NumberCard>
          </div>
        </Link>

        <Link to ={'/reports/target-achievement'}>
          <div className='pointer'>
            <NumberCard title='achievement' subTitle='(mtd)'>
              <Number number={fixedFloatAndComma(ach)}label='' size={32} numberPostfix />
            </NumberCard>
          </div>
        </Link>
        <Link to ={'/reports/target-achievement'}>
          <div className='pointer'>
            <NumberCard title='Ach. Percentage' subTitle='(MTD)'>
              <Number number={fixedFloatAndComma(parseInt(achievement, 10))}  label='' size={32} label='%' numberPostfix />
            </NumberCard>
          </div>
        </Link>
        <NumberCard title='Productivity' subTitle='(MTD)'>
          <Number number={fixedFloatAndComma(parseInt(productivity, 10))} size={32} numberPostfix label='%'/>
        </NumberCard>
        {/* <NumberCard title='SBD' subTitle=''>
          <Number number={fixedFloatAndComma(parseInt(sbd, 10))} label='%' size={32} numberPostfix />
        </NumberCard> */}

      </SectionStyled>
    </MonthlyWrap>
  );
};

MonthlyDetail.propTypes = propTypes;

export default MonthlyDetail;
