import React from 'react';
import { Icon } from '..';
import { menuActionWithTitle } from '../../../utils/paginatedListUtils';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import CardStyled from './CardStyled';
import { Search } from '../../../components';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

interface CardProps {
  onItemClick: (list: any) => void;
  handleSearchInput: any,
  title: string,
  selectedId: number,
  handleIconClick: any,
  data: [],
  permission: {
    read?: boolean,
    create?: boolean,
    update?: boolean,
    delete?: boolean,
    approve?: boolean,
    bulkPrint?: boolean,
  },
  menuList?: boolean,
  cardIndex?: number,
  handleBulkPrintClick?: any,
};

const menuConfigList = [
  {
    title: 'View',
    icon: '',
    permissionDerivedBy: 'read',
    type: EVENT_OPERATION.READ,
  },
  {
    title: 'Edit',
    icon: '',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: '',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];


const Card = ({ data, onItemClick, handleSearchInput, title, selectedId, handleIconClick, permission, menuList, cardIndex, handleBulkPrintClick }: CardProps) => {
  return (
    <CardStyled>
      <div className='card-pannel'>
        <div className='title-section'>
          <label>{title}</label>
          <div>
            {permission.bulkPrint && <Icon
              iconName='print'
              className='cursor-pointer'
              onClick={() => handleBulkPrintClick(EVENT_OPERATION.BULK_PRINT, {}, title, data)}
            />}
            {permission.create && <Icon
              iconName='plus'
              className='cursor-pointer'
              onClick={() => handleIconClick(EVENT_OPERATION.CREATE, {}, title, data)}
            />}
          </div>
        </div>
        <div className='body-section'>
          <Search hideInput={false} handleSearchInput={(text: any) => handleSearchInput(text, title, data, cardIndex)} />
          <div className='list-section'>
            <ul>
              {data.map((list: any) => (
                <li key={list.id} className={`${list.id === selectedId ? 'active' : 'inActive'}`}>
                  {(permission.read || permission.update || permission.delete) && menuActionWithTitle(menuList ? [...menuConfigList,
                  {
                    title: 'Print',
                    icon: '',
                    permissionDerivedBy: 'read',
                    type: EVENT_OPERATION.PRINT,
                  },] : menuConfigList, { menuIcon: 'ellipsis-v' }, handleIconClick, list, title, permission)}
                  <span
                    key={list.id}
                    onClick={() =>
                      onItemClick
                        ? onItemClick(list)
                        : ''
                    }
                  >
                    <span>
                      {list.title}
                      <p>{list.levelIndication}</p>
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </CardStyled>
  );
};

Card.defaultProps = {
  onItemClick: () => null,
  title: '',
  selectedId: 0,
  permission: PERMISSION_OBJ,
};

export default Card;
