import React, { useEffect, useState } from 'react';
import { baseApiUrlMapper } from '../Mapper/dataMapper';
import CryptoJS from 'crypto-js';
import { IV } from '../config/config';
import { isError } from '../../../../common/HelperFunctions';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';

const useScanDevicesController = (props) => {
  const { displayAlert, getLinkedDeviceToken, serverResponseWaiting } = props;
  const [baseApiUrl, setBaseApiUrl] = useState(null);
  const getLinkedDeviceTokenAPI = () => {
    getLinkedDeviceToken(
      {},
      {
        handleSuccess: (response) => {
          if (isError(response)) onAPIRequestFailure(response?.errors[0]);
          const deviceToken = baseApiUrlMapper(response?.data?.getLinkedDeviceToken);
          setBaseApiUrl(deviceToken);
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const QrData = baseApiUrl && JSON.stringify(baseApiUrl.data);

  const KEY_PARSED = baseApiUrl?.encryptKey && CryptoJS.enc.Utf8.parse(baseApiUrl?.encryptKey);
  const IV_PARSED = CryptoJS.enc.Utf8.parse(IV);

  const encryptedApiUrl =
    QrData &&
    KEY_PARSED &&
    CryptoJS.AES.encrypt(QrData, KEY_PARSED, {
      iv: IV_PARSED,
      mode: CryptoJS.mode.CBC,
    }).toString();

  useEffect(() => {
    getLinkedDeviceTokenAPI();
  }, []);

  return {
    encryptedApiUrl,
    serverResponseWaiting,
  };
};

export default useScanDevicesController;
