import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../v4/components';
import withLoading from '../../../utils/composition/withLoading';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.array,
    total: PropTypes.number,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  onPageChange: PropTypes.func,
  onHandleClick: PropTypes.func.isRequired,
};

const defaultProps = {
  onPageChange: () => null,
};

const View = ({ ...props }) => {
  const { data, onPageChange, pagination, onHandleClick } = props;

  return (
    <Fragment>
      <div className='table-wrap'>
        <table>
          {TableHeader()}
          <tbody>{data.list.map((channelAssortment) => TableBody({ data: channelAssortment, onHandleClick }))}</tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(View);

export default LoginViewWithErrorAndLoading;
