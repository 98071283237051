import styled from 'styled-components';

const UserWrapper = styled.div`
  .month-wrap {
    margin-bottom: 24px;
    label {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 4px;

      + div {
        width: 100%;
        background: #f1f2f5;
        border: 1px solid #e7e7ed;
        border-radius: 4px;
        padding: 0 16px;

        > div {
          margin: 0;
          display: block;
        }
      }
    }
    .month-view {
      position: relative;

      .month-icon {
        position: absolute;
        right: 0;
        margin: 0;
      }

      .ic-dropdown {
        display: none;
      }
    }
  }
  .table-wrap table {
    thead,
    tbody {
      th,
      td {
        width: auto;
        white-space: nowrap;
        &:last-child {
          text-align: left;
          padding-left: 48px;
        }
      }
    }
  }
`;

export default UserWrapper;
