import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { Input } from '../../../../../../components';
import { EMPTY, INVALID, UNIQUE } from '../../../../../../data/enums/ErrorMessage';
import CustomSelect from '../../../../../../components/CustomSelect';
import { USER_ROLE } from '../../../../../../data/enums';
import { uniqueFields } from '../config';
import UserAccountStyled from '../../UserAccountStyled';
import FormContentStyled from '../../../../../../components/global-css/FormContentStyled';
import { Switch, Button } from '../../../../../../v4/components';
import { JWT } from '../../../../../../environment';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  roles: PropTypes.arrayOf(Object).isRequired,
  refsObj: PropTypes.instanceOf(Object),
  parentUsers: PropTypes.arrayOf(Object).isRequired,
  onInputChange: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func.isRequired,
  billingUsers: PropTypes.arrayOf(Object).isRequired,
  crudMode: PropTypes.string.isRequired,
  menu: PropTypes.instanceOf(Object).isRequired,
  billingStatus: PropTypes.bool,
  enableUniqueFieldError: PropTypes.instanceOf(Object).isRequired,
  resetPassword: PropTypes.func,
  rigoIntegration: PropTypes.bool,
};
const defaultProps = {
  refsObj: {},
  enableErrorDisplay: false,
  billingStatus: false,
  resetPassword: () => null,
};

const getParentUsersForRole = (roleId = null, roles = [], users = []) => {
  const selectedRole = roles.find(role => role.id === roleId);

  const parentUserGroupId = selectedRole && selectedRole.UserGroup && selectedRole.UserGroup.parentGroupId;

  const parentRoleIds = roles.filter(role => role.userGroupId === parentUserGroupId);

  return parentRoleIds.length > 0 ? users.filter(user => parentRoleIds.includes(user.roleId)) : users;
};

const General = ({ ...props }) => {
  const {
    data,
    roles,
    designations,
    refsObj,
    parentUsers,
    onInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    billingUsers,
    crudMode,
    menu,
    billingStatus,
    enableUniqueFieldError,
    resetPassword,
    classificationTypes,
  } = props;
  const checkDistributorRole = data.roleId === USER_ROLE.DSE;
  const distributorsData = data.distributorIds || [];
  const filteredParentList = getParentUsersForRole(data.roleId, roles, parentUsers);
  const removeCurrentDistributorFromList =
    filteredParentList?.filter?.(item => item?.id !== data?.id) ?? filteredParentList;
  const checkBillingUser =
    data.roleId === USER_ROLE.BILLING_USER && distributorsData.length === 1 && billingUsers.length > 0;
  const {
    Role: { id },
  } = JSON.parse(localStorage.getItem('rosia-detail'));
  const buSettings = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.BU_SETTINGS));
  const fieldUsers = data.roleId === USER_ROLE.BRANCH_HEAD || data.roleId === USER_ROLE.DSE;
  return (
    <UserAccountStyled>
      <div
        className={`user-form-wrap create-ui-inner 
      ${crudMode === EVENT_OPERATION.CREATE ? 'tab-less-height' : ''}
       ${crudMode === EVENT_OPERATION.READ ? 'disabled' : ''}`}
      >
        <FormContentStyled>
          <h3>General Details</h3>
          <div className="form-input-wrap">
            <Input
              ref={ref => (refsObj.firstName = ref)}
              value={data.firstName}
              name="firstName"
              type="text"
              rule="matches"
              labelContent="First Name"
              argument={/^[a-zA-Z, ]+$/} /* matches alphanumeric, underscore ,'-' & ' ' */
              placeholder="First Name"
              enableErrorDisplay={enableErrorDisplay}
              enableValidation
              errorMessage={data.firstName ? INVALID : EMPTY}
              onChange={event => onInputChange(event)}
            />
            <Input
              ref={ref => (refsObj.lastName = ref)}
              value={data.lastName}
              name="lastName"
              type="text"
              labelContent="Last Name"
              placeholder="Last Name"
              enableErrorDisplay={enableErrorDisplay}
              enableValidation
              onChange={event => onInputChange(event)}
            />
            <Input
              ref={ref => (!checkDistributorRole ? (refsObj.email = ref) : '')}
              value={data.email}
              name="email"
              type="email"
              placeholder="Email"
              labelContent="Email"
              enableErrorDisplay={enableErrorDisplay || enableUniqueFieldError[uniqueFields.EMAIL]}
              enableValidation
              externalValidation={!enableUniqueFieldError[uniqueFields.EMAIL]}
              errorMessage={data.email ? (enableUniqueFieldError[uniqueFields.EMAIL] ? UNIQUE : INVALID) : EMPTY}
              displayMessage={!checkDistributorRole}
              onChange={event => onInputChange(event)}
            />
            {crudMode === EVENT_OPERATION.CREATE && (
              <Input
                ref={ref => (refsObj.password = ref)}
                value={data.password}
                name="password"
                type="password"
                placeholder="Password"
                labelContent="Password"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                errorMessage={data.password ? INVALID : EMPTY}
                onChange={event => onInputChange(event)}
              />
            )}
            <Input
              ref={ref => (refsObj.phoneNumber = ref)}
              value={data.phoneNumber}
              name="phoneNumber"
              type="number"
              rule="matches"
              labelContent="Phone Number"
              argument={/\b(\d{10}$)\b|\b(\d{7}$)\b/} /* matches digit of length 10 or 7 */
              placeholder="Phone Number"
              enableErrorDisplay={enableErrorDisplay || enableUniqueFieldError[uniqueFields.PHONE]}
              enableValidation
              externalValidation={!enableUniqueFieldError[uniqueFields.PHONE]}
              onChange={event => onInputChange(event)}
              errorMessage={data.phoneNumber ? (enableUniqueFieldError[uniqueFields.PHONE] ? UNIQUE : INVALID) : EMPTY}
            />
            <CustomSelect
              ref={ref => {
                refsObj.roleId = ref;
                return null;
              }}
              clearable={false}
              className="custom-select"
              placeholder="Select Role"
              labelContent="Role"
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              options={roles}
              enableValiation
              enableErrorDisplay={enableErrorDisplay}
              value={roles.filter(({ id }) => id === data.roleId)}
              onChange={e => handleDropDownChange(e.id, ['roleId'], handleDropDownChange)}
            />
            <CustomSelect
              className="custom-select"
              placeholder="Select Designation"
              labelContent="Designation"
              getOptionLabel={({ title }) => title}
              getOptionValue={({ id }) => id}
              options={designations}
              value={designations?.filter(({ id }) => id === data.designationId)}
              onChange={e => handleDropDownChange(e.id, ['designationId'], handleDropDownChange)}
            />
            <CustomSelect
              ref={ref => (fieldUsers ? (refsObj.classificationTypeId = ref) : undefined)}
              clearable={false}
              className="custom-select"
              placeholder="Select User Type"
              labelContent="User Type"
              getOptionLabel={({ title }) => title}
              getOptionValue={({ id }) => id}
              options={classificationTypes}
              enableValiation={!!fieldUsers}
              enableErrorDisplay={fieldUsers ? enableErrorDisplay : false}
              value={classificationTypes.filter(({ id }) => id === data.classificationTypeId)}
              onChange={e => handleDropDownChange(e.id, ['classificationTypeId'], handleDropDownChange)}
            />
            {data.roleId !== USER_ROLE.SUPER_ADMIN && (
              <CustomSelect
                ref={ref => {
                  refsObj.parentUserId = ref;
                  return null;
                }}
                clearable={false}
                className="custom-select"
                placeholder="Select Supervisor"
                labelContent="Supervisor"
                getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                getOptionValue={({ id }) => id}
                options={removeCurrentDistributorFromList}
                enableValiation
                enableErrorDisplay={enableErrorDisplay}
                value={parentUsers.filter(({ id }) => id === data.parentUserId)}
                onChange={e => handleDropDownChange(e.id, ['parentUserId'])}
              />
            )}
            {(data.roleId > USER_ROLE.MIS_ASSOCIATE || data.roleId === USER_ROLE.BRANCH_HEAD) &&
              data.parentUserId !== 0 &&
              data.roleId !== USER_ROLE.VISIBILITY_TEAM_LEADER &&
              data.roleId !== USER_ROLE.TAS && (
                <CustomSelect
                  ref={ref => {
                    refsObj.distributorIds = ref;
                    return null;
                  }}
                  isMulti
                  clearable={false}
                  className="custom-select"
                  placeholder="Select Distributor"
                  labelContent="Distributor"
                  getOptionLabel={({ title }) => title}
                  getOptionValue={({ id }) => id}
                  options={menu.distributorList}
                  enableValiation
                  enableErrorDisplay={enableErrorDisplay}
                  value={distributorsData ? menu.distributorList.filter(({ id }) => distributorsData.includes(id)) : []}
                  onChange={e => {
                    const distributors = e.map(d => d.id);
                    handleDropDownChange(distributors, ['distributorIds'], handleDropDownChange);
                  }}
                />
              )}
            {buSettings.rigoIntegration && (
              <>
                <Input
                  value={data?.rigoDetails?.rigoId}
                  name="rigoId"
                  type="number"
                  labelContent="RIGO Employee Id"
                  placeholder="RIGO Employee Id"
                  onChange={event => onInputChange(event, 'rigoDetails', ['rigoDetails', 'rigoId'])}
                />
                <Input
                  value={data?.rigoDetails?.deviceId}
                  name="deviceId"
                  type="number"
                  labelContent="RIGO Device Id"
                  placeholder="RIGO Device Id"
                  onChange={event => onInputChange(event, 'rigoDetails', ['rigoDetails', 'deviceId'])}
                />
              </>
            )}
          </div>
        </FormContentStyled>
        {(id === USER_ROLE.ADMIN || id === USER_ROLE.SUPER_ADMIN) && (
          <FormContentStyled>
            {crudMode === EVENT_OPERATION.UPDATE && (
              <>
                <h3>Security</h3>
                <div className="form-input-wrap">
                  <Input type="password" labelContent="Password" value="******" disabled />
                  <div className="reset-pw">
                    {/* <span>Change  </span>
                <span>|</span> */}
                    <span
                      onClick={() => {
                        resetPassword(EVENT_OPERATION.RESET);
                      }}
                    >
                      {' '}
                      Reset
                    </span>
                  </div>
                  {data.enableGoogleAuth && (
                    <div className="reset-two-factor-div">
                      <h1 className="reset-head-two-fact">Reset Two-Factor (2FA) Authentication</h1>
                      <Button
                        title="Reset"
                        className="two-factor-reset-btn"
                        onClick={() => {
                          resetPassword(EVENT_OPERATION.RESET_TWO_FACTOR_AUTHENTICATION);
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </FormContentStyled>
        )}
        {checkBillingUser && (
          <FormContentStyled>
            <h3>Billing Info</h3>
            <div className="form-input-wrap">
              <Switch
                onToggle={(checked, event) => {
                  onInputChange(
                    {
                      formattedValue: !!checked,
                      target: {
                        name: 'active',
                        type: 'boolean',
                        value: !!checked,
                      },
                    },
                    'billingStatus',
                    ['billingStatus'],
                  );
                }}
                checked={billingStatus}
                name="active"
                labelContent="Billing"
              />
              {billingStatus && (
                <CustomSelect
                  ref={ref => {
                    refsObj.billingUserId = ref;
                    return null;
                  }}
                  clearable={false}
                  className="custom-select"
                  placeholder="Map Billing User"
                  labelContent="Billing user"
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ idUsers }) => idUsers}
                  options={billingUsers}
                  enableValiation
                  enableErrorDisplay={enableErrorDisplay}
                  value={billingUsers.filter(({ idUsers }) => idUsers === data.billingCredentials.user_id)}
                  onChange={e =>
                    handleDropDownChange(e.idUsers, ['billingCredentials', 'user_id'], handleDropDownChange)
                  }
                />
              )}
            </div>
          </FormContentStyled>
        )}
      </div>
    </UserAccountStyled>
  );
};

General.propTypes = propTypes;

General.defaultProps = defaultProps;
export default General;
