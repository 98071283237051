import { support as supportRoute, requests as requestsRoute } from '../../../../data/enums/Route';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';
import { FORM_CONFIG } from '../../../../data/enums/config';

const title = 'Geolocation Change Request';
const breadCrumb = [supportRoute, requestsRoute];
const filter = getFilterConfig([FILTER.REQUESTS_STATUS, FILTER.SUB_D, FILTER.STL, FILTER.ASM]);
const formConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    id: element.id || '',
    processId: element.processId || '',
    status: element.status || '',
    geolocationChangeData: element.geolocationChangeData || {},
  }),
  [FORM_CONFIG.TITLE]: 'Create',
};

const updateHistoryConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    geolocationChangeData: element.geolocationChangeData || {},
  }),
  [FORM_CONFIG.TITLE]: '',
};

export {
  breadCrumb as breadCrumbConfig, formConfig, title, filter as filterConfig, updateHistoryConfig
};
