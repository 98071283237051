import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import PSRView from './View';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { breadCrumbConfig, filterConfig, title } from './config';
import { TableHeader } from './tableConfig';
import { clone } from '../../../utils/objectProcessor';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getPSRList: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class PSR extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
    };

    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }
  componentDidMount() {
    this.getFilterStateFromStorage();
    TableHeader({ dynamicHeader: true });
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.PSR
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.PSR);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getPSRList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getPSRList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.getPsrReport && response.data.getPsrReport.rows) || [];
          data.total = (response.data.getPsrReport && response.data.getPsrReport.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const { data, queryParameters, display,filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create: false,
                download: true,
                filter: true,
                search: false,
                date: true,
              }}
              downloadConfig={{
                domain: 'PRODUCT_SALES_REPORT',
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={() => {}}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content table-present">
          <PSRView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            onIconClick={() => {}}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </Fragment>
    );
  }
}

PSR.propTypes = propTypes;

PSR.defaultProps = defaultProps;

export default withAlert()(PSR);
