import { compose } from 'recompose';
import Report from './Report';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { query } from './API';

const ComposedReport = compose(
  withRequestTracker({
    query,
  }),
)(Report);

export default ComposedReport;
