import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Popover from '../../../../components/Popover/Popover';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { dateTimePresenter } from '../../../../utils/date';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { getUpdatedLabel, headerLabelConfig } from '../../../common/HelperFunctions';
import TableHeader from '../../../base/TableHeader';
import RoundedLetter from '../../../components/RoundedLetter';
import {
  Icon,Badge,Button
} from '../../../../v4/components';

// todo update the configurations.
const labelMappings = {
  id: 'id',
  date: 'dateTime',
  outlet: 'outlet',
  route: 'route',
  dse: 'dse',
  totalNetValue: 'totalNetValue',
  distributor: 'distributor',
  notes: 'notes',
  channel: 'channel',
  category: 'category',
  channelAndCategory: 'channel',
};

const notesIcon = <Icon iconName='comments' />;

const getNotesFooter = (dse, date) => (
  <div>
    <span>{dse}</span>
    <span>{date}</span>
  </div>
);

const returnTableBody = (data, onRowClick) => {
  const table = {};
  table[labelMappings.id] = (
    <td onClick={() => onRowClick(data.id, data.Distributor.id)} key={`id-${data.id}`}>
      {
        /** todo chip implementation here */
        <span>
          <span className='tab-label'>{data.id}</span>
          <Badge light label={data.totalOrderCount} />
        </span>
      }
    </td>
  );
  table[labelMappings.date] = (
    <td key={`date-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {`${dateTimePresenter(data.callTime ? data.callTime.callStart : '')}`}
    </td>
  );

  table[labelMappings.outlet] = (
    <td key={`outlet-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      <a href={`outlet/details/${data.RetailOutlet.id}`} onClick={(e) => e.preventDefault()}>
        {data.RetailOutlet ? data.RetailOutlet.title : ''}
      </a>
    </td>
  );
  table[labelMappings.channel] = (
    <td key={`channel-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.RetailOutlet.Category
        ? `${data.RetailOutlet.Category.Channel.title}/${data.RetailOutlet.Category.title}`
        : ''}
    </td>
  );
  table[labelMappings.route] = (
    <td key={`route-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.Route ? data.Route.title : ''}
    </td>
  );
  table[labelMappings.dse] = (
    <td key={`dse-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.User ? data.User.fullName : ''}
      &nbsp;
      {data.isRetailerOrder ? <RoundedLetter letter='r' color='#FF73C3' /> : ''}
    </td>
  );

  table[labelMappings.totalNetValue] = (
    <td
      key={`total-net-value-${data.id}`}
      className='text-right'
      onClick={() => onRowClick(data.id, data.Distributor.id)}
    >
      {fixedFloatAndCommas(data.callOrderValue ? data.callOrderValue.netAmount || 0 : 0)}
    </td>
  );
  table[labelMappings.distributor] = (
    <td key={`distributor-${data.id}`} onClick={() => onRowClick(data.id, data.Distributor.id)}>
      {data.Distributor ? data.Distributor.title : ''}
    </td>
  );
  table[labelMappings.notes] = (
    <td key={`pop-over-${data.id}`} onClick={(event) => event.stopPropagation()}>
      <div className='simple-popup'>
        <Popover
          popoverIcon={<Button iconName='comments' disabled={!data.notes} />}
          disabled={!data.notes}
          headerTitle='Note'
          footer={getNotesFooter(data.User.fullName, data.callDate)}
        >
          <span className='arrow-right' />
          {<span>{data.notes}</span>}
        </Popover>
      </div>
    </td>
  );

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'GID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.date,
    title: 'Date/Time',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 4,
    label: labelMappings.channel,
    title: 'Channel/Category',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 5,
    label: labelMappings.route,
    title: 'Route',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 6,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: true,
    sortable: true,
    className: 'sorting',
    isDynamic: true,
  },
  {
    id: 7,
    label: labelMappings.totalNetValue,
    title: 'Gross Amount',
    show: true,
    sortable: true,
    className: 'text-right',
    align: true,
  },
  {
    id: 8,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 9,
    label: labelMappings.notes,
    title: 'Notes',
    action: true,
    show: true,
    sortable: false,
    className: '',
  },
  /* {
    id: 10,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
    sortable: true,
    className:'sorting',
  }, */
];

const TableBody = ({ data, onRowClick }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, onRowClick);

  return <Fragment>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};
const TableHeaderConfig = ({ onHeaderClick, dynamicHeader }) => (
  <TableHeader
    headerDetails={dynamicHeader ? getUpdatedLabel(labelConfig) : labelConfig}
    onHeaderClick={onHeaderClick}
  />
);

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  onRowClick: PropTypes.func,
};

TableBody.defaultProps = {
  onRowClick: () => null,
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func,
  dynamicHeader: PropTypes.bool,
};

TableHeaderConfig.defaultProps = {
  onHeaderClick: () => null,
  dynamicHeader: false,
};

export {
  TableHeaderConfig as TableHeader, TableBody, headerLabelConfig
};
