import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import WarehouseReportView from './View';
import PageHeader from '../../base/PageHeader';
import { getUser } from '../../../data/services';
import { formConfig } from '../../inventory/stockAdjustment/config';
import * as queryService from '../../base/query.service';
import { warehouseReport } from '../../common/DomainConfig';
import { PROGRAM } from '../../../data/enums/Permission';
import { filterConfig, breadCrumbConfig } from './config';
import { FORM_CONFIG } from '../../../data/enums/config';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { getCurrentDay } from '../../../utils/date';
import { isError } from '../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { PAGINATION, USER_ROLE } from '../../../data/enums';
import { clone } from '../../../utils/objectProcessor';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getWarehouseReportList: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class WarehouseReport extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      dataDetails: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        sort: {},
        date: { start: getCurrentDay(), end: getCurrentDay() },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
      filterMenu: this.filterConfigMenu || {},
      distributorList: [],
      dialog: {
        type: '',
        element: formConfig[EVENT_OPERATION.UPDATE][FORM_CONFIG.MAPPER]({
          distributorId: this.userInfo.Distributor.length === 1 ? this.userInfo.Distributor[0].id : null,
        }),
      },
      activeRowId: null,
      ledgerDetail: [],
      closeAccordion: false,
      previousSearch: '',
    };
    this.permission = { update: true, create: true, read: true };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = data => {
      this.setState({ data });
    };

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    if (
      ADMIN_ROLE_GROUP.includes(this.userInfo.roleId) ||
      DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId) ||
      this.userInfo.roleId === USER_ROLE.TAS_TEAM_LEAD
    ) {
      this.getDistributorsList();
      this.getFilterStateFromStorage();
    } else this.getFilterStateFromStorage();
  }
  componentWillUnmount() {
    this.setFilterStateInStorage();
  }
  resetCloseAccordion = () => {
    setTimeout(() => {
      this.setState({ closeAccordion: false });
    }, 500);
  };
  componentDidUpdate(prevProps, prevState) {
    const { queryParameters, previousSearch } = this.state;
    if (previousSearch !== queryParameters?.search) {
      this.setState({ closeAccordion: true, previousSearch: prevState?.queryParameters?.search }, () =>
        this.resetCloseAccordion(),
      );
    }
  }

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          this.setState({ distributorList: response.data.distributors.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  handleDistributorSelect = distributorId => {
    const { dialog, queryParameters } = this.state;
    dialog.element.distributorId = distributorId;
    queryParameters.pagination = {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    };
    this.setState({ dialog, queryParameters, activeRowId: null, closeAccordion: true }, () => this.loadTableData());
  };

  setFilterStateInStorage = () => {
    const { dialog } = this.state;
    const {
      filterMenu,
      queryParameters: { filter, date, search },
    } = this.state;
    storeInLocalStorage('DISTRIBUTOR_ID', dialog?.element?.distributorId, DOMAIN.WAREHOUSE_REPORT);
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.WAREHOUSE_REPORT,
    );
  };
  getFilterStateFromStorage = () => {
    const { dialog } = this.state;
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.WAREHOUSE_REPORT);
    const distributorId = fetchFromLocalStorage('DISTRIBUTOR_ID', DOMAIN.WAREHOUSE_REPORT);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        search: '',
      }),
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          search: newFilterConfig.search,
          date: newFilterConfig.date,
        },
        dialog: { ...dialog, element: { ...dialog?.element, distributorId } },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  loadTableData = () => {
    const { getWarehouseReport, displayAlert } = this.props;
    const { queryParameters, data, dialog } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    if (dialog.element.distributorId) {
      getWarehouseReport(
        {
          offset,
          limit: queryParameters.pagination.limit,
          filter: {
            filters: [
              ...apiFilterProcessor(queryParameters.filter),
              {
                column: 'distributor_id',
                value: [dialog.element.distributorId.toString() || ''],
              },
            ],
            dateRange: queryParameters.date,
            queryString: queryParameters.search,
          },
        },
        {
          handleSuccess: response => {
            data.list = response?.data?.getWarehouseReport?.rows || [];
            data.total = response?.data?.getWarehouseReport?.count || 0;
            if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
            this.setState({ data, closeAccordion: false });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  stateSetter = (stateField, value) => {
    this.setState({ [stateField]: value });
  };

  handleDownloadClick = (reportType, domain) => {
    const { queryParameters, dialog, distributorList } = this.state;
    const distributorIdFilterValue = dialog?.element?.distributorId
      ? [dialog?.element?.distributorId?.toString()]
      : distributorList?.map(d => d?.id?.toString());
    const filter = {
      filters: [
        ...apiFilterProcessor(queryParameters.filter),
        {
          column: 'distributor_id',
          value: distributorIdFilterValue,
        },
      ],
      queryString: queryParameters.search,
      dateRange: queryParameters.date,
    };

    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
  };

  handleRowClick = element => {
    const { queryParameters, activeRowId } = this.state;
    const { getWarehouseDetailsReport, displayAlert } = this.props;
    this.setState({ dataDetails: [] });
    getWarehouseDetailsReport(
      {
        distributorId: element?.distributor_id,
        binLocationId: element?.bin_location_id,
        filter: {
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          this.setState(
            {
              dataDetails: response?.data?.getBinLocationStockDetail || [],
              activeRowId: element?.bin_location_id,
            },
            () => this.loadTableData(),
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  render() {
    const {
      data,
      dataDetails,
      queryParameters,
      display,
      filter,
      distributorList,
      dialog,
      ledgerDetail,
      filterMenu,
      closeAccordion,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const { element } = dialog;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: warehouseReport.title,
                filter: false,
                download: true,
                date: true,
                search: true,
              }}
              filter={{
                date: queryParameters.date,
                metaData: filter,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              downloadConfig={{
                domain: warehouseReport?.domain,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              resetFilter={this.basePaginationService.resetFilter}
              onFilterChange={this.basePaginationService.handleFilterChange}
              handleDownloadClick={this.handleDownloadClick}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
            />
          </PanelStyled>
        </div>
        <div className={`section-content section-select table-present`}>
          <WarehouseReportView
            data={data}
            inputData={element}
            breadCrumb={breadCrumbConfig}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            componentDisplay={this.componentConfig}
            onPageChange={this.basePaginationService.onPageSelect}
            distributorList={distributorList}
            onHeaderClick={this.basePaginationService.handleTableSorting}
            onRowClick={this.handleRowClick}
            ledgerDetail={dataDetails}
            onDropDownChange={this.handleDistributorSelect}
            closeAccordion={closeAccordion}
          />
        </div>
      </Fragment>
    );
  }
}

WarehouseReport.propTypes = propTypes;

WarehouseReport.defaultProps = defaultProps;

export default withAlert()(WarehouseReport);
