import { compose } from 'recompose';
import AddDevice from './AddDevice.jsx';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';
import { query, mutation } from './API';

const AddLinkedDevice = compose(
  withRequestTracker({
    query,
    mutation,
  }),
)(AddDevice);

export default AddLinkedDevice;
