import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../../utils/composition/withLoading';
import { dataProps } from '../../../../data/enums/Component';
import ReportTableStyled from '../ReportTableStyled';
import { TableHeader, TableBody } from './tableConfig';

const propTypes = {
  data: dataProps.value,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  config: PropTypes.instanceOf(Object),
  onHeaderClick: PropTypes.func,
};

const defaultProps = {
  data: dataProps.defaultValue,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  config: {},
  onHeaderClick: () => null,
};

const PFDTargetView = ({ ...props }) => {
  const { data, config, onHeaderClick } = props;
  return (
    <ReportTableStyled>
      <div className='table-wrap'>
        <table>
          <TableHeader config={config} onHeaderClick={onHeaderClick} />
          <tbody>{data?.list?.map((pfdSalesTarget) => TableBody({ data: pfdSalesTarget, config }))}</tbody>
        </table>
      </div>
    </ReportTableStyled>
  );
};

PFDTargetView.propTypes = propTypes;

PFDTargetView.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(PFDTargetView);

export default LoginViewWithErrorAndLoading;
