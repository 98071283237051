import { compose } from 'recompose';
import CustomerDetail from './CustomerDetail';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';
import { GET_CUSTOMERS, GET_OUTLET_DETAIL_WITH_CUSTOMERS } from '../../../../../common/GqlAPI';
import { GET_OUTSTANDING_VALUE } from '../API';

const ComposedCustomerDetail = compose(
  withRequestTracker({
    query: {
      getCustomers: GET_CUSTOMERS,
      getOutletDetail: GET_OUTLET_DETAIL_WITH_CUSTOMERS,
      getOutstandingValue: GET_OUTSTANDING_VALUE,
    },
  }),
)(CustomerDetail);

export default ComposedCustomerDetail;
