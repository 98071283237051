import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownStyled from './DropDownStyled';
import { Icon } from '../../../../../v4/components';

const propTypes = {
  showModal: PropTypes.func.isRequired,
  outletId: PropTypes.number.isRequired,
};
class DropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  setWrapperRef(node) {
    if (node) {
      this.wrapperRef = node;
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showMenu: false });
    }
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true });
  }

  render() {
    const { showMenu } = this.state;
    const { showModal, outletId } = this.props;
    return (
      <DropdownStyled>
        <span className='dropdown' ref={node => this.setWrapperRef(node)}>
          <Icon className='simple-popup-actions' iconName='ellipsis-v' onClick={this.showMenu} />
          {showMenu ? (
            <ul className='menu'>
              <li onClick={() => showModal(outletId)}>Transfer</li>
            </ul>
          ) : null}
        </span>
      </DropdownStyled>
    );
  }
}

DropDown.propTypes = propTypes;

export default DropDown;
