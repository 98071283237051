import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Item from './Item';

const Row = (props) => {
  const { data, index, style } = props;
  const item = data.items[index];
  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }
  return (
    <Draggable draggableId={item.id} index={index} key={item.id}>
      {(provided) => <Item provided={provided} item={item} style={style} outletState={data.id} />}
    </Draggable>
  );
};

export default Row;
