import { compose } from 'recompose';
import CallHistory from './CallHistory';
import { query } from './API';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const composedCallHistory = compose(
  withRequestTracker({
    query,
  }),
)(CallHistory);

export default composedCallHistory;
