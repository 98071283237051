import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableHeader } from '../tableConfig';
import SplitPayment from '../../../../components/SplitPayment';
import { Pagination } from '../../../../../v4/components';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../../../data/enums/Component';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  pendings: dataProps.value,
  onPageChange: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  fetchAllData: PropTypes.func,
  pagination: paginationProps.value,
};

const defaultProps = {
  pendings: dataProps.defaultValue,
  onPageChange: () => null,
  permission: PERMISSION_OBJ,
  fetchAllData: () => null,
  pagination: paginationProps.defaultValue,
};
class Pending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  getStatus = (status, statusType) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE[statusType === 'err' ? 'INFO' : 'SUCCESS'], status);
  };

  render() {
    const { dialog } = this.state;
    const { type } = dialog;
    const { tableConfig, pendings, onPageChange, fetchAllData, permission, pagination } = this.props;
    return (
      <Fragment>
        {type && (
          <SplitPayment
            data={dialog.element}
            type={type}
            resetDialog={this.resetDialog}
            fetchAllData={fetchAllData}
            getStatus={this.getStatus}
          />
        )}
        <div className="table-wrap">
          <table>
            {TableHeader({
              tableConfig,
            })}

            <tbody>
              {pendings.list.map((pending, key) =>
                TableBody({
                  data: pending,
                  handleIconClick: this.handleIconClick,
                  tableConfig,
                  section: 'pending',
                  permission,
                  index: key,
                }),
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={pagination.page}
          limit={pagination.limit}
          totalItems={pendings.total}
          onPageChange={onPageChange}
        />
      </Fragment>
    );
  }
}

Pending.propTypes = propTypes;

Pending.defaultProps = defaultProps;

export default withAlert()(withErrorAndLoadingScreen(Pending));
