import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const CALENDAR_FRAGMENT = gql`
  fragment CalendarFragment on Calendar {
    id
    domain
    distributorId
    weekendDetails
    holidayCount {
      casual
      sick
      home
      unpaid
    }
    holidayDetails: HolidayDetails {
      id
      start: startDate
      end: endDate
      title
    }
    replicate
  }
`;

const GET_CALENDAR = gql`
  query getCalendarDetail($input: CalendarSearchInput!) {
    getCalendar(input: $input) {
      ...CalendarFragment
    }
  }
  ${CALENDAR_FRAGMENT}
`;

const CREATE_CALENDAR = graphql(
  gql`
    mutation ($input: CalendarInput!) {
      createCalendar(input: $input) {
        ...CalendarFragment
      }
    }
    ${CALENDAR_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createCalendar: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_CALENDAR = graphql(
  gql`
    mutation ($id: Int!, $input: CalendarUpdateInput!) {
      updateCalendar(id: $id, input: $input) {
        ...CalendarFragment
      }
    }
    ${CALENDAR_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateCalendar: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getCalendarDetail: GET_CALENDAR,
};

const mutation = {
  createCalendar: CREATE_CALENDAR,
  updateCalendar: UPDATE_CALENDAR,
};

export {
  CALENDAR_FRAGMENT, UPDATE_CALENDAR, GET_CALENDAR, CREATE_CALENDAR
};

export { query, mutation };
