import React, { Component } from 'react';
import { Alert } from '../../components';
import { Col, Row } from 'react-flexbox-grid';

class DemoAlert extends Component {
  render() {
    return (
      <div className='demo-section button-wrap'>
        <div className='demo-section-inner'>
          <div className='demo-header'>
            <h2>Alerts</h2>
            <p>Alerts are used to capture the attention of the user in an intrusive way.</p>
          </div>
          <div className='demo-body'>
            <div className='demo-content'>
              {/* Primary Button */}
              <div className='demo-content-list'>
                <div className='demo-sample'>
                  <Row>
                    <Col xs={6}>
                      <div>
                        <div className='demo-alert-container'>
                          <Alert type='success' title='Success' iconName='check-circle-full' active>
                            This is a success message.
                          </Alert>
                        </div>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div>
                        <div className='demo-alert-container'>
                          <Alert type='danger' title='Danger' iconName='exclamation-full' active>
                            This is a danger message.
                          </Alert>
                        </div>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div>
                        <div className='demo-alert-container'>
                          <Alert type='info' title='Info' iconName='info-circle' active>
                            This is an info message.
                          </Alert>
                        </div>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div>
                        <div className='demo-alert-container'>
                          <Alert type='warning' title='Warning' iconName='warning-full' active>
                            This is a warning message.
                          </Alert>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DemoAlert;
