import { compose } from 'recompose';
import ICOGroupMaster from './ICOGroupMaster';
import {
  query, mutation, TOGGLE_STATE, createICOGroupMaster, updateICOGroupMaster
} from '../Repository/API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedICOGroup = compose(
  TOGGLE_STATE,
  createICOGroupMaster,
  updateICOGroupMaster,
  withRequestTracker({
    query,
    mutation,
  }),
)(ICOGroupMaster);

export default composedICOGroup;
