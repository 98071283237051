import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT, GET_DISTRIBUTORS } from '../../common/GqlAPI';

const GET_WAREHOUSE_REPORT = gql`
  query GetWarehouseReport($filter: FilterInput!, $offset: Int, $limit: Int) {
    getWarehouseReport(filter: $filter, offset: $offset, limit: $limit) {
      count
      rows {
        bin_code
        bin_location_id
        closing_sellable
        distributor
        distributor_id
        opening_sellable
        period_adjustment
        period_damages
        period_expiry
        period_inward
        period_outward
      }
    }
  }
`;
const GET_WAREHOUSE_DETAILS_REPORT = gql`
  query GetBinLocationStockDetail($distributorId: Int!, $binLocationId: Int!, $filter: FilterInput!) {
    getBinLocationStockDetail(distributorId: $distributorId, binLocationId: $binLocationId, filter: $filter) {
      distributor_id
      bin_location_id
      sku_batch_id
      batch_name
      sku_id
      sku
      manufacture_date
      expiry_date
      opening_sellable
      period_inward
      period_outward
      period_damages
      period_expiry
      period_adjustment
      closing_sellable
    }
  }
`;

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getWarehouseReport: GET_WAREHOUSE_REPORT,
  getWarehouseDetailsReport: GET_WAREHOUSE_DETAILS_REPORT,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { query, mutation, DOWNLOAD_REPORT };
