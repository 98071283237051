import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Detail from './detail';
import { TableHeader } from './tableConfig';
import { Pagination } from '../../../../v4/components';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { LIMIT, PAGE } from '../../../../data/enums/GeneralConstants';
import InvoiceStyled from './InvoiceStyled';
import CTAccordionStyled from '../../../../components/CollapsibleTable/CTAccordionStyled';

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    list: PropTypes.array,
    total: PropTypes.number,
  }),
  activeLine: PropTypes.shape({
    id: PropTypes.number,
    sellerId: PropTypes.number,
  }),
  onPageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  selectedOrders: PropTypes.instanceOf(Array),
  onOrderStatusChangeClick: PropTypes.func.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
};

const defaultProps = {
  data: {
    list: [],
    total: 0,
    limit: LIMIT,
    page: PAGE,
  },
  activeLine: {
    id: '',
    sellerId: 0,
  },
  loading: false,
  selectedOrders: [],
};

const InvoicedView = ({ ...props }) => {
  const {
    data,
    loading,
    activeLine,
    onPageChange,
    onRowClick,
    onOrderStatusChangeClick,
    selectedOrders,
    onHeaderClick,
    onPrintClick
  } = props;

  return (
    <InvoiceStyled>
      <div className='tab-section'>
        <CTAccordionStyled>
          <div className='table-wrap'>
            <table>
              {TableHeader({ onHeaderClick })}

              <Fragment>
                <Detail
                  data={data.list}
                  loading={loading}
                  activeLine={activeLine}
                  onRowClick={onRowClick}
                  selectedOrders={selectedOrders}
                  onOrderStatusChangeClick={onOrderStatusChangeClick}
                  onPrintClick = {onPrintClick}
                />
              </Fragment>
            </table>
          </div>
        </CTAccordionStyled>
        <Pagination currentPage={data.page} limit={data.limit} totalItems={data.total} onPageChange={onPageChange} />
      </div>
    </InvoiceStyled>
  );
};

InvoicedView.propTypes = propTypes;

InvoicedView.defaultProps = defaultProps;

const InvoicedViewWithErrorAndLoading = withErrorAndLoadingScreen(InvoicedView);

export default InvoicedViewWithErrorAndLoading;
