import { EVENT_OPERATION } from '../../data/enums/EventOperation';
import { FORM_CONFIG } from '../../data/enums/config';
import { refGenerator } from '../../utils/refGenerator';

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    mapper: (element) => ({
      email: element.email || '',
    }),
    [FORM_CONFIG.TITLE]: 'Reset Password',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['email']),
    buttonName: 'Reset',
  },
};
const userRoleForTASDashboard={
  VISIBILITY_TEAM_LEADER:14,
  TAS: 18,
}
export { userRoleForTASDashboard,formConfig, }
export default formConfig;
