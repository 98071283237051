import { Catalogs } from '../../../../../data/enums/ApiFields';

const catalogSelectFormConfig = {
  mapper: (element) => ({
    [Catalogs.Catalog]: {
      id: element.Catalog ? element.Catalog.id : '',
    },
    [Catalogs.CatalogDetail]: {
      title: element.CatalogDetail ? element.CatalogDetail.title : '',
    },
    catalogDetailList: [],
    catalogList: [],
  }),
  validationField: [Catalogs.Catalog, Catalogs.CatalogDetail],
  validationRequired: true,
};

const REQUIRED_CATALOG_ID = 7;
const SKU_CATALOG_DETAILS_LENGTH = 7;
export {
  catalogSelectFormConfig, REQUIRED_CATALOG_ID, SKU_CATALOG_DETAILS_LENGTH
};
