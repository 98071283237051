import { compose } from 'recompose';

import OrderHistory from './OrderHistory';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';
import { ORDER_HISTORY } from '../API';

const query = {
  getOrderHistory: ORDER_HISTORY,
};

const ComposedOutletOrderHistory = compose(
  withRequestTracker({
    query,
  }),
)(OrderHistory);

export default ComposedOutletOrderHistory;
