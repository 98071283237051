import { compose } from 'recompose';
import StockReplacement from './StockReplacement';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedStockReplacement = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(StockReplacement);

export default ComposedStockReplacement;
