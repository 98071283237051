import { fixedFloatAndCommas } from './conversion';

const addEdit = (mode, data) => (mode ? `Edit ${data}` : data);

const uniqueArrayElement = array => {
  return Array.from(new Set([...array]));
};

const uniqueArrayElementString = array => {
  return uniqueArrayElement(array).toString().toLowerCase();
};

const getPercentage = (dividend = 1, divisor) => Number(fixedFloatAndCommas((dividend * 100) / (divisor || 1)));
export const getTotalOf = (list: any, field: string) => {
  const sum = list?.reduce((sum: number, acc: any) => sum + acc[field], 0);
  return sum;
};
export { addEdit, uniqueArrayElementString, uniqueArrayElement, getPercentage };
