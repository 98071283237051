import React, { Component } from 'react';

// HOC holding the state lifted values.
const superHOC =
  WrappedComponent =>
  ({ ...props }) => {
    class PaginationContainer extends Component {
      makeRequest = () => {
        props.loginWithServer(
          { username: 'superadmin@rosia.one', password: 'superadmin123' },
          {
            handleSuccess: data => this.handleSuccess(data),
            handleError: data => this.handleFailure(data),
          },
        );
      };

      handleSuccess(data) {
        alert(`Woww can do anything here${data}`);
        console.log('calling success function');
      }

      handleFailure(data) {
        alert(`OOPs can do anything here${data}`);
        console.log('calling failure function');
      }

      render() {
        return (
          <div>
            <WrappedComponent {...this.props} makeRequest={this.makeRequest} />
            <div onClick={() => this.makeRequest()}>SUPER HOC Me I will Make The Request with Request Tracker</div>
            <div>Loading State {JSON.stringify(props.loginWithServerLoading)}</div>
            <div>ERROR DETAILS {JSON.stringify(props.loginWithServerError)}</div>
          </div>
        );
      }
    }

    return <PaginationContainer />;
  };

export default superHOC;

// 'superadmin@rosia.one', 'superadmin123'
