import React from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { CheckBox, tableHeaderWithCheckBox } from '../../../v4/components';

const labelMappings = {
  checkbox: 'checkbox',
  dse: 'dse',
  distributor: 'distributor',
  route: 'route',
  town: 'town',
  zone: 'zone',
  scheduled: 'scheduled',
  calls_made: 'calls_made',
  successful: 'successful',
  unsuccessful: 'unsuccessful',
  productivity: 'productivity',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 1,
    label: labelMappings.dse,
    title: 'DSE Name',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: false,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: false,
  },
  {
    id: 4,
    label: labelMappings.town,
    title: 'Town',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.zone,
    title: 'Zone',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.scheduled,
    title: 'Scheduled',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.calls_made,
    title: 'Call Made',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.successful,
    title: 'Successful',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.unsuccessful,
    title: 'Unsuccessful',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.productivity,
    title: 'Productivity(%)',
    classNames: 'text-right',
    show: true,
  },
];

const TableHeader = ({ tableConfig, dataListLength, primaryCheckboxHandler, checkedListLength }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    { delete: true },
    tableConfig,
    labelMappings,
  );

const returnTableBody = (data, checkedList, secondaryCheckboxHandler) => {
  const table = {};
  table[labelMappings.checkbox] = true && (
    <td onClick={(e) => e.stopPropagation()} key={`productivity-${data.dse_id}-checkbox`}>
      <CheckBox
        checked={checkedList.includes(data.dse_id)}
        onCheckBoxClick={() => secondaryCheckboxHandler(data.dse_id)}
      />
    </td>
  );
  table[labelMappings.dse] = <td key={`productivity-${data.dse_id}-dse`}>{data.dse}</td>;
  table[labelMappings.distributor] = <td key={`productivity-${data.dse_id}-distributor`}>{data.distributor}</td>;
  table[labelMappings.route] = <td key={`productivity-${data.dse_id}-route`}>{data.route}</td>;
  table[labelMappings.town] = <td key={`productivity-${data.dse_id}-town`}>{data.town}</td>;
  table[labelMappings.zone] = <td key={`productivity-${data.dse_id}-zone`}>{data.zone}</td>;
  table[labelMappings.scheduled] = <td key={`productivity-${data.dse_id}-scheduled`}>{data.scheduled}</td>;
  table[labelMappings.calls_made] = <td key={`productivity-${data.dse_id}-calls_made`}>{data.calls_made}</td>;
  table[labelMappings.successful] = <td key={`productivity-${data.dse_id}-successful`}>{data.successful}</td>;
  table[labelMappings.unsuccessful] = <td key={`productivity-${data.dse_id}-unsuccessful`}>{data.unsuccessful}</td>;
  table[labelMappings.productivity] = <td className='text-right'>{fixedFloatAndCommas(data.productivity)}</td>;

  return table;
};

const TableBody = ({ data, tableConfig, secondaryCheckboxHandler, checkedList }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data, checkedList, secondaryCheckboxHandler);

  return (
    <tr key={`productivity-${data.dse_id}-details`}>
      {tableConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeader, TableBody, labelConfig
};
