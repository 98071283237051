import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isError } from 'lodash';
import { PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../base/PageHeader';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import * as queryService from '../../../base/query.service';
import View from './View';
import { breadCrumbConfig, filterConfig, title } from './config';
import { labelConfig } from './tableConfig';
import OutstandingWrapper from './OutstandingWrapper';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../../utils/api';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../../common';
import confirmationGenerator from '../../../common/DialogConfirmation';
import { getPermissionForPayment } from '../../../base/permission';
import { clone } from '../../../../utils/objectProcessor';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../../common/HelperFunctions';
import { storageKeys } from '../../../sales/orderProcessing/config';
import { DOMAIN } from '../../../../data/enums/config';

const propTypes = {
  getOutstandingsList: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
  updateRetailOutletTransactionStatus: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class Outstandings extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      tableInfo: labelConfig,
      type: '',
      outletId: '',
    };
    this.permission = getPermissionForPayment();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount (){
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.OUTSTANDING
    );
  };

  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.OUTSTANDING);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  columnSelectChange = data => {
    this.setState({
      tableInfo: data,
    });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  loadTableData = () => {
    const { queryParameters, data } = this.state;
    const { getOutstandingsList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getOutstandingsList(
      {
        limit: queryParameters.pagination.limit,
        offset,
        filter: {
          filters: [...apiFilterProcessor(queryParameters.filter)],
          dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.outstandingReports && response.data.outstandingReports.rows) || [];
          data.total = response.data.outstandingReports && response.data.outstandingReports.count;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  onDialogSubmit = () => {
    const { updateRetailOutletTransactionStatus, displayAlert } = this.props;
    const { outletId, type, data } = this.state;
    updateRetailOutletTransactionStatus(
      {
        id: outletId,
        transactionStatus: type,
      },
      {
        handleSuccess: response => {
          const responseData = response.data.updateRetailOutletTransactionStatus;
          data.list.forEach(d => {
            if (d.id === responseData.id) {
              d.transactionStatus = responseData.transactionStatus;
            }
          });
          this.setState(
            {
              data,
            },
            () => {
              displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Updated');
            },
          );
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  onStatusChanges = (type, data) => {
    this.setState({
      type,
      outletId: data.outletId,
    });
  };

  render() {
    const { data, queryParameters, tableInfo, type,filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <OutstandingWrapper>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create: false,
                download: true,
                filter: true,
                upload: false,
                date: false,
                search: true,
                columSelect: true,
              }}
              downloadConfig={{
                domain: [DOWNLOAD_DOMAIN.OUTSTANDING_REPORT, DOWNLOAD_DOMAIN.OUTSTANDING_DETAIL_REPORT],
              }}
              filter={{
                // date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              labelConfig={tableInfo}
              queryParameters={queryParameters}
              columnSelectChange={this.columnSelectChange}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.onReceiptsClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        {type && (
          <DialogFormWrapper
            formConfig={{ title: 'Update Status' }}
            onDialogSubmit={() => this.onDialogSubmit()}
            onDialogCancel={() => {
              this.setState({ type: '' });
            }}
            type={type}
            renderDialog={() => <>{confirmationGenerator(type)}</>}
          />
        )}
        <div className="section-content table-present">
          <View
            data={data}
            pagination={queryParameters.pagination}
            loading={serverResponseWaiting || false}
            onPageChange={this.basePaginationService.onPageSelect}
            tableConfig={tableInfo}
            onStatusChanges={this.onStatusChanges}
            permission={this.permission}
          />
        </div>
      </OutstandingWrapper>
    );
  }
}

Outstandings.propTypes = propTypes;

Outstandings.defaultProps = defaultProps;

export default withAlert()(Outstandings);
