import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { GET_CATALOG_DETAIL } from '../../sku/createSku/API';

const ASSORTMENT_COUNT_BY_PRODUCT_HIERARCHY_FRAGMENT = gql`
  query GetICOProductHierarchyCount($icoGroupId: Int!) {
    getICOProductHierarchyCount(icoGroupId: $icoGroupId) {
      brands {
        id
        count
      }
      skuFamilies {
        id
        count
      }
      productCategories {
        id
        count
      }
    }
  }
`;

const GET_ASSORTMENT_COUNT_BYPRODUCT_HIERARCHY = gql`
query getICOProductHierarchyCount($icoGroupId: Int!) {
  getICOProductHierarchyCount(icoGroupId: $icoGroupId) {
    brands {
      id
      count
    }
    skuFamilies {
      id
      count
    }
    productCategories {
      id
      count
    }
  }
}
`;

const GET_DISTRIBUTOR_ASSORTMENT_SKUS = gql`
  query icoGroupDetailSkus($icoGroupId: Int!, $brandId: Int!) {
    icoGroupDetailSkus(icoGroupId: $icoGroupId, brandId: $brandId) {
      rows
    }
  }
`;

const TOGGLE_SKU_IN_SUBD_ASSORTMENT = graphql(
  gql`
    mutation ToggleSkuInICOGroupDetail($icoGroupId: Int!, $status: Boolean!, $skuIdList: [Int!]) {
      toggleSkuInICOGroupDetail(icoGroupId: $icoGroupId, status: $status, skuIdList: $skuIdList) {
        count {
          brands {
            id
            count
          }
          productGroups: productCategories {
            id
            count
          }
          skuFamilies {
            id
            count
          }
        }
        rows
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleSkuInICOGroupDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_ICO_GROUP_SKU_STATUS = gql`
query getIcoGroupSkuStatus($icoGroupId: Int) {
  getIcoGroupSkuStatus(icoGroupId: $icoGroupId) {
    rows {
      ico_group_id
      sku_family_id
      sku_id
      sku_status
    }
  }
}
`;

const query = {
  getCatalogDetails: GET_CATALOG_DETAIL,
  getDistributorAssortmentSkus: GET_DISTRIBUTOR_ASSORTMENT_SKUS,
  getAssortmentCountByProductHierarchy: GET_ASSORTMENT_COUNT_BYPRODUCT_HIERARCHY,
  getIcoGroupSkuStatus: GET_ICO_GROUP_SKU_STATUS
};
const mutation = {
  toggleSkuInICOGroupDetail: TOGGLE_SKU_IN_SUBD_ASSORTMENT,
};

export {
  query, mutation, TOGGLE_SKU_IN_SUBD_ASSORTMENT, ASSORTMENT_COUNT_BY_PRODUCT_HIERARCHY_FRAGMENT
};
