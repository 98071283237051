import { getCurrentDay } from '../../../utils/date';
import { CheckBox, Icon } from '../../components';
import { EVENT_OPERATION } from '../../constants/EventOperation';

interface Props {
  checkedListLength: number;
  dataLength: number;
  onIconClick: () => void;
  replicate: boolean;
  onReplicateClick: (type: string, date: object) => void;
  onReplicateTypeClick: (type: string) => void;
}
const BulkDeleteView = ({ checkedListLength, dataLength, onIconClick, replicate, onReplicateClick, onReplicateTypeClick }: Props) =>
  checkedListLength > 0 ? (
    <>
      <div className="selected-status">
        <div className="content-center">
          <div>
            <CheckBox onCheckBoxClick={() => { }} checked className="disabled-btn" />
            <p className="text">
              <span>{checkedListLength}</span>
              of
              <span>{dataLength}</span>
              items selected
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div className="del-btn" onClick={() => onIconClick()}>
              <Icon iconName="trash" />
            </div>
            {replicate && (
              <div
                className='del-btn'
                onClick={() => {
                  onReplicateClick(EVENT_OPERATION.REPLICATE, { startDate: getCurrentDay(), endDate: getCurrentDay() });
                  onReplicateTypeClick(EVENT_OPERATION.REPLICATE);
                }}
              >
                <Icon iconName='copy' />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;

export default BulkDeleteView;
