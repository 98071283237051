import styled from 'styled-components';

const PermissionStyled = styled.div`
  .panel-section {
    display: flex;
    .panel {
      background-color: #ffffff;
      border-radius: 4px;

      &.three {
        flex: 1;
        .panel-title {
          padding: 24px 48px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > span {
            font-size: 14px;
            line-height: 21px;
          }
        }
        .div-thead{
          padding: 16px 48px;
          background-color: #f1f2f5;
          font-size: 12px;
          line-height: 15px;
          color: #6b6c7e;
          font-weight: 500;
          display:flex;
          position: sticky;
          top: 0;
         z-index: 1;
          .entity{
            width:30%;
          }
          .permission-access{
            display: flex;
            flex: 1;
            .permission{
              flex:1;
            }
            .operations{
              flex-basis: 450px;
              display: flex;
              justify-content: space-between;
            }
          }
        }
        .div-tbody .collapsible-inner,
        .div-tbody .header-wrap{
          border-bottom: 1px solid #e7e7ed;
          display: flex;
          padding: 20px 48px;
          align-items: center;
          background-color: #f1f2f5;
          font-size: 12px;
          line-height: 15px;
          color: #6b6c7e;
          font-weight: 500;
          .status {
            display: flex;
            align-items: center;
            width: 40%;
            .form-input {
              margin-left: 12px;
            }
          }
        }

        .div-tbody {
          min-height: calc(100vh - 197px);
          overflow-y: auto;
          background-color: #fbfbfc;
          padding-bottom: 20px;
          .active:last-child{
              &>div:not(.collapsible-innt)){
                border-bottom:0;
              }
          }
          .header {
            .panel-title {
              padding: 0px;
            }
            .collapsible-inner {
              width: 100%;
              .status {
                width: 35%;
              }
            }
            .header-wrap {
              background-color: #ffffff;
              padding: 20px 48px;
              .status {
                width: 40%;
              }
            }
            .header-wrap {
              cursor: pointer;
              & > span {
                position: relative;
                &::before {
                  content: '';
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: 6px solid #6b6c7e;
                  transform: rotate(-90deg);
                  transition: all 0.23s ease-in-out;
                  position: absolute;
                  top: 6px;
                  left: 0;
                }
                .text {
                  margin-left: 30px;
                }
                .badge {
                  margin-left: 8px;
                }
              }
            }

            &.active {
              display:flex;
              &>div:not(.collapsible-innt){
                background-color:#FFFFFF;
                border-bottom: 1px solid #e7e7ed;
                width:30%;
              }
              .collapsible-inner:first-child{
                padding: 14px 48px 10px 0;
              }
                .collapsible-inner:last-child{
                  border-bottom: 1px solid #e7e7ed;
                  padding: 10px 48px 14px 0;
                }
                .collapsible-inner.zero:last-child{
                  padding: 14px 48px 14px 0;
                }
              .collapsible-innt{
                flex:1;
                .permission{
                flex:1;
                }
                .operations{
                  flex-basis: 450px;
                  display: flex;
                  justify-content: space-between;
                }
              }
              .header-wrap {
                border-bottom:0;
                span::before {
                  transform: rotate(-180deg);
                  transition: all 0.23s ease-in-out;
                  border-top: 6px solid #0b5fff;
                  top: 5px;
                }
              }
              .collapsible-inner {
                padding: 10px 48px 10px 0;
                background-color:#FFFFFF;
                border-bottom:0;
                & > span {
                  margin-left: 20px;
                }
              }
            }

            &.inactive {
              margin: 0;
              transition: all 0.4s ease-in;
              top: 28px;
            }
          }
        }
      }
    }
  }
`;

export default PermissionStyled;
