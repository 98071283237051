import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import FormContentStyled from '../../../components/global-css/FormContentStyled';
import { AutoComplete, CustomSelect } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { Icon ,Button } from '../../../v4/components'
import ICOStyled from './ICOStyled';

const propTypes = {
  fileName: PropTypes.string,
  showPaginatedList: PropTypes.bool.isRequired,
  updateDialog: PropTypes.func.isRequired,
  handleFileCancel: PropTypes.func.isRequired,
  handleDistributorSelect: PropTypes.func.isRequired,
  handleAutoCompleteChange: PropTypes.func.isRequired,
  distributorList: PropTypes.arrayOf(PropTypes.object).isRequired,
  brandList: PropTypes.arrayOf(PropTypes.object).isRequired,
  distributor: PropTypes.objectOf(Object),
  brand: PropTypes.objectOf(Object),
  loading: PropTypes.bool,
  title:PropTypes.string
};

const defaultProps = {
  fileName: '',
  distributor: {},
  brand: {},
  loading: false,
  title:''
};

const UploadSection = (props) => {
  const {
    distributorList, distributor, showPaginatedList, updateDialog,
    fileName, handleDistributorSelect, handleFileCancel, loading, brandList, brand, handleAutoCompleteChange,
  } = props;
  return (
    <ICOStyled>
    <Fragment>
      {
        !showPaginatedList && (
          <div className='upload-section '>
            <FormContentStyled>
              <div className='form-input-wrap'>
                <CustomSelect
                  enableValidation
                  options={distributorList}
                  className='custom-select'
                  labelContent='Sub D'
                  placeholder='Select Distributor'
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ title }) => title}
                  onChange={(event) => {
                    handleDistributorSelect(event);
                  }}
                  value={distributorList.filter(({ id }) => id === distributor.id) || []}
                  disabled={fileName !== '' || loading}
                />
                <AutoComplete
                  name='ids'
                  searchIcon
                  newClassName='autocomplete_value'
                  labelContent='Brand'
                  dropDownList={brandList}
                  placeholder='Search and Select'
                  selectedDropDowns={brand.ids}
                  onDropDownSelection={handleAutoCompleteChange}
                />
                <div className='form-input'>
                  <label>Target & CRR</label>
                  <div className='upload-input' style={{position:"relative"}}>
                    <Button
                      secondary
                      iconName='upload'
                      onClick={() => updateDialog()}
                      disabled={distributor.id === '' || fileName !== '' || loading}
                    >
                      Upload
                      </Button>
                      <div className='no-file'>
                        {fileName ? (
                          <span className='file-name'>
                            <div style={{ display:'flex' }}>
                              <span className='tool-tip'>{fileName}
                              </span>
                              <span className='tool-tip-text'>
                                {fileName?fileName:''}
                              </span>
                              <Icon small iconName='times' onClick={() => handleFileCancel()} />
                            </div>
                          </span>
                        ): 'No file Selected'}
                      </div>
                    </div>
                  </div>
                </div>
              </FormContentStyled>
            </div>

          )
        }
      </Fragment>
    </ICOStyled>
  );
};
UploadSection.propTypes = propTypes;

UploadSection.defaultProps = defaultProps;

const UploadSectionWithErrorAndLoading = withLoading(UploadSection);

export default UploadSectionWithErrorAndLoading;
