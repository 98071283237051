import { compose } from 'recompose';
import GRNReport from './GRNReport';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedGRNReport = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(GRNReport);

export default ComposedGRNReport;
