import { compose } from 'recompose';
import RouteTransfer from './RouteTransfer';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

import {
  query, mutation, BULK_UPDATE_APPROVAL_REQUEST, DOWNLOAD_REPORT
} from './API';

const ComposedRouteTransfer = compose(
  BULK_UPDATE_APPROVAL_REQUEST,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(RouteTransfer);

export default ComposedRouteTransfer;
