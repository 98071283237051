import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  DOWNLOAD_REPORT, GET_DISTRIBUTORS, TOGGLE_STATE
} from '../../common/GqlAPI';

const GET_WAREHOUSE_LEVELS = gql`
  query getWarehouseLevels($limit: Int, $offset: Int, $filter: FilterInput) {
    getWarehouseLevels(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        title
        parentId
        parent {
          id
          title
          parentId
          levelIndication
          dimensionLength
          dimensionHeight
          dimensionWidth
          active
        }
        levelIndication
        dimensionLength
        dimensionHeight
        dimensionWidth
        active
      }
      count
    }
  }
`;

const GET_WAREHOUSE_LEVELS_DETAILS = gql`
  query getWarehouseLevelDetails(
    $levelId: Int
    $parentId: Int
    $distributorId: Int!
    $limit: Int
    $offset: Int
    $filter: FilterInput
  ) {
    getWarehouseLevelDetails(
      levelId: $levelId
      parentId: $parentId
      distributorId: $distributorId
      limit: $limit
      offset: $offset
      filter: $filter
    ) {
      rows {
        id
        title
        parentId
        distributorId
        levelId
        levelIndication
        binLocationNumber
        active
        WarehouseLevel {
          id
          title
          parentId
        }
        parent {
          id
          title
          parentId
          distributorId
          levelId
          levelIndication
          binLocationNumber
          active
        }
      }
      count
    }
  }
`;
const EDIT_WAREHOUSE_LEVEL_DETAIL = graphql(
  gql`
    mutation ($id: Int!, $input: WarehouseLevelDetailInput!) {
      editWarehouseLevelDetail(id: $id, input: $input) {
        title
        parentId
        distributorId
        levelId
        levelIndication
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      editWarehouseLevelDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_LEVEL_INDICATION = gql`
  query GetWarehouseLevelHierarchy($warehouseLevelDetailId: Int!) {
  getWarehouseLevelHierarchy(warehouseLevelDetailId: $warehouseLevelDetailId) {
    level
    indication
  }
}
`;

const  GET_WAREHOUSE_LEVEL_HIERARCHIES = gql`
query GetWarehouseLevelHierarchies($warehouseLevelDetailIds: [Int]!) {
  getWarehouseLevelHierarchies(warehouseLevelDetailIds: $warehouseLevelDetailIds) {
    id
    binCode
    hierarchy {
      level
      indication
    }
  }
}
`;

const CREATE_WAREHOUSE_LEVEL_DETAIL = graphql(
  gql`
    mutation ($input: WarehouseLevelDetailInput!) {
      createWarehouseLevelDetail(input: $input) {
        id
        title
        parentId
        distributorId
        levelId
        levelIndication
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createWarehouseLevelDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getWarehouseLevels: GET_WAREHOUSE_LEVELS,
  getWarehouseLevelDetails: GET_WAREHOUSE_LEVELS_DETAILS,
  getWarehouseLevelHierarchy: GET_LEVEL_INDICATION,
  getWarehouseLevelHierarchies: GET_WAREHOUSE_LEVEL_HIERARCHIES,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
  createWarehouseLevelDetail: CREATE_WAREHOUSE_LEVEL_DETAIL,
  editWarehouseLevelDetail: EDIT_WAREHOUSE_LEVEL_DETAIL,
  toggleState: TOGGLE_STATE,
};

export {
  query, mutation, DOWNLOAD_REPORT, CREATE_WAREHOUSE_LEVEL_DETAIL, EDIT_WAREHOUSE_LEVEL_DETAIL
};
