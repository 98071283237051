import React from 'react';
import { Form, SingleDatePicker } from '../../../../../../v4/components';
import { CustomSelect, Input } from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { getStartOfCurrentMonth } from '../../../../../../utils/date';

const SkuGroupTypeCreateUpdateDialog = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
    lineOptions,
    handleDropDownChange,
    enableRouteSegmentation,
  } = props;

  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        argument={{ min: 0 }}
        placeholder='Title'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Title'
      />
      {enableRouteSegmentation && (
        <CustomSelect
          labelContent='Line'
          placeholder='Select'
          getOptionValue={({ id }) => id}
          getOptionLabel={({ title }) => title}
          options={lineOptions}
          name='Line'
          value={lineOptions.filter(({ id }) => id === data.line.id)}
          onChange={(event) => handleDropDownChange(event)}
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          ref={(ref) => (refsObj.lineId = ref)}
        />
      )}
      <div className='single-date-picker'>
        <label>Start Date</label>
        <SingleDatePicker
          date={data.start}
          name='start'
          placeholder='Start Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name,
                  value: date,
                },
              },
              '',
            )
          }
          labelContent='Start Date'
          maxDate={data.end}
          minDate={getStartOfCurrentMonth()}
        />
      </div>
      <div className='single-date-picker'>
        <label>End Date</label>
        <SingleDatePicker
          date={data.end}
          name='end'
          placeholder='End Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name,
                  value: date,
                },
              },
              '',
            )
          }
          labelContent='End Date'
          minDate={data.start}
        />
      </div>
    </Form>
  );
};

const SkuGroupTypeCreateUpdateDialogWithErrorAndLoading = withLoading(SkuGroupTypeCreateUpdateDialog);

export default SkuGroupTypeCreateUpdateDialogWithErrorAndLoading;
