import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { refGenerator } from '../../../../../../utils';
import LedgerAdd from '../../../../../components/LedgerAdd';
import { inputChange } from '../../../../../../utils/formHandlers';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import { refValidator } from '../../../../../../utils/refGenerator';
import withAlert from '../../../../../../utils/composition/withAlert';
import { cashLedger, requiredFields, billingHeaders, restUrl } from './config';
import { customerDetailMapper, customerMapper } from '../config';
import restClient from '../../../../../../apiClient/rest';
import { getUser } from '../../../../../../data/services';
import { getPaymentModeList } from '../../../../../../data/dao/paymentMode';

const propTypes = {
  permission: PropTypes.shape({
    BILLING: PropTypes.bool,
    INVOICE: PropTypes.bool,
  }),
  enableErrorDisplay: PropTypes.bool,
};

const defaultProps = {
  permission: {
    BILLING: true,
    INVOICE: true,
  },
  enableErrorDisplay: false,
};

class CustomerDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      outletId: nextProps.outletId,
    };
  }

  constructor(props) {
    super(props);
    const user = getUser().Distributor;
    this.billing = user.length
      ? {
          status: user[0].servicesUsed.billing.status,
          url: user[0].servicesUsed.billing.url,
        }
      : {};

    const billingStatus = props?.permission?.BILLING || false;
    this.state = {
      data: customerDetailMapper({}),
      paymentModeList: [],
      outletId: props?.outletId,
      showDialog: false,
      customerList: [],
      cashLedgerAdded: false,
    };

    this.formRefs = refGenerator(billingStatus ? requiredFields.billing : requiredFields.nonBilling);
    /** todo: write function to fetch user permission in utils page */
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  componentDidUpdate(prevProps, prevState) {
    const { outletId } = this.state;
    const { distributorId, permission } = this.props;
    if (!prevProps?.permission.BILLING && permission.BILLING) {
      (async () => {
        const paymentModeList = distributorId > 0 ? await getPaymentModeList(distributorId) : [];
        this.setState({ paymentModeList: paymentModeList?.filter(a => a?.active) });
      })();
    }
    if (outletId !== prevState.outletId) {
      this.getCustomerList();
    }
  }

  /* getUserPermission = (label) => {
    // todo fetch user permission here
    const permission = {
      SALES: {
        BILLING: true,
      },
    };
    return permission[label];
  }; */

  // event: event received from dom
  // firstParam: first Level Parameters
  // paramList: hierarchy in nestedObject : ['name', 'children', 'value']
  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;

    const updatedData = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedData });
  };
  handleInputPaymentMode = (value, firstParam = '') => {
    this.setState(prevState => {
      const { data } = prevState;
      const updatedData = {
        ...data,
        [firstParam]: value,
        Customer: customerMapper({}),
        billName: '',
      };
      return { data: updatedData };
    });
  };

  addCashLedger = () => {
    const { customerList } = this.state;
    customerList.push(cashLedger);

    this.setState({ customerList, cashLedgerAdded: true });
  };

  getOutstandingAmount = customerId => {
    const { getOutstandingValue, distributorId, displayAlert } = this.props;
    getOutstandingValue(
      {
        customerId,
        distributorId,
      },
      {
        handleSuccess: res => {
          // eslint-disable-next-line max-len
          const { data } = this.state;
          if (res?.data?.getOutstandingValue) {
            data.Customer.outstandingAmount = res?.data?.getOutstandingValue?.outstanding_amount;
          } else {
            data.Customer.outstandingAmount = 0;
          }
          this.setState({ data });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data, customerList } = this.state;

    /** handling dropdown of ledgers only */
    // const updatedDetails = dropdownChange(data, parameterRef, value);
    const filteredCustomer = customerMapper(customerList.filter(item => item.id === value)[0]);
    data.Customer = filteredCustomer;
    data.billName = filteredCustomer.name;

    this.getOutstandingAmount(filteredCustomer.id);
  };

  getValidationStatus = () => {
    const { permission } = this.props;
    if (!permission.BILLING) {
      delete this.formRefs.customerId;
      delete this.formRefs.billName;
      delete this.formRefs.remarks;
    }

    return refValidator(this.formRefs);
  };

  exportData = () => {
    const { data } = this.state;

    return data;
  };

  handleDialogSubmit = ledger => {
    const { data } = this.state;
    const { displayAlert } = this.props;
    const { customerList } = this.state;
    const formattedLedger = customerMapper(ledger) || {};
    if (formattedLedger.id) {
      data.Customer = formattedLedger;
      if (ledger.billingLedgerId) {
        // this.getCustomerOutstandingFromBilling(ledger.billingLedgerId);
        this.getOutstandingAmount(formattedLedger.id);
      } else if (ledger.openingBalance) {
        const { data } = this.state;
        data.Customer.outstandingAmount =
          ledger.openingBalanceType === 'Dr' ? -1 * ledger.openingBalance : ledger.openingBalance;
        this.setState({ data });
      }
      data.billName = formattedLedger.name;
      customerList.push(formattedLedger);
      this.setState({ customerList, showDialog: false, data });
    } else {
      this.setState({ showDialog: false });
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Customer Id not available');
    }

    /** post newly created user details and
     * update state customer data after receiving confirmation response */
  };

  getCustomerList = () => {
    const { getCustomers, displayAlert, getOutletDetail, outletId, distributorId } = this.props;
    const { customerList } = this.state;

    getOutletDetail(
      {
        id: outletId.toString(),
        retailOutletId: outletId,
        distributorId,
        filter: {
          filters: [
            {
              column: 'distributor_id',
              value: [distributorId.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          // eslint-disable-next-line max-len
          const outletCustomer =
            (response.data.retailOutlets && response.data.retailOutlets.rows?.[0]?.Customers) || [];
          const customers = [...outletCustomer, ...customerList];
          this.setState({ customerList: customers });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
    /** fetch ledger list here */
  };

  toggleDialogAppearance = () => {
    const { showDialog } = this.state;

    this.setState({ showDialog: !showDialog });
  };

  render() {
    const { data, customerList, showDialog, checkBoxStatus, outletId, paymentModeList } = this.state;
    const { permission, enableErrorDisplay, townId, distributorId, salesData } = this.props;

    // permission.BILLING_STATUS
    const distributorSpecificCustomerList = customerList.filter(customer => customer.distributorId === distributorId);
    return (
      <Fragment>
        {showDialog && (
          <LedgerAdd
            onCancel={this.toggleDialogAppearance}
            onSubmit={this.handleDialogSubmit}
            townId={townId}
            id={outletId}
            billingUser={permission.BILLING}
            search
          />
        )}

        <View
          data={data}
          customerList={distributorSpecificCustomerList}
          refsObj={this.formRefs}
          salesData={salesData}
          permission={permission}
          checkboxStatus={checkBoxStatus}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={this.handleInputChange}
          handleInputPaymentMode={this.handleInputPaymentMode}
          handleDropDownChange={this.handleDropDownChange}
          handleIconClick={this.toggleDialogAppearance}
          paymentModeList={paymentModeList}
        />
      </Fragment>
    );
  }
}

CustomerDetail.propTypes = propTypes;

CustomerDetail.defaultProps = defaultProps;
export default withAlert()(CustomerDetail);

export const CustDet = CustomerDetail;
