import PropTypes from 'prop-types';
import { tableHeaderWithCheckBox } from '../../../../../../v4/components';
import { PERMISSION_OBJ } from '../../../../../../data/enums/Permission';

const labelMappings = {
  orderID: 'orderID',
  outlet: 'outlet',
  orderQuantity: 'orderQuantity', 
  // inStock: 'inStock',
  // gap: 'gap', 
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.orderID,
    title: 'Order ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.orderQuantity,
    title: 'Order Quantity',
    show: true,
  },
  // {
  //   id: 4,
  //   label: labelMappings.inStock,
  //   title: 'In Stock',
  //   show: true,
  // },
  // {
  //   id: 5,
  //   label: labelMappings.gap,
  //   title: 'Gap',
  //   show: true,
  // },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.orderID] = <td key={`${data.id}-orderID`}>{data.orderId}</td>;
  table[labelMappings.outlet] = <td key={`${data.id}-outlet`}>{data.outletTitle}</td>;
  table[labelMappings.orderQuantity] = <td key={`${data.id}-orderQuantity`}>{data.orderQuantity}</td>;
  // table[labelMappings.inStock] = <td key={`${data.id}-inStock`}>{data.inStock}</td>;
  // table[labelMappings.gap] = <td key={`${data.id}-gap`}>{data.gap}</td>;
  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission, onViewClick }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr key={data.id} style={{ cursor: 'pointer' }} onClick={() => onViewClick(data.id)}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
