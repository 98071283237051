import React from 'react';
import PropTypes from 'prop-types';
import { Button } from  '../../../../../../v4/components'
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
import { TableBody, TableHeader } from './tableConfig';
import { EVENT_OPERATION } from '../../../../../../v4/constants/EventOperation';

const propTypes = {
  data: PropTypes.objectOf(Object),
  onInputChange: PropTypes.func.isRequired,
};

const defaultProps = {
  data: {},
};

const ReplenishmentModelView = ({ data,handleSearchInput,checkedList,primaryCheckboxHandler,secondaryCheckboxHandler,permission,onIconClick }) =>
{
  return(
    <>
      <div className='top-section'>
        <span>{`${data?.length} items`}</span>
        <div className='top-right'>
          {/* <Search handleSearchInput={(text) => handleSearchInput(text)} /> */}
          <Button small onClick={() => onIconClick(EVENT_OPERATION.CREATE)} iconBtnSmall iconName='plus' title='Add' className='add-btn'/>
        </div>
      </div>
      <div className='table-wrap'>
        <table>
          {TableHeader({
            dataListLength: data?.length,
            checkedListLength: checkedList.length,
            primaryCheckboxHandler,
            permission,
          })}
          <tbody>
            {data?.map((replenishment) =>
              TableBody({
                data: replenishment,
                checkedList: checkedList,
                secondaryCheckboxHandler,
                permission,
                onIconClick,
              }),
            )}
          </tbody>
        </table>
      </div>
    </>
  )};

ReplenishmentModelView.propTypes = propTypes;

ReplenishmentModelView.defaultProps = defaultProps;
const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(ReplenishmentModelView);
export default LoginViewWithErrorAndLoading;
