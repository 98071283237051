
import { compose } from 'recompose';
import { query } from './API';
import CallPlan from './CallPlan';
import withRequestTracker from '../../../utils/composition/withRequestTracker';


const composedCallPlan = compose(
  withRequestTracker({
    query
  }),
)(CallPlan);

export default composedCallPlan;
