import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { Pagination, BulkDelete } from '../../../v4/components';
import { TableHeader, TableBody } from './tableConfig';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  checkedList: PropTypes.instanceOf(Array),
  breadCrumb: PropTypes.instanceOf(Array),
  onBulkDelete: PropTypes.func.isRequired,
  onReplicateTypeClick: PropTypes.func,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  showBulkSelect: PropTypes.bool,
  getCheckedListData: PropTypes.func,
  onPromotionItemClick: PropTypes.func.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onReplicateTypeClick: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  checkedList: [],
  breadCrumb: [],
  permission: PERMISSION_OBJ,
  showBulkSelect: false,
  getCheckedListData: () => null,
};

const View = ({ ...props }) => {
  const {
    data,
    pagination,
    permission,
    onIconClick,
    onPageChange,
    onBulkDelete,
    onReplicateTypeClick,
    getCheckedListData,
    showBulkSelect,
    onPromotionItemClick,
  } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.PROMOTION}
        handler={onBulkDelete}
        replicate
        showBulkSelect={showBulkSelect}
        getCheckedListData={getCheckedListData}
        onReplicateClick={onIconClick}
        onReplicateTypeClick={onReplicateTypeClick}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap promotion'>
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: checkedList.length,
                primaryCheckboxHandler,
                permission,
              })}

              <tbody>
                {data.list.map((promotion) =>
                  TableBody({
                    data: promotion,
                    checkedList: checkedList,
                    secondaryCheckboxHandler,
                    permission,
                    onPromotionItemClick,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
