import React from 'react';
import { Button, MonthPicker } from '../../../../v4/components';
import { Input } from '../../../../components';
import { TableBody, TableHeader } from './tableConfig';
import { AVERAGE_BASE } from './config';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';

const View = (props) => {
  const {
    read,
    update,
    data,
    handleCalculateTarget,
    handleDateChange,
    handleInputChange,
    refsObj,
    enableErrorDisplay,
    calculateClicked,
    loading,
    id,
    permission,
  } = props;

  const CalculateButton = () => {
    return (
      <Button medium secondary onClick={() => handleCalculateTarget()} className='targetBtn' disabled={loading}>
        <span>Calculate Target</span>
      </Button>
    );
  };

  return (
    <>
      <div className={read ? 'field-section disabled' : 'field-section'}>
        <div className={update || calculateClicked ? 'month-wrap disabled' : 'month-wrap'}>
          <label>Month</label>
          <MonthPicker isDisabledReq value={data?.duration} onSelect={(e) => handleDateChange(e, 'duration')} />
        </div>
        <Input
          ref={(ref) => (refsObj.target = ref)}
          value={data.target}
          name='target'
          type='number'
          argument={{ min: 0 }}
          placeholder='National Target'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event)}
          labelContent='National Target'
          decimalWithPrecedingZero
        />

        <div className='mt-24'>
          {id && permission.update ? <CalculateButton /> : ''}

          {!id && permission.create ? <CalculateButton /> : ''}
        </div>
      </div>
      {data?.list?.length > 0 && (
        <div className='table-wrap prn'>
          <table>
            {TableHeader()}

            <tbody>
              {data?.list?.map((target) =>
                TableBody({
                  data: target,
                }),
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
const ViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default ViewWithErrorAndLoading;
