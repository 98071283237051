import { presentor } from '../../../../utils/date';
import { SingleDatePicker } from '../../../../v4/components';

export const FiscalYear = ({ ...props }) => {
  const { data, handleDateRangeChange } = props;
  return (
    <>
      <div className="form-input-wrap">
        <div className={`form-input card date`}>
          <label>
            <span>Starting Month</span>
          </label>
          <SingleDatePicker
            date={data?.fyData?.fiscalYear?.start || presentor(new Date())}
            name="start"
            onChange={(name, date) => {
              handleDateRangeChange(name, date);
            }}
          />
        </div>
        <div className={'form-input card date'}>
          <label>
            <span>End Month</span>
          </label>
          <SingleDatePicker
            date={data?.fyData?.fiscalYear?.end || presentor(new Date())}
            name="end"
            onChange={(name, date) => {
              handleDateRangeChange(name, date);
            }}
          />
        </div>
      </div>
    </>
  );
};
