import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_PAYMENTS_LIST = gql`
  query payments($limit: Int, $offset: Int, $filter: FilterInput) {
    payments(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        amount
        remarks
        distributor: Distributor {
          title
        }
        collector: Collector {
          fullName
          role: Role {
            name
          }
        }
        outlet: RetailOutlet {
          title
          route: Route {
            title
            user: User {
              fullName
            }
          }
        }
        collectionType
        status
        details {
          chequeNumber
          bank
          image {
            src: thumbnail
            thumbnail
            created_at
          }
          valuedDate
        }
        date
      }
      count
    }
  }
`;

const CREATE_PAYMENT = graphql(
  gql`
    mutation ($input: PaymentInput!) {
      createPayment(input: $input) {
        id
        User {
          id
          firstName
          lastName
        }
        Route {
          id
        }
        RetailOutlet {
          id
        }
        details {
          chequeNumber
          bank
          valuedDate
        }
        amount
        remarks
        date
        collectionType
        collectorId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createPayment: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_ROUTE_LIST = gql`
  query getRoutes($offset: Int, $limit: Int, $filter: FilterInput) {
    routes(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
      }
      count
    }
  }
`;
const GET_OUTLETS = gql`
  query getOutlets($limit: Int, $offset: Int, $filter: FilterInput) {
    retailOutlets(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        title
        Route {
          id
          title
        }
        Distributor {
          id
          title
        }
        townId
      }
      count
    }
  }
`;

const GET_ROLES_LIST = gql`
  query getRoles($offset: Int, $limit: Int, $filter: FilterInput) {
    roles(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        name
        displayName
      }
      count
    }
  }
`;

const GET_USERS = gql`
  query getUsers($limit: Int, $offset: Int, $filter: FilterInput) {
    users(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        fullName
        Role {
          id
          name
        }
      }
      count
    }
  }
`;

const GET_BANK_LIST = gql`
  query banks {
    banks {
      id
      title
    }
  }
`;

const GET_DISTRIBUTOR_LIST = gql`
  query getDistributors($offset: Int, $limit: Int, $filter: FilterInput) {
    distributors(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
      }
      count
    }
  }
`;

const query = {
  getOutlets: GET_OUTLETS,
  getRouteList: GET_ROUTE_LIST,
  payments: GET_PAYMENTS_LIST,
  getRoles: GET_ROLES_LIST,
  getUsers: GET_USERS,
  getBankList: GET_BANK_LIST,
  getDistributorList: GET_DISTRIBUTOR_LIST,
};

const mutation = {
  createPayment: CREATE_PAYMENT,
};

export { CREATE_PAYMENT };

export { query, mutation };
