import { compose } from 'recompose';
import UserCluster from './UserCluster';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedUserCluster = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(UserCluster);

export default ComposedUserCluster;
