import { breadCrumbConfig, title } from '../config';
import { SYSTEM_ALERT } from '../../../../data/enums/Route';
import { getCurrentDay, getTomorrowDate } from '../../../../utils/date';
import { checkRepeatTime } from '../../../../data/enums/Notification';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: `${title}s`,
    path: `/${SYSTEM_ALERT}`,
    active: true,
  },
];

const formConfig = {
  mapper: (element) => ({
    id: element.id || 0,
    title: element.title || '',
    message: {
      title: (element.message && element.message.title) || '',
      link: (element.message && element.message.link) || '',
      description: (element.message && element.message.description) || '',
      message: (element.message && element.message.message) || '',
    },
    audience: {
      info: [
        {
          userRole: [],
          userIds: [],
          platform: [],
        },
      ],
    },
    attribute: element.attribute || '',
    threshold: {
      value: element.threshold ? element.threshold.value : null,
      operator: element.threshold ? element.threshold.operator : '>=',
      dimension: element.threshold ? element.threshold.dimension : '%',
    },
    repeatedTimes: {
      daily: (element.repeatedTimes && element.repeatedTimes.daily) || true,
      weekly: (element.repeatedTimes && element.repeatedTimes.weekly) || false,
    },
    startDate: element.startDate || getCurrentDay(),
    endDate: element.endDate || getCurrentDay(),
    times: {
      hours: (element.times && element.times.hours) || '00',
      minutes: (element.times && element.times.minutes) || '00',
      period: (element.times && element.times.period) || 'AM',
    },
    time: element.times && `${element.times.hours}:${element.times.minutes} ${element.times.period}`,
    repeatDays: element.repeatDays || [],
  }),
  validationField: ['attribute', 'value', 'role', 'userIds', 'title'],
  validationRequired: true,
};

const transformData = (data) => ({
  sendNotifications: true,
  title: data.title,
  attribute: data.attribute,
  threshold: { ...data.threshold, value: data?.threshold?.value?.toString() },
  message: data.message,
  audienceFilter: data.audience.info,
  repeatTimes: checkRepeatTime(data.repeatedTimes),
  repeatDays: data.repeatDays,
  startDate: data.startDate,
  endDate: data.endDate,
  time:
    data.times &&
    `${data.times.hours}:${data.times.minutes.length < 2 ? `0${data.times.minutes}` : data.times.minutes} ${
      data.times.period
    }`,
});

const reponseToStateTransformer = (data) => ({
  attribute: data.attribute,
  title: data.title,
  audience: { info: [...data.audienceFilter] },
  message: {
    title: (data.messageDetail.length && data.messageDetail[0].title) || '',
    link: (data.messageDetail.length && data.messageDetail[0].link) || '',
    description: (data.messageDetail.length && data.messageDetail[0].description) || '',
    message: (data.messageDetail.length && data.messageDetail[0].message) || '',
  },
  times: data.time
    ? {
      hours: data.time.substr(0, data.time.indexOf(':')),
      minutes: data.time.substr(data.time.indexOf(':') + 1, 3).trim(''),
      period: data.time.substr(5).trim(' '),
    }
    : formConfig.mapper({}).times,
  startDate: data.startDate,
  endDate: data.endDate,
  repeatedTimes: {
    daily: data.repeatTimes === 'DAILY' || false,
    weekly: data.repeatTimes === 'WEEKLY' || false,
    monthly: data.repeatTimes === 'MONTHLY' || false,
  },
  repeatDays: data.repeatDays || [],
  threshold: data.threshold || {},
});

export {
  breadCrumb as breadCrumbConfig, formConfig, transformData, reponseToStateTransformer
};
