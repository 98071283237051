import { refGenerator } from '../../../../../utils/refGenerator';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { getTodaysDate } from '../../../../../utils/date';

const summaryDetailsMapper = (element = {}, distributorId = 0) => ({
  grnInvoiceNumber: element.invoiceNumber || element.inputInvoiceNumber || '',
  prnInvoiceNumber: element.prnInvoiceNumber || '',
  distributorId: element.distributorId || distributorId || 0,
  reason: '',
  vendorId: element.vendorId || '',
  inputInvoiceNumber: element.inputInvoiceNumber || '',
  prnDate: element.prnDate || getTodaysDate(),
  grnDate: element.grnDate || getTodaysDate(),
});

const summaryDetailsFormConfig = {
  [FORM_CONFIG.MAPPER]: (element) => summaryDetailsMapper(element, element.distributorId),
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['distributorId', 'reason', 'vendorId','grnInvoiceNumber']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

export { summaryDetailsFormConfig, summaryDetailsMapper };
