import React, { useEffect, useState } from 'react';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { BreadCrumb, Button, Download, Icon } from '../../../../v4/components';
import {
  ZONE_ID,
  breadCrumb,
  dimensionList,
  getZoneDataPayloadIn,
  initialDetails,
  MSG,
  updatePayloadOut,
  getCalculatedTargetPayloadOut,
  getSummedResult,
  getDownloadPayloadOut,
  getPayloadIn,
  getListData,
} from './config';
import NewView from './NewView';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { DetailStyled } from './DetailStyled';
import { refGenerator } from '../../../../utils';
import { refValidator } from '../../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../../v4/constants/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { TARGET_DISTRIBUTION } from '../../../../data/enums/Route';
import * as downloadService from '../../../base/download.service';
import history from '../../../../utils/history';
import { DOWNLOAD_DOMAIN } from '../../../../data/enums/GraphQL';
import { getPermissionForTargetDistribution } from '../../../base/permission';
import { clone } from '../../../../utils/objectProcessor';
import { getTotalOf } from '../../../../utils/utilities';

const TargetDistributionDetail = props => {
  const {
    serverResponseWaiting,
    match,
    displayAlert,
    createAutomatedSalesTarget,
    updateAutomatedSalesTarget,
    getAutomatedSalesTargetDetails,
    calculateAutomatedSalesTarget,
    downloadReport,
  } = props;

  const permission = getPermissionForTargetDistribution();
  const validation = refGenerator(['target']);
  const id = parseInt(match.params.id, 10);
  const containsPG = location.pathname.includes('pg');

  const [details, setDetails] = useState({ ...initialDetails });
  const [cloneData, setCloneData] = useState({ details: {}, listData: [] });
  const [crudMode, setCrudMode] = useState(id && id !== '0' ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE);
  const [enableValidation, setEnableValidation] = useState(false);
  const [calculateClicked, setCalculateClicked] = useState(false);
  const [principalList, setPrincipalList] = useState([]);
  const [listData, setListData] = useState([]);
  const [firstTime, setFirstTime] = useState(true);

  const read = crudMode === EVENT_OPERATION.READ;
  const update = crudMode === EVENT_OPERATION.UPDATE;
  const create = crudMode === EVENT_OPERATION.CREATE;
  const locationEnabled = details?.dimensionId === ZONE_ID;

  useEffect(() => {
    (async () => {
      if (principalList?.length === 0) {
        await getPrinciple();
      }
      if (id && read && listData?.length === 0) {
        await getData();
      }
    })();
  }, [id, read, principalList]);

  const handleEditIconClick = () => {
    setCrudMode(EVENT_OPERATION.UPDATE);
  };

  const handleCancelClick = () => {
    if (create) {
      setDetails({ ...initialDetails });
      setCalculateClicked(false);
    } else {
      setCrudMode(EVENT_OPERATION.READ);
      setDetails(cloneData?.details);
      setListData(cloneData?.listData);
    }
  };
  const pushToTargetDistribution = () =>
    setTimeout(() => {
      history.push(`/${TARGET_DISTRIBUTION}`);
    }, 1200);

  const createAutomated = details => {
    createAutomatedSalesTarget(
      {
        automatedSalesTargetId: details?.id,
        principleId: details?.principalId,
      },
      {
        handleSuccess: response => {
          displayAlert(ALERT_TYPE.SUCCESS, MSG.CREATE_SUCCESS);
          pushToTargetDistribution();
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  const updateAutomated = details => {
    updateAutomatedSalesTarget(
      {
        input: updatePayloadOut(details, locationEnabled, principalList, create),
      },
      {
        handleSuccess: response => {
          displayAlert(ALERT_TYPE.SUCCESS, MSG.UPDATE_SUCCESS);
          pushToTargetDistribution();
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  const validateTargetEquality = (details, zoneList) => {
    if (locationEnabled && details.target !== getTotalOf(zoneList, 'targetContribution')) {
      displayAlert(ALERT_TYPE.DANGER, {
        message: MSG.NO_EQUAL,
      });
      return false;
    }
    return true;
  };
  const handleSaveClick = () => {
    const valid = refValidator(validation);
    if (!valid) {
      setEnableValidation(true);
      return;
    } else {
      if (create) {
        createAutomated(details);
      } else {
        updateAutomated(details);
      }
    }
  };
  const calculateAutomatedSalesTargetApiCall = () => {
    calculateAutomatedSalesTarget(
      {
        input: getCalculatedTargetPayloadOut({ details, create, firstTime, locationEnabled, principalList }),
      },
      {
        handleSuccess: response => {
          const result = response?.data?.calculateAutomatedSalesTarget;
          if (result) {
            setDetails({
              ...details,
              ...(locationEnabled || { list: getSummedResult(result?.detail, 'channelId', locationEnabled) }),
              ...(locationEnabled && { zoneList: getSummedResult(result?.detail, 'territoryId', locationEnabled) }),
              id: result?.automatedSalesTargetId,
            });
            setCalculateClicked(true);
            setFirstTime(false);
          }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  const handleCalculateTarget = () => {
    const valid = refValidator(validation);
    if (!valid) {
      setEnableValidation(true);
      return;
    }
    if (locationEnabled && !validateTargetEquality(details, details?.zoneList)) return;
    if (details.averageBase === '') {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, MSG.AVG_BASE);
      return;
    }
    calculateAutomatedSalesTargetApiCall();
  };

  const handleDateChange = (event, firstParam = '', paramList = []) => {
    details[firstParam] = event;
    setDetails({ ...details, [firstParam]: event });
    setCalculateClicked(false);
    setFirstTime(true);
  };

  const handleInputChange = (e, type) => {
    setDetails({ ...details, [e.target.name]: type === 'num' ? Number(e.target.value) : e.target.value });
    setCalculateClicked(false);
  };

  const handleDownloadClick = domain => {
    downloadReport(
      {
        input: getDownloadPayloadOut(domain, id),
      },
      {
        handleSuccess: response => {
          downloadService.resolver(response.data.downloadReport.file);
          displayAlert(ALERT_TYPE.SUCCESS, MSG.DOWNLOAD_SUCCESS);
        },
        handleError: () => {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, MSG.DOWNLOAD_FAILED);
        },
      },
    );
  };

  const getData = async () => {
    getAutomatedSalesTargetDetails(
      {
        getAutomatedSalesTargetDetailsId: id,
      },
      {
        handleSuccess: res => {
          if (res) {
            const result = res?.data?.getAutomatedSalesTargetDetails;
            if (result) {
              const payloadInDetails = getPayloadIn(result);
              const dataList = getListData(payloadInDetails, principalList);
              setDetails(payloadInDetails);
              setCalculateClicked(true);
              setListData(dataList);
              setCloneData({ ...cloneData, details: clone(payloadInDetails), listData: clone(dataList) });
            }
          }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  const getZone = async newDetails => {
    const { getTerritoryList, displayAlert } = props;
    getTerritoryList(
      {},
      {
        handleSuccess: response => {
          const zone = response.data.territories.rows;
          setDetails({ ...newDetails, zoneList: getZoneDataPayloadIn(zone) });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  const onSelectChange = async (event, name, type) => {
    const newDetails = {
      ...details,
      [name]: event?.id,
    };
    if (details?.zoneList?.length === 0 && event?.id === ZONE_ID) {
      await getZone(newDetails);
      return;
    }
    setDetails({ ...newDetails });
    setCalculateClicked(false);
  };
  const getPrinciple = async () => {
    const { getCatalogListDetail, displayAlert } = props;
    getCatalogListDetail(
      {
        catalogId: 1,
      },
      {
        handleSuccess: response => {
          const catalogDetails = response?.data?.catalogDetails?.rows;
          setPrincipalList([...catalogDetails]);
          setDetails({ ...details, ...(create && { principalId: catalogDetails?.[0]?.id, dimensionId: 1 }) });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  const handleInputChangeTable = (value, item) => {
    const updateNewData = clone(details);
    const index = updateNewData?.zoneList?.findIndex(list => list?.id === item?.id);
    updateNewData.zoneList[index] = { ...item, targetContribution: Number(value) };
    setDetails({ ...updateNewData });
  };
  return (
    <DetailStyled>
      <div className="section-header">
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <div className="flex items-center justify-between m-0 flex-1">
              <h2>
                {create
                  ? 'Create Target Distribution'
                  : read
                  ? `${details?.monthName}, ${details?.duration?.year}`
                  : `Edit ${details?.monthName}, ${details?.duration?.year}` || ''}
              </h2>
              {!read && calculateClicked && (
                <div>
                  <Button small disabled={serverResponseWaiting} secondary onClick={() => handleCancelClick()}>
                    <span>Cancel</span>
                  </Button>
                  <Button primary disabled={serverResponseWaiting} small onClick={() => handleSaveClick()}>
                    <span>Save</span>
                  </Button>
                </div>
              )}
              {read ? (
                <div className="flex-display">
                  <Download handleDownloadClick={() => handleDownloadClick(DOWNLOAD_DOMAIN.TARGET_DISTRIBUTION)} />
                  {permission.update && (
                    <Button secondary iconBtnSmall onClick={handleEditIconClick} className="ml-16">
                      <Icon iconName="pencil" />
                    </Button>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className="section-content custom-scroll">
        <div className="create-ui">
          <NewView
            read={read}
            update={update}
            data={details}
            handleCalculateTarget={handleCalculateTarget}
            handleDateChange={handleDateChange}
            onSelectChange={onSelectChange}
            handleInputChange={handleInputChange}
            refsObj={validation}
            enableErrorDisplay={enableValidation}
            loading={serverResponseWaiting}
            calculateClicked={calculateClicked}
            id={id}
            permission={permission}
            handleInputChangeTable={handleInputChangeTable}
            principalList={principalList}
            dimensionList={dimensionList}
            locationEnabled={locationEnabled}
            listData={listData}
          />
        </div>
      </div>
    </DetailStyled>
  );
};

export default withAlert()(TargetDistributionDetail);
