const TARGET_AUDIENCE = {
  1: 'USER',
  2: 'OUTLET',
  3: 'OUTLET_USER',
};

const TARGET_AUDIENCE_BY_NAME = {
  USER: 1,
  OUTLET: 2,
  OUTLET_USER: 3,
};

const MESSAGE_PLATFORM = {
  SMS: 1,
  NOTIFICATION: 2,
  VIBER: 3,
};

export {
  TARGET_AUDIENCE, TARGET_AUDIENCE_BY_NAME, MESSAGE_PLATFORM
};
