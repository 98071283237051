export const dataX = {
  "distributorId": 1,
  "retailOutletId": 9297,
  "callTime": {
    "callStart": "2023-04-20 12:39:02",
    "callEnd": "2023-04-20 12:40:34"
  },
  "location": {
    "latitude": 27.69232230977099,
    "longitude": 85.3195977467727
  },
  "orderCount": {
    "lineCount": 1,
    "brandCount": 1
  },
  "callOrderValue": {
    "grossAmount": 4565200,
    "taxableAmount": 4040000,
    "netAmount": 4565200,
    "taxAmount": 525200,
    "discountAmount": 0,
    "promotionDiscount": 0,
    "billDiscount": 0,
    "topUpDiscount": 0,
    "subTotal": 4040000,
    "tradeDiscount": 0
  },
  "type": "SUCCESSFUL",
  "images": [],
  "notes": "Note",
  "remarks": "Remarks",
  "deliveryFrom": "2021-04-20 14:00:00",
  "deliveryTo": "2023-01-20 14:30:00",
  "orders": [
    {
      "skuBatchRateId": 1,
      "skuId": 17,
      "familyId": 7,
      "distributorId": 1,
      "rateDetails": {
        "rlp": 202000,
        "rlpWithVat": 228260,
        "netPrice": 202000,
        "netPriceWithVat": 228260
      },
      "amountDetails": {
        "grossAmount": 4565200,
        "taxableAmount": 4040000,
        "netAmount": 4565200,
        "taxAmount": 525200,
        "discountAmount": 0,
        "promotionDiscount": 0,
        "topUpDiscount": 0,
        "subTotal": 4040000,
        "billDiscount": 0
      },
      "promotionStatus": false,
      "promotions": [],
      "quantity": 20
    }
  ]
}