import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { BulkDelete, Pagination } from '../../../v4/components';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { TableHeader, TableBody } from './tableConfig';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  onBulkDelete: PropTypes.func.isRequired,
  onEventFired: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  pagination: paginationProps.value,
};

const defaultProps = {
  data: { list: [], total: 0 },
  permission: PERMISSION_OBJ,
  pagination: paginationProps.defaultValue,
};

const RouteSegmentationView = ({ ...props }) => {
  const { data, onEventFired, pagination, onPageChange, permission, onBulkDelete } = props;

  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.LINE}
        handler={onBulkDelete}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap'>
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: checkedList.length,
                primaryCheckboxHandler,
                permission,
              })}
              <tbody>
                {data.list.map((route) =>
                  TableBody({
                    data: route,
                    checkedList: checkedList,
                    secondaryCheckboxHandler,
                    permission,
                    onTableBodyClick: onEventFired,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      {data.total > 50 && (
        <Pagination
          currentPage={pagination.page}
          limit={pagination.limit}
          totalItems={data.total}
          onPageChange={onPageChange}
        />
      )}
    </Fragment>
  );
};

export default withErrorAndLoadingScreen(RouteSegmentationView);

RouteSegmentationView.propTypes = propTypes;

RouteSegmentationView.defaultProps = defaultProps;
