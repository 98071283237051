import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import BulkDeleteView from './View';
import ModalWrapper from '../../common/DialogFormWrapper';
import Confirmation from '../../common/DialogConfirmation';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { formConfig, crudRequestConfig, crudWarehouseRequestConfig } from './config';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { extractSingleItemFromList } from '../../../utils/arrayProcessor';
import { handleFormSubmit, responseInterpreter } from '../../../utils/crudResponseProcessor';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  displayAlert: PropTypes.func.isRequired,
  handler: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  showBulkSelect: PropTypes.bool,
  toggleState: PropTypes.func.isRequired,
  getCheckedListData: PropTypes.func,
  type: PropTypes.string,
  onReplicateClick: PropTypes.func,
  onReplicateTypeClick: PropTypes.func,
  deleteServerCall: PropTypes.func,
  useExternalAPI: PropTypes.bool,
  crudExternalRequestConfig: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: [],
  serverResponseWaiting: false,
  type: null,
  showBulkSelect: true,
  getCheckedListData: () => {},
  onReplicateClick: () => null,
  onReplicateTypeClick: () => null,
  deleteServerCall: () => null,
  useExternalAPI: false,
  crudExternalRequestConfig: {},
};

class BulkDelete extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      checkedList: [],
      primarySelected: false,
      dialog: {
        type: '',
        element: {},
      },
    };
    const serverCall = {
      [EVENT_OPERATION.BULK_DELETE]: props.useExternalAPI ? props.deleteServerCall : props.toggleState,
    };
    this.onCRUDSuccess = responseInterpreter(this.handleSuccessResponse);
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      this.handleApiFailure,
      props.useExternalAPI ? props.crudExternalRequestConfig : crudRequestConfig,
      serverCall,
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { showBulkSelect } = this.props;
    if (showBulkSelect !== undefined && prevProps.showBulkSelect !== showBulkSelect) {
      this.onCancelClick();
    }
  };

  handlePrimaryCheckboxClick = e => {
    const { data, replicate, getCheckedListData } = this.props;
    if (e.target.checked) {
      const checkedList = extractSingleItemFromList(data);
      this.setState({ checkedList }, replicate && getCheckedListData(checkedList));
    } else {
      this.setState({ checkedList: [] });
    }
  };

  handleSecondaryCheckboxClick = id => {
    const { checkedList } = this.state;
    const { getCheckedListData, replicate } = this.props;
    const index = checkedList.indexOf(id);
    if (index > -1) {
      checkedList.splice(index, 1);
    } else {
      checkedList.push(id);
    }
    this.setState({ checkedList }, replicate && getCheckedListData(checkedList));
  };

  handleApiFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ dialog: { type: '', element: '' }, checkedList: [] });
  };

  handleSuccessResponse = (type, response) => {
    const { handler, displayAlert } = this.props;
    if (!handler) displayAlert(ALERT_TYPE.SUCCESS, response.message);
    else handler(type, response);
  };

  onDeleteClick = () => {
    const { checkedList } = this.state;
    const { type } = this.props;
    this.setState({
      dialog: {
        type: EVENT_OPERATION.BULK_DELETE,
        element: { data: checkedList, type },
      },
    });
  };

  onCancelClick = () => {
    this.setState({
      checkedList: [],
    });
  };

  render() {
    const { data, serverResponseWaiting, render, replicate, onReplicateClick, onReplicateTypeClick, showBulkSelect } =
      this.props;

    const { checkedList } = this.state;
    const {
      dialog: { type },
    } = this.state;
    const {
      dialog: { element },
    } = this.state;
    return (
      <Fragment>
        {type && (
          <ModalWrapper
            type={type}
            dialogData={element}
            formConfig={formConfig[type]}
            onDialogCancel={this.handleClose}
            onDialogSubmit={this.onFormSubmit}
            renderDialog={() => <Fragment>{Confirmation(EVENT_OPERATION.BULK_DELETE, checkedList.length)}</Fragment>}
          />
        )}
        <BulkDeleteView
          checkedListLength={checkedList.length}
          dataLength={data.length}
          onIconClick={this.onDeleteClick}
          loading={serverResponseWaiting}
          replicate={replicate}
          onCancelClick={this.onCancelClick}
          onReplicateClick={onReplicateClick}
          onReplicateTypeClick={onReplicateTypeClick}
          showBulkSelect={showBulkSelect}
        />
        {render(this.state, this.handlePrimaryCheckboxClick, this.handleSecondaryCheckboxClick)}
      </Fragment>
    );
  }
}

BulkDelete.propTypes = propTypes;

BulkDelete.defaultProps = defaultProps;

export default withAlert()(BulkDelete);
