import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import SubCampaignStyled from '../campaign/details/subCampaign/SubCampaignStyled';
import { CustomSelect, SingleDatePicker } from '../../../components';
import { BudgetSelect, TargetSelect } from '../campaign/details/subCampaign/BudgetSelect';
import withLoading from '../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { dimensionListConfig } from './config';

const propTypes = {
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
  getState: PropTypes.func,
  updateState: PropTypes.func,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  getState: () => null,
  updateState: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const Form = ({ ...props }) => {
  const {
    data,
    totalBudget,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleCancel,
    subCampaignTypeList,
    onDropDownChangeTarget,
    handleAdd,
    budgetOverflowFlag,
    targetOverflowFlag,
    onHandleInputChange,
    catalogDetailList,
    cardTitle,
    type,
    columnList,
    campaignDataObj,
    handleDimensionChange,
    detailData,
  } = props;
  return (
    <SubCampaignStyled>
      <div className='body_wrap'>
        <div className='row-custom r1'>
          <Row>
            <Col md={12}>
              <CustomSelect
                options={catalogDetailList}
                labelContent={cardTitle}
                className='custom-select'
                placeholder={`Select ${cardTitle}`}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                enableValidation
                ref={(ref) => (refsObj.title = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleDropDownChange(event.id, ['typeId'])}
                value={catalogDetailList.filter(({ id }) => id === data.typeId)}
                disabled={type !== EVENT_OPERATION.CREATE}
              />
            </Col>
            <Col md={6}>
              <div className='single-date-picker'>
                <label>Start Date</label>
                <SingleDatePicker name='startDate' date={data.startDate} disabled />
              </div>
            </Col>
            <Col md={6}>
              <div className='single-date-picker end-date'>
                <label>End Date</label>
                <SingleDatePicker name='endDate' date={data.endDate} disabled />
              </div>
            </Col>
          </Row>
        </div>
        <div className={`r2 row-custom ${type === EVENT_OPERATION.READ ? 'disabled' : ''}`}>
          <BudgetSelect
            data={data}
            title='Budget'
            onHandleInputChange={onHandleInputChange}
            handleDropDownChange={handleDropDownChange}
            refsObj={refsObj}
            campaignDataObj={campaignDataObj}
            enableErrorDisplay={enableErrorDisplay}
            totalBudget={totalBudget}
            handleAdd={handleAdd}
            handleCancel={handleCancel}
            subCampaignTypeList={subCampaignTypeList}
            budgetOverflowFlag={budgetOverflowFlag}
            dimensionList={dimensionListConfig}
            handleDimensionChange={handleDimensionChange}
            disableAdd={detailData.subCampaignBudget.length === 0}
          />
        </div>
        <div className={`r3 row-custom ${type === EVENT_OPERATION.READ ? 'disabled' : ''}`}>
          <TargetSelect
            data={data}
            title='Target'
            onHandleInputChange={onHandleInputChange}
            handleDropDownChange={handleDropDownChange}
            refsObj={refsObj}
            enableErrorDisplay={enableErrorDisplay}
            onDropDownChangeTarget={onDropDownChangeTarget}
            handleAdd={handleAdd}
            handleCancel={handleCancel}
            subCampaignTypeList={subCampaignTypeList}
            dimensionList={dimensionListConfig}
            handleDimensionChange={handleDimensionChange}
            disableAdd={detailData.subCampaignTarget.length === 0}
          />
        </div>
      </div>
    </SubCampaignStyled>
  );
};

Form.propTypes = propTypes;

Form.defaultProps = defaultProps;

const FormWithErrorAndLoading = withLoading(Form);

export default FormWithErrorAndLoading;
