import { compose } from 'recompose';
import Detail from './Detail';
import {
  query, mutation, SEND_SYSTEM_ALERT, EDIT_SYSTEM_ALERT, TOGGLE_STATE
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedDetail = compose(
  SEND_SYSTEM_ALERT,
  EDIT_SYSTEM_ALERT,
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(Detail);

export default ComposedDetail;
