import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../v4/components';

const labelMappings = {
  id: 'id',
  title: 'title',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.id,
    title: 'ID',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
];

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data.title}</td>;

  return table;
};

const TableBody = ({ data, onHandleClick }) => {
  const tableBody = returnTableBody(data);

  return (
    <tr
      key={`chanAssort-${data.id}`}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onHandleClick(data.id);
      }}
    >
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onHandleClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

export { TableHeader, TableBody };
