import React from 'react';
import InfoSection from './InfoSection/InfoSection';
import PurchaseOrderDetailTable from './TableSection/PurchaseOrderDetailTable';

const RowItemDetailDialog = ({dialogData}) => {
    const infoSectionData = {
      totalOrderQuantity: dialogData?.orderQuantity,
      totalInStock: dialogData?.inStock,
      totalGap: dialogData?.gap,
    };
    const tableData = dialogData?.purchaseOrderDetail;

  return (
    <>
      <InfoSection infoSectionData={infoSectionData}/>
      <PurchaseOrderDetailTable tableData={tableData}/>
    </>
  );
};

export default RowItemDetailDialog;
