import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { normalPresentor } from '../../../utils/date';
import TableHeader from '../../base/TableHeader';

const labelMappings = {
  skuId: 'skuId',
  skuTitle: 'skuTitle',
  balance: 'balance',
  adjustment: 'adjustment',
  lastSynced: 'lastSynced',
  reason: 'reason',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.skuId,
    title: 'SKU ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.skuTitle,
    title: 'SKU',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.balance,
    classNames: 'text-right',
    title: 'Quantity in System',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 4,
    label: labelMappings.adjustment,
    classNames: 'text-right',
    title: 'Adjustment',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 5,
    label: labelMappings.lastSynced,
    classNames: 'text-center',
    title: 'Last Synced',
    show: true,
    sortable: true,
    className: 'sorting text-center',
  },
  {
    id: 6,
    label: labelMappings.reason,
    title: 'Reason',
    show: true,
    sortable: false,
    className: '',
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
);

const returnTableBody = (data, onRowClick) => {
  const table = {};
  table[labelMappings.skuId] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockAdjustment-${data.skuId}-skuId`}>
      <span>{(data.sku && data.skuId) || ''}</span>
    </td>
  );
  table[labelMappings.skuTitle] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockAdjustment-${data.skuId}-title`}>
      {(data.sku && data.sku.title) || ''}
    </td>
  );
  table[labelMappings.balance] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockAdjustment-${data.skuId}-balance`} className='text-right'>
      {data.balance}
    </td>
  );
  table[labelMappings.adjustment] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockAdjustment-${data.skuId}-adjustment`} className='text-right'>
      <span className={!data.negative ? (data.lastAdjustment !== 0 ? 'positive' : '') : 'negative'}>
        {data.lastAdjustment}
      </span>
    </td>
  );
  table[labelMappings.lastSynced] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockAdjustment-${data.skuId}-lastSynced`} className='text-center'>
      {normalPresentor(data.lastDate) === 'Invalid date' ? '-' : normalPresentor(data.lastDate)}
    </td>
  );
  table[labelMappings.reason] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockAdjustment-${data.skuId}-reason`}>
      {data.reason}
    </td>
  );

  return table;
};

const TableBody = ({ data, onRowClick }) => {
  const tableBody = returnTableBody(data, onRowClick);
  return <Fragment key={data.skuId}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

export { TableHeaderConfig as TableHeader, TableBody };
