import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import SystemAlertWrapper from './SystemAlertWrapper';
import { title, breadCrumbConfig, crudRequestConfig } from './config';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { getPermissionForSystemAlert } from '../../base/permission';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { SYSTEM_ALERT_DETAILS, SYSTEM_ALERT_CREATE } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { DialogWrapper } from '../../common';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { isError } from '../../common/HelperFunctions';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getSystemAlerts: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  toggleState: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class SystemAlert extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();

    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
      },
    };
    this.permission = getPermissionForSystemAlert();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        props.displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      },
      () => this.getData(),
    );
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  componentDidMount() {
    this.loadTableData();
  }

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  directToCreatePage = () => {
    const { history } = this.props;
    history.push(`/${SYSTEM_ALERT_CREATE}`);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  loadTableData = () => {
    const { getSystemAlerts } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getSystemAlerts(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.systemAlerts && response.data.systemAlerts.rows) || [];
          data.total = (response.data.systemAlerts && response.data.systemAlerts.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleRowClick = id => {
    const { history } = this.props;
    history.push(`/${SYSTEM_ALERT_DETAILS}/${id}`);
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const { queryParameters, data } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <DialogWrapper
        header=""
        onDialogSubmit={this.onFormSubmit}
        render={({ onDialogItemClick }) => (
          <>
            <div className="section-header">
              <PanelStyled>
                <PageHeader
                  breadCrumb={breadCrumbConfig}
                  config={{
                    title: `${title}s`,
                    create: this.permission?.create,
                    search: true,
                  }}
                  queryParameters={queryParameters}
                  controlDisplay={this.controlDisplay}
                  handleCreateClick={this.directToCreatePage}
                  resetFilter={this.basePaginationService.resetFilter}
                  clearSearchText={this.basePaginationService.clearSearchText}
                  handleSearchChange={this.basePaginationService.handleSearchInputChange}
                />
              </PanelStyled>
            </div>
            <div className="section-content table-present">
              <SystemAlertWrapper>
                <View
                  data={data}
                  onIconClick={onDialogItemClick}
                  loading={serverResponseWaiting}
                  pagination={queryParameters.pagination}
                  permission={this.permission}
                  onRowClick={this.handleRowClick}
                  onBulkDelete={this.handleBulkDelete}
                  onPageChange={this.basePaginationService.onPageSelect}
                />
              </SystemAlertWrapper>
            </div>
          </>
        )}
      />
    );
  }
}

SystemAlert.propTypes = propTypes;

SystemAlert.defaultProps = defaultProps;

export default withAlert()(SystemAlert);
