import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../../v4/components/';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import withLoading from '../../../../utils/composition/withLoading';
import { TableBody, TableHeader } from './tableConfig';

const propTypes = {
  data: PropTypes.shape({
    list: PropTypes.array,
    total: PropTypes.number,
  }).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  onIconClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onTableDataClick: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func.isRequired,
  permission: PropTypes.shape({
    read: PropTypes.bool,
    create: PropTypes.bool,
    update: PropTypes.bool,
    delete: PropTypes.bool,
  }),
  onToggleActiveStatus: PropTypes.func.isRequired,
};

const defaultProps = {
  onIconClick: () => null,
  onPageChange: () => null,
  permission: PERMISSION_OBJ,
};

const UserAccountView = ({ ...props }) => {
  const {
    data,
    permission,
    pagination,
    onPageChange,

    onTableRowClick,
    onTableDataClick,
    onToggleActiveStatus,
  } = props;
  return (
    <Fragment>
      <div className='table-wrap'>
        <table>
          {TableHeader()}
          <tbody>
            {data.list.map((user) =>
              TableBody({
                data: user,
                onTableRowClick,
                permission,
                onTableDataClick,
                onToggleActiveStatus,
              }),
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

UserAccountView.propTypes = propTypes;

UserAccountView.defaultProps = defaultProps;

const UserAccountViewWithErrorAndLoading = withLoading(UserAccountView);

export default UserAccountViewWithErrorAndLoading;
