import React from 'react';
import PropTypes from 'prop-types';
import { menuAction, tableHeaderWithCheckBox } from '../../../../../v4/components';
import * as dateUtil from '../../../../../utils/date';
import { CheckBox } from '../../../../../v4/components';
import { fixedFloat } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  subCampaignType: 'subCampaignType',
  budget: 'budget',
  target: 'target',
  startDate: 'startDate',
  endDate: 'endDate',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: false,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.subCampaignType,
    title: 'Sub Campaign Type',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.budget,
    title: 'Budget',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.target,
    title: 'Target',
    show: true,
    classNames: 'text-right target',
  },
  {
    id: 6,
    label: labelMappings.startDate,
    title: 'Start Date',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.endDate,
    title: 'End Date',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
};
const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission, update) => {
  const table = {};
  table[labelMappings.checkbox] = permission && (
    <td key={`campaigns-${data.id}-check`} onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.title] = <td key={`${data}-title`}>{data.title}</td>;
  table[labelMappings.subCampaignType] = <td key={`${data}-sub_campaign_type`}>{data.subCampaignType}</td>;
  table[labelMappings.budget] = (
    <td key={`${data}-budget`} className='text-right'>
      {data.subCampaignBudget.map((l, i) => (
        <div key={i}>
          <span>
            {l.dimension === 'PERCENT'
              ? `${fixedFloat(l.value)}%   (Rs ${fixedFloat(l.calculatedValue)})`
              : `Rs ${fixedFloat(l.value)}`}
          </span>
          <p>{l.budgetType}</p>
        </div>
      ))}
    </td>
  );
  table[labelMappings.target] = (
    <td key={`${data}-target`} className='text-right target'>
      {' '}
      {data.subCampaignTarget.map((l, i) => (
        <div key={i}>
          <span>{fixedFloat(l.value)}</span>
          <p>{l.dimension === 'VALUE' ? `${l.targetType} (In Value)` : `${l.targetType}`}</p>
        </div>
      ))}
    </td>
  );
  table[labelMappings.startDate] = <td key={`${data}-startDate`}>{dateUtil.presentor(data.startDate)}</td>;
  table[labelMappings.endDate] = <td key={`${data}-endDate`}>{dateUtil.presentor(data.endDate)}</td>;
  table[labelMappings.actionHolders] = (
    <td
      key={`${data}-actionHolders`}
      className={`text-right simple-popup-actions ${!update.status ? 'disabled' : null}`}
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, { update: true, delete: true })}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission,
  update,
  onTableRowClick,
}) => {
  const tableBody = returnTableBody(
    data,
    checkedList,
    onIconClick,
    secondaryCheckboxHandler,
    permission,
    update,
    onTableRowClick,
  );
  return (
    <tr
      key={data.campaignId}
      onClick={() => onTableRowClick(data.id, update)}
      className={!update.status && 'enable-cursor'}
    >
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export { TableHeader, TableBody };
