import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import { PanelStyled } from '../../common/configuration';
import { Pagination } from '../../../v4/components';
import CustomSelect from '../../../components/CustomSelect';
import { TableHeader } from './tableConfig';
import CTAccordionStyled from '../../../components/CollapsibleTable/CTAccordionStyled';
import Detail from './detail';
import { SELECT_OPTIONS } from '../../../v4/constants/pagination';

const propTypes = {
  data: dataProps.value,
  onIconClick: PropTypes.func,
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
  distributorList: PropTypes.arrayOf(Object).isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool,
  inputData: PropTypes.instanceOf(Object),
  onHeaderClick: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  ledgerDetail: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onIconClick: () => null,
  enableErrorDisplay: false,
  inputData: {},
  onHeaderClick: () => null,
  ledgerDetail: [],
};

const View = ({ ...props }) => {
  const {
    data,
    onPageChange,
    pagination,
    distributorList,
    onHeaderClick,
    onRowClick,
    ledgerDetail,
    onDropDownChange,
    enableErrorDisplay,
    inputData,
    handleTransferButton,
    closeAccordion,
  } = props;
  return (
    <>
      {distributorList?.length > 0 ? (
        <PanelStyled>
          <div className="width_300">
            <CustomSelect
              small
              enableValidation
              options={distributorList}
              className="custom-select"
              placeholder="Select Distributor"
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              enableErrorDisplay={enableErrorDisplay}
              onChange={event => onDropDownChange(event?.id)}
              value={distributorList?.filter(({ id }) => id === inputData) || []}
            />
          </div>
        </PanelStyled>
      ) : null}
      {data?.list?.length > 0 && (
        <CTAccordionStyled>
          <div
            className={`table-wrap ${distributorList.length === 0 ? 'no-select' : 'select'} ${
              data?.total > SELECT_OPTIONS[0].value - 1 ? 'pagination' : 'no-pagination'
            }`}
          >
            <table>
              {TableHeader({ onHeaderClick })}
              <Fragment>
                <Detail
                  data={data.list}
                  onRowClick={onRowClick}
                  ledgerDetail={ledgerDetail}
                  handleTransferButton={handleTransferButton}
                  closeAccordion={closeAccordion}
                />
              </Fragment>
            </table>
          </div>
        </CTAccordionStyled>
      )}
      {data.total > SELECT_OPTIONS[0].value - 1 && (
        <Pagination
          currentPage={pagination.page}
          limit={pagination.limit}
          totalItems={data.total}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
