import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const printLedgerDetailPdfContent = printData => {
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a4',
  });

  const convertPdfPxToBrowserBase = number => {
    // had to convert it because the px in print preview is different from standard browser px
    return 5.05 * (number / 9);
  };

  const PAGE_WIDTH = doc.internal.pageSize.getWidth();
  const OUTER_CORDINATE_X = 12;
  const OUTER_CORDINATE_Y = 12;
  const SECTION_WIDTH = PAGE_WIDTH - OUTER_CORDINATE_X * 2;
  const HEADER_RECTANGLE_HEIGHT = convertPdfPxToBrowserBase(192);
  const BORDER_COLOR = '#272833';

  const Primary_Font_Size = convertPdfPxToBrowserBase(18);
  const Secondary_Font_Size = convertPdfPxToBrowserBase(48);

  // global font setting
  doc.setFont('Helvetica', '');

  printData?.length > 0 &&
    printData?.forEach((item,index) => {
      let precedingTableEnding = doc?.lastAutoTable?.finalY ? doc?.lastAutoTable?.finalY + OUTER_CORDINATE_Y + 20 : 0;
      if (index > 0 && index % 5 === 0) {
        doc.addPage(); // Add a new page after the 5th array element
        precedingTableEnding = 0;
      }

      const addTopHeaderSection = () => {
        // top Header
        doc.setLineWidth(0.2);
        doc.setDrawColor(BORDER_COLOR);
        // square for QR
        doc.rect(
          OUTER_CORDINATE_X,
          OUTER_CORDINATE_Y + precedingTableEnding,
          HEADER_RECTANGLE_HEIGHT,
          HEADER_RECTANGLE_HEIGHT,
        );
        // Rectangle for table
        doc.rect(
          OUTER_CORDINATE_X + HEADER_RECTANGLE_HEIGHT,
          OUTER_CORDINATE_Y + precedingTableEnding,
          SECTION_WIDTH - HEADER_RECTANGLE_HEIGHT,
          HEADER_RECTANGLE_HEIGHT,
        );

        item?.qrValue &&
          doc.addImage(
            item?.qrValue,
            '',
            OUTER_CORDINATE_X + 2,
            OUTER_CORDINATE_Y + 2 + precedingTableEnding,
            HEADER_RECTANGLE_HEIGHT - 4,
            HEADER_RECTANGLE_HEIGHT - 4,
            item?.qrValue,
            'SLOW',
          );
      };

      // Table section
      autoTable(doc, {
        theme: 'plain',
        tableWidth: SECTION_WIDTH - HEADER_RECTANGLE_HEIGHT - convertPdfPxToBrowserBase(32),
        margin: {
          left: OUTER_CORDINATE_X + HEADER_RECTANGLE_HEIGHT + convertPdfPxToBrowserBase(16),
          right: OUTER_CORDINATE_X + convertPdfPxToBrowserBase(16),
        },
        showFoot: 'lastPage',
        styles: {
          valign: 'middle',
          textColor: 'black',
          fontSize: Secondary_Font_Size,
          halign: 'center',
          fontStyle: 'bold',
        },
        rowPageBreak: 'avoid',
        startY: OUTER_CORDINATE_Y + HEADER_RECTANGLE_HEIGHT / 2 - 43.2 / 2 + precedingTableEnding, //43.2 is the hight of the table
        headStyles: {
          fillColor: 'white',
          textColor: 'black',
          fontSize: Primary_Font_Size,
          minCellHeight: 15,
          halign: 'center',
          fontStyle: 'bold',
        },
        footStyles: { textColor: 'black', fillColor: false },
        head: [item?.warehouseLevelsData?.wareHouseLevelsHead],
        body: [item?.warehouseLevelsData?.wareHouseLevelsBody],

        didDrawPage(data) {
          // data.settings.margin.top adds top space to every page after first page
          data.settings.margin.top = HEADER_RECTANGLE_HEIGHT + OUTER_CORDINATE_Y;
          data.settings.margin.bottom = OUTER_CORDINATE_Y;
          addTopHeaderSection();
        },
      });
    });

  // adds pdf content to printer tab
  doc.autoPrint();

  const uri = doc.output('datauristring');

  return uri && uri;
};

const simplePrintPdfContent = printData => {
  const uri = printLedgerDetailPdfContent(printData);
  return uri;
};

export default simplePrintPdfContent;
