import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon,Button } from '../../../v4/components';
import ImageUpload from '../ImageUpload';
import { Thumbnail } from '../../../components/ImageViewer';
import ImgUploadStyled from './ImgUploadStyled';
import { MessageStyled } from '../../../components/Input/TextIndex';

const propTypes = {
  domain: PropTypes.string,
  /** enable error display mode in the form */
  enableErrorDisplay: PropTypes.bool,
  /** @default true
   * enable validation in the form
   */
  enableValidation: PropTypes.bool,
  /** @default ''
   * error Message to display.
   */
  errorMessage: PropTypes.string,
  /** @default ''
   * placeholder text for the select value
   */
  images: PropTypes.arrayOf(Object),
  getImages: PropTypes.func.isRequired,
};
const defaultProps = {
  domain: '',
  enableErrorDisplay: false,
  enableValidation: true,
  images: [],
  errorMessage: 'Image is Required',
};

export class Image extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      images: [
        {
          image: '',
          thumbnail: '',
          created_at: '',
        },
        ...(props.images || []),
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { images } = this.state;
    if (prevState.images.length !== images.length) {
      const { getImages } = this.props;
      getImages(this.exportData());
    }
  }

  toggleShow = (state = false) => this.setState({ show: state });

  handleSubmit = url => {
    const { images } = this.state;
    this.setState({
      images: [...images, { image: `${url}`, thumbnail: `${url}` }],
    });
  };

  handleRemoveImage = url => {
    const { images } = this.state;
    this.setState({
      images: images.filter(img => img.thumbnail !== url),
    });
  };

  exportData = () => {
    const { images } = this.state;
    return images.filter(data => data.image !== '');
  };

  getValidState = () => {
    const { images } = this.state;
    return images.length > 1;
  };

  render() {
    const { show, images } = this.state;
    const { domain, errorMessage, enableErrorDisplay, enableValidation, checkPromotionBannerImage } = this.props;
    const valid = this.getValidState();
    const displayError = enableValidation && enableErrorDisplay && !valid;
    return (
      <ImgUploadStyled>
        {show && (
          <ImageUpload
            onSubmit={(type, url) => {
              this.handleSubmit(url);
              this.toggleShow(false);
            }}
            onClose={this.toggleShow}
            domain={domain}
            checkPromotionBannerImage={checkPromotionBannerImage}
          />
        )}
        {
          <div className="upload-img-add">
            <Button secondary big iconName="plus" onClick={() => this.toggleShow(true)} />
            {images.map(image => (
              <>
                {image.thumbnail && (
                  <div className="attachment-item">
                    <span className="del-icon" onClick={() => this.handleRemoveImage(image.thumbnail)}>
                      x
                    </span>
                    <Thumbnail image={image.thumbnail} title="dummy" className="" />
                  </div>
                )}
              </>
            ))}
          </div>
        }
        {displayError && (
          <MessageStyled className="form-error">
            <span className="error-message">
              {<Icon iconName="exclamation-full" />}
              {errorMessage}
            </span>
          </MessageStyled>
        )}
      </ImgUploadStyled>
    );
  }
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
