import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { title, breadCrumbConfig, formMapper, crudSuccess as crudRequestConfig, getPayloadIn } from './config';
import DetailsStyled from './DetailsStyled';
import history from '../../../../utils/history';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import ClientDetails from './ClientDetails';
import DeliveryDetails from './DeliveryDetails';
import { has } from '../../../../utils/objectPrototypes';
import { clone } from '../../../../utils/arrayProcessor';
import * as queryService from '../../../base/query.service';
import { refGenerator } from '../../../../utils/refGenerator';
import withAlert from '../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { PanelCard, BreadCrumb, Icon, Button } from '../../../../v4/components';
import { ORDER_FULFILMENT } from '../../../../data/enums/Route';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { inputChange, dropdownChange } from '../../../../utils/formHandlers';
import Product from './Products';
import { getSubdUserStatusAndId } from '../../../common/HelperFunctions';
import { dataX } from './data';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class OrdersDetails extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const { id = 0, exist = false } = getSubdUserStatusAndId();
    this.distributorId = id;
    this.subDUser = exist;
    queryService.resetBaseQueryParameters();
    this.state = {
      data: formMapper({}, this.distributorId),
      orderId: has.call(props.match.params, 'id') ? parseInt(props?.match?.params?.id, 10) : 0,
      update: {
        type: EVENT_OPERATION.CREATE,
        status: true,
      },
      enableErrorDisplay: false,
      isLoading: false,
      backUpData: formMapper({}),
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      outletList: [],
      distributorList: [],
      distributorServices: {
        billing: {
          status: false,
          url: '',
          versionCode: null,
        },
        // LOGISTICS: { }
      },
      distributor: {
        id: null,
        title: '',
        townList: [],
      },
      skus: [],
    };
    this.permission = { update: true, create: true };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createWebOrder,
    };
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator([
      'client',
    ]);
  }

  componentDidMount() {
    const { orderId, update, outletList } = this.state;
    this.getDistributorsList();
    this.getSKUs();
    if (orderId) {
      update.type = EVENT_OPERATION.UPDATE;
      update.status = false;
      this.setState(
        {
          orderId,
          update,
        }
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { orderId, outletList, skus } = this.state;
    if (outletList !== prevState.outletList && orderId) {
      this.getOrderDetails(orderId, skus)
    }
  }
  getOrderDetails = id => {
    const { queryParameters, outletList, skus } = this.state;
    this.setState(
      {
        data: getPayloadIn(dataX, outletList, skus),
        // backUpData: clone(dataDetails),
      });
    // const { getCampaignDetail, displayAlert } = this.props;
    // getCampaignDetail(
    //   {
    //     filter: {
    //       filters: [
    //         {
    //           column: 'id',
    //           value: [id.toString()],
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     handleSuccess: response => {
    //       const dataDetails = dataS;
    //       this.setState(
    //         {
    //           data: dataDetails,
    //           backUpData: clone(dataDetails),
    //         },
    //         () => {
    //           this.setDate();
    //         },
    //       );
    //     },
    //     handleError: error => {
    //       displayAlert(ALERT_TYPE.DANGER, error);
    //     },
    //   },
    // );
  };
  setDistributorServices = distributor => {
    const { distributorServices, distributor: stateDistributor } = this.state;
    stateDistributor.townList = distributor?.Towns?.map?.(town => town?.id);
    stateDistributor.id = distributor?.id;
    stateDistributor.title = distributor?.title;
    this.setState(
      {
        distributorServices: distributor?.servicesUsed || distributorServices,
        distributorBatchFlag: distributor.batchImplementation,
        distributor: stateDistributor,
      },
      () => {
        this.getOutletList();
      },
    );
  };
  getOutletList = () => {
    const { getOutlets, displayAlert } = this.props;

    const { distributor } = this.state;

    if (distributor.townList.length > 0) {
      const filter = {
        filters: [
          {
            column: 'town_id',
            value: distributor.townList.map(String),
          },
        ],
      };

      getOutlets(
        {
          filter,
        },
        {
          handleSuccess: response => {
            this.setState({ outletList: response.data.retailOutlets.rows });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };
  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          const distributorList = response.data.distributors ? response.data.distributors.rows || [] : [];
          this.setState(
            {
              distributorList,
            },
            () => {
              if (this.subDUser && distributorList.length > 0) {
                this.setDistributorServices(distributorList[0]);
              }
            },
          );
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus(
      {},
      {
        handleSuccess: response => {
          this.setState({ skus: response.data.skus.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };
  getDetailsValidationStatus = () => !Object.values?.(this.formReference)?.find(item => item?.getValidState() === false);

  responseProcessor = () => {
    const onAPIRequestSuccess = type => response => {
      const { displayAlert } = this.props;
      const { update, orderId } = this.state;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage, response);
      if (type === 'CREATE') {
        setTimeout(() => {
          history.push(`/${ORDER_FULFILMENT}`);
          this.setState({ isLoading: false })
        }, 2000);
      }
      if (type === 'UPDATE') {
        update.status = false;
        this.setState({ update });
        this.getOrderDetail(orderId);
        this.setState({ orderId });
      }
    };
    return onAPIRequestSuccess;
  };

  onAPIRequestFailure = error => {
    const { displayAlert, isLoading } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.setState({ isLoading: false })
  };

  getActionType = () => {
    const { update } = this.state;
    return update.type !== EVENT_OPERATION.CREATE
      ? update.type === EVENT_OPERATION.UPDATE
        ? EVENT_OPERATION.UPDATE
        : EVENT_OPERATION.APPROVE
      : EVENT_OPERATION.CREATE;
  };

  createOrder = () => {
    const { data } = this.state;
    const updatedData = clone(data);
    const type = this.getActionType();
    this.onFormSubmit(type, updatedData);
    this.setState({ data: updatedData, isLoading: true });
  };

  handleButtonSubmit = () => {
    const valid = this.getValidationStatus();
    if (valid) {
      this.createOrder();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };


  getValidationStatus = () => {
    const { data } = this.state;
    const { displayAlert } = this.props;
    const detailsStatus = this.getDetailsValidationStatus();
    if (data?.productArr?.length === 0) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, "Atleast one product is required");
      return;
    }
    return detailsStatus;
  };

  handleButtonCancel = () => {
    history.push(`/${ORDER_FULFILMENT}`);
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedData = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedData });
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedDetails = dropdownChange(data, parameterRef, value);
    this.setState({ data: updatedDetails }, () => {
      callBack(value);
      if (parameterRef[0] === 'Distributor') {
        this.setDistributorServices(value);
      }
    });
  };

  getHeader = () => {
    const { update } = this.state;
    const crudMode = update.status ? EVENT_OPERATION_MAPPER[update.type].toLowerCase() : '';
    return (
      <>
        <span>{crudMode} </span>
        {title}
      </>
    );
  };
  render() {
    const {
      update,
      data,
      enableErrorDisplay,
      orderId,
      skus,
      outletList,
      distributorList,
      distributor,
      isLoading,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    const header = this.getHeader();
    return (
      <DetailsStyled>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{header}</h2>
              <div className="flex m-0">
                {update.status && (
                  <div>
                    <Button small secondary disabled={serverResponseWaiting} onClick={() => this.handleButtonCancel()}>
                      <span>Cancel</span>
                    </Button>
                    <Button small primary disabled={serverResponseWaiting || isLoading} onClick={() => this.handleButtonSubmit()}>
                      <span>Save</span>
                    </Button>
                  </div>
                )}
                {!update.status && this.permission.update && (
                  <Button
                    secondary
                    iconBtnSmall
                    disabled={update.type === EVENT_OPERATION.UPDATE && update.status}
                    onClick={() => {
                      this.setState({
                        update: {
                          type: EVENT_OPERATION.UPDATE,
                          status: true,
                        },
                      });
                    }}
                    className="ml-16"
                  >
                    <Icon iconName="pencil" />
                  </Button>
                )}
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className={`section-content pad-48 ${update.type === 'UPDATE' ? 'update' : 'create'}`}>
          <PanelCard cardTitle="Client Details" className={!update.status ? 'disabled' : null}>
            <ClientDetails
              update={update}
              loading={serverResponseWaiting}
              enableErrorDisplay={enableErrorDisplay}
              data={data}
              outletList={outletList}
              handleInputChange={this.handleInputChange}
              handleDropDownChange={this.handleDropDownChange}
              dropDownCallBack={this.setDate}
              refsObj={this.formReference}
              distributorList={distributorList}
              subDUser={this.subDUser}
            />
          </PanelCard>
          <PanelCard cardTitle="Delivery Details" className={!update.status ? 'disabled' : null}>
            <DeliveryDetails
              update={update}
              loading={serverResponseWaiting}
              enableErrorDisplay={enableErrorDisplay}
              data={data}
              handleInputChange={this.handleInputChange}
              handleDropDownChange={this.handleDropDownChange}
              dropDownCallBack={this.setDate}
              refsObj={this.formReference}
            />
          </PanelCard>
          <PanelCard cardTitle="Products" className="product-table">
            <Product
              data={data}
              skus={skus}
              orderId={orderId}
              update={update}
              getStatus={childMethod => (this.getTableValidationStatus = childMethod)}
              getDetails={childMethod => (this.getTableDetails = childMethod)}
              enableErrorDisplay={enableErrorDisplay}
            />
          </PanelCard>
        </div>
      </DetailsStyled>
    );
  }
}
OrdersDetails.propTypes = propTypes;
OrdersDetails.defaultProps = defaultProps;
export default withAlert()(OrdersDetails);
