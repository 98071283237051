import {
  marker1,
  marker2,
  marker3,
  marker4,
  marker5,
  marker6,
  marker7,
  marker8,
  marker9,
  marker10,
  startMarker,
  marker12,
  marker13,
  marker14,
  marker15,
  marker16,
  marker17,
  lastMarker,
  startFlag,
  endFlag,
  circleRedMarker,
} from './Images';

const iconUrls = {
  markerColor: [
    marker1,
    marker2,
    marker3,
    marker4,
    marker5,
    marker6,
    marker7,
    marker8,
    marker9,
    marker10,
    marker12,
    marker13,
    marker14,
    marker15,
    marker16,
    marker17,
  ],
  startingPoint: startMarker,
  lastMarker,
  startFlag,
  endFlag,
  circleRedMarker,
};

export default iconUrls;
