import React from 'react'
import styled from 'styled-components'
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { TableHeader, TableBody } from './tableConfig';

const CallAnalysis = ({ orderSummary, summary }) => {
  return (
    <CallAnalysisContainer>
      <section className='target-order-summary row'>
        <div className='summary-item col-xs-12 col-sm-6 col-md-2 col-md-lg-1'>
          <label>Target</label>
          <div className='summary-amount'>Rs. {fixedFloatAndCommas(summary?.callTarget) || 0}</div>
        </div>
        <div className='summary-item col-xs-12 col-sm-6 col-md-2 col-md-lg-1'>
          <label>Order Received</label>
          <div className='summary-amount'>Rs. {fixedFloatAndCommas(summary?.callOrderValue?.netAmount) || 0}</div>
        </div>
      </section>
      <div className='tager-order-summary-details'>
        <table>
          {TableHeader()}
          <tbody>
            {orderSummary.length > 0 && orderSummary.map((skuCategory) => (
              skuCategory.skus.length > 0 ?
                <React.Fragment key={skuCategory.title}>
                  <tr>
                    <td id='sku-title' colSpan={4}>{skuCategory.title}</td>
                  </tr>
                  {skuCategory.skus.map((sku) => TableBody({
                    data: sku,
                  }))}
                </React.Fragment>
                : null
            ))}
          </tbody>
        </table>
      </div>
    </CallAnalysisContainer>
  )
}

const CallAnalysisContainer = styled.div`
  .target-order-summary {
    gap: 1rem;
    padding:24px;
    &.row {
      margin: 0;
    }
  } 

  .summary-item {
    border: 1px solid #E7E7ED;
    border-radius: 8px;
    padding: .5rem 1rem;

    label {
      text-transform: capitalize;
      display: inline-block;
      color: #6B6C7E;
      font-size: 12px;
    }

    .summary-amount {
      font-weight: 500;
      font-size: 16px;
    }
  }
  .tager-order-summary-details{
  table {
    border: none !important;
    tbody,
    thead{
      tr{
      td,
      td#sku-title,
      th{
        &:first-child{
          padding-left:24px !important;
        }
        &:last-child{
          padding-right:24px !important;
        }
      }
      }
    }
    tbody{
      tr > td > .form-input {
        input {
          max-width: 6rem;
          padding: 8px;
          height: 2rem;
        }
      }

     tr > td.remove > span > svg.icon {
        color: #DA1414
      }
    }
  }
  }

  table th:first-child, table td {
    border-left: none !important;
    border-right: none !important;
  }

  #sku-title {
    padding: 2rem 0 0 0 !important;
    font-size: 16px;
    font-weight: 500;
    border: none !important;
  } 
`;

export default CallAnalysis
