import PropTypes from 'prop-types';
import { tableHeaderWithCheckBox } from '../../../../../v4/components';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';

const labelMappings = {
  distributor: 'distributor',
  sku: 'sku',
  orderQuantity: 'orderQuantity',
  inStock: 'inStock',
  gap: 'gap',
};

const labelConfig = [
  {
    id: 3,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.orderQuantity,
    title: 'Order Quantity',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.inStock,
    title: 'In Stock',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 6,
    label: labelMappings.gap,
    title: 'Gap',
    show: true,
    classNames: 'text-right',
  },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.distributor] = <td key={`${data.id}-distributor`}>{data.distributor}</td>;
  table[labelMappings.sku] = <td key={`${data.id}-sku`}>{data.sku}</td>;
  table[labelMappings.orderQuantity] = (
    <td key={`${data.id}-orderQuantity`} className="text-right">
      {data.orderQuantity}
    </td>
  );
  table[labelMappings.inStock] = (
    <td key={`${data.id}-inStock`} className="text-right">
      {data.inStock}
    </td>
  );
  table[labelMappings.gap] = (
    <td key={`${data.id}-gap`} className="text-right">
      {data.gap}
    </td>
  );

  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission, onViewClick }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr
      key={data.id}
      style={{ cursor: 'pointer' }}
      onClick={() => onViewClick({ type: EVENT_OPERATION.READ, element: data })}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
