import { compose } from 'recompose';
import FocusedSku from './FocusedSku';
import { query } from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const composedFocusedSku = compose(
  withRequestTracker({
    query,
  }),
)(FocusedSku);

export default composedFocusedSku;
