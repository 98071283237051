import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../../base/TableHeader';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { COVERAGE_TYPE } from '../../../../data/enums/GraphQL';
import { SKU_COVERAGE } from '../../../../data/enums/Route';

const labelMappings = {
  channel: 'name',
  category: 'name',
  subd: 'name',
  route: 'name',
  coverage: 'coverage',
  active_coverage: 'active_coverage',
  sku_coverage: 'sku_coverage',
  coverage_percent: 'coverage_percent',
};
const skuDistributionLink = {
  id: 1,
  title: 'SKU Coverage',
  path: `/${SKU_COVERAGE}`,
  active: true,
};

const generalHeaderConfig = [
  {
    id: 3,
    label: labelMappings.coverage,
    title: 'Coverage Outlet',
    show: true,
    className: 'text-right',
  },
  {
    id: 4,
    label: labelMappings.active_coverage,
    title: 'Active Outlet',
    show: true,
    className: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.sku_coverage,
    title: 'Distribution',
    show: true,
    className: 'text-right',
  },
  {
    id: 6,
    label: labelMappings.coverage_percent,
    title: 'Coverage %',
    className: 'text-right',
    show: true,
  },
];

const viewBasedHeaderConfig = {
  [COVERAGE_TYPE.CHANNEL]: [
    {
      id: 1,
      label: labelMappings.channel,
      title: 'Channel',
      show: true,
    },
    ...generalHeaderConfig,
  ],
  [COVERAGE_TYPE.CATEGORY]: [
    {
      id: 1,
      label: labelMappings.category,
      title: 'Category',
      show: true,
    },
    {
      id: 2,
      label: 'parent_name',
      title: 'Channel',
      show: true,
    },
    ...generalHeaderConfig,
  ],
  [COVERAGE_TYPE.SUB_D]: [
    {
      id: 1,
      label: labelMappings.subd,
      title: 'Sub D',
      show: true,
    },
    ...generalHeaderConfig,
  ],
  [COVERAGE_TYPE.ROUTE]: [
    {
      id: 1,
      label: labelMappings.route,
      title: 'Route',
      show: true,
    },
    {
      id: 2,
      label: 'parent_name',
      title: 'Sub D',
      show: true,
    },
    ...generalHeaderConfig,
  ],
};

const getTableTd = (data, labelConfig) => ({
  name: (
    <td key={`${labelConfig.id}-name`} className={labelConfig.className}>
      {data[labelConfig.label]}
    </td>
  ),
  parent_name: (
    <td key={`${labelConfig.id}-parent-name`} className={labelConfig.className}>
      {data[labelConfig.label]}
    </td>
  ),
  coverage: (
    <td key={`${labelConfig.id}-coverage`} className={labelConfig.className}>
      {data[labelConfig.label]}
    </td>
  ),
  active_coverage: (
    <td key={`${labelConfig.id}-active-coverage`} className={labelConfig.className}>
      {data[labelConfig.label]}
    </td>
  ),
  sku_coverage: (
    <td key={`${labelConfig.id}-sku-coverage`} className={labelConfig.className}>
      {data[labelConfig.label]}
    </td>
  ),
  coverage_percent: (
    <td key={`${labelConfig.id}-coverage-percentage`} className={labelConfig.className}>
      {`${data[labelConfig.label] ? `${fixedFloatAndCommas(data[labelConfig.label])} % ` : 0}`}
    </td>
  ),
});

const returnTableBody = (data, labelConfig = []) =>
  labelConfig.map((item) => {
    const tableRow = getTableTd(data, item);

    return item.show && tableRow[item.label];
  });

const TableBody = ({ data, onRowClick, viewType }) => {
  const tableBody = returnTableBody(data, viewBasedHeaderConfig[viewType]);

  return <tr onClick={() => onRowClick(viewType, data)}>{tableBody.map((row) => row)}</tr>;
};

const TableHeaderConfig = ({ viewType }) => <TableHeader headerDetails={viewBasedHeaderConfig[viewType]} />;

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  viewType: PropTypes.string,
  onRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  viewType: COVERAGE_TYPE.CHANNEL,
};

TableHeaderConfig.propTypes = {
  viewType: PropTypes.string,
};

TableHeaderConfig.defaultProps = {
  viewType: COVERAGE_TYPE.CHANNEL,
};

export {
  TableHeaderConfig as TableHeader,
  TableBody,
  returnTableBody,
  getTableTd,
  generalHeaderConfig,
  labelMappings,
  skuDistributionLink,
  viewBasedHeaderConfig,
};
