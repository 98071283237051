import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const labelMappings = {
  brand: 'brand',
  sku: 'sku',
  upc: 'upc',
  reorderMOQ: 'reorderMOQ',
  currentStock: 'currentStock',
  sit: 'sit',
  pso: 'pso',
  doh: 'doh',
  avg_sales: 'avg_sales',
  total_stock: 'total_stock',
  order_pcs: 'order_pcs',
  order_cases: 'order_cases',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.upc,
    title: 'UPC',
    show: true,
  },

  {
    id: 4,
    label: labelMappings.reorderMOQ,
    title: 'Re-order MOQ',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.currentStock,
    title: 'Current Stock',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 6,
    label: labelMappings.sit,
    title: 'SIT (Stock In Transit)',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 7,
    label: labelMappings.pso,
    title: 'PSO (Pending Sales Orders)',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 8,
    label: labelMappings.avg_sales,
    title: 'Average P3M Sales',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 9,
    label: labelMappings.doh,
    title: 'DOH (Days Of Holding)',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 10,
    label: labelMappings.total_stock,
    title: 'Total Stock',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 11,
    label: labelMappings.order_pcs,
    title: 'Order pcs',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 12,
    label: labelMappings.order_cases,
    title: 'Order Cases',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[labelMappings.brand] = <td key={`${data.id}-brand`}>{data.brand}</td>;
  table[labelMappings.sku] = <td key={`${data.id}-sku`}>{data.sku}</td>;
  table[labelMappings.upc] = (
    <td key={`${data.id}-upc`} className='text-right'>
      {fixedFloatAndCommas(data.upc)}
    </td>
  );
  table[labelMappings.reorderMOQ] = (
    <td key={`${data.id}-reorderMOQ`} className='text-right'>
      {fixedFloatAndCommas(data.reorderMOQ)}
    </td>
  );
  table[labelMappings.currentStock] = (
    <td key={`${data.id}-currentStock`} className='text-right'>
      {fixedFloatAndCommas(data.currentStock)}
    </td>
  );
  table[labelMappings.sit] = (
    <td key={`${data.id}-sit`} className='text-right'>
      {fixedFloatAndCommas(data.sit)}
    </td>
  );
  table[labelMappings.pso] = (
    <td key={`${data.id}-pso`} className='text-right'>
      {fixedFloatAndCommas(data.pso)}
    </td>
  );
  table[labelMappings.avg_sales] = (
    <td key={`${data.id}-avg_sales`} className='text-right'>
      {fixedFloatAndCommas(data.avg_sales)}
    </td>
  );
  table[labelMappings.doh] = (
    <td key={`${data.id}-doh`} className='text-right'>
      {fixedFloatAndCommas(data.doh)}
    </td>
  );
  table[labelMappings.total_stock] = (
    <td key={`${data.id}-totalStock`} className='text-right'>
      {fixedFloatAndCommas(data.totalStock)}
    </td>
  );
  table[labelMappings.order_pcs] = (
    <td key={`${data.id}-orderPcs`} className='text-right'>
      {fixedFloatAndCommas(data.orderPcs)}
    </td>
  );
  table[labelMappings.order_cases] = (
    <td key={`${data.id}-orderCases`} className='text-right'>
      {fixedFloatAndCommas(data.orderCases)}
    </td>
  );

  return table;
};

const TableBody = ({ data }) => {
  // Expanding/Dragging and collapsing table body.
  // color based upon table body
  // tooltip if it is sorted.
  // active className
  const tableBody = returnTableBody(data);

  return (
    <tr key={`userCluster-${data.id}`}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export { TableHeaderConfig as TableHeader, TableBody };
