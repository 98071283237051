import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const GET_LOCATION_HIERARCHY = gql`
  query {
    getLocationHierarchy {
      rows {
        id
        title
        parentId
        parent {
          id
          title
          parentId
        }
        active
      }
    }
  }
`;

const GET_LOCATION_HIERARCHY_DETAILS = gql`
  query getLocationHierarchyDetails(
    $locationHierarchyId: Int
    $parentId: Int
    $limit: Int
    $offset: Int
    $filter: FilterInput
  ) {
    getLocationHierarchyDetails(
      locationHierarchyId: $locationHierarchyId
      parentId: $parentId
      limit: $limit
      offset: $offset
      filter: $filter
    ) {
      rows {
        id
        title
        parentId
        locationHierarchyId
        active
        LocationHierarchy {
          id
          title
          parentId
        }
        parent {
          id
          title
          parentId
          active
        }
      }
      count
    }
  }
`;
const EDIT_LOCATION_HIERARCHY_DETAIL = graphql(
  gql`
    mutation ($id: Int!, $input: LocationHierarchyDetailInput!) {
      editLocationHierarchyDetail(id: $id, input: $input) {
        title
        parentId
        locationHierarchyId
        images {
          image
          thumbnail
          created_at
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      editLocationHierarchyDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_LOCATION_HIERARCHY_DETAIL = graphql(
  gql`
    mutation ($input: LocationHierarchyDetailInput!) {
      createLocationHierarchyDetail(input: $input) {
        title
        parentId
        locationHierarchyId
        images {
          image
          thumbnail
          created_at
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createLocationHierarchyDetail: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getLocationHierarchy: GET_LOCATION_HIERARCHY,
  getLocationHierarchyDetails: GET_LOCATION_HIERARCHY_DETAILS,
};

const mutation = {
  createLocationHierarchyDetail: CREATE_LOCATION_HIERARCHY_DETAIL,
  editLocationHierarchyDetail: EDIT_LOCATION_HIERARCHY_DETAIL,
  toggleState: TOGGLE_STATE,
};

export {
  query, mutation, CREATE_LOCATION_HIERARCHY_DETAIL, EDIT_LOCATION_HIERARCHY_DETAIL, GET_LOCATION_HIERARCHY
};
