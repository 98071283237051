import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onImageClick: PropTypes.func,
};

const defaultProps = {
  onImageClick: () => null,
};

const Thumbnail = ({ image, className, title, onImageClick }) => (
  <img onClick={() => onImageClick()} src={image} alt={title} className={`${className} thumbnail-img`} />
);

Thumbnail.propTypes = propTypes;
Thumbnail.defaultProps = defaultProps;

export default Thumbnail;
