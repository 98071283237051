import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const GET_TARGETS_DETAIL = gql`
query GetAutomatedSbdTargetDetails($getAutomatedSbdTargetDetailsId: Int) {
  getAutomatedSbdTargetDetails(id: $getAutomatedSbdTargetDetailsId) {
    id
    month
    monthName
    national_target
    year
    automated_sbd_target_details {
      category {
        id
        title
      }
      id
      outlet_target
    }
  }
}
`;

export const CREATE_TARGET_DISTRIBUTION = graphql(
  gql`
  mutation CreateAutomatedSbdTarget($automatedSalesTargetId: Int!) {
    createAutomatedSbdTarget(automatedSalesTargetId: $automatedSalesTargetId) {
      id
      month
      monthName
      national_target
      year
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      createAutomatedSbdTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

export const UPDATE_TARGET_DISTRIBUTION = graphql(
  gql`
  mutation UpdateAutomatedSbdTarget($input: automatedSbdTargetInput!) {
    updateAutomatedSbdTarget(input: $input) {
      id
      monthName
      month
      national_target
      year
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      updateAutomatedSbdTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

export const CALCULATE_AUTOMATED_SALES_TARGET = graphql(
  gql`
  mutation CalculateAutomatedSbdTarget($input: automatedSbdTargetInput!) {
    calculateAutomatedSbdTarget(input: $input) {
      automatedSbdTargetId
      detail {
        category
        categoryId
        outletTarget
      }
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      calculateAutomatedSbdTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getAutomatedSbdTargetDetails: GET_TARGETS_DETAIL,
};

const mutation = {
  createAutomatedSbdTarget: CREATE_TARGET_DISTRIBUTION,
  updateAutomatedSbdTarget: UPDATE_TARGET_DISTRIBUTION,
  calculateAutomatedSbdTarget: CALCULATE_AUTOMATED_SALES_TARGET,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, DOWNLOAD_REPORT
};
