import classNames from 'classnames';
import BadgeStyled from './BadgeStyled';

interface BadgeProps {
  children: JSX.Element | string | number;
  label: string | number;
  className: string;
  primary: boolean;
  secondary: boolean;
  success: boolean;
  warning: boolean;
  light: boolean;
  info: boolean;
  error: boolean;
  small: boolean;
}

const Badge = ({
  children,
  className,
  primary,
  secondary,
  success,
  warning,
  light,
  info,
  error,
  small,
  label,
}: BadgeProps) => {
  const classes = classNames(
    {
      primary,
      secondary,
      success,
      warning,
      light,
      info,
      error,
      small,
    },
    className,
  );
  return (
    <BadgeStyled className={classes}>
      <span>{label}</span>
      {children}
    </BadgeStyled>
  );
};

Badge.defaultProps = {
  children: undefined,
  className: '',
  primary: false,
  secondary: false,
  success: false,
  warning: false,
  light: false,
  info: false,
  error: false,
  small: false,
};

export default Badge;
