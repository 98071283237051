import styled from 'styled-components';

export const CallPlanContainer = styled.main`
  display:flex;
  overflow: hidden;
  flex-wrap: wrap;
  border-top: 1px solid #e7e7ed;
  width: calc(100vw - 64px);
  height:unset !important;
  .create-ui-inner{
    flex:unset;
    padding: 0 10px 0 45px;
    background-color:#FFFFFF;
    flex-basis: 360px;
    height: calc(100vh - 116px);
    overflow: auto;
    box-sizing: content-box;
    }
    &>div:last-child{
      flex-direction: column;
    display: flex;
    width: calc(100vw - 536px - 64px);
    height:unset;
    border-left:0;
    .search-wrapper  + .table-wrap{
      height: calc(100vh - 314px);
    }
    .react-tabs__tab-list{
      border-left: 1px solid #E7E7ED;
      padding: 12px 24px 12px 24px;
    }
    .react-tabs .tab-container{
      max-height: unset;
      overflow-y: hidden;
    }
    .table-wrap{
      overflow:auto;
      height:calc(100vh - 250px);
      border-left: 1px solid #E7E7ED;
      table{
        border:0;
        tbody{
        th, td{
          &:first-child{
          border-left:0;
          }
        }
        }
      }
    }
    .tab-container{
      border-left: 1px solid #E7E7ED;
      .table-wrap{
        border-left:0;
      }
    }
    &>section{
   
    .drawer-container{
      position: fixed;
      /* z-index: 1040; */
      bottom: 0;
      border: 1px solid #E7E7ED;
       border-bottom: 0;
        -webkit-transition: top 0.5s ease-in-out;
        transition: top 0.5s ease-in-out;
        background-color: #FFFFFF;
        width:unset;
        width: calc(100vw - 477px);
        overflow-x: auto;
        overflow-y:hidden;
        }
      }
    }
`

export const OutletDetailsContainer = styled.div`
  flex: 1;
  overflow-y: scroll;


  .outlet-details-container > div {
    border-top: 1px solid rgb(231, 231, 237); 
    padding: 2rem 0;
  }

  .outlet-details-container {
    .billing-notes {
      margin-top: 0;
      border-top: none;
      margin-bottom: .5rem;
      padding-top: 0;

      h3 {
        margin-bottom: 1rem;
      }

      textarea {
        width: 100%;
        border-radius: 4px;
        padding: 1rem;
        font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        resize:none;
        color:#6B6C7E;
        font-size:14px;
        background-color:#F7F8F9;
        :focus{
          outline:none;
        }
        &:focus-visible {
          border: 1px solid #0B5FFF !important;
          background-color: #F7F8F9 !important;
          outline: none;
        }
      }
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    color: #6b6c7e;
    margin-bottom: 1.5rem;
  }

  .with-border {
    border: 1px solid #E7E7ED;
    margin-bottom: .5rem;
    border-radius: 8px;
    padding: 1rem;
  }

  .outlet-name {
    padding: 1rem 0;
    margin-bottom: 8px;
    line-height: 24px;
    font-size: 1.3rem;

    .icon {
      margin-right: 0.5rem;
      height: 24px;
      width: 24px;
    }
  }

  .skus-nav {
    padding: 2rem 0;
    border-top: 1px solid rgb(231, 231, 237); 

    button{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #0B5FFF;
      margin: 1rem 0;

      &:hover, &:focus {
        color: #fff !important;

        .icon{
          color: #fff !important;
        }
      }

      .icon {
        color: #0B5FFF;
      }
    }
  }
`

export const SKUDetailsContainer = styled.div`
  border-left: 1px solid rgb(231, 231, 237); 
  flex: 3;
  height: calc(100% - 4rem);

  .react-tabs {
    height: 100%;

    .tab-container{
      max-height: calc(100% - 3.5rem);  
      overflow-y: auto;
    }
  }
`

export const DialogContainer = styled.div`
  .modal-inner {
    max-width: 866px;
    .no-order-btn{
      border: 1px solid #0B5FFF;
      color: #0B5FFF;
    }
    .evolve-dialog__body{
      max-height:65vh;
      overflow:auto;
    }
  }
  
  .add_order, .promotion, .edit{
      .evolve-dialog__body{
        height:475px;
        overflow-y: auto;
      }
    }
    .delete, .delete_promotion{
      max-width: 395px;
    }
`
