import React from 'react';
import PropTypes from 'prop-types';
import withAlert from '../../../../../utils/composition/withAlert';
import { DialogFormWrapper } from '../../../../common';
import SKUGroupTypeTableList from '../components/SkuGroupTypeTableList/SkuGroupTypeTableList';
import { formConfig, breadCrumbConfig } from '../config';
import { PanelStyled } from '../../../../common/configuration';
import PageHeader from '../../../../base/PageHeader';
import { EVENT_OPERATION } from '../../../../../v4/constants/EventOperation';
import SKUGroupTypeCreateUpdateDialog from '../components/SkuGroupTypeCreateUpdateDialog/SkuGroupTypeCreateUpdateDialog';
import confirmationGenerator from '../../../../../v4/components/DialogConfirmation/DialogConfirmation';
import useSKUGroupType from '../Controller/useSkuGroupType.controller';
import { SKUGroupTypeStyled } from './SkuGroupTypeStyled';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const SKUGroupType = props => {
  const {
    dialog,
    data,
    dialogData,
    serverResponseWaiting,
    permission,
    queryParameters,
    filterMenu,
    basePaginationService,
    toggleState,
    lineOptions,
    groupMasterTitle,
    enableRouteSegmentation,
    handleDialogSubmit,
    resetDialog,
    handleInputChange,
    handleIconClick,
    handleTableRowClick,
    handleBulkDelete,
    handleDropDownChange,
    generateFormTitle,
  } = useSKUGroupType(props);

  return (
    <SKUGroupTypeStyled>
      {dialog.type && (
        <DialogFormWrapper
          formConfig={formConfig[dialog.type]}
          formTitle={generateFormTitle(dialog.type, dialog.element)}
          dialogElement={dialog.element}
          onDialogSubmit={handleDialogSubmit}
          onDialogCancel={resetDialog}
          type={dialog.type}
          disableDialogClose
          renderDialog={({ refsObj, enableErrorDisplay }) => (
            <>
              {dialog.type === EVENT_OPERATION.DELETE &&
                confirmationGenerator(EVENT_OPERATION.DELETE, null, data.title)}
              {(dialog.type === EVENT_OPERATION.CREATE ||
                dialog.type === EVENT_OPERATION.UPDATE ||
                dialog.type == EVENT_OPERATION.REPLICATE) && (
                <SKUGroupTypeCreateUpdateDialog
                  data={dialogData}
                  refsObj={refsObj}
                  loading={serverResponseWaiting}
                  handleInputChange={handleInputChange}
                  enableErrorDisplay={enableErrorDisplay}
                  lineOptions={lineOptions}
                  handleDropDownChange={handleDropDownChange}
                  enableRouteSegmentation={enableRouteSegmentation}
                />
              )}
            </>
          )}
        />
      )}
      <div className="section-header">
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumbConfig}
            config={{
              title: groupMasterTitle || '',
              create: permission.create,
              filter: false,
              date: false,
              search: true,
            }}
            queryParameters={queryParameters}
            handleCreateClick={() => handleIconClick(EVENT_OPERATION.CREATE, {})}
            filter={{
              date: queryParameters.date,
              menuList: filterMenu,
              onFilterChange: basePaginationService.handleFilterChange,
            }}
            resetFilter={basePaginationService.resetFilter}
            handleDateRangeChange={basePaginationService.handleDateRangeChange}
            handleSearchChange={basePaginationService.handleSearchInputChange}
          />
        </PanelStyled>
      </div>
      <div className="section-content table-present">
        <SKUGroupTypeTableList
          data={data}
          loading={serverResponseWaiting}
          handleIconClick={handleIconClick}
          onTableBodyClick={handleTableRowClick}
          pagination={queryParameters.pagination}
          onPageChange={basePaginationService.onPageSelect}
          permission={permission}
          onBulkDelete={handleBulkDelete}
          toggleState={toggleState}
        />
      </div>
    </SKUGroupTypeStyled>
  );
};

SKUGroupType.propTypes = propTypes;

SKUGroupType.defaultProps = defaultProps;

export default withAlert()(SKUGroupType);
