import React from 'react';
import PropTypes from 'prop-types';
import SectionTitleStyled from './SectionTitleStyled';
import SectionStyled from '../Section/SectionStyled';

const propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
};

const SectionWithTitle = ({ title, children }) => (
  <SectionStyled className='custom-display'>
    <SectionTitleStyled>
      <div className='section-title'>
        <span>{title}</span>
      </div>
    </SectionTitleStyled>
    {children}
  </SectionStyled>
);

SectionWithTitle.propTypes = propTypes;

export default SectionWithTitle;
