import styled from 'styled-components';

export const DetailStyled = styled.div`
.field-section{
  padding: 32px 48px 8px 48px;
  display: flex;
  background: #FFFFFF;
  border-bottom: 1px solid #E7E7ED;

  .targetBtn{
    border-radius: 4px;
    border: 1px solid var(--primary-primary, #0B5FFF);
    background: var(--light-white, #FFF);
    color: #0B5FFF;
    margin-left: 24px;

  }
  .permission-choose {
    margin-top: 10px;
    margin-left: 24px;
    width: 25%;

    > label {
      display: block;
      margin-bottom: 16px;
      color: #272833;
      font-size: 14px;
      font-weight: 600;
    }

    > div {
      display: flex;
    }

    .radio-list {
      margin-right: 24px;
      cursor: pointer;
      display: flex;
      justify-content: center;

      &:last-child {
        margin-right: 0;
      }
    }

    .text {
      font-size: 12px;
      font-weight: 600;
      color: #272833;
      display: inline-block;
    }
  }

  .month-wrap {
    width: 25%;
    padding-right: 24px;
    label {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 4px;

      + div {
        width: 100%;
        background: #f1f2f5;
        border: 1px solid #e7e7ed;
        border-radius: 4px;
        padding: 0 16px;

        > div {
          margin: 0;
          display: block;
        }
      }
    }
    .month-view {
      position: relative;

      .month-icon {
        position: absolute;
        right: 0;
        margin: 0;
      }

      .ic-dropdown {
        display: none;
      }
    }
  }
  .form-input{
    width: 25%;
  }
}
`;
