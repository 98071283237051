import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CoverageView from './View';
import PageHeader from '../../../base/PageHeader';
import { computeFilterConfig, brandDistributionLink } from './config';
import { TableBody, TableHeader } from './tableConfig';
import { apiFilterProcessor } from '../../../../utils/api';
import * as queryService from '../../../base/query.service';
import { PanelStyled } from '../../../common/configuration';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { COVERAGE_TYPE } from '../../../../data/enums/GraphQL';
import withAlert from '../../../../utils/composition/withAlert';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { BRAND_COVERAGE, reports } from '../../../../data/enums/Route';
import CoverageStyled from './CoverageStyled';
import { getUser } from '../../../../data/dao';
import { controlFilterDisplay, getDistributorFilteredViewList } from '../../../common/HelperFunctions';
import { VIEW_LIST } from '../../productive-coverage/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getBrandDetailCoverage: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  downloadReport: PropTypes.func,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class BrandCoverage extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const { state = {} } = props.location;
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        coverage: 0,
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
        type: COVERAGE_TYPE.CHANNEL,
      },
      filterConfig: computeFilterConfig(COVERAGE_TYPE.CHANNEL),
    };
    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    this.userInfo = getUser();
    this.viewList = getDistributorFilteredViewList(
      this.userInfo,
      VIEW_LIST.filter(view => view.label !== COVERAGE_TYPE.ROUTE),
    );
    this.breadCrumbConfig = [
      {
        ...reports,
        state,
      },
      brandDistributionLink,
    ];
  }

  componentDidMount() {
    this.loadTableData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { queryParameters } = this.state;
    if (prevState.queryParameters.type !== queryParameters.type) {
      queryParameters.filter = queryService.baseQueryParameters.filter;
      this.setState({ filterConfig: computeFilterConfig(queryParameters.type), queryParameters });
    }
  }

  loadTableData = () => {
    const { queryParameters, data } = this.state;
    const { getBrandDetailCoverage, match } = this.props;
    getBrandDetailCoverage(
      {
        id: parseInt(match.params.id || 0, 10),
        type: queryParameters.type,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          const { getBrandDetailCoverage = {} } = response.data;
          data.list = (getBrandDetailCoverage && getBrandDetailCoverage.rows) || [];
          data.total = (getBrandDetailCoverage && getBrandDetailCoverage.rows.length) || 0;
          data.coverage = getBrandDetailCoverage.coverage || 0;
          this.setState({ data });
        },
        handleError: err => {
          console.log(err);
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleTableRowClick = (type, row) => {
    const { history, location } = this.props;
    const { state = {} } = location;
    switch (type) {
      case COVERAGE_TYPE.CHANNEL:
        // case COVERAGE_TYPE.SUB_D:
        history.push(`/${BRAND_COVERAGE}/${state.brandId}/details/${row.id}`, {
          brandId: state.brandId,
          brand: state.title,
          title: row.name,
          type,
          id: row.id,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { data, queryParameters, display, filterConfig } = this.state;
    const { serverResponseWaiting, location } = this.props;
    const { state = {} } = location;

    return (
      <CoverageStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={this.breadCrumbConfig}
              config={{
                title: `${state.title} ${data.coverage ? `: ${fixedFloatAndCommas(data.coverage)}%` : ''}`,
                create: false,
                download: false,
                filter: controlFilterDisplay(filterConfig),
                search: true,
                date: true,
                singleDate: false,
                viewType: true,
              }}
              filter={{
                updateMenu: true,
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              viewTypeConfig={{
                value: queryParameters.type,
                list: this.viewList,
              }}
              handleViewTypeChange={this.basePaginationService.handleViewTypeChange}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <CoverageView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            handleRowClick={this.handleTableRowClick}
            loading={serverResponseWaiting}
            viewType={queryParameters.type}
            onPageChange={this.basePaginationService.onPageSelect}
            TableHeader={TableHeader}
            TableBody={TableBody}
          />
        </div>
      </CoverageStyled>
    );
  }
}

BrandCoverage.propTypes = propTypes;

BrandCoverage.defaultProps = defaultProps;

export default withAlert()(BrandCoverage);
