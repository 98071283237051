import moment from 'moment';

const getAbsoluteValue = Math.abs;

const getEnumFromObject = (object) => Object.freeze({ ...object });

const NUMBER_OF_WEEK_DAYS = 7;

const addDaysInDate = (prevDate, numberOfDays) =>
  moment(prevDate).clone().add(numberOfDays, 'days').format('YYYY-MM-DD');

const subtractDaysInDate = (prevDate, numberOfDays) =>
  moment(prevDate).clone().subtract(numberOfDays, 'days').format('YYYY-MM-DD');

// ENUMS: Based upon moment js.
const WEEK_DAYS = getEnumFromObject({
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0,
});

const WEEK_DAYS_TITLE = ['SUN', 'MON', 'TUE', 'WED', 'THR', 'FRI', 'SAT'];

const WEEK_DAYS_TITLE_MAPPER = {
  MON: WEEK_DAYS.MONDAY,
  TUE: WEEK_DAYS.TUESDAY,
  WED: WEEK_DAYS.WEDNESDAY,
  THR: WEEK_DAYS.THURSDAY,
  FRI: WEEK_DAYS.FRIDAY,
  SAT: WEEK_DAYS.SATURDAY,
  SUN: WEEK_DAYS.SUNDAY,
};

const DOUBLE_WEEK_DAYS_TITLE = [...WEEK_DAYS_TITLE, ...WEEK_DAYS_TITLE];

/** ********************************* Days Utils *********************************************** */

const getWeekDay = (date = '2020-01-01') => new Date(date).getDay();

const getDayFromDate = (date) => moment(date).date();

const checkIfToday = (date) => moment(new Date()).format('YYYY-MM-DD') === date;

const getWeekDays = (weekStartDay) => DOUBLE_WEEK_DAYS_TITLE.slice(weekStartDay, weekStartDay + NUMBER_OF_WEEK_DAYS);

const getDateList = (start, end) => {
  const duration = moment(end).diff(moment(start), 'days');

  if (duration === 0) {
    return [start];
  }

  if (duration < 0) {
    return [];
  }

  const dateList = [start];

  for (let count = 1; count < duration + 1; count += 1) {
    const date = addDaysInDate(start, count);

    dateList.push(date);
  }

  return dateList;
};

const checkIfTodayOrGreater = (date) => {
  const now = new Date();

  const isAfter = moment(date).isAfter(now);

  if (isAfter) {
    return isAfter;
  }

  const today = checkIfToday(date);

  if (today) {
    return today;
  }

  return false;
};

/** ********************************* Week Utils *********************************************** */

const getCurrentWeek = (date = moment(new Date()).format('YYYY-MM-DD'), weekStartDay = WEEK_DAYS.SUNDAY) => {
  const currentWeekDay = new Date(date).getDay();

  const startingWeekDay = weekStartDay;

  const absoluteDayDifference = getAbsoluteValue(startingWeekDay - currentWeekDay);

  const currentDayPosition =
    startingWeekDay <= currentWeekDay ? absoluteDayDifference : NUMBER_OF_WEEK_DAYS - absoluteDayDifference;

  const weekStart = subtractDaysInDate(date, currentDayPosition);

  const weekEnd = addDaysInDate(weekStart, NUMBER_OF_WEEK_DAYS - 1);

  return {
    start: weekStart,
    end: weekEnd,
  };
};

const getNextWeek = (start, end) => {
  const startDate = addDaysInDate(end, 1);
  const endDate = addDaysInDate(end, 7);

  return {
    start: startDate,
    end: endDate,
  };
};

const getPrevWeek = (start, end) => {
  const startDate = subtractDaysInDate(start, 7);
  const endDate = subtractDaysInDate(start, 1);

  return {
    start: startDate,
    end: endDate,
  };
};

const getWeeksInMonth = (start, end, weekStart = WEEK_DAYS.SUNDAY) => {
  const weekList = [];
  let weekDetails = getCurrentWeek(start, weekStart);
  weekList.push(weekDetails);

  while (new Date(weekDetails.end) < new Date(end)) {
    const nextDate = addDaysInDate(weekDetails.end, 1);
    weekDetails = getCurrentWeek(nextDate, weekStart);
    weekList.push(weekDetails);
  }

  return weekList;
};

const getWeekAndMonthDay = (date) => {
  const weekDay = new Date(date).getDay();

  return {
    weekDay,
    weekDayTitle: WEEK_DAYS_TITLE[weekDay],
    monthDay: new Date(date).getDate(),
    month: '',
  };
};

const getWeekStartDayFromWeekEnds = (weekEnds) => {
  if (weekEnds.length === 0) {
    return WEEK_DAYS.SUNDAY;
  }

  const lastWeekEnd = weekEnds[weekEnds.length - 1];

  return lastWeekEnd === WEEK_DAYS.SATURDAY ? WEEK_DAYS.SUNDAY : lastWeekEnd + 1;
};

// if weekEnd contains 7, resolve to 1
const weekEndSundayResolver = (weekEnds) => weekEnds.map((day) => (day === 7 ? 0 : day));

/** *********************************** Month Utils ********************************************* */

const getMonth = (date) => moment(date).month();

const getNextMonth = (date) => {
  const nextMonth = moment(date).add(1, 'M');

  const start = nextMonth.startOf('month').format('YYYY-MM-DD');
  const end = nextMonth.endOf('month').format('YYYY-MM-DD');

  return {
    start,
    end,
  };
};

const getPrevMonth = (date) => {
  const nextMonth = moment(date).subtract(1, 'M');

  const start = nextMonth.startOf('month').format('YYYY-MM-DD');
  const end = nextMonth.endOf('month').format('YYYY-MM-DD');

  return {
    start,
    end,
  };
};

const getCurrentMonth = (date = moment(new Date()).format('YYYY-MM-DD')) => {
  const month = moment(date);

  const start = month.startOf('month').format('YYYY-MM-DD');
  const end = month.endOf('month').format('YYYY-MM-DD');

  return {
    start,
    end,
  };
};

const getMonthStartAndEnd = (date, noOfAddedMonth = 0) => {
  const currentMonth = moment(date);

  const endMonth = moment(date).add(noOfAddedMonth, 'M');

  const start = currentMonth.startOf('month').format('YYYY-MM-DD');

  const end = endMonth.endOf('month').format('YYYY-MM-DD');

  return {
    start,
    end,
  };
};

const getDateFromYearAndMonth = (year, month) => {
  const startDate = moment([year, month - 1]);

  const endDate = moment(startDate).endOf('month');

  return {
    start: startDate.format('YYYY-MM-DD'),
    end: endDate.format('YYYY-MM-DD'),
  };
};

const getMonthYearTitleFromDate = (date) => {
  const momentDate = moment(date);
  const month = momentDate.format('MMMM');
  const year = momentDate.year();

  return `${month} ${year}`;
};

const getDayMonthTitleFromDate = (date) => {
  const momentDate = moment(date);
  const month = momentDate.format('MMM');
  const day = getDayFromDate(date);

  return `${month} ${day}`;
};

/** *********************************** Misc Utils ********************************************* */

const spliceDate = (dateRange, date) => ({
  start: moment(dateRange.start) < moment(date) ? date : dateRange.start,
  end: moment(dateRange.end) < moment(date) ? date : dateRange.end,
});

const getRosterDateList = (date, frequency = 7, monthCoverage = 0) => {
  const month = getMonthStartAndEnd(date, monthCoverage);

  // splice date grater than current date
  const splicedDate = spliceDate(month, date);

  let baseDate = date;
  let newBaseDate = subtractDaysInDate(date, frequency);

  const dateList = [];

  while (moment(newBaseDate) >= moment(splicedDate.start)) {
    dateList.push(newBaseDate);

    newBaseDate = subtractDaysInDate(newBaseDate, frequency);
  }

  while (moment(baseDate) <= moment(splicedDate.end)) {
    dateList.push(baseDate);

    baseDate = addDaysInDate(baseDate, frequency);
  }

  return dateList;
};

export {
  getDayFromDate,
  getDayMonthTitleFromDate,
  checkIfToday,
  getWeekDay,
  getWeekDays,
  addDaysInDate,
  subtractDaysInDate,
  getCurrentWeek,
  getCurrentMonth,
  getWeeksInMonth,
  getDateList,
  getNextWeek,
  getPrevWeek,
  getWeekAndMonthDay,
  getNextMonth,
  getPrevMonth,
  getMonth,
  getMonthYearTitleFromDate,
  getRosterDateList,
  checkIfTodayOrGreater,
  weekEndSundayResolver,
  getWeekStartDayFromWeekEnds,
  getDateFromYearAndMonth,
  WEEK_DAYS,
  WEEK_DAYS_TITLE_MAPPER,
};
