import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { addMouseClickListener, removeMouseClickListener } from '../../utils/mouseEvents';
import { CheckBox, Icon } from '../index';

const getSelectedItemChips = (
  elementList: { id: number; title: string }[],
  handleIconClick: (element: { id: number; title: string }) => void,
) => (
  <div className="selected-filter-item-wrap">
    {elementList.map(element => (
      <div key={element.id} className="selected-filter-item">
        {element.title}
        <div className="filter-close" onClick={() => handleIconClick(element)}>
          <Icon iconName="times" iconWidth="13px" iconHeight="13px" />
        </div>
      </div>
    ))}
  </div>
);

const getUpdatedSelectionList = (passedProps: any) => {
  const { selectionList, filter } = passedProps;
  const newSelectionList: any = [];
  selectionList.map((element: any) => newSelectionList.push(element));
  if (newSelectionList.indexOf(filter) < 0 && filter.label !== 'userOption') {
    newSelectionList.push(filter);
  }
  return newSelectionList;
};

const BaseFilter = (props: any) => {
  const { onGroupDelete, selectedIdList, selectedItemList, getOptionList } = props;
  const initialFilter = {
    id: props.filter.id,
    title: props.filter.title,
    label: props.filter.label,
    value: props.filter.value,
    selectedItemList: props.filter.selectedItemList || [],
    selectedIdList: props.filter.selectedIdList || [],
    selected: props.filter.selected || false,
  };

  const [selectionList, setSelectionList] = useState(getUpdatedSelectionList(props));
  const [filter, setFilter] = useState(initialFilter);
  const [optionList, setOptionList] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [showMenuOptions, setShowMenuOptions] = useState(false);
  const [isMounted, setIsMounted] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    setIsMounted(true);
    addMouseClickListener(event => {
      const reference: any = ref && ref.current
      const clickedInsideDiv = reference && (reference).contains(event.target);
      if (!clickedInsideDiv) {
        setShowMenuOptions(false);
      } else {
        setShowMenuOptions(true);
      }
    });

    return () => {
      setIsMounted(false);
      removeMouseClickListener(() => { });
    };
  }, []);

  const onSetOptionList = async (filterOptions: any) => {
    if (filterOptions) {
      const FilteredOptionList = await getOptionList(filterOptions.label);
      if (isMounted) {
        setOptionList(FilteredOptionList);
      }
    }
  };

  const getFilteredOptionList = () => {
    const filterTextLower = filterText.toLowerCase();
    return optionList.length > 0
      ? optionList.filter((option: any) =>
        option.title.toLowerCase().includes(filterTextLower),
      )
      : [];
  };

  const handleFilterGroupDelete = () => {
    onGroupDelete(filter);
  };

  const handleFilterInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const onSelectChange = (event: any) => {
    const { onFilterGroupSelect } = props;
    const oldFilter = { ...filter };
    const newFilter: any = {};
    newFilter.id = event.id;
    newFilter.title = event.title;
    newFilter.label = event.label;
    newFilter.value = event.value;
    newFilter.selected = event.selected || false;
    newFilter.selectedIdList = event.selectedIdList || [];
    newFilter.selectedItemList = event.selectedItemList || [];
    setShowMenuOptions(true);

    onFilterGroupSelect({ ...event }, { ...oldFilter });
    setFilter(newFilter);
    onSetOptionList(newFilter);
  };

  const handleCheckBoxClick = (element: any) => {
    let { selectedItemList } = props;
    const { selectedIdList, onCheckBoxClick } = props;
    const elementId = element.id;
    const idIndex = selectedIdList.indexOf(elementId);
    // todo checkbox processor over here
    if (idIndex > -1) {
      selectedIdList.splice(idIndex, 1);
      selectedItemList = selectedItemList.filter(
        (d: any) => d.id !== element.id,
      );
    } else {
      selectedIdList.push(elementId);
      selectedItemList.push(element);
    }
    onCheckBoxClick(filter.label, selectedItemList, selectedIdList, filter);
  };

  useEffect(() => {
    onSetOptionList(filter);
  }, []);

  const filteredOptionList = getFilteredOptionList();
  const selectedItemChips = getSelectedItemChips(
    selectedItemList,
    handleCheckBoxClick,
  );

  return (
    <div className="filter-flex">
      <div className="filter-close">
        <Icon iconName="times" iconWidth="12px" iconHeight="12px" onClick={handleFilterGroupDelete} />
      </div>
      <div className="filter-select-input">
        <Select
          isClearable={false}
          className="custom-select"
          classNamePrefix="filter-select"
          placeholder="Select Filter"
          getOptionLabel={({ title }) => `${title}`}
          getOptionValue={({ id }) => id}
          value={selectionList.filter(({ label }: any) => label === filter.label)}
          options={selectionList}
          enableValiation
          onChange={e => onSelectChange(e)}
        />
      </div>
      <div className="filter-contain-wrap">
        <span className="filter-contain-text">Contains</span>
        <div ref={ref}>
          <div className="filter-contain-input-wrap">
            {selectedItemChips}
            <input
              type="text"
              id="myText"
              placeholder="Search"
              value={filterText}
              onChange={e => handleFilterInputChange(e)}
              autoComplete="off"
            />
          </div>
          {showMenuOptions && (
            <div className="filter-contain-group">
              {filteredOptionList.map((option: any) => (
                <div key={option.id} className="filter-contain-item">
                  <CheckBox
                    checked={selectedIdList.includes(option.id)}
                    onCheckBoxClick={() => handleCheckBoxClick(option)}
                  />
                  <span className="filter-item-name">{option.title}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseFilter;
