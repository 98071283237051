import React from 'react';
import PropTypes from 'prop-types';
import { menuAction, tableHeaderWithCheckBox } from '../../../../../v4/components';
import * as dateUtil from '../../../../../utils/date';
import { CheckBox } from '../../../../../v4/components';
import { fixedFloat, fixedFloatAndCommas } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const labelMappings = {
  checkbox: 'checkbox',
  index: 'index',
  items: 'items',
  rate: 'rate',
  gross: 'gross',
  grossWithVat: 'grossWithVat',
  quantity: 'quantity',
  discount: 'discount',
  taxableAmount: 'taxableAmount',
  taxAmount: 'taxAmount',
  netAmount: 'netAmount',
  value: 'value',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: false,
  },
  {
    id: 1.1,
    label: labelMappings.index,
    title: 'SN.',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.items,
    title: 'Item',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.rate,
    title: 'Rate',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 3,
    label: labelMappings.quantity,
    title: 'Quantity',
    show: true,
    classNames: 'text-right',
  },
  // {
  //   id: 4,
  //   label: labelMappings.gross,
  //   title: 'Gross Amount',
  //   show: true,
  //   classNames: 'text-right',
  // },
  // {
  //   id: 4.1,
  //   label: labelMappings.grossWithVat,
  //   title: 'Gross With VAT Amount',
  //   show: true,
  //   classNames: 'text-right',
  // },

  // {
  //   id: 5,
  //   label: labelMappings.discount,
  //   title: 'Discount',
  //   show: true,
  //   classNames: 'text-right',
  // },
  {
    id: 6,
    label: labelMappings.taxableAmount,
    title: 'Value',
    show: true,
    classNames: 'text-right',
  },
  // {
  //   id: 6.1,
  //   label: labelMappings.taxAmount,
  //   title: 'Tax Amount',
  //   show: true,
  //   classNames: 'text-right',
  // },
  // {
  //   id: 10,
  //   label: labelMappings.netAmount,
  //   title: 'Net Amount',
  //   show: true,
  //   classNames: 'text-right',
  // },
  {
    id: 11,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
};
const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const returnTableBody = (index, data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission, update) => {
  const table = {};
  table[labelMappings.checkbox] = permission && (
    <td key={`campaigns-${data.id}-check`} onClick={e => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.index] = <td key={`${data}-index`}>{index + 1}</td>;
  table[labelMappings.items] = <td key={`${data}-items`}>{data?.product?.title}</td>;
  table[labelMappings.rate] = (
    <td key={`${data}-rate`} className="text-right">
      {data?.product?.Rates?.[0]?.priceDetails?.rlp}
    </td>
  );

  table[labelMappings.quantity] = (
    <td key={`${data}-quantity`} className="text-right">
      {data?.quantity}
    </td>
  );
  // table[labelMappings.gross] = (
  //   <td key={`${data}-gross`} className="text-right">
  //     {fixedFloatAndCommas(data?.gross)}
  //   </td>
  // );
  // table[labelMappings.grossWithVat] = (
  //   <td key={`${data}-grossWithVat`} className="text-right">
  //     {fixedFloatAndCommas(data?.grossWithVat)}
  //   </td>
  // );
  // table[labelMappings.discount] = (
  //   <td key={`${data}-discount`} className="text-right">
  //     {fixedFloatAndCommas(data?.discount)}
  //   </td>
  // );
  table[labelMappings.taxableAmount] = (
    <td key={`${data}-taxableAmount`} className="text-right">
      {fixedFloatAndCommas(data?.taxableAmount)}
    </td>
  );
  // table[labelMappings.taxAmount] = (
  //   <td key={`${data}-taxAmount`} className="text-right">
  //     {fixedFloatAndCommas(data?.taxAmount)}
  //   </td>
  // );
  // table[labelMappings.netAmount] = (
  //   <td key={`${data}-netAmount`} className="text-right">
  //     {fixedFloatAndCommas(data?.netAmount) ?? 0}
  //   </td>
  // );
  table[labelMappings.actionHolders] = (
    <td
      key={`${data}-actionHolders`}
      className={`text-right simple-popup-actions ${!update.status ? 'disabled' : null}`}
      onClick={e => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, { update: true, delete: true })}
    </td>
  );

  return table;
};

const TableBody = ({
  index,
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission,
  update,
  onTableRowClick,
}) => {
  const tableBody = returnTableBody(
    index,
    data,
    checkedList,
    onIconClick,
    secondaryCheckboxHandler,
    permission,
    update,
    onTableRowClick,
  );
  return (
    <tr
      key={data.campaignId}
      onClick={() => onTableRowClick(data.id, update)}
      className={!update.status && 'enable-cursor'}
    >
      {labelConfig.map((label, index) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export { TableHeader, TableBody };
