import { compose } from 'recompose';
import Invoiced from './Invoiced';
import { DISPATCH_INVOICE, DELIVER_INVOICE } from '../API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { query } from './API';

const ComposedInvoiced = compose(
  DISPATCH_INVOICE,
  DELIVER_INVOICE,
  withRequestTracker({
    query: query,
    mutation: {
      dispatchInvoice: DISPATCH_INVOICE,
      deliverInvoice: DELIVER_INVOICE,
    },
  }),
)(Invoiced);

export default ComposedInvoiced;
