import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from '../CustomSelect';
import {
  getCurrentDay,
  getStartOfCurrentMonth,
  getYesterDay,
  getStartOfCurrentWeek,
  getLastMonthStartDay,
  getLastMonthEndDay,
} from '../../utils/date';

const propTypes = {
  onDropDownChange: PropTypes.func.isRequired,
  labelName: PropTypes.string.isRequired,
};

class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateList: [
        {
          label: 'Today',
          value: {
            start: getCurrentDay(),
            end: getCurrentDay(),
          },
        },
        {
          label: 'Last Day',
          value: {
            start: getYesterDay(),
            end: getYesterDay(),
          },
        },
        {
          label: 'MTD',
          value: {
            start: getStartOfCurrentMonth(),
            end: getCurrentDay(),
          },
        },
        {
          label: 'Last Month',
          value: {
            start: getLastMonthStartDay(),
            end: getLastMonthEndDay(),
          },
        },
        {
          label: 'Weekly',
          value: {
            start: getStartOfCurrentWeek(),
            end: getCurrentDay(),
          },
        },
      ],
    };
  }

  render() {
    const { dateList } = this.state;
    const { onDropDownChange, labelName } = this.props;
    return (
      <div>
        <CustomSelect
          small
          className='custom-select'
          options={dateList}
          placeholder='Select Channel'
          getOptionValue={({ value }) => value}
          getOptionLabel={({ label }) => label}
          onChange={(event) => onDropDownChange(event)}
          value={dateList.filter((d) => d.label === labelName)}
          isSearchable={false}
        />
      </div>
    );
  }
}

Filter.propTypes = propTypes;

export default Filter;
