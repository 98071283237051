import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import DetailForm from './Form';
import { customerMapper } from './config';
import LEDGER from '../../../../../data/enums/Ledgers';
import { clone, compareList } from '../../../../../utils/arrayProcessor';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { refValidator } from '../../../../../utils/refGenerator';
import withAlert from '../../../../../utils/composition/withAlert';
import withLoading from '../../../../../utils/composition/withLoading';
import { debouncer, dropdownChange, inputChange } from '../../../../../utils/formHandlers';
import { summaryDetailsMapper } from '../../create/config';
import { getPaymentModeList } from '../../../../../data/dao/paymentMode';
import LedgerAdd from '../../../../components/LedgerAdd';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  getDetails: PropTypes.func,
  getStatus: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  distributorList: PropTypes.instanceOf(Array),
  displayAlert: PropTypes.func.isRequired,
  outletList: PropTypes.instanceOf(Array),
  onInvoiceNumberChange: PropTypes.func,
  getInvoiceNumberList: PropTypes.func,
  distributorServices: PropTypes.instanceOf(Object),
  updateDistributorServices: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  invoiceNumberStatus: PropTypes.bool,
  subDUser: PropTypes.bool,
  showDialog: PropTypes.bool,
  handleDialogSubmit: PropTypes.func,
};

const defaultProps = {
  data: {},
  showDialog: false,
  handleDialogSubmit: () => null,
  distributorList: [],
  getStatus: () => null,
  getDetails: () => null,
  enableErrorDisplay: false,
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
  outletList: [],
  onInvoiceNumberChange: () => null,
  getInvoiceNumberList: () => null,
  distributorServices: {},
  updateDistributorServices: () => null,
  invoiceNumberStatus: false,
  subDUser: false,
};

class SummaryDetails extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps;
    const { backupData } = prevState;

    if (!compareList(data, backupData)) {
      return {
        data: nextProps.data,
        backupData: nextProps.data,
      };
    }
    return data;
  }

  constructor(props) {
    super(props);
    this.state = {
      backupData: props.data,
      data: clone(props.data),
      invoiceNumberList: [],
      customerList: [],
      srnRouteLineList: [],
      loadingInvoiceStatus: false,
      paymentModeList: [],
    };
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  componentDidUpdate(prevProps) {
    const { data, outletList, pendingcustomerList, distributorServices } = this.props;
    const { customerList } = this.state;
    if (!prevProps?.distributorServices?.billing?.status && distributorServices?.billing?.status) {
      const distributorId = data?.Distributor?.id ?? distributorServices?.id;
      (async () => {
        const paymentModeList = distributorId > 0 ? await getPaymentModeList(distributorId) : [];
        await this.getCustomerList(pendingcustomerList);
        this.setState({ paymentModeList: paymentModeList?.filter(a => a?.active) });
      })();
    }
    if (data.RetailOutlet.id && !customerList.length && outletList.length) {
      if (data.RetailOutlet.id !== prevProps.data.RetailOutlet.id) {
        this.updateCustomerList(data.RetailOutlet.id);
      }
      // if (pendingcustomerList) {
      //   this.getCustomerList(pendingcustomerList);
      // }
    }
  }
  getCustomerList = async pendingcustomerList => {
    const { getCustomers, newCustomerList } = this.props;
    const { data } = this.state;
    getCustomers(
      {
        offset: 0,
        filter: {
          filters: [
            {
              column: 'retail_outlet_id',
              value: [data.RetailOutlet.id?.toString()],
            },
            {
              column: 'active',
              value: ['true'],
            },
            {
              column: 'distributor_id',
              value: [data.Distributor.id?.toString()],
            },
          ],
        },
        retailOutletId: data.RetailOutlet.id,
        distributorId: data.Distributor.id,
      },
      {
        handleSuccess: response => {
          const customerList = data?.isOldBill ? response?.data?.customers?.rows : [pendingcustomerList];
          const updateData = { ...data, ...(data?.isOldBill && { Customer: { id: null, name: '' } }) };
          const updateCustomerList = !!newCustomerList ? customerList?.push(newCustomerList) : customerList;
          this.setState({ customerList: updateCustomerList, data: { ...updateData } });
          this.props.fillCustomerListData(customerList);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };
  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  // getCustomerList = pendingcustomerList => {
  //   this.setState({ customerList: [pendingcustomerList] });
  //   this.props.fillCustomerListData([pendingcustomerList]);
  // };

  getInvoiceNumberList = salesInvoiceNumber => {
    const { data, invoiceNumberList } = this.state;
    const { onInvoiceNumberChange, getInvoiceNumberList, displayAlert } = this.props;
    if (!data.Distributor.id) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Distributor is not selected');
      return 0;
    }
    if (salesInvoiceNumber.length >= 3) {
      let exactlyMatched = invoiceNumberList.indexOf(salesInvoiceNumber) > -1;
      if (!exactlyMatched) {
        this.setState({ loadingInvoiceStatus: true });
        getInvoiceNumberList(
          {
            invoiceString: salesInvoiceNumber,
            distributorId: data.Distributor.id,
          },
          {
            handleSuccess: response => {
              exactlyMatched = response.data.searchInvoiceNumber.matched;
              this.setState(
                {
                  invoiceNumberList: response.data.searchInvoiceNumber.invoiceNumbers || [],
                  loadingInvoiceStatus: false,
                },
                () => onInvoiceNumberChange(salesInvoiceNumber, exactlyMatched),
              );
            },
            handleError: error => {
              this.setState({ invoiceNumberList: [], loadingInvoiceStatus: false }, () =>
                onInvoiceNumberChange(salesInvoiceNumber, exactlyMatched),
              );
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      } else {
        onInvoiceNumberChange(salesInvoiceNumber, exactlyMatched);
      }
    } else {
      this.setState({ invoiceNumberList: [], loadingInvoiceStatus: false }, () =>
        onInvoiceNumberChange(salesInvoiceNumber, false),
      );
    }
  };

  resetData = (updatedData, callBack) => {
    const refreshedData = summaryDetailsMapper({
      invoiceNumber: updatedData.salesInvoiceNumber,
      Distributor: { id: updatedData.Distributor.id },
    });
    this.setState({ data: refreshedData, customerList: [] }, () =>
      debouncer(callBack, 300)(updatedData.salesInvoiceNumber),
    );
  };

  updateCustomerList = outletId => {
    const { outletList, distributorServices } = this.props;
    const selectedOutlet = outletList.find(outlet => outlet.id === outletId);
    this.getLinesByOutletId(outletId);
    if (selectedOutlet) {
      let customerList = selectedOutlet.Customers || [];
      customerList = customerList.filter(item => item.id !== LEDGER.CASH.id);
      if (!distributorServices.billing.status) {
        customerList.push(LEDGER.CASH);
      }
      this.setState({ customerList });
    }
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;

    const updatedData = inputChange(data, event, firstParam, paramList);
    switch (event.target.name) {
      case 'salesInvoiceNumber':
        this.resetData(updatedData, this.getInvoiceNumberList);
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  filterItemFromList = (list, value, expectedDefaultValue, returnValueKey, key = 'id') => {
    const item = list.filter(item => item[key] === value)[0];
    const k = returnValueKey ? item[returnValueKey] || expectedDefaultValue : item;

    return k;
  };

  getLinesByOutletId = id => {
    const { getLinesByOutlet, displayAlert } = this.props;
    const { data } = this.state;
    getLinesByOutlet(
      { outletId: id },
      {
        handleSuccess: response => {
          const responseData = response.data.linesByOutletId;
          if (responseData.length === 1) {
            data.lineId = responseData[0] && responseData[0].id;
            this.setState({
              data,
            });
          }
          this.setState({
            srnRouteLineList: responseData || [],
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleDropDownChange = (value, parameterRef = [], name, selectedObj = {}) => {
    const { data } = this.state;
    const { updateDistributorServices } = this.props;
    const updatedData = dropdownChange(data, parameterRef, value);
    switch (name) {
      case 'outlet':
        updatedData.Customer = customerMapper({});
        this.setState({ data: updatedData }, () => {
          this.updateCustomerList(updatedData.RetailOutlet.id);
        });
        break;
      case 'customer':
        this.handleCustomerChange(updatedData.Customer.id);
        break;
      case 'distributor':
        updatedData.Customer = customerMapper({});
        this.setState({ data: updatedData }, () => updateDistributorServices(selectedObj));
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  handleCustomerChange = customerId => {
    const { customerList, data } = this.state;
    data.Customer = this.filterItemFromList(customerList, customerId, null) || customerMapper({});
    this.setState({ data });
  };

  getValidationStatus = () => {
    const { formConfig } = this.props;
    /* const { data } = this.state;
    const m = Object.values(formConfig[FORM_CONFIG.REFS_OBJ]);
    const k = m.find(item => (item.getValidState() === false)); */

    return refValidator(formConfig[FORM_CONFIG.REFS_OBJ]);
    // return (!Object.values(formConfig[FORM_CONFIG.REFS_OBJ]).find(item => item.getValidState() === false));
  };

  exportData = () => {
    const { data } = this.state;
    return data;
  };
  handleInputPaymentMode = (value, firstParam = '') => {
    this.setState(prevState => {
      const { data } = prevState;
      const updatedData = {
        ...data,
        [firstParam]: value,
        Customer: customerMapper({}),
      };
      return { data: updatedData };
    });
  };

  render() {
    const {
      subDUser,
      formConfig,
      outletList,
      enableErrorDisplay,
      distributorList,
      loading,
      distributorServices,
      invoiceNumberStatus,
      pending,
      handleIconClick,
      showDialog,
      handleDialogSubmit,
      distributor,
    } = this.props;
    const { data, loadingInvoiceStatus, customerList, invoiceNumberList, srnRouteLineList, paymentModeList } =
      this.state;
    return (
      <Fragment>
        {showDialog && (
          <LedgerAdd
            onCancel={handleIconClick}
            onSubmit={handleDialogSubmit}
            townId={distributor.townList.map(String)}
            id={data.RetailOutlet.id}
            distributorId={data?.Distributor?.id}
            billingUser
            search
          />
        )}
        <DetailForm
          show
          pending={pending}
          subDUser={subDUser}
          data={data}
          customerList={customerList}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={this.handleInputChange}
          handleDropDownChange={this.handleDropDownChange}
          handleInputPaymentMode={this.handleInputPaymentMode}
          outletList={outletList}
          distributorList={distributorList}
          refsObj={formConfig[FORM_CONFIG.REFS_OBJ]}
          invoiceNumberList={invoiceNumberList}
          distributorServices={distributorServices}
          invoiceNumberStatus={invoiceNumberStatus}
          loading={loading}
          srnRouteLineList={srnRouteLineList}
          loadingInvoiceStatus={loadingInvoiceStatus}
          paymentModeList={paymentModeList}
          handleIconClick={handleIconClick}
        />
      </Fragment>
    );
  }
}

SummaryDetails.propTypes = propTypes;

SummaryDetails.defaultProps = defaultProps;

export default withAlert()(withLoading(SummaryDetails));
