import styled from 'styled-components';

const SectionTitleStyled = styled.div`
  width: 100%;
  .section-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`;
export default SectionTitleStyled;
