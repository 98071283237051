import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { salesForce as salesForceRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { FORM_CONFIG } from '../../../v4/constants/formConfig';
import { EVENT_OPERATION } from '../../../v4/constants/EventOperation';

const title = 'Promotions';

const breadCrumb = [salesForceRoute];

const menuConfigList = [
  {
    title: 'Replicate',
    icon: 'copy',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.REPLICATE,
  },
];

const filter = getFilterConfig([FILTER.STATUS, FILTER.PROMOTION_TYPE, FILTER.CATEGORY, FILTER.CHANNEL,FILTER.PRINCIPAL]);

const form = {
  mapper: (element) => ({
    id: element.id || '',
    title: element.title || '',
    endDate: element.endDate || '',
    startDate: element.startDate || '',
  }),
  validationField: ['name'],
  validationRequired: true,
};

const multipleReplicateForm = {
  [EVENT_OPERATION.REPLICATE]:
    {
      mapper: (element) => ({
        id: element.id || '',
        title: element.title || '',
        endDate: element.endDate || '',
        startDate: element.startDate || '',
      }),
      validationField: ['name'],
      validationRequired: false,
    },
  [EVENT_OPERATION.REPLICATE_ALL]:
    {
      [FORM_CONFIG.MAPPER]: (element) => ({
        endDate: element.endDate || '',
        startDate: element.startDate || '',
      }),
      [FORM_CONFIG.TITLE]: `Extend All ${title}`,
    },
}


const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({
      name: data.name,
      displayName: data.displayName,
      userGrpId: data.UserGroup.id,
    }),
    responseName: 'createRole',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      name: data.name,
      displayName: data.displayName,
      userGrpId: data.UserGroup.id,
      UserGroup: {
        id: data.userGroupId,
      },
    }),
    responseName: 'updateRole',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'deleteSKU',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateSKU',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: title,
      active: !data.active,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};

export {
  form,
  title,
  crudRequest,
  menuConfigList,
  crudRequest as crudSuccess,
  breadCrumb as breadCrumbConfig,
  filter as filterConfig,
  multipleReplicateForm,
};
