import { SBD } from '../../../../data/enums/Route';
import { breadCrumb as breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'SBD',
    path: `/${SBD}`,
    active: true,
  },
];

export { breadCrumb };
