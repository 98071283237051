import React from 'react';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils/refGenerator';
import { PROMOTION_TYPES } from '../../../../../data/enums/GraphQL';
import { RETURN_TYPE } from '../../../../common/DomainConfig';
import { ORDER_TABS, SERVER_ORDER_STATUS } from '../../config';
import { Button } from '../../../../../v4/components';
import { getPercentage } from '../../../../../utils/utilities';

const getSkuObj = (element) => {
  const promotionPerUnitSKU = element.promotionAmount ? element.promotionAmount / element.quantity : 0;
  const splitTotalPromotionAmount = element.minimumSplitQuantity
    ? element.minimumSplitQuantity * promotionPerUnitSKU
    : 0;
  const rate = element.rateDetails ? element.rateDetails.rlp : 0;
  const splitTotalAmount = element.minimumSplitQuantity
    ? (element.minimumSplitQuantity * rate - splitTotalPromotionAmount) * 1.13
    : 0;
  return {
    title: element.SKU ? element.SKU.title : '',
    quantity: element.minimumSplitQuantity ? element.minimumSplitQuantity : 0,
    promotion: splitTotalPromotionAmount,
    value: splitTotalAmount,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['quantity']),
    displayError: false,
  };
};

const getMinimumSplitQuantity = (promotionCriteria, lineAmount, promotionType = PROMOTION_TYPES.NORMAL) => {
  if (!promotionCriteria.operation.min.value || promotionType !== PROMOTION_TYPES.NORMAL) {
    return 0;
  }

  if (promotionCriteria.dimension === 'QUANTITY') {
    return promotionCriteria.operation.min.value || 0;
  }

  return Math.ceil(promotionCriteria.operation.min.value / lineAmount);
};

const calculatePromotionItemTotal = (list) =>
  list.reduce((acc, cur) => {
    acc.quantity = (acc.quantity || 0) + cur.quantity;
    acc.grossAmount = (acc.grossAmount || 0) + cur.amountDetails.grossAmount;
    acc.promotionDiscount = (acc.promotionDiscount || 0) + cur.amountDetails.promotionDiscount;
    acc.topUpDiscount = (acc.topUpDiscount || 0) + cur.amountDetails.topUpDiscount;
    acc.subTotal = (acc.subTotal || 0) + cur.amountDetails.subTotal;

    return acc;
  }, {});

const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id || 0,
      quantity: element.quantity || 0,
      baseQuantity: element.quantity || 0,
      rateDetails: {
        rlp: element.rateDetails ? element.rateDetails.rlp : 0,
      },
      amountDetails: {
        netAmount: element.amountDetails ? element.amountDetails.netAmount : 0,
      },
      callId: element.callId || null,
    }),
    [FORM_CONFIG.TITLE]: 'Modify Order',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['quantity', 'reason']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id || 0,
      reason: element.reason || '',
      callId: element.callId || null,
      type: RETURN_TYPE.PARTIAL,
      promotionOrderIds: element.promotionOrderIds,
    }),
    [FORM_CONFIG.TITLE]: 'Cancel Order',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['reason']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      ids: element.ids || 0,
      reason: element.reason || '',
      callId: element.callId || null,
      type: element.type || RETURN_TYPE.PARTIAL,
    }),
    [FORM_CONFIG.TITLE]: 'Cancel Orders',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['reason']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.SPLIT]: {
    [FORM_CONFIG.MAPPER]: (element) => {
      const promotion = {
        title: element.Promotion ? element.Promotion.title : '',
        id: element.Promotion ? element.Promotion.id : 0,
        criteria: element.Promotion
          ? element.Promotion.criteria
          : {
            dimension: 'QUANTITY',
            operation: {
              min: { value: 0 },
              max: { value: 0 },
            },
          },
      };

      const amountDetails = {
        netAmount: element.amountDetails ? element.amountDetails.netAmount : 0,
        grossAmount: element.amountDetails ? element.amountDetails.grossAmount : 0,
      };

      const promotionPerUnitSKU = element.amountDetails ? element.amountDetails.discountAmount / element.quantity : 0;

      const minimumSplitQuantity = getMinimumSplitQuantity(
        promotion.criteria,
        amountDetails.netAmount / element.quantity,
        promotion.type,
      );
      // minimum split quantity is determined from promotion critera
      // if dimension is quantity, then it is minimun split criteria
      // if dimension is amount, it is amount / unit per sku ceiled to top
      return {
        id: element.id || 0,
        totalNetAmount: element.totalNetAmount || 0,
        quantity: element.quantity || 0,
        rateDetails: {
          rlp: element.rateDetails ? element.rateDetails.rlp : 0,
        },
        amountDetails: {
          netAmount: element.amountDetails ? element.amountDetails.netAmount : 0,
        },
        SKU: {
          title: element.SKU ? element.SKU.title : '',
        },
        skuObj: getSkuObj(element),
        promotionPerUnitSKU,
        minimumSplitQuantity,
        totalQuantity: element.quantity || 0,
        Promotion: promotion,
        splitList: [],
      };
    },
    [FORM_CONFIG.TITLE]: 'Split order',
    [FORM_CONFIG.REFS_OBJ]: refGenerator([]),
    MESSAGE: {
      QUANTITY_ERROR: 'Split total quantity does not matches order quantity',
      MINIMUM_SPLIT_QTY: 'Violates minimum criteria',
      INVALID: 'Not valid!',
    },
  },

  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.MAPPER]: (dataList) => {
      const total = calculatePromotionItemTotal(dataList.Lines || []) || {};

      return { ...dataList, total };
    },
    [FORM_CONFIG.TITLE]: 'Promotion Details',
  },
  [EVENT_OPERATION.REPLACED]: {
    [FORM_CONFIG.MAPPER]: (call) => ({
      id: call.id || '',
      outlet: (call.RetailOutlet && call.RetailOutlet.title) || '',
      dse: (call.User && call.User.fullName) || '',
      route: (call.Route && call.Route.title) || '',
      replacementGroupId: (call.stockReplacement && call.stockReplacement.groupId) || '',
      distributorId: (call.Distributor && call.Distributor.id) || '',
    }),
    [FORM_CONFIG.TITLE]: 'Stock Replacement',
    [FORM_CONFIG.REFS_OBJ]: refGenerator([]),
    buttonName: 'Replace',
    getExtraFooterElements: (callBack) => (
      <Button secondary onClick={(e) => callBack()}>
        <span>Cancel Replacement</span>
      </Button>
    ),
  },

  [EVENT_OPERATION.READ_SUB_TYPE]: {
    [FORM_CONFIG.MAPPER]: (call) => ({
      total: call.callOrderValue.billDiscount,
      list: call.billDiscountDetail.map((item) => ({
        ...item,
        discountPercentage: getPercentage(item.amount, call.callOrderValue.subTotal || 1),
      })),
    }),
    [FORM_CONFIG.TITLE]: 'Bill Discount Details',
  },
};

const crudRequest = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      input: {
        orderId: data.id,
        quantity: data.quantity,
        callId: data.callId,
        reason: data.reason,
      },
    }),
    responseName: 'updateOrder',
    message: 'Order updated successfully.',
  },

  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      orderIds: [data.id],
      reason: data.reason,
      callId: data.callId,
      type: RETURN_TYPE.PARTIAL,
      promotionOrderIds: data.promotionOrderIds,
    }),
    responseName: 'cancelOrders',
    message: 'Order updated successfully.',
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper1: (data) => ({
      orderIds: data.ids,
      reason: data.reason,
    }),
    objectMapper: (data) => ({
      orderIds: data.ids,
      reason: data.reason,
      callId: data.callId,
      type: data.type,
    }),
    responseName: 'cancelOrders',
    message: 'Orders cancelled successfully.',
  },
  [EVENT_OPERATION.SPLIT]: {
    objectMapper: (data) => ({
      quantity: data.splitList.map((item) => item.quantity),
      id: data.id,
    }),
    responseName: 'splitOrder',
    message: 'Orders splitted successfully.',
  },
  [EVENT_OPERATION.REPLACED]: {
    objectMapper: (data) => {
      const orderList = data.list.map((stock) => ({
        id: stock.id,
        replacementSkuId: stock.replacedSkuId,
        replacementSkuBatchId: stock.replacedSkuBatchId,
        replacementSkuQuantity: stock.replacedQuantity,
      }));
      return { orderList, status: data.type || SERVER_ORDER_STATUS[ORDER_TABS.REPLACED] };
    },
    responseName: 'updateReplacementOrders',
    message: 'Replacement orders status changed successfully.',
  },
};

export {
  formConfig, getSkuObj, crudRequest, calculatePromotionItemTotal
};
