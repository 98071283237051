import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import ProductForm from './Form';
import history from '../../../../../utils/history';
import { refGenerator } from '../../../../../utils';
import { DialogFormWrapper } from '../../../../common';
import ProductStyled from './ProductStyled';
import { CAMPAIGNS } from '../../../../../data/enums/Route';
import withAlert from '../../../../../utils/composition/withAlert';
import Confirmation from '../../../../common/DialogConfirmation';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { getPermissionForCampaigns } from '../../../../base/permission';
import { findAndReplaceItem } from '../../../../../utils/arrayProcessor';
import { isConfirmationType } from '../../../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../../utils/crudResponseProcessor';
import { dropdownChange, debouncer } from '../../../../../utils/formHandlers';
import { formConfig, crudRequest, totalViewRefs, totalPriceMapper } from './config';
import { clone } from '../../../../../utils/objectProcessor';

const propTypes = {
  update: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  serverResponseWaiting: PropTypes.bool,
  getStatus: PropTypes.func,
  getDetails: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  update: {
    type: '',
    status: true,
  },
  enableErrorDisplay: false,
  serverResponseWaiting: false,
  getStatus: () => null,
  getDetails: () => null,
};

class SubCampaign extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { data: nextProps.data };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.permission = getPermissionForCampaigns();
    this.state = {
      data: {
        productArr: [],
        priceDetails: {},
      },
      dialog: {
        type: '',
        element: '',
      },
      totalAmount: totalPriceMapper({}),
    };
    const serverCall = {
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequest, serverCall);
    this.formReference = refGenerator(['value']);
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getDetails(this.exportData);
  }

  responseProcessor = () => {
    const onAPIRequestSuccess = type => response => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequest[type].message, this.directToMainPage, response);
    };
    return onAPIRequestSuccess;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getValidationStatus = () => {
  };

  exportData = () => {
    const { data } = this.state;
    return { ...data };
  };


  handleIconClick = (type, element = {}) => {
    if (type === EVENT_OPERATION.DELETE) {
      this.setState({
        dialog: {
          type,
          element: { ...element, id: element?.idx },
        },
      });
      return;
    }
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  handleCancel = (name, label, data) => {
  };

  handleAdd = (title, data) => {
  };

  handleFormSubmit = (type, dialogData, error) => {
    if (type === EVENT_OPERATION.DELETE) {
      this.handleRowDelete(dialogData);
    }
    else if (type === EVENT_OPERATION.CREATE) {
      this.handleDataUpdate({ ...dialogData }, type);
    }
    else {
      this.handleDataUpdate({ ...dialogData }, type);
    }
  };

  calcTotalEach = (items, param) => {
    const sum = items?.reduce?.((accumulator, item) => accumulator + item?.[param], 0);
    return sum;
  }

  calculatePriceDetails = () => {
    const { data } = this.state;
    data.priceDetails.subTotal = this.calcTotalEach(data?.productArr, 'grossWithVat');
    data.priceDetails.gross = this.calcTotalEach(data?.productArr, 'gross');
    data.priceDetails.taxableAmount = this.calcTotalEach(data?.productArr, 'taxableAmount');
    data.priceDetails.taxAmount = this.calcTotalEach(data?.productArr, 'taxAmount');
    data.priceDetails.netAmount = this.calcTotalEach(data?.productArr, 'netAmount');
    this.setState({ data });
  }
  handleDataUpdate = (dialogData, type) => {
    const { data } = this.state;
    const rate = dialogData?.product?.Rates?.[0];
    const priceDetails = rate?.priceDetails;
    const gross = (priceDetails?.rlp * dialogData?.quantity) ?? 0;
    const grossWithVat = ((priceDetails?.rlp * (1 + priceDetails?.vatPercentage)) * dialogData?.quantity) ?? 0;
    const taxableAmount = gross - dialogData?.discount ?? 0;
    const taxAmount = (gross - dialogData?.discount) * priceDetails?.vatPercentage ?? 0;
    const netAmount = (gross - dialogData?.discount) + taxAmount ?? 0;
    if (type === EVENT_OPERATION?.UPDATE) {
      const findindexd = data?.productArr?.findIndex?.(a => a.idx === dialogData?.idx)
      data.productArr[findindexd] = { ...dialogData, taxableAmount, taxAmount, netAmount, rate: priceDetails?.rlp, gross, grossWithVat }
    }
    else {
      data?.productArr.push({ ...dialogData, taxableAmount, taxAmount, netAmount, rate: priceDetails?.rlp, gross, grossWithVat, idx: Math.random().toString(16).slice(2) });
    }
    this.setState({ data }, () => {
      this.calculatePriceDetails();
    });
  };

  handleRowDelete = dialogData => {
    const { data } = this.state;
    const indexTemp = data?.productArr?.findIndex(item => item.idx === dialogData.id);
    data.productArr.splice(indexTemp, 1);
    this.setState({ data }, () => this.calculatePriceDetails())
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const { data, dialog } = this.state;
    const { type } = dialog;
    const { serverResponseWaiting, update, skus, orderId, enableErrorDisplay, totalAmount } = this.props;
    const orderLines = data.productArr ?? [];
    const priceDetails = totalAmount || {};
    return (
      <ProductStyled>
        {type && (
          <DialogFormWrapper
            formConfig={formConfig[type]}
            dialogElement={dialog.element}
            onDialogSubmit={this.handleFormSubmit}
            onDialogCancel={this.resetDialog}
            type={type}
            renderDialog={({ refsObj, dialogData, enableErrorDisplay, handleDropDownChange, handleInputChange }) => (
              <Fragment>
                {(type === EVENT_OPERATION.UPDATE || type === EVENT_OPERATION.CREATE) && (
                  <ProductForm
                    show
                    update={update}
                    refsObj={refsObj}
                    data={dialogData}
                    loading={serverResponseWaiting}
                    handleInputChange={handleInputChange}
                    handleDropDownChange={handleDropDownChange}
                    type={type}
                    handleCancel={this.handleCancel}
                    enableErrorDisplay={enableErrorDisplay}
                    skus={skus}
                  />
                )}
                {isConfirmationType(type) && Confirmation(type, 1)}
              </Fragment>
            )}
          />
        )}
        <View
          data={orderLines}
          update={update}
          onIconClick={this.handleIconClick}
          permission={this.permission}
          onBulkDelete={this.handleBulkDelete}
          onTableRowClick={() => null}
          orderDataObj={data}
          totalViewRefs={totalViewRefs}
          priceDetails={data?.priceDetails}
          enableErrorDisplay={enableErrorDisplay}
          orderId={orderId}
        />
      </ProductStyled>
    );
  }
}

SubCampaign.defaultProps = defaultProps;

SubCampaign.propTypes = propTypes;

export default withAlert()(SubCampaign);
