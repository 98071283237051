import styled from 'styled-components';

const NumberWrap = styled.div`
  .label {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    color: #6b6c7e;
    text-transform: uppercase;
    margin-bottom: 1px;
    &.subTitle {
      font-size: 8px;
      line-height: 10px;
      margin-bottom: 8px;
    }
    &.pad-top {
      padding-top: 6.5px;
    }
  }
`;

export default NumberWrap;
