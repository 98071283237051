import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import General from './General';
import Image from './Image';
import Orders from './Orders';
import OutletDetailWrap from './OutletDetailStyled';
import { has } from '../../../../../utils/objectPrototypes';
import LedgerInfo from './LedgerInfo';
import { crudSuccess, formConfig, breadCrumbConfig } from './config';
import history from '../../../../../utils/history';
import { refValidator } from '../../../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { getPermissionForOutlet, getPermissionToCreateOutlet } from '../../../../base/permission';
import { dropdownChange, inputChange } from '../../../../../utils/formHandlers';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../../../data/services';
import { OutletPermissionContext } from '../config';
import { PanelHeader, PanelStyled } from '../../../../common/configuration';
import CallHistory from './CallHistory';
import { Tab, TabList, TabPanel, Tabs, Button, BreadCrumb } from '../../../../../v4/components';

const propTypes = {
  getOutletDetail: PropTypes.func.isRequired,
  updateOutlet: PropTypes.func.isRequired,
  createOutlet: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  match: PropTypes.instanceOf(Object).isRequired,
  getRetailOutletHistory: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const outletData = {
  title: '',
  rMapId: 0,
  imageUrl: [],
  address: '',
  panNumber: '',
  active: true,
  geoLocation: {
    latitude: 0,
    longitude: 0,
  },
  Route: {
    id: '',
    title: '',
  },
  Town: {
    id: '',
    title: '',
  },
  Category: {
    id: '',
    title: '',
    Channel: {
      id: '',
      title: '',
    },
  },
  Channel: {
    id: '',
    title: '',
  },
  contactInfo: {
    name: '',
    phoneNumber: '',
  },
  socialContacts: {
    whatsapp: '',
    viber: '',
    landline: '',
  },
  Distributor: {
    servicesUsed: {
      billing: {
        status: false,
      },
    },
    id: '',
  },
  phoneNumberVerified: false,
  additionalInfo: {
    outletCode: '',
  },
};

class Detail extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.location?.state?.activeTab || Number(localStorage.getItem('outletActiveTab')) || 0,
      id: has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0,
      data: {
        ...outletData,
      },
      retailOutletHistory: [
        {
          outletid: 0,
          phonenumber: ' ',
          userid: 0,
          name: '',
          updatedtime: '',
          isverified: false,
        },
      ],
      menu: {
        channelList: [],
        categoryList: [],
        routeList: [],
        townList: [],
      },
      enableFormValidation: false,
      formReference: formConfig.refsObj,
      crudMode: props.match.params.id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
    };
    this.permission = getPermissionForOutlet();
    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    this.enableToUpdate = getPermissionToCreateOutlet();
  }

  componentDidMount() {
    const { match } = this.props;
    localStorage.removeItem('outletActiveTab');
    if (match.params.id) {
      this.getOutletDetail();
    }
    this.loadDataForDropDown();
  }

  onTabChange = tabIndex => {
    this.setState({ activeTab: tabIndex });
  };

  getOutletDetail() {
    const { data } = this.state;
    const { getOutletDetail, displayAlert, match } = this.props;
    getOutletDetail(
      {
        id: match.params.id.toString(),
      },
      {
        handleSuccess: response => {
          const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE].objectMapper(
            response.data ? response.data?.retailOutlets.rows[0] : data,
          );

          this.setState({ data: formattedDetail });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  getRetailOutletHistory = id => {
    const { getRetailOutletHistory, displayAlert } = this.props;
    getRetailOutletHistory(
      {
        outletId: id,
      },
      {
        handleSuccess: response => {
          this.setState({ retailOutletHistory: response.data?.getRetailOutletHistory });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL).then(response => {
      menu.channelList = response;
      this.setState({ menu });
    });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY).then(response => {
      menu.categoryList = response;
      this.setState({ menu });
    });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.TOWN).then(response => {
      menu.townList = response;
      this.setState({ menu });
    });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  onIconClick = () => {};

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedDetails = inputChange(data, event, firstParam, paramList);
    this.setState({ data: updatedDetails, enableFormValidation: true });
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    if (parameterRef[0] === 'Channel') {
      updatedData.Category.id = '';
      updatedData.Category.title = '';
    }
    this.setState({ data: updatedData });
  };

  transformData = data => ({
    title: data?.title,
    rMapId: data?.rMapId,
    address: data?.address === null ? '' : data?.address,
    panNumber: data?.panNumber ? data?.panNumber.toString() : data?.panNumber === 0 ? '' : data?.panNumber,
    imageUrl: data?.imageUrl,
    geoLocation: data?.geoLocation,
    townId: data?.Town.id,
    categoryId: data?.Category.id,
    contactInfo: {
      name: data?.contactInfo?.name,
      phoneNumber: data?.contactInfo?.phoneNumber.toString(),
    },
    socialContacts: {
      landline: data.socialContacts.landline && data?.socialContacts?.landline.toString(),
      whatsapp: data.socialContacts.whatsapp && data?.socialContacts?.whatsapp.toString(),
      viber: data.socialContacts.viber && data?.socialContacts?.viber.toString(),
    },
    active: data.active,
    additionalInfo: {
      outletCode: data.additionalInfo?.outletCode,
    },
  });

  handleButtonSubmit = () => {
    const { formReference, data, crudMode } = this.state;
    const { updateOutlet, createOutlet, displayAlert } = this.props;
    const formValidation = refValidator(formReference);
    if (!formValidation) {
      this.setState({ enableFormValidation: true });
    } else {
      const formattedData = this.transformData(data);
      if (crudMode === EVENT_OPERATION.CREATE) {
        createOutlet(
          { input: formattedData },
          {
            handleSuccess: res => {
              // eslint-disable-next-line max-len
              const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE].objectMapper(
                res.data && res.data.createRetailOutlet,
              );
              this.setState({ data: formattedDetail, crudMode: EVENT_OPERATION.READ });
              displayAlert(ALERT_TYPE.SUCCESS, 'Outlet Created');
              history.push(`/sales/outlet/details/${formattedDetail.id}`);
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }

      if (crudMode === EVENT_OPERATION.UPDATE) {
        updateOutlet(
          {
            id: data?.id,
            input: formattedData,
          },
          {
            handleSuccess: response => {
              // eslint-disable-next-line max-len
              const formattedDetail = crudSuccess[EVENT_OPERATION.UPDATE].objectMapper(
                response.data && response.data?.updateRetailOutlet,
              );
              this.setState({ data: formattedDetail, crudMode: EVENT_OPERATION.READ });
              displayAlert(ALERT_TYPE.SUCCESS, 'Outlet Updated');
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
    }
  };

  handleButtonCancel = () => {
    const { crudMode, data } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: {
          ...outletData,
          id: data?.id,
        },
      });
    } else {
      this.setState({
        crudMode: EVENT_OPERATION.READ,
      });
      this.getOutletDetail();
    }
  };

  handleEditIconClick = () => {
    this.setState({ crudMode: EVENT_OPERATION.UPDATE });
  };

  getGeoLocation = location => {
    const { data } = this.state;
    const { geoLocation } = data;
    const geoData = {
      ...data,
      ...{ geoLocation: { ...geoLocation, ...location } },
    };
    this.setState({ data: geoData });
  };

  render() {
    const { activeTab, id, data, menu, crudMode, formReference, enableFormValidation, retailOutletHistory } =
      this.state;
    const disableEditButton = crudMode === EVENT_OPERATION.UPDATE;
    // const !disableEditButton = crudMode !== EVENT_OPERATION.UPDATE;
    const { serverResponseWaiting } = this.props;
    const title = crudMode === EVENT_OPERATION.CREATE ? 'Create Outlet' : `${data?.title}`;

    return (
      <OutletPermissionContext.Provider value={{ permission: this.permission }}>
        <Fragment>
          <div className="section-header">
            <PanelStyled className="padding-0">
              <BreadCrumb list={breadCrumbConfig} />
              <PanelHeader>
                <div className="flex items-center justify-between m-0 flex-1">
                  <h2>{disableEditButton ? `Edit ${data?.title}` : title}</h2>
                  <div className="flex m-0">
                    {crudMode !== EVENT_OPERATION.READ && (
                      <div>
                        <Button
                          secondary
                          disabled={serverResponseWaiting}
                          small
                          title="Cancel"
                          onClick={() => this.handleButtonCancel()}
                        />
                        <Button
                          primary
                          small
                          disabled={serverResponseWaiting}
                          title="Save"
                          onClick={() => this.handleButtonSubmit()}
                        />
                      </div>
                    )}
                    {crudMode === EVENT_OPERATION.READ && this.permission.update && (
                      <div className="text-right">
                        <Button
                          secondary
                          iconBtnSmall
                          iconName="pencil"
                          onClick={this.handleEditIconClick}
                          disabled={this.disableEditButton}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </PanelHeader>
            </PanelStyled>
          </div>
          <div className="section-content section-tab">
            {crudMode === EVENT_OPERATION.READ ? (
              <OutletDetailWrap>
                <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.onTabChange(tabIndex)}>
                  <TabList>
                    <Tab>
                      <span className="tab-label">Detail</span>
                    </Tab>
                    <Tab>
                      <span className="tab-label">Call History</span>
                    </Tab>
                    <Tab>
                      <span className="tab-label">Order History</span>
                    </Tab>
                    <Tab>
                      <span className="tab-label">Images</span>
                    </Tab>
                    <Tab>
                      <span className="tab-label">Ledger Info</span>
                    </Tab>
                  </TabList>
                  <div className="tab-content-wrap">
                    <TabPanel>
                      <section id="detail-tab">
                        <General
                          data={data}
                          id={id}
                          menu={menu}
                          crudMode={crudMode}
                          refsObj={formReference}
                          loading={serverResponseWaiting}
                          retailOutletHistory={retailOutletHistory}
                          getRetailOutletHistory={this.getRetailOutletHistory}
                          onInputChange={this.handleInputChange}
                          enableErrorDisplay={enableFormValidation}
                          handleButtonSubmit={this.handleButtonSubmit}
                          handleButtonCancel={this.handleButtonCancel}
                          onDropDownChange={this.handleDropDownChange}
                          handleEditIconClick={this.handleEditIconClick}
                          getGeoLocation={this.getGeoLocation}
                        />
                      </section>
                    </TabPanel>
                    <TabPanel>
                      <section id="call-history-tab">
                        <CallHistory id={id} />
                      </section>
                    </TabPanel>
                    <TabPanel>
                      <section id="order-history-tab">
                        <Orders id={id} />
                      </section>
                    </TabPanel>
                    <TabPanel>
                      <section id="image-tab">
                        <Image id={id} />
                      </section>
                    </TabPanel>
                    <TabPanel>
                      <section id="ledger-tab">
                        <LedgerInfo id={id} townId={data?.Town && data?.Town.id} />
                      </section>
                    </TabPanel>
                  </div>
                </Tabs>
              </OutletDetailWrap>
            ) : (
              <General
                id={id}
                data={data}
                menu={menu}
                crudMode={crudMode}
                refsObj={formReference}
                loading={serverResponseWaiting}
                retailOutletHistory={retailOutletHistory}
                getRetailOutletHistory={this.getRetailOutletHistory}
                onInputChange={this.handleInputChange}
                enableErrorDisplay={enableFormValidation}
                handleButtonSubmit={this.handleButtonSubmit}
                handleButtonCancel={this.handleButtonCancel}
                onDropDownChange={this.handleDropDownChange}
                handleEditIconClick={this.handleEditIconClick}
                getGeoLocation={this.getGeoLocation}
              />
            )}
          </div>
        </Fragment>
      </OutletPermissionContext.Provider>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export { Detail };

export default withAlert()(Detail);
