import { USER_ROLE_TITLE } from './UserRole';
import { JWT } from '../../environment';

const headerLabelConfig = (user) => {
  if (JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.LABEL.HEADER))) {
    return JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.LABEL.HEADER))[user];
  }
};

const FILTER = {
  USER_OPTION: {
    TITLE: '',
    LABEL: 'userOption',
    VALUE: 'user_option',
  },
  CALL_STATUS: {
    TITLE: 'Call Status',
    LABEL: 'call_status',
    VALUE: 'call_status_id',
  },
  PERIOD: {
    TITLE: '',
    LABEL: 'Period',
    VALUE: 'user_option',
  },
  SUB_D: {
    TITLE: 'Sub D',
    LABEL: 'sub_d',
    VALUE: 'distributor_id',
  },
  CHANNEL: {
    TITLE: 'Channel',
    LABEL: 'channel',
    VALUE: 'channel_id',
  },
  CATEGORY: {
    TITLE: 'Category',
    LABEL: 'category',
    VALUE: 'category_id',
  },
  PRINCIPAL: {
    TITLE: 'Principal',
    LABEL: 'principal',
    VALUE: 'principal_id',
  },
  PRODUCT_GROUP: {
    TITLE: 'Product Group',
    LABEL: 'product_group',
    VALUE: 'product_group_id',
  },
  BRAND: {
    TITLE: 'Brand',
    LABEL: 'brand',
    VALUE: 'brand_id',
  },
  BRANCH_HEAD: {
    TITLE: `${headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'} / TAS / Branch Head`,
    LABEL: 'branch_head',
    VALUE: 'user_id',
  },
  SUB_BRAND: {
    TITLE: 'Sub Brand',
    LABEL: 'sub_brand',
    VALUE: 'sub_brand_id',
  },
  SUB_BRAND_FORM: {
    TITLE: 'Sub Brand Form',
    LABEL: 'sub_brand_form',
    VALUE: 'sub_brand_form_id',
  },
  MARKET_CATALOG: {
    TITLE: 'Market Catalog',
    LABEL: 'market_catalog',
    VALUE: 'market_catalog_id',
  },
  SKU_FAMILY: {
    TITLE: 'SKU Family',
    LABEL: 'sku_family',
    VALUE: 'sku_family_id',
  },
  SKU: {
    TITLE: 'SKU',
    LABEL: 'sku',
    VALUE: 'sku_id',
  },
  TOWN: {
    TITLE: 'Town',
    LABEL: 'town',
    VALUE: 'town_id',
  },
  TERRITORY: {
    TITLE: 'Territory',
    LABEL: 'territory',
    VALUE: 'territory_id',
  },
  USER_ROLE: {
    TITLE: 'User Role',
    LABEL: 'user_role',
    VALUE: 'role_id',
  },
  ROUTE: {
    TITLE: 'Route',
    LABEL: 'route',
    VALUE: 'route_id',
  },
  ROUTE_VISIT_FREQUENCY: {
    TITLE: 'Visit Frequency',
    LABEL: 'route_visit_frequency',
    VALUE: 'visit_frequency_id',
  },
  ROUTE_VISIT_TYPE: {
    TITLE: 'Visit Type',
    LABEL: 'route_visit_type',
    VALUE: 'visit_type_id',
  },
  ROUTE_VISIT_CATEGORY: {
    TITLE: 'Visit Category',
    LABEL: 'route_visit_category',
    VALUE: 'visit_category_id',
  },
  HIERARCHY_STATUS: {
    TITLE: 'Status',
    LABEL: 'status',
    VALUE: 'status',
  },
  STATUS: {
    TITLE: 'Status',
    LABEL: 'active',
    VALUE: 'active',
  },
  MESSAGING_PLATFORM: {
    TITLE: 'Messaging Platform',
    LABEL: 'platform',
    VALUE: 'platform',
  },
  LEAVE_STATUS: {
    TITLE: 'Status',
    LABEL: 'leave_status',
    VALUE: 'status',
  },
  LEAVE_TYPE: {
    TITLE: 'Type',
    LABEL: 'leave_type',
    VALUE: 'leave_type',
  },
  BUSINESS_TYPE: {
    TITLE: 'Business Type',
    LABEL: 'business_type',
    VALUE: 'type',
  },
  PROMOTION_TYPE: {
    TITLE: 'Promotion Type',
    LABEL: 'promotion_type',
    VALUE: 'type',
  },
  DSE: {
    TITLE: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    LABEL: 'dse',
    VALUE: 'user_id',
  },
  DSE_OUTLET: {
    TITLE: `${headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'}`,
    LABEL: 'dse_outlet',
    VALUE: 'user_id',
  },
  DSE_TAS: {
    TITLE: `${headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'} / TAS`,
    LABEL: 'dse_tas',
    VALUE: 'user_id',
  },
  ASM: {
    TITLE: headerLabelConfig(USER_ROLE_TITLE.ASM) || 'ASM',
    LABEL: 'asm',
    VALUE: 'asm_id',
  },
  ZONE: {
    TITLE: 'Zone',
    LABEL: 'zone',
    VALUE: 'zone_id',
  },
  STL: {
    TITLE: headerLabelConfig(USER_ROLE_TITLE.STL) || 'STL',
    LABEL: 'stl',
    VALUE: 'stl_id',
  },
  PAYMENT_STATUS: {
    TITLE: 'Status',
    LABEL: 'payment_status',
    VALUE: 'status',
  },
  COLLECTION_TYPE: {
    TITLE: 'Collection Type',
    LABEL: 'collection_type',
    VALUE: 'collection_type',
  },
  AMOUNT: {
    TITLE: 'Amount',
    LABEL: 'amount',
    VALUE: 'amount',
  },
  BANK: {
    TITLE: 'Bank',
    LABEL: 'bank',
    VALUE: 'bank',
  },
  OUTSTANDINGS_STATUS: {
    TITLE: 'Status',
    LABEL: 'transaction_status',
    VALUE: 'transaction_status',
  },
  OSR_STATUS: {
    TITLE: 'Current Status',
    LABEL: 'current_status',
    VALUE: 'current_status',
  },
  REQUESTS_STATUS: {
    TITLE: 'Status',
    LABEL: 'request_status',
    VALUE: 'status',
  },
  MERCHANDISE_DEVICE: {
    TITLE: 'Merchandise Device',
    LABEL: 'merchandise_device',
    VALUE: 'device_id',
  },
  POWER_SKU: {
    TITLE: 'Power SKU',
    LABEL: 'power_sku',
    VALUE: 'power_sku',
  },
  TAS_USER:{
    TITLE: 'TAS',
    LABEL: 'tas_user',
    VALUE: 'tas_user_call_plan.user_id',
  },
  TAS:{
    TITLE: 'TAS',
    LABEL: 'tas',
    VALUE: 'user_id',
  },
  COLLECTOR:{
    TITLE: 'Collector',
    LABEL: 'collector',
    VALUE: 'collector_id',
  }
};

const MAX_LIMIT_OFFSET_FILTER = {
  offset: 0,
};

export { FILTER, MAX_LIMIT_OFFSET_FILTER };
