import gql from 'graphql-tag';
import { CHANNEL_FRAGMENT } from '../channel/API';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_CHANNEL_LIST = gql`
  query getChannel($offset: Int, $limit: Int, $filter: FilterInput) {
    channels(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...ChannelFragment
      }
      count
    }
  }
  ${CHANNEL_FRAGMENT}
`;

const query = {
  getChannelList: GET_CHANNEL_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, DOWNLOAD_REPORT
};
