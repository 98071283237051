import { compose } from 'recompose';
import WarehouseInventory from './WarehouseInventory';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { query, mutation, DOWNLOAD_REPORT, TRANSFER_SKU_BATCH } from './API';

const ComposedWarehouseInventory = compose(
  DOWNLOAD_REPORT,
  TRANSFER_SKU_BATCH,
  withRequestTracker({
    query,
    mutation,
  }),
)(WarehouseInventory);

export default ComposedWarehouseInventory;
