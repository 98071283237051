import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import {
  GET_DISTRIBUTORS_SERVICES, GET_OUTLETS, GET_SKU_WITH_BATCH
} from '../../../common/GqlAPI';
import {
  GET_INVOICE_BY_INVOICE_NUMBER, GET_SKU_BATCH_DETAILS, VALIDATE_SRN_DATA
} from '../create/API';
import { GET_SRN_DETAIL } from '../details/API';

const APPROVE_SRN = graphql(
  gql`
    mutation ($id: Int!, $input: SRNInput!) {
      approveSalesReturn(id: $id, input: $input) {
        id
        SRNInvoiceNumber
        invoiceId
        retailOutletId
        distributorId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      approveSalesReturn: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CANCEL_SRN = graphql(
  gql`
    mutation ($id: Int!) {
      cancelSalesReturn(id: $id) {
        ids
        message
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      cancelSalesReturn: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getOutlets: GET_OUTLETS,
  getSkus: GET_SKU_WITH_BATCH,
  getSrnDetails: GET_SRN_DETAIL,
  validateSrnData: VALIDATE_SRN_DATA,
  getSKUBatchDetail: GET_SKU_BATCH_DETAILS,
  getDistributors: GET_DISTRIBUTORS_SERVICES,
  getInvoiceDetail: GET_INVOICE_BY_INVOICE_NUMBER,
};

const mutation = {
  approveSalesReturn: APPROVE_SRN,
  cancelSalesReturn: CANCEL_SRN,
};

export {
  query, mutation, APPROVE_SRN, CANCEL_SRN
};
