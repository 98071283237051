const STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

const LINE_TYPE = {
  NORMAL: 'NORMAL',
  DEFAULT: 'DEFAULT',
};

export { STATUS, LINE_TYPE };
