import PropTypes from 'prop-types';
import { CheckBox } from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { menuAction, tableData } from '../../../../v4/components';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import { fixedFloatAndCommas } from '../../../../utils/conversion';

const labelMappings = {
  checkbox: 'checkbox',
  month: 'month',
  target: 'target',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: false,
  },
  {
    id: 2,
    label: labelMappings.month,
    title: 'Month',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.target,
    title: 'Targets',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.actionHolders,
    title: '',
    show: false,
  },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.month] = <td key={`${data.id}-title`}>{`${data.monthName}, ${data.year}`}</td>;
  table[labelMappings.target] = <td key={`${data.id}-target`}>{fixedFloatAndCommas(data?.national_target)}</td>;

  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr key={data.id} onClick={() => onIconClick(EVENT_OPERATION.DETAILS, data)} className='cursor-pointer'>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) => (
  <thead className=''>
    <tr>
      {labelConfig.map((label) =>
        label.show
          ? label.label === labelMappings.checkbox
            ? dataListLength > 0 &&
              permission.delete && (
              <th>
                <CheckBox
                  checked={dataListLength === checkedListLength}
                  onCheckBoxClick={(e) => primaryCheckboxHandler(e)}
                />
              </th>
            )
            : tableData(label)
          : null,
      )}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
