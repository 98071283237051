import classNames from 'classnames';
import ConfigPanelStyled from './ConfigPanelStyled';

interface PanelCardProps {
  children: any;
  className: string;
  cardTitle: string;
  skuClassStatus: boolean;
}

const PanelCard = ({ children, className, cardTitle, skuClassStatus }: PanelCardProps) => {
  const cardclasses = classNames(' config-panel-card', { sku: skuClassStatus }, className);
  return (
    <ConfigPanelStyled>
      <div className={cardclasses}>
        <h3 className='config-panel-title'>{cardTitle.toUpperCase()}</h3>
        <div className='config-panel-contents'>{children}</div>
      </div>
    </ConfigPanelStyled>
  );
};

PanelCard.defaultProps = {
  children: null,
  className: '',
  cardTitle: '',
  skuClassStatus: false,
};

export default PanelCard;
