import { compose } from 'recompose';
import ReplenishmentModel from './ReplenishmentModel';
import {
  query, mutation, CREATE_ICO_STOCK_GROUPS, UPDATE_ICO_STOCK_GROUPS, TOGGLE_STATE
} from './API';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const composedReplenishmentModel = compose(
  CREATE_ICO_STOCK_GROUPS,
  UPDATE_ICO_STOCK_GROUPS,
  TOGGLE_STATE,
  withRequestTracker({ query, mutation }),
)(ReplenishmentModel);

export default composedReplenishmentModel;
