import React from 'react'
import styled from 'styled-components';
import { CustomSelect, Input } from '../../../../../components';
import { periodType } from '../../../../../data/enums/Notification';
import { SingleDatePicker } from '../../../../../v4/components';

const UnsuccessFullcallForm = ({ dialogData,onRadioButtonChange,onInputChange,reasonList,handleDropDownChange }) => {
  return (
    <UnsuccessFulFormStyled>
      <div className='no-order-dialog_body'>
        <div className='title'>Reasons</div>

        {reasonList.map((list)=>(
          <>
            <label className='radio-list'>
              <div className='radio-input'>
                <input
                  type='radio'
                  name='remarks'
                  value={list.title}
                  onChange={(e) => onRadioButtonChange(e,[],()=>null,'noLowerCase',list.completecall)}
                  checked={dialogData.remarks.receive}
                />
                <span className='text'>{list.title}</span>
              </div>
            </label>
          </>
        ))}
        <label className='radio-list other'>
          <div className='radio-input'>
            <input
              type='radio'
              name='remarks'
              value='Other'
              onChange={(e) => onRadioButtonChange(e,[],()=>null,'noLowerCase',true)}
              checked={dialogData.remarks.others}
            />
            <span className='text'>Other</span>
          </div>
          <textarea
            name='message'
            type='text'
            id='message'
            placeholder='Message'
            value={dialogData.message}
            onChange={(event) => onInputChange({ ...event,name:'message', formattedValue: event?.target.value },'')}
            // className={(dialogData.reason===''||data.reason==='noofftake'||data.reason==='marginissue')?'disabled':''}
          />
        </label>
        <div className='custom-date'>
          <label className='call-date-label'>Schedule Call Date</label>
          <SingleDatePicker
            date={dialogData.startDate}
            name='date'
            onChange={(name, date) =>
            {
              onInputChange({
                target: {
                  name: 'startDate',
                  value: date,
                },
                formattedValue: date,
              })
            }

            }
          />
        </div>
        <label>Time</label>
        <div className='time'>
          <div className='time__hours'>
            <Input
              value={dialogData.times.hours}
              type='number'
              onChange={(event) => onInputChange(event, 'times', ['times', 'hours'])}
              argument={{
                max: 12,
              }}
            />
            <label>HH</label>
          </div>
          <div className='time__hours'>
            <Input
              value={dialogData.times.minutes}
              type='number'
              placeholder='MM'
              onChange={(event) => onInputChange(event, 'times', ['times', 'minutes'])}
              argument={{
                max: 59,
              }}
            />
            <label>MM</label>
          </div>
          <div className='time__hours'>
            <CustomSelect
              name='period'
              className='custom-select'
              placeholder = 'AM/PM'
              options={periodType}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              value={periodType.filter(({ title }) => title === dialogData.times.period)}
              onChange={(event) => handleDropDownChange(event.title, ['times', 'period'])}
            />
            <label>AM/PM</label>
          </div>
        </div>
      </div>
    </UnsuccessFulFormStyled>
  )
}

const UnsuccessFulFormStyled = styled.div`
  .disabled {
    cursor: not-allowed;
      pointer-events: none ;
  }

 
    .call-date-label {
      display:block;
      Width:130px;
      height:21px;
      Size:14px;
      line-height:21px;
      margin-bottom:10px;
    }  
    
    .time {
      display : flex;
      width: 60%;
      margin-bottom: 32px;
      > label {
        width: calc(166.57% - 10px);
        color: #272833;
        font-size: 14px;
        font-weight: 500;
        margin-top: 13px;
        padding-left: 4px;
      }
      .form-input{
        width:50%
        margin-bottom: 0px;
      }
      .form-select-input{
        > label {
          display: none;
        }
        > div {
              width: 100%;
              margin-bottom: 0px;
        }
        }
        .time__hours{
            display:flex;
            flex-direction: column;
             width:100%;
           .form-input{
              width:calc(100% - 10px);
              margin-right: 10px;
              margin-bottom: 0px;
            }
            .form-select-input{
               margin-bottom: 0px;
               .zindex-2__value-container{
                padding: 2px 13px;
               }
            }
            > label {
              font-size: 10px;
              color: #A7A9BC;
              margin-top: 6px;
              margin-left: 16px;
              line-height: 10px;
            }
        }
    }

  .modal-inner.unsuccessful_call  {
    max-width: 20% ;
  }
  .no-order-dialog_body{
    width: 100%;
    .title{
      font-size:18px;
      line-height:27px;
      font-weight:500;
      padding-bottom:24px;
      border-bottom:1px solid #E7E7ED;
    }
    &>label{
      &:not(.radio-list){
        font-size:16px;
        line-height:19px;
        color:#272833;
        display:block;
        padding:24px 0 14px 0;
        font-weight:500;
      }
      &.radio-list{
        border:1px solid #E7E7ED;
        padding:15px 18px ;
        border-radius:4px;
        display:flex;
        margin-bottom:14px;
        justify-content:space-between;
        align-items:center;
        span{
          font-size:14px ;
          line-height:21px;
          color:#272833;
        }
        .rlp-input{
          display:flex;
          align-items:center;
          label{
            font-size:12px;
            line-height:14px;
            color:#272833;
            margin-right:8px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
           -webkit-appearance: none;
            margin: 0;
              }
          .form-input input{
            border:1px solid #80ACFF;
            border-radius:4px;
            background-color:#F0F5FF;
            height:24px;
            min-width:60px;
            width:60px;
            padding:5px;
            font-size:12px;
            color:#272833;
            text-align:right;
          }
        }
        &.other{
          flex-direction:column;
          align-items: revert;
          textarea{
            margin-left:28px;
            margin-top:15px;
            border-radius:4px ;
            border:1px solid #E7E7ED;
            background-color:#F1F2F5;
            resize:none;
            padding:10px 16px;
            font-size:16px;
            line-height:24px;
            color:#6B6C7E;
          }
        }
      }
    }
  }
`

export default UnsuccessFullcallForm
