import React from 'react';
import PropTypes from 'prop-types';
import StockLedgerChildView from './childView';
import { TableBody } from '../tableConfig';
import { AccordionTable } from '../../../../components/CollapsibleTable';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func.isRequired,
  ledgerDetail: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: [],
  ledgerDetail: [],
};

const StockLedgerView = ({ ...props }) => {
  const { data, onRowClick, ledgerDetail } = props;
  return (
    <tbody>
      {
        <AccordionTable>
          {data.length > 0 &&
            data.map((stockledger) => (
              <tr
                key={stockledger.skuId}
                trigger={TableBody({
                  data: stockledger,
                  onRowClick,
                })}
              >
                {StockLedgerChildView({ data: ledgerDetail || [] })}
              </tr>
            ))}
        </AccordionTable>
      }
    </tbody>
  );
};

StockLedgerView.propTypes = propTypes;

StockLedgerView.defaultProps = defaultProps;

export default StockLedgerView;
