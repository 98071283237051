import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { fixedFloatAndCommas } from '../../../utils/conversion';

const labelMappings = {
  brand: 'brand',
  sku_family: 'sku_family',
  sku: 'sku',
  upc: 'upc',
  reorder_moq: 'reorder_moq',
  sit: 'sit',
  pso: 'pso',
  total_stock: 'total_stock',
  avg_p3m_sales: 'avg_p3m_sales',
  heavy_ups: 'heavy_ups',
  crr: 'crr',
  icod: 'icod',
  ico: 'ico',
  current_stock: 'current_stock',
  diff_in_ico_and_fi: 'diff_in_ico_and_fi',
  stock_holding_days: 'stock_holding_days',
  order_in_pcs: 'order_in_pcs',
  order_in_cs: 'order_in_cs',
  doh: 'doh',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },

  {
    id: 2,
    label: labelMappings.sku_family,
    title: 'SKU Family',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.sku,
    title: 'SKU Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.upc,
    title: 'Case Size',
    show: true,
    className: 'text-right',
  },
  {
    id: 5,
    label: labelMappings.reorder_moq,
    title: 'Re-order MOQ (Pcs)',
    show: true,
    className: 'text-right',
  },
  {
    id: 6,
    label: labelMappings.sit,
    title: 'Stock In Transit (SIT)',
    show: true,
    className: 'text-right',
  },
  {
    id: 7,
    label: labelMappings.pso,
    title: 'Pending Sales Orders (PSO)',
    show: true,
    className: 'text-right',
  },
  {
    id: 8,
    label: labelMappings.current_stock,
    title: 'Current Stock',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 9,
    label: labelMappings.avg_p3m_sales,
    title: 'P3M Average Sales',
    show: true,
    className: 'text-right',
  },
  {
    id: 10,
    label: labelMappings.heavy_ups,
    title: 'Target',
    show: true,
    className: 'text-right',
  },
  {
    id: 11,
    label: labelMappings.crr,
    title: 'CRR',
    show: true,
    className: 'text-right',
  },
  {
    id: 12,
    label: labelMappings.icod,
    title: 'ICO Demand',
    show: true,
    className: 'text-right',
  },
  {
    id: 13,
    label: labelMappings.ico,
    title: 'ICO',
    show: true,
    className: 'text-right ',
  },
  {
    id: 14,
    label: labelMappings.total_stock,
    title: 'Floor Inventory (FI)',
    show: true,
    className: 'text-right',
  },
  {
    id: 15,
    label: labelMappings.diff_in_ico_and_fi,
    title: 'Difference (ICO vs FI)',
    show: true,
    className: 'text-right',
  },
  {
    id: 16,
    label: labelMappings.stock_holding_days,
    title: 'Stock Holding Days',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 17,
    label: labelMappings.order_in_pcs,
    title: 'Order Requirement (Pcs)',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 18,
    label: labelMappings.order_in_cs,
    title: 'Order Requirement (Case)',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 19,
    label: labelMappings.doh,
    title: 'Days Of Holding (DOH)',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
);

const returnTableBody = data => {
  const table = {};
  if (!data.sku) delete labelConfig[2];
  table[labelMappings.brand] = <td key={`${data.id}-brand`}>{data.brand}</td>;
  table[labelMappings.sku_family] = <td key={`${data.id}-id`}>{data.sku_family}</td>;
  table[labelMappings.sku] = <td key={`${data.id}-sku`}>{data.sku}</td>;
  table[labelMappings.upc] = (
    <td key={`${data.id}-upc`} className="text-right">
      {fixedFloatAndCommas(data.upc)}
    </td>
  );
  table[labelMappings.reorder_moq] = (
    <td key={`${data.id}-reorder_moq`} className="text-right">
      {data.reorder_moq === 0 ? fixedFloatAndCommas(data.upc) : fixedFloatAndCommas(data.reorder_moq)}
    </td>
  );
  table[labelMappings.sit] = (
    <td key={`${data.id}-sit`} className="text-right">
      {fixedFloatAndCommas(data.sit)}
    </td>
  );
  table[labelMappings.pso] = (
    <td key={`${data.id}-pso`} className="text-right">
      {fixedFloatAndCommas(data.pso)}
    </td>
  );
  table[labelMappings.total_stock] = (
    <td key={`${data.id}-total_stock`} className="text-right">
      {fixedFloatAndCommas(data.total_stock)}
    </td>
  );
  table[labelMappings.avg_p3m_sales] = (
    <td key={`${data.id}-avg_p3m_sales`} className="text-right">
      {fixedFloatAndCommas(data.avg_p3m_sales)}
    </td>
  );
  table[labelMappings.heavy_ups] = (
    <td key={`${data.id}-heavy_ups`} className="text-right">
      {fixedFloatAndCommas(data.heavy_ups)}
    </td>
  );
  table[labelMappings.crr] = (
    <td key={`${data.id}-crr`} className="text-right">
      {fixedFloatAndCommas(data.crr)}
    </td>
  );
  table[labelMappings.icod] = (
    <td key={`${data.id}-icod`} className="text-right">
      {fixedFloatAndCommas(data.icod)}
    </td>
  );
  table[labelMappings.ico] = (
    <td key={`${data.id}-ico`} className="text-right">
      {fixedFloatAndCommas(data.ico)}
    </td>
  );
  table[labelMappings.current_stock] = (
    <td key={`${data.id}-current_stock`} className="text-right">
      {fixedFloatAndCommas(data.current_stock)}
    </td>
  );
  table[labelMappings.diff_in_ico_and_fi] = (
    <td key={`${data.id}-diff_in_ico_and_fi`} className="text-right">
      {fixedFloatAndCommas(data.diff_in_ico_and_fi)}
    </td>
  );
  table[labelMappings.order_in_pcs] = (
    <td key={`${data.id}-order_in_pcs`} className="text-right">
      {fixedFloatAndCommas(data.order_in_pcs)}
    </td>
  );
  table[labelMappings.order_in_cs] = (
    <td key={`${data.id}-order_in_cs`} className="text-right">
      {fixedFloatAndCommas(data.order_in_cs)}
    </td>
  );
  table[labelMappings.doh] = (
    <td key={`${data.id}-doh`} className="text-right">
      {fixedFloatAndCommas(data.doh)}
    </td>
  );
  table[labelMappings.stock_holding_days] = (
    <td key={`${data.id}-stock_holding_days`} className="text-right">
      {fixedFloatAndCommas(data.stock_holding_days)}
    </td>
  );

  return table;
};

const TableBody = ({ data }) => {
  const tableBody = returnTableBody(data);
  return (
    <tr key={`userCluster-${data.id}`}>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export { TableHeaderConfig as TableHeader, TableBody };
