import { refGenerator } from '../../../../../utils';
import { STATEFUL_ENTITIES } from '../../../../../data/enums/GraphQL';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { CONFIRMATION_CONFIG, FORM_CONFIG } from '../../../../../data/enums/config';

const title = 'Sub D Group';

const formMapper = (element) => ({
  id: element.id || '',
  title: element.title || '',
  active: element.active || true,
});

const requiredList = ['title'];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.MAPPER]: (element) => formMapper(element || {}),
    [FORM_CONFIG.TITLE]: `Create ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: (element) => formMapper(element || {}),
    [FORM_CONFIG.TITLE]: `Edit ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },

  ...CONFIRMATION_CONFIG,
};

const crudRequestConfig = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => {
      return { title: data.title };
    },
    responseName: 'createDistributorGroup',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => {
      return { title: data.title, id: data.id };
    },
    responseName: 'updateDistributorGroup',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.SUB_D_ASSORTMENT,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: STATEFUL_ENTITIES.SUB_D_ASSORTMENT,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export { formConfig, crudRequestConfig };
