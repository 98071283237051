import { configuration } from '../../../../data/enums/Route';
import { subD, replenishment } from '../../../common/DomainConfig';

const title = replenishment.title;

const breadCrumb = [
  configuration,
  {
    id: 1,
    title: subD.title,
    path: '#',
    active: true,
  },
];

export { breadCrumb as breadCrumbConfig, title as subDAssortmentTitle };
