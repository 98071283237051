import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Target & Achievement';

const breadCrumb = [reportsRoute];

const filter = getFilterConfig([FILTER.BRAND, FILTER.SKU]);

export {
  title, breadCrumb as breadCrumbConfig, filter as filterConfig
};
