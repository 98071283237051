import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableHeader } from '../tableConfig';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withImageSlider from '../../../../../utils/composition/withImageSlider';
import SplitPayment from '../../../../components/SplitPayment';
import { Pagination } from '../../../../../v4/components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import ReportTableStyled from '../../../../reports/ReportTableStyled';
import BulkSelect from '../../../../components/BulkSelect';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { dataProps, paginationProps } from '../../../../../data/enums/Component';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';

const propTypes = {
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  received: dataProps.value,
  onPageChange: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  fetchAllData: PropTypes.func,
  pagination: paginationProps.value,
  handleSliderOpen: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  received: dataProps.defaultValue,
  onPageChange: () => null,
  permission: PERMISSION_OBJ,
  fetchAllData: () => null,
  pagination: paginationProps.defaultValue,
};

class Received extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  getStatus = (status, statusType) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE[statusType === 'err' ? 'INFO' : 'SUCCESS'], status);
  };

  render() {
    const { dialog } = this.state;
    const { type } = dialog;
    const { tableConfig, received, pagination, onPageChange, handleSliderOpen, fetchAllData, permission } = this.props;
    return (
      <Fragment>
        {type && (
          <SplitPayment
            data={dialog.element}
            type={type}
            resetDialog={this.resetDialog}
            fetchAllData={fetchAllData}
            getStatus={this.getStatus}
          />
        )}
        <div className="table-wrap">
          <table>
            {TableHeader({
              tableConfig,
              permission,
            })}

            <tbody>
              {received.list.map(received =>
                TableBody({
                  data: received,
                  onIconClick: this.handleIconClick,
                  tableConfig,
                  handleSliderOpen,
                  section: 'received',
                  permission,
                }),
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          currentPage={pagination.page}
          limit={pagination.limit}
          totalItems={received.total}
          onPageChange={onPageChange}
        />
      </Fragment>
    );
  }
}

Received.propTypes = propTypes;

Received.defaultProps = defaultProps;

const ReceivedWithErrorAndLoading = withErrorAndLoadingScreen(Received);

const ReceivedWithImageSlider = withImageSlider(ReceivedWithErrorAndLoading);

export default withAlert()(ReceivedWithImageSlider);
