import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_DISTRIBUTORS_SERVICES, GET_SKU_WITH_BATCH } from '../../../common/GqlAPI';
import {
  GET_INVOICE_BY_INVOICE_NUMBER, GET_SKU_BATCH_DETAILS, VALIDATE_SRN_DATA
} from '../create/API';
import { GET_SRN_DETAIL } from '../details/API';

const RECONCILE_RETURN = graphql(
  gql`
    mutation ($id: Int!, $input: SRNInput!) {
      reconcileReturnRequest(id: $id, input: $input) {
        id
        invoiceNumber
        SRNInvoiceNumber
        status
        approved
        paymentMode
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      reconcileReturnRequest: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const REJECT_RETURNS = graphql(
  gql`
    mutation ($id: Int!) {
      rejectReturnRequest(id: $id)
    }
  `,
  {
    props: ({ mutate }) => ({
      rejectReturnRequest: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getSkus: GET_SKU_WITH_BATCH,
  getSrnDetails: GET_SRN_DETAIL,
  validateSrnData: VALIDATE_SRN_DATA,
  getSKUBatchDetail: GET_SKU_BATCH_DETAILS,
  getDistributors: GET_DISTRIBUTORS_SERVICES,
  getInvoiceDetail: GET_INVOICE_BY_INVOICE_NUMBER,
};

const mutation = {
  reconcileReturnRequest: RECONCILE_RETURN,
  rejectReturnRequest: REJECT_RETURNS,
};

export {
  query, mutation, RECONCILE_RETURN, REJECT_RETURNS
};
