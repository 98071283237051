import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../../../user/userRole/API';

const CREATE_REPLENISHMENT_GROUP = graphql(
  gql`
    mutation ($input: StockGroupInput!) {
      createReplenishmentGroup(input: $input) {
        id
        title
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createReplenishmentGroup: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_REPLENISHMENT_GROUP = graphql(
  gql`
    mutation ($input: StockGroupUpdateInput!, $id: Int!) {
      updateReplenishmentGroup(input: $input, id: $id) {
        id
        title
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateReplenishmentGroup: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const mutation = {
  createReplenishmentGroup: CREATE_REPLENISHMENT_GROUP,
  updateReplenishmentGroup: UPDATE_REPLENISHMENT_GROUP,
  toggleState: TOGGLE_STATE,
};

export {
  mutation, CREATE_REPLENISHMENT_GROUP, UPDATE_REPLENISHMENT_GROUP
};
