const LEAVE_STATUS = {
  APPLIED: 'APPLIED',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
};

const dseList = [
  {
    id: 1,
    title: 'Ram Chandra Jha',
  },
  {
    id: 2,
    title: 'Krishna Nepal',
  },
  {
    id: 3,
    title: 'Bibk Sha',
  },
  {
    id: 4,
    title: 'Pla',
  },
];

const sampleData = [
  {
    date: '2020-01-17',
    routeList: [
      {
        id: 1,
        title: 'Nepal Ko Route',
        retailOutlets: 12,
        line: {
          id: 1,
          title: 'Line title',
          visitFrequency: 11,
        },
      },
      {
        id: 14,
        title: 'India ko route',
        retailOutlets: 12,
        line: {
          id: 1,
          title: 'Line title',
          visitFrequency: 11,
        },
      },
    ],
  },
  {
    date: '2020-01-22',
    routeList: [
      {
        id: 1,
        title: 'China Ko Route',
        retailOutlets: 24,
      },
    ],
  },
  {
    date: '2020-01-27',
    routeList: [
      {
        id: 13,
        title: 'Jaal Marga',
        outletCount: 234,
      },
    ],
  },
];

const leaveData = [
  {
    id: 12,
    date: '2020-01-22',
    status: LEAVE_STATUS.APPLIED,
  },
  {
    id: 23,
    date: '2020-02-22',
    status: LEAVE_STATUS.PENDING,
  },
  {
    id: 23,
    date: '2020-02-22',
    status: LEAVE_STATUS.APPLIED,
  },
];

const holidays = [
  {
    id: 12,
    date: '2020-01-22',
    title: 'NepaliKo Holiday',
  },
  {
    id: 12,
    date: '2020-01-22',
    title: 'India Ko Holiday',
  },
];

const overAllData = {
  routeList: sampleData,
  leaveList: leaveData,
  holidayList: holidays,
};

export default sampleData;

export { dseList, overAllData };
