import { compose } from 'recompose';
import DistributorSalesTarget from './DistributorSalesTarget';
import {
  query,
  mutation,
  UPLOAD,
  DOWNLOAD_REPORT,
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedDistributorSalesTarget = compose(
  UPLOAD,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  })
)(DistributorSalesTarget);


export default ComposedDistributorSalesTarget;
