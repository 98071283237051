import React, { Component } from 'react';
import PropTypes from 'prop-types';
import scaleOrdinal from 'd3-scale/src/ordinal';
import { arc, pie } from 'd3-shape';
import DonutStyled from './DonutStyled';
import { fixedFloatAndComma } from '../../../utils/conversion';

const propTypes = {
  legend: PropTypes.bool,
};

const defaultProps = {
  legend: true,
};

class DonutChartWithPercent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {}

  buildChart() {
    const { data, dimensions, id, callageSet, translateY, percent, colorSet, legend } = this.props;

    const color = scaleOrdinal().range(colorSet);

    const radius = Math.min(dimensions.height - 30, dimensions.width) / 2;

    const dPie = pie()
      .sort(null)
      .value((d) => d.value);

    const dArcs = arc()
      .outerRadius(radius)
      .innerRadius(radius * 0.81)
      .cornerRadius(0)
      .padAngle(0);
    return (
      <DonutStyled>
        <svg id={`${id}-donut-percent`} width={dimensions.width + 10} height={dimensions.height + 10}>
          <g transform={`translate(${dimensions.width / 2}, ${dimensions.height / 2})`}>
            <circle r={radius * 0.93} stroke='#f4f6fc' strokeWidth='13.5px' fill='transparent' />
            <g className='slices'>
              {dPie(data).map((d, i) => (
                <path key={i} className='slice' d={dArcs(d)} fill={colorSet[d.data.label]} />
              ))}
            </g>
            <g className='legend' transform='translate(0, -10)' fill='rgb(100, 102, 119)'>
              <text
                className='value'
                dominantBaseline='hanging'
                x='0'
                y={percent.label ? -10 : 0}
                textAnchor='middle'
                color='#272833'
                style={{ fontSize: '24px' }}
              >
                {percent.value === Infinity ? 0 : fixedFloatAndComma(percent.value)}%
              </text>
              {percent.label && (
                <text
                  className='value'
                  dominantBaseline='hanging'
                  x='0'
                  y='20'
                  textAnchor='middle'
                  color='#6B6C7E'
                  style={{ fontSize: '12px' }}
                >
                  {percent.label}
                </text>
              )}
            </g>
          </g>
        </svg>
      </DonutStyled>
    );
  }

  render() {
    return this.buildChart();
  }
}

DonutChartWithPercent.propTypes = propTypes;

DonutChartWithPercent.defaultProps = defaultProps;

export default DonutChartWithPercent;
