import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  trigger: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  classes: PropTypes.string,
};
const defaultProps = {
  classes: '',
};

class AccordionSection extends Component {
  static propTypes = propTypes;
  static defaultProps = defaultProps;

  onClick = () => {
    const { onClick, id } = this.props;
    onClick(id);
  };

  render() {
    const {
      onClick,
      props: { isOpen, trigger, children, classes },
    } = this;

    return (
      <Fragment>
        <tr onClick={onClick} style={{ cursor: 'pointer' }} className={isOpen ? `active ${classes}` : `inActive`}>
          {trigger}
        </tr>
        {isOpen && (
          <tr className="collapsible-body-tr">
            <td colSpan="75%" className="collapsible-body-td">
              <div className="td-wrapper">
                <table>
                  <tbody>{children}</tbody>
                </table>
              </div>
            </td>
          </tr>
        )}
      </Fragment>
    );
  }
}

AccordionSection.displayName = 'AccordionSection';

export default AccordionSection;
