import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import MASTER_DATA_TYPES from '../../../../../data/enums/MasterData';
import { NOTIFICATION_ALERT_MESSAGE } from '../../../../../data/enums/Notification';
import { hyphenPresentor } from '../../../../../utils/date';
import history from '../../../../../utils/history';
import { Button } from '../../../../../v4/components';
import { DialogFormWrapper } from '../../../../common';
import { DialogContainer } from '../CallPlanDetails.styled';
import { formConfig, OPERATION } from '../config';
import UnsuccessFullcallForm from './UnsuccessFullcallForm';




const Timer = (props) => {
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  })
  const [reasonList,setReasonList] = useState([])

  const[closeDialog, setCloseDialog] = useState(false)

  const handleClick = (type, element = {}, tabLabel) => {
    setDialog({
      type,
      element: {
        ...element, tabLabel
      },
    });
  }

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
  };

  const { seconds, minutes, serverResponseWaiting, stopTimer, SaveTASUnsuccessfulCallsApi, tasUserCallPlanId, callPlanDetailsData, displayAlert, callNotes, getMasterData } = props

  const getUnsuccessfulCallReason = () => {
    getMasterData(
      {},
      {
        handleSuccess: (res) => {
          const { listMasterData = [] } = res.data;
          const dataObj = {
            [MASTER_DATA_TYPES.NO_ORDER_REASON_TAS]: [],
          };
          if (listMasterData.length > 0) {
            listMasterData.forEach((item) => {
              dataObj[item.type] = [...(item.list || [])];
            });
            setReasonList(dataObj[MASTER_DATA_TYPES.NO_ORDER_REASON_TAS]);
          }
        },
        handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
      },
    );
  };

  useEffect(() => {
    getUnsuccessfulCallReason();
  }, [])

  const handleDialogSubmit = (type, dialogData,error) => {
    if (
      typeof dialogData.remarks==='object'
    ) {
      setCloseDialog(true)
      displayAlert(ALERT_TYPE.CUSTOM_DANGER,'Select reason for unsuccessful call');
      return;
    }
    const callEvent = new Date();
    callEvent.setMinutes(callEvent.getMinutes() - minutes || 0);
    callEvent.setSeconds(callEvent.getSeconds() - seconds || 0);
    const callStartTime = callEvent.toString().split(' ')[4]
    const callEndTime = new Date().toString().split(' ')[4]
    const callDate = new Date().toISOString().split('T')[0];
    const callStart = `${callDate} ${callStartTime}`
    const callEnd = `${callDate} ${callEndTime}`

    const tasEvent = new Date(dialogData.startDate);
    tasEvent.setMinutes(dialogData?.times?.minutes);
    tasEvent.setHours(dialogData?.times?.hours);
    let hours = 0;
    if (dialogData?.times?.period === 'PM') {
      hours = dialogData?.times?.hours >= 12 ? dialogData?.times?.hours : 12 + Number(dialogData?.times?.hours);
    } else {
      hours = dialogData?.times?.hours >= 12 ? Number(dialogData?.times?.hours) - 12 : dialogData?.times?.hours;
    }
    if (dialogData?.times.hours > 12) {
      displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.HOUR_ALERT}`);
      return;
    }

    if (dialogData?.times.minutes > 59) {
      displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.MINUTE_ALERT}`);
      return;
    }
    const mins = dialogData?.times?.minutes < 10 && dialogData?.times?.minutes >= 1 ? `0${dialogData?.times?.minutes}` : dialogData?.times?.minutes <=0 ? '00' : dialogData?.times?.minutes;
    const tasTime = `${hours===0 ? '00' :hours}:${mins}:00`
    const tasDate = hyphenPresentor(tasEvent);
    const tasReminder = ` ${tasDate} ${tasTime} `

    const formattedData = {
      retailOutletId: callPlanDetailsData.outletBasic.id,
      tas_user_call_plan_id: Number(tasUserCallPlanId),
      tasReminder: tasReminder,
      callTime: {
        callStart,
        callEnd
      },
      notes: callNotes,
      remarks: dialogData.remarks === 'Other' ? dialogData.message || '' : dialogData.remarks || '',
      completecall: dialogData.completecall,
      type: 'UNSUCCESSFUL'
    }

    SaveTASUnsuccessfulCallsApi(
      {
        input: formattedData,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'Call Plan Completed');
          setTimeout(() => {
            history.push({
              pathname: '/tas/callPlan',
              state: dialogData.completecall?{ tab: 2 }:{ tab: 1 }
            })
          }, 2000);
          resetDialog();
        },
        handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
      },
    );

  }

  return (
    <>
      <TimerStyled>
        <DialogUnsuccessFullContainer>
          {dialog.type && (
            <DialogContainer className='dialog-wrap'>

              <DialogFormWrapper
                disableDialogClose={true}
                formConfig={formConfig[dialog.type]}
                dialogElement={dialog.element}
                formTitle={dialog.type === OPERATION.ADD_ORDER && dialog.element?.title}
                onDialogSubmit={handleDialogSubmit}
                onDialogCancel={resetDialog}
                onNoOrderClick={handleClick}
                withOutPadding={dialog.type === formConfig[dialog.type]}
                type={dialog.type}
                controlDialogClose={closeDialog}
                submitClickedControl
                loading={serverResponseWaiting}
                renderDialog={({ dialogData, handleInputChange, handleRadioButtonChange, handleDateChange,handleDropDownChange }) => (
                  <>
                    {(dialog.type === OPERATION.UNSUCCESSFUL_CALL) && (
                      <UnsuccessFullcallForm
                        dialogData={dialogData}
                        onRadioButtonChange={handleRadioButtonChange}
                        onInputChange={handleInputChange}
                        handleDateChange={handleDateChange}
                        reasonList={reasonList}
                        handleDropDownChange={handleDropDownChange}
                      />
                    )}
                  </>
                )}
              />
            </DialogContainer>
          )}

          <Button iconBtnSmall className='timer'>{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Button>
          <Button iconBtnSmall className='unsucessfulcall' onClick={() => handleClick(OPERATION.UNSUCCESSFUL_CALL, stopTimer)}>Unsucessful Call</Button>

        </DialogUnsuccessFullContainer>
      </TimerStyled>
    </>
  )
};

const TimerStyled = styled.div`
  .timer{
    color: #DA1414;
    border: 1px solid #DA1414;
    font-size: 14px;
    line-height: 21px;
  }
  .unsucessfulcall{
    color: #FFFFFF;
    border: 1px solid #DA1414;
    background-color: #DA1414;
    font-size: 14px;
    line-height: 21px;

  }
`

export const DialogUnsuccessFullContainer = styled.div`
  .modal-inner {
    max-width: 549px !important;
  }
`

export default Timer;
