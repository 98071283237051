import React from 'react';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { Button, Icon } from '../../../../../v4/components';
import { OrderPadContainer } from './OrderPadDrawer.styled';
import { TableBody, TableHeader } from './tableConfig';
import { USER_ROLE } from '../../../../../data/enums';

const OrderPadDrawer = ({ showSummaryOverlay, toggleOverlay, closeOverlay, overlaySummary, orderPadSummaryDetails, handleEditOrder, handleRemoveOrder, handleCompleteOrder, timer, isCompleted, handlePromotionClick,editGroupPromotion,orderPadDetail,userInfo }) => {
  const { totalLines = 0, sbdSkuLines = 0 } = overlaySummary;

  return (
    <OrderPadContainer>
      {showSummaryOverlay
        ? <div className='overlay' />
        : null
      }
      <div className={`drawer-container${showSummaryOverlay ? ' active' : ''}`}>
        <div className='order-pad-title'>
          <Icon iconName={`${showSummaryOverlay ? 'angle-down' : 'angle-up'}`} onClick={toggleOverlay} />
          <h3>Order Pad</h3>
          <div className='order-pad-summary'>
            <div className='summary'>
              <label>Total Lines</label>
              <div className={`order-pad-value${totalLines > 0 ? ' primary' : ''}`}>{totalLines}</div>
            </div>
            <div className='summary'>
              <label>SBD SKUs</label>
              <div className={`order-pad-value${totalLines > 0 ? ' primary' : ''}`}>{sbdSkuLines}</div>
            </div>
            <div className='summary'>
              <label>Net Saving</label>
              <div className={`order-pad-value${totalLines > 0 ? ' primary' : ''}`}>{overlaySummary?.callOrderValue?.discountAmount ? `Rs. ${fixedFloatAndCommas(overlaySummary?.callOrderValue?.discountAmount)}` : '-'}</div>
            </div>
            <div className='summary'>
              <label>Net Amount</label>
              <div className={`order-pad-value${totalLines > 0 ? ' primary' : ''}`}>{overlaySummary?.callOrderValue?.netAmount ? `Rs. ${fixedFloatAndCommas(overlaySummary?.callOrderValue?.netAmount)}` : '-'}</div>
            </div>
          </div>
          {showSummaryOverlay && !isCompleted
            ? <div className='right'>
              {!isCompleted ?
                <div className='time-counter'>{timer}</div>
                : null
              }
              <Button iconName='cross' onClick={closeOverlay} /> </div>
            : !isCompleted && <Button primary title='Complete Order' onClick={handleCompleteOrder} disabled={userInfo.roleId!==USER_ROLE.TAS}/>}
        </div>
        {showSummaryOverlay ? <div className='order-pad-details-summary'>
          <table>
            {TableHeader()}
            <tbody>
              {orderPadSummaryDetails.length > 0 && orderPadSummaryDetails.map((skuCategory) => (
                skuCategory.skus.length > 0 ?
                  skuCategory.skus.map((sku) => sku?.quantity ? TableBody({
                    data: sku,
                    handleEditOrder,
                    handleRemoveOrder,
                    isCompleted,
                    handlePromotionClick,
                    editGroupPromotion,
                    orderPadDetail
                  }) : null)
                  : null
              ))}
            </tbody>
          </table>
        </div> : null}
        <div className='complete-order-pad-btn' >
          {showSummaryOverlay && !isCompleted ? <Button primary title='Complete Order' onClick={handleCompleteOrder}  disabled={userInfo.roleId!==USER_ROLE.TAS}/> : null}
        </div>
      </div>
    </OrderPadContainer>
  )
}

OrderPadDrawer.defaultProps = {
  orderPadSummaryDetails: [],
  showSummaryOverlay: false,
  toggleCallTargetOverlay: () => { },
  overlaySummary: {
    totalLines: 0,
    netAmount: 0
  },
  handleCallTarget: () => { },
}

export default OrderPadDrawer
