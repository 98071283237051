import { TAS_USERDETAIL_DASHBOARD as dashboardRoute } from '../../../../../data/enums/Route';
import { getFilterConfig } from '../../../../../utils/filter';
import { FILTER } from '../../../../../data/enums/filter';
import { USER_ROLE_TITLE } from '../../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../../common/HelperFunctions';
import { getUser } from '../../../../../data/services';
import { isTASUser,isTasTeamLead } from '../../../dashboard/config';
import { getUserRole } from '../../../../../data/dao';

const userInfo = getUser();

const redirectPath = isTasTeamLead(userInfo && userInfo.roleId) ? '/tas/user-detail' : '#';
const breadCrumb = () => [
  {
    id: 1,
    title: 'reports',
    path: redirectPath,
    active: true,
  },
];

export { breadCrumb as breadCrumbConfig };
