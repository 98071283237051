import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableHeader } from '../tableConfig';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import { Pagination } from '../../../../v4/components';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { BulkSelect } from '../../../../v4/components';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';

const propTypes = {
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  pending: dataProps.value,
  onPageChange: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  fetchAllData: PropTypes.func,
  pagination: paginationProps.value,
  handleSliderOpen: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  pending: dataProps.defaultValue,
  onPageChange: () => null,
  permission: PERMISSION_OBJ,
  fetchAllData: () => null,
  pagination: paginationProps.defaultValue,
};

class Completed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  getStatus = (status, statusType) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE[statusType === 'err' ? 'INFO' : 'SUCCESS'], status);
  };

  render() {
    const { dialog } = this.state;
    const { tableConfig, completed, pagination, onPageChange, handleSliderOpen, fetchAllData, permission,onTableRowClick,onHeaderClick } = this.props;
    const filterTableConfig = tableConfig;
    return (
      <Fragment>
        <BulkSelect
          data={completed.list}
          fetchAllData={fetchAllData}
         
          render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
            <div className="table-wrap">
              <table>
                {TableHeader({
                  tableConfig: filterTableConfig,
                  dataListLength: completed.list.length,
                  onHeaderClick
                })}

                <tbody>
                  {completed.list.map(completed =>
                    TableBody({
                      data: completed,
                      onIconClick: this.handleIconClick,
                      tableConfig: filterTableConfig,
                      handleSliderOpen,
                      checkedList: checkedList,
                      secondaryCheckboxHandler,
                      section: 'completed',
                      permission,
                      pendingAction: true,
                      onTableRowClick
                    }),
                  )}
                </tbody>
              </table>
            </div>
          )}
        />

        <Pagination
          currentPage={pagination.page}
          limit={pagination.limit}
          totalItems={completed.total}
          onPageChange={onPageChange}
        />
      </Fragment>
    );
  }
}

Completed.propTypes = propTypes;

Completed.defaultProps = defaultProps;

const CompletedWithErrorAndLoading = withErrorAndLoadingScreen(Completed);

const CompletedWithImageSlider = withImageSlider(CompletedWithErrorAndLoading);

export default withAlert()(CompletedWithImageSlider);
