import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import withAlert from '../../../utils/composition/withAlert';
import { dataMapper,MODULES,roleBasedUserStatus,getUserWiseView,randomColor } from './config';
import { getUser } from '../../../data/dao';
import { convertObjToArray } from '../../../utils/objectProcessor';
import View from './View';
import { getFromLocalStorage } from '../../common/HelperFunctions';
import { JWT } from '../../../environment';
import {TAS_DASHBOARD } from '../../../data/enums/Route';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
  getLastSyncTime: PropTypes.func.isRequired,
  getDashboardInfo: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class TASDashboard extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const sbd =
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS) && getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS).sbd;
    const dimension = 220;
    this.userInfo = getUser();
    const margin = {
      top: 80,
      left: 80,
      bottom: 80,
      right: 80,
    };
    this.state = {
      dimensions: {
        height: dimension,
        width: dimension,
        margin,
      },
      filter: 'MTD',
      data: dataMapper({}, this.userInfo.roleId),
      serverData: {},
      syncTime: ' ',
      displayGoldenPoint: sbd,
      sbdPoints:{
        sbdRows:[]
      },
      userStateDetail:{
        rows:[],
        total:{},
      },
    };
    this.userWiseView = getUserWiseView(this.userInfo.roleId);
  }

  componentDidMount() {
    this.loadSBDData();
    this.loadData();
    this.lastSyncTime();
    this.loadUserDetails();
  }

  lastSyncTime = () => {
    const { getLastSyncTime } = this.props;
    getLastSyncTime(
      {},
      {
        handleSuccess: response => {
          if (response.data) {
            const { getLastSyncTime = ' ' } = response.data;
            const lastPart = (getLastSyncTime && getLastSyncTime.split(' ').pop()) || '';
            this.setState({ syncTime: lastPart });
          }
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };
  calculateSum = (data,key) => {
    return data?.rows?.reduce((a, b) => a + (b[key] || 0), 0);
}
calculateTotal =(data) =>{
  const scheduled = this.calculateSum(data,'scheduled') || 0;
  const successful =  this.calculateSum(data,'successful') || 0;
  const unsuccessful =  this.calculateSum(data,'unsuccessful') || 0;
  const remaining =  this.calculateSum(data,'remaining')|| 0;
  const productivity = scheduled>0? parseInt((successful/scheduled)*100):0;
  const target =  this.calculateSum(data,'target') || 0;
  const received =  this.calculateSum(data,'received') || 0;
  return ({
    scheduled,
    successful,
    unsuccessful,
    remaining,
    productivity,
    target,
    received,
  })
}
  loadUserDetails = () => {
    const {getTasUserStateDetailList ,displayAlert,} = this.props;
    const {userStateDetail ,} = this.state;
    getTasUserStateDetailList({
      "filter": {
        "filters": [
          {
            "column": "monthlyTas",
            "value": "tas"
          }
        ]
      }
    }, {
      handleSuccess: (response) => {
        if (response.data) {
          const data=response?.data?.getTasUserStateDetailList || {};
          const total =this.calculateTotal(data) ;
          const dataWithColor = {
            rows: data?.rows?.map(d => ({
              ...d,
              ...{ color: randomColor() },
            })),
          };
          userStateDetail.rows =dataWithColor?.rows || [];
          userStateDetail.total =total ||{};
          this.setState({
            userStateDetail
          });
        }
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    }
    )
  };
  
  loadData = () => {
    const {getDashboardInfo ,displayAlert,} = this.props;
    getDashboardInfo({}, {
      handleSuccess: (response) => {
        if (response.data) {
          this.setState({
            serverData: response?.data?.tasDashboard || {},
            data: this.assignDataFormatter(response?.data?.tasDashboard || {}),
          });
        }
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    }
    )
  };
  loadSBDData = () => {
    const {getSBDPoints ,displayAlert} = this.props;
    getSBDPoints({}, {
      handleSuccess: (response) => {
        if (response.data) {
          this.setState({
            sbdPoints :{
              sbdRows:response?.data?.getBrandWiseSBDForTAS || []
            }
          });
        }
      },
      handleError: (error) => displayAlert(ALERT_TYPE.DANGER, error)
    }
    )
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  assignDataFormatter = data => {
    return { ...data, ...this.adminDataFormatter(data)}
  };

  adminDataFormatter = data => {
    const formattedData = { ...data };
    formattedData.sales = convertObjToArray(data.salesDetail) || [];
    formattedData.callage = convertObjToArray(data.callageDetail) || [];
    formattedData.monthlyDetail = data.monthlyDetail || {};
    formattedData.unmodifiedSales = data.salesDetail;
    formattedData.userStatus= roleBasedUserStatus(data);
    return formattedData;
  };
  handleModuleClick = module => {
    const { history } = this.props;
    history.push(`${TAS_DASHBOARD}`);
  };

  render() {
    const { data, dimensions, syncTime ,sbdPoints,userStateDetail} = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <View
        userWiseView={this.userWiseView}
        data={data}
        dimensions={dimensions}
        loading={serverResponseWaiting}
        handleModuleClick={this.handleModuleClick}
        syncTime={syncTime}
        sbdPoints={sbdPoints}
        userStateDetail={userStateDetail}
      />
    );
  }
}

TASDashboard.propTypes = propTypes;

TASDashboard.defaultProps = defaultProps;

const LoadingTASDashboard = withErrorAndLoadingScreen(TASDashboard);

export default withAlert()(LoadingTASDashboard);
