import styled from 'styled-components';

const PromotionStyled = styled.div`
.modal-wrapper{
  .replicate{
    max-width: 550px;
    .evolve-dialog__body{
      height: 500px;
    }
  }
  .replicate_all{
    max-width: 550px;
    .evolve-dialog__body{
      height: 300px;
    }
  }
}
`;

export default PromotionStyled;
