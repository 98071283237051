const containsObject = (obj, list) => {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
};

const splitFilteredArray = (arr = [], filterFn) => {
  return arr.reduce((acc, el) => {
    const condition = filterFn(el);

    if (condition) {
      acc.filtered.push(el);
    } else {
      acc.remaining.push(el);
    }

    return acc;
  }, { filtered: [], remaining: [] });
}

export { containsObject, splitFilteredArray };
