import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE, DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_BRAND_DETAILS = gql`
  query getBrandDetails {
    catalogDetails(catalogId: 3, limit: 1000) {
      rows {
        id
        title
        parentCatalogDetailId
      }
    }
  }
`;

const GET_PRESENTATION_LIST = gql`
  query getPresentation($offset: Int, $limit: Int, $filter: FilterInput) {
    getPresentation(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        type
        id
        title
        brandId
        brand: Brand {
          title
        }
        url {
          url
        }
      }
      count
    }
  }
`;

const CREATE_PRESENTATION = graphql(
  gql`
    mutation createPresentation($input: PresentationInput!) {
      createPresentation(input: $input) {
        type
        id
        title
        brandId
        url {
          url
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createPresentation: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getPresentation: GET_PRESENTATION_LIST,
};
const mutation = {
  toggleState: TOGGLE_STATE,
  createPresentation: CREATE_PRESENTATION,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, CREATE_PRESENTATION
};
