import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { DialogFormWrapper } from '../../../../common';
import { formConfig, crudRequestConfig } from '../config';
import Confirmation from '../../../../common/DialogConfirmation';
import withAlert from '../../../../../utils/composition/withAlert';
import { isConfirmationType, getFromLocalStorage } from '../../../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { handleFormSubmit, responseInterpreter } from '../../../../../utils/crudResponseProcessor';
import { JWT } from '../../../../../environment';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';

const propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateFocusedSKU: PropTypes.func.isRequired,
  createFocusedSKU: PropTypes.func.isRequired,
  element: PropTypes.instanceOf(Object),
  serverResponseWaiting: PropTypes.bool,
  toggleState: PropTypes.func.isRequired,
  replicateFocusedSKU: PropTypes.func.isRequired,
  onApiFailure: PropTypes.func.isRequired,
  getLineDetails: PropTypes.func.isRequired,
};

const defaultProps = {
  element: {},
  serverResponseWaiting: false,
  type: EVENT_OPERATION.CREATE,
};

class StockCountListDialog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const routeSegmentation =
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS) &&
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS).routeSegmentation;
    this.state = {
      menu: { lineList: [] },
      enableRouteSegmentation: routeSegmentation,
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createFocusedSKU,
      [EVENT_OPERATION.UPDATE]: props.updateFocusedSKU,
      [EVENT_OPERATION.REPLICATE]: props.replicateFocusedSKU,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = responseInterpreter(this.handleSuccessResponse);
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      props.onApiFailure || props.onClose,
      crudRequestConfig,
      serverCall,
    );
  }

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleSuccessResponse = (type, response) => {
    const { onClose, onSubmit } = this.props;
    onClose();
    onSubmit(type, response);
  };

  loadLineList = () => {
    const { getLineDetails } = this.props;
    getLineDetails(
      { offset: 0, filter: { filters: [], queryString: '' } },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.lineList = res.data.lines.rows || [];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  componentDidMount() {
    const { enableRouteSegmentation } = this.state;
    if (enableRouteSegmentation) {
      this.loadLineList();
    }
  }

  render() {
    const { type, element, onClose } = this.props;
    return (
      <DialogFormWrapper
        formConfig={formConfig[type]}
        dialogElement={element}
        onDialogSubmit={this.onFormSubmit}
        onDialogCancel={onClose}
        type={type}
        renderDialog={() => (
          <div>
            {isConfirmationType(type) && Confirmation(type)}
          </div>
        )}
      />
    );
  }
}

StockCountListDialog.propTypes = propTypes;

StockCountListDialog.defaultProps = defaultProps;

export default withAlert()(StockCountListDialog);
