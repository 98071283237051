import { getTodaysDate } from '../../../../../utils/date';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const title = 'GRN';
// 95189
const formMapper = (element) => ({
  id: element.id || '',
  skuId: element.skuId || 0,
  skuBatchId: element.skuBatchId || 0,
  expiryDate: element.expiryDate || getTodaysDate(),
  manufactureDate: element.manufactureDate || getTodaysDate(),
  quantity: element.quantity || 0,
  priceDetails: {
    rate: element.priceDetails ? Number(element.priceDetails.rate) : 0,
    amount: element.priceDetails ? element.priceDetails.amount : 0,
    discount: element.priceDetails ? Number(element.priceDetails.discount) : 0,
    netAmount: element.priceDetails ? element.priceDetails.netAmount : 0,
    exciseAmount: element.priceDetails ? Number(element.priceDetails.exciseAmount) : 0 || 0,
  },
  damages: element.damages || 0,
  shortages: element.shortages || 0,
  damageImages: element.damageImages || [],
  bestBefore: element.bestBefore ? element.bestBefore.toString() : '',
  batchName: element.batchName || '',
  warehouseLevelDetails: element.warehouseLevelDetails || [],
});

const requiredList = [
  'skuId',
  'expiryDate',
  'manufactureDate',
  'quantity',
  'rate',
  'discount',
  'batchName',
  'binLocationQuantity',
];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: () => formMapper({}),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Update SKU',
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: (element) => formMapper(element),
    [FORM_CONFIG.REFS_OBJ]: refGenerator([...requiredList]),
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'DELETE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({ id: element.id || '' }),
  },
};

const totalViewRefs = refGenerator(['billDiscount', 'tradeDiscount']);

const updateRate = (state, stateUpdater, selectedBatch) => {
  state.priceDetails.rate = selectedBatch.dlp;
  stateUpdater(state);
};

const updateDamageImages = (state, stateUpdater, value) => {
  /** todo instead of dummyImageUrl add value */
  const image = {
    image: '',
    thumbnail: value,
  };
  state.damageImages.push(image);
  stateUpdater(state);
};

const removeDamageImage = (getState, uploadState, item) => {
  const dialogState = getState();
  const updatedDamageImages = dialogState.damageImages.filter((image) => image.thumbnail !== item.thumbnail);
  dialogState.damageImages = [...updatedDamageImages];
  uploadState(dialogState);
};

const updateSkuLine = (element, sku, skuBatch) => ({
  ...element,
  priceDetails: {
    ...element.priceDetails,
    exciseAmount: Number(element.priceDetails.exciseAmount),
    amount: element.quantity * Number(element.priceDetails.rate),
    netAmount: element.quantity * Number(element.priceDetails.rate) - element.priceDetails.discount + Number(element.priceDetails.exciseAmount),
  },
  SKU: {
    id: sku.id,
    title: sku.title,
  },
  SkuBatch: {
    id: skuBatch.id,
    batchName: skuBatch.batchName,
    batchDetails: { batchNumber: skuBatch.batchName },
    shortages: skuBatch.shortages,
    damages: skuBatch.damages,
    usageDate: {
      manufactureDate: element.manufactureDate,
      expiryDate: element.expiryDate,
    },
  },
});

const errorMessage = {
  quantityError: 'The sum of shortage and damages must',
};

export {
  formConfig,
  formMapper as listMapper,
  title,
  updateRate,
  totalViewRefs,
  updateSkuLine,
  updateDamageImages,
  removeDamageImage,
  errorMessage,
};
