const MASTER_DATA_TYPES = {
  SBD: 'SBD',
  PRODUCTIVITY: 'PRODUCTIVITY',
  SKU_TAGS: 'SKU_TAGS',
  CASE_UNIT: 'CASE_UNIT',
  OPERATOR: 'OPERATOR',
  DIMENSION: 'DIMENSION',
  SYSTEM_ALERT_ATTRIBUTE: 'SYSTEM_ALERT_ATTRIBUTE',
  SUB_CAMPAIGN_TYPES: 'SUB_CAMPAIGN_TYPES',
  SUB_CAMPAIGN_DIMENSIONS: 'SUB_CAMPAIGN_DIMENSIONS',
  SUB_CAMPAIGN_DETAIL_TYPES: 'SUB_CAMPAIGN_DETAIL_TYPES',
  USER: 'USER',
  CALLAGE: 'CALLAGE',
  DSE_ATTENDANCE: 'DSE ATTENDANCE',
  NO_ORDER_REASON_TAS: 'NO_ORDER_REASON_TAS'
};

export default MASTER_DATA_TYPES;
