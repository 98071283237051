import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { defaultMenuConfigList, menuAction } from '../../../../../v4/components';
import BadgeStyled from '../../../../../v4/components/Badge/BadgeStyled';
import { tableData } from '../../../../../v4/components/Table/TableSort';

const labelMappings = {
  id: 'id',
  name: 'name',
  email: 'email',
  accountActivatedDate: 'account_activated_date',
  lastActiveDate: 'last_active_date',
  activeDays: 'active_days',
  active: 'active',
  options: 'options',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.name,
    title: 'Full Name',
    show: true,
    align: 'text-left',
  },
  {
    id: 2,
    label: labelMappings.email,
    title: 'Email',
    show: true,
    align: 'text-left',
  },
  {
    id: 3,
    label: labelMappings.accountActivatedDate,
    title: 'Account Activated Date',
    show: true,
    align: 'text-left',
  },
  {
    id: 4,
    label: labelMappings.lastActiveDate,
    title: 'Last Active Date',
    show: true,
    align: 'text-left',
  },
  {
    id: 5,
    label: labelMappings.activeDays,
    title: 'Days Since Activated',
    show: true,
    align: 'text-right',
    sortable: true,
    sortLabel: 'account_activated_date',
  },
  {
    id: 6,
    label: labelMappings.active,
    title: 'Status',
    show: true,
    align: 'text-center',
  },
  {
    id: 7,
    label: labelMappings.options,
    title: '',
    show: true,
    align: 'text-left',
  },
];

const menuConfigList = [
  defaultMenuConfigList[EVENT_OPERATION.ACTIVATE],
  defaultMenuConfigList[EVENT_OPERATION.SUSPEND],
];

export const TableHeader = (handleSortClick: any, sort: any) => (
  <thead>
    <tr>{labelConfig.map(label => tableData(label, sort, handleSortClick))}</tr>
  </thead>
);

export const returnTableBody = (data: any, handleIconClick: any, permission: any) => {
  const table: { [key: string]: any } = {};
  table[labelMappings.name] = <td key={`${data.id}-${labelMappings.name}`}> {data?.name} </td>;
  table[labelMappings.email] = <td key={`${data.id}-${labelMappings.email}`}> {data?.email} </td>;
  table[labelMappings.accountActivatedDate] = (
    <td key={`${data.accoutn_activated_date}-${labelMappings.accountActivatedDate}`}>
      {' '}
      {data?.account_activated_date}{' '}
    </td>
  );
  table[labelMappings.lastActiveDate] = (
    <td key={`${data.last_active_date}-${labelMappings.lastActiveDate}`}> {data?.last_active_date} </td>
  );
  table[labelMappings.activeDays] = (
    <td key={`${data.active_days}-${labelMappings.activeDays}`} className="active-days">
      {' '}
      {data?.active_days}{' '}
    </td>
  );
  table[labelMappings.active] = (
    <td key={`${data.active}-${labelMappings.active}`} className="text-center status">
      {data?.active ? (
        <BadgeStyled className="success"> Active </BadgeStyled>
      ) : (
        <BadgeStyled className="danger">Inactive</BadgeStyled>
      )}
    </td>
  );

  table[labelMappings.options] = (
    <td
      key={`${data.id}-action`}
      className={`"text-right simple-popup-actions " ${!permission?.update && 'disabled'}`}
      onClick={e => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, handleIconClick, data, {
        activate: !data?.active,
        suspend: data?.active,
      })}
    </td>
  );
  return table;
};

export const TableBody = ({ data, handleIconClick, permission }: any) => {
  const tableBody = returnTableBody(data, handleIconClick, permission);
  return (
    <tr key={`user-account-${data.id}`}>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</tr>
  );
};
