import { compose } from 'recompose';
import {
  query, mutation, RECONCILE_RETURN, REJECT_RETURNS
} from './API';
import Details from './Details';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedReturnPendingDetail = compose(RECONCILE_RETURN, REJECT_RETURNS , withRequestTracker({ query, mutation }))(Details);

export default composedReturnPendingDetail;
