import styled from 'styled-components';

export const OrderPadContainer = styled.section`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1040;
    transition: opacity 0.3s ease 0s;
    background-color: rgba(57, 58, 74, .8);
  }

  .drawer-container {
    position: fixed;
    border-top: 1px solid rgb(231, 231, 237); 
    bottom: 0;
    width: calc(75% - (4rem + 56px));
    transition: top 0.5s ease-in-out;
    top: calc(100% - 5rem);
    &.active {
      box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
      z-index: 1045;
      background: #fff;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .order-pad-title{
      padding:16px;
      .icon{
       cursor:pointer;
      }
    }
  }

  .time-counter {
    border: 1px solid rgba(218,20,20,0.4);
    border-radius: 4px;
    display: inline-block;
    color: #DA1414;
    padding: 8px 12px;
    margin-right: 1rem;
  }

  .order-pad-details-summary {
    height: calc(100% - 152px);
    overflow-y: auto;
    &>table{
      &>tbody tr,
      &>thead tr{
        td,
        th{
          &:first-child{
            padding-left:24px !important;
          }
          &:last-child{
            padding-right:24px !important;
          }
        }
      }
    }
  }

  .complete-order-pad-btn {
    display: flex;
    position: fixed;
    align-items: center;
    bottom: 0;
    right: 0;
    justify-content: flex-end;
    padding:16px;
  }

  .order-pad-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    .icon {
      color: #0B4FFF;
    }

    h3 {
      margin: 0;
      font-weight: 400;
    }

    .order-pad-summary {
      margin-left: 3rem;
      gap: 3rem;
      display: flex;
      flex-direction: row;
        label {
          text-transform: capitalize;
          display: block;
          font-size: 12px;
          margin-bottom: 6px;
        }

        .order-pad-value {
          color: #A7A9BC;
          font-size: 16px;
          font-weight: 500;
          &.primary {
            color: #0B4FFF;
          }
        }
    }

    .right {
      margin-left: auto;
    }

    button {
      margin-left: auto;

      .icon {
        color: rgb(107, 108, 126);
      }
    }
  }

  table {
    border: none !important;
    thead > tr > th.left {
      &:first-child {
        padding-left: 0;
      }
    }
    tbody{
      tr > td > .form-input {
        input {
          max-width: 6rem;
          padding: 8px;
          height: 2rem;
        }
      }

      tr > td > span.sbd-sku {
        margin-left: 8px;
        padding: 2px 8px;
        line-height: 18px;
        text-transform: uppercase;
        background-color: rgb(255, 115, 195);
        border-radius: 4px;
        font-size: 12;
        color: rgba(255,255,255);
        &.in-stock {
          background-color: #50D2A0; 
        }
      }

     tr > td.remove > span > svg.icon {
        color: #DA1414
      }

     tr > td.edit > span > svg.icon {
        color: #0B5FFF;
      }

      td:first-child {
        padding: 0 !important;
      }

      .simple-popup {
        width: fit-content;
        display: inline-block;
        margin-left: .5rem;
        vertical-align: middle;

        .filter-background {
          color: #0B5FFF;
          font-weight: 500;

          .header-dom {
            padding: 1rem 0;
          }
        }
      &.promotion {
        .filter-dropdown-card{
          left: -16px;
          top: 180%;
          right: unset;
          padding:0;
          .filter-label{
            padding:14.5px 12.5px;
          }
          .filter-content {
            padding:0;
            .available-promotions{
              padding: 16px 12.5px;
              padding-bottom: 24px;
              li{
                text-align: left;
                list-style: disc;
                margin-bottom:16px;
                font-size:11px;
                color:#2E384D;
                list-style-position: inside;
                &:last-child{
                  margin-bottom:0;
                }
              }
            }
            .arrow-up {
              top: -6px;
              left: 15px;
              &::before{
                content: '';
                height: 8px;
                width: 8px;
                top: 2.5px;
                left: -4px;
                position: absolute;
                transform: rotate(45deg);
                border-left: 1px solid rgb(231,231,237);
                border-top: 1px solid rgb(231,231,237);
              }
            }
          }
        }
      }
      }
    }
  }

  table th:first-child, table td {
    border-left: none !important;
    border-right: none !important;
  }

  #sku-title {
    padding: 2rem 0 0 0 !important;
    font-size: 16px;
    font-weight: 500;
    border: none !important;
  } 
`;

