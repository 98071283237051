export const labelMappings = {
  id: 'id',
  brand: 'Brand',
  sku: 'SKU',
  rlp: 'RLP',
  lastInvoicedQuantity:'Last Invoiced Qty',
  lastInvoiced: 'Last Invoiced',
  promotion: 'promotion',
  callTarget: 'Call Target',
};
