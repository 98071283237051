import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../common/GqlAPI';

const GET_CAMPAIGNS_LIST = gql`
  query getCampaigns($offset: Int, $limit: Int, $filter: FilterInput) {
    campaigns(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        budget
        startDate
        endDate
        fiscalYearId
        FiscalYear {
          id
          title
          endDate
          startDate
        }
        SubCampaign {
          id
          title
          startDate
          endDate
          subCampaignType
          SubCampaignBudget {
            id
            budgetType
            value
          }
          SubCampaignTarget {
            id
            targetType
            value
            dimension
          }
        }
      }
      count
    }
  }
`;

const query = {
  getCampaignsList: GET_CAMPAIGNS_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
};

export { GET_CAMPAIGNS_LIST, TOGGLE_STATE };

export { query, mutation };
