import styled from 'styled-components';

const DetailStyled = styled.div`
  .section-header {
    h2 {
      display: flex;
      align-items: center;
      & > span {
        margin-right: 10px;
        height: 32px;
        width: 32px;
        text-align: center;
        line-height: 28px;
        border-radius: 50%;
        border: 1px solid #cdced9;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .filter-wrap {
      .form-select-input {
        margin: 0;
        label {
          display: none;
        }
        .select-css {
          .zindex-2__control {
            width: 92px;
            min-height: 32px;
            height: 32px;
            .zindex-2__single-value {
              font-size: 14px;
              color: #6b6c7e;
              top: 42%;
            }
            .zindex-2__indicators {
              .icon {
                color: #6b6c7e;
                position: relative;
                top: -1px;
              }
            }
          }
        }
      }
    }
  }
  .section-content {
    &.pad-lr-48 {
      padding: 0 48px;
      background: rgba(231, 231, 237, 0.2);
    }
    .title-detail-dse {
      margin: 0;
      font-size: 20px;
      line-height: 30px;
      color: #272833;
      font-weight: 400;
      margin-bottom: 16px;
    }
    .dse-user-stat {
      padding-top: 32px;
      .custom-display {
        margin: 0;
      }
    }
    .dse-user-routedetail {
      padding: 32px 0;
      .table-wrap {
        table {
          box-shadow: rgba(39, 40, 51, 0.12) 0px 4px 8px 0px;
          border: 1px solid #e7e7ed;
          border-radius: 4px;
          thead {
            tr {
              th {
                padding: 7px 32px;
                color: #6b6c7e;
                top: -2px;
                &:first-child {
                  padding-left: 24px;
                  width: 16%;
                }
                &:last-child {
                  padding-right: 24px;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 7px 32px;
                font-size: 14px;
                line-height: 21px;
                color: #272833;
                &:first-child {
                  padding: 0;
                }
                &:last-child {
                  padding-right: 24px;
                }
                & > div {
                  padding-left: 20px;
                  height: 56px;
                  display: flex;
                  align-items: center;
                  &.border-l-g {
                    border-left: 4px solid #50d2a0;
                  }
                  &.border-l-r {
                    border-left: 4px solid#FF5F5F;
                  }
                  &.border-l-t {
                    border-left: 4px solid transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default DetailStyled;
