import { compose } from 'recompose';
import Dialog from './Dialog';
import {
  query, mutation, createSKUGroup, updateSKUGroup, REPLICATE_SKU_GROUP
} from './API';
import { TOGGLE_STATE } from '../../../../../common/GqlAPI';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const ComposedDialog = compose(
  createSKUGroup,
  updateSKUGroup,
  TOGGLE_STATE,
  REPLICATE_SKU_GROUP,
  withRequestTracker({
    query,
    mutation,
  }),
)(Dialog);

export default ComposedDialog;
