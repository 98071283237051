import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  Input, Form, CustomSelect
} from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { editReasonsList } from '../../config';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
};

const UpdateView = ({ ...props }) => {
  const { data, refsObj, enableErrorDisplay, handleInputChange, handleDropDownChange } = props;
  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <Form>
            <Fragment>
              <Input
                name='quantity'
                type='number'
                rule='isInt'
                labelContent='Quantity'
                enableValidation
                value={data.quantity}
                enableErrorDisplay={enableErrorDisplay}
                argument={{
                  min: 1,
                  allow_leading_zeroes: false,
                }}
                ref={(ref) => (refsObj.quantity = ref)}
                onChange={(event) => handleInputChange(event)}
              />
              <CustomSelect
                ref={(ref) => (refsObj.reason = ref)}
                labelContent='Reason'
                name='reason'
                placeholder='Reason'
                className='custom-select'
                options={editReasonsList}
                getOptionValue={({ reason }) => reason}
                getOptionLabel={({ reason }) => reason}
                onChange={(event) => handleDropDownChange(event.reason, ['reason'])}
                value={editReasonsList.filter(({ reason }) => reason === data.reason)}
                isSearchable={false}
              />
            </Fragment>
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
};

UpdateView.propTypes = propTypes;

UpdateView.defaultProps = defaultProps;

const ReceivedFormWithErrorAndLoading = withLoading(UpdateView);

export default ReceivedFormWithErrorAndLoading;
