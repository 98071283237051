import { compose } from 'recompose';
import SubCampaign from './SubCampaign';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  TOGGLE_STATE,
  CREATE_SUB_CAMPAIGN_DETAIL,
  EDIT_SUB_CAMPAIGN_DETAIL,
  EDIT_SUB_CAMPAIGN,
  REMOVE_SCOPE,
} from './API';

const ComposedSubCampaign = compose(
  TOGGLE_STATE,
  REMOVE_SCOPE,
  EDIT_SUB_CAMPAIGN,
  CREATE_SUB_CAMPAIGN_DETAIL,
  EDIT_SUB_CAMPAIGN_DETAIL,
  withRequestTracker({
    query,
    mutation,
  }),
)(SubCampaign);

export default ComposedSubCampaign;
