import React from 'react';
import { Col, Row } from 'react-flexbox-grid';

const InfoSection = ({infoSectionData}) => {
  return (
    <div className="info-section">
      <Row>
        <Col lg={4}>
          <p className="bold-text">Total Order Quantity</p>
          <p className="lead-text">{infoSectionData?.totalOrderQuantity || 0}</p>
        </Col>
        <Col lg={4}>
          <p className="bold-text">Total In Stock</p>
          <p className="lead-text">{infoSectionData?.totalInStock || 0}</p>
        </Col>
        <Col lg={4}>
          <p className="bold-text">Total Gap</p>
          <p className="lead-text">{infoSectionData?.totalGap || 0}</p>
        </Col>
      </Row>
    </div>
  );
};

export default InfoSection;
