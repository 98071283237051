import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Pagination, BulkDelete } from '../../../v4/components';
import { TableBody, TableHeader } from './tableConfig';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  onBulkDelete: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  pagination: dataProps.value,
  permission: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: dataProps.defaultValue,
  permission: PERMISSION_OBJ,
  pagination: paginationProps.defaultValue,
};

const ChannelView = ({ data, onBulkDelete, permission, onRowClick, onIconClick, pagination, onPageChange }) => (
  <Fragment>
    <BulkDelete
      data={data.list}
      type={STATEFUL_ENTITIES.CHANNEL}
      handler={onBulkDelete}
      render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
        <div className='table-wrap prn'>
          <table>
            {TableHeader({
              dataListLength: data.list.length,
              checkedListLength: checkedList.length,
              primaryCheckboxHandler,
              permission,
            })}

            <tbody>
              {data.list.map((srn) =>
                TableBody({
                  data: srn,
                  checkedList: checkedList,
                  onIconClick,
                  secondaryCheckboxHandler,
                  permission,
                  onRowClick,
                }),
              )}
            </tbody>
          </table>
        </div>
      )}
    />
    <Pagination
      currentPage={pagination.page}
      limit={pagination.limit}
      totalItems={data.total}
      onPageChange={onPageChange}
    />
  </Fragment>
);

ChannelView.propTypes = propTypes;

ChannelView.defaultProps = defaultProps;

export default withErrorAndLoadingScreen(ChannelView);
