import { persistentClient, client } from '../../apiClient/apollo';

import { GET_TOWN_LIST } from '../../views/configuration/town/API';
import { GET_CHANNEL_LIST, GET_CATEGORY_LIST } from '../../views/configuration/channel/API';

const DEFAULT_LIMIT = 5000;
const DEFAULT_OFFSET = 0;

const DEFAULT_QUERY_VARIABLES = {
  offset: DEFAULT_OFFSET,
  filter: {},
};

const errorProcessor = () => {
  console.log('Base Data Not Loaded');
};

export {
  persistentClient as clientResolver, client as apolloClient, DEFAULT_QUERY_VARIABLES, errorProcessor
};

export {
  GET_TOWN_LIST, GET_CATEGORY_LIST, GET_CHANNEL_LIST
};
