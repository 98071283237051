import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import withAlert from '../../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import { apiFilterProcessor, getOffsetFromPageAndLimit } from '../../../../../../utils/api';
import * as queryService from '../../../../../base/query.service';
import { getPermissionForBillingPlatform } from '../../../../../base/permission';
import { getUser } from '../../../../../../data/services';

const propTypes = {
  townId: PropTypes.number,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  id: PropTypes.number.isRequired,
  getLedgers: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  townId: null,
};

class LedgerInfo extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.userInfo = getUser();
    this.state = {
      show: false,
      update: false,
      outletId: props.id,
      data: {
        list: [],
        total: 0,
      },
      selectedLedger: {},
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: {
          retail_outlet_id: [props.id || 0],
          active: ['true'],
        },
      },
      distributorId: this.userInfo.Distributor.length > 0 && this.userInfo.Distributor[0].id,
    };
    this.permission = getPermissionForBillingPlatform();
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { queryParameters, date, data, outletId, distributorId } = this.state;

    const { getLedgers } = this.props;

    const offset = getOffsetFromPageAndLimit(queryParameters.pagination.page, queryParameters.pagination.limit);

    const filterString = apiFilterProcessor(queryParameters.filter);
    const parameter = {
      offset,
      filter: {
        filters: [...filterString, { column: 'active', value: ['true'] }],
        dateRange: date,
        queryString: '',
        sort: {},
      },
      retailOutletId: outletId,
    };
    const billingParameter = {
      ...parameter,
      filter: {
        filters: [
          ...filterString,
          { column: 'active', value: ['true'] },
          { column: 'distributor_id', value: distributorId ? [distributorId.toString()] : null },
        ],
        dateRange: date,
        queryString: '',
        sort: {},
      },
      distributorId,
    };
    getLedgers(this.userInfo.Distributor.length > 0 ? billingParameter : parameter, {
      handleSuccess: response => {
        data.list = response.data.customers.rows;
        data.total = response.data.customers.count;
        this.setState({ data });
      },
      handleError: error => {
        this.onAPIRequestFailure(error);
      },
    });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleCreateClick = () => {
    this.setState({ show: true, update: false });
  };

  onCancel = () => {
    this.setState({ show: false });
  };

  onSubmit = dialogData => {
    this.setState(
      {
        show: false,
      },
      () => this.getData(),
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleTableIconClick = (action, ledgerInfo) => {
    const { toggleState, displayAlert, editLedger } = this.props;
    const { data } = this.state;

    if (action === 'DELETE') {
      toggleState(
        {
          ids: [parseInt(ledgerInfo.id, 10)],
          type: 'Customer',
          active: false,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Ledger Mapping Removed');
            data.list = data.list.filter(ledger => ledger.id !== ledgerInfo.id);
            data.total -= 1;
            this.setState({ data });
          },
          handleError: err => {
            this.onAPIRequestFailure(err);
          },
        },
      );
    } else if (action === 'UPDATE') {
      this.handleCreateClick();
      this.setState({ update: true, selectedLedger: ledgerInfo });
    }
  };

  render() {
    const { show, data, outletId, update, selectedLedger } = this.state;
    const { serverResponseWaiting, townId } = this.props;
    return (
      <View
        data={data.list}
        onCancel={this.onCancel}
        onSubmit={this.onSubmit}
        handleCreateClick={this.handleCreateClick}
        show={show}
        update={update}
        loading={serverResponseWaiting}
        id={outletId}
        billingUser={this.userInfo.Distributor.length > 0 && this.userInfo.Distributor[0].servicesUsed.billing.status}
        townId={townId}
        permission={this.permission}
        handleTableIconClick={this.handleTableIconClick}
        ledgerInfo={selectedLedger}
      />
    );
  }
}

LedgerInfo.propTypes = propTypes;

LedgerInfo.defaultProps = defaultProps;

export default withAlert()(LedgerInfo);
