import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { SKU_STATUS } from '../../config';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import Popover from '../../../../../components/Popover/Popover';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { Button } from '../../../../../v4/components';

const detailLabelMappings = {
  id: 'id',
  sku: 'sku',
  quantity: 'quantity',
  rate: 'rate',
  status: 'status',
  poStatus: 'poStatus',
  promotion: 'promotion',
  value: 'value',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.id,
    title: 'Order ID',
    show: true,
  },
  {
    id: 2,
    label: detailLabelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 3,
    label: detailLabelMappings.quantity,
    title: 'Quantity',
    align: true,
    show: true,
  },
  {
    id: 4,
    label: detailLabelMappings.rate,
    title: 'Rate',
    align: true,
    show: true,
  },
  {
    id: 5,
    label: detailLabelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 6,
    label: detailLabelMappings.poStatus,
    title: 'PO Status',
    classNames: 'text-center',
    show: true,
  },
  {
    id: 7,
    label: detailLabelMappings.promotion,
    title: 'Promotion',
    classNames: 'text-center',
    show: true,
  },
  {
    id: 8,
    label: detailLabelMappings.value,
    title: 'Value',
    align: true,
    show: true,
  },
];

const getMenuHeader = (disabledStatus = false) => <Button iconName="ellipsis-v" disabled={disabledStatus} />;

const returnDetailTableBody = (data, index, onIconClick, order, permission, stockStatusInfo) => {
  const table = {};
  const stockStatusTitle = (
    <span className={`${SKU_STATUS[data.inStock]} stock-label`}>{SKU_STATUS[data.inStock]}</span>
  );
  table[detailLabelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[detailLabelMappings.sku] = (
    <td key={`${data.id}-sku`}>
      {data.billDiscountSku && <span className="bill-discount-indicator" />}
      {data.freeSku && <span className="free-sku-indicator" />}
      {data.SKU ? data.SKU.title : ''}
      {data.focusedSku && <span className="focused-sku-indicator" />}
    </td>
  );
  table[detailLabelMappings.quantity] = (
    <td key={`${data.id}-quantity`} className="text-right">
      {data.quantity}
    </td>
  );
  table[detailLabelMappings.rate] = (
    <td key={`${data.id}-rate`} className="text-right">
      {data.rateDetails ? data.rateDetails.rlp : null}
    </td>
  );
  table[detailLabelMappings.status] = (
    <td key={`${data.id}-popup`}>
      {stockStatusInfo ? (
        <div className="simple-popup">
          <Popover popoverIcon={stockStatusTitle} headerTitle={SKU_STATUS[data.inStock]}>
            {data.SKU ? data.SKU.title : ''}
            <br />
            <b>{`Quantity : ${data.stockBalance === null ? 0 : data.stockBalance}`}</b>
          </Popover>
        </div>
      ) : (
        stockStatusTitle
      )}
    </td>
  );
  table[detailLabelMappings.poStatus] = (
    <td key={`${data.id}-poStatus`} className="text-center">
      {data.inStock === 'INSTOCK' ? (
        '-'
      ) : (
        <span
          className={`${
            data.pqStatus === 'REQUESTED' ? SKU_STATUS.STOCK_INSUFFICIENT : SKU_STATUS.INSTOCK
          } stock-label`}
        >
          {data.pqStatus}
        </span>
      )}
    </td>
  );
  table[detailLabelMappings.promotion] =
    index === 0 && order.promotionId ? (
      <td key={`${data.id}-image`} className="text-center">
        <img
          src="/image/icons/Promotion.svg"
          style={{ cursor: 'pointer' }}
          onClick={() => onIconClick(EVENT_OPERATION.READ, order)}
        />
      </td>
    ) : (
      <td key={`${data.id}-image`} />
    );
  table[detailLabelMappings.value] = (
    <td key={`${data.id}-amount`} className="text-right">
      {fixedFloatAndCommas(data.amountDetails.subTotal || 0)}
    </td>
  );
  return table;
};

const DetailTableBody = ({ data, index, order, onIconClick, permission, stockStatusInfo }) => {
  const tableBody = returnDetailTableBody(data, index, onIconClick, order, permission, stockStatusInfo);
  return <Fragment>{detailsLabelConfig.map(label => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

DetailTableBody.propTypes = {
  onSecondaryCheckBoxClick: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  order: PropTypes.instanceOf(Object),
  checkBoxStatus: PropTypes.func,
  onIconClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  callOrderValue: PropTypes.instanceOf(Object),
  stockStatusInfo: PropTypes.bool,
};

DetailTableBody.defaultProps = {
  onSecondaryCheckBoxClick: () => null,
  data: {},
  checkBoxStatus: () => null,
  order: {},
  permission: PERMISSION_OBJ,
  onIconClick: () => null,
  callOrderValue: {},
  stockStatusInfo: false,
};

export { DetailTableBody, detailsLabelConfig };
