import React from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../../../../utils/composition/withLoading';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';

const propTypes = {
  data: PropTypes.instanceOf(Object),
};
const defaultProps = {
  data: {
    Promotion: { title: 'abc' },
    Lines: [],
  },
};
const PromotionView = ({ data }) => (
  <div className="promotion-popup">
    <h3> Promotion </h3>
    <span className="text">{data.Promotion ? data.Promotion.title : ''}</span>
    <div className="table">
      <table>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>SKU</th>
            <th>Rate</th>
            <th>Quantity</th>
            <th>Gross Amount</th>
            <th>Discount</th>
            <th>Top Up Discount</th>
            <th>Net Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.Lines.map(item => (
            <tr>
              <td>{item.id}</td>
              <td style={{ minWidth: 220 }}>{item.SKU.title}</td>
              <td>{item.rateDetails.rlp}</td>
              <td>{item.quantity}</td>
              <td>{fixedFloatAndCommas(item.amountDetails.grossAmount)}</td>
              <td>{fixedFloatAndCommas(item.amountDetails.promotionDiscount)}</td>
              <td>{fixedFloatAndCommas(item.amountDetails.topUpDiscount)}</td>
              <td>{fixedFloatAndCommas(item.amountDetails.subTotal)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>Total</td>
            <td>{data.total.quantity}</td>
            <td>{fixedFloatAndCommas(data.total.grossAmount)}</td>
            <td>{fixedFloatAndCommas(data.total.promotionDiscount)}</td>
            <td>{fixedFloatAndCommas(data.total.topUpDiscount)}</td>
            <td>{fixedFloatAndCommas(data.total.subTotal)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
);

PromotionView.propTypes = propTypes;

PromotionView.defaultProps = defaultProps;

const PromotionViewErrorAndLoading = withLoading(PromotionView);

export default PromotionViewErrorAndLoading;
