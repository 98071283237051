import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_TAS_LIST = gql`
  query getTasOrderAnalysisReport($filter: FilterInput) {
  getTasOrderAnalysisReport(filter: $filter) {
    rows {
      distributor
      zone
      pending
      one_day_pending
      two_day_pending
      three_day_pending
      three_day_above_pending
      total_invoiced
      cancelled
      order_value
    }
    count
  }
}
`;

const query = {
  getTASList: GET_TAS_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
