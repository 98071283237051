import React from 'react';
import Error from './Error';

const ErrorPage = ({ ...props }) => {
  const { match } = props;
  return (
    <Error
      value={parseInt(match.params.id, 10)}
    />
  )
};

export default ErrorPage;
