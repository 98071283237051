import styled from 'styled-components';

const SkuCoverageStyled = styled.div`
  .channel {
    table {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
  .distributor,
  .category {
    table {
      tbody {
        tr {
          cursor: default;
        }
      }
    }
  }
`;
export default SkuCoverageStyled;
