import React from 'react';
import { monthList as defaultMonthList } from '../../constants/monthList';
import { getCurrentMonth, getCurrentYear } from '../../../utils/date';

const getMonthList = (months: any, activeMonth: number, isPresentationYearActive: boolean, onClick: (month: any) => void, yearInPresentation: number, isDisabledReq = false, columnWidth = 4,) => {
  const numberOfMonths = 12;
  const rowList = [...Array(numberOfMonths / columnWidth).keys()];
  const columnList = [...Array(columnWidth).keys()];

  const currentMonthIndex = getCurrentMonth() + 1; // Adding 1 because months are zero-based

  return rowList.map((rowElement, rowIndex) => (
    <div key={rowIndex} className='month-date-list-wrap'>
      {columnList.map((columnElement, columnIndex) => {
        const monthIndex = rowIndex * columnWidth + columnIndex + 1; // Adding 1 because months are one-based
        const isDisabled = (yearInPresentation === getCurrentYear()) ? monthIndex < currentMonthIndex : yearInPresentation < getCurrentYear() ? true : false;

        return (
          <div
            key={columnIndex}
            onClick={() => isDisabledReq ? !isDisabled && onClick(months[rowIndex * columnWidth + columnIndex]) : onClick(months[rowIndex * columnWidth + columnIndex])}
            className={
              isPresentationYearActive && activeMonth === months[rowIndex * columnWidth + columnIndex].value.monthIndex
                ? 'active month-date-list'
                : isDisabledReq ? isDisabled ? 'disabled month-date-list' : 'month-date-list' : 'month-date-list'
            }
          >
            {months[rowIndex * columnWidth + columnIndex].title}
          </div>
        );
      })}
    </div>
  ));
};

const MonthPresentor = ({ ...props }) => {
  const { monthList, onMonthClick, activeMonth, isPresentationYearActive, yearInPresentation, isDisabledReq } = props;
  return (
    <div style={{ padding: '5px 16px' }}>
      {getMonthList(monthList, activeMonth, isPresentationYearActive, onMonthClick, yearInPresentation, isDisabledReq)}
    </div>
  );
};



MonthPresentor.defaultProps = {
  activeMonth: 1,
  onMonthClick: () => null,
  monthList: defaultMonthList,
  isPresentationYearActive: false,
};

export default MonthPresentor;
