import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { HEADER_ICONS } from './config';
import MonthlyView from './MonthlyView';
import WeeklyView from './WeeklyView';
import WeekTitle from './WeeklyView/WeekTitle';
import { Button } from '../../../v4/components';
import MonthTitle from './MonthlyView/MonthTitle';
import { CustomSelect } from '../../../components';
import dseEvents from './MonthlyView/monthyViewData';
import { CALENDAR_PREVIEW } from '../../../data/enums';
import withLoading from '../../../utils/composition/withLoading';
import RosterPrintViewStyled from './RosterPrintViewStyled';
import { getDayMonthTitleFromDate, getMonthYearTitleFromDate } from './date';

const propTypes = {
  calendarHeader: PropTypes.node,
  calendarPreview: PropTypes.string,
  week: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  month: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  dateArray: PropTypes.instanceOf(Array),
  weekStartDay: PropTypes.number,
  onHeaderIconClick: PropTypes.func,
  onMonthChange: PropTypes.func,
  onWeekChange: PropTypes.func,
  onCalendarClick: PropTypes.func,
  weekEndList: PropTypes.arrayOf(Array),
  holidayList: PropTypes.arrayOf(Array),
  rosterData: PropTypes.arrayOf(Array),
  onRoleSelect: PropTypes.func,
  userRoleList: PropTypes.arrayOf(Object),
  activeRoleId: PropTypes.number,
  userList: PropTypes.arrayOf(Object),
  leaveList: PropTypes.arrayOf(Object),
  subDDetails: PropTypes.objectOf(Object),
  printMode: PropTypes.bool,
  activeUserId: PropTypes.number,
  callRosterPermission: PropTypes.obj,

};

const defaultProps = {
  calendarHeader: null,
  calendarPreview: 'WEEKLY',
  week: {
    start: '2010-01-01',
    end: '2020-01-08',
  },
  month: {
    start: '2010-01-01',
    end: '2020-01-31',
  },
  dateArray: [],
  weekStartDay: 0,
  onHeaderIconClick: () => null,
  onMonthChange: () => null,
  onWeekChange: () => null,
  onCalendarClick: () => null,
  weekEndList: [],
  holidayList: [],
  rosterData: [],
  onRoleSelect: () => null,
  userRoleList: [],
  activeRoleId: 0,
  userList: [],
  leaveList: [],
  subDDetails: {},
  printMode: false,
  activeUserId: null,
  callRosterPermission:{},
};

const View = ({ ...props }) => {
  const {
    calendarHeader,
    calendarPreview,
    week,
    dateArray,
    month,
    weekStartDay,
    onMonthChange,
    onWeekChange,
    onCalendarClick,
    onHeaderIconClick,
    weekEndList,
    holidayList,
    rosterData,
    onRoleSelect,
    userRoleList,
    activeRoleId,
    userList,
    leaveList,
    subDDetails,
    printMode,
    activeUserId,
    callRosterPermission,
    routeList,
  } = props;

  const activeUser = userList.find((user) => user.id === activeUserId) || {
    firstName: '',
    lastName: '',
  };

  return (
    <div className='roster-wrapper'>
      {!printMode ? (
        <div className='roster-top-filter'>
          <div className='roster-top-search'>
            {
            // userRoleList.length > 0 ? (
              <>
                <CustomSelect
                  small
                  clearable={false}
                  className='custom-select'
                  placeholder='Select User Role'
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  options={userRoleList}
                  enableValiation
                  value={userRoleList.filter(({ id }) => id === activeRoleId)}
                  onChange={(route) => onRoleSelect(route)}
                />
                <div className='vertical-divider' />
              </>
            // )
            //   : null
            }
            <div className='week-month-btn'>
              <Button
                primary={calendarPreview === CALENDAR_PREVIEW.WEEKLY}
                title='Weekly'
                onClick={() => onHeaderIconClick(HEADER_ICONS.WEEKLY)}
              />
              <Button
                primary={calendarPreview === CALENDAR_PREVIEW.MONTHLY}
                title='Monthly'
                onClick={() => onHeaderIconClick(HEADER_ICONS.MONTHLY)}
              />
            </div>
          </div>
          <>
            <div className='roster-date-select'>
              {calendarPreview === CALENDAR_PREVIEW.MONTHLY ? (
                <MonthTitle month={month} onMonthChange={onMonthChange} />
              ) : null}
              {calendarPreview === CALENDAR_PREVIEW.WEEKLY ? (
                <WeekTitle weekRange={week} onWeekChange={onWeekChange} />
              ) : null}
            </div>
            <div className='roster-action'>
              <Button primary title='Today' onClick={() => onHeaderIconClick(HEADER_ICONS.TODAY)} />
              <div className='vertical-divider' />
              {callRosterPermission?.create && <Button outlined iconName='copy' onClick={() => onHeaderIconClick(HEADER_ICONS.REPLICATION)} />}
              <Button outlined iconName='print' onClick={() => onHeaderIconClick(HEADER_ICONS.PRINT)} />
            </div>
          </>
        </div>
      ) : null}

      {printMode ? (
        <RosterPrintViewStyled>
          <div className='roster-print-wrap'>
            <div className='roster-print-header'>
              {calendarPreview === CALENDAR_PREVIEW.WEEKLY ? (
                <Fragment>
                  <h2 className='weekly-title'>{subDDetails.title}</h2>
                  <p className='weekly-address'>{subDDetails.address}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <h2 className='user'>
                    {activeUser.firstName}
                    {activeUser.lastName}
                  </h2>
                  <h3 className='monthly-title'>{subDDetails.title}</h3>
                  <p className='monthly-address'>{subDDetails.address}</p>
                </Fragment>
              )}
            </div>
            <div className='roster-print-date'>
              <h3>
                {calendarPreview === CALENDAR_PREVIEW.WEEKLY
                  ? `${getDayMonthTitleFromDate(week.start)} - ${getDayMonthTitleFromDate(week.end)}`
                  : `${getMonthYearTitleFromDate(month.start)}`}
              </h3>
              <span>Call Roster</span>
            </div>
            {calendarPreview === CALENDAR_PREVIEW.MONTHLY ? (
              <MonthlyView
                header={calendarHeader}
                data={rosterData.length > 0 ? rosterData[0].data : []}
                userList={userList}
                month={month}
                events={dseEvents}
                weekStartDay={weekStartDay}
                weekEndList={weekEndList}
                holidayList={holidayList}
                leaveList={leaveList}
                subDDetails={subDDetails}
                callRosterPermission={callRosterPermission}
              />
            ) : (
              <WeeklyView
                header={calendarHeader}
                userList={userList}
                weekRange={week}
                weekEndList={weekEndList}
                data={rosterData}
                dateList={dateArray}
                weekStartDay={weekStartDay}
                holidayList={holidayList}
                leaveList={leaveList}
                onCalendarClick={onCalendarClick}
                subDDetails={subDDetails}
                callRosterPermission={callRosterPermission}
              />
            )}
          </div>
        </RosterPrintViewStyled>
      ) : null}

      {calendarPreview === CALENDAR_PREVIEW.MONTHLY && !printMode ? (
        <MonthlyView
          header={calendarHeader}
          data={rosterData.length > 0 ? rosterData[0].data : []}
          userList={userList}
          month={month}
          events={dseEvents}
          weekStartDay={weekStartDay}
          weekEndList={weekEndList}
          holidayList={holidayList}
          leaveList={leaveList}
          subDDetails={subDDetails}
          routeList={routeList}
          callRosterPermission={callRosterPermission}
        />
      ) : null}
      {calendarPreview === CALENDAR_PREVIEW.WEEKLY && !printMode ? (
        <WeeklyView
          header={calendarHeader}
          userList={userList}
          weekRange={week}
          weekEndList={weekEndList}
          data={rosterData}
          dateList={dateArray}
          weekStartDay={weekStartDay}
          holidayList={holidayList}
          leaveList={leaveList}
          onCalendarClick={onCalendarClick}
          subDDetails={subDDetails}
          routeList={routeList}
          callRosterPermission={callRosterPermission}
        />
      ) : null}
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
