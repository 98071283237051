import { compose } from 'recompose';
import CallPlanDetails from './CallPlanDetails';
import { query, mutation, SAVE_PLANS_FOR_TAS, SAVE_TAS_FOR_UNSUCCESSFUL_CALLS, SAVE_PLANS_FOR_SUCCESSFUL_CALLS } from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedCallPlanDetails = compose(
  SAVE_PLANS_FOR_TAS,
  SAVE_TAS_FOR_UNSUCCESSFUL_CALLS,
  SAVE_PLANS_FOR_SUCCESSFUL_CALLS,
  withRequestTracker({
    query,
    mutation
  })
)(CallPlanDetails)

export default ComposedCallPlanDetails;
