import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const CHANNEL_FRAGMENT = gql`
  fragment ChannelFragment on Channel {
    id
    title
  }
`;

const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    title
    Channel {
      id
    }
  }
`;

const CATEGORY_SUMMARY_FRAGMENT = gql`
  fragment CategorySummaryFragment on Category {
    id
    title
  }
`;

const CHANNEL_CATEGORY_FRAGMENT = gql`
  fragment ChannelCategoryFragment on Channel {
    ...ChannelFragment
    Categories {
      ...CategoryFragment
      channelId
    }
  }
  ${CHANNEL_FRAGMENT}
  ${CATEGORY_FRAGMENT}
`;

const GET_CHANNEL_LIST = gql`
  query getChannel($offset: Int, $limit: Int) {
    channels(offset: $offset, limit: $limit) {
      rows {
        ...ChannelCategoryFragment
        channelType
      }
      count
    }
  }
  ${CHANNEL_CATEGORY_FRAGMENT}
`;

const GET_CATEGORY_LIST = gql`
  query getCategory($offset: Int, $limit: Int) {
    categories(offset: $offset, limit: $limit) {
      rows {
        ...CategoryFragment
      }
      count
    }
  }
  ${CATEGORY_FRAGMENT}
`;

const CREATE_CHANNEL = graphql(
  gql`
    mutation ($title: String!, $channelType: channelType!) {
      createChannel(title: $title, type: $channelType) {
        ...ChannelCategoryFragment
        channelType
      }
    }
    ${CHANNEL_CATEGORY_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createChannel: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_CATEGORY = graphql(
  gql`
    mutation ($title: String!, $channelId: Int!) {
      createCategory(title: $title, channelId: $channelId) {
        title
        channelId
      }
    }
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createCategory: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_CHANNEL = graphql(
  gql`
    mutation ($id: Int!, $title: String!, $channelType: channelType!) {
      updateChannel(id: $id, title: $title, type: $channelType) {
        ...ChannelCategoryFragment
        channelType
      }
    }
    ${CHANNEL_CATEGORY_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateChannel: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_CATEGORY = graphql(
  gql`
    mutation ($id: Int!, $title: String!, $channelId: Int!) {
      updateCategory(id: $id, title: $title, channelId: $channelId) {
        ...CategoryFragment
        channelId
      }
    }
    ${CATEGORY_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateCategory: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DELETE_CHANNEL_CATEGORY = graphql(
  gql`
    mutation ($ids: [Int]!, $type: statefulEntities, $active: Boolean!) {
      toggleState(ids: $ids, type: $type, active: $active) {
        ids
        message
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleState: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getChannelList: GET_CHANNEL_LIST,
  getCategoryList: GET_CATEGORY_LIST,
};

const mutation = {
  createChannel: CREATE_CHANNEL,
  createCategory: CREATE_CATEGORY,
  updateChannel: UPDATE_CHANNEL,
  updateCategory: UPDATE_CATEGORY,
  toggleState: DELETE_CHANNEL_CATEGORY,
};

export {
  GET_CHANNEL_LIST,
  GET_CATEGORY_LIST,
  CHANNEL_FRAGMENT,
  CATEGORY_FRAGMENT,
  CREATE_CHANNEL,
  CREATE_CATEGORY,
  UPDATE_CHANNEL,
  UPDATE_CATEGORY,
  CATEGORY_SUMMARY_FRAGMENT,
  DELETE_CHANNEL_CATEGORY,
};

export { query, mutation };
