import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PromotionInfo from './info/View';
import PromotionScope from './scope';
import PromotionBanner from './banner';
import PromotionCondition from './conditions';
import ProductCatalog from './scope/ProductCatalog';
import PromotionDisbursement from './disbursements';
import { getCurrentDay } from '../../../../utils/date';
import { has } from '../../../../utils/objectPrototypes';
import { clone } from '../../../../utils/objectProcessor';
import { SKU_CATALOG_LEVEL } from '../../../../data/enums';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { PROMOTION } from '../../../../data/enums/Route';
import { getPermissionForPromotion } from '../../../base/permission';
import { EVENT_OPERATION, EVENT_OPERATION_MAPPER } from '../../../../data/enums/EventOperation';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { refGenerator, refValidator } from '../../../../utils/refGenerator';
import { inputChange, dropdownChange } from '../../../../utils/formHandlers';
import { getDataFromLocalStorage, CLIENT_STORAGE_TABLE } from '../../../../data/services';
import {
  title,
  breadCrumbConfig,
  form as formConfig,
  stateToRequestProcessor,
  responseToStateProcessor,
  CUSTOM_SKU_GROUPS,
} from './config';
import PromotionDetailStyled from './PromotionDetailStyled';
import { DEFAULT_QUERY_VARIABLES } from '../../../../data/services/common';
import { PanelCard, BreadCrumb, Icon,Button} from '../../../../v4/components';
import { CATALOG_LEVEL } from '../../../../data/enums/Catalog';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  updatePromotion: PropTypes.func.isRequired,
  createPromotion: PropTypes.func.isRequired,
  getPromotionDetail: PropTypes.func.isRequired,
  getFocusedSKUListWithTitle: PropTypes.func.isRequired,
  getSBDListWithTitle: PropTypes.func.isRequired,
  getRetailOutletCount: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const promotionCriteriaBaseForCustomGroups = {
  operation: {
    max: {
      value: 0,
      // greater than equals
      operator: 'GREATER_THAN_EQUALS', // PROMOTION_CRITERIA_OPERATION.filter(el => el.id === 2).value,
    },
    min: {
      value: 0,
      // greater than equals
      operator: 'GREATER_THAN_EQUALS', // PROMOTION_CRITERIA_OPERATION.filter(el => el.id === 2).value,
    },
  },
  dimension: 'QUANTITY', // PROMOTION_CRITERIA_DIMENSION.filter(el => el.id === 1).value,
  skuCount: 1,
  allowMultiple: true,
};

const promotionScopeBase = {
  categoryIds: [],
  channelCategoryInfo: {
    channelId: null,
    categoryIds: [],
  },
  territorySubDInfo:{
    territoriesId:null,
    subDIdList:[]
  },
  promotionCatalog: [{ catalogInfo: {}, criteria: promotionCriteriaBaseForCustomGroups }],
  customGroupDetails: {
    sku: {
      id: null,
      criteria: promotionCriteriaBaseForCustomGroups,
    },
    skuFamily: {
      id: null,
      criteria: promotionCriteriaBaseForCustomGroups,
    },
    criteria: promotionCriteriaBaseForCustomGroups,
  },
};

const customGroupBase = {
  skus: [promotionScopeBase.customGroupDetails.sku],
  skuFamily: [promotionScopeBase.customGroupDetails.skuFamily],
  criteria: promotionScopeBase.customGroupDetails.criteria,
};

const getPromotionId = props =>
  props && props.match && props.match.params && has.call(props.match.params, 'id')
    ? parseInt(props.match.params.id, 10)
    : 0;

const getPromotionInitialState = (props = null) => ({
  territories:[],
  info: {
    promotionId: getPromotionId(props),
    title: '',
    startDate: getCurrentDay(),
    endDate: getCurrentDay(),
    active: true,
    description: '',
    notification: false,
    validCount: {
      status: false,
      count: 0,
    },
  },
  banner: {
    images: [],
  },
  scope: {
    categoryList: [],
    catalogGroupId: [],
    channelList: [],
    type: '', // Normal, Current Bill, Next Bill, Reimbursement
    relatedType: '', // Focused SKU, Disbursement
    // applicableSkus: [],
    relatedId: '',
    territorySubDInfo:[{...promotionScopeBase.territorySubDInfo}],
    promotionAreas: {
      subDIdList: [],
      townIdList: [],
    },
    promotionCatalog: [...promotionScopeBase.promotionCatalog],
    channelCategoryInfo: [
      {
        ...promotionScopeBase.channelCategoryInfo,
      },
    ],
    customGroupDetails: [
      {
        skus: [promotionScopeBase.customGroupDetails.sku],
        skuFamily: [promotionScopeBase.customGroupDetails.skuFamily],
        criteria: promotionScopeBase.customGroupDetails.criteria,
      },
    ],
    customGroupStatus: false,
    categoryIds: [],
    enablePromotions: [],
    platform: {
      retailer: true,
      marketplace: true,
      sales: true,
    },
    billing_status: false,
    billIssueStatus:false,
    outletCount: null,
  },
  criteria: {
    dimension: 'QUANTITY', // promotion criteria dimension
    operation: {
      max: {
        value: 0,
        operator: 'GREATER_THAN_EQUALS', // promotion criteria operator
      },
      min: {
        value: 0,
        operator: 'GREATER_THAN_EQUALS', // promotion criteria operator
      },
    },
    type: 'NORMAL',
    skuCount: 1,
    allowMultiple: false,
  },
  disbursement: {
    dimension: 'FREE_SKU', // PROMOTION disbursement type
    value: 1,
    // catalogId: 0,
    // freeSkuId: [], // 1,2,3,4,5
    freeSKUs: [],
    allowMultiple: false,
    searchText: '',
  },
  menu: {
    catalogList: [],
    scopeCatalogChildren: [],
    scopeCatalogGroupChildren: [],
    channelList: [],
    categoryList: [],
    disbursementCatalogChildren: [],
    focusedSKUList: [],
    sbdList: [],
    subDList: [],
    townList: [],
  },
  displayErrorInForm: false,
  crudMode: getPromotionId(props) ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
});

class PromotionDetail extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = getPromotionInitialState(props);
    this.permission = getPermissionForPromotion();
    this.formReference = {
      info: refGenerator(formConfig.validationField.info),
      disbursement: refGenerator(formConfig.validationField.disbursement),
      scope: refGenerator(formConfig.validationField.scope),
      productCatalog: refGenerator(formConfig.validationField.productCatalog),
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createPromotion,
      [EVENT_OPERATION.UPDATE]: props.updatePromotion,
    };

    this.onFormSubmit = (crudMode, data) => {
      // eslint-disable-next-line react/prop-types
      const { history, displayAlert } = this.props;
      serverCall[crudMode](data, {
        handleSuccess: res => {
          if (crudMode === EVENT_OPERATION.UPDATE) {
            const id = res.data.updatePromotion.id;
            displayAlert(ALERT_TYPE.SUCCESS, 'Promotion Updated.');
            setTimeout(() => {
              history.push(`/${PROMOTION}`);
            }, 900);
          }

          if (crudMode === EVENT_OPERATION.CREATE) {
            const promotionId = res.data.createPromotion.id;
            displayAlert(ALERT_TYPE.SUCCESS, 'Promotion Created.');
            setTimeout(() => {
              history.push(`/${PROMOTION}`);
            }, 900);
          }
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      });
    };
  }

  componentDidMount() {
    const { info: { promotionId } } = this.state;
    this.fetchTerritoryList();
    if (promotionId) {
      this.getData(promotionId);
    } else {
      this.loadData();
    }
    // this.loadFocusedSKU();
    // this.loadSBDList();
  }

  fetchTerritoryList = () => {
    const { getTerritoryList } = this.props;

    getTerritoryList(
      { ...DEFAULT_QUERY_VARIABLES },
      {
        handleSuccess: response => {
          const { territories = {} } = response.data;
          this.setState({ territories: territories ? territories.rows : [] });
        },
        handleError: err => this.handleAPIFailure(err),
      },
    );
  };

  getState = () => this.state;

  getData = id => {
    const { getPromotionDetail } = this.props;
    getPromotionDetail({ id: id.toString() }, {
      handleSuccess: (response) => {
        const data = response.data.promotions.rows[0];
        const state = this.getState();
        const updatedState = responseToStateProcessor(data, state);
        updatedState.crudMode = EVENT_OPERATION.READ;
        const { promotionCatalog } = data;
        const promotionScopeCatalogLevel = promotionCatalog.length ? (promotionCatalog[0].catalogInfo.catalogId || SKU_CATALOG_LEVEL) : SKU_CATALOG_LEVEL;
        this.updateMenuForPromotionScopeCatalogChildren(promotionScopeCatalogLevel);
        updatedState.scope.catalogGroupId.forEach((groupId, index) => {
          this.updateMenuForPromotionScopeGroupCatalogChildren(groupId, 0, index);
        });
        this.setState(updatedState, () => { this.loadData(); });
        this.getRetailOutletCount();
      },
      handleError: (error) => {
        this.onAPIRequestFailure(error);
      },
    });
  };

  loadFocusedSKU = () => {
    const { menu } = this.state;
    const { getFocusedSKUListWithTitle } = this.props;

    getFocusedSKUListWithTitle({
      offset: 0,
    }, {
      handleSuccess: (response) => {
        menu.focusedSKUList = response.data.focusedSKUs ? response.data.focusedSKUs.rows || [] : [];
        this.setState({ menu });
      },
      handleError: (error) => {
      },
    });
  };

  loadSBDList = () => {
    const { getSBDListWithTitle } = this.props;
    const { menu } = this.state;

    getSBDListWithTitle(
      {
        offset: 0,
      },
     {
      handleSuccess: (response) => {
        menu.sbdList = response.data.sbds.rows;
        this.setState({ menu });
      },
      handleError: (error) => {
      },
    });
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleDateRangeChange = (label, value) => {
    const { info } = this.state;
    info[label] = value;
    this.setState({ info });
  };

  handleDropDownChange = label => (value, parameterRef = [], callBack = () => null) => {
    const { [label]: previousState, disbursement } = this.state;
    const updatedState = dropdownChange(previousState, parameterRef, value);

    if (label === 'disbursement') {
      if (updatedState.dimension !== 'FREE_SKU') {
        updatedState.freeSKUs = [];
        updatedState.allowMultiple = false;
      }
    }
    if (label === 'criteria' && (value === 'CURRENT_BILL')) {
      disbursement.dimension = 'PERCENT';
      disbursement.freeSKUs = [];
      disbursement.allowMultiple = false;
      this.setState(disbursement);
    }
    if (label === 'criteria' && (value === 'AMOUNT' || value === 'TOP_UP')) {
      disbursement.freeSKUs = [];
      disbursement.allowMultiple = false;
      this.setState(disbursement);
    }
    if(label === 'criteria' && value === 'NORMAL'){
      updatedState.dimension= 'QUANTITY';
    }
    this.setState({ [label]: updatedState });
  };

  handleAutoCompleteChange = key => (value, fieldName, index) => {
    const { [key]: keyValue, scope,territories,info} = this.state;
    // Refactor with switch case
    if (key === 'scope' && fieldName === 'catalogInfo') {
      keyValue.promotionCatalog[index].catalogInfo.catalogDetailIds = [...value];
    } else if (key === 'scope' && fieldName === 'catalogDetails') {
      keyValue.promotionCatalog[index] = {
        catalogInfo: {
          catalogId: SKU_CATALOG_LEVEL,
          catalogDetails: [...value],
        },
        criteria: keyValue.promotionCatalog[index].criteria,
      };
    } else if (key === 'scope' && fieldName === 'skuFamily') {
      keyValue.promotionCatalog[index] = {
        catalogInfo: {
          catalogId: CATALOG_LEVEL.SKU_FAMILY,
          skuFamily: [...value],
        },
        criteria: keyValue.promotionCatalog[index].criteria,
      };
    } else if (key === 'scope' && fieldName === CUSTOM_SKU_GROUPS.SKU) {
      if (keyValue.customGroupDetails[index].skus) {
        keyValue.customGroupDetails[index].skus = [...value];
      } else if (keyValue.customGroupDetails[index].skuFamily) {
        keyValue.customGroupDetails[index].skuFamily = [...value];
      }
    } else if (key === 'scope' && fieldName === CUSTOM_SKU_GROUPS.HEADER) {
      keyValue.customGroupDetails[index].criteria = value;
    } else if (key === 'scope' && fieldName === 'categoryList') {
      keyValue.categoryIds = [...value];
    } else if (key === 'scope' && fieldName === 'channelCategoryInfo') {
      const newValue = value.map(va => va);
      keyValue.channelCategoryInfo[index].categoryIds = [...newValue];
    } 
    else if(fieldName==='territoriesId'){
      keyValue.territorySubDInfo[index][fieldName] =value;
    }
    else if( fieldName === 'subDIdList'){
      if (value[value.length - 1] === 0) {
        const getUserListUnderRole = () =>
        territories.filter((a)=>a.id ===scope.territorySubDInfo[index].territoriesId).map((a)=>a?.Towns?.map((b)=>b?.Distributor.length > 0 ?b?.Distributor.flat():[]).flat()).flat().map(a => a.id);
        scope.territorySubDInfo[index].subDIdList = getUserListUnderRole();
      }
        else {
          if(info.promotionId){
            scope.promotionAreas.subDIdList= [...value];
          }else{
          scope.territorySubDInfo[index].subDIdList= [...value];
          }
        }
        if(!info.promotionId){
        const allSubDIds =scope.territorySubDInfo.map(a =>a.subDIdList.map(a=>a)).flat();
        scope.promotionAreas.subDIdList =allSubDIds;
        }
    }
    else {
      [keyValue][fieldName] = value;
    }

    this.setState({ [key]: keyValue });
    if (key === 'scope') { this.getRetailOutletCount(scope); }
  };

  handleInputChange = label => (event, firstParam = '', paramList = []) => {
    const { [label]: prevState } = this.state;
    const updatedDetails = inputChange(prevState, event, firstParam, paramList);
    if (updatedDetails.customGroupDetails && updatedDetails.customGroupDetails.length) {
      updatedDetails.promotionCatalog = [...promotionScopeBase.promotionCatalog];
    }
    this.setState({ [label]: updatedDetails }, () => {
      const { [label]: updateData } = this.state;
      if (firstParam === 'validCount' && updateData.validCount.status === false) {
        updateData.validCount.count = 0;
      }
    });
    if (label === 'scope') { 
      this.getRetailOutletCount(updatedDetails); 
      this.setState({
        criteria: {
          dimension: 'QUANTITY', // promotion criteria dimension
          operation: {
            max: {
              value: 0,
              operator: 'GREATER_THAN_EQUALS', // promotion criteria operator
            },
            min: {
              value: 0,
              operator: 'GREATER_THAN_EQUALS', // promotion criteria operator
            },
          },
          type: 'NORMAL',
          skuCount: 1,
          allowMultiple: false,
        }
      })
    }
  };

  updateMenuForPromotionScopeCatalogChildren = (level) => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, level)
      .then(
        (response) => {
          menu.scopeCatalogChildren = response;
          this.setState({
            menu,
          });
        },
      );
  };

  updateMenuForPromotionScopeGroupCatalogChildren = (level, splice, index) => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, level)
      .then(
        (response) => {
          menu.scopeCatalogGroupChildren.splice(index, splice, response);
          this.setState({
            menu,
          });
        },
      );
  };

  handleScopeDropDownChange = (value, index = 0, field = 'promotionCatalog') => {
    const { scope } = this.state;
    if (field === 'promotionCatalog') {
      scope[field][index].catalogInfo.catalogId = value;
      scope[field][index].catalogDetailIds = [];
      this.updateMenuForPromotionScopeCatalogChildren(value);
    } else if (field === 'relatedType') {
      scope[field] = value;
      scope.relatedId = '';
    } else if (field === 'promotionAreas') {
      scope[field][index] = value;
    } else if (field === 'channelCategoryInfo') {
      scope.channelCategoryInfo[index].channelId = value;
      scope.channelCategoryInfo[index].categoryIds = [];
      this.getRetailOutletCount(scope);
    } else if (field === 'customGroupDetails') {
      scope.catalogGroupId[index] = value;
      if (value === CATALOG_LEVEL.SKU_FAMILY && scope.customGroupDetails[index].skus) {
        delete scope.customGroupDetails[index].skus;
        scope.customGroupDetails[index].skuFamily = [];
        this.updateMenuForPromotionScopeGroupCatalogChildren(value, 1, index);
      } else if (value === SKU_CATALOG_LEVEL && scope.customGroupDetails[index].skuFamily) {
        delete scope.customGroupDetails[index].skuFamily;
        scope.customGroupDetails[index].skus = [];
        this.updateMenuForPromotionScopeGroupCatalogChildren(value, 1, index);
      } else {
        this.updateMenuForPromotionScopeGroupCatalogChildren(value, 0, index);
      }
    } else {
      scope[field] = value;
    }

    this.setState({ scope });
  };

  handleDisbursementDropDownChange = (value, parameterRef = []) => {
    const { disbursement, menu } = this.state;

    if (parameterRef[0] === 'catalogId') {
      disbursement.catalogId = value;
      disbursement.freeSkuId = [];
      disbursement.searchText = '';
      // this.setState({ disbursement });
      getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, value).then((response) => {
        menu.disbursementCatalogChildren = response;
        this.setState({
          menu,
          disbursement,
        });
      });
    } else {
      this.handleDropDownChange('disbursement')(value, parameterRef);
    }
  };

  handleImagesFetch = images => {
    const { banner } = this.state;
    this.setState({
      banner: {
        ...banner.images,
        images,
      },
    });
  };

  handleButtonSubmit = () => {
    // edit or create based upon the mode
    const { crudMode } = this.state;
    const updatedData = stateToRequestProcessor(this.state);
    const { formReference } = this;
    // call the request processor over here.
    this.setState({ displayErrorInForm: true }, () => {
      const validationOnInfo = refValidator(formReference.info);
      const validationOnDisbursement = refValidator(formReference.disbursement);
      const validationOnScope = refValidator(formReference.scope);
      const validationOnPrductCatalog = refValidator(formReference.productCatalog);
      if (validationOnInfo && validationOnDisbursement && validationOnScope && validationOnPrductCatalog) {
        this.onFormSubmit(crudMode, { ...updatedData });
      }
    });
  };

  handleButtonCancel = () => {
    const { crudMode } = this.state;
    if (crudMode === EVENT_OPERATION.UPDATE) {
      // TODO: store backup, restore garnu paryo
      const { info } = this.state;
      this.getData(info.promotionId);
    } else {
      const newState = getPromotionInitialState(null);
      this.setState(newState, () => {
        this.loadData();
        this.fetchTerritoryList();
      });
    }
  };

  handleScopeIconClick = (action, field, index = 0) => {
    const { scope } = this.state;

    if (action === 'remove') {
      scope[field].splice(index, 1);
    }

    if (action === 'add') {
      const baseObj = promotionScopeBase[field];
      scope[field].push({ ...baseObj });
    }

    this.setState({ scope });
  };

  handleCustomGroupAddCatalogClick = () => {
    const { scope } = this.state;
    const { displayAlert } = this.props;

    if (!scope.customGroupDetails) {
      scope.customGroupDetails = [clone(customGroupBase)];
    } else {
      const validationOnPrductCatalog = refValidator(this.formReference.productCatalog);
if(validationOnPrductCatalog){
      scope.customGroupDetails.push(clone(customGroupBase));
    }else{
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Value must not be 0');
    }}
    this.setState({ scope });
  };

  handleCustomGroupCrossClick = index => {
    const { scope } = this.state;

    scope.customGroupDetails.splice(index, 1);

    this.setState({ scope });
  };

  getHeader = () => {
    const { crudMode, info } = this.state;
    const header = crudMode === EVENT_OPERATION.UPDATE ? info.title : title;
    if (crudMode === EVENT_OPERATION.READ) return info.title;

    return (
      <>
        <span>{EVENT_OPERATION_MAPPER[crudMode].toLowerCase()}</span> {header}
      </>
    );
  };

  loadData = () => {
    const { menu, scope } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG)
      .then((response) => {
        const updatedMenu = [];
        response.forEach((cat) => {
          if (cat.id === 7 || cat.id === 8) {
            updatedMenu.push(cat);
          }
        });
        menu.catalogList = updatedMenu || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CHANNEL)
      .then((response) => {
        menu.channelList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY)
      .then((response) => {
        menu.categoryList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.TOWN)
      .then((response) => {
        menu.townList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.SUB_D)
      .then((response) => {
        menu.subDList = response || [];
        this.setState({ menu });
      });

    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, SKU_CATALOG_LEVEL)
      .then(
        (response) => {
          menu.disbursementCatalogChildren = response || [];
          this.setState({
            menu,
            scope,
          });
        },
      );

    // if catalog Scope is assigned, update scope catalog children.
    if (scope.promotionCatalog.length) {
      getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATALOG_CHILDREN, scope.promotionCatalog[0].catalogInfo.catalogId)
        .then((response) => {
          menu.scopeCatalogChildren = response || [];
          this.setState({ scope });
        });
    }
  };

  getRetailOutletCount = data => {
    const { scope } = this.state;
    const { getRetailOutletCount } = this.props;

    getRetailOutletCount({
      input: {
        distributorId: scope.promotionAreas.subDIdList,
        platform: data ? data.platform : scope.platform,
        channelCategory: data ? data.channelCategoryInfo
          : scope.channelCategoryInfo,
      },
    }, {
      handleSuccess: (res) => {
        scope.outletCount = res.data.retailOutletCount.count;
        this.setState(scope);
      },
    });
  }

  render() {
    const { disbursement, menu, criteria, scope, info, crudMode, displayErrorInForm, banner,territories } = this.state;

    const { serverResponseWaiting } = this.props;

    const updateMode = crudMode !== EVENT_OPERATION.READ;
    const headerTitle = this.getHeader();
    return (
      <PromotionDetailStyled>
        <div className="section-header">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{headerTitle}</h2>
              <div className="flex m-0">
                {
                    updateMode && (
                      <div>
                        <Button
                          small
                          secondary
                          disabled={serverResponseWaiting}
                          onClick={() => this.handleButtonCancel()}
                        >
                          <span>

                            Cancel
                          </span>
                        </Button>
                        <Button
                          small
                          primary
                          disabled={serverResponseWaiting}
                          onClick={() => this.handleButtonSubmit()}
                        >
                          <span>

                            Save
                          </span>
                        </Button>
                      </div>
                    )
                  }
                {
                    ((crudMode !== EVENT_OPERATION.CREATE
                       && !updateMode) && this.permission.update) && (
                       <Button
                         secondary
                         iconBtnSmall
                         disabled={updateMode}
                         onClick={() => {
                           this.setState({
                             crudMode: EVENT_OPERATION.UPDATE,
                           });
                         }}
                         className="ml-16"
                       >
                         <Icon iconName="pencil" />
                       </Button>
                    )
                  }
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <div className={!updateMode ? 'disabled' : null}>
            <PanelCard cardTitle="Promotion info">
              <PromotionInfo
                loading={serverResponseWaiting}
                info={info}
                refsObj={this.formReference.info}
                enableErrorDisplay={displayErrorInForm}
                onInputChange={this.handleInputChange('info')}
                onDateRangeChange={this.handleDateRangeChange}
              />
            </PanelCard>
            <PanelCard cardTitle=" Promotion Banner">
              <PromotionBanner
                handleImagesFetch={this.handleImagesFetch}
                banner={banner}
              />
            </PanelCard>
            <PanelCard cardTitle=" Scope of Promotion">
              <PromotionScope
                scope={scope}
                promotionId={info.promotionId}
                selectedCategoryIds={scope.categoryIds}
                refsObj={this.formReference.scope}
                channelList={menu.channelList}
                categoryList={menu.categoryList}
                sbdsList={menu.sbdList}
                focusedSKUsList={menu.focusedSKUList}
                townList={menu.townList || []}
                subDList={menu.subDList}
                onIconClick={this.handleScopeIconClick}
                enableErrorDisplay={displayErrorInForm}
                onDropDownChange={this.handleScopeDropDownChange}
                onAutoCompleteChange={this.handleAutoCompleteChange('scope')}
                onInputChange={this.handleInputChange('scope')}
                territories={territories}
              />
            </PanelCard>
            <PanelCard cardTitle="product catalog">
              <ProductCatalog
                onAddCatalogClick={this.handleCustomGroupAddCatalogClick}
                onCustomGroupCrossClick={this.handleCustomGroupCrossClick}
                scope={scope}
                customGroupStatus={scope.customGroupStatus}
                refsObj={this.formReference.productCatalog}
                channelList={menu.channelList}
                categoryList={menu.categoryList}
                catalogList={menu.catalogList}
                sbdsList={menu.sbdList}
                focusedSKUsList={menu.focusedSKUList}
                townList={menu.townList || []}
                subDList={menu.subDList}
                customGroupList={scope.customGroupDetails}
                crudMode={crudMode}
                onIconClick={this.handleScopeIconClick}
                enableErrorDisplay={displayErrorInForm}
                catalogChildrenList={menu.scopeCatalogChildren}
                catalogGroupChildrenList={menu.scopeCatalogGroupChildren}
                onInputChange={this.handleInputChange('scope')}
                onDropDownChange={this.handleScopeDropDownChange}
                onAutoCompleteChange={this.handleAutoCompleteChange('scope')}
              />
            </PanelCard>
            <PanelCard cardTitle="Condition">
              <PromotionCondition
                customGroupStatus={scope.customGroupStatus}
                criteria={criteria}
                enableErrorDisplay={displayErrorInForm}
                onInputChange={this.handleInputChange('criteria')}
                onDropDownChange={this.handleDropDownChange('criteria')}
              />
            </PanelCard>
            <PanelCard cardTitle="DISBURSEMENT">
              <PromotionDisbursement
                disbursement={disbursement}
                criteria={criteria}
                catalogList={menu.catalogList}
                refsObj={this.formReference.disbursement}
                enableErrorDisplay={displayErrorInForm}
                catalogChildrenList={menu.disbursementCatalogChildren}
                onInputChange={this.handleInputChange('disbursement')}
                onDropDownChange={this.handleDisbursementDropDownChange}
                onAutoCompleteChange={this.handleAutoCompleteChange('disbursement')}
              />
            </PanelCard>
          </div>
        </div>
      </PromotionDetailStyled>
    );
  }
}

PromotionDetail.propTypes = propTypes;

PromotionDetail.defaultProps = defaultProps;

export { promotionScopeBase };

export default withAlert()(PromotionDetail);
