import { compose } from 'recompose';
import UserProfile from './UserProfile';
import withRequestTracker from '../../utils/composition/withRequestTracker';
import {
  query, mutation, CHANGE_PASSWORD, UPDATE_USER_PROFILE
} from './API';

const ComposeUserProfile = compose(
  CHANGE_PASSWORD,
  UPDATE_USER_PROFILE,
  withRequestTracker({
    query,
    mutation,
  }),
)(UserProfile);

export default ComposeUserProfile;
