import styled from 'styled-components';

const RosterStyled = styled.div`
  .roster-user-id {
    display: inline-block;
    margin: 0 0 24px;
  }

  .roster-container {
    background: #fbfbfc;
    padding: 24px 48px;
    min-height: 100vh;

    @media print {
      padding: 0;
    }
  }

  .roster-top-search {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    .form-select-input {
      width: 190px;
      margin-bottom: 0;
      > div > div {
        font-size: 14px;
      }

      label:empty {
        display: none;
      }
    }
  }

  .roster-top-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .week-month-btn {
    button {
      border: 1px solid #cdced9;
      font-size: 14px;
      padding: 5px 12px;
      line-height: 21px;
      color: #6b6c7e;
      margin: 0;
      font-weight: 600;

      &:first-child {
        border-radius: 4px 0 0 4px;
        border-right: 0;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
        border-left: 0;
      }

      &.primary {
        color: #fff;
        border-color: #0056e0;
      }
    }
  }

  .roster-action {
    display: flex;
    align-items: center;

    button.primary {
      font-size: 14px;
      padding: 5px 13px;
      line-height: 21px;
      margin: 0;
      font-weight: 600;
      color: #fff;
    }

    button.outlined {
      padding: 3px 7px;
      color: #6b6c7e;

      .icon {
        opacity: 1;
      }

      &:hover,
      &:focus {
        .icon {
          color: #fff;
        }
      }
    }
  }

  .vertical-divider {
    margin: 0 16px;
    width: 2px;
    height: 16px;
    background: #e7e7ed;
  }

  .roster-legend-wrap {
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    margin: 20px 0;
    padding: 0 4px;

    ul {
      display: flex;
      align-items: center;
    }

    li {
      margin-right: 24px;
      &:last-child {
        margin-right: 0;
      }

      span {
        position: relative;
        display: flex;
        align-items: center;

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 4px;
        }

        &.daily {
          &:before {
            background: #5fc8ff;
          }
        }

        &.weekly {
          &:before {
            background: #ffb46e;
          }
        }

        &.fortnightly {
          &:before {
            background: #ff73c3;
          }
        }

        &.monthly {
          &:before {
            background: #9be169;
          }
        }

        &.once {
          &:before {
            background: #e7e7ed;
          }
        }

        &.leave-pending {
          &:before {
            background: #a7a9bc;
          }
        }

        &.on-leave {
          &:before {
            background: #ff5f5f;
          }
        }
      }
    }

    .inner {
      display: flex;
      justify-content: space-between;
    }
  }
  /* @page {
  size: A4;
} */

  @media print {
    .section-header {
      display: none;
    }

    .roster-legend-wrap {
      display: none;
    }

    .calendar-week-title {
      height: 90px;
    }

    .calendar-bottom-content {
      height: 100%;
    }

    .roster-route-view {
      width: auto;
    }

    .action-btn {
      position: relative;
      right: 3px;
    }

    .roster-top-filter {
      display: none;
    }

    .roster-container {
      background: #fff;
      border-top: 0;
    }
  }

  /* roster add popup card of assign route styling start */
  .roster-popup {
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;

    &-inner {
      min-width: 262px;
      background: #fff;
      padding: 16px 0;
      border-radius: 4px;
      box-shadow: 0 4px 12px 6px rgba(39, 40, 51, 0.12);
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px 11px;
      border-bottom: 1px solid #e7e7ed;

      h3 {
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        margin: 0;
      }

      .icon {
        color: #6b6c7e;
        width: 14px;
      }
    }

    .user-title {
      padding: 20px 16px 16px;
      border-bottom: 1px solid #e7e7ed;

      .sub-title {
        font-size: 10px;
        color: #a7a9bc;
        font-weight: 600;
        line-height: 12px;
        display: inline-block;
        margin-bottom: 4px;
      }

      h4 {
        margin: 0 0 16px;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        padding: 10px 16px;
        border-radius: 4px;
        border: 1px solid #e7e7ed;

        &:empty {
          display: none;
        }
      }

      .assigned-wrap {
        display: flex;
      }

      button {
        font-size: 10px;
        padding: 6px 10px;
      }
    }

    .inline-radio-label {
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      margin-right: 8px;
      display: flex;
      align-items: center;

      span {
        margin-left: 3px;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .route-frequency-wrap {
      padding: 24px 16px 16px;
      border-bottom: 1px solid #e7e7ed;
    }

    .route-frequency {
      display: flex;

      .route {
        width: 60%;
        padding-right: 8px;
      }

      .frequency {
        width: 40%;

        .btn {
          font-size: 12px;
          border: 1px solid #cccccc;
          padding: 10px 8px 10px 24px;
          border-radius: 4px;
          line-height: 15px;
          position: relative;

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 12px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: transparent;
            left: 8px;
          }

          &.status_1 {
            &:before {
              background: #5fc8ff;
            }
          }

          &.status_2 {
            &:before {
              background-color: #ffb46e;
            }
          }

          &.status_3 {
            &:before {
              background-color: #ff73c3;
            }
          }

          &.status_4 {
            &:before {
              background-color: #9be169;
            }
          }

          &.status_5 {
            &:before {
              background-color: #e7e7ed;
            }
          }
        }
      }

      .form-select-input {
        margin-bottom: 0;
        font-size: 12px;

        label:empty {
          display: none;
        }
      }

      .route,
      .frequency {
        > span {
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
          color: #a7a9bc;
          display: inline-block;
          margin-bottom: 4px;
        }
      }
    }

    .repeat-block {
      margin-top: 16px;
      display: flex;

      button {
        font-size: 10px;
        padding: 6px 10px;
      }
    }

    .edit-text {
      padding: 15px;
      font-size: 10px;
      line-height: 10px;
      color: #6b6c7e;
      border-bottom: 1px solid#E7E7ED;
      display: flex;
      align-items: baseline;

      .icon {
        width: 10px;
      }

      .ml-4 {
        margin-left: 4px;
      }
    }

    .assign-btn {
      padding: 16px 16px 0;

      button {
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        padding: 6px;
      }
    }
  }
  /* roster add popup card of assign route styling start */

  /* replicate call roster modal styling start */
  .modal-wrapper {
    &.roster {
      .header {
        padding: 20px 24px;
        border-bottom: 1px solid #e7e7ed;

        h2 {
          margin: 0;
          font-size: 20px;
          font-weight: bold;
          color: #272833;
        }
      }

      .body {
        padding: 24px;
        border-bottom: 1px solid #e7e7ed;

        h3 {
          margin: 0 0 24px;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }

        label {
          display: block;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 4px;

          + div {
            width: 100%;
            background: #f1f2f5;
            border: 1px solid #e7e7ed;
            border-radius: 4px;
            padding: 0 16px;

            > div {
              margin: 0;
              display: block;
            }
          }
        }

        .mb-24 {
          margin-bottom: 24px;
        }
      }

      .month-view {
        position: relative;

        .month-icon {
          position: absolute;
          right: 0;
          margin: 0;
        }

        .ic-dropdown {
          display: none;
        }
      }

      .modal-button {
        text-align: right;
        padding: 15px 24px;
      }

      .filter-dropdown-card {
        min-width: 347px;
        left: -16px;
        right: 0;
      }
    }
  }
  .zindex-2__control {
    svg.icon {
      height: 12px;
      width: 12px;
    }
    .zindex-2__placeholder,
    .zindex-2__single-value {
      font-weight: 400;
      font-size: 12px;
    }
  }
  .zindex-2__menu {
    font-size: 12px;
  }
  /* replicate call roster modal styling end */
`;

export default RosterStyled;
