import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import View from './View';
import LocationHierarchyForm from './Form';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { refGenerator } from '../../../utils/refGenerator';
import withAlert from '../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { getPermissionForLocationHierarchy } from '../../base/permission';
import LocationHierarchyStyled from './LocationHierarchyStyled';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { crudRequest as crudRequestConfig, breadCrumbConfig, formConfig, filterConfig, title } from './config';
import { MESSAGE_EVENT_OPERATION } from '../../../v4/constants/successMessage';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  createLocationHierarchy: PropTypes.func.isRequired,
  editLocationHierarchy: PropTypes.func.isRequired,
  getLocationHierarchyList: PropTypes.func.isRequired,
  deleteLocationHierarchies: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  levelTitle: PropTypes.string,
};

const defaultProps = {
  serverResponseWaiting: false,
  levelTitle: 'Location Hierarchy',
};

class LocationHierarchy extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
    };

    this.permission = getPermissionForLocationHierarchy();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createLocationHierarchy,
      [EVENT_OPERATION.UPDATE]: props.editLocationHierarchy,
      [EVENT_OPERATION.DELETE]: props.deleteLocationHierarchies,
    };
    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        this.setState(data);
        displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
        if (type === EVENT_OPERATION.DELETE || type === EVENT_OPERATION.BULK_DELETE) {
          this.loadTableData();
        }
      },
      this.getData,
      ['id', 'title', 'parentId'],
    );
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getLocationHierarchyList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getLocationHierarchyList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          sort: [{ sortBy: 'id', order: 'ASC' }],
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.getLocationHierarchy && response.data.getLocationHierarchy.rows) || [];
          data.total = (response.data.getLocationHierarchy && response.data.getLocationHierarchy.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`);
    this.loadTableData();
  };
  render() {
    const { data, queryParameters, display } = this.state;
    const { serverResponseWaiting, levelTitle, deleteLocationHierarchies } = this.props;
    const lastParentList = data.list;
    const lastParent = lastParentList[lastParentList.length - 1];
    return (
      <LocationHierarchyStyled>
        <DialogWrapper
          onDialogSubmit={this.onFormSubmit}
          formConfig={formConfig}
          refsObj={this.formReference}
          title={levelTitle}
          renderDialog={({
            onDialogSubmit,
            handleDialogInputChange,
            handleDialogDropDownChange,
            dialogData,
            enableErrorDisplay,
            type,
          }) => (
            <LocationHierarchyForm
              show
              data={dialogData}
              parent={data.list}
              lastParent={[lastParent]}
              type={type}
              refsObj={this.formReference}
              onFormSubmit={onDialogSubmit}
              loading={serverResponseWaiting}
              enableErrorDisplay={enableErrorDisplay}
              handleInputChange={handleDialogInputChange}
              handleDropDownChange={handleDialogDropDownChange}
            />
          )}
          render={({ onDialogItemClick }) => (
            <>
              <div className="section-header">
                <PanelStyled>
                  <PageHeader
                    display={display}
                    breadCrumb={breadCrumbConfig}
                    config={{
                      title,
                      create: this.permission.create,
                      download: false,
                      filter: false,
                      search: false,
                    }}
                    filter={{
                      date: queryParameters.date,
                      menuList: filterConfig.menu,
                      onFilterChange: this.basePaginationService.handleFilterChange,
                    }}
                    queryParameters={queryParameters}
                    controlDisplay={this.controlDisplay}
                    handleCreateClick={onDialogItemClick}
                    resetFilter={this.basePaginationService.resetFilter}
                    clearSearchText={this.basePaginationService.clearSearchText}
                    handleSearchChange={this.basePaginationService.handleSearchInputChange}
                    handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                    handleDownloadClick={this.basePaginationService.handleDownloadClick}
                  />
                </PanelStyled>
              </div>
              <div className="section-content table-present">
                <View
                  data={data}
                  permission={this.permission}
                  pagination={queryParameters.pagination}
                  onIconClick={onDialogItemClick}
                  loading={serverResponseWaiting}
                  onPageChange={this.basePaginationService.onPageSelect}
                  onBulkDelete={this.handleBulkDelete}
                  deleteLocationHierarchies={deleteLocationHierarchies}
                />
              </div>
            </>
          )}
        />
      </LocationHierarchyStyled>
    );
  }
}

LocationHierarchy.propTypes = propTypes;

LocationHierarchy.defaultProps = defaultProps;

export default withAlert()(LocationHierarchy);
