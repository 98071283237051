import { ApolloLink } from 'apollo-link';

const stripTypenames = (obj, propToDelete) => {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property;
      const newData = stripTypenames(obj[property], propToDelete);
      obj[property] = newData;
    } else {
      if (property === propToDelete) {
        delete obj[property];
      }
    }
  }
  return obj;
};

const removeTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripTypenames(operation.variables, '__typename');
    return forward ? forward(operation) : null;
  }
});

export default removeTypenameLink;
