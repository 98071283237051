import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const getSkuPurchaseDetailsApi = gql`
  query GetSkuPurchaseDetails($limit: Int, $offset: Int, $filter: FilterInput) {
    getSkuPurchaseDetails(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        distributor_id
        distributor_title
        order_quantity
        gap
        in_stock
        sku_id
        sku_title
        po_detail
      }
      count
    }
  }
`;

const getPurchaseRequestedListApi = gql`
  query PurchaseRequestedStatusList($limit: Int, $filter: FilterInput, $offset: Int) {
    purchaseRequestedStatusList(limit: $limit, filter: $filter, offset: $offset) {
      rows {
        sku
        sku_code
        quantity
        distributor_id
        distributor
        requested_date
        document_number
        message
      }
      count
    }
  }
`;

const getPurchaseOrderCountApi = gql`
  query Rows($limit: Int, $offset: Int, $filter: FilterInput) {
    purchaseRequestedListCount(limit: $limit, offset: $offset, filter: $filter) {
      pendingCount
      successFulcount
      unSuccessFulcount
    }
  }
`;

const createPurchaseOrder = graphql(
  gql`
    mutation CreatePurchaseOrder {
      createPurchaseOrder
    }
  `,
  {
    props: ({ mutate }) => ({
      createPurchaseOrder: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getSkuPurchaseDetailsApi,
  getPurchaseRequestedListApi,
  getPurchaseOrderCountApi,
};

const mutation = {
  createPurchaseOrder,
  downloadReport: DOWNLOAD_REPORT,
};

export { query, mutation, createPurchaseOrder };
