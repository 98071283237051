import React, { Component } from 'react';
import { breadCrumb, crudRequestConfig, dataMapper } from './config';
import SummaryDetails from './summaryDetails';
import PageHeader from '../../../base/PageHeader';
import { PanelStyled } from '../../../common/configuration';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { PanelCard, Button, Loading } from '../../../../v4/components';
import Table from './table/Table';
import { getUser } from '../../../../data/services';
import { USER_ROLE } from '../../../../data/enums';
import { clone } from '../../../../utils/arrayProcessor';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { getPermissionForSrn } from '../../../base/permission';
import withAlert from '../../../../utils/composition/withAlert';
import withLoading from '../../../../utils/composition/withLoading';
import { SRN } from '../../../../data/enums/Route';
import SRNStyled from '../SRNStyled';
import { handlePrint, imageSliderListFormatter } from '../../../common/HelperFunctions';
import { DOMAIN } from '../../../../data/enums/config';
import { customerMapper } from './summaryDetails/config';

class Details extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.subDUser =
      this.userInfo.roleId === USER_ROLE.DISTRIBUTOR_ADMIN || this.userInfo.roleId === USER_ROLE.BILLING_USER;
    this.state = {
      showDialog: false,
      list: dataMapper({}),
      printDataLoading: false,
      outletList: [],
      distributorList: [],
      enableErrorDisplay: false,
      lndEnabled: false,
      distributorServices: {
        billing: {
          status: false,
          url: '',
        },
      },
      distributor: {
        id: null,
        title: '',
        townList: [],
      },
      skus: [],
      customerList: [],
      newCustomerList: undefined,
    };
    this.getData = () => {
      const { list } = this.state;
      return list;
    };
    const serverCall = {
      [EVENT_OPERATION.APPROVE]: props.approveSalesReturn,
      [EVENT_OPERATION.CANCEL]: props.cancelSalesReturn,
    };
    this.onCRUDSuccess = this.responseProcessor(this.handleSrnSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.permission = getPermissionForSrn();
  }

  componentDidMount() {
    this.getDistributorsList();
    this.getList();
    this.getSKUs();
  }

  directToMainPage = () => {
    /** direct to Srn page */
    const { history } = this.props;
    history.push(`/${SRN}`);
  };

  handleSrnSuccess = (response, type) => {
    const { distributorServices, list } = this.state;
    const { displayAlert } = this.props;
    if (distributorServices.billing.status) {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      this.setState({
        printDataLoading: true,
      });
      handlePrint(
        {
          distributorServices,
          invoiceNumber: response.SRNInvoiceNumber,
          module: distributorServices?.billing?.versionCode === 2 ? DOMAIN.DBS_SALES_RETURN : DOMAIN.SALES_RETURN,
          newVersionCode: distributorServices?.billing.versionCode === 2 ? true : false,
          distributorId: list.Distributor.id,
          directPrint: true,
        },
        this.directToMainPage,
      );
      this.setState({
        printDataLoading: false,
      });
    } else {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus(
      {},
      {
        handleSuccess: response => {
          this.setState({ skus: response.data.skus.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setDistributorServices = distributor => {
    const { distributorServices, distributor: stateDistributor } = this.state;

    stateDistributor.townList = distributor.Towns.map(town => town.id);
    stateDistributor.id = distributor.id;
    stateDistributor.title = distributor.title;
    this.setState(
      {
        distributorServices: { ...distributor.servicesUsed, id: distributor.id } || distributorServices,
        distributor: stateDistributor,
        lndEnabled: distributor.servicesUsed.logistic.status,
      },
      () => {
        localStorage.setItem('isBillingUser', JSON.stringify(distributor.servicesUsed?.billing?.status ?? false));
        this.getOutletList();
      },
    );
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          const distributorList = response.data.distributors ? response.data.distributors.rows || [] : [];
          this.setState(
            {
              distributorList,
            },
            () => {
              if (this.subDUser && distributorList.length > 0) {
                this.setDistributorServices(distributorList[0]);
              }
            },
          );
        },
        // handleError: (error) => {
        //   displayAlert(ALERT_TYPE.DANGER, error);
        // },
      },
    );
  };

  getOutletList = () => {
    const { getOutlets, displayAlert } = this.props;

    const { distributor } = this.state;

    if (distributor.townList.length > 0) {
      const filter = {
        filters: [
          {
            column: 'town_id',
            value: distributor.townList.map(String),
          },
        ],
      };

      getOutlets(
        {
          filter,
        },
        {
          handleSuccess: response => {
            this.setState({ outletList: response.data.retailOutlets.rows });
          },
          handleError: error => {
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  getList = () => {
    const { getSrnDetails, displayAlert, match } = this.props;
    getSrnDetails(
      {
        srnId: parseInt(match.params.id || '', 10),
      },
      {
        handleSuccess: response => {
          const { srnDetails = {} } = response.data;
          const modifiedData = dataMapper(srnDetails);
          this.setState({ list: modifiedData });
          this.setReconciliationData();
          const distributorDataFromDetailId = this.state.distributorList?.find(
            item => item.id === modifiedData?.Distributor?.id,
          );
          this.setDistributorServices(distributorDataFromDetailId);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  cancelSrn = () => {
    const { list } = this.state;
    this.onFormSubmit(EVENT_OPERATION.CANCEL, list);
  };

  onSubmit = type => {
    if (type === EVENT_OPERATION.CANCEL) {
      this.cancelSrn();
    } else if (type === EVENT_OPERATION.APPROVE) {
      const valid = this.getValidationStatus();
      if (valid) {
        this.approveSrn();
      } else {
        this.setState({ enableErrorDisplay: true });
      }
    }
  };

  approveSrn = () => {
    const { list } = this.state;
    const updatedData = clone(list);
    const tableData = this.getTableDetails();
    updatedData.details = this.getDetails();
    updatedData.amount = tableData.totalAmount;
    updatedData.srnType = tableData.srnType;
    updatedData.returnOrders = tableData.returnOrders;
    updatedData.oldBillStatus = false;
    this.onFormSubmit(EVENT_OPERATION.APPROVE, updatedData);
  };

  getValidationStatus = () => {
    const detailsStatus = this.getDetailsValidationStatus();
    const tableStatus = this.getTableValidationStatus();

    return detailsStatus && tableStatus;
  };

  responseProcessor = callBack => type => response => {
    callBack(response, type);
  };

  setReconciliationData = () => {
    const { list } = this.state;
    list.orders.forEach(order => {
      if (order.verifiedQuantity < order.returnQuantity) {
        order.reconciliationColor = 'red';
      } else if (order.quantity === order.verifiedQuantity) {
        order.reconciliationColor = 'green';
      }
      if (order.damagedImages) {
        order.damagedImages = [...(imageSliderListFormatter(order.damagedImages) || [])];
      }
      if (order.expiredImages) {
        order.expiredImages = [...(imageSliderListFormatter(order.expiredImages) || [])];
      }
    });
    this.setState({ list });
  };

  toggleDialogAppearance = e => {
    const { showDialog } = this.state;
    e.preventDefault();
    this.setState({ showDialog: !showDialog });
  };

  handleDialogSubmit = ledger => {
    const { list, customerList } = this.state;
    const { displayAlert } = this.props;
    debugger;
    const formattedLedger = customerMapper(ledger) || {};
    if (formattedLedger.id) {
      list.Customer = formattedLedger;
      list.customerName = formattedLedger.name;
      customerList.push(formattedLedger);
      this.setState({ customerList, showDialog: false, list, newCustomerList: formattedLedger });
    } else {
      this.setState({ showDialog: false });
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Customer Id not available');
    }
  };

  fillCustomerListData = customerList => {
    this.setState({ customerList });
  };

  render() {
    const {
      outletList,
      distributorList,
      enableErrorDisplay,
      distributorServices,
      skus,
      list,
      showDialog,
      distributor,
      newCustomerList,
      lndEnabled,
    } = this.state;
    const { serverResponseWaiting, getSKUBatchDetail, validateSrnData } = this.props;
    return (
      <SRNStyled>
        <div className="section-header">
          <PanelStyled>
            <div className="prn-page-header">
              <PageHeader
                breadCrumb={breadCrumb}
                config={{
                  title: `SRN #${list.srnInvoiceNumber}`,
                }}
              />
              <div className="flex m-0">
                <div className={list?.inboundPending ? 'audit-blocked' : ''}>
                  <Button
                    secondary
                    small
                    disabled={list?.inboundPending || lndEnabled || !this.permission.create}
                    onClick={() => this.onSubmit(EVENT_OPERATION.CANCEL)}
                  >
                    <span> Reject</span>
                  </Button>
                </div>
                <div className={list?.inboundPending ? 'audit-blocked' : ''}>
                  <Button
                    primary
                    small
                    disabled={list?.inboundPending || !this.permission.create}
                    onClick={() => this.onSubmit(EVENT_OPERATION.APPROVE)}
                  >
                    <span> Approve</span>
                  </Button>
                </div>
              </div>
            </div>
          </PanelStyled>
        </div>
        <div className="section-content pad-48">
          <PanelCard cardTitle="details" className={this.permission.create ? '' : 'disabled'}>
            <SummaryDetails
              pending
              enableErrorDisplay={enableErrorDisplay}
              loading={serverResponseWaiting}
              outletList={outletList}
              distributorList={distributorList}
              updateDistributorServices={this.setDistributorServices}
              data={list}
              pendingcustomerList={list.Customer}
              subDUser={this.subDUser}
              distributorServices={distributorServices}
              getStatus={childMethod => (this.getDetailsValidationStatus = childMethod)}
              getDetails={childMethod => (this.getDetails = childMethod)}
              handleIconClick={this.toggleDialogAppearance}
              handleDialogSubmit={this.handleDialogSubmit}
              distributor={distributor}
              showDialog={showDialog}
              fillCustomerListData={this.fillCustomerListData}
              newCustomerList={newCustomerList}
            />
          </PanelCard>
          <PanelCard cardTitle="sku" skuClassStatus>
            <Table
              data={list}
              skuList={skus}
              enableErrorDisplay={enableErrorDisplay}
              getStatus={childMethod => (this.getTableValidationStatus = childMethod)}
              getDetails={childMethod => (this.getTableDetails = childMethod)}
              getSKUBatchDetail={getSKUBatchDetail}
              validateSrnData={validateSrnData}
              permission={this.permission}
            />
          </PanelCard>
        </div>
        {this.state.printDataLoading && <Loading title={'Print Data Loading... Please wait.'} />}
      </SRNStyled>
    );
  }
}

export default withLoading(withAlert()(Details));
