import { compose } from 'recompose';
import TASDashboard from './Dashboard';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { query, mutation } from './API';

const composedTASDashboard = compose(
  withRequestTracker({
    query,
    mutation,
  }),
)(TASDashboard);

export default composedTASDashboard;
