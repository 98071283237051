import gql from 'graphql-tag';

const GET_LINKED_DEVICE_TOKEN = gql`
  query GetLinkedDeviceToken {
    getLinkedDeviceToken {
      mobile_api_url_decrypt_key
      mobile_auth_token
    }
  }
`;

const query = {
  getLinkedDeviceToken: GET_LINKED_DEVICE_TOKEN,
};

const mutation = {};

export {};

export { query, mutation };
