import styled from 'styled-components';

const FocusedDetailsStyled = styled.div`
.sbd-bottom-wrap{
  .sbd-detail-table{
  .popover_td{
    color:#0b5fff;
  }
}
.info-icon {
  position: relative;
  cursor: pointer;
  &:after {
    content: 'Choose radio buttons “P” \
     for Primary and “S” for \
     Secondary';
    position: absolute;
    opacity: 0;
    pointer-events: none;
    top: 150%;
    right: -50%;
    -webkit-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background: #333;
    height: auto;
    width: 205px;
    padding: 8px 12px;
    border-radius: 4px;
    z-index: 99;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #fff;
    text-align: left;
  }
  &:hover {
    &:after {
      opacity: 1;
      pointer-events: auto;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }
  }
}
}
.permission-choose{
  .radio-list { 
    width: 90px;
    margin-bottom: 11px; 
    &:last-child {
      margin-right: 0; 
      width: 108px;
    }
  }
}
.info-pri-sec{
  margin-top: 24px;
  color: #6B6C7E;
}
`;
export default FocusedDetailsStyled;
