import React, { useEffect, useState } from 'react';
import DetailStyled from '../Common/CreateDetailStockCount.styled';
import { PanelHeader, PanelStyled } from '../../../common/configuration';
import { BreadCrumb, Button } from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { breadCrumb } from '../Common/config';
import { ALERT_TYPE } from '../../../../v4/constants/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import history from '../../../../utils/history';
import { STOCK_COUNT } from '../../../../data/enums/Route';
import { refGenerator, refValidator } from '../../../../utils/refGenerator';
import DetailView from './DetailView';
import BasicDetail from '../Common/BasicDetail';

const StockCountDetail = (props) => {
  const {
    serverResponseWaiting,
    updateStockCount,
    displayAlert,
    getStockSkuStatus,
    match,
    getSKUFamilyDetails,
    getProductGroupDetails,
    getBrandDetails,
    getLogisticUser,
    getStockCountDetails,
    getStockCountList,
  } = props;
  const [crudMode, setcrudMode] = useState(EVENT_OPERATION.READ);
  const [basicDetail, setbasicDetail] = useState({});
  const [assignedTo, setassignedTo] = useState([]);
  const [data, setdata] = useState({
    categoryId: '',
    productCategoryId: '',
    brandId: '',
    skuId: '',
  });
  const [brandList, setbrandList] = useState([]);
  const [inputRes, setinputRes] = useState([]);
  const [stockId, setstockId] = useState(parseInt(match.params.id, 10));
  const [alreadySelectedSku, setalreadySelectedSku] = useState([]);
  const [activeSku, setactiveSku] = useState([]);
  const [productCategoryList, setproductCategoryList] = useState([]);
  const [filteredBrandList, setfilteredBrandList] = useState([]);
  const [skuFamilyList, setskuFamilyList] = useState([]);
  const [enableValidation, setenableValidation] = useState(false);
  const [count, setcount] = useState({
    productCategories: [],
    brands: [],
  });
  const [searchText, setsearchText] = useState('');
  const [SKUFamilyList, setSKUFamilyList] = useState([]);
  const read = crudMode===EVENT_OPERATION.READ;

  const onIconClick = (type) => {
    setcrudMode(type);
  };

  const handleInputChange = (e, params) => {
    if (params === 'fortnightly') {
      let repeatDates = [];
      const todayDate = new Date();
      let [year, month, day] = [todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate()];
      day = parseInt(e.target.value);
      const lastDayOfMonth = new Date(year, month, 0);

      if (day + 14 > lastDayOfMonth) {
        repeatDates.push(day);
      } else {
        repeatDates.push(day);
        if (day <= 15) {
          repeatDates.push(day + 14);
        }
      }
      setbasicDetail({ ...basicDetail, [e.target.name]: repeatDates });
    } else {
      setbasicDetail({ ...basicDetail, [e.target.name]: e.target.value });
    }
  };

  const handleDropDownChange = (value, params) => {
    if (params === 'frequency_type') {
      basicDetail[params] = value;
      basicDetail.dayOfTheMonth = '';
      basicDetail.days = '';
    } else {
      basicDetail[params] = value;
    }
    setbasicDetail({ ...basicDetail });
  };

  const handleSearchInput = (text) => {
    const regex = new RegExp(text, 'i');
    const bySkus = SKUFamilyList.filter((i) => i.skus.filter((s) => s.title.search(regex) > -1).length > 0);
    const bySKUFamilies = SKUFamilyList.filter((p) => p.title.search(regex) > -1);
    const union = [...bySKUFamilies, ...bySkus];
    const result = union.filter((item, index) => union.indexOf(item) === index);
    setsearchText(text);
    setskuFamilyList(result);
  };
  const getStockStatus = () => {
    getStockSkuStatus(
      {
        stockId: stockId || null,
      },
      {
        handleSuccess: (response) => {
          const result =
            response?.data?.getStockSkuStatus?.rows?.filter((da)=>da.sku_status==='disable')?.map((re) => ({
              sku_id: re.sku_id,
              sku_family_id: re.sku_family_id,
            })) || [];
          setalreadySelectedSku(result);
          const res =
            response?.data?.getStockSkuStatus?.rows?.filter((da)=>da.sku_status==='active')?.map((re) => ({
              sku_id: re.sku_id,
              sku_family_id: re.sku_family_id,
            })) || [];
          setactiveSku(res);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const removeDuplicates=(arr)=> {
    const seen = new Set();
    return arr.filter((obj) => {
      const stringified = JSON.stringify(obj);
      if (!seen.has(stringified)) {
        seen.add(stringified);
        return true;
      }
      return false;
    });
  }

  const getSKUsCountDetail = (initial=false) => {
    const {  productCategoryId, brandId, } = data;

    getStockCountDetails({
      stockId: stockId || 0,
      buId: null,
      categoryId: parseInt(productCategoryId, 0) || 0,
      brandId: parseInt(brandId, 10) || 0,
    }, {
      handleSuccess: (response) => {
        const { getStockCountDetails } = response.data;
        const responseCount = getStockCountDetails?.rows[0];
        const categoryCount = responseCount?.Category;
        const brandCount =  responseCount?.Brand;

        const copyCount = structuredClone(count);

        for (const inp of inputRes) {
          if(inp.product_category_id === productCategoryId){
            const checkIsBrandPresent = brandCount.find((brand) => brand.id === inp.brand_id);
            if(!checkIsBrandPresent) {
              brandCount.push({ count:0,id:inp.brand_id });
            }
          }
        }

        brandCount?.map((a) => {
          const active  =  inputRes.filter((b) => {
            return (b.brand_id === a.id) && b.active
          })

          const inactive = inputRes.filter((b) => {
            return (b.brand_id === a.id) && !b.active
          })

          a.count = Number(a.count) + Number(active?.length||0) - Number(inactive?.length||0);
          a.productCategoryId = active?.length>0?active?.[0]?.product_category_id:productCategoryId;
        })
        const uniqueArray = removeDuplicates(brandCount);
        copyCount.brands = uniqueArray;

        if(initial){
          copyCount.productCategories = categoryCount;
        }

        setcount(copyCount);
      },
      handleError: (err) => {
        displayAlert(ALERT_TYPE.DANGER, err);
      },
    });
  };

  const getProductGroupList = () => {
    getProductGroupDetails(
      { parentCatalogDetailId: null },
      {
        handleSuccess: (response) => {
          const result = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          setproductCategoryList(result);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const getBrandList = () => {
    getBrandDetails(
      {},
      {
        handleSuccess: response => {
          const result = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          setbrandList(result);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const handleProductCategoryClick = groupId => {
    data.productCategoryId = groupId;
    data.brandId = '';
    const newBrandList = brandList.filter(brand => parseInt(brand.parentCatalogDetailId, 10) === groupId);
    setdata(data);
    setfilteredBrandList(newBrandList);
    getSKUsCountDetail();
  };

  const handleBrandClick = brandId => {
    data.brandId = brandId;
    getSKUFamilyList(brandId);
    setdata(data);
    setsearchText('');
    getSKUsCountDetail();
  };

  const getSKUFamilyList = id => {
    const { brandId, productCategoryId } = data;
    getSKUFamilyDetails(
      {
        parentCatalogDetailId: id,
      },
      {
        handleSuccess: response => {
          const skuList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          const filteredSkuList = skuList.filter(i => i.skus && i.skus.length > 0);
          const skuIdToAlreadyExistMap = {};
          const skuIdToActiveMap = {};
          const skuIdToInputResMap = {};

          alreadySelectedSku.forEach(aItem => {
            skuIdToAlreadyExistMap[aItem.sku_id] = true;
          });
          activeSku.forEach(cItem => {
            skuIdToActiveMap[cItem.sku_id] = true;
          });
          inputRes?.forEach(cItem => {
            skuIdToInputResMap[cItem.sku_id] = cItem.active;
          });
          filteredSkuList.forEach(bItem => {
            if (bItem.skus && Array.isArray(bItem.skus)) {
              bItem.skus.forEach(skuItem => {
                skuItem.alreadyExist = skuIdToAlreadyExistMap[skuItem.id] || false;
                skuItem.status =
                  skuIdToInputResMap[skuItem.id] != null
                    ? skuIdToInputResMap[skuItem.id]
                    : skuIdToActiveMap[skuItem.id] || false;
                skuItem.editActive = skuIdToActiveMap[skuItem.id] || false;
                skuItem.brandId = brandId;
                skuItem.productCategoryId = productCategoryId;
              });
            }
          });
          setskuFamilyList(filteredSkuList);
          setSKUFamilyList(filteredSkuList);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const getStockCount = (uniqueObjects, remainingObj) => {
    const skus = uniqueObjects
      ?.map(item => item.skus)
      ?.flat()
      ?.filter(a => a.status);

    const newCount = structuredClone(count);
    const matchingBrand = newCount.brands.find(brand => brand.id === remainingObj.brandId);

    // If a matching brand is found, update the count; otherwise, add a new object
    if (matchingBrand) {
      matchingBrand.count = skus?.length;
      matchingBrand.productCategoryId = remainingObj.productCategoryId;
    } else {
      newCount.brands.push({
        id: remainingObj.brandId,
        count: skus?.length,
        productCategoryId: remainingObj.productCategoryId,
      });
    }
    newCount.brands.forEach(brand => {
      const matchingProductCategory = newCount.productCategories.find(pc => pc.id === brand.productCategoryId);
      if (brand.productCategoryId === remainingObj.productCategoryId) {
        if (matchingProductCategory) {
          matchingProductCategory.count = brand.count;
        } else {
          newCount.productCategories.push({
            id: brand.productCategoryId,
            count: brand.count,
          });
        }
      }
    });

    const commonProductId = [];
    for (const product of newCount.productCategories) {
      for (const brand of newCount.brands) {
        if (brand.productCategoryId === product.id) {
          commonProductId.push({ count: brand.count, id: product.id });
        }
      }

      let sum = commonProductId?.reduce((accumulator, currentItem) => {
        const existingItem = accumulator.find(item => item.productCategoryId === currentItem.productCategoryId);

        if (existingItem) {
          existingItem.count += currentItem.count;
        } else {
          accumulator.push({ ...currentItem });
        }

        return accumulator;
      }, []);

      product.count = sum?.some(item => item.id === product.id) ? sum[0].count : product.count;
    }

    setcount(newCount);
  };

  const handleSKUFamilySwitchChange = (toggleStatus, ids, params, remainingObj) => {
    for (let i = 0; i < ids.length; i++) {
      const submittingData = {
        active: toggleStatus,
        brand_id: remainingObj.brandId,
        principal_id: 1,
        product_category_id: remainingObj.productCategoryId,
        sku_family_id: remainingObj.skuFamilyId,
        sku_id: ids[i],
      };
      if (remainingObj?.editActive?.[i] === true) {
        if (toggleStatus) {
          const filteredInput = inputRes.filter(item => !ids.includes(item.sku_id));
          const uniqueInputRes = removeDuplicates(filteredInput);

          setinputRes(uniqueInputRes);
        } else {
          inputRes.push(submittingData);
          setinputRes(inputRes);
        }
      } else {
        if (toggleStatus) {
          inputRes.push(submittingData);
          setinputRes(inputRes);
        } else {
          const filteredInput = inputRes.filter(item => {
            return !ids.includes(item.sku_id) || !item.active;
          });
          const uniqueInputRes = removeDuplicates(filteredInput);

          setinputRes(uniqueInputRes);
        }
      }
    }
    const indexOfSKUFam = SKUFamilyList?.findIndex(a => a.id === remainingObj.skuFamilyId);

    SKUFamilyList[indexOfSKUFam].skus = SKUFamilyList[indexOfSKUFam].skus?.map(a => ({ ...a, status: toggleStatus }));
    if (searchText !== '') {
      const copySKUList = structuredClone(skuFamilyList);
      copySKUList[params[1]].skus = SKUFamilyList[indexOfSKUFam].skus?.map(a => ({ ...a, status: toggleStatus }));
      setskuFamilyList(copySKUList);
    }
    setSKUFamilyList(SKUFamilyList);
    getStockCount(SKUFamilyList, remainingObj);
  };

  const handleSwitchChange = (toggleStatus, ids, params, remainingObj) => {
    for (let i = 0; i < ids.length; i++) {
      const submittingData = {
        active: toggleStatus,
        brand_id: remainingObj.brandId,
        principal_id: 1,
        product_category_id: remainingObj.productCategoryId,
        sku_family_id: remainingObj.skuFamilyId,
        sku_id: ids[i],
      };
      if (remainingObj?.editActive) {
        if (toggleStatus) {
          const filteredInput = inputRes.filter(x => x.sku_id !== submittingData.sku_id);
          setinputRes(filteredInput);
        } else {
          inputRes.push(submittingData);
        }
      } else {
        if (toggleStatus) {
          inputRes.push(submittingData);
        } else {
          const filteredInput = inputRes.filter(x => x.sku_id !== submittingData.sku_id);
          setinputRes(filteredInput);
        }
      }
    }
    const indexOfSKUFam = SKUFamilyList?.findIndex(a => a.id === remainingObj.skuFamilyId);
    SKUFamilyList[indexOfSKUFam].skus[params[2]].status = toggleStatus;
    if (searchText !== '') {
      const copySKUList = structuredClone(skuFamilyList);
      copySKUList[params[1]].skus[params[2]].status = toggleStatus;
      setskuFamilyList(copySKUList);
    }
    setSKUFamilyList(SKUFamilyList);
    getStockCount(SKUFamilyList, remainingObj);
  };

  const getAssignedUser = () => {
    getLogisticUser(
      {},
      {
        handleSuccess: response => {
          const result = response?.data?.getLogisticUser || [];
          setassignedTo(result);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  const validation = refGenerator(['title', 'frequency_type', 'assignedTo']);

  const handleSaveClick = () => {
    const inpResponse = count.productCategories?.filter((a)=>a.count>0)
    const formValidation = refValidator(validation);
    if (!formValidation) {
      setenableValidation(true);
    } else {
      if (inpResponse?.length > 0) {
        let dayOfMonth = null;

        if (basicDetail?.frequency_type === 'MONTHLY') {
          dayOfMonth = basicDetail?.dayOfTheMonth !== '' ? [Number(basicDetail?.dayOfTheMonth)] : null;
        } else if (basicDetail?.frequency_type === 'FORTNIGHTLY') {
          dayOfMonth = basicDetail?.dayOfTheMonth !== '' ? basicDetail.dayOfTheMonth : null;
        }

        const inputData = {
          id: stockId,
          assigned_to: basicDetail?.assigned?.name,
          assigned_to_code: basicDetail?.assigned?.id,
          frequency_details: {
            dayOfMonth: dayOfMonth,
            dayOfWeek:
              basicDetail?.frequency_type === 'DAILY'
                ? WEEK_DAYS_TITLE?.find(a => a.id === todaysDay)?.title
                : basicDetail?.days !== ''
                ? [basicDetail?.days]
                : [] || [],
          },
          frequency_type: basicDetail?.frequency_type,
          title: basicDetail.title,
          stock_Count_detail: inputRes?.filter((a)=>a.active===true),
          delete_stock_count_detail: inputRes?.filter((a)=>a.active===false)?.map((b)=>b.sku_id)
        };

        updateStockCount(
          {
            input: inputData,
          },
          {
            handleSuccess: (response) => {
              if (response) {
                displayAlert(ALERT_TYPE.SUCCESS, 'Stock Count updated successfully!');
                setTimeout(() => {
                  history.push(`/${STOCK_COUNT}`);
                }, 1200);
              }
            },
            handleError: (error) => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      } else{
        displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'SKU Family / SKUS is not selected.');
      }
    }
  };
  const handleCancelClick = () => {
    setcrudMode(EVENT_OPERATION.READ);
    getSKUsCountDetail(true);
    getListDetail();
    setdata({
      categoryId: '',
      productCategoryId: '',
      brandId: '',
      skuId: '',
    })
  };

  const getListDetail=()=>{
    getStockCountList(
      {
        offset:0,
        limit: 100,
        filter:{},
        stockId,
      },
      {
        handleSuccess: (response) => {
          const stockCountDetailData = (response.data.getStockCountList && response.data.getStockCountList.rows?.[0]||{}) || {};
          const listData={
            title:stockCountDetailData.title,
            frequency_type:stockCountDetailData.frequency_type,
            days:stockCountDetailData.frequency_details?.dayOfWeek?.[0]||null,
            dayOfTheMonth: stockCountDetailData.frequency_details?.dayOfMonth || null,
            assigned:{
              id:stockCountDetailData.assigned_to_code,
              name:stockCountDetailData.assigned_to,
            }
          }
          setbasicDetail(listData);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  }

  useEffect(() => {
    getProductGroupList();
    getBrandList();
    getStockStatus();
    getAssignedUser();
    getListDetail();
    getSKUsCountDetail(true);
  }, []);

  return (
    <DetailStyled>
      <div className='section-header'>
        <PanelStyled>
          <BreadCrumb list={breadCrumb} />
          <PanelHeader>
            <div className='flex items-center justify-between m-0 flex-1'>
              <h2>{read ? basicDetail.title:`Edit ${basicDetail.title}` || ''}</h2>
              {crudMode === EVENT_OPERATION.READ ? (
                <div className="flex m-0">
                  <Button
                    secondary
                    iconBtnSmall
                    onClick={() => onIconClick(EVENT_OPERATION.UPDATE)}
                    iconName="pencil"
                  />
                  {/* <Button secondary iconBtnSmall onClick={() => onIconClick(EVENT_OPERATION.DELETE)} iconName='trash' /> */}
                </div>
              ) : (
                (crudMode === EVENT_OPERATION.CREATE || crudMode === EVENT_OPERATION.UPDATE) && (
                  <div>
                    <Button small disabled={serverResponseWaiting} secondary onClick={() => handleCancelClick()}>
                      <span>Cancel</span>
                    </Button>
                    <Button primary disabled={serverResponseWaiting} small onClick={() => handleSaveClick()}>
                      <span>Save</span>
                    </Button>
                  </div>
                )
              )}
            </div>
          </PanelHeader>
        </PanelStyled>
      </div>
      <div className='section-content'>
        <div className='create-ui'>
          <BasicDetail
            basicDetail={basicDetail}
            assignedTo={assignedTo}
            handleInputChange={handleInputChange}
            handleDropDownChange={handleDropDownChange}
            refsObj={validation}
            enableErrorDisplay={enableValidation}
            read={read}
            stockId={stockId}
          />
        </div>
        <DetailView
          loading={serverResponseWaiting}
          brandList={filteredBrandList}
          data={data}
          productCategoryList={productCategoryList}
          onProductCategoryClick={handleProductCategoryClick}
          onBrandClick={handleBrandClick}
          SKUFamilyList={skuFamilyList}
          count={count}
          onToggle={handleSwitchChange}
          onSKUFamilyToggle={handleSKUFamilySwitchChange}
          onHandleSearchInput={handleSearchInput}
          searchText={searchText}
          alreadySelectedSku={alreadySelectedSku}
          read={read}
        />
      </div>
    </DetailStyled>
  );
};

export default withAlert()(StockCountDetail);
