/* global google */
class Marker {
  generateMarker(
    position,
    id,
    name,
    icon,
    label,
    distance,
    text,
    details,
    lastCallDate,
    callType,
    reason,
    distributorId,
    routeId,
  ) {
    return new google.maps.Marker({
      position,
      id,
      name,
      icon,
      label,
      distance,
      text,
      lastTransactionDate: details,
      map: window.globalMap,
      lastCallDate,
      callType,
      reason,
      distributorId,
      routeId,
    });
  }
}

const marker = new Marker();
export default marker;
