import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../v4/components';
import { status } from '../config';

const labelMappings = {
  name: 'name',
  status: 'status',
  logTime: 'logTime',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.name,
    title: 'Name',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.status,
    title: 'Status',
    show: true,
    classNames: 'text-center',
  },
  {
    id: 3,
    label: labelMappings.logTime,
    title: 'Log Time',
    show: true,
  },
];
const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.name] = <td key={`${data.id}-name`}>{data.name}</td>;
  table[labelMappings.status] = (
    <td key={`${data.id}-status`} className='text-center'>
      <span className={`status-label ${parseInt(data.status, 10) === 1 ? 'active' : 'passive'}`}>
        {status[data.status]}
      </span>
    </td>
  );
  table[labelMappings.logTime] = <td key={`${data.id}-logTime`}>{data.loginTime}</td>;
  return table;
};
const TableBody = ({ data }) => {
  const tableBody = returnTableBody(data);

  return (
    <tr key={`asm-report-${data.id}`}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
};

export { TableHeader, TableBody };
