import React from 'react';
import DseListViewStyled from './DseListViewStyled';
import { headerLabelConfig } from '../../../../../common/HelperFunctions';
import { USER_ROLE_TITLE } from '../../../../../../data/enums/UserRole';

const DseListView = ({ onDseCardClick, data, selectedDseId }) => (
  <>
    <DseListViewStyled>
      <div className='dse-li-wrap'>
        <p className='dse-li-view-title'>{headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'}</p>
        {data.map((dse, i) => (
          <div
            key={i}
            className={`tr-card ${selectedDseId ? (selectedDseId === dse.id ? 'highlight' : 'blur') : ' tr-card'}`}
            style={{ borderLeft: `4px solid ${dse.active ? dse.color : '#CDCED9'}`, cursor: 'pointer' }}
            onClick={() => onDseCardClick(dse.id)}
          >
            <p>
              <img src={dse.profileImage ? dse.profileImage[0].image : '/image/icons/user.svg'} alt='user-image' />
            </p>
            <span>{dse.name}</span>
          </div>
        ))}
      </div>
    </DseListViewStyled>
  </>
);

export default DseListView;
