import { CALLAGE, SALES } from '../../data/enums/Dashboard';

const salesData = [
  {
    label: SALES.TARGET,
    value: 1122421355,
  },
  {
    label: SALES.ACHIEVEMENT,
    value: 99335213,
  },
];

const callageData = [
  {
    label: CALLAGE.successful,
    value: 176,
  },
  {
    label: CALLAGE.unsuccessful,
    value: 83,
  },
  {
    label: CALLAGE.remaining,
    value: 24,
  },
  {
    label: CALLAGE.offroute,
    value: 7,
  },
];

const asm = {
  active: 2,
  inactive: 1,
  leave: 0,
};
const stl = {
  active: 2,
  inactive: 1,
  leave: 0,
};
const dse = {
  active: 2,
  inactive: 1,
  leave: 0,
};
/* const userStatusList = [{
  title: 'asm',
  status: {
    active: 2,
    inactive: 1,
    leave: 0,
  },
}, {
  title: 'stl',
  status: {
    active: 2,
    inactive: 1,
    leave: 0,
  },
}, {
  title: 'dse',
  status: {
    active: 2,
    inactive: 1,
    leave: 0,
  },
}]; */
const userStatusList = { asm, stl, dse };

const monthlyDetail = {
  target: 200,
  achievement: 20,
  coverage: 2330,
  active: 20,
  sbd: 20,
};

const dseDetail = {
  rows: [
    {
      name: 'Ram Bahadur',
      route: 'Patan I',
      scheduled: 25,
      successful: 22,
      unsuccessful: 2,
      remaining: 1,
      productivity: 88,
      goldenpoint: 3,
      target: 12312312.33,
      received: 12312321,
      outlets: [
        {
          id: 1,
          title: 'Patan Store',
          color: 'red',
          location: {
            latitude: 27.7173,
            longitude: 85.325,
          },
        },
      ],
    },
    {
      name: 'Shyam Bahadur',
      route: 'New Road I',
      scheduled: 25,
      successful: 22,
      unsuccessful: 2,
      remaining: 1,
      productivity: 88,
      goldenpoint: 3,
      target: 12312312.33,
      received: 12312321,
      outlets: [
        {
          id: 2,
          title: 'NewRoad Store',
          color: 'green',
          location: {
            latitude: 27.7172,
            longitude: 85.324,
          },
        },
      ],
    },
  ],
  total: {
    scheduled: 30,
    successful: 22,
    unsuccessful: 5,
    remaining: 3,
    productivity: 67,
    target: 124234.23,
    received: 234224.11,
    goldenpoint: 6,
  },
};

const inventoryDetail = {
  cfr: 50,
  availability: 50,
  sufficiency: 50,
  stock: 5000,
};

const mapStyles = [
  {
    featureType: 'all',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36,
      },
      {
        color: '#333333',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe',
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede',
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#979696',
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#ff0000',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#979696',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [
      {
        visibility: 'on',
      },
      {
        color: '#d3d3d3',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2',
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e9e9e9',
      },
      {
        lightness: 17,
      },
    ],
  },
];

export {
  salesData, callageData, userStatusList, monthlyDetail, dseDetail, inventoryDetail, mapStyles
};
