import styled from 'styled-components';

const RoleStyled = styled.div`
  .section-content{
    .table-wrap{
      tbody{
        tr{
          cursor:pointer;
        }
      }
    }
  }
`;
export default RoleStyled;
