import { useState, useEffect } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Button, Menu } from '../index';
import { dateConfiguration, formatter, getCurrentDay, getStartOfCurrentMonth, normalPresentor, simplePresentor } from '../../../utils/date';
import DatePickerStyled from './DatePickerStyled';
import DateStringPicker from './DateStringPicker';
import DateToRender from './DateToRender';

interface DatePickerProps {
  date: {
    start: string;
    end: string;
  };

  onChange: (label: string, date: { start: string; end: string }) => void;
}

interface Range {
  startDate?: Date;
  endDate?: Date;
}

const DatePicker = (props: DatePickerProps) => {
  const { date: dateProps, onChange } = props;

  const [date, setDate] = useState({
    start:dateProps.start || getStartOfCurrentMonth(),
    end: dateProps.end || getCurrentDay(),
  });

  

  const [stringDate, setStringDate] = useState({
    title: '',
    show: false,
  });

  const [actualDate, setActualDate] = useState(false);

  const onDateStringClick = (value: { start: string; end: string }, title: string) => {
    const dateReplica = { ...value };
    date.start = dateReplica.start;
    date.end = dateReplica.end;

    setDate(date);
    setStringDate({ title, show: true });
    setActualDate(false);

    onDateRangeChange();
  };

  const setDateTitle = (date: { start: string, end: string }) => {
    const start = simplePresentor(date.start);
    const end = simplePresentor(date.end);
    const checkDate = dateConfiguration.find(d => start === d.date.start && end === d.date.end);
    const title = (checkDate && checkDate.title) || '';
    setDate(date);
    setStringDate({ title, show: true });
    setActualDate(!title);
  };

  const onDateRangeChange = () => {
    onChange('date', date);
  };

  const handleSelect = (selectedDate: Range | Date, label: 'start' | 'end') => {
    const formattedDate = { ...date };
    formattedDate[label] = normalPresentor(selectedDate);
    date.start = formattedDate.start;
    date.end = formattedDate.end;

    setDate(date);
    setActualDate(true);
    setStringDate({ show: false, title: '' });
  };

  const header = (
    <div className="datepicker-content">
      {actualDate && (
        <span className="date-actual">
          <DateToRender date={date} />
        </span>
      )}
      {stringDate.show && <>{stringDate.title && <span className="title">{stringDate.title}</span>}</>}
      <span className="psr-icons date">Date</span>
    </div>
  );

  const startDate = (
    <div className="date-input">
      <span className="text">From</span>
      <div className="moment">
        {<span className="date-from">{normalPresentor(date.start)}</span>}
        <span className="ic-date-icon" />
      </div>
    </div>
  );

  const endDate = (
    <div className="date-input">
      <span className="text">To</span>
      <div className="moment">
        {<span className="date-to">{normalPresentor(date.end)}</span>}
        <span className="ic-date-icon" />
      </div>
    </div>
  );

  useEffect(() => {
    setDateTitle(dateProps);
  }, [dateProps, date]);


  return (
    <DatePickerStyled>
      <div className="datepicker-wrapper">
        <Menu header={header}>
          <div className="datepicker-block">
            <DateStringPicker onClick={onDateStringClick} date={date} />
            <div className="date-input-wrap">
              <span>
                <Menu header={startDate} hideOnClick>
                  <Calendar
                    showMonthAndYearPickers
                    date={formatter(date.start).toDate()}
                    maxDate={formatter(date.end).toDate()}
                    onChange={selectedDate => handleSelect(selectedDate, 'start')}
                  />
                </Menu>
              </span>
              <span>
                <Menu header={endDate}>
                  <Calendar
                    showMonthAndYearPickers
                    date={formatter(date.end).toDate()}
                    minDate={formatter(date.start).toDate()}
                    onChange={selectedDate => handleSelect(selectedDate, 'end')}
                  />
                </Menu>
              </span>
              <span>
                <Button primary small onClick={() => onDateRangeChange()}>
                  Apply
                </Button>
              </span>
            </div>
          </div>
        </Menu>
      </div>
    </DatePickerStyled>
  );
};

export default DatePicker;

