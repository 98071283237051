import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const GET_USER_PROFILE = gql`
  query getUser($limit: Int, $offset: Int, $filter: FilterInput) {
    users(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        email
        fullName
        phoneNumber
        Role {
          name
          id
        }
        billingCredentials{
          accessKey
          secretKey
        }
        Distributor {
          title
        }
        profileImage {
          image
          thumbnail
          created_at
        }  
      }
      count
    }
  }
`;

const UPDATE_USER_PROFILE = graphql(
  gql`
    mutation ($id: Int!, $input: UserUpdateInput!) {
      updateUser(id: $id, input: $input) {
        id
        firstName
        lastName
        phoneNumber
        roleId
        profileImage {
          image
          thumbnail
          created_at
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateUser: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CHANGE_PASSWORD = graphql(
  gql`
    mutation ($userId: Int!, $oldPassword: String!, $newPassword: String!) {
      changePassword(userId: $userId, oldPassword: $oldPassword, newPassword: $newPassword)
    }
  `,
  {
    props: ({ mutate }) => ({
      changePassword: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getUserProfile: GET_USER_PROFILE,
};

const mutation = {
  changePassword: CHANGE_PASSWORD,
  updateUser: UPDATE_USER_PROFILE,
};

export {
  query, mutation, CHANGE_PASSWORD, UPDATE_USER_PROFILE
};
