import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../v4/components';
import { ImageColumn } from '../../../utils/tableUtils';
import { getUpdatedLabel, headerLabelConfig } from '../../common/HelperFunctions';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';

const labelMappings = {
  date: 'date',
  device: 'device',
  outlet: 'outlet',
  image: 'image',
  category: 'category',
  dse: 'dse',
  stl: 'stl',
  asm: 'asm',
  distributor: 'distributor',
  town: 'town',
  territory: 'territory',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.date,
    title: 'Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.device,
    title: 'Device',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.images,
    title: 'Images',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.category,
    title: 'Category',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: true,
    isDynamic: true,
  },
  {
    id: 7,
    label: labelMappings.stl,
    title: headerLabelConfig(USER_ROLE_TITLE.STL) || 'STL',
    show: true,
    isDynamic: true,
  },
  {
    id: 8,
    label: labelMappings.asm,
    title: headerLabelConfig(USER_ROLE_TITLE.ASM) || 'ASM',
    show: true,
  },
  {
    id: 9,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.town,
    title: 'Town',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.territory,
    title: 'Territory',
    show: true,
  },
];

const TableHeader = (dynamicHeader = false) => (
  <thead className=''>
    <tr>
      {(dynamicHeader ? getUpdatedLabel(labelConfig) : labelConfig).map((label) =>
        label.show ? tableData(label) : null,
      )}
    </tr>
  </thead>
);

const returnTableBody = (data, handleSliderOpen) => {
  const table = {};

  table[labelMappings.date] = <td key={`${data.id}-date`}>{data.date}</td>;
  table[labelMappings.device] = <td key={`${data.id}-device`}>{data.device}</td>;
  table[labelMappings.outlet] = <td key={`${data.id}-outlet`}>{data.outlet}</td>;
  table[labelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-images-${data.id}`}
      imageList={data.merchandise_images || []}
      onImageClick={handleSliderOpen}
      key={`${data.id}-image`}
    />
  );
  table[labelMappings.category] = <td key={`${data.id}-category`}>{data.category}</td>;
  table[labelMappings.dse] = <td key={`${data.id}-dse`}>{data.dse}</td>;
  table[labelMappings.stl] = <td key={`${data.id}-stl`}>{data.stl}</td>;
  table[labelMappings.asm] = <td key={`${data.id}-asm`}>{data.asm}</td>;
  table[labelMappings.distributor] = <td key={`${data.id}-distributor`}>{data.distributor}</td>;
  table[labelMappings.town] = <td key={`${data.id}-town`}>{data.town}</td>;
  table[labelMappings.territory] = <td key={`${data.id}-territory`}>{data.territory}</td>;

  return table;
};

const TableBody = ({ data, index, handleSliderOpen }) => {
  const tableBody = returnTableBody(data, handleSliderOpen);

  return (
    <tr key={`merchandise-report-${index}`}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  index: PropTypes.number,
  handleSliderOpen: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  index: null,
};

export { TableHeader, TableBody };
