import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableHeader } from '../tableConfig';
import withImageSlider from '../../../../../utils/composition/withImageSlider';
import SplitPayment from '../../../../components/SplitPayment';
import { Pagination } from '../../../../../v4/components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import ReportTableStyled from '../../../../reports/ReportTableStyled';
import { dataProps, paginationProps } from '../../../../../data/enums/Component';

const propTypes = {
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  reconcile: dataProps.value,
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
  handleSliderOpen: PropTypes.func.isRequired,
};

const defaultProps = {
  reconcile: dataProps.defaultValue,
  onPageChange: () => null,
  pagination: paginationProps.defaultValue,
};

class Reconciled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
      },
    };
  }

  handleIconClick = (type, element = {}) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  render() {
    const { dialog } = this.state;
    const { type } = dialog;
    const { tableConfig, reconcile, pagination, onPageChange, handleSliderOpen, permission } = this.props;
    return (
      <Fragment>
        <ReportTableStyled>
          {type && <SplitPayment data={dialog.element} type={type} resetDialog={this.resetDialog} />}
          <div className="table-wrap">
            <table>
              {TableHeader({
                tableConfig,
              })}

              <tbody>
                {reconcile.list.map(reconciled =>
                  TableBody({
                    data: reconciled,
                    onIconClick: this.handleIconClick,
                    tableConfig,
                    handleSliderOpen,
                    section: 'reconciled',
                    permission,
                  }),
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={pagination.page}
            limit={pagination.limit}
            totalItems={reconcile.total}
            onPageChange={onPageChange}
          />
        </ReportTableStyled>
      </Fragment>
    );
  }
}

Reconciled.propTypes = propTypes;

Reconciled.defaultProps = defaultProps;

const ReconciledWithErrorAndLoading = withErrorAndLoadingScreen(Reconciled);

const ReconciledWithImageSlider = withImageSlider(ReconciledWithErrorAndLoading);

export default ReconciledWithImageSlider;
