import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { has } from '../../../../utils/objectPrototypes';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { getPermissionForRouteSegmentation } from '../../../base/permission';
import { ROUTE_SEGMENTATION } from '../../../../data/enums/Route';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import View from './View';
import { breadCrumbConfig } from './config';
import { refGenerator } from '../../../../utils';
import { refValidator } from '../../../../utils/refGenerator';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { debouncer } from '../../../../utils/formHandlers';
import { LINE_TYPE } from '../../../../data/enums/Status';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getRouteSegmentationDetails: PropTypes.func.isRequired,
  createLine: PropTypes.func.isRequired,
  updateLine: PropTypes.func.isRequired,
  getRouteSegmentation: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Detail extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const { match } = props;
    this.id = has.call(match.params, 'id') && match.params.id !== 'create' ? parseInt(match.params.id, 10) : 0;
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      title: '',
      id: this.id,
      crudMode: this.id ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      brands: [],
      serverBrands: [],
      selectedSkus: [],
      unselectedSkus: [],
      unassign: [],
      serverSelectedSkus: [],
      searchText: '',
      enableFormValidation: false,
      prevTitle: '',
      titleAlreadyExist: false,
    };
    this.permission = getPermissionForRouteSegmentation();
    this.formReference = refGenerator(['title']);
  }

  componentDidMount() {
    this.fetchDetails();
    this.loadTableData();
  }

  fetchDetails = () => {
    const { selectedSkus, crudMode } = this.state;
    const {
      getRouteSegmentationDetails,
      match: {
        params: { id },
      },
    } = this.props;
    const selectedId = crudMode === EVENT_OPERATION.CREATE ? 0 : parseInt(id);
    getRouteSegmentationDetails(
      { id: selectedId },
      {
        handleSuccess: response => {
          const { lineDetails = {} } = response.data;
          const brandsWithFlattenedSkus =
            lineDetails.brands &&
            lineDetails.brands.map(brand => ({
              ...brand,
              skus: (brand.lineSkuFamily && brand.lineSkuFamily.map(sku => sku.skus)).flat() || [],
            }));
          const skus = [...selectedSkus];
          brandsWithFlattenedSkus.forEach(brand => {
            skus.push(...this.getSkuWithTrueStatus(brand.skus));
          });
          this.setState({
            title: lineDetails.title,
            brands: brandsWithFlattenedSkus,
            serverBrands: brandsWithFlattenedSkus,
            selectedSkus: skus,
            serverSelectedSkus: skus,
            prevTitle: lineDetails.title,
          });
        },
        handleError: err => {
          this.handleAPIFailure(err);
        },
      },
    );
  };

  loadTableData = () => {
    const { getRouteSegmentation } = this.props;
    const { data } = this.state;
    getRouteSegmentation(
      {
        offset: 0,
        filter: {
          filters: [],
          queryString: '',
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.lines && response.data.lines.rows.filter(d => d.type !== LINE_TYPE.DEFAULT)) || [];
          data.total = (response.data.lines && response.data.lines.count) || 0;
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  handleSearchInput = text => {
    const { serverBrands } = this.state;
    const regex = new RegExp(text, 'i');
    const bySkus = serverBrands.filter(i => i.lineSkuFamily.filter(s => s.title.search(regex) > -1).length > 0);
    const bySKUFamilies = serverBrands.filter(p => p.title.search(regex) > -1);
    const searchSet = new Set([...bySKUFamilies, ...bySkus]);
    this.setState({ brands: [...(Array.from(searchSet) || [])], searchText: text });
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  getSkuWithTrueStatus = list =>
    list.reduce((acc, sku) => {
      if (sku.status === true) {
        acc.push(sku.id);
      }
      return acc;
    }, []);

  handleAPIFailure = err => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, err);
  };

  handleSwitchToggle = (e, skus = []) => {
    const { selectedSkus, unselectedSkus } = this.state;
    if (e) {
      const skusSet = new Set([...selectedSkus, ...skus]);
      this.setState({
        selectedSkus: [...(skusSet || [])],
        unselectedSkus: [],
      });
    } else {
      const unselectskusSet = new Set([...unselectedSkus, ...skus]);
      this.setState({
        selectedSkus: selectedSkus.filter(id => !skus.includes(id)),
        unselectedSkus: [...selectedSkus.filter(id => skus.includes(id)), ...unselectedSkus],
        unassign: [...(unselectskusSet || [])],
      });
    }
  };

  handleSaveClick = () => {
    const { crudMode, title, id, selectedSkus, unassign } = this.state;
    const { createLine, updateLine, displayAlert, history } = this.props;
    const formValidation = refValidator(this.formReference);
    const unassignIds = unassign.filter(unselectedArray => !selectedSkus.includes(unselectedArray));
    if (!formValidation) {
      this.setState({ enableFormValidation: true });
    } else if (selectedSkus.length === 0) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Brand/SKU must be selected');
    } else {
      if (crudMode === EVENT_OPERATION.CREATE) {
        createLine(
          {
            input: {
              title,
              assign: selectedSkus,
            },
          },
          {
            handleSuccess: res => {
              displayAlert(ALERT_TYPE.SUCCESS, `Line ${MESSAGE_EVENT_OPERATION.CREATE}`);
              history.push(`/${ROUTE_SEGMENTATION}`);
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
      if (crudMode === EVENT_OPERATION.UPDATE) {
        updateLine(
          {
            input: {
              id,
              title,
              assign: selectedSkus,
              unassign: unassignIds,
            },
          },
          {
            handleSuccess: () => {
              displayAlert(ALERT_TYPE.SUCCESS, `Line ${MESSAGE_EVENT_OPERATION.UPDATE}`);
              this.setState(
                {
                  crudMode: EVENT_OPERATION.READ,
                },
                () => this.fetchDetails(),
              );
            },
            handleError: err => {
              this.onAPIRequestFailure(err);
            },
          },
        );
      }
    }
  };

  handleCancelClick = () => {
    const { crudMode, serverSelectedSkus, serverBrands, prevTitle } = this.state;
    if (crudMode === EVENT_OPERATION.UPDATE) {
      this.setState({
        title: prevTitle,
        selectedSkus: serverSelectedSkus,
        crudMode: EVENT_OPERATION.READ,
        searchText: '',
        brands: serverBrands,
      });
    }
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        title: '',
        selectedSkus: serverSelectedSkus,
        crudMode: EVENT_OPERATION.CREATE,
        searchText: '',
        brands: serverBrands,
      });
    }
  };

  updateCrudMode = mode => {
    this.setState({ crudMode: mode });
  };

  handleEditIconClick = () => {
    this.setState({ crudMode: EVENT_OPERATION.UPDATE }, () => null);
  };

  checkLineTitle = () => {
    const { data, title } = this.state;
    const filteredTitle = data.list.map(line => line.title);
    const uniqueTitle = filteredTitle.includes(title);
    if (uniqueTitle === true) {
      this.setState({ titleAlreadyExist: true });
    } else {
      this.setState({ titleAlreadyExist: false });
    }
  };

  handleInputChange = event => {
    const { name = '', value } = event.target;
    this.setState({ title: value }, () => debouncer(this.checkLineTitle, 1000)(name, value));
  };

  render() {
    const { brands, crudMode, selectedSkus, title, id, enableFormValidation, searchText, titleAlreadyExist } =
      this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <View
          routeDetailTitle={title}
          id={id}
          handleSwitchToggle={this.handleSwitchToggle}
          crudMode={crudMode}
          permission={this.permission}
          handleSaveClick={this.handleSaveClick}
          handleCancelClick={this.handleCancelClick}
          handleEditClick={this.handleEditIconClick}
          onInputChange={this.handleInputChange}
          brands={brands}
          selectedSkus={selectedSkus}
          breadCrumbConfig={breadCrumbConfig}
          loading={serverResponseWaiting}
          searchText={searchText}
          onSearchInputChange={this.handleSearchInput}
          enableErrorDisplay={enableFormValidation}
          refsObj={this.formReference}
          titleAlreadyExist={titleAlreadyExist}
        />
      </Fragment>
    );
  }
}

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
