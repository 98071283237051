import { compose } from 'recompose';
import Navigation from './Navigation';
import withRequestTracker from '../../utils/composition/withRequestTracker';
import { LOG_OUT, mutation } from './API';

const ComposedTown = compose(
  LOG_OUT,
  withRequestTracker({
    mutation,
  }),
)(Navigation);

export default ComposedTown;
