import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { UPLOAD, TOGGLE_STATE, DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import { SUB_D_DETAILS_FRAGMENT } from '../API';

const GET_SUB_D_DETAIL = gql`
  query getDistributors($id: String) {
    distributors(
      filter: { filters: [{ column: "id", value: [$id] }, { column: "active", value: ["true", "false"] }] }
    ) {
      rows {
        ...SubDDetailsFragment
        vendorLedgerId
        vendorLedgerIdv2
        salesReturnPolicy {
          full
          partial
          both
          defaultBinId
        }
        has_warehouse
        readyStock
      }
    }
  }
  ${SUB_D_DETAILS_FRAGMENT}
`;

const GET_BILLING_VENDORS = gql`
  query getBillingVendors($distributorId: Int!) {
    billingVendors(distributorId: $distributorId) {
      rows {
        customerId
        title
        state
        externalCode
      }
    }
  }
`;

const UPDATE_SUB_D = graphql(
  gql`
    mutation ($id: Int!, $input: DistributorInput!) {
      updateDistributor(id: $id, input: $input) {
        ...SubDDetailsFragment
        salesReturnPolicy {
          full
          partial
          both
          defaultBinId
        }
        readyStock
      }
    }
    ${SUB_D_DETAILS_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateSubD: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_SUB_D = graphql(
  gql`
    mutation ($input: DistributorInput!) {
      createDistributor(input: $input) {
        ...SubDDetailsFragment
      }
    }
    ${SUB_D_DETAILS_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createSubD: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_DISTRIBUTOR_LABELS = gql`
  query getDistributorLabels {
    getDistributorLabels {
      title
      label
    }
  }
`;

const SYNC_HS_CODE = graphql(
  gql`
    mutation SyncHsCodeToBilling($distributorId: Int!) {
      syncHsCodeToBilling(distributorId: $distributorId) {
        distributorId
        count
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      syncHsCodeToBilling: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_LAST_LEVEL_WAREHOUSE_DETAILS = gql`
  query getLastLevelWarehouseDetails($distributorId: Int!) {
    getLastLevelWarehouseDetails(distributorId: $distributorId) {
      id
      binLocationNumber
      levelId
      title
    }
  }
`;

const query = {
  getSubDDetail: GET_SUB_D_DETAIL,
  getBillingVendors: GET_BILLING_VENDORS,
  getDistributorLabels: GET_DISTRIBUTOR_LABELS,
  getLastLevelWarehouseDetails: GET_LAST_LEVEL_WAREHOUSE_DETAILS,
};

const mutation = {
  updateSubD: UPDATE_SUB_D,
  createSubD: CREATE_SUB_D,
  syncHsCodeToBilling: SYNC_HS_CODE,
  downloadReport: DOWNLOAD_REPORT,
  upload: UPLOAD,
  toggleState: TOGGLE_STATE,
};

export { UPDATE_SUB_D, CREATE_SUB_D, SYNC_HS_CODE, DOWNLOAD_REPORT, UPLOAD, TOGGLE_STATE };

export { query, mutation };
