import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import {
  CustomSelect, Input, SingleDatePicker
} from '../../../../../components';

const propTypes = {
  basicDetail: PropTypes.objectOf(Object).isRequired,
};

const BasicDetail = ({ basicDetail: { title, start, end, lineId }, enableRouteSegmentation, options }) => (
  <section className='sbd-top-wrap disabled'>
    <Row>
      <Col md={3}>
        <Input name='title' type='text' value={title} labelContent='Title' />
      </Col>

      {enableRouteSegmentation && (
        <Col md={3}>
          <label>Line</label>
          <CustomSelect
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            options={options}
            name='Line'
            value={options.filter(({ id }) => id === lineId)}
          />
        </Col>
      )}
      <Col md={3}>
        <div className='custom-date'>
          <label>Start Date</label>
          <SingleDatePicker onChange={() => {}} date={start} name='start' labelContent='Start Date' />
        </div>
      </Col>
      <Col md={3}>
        <div className='custom-date'>
          <label>End Date</label>
          <SingleDatePicker onChange={() => {}} date={end} name='end' labelContent='End Date' />
        </div>
      </Col>
    </Row>
  </section>
);

BasicDetail.propTypes = propTypes;

export default BasicDetail;
