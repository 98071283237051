import { compose } from 'recompose';
import { query } from './API';
import UploadWithInput from './UploadWithInput';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const composedUploadWithInput = compose(
  withRequestTracker({
    query,
  }),
)(UploadWithInput);

export default composedUploadWithInput;
