import { compose } from 'recompose';
import BrandCoverage from './BrandCoverage';

import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  UPLOAD,
  DOWNLOAD_REPORT,
  CREATE_BRAND_COVERAGE_TARGET,
  UPDATE_BRAND_COVERAGE_TARGET,
} from './API';

const ComposedBrandCoverage = compose(
  UPLOAD,
  DOWNLOAD_REPORT,
  CREATE_BRAND_COVERAGE_TARGET,
  UPDATE_BRAND_COVERAGE_TARGET,
  withRequestTracker({
    query,
    mutation,
  }),
)(BrandCoverage);

export default ComposedBrandCoverage;
