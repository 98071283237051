
export const fetchUriPrint = (uriData) => {
  const splitString = uriData?.split(',');
  const base64Data = splitString[1];

  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);


  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';

  iframe.classList.add('appended-iframe');

  // Find the iframe element with class name 'appended-iframe'
  let appendedIframe = document.querySelector('iframe.appended-iframe');
  // Check if the iframe element exists
  if (appendedIframe) {
    // Remove the iframe element from the DOM
    appendedIframe?.parentNode?.removeChild(appendedIframe);
  }

  document.body.appendChild(iframe);

  iframe.onload = () => {
    iframe?.contentWindow?.print();
  };
};


