import { monthDateYear } from '../../../../../utils/date';

export const linkedDeviceListMapper = (Devicedata) => {
  return Devicedata?.map((device) => ({
    id: device.id,
    phoneModel: device.phone_model ?? '-',
    deviceName: device.phone_name ?? '-',
    userName: device?.User?.fullName ? device.User.fullName : '-',
    createdDate: device.createdAt ? monthDateYear(device.createdAt) : '-',
    status: device.status ? device.status.toUpperCase() : '-',
  }));
};

export const baseApiUrlMapper = (data) => {
  return {
    data: {
      url: `${window.location.hostname}/api`,
      secretToken: data?.mobile_auth_token,
    },
    encryptKey: data?.mobile_api_url_decrypt_key || null,
  };
};
