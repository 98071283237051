import {
  apolloClient, DEFAULT_QUERY_VARIABLES, errorProcessor
} from './common';
import { GET_BANK_LIST } from '../../views/sales/collection/cheque/API';

const getBankFromServer = () =>
  apolloClient
    .query({
      query: GET_BANK_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })

    .then((res) => res.data.banks)
    .catch((err) => errorProcessor(err));

export { getBankFromServer };
