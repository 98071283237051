import React from 'react';
import { CheckBox, Button } from '..';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

interface Props {
  checkedListLength: number;
  dataLength: number;
  onIconClick: (status: any) => void;
  Buttons: any[];
  loading: boolean;
}

const BulkSelectView = ({ checkedListLength, dataLength, onIconClick, Buttons }: Props) =>
  checkedListLength > 0 && (
    <div className='selected-status'>
      <div className='content-center'>
        <div>
          <CheckBox onCheckBoxClick={() => { }} checked className='disabled-btn' />
          <p className='text'>
            <span>{checkedListLength}</span>
            of
            <span>{dataLength}</span>
            items selected
          </p>
        </div>
        <div className='button-section'>
          {Buttons.map((button: any) => (
            <Button
              key={button.label}
              small
              secondary={button.label === 'Remove'}
              primary={button.label !== 'Remove'}
              onClick={() => onIconClick(button.status)}
            >
              {button.label}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );

const BulkDeleteWithErrorAndLoading = withErrorAndLoadingScreen(BulkSelectView);

export default BulkDeleteWithErrorAndLoading;
