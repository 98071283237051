import { compose } from 'recompose';
import GeoLocation from './geoLocation';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  DOWNLOAD_REPORT, BULK_UPDATE_APPROVAL_REQUEST, query, mutation
} from './API';

const ComposedGeoLocation = compose(
  BULK_UPDATE_APPROVAL_REQUEST,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(GeoLocation);

export default ComposedGeoLocation;
