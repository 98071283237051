import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input/Text';
import { debouncer } from '../../utils/formHandlers';

const propTypes = {
  delay: PropTypes.number,
  labelContent: PropTypes.string,
  placeHolder: PropTypes.string,
  onInputChange: PropTypes.func,
};
const defaultProps = {
  delay: 1000,
  labelContent: '',
  placeHolder: 'Search',
};

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value === state.value) {
      return {
        value: props.value,
      };
    }
  }

  handleInputChange = value => {
    const { onInputChange, delay } = this.props;
    return debouncer(() => onInputChange(value), delay)();
  };

  render() {
    const { value } = this.state;
    const { labelContent, placeHolder } = this.props;

    return (
      <Input
        type="text"
        name="search text"
        value={value}
        placeholder={placeHolder}
        labelContent={labelContent}
        onChange={e => {
          this.setState({ value: e.target.value });
          this.handleInputChange(e.target.value);
        }}
      />
    );
  }
}

SearchInput.propTypes = propTypes;
SearchInput.defaultProps = defaultProps;

export default SearchInput;
