import { useState, useEffect } from 'react';
import * as queryService from '../../../base/query.service';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { ALERT_TYPE } from '../../../../v4/constants/AlertType';
import { fetchFromLocalStorage, isError, storeInLocalStorage } from '../../../common/HelperFunctions';
import { getPermissionForSkuGroup } from '../../../base/permission';
import { MESSAGE_EVENT_OPERATION } from '../../../../v4/constants/successMessage';
import {
  failedServerToClientDataMapper,
  pendingServerToClientDataMapper,
  successServerToClientDataMapper,
} from '../DataMapper/dataMapper';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import { PAGINATION } from '../../../../data/enums';
import { clone } from '../../../../utils/objectProcessor';
import { filterConfig } from '../config';
import { DOMAIN } from '../../../../data/enums/config';
import { storageKeys } from '../../../sales/orderProcessing/config';

const usePurchaseOrderList = props => {
  const {
    displayAlert,
    getSkuPurchaseDetailsApi,
    getPurchaseRequestedListApi,
    createPurchaseOrder,
    getPurchaseOrderCountApi,
  } = props;

  const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.PURCHASE_ORDER);
  const localTab = fetchFromLocalStorage('TABINDEX', DOMAIN.PURCHASE_ORDER);
  const newFilterConfig = {
    ...(localData || {
      date: { ...filterConfig.date },
      filter: { ...filterConfig.filter },
      filterMenu: { ...filterConfig.filterMenu },
      searchText: '',
    }),
  };

  const filterConfigMenu = clone(filterConfig.menu);
  const [data, setData] = useState({ list: [], total: 0 });
  const [formClicked, setFormClicked] = useState(false);
  const [count, setCount] = useState({ pendingCount: 0, successCount: 0, failedCount: 0 });
  const [loading, setLoading] = useState(false);
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    search: queryService.baseQueryParameters.search,
    filter:
      Object.keys(newFilterConfig?.filter)?.length > 0
        ? newFilterConfig?.filter
        : queryService.baseQueryParameters.filter,
    date:
      Object.keys(newFilterConfig?.date)?.length > 0
        ? newFilterConfig?.date
        : { ...queryService.baseQueryParameters.date },
  });

  const [filterMenu, setFilterMenu] = useState(
    Object.keys(newFilterConfig?.filterMenu)?.length > 0 ? newFilterConfig?.filterMenu : filterConfigMenu,
  );

  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [activeTab, setActiveTab] = useState(localTab ?? 0);

  const getPurchaseOrderListCount = () => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    getPurchaseOrderCountApi(
      {
        limit: queryParameters.pagination.limit,
        offset,
        filter: {
          queryString: queryParameters.search,
          filters: [...apiFilterProcessor(queryParameters.filter)],
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: res => {
          if (isError(res)) onAPIRequestFailure(res.errors[0]);
          const response = res.data.purchaseRequestedListCount;
          setCount({
            pendingCount: response?.pendingCount ?? 0,
            successCount: response.successFulcount ?? 0,
            failedCount: response.unSuccessFulcount ?? 0,
          });
          setLoading(false);
        },
        handleError: err => {
          onAPIRequestFailure(err);
          setLoading(false);
        },
      },
    );
  };

  const loadTableData = activeTab => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    setFilterStateInStorage();

    if (activeTab === 0) {
      setLoading(true);
      getSkuPurchaseDetailsApi(
        {
          limit: queryParameters.pagination.limit,
          offset,
          filter: {
            queryString: queryParameters.search,
            filters: [...apiFilterProcessor(queryParameters.filter)],
            // dateRange: queryParameters.date,
          },
        },
        {
          handleSuccess: res => {
            if (isError(res)) onAPIRequestFailure(res.errors[0]);
            const response = res.data.getSkuPurchaseDetails;
            setData({
              list: response ? pendingServerToClientDataMapper(response?.rows) : [],
              total: response ? response?.count : 0,
            });
            setLoading(false);
          },
          handleError: err => {
            onAPIRequestFailure(err);
            setLoading(false);
          },
        },
      );
    }
    if (activeTab === 1) {
      setLoading(true);
      getPurchaseRequestedListApi(
        {
          limit: queryParameters.pagination.limit,
          offset,
          filter: {
            queryString: queryParameters.search,
            filters: [
              ...apiFilterProcessor(queryParameters.filter),
              {
                column: 'status',
                value: 'SUCCESS',
              },
            ],
            dateRange: queryParameters.date,
          },
        },
        {
          handleSuccess: res => {
            if (isError(res)) onAPIRequestFailure(res.errors[0]);
            const response = res.data.purchaseRequestedStatusList;
            setData({
              list: response ? successServerToClientDataMapper(response.rows) : [],
              total: response ? response.count : 0,
            });
            setLoading(false);
          },
          handleError: err => {
            onAPIRequestFailure(err);
            setLoading(false);
          },
        },
      );
    }
    if (activeTab === 2) {
      setLoading(true);
      getPurchaseRequestedListApi(
        {
          limit: queryParameters.pagination.limit,
          offset,
          filter: {
            queryString: queryParameters.search,
            filters: [
              ...apiFilterProcessor(queryParameters.filter),
              {
                column: 'status',
                value: 'FAILURE',
              },
            ],
            dateRange: queryParameters.date,
          },
        },
        {
          handleSuccess: res => {
            if (isError(res)) onAPIRequestFailure(res.errors[0]);
            const response = res.data.purchaseRequestedStatusList;
            setData({
              list: response ? failedServerToClientDataMapper(response.rows) : [],
              total: response ? response.count : 0,
            });
            setLoading(false);
          },
          handleError: err => {
            onAPIRequestFailure(err);
            setLoading(false);
          },
        },
      );
    }
  };

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const setParameters = queryParams => setQueryParameters({ ...queryParams });

  const { downloadReport } = props;
  const basePaginationService = new queryService.QueryClass(
    setParameters,
    () => queryParameters,
    loadTableData,
    downloadReport,
    displayAlert,
  );

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
  };

  const handleIconClick = (type, element = {}) => {
    setDialog({
      type,
      element,
    });
  };

  const handleDialogSubmit = type => {
    if (type === EVENT_OPERATION.CREATE) {
      setFormClicked(true);
      createPurchaseOrder(
        {},
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${MESSAGE_EVENT_OPERATION[type]}`);
            loadTableData(activeTab);
            getPurchaseOrderListCount();
            resetDialog();
            setFormClicked(false);
          },
          handleError: err => {
            onAPIRequestFailure(err);
            setFormClicked(false);
          },
        },
      );
    }
  };

  const permission = getPermissionForSkuGroup();

  const onTabChange = tab => {
    setData({
      list: [],
      total: 0,
    });

    queryParameters;
    setQueryParameters({
      ...queryParameters,
      pagination: {
        page: PAGINATION.PAGE,
        limit: PAGINATION.LIMIT,
      },
    });

    setActiveTab(tab);
    loadTableData(tab);
    getPurchaseOrderListCount();
    storeInLocalStorage('TABINDEX', tab, DOMAIN.PURCHASE_ORDER);
  };

  const tabWiseDownloadFilter = () => {
    if (activeTab === 1) {
      return {
        column: 'status',
        value: 'SUCCESS',
      };
    }
    if (activeTab === 2) {
      return {
        column: 'status',
        value: 'FAILURE',
      };
    }
  };

  const tabWiseDownloadDomain = domain => {
    if (activeTab === 1) {
      return 'SUCCESS_PO';
    }
    if (activeTab === 2) {
      return 'FAILED_PO';
    }
    return domain;
  };

  const handleDownloadClick = (reportType, domain) => {
    const filter = {
      filters: [...apiFilterProcessor(queryParameters.filter), tabWiseDownloadFilter()].filter(Boolean),
      queryString: queryParameters.search,
      dateRange: queryParameters.date,
    };

    basePaginationService.handleDownloadClickWithCustomFilter('csv', tabWiseDownloadDomain(domain), filter);
  };

  const handleRowClick = ({ type, element }) => {
    setDialog({
      type,
      element,
    });
  };

  const setFilterStateInStorage = () => {
    const { filter, date, search } = queryParameters;
    storeInLocalStorage('TABINDEX', activeTab, DOMAIN.PURCHASE_ORDER);
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.PURCHASE_ORDER,
    );
  };

  useEffect(() => {
    setData({
      list: [],
      total: 0,
    });

    loadTableData(activeTab);
    getPurchaseOrderListCount();
  }, [queryParameters]);

  return {
    dialog,
    data,
    loading,
    permission,
    queryParameters,
    activeTab,
    count,
    formClicked,
    basePaginationService,
    filterMenu,
    onTabChange,
    handleDialogSubmit,
    resetDialog,
    handleIconClick,
    handleDownloadClick,
    handleRowClick,
  };
};

export default usePurchaseOrderList;
