
const title = 'Call Plan';


const CALLPLAN_TABS = {
  PENDING: 'pending',
  PLANNED:'planned',
  COMPLETED:'completed'
};

const tabMapper = {
  0: CALLPLAN_TABS.PENDING,
  1: CALLPLAN_TABS.PLANNED,
  2: CALLPLAN_TABS.COMPLETED,
};

export {
  title, tabMapper, CALLPLAN_TABS
};
