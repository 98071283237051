import { sales as salesRoute, collection as collectionRoute } from '../../../../data/enums/Route';
import { FILTER } from '../../../../data/enums/filter';
import { getFilterConfig } from '../../../../utils/filter';

const title = 'Cash';

const breadCrumbConfig = [salesRoute, collectionRoute];

const CASH_TABS = {
  PENDING: 'pending',
  RECONCILED: 'reconciled',
};

const tabMapper = {
  0: CASH_TABS.PENDING,
  1: CASH_TABS.RECONCILED,
};

const filterConfig = getFilterConfig([FILTER.SUB_D, FILTER.ROUTE, FILTER.DSE, FILTER.AMOUNT]);

export {
  title, breadCrumbConfig, tabMapper, CASH_TABS, filterConfig,
};
