import { compose } from 'recompose';
import TimeSpendOnRoute from './TimeSpendOnRoute';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const ComposedTSOR = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(TimeSpendOnRoute);

export default ComposedTSOR;
