import React from 'react';
import PropTypes from 'prop-types';
import SectionWithTitle from '../components/SectionWithTitle';
import CoverageMap from '../components/CoverageMap';

const RouteCoverage = ({ data }) => (
  <SectionWithTitle title='route coverage'>
    <CoverageMap data={data} zoom={11} />
  </SectionWithTitle>
);

RouteCoverage.propTypes = {
  data: PropTypes.arrayOf(Object).isRequired,
};

export default RouteCoverage;
