import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BrandCoverageView from './View';
import PageHeader from '../../base/PageHeader';
import { breadCrumbConfig } from '../config';
import { isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { brandCoverage } from '../../common/DomainConfig';
import { BRAND_COVERAGE } from '../../../data/enums/Route';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import CoverageStyled from './brand-distribution/CoverageStyled';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getBrandCoverageReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  downloadReport: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class BrandCoverage extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 0,
        coverage: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
    };

    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getBrandCoverageReport } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getBrandCoverageReport(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          data.coverage = (response.data.getBrandCoverage && response.data.getBrandCoverage.coverage) || 0;
          data.list = (response.data.getBrandCoverage && response.data.getBrandCoverage.rows) || [];
          data.total = (response.data.getBrandCoverage && response.data.getBrandCoverage.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleTableRowClick = row => {
    const { history } = this.props;
    history.push(`/${BRAND_COVERAGE}/${row.id}`, { title: row.name || 'Brand name', brandId: row.id });
  };

  render() {
    const { data, queryParameters, display } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <CoverageStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: `${brandCoverage.title} ${data.coverage ? `: ${fixedFloatAndCommas(data.coverage)}%` : ''}`,
                create: false,
                download: true,
                filter: false,
                search: false,
                date: true,
              }}
              downloadConfig={{
                domain: brandCoverage.domain,
              }}
              filter={{
                date: queryParameters.date,
              }}
              queryParameters={queryParameters}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <BrandCoverageView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            onIconClick={() => {}}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
            handleRowClick={this.handleTableRowClick}
          />
        </div>
      </CoverageStyled>
    );
  }
}

BrandCoverage.propTypes = propTypes;

BrandCoverage.defaultProps = defaultProps;

export default withAlert()(BrandCoverage);
