import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_NEW_CHANNEL_LIST } from '../../channelV3.1/API';

const GET_PRODUCT_GROUP_DETAILS = gql`
  query getProductGroupDetails {
    catalogDetails(catalogId: 2) {
      rows {
        id
        title
      }
    }
  }
`;

const GET_BRAND_DETAILS = gql`
  query getBrandDetails {
    catalogDetails(catalogId: 3, limit: 1000) {
      rows {
        id
        title
        parentCatalogDetailId
      }
    }
  }
`;

const GET_SKU_FAMILY_DETAILS = gql`
  query getSKUFamilyDetails($parentCatalogDetailId: Int) {
    catalogDetails(offset: 0, limit: 1000, catalogId: 7, parentCatalogDetailId: $parentCatalogDetailId) {
      rows {
        id
        title
        skus: SKUs {
          id
          title
        }
      }
    }
  }
`;

const GET_ASSORTMENT_CATEGORY_COUNT = gql`
  query getAssortmentCategoryCount($channelId: Int) {
    categoriesAssortmentCount(channelId: $channelId) {
      rows {
        categoryId
        count
      }
    }
  }
`;

const GET_PRODUCT_HIERARCHY_ASSORTMENT_COUNT = gql`
  query getAssortmentCountByProductHierarchy($categoryId: Int!) {
    productHierarchyAssortmentCount(categoryId: $categoryId) {
      brands {
        id
        count
      }
      skuFamilies: productPacks {
        id
        count
      }
      productCategories {
        id
        count
      }
    }
  }
`;

const GET_ASSORTED_SKUS = gql`
  query getAssortedSKUList($categoryId: Int!) {
    appliedSKUSForCategoryAssortment(categoryId: $categoryId) {
      rows
    }
  }
`;

const TOGGLE_CHANNEL_CATEGORY_SKUS = graphql(
  gql`
    mutation ($categoryId: Int!, $skuIds: [Int!], $status: Boolean!) {
      createCategorySKUAssortment(categoryId: $categoryId, skuIds: $skuIds, status: $status) {
        rows
        count {
          categories {
            categoryId
            count
          }
          brands {
            id
            count
          }
          skuFamilies: productPacks {
            id
            count
          }
          productCategories {
            id
            count
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      toggleCategorySKUAssortment: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getCategoryList: GET_NEW_CHANNEL_LIST,
  getProductGroupDetails: GET_PRODUCT_GROUP_DETAILS,
  getBrandDetails: GET_BRAND_DETAILS,
  getSKUFamilyDetails: GET_SKU_FAMILY_DETAILS,
  getAssortmentCategoryCount: GET_ASSORTMENT_CATEGORY_COUNT,
  getAssortmentCountByProductHierarchy: GET_PRODUCT_HIERARCHY_ASSORTMENT_COUNT,
  getAssortedSKUList: GET_ASSORTED_SKUS,
};

const mutation = {
  toggleCategorySKUAssortment: TOGGLE_CHANNEL_CATEGORY_SKUS,
};

export {
  query, GET_BRAND_DETAILS, GET_PRODUCT_GROUP_DETAILS, mutation, TOGGLE_CHANNEL_CATEGORY_SKUS
};
