import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DistributionCoverageView from './View';
import PageHeader from '../../base/PageHeader';
import { headerLabelConfig, isError } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../utils/api';
import { filterConfig } from './config';
import { breadCrumbConfig } from '../config';
import { USER_ROLE } from '../../../data/enums';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Report extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
      },
    };

    const { displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getReport } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getReport(
      {
        roleId: USER_ROLE.STL,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.getUserWithStateList && response.data.getUserWithStateList.rows) || [];
          data.total = (response.data.getUserWithStateList && response.data.getUserWithStateList.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const { data, queryParameters, display } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: headerLabelConfig(USER_ROLE_TITLE.STL) || 'STL',
                create: false,
                download: false,
                filter: true,
                search: true,
                date: false,
              }}
              filter={{
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <DistributionCoverageView
            data={data}
            pagination={queryParameters.pagination}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
          />
        </div>
      </Fragment>
    );
  }
}

Report.propTypes = propTypes;

Report.defaultProps = defaultProps;

export default withAlert()(Report);
