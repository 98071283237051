import gql from 'graphql-tag';
import {
  clientResolver, DEFAULT_QUERY_VARIABLES, apolloClient, errorProcessor
} from './common';

const TABLE = {
  MERCHANDISE_DEVICE: 'merchandiseDevices',
};

const query = gql(`
    query getMerchandiseDeviceList {
        ${TABLE.MERCHANDISE_DEVICE} {
            rows{
                id
                title
            } 
        }
    }
`);

const getMerchandiseDeviceListFromServer = () =>
  apolloClient
    .query({
      query,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then((response) => response.data.merchandiseDevices.rows)
    .catch((err) => errorProcessor(err));

const insertMerchandiseDeviceList = (data) => {
  clientResolver.then((client) =>
    client.writeQuery({
      query,
      data: {
        [TABLE.MERCHANDISE_DEVICE]: data,
      },
    }),
  );
};

const fetchAndSetMerchandiseDeviceList = async () => {
  const merchandiseDeviceList = await getMerchandiseDeviceListFromServer();
  insertMerchandiseDeviceList(merchandiseDeviceList);
};

const getMerchandiseDeviceList = async () =>
  clientResolver
    .then((client) => client.readQuery({ query }))
    .then((response) => response[TABLE.MERCHANDISE_DEVICES] || []);

export {
  TABLE,
  insertMerchandiseDeviceList,
  fetchAndSetMerchandiseDeviceList,
  getMerchandiseDeviceListFromServer as getMerchandiseDeviceList,
};
