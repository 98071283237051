import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT, UPLOAD } from '../../../common/GqlAPI';

const GET_USER_FUNDAMENTAL_TARGETS = gql`
  query Rows {
    listUserFundamentalTarget {
      rows {
        id
        title
        start_date
        end_date
      }
      count
    }
  }
`;

const CREATE_OUTLET_COVERAGE_TARGET = graphql(
  gql`
    mutation createOutletCoverageTarget($input: OutletCoverageTargetInput!) {
      createOutletCoverageTarget(input: $input) {
        id
        title
        startDate
        endDate
        target
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createOutletCoverageTarget: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const CREATE_FUNDAMENTAL_USER_TARGET = graphql(
  gql`
    mutation ($input: FundamentalTargetInput) {
      createUserFundamentalTarget(input: $input) {
        title
        start_date
        end_date
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createFundamentalUserTarget: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_OUTLET_COVERAGE_TARGET = graphql(
  gql`
    mutation updateOutletCoverageTarget($id: Int!, $input: OutletCoverageTargetInput!) {
      updateOutletCoverageTarget(id: $id, input: $input) {
        id
        title
        startDate
        endDate
        target
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateOutletCoverageTarget: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getUserFundamentalTargets: GET_USER_FUNDAMENTAL_TARGETS,
};

const mutation = {
  upload: UPLOAD,
  downloadReport: DOWNLOAD_REPORT,
  createFundamentalUserTarget: CREATE_FUNDAMENTAL_USER_TARGET,
  updateOutletCoverageTarget: UPDATE_OUTLET_COVERAGE_TARGET,
};

export { query, mutation, UPLOAD, CREATE_FUNDAMENTAL_USER_TARGET, UPDATE_OUTLET_COVERAGE_TARGET, DOWNLOAD_REPORT };
