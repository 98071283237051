import styled from 'styled-components';

const CallPlanWrapper = styled.div`
  .table-wrap {
    td {
      .light {
        min-width: 90px;
        padding: 3px 4px;
        text-align: center;
        line-height: 10px;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;
        border-radius: 2px;
      }
      .successful {
        border: 0;
        padding: 0;
        .light {
          border: 1px solid #47C464;
          color:#287D3C;
        }
      }
      .unsuccessful {
        .light {
          border: 1px solid rgba(218,20,20,0.4);
          color: #DA1414;
        }
      }
    }
  }
      .dashboard-wrapper{
        .dashboard-btn{
        border:1px solid rgba(167, 169, 188, .4);
        position: fixed;
          top: 45%;
          z-index: 2;
          right: 0;
          padding:36px 6px;
          border-radius:8px 0 0 8px;
          background:rgba(231, 231, 237, .4);
          .icon{
            color:#A7A9BC;
          }
        }
        .menu {
          background: #FFFFFF;
          box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
          height: 100vh;
          width: 430px;
          position: fixed;
          top: 0; 
          z-index: 1;
          transition: all 0.25s ease;
          transform: translateX(50%);
          &.inActive{
            right: -215px;
          }
          header{
            padding:17px 24px;
            border-bottom:1px solid #E7E7ED;
            display:flex;
            justify-content: space-between;
            align-items:center;
            h2{
              margin:0;
              font-size:20px;
              line-height:25px;
              color:#272833;
            }
          }
          main{
            padding:24px;
            height: calc(100vh - 60px);
            overflow-y: auto;
            overflow-x:hidden;
            .row{
              align-items:center;
            }
            .card{
              border:1px solid #E7E7ED;
              border-radius:4px;
              padding:16px;
              margin-bottom:24px;
              h5{
                font-size:12px;
                line-height:15px;
                color:#6B6C7E;
                margin:0;
                margin-bottom:4px;
                font-weight:400;
              }
              p{
                font-size:24px;
                line-height:29px;
                margin:0;
                color:#272833;
                font-weight:400;
                overflow:hidden;
                word-break:break-all;
              }
              &.c2{
                &:not(.planned){
                  p span{
                    margin-right:8px;
                  }
                }
                &.planned{
                background:rgba(238, 242, 250, .5);
                border:1px solid rgba(46, 90, 172, .5);
                }
              }
              &.c1{
                text-align:center;
                &.received{
                  border:1px solid rgba(255, 143, 57, .5);
                  background:#FFF4EC;
                  display:flex;
                  justify-content: space-between;
                  align-items:center;
                  h5{
                    color:#272833;
                    font-size:16px;
                    margin-top:8px;
                  }
                  p{
                  color: #272833;
                  font-weight:500;
                  }

                }
              }
            }
          }
        }

        .active {
          opacity:1;
          visibility: visible;
          transition: all 0.25s ease;
          transform: translateX(0);
          z-index: 2;
          right:0;
          width:430px;
          & + .dashboard-btn {
                    transition: all 0.25s ease;
                  transform: translateX(-430px);
                  background:#6B6C7E;
                  padding:30px 6px;
                  border:0;
                  top: 47.5%;
                    .icon{
                          transition: all 0.25s ease;
                        transform: rotate(180deg);
                        color:#FFFFFF;
                    }
                  }
        }
      }
      .dashboard-wrapper{
      .loading-main {
        bottom: 10px;
        right: 20px;
      }
      }
`;

export default CallPlanWrapper;
