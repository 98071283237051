import styled from 'styled-components';

const ErrorStyled = styled.div`
  width: 100%;
  height: 100vh;

  .error-container {
    max-width: 500px;
    padding: 0 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;

    img {
      height: 126px;
    }
  }

  .error-type {
    color: #e7e7ed;
    font-size: 144px;
    font-weight: bold;
    line-height: 105px;
    text-align: center;
    margin-top: 32px;
  }

  .error-message {
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    margin-top: 24px;
  }

  .message {
    color: #272833;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    margin: 48px 0;
  }
`;

export default ErrorStyled;
