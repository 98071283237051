import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MonthPicker } from '../../../v4/components';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  type: '',
};

const TargetAchievementForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, type,handleDateChange } = props;

  return (
    <Form>
      <>
        <div className={type === EVENT_OPERATION.READ ? 'month-wrap disabled' : 'month-wrap'}>
          <label>Month</label>
          <MonthPicker value={data.duration}
            onSelect={(e)=> handleDateChange(e,'duration')}
          />
        </div>
        <Input
          ref={(ref) => (refsObj.target = ref)}
          value={data.target}
          name='target'
          type='number'
          argument={{ min: 0 }}
          placeholder='Monthly Target'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event)}
          labelContent='Monthly Target'
          disabled={type === EVENT_OPERATION.READ}
          decimalWithPrecedingZero
        />
      </>
    </Form>
  );
};

TargetAchievementForm.propTypes = propTypes;

TargetAchievementForm.defaultProps = defaultProps;

const TargetAchievementFormWithErrorAndLoading = withLoading(TargetAchievementForm);

export default TargetAchievementFormWithErrorAndLoading;
