import styled from 'styled-components';

const OpeningBalanceStyled = styled.div`
  background: #fbfbfc;
  height: 100%;
  .mt-6 {
    margin-top: 6px;
    padding-left: 0;
  }

  .distributor-selection {
    padding: 0px 48px;
  }

  table td:last-child {
    text-align: left;
  }

  .opening-balance-container {
    text-align: center;
    /* margin: 250px; */
  }
  .text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-inner {
    max-width: 600px;
    .upload {
      padding: 0px;
      background: #f1f2f5;
      padding-bottom: 30px;
    }
    .mb-24 {
      position: absolute;
      right: 120px;
      padding-top: 10px;
    }
  }

  table {
    border: 0;
    tr {
      &.collapsible-body-tr {
        tbody {
          tr {
            th,
            td {
              &:first-child {
                padding: 7px 12px 7px 270px;
                text-align: left;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        &.active {
          td:first-child > span:before {
            transform: rotate(180deg);
            transition: all 0.23s ease-in-out;
            border-top-color: #004ad7;
          }
        }
        td:first-child {
          > span {
            position: relative;
            padding-left: 26px;

            &:before {
              content: '';
              width: 0;
              height: 0;
              border-radius: 4px;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #6b6c7e;
              position: absolute;
              top: 5px;
              left: 0;
              transition: all 0.23s ease-in-out;
            }
          }
        }
      }
    }
  }

  .collapsible-body-tr {
    &:hover,
    &:focus {
      background: transparent;
    }

    table {
      box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
      border: none;

      tbody {
        tr:last-child {
          border-top: 1px solid #e7e7ed;
        }
      }

      tr {
        th {
          background: transparent;
          width: auto;
        }
      }
      th,
      td:last-child {
        text-align: right;
        padding-right: 200px;
      }
    }
  }
`;

export default OpeningBalanceStyled;
