import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import { GET_CATALOG_DETAIL_LIST } from '../../../configuration/catalog/details/API';
import { GET_TERRITORY_LIST } from '../../../configuration/territory/API';

const GET_TARGETS_DETAIL = gql`
  query GetAutomatedSalesTargetDetails($getAutomatedSalesTargetDetailsId: Int) {
    getAutomatedSalesTargetDetails(id: $getAutomatedSalesTargetDetailsId) {
      year
      month
      national_target
      average_base {
        is_same_quarter
      }
      monthName
      automated_sales_target_details {
        id
        outlet_target
        channel {
          id
          title
        }
        territory {
          id
          title
        }
        principalId: principle_id
        base_metric {
          dimension
        }
      }
      id
    }
  }
`;

export const CREATE_TARGET_DISTRIBUTION = graphql(
  gql`
    mutation CreateAutomatedSalesTarget($automatedSalesTargetId: Int!, $principleId: Int!) {
      createAutomatedSalesTarget(automatedSalesTargetId: $automatedSalesTargetId, principleId: $principleId) {
        id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createAutomatedSalesTarget: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

export const UPDATE_TARGET_DISTRIBUTION = graphql(
  gql`
    mutation UpdateAutomatedSalesTarget($input: automatedSalesTargetInput!) {
      updateAutomatedSalesTarget(input: $input) {
        monthName
        id
        year
        month
        national_target
        average_base {
          is_same_quarter
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateAutomatedSalesTarget: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

export const CALCULATE_AUTOMATED_SALES_TARGET = graphql(
  gql`
    mutation CalculateAutomatedSalesTarget($input: automatedSalesTargetInput!) {
      calculateAutomatedSalesTarget(input: $input) {
        automatedSalesTargetId
        detail {
          channel
          channelId
          territory
          territoryId
          targetContribution: outletTarget
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      calculateAutomatedSalesTarget: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getAutomatedSalesTargetDetails: GET_TARGETS_DETAIL,
  getCatalogListDetail: GET_CATALOG_DETAIL_LIST,
  getTerritoryList: GET_TERRITORY_LIST,
};

const mutation = {
  createAutomatedSalesTarget: CREATE_TARGET_DISTRIBUTION,
  updateAutomatedSalesTarget: UPDATE_TARGET_DISTRIBUTION,
  calculateAutomatedSalesTarget: CALCULATE_AUTOMATED_SALES_TARGET,
  downloadReport: DOWNLOAD_REPORT,
};

export { query, mutation, DOWNLOAD_REPORT };
