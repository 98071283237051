import { EVENT_OPERATION } from './EventOperation';

const FORM_CONFIG = {
  MAPPER: 'mapper',
  REFS_OBJ: 'refsObj',
  TITLE: 'title',
  VALIDATION_FIELD: 'validationField',
  VALIDATION_REQUIRED: 'validationRequired',
};

const CONFIRMATION_CONFIG = {
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.MAPPER]: (element: any) => ({
      id: element.id || '',
    }),
    [FORM_CONFIG.TITLE]: 'Confirm',
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    [FORM_CONFIG.MAPPER]: (element: any) => ({
      ids: element || '',
    }),
    [FORM_CONFIG.TITLE]: 'Confirm',
  },
  [EVENT_OPERATION.UPDATE_STATUS]: {
    [FORM_CONFIG.MAPPER]: (element: any) => ({
      id: element.id || '',
    }),
    [FORM_CONFIG.TITLE]: 'Confirm',
  },
  [EVENT_OPERATION.CHANGE_STATUS]: {
    [FORM_CONFIG.MAPPER]: (element: any) => ({
      id: element.id || '',
    }),
    [FORM_CONFIG.TITLE]: '',
  },

  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.MAPPER]: (element: any) => ({
      file: element.file || {},
    }),
    [FORM_CONFIG.TITLE]: 'File Upload',
  },
};

const WIDGETS = {
  SORT: 'SORT',
  FILTER: 'FILTER',
  SEARCH: 'SEARCH',
  UPLOAD: 'UPLOAD',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DOWNLOAD: 'DOWNLOAD',
  VIEW: 'VIEW',
  MANUAL: 'MANUAL',
  PRINT: 'PRINT',
};

const DOMAIN = {
  CHANNEL_CATEGORY: 'CHANNEL_CATEGORY',
  OPENING_BALANCE: 'OPENING_BALANCE',
  PURCHASE_RETURN: 'PURCHASE_RETURN',
  RETAIL_OUTLET: 'RETAIL_OUTLET',
  ROUTE: 'ROUTE',
  SALES: 'SALES',
  SALES_RETURN: 'SALES_RETURN',
  SBD: 'SBD',
  FOCUSED_SKU: 'FOCUSED_SKU',
  STOCK_LEDGER: 'STOCK_LEDGER',
  TERRITORY: 'TERRITORY',
  TOWN: 'TOWN',
  USER_ACCOUNT: 'USER_ACCOUNT',
  USER_ROLE: 'USER_ROLE',
  SUB_D: 'SUB_D',
  STOCK_ADJUSTMENT: 'STOCK_ADJUSTMENT',
  CHANNEL_ASSORTMENT: 'CHANNEL_ASSORTMENT',
  REPLENISHMENT: 'REPLENISHMENT',
  STOCK_AVAILABILITY: 'STOCK_AVAILABILITY',
  STOCK_REPLENISHMENT: 'STOCK_REPLENISHMENT',
  STOCK_REPLACEMENT: 'STOCK_REPLACEMENT',
  SKU_COVERAGE: 'SKU_COVERAGE',
  BRAND_COVERAGE: 'BRAND_COVERAGE',
  CASH: 'CASH',
};

export { DOMAIN, FORM_CONFIG, CONFIRMATION_CONFIG, WIDGETS };
