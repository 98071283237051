import { compose } from 'recompose';
import BrandCoverageDetail from './BrandCoverageDetail';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_OR_EDIT_BRAND_COVERAGE_USER_TARGET
} from './API';

const ComposedBrandCoverageDetail = compose(
  CREATE_OR_EDIT_BRAND_COVERAGE_USER_TARGET,
  withRequestTracker({
    query,
    mutation,
  }),
)(BrandCoverageDetail);

export default ComposedBrandCoverageDetail;
