import { clone, updateObjectAttribute } from './objectProcessor';

let interval;

const inputChange = (data, event, firstParam = '', paramList = [],param) => {
  const details = clone(data);
  if (!firstParam) {
    details[event.target.name] = event.formattedValue;

    return details;
  }
  if (paramList.length === 0) {
    details[firstParam][event.target.name] = event.formattedValue;

    return details;
  }
  const updatedDetails = updateObjectAttribute(details, paramList, event.formattedValue,param);

  return updatedDetails;
};

const radioChange = (data, paramList = [],value) => {
  const details = clone(data);
  const updatedDetails = updateObjectAttribute(details, paramList, value);
  return updatedDetails;
};

const dropdownChange = (data, parameterRef, value) => {
  const details = updateObjectAttribute(clone(data), parameterRef, value);

  return details;
};

const debouncer =
  (callback, time) =>
    (...args) => {
      clearTimeout(interval);
      interval = setTimeout(() => {
        interval = null;
        callback(...args);
      }, time);
    };

export {
  inputChange, dropdownChange, debouncer, radioChange
};
