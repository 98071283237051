import { useEffect, useState } from 'react';

const useDragAndDrop = (data, onSequenceChange) => {
  const [items, setItems] = useState(data);

  useEffect(() => {
    setItems(data);
    //when the drag and drop action is triggered sorted data is sent to the parent component
  }, [data]);
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    onSequenceChange(result, items);
    return result;
  };

  const onDragEnd = e => {
    if (!e.destination) {
      return;
    }

    const sorted = reorder(items, e.source.index, e.destination.index);
    // sortedTableData(sorted);
    setItems(sorted);
  };

  const getListStyle = isDraggingOver => ({
    // width: 1200
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  return {
    onDragEnd,
    getListStyle,
    getItemStyle,
    items,
  };
};

export default useDragAndDrop;
