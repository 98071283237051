import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LabelStyled, MessageStyled } from '../Input/TextStyled';
import { Input } from '../index';
import { EMPTY as EMPTY_ERROR } from '../../data/enums/ErrorMessage';
import CustomSelectBtnStyle from './CustomSelectBtnStyle';
import { Menu, Button, Icon } from "../../v4/components";

const propTypes = {
  placeholder: PropTypes.string,
  searchText: PropTypes.string,
  labelContent: PropTypes.string,
  errorMessage: PropTypes.string,
  enableValidation: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  dropDownValueKey: PropTypes.string,
  onChange: PropTypes.func,
  dropDownDisplayKey: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
};

const defaultProps = {
  searchText: '',
  placeholder: '',
  options: [],
  labelContent: '',
  value: '',
  dropDownValueKey: 'name',
  dropDownDisplayKey: 'name',
  enableValidation: false,
  enableErrorDisplay: false,
  errorMessage: EMPTY_ERROR,
  onChange: () => null,
  data: '',
};

class CustomSelectWithBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClose: false,
    };
  }

  getValidState = () => {
    const { data } = this.props;
    return data.Customer.name !== '';
  };

  getDropDownListForUserInput = () => {
    const { dropDownDisplayKey, options, data } = this.props;

    const validDropDownCriteria = el => {
      const searchCriteria = el[dropDownDisplayKey].toLowerCase().includes(data.Customer.name.toLowerCase());

      return searchCriteria;
    };
    const searchList = options.filter(validDropDownCriteria) || [];

    return searchList;
  };

  openMenu = () => {
    this.setState({ onClose: false });
  };

  render() {
    const {
      labelContent,
      options,
      placeholder,
      name,
      enableValidation,
      enableErrorDisplay,
      iconName,
      errorMessage,
      disabled,
      handleIconClick,
      dropDownDisplayKey,
      data,
      onChange,
      handleInputChange,
    } = this.props;
    const { onClose } = this.state;
    const valid = this.getValidState();
    const updatedDropDownList = this.getDropDownListForUserInput();
    const displayError = enableValidation && enableErrorDisplay && !valid;
    const menuHeader = (
      <CustomSelectBtnStyle>
        {labelContent !== undefined ? <LabelStyled>{labelContent}</LabelStyled> : ''}
        <Input
          name={name}
          type="text"
          value={data.Customer.name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={event => {
            handleInputChange(event, 'Customer', ['Customer', 'name']);
            this.openMenu();
          }}
        />
        {options.length > 0 && (
          <span className="dropdown">
            <Icon iconName="caret-bottom" />
          </span>
        )}
      </CustomSelectBtnStyle>
    );

    return (
      <CustomSelectBtnStyle>
        <div className="customselect-pop-up">
          <Menu
            block
            header={menuHeader}
            hideOnClick
            disabled={disabled}
            disableBody={disabled}
            onClose={onClose}
            openMenu={this.openMenu}
          >
            {options.length > 0 && (
              <>
                <div className="autocomplete ">
                  {updatedDropDownList.map(dropDown => (
                    <div
                      onClick={() => {
                        onChange(dropDown);
                        this.setState({ onClose: true });
                      }}
                    >
                      <span>{dropDown[dropDownDisplayKey]}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="create-btn">
              <Button small primary onClick={e => handleIconClick(e)}>
                Create
              </Button>
            </div>
          </Menu>
          {displayError && (
            <MessageStyled>
              {iconName !== undefined ? <Icon iconName={iconName} /> : ''}
              <span className="error-message">
                <Icon iconName="exclamation-full" />
                {errorMessage}
              </span>
            </MessageStyled>
          )}
        </div>
      </CustomSelectBtnStyle>
    );
  }
}

CustomSelectWithBtn.propTypes = propTypes;

CustomSelectWithBtn.defaultProps = defaultProps;

export default CustomSelectWithBtn;
