import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { TableHeader, TableBody } from './tableConfig';
import { Pagination } from '../../../../v4/components';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { dataProps, paginationProps } from '../../../../data/enums/Component';

const propTypes = {
  data: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func.isRequired,
  onStatusChanges: PropTypes.func.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const View = ({ ...props }) => {
  const { tableConfig, pagination, onPageChange, data, onStatusChanges, permission, } = props;

  return (
    <Fragment>
      <div className='table-wrap'>
        <table>
          {TableHeader(tableConfig)}
          <tbody>
            {data.list.map((outstandings) =>
              TableBody({
                data: outstandings,
                tableConfig,
                onStatusChanges,
                permission,
              }),
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

export default withErrorAndLoadingScreen(View);
