export const PAGE_TO_DISPLAY = 3;

export const DEFAULT_SELECT_OPTION = 50;

export const SELECT_OPTIONS = [
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
  {
    label: '150',
    value: 150,
  },
];

export const DEFAULT_PAGINATION_OPTION = {
  page: 1,
  limit: 50,
};
