import styled from 'styled-components';

const ChartWrap = styled.div`
  a {
    text-decoration: none;
    color: #272833;
  }
  .sales-order-li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      width: 32.6%;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
      svg {
        .value:nth-child(2) {
          font-size: 21px !important;
        }
      }
    }
    svg {
      width: 100% !important;
      height: 50px;
    }
  }
  .callage-height {
    min-height: 287px;
  }

  .chart-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    .donutchart {
      display: flex;
      align-items: center;
    }
    .donutlegend {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 4px;
      .reference {
        cursor: pointer;
      }
    }
  }
`;

export default ChartWrap;
