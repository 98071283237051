import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, CustomSelect
} from '../../../components';
import withLoading from '../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  parent: PropTypes.instanceOf(Array),
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  data: {
    name: '',
    parentId: 0,
  },
  refsObj: {},
  parent: [],
  handleDropDownChange: () => null,
};

const WarehouseLevelsForm = ({ ...props }) => {
  const { data, enableErrorDisplay, handleInputChange, refsObj, parent, handleDropDownChange, type, lastParent } =
    props;
  const firstCreate = lastParent[0] !== undefined;
  return (
    <Form>
      <div className={`${firstCreate ? ' ' : 'pad-b-24 '} form-upper`}>
        <Input
          ref={(ref) => (refsObj.title = ref)}
          value={data.title}
          name='title'
          type='text'
          placeholder='Title'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event)}
          labelContent='Title'
        />
        <Input
          ref={(ref) => (refsObj.levelIndication = ref)}
          value={data.levelIndication}
          name='levelIndication'
          type='text'
          placeholder='Level Indication'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event)}
          labelContent='Level Indication (Letters or Numbers)'
        />
        {type === EVENT_OPERATION.CREATE && firstCreate ? (
          <CustomSelect
            ref={(ref) => {
              refsObj.parentId = ref;
              return null;
            }}
            clearable={false}
            className='custom-select'
            placeholder='Select Parent Level'
            getOptionLabel={({ title }) => title}
            getOptionValue={({ id }) => id}
            options={lastParent}
            enableValiation
            enableErrorDisplay={enableErrorDisplay}
            value={parent.filter(({ id }) => id === data.parentId)}
            onChange={(e) => handleDropDownChange(e.id, ['parentId'])}
            labelContent='Parent Level'
          />
        ) : null}
      </div>
      <div className='form-lower'>
        <div className='placeholder' data-placeholder='(in Meters)'>
          <Input
            ref={(ref) => (refsObj.dimensionLength = ref)}
            value={data.dimensionLength}
            name='dimensionLength'
            type='number'
            placeholder='Enter Length'
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={(event) => handleInputChange(event)}
            labelContent='Length'
          />
        </div>
        <div className='placeholder' data-placeholder='(in Meters)'>
          <Input
            ref={(ref) => (refsObj.dimensionWidth = ref)}
            value={data.dimensionWidth}
            name='dimensionWidth'
            type='number'
            placeholder='Enter Width'
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={(event) => handleInputChange(event)}
            labelContent='Width'
          />
        </div>
        <div className='placeholder' data-placeholder='(in Meters)'>
          <Input
            ref={(ref) => (refsObj.dimensionHeight = ref)}
            value={data.dimensionHeight}
            name='dimensionHeight'
            type='number'
            placeholder='Enter Height'
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={(event) => handleInputChange(event)}
            labelContent='Height'
          />
        </div>
      </div>
    </Form>
  );
};

WarehouseLevelsForm.propTypes = propTypes;

WarehouseLevelsForm.defaultProps = defaultProps;

const WarehouseLevelsFormViewWithErrorAndLoading = withLoading(WarehouseLevelsForm);

export default WarehouseLevelsFormViewWithErrorAndLoading;
