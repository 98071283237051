import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import {
  Badge, tableData, menuAction
} from '../../../../v4/components';
import { dataProps } from '../../../../data/enums/Component';

const propTypes = {
  data: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  onStatusChanges: PropTypes.func.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
};

const labelMappings = {
  outlet: 'outlet',
  assignedTo: 'assignedTo',
  amount: 'amount',
  overDue: 'overDue',
  status: 'status',
  distributor: 'distributor',
  route: 'route',
  actionHolders: 'actionHolders',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.assignedTo,
    title: 'Assigned To',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.amount,
    title: 'Amount',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.overDue,
    title: 'Over Due',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.status,
    title: 'Status',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.actionHolders,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'In Progress',
    type: EVENT_OPERATION.IN_PROGRESS,
  },
  {
    title: 'Stop',
    type: EVENT_OPERATION.STOP,
  },
  {
    title: 'Black List',
    type: EVENT_OPERATION.BLACKLIST,
  },
];

const TableHeader = (tableConfig) => (
  <thead className=''>
    <tr>{tableConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const returnTableBody = (data, onStatusChanges, permission) => {
  const table = {};
  table[labelMappings.outlet] = <td key={`outstandings-${data.outletId}-outlet`}>{data.title}</td>;
  table[labelMappings.assignedTo] = (
    <td key={`outstandings-${data.outletId}-dse`}>{data.route && data.route.user ? data.route.user.fullName : ''}</td>
  );
  table[labelMappings.route] = (
    <td key={`outstandings-${data.outletId}-route`}>{data.route ? data.route.title : ''}</td>
  );
  table[labelMappings.amount] = (
    <td key={`outstandings-${data.outletId}-amount`}>{data.outstanding ? fixedFloatAndCommas(data.outstanding) : 0}</td>
  );
  table[labelMappings.overDue] = (
    <td key={`outstandings-${data.outletId}-overDue`}>
      {data.creditDays
        ? `${Math.sign(data.creditDays) === -1 ? '' : '+'}${fixedFloatAndCommas(data.creditDays)} days`
        : 'N/A'}
    </td>
  );
  table[labelMappings.status] = (
    <td key={`outstandings-${data.outletId}-status`}>
      {data.transactionStatus && (
        <span className={data.transactionStatus.toLowerCase()}>
          <span className='badge_border'>
            <Badge light>{data.transactionStatus.replace('_', ' ')}</Badge>
          </span>
        </span>
      )}
    </td>
  );
  table[labelMappings.distributor] = <td key={`outstandings-${data.id}-distributor`}>{data.distributor ? data.distributor.title : data.distributorTitle}</td>;
  table[labelMappings.actionHolders] = (
    <td
      key={`outstandings-${data.id}-action`}
      className='text-right simple-popup-actions'
      onClick={(e) => e.stopPropagation()}
    >
      {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onStatusChanges, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({ data, tableConfig, onStatusChanges, permission }) => {
  const tableBody = returnTableBody(data, onStatusChanges, permission);
  return (
    <tr key={`productivity-${data.id}-details`}>
      {tableConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = propTypes;

TableBody.defaultProps = defaultProps;

export {
  TableHeader, TableBody, labelConfig
};
