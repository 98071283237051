import { numberPresentor } from '../../../../utils/conversion';
import { tableData, Icon } from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';

const labelMappings = {
  subd: 'subd',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.subd,
    title: 'Sub D',
    show: true,
  },
];

const returnTableBody = (data, handleIconClick, displayEdit) => {
  const table = {};
  table[labelMappings.subd] = <td key={`${data.id}-subd`}>{data.title}</td>;

  return table;
};
const TableHeader = () => (
  <thead>
    <tr>{labelConfig.map(label => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const TableBody = ({ data, onTableBodyClick, handleIconClick, displayEdit }) => {
  const tableBody = returnTableBody(data, handleIconClick, displayEdit);

  return (
    <tr key={data.distributorId} className="cursor-pointer" onClick={() => onTableBodyClick(data)}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export { TableHeader, TableBody };
