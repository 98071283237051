import gql from 'graphql-tag';
import client from '../../apiClient/apollo';
import { query as userRoleQuery } from '../../views/configuration/user/userRole/API';

const query = gql`
  query getUser {
    userLabel {
      id
      stl
      asm
      rsm
    }
  }
`;

const userLabel = {
  id: '1',
  stl: 'STL_NEW',
  asm: 'ASM_NEW',
  rsm: 'RSM_NEW',
  __typename: 'UserLabel',
};

/** const userLabelList = [
 {
    id: '2',
    key: 'asm',
    value: 'ASM_NEW',
    __typename: 'UserLabel',
  },
 {
    id: '3',
    key: 'rsm',
    value: 'RSM',
    __typename: 'UserLabel',
  },
 ]; */

const insertUserLabel = () => {
  client.writeQuery({
    query,
    data: {
      userLabel,
    },
  });
};

const getUserRoleFromServer = async () => {
  const userRole = await client
    .query({
      query: userRoleQuery.getUserGroupList,
    })
    .then((response) => response);
  // TODO insert user Role into the db that can be accessed later
  return userRole;
};

const getUserRole = async () => {
  const userRole = await client
    .query({
      query: userRoleQuery.getUserRoleList,
    })
    .then((response) => response);

  return userRole;
};

const getUserLabel = () => client.readQuery({ query });

export {
  insertUserLabel, getUserLabel, getUserRoleFromServer, getUserRole
};
