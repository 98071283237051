import styled from 'styled-components';

export const PanelStyled = styled.div`
  padding: 16px 48px 17px;
  & > div {
    margin-top: unset;
  }

  h2 {
    margin: 0px;
    font-size: 24px;
    color: rgb(39, 40, 51);
    line-height: 32px;
    display: flex;
    text-transform: capitalize;
    font-weight: 600;
  }

  .filter-wrap {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
`;


export const TwoFactorContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  width: 100%;
  height: calc(100vh - 66px);
  overflow: scroll;
  background: rgb(247, 248, 249);

  input {
    background: rgb(241, 242, 245) !important;
    color: rgb(3, 9, 19) !important;
  }

  @media (min-width: 1920px) {
    .wrapper {
      width: 40% !important;
    }
  }

  .wrapper {
    width: 50%;
    background: #ffffff;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    margin-top: 48px;
  }

  .enter-code-wrapper {
    width: 45%;
    background: #ffffff;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding-top: 48px;
    margin-top: 48px;
    /* font-family: SF Pro Text; */
    padding: 48px;

    .header {
      display: flex;
      color: var(--dark-default);

      .security-logo {
        width: 103px;
        height: 120px;
      }

      .text-messages {
        display: flex;
        flex-direction: column;
        padding-left: 32px;

        .header-text {
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          margin: unset;
        }
        .reset-device {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          margin-top: 16px;
          margin-bottom: unset;
        }
      }
    }

    .code-body {
      padding-top: 48px;
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 6px;
      }

      .submit-button {
        width: 100%;
      }
    }
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    padding-left: 48px;
    padding-right: 48px;
    text-align: left;
    margin: unset;
  }

  .list-container {
    padding: 24px 48px 24px 63px;
    margin: unset;
  }

  .list-items {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B6C7E;
  }

  .image-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-left: 48px;
    padding-right: 48px;

    .two-fa-qr {
      width: 226px;
      height: 226px;
    }

    .form-input {
      width: 282px;

      label {
        margin-bottom: 6px;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: end;
    padding: 16px 24px 16px 16px;
    border-top: 1px solid var(--secondary-l3);
  }
`;
