import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-flexbox-grid';
import { Input } from '../../../../../components';
import { Col } from '../../../../../components/Grid';
import { normalPresentor } from '../../../../../utils/date';
import { dataProps } from '../../../../../data/enums/Component';
import { INVALID } from '../../../../../data/enums/ErrorMessage';
import { menuAction } from '../../../../../utils/paginatedListUtils';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import {
  Icon, Button, CheckBox
} from '../../../../../v4/components';

const propTypes = {
  data: dataProps.value,
  onIconClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onButtonClick: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  skuLines: PropTypes.instanceOf(Array),
  breadCrumb: PropTypes.instanceOf(Array),
  checkedList: PropTypes.instanceOf(Array),
  viewType: PropTypes.string,
  onPrimaryCheckBoxClick: PropTypes.func,
  checkBoxStatus: PropTypes.func,
  onSecondaryCheckBoxClick: PropTypes.func,
  validSkuLength: PropTypes.number,
  invoiceNumberStatus: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  priceDetails: PropTypes.instanceOf(Object),
  onInputChange: PropTypes.func,
};

const defaultProps = {
  checkedList: [],
  breadCrumb: [],
  onIconClick: () => null,
  onPageChange: () => null,
  onButtonClick: () => null,
  onCheckBoxClick: () => null,
  onSecondaryCheckBoxClick: () => null,
  data: dataProps.defaultValue,
  skuLines: [],
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  viewType: '',
  onPrimaryCheckBoxClick: () => null,
  checkBoxStatus: () => null,
  validSkuLength: null,
  invoiceNumberStatus: false,
  refsObj: {},
  enableErrorDisplay: false,
  priceDetails: {},
  onInputChange: () => null,
};

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const TableView = ({ ...props }) => {
  const {
    viewType,
    skuLines,
    onIconClick,
    checkedList,
    validSkuLength,
    checkBoxStatus,
    invoiceNumberStatus,
    onPrimaryCheckBoxClick,
    onSecondaryCheckBoxClick,
    exciseInLine
  } = props;

  return (
    <Fragment>
      {(checkedList.length > 0 && skuLines.length > 0) && (
        <div className='selected-status'>
          <div className='content-center'>
            <div>
              <CheckBox checked disabled />
              <p className='text'>
                <span>{checkedList.length}</span>


                of
                <span>{skuLines.length}</span>


                items selected
              </p>
            </div>
            <div className='del-btn' onClick={() => null}>
              <Icon iconName='cross' />
            </div>
          </div>
        </div>
      )}
      <div className='table-wrap'>
        <table>
          <thead>
            <tr>
              {invoiceNumberStatus && skuLines.length
                && (
                  <th>
                    <CheckBox
                      checked={validSkuLength === checkedList.length}
                      onCheckBoxClick={(e) => onPrimaryCheckBoxClick(e)}
                      disabled={skuLines?.filter((a)=>a?.isBlocked)?.length>0}
                    />
                  </th>
                )}
              <th>S.No.</th>
              <th>SKU</th>
              <th>Batch</th>
              <th className='text-center'>MFG Date</th>
              <th className='text-center'>EXP Date</th>
              <th className='text-right'>Quantity</th>
              <th className='text-right'>Sellable</th>
              <th className='text-right'>Damage</th>
              <th className='text-right'>Shortage</th>
              {!invoiceNumberStatus && (<th className='text-right'>Expiry</th>)}
              <th className='text-right'>Rate</th>
              <th className='text-right'>Amount</th>
              <th className='text-right'>Discount</th>
              {(exciseInLine) && <th className='text-right'>Excise</th>}
              <th className='text-right'>Net Amount</th>
              {viewType !== EVENT_OPERATION.DETAILS && <th />}
            </tr>
          </thead>
          <tbody>
            {
              skuLines.map((order, index) => (
                <Fragment key={index}>
                  <tr key={order.id} className={order.isBlocked?'audit-blocked':''}>
                    {invoiceNumberStatus && (
                      <td>
                        <CheckBox
                          checked={checkBoxStatus(order.id)}
                          onCheckBoxClick={(e) => onSecondaryCheckBoxClick(order)}
                          disabled={order.isBlocked}
                        />
                      </td>
                    )}
                    <td>{index + 1}</td>
                    <td>{order.SKU.title}</td>
                    <td>
                      {order.SkuBatch
                        ? order.SkuBatch.batchName
                        : null}
                    </td>
                    <td className='text-center'>
                      {order.SkuBatch
                        ? ((normalPresentor(order.SkuBatch.manufactureDate) === 'Invalid date')
                          ? '-' : normalPresentor(order.SkuBatch.manufactureDate)) : null}
                    </td>

                    <td className='text-center'>
                      {order.SkuBatch
                        ? ((normalPresentor(order.SkuBatch.expiryDate) === 'Invalid date')
                          ? '-' : normalPresentor(order.SkuBatch.expiryDate)) : null}
                    </td>
                    <td className='text-right'>{order.updatedQuantity}</td>
                    <td className='text-right'>{order.sellable}</td>
                    <td className='text-right'>{order.damages}</td>
                    <td className='text-right'>{order.shortages}</td>
                    {!invoiceNumberStatus && (<td className='text-right'>{order.expiry}</td>)}
                    <td className='text-right'>{order.updatedPriceDetails.rate}</td>
                    <td
                      className='text-right'
                    >
                      {fixedFloatAndCommas(order.updatedPriceDetails.amount)}
                    </td>
                    <td
                      className='text-right'
                    >
                      {fixedFloatAndCommas(order.updatedPriceDetails.discount)}
                    </td>
                    {(exciseInLine)&&<td
                      className='text-right'
                    >
                      {fixedFloatAndCommas(order.updatedPriceDetails.exciseAmount)}
                    </td>}
                    <td
                      className='text-right'
                    >
                      {fixedFloatAndCommas(order.updatedPriceDetails.netAmount)}
                    </td>
                    {viewType !== EVENT_OPERATION.DETAILS && (
                      <td className={`text-right simple-popup-actions ${order?.isBlocked?'disabled':''}`} onClick={(e) => e.stopPropagation()}>
                        {menuAction(menuConfigList, { menuIcon: 'ellipsis-v' },
                          onIconClick, order, { update: true, delete: !invoiceNumberStatus })}
                      </td>
                    )}
                  </tr>
                </Fragment>

              ))}
          </tbody>
        </table>
        {
          viewType !== EVENT_OPERATION.DETAILS && !invoiceNumberStatus && (
            <div className='grn-sku-add'>
              <Button
                iconBtnSmall
                secondary
                iconName='plus'
                onClick={() => onIconClick(EVENT_OPERATION.CREATE, {})}
              />
            </div>
          )
        }
      </div>
    </Fragment>
  );
};

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithErrorAndLoading = withErrorAndLoadingScreen(TableView);

const TotalView = ({ ...props }) => {
  const {
    refsObj,
    enableErrorDisplay,
    priceDetails,
    onInputChange,
    invoiceNumberStatus,
    viewType,
  } = props;

  return (
    <Fragment>
      <div className='grn-sku-update'>
        <div className='grn-sku-update-inner'>
          <Row>
            <Col md={6}>
              <span>Sub Total</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.subTotal)}
              </div>
            </Col>
            <Col md={6} className='mt-5'>
              <span>Bill Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name='billDiscount'
                type='number'
                enableValidation
                rule='isFloat'
                ref={(ref) => refsObj.billDiscount = ref}
                argument={{
                  min: 0,
                  max: priceDetails.subTotal - priceDetails.tradeDiscount,
                }}
                value={priceDetails.billDiscount}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => onInputChange(event)}
                disabled={invoiceNumberStatus || (viewType === EVENT_OPERATION.DETAILS)}
                errorMessage={INVALID}
                decimalWithPrecedingZero
              />
            </Col>
            <Col md={6} className='mt-5'>
              <span>Trade Discount</span>
            </Col>
            <Col md={6}>
              <Input
                name='tradeDiscount'
                type='number'
                enableValidation
                rule='isFloat'
                argument={{
                  min: 0,
                  max: priceDetails.subTotal - priceDetails.billDiscount,
                }}
                ref={(ref) => refsObj.tradeDiscount = ref}
                value={priceDetails.tradeDiscount}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => onInputChange(event)}
                disabled={invoiceNumberStatus || (viewType === EVENT_OPERATION.DETAILS)}
                errorMessage={INVALID}
                decimalWithPrecedingZero
              />
            </Col>
            <Col md={6}>
              <span>Taxable Amount</span>
            </Col>
            <Col md={6}>
              <div>
                {' '}
                {fixedFloatAndCommas(priceDetails.taxableAmount)}
                {' '}
              </div>
            </Col>
            <Col md={6}>
              <span>VAT</span>
            </Col>
            <Col md={6}>
              <div>
                {fixedFloatAndCommas(priceDetails.taxAmount)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className='grn-sku-update-total'>
        <span>Total</span>
        <div>
          {fixedFloatAndCommas(priceDetails.total)}
        </div>
      </div>
    </Fragment>
  );
};

export { TableViewWithErrorAndLoading as TableView, TotalView };

TotalView.propTypes = propTypes;

TotalView.defaultProps = defaultProps;

export default TableViewWithErrorAndLoading;
