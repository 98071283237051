import { compose } from 'recompose';
import DistributionCoverage from './DistributionCoverage';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedDistributionCoverage = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(DistributionCoverage);

export default ComposedDistributionCoverage;
