import gql from 'graphql-tag';

const GET_STOCK_COUNT_LIST = gql`
query GetStockCountList($limit: Int, $offset: Int, $filter: FilterInput,$stockId: Int) {
  getStockCountList(limit: $limit, offset: $offset, filter: $filter,stockId: $stockId) {
    count
    rows {
      assigned_to
      assigned_to_code
      count_skus
      frequency_details {
        dayOfWeek
        dayOfMonth
      }
      frequency_type
      id
      title
    }
  }
}
`;

const query = {
  getStockCountList: GET_STOCK_COUNT_LIST,
};

export { query, GET_STOCK_COUNT_LIST };
