import styled from 'styled-components';

const DashWrapper = styled.div`
  padding: 48px;
  height: 100%;
  overflow: auto;
  .pointer {
    cursor:pointer;
  }
  .row {
    margin-left: -12px;
    margin-right: -12px;
    #monthly-detail .custom-display > div ,
    a{
      cursor:unset !important;
    }
    &.chart{
      .callage{
        a{
          cursor:pointer !important;
        }
      }
    }
    .user-detail{
      padding: 16px 24px;
    }

    &.user-status {
      .col-lg-4 {
        &:last-child {
          cursor:pointer !important
        }
      }
    }
  }
  .chart .custom-display{
    min-height:430px;
    .sales-order-li{
      justify-content:initial;
    }
  }
  .donutlegend .reference {
    cursor: unset !important;
}
.callage-height  .donutlegend>div {
  cursor: pointer !important;
}
 &.tas .callage-height {
    min-height: 340px;
  }
  &.tas .greet h1{
    height: 26px;
  }
  table caption{
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    text-transform: uppercase;
  }
  .dse-table-wrap{
    table{
      tbody,
      tfoot,
      thead{
        th,td{
          .user-name{
            padding-top:2px !important;
            span{
              position: relative;
              top: 2px;
            }
          }
          &.dse-disabled {
            .inactive,
            .user-image{
              opacity:.3;
            }
            span{
          color: #aeb0c1;
            }
          }
          &.schedule{
            text-align:center !important;
          }
        }
      }
    }
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    padding-left: 12px;
    padding-right: 12px;
  }
  #monthly-detail {
    .custom-display {
      min-height: 120px;
      & > div {
        &:nth-child(3),
        &:nth-child(2),
        &:first-child {
          cursor: pointer;
        }
      }
    }
  }
`;

export default DashWrapper;
