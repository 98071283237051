import React from 'react';
import {
  Badge, Button, CheckBox
} from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { contactVerified } from '../../../common/HelperFunctions';

const SelectedOutlet = (props) => {
  const { data, removedList, showMapView, toggleMapView, selectedOutlet, onAddOutlet, onAddCallPlan, onRemoveSelected, onPrimaryCheckboxClick, onSecondaryCheckboxClick } = props;
  return (
    <div className='selected-outlet'>
      <div className='title'>
      SELECTED OUTLETS
        <Badge success label={selectedOutlet.length}/>
        <div className='view-btns'>
          <Button
            secondary
            iconBtnSmall
            iconName='list'
            className='table-view-ico'
            onClick={() => toggleMapView(false)}
          />
          {/* <Button className='map-view-ico' secondary iconBtnSmall onClick={() => toggleMapView(true)}>
            <img alt='map-view' src='/image/icons/map-view.svg' />
          </Button> */}
        </div>
      </div>
      {removedList.length !== 0 && (
        <div className='outlet-content'>
          <div className='outlet-title'>
            <CheckBox checked={selectedOutlet.length === removedList.length}
              onCheckBoxClick={(e) => onPrimaryCheckboxClick(e,'remove')}
              labelText={`${removedList.length} Selected`}/>
          </div>
          <Button primary onClick={() => onRemoveSelected()}>
                Remove Selected
          </Button>
        </div>
      )}
      <div className='outlet-section'>
        {!showMapView ?(selectedOutlet.map((list)=>
          <div className='outlet-content'>
            <div className='outlet-title'>
              <CheckBox checked={removedList.includes(list)} onCheckBoxClick={() => onSecondaryCheckboxClick(list,'remove')}/>
              <div className='title-content'>
                <p>{list.title}
                  {list.isverified!==null&&<img src={contactVerified(list.isverified)} alt='V' />}
                </p>
                <span className='channel-category'>{`${list.channel}/${list.category}`}</span>
                <span className='reason'>
                  {list.reason}
                </span>
              </div>
            </div>
            <Button small iconBtnSmall disabled={removedList.filter((a)=>data.list.includes(a)).length>0} onClick={() => onAddOutlet(list,'remove')}>
              <img src='/image/icons/remove.svg' />
            </Button>
          </div>)):('')
        }
      </div>
      {selectedOutlet.length>0 &&(
        <div className='call-plan'>
          <Button primary small onClick={() => onAddCallPlan(EVENT_OPERATION.CREATE)}>Add to Call Plan</Button>
        </div>
      )}
    </div>
  );
};


export default SelectedOutlet;
