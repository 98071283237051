import styled from 'styled-components';

const GRNStyled = styled.div`
  .grn {
    &__create {
      &--image {
        .config-panel-header {
          display: inline-block;
          margin-bottom: 4px;
          color: #272833;
          font-size: 14px;
          font-weight: 600;
        }
        .grnImage {
          display: flex;
          flex-wrap: wrap;
          .image-holder {
            width: 40px;
            height: 40px;
            line-height: 33px;
            text-align: center;
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid #cdced9;
            svg {
              height: 20px;
              width: 20px;
              color: #6b6c7e;
            }
          }
          .attachment-item {
            order: -1;
            margin: 0;
            margin-right: 10px;
            position: relative;
            top: 2px;
            img {
              margin: 0;
            }
          }
        }
      }
    }
  }
  &.grn__create .create {
    .custom-file-upload-wrap {
      overflow: unset;
    }
    .attachment-item {
      margin-top: 24px;
    }
    .custom-file-upload {
      width: 40px;
      height: 40px;
      line-height: 16px;
      text-align: center;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid #cdced9;
      position: relative;
      top: 2px;
      margin-left: -10px;
      svg {
        height: 20px;
        width: 20px;
        color: #6b6c7e;
      }
    }
  }
  .audit-blocked {
    &:after {
      content: ${(props) => props.isBlocked?'\'Audit is still pending for some SKUs of this bill.\'': props.inboundPending?'\'Inbound of this bill is not completed.\'':''};
      top: 130%;
      left: unset;
      right: 0;
    }
  }
`;
export default GRNStyled;
