import styled from 'styled-components';

const AttendanceStyled = styled.div`
  .table-wrap {
    td {
      white-space: nowrap;
      &.location-td {
        img {
          margin-right: 3px;
          height: 16px;
          width: 16px;
          cursor: pointer;
          position: relative;
          top: 2px;
        }
        span {
          position: relative;
          top: -2px;
        }
      }
      svg.icon {
        cursor: pointer;
      }
    }
  }
  .modal-wrapper {
    .badge_border {
      position: relative;
      top: -2px;
      .light {
        min-width: unset;
      }
    }
    .read {
      max-width: 743px;
      max-width: unset;
      width: 59%;
      .with-out-padding {
        border-bottom: 0;
      }
    }
    .update {
      max-width: 580px;
      max-width: unset;
      width: 46%;
    }
  }
  @media only screen and (max-width: 980px) {
    .update,
    .read {
      width: 80%;
    }
  }
`;
export default AttendanceStyled;
