import styled from 'styled-components';

const CreateSKUFormStyled = styled.div`

    .scope{
      position:relative
      left:58%   
        &>span{
          position:absolute
          left:50%
          width:523px
          height:56px
          display:flex;
          align-items:center;
          padding:18px;
          border:1px solid rgba(46,90,172,0.4);
          border-radius:4px;
          background-color:#EEF2FA;
          color:#2E5AAC;
          font-weight:400;
          font-size:14px;
          line-height:21px;
          b{
            font-weight:600;
            margin-right:4px;
          }
          svg{
            height:14px;
            width:14px;
            fill:#2E5AAC;
            margin-right:8px;
          
        }
        }
      }
    }
    .form-input-wrap{
      .line{
        display:flex;
        flex-wrap:wrap;

        label {
          font-size: 14px;
          line-height: 21px;
          color: #272833;
          font-weight: 500;
          width: calc(48.57% - 10px);
          margin-bottom: 4px;
          margin-right:3px;
        
        }
        .line-checkbox-list{
          display:flex;
          flex-direction:column;
          flex:1;
          margin-left:16px;
        }
      }
    }
  

`;

export default CreateSKUFormStyled;
