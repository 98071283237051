import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { refGenerator } from '../../../utils';

const formConfig = {
  [EVENT_OPERATION.RECONCILED]: {
    title: 'Reconcile Payment - ',
  },
  [EVENT_OPERATION.READ]: {
    title: 'Alias Details - ',
  },
  [EVENT_OPERATION.DISHONORED]: {
    title: 'Dishonor Payment - ',
  },
  [EVENT_OPERATION.DISCARDED]: {
    title: 'Remove Payment',
  },
  [EVENT_OPERATION.UPDATE]: {
    title: 'Update Payment - ',
  },
};

const billingformConfig = {
  [EVENT_OPERATION.RECONCILED]: {
    title: 'Reconcile Payment - ',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['toLedgerId', 'amount', 'fromLedgerId','toCustomerId']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.READ]: {
    title: 'Alias Details - ',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['toLedgerId', 'amount', 'fromLedgerId','toCustomerId']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DISHONORED]: {
    title: 'Dishonor Payment - ',
  },
  [EVENT_OPERATION.DISCARDED]: {
    title: 'Remove Payment',
  },
  [EVENT_OPERATION.UPDATE]: {
    title: 'Update Payment - ',
  },
};

export { formConfig, billingformConfig };
