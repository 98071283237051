import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { getTodaysDate } from '../../../../../utils/date';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import withLoading from '../../../../../utils/composition/withLoading';
import { Form, Input, CustomSelect } from '../../../../../components/index';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import SingleDatePicker from '../../../../../components/DatePicker/SingleDatePicker';
import { meridiemList } from '../config';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const DetailForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    enableErrorDisplay,
    handleInputChange,
    handleDropDownChange,
    dropDownCallBack,
  } = props;
  return (
    <Form className="delivery-form">
      <Row>
        <Col md={6} className="mb-16 delivery-form-wrap">
          <div className="single-date-picker">
            <label>Prefered Delivery Date and Time *</label>
            <SingleDatePicker
              name="deliveryDate"
              date={data.deliveryDate || getTodaysDate()}
              onChange={(name, date) =>
                handleInputChange(
                  {
                    target: {
                      name: 'deliveryDate',
                    },
                    formattedValue: date,
                  },
                  'deliveryDate',
                  ['deliveryDate'],
                )
              }
              minDate={getTodaysDate()}
            />
          </div>
          <Input
            value={data.time}
            name="time"
            type="text"
            rule='matches'
            argument={/^((0?[0-9])|(1[0-9])|(2[0-3])):[0-5][0-9]$/}
            placeholder="00:00"
            labelContent=""
            enableErrorDisplay={data?.time?.length > 0}
            enableValidation
            onChange={(event) => handleInputChange(event)}
            errorMessage={data?.time?.length > 0 ? INVALID : ''}
          />
          <CustomSelect
            options={meridiemList}
            labelContent=""
            className="custom-select"
            placeholder="AM/PM"
            getOptionValue={({ label }) => label}
            getOptionLabel={({ label }) => label}
            onChange={event => handleDropDownChange(event?.label, ['amPm'], dropDownCallBack)}
            value={meridiemList?.filter?.(({ label }) => label === data?.amPm)}
          />
        </Col>

        <Col className="mb-16 notes" md={6}>
          <Input
            value={data.notes}
            name="notes"
            type="text"
            placeholder="Remarks"
            labelContent="Remarks"
            onChange={event => handleInputChange(event, 'notes', ['notes'])}
          />
        </Col>
      </Row>
    </Form>
  );
};

DetailForm.propTypes = propTypes;

DetailForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(DetailForm);

export default LoginViewWithErrorAndLoading;
