import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../../v4/components';
import { PanelStyled } from '../../common/configuration';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  baseId: PropTypes.number,
  handleIconClick: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  handleSearchInput: PropTypes.func.isRequired,
  onLocationDetailsCardClick: PropTypes.func.isRequired,
  locationHierarchyList: PropTypes.instanceOf(Array).isRequired,
  locationHierarchyDetailList: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {
  baseId: null,
};

const getTitleFromLocationHierarchies = (levelList, index) => {
  const object = levelList[index];
  return object && object.title;
};

const View = ({ ...props }) => {
  const {
    onLocationDetailsCardClick,
    handleSearchInput,
    handleIconClick,
    locationHierarchyList,
    locationHierarchyDetailList,
    baseId,
    permission
  } = props;

  return (
    <PanelStyled>
      <div className='card-wrapper'>
        {locationHierarchyDetailList.map(
          (locationDetail, index) =>
            locationDetail.locationHierarchyId !== null && (
              <Card
                selectedId={baseId[locationDetail.locationHierarchyId]}
                data={locationDetail.list}
                handleIconClick={handleIconClick}
                handleSearchInput={handleSearchInput}
                onItemClick={onLocationDetailsCardClick}
                title={getTitleFromLocationHierarchies(locationHierarchyList, index)}
                permission={permission}
              />
            ),
        )}
      </div>
    </PanelStyled>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const WarehouseViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default WarehouseViewWithErrorAndLoading;
