import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withAlert from '../../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  DialogBody, DialogFooter, DialogHeader, Form
} from '../../../../../components';
import { Button } from '../../../../../v4/components';

const propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  element: PropTypes.instanceOf(Object),
};

const defaultProps = {
  element: {},
  type: EVENT_OPERATION.CREATE,
};

class Dialog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { element, onClose, onSubmit, handleRadioBtnClick, type, automatedTarget } = this.props;

    return (
      <div className='modal-wrapper'>
        <div className='modal-inner'>
          <DialogHeader>
            <h2>{type===EVENT_OPERATION.PRIMARY_SECONDARY?'Choose Primary / Secondary':'Create SKU Group'}</h2>
            <Button iconName='times' onClick={onClose}/>
          </DialogHeader>
          <DialogBody>
            <Form>
              {type===EVENT_OPERATION.PRIMARY_SECONDARY ?
                <span>Please choose Primary or Secondary for selected  FSKU - <b>{element.title}</b></span>:
                (<>
                  <div>Are you sure you want to create a Focused SKU
          group?</div>
                  {automatedTarget&&<div className='info-pri-sec'>Please choose Primary or Secondary</div>}
                </>)
              }
              {automatedTarget&&<div className='permission-choose'>
                <label className='radio-list'>
                  <input
                  // key={i}
                    type='radio'
                    value='isPrimary'
                    onChange={(e) => handleRadioBtnClick(e)}
                    checked={element.isPrimary===true}
                  />
                  <span className='text'>Primary</span>
                </label>
                <label className='radio-list'>
                  <input
                  // key={i}
                    type='radio'
                    value='isSecondary'
                    onChange={(e) => handleRadioBtnClick(e)}
                    checked={element.isSecondary===true}
                  />
                  <span className='text'>Secondary</span>
                </label>
              </div>}
            </Form>
          </DialogBody>
          <DialogFooter>
            <>
              <Button secondary onClick={onClose}>
                <span>Cancel</span>
              </Button>
              <Button primary onClick={onSubmit}>
                <span>Ok</span>
              </Button>
            </>
          </DialogFooter>
        </div>
      </div>
    );
  }
}

Dialog.propTypes = propTypes;

Dialog.defaultProps = defaultProps;

export default withAlert()(Dialog);
