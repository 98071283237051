import { compose } from 'recompose';
import {
  query, mutation, APPROVE_SRN, CANCEL_SRN
} from './API';
import Details from './Details';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedSrnPendingDetail = compose(APPROVE_SRN, CANCEL_SRN, withRequestTracker({ query, mutation }))(Details);

export default composedSrnPendingDetail;
