import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PromotionArea from './Area';
import { Col, Row } from '../../../../../components/Grid';
import { PROMOTION_RELATED_TYPE } from '../../../../../data/enums';
import { AutoComplete, CustomSelect } from '../../../../../components';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import {
  Icon, Button, CheckBox
} from '../../../../../v4/components';

const propTypes = {
  scope: PropTypes.instanceOf(Object).isRequired,
  categoryList: PropTypes.instanceOf(Array),
  channelList: PropTypes.instanceOf(Array),
  selectedCategory: PropTypes.instanceOf(Array),
  onAutoCompleteChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  townList: PropTypes.arrayOf(Object),
  subDList: PropTypes.arrayOf(Object),
  relatedType: PropTypes.oneOf([PROMOTION_RELATED_TYPE.SBD, PROMOTION_RELATED_TYPE.FOCUSED_SKU, '', null]),
};

const defaultProps = {
  selectedCategory: [],
  categoryList: [],
  channelList: [],
  relatedType: null,
  townList: [],
  subDList: [],
};

// eslint-disable-next-line consistent-return
const View = ({ ...props }) => {
  const {
    scope,
    categoryList,
    channelList,
    onIconClick,
    onDropDownChange,
    onAutoCompleteChange,
    townList,
    subDList,
    onInputChange,
    territories,
    promotionId,
  } = props;

  const disableChannelCategoryAddition = false;
  // !scope.channelCategoryInfo[scope.channelCategoryInfo.length - 1].channelId;

  // eslint-disable-next-line max-len
  const handleScopeAutoCompleteChange = (index) => (value, fieldName) => onAutoCompleteChange(value, fieldName, index);

  const getCategoryListUnderChannel = (channelId) => categoryList.filter((category) => category.Channel.id === channelId);

  const selectedChannelIdList = scope.channelCategoryInfo.map((channelCategory) => channelCategory.channelId);

  const getDropDownForChannelList = (channelId) =>
    channelList.filter((channel) => channel.id === channelId || !selectedChannelIdList.includes(channel.id));

  return (
    <div className='scope'>
      <Row>
        <Col md={12}>
          {scope.channelCategoryInfo.map((channelCategory, index) => (
            <Fragment key={index}>
              {index > 0 && (
                <div className='config-promotion-panel-header'>
                  <div className='flex m-0'>
                    <Icon
                      iconName='times'
                      primary
                      onClick={() => onIconClick('remove', 'channelCategoryInfo', index)}
                    />
                  </div>
                </div>
              )}
              <div className='config-catalog-wrap'>
                <Row key={`channel-category-${channelCategory.channelId}`}>
                  <Col md={4} className='mb-24'>
                    <CustomSelect
                      enableValidation
                      className='custom-select'
                      placeholder='Channel'
                      labelContent='Channel'
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      options={getDropDownForChannelList(channelCategory.channelId)}
                      onChange={(event) => onDropDownChange(event.id, index, 'channelCategoryInfo')}
                      value={channelList.filter(({ id }) => id === channelCategory.channelId)}
                    />
                  </Col>
                  <Col md={4} className='mb-24'>
                    <AutoComplete
                      name='channelCategoryInfo'
                      labelContent='Category'
                      dropDownList={getCategoryListUnderChannel(channelCategory.channelId)}
                      placeholder='Search'
                      selectedDropDowns={[...channelCategory.categoryIds]}
                      onDropDownSelection={handleScopeAutoCompleteChange(index)}
                    />
                  </Col>
                  {/* <Col md={1}>
                            {
                            index > 0 && (
                              <div className="suggested-autocomplete-list">
                                <span
                                  className="ic-close"
                                  onClick={() => onIconClick
                                    ('remove', 'channelCategoryInfo', index)}
                                >
                                  Close
                                </span>
                              </div>)
                          }
                          </Col> */}
                </Row>
              </div>
            </Fragment>
          ))}
          <Row>
            <Col md={4} className='mt-24'>
              <div className={`filter-btn add ${disableChannelCategoryAddition ? 'disabled' : ''}`}>
                <Button
                  iconBtnSmall
                  secondary
                  iconName='plus'
                  onClick={() => onIconClick('add', 'channelCategoryInfo')}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12} className='mt-24 mb-24'>
          <PromotionArea
            townList={townList}
            subDList={subDList}
            onDropDownChange={onDropDownChange}
            promotionAreas={scope.promotionAreas}
            scope ={scope}
            onAutoCompleteChange={onAutoCompleteChange}
            territories={territories}
            promotionId={promotionId}
            onIconClick={onIconClick}
          />
        </Col>
      </Row>
      <div className='enable-promotions mt-24'>
        <label htmlFor=''>Enable promotions to</label>
        <div className='enable-promotion__checkbox'>
          <CheckBox
            checked={scope.platform.retailer}
            labelText='Retailer'
            onCheckBoxClick={(event) => {
              onInputChange(
                {
                  formattedValue: !!event.target.checked,
                  target: {
                    name: 'retailer',
                    type: 'boolean',
                    value: !!event.target.checked,
                  },
                },
                'platform',
                ['platform', 'retailer'],
              );
            }}
          />
          <CheckBox
            checked={scope.platform.marketplace}
            labelText='Marketplace'
            onCheckBoxClick={(event) => {
              onInputChange(
                {
                  formattedValue: !!event.target.checked,
                  target: {
                    name: 'marketplace',
                    type: 'boolean',
                    value: !!event.target.checked,
                  },
                },
                'platform',
                ['platform', 'marketplace'],
              );
            }}
          />
          <CheckBox
            checked={scope.platform.sales}
            labelText='Sales'
            onCheckBoxClick={(event) => {
              onInputChange(
                {
                  formattedValue: !!event.target.checked,
                  target: {
                    name: 'sales',
                    type: 'boolean',
                    value: !!event.target.checked,
                  },
                },
                'platform',
                ['platform', 'sales'],
              );
            }}
          />
          <CheckBox
            checked={scope.billing_status}
            labelText='Billing SUBDs'
            onCheckBoxClick={(event) => {
              onInputChange(
                {
                  formattedValue: !!event.target.checked,
                  target: {
                    name: 'billing_status',
                    type: 'boolean',
                    value: !!event.target.checked,
                  },
                },
                'billing_status',
                ['billing_status'],
              );
            }}
          />
        </div>
        {scope.outletCount > 0 ? (
          <>
            <div className='enable-promotion__outlets-info' />
            <span>
              <Icon iconName='info-panel-open' />
              <b>Info:</b>
              {`This promotion will be applied to ${scope.outletCount} outlets`}
            </span>
            <div />
          </>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const PromotionDetailViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default PromotionDetailViewWithErrorAndLoading;
