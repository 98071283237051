import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { UPLOAD, DOWNLOAD_REPORT } from '../../../common/GqlAPI';

const GET_BRANDWISE_DISTRIBUTOR_TARGET = gql`
query getbrandwisedistributortarget($buTargetId: Int!, $distributorId: Int!) {
  getTargetDistributorBrandWise(buTargetId: $buTargetId,distributorId: $distributorId) {
    icoSKUConfigType
    brandwisetarget {
      brand_id
      lastmonthtarget
      lastthreemonthtarget
      target
      title
      coverage
    }  
    skuwisetarget {
      brand_id
      sku_id
      sku_title
      currenttarget
      lastmonthtarget
      lastthreemonthtarget
    }
    skufamilywisetarget {
      brand_id
      lastmonthtarget
      lastthreemonthtarget
      sku_family_id
      sku_family_title
      currenttarget
    }
  }
}
`;

const CREATE_BRANDWISE_DISTRIBUTION_TARGET = graphql(
  gql`
  mutation ($input: DistributorTargetInput!, $brandId: Int!) {
    createDistributorTarget(input: $input, brandId: $brandId) {
      icoSKUConfigType
    }
  }
`,
  {
    props: ({ mutate }) => ({
      createDistributorTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  }
)

const query = {
  getBrandwiseDistributorTarget: GET_BRANDWISE_DISTRIBUTOR_TARGET
};

const mutation = {
  createDistributorTarget: CREATE_BRANDWISE_DISTRIBUTION_TARGET,
  upload: UPLOAD,
  downloadReport: DOWNLOAD_REPORT,
};

export {
  query, mutation, CREATE_BRANDWISE_DISTRIBUTION_TARGET
};
