import React from 'react';
import successfulCallMarker from '../../assets/markers/successful-call.svg';
import unsuccessfulCallMarker from '../../assets/markers/unsuccessful-call.svg';
import remainingCallMarker from '../../assets/markers/remaining-call.svg';

const Legend = () => (
  <>
    {/* <span>{markerImage(lastCallMarker)}</span><span>Last Call</span> Might need later */}
    <p>
      <img src={remainingCallMarker} alt='images' />
      <span>Remaining</span>
    </p>
    <p>
      <img src={successfulCallMarker} alt='images' />
      <span>Successful</span>
    </p>
    <p>
      <img src={unsuccessfulCallMarker} alt='images' />
      <span>Unsuccessful</span>
    </p>
  </>
);

export default Legend;
