import styled from 'styled-components';

const TargetDetailWrapper = styled.div`
  .collapsible-body-tr {
    .section-content {
      border: 0;
      .table-wrap-second {
        table thead tr {
          height: 48px;
          th {
            background: #f1f2f5 !important;
            color: #6b6c7e;
            font-weight: 600;
            border-top: 0;
          }
        }
      }
    }
  }
  .filter-wrap {
    display: inline-block;
    margin-right: 30px;
  }

  table {
    tbody {
      tr {
        td {
          &:last-child:not(:first-child) {
            .view {
              margin-right: 40px;
            }
            span {
              cursor: pointer;
              color: #6b6c7e;
              font-size: 14px;
            }

            svg.icon {
              height: 14px;
              width: 14px;
              margin-right: 9px;
            }
          }

          &:first-child {
            border-left: 0;
          }
        }
      }
    }

    .collapsible-body-tr {
      &:hover,
      &:focus {
        background: transparent;
      }

      table {
        border: none;

        tr {
          cursor: default;
          border-bottom: 1px solid #e7e7ed;

          th {
            background: transparent;
            width: auto;
          }

          th,
          td {
            border-bottom: none;

            span {
              cursor: pointer;
            }
          }
        }
      }
    }

    tr {
      th,
      td {
        width: auto;
        white-space: nowrap;
        /* &:nth-last-child(3),
        &:nth-last-child(2),
        &:nth-last-child(1) {
          width: 80px !important;
        } */

        &:first-child {
          width: 36%;
        }

        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(4),
        &:nth-child(3) {
          text-align: right;
          width: 200px;
        }
      }
    }
  }

  table tbody {
    /* for gap only pad */
    .active + .collapsible-body-tr {
      .collapsible-body-td {
        table {
          tbody {
            tr {
              &.active,
              &.inActive {
                td {
                  &:first-child {
                    padding-left: calc(48px + 16px);
                  }
                }
              }
            }

            /* --2step nested */

            .active + .collapsible-body-tr {
              border: 0;

              .collapsible-body-td {
                table {
                  tbody {
                    tr {
                      &.active,
                      &.inActive {
                        td {
                          &:first-child {
                            padding-left: calc(48px + 16px + 16px);
                          }
                        }
                      }
                    }
                    .active + .collapsible-body-tr {
                      .collapsible-body-td {
                        table {
                          tbody {
                            tr {
                              &.active,
                              &.inActive {
                                td {
                                  &:first-child {
                                    padding-left: calc(48px + 16px + 16px + 16px);
                                  }
                                }
                              }
                            }
                            .active + .collapsible-body-tr {
                              .collapsible-body-td {
                                table {
                                  tbody {
                                    tr {
                                      &.active,
                                      &.inActive {
                                        td {
                                          &:first-child {
                                            padding-left: calc(48px + 16px + 16px + 16px + 16px);
                                          }
                                        }
                                      }
                                    }
                                    .active + .collapsible-body-tr {
                                      .collapsible-body-td {
                                        padding: 0 !important;
                                        table {
                                          tbody {
                                            tr {
                                              &.active,
                                              &.inActive {
                                                td {
                                                  &:first-child {
                                                    /* width: 39%; */
                                                    padding-left: calc(48px + 16px + 16px + 16px + 16px + 16px);
                                                  }
                                                }
                                              }
                                            }
                                            .active + .collapsible-body-tr {
                                              .collapsible-body-td {
                                                padding: 0 !important;
                                                table {
                                                  tbody {
                                                    tr {
                                                      &.active,
                                                      &.inActive {
                                                        td {
                                                          &:first-child {
                                                            /* width: 39%; */
                                                            padding-left: calc(
                                                              48px + 16px + 16px + 16px + 16px + 16px + 16px
                                                            ) !important;
                                                          }
                                                        }
                                                      }
                                                    }
                                                    .active + .collapsible-body-tr {
                                                      .collapsible-body-td {
                                                        padding: 0 !important;
                                                        table {
                                                          tbody {
                                                            tr {
                                                              &.active,
                                                              &.inActive {
                                                                td {
                                                                  &:first-child {
                                                                    /* width: 39%; */
                                                                    padding-left: calc(
                                                                      48px + 16px + 16px + 16px + 16px + 16px + 16px +
                                                                        16px
                                                                    ) !important;
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            /* --2step nested  end*/
          }
        }
      }
    }

    /* for gap only pad end */
    span.active-td {
      &::before {
        visibility: hidden !important;
      }
    }
    tr {
      &.active {
        td:first-child > span:before {
          transform: rotate(-90deg);
          transition: all 0.23s ease-in-out;
          border-top-color: #004ad7;
        }
      }

      td:first-child {
        > span {
          position: relative;
          padding-left: 26px;

          &:before {
            content: '';
            width: 0;
            height: 0;
            border-radius: 4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #6b6c7e;
            position: absolute;
            top: 5px;
            left: 0;
            transition: all 0.23s ease-in-out;
          }
        }
      }
    }
  }
`;

export default TargetDetailWrapper;
