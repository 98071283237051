import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RetailerSkuAssortmentView from './View';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { CATALOG_HIERARCHY } from '../../../../common/DomainConfig';
import { crudRequestConfig } from './config';
import { productHierarchyCountMapper } from '../skuAssortment/config';
import { MAX_LIMIT_OFFSET_FILTER } from '../../../../../data/enums/filter';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import AssortmentDetailStyled from '../../../channelAssortment/details/AssortmentDetailStyled';
import { handleFormSubmit, responseInterpreter } from '../../../../../utils/crudResponseProcessor';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getCatalogDetails: PropTypes.func.isRequired,
  distributorGroupId: PropTypes.number.isRequired,
  getRetaileSKUAssortmentSkus: PropTypes.func.isRequired,
  toggleSkuInRetailerAssortment: PropTypes.func.isRequired,
  getAssortmentCountByProductHierarchy: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class RetailerSkuAssortment extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      productGroups: [],
      productHierarchyAssortmentCount: productHierarchyCountMapper({}),
      brands: [],
      serverSKUFamilies: [],
      skuFamilies: [],
      selectedSkus: [],
      brandId: '',
      productGroupId: '',
      searchText: '',
    };
    const serverCall = {
      [EVENT_OPERATION.UPDATE_STATUS]: props.toggleSkuInRetailerAssortment,
    };
    this.onCRUDSuccess = responseInterpreter(this.onMutationSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.handleAPIFailure, crudRequestConfig, serverCall);
  }

  componentDidMount() {
    this.fetchCatalogDetails({
      catalogId: CATALOG_HIERARCHY.PRODUCT_GROUP,
      callBack: this.setProductGroups,
    });
    this.fetchAssortmentCountByProductHierarchy();
  }

  fetchCatalogDetails = ({ catalogId, parentCatalogDetailId, includeSku, callBack }) => {
    const { getCatalogDetails, distributorGroupId } = this.props;

    getCatalogDetails(
      {
        distributorGroupId,
        ...MAX_LIMIT_OFFSET_FILTER,
        catalogId,
        parentCatalogDetailId,
        includeSku,
      },
      {
        handleSuccess: response =>
          callBack(
            response.data.retailerCatalogDetails ? response.data.retailerCatalogDetails.rows : [],
            parentCatalogDetailId,
          ),
        handleError: err => {
          callBack([], parentCatalogDetailId);
          this.handleAPIFailure(err);
        },
      },
    );
  };

  fetchAssortmentCountByProductHierarchy = () => {
    const { distributorGroupId, getAssortmentCountByProductHierarchy } = this.props;
    getAssortmentCountByProductHierarchy(
      { distributorGroupId },
      {
        handleSuccess: response => {
          const { getRetailerSkuAssortedCount = {} } = response.data;
          this.setState({
            productHierarchyAssortmentCount: productHierarchyCountMapper(getRetailerSkuAssortedCount || {}),
          });
        },
      },
    );
  };

  fetchDistributorAssortmentSkus = brandId => {
    const { getRetaileSKUAssortmentSkus, distributorGroupId } = this.props;
    getRetaileSKUAssortmentSkus(
      { distributorGroupId, brandId },
      {
        handleSuccess: response => {
          const { retailerSkuAssortments = {} } = response.data;
          this.setState({
            selectedSkus: [...(retailerSkuAssortments ? retailerSkuAssortments.rows : [])],
          });
        },
        handleError: err => {
          this.setState({ selectedSkus: [] });
          console.log(err);
        },
      },
    );
  };

  getBrands = productGroupId => {
    this.fetchCatalogDetails({
      catalogId: CATALOG_HIERARCHY.BRAND,
      parentCatalogDetailId: productGroupId,
      callBack: this.setBrands,
    });
  };

  getSKUFamilies = brandId => {
    this.fetchDistributorAssortmentSkus(brandId);
    this.fetchCatalogDetails({
      catalogId: CATALOG_HIERARCHY.SKU_FAMILY,
      parentCatalogDetailId: brandId,
      includeSku: true,
      callBack: this.setSKUFamilies,
    });
  };

  setProductGroups = (productGroups = []) => {
    this.setState({ productGroups });
  };

  setBrands = (brands, productGroupId) => {
    this.setState({
      brands,
      productGroupId,
      brandId: '',
      skuFamilies: [],
      serverSKUFamilies: [],
      searchText: '',
    });
  };

  setSKUFamilies = (skuFamilies, brandId) => {
    const filteredSKUFamilies = skuFamilies.filter(pp => pp.skus && pp.skus.length > 0);
    this.setState({
      skuFamilies: filteredSKUFamilies,
      brandId,
      serverSKUFamilies: filteredSKUFamilies,
      searchText: '',
    });
  };

  onMutationSuccess = (type, response, payload) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);

    this.setState(
      {
        productHierarchyAssortmentCount: productHierarchyCountMapper(response.count || {}),
      },
      () => this.handleSwitchChange(payload.status, payload.skuIdList),
    );
  };

  handleSwitchChange = (status, skuIdList) => {
    const { selectedSkus } = this.state;
    if (status) {
      const skusSet = new Set([...selectedSkus, ...skuIdList]);
      this.setState({
        selectedSkus: [...(Array.from(skusSet) || [])],
      });
    } else {
      this.setState({ selectedSkus: selectedSkus.filter(id => !skuIdList.includes(id)) });
    }
  };

  handleSkuSubmit = (status, ids) => {
    const { distributorGroupId } = this.props;
    this.onFormSubmit(EVENT_OPERATION.UPDATE_STATUS, {
      skuIdList: [...ids],
      distributorGroupId,
      status,
    });
  };

  handleAPIFailure = err => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, err);
  };

  handleSearchInput = text => {
    const { serverSKUFamilies } = this.state;
    const regex = new RegExp(text, 'i');
    const bySkus = serverSKUFamilies.filter(i => i.skus.filter(s => s.title.search(regex) > -1).length > 0);
    const bySKUFamilies = serverSKUFamilies.filter(p => p.title.search(regex) > -1);
    const searchSet = new Set([...bySKUFamilies, ...bySkus]);
    this.setState({ skuFamilies: [...(Array.from(searchSet) || [])], searchText: text });
  };

  render() {
    const {
      productGroups,
      brands,
      brandId,
      productGroupId,
      skuFamilies,
      selectedSkus,
      productHierarchyAssortmentCount,
      searchText,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <AssortmentDetailStyled>
        <div className="section-content no-border">
          <RetailerSkuAssortmentView
            brands={brands}
            brandId={brandId}
            searchText={searchText}
            selectedSkus={selectedSkus}
            skuFamilies={skuFamilies}
            productGroups={productGroups}
            productGroupId={productGroupId}
            loading={serverResponseWaiting}
            onSwitchChange={this.handleSkuSubmit}
            onSearchInputChange={this.handleSearchInput}
            onBrandLineClick={this.getSKUFamilies}
            onProductGroupLineClick={this.getBrands}
            assortmentCounts={productHierarchyAssortmentCount}
          />
        </div>
      </AssortmentDetailStyled>
    );
  }
}

RetailerSkuAssortment.propTypes = propTypes;

RetailerSkuAssortment.defaultProps = defaultProps;

export default withAlert()(RetailerSkuAssortment);
