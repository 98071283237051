import { refGenerator } from '../../../../utils';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { FORM_CONFIG } from '../../../../v4/constants/formConfig';
import { configuration } from '../../../../data/enums/Route';

const breadCrumb = [configuration];
const requiredList = ['metric', 'achievementCriteriaType', 'achievementCriteriaValue'];

const achievementCriteriaTypeOptions = [
  {
    id: 1,
    title: 'In %',
    value: 'PERCENTAGE',
  },
  {
    id: 2,
    title: 'In Count',
    value: 'COUNT',
  },
];

const initialDialogState = {
  id: '',
  metric: {
    id: '',
    title: '',
  },
  achievementCriteriaType: achievementCriteriaTypeOptions[0],
  achievementCriteriaValue: 0,
  active: true,
  sequence: '',
};

const crudSuccess = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      ids: [data.id],
      type: Title,
      active: false,
    }),
    responseName: 'toggleState',
  },
  // [EVENT_OPERATION.BULK_DELETE]: {
  //   objectMapper: (data) => ({
  //     ids: data.ids,
  //     type: Title,
  //     active: false,
  //   }),
  //   responseName: 'toggleState',
  // },
};

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Add',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    buttonName: 'Add'
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Edit',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    buttonName: 'Add'
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Confirm',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
  },
};

export {
  breadCrumb as breadCrumbConfig,
  formConfig,
  crudSuccess,
  achievementCriteriaTypeOptions,
  initialDialogState,
};
