import { compose } from 'recompose';
import TwoFactorAuth from './TwoFactorAuth';
import withRequestTracker from '../../utils/composition/withRequestTracker';
import { query, LOG_OUT, VERIFY_QR_CODE, mutation } from './API';

const TwoFactorAuthentication = compose(
  LOG_OUT,
  VERIFY_QR_CODE,
  withRequestTracker({
    mutation,
    query,
  }),
)(TwoFactorAuth);

export default TwoFactorAuthentication;
