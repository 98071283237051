import React, { ChangeEvent, useState } from 'react';
import BulkSelectView from './BulkSelectView';
import DialogConfirmation from '../DialogConfirmation';
import { ALERT_TYPE } from '../../constants/AlertType';
import { CONFIRMATION_CONFIG } from '../../constants/formConfig';
import { EVENT_OPERATION } from '../../constants/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../constants/successMessage';
import { extractObjectFromList } from '../../utils/arrayProcessor';
import withAlert from '../../../utils/composition/withAlert';
import ModalWrapper from '../../../views/common/DialogFormWrapper';
import { handleFormSubmit, responseInterpreter } from '../../../utils/crudResponseProcessor';

const crudRequestConfig = {
  [EVENT_OPERATION.CHANGE_STATUS]: {
    objectMapper: (element: any) => ({
      ids: [...element.data],
      status: element.status,
    }),
    responseName: 'changePaymentStatus',
    message: `${MESSAGE_EVENT_OPERATION.UPDATE_STATUS}`,
  },
};

const BulkSelect = ({ ...props }) => {
  const { data, render, replicate, getCheckedListData, handler, displayAlert, serverResponseWaiting, Buttons, fetchAllData } = props;
  const [checkedList, setCheckedList] = useState<Array<Object>>([]);
  const initialdialog = {
    type: '',
    element: { status: '', data: {} },
  };
  const [dialog, setdialog] = useState(initialdialog);
  const onButtonClick = (status: any) => {
    setdialog({
      type: EVENT_OPERATION.CHANGE_STATUS,
      element: { data: checkedList, status },
    });
  };

  const handleClose = (type: string, element: object) => {
    setCheckedList([]);
    setdialog({ type, element: { status: '', data: {} }, });
  };

  const handleApiFailure = (error: string) => {
    displayAlert(ALERT_TYPE.DANGER, error);
    handleClose('', {});
  };

  const handleSuccessResponse = (type: string, response: any) => {
    if (!handler) {
      displayAlert(ALERT_TYPE.SUCCESS, response.message);
      fetchAllData();
    } else {
      handler(type, response);
      fetchAllData();
      displayAlert(ALERT_TYPE.SUCCESS, response.message);
    }
  };

  const onCRUDSuccess = responseInterpreter(handleSuccessResponse);

  const serverCall = {
    [EVENT_OPERATION.CHANGE_STATUS]: props.changePaymentStatus,
  };

  const onFormSubmit = handleFormSubmit(
    onCRUDSuccess,
    handleApiFailure,
    crudRequestConfig,
    serverCall,
  );


  const handlePrimaryCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const checked = extractObjectFromList(data);
      setCheckedList(checked);
      if (replicate) { getCheckedListData(checked); }
    } else {
      setCheckedList([]);
    }
  };

  const handleSecondaryCheckboxClick = (id: any) => {
    const index = checkedList.indexOf(id);
    if (index > -1) {
      checkedList.splice(index, 1);
      setCheckedList([...checkedList]);
    } else {
      setCheckedList([...checkedList, id]);
    }
  };


  return (
    <>
      {dialog.type && (
        <ModalWrapper
          type={dialog.type}
          dialogData={dialog.element}
          formConfig={CONFIRMATION_CONFIG[dialog.type]}
          onDialogCancel={handleClose}
          onDialogSubmit={onFormSubmit}
          formTitle={dialog.element.status !== EVENT_OPERATION.DISCARDED ? 'Change Status' : 'Remove Payment'}
          renderDialog={() => (
            <>
              {DialogConfirmation(
                dialog.element.status,
                checkedList.length,
              )}
            </>
          )}
        />
      )}
      <BulkSelectView
        checkedListLength={checkedList.length}
        dataLength={data.length}
        onIconClick={onButtonClick}
        Buttons={Buttons}
        loading={serverResponseWaiting}
        children={undefined}
        title={undefined} />
      {render(
        checkedList,
        handlePrimaryCheckboxClick,
        handleSecondaryCheckboxClick,
      )}
    </>
  );
};

export default withAlert()(BulkSelect);