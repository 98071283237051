import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const detailLabelMappings = {
  batch: 'batch',
  quantity: 'quantity',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.batch,
    title: 'Batch',
    show: true,
    align: true,
  },
  {
    id: 2,
    label: detailLabelMappings.quantity,
    title: 'Quantity',
    show: true,
    align: true,
  },
];

const returnDetailTableBody = (data) => {
  const table = {};
  table[detailLabelMappings.batch] = (
    <td key={`${data.SKUBatch.id}-id`} className='text-right'>
      {data.SKUBatch.batchName}
    </td>
  );
  table[detailLabelMappings.quantity] = (
    <td key={`${data.SKUBatch.id}-quantity`} className='text-right'>
      {data.balance}
    </td>
  );

  return table;
};

const DetailTableBody = ({ data }) => {
  const tableBody = returnDetailTableBody(data);

  return <Fragment>{detailsLabelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

DetailTableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

DetailTableBody.defaultProps = {
  data: {},
};

export { DetailTableBody, detailsLabelConfig };
