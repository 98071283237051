import { fileParser } from '../../../utils/parser';
import { FILTER } from '../../../data/enums/filter';
import { getFilterMenu } from '../../../utils/filter';
import { refGenerator } from '../../../utils/refGenerator';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { CONFIRMATION_CONFIG, FORM_CONFIG } from '../../../data/enums/config';
import { salesForce as salesForceRoute } from '../../../data/enums/Route';

const title = 'Target';

const breadCrumb = [salesForceRoute];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      target: element.target,
      duration: {
        year: 2020,
        month: 9,
      },
    }),
    [FORM_CONFIG.TITLE]: `Create ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['target', 'duration']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    buttonName: 'Create',
  },
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id,
      target: element.target,
      duration: {
        year: element.year,
        month: element.month,
      },
    }),
    [FORM_CONFIG.TITLE]: '',
    [EVENT_OPERATION.UPLOAD]: `${EVENT_OPERATION.READ} ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['target', 'duration']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      id: element.id,
      target: element.target,
      duration: {
        year: element.year,
        month: element.month,
      },
    }),
    [FORM_CONFIG.TITLE]: 'Edit',
    [EVENT_OPERATION.UPLOAD]: `${EVENT_OPERATION.UPDATE} ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['target', 'duration']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    buttonName: 'Update',
  },
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.TITLE]: 'File Upload',
    [FORM_CONFIG.MAPPER]: (element) => ({
      name: element.name,
      file: element.file,
    }),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.REPLACE]: {
    [FORM_CONFIG.TITLE]: 'Replace Sales Target',
  },
  ...CONFIRMATION_CONFIG,
};

const getFilterConfig = (filterList) => {
  const labelList = filterList.map((element) => element.LABEL);

  const menu = getFilterMenu(labelList);

  const instance = {};

  filterList.forEach((element) => (instance[element.VALUE] = {}));

  return {
    menu,
    instance,
  };
};

const filter = getFilterConfig([FILTER.SUB_D]);

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({
      target: Number(data.target),
      month: data.duration.month,
      year: data.duration.year,
    }),
    responseName: 'createBuTarget',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      target: Number(data.target),
      year: data.duration.year,
      month: data.duration.month,
    }),
    responseName: 'updateBuTarget',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: data.name,
        },
      }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

export {
  formConfig, crudSuccess, filter as filterConfig, breadCrumb as breadCrumbConfig
};
