const messagePlatform = [
  { title: 'SMS', id: 1, value: 'SMS' },
  { title: 'EMAIL', id: 2, value: 'EMAIL' },
  { title: 'IN_APP_NOTIFICATION', id: 2, value: 'IN_APP_NOTIFICATION' },
];

const periodType = [
  {
    id: 1,
    title: 'AM',
    value: 'AM',
  },
  {
    id: 2,
    title: 'PM',
    value: 'PM',
  },
];

const weekDays = {
  sunday: 7,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

const week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const checkRepeatTime = (repeatedTimes) => {
  if (repeatedTimes.daily) {
    return 'DAILY';
  }
  if (repeatedTimes.weekly) {
    return 'WEEKLY';
  }
  if (repeatedTimes.monthly) {
    return 'MONTHLY';
  }
  return null;
};

const NOTIFICATION_ALERT_MESSAGE = {
  PLATFORM: 'Select at least one platform',
  END_DATE_GREATER: 'End date must be greater than start date',
  START_DATE_EARLIER: 'Start date must be earlier than end date',
  REPEAT_DAYS: 'Select at least one repeat days to send an alert',
  HOUR_ALERT: 'Please enter HH between 0 to 12',
  MINUTE_ALERT: 'Please enter MM between 0 to 59',
};

const PLATFORM_TYPE = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
  IN_APP_NOTIFICATION: 'NOTIFICATION',
};

export {
  messagePlatform, periodType, weekDays, week, checkRepeatTime, NOTIFICATION_ALERT_MESSAGE, PLATFORM_TYPE
};
