import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SbdView from './View';
import PageHeader from '../../base/PageHeader';
import SbdDialog from './components/sbdDialog';
import { SBD_DETAILS } from '../../../data/enums/Route';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { getPermissionForSbd } from '../../base/permission';
import withAlert from '../../../utils/composition/withAlert';
import { shopperBasedDesign } from '../../common/DomainConfig';
import { crudRequestConfig } from './components/sbdDialog/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { crudResponseProcessor } from '../../../utils/crudResponseProcessor';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { breadCrumb as breadCrumbConfig } from './config';
import { isError } from '../../common/HelperFunctions';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getSBDListWithTitle: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class SBD extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.state = {
      data: {
        list: [],
        total: 1,
      },
      display: {
        searchBox: false,
      },
      dialog: {
        type: '',
        element: '',
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        date: { ...queryService.baseQueryParameters.date },
        filter: queryService.baseQueryParameters.filter,
        search: queryService.baseQueryParameters.search,
        pagination: queryService.baseQueryParameters.pagination,
      },
    };
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = data => {
      this.setState({ data });
    };
    this.permission = getPermissionForSbd();

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
  }

  componentDidMount() {
    this.loadTableData();
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getSBDListWithTitle, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);

    getSBDListWithTitle(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.sbds && response.data.sbds.rows) || [];
          data.total = (response.data.sbds && response.data.sbds.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type, element) => {
    const { history } = this.props;
    const id = element ? element.id || '' : '';

    switch (type) {
      case EVENT_OPERATION.DETAILS:
        history.push(`/${SBD_DETAILS}/${id}`);
        break;
      case EVENT_OPERATION.CREATE:
      case EVENT_OPERATION.UPDATE:
      case EVENT_OPERATION.REPLICATE:
      case EVENT_OPERATION.DELETE:
        this.setState({
          dialog: {
            type,
            element,
          },
        });
        break;
      default:
        history.push(`/${SBD_DETAILS}/${id}`);
    }
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleSubmit = (type, response) => {
    const { displayAlert } = this.props;
    const { setData, getData } = this;
    displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    crudResponseProcessor(setData, getData, ['title', 'start', 'end'])(type)(response);
  };

  handleClose = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleApiFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  stateSetter = (stateField, value) => {
    this.setState({ [stateField]: value });
  };

  render() {
    const { data, queryParameters, display, dialog } = this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: shopperBasedDesign.title,
                create: this.permission.create,
                download: false,
                filter: false,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.handleIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
              filter={{
                date: queryParameters.date,
              }}
            />
          </PanelStyled>
        </div>
        <div className="section-content table-present">
          <SbdView
            data={data}
            permission={this.permission}
            loading={serverResponseWaiting}
            pagination={queryParameters.pagination}
            onIconClick={this.handleIconClick}
            onBulkDelete={this.handleSubmit}
            onPageChange={this.basePaginationService.onPageSelect}
          />
          {dialog.type && (
            <div className="sbd-modal">
              <SbdDialog
                type={dialog.type}
                element={dialog.element}
                onSubmit={this.handleSubmit}
                onClose={this.handleClose}
                onApiFailure={this.handleApiFailure}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}
SBD.propTypes = propTypes;

SBD.defaultProps = defaultProps;

export default withAlert()(SBD);
