import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../base/PageHeader';
import { getPermissionForApprovalRequest } from '../../base/permission';
import { PanelStyled } from '../../common/configuration';
import DeBoardOutletWrapper from './DeBoardOutletWrapper';
import * as queryService from '../../base/query.service';
import { breadCrumbConfig, title, filterConfig } from './config';
import View from './View';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { isError, generateRequestMessage, imageSliderListFormatter } from '../../common/HelperFunctions';
import withAlert from '../../../utils/composition/withAlert';
import { APPROVAL_PROCESS } from '../../../data/enums/Support';
import ModalWrapper from '../../common/DialogFormWrapper';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import { formConfig } from '../../components/RequestBulkSelect/config';
import confirmationGenerator from '../../common/DialogConfirmation';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { clone } from '../../../utils/objectProcessor';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getApprovalRequest: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};

class DeBoardOutlet extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      data: {
        list: [],
        total: 0,
      },
      dialog: {
        type: '',
        checkedList: '',
      },
    };
    const { downloadReport, displayAlert } = props;
    this.permission = getPermissionForApprovalRequest();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadOutletDeleteData,
      downloadReport,
      displayAlert,
    );
  }
  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.DEBOARD_OUTLET
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.DEBOARD_OUTLET);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadOutletDeleteData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadOutletDeleteData();
      },
    );
  };



  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  handleRequest = (status, checkedList, checkStatus = () => null) => {
    const { update, displayAlert } = this.props;

    update(
      {
        ids: checkedList,
        processId: APPROVAL_PROCESS.OUTLET_DELETE_APPROVAL.processId,
        status,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, generateRequestMessage(status));
          this.loadOutletDeleteData();
          checkStatus();
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  loadOutletDeleteData = () => {
    const { getApprovalRequest } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getApprovalRequest(
      {
        offset,
        type: APPROVAL_PROCESS.OUTLET_DELETE_APPROVAL.type,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
        },
      },
      {
        handleSuccess: response => {
          const deboardOutletList = (response.data.approvalRequests && response.data.approvalRequests.rows) || [];
          data.total = (response.data.approvalRequests && response.data.approvalRequests.count) || 0;
          data.list = deboardOutletList.map(outlet => {
            if (outlet.outletDeleteData.image) {
              outlet.outletDeleteData.image = [
                ...(imageSliderListFormatter(outlet.outletDeleteData.image, '', { w: 2400, h: 1100 }) || []),
              ];
            }
            return outlet;
          });
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  handleClose = () => {
    this.setState({ dialog: { type: '', checkedList: '' } });
  };

  onActionClick = (status, checkedList, checkStatus) => {
    this.setState({
      dialog: {
        type: status,
        checkedList,
      },
    });
  };

  render() {
    const { queryParameters, data, dialog,filterMenu } = this.state;
    const { type, checkedList } = dialog;
    const { serverResponseWaiting } = this.props;
    return (
      <>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                create: false,
                download: true,
                filter: true,
                search: true,
                date: true,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              downloadConfig={{
                domain: DOWNLOAD_DOMAIN.DEBOARD_OUTLET,
              }}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              queryParameters={queryParameters}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content table-present">
          {type && (
            <ModalWrapper
              type={type}
              dialogData={checkedList}
              formConfig={formConfig}
              onDialogCancel={this.handleClose}
              onDialogSubmit={this.handleRequest}
              renderDialog={() => <>{confirmationGenerator(type)}</>}
            />
          )}
          <DeBoardOutletWrapper>
            <View
              data={data}
              permission={this.permission}
              onIconClick={this.onActionClick}
              handleRequest={this.handleRequest}
              pagination={queryParameters.pagination}
              onPageChange={this.basePaginationService.onPageSelect}
              loading={serverResponseWaiting}
            />
          </DeBoardOutletWrapper>
        </div>
      </>
    );
  }
}

DeBoardOutlet.propTypes = propTypes;

DeBoardOutlet.defaultProps = defaultProps;

export default withAlert()(DeBoardOutlet);
