import { TableBody, TableHeader } from './tableConfig';
import withLoading from '../../../../../../utils/composition/withLoading';
import BulkDelete from '../../../../../../v4/components/BulkDelete/BulkDelete';

const PurchaseOrderDetailTable = props => {
  const { tableData } = props;

  return (
    <>
      <BulkDelete
        data={tableData}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {TableHeader({
                dataListLength: tableData?.length,
                checkedListLength: checkedList?.length,
                primaryCheckboxHandler,
              })}
              <tbody>
                {tableData?.map(subD =>
                  TableBody({
                    data: subD,
                    checkedList: checkedList,
                    onViewClick: () => {},
                    onIconClick: () => {},
                    secondaryCheckboxHandler,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
    </>
  );
};

const PurchaseOrderDetailTableWithLoading = withLoading(PurchaseOrderDetailTable);

export default PurchaseOrderDetailTableWithLoading;
