import React from 'react'
import styled from 'styled-components';
import { Input } from '../../../../../components';
import { EMPTY } from '../../../../../data/enums/ErrorMessage';

const NoOrderForm = ({ data,onRadioButtonChange,onInputChange, handleRadioCallback,enableErrorDisplay,refsObj ,noOrdervalidation }) => {
  return (
    <NoOrderFormStyled>
      <div className='no-order-dialog_body'>
        <div className='title'>{data?.title||'Pantene 2 in 1 Hair Fall Control 1000 ml'}</div>
        <label>Reasons</label>

        <label className='radio-list'>
          <div className='radio-input'>
            <input
              type='radio'
              name='reason'
              value='noOfftake'
              onChange={(e) => onRadioButtonChange(e, [], handleRadioCallback)}
              checked={data?.reason?.noofftake}
            />
            <span className='text'>No Offtake</span>
          </div>
        </label>
        <label className='radio-list'>
          <div className='radio-input'>
            <input
              type='radio'
              name='reason'
              value='marginIssue'
              onChange={(e) => onRadioButtonChange(e, [], handleRadioCallback)}
              checked={data?.reason?.marginissue}
            />
            <span className='text'>Margin Issue</span>
          </div>
          <div className='rlp-input'>
            <Input
              labelContent={'RLP'}
              ref={(ref) => (data?.reason===''||data?.reason==='noofftake'||data?.reason==='other'?ref===undefined:refsObj.marginissue = ref)}
              value={data.rlp}
              name='rlp'
              type='number'
              rule='matches'
              argument={/\d+(\.\d\d)?/}
              onChange={(event) => onInputChange(event,'', [])}
              disabled={data?.reason===''||data?.reason==='noofftake'||data.reason==='other'}
              enableErrorDisplay={enableErrorDisplay}
              enableValidation
              errorMessage={data?.reason==='marginissue' && data?.rlp!==0?'':EMPTY}
              externalValidation={data?.reason==='marginissue' ? data?.rlp!==0:null}
            />
          </div>
        </label>
        <label className='radio-list other'>
          <div className='radio-input'>
            <input
              type='radio'
              name='reason'
              value='Other'
              onChange={(e) => onRadioButtonChange(e, [], handleRadioCallback)}
              checked={data?.reason?.other}
            />
            <span className='text'>Other</span>
          </div>
          <textarea
            name='message'
            type='text'
            id='message'
            placeholder='Message'
            value={data?.message}
            onChange={(event) => onInputChange({ ...event,name:'message', formattedValue: event?.target.value },'')}
            className={(data?.reason===''||data?.reason==='noofftake'||data?.reason==='marginissue')?'disabled':''}
          />
        </label>
      </div>
    </NoOrderFormStyled>
  )
}

const NoOrderFormStyled = styled.div`
  .disabled {
    cursor: not-allowed;
      pointer-events: none ;
  }
  .no-order-dialog_body{
    width: 493px;
    width: 60%;
    padding: 24px;
    .title{
      font-size:18px;
      line-height:27px;
      font-weight:500;
      padding-bottom:24px;
      border-bottom:1px solid #E7E7ED;
    }
    &>label{
      &:not(.radio-list){
        font-size:16px;
        line-height:19px;
        color:#272833;
        display:block;
        padding:24px 0 14px 0;
        font-weight:500;
      }
      &.radio-list{
        border:1px solid #E7E7ED;
        padding:15px 18px ;
        border-radius:4px;
        display:flex;
        margin-bottom:14px;
        justify-content:space-between;
        span{
          font-size:14px ;
          line-height:21px;
          color:#272833;
        }
        .radio-input{
          input[type="radio"]{
            position:relative;
            top:1px;
            margin-right: 12px;
          }
        }
        .rlp-input{
          display:flex;
          align-items:center;
          &>.form-input{
            display: flex;
            flex-wrap: wrap;
            width: 150px;
            justify-content: flex-end;
            align-items: center;
            .form-error{
              width: 148px;
            }
          }
          label{
            font-size:12px;
            line-height:14px;
            color:#272833;
            margin-right:8px;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
           -webkit-appearance: none;
            margin: 0;
              }
          .form-input input{
            border:1px solid #80ACFF;
            border-radius:4px;
            background-color:#F0F5FF;
            height:24px;
            min-width:60px;
            width:60px;
            padding:5px;
            font-size:12px;
            color:#272833;
            text-align:right;
          }
        }
        &.other{
          flex-direction:column;
          align-items: revert;
          textarea{
            margin-left:28px;
            margin-top:15px;
            border-radius:4px ;
            border:1px solid #E7E7ED;
            background-color:#F1F2F5;
            resize:none;
            padding:10px 16px;
            font-size:16px;
            line-height:24px;
            color:#6B6C7E;
          }
        }
      }
    }
  }
`

export default NoOrderForm
