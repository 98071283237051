import { compose } from 'recompose';
import CoverageDetail from './CoverageDetail';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_OR_EDIT_OUTLET_COVERAGE_USER_TARGET
} from './API';

const ComposedCoverageDetail = compose(
  CREATE_OR_EDIT_OUTLET_COVERAGE_USER_TARGET,
  withRequestTracker({
    query,
    mutation,
  }),
)(CoverageDetail);

export default ComposedCoverageDetail;
