import styled from 'styled-components';

const BrandCoverageWrapper = styled.div`
  table {
    td {
      width: auto;
      white-space: nowrap;
      &:first-child {
        width: 30%;
      }
      &:last-child {
        .view {
          margin-right: 40px;
        }
        span {
          cursor: pointer;
          color: #6b6c7e;
          font-size: 14px;
        }
        svg.icon {
          height: 14px;
          width: 14px;
          margin-right: 9px;
        }
      }
    }
    td,
    th {
      &:last-child {
        text-align: right;
      }
    }
  }
  .modal-wrapper {
    .modal-inner {
      width: 580px;
      max-width: unset;
      .evolve-dialog__body {
        .form-input {
          margin: 24px 0px;
        }

        .brand-detail-wrap {
          display: flex;
          .single-date-picker {
            width: 50%;
            margin: 24px;

            &:last-child {
              margin-left: 0;
            }
          }
        }
        .table-wrap {
          height: 288px;
          overflow: auto;
          table {
            border-radius: 0 0 4px 4px;
            position: relative;
            tr {
              height: 40px;
              th,
              td {
                width: 50%;
                padding: 0;
                &:first-child {
                  padding: 0 12px 0 24px;
                }
                &:last-child {
                  text-align: right;
                  padding-right: 25%;
                }
                .form-input {
                  padding: 4px;
                  margin: 0;
                  label {
                    font-size: 14px;
                    line-height: 21px;
                  }
                  input {
                    width: 172px;
                    border: 1px solid #e7e7ed;
                    text-align: right;

                    :disabled {
                      border: 1px solid white;
                      background: white;
                      color: #272833;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BrandCoverageWrapper;
