import gql from 'graphql-tag';
import { apolloClient } from '../services/common';

const GET_PAYMENT_LIST = gql`
  query GetBillingPaymentModes($limit: Int, $offset: Int, $distributorId: Int, $filter: FilterInput) {
    getBillingPaymentModes(limit: $limit, offset: $offset, distributorId: $distributorId, filter: $filter) {
      count
      rows {
        active
        alias
        id
        title
        type
      }
    }
  }
`;
const errorProcessorInFilterDataFetch = () => null;
export const getPaymentModeList = id =>
  apolloClient
    .query({
      query: GET_PAYMENT_LIST,
      variables: {
        offset: 0,
        filter: {},
        ...(id > 0 && { distributorId: id }),
      },
    })
    .then(response => (response.data.getBillingPaymentModes ? response.data.getBillingPaymentModes.rows || [] : []))
    .catch(err => errorProcessorInFilterDataFetch(err));
