import React, { useEffect, useState } from 'react';
import { WIDGETS } from '../../../../data/enums/config';
import {  PanelStyled } from '../../../common/configuration';
import PageHeader from '../../../common/detailViews/pageHeader';
import { breadCrumb, formConfig } from './config';
import View from './View';
import { DialogFormWrapper } from '../../../common';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { inputChange } from '../../../../utils/formHandlers';
import DetailStyled from './DetailStyled';
import { ALERT_TYPE } from '../../../../v4/constants/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { MESSAGE_EVENT_OPERATION } from '../../../../v4/constants/successMessage';
import { fileParser } from '../../../../utils/parser';
import { DOWNLOAD_DOMAIN, UPLOADABLES } from '../../../../data/enums/GraphQL';
import { Icon, CSVUploadForm } from '../../../../v4/components';
import * as downloadService from '../../../base/download.service';
import { getFromLocalStorage } from '../../../common/HelperFunctions';
import { JWT } from '../../../../environment';
import { getPermissionForTargets } from '../../../base/permission';

const Detail = (props) => {
  const { serverResponseWaiting, displayAlert,getBrandwiseDistributorTarget, match, location, createDistributorTarget, upload, downloadReport } = props;

  const { state = {} } = location;
  const distributorId = state.distributorId;
  const subDTitle = state.subDTitle;
  const id = parseInt(match.params.id || '', 10);
  const sku =
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS) &&
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS).ico.sku;
  const [data, setData] = useState({ list: [], icoSKUConfigType: false });
  const [actionWidgets, setActionWidgets] = useState({
    [WIDGETS.UPLOAD]: true,
    [WIDGETS.DOWNLOAD]: true,
  })
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [button, setButton] = useState({
    buttonType: EVENT_OPERATION.READ,
    buttonData: '',
  });
  const getActionConfig = () =>({ handler: getHandlerFunc });
  const getHandlerFunc = (type,element={}) =>{
    switch (type) {
    case WIDGETS.UPLOAD:
      handleUploadClick(type,element);
      break;
    case WIDGETS.DOWNLOAD:
      handleDownloadClick(type);
      break;
    default:
      break;
    }
  }

  const handleUploadClick = (type, element = {}) => {
    setDialog({
      type,
      element,
    });
  }

  const uploadData = async (list) => {
    const fileData = await fileParser(list.file).then((file) => ({
      input: {
        file,
        type: UPLOADABLES.SUBDSKUTARGET,
        references:[{
          type:'distributor_target_id',
          id,
        },
        {
          type:'distributor_id',
          id:distributorId,
        }
        ]
      },
    }));

    return fileData;
  };

  const handleFormSubmit = async (type,list) => {
    const inputData = await uploadData(list);
    upload({
      ...inputData
    },{
      handleSuccess :() => {
        displayAlert(ALERT_TYPE.SUCCESS,`File ${MESSAGE_EVENT_OPERATION.UPLOAD}`)
        loadTableData(EVENT_OPERATION.UPLOAD);
      },
      handleError: (err) => {
        onAPIRequestFailure(err);
      },
    }
    )
  }

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
  }

  const handleRowClick = (rowdata) => {
    setButton(
      {
        buttonType: EVENT_OPERATION.READ,
        buttonData: rowdata,
      }
    )
  };

  const handleIconClick = (buttonType,buttonData) => {
    setButton({
      buttonType,
      buttonData
    });
  };

  const handleCancelClick = (details) => {
    setButton({
      buttonType: EVENT_OPERATION.READ,
      buttonData: details,
    });
  };

  const handleSaveClick = () => {
    const payload = button.buttonData;
    createDistributorTarget({
      input:{
        bu_target_id: id,
        distributor_id: distributorId,
        brandwisetargetinput: [{
          brand_id: payload.brand_id,
          target: payload.target,
        }],
        skuwisetargetinput: data.icoSKUConfigType ? payload.details.map((detail)=>({
          brand_id: detail.brand_id,
          sku_id: detail.sku_id,
          target: detail.currenttarget,
        })): null,
        skufamilywisetargetinput: data.icoSKUConfigType ? null : payload.details.map((detail)=>({
          brand_id: detail.brand_id,
          sku_family_id: detail.sku_family_id,
          target: detail.currenttarget,
        })),
      },
      brandId:payload.brand_id,
    },{
      handleSuccess: () => {
        setButton({
          buttonType: EVENT_OPERATION.READ,
          buttonData: payload,
        });
        loadTableData();
        displayAlert(ALERT_TYPE.SUCCESS,`Distributor Sales Targets ${MESSAGE_EVENT_OPERATION.UPDATE}`)
      },
      handleError: (err) => {
        onAPIRequestFailure(err);
      },
    }
    )
  };

  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const loadTableData = (type='') => {
    getBrandwiseDistributorTarget({
      buTargetId: id,
      distributorId,
    }, {
      handleSuccess: (res) => {
        const response = res.data.getTargetDistributorBrandWise;
        const skuDetail = response.icoSKUConfigType ? response.skuwisetarget : response.skufamilywisetarget || [];
        const brandwisetarget = response.brandwisetarget.length>0 ? response.brandwisetarget.map((brand)=>({
          ...brand,
          details: skuDetail.filter((sku) => sku.brand_id === brand.brand_id),
        })) : [];
        setData({
          icoSKUConfigType: response.icoSKUConfigType,
          list: brandwisetarget,
        });
        if(type===EVENT_OPERATION.UPLOAD){
          setButton({
            buttonType: EVENT_OPERATION.READ,
            buttonData: brandwisetarget.filter((x)=>x.brand_id===button.buttonData.brand_id)?.[0],
          });
        }},
      handleError: (err) => {
        onAPIRequestFailure(err);
      },
    })
  };

  const handleInputChange=(event,firstParam = '', paramList = [])=>{
    const updatedDetails = inputChange(button.buttonData, event, firstParam, paramList);
    const target = updatedDetails.details.reduce((prev, current) => prev + +current.currenttarget, 0);
    setButton(
      {
        buttonType: EVENT_OPERATION.UPDATE,
        buttonData: {
          ...updatedDetails,
          target
        }
      }
    )
  }

  const handleDownloadClick = (type) => {
    const typeCheck = type?.length ? DOWNLOAD_DOMAIN.DISTRIBUTOR_SKU_TARGET: DOWNLOAD_DOMAIN.DISTRIBUTOR_TARGET;
    const filterDistributorId = {
      column: 'distributor_id',
      value: [distributorId.toString()],
    };
    const filterBuTargetId ={
      column: 'buTarget_Id',
      value: [id.toString()],
    };
    const distributorSKUDownload ={
      domain: typeCheck,
      filter: {
        filters: [
          filterDistributorId,
          filterBuTargetId,
        ],
      },
      reportType: 'csv',
    };
    const distributorTargetDownload ={
      domain: typeCheck,
      filter: {
        filters: [
          filterDistributorId,
        ],
      },
      reportType: 'csv',
    };
    const input =type?.length ? distributorSKUDownload:distributorTargetDownload;
    downloadReport(
      {
        input
      },
      {
        handleSuccess: (response) => {
          downloadService.resolver(response.data.downloadReport.file);
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
        },
        handleError: () => {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Cannot download file');
        },
      },
    );
  };
  const permission = getPermissionForTargets();

  useEffect(() => {
    loadTableData()
  }, [])

  return (
    <DetailStyled>
      {dialog.type && (
        <DialogFormWrapper
          onDialogSubmit={handleFormSubmit}
          formConfig={formConfig}
          dialogElement={dialog.element}
          onDialogCancel={resetDialog}
          type={dialog.type}
          renderDialog={({ dialogData, enableErrorDisplay, handleFileUpload, refsObj }) => (
            <>
              <CSVUploadForm
                show
                data={dialogData}
                loading={serverResponseWaiting}
                handleFileUpload={handleFileUpload}
                enableErrorDisplay={enableErrorDisplay}
                refsObj={refsObj}
              />
              <a onClick={() => handleDownloadClick()}>
                <span className='sample-csv'>
                  <Icon iconName='download' />
                    Sample CSV
                </span>
              </a>
            </>
          )}
        />
      )}
      <div className='section-header'>
        <PanelStyled>
          <PageHeader
            breadCrumb={breadCrumb}
            title={subDTitle}
            actions={actionWidgets}
            getActionConfig={getActionConfig}
          />
        </PanelStyled>
      </div>
      <div className='section-content table-present'>
        <View
          data={data}
          sku={sku}
          button={button}
          loading={serverResponseWaiting}
          onRowClick={handleRowClick}
          onIconClick={handleIconClick}
          onSaveClick={handleSaveClick}
          onCancelClick={handleCancelClick}
          onInputChange={handleInputChange}
          permission={permission}
        />
      </div>
    </DetailStyled>
  )
}

export default withAlert()(Detail);
