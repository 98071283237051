import styled from 'styled-components';

const LedgerStyled = styled.div`
  .ledger-top-wrap {
    display: flex;
    justify-content: space-between;

    .absolute {
      align-self: center;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
    }
  }

  .table-wrap {
    tbody,
    thead {
      td,
      th {
        width: 10%;
        &:first-child {
          width: 2%;
        }
        &:last-child {
          width: 10%;
        }
      }
    }
  }

  .modal-inner {
    .auto-complete-pop-up {
      .form-input {
        &:last-child {
          margin-bottom: 24px;
        }
      }
    }
  }
`;

export default LedgerStyled;
