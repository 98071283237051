import { Input } from '../../../../components';
import { Button, Icon } from '../../../../v4/components';

export const UOM = ({ ...props }) => {
  const { data, onInputChange, handleMeasurementCancel, handleMeasurementAdd, enableErrorDisplay, refsObj } = props;
  return (
    <>
      <h3 className="cardTitle mt-0">Units of Measurement</h3>
      <div className="form-input-wrap card w-100per">
        <div className="measurement-wrap w-100per">
          <div>
            <ul className="list-title">
              <li className="large">
                <span>Title</span>
              </li>
              <li>
                <span>Label</span>
              </li>
            </ul>
          </div>
          <div>
            {data.unitsOfMeasurement.map((measure, index) => (
              <div className="list-content w-100per" key={index}>
                <label className="large custom-control-label-text">
                  <Input
                    type="text"
                    name="title"
                    placeholder="title"
                    value={measure.title}
                    onChange={event =>
                      onInputChange(event, 'unitsOfMeasurement', ['uomData', 'unitsOfMeasurement', [index], 'title'])
                    }
                    enableValidation
                    ref={ref => (refsObj.title = ref)}
                    enableErrorDisplay={enableErrorDisplay}
                  />
                </label>
                <label className="custom-control-label-text">
                  <Input
                    type="text"
                    name="label"
                    placeholder="label"
                    value={measure.label}
                    onChange={event =>
                      onInputChange(event, 'unitsOfMeasurement', ['uomData', 'unitsOfMeasurement', [index], 'label'])
                    }
                    enableValidation
                    ref={ref => (refsObj.label = ref)}
                    enableErrorDisplay={enableErrorDisplay}
                  />
                </label>
                <Icon iconName="times" onClick={() => handleMeasurementCancel(measure.title)} />
              </div>
            ))}
          </div>
        </div>
        <Button small secondary onClick={() => handleMeasurementAdd()}>
          <Icon iconName="plus" />
          <span>Add</span>
        </Button>
      </div>
    </>
  );
};
