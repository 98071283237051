import { compose } from 'recompose';
import MerchandiseReport from './MerchandiseReport';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, DOWNLOAD_REPORT, mutation
} from './API';

const ComposedMerchandiseReport = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(MerchandiseReport);

export default ComposedMerchandiseReport;
