import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const ResetPasswordAPI = graphql(
  gql`
    mutation ($newPassword: String!, $token: String!) {
      resetPasswordFromWeb(newPassword: $newPassword, token: $token)
    }
  `,
  {
    props: ({ mutate }) => ({
      resetPasswordFromWeb: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {};

const mutation = {
  resetPasswordFromWeb: ResetPasswordAPI,
};

export {
  query, mutation, ResetPasswordAPI
};
