import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { CONFIRMATION_CONFIG } from '../../../data/enums/config';

const formConfig = {
  ...CONFIRMATION_CONFIG,
};

const crudRequestConfig = {
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (element) => ({
      ids: [...element.data],
      type: element.type,
      active: false,
    }),
    responseName: 'toggleState',
    message: `${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export { crudRequestConfig, formConfig };
