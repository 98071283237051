import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../../../user/userAccount/API';
import { GET_ROUTE_SEGMENTATION_LIST } from '../../../../../salesForce/routeSegmentation/API';
import { createSKUGroup, updateSKUGroup } from '../../../SKUGroupType/Repository/API';

const REPLICATE_SKU_GROUP = graphql(
  gql`
  mutation replicateSKUGroup($id: Int!, $input: SKUGroupInput!) {
    replicateSKUGroup(id: $id, input: $input) {
      id
      title
      active
      start_date
      end_date
      startDate
      endDate
      master_id
      line_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      replicateSKUGroup: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const mutation = {
  createSKUGroup,
  updateSKUGroup,
  replicateSKUGroup: REPLICATE_SKU_GROUP,
  toggleState: TOGGLE_STATE,
};

const query = { getLineDetails: GET_ROUTE_SEGMENTATION_LIST };

export {
  query, mutation, createSKUGroup, updateSKUGroup, REPLICATE_SKU_GROUP
};
