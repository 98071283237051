import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RateForm from '../RateForm';
import { rateFormConfig } from '../config';
import { DialogFormWrapper } from '../../../../common';
import { Icon,Button } from '../../../../../v4/components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { hyphenPresentor } from '../../../../../utils/date';
import { numberPresentor } from '../../../../../utils/conversion';
import SKUStyled from '../../SKUStyled';

const propTypes = {
  crudMode: PropTypes.string,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      usageDate: PropTypes.instanceOf(Object),
      priceDetails: PropTypes.instanceOf(Object),
      batchDetails: PropTypes.instanceOf(Object),
    }),
  ),
  getRates: PropTypes.func,
};

const defaultProps = {
  rates: [],
  crudMode: EVENT_OPERATION.READ,
  getRates: () => {},
};

class GridDialogLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        type: '',
        element: '',
        index: null,
      },
      rates: [...props.rates],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { rates } = prevState;
    if (nextProps.rates.length > rates.length) {
      return { rates: nextProps.rates };
    }
    if (nextProps.clearData) {
      return { rates: [] };
    }
    return { rates };
  }

  componentDidMount() {
    const { getRates } = this.props;
    getRates(this.exportData);
  }

  onDialogOkClick = (type, dialogData) => {
    const { rates, dialog } = this.state;
    const transformedData = {
      ...dialogData,
      priceDetails: {
        rlp: dialogData.priceDetails ? Number(dialogData.priceDetails.rlp) : 0,
        vatPercentage: dialogData.priceDetails ? dialogData.priceDetails.vatPercentage : 0.13,
        mrpStatus: dialogData.priceDetails ? dialogData.priceDetails.mrpStatus : true,
        mrp: dialogData.priceDetails ? Number(dialogData.priceDetails.mrp) : 0,
        srp: dialogData.priceDetails ? Number(dialogData.priceDetails.srp) : 0,
        b2bRate:dialogData.priceDetails ? dialogData.priceDetails.b2bRate : 0,
      },
    };
    if (type === EVENT_OPERATION.UPDATE) {
      rates[dialog.index] = transformedData;
      this.setState({ rates });
    } else {
      rates.push(transformedData);
      this.setState(rates);
    }
  };

  updateDialog = (type, element = {}, index) => {
    const { onAddClick } = this.props;
    this.setState({
      dialog: {
        type,
        element,
        index,
      },
    });
    onAddClick();
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
        index: null,
      },
    });
  };

  exportData = () => {
    const { clearData } = this.props;
    const { rates } = this.state;
    if (clearData) {
      this.setState({ rates: [] });
    }
    return rates;
  };

  render() {
    const { rates, dialog } = this.state;
    const { crudMode, onAddClick,listMasterConfigurationData,routeSegmentationValidation } = this.props;
    const { type } = dialog;
    const b2bValidation = listMasterConfigurationData.find(element => element.type==='SKU_SRP_CONFIG');
    const propRatesLength = this.props.rates.length;
    
    return (
      <SKUStyled>
        <div className="config-panel-header">
          <label>SKU Rate</label>
        </div>
        <div className="config-panel-content sku-batch">
          {type && (
            <DialogFormWrapper
              onDialogSubmit={this.onDialogOkClick}
              formConfig={rateFormConfig[type]}
              dialogElement={dialog.element}
              onDialogCancel={this.resetDialog}
              type={type}
              renderDialog={({ handleInputChange, dialogData, enableErrorDisplay, refsObj }) => (
                <Fragment>
                  <RateForm
                    show
                    data={dialogData}
                    enableErrorDisplay={enableErrorDisplay}
                    handleInputChange={handleInputChange}
                    refsObj={refsObj}
                    listMasterConfigurationData={listMasterConfigurationData}
                    routeSegmentationValidation={routeSegmentationValidation}
                  />
                </Fragment>
              )}
            />
          )}
          <div className="table-wrap">
            <table>
              <thead>
                <tr>
                  <th>Updated Date</th>
                  <th className="text-right">RLP</th>
                  <th className="text-right">MRP</th>
                  <th className="text-right">SRP</th>
                 {b2bValidation?.configuration?.enableB2BRate&& <th className="text-right">B2B</th>} 
                  <th className="text-right">VAT</th>
                  {crudMode !== EVENT_OPERATION.CREATE && <th className="text-center">Status</th>}
                  <th />
                </tr>
              </thead>
              <tbody>
                {rates.map((item, key) => (
                  <tr key={key}>
                    <td>{item.updatedAt ? hyphenPresentor(item.updatedAt) : hyphenPresentor(new Date())}</td>
                    <td className="text-right">{item.priceDetails.rlp}</td>
                    <td className="text-right">{item.priceDetails.mrp}</td>
                    <td className="text-right">{item.priceDetails.srp}</td>
                    {b2bValidation?.configuration?.enableB2BRate&& <td className="text-right">{item.priceDetails.b2bRate}</td>}
                    <td className="text-right">{numberPresentor(0.13 * item.priceDetails.rlp)}</td>
                    {crudMode !== EVENT_OPERATION.CREATE && (
                      <td className="text-center">
                        <span className={`status-label ${item.active ? 'active' : 'passive'}`}>
                          {item.active ? 'ACTIVE' : 'INACTIVE'}
                        </span>
                      </td>
                    )}
                    <td>
                      {key < propRatesLength ? null : (
                        <Icon
                          className="cursor-pointer"
                          iconName="pencil"
                          onClick={() => this.updateDialog(EVENT_OPERATION.UPDATE, item, key)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={12}>
                    <Button
                      secondary
                      small
                      iconName="plus"
                      onClick={() => this.updateDialog(EVENT_OPERATION.CREATE, {})}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </SKUStyled>
    );
  }
}

GridDialogLayout.propTypes = propTypes;

GridDialogLayout.defaultProps = defaultProps;

export default GridDialogLayout;
