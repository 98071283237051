import { FILTER } from '../../../../data/enums/filter';
import { getFilterConfig } from '../../../../utils/filter';
import { reports as reportsRoute } from '../../../../data/enums/Route';


const status = {
  0: 'INACTIVE',
  1: 'ACTIVE',
  2: 'ON LEAVE',
};

const breadCrumb = [reportsRoute];

const filter = getFilterConfig([FILTER.HIERARCHY_STATUS]);

export {
  breadCrumb as breadCrumbConfig, filter as filterConfig,status
};
