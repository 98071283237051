import { compose } from 'recompose';
import Srn from './SRN';
import { query, mutation } from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const ComposedSrn = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(Srn);

export default ComposedSrn;
