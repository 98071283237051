import React from 'react';
import {
  Badge, Button, CheckBox
} from '../../../../v4/components';
import { contactVerified } from '../../../common/HelperFunctions';

const FilteredOutlet = (props) => {
  const { data, checkedList, onAddSelected, onAddOutlet, onPrimaryCheckboxClick, onSecondaryCheckboxClick }= props;
  return (
    <div className='outlets'>
      <div className='title'>
        OUTLET FILTERED
        <Badge className={data.list && data.list.length ? 'primary':'light'} label={data.list?data.list.length:0}/>
      </div>
      {checkedList.filter((a)=>data.list.includes(a)).length !== 0 && (
        <div className='outlet-content'>
          <div className='outlet-title'>
            <CheckBox checked={data.list && data.list.length === checkedList.length}
              onCheckBoxClick={(e) => onPrimaryCheckboxClick(e,'add')}
              labelText={`${checkedList.length} Selected`}/>
          </div>
          <Button primary onClick={() => onAddSelected()}>
                Add Selected
          </Button>
        </div>
      )}
      <div className='outlet-section'>
        {data.list.map((list)=>
          <div className={`outlet-content ${list.isplanned?'disabled':''}`}>
            <div className='outlet-title'>
              <CheckBox checked={checkedList.includes(list)} onCheckBoxClick={() => onSecondaryCheckboxClick(list,'add')}/>
              <div className='title-content'>
                <p>{list.title}
                  {list.isverified!==null&&<img src={contactVerified(list.isverified)} alt='V' />}
                </p>
                <span className='channel-category'>{`${list.channel}/${list.category}`}{list.isplanned&&<Badge light label='PLANNED' />}</span>

                <span className='reason'>
                  {list.reason}
                </span>
              </div>
            </div>
            {!list.isplanned&&<Button small iconBtnSmall iconName='plus' className='add' disabled={checkedList.filter((a)=>data.list.includes(a)).length>0} onClick={() => onAddOutlet(list,'add')}/>}
          </div>)
        }
      </div>
    </div>);
};

export default FilteredOutlet;
