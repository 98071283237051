import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { salesForce } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Leave Management';

const breadCrumb = [salesForce];

const filter = getFilterConfig([FILTER.LEAVE_STATUS, FILTER.LEAVE_TYPE, FILTER.USER_ROLE]);

const crudSuccess = {
  UPDATE: {
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

export {
  crudSuccess, breadCrumb as breadCrumbConfig, filter
};
