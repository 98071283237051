import React from 'react';
import PropTypes from 'prop-types';
import DetailView from './View';
import withAlert from '../../../../utils/composition/withAlert';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  openingBlncDetail: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func.isRequired,
};

const defaultProps = {
  data: [],
  openingBlncDetail: [],
};

const Detail = ({ ...props }) => {
  const { data, onRowClick, openingBlncDetail } = props;

  return <DetailView data={data} onRowClick={onRowClick} openingBlncDetail={openingBlncDetail} />;
};

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
