import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import LEDGERS from '../../../../../data/enums/Ledgers';
import { Form, Input, SingleDatePicker } from '../../../../../components';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import CustomAutoComplete from '../../../../../components/CustomAutoComplete';
import { remarksList } from './config';
import { getTodaysDate } from '../../../../../utils/date';
import CustomSelectWithBtn from '../../../../../components/CustomSelectWithBtn';
import { Icon, CheckBox } from '../../../../../v4/components';
import { PAYMENT_MODE } from '../../../orderProcessing/received/salesInvoice/customerDetail/config';

const propTypes = {
  subDUser: PropTypes.bool,
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  distributorList: PropTypes.instanceOf(Array),
  customerList: PropTypes.instanceOf(Array),
  invoiceNumberList: PropTypes.instanceOf(Array),
  srnRouteLineList: PropTypes.instanceOf(Array),
  distributorServices: PropTypes.instanceOf(Object),
  invoiceNumberStatus: PropTypes.bool,
  loadingInvoiceStatus: PropTypes.bool,
  handleIconClick: PropTypes.func,
  pending: PropTypes.bool,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  subDUser: false,
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  distributorList: [],
  handleDropDownChange: () => null,
  handleInputChange: () => null,
  customerList: [],
  invoiceNumberList: [],
  distributorServices: {},
  invoiceNumberStatus: false,
  loadingInvoiceStatus: false,
  srnRouteLineList: [],
  handleIconClick: () => null,
  pending: false,
};

const SrnSkuForm = ({ ...props }) => {
  const {
    data,
    update,
    subDUser,
    refsObj,
    customerList,
    distributorList,
    handleInputChange,
    invoiceNumberList,
    enableErrorDisplay,
    distributorServices,
    handleDropDownChange,
    invoiceNumberStatus,
    loadingInvoiceStatus,
    srnRouteLineList,
    pending,
    handleIconClick,
    handleInputPaymentMode,
    paymentModeList,
  } = props;

  const onInvoiceNumberSelection = (formattedValue, name) => {
    handleInputChange({ formattedValue, target: { name } });
  };

  const distributorSpecificCustomerList = customerList.filter(
    customer => customer.distributorId === data.Distributor.id || customer.id === LEDGERS.CASH.id,
  );
  const isBillingUser = distributorServices?.billing?.status;
  const paymentModeType = paymentModeList?.find(a => a?.alias === data?.paymentMode)?.type;
  return (
    <Form className="grn-form disabled">
      <Row>
        <Col md={9}>
          <Row>
            {!subDUser && (
              <Fragment>
                <Col className="mb-24" md={8}>
                  <CustomSelect
                    enableValidation
                    labelContent="Distributor"
                    options={distributorList}
                    className="custom-select"
                    placeholder="Select Distributors"
                    getOptionValue={({ id }) => id}
                    getOptionLabel={({ title }) => title}
                    enableErrorDisplay={enableErrorDisplay}
                    ref={ref => (refsObj.distributorId = ref)}
                    onChange={event => handleDropDownChange(event.id, ['Distributor', 'id'], 'distributor', event)}
                    value={distributorList.filter(({ id }) => id === data.Distributor.id)}
                  />
                </Col>
              </Fragment>
            )}
            {pending ? (
              <Col md={4} className="mb-24">
                <Input name="salesInvoiceNumber" value={data.salesInvoiceNumber} labelContent="Sales Invoice Number" />
              </Col>
            ) : (
              <Col className="mb-48" md={4}>
                <div className="grn-label-text">
                  <CustomAutoComplete
                    name="salesInvoiceNumber"
                    onChange={handleInputChange}
                    dropDownList={invoiceNumberList}
                    labelContent="Sales Invoice Number"
                    dropDownValueKey={null}
                    dropDownDisplayKey={null}
                    disabled={loadingInvoiceStatus}
                    onDropDownSelection={onInvoiceNumberSelection}
                  />
                  {invoiceNumberStatus && (
                    <div className="success-label">
                      <Icon iconName="check-circle-full" />
                      <span>Valid Number</span>
                    </div>
                  )}
                </div>
              </Col>
            )}
            <Col className="mb-24" md={4}>
              <Input enableValidation labelContent="Outlet" disabled={pending} value={data.RetailOutlet.title} />
            </Col>
            {srnRouteLineList && srnRouteLineList.length > 0 && (
              <Fragment>
                {srnRouteLineList.length === 1 ? (
                  <Col className="mb-24" md={4}>
                    <Input
                      disabled
                      name="line"
                      type="text"
                      value={srnRouteLineList[0] && srnRouteLineList[0].title}
                      labelContent="Route Segmentation"
                      placeholder="Route Segmentation"
                    />
                  </Col>
                ) : (
                  <Col className="mb-24" md={4}>
                    <CustomSelect
                      enableValidation
                      options={srnRouteLineList}
                      labelContent="Route Segmentation"
                      className="custom-select"
                      placeholder="Select Line"
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ title }) => title}
                      ref={ref => (refsObj.lineId = ref)}
                      enableErrorDisplay={enableErrorDisplay}
                      disabled={update.type === EVENT_OPERATION.APPROVE || invoiceNumberList.length > 0}
                      onChange={event => handleDropDownChange(event.id, ['lineId'])}
                      value={srnRouteLineList.filter(({ id }) => id === data.lineId) || []}
                    />
                  </Col>
                )}
              </Fragment>
            )}
            {isBillingUser && (
              <Col md={4} className=" payment-mode">
                <CustomSelect
                  small
                  className="custom-select"
                  options={paymentModeList}
                  placeholder="Select Payment mode"
                  getOptionValue={({ alias }) => alias}
                  getOptionLabel={({ title }) => title}
                  onChange={event => handleInputPaymentMode(event?.alias, 'paymentMode')}
                  value={paymentModeList?.filter(d => d.alias === data?.paymentMode)}
                  isSearchable
                  isClearable
                  disabled
                  enableErrorDisplay={isBillingUser && enableErrorDisplay}
                  ref={isBillingUser ? ref => (refsObj.paymentMode = ref) : null}
                  labelContent="Payment Mode"
                />
              </Col>
            )}
            {isBillingUser ? (
              <Col className="mb-24" md={4}>
                <CustomSelectWithBtn
                  name="customerId"
                  options={distributorSpecificCustomerList}
                  placeholder="Select Customer Ledger"
                  labelContent="Select Customer Ledger"
                  onChange={event => handleDropDownChange(event.id, ['Customer', 'id'], 'customer')}
                  value={distributorSpecificCustomerList.filter(({ id }) => id === data.Customer.id)}
                  handleIconClick={handleIconClick}
                  data={data}
                  disabled={!data.RetailOutlet.id}
                  enableErrorDisplay={
                    ![PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType) && isBillingUser
                      ? enableErrorDisplay
                      : false
                  }
                  enableValidation={![PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType)}
                  ref={
                    ![PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType) && isBillingUser
                      ? ref => (refsObj.ledgerId = ref)
                      : null
                  }
                />
              </Col>
            ) : (
              <Col md={4}>
                <CustomSelectWithBtn
                  name="customerId"
                  options={distributorSpecificCustomerList}
                  placeholder="Select Customer Ledger"
                  labelContent="Select Customer Ledger"
                  enableErrorDisplay={isBillingUser ? enableErrorDisplay : false}
                  onChange={event => handleDropDownChange(event.id, ['Customer', 'id'], 'customer')}
                  value={distributorSpecificCustomerList.filter(({ id }) => id === data.Customer.id)}
                  handleIconClick={handleIconClick}
                  data={data}
                  disabled={!data.RetailOutlet.id}
                  enableValidation
                  ref={isBillingUser ? ref => (refsObj.ledgerId = ref) : null}
                />
              </Col>
            )}
            <Col className="mb-24" md={4}>
              <Input
                disabled
                name="billName"
                type="text"
                value={data.customerName}
                labelContent="Bill Name"
                placeholder="Bill Name"
              />
            </Col>
            <Col className="mb-24" md={4}>
              <Input
                disabled
                name="address"
                type="text"
                value={data.Customer.address}
                labelContent="Address"
                placeholder="Address"
              />
            </Col>
            <Col className="mb-24" md={4}>
              <Input
                disabled
                name="panNumber"
                type="number"
                value={data.Customer.panNumber}
                labelContent="PAN"
                placeholder="PAN Number"
              />
            </Col>
            <Col className="mb-24" md={8}>
              <Input
                disabled
                labelContent="Remarks"
                name="reason"
                type="text"
                value={data.reason}
                placeholder="Add Remarks"
              />
            </Col>
            <Col className="m-24" md={4}>
              <div className="single-date-picker" style={{ marginTop: 0 }}>
                <label>SRN Date</label>
                <SingleDatePicker
                  name="srnDate"
                  date={data.srnDate}
                  maxDate={getTodaysDate()}
                  onChange={(name, date) =>
                    handleInputChange({
                      target: {
                        name: 'srnDate',
                      },
                      formattedValue: date,
                    })
                  }
                />
              </div>
            </Col>
            {isBillingUser || (
              <Col md={3}>
                <div className="info payment-mode">
                  <label htmlFor="" style={{ display: 'block', marginBottom: 12 }}>
                    Payment Mode
                  </label>
                  <CheckBox
                    className="ledger-info"
                    labelText="Cash"
                    checked={data.cashStatus}
                    onCheckBoxClick={() => {
                      handleInputChange({
                        formattedValue: !data.cashStatus,
                        target: {
                          name: 'cashStatus',
                          value: 'boolean',
                          checked: !data.cashStatus,
                        },
                      });
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

SrnSkuForm.propTypes = propTypes;

SrnSkuForm.defaultProps = defaultProps;

export default SrnSkuForm;
