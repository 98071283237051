import { compose } from 'redux';
import Table from './Table';
import { CHECK_SKU_STOCK_WITH_BATCH } from '../API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedPrnTable = compose(
  withRequestTracker({ query: { checkSkuStockWithBatch: CHECK_SKU_STOCK_WITH_BATCH } }),
)(Table);

export default composedPrnTable;
