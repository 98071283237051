import styled from 'styled-components';

const MonthlyWrap = styled.div`
  .custom-display {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .section-title {
      width: 100%;
    }

    .pointer {
      cursor:pointer
    }
  }
`;

export default MonthlyWrap;
