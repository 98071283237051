import { useEffect, useState } from 'react';
import Alert from './Alert';
import { AlertPosition } from './AlertStyled';
import { ALERT_CONFIG as config, ALERT_TYPE } from './config';

const AlertBaseComponent = ({ ...props }) => {
  const [show, setShow] = useState(false);
  const [type, setType] = useState(ALERT_TYPE.INFO);
  const [message, setMessage] = useState('this is the info');

  const resetAlert = () => {
    setShow(false);
    setType(type);
    setMessage('');
  };

  const attachTimeOut = () => {
    setTimeout(() => {
      resetAlert();
    }, config[type].timeout);
  };

  const handleAlertChange = (event: any) => {
    const { type: alertType, message: alertMessage } = event.detail;
    setShow(true);
    setType(alertType);
    setMessage(alertMessage);
    attachTimeOut();
  };

  useEffect(() => {
    window.addEventListener('rmap-alert', handleAlertChange);
  });

  return (
    <>
      {show && (
        <AlertPosition>
          <Alert
            type={type}
            active={show}
            onChange={resetAlert}
            iconName={config[type].iconName}
            title={config[type].title}
          >
            {message}
          </Alert>
        </AlertPosition>
      )}
    </>
  );
};

export default AlertBaseComponent;
