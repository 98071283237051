import React, { Fragment } from 'react';
import { numberPresentor } from '../../../utils/conversion';
import TargetDetailWrapper from '../../salesForce/target-Achievement/details/TargetDetailWrapper';
import { dataProps } from '../../../data/enums/Component';
import AccordionTable from '../../../components/CollapsibleTable/AccordionTable';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
};
const defaultProps = {
  data: dataProps.defaultValue,
};

const SubDTargetAchievementView = ({ ...props }) => {
  const { data, settings, targetDimensionMetric } = props;
  const tableData = (childUser, collapsible = false) => (
    <>
      <td>
        <>
          {collapsible ? <span>{childUser?.name}</span> : childUser?.name}
          {childUser?.active ||
            <b>
              <span className={`status-label passive`}>
                {'INACTIVE'}
              </span>
            </b>
          }
        </>
      </td>
      <td className="text-right width-150">{numberPresentor(childUser?.target)}</td>
      <td className="text-right width-150">{numberPresentor(childUser?.achievement)}</td>
      <td className="text-right width-150">{childUser?.target === 0 ? 0 : numberPresentor((childUser?.achievement * 100) / childUser?.target)}%</td>
    </>
  );

  const getOutletView = (dse) => (
    <Fragment>
      <tr className='tr-head'>
        <th>Outlet</th>
        <th>Town</th>
        <th>Channel</th>
        <th>Category</th>
        <th className='text-right'>Amount (in {targetDimensionMetric?.toUpperCase()})</th>
      </tr>
      {dse?.map?.((data) => {
        const itemArr = Object.values(data);
        itemArr.pop();
        const show = itemArr?.every(a => a !== null)
        return (
          <tr>
            <td>{data?.name}</td>
            <td>{data?.town}</td>
            <td>{data?.channel}</td>
            <td>{data?.category}</td>
            <td>{numberPresentor(data?.achievement)}</td>
          </tr>
        )
      })}
    </Fragment>
  );

  const getCollapsibleComponent = (dataList = [], settings = {}) => {
    return (
      <AccordionTable>
        {dataList?.map?.((user) => (
          <tr
            key={user?.id}
            trigger={
              <>
                <td>
                  <span>{user?.name}</span>
                </td>
                <td className='text-right width-150'>{numberPresentor(user?.target)}</td>
                <td className='text-right width-150'>{numberPresentor(user?.achievement)}</td>
                <td className='text-right width-150'>{user?.target === 0 ? 0 : numberPresentor((user?.achievement * 100) / user?.target)}%</td>
              </>
            }
            isChildPresent={!(user?.users?.length > 0)}
            simpleChild={
              <tr className='active' key={user?.id}>
                {tableData(user)}
              </tr>
            }
          >
            <AccordionTable>
              {user?.users?.map?.((childUser1) => {
                const childUserExist = childUser1?.outlets?.length > 0;
                return (
                  <tr
                    key={childUser1?.id}
                    trigger={tableData(childUser1, childUserExist)}
                    isChildPresent={!childUserExist}
                    simpleChild={
                      <tr className='active' key={childUser1?.id}>
                        {tableData(childUser1)}
                      </tr>
                    }
                  >
                    {getOutletView(childUser1?.outlets || [])}
                  </tr>
                );
              })}
            </AccordionTable>
          </tr>
        ))}
      </AccordionTable>
    )
  };
  return (
    <TargetDetailWrapper>
      <div className='table-wrap table-wrap-second'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-right width-150">{`Target (in `}
                <span>{targetDimensionMetric}</span>
                {`)`}</th>
              <th className="text-right width-150">{`Achievement (in `}
                <span>{targetDimensionMetric}</span>
                {`)`}</th>
              <th className="text-right width-150">Achievement %</th>
              {/*  {settings.brandWise && <th />} */}
            </tr>
          </thead>
          <tbody>{getCollapsibleComponent(data, settings)}</tbody>
        </table>
      </div>
    </TargetDetailWrapper>
  );
};

SubDTargetAchievementView.propTypes = propTypes;

SubDTargetAchievementView.defaultProps = defaultProps;

const LoginViewWithErrorAndLoadingSubdView = withErrorAndLoadingScreen(SubDTargetAchievementView);

export default LoginViewWithErrorAndLoadingSubdView;
