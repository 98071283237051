import styled from 'styled-components';

const ICODetailStyled = styled.div`

  height: 100%;
  .panel-section {
    height: calc(100% - 30px);
  }
  .status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .form-input {
      margin-left: 12px;
    }
  }
  .panel-section {
    display: flex;
    .panel {
      background-color: #ffffff;
      border-radius: 4px;
      .p-title {
        margin: 0;
        padding: 24px;
        font-size: 10px;
        line-height: 10px;
        color: #6b6c7e;
        text-transform: uppercase;
      }
      ul {
        height: calc(100vh - 238px);
        overflow-y: auto;
        padding: 0 12px;
        li {
          padding: 9px 12px;
          font-size: 12px;
          line-height: 15px;
          font-weight: 500;
          color: #6b6c7e;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          text-transform: capitalize;
          p {
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 125px;
          }
          .light {
            margin-right: 16px;
          }

          &.active {
            background-color: #f7f8f9;
            color: #272833;
            border-radius: 4px;
            position: relative;
            line-height: 20px;
            &:after {
              content: '';
              position: absolute;
              top: 13px;
              right: 12px;
              display: block;
              width: 12px;
              height: 12px;
              border-top: 2px solid #0b5fff;
              border-left: 2px solid #0b5fff;
              -webkit-transform: rotate(135deg);
              -ms-transform: rotate(135deg);
              transform: rotate(135deg);
              border-radius: 2px 2px 0 2px;
            }
          }
          &:hover {
            cursor: pointer;
          }
          &.inactive {
            &:hover {
              color: #0b5fff;
            }
          }
        }
      }
      &.two,
      &.one {
        width: 215px;
        display: inline-block;
        margin-right: 4px;
      }

      /* third panel styling only */
      &.three {
        flex: 1;
        .panel-title {
          padding: 14px 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .p-title {
            margin: 0;
            padding: 0;
          }
        }
        /* only for table inside third panel */
        .table-wrap {
          max-height: unset;
          height: calc(100vh - 238px);
          overflow-y: auto;
          border-radius: 0 0 4px 4px;
          .simple-popup {
            color: #0b5fff;
            .filter-dropdown-card {
              right: -5px;
              z-index: 1;
              min-width: 326px;
              top: calc(100% - -9px);
              .arrow-up {
                right: 4px;
              }
              .filter-label {
                font-size: 14px;
                line-height: 21px;
                .icon {
                  margin-left: 18px;
                }
              }
              .filter-content {
                padding: 3px 0 0 0;
                color: #6b6c7e;
                font-size: 10px;
                line-height: 12px;
                text-align: left;
                .pop-up-sku {
                  span {
                    display: flex;
                    margin-top: 12px;
                  }
                }
              }
            }
          }
          table {
            border: 0;
            td,
            th {
              &:first-child {
                padding: 7px 12px 7px 24px;
                border-left: 0;
              }
              &:last-child {
                padding: 7px 24px 7px 12px;
                border-right: 0;
              }
            }
            .switch {
              span {
                position: relative;
                top: -7px;
              }
              & > div {
                display: inline-block;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
`;

export default ICODetailStyled;
