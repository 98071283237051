import React, { Component } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import { formConfig } from './config';
import ModalWrapper from '../../common/DialogFormWrapper';
import Confirmation from '../../common/DialogConfirmation';
import withAlert from '../../../utils/composition/withAlert';
import { extractSingleItemFromList } from '../../../utils/arrayProcessor';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  handleRequest: PropTypes.func,
  render: PropTypes.func.isRequired,
  status: PropTypes.string,
  serverResponseWaiting: PropTypes.bool,
  Buttons: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: [],
  handleRequest: () => null,
  status: 'PENDING',
  serverResponseWaiting: false,
  Buttons: [
    {
      label: 'Reject',
      status: EVENT_OPERATION.REJECTED,
    },
    {
      label: 'Approve',
      status: EVENT_OPERATION.APPROVED,
    },
  ],
};

class RequestBulkSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedList: [],
      dialog: {
        type: '',
        element: {},
      },
    };
  }

  handlePrimaryCheckboxClick = e => {
    const { data, status } = this.props;
    if (e.target.checked) {
      const filteredList = data.filter(item => item.status === status);
      const checkedList = extractSingleItemFromList(filteredList);
      this.setState({ checkedList });
    } else {
      this.setState({ checkedList: [] });
    }
  };

  handleSecondaryCheckboxClick = id => {
    const { checkedList } = this.state;
    const index = checkedList.indexOf(id);
    if (index > -1) {
      checkedList.splice(index, 1);
    } else {
      checkedList.push(id);
    }
    this.setState({ checkedList });
  };

  handleCheckList = () => {
    this.setState({ checkedList: [] });
  };

  onButtonClick = status => {
    const { checkedList } = this.state;
    this.setState({
      dialog: {
        type: status,
        element: { data: checkedList, status },
      },
    });
  };

  onFormSubmit = status => {
    const { checkedList } = this.state;
    const { handleRequest } = this.props;
    handleRequest(status, checkedList, this.handleCheckList);
  };

  handleClose = () => {
    this.setState({ dialog: { type: '', element: '' }, checkedList: [] });
  };

  render() {
    const { data, serverResponseWaiting, render, Buttons } = this.props;
    const { checkedList, dialog } = this.state;
    const { type, element } = dialog;

    return (
      <>
        {type && (
          <ModalWrapper
            type={type}
            dialogData={element}
            formConfig={formConfig}
            onDialogCancel={this.handleClose}
            onDialogSubmit={this.onFormSubmit}
            renderDialog={() => <>{Confirmation(element.status, checkedList.length > 1 ? checkedList.length : null)}</>}
          />
        )}
        <View
          checkedListLength={checkedList.length}
          dataLength={data.length}
          onIconClick={this.onButtonClick}
          loading={serverResponseWaiting}
          Buttons={Buttons}
        />
        {render(this.state, this.handlePrimaryCheckboxClick, this.handleSecondaryCheckboxClick)}
      </>
    );
  }
}
RequestBulkSelect.propTypes = propTypes;

RequestBulkSelect.defaultProps = defaultProps;

export default withAlert()(RequestBulkSelect);
