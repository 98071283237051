import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT, UPLOAD } from '../../../common/GqlAPI';

const GET_OUTLET_COVERAGE_TARGETS = gql`
  query getOutletCoverageTargets($offset: Int, $limit: Int) {
    outletCoverageTargets(offset: $offset, limit: $limit) {
      rows {
        id
        title
        startDate
        endDate
        target
      }
      count
    }
  }
`;

const CREATE_OUTLET_COVERAGE_TARGET = graphql(
  gql`
    mutation createOutletCoverageTarget($input: OutletCoverageTargetInput!) {
      createOutletCoverageTarget(input: $input) {
        id
        title
        startDate
        endDate
        target
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createOutletCoverageTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_OUTLET_COVERAGE_TARGET = graphql(
  gql`
    mutation updateOutletCoverageTarget($id: Int!, $input: OutletCoverageTargetInput!) {
      updateOutletCoverageTarget(id: $id, input: $input) {
        id
        title
        startDate
        endDate
        target
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateOutletCoverageTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getOutletCoverageTargets: GET_OUTLET_COVERAGE_TARGETS,
};

const mutation = {
  upload: UPLOAD,
  downloadReport: DOWNLOAD_REPORT,
  createOutletCoverageTarget: CREATE_OUTLET_COVERAGE_TARGET,
  updateOutletCoverageTarget: UPDATE_OUTLET_COVERAGE_TARGET,
};

export {
  query, mutation, UPLOAD, CREATE_OUTLET_COVERAGE_TARGET, UPDATE_OUTLET_COVERAGE_TARGET, DOWNLOAD_REPORT
};
