import { JWT } from '../../environment';

const setUser = () => {};

const setToken = () => {};

const getUser = () => {
  const user = localStorage.getItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME);

  return JSON.parse(user);
};

const getUserRole = () => {
  const user = localStorage.getItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME);
  const parsedUser = JSON.parse(user);

  return parsedUser ? parsedUser.roleId : null;
};

const getToken = () => {};

export {
  setUser, setToken, getToken, getUser, getUserRole
};
