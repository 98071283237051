export const EVENT_OPERATION = {
  READ: 'READ',
  SORT: 'SORT',
  MENU: 'MENU',
  SPLIT: 'SPLIT',
  CREATE: 'CREATE',
  ASSIGN: 'ASSIGN',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  UPLOAD: 'UPLOAD',
  UPLOADED: 'UPLOADED',
  APPROVE: 'APPROVE',
  APPROVED: 'APPROVED',
  DETAILS: 'DETAILS',
  DOWNLOAD: 'DOWNLOAD',
  DELIVERED: 'DELIVERED',
  DISPATCHED: 'DISPATCHED',
  BULK_DELETE: 'BULK_DELETE',
  UPDATE_STATUS: 'UPDATE_STATUS',
  REMOVE: 'UNASSIGN',
  REPLICATE: 'REPLICATE',
  EXTEND: 'EXTEND',
  CREATE_GROUP: 'CREATE_GROUP',
  REPLACED: 'REPLACED',
  REPLACE: 'REPLACE',
  SHARE: 'SHARE',
  SAVE: 'SAVE',
  RECEIVED: 'RECEIVED',
  RECONCILED: 'RECONCILED',
  DISHONORED: 'DISHONORED',
  CHANGE_STATUS: 'CHANGE_STATUS',
  CHEQUE_RECEIVED: 'CHEQUE_RECEIVED',
  DISCARDED: 'DISCARDED',
  IN_PROGRESS: 'IN_PROGRESS',
  STOP: 'STOPPED',
  BLACKLIST: 'BLACK_LISTED',
  RESET: 'RESET',
  READ_SUB_TYPE: 'READ_SUB_TYPE',
  SEND_MESSAGE: 'SEND_MESSAGE',
  REJECTED: 'REJECTED',
  CANCEL: 'CANCEL',
  EDIT: 'EDIT',
  ADD_SCOPE: 'ADD_SCOPE',
  REMOVE_SCOPE: 'REMOVE_SCOPE',
  VIEW: 'VIEW',
  REQUEST_ORDER: 'REQUEST_ORDER',
  REPLICATE_ALL: 'REPLICATE_ALL'
};

export const EVENT_OPERATION_MAPPER = {
  [EVENT_OPERATION.UPDATE]: 'EDIT',
  [EVENT_OPERATION.CREATE]: 'CREATE',
  [EVENT_OPERATION.APPROVE]: 'APPROVE',
  [EVENT_OPERATION.REPLICATE]: 'REPLICATE',
};
