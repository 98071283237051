import gql from 'graphql-tag';

const GET_REPORT_LIST = gql`
  query getReport($roleId: Int!, $filter: FilterInput) {
    getUserStateDetailList(roleId: $roleId, filter: $filter) {
      rows {
        id
        route_id
        name
        status
        distributor
        route
        loginTime: login_time
        scheduled
        successful
        unsuccessful
        remaining
        productivity
        target
        achievementValue: achievement_value
      }
      count
    }
  }
`;

const query = {
  getReport: GET_REPORT_LIST,
};

export { query };
