import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { CustomSelect, Input } from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { EMPTY, INVALID } from '../../../../../../data/enums/ErrorMessage';
import CustomSelectWithBtn from '../../../../../../components/CustomSelectWithBtn';
import { fixedFloatAndCommas } from '../../../../../../utils/conversion';
import { Switch, CheckBox } from '../../../../../../v4/components';
import { PAYMENT_MODE } from './config';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  vendorList: [],
  distributorList: [],
  handleDropDownChange: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const DetailForm = ({ ...props }) => {
  const {
    data,
    permission,
    update,
    refsObj,
    vendorList,
    customerList,
    handleIconClick,
    distributorList,
    dropdownCallBack,
    salesData,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleInputPaymentMode,
    paymentModeList,
  } = props;
  const billingVersion = salesData?.Distributor?.servicesUsed?.billing?.versionCode;
  const paymentModeType = paymentModeList?.find(a => a?.alias === data?.paymentMode)?.type;
  return (
    <>
      <Row>
        <Col md={12}>
          <Row className="invoice-form">
            {permission?.BILLING && (
              <Col md={4} className="payment-mode">
                <CustomSelect
                  small
                  className="custom-select"
                  options={paymentModeList}
                  placeholder="Select Payment mode"
                  getOptionValue={({ alias }) => alias}
                  getOptionLabel={({ title }) => title}
                  onChange={event => handleInputPaymentMode(event?.alias, 'paymentMode')}
                  value={paymentModeList?.filter(d => d.alias === data?.paymentMode)}
                  isSearchable
                  isClearable
                  enableErrorDisplay={permission.BILLING && enableErrorDisplay}
                  ref={permission.BILLING ? ref => (refsObj.paymentMode = ref) : null}
                  labelContent="Payment Mode"
                />
              </Col>
            )}
            <Col md={4}>
              <div className="add-ledger-wrap">
                <CustomSelectWithBtn
                  name="customerId"
                  enableValidation={![PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType)}
                  options={customerList}
                  placeholder="Select Customer Ledger"
                  labelContent="Select Customer Ledger"
                  enableErrorDisplay={
                    ![PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType) && permission.BILLING
                      ? enableErrorDisplay
                      : false
                  }
                  onChange={event => handleDropDownChange(event.id, ['Customer', 'id'])}
                  value={customerList.filter(({ id }) => id === data.Customer.id)}
                  handleIconClick={handleIconClick}
                  data={data}
                  ref={
                    ![PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType)
                      ? ref => (refsObj.customerId = ref)
                      : null
                  }
                  handleInputChange={handleInputChange}
                />
              </div>
            </Col>
            {data.Customer.id !== '' ? (
              <>
                {permission.BILLING && (
                  <>
                    <Col md={4}>
                      <div className="info mb-24">
                        <Input
                          type="text"
                          name="billName"
                          value={data?.billName}
                          placeholder="Bill Name"
                          labelContent="Bill Name"
                          disabled={
                            ![PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType) ||
                            data?.Customer?.name?.length > 0
                          }
                          enableErrorDisplay={
                            [PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType) && permission.BILLING
                              ? enableErrorDisplay
                              : false
                          }
                          enableValidation={[PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType)}
                          ref={
                            [PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType)
                              ? ref => (refsObj.billName = ref)
                              : null
                          }
                          onChange={event => handleInputChange(event)}
                        />
                      </div>
                    </Col>
                    {/* {billingVersion===1 &&  */}
                    <Col md={12}>
                      <div className="info">
                        <label>Abbrevated Invoice</label>
                        <Switch
                          className="ledger-info"
                          checked={data.isAbbreviatedInvoice}
                          onToggle={(checked, event) => {
                            handleInputChange({
                              formattedValue: !!checked,
                              target: {
                                name: 'isAbbreviatedInvoice',
                                type: 'boolean',
                                value: !!checked,
                              },
                            });
                          }}
                        />
                      </div>
                    </Col>
                  </>
                )}
                <Col md={4} className={permission.BILLING ? 'mt-24 mb-24' : ''}>
                  <div className="info">
                    <label>PAN</label>
                    <div className="ledger-info">{data.Customer.panNumber}</div>
                  </div>
                </Col>
                <Col md={4} className={permission.BILLING ? 'mt-24' : ''}>
                  <div className="info">
                    <label>Address</label>
                    <div className="ledger-info">{data.Customer.address}</div>
                  </div>
                </Col>
                {permission.BILLING && (
                  <>
                    <Col md={4} className="mt-24">
                      <div className="info">
                        <label>Credit Limit</label>
                        <div className="ledger-info">{fixedFloatAndCommas(data.Customer.creditLimit)}</div>
                      </div>
                    </Col>
                    <Col md={4} className="mt-24">
                      <div className="info">
                        <label>Credit Days</label>
                        <div className="ledger-info">{data.Customer.creditDay}</div>
                      </div>
                    </Col>
                    <Col md={4} className="mt-24">
                      <div className="info">
                        <label>Outstanding Amount</label>
                        <div className="ledger-info">
                          {data.Customer.outstandingAmount >= 0
                            ? `${fixedFloatAndCommas(data.Customer.outstandingAmount)}   Cr `
                            : `${fixedFloatAndCommas(data.Customer.outstandingAmount * -1)}  Dr `}
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </>
            ) : (
              <>
                {permission.BILLING && (
                  <>
                    <Col md={4} className="mb-24">
                      <div className="info">
                        <Input
                          type="text"
                          name="billName"
                          value={data.billName}
                          placeholder="Bill Name"
                          labelContent="Bill Name"
                          disabled={
                            ![PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType) ||
                            data?.Customer?.name?.length > 0
                          }
                          enableErrorDisplay={
                            [PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType) && permission.BILLING
                              ? enableErrorDisplay
                              : false
                          }
                          enableValidation={[PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType)}
                          ref={
                            [PAYMENT_MODE?.CASH, PAYMENT_MODE?.COD].includes(paymentModeType)
                              ? ref => (refsObj.billName = ref)
                              : null
                          }
                          onChange={event => handleInputChange(event)}
                        />
                      </div>
                    </Col>
                    {/* {billingVersion===1 &&  */}
                    <Col md={4}>
                      <div className="info">
                        <label>Abbrevated Invoice</label>
                        <Switch
                          className="ledger-info"
                          checked={data.isAbbreviatedInvoice}
                          onToggle={(checked, event) => {
                            handleInputChange({
                              formattedValue: !!checked,
                              target: {
                                name: 'isAbbreviatedInvoice',
                                type: 'boolean',
                                value: !!checked,
                              },
                            });
                          }}
                        />
                      </div>
                    </Col>
                    {/* } */}
                  </>
                )}{' '}
              </>
            )}
          </Row>
          <Row className="invoice-form">
            <Col md={4}>
              <Input
                type="text"
                name="remarks"
                enableValidation
                value={data.remarks}
                placeholder="Remarks"
                labelContent="Remarks"
                ref={ref => (refsObj.remarks = ref)}
                enableErrorDisplay={enableErrorDisplay && permission.BILLING}
                onChange={event => handleInputChange(event)}
              />
            </Col>

            {!permission.BILLING && (
              <Col md={4}>
                <Input
                  type="text"
                  enableValidation
                  name="invoiceNumber"
                  placeholder="Invoice Number"
                  value={data.invoiceNumber}
                  rule="matches"
                  argument={/^[0-9]+$/g} /* matches only numbers */
                  labelContent="Invoice Number"
                  ref={ref => (refsObj.invoiceNumber = ref)}
                  enableErrorDisplay={enableErrorDisplay && !permission.BILLING}
                  errorMessage={data.invoiceNumber ? INVALID : EMPTY}
                  onChange={event => handleInputChange(event)}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

DetailForm.propTypes = propTypes;

DetailForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(DetailForm);

export default LoginViewWithErrorAndLoading;
