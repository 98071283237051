import { compose } from 'recompose';
import TargetDistribution from './TargetDistribution';
import { query, mutation } from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { TOGGLE_STATE } from '../../../common/GqlAPI';
const ComposedTargets = compose(
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(TargetDistribution);

export default ComposedTargets;
