import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import MerchandiseDeviceView from './View';
import MerchandiseDeviceForm from './Form';
import { DialogWrapper } from '../../common';
import { refGenerator } from '../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { apiFilterProcessor, getOffsetFromPageAndLimit } from '../../../utils/api';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { CLIENT_STORAGE_TABLE, getDataFromLocalStorage } from '../../../data/services';
import {
  crudRequest as crudRequestConfig, breadCrumbConfig, formConfig, title, filterConfig,
} from './config';
import { clone } from '../../../utils/objectProcessor';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import * as queryService from '../../base/query.service';
import { getPermissionForMerchandisingDevices } from '../../base/permission';
import PageHeader from '../../base/PageHeader';
import { PanelStyled } from '../../common/configuration';
import { isError } from '../../common/HelperFunctions';
import MerchandiseDeviceStyled from './MerchandiseDeviceStyled';
import history from '../../../utils/history';
import { MERCHANDISE,MERCHANDISE_DETAILS } from '../../../data/enums/Route';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  getMerchandiseDeviceList: PropTypes.func.isRequired,
  createMerchandiseDevice: PropTypes.func.isRequired,
  updateMerchandiseDevice: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class MerchandiseDevice extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      showDialog: false,
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        // date: { ... queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      menu: {
        categoryList: [],
        brandList: [],
      },
    };
    this.permission = getPermissionForMerchandisingDevices();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };

    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createMerchandiseDevice,
      [EVENT_OPERATION.UPDATE]: props.updateMerchandiseDevice,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };

    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      // clear the checked list.
      this.setState({data:data,showDialog:false});
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
    }, this.getData, ['title', 'categoryIds', 'Categories', 'brandIds', 'Brands']);

    this.onFormSubmit = (operationType, data) => {
      handleFormSubmit(
        this.onCRUDSuccess,
        this.onAPIRequestFailure,
        crudRequestConfig,
        serverCall,
      )(operationType, data);
    };

    this.onDialogShowHide = (show) => {
      this.setState({showDialog:show});
    }

    this.formReference = refGenerator(formConfig.validationField);
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
    this.loadDataForDropDown();
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.POSMs
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.POSMs);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };


  loadTableData = () => {
    const { queryParameters, data } = this.state;
    const { getMerchandiseDeviceList } = this.props;
    const offset = getOffsetFromPageAndLimit(queryParameters.pagination);
    this.setFilterStateInStorage();
    getMerchandiseDeviceList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: (response) => {
          data.list = response.data.merchandiseDevices
            ? response.data.merchandiseDevices.rows || [] : [];
          data.total = response.data.merchandiseDevices
            ? response.data.merchandiseDevices.count || 0 : 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  }

  loadDataForDropDown = () => {
    const { menu } = this.state;
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.CATEGORY)
      .then((res) => {
        menu.categoryList = res;
        this.setState({ menu });
      });
    getDataFromLocalStorage(CLIENT_STORAGE_TABLE.BRAND)
      .then((res) => {
        menu.brandList = res;
        this.setState({ menu });
      });
  };

  setQueryParameters = (queryParams, callBack = () => null) => this.setState(
    { queryParameters: queryParams },
    callBack,
  );

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    this.setState({showDialog:true});
  };

  getCategoryTitle = (categoryId, index, length) => {
    const { menu } = this.state;
    const indexInList = menu.categoryList.findIndex(category => category.id === categoryId);
    if (indexInList > -1 && index === (length - 1)) {
      return `${menu.categoryList[indexInList].title}`;
    }
    if (indexInList > -1) {
      return `${menu.categoryList[indexInList].title},`;
    }

    return '';
  };

  getBrandTitle = (brandId, index, length) => {
    const { menu } = this.state;
    const indexInList = menu.brandList.findIndex(brand => brand.id === brandId);

    if (indexInList > -1 && index === (length - 1)) {
      return `${menu.brandList[indexInList].title}`;
    }

    if (indexInList > -1) {
      return `${menu.brandList[indexInList].title},`;
    }

    return '';
  };


  onIconClick = type => {
    history.push(`/${MERCHANDISE}/create`);
  };
  onTableRowClick = (id, type) => {
    history.push(`/${MERCHANDISE_DETAILS}/${id}`);
  };
  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  render() {
    const {
      data, queryParameters, menu, display,showDialog,filterMenu
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <MerchandiseDeviceStyled>
        <div className="merchandising-config-panel">
          <DialogWrapper
            onDialogSubmit={this.onFormSubmit}
            onDialogShowHide={this.onDialogShowHide}
            title=" POSMs"
            controlFromParent= {true}
            showDialog={showDialog}
            formConfig={formConfig}
            refsObj={this.formReference}
            renderDialog={
              (
                {
                  onDialogSubmit,
                  dialogData,
                  enableErrorDisplay,
                  handleDialogInputChange,
                  handleDialogDropDownChange,
                  handleDialogAutoCompleteChange,
                },
              ) => (
                <Fragment>
                  <MerchandiseDeviceForm
                    show
                    data={dialogData}
                    refsObj={this.formReference}
                    onFormSubmit={onDialogSubmit}
                    loading={serverResponseWaiting}
                    categoryList={menu.categoryList}
                    brandList={menu.brandList}
                    enableErrorDisplay={enableErrorDisplay}
                    handleInputChange={handleDialogInputChange}
                    handleDropDownChange={handleDialogDropDownChange}
                    onAutoCompleteChange={handleDialogAutoCompleteChange}
                  />
                </Fragment>
              )}
            render={({ onDialogItemClick }) => (
              <Fragment>
                <div className="section-header">
                  <PanelStyled>
                    <PageHeader
                      display={display}
                      breadCrumb={breadCrumbConfig}
                      config={{
                        title,
                        create: this.permission.create,
                        download: false,
                        filter: true,
                        search: true,
                      }}
                      filter={{
                        date: queryParameters.date,
                        menuList: filterMenu,
                        onFilterChange: this.basePaginationService.handleFilterChange,
                      }}
                      queryParameters={queryParameters}
                      controlDisplay={this.controlDisplay}
                      handleCreateClick={this.onIconClick}
                      resetFilter={this.basePaginationService.resetFilter}
                      clearSearchText={this.basePaginationService.clearSearchText}
                      handleSearchChange={this.basePaginationService.handleSearchInputChange}
                      handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                      handleDownloadClick={this.basePaginationService.handleDownloadClick}
                    />
                  </PanelStyled>
                </div>
                <div className="section-content table-present">
                  <MerchandiseDeviceView
                    data={data}
                    pagination={queryParameters.pagination}
                    loading={serverResponseWaiting}
                    onPageChange={this.basePaginationService.onPageSelect}
                    getCategoryTitle={this.getCategoryTitle}
                    getBrandTitle={this.getBrandTitle}
                    onBulkDelete={this.handleBulkDelete}
                    permission={this.permission}
                    onIconClick={onDialogItemClick}
                    onTableRowClick={this.onTableRowClick}
                  />
                </div>
              </Fragment>
            )}
          />
        </div>
      </MerchandiseDeviceStyled>
    );
  }
}

MerchandiseDevice.propTypes = propTypes;

MerchandiseDevice.defaultProps = defaultProps;

export default withAlert()(MerchandiseDevice);
