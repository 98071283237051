import gql from 'graphql-tag';
import {
  DOWNLOAD_REPORT, GET_DISTRIBUTORS, UPLOAD
} from '../../common/GqlAPI';
import { SKU_FRAGMENT } from '../../configuration/sku/API';

const OPENING_BALANCE_FRAGMENT = gql`
  fragment OpeningBalanceFragment on OpeningBalance {
    id
    SKU {
      id
      title
    }
    distributorId
    balance
  }
`;

const GET_OPENING_BALANCE_LIST = gql`
  query getOpeningBalanceList($offset: Int, $limit: Int, $filter: FilterInput) {
    openingBalance(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...OpeningBalanceFragment
      }
      count
    }
  }
  ${OPENING_BALANCE_FRAGMENT}
`;

const GET_OPENING_BALANCE_BATCH_DETAIL = gql`
  query getOpeningBalanceBatchDetail($distributorId: Int, $skuId: Int) {
    getOpeningBalanceBatchDetail(distributorId: $distributorId, skuId: $skuId) {
      distributorId: distributor_id
      skuId: sku_id
      skuBatchId: sku_batch_id
      balance
      SKUBatch {
        id
        batchName
        manufactureDate
        expiryDate
      }
    }
  }
`;

const query = {
  getDistributors: GET_DISTRIBUTORS,
  getOpeningBalanceList: GET_OPENING_BALANCE_LIST,
  getOpeningBalanceBatchDetail: GET_OPENING_BALANCE_BATCH_DETAIL,
};
const mutation = { upload: UPLOAD, downloadReport: DOWNLOAD_REPORT };

export { query, mutation };
