/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Button } from '../../../../../components';
import PageHeader from '../../../../base/PageHeader';
import { DialogFormWrapper } from '../../../../common';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { formConfig } from '../config/config';
import { LinkedDevicesStyled } from './LinkedDevicesStyled.jsx';
import { TableHeader, TableBody } from './tableConfig.view.jsx';
import useLinkedDeviceContoller from '../Controllers/useLinkedDevicesListController';
import { LINKED_DEVICES_ADD } from '../../../../../data/enums/Route';
import history from '../../../../../utils/history';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import withAlert from '../../../../../utils/composition/withAlert';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const LinkedDevicesList = (props) => {
  const {
    dialog,
    handleFormSubmit,
    handleDialogClose,
    totalItems,
    data,
    handleIconClick,
    basePaginationService,
    queryParameters,
    serverResponseWaiting,
    enableDateParamOnlyOnManualDateChange,
    permission,
  } = useLinkedDeviceContoller(props);

  const dialogEventType =
    dialog.type === EVENT_OPERATION.DELETE
      ? EVENT_OPERATION.DELETE
      : dialog.type === EVENT_OPERATION.BLOCK_DEVICE
        ? EVENT_OPERATION.BLOCK_DEVICE
        : dialog.type === EVENT_OPERATION.UNBLOCK_DEVICE
          ? EVENT_OPERATION.UNBLOCK_DEVICE
          : '';

  const parentBtn = () => (
    <Button
      iconName='qr-code'
      primary
      small
      title='Scan'
      onClick={() => history.push(`/${LINKED_DEVICES_ADD}`)}
      className='qr-code-button'
    />
  );

  return (
    <LinkedDevicesStyled>
      {dialog.type && (
        <DialogFormWrapper
          type={dialog.type}
          dialogElement={dialog.element}
          onDialogSubmit={handleFormSubmit}
          formConfig={formConfig[dialog.type]}
          onDialogCancel={handleDialogClose}
          newEventType={dialogEventType}
          multipleDialog
          activeStatus
          renderDialog={() => (
            <>
              {dialog.type === EVENT_OPERATION.DELETE && (
                <p>{`Are you sure you want to delete ${dialog?.element?.data?.deviceName}?`} </p>
              )}

              {dialog.type === EVENT_OPERATION.UNBLOCK_DEVICE && (
                <p>{`Are you sure you want to unblock ${dialog?.element?.data?.deviceName}?`} </p>
              )}

              {dialog.type === EVENT_OPERATION.BLOCK_DEVICE && (
                <p>{`Are you sure you want to block ${dialog?.element?.data?.deviceName}?`} </p>
              )}
            </>
          )}
        />
      )}
      <PageHeader
        config={{
          title: 'Linked Devices',
          filter: false,
          date: true,
          search: true,
        }}
        children={permission?.create ? parentBtn() : ''}
        queryParameters={queryParameters}
        // resetFilter={basePaginationService.resetFilter}
        clearSearchText={basePaginationService.clearSearchText}
        handleSearchChange={basePaginationService.handleSearchInputChange}
        handleDateRangeChange={(_,date) => {
          basePaginationService.handleDateRangeChange(null,date);
          enableDateParamOnlyOnManualDateChange();
        }}
        // handleDownloadClick={handleDownloadClick}
      />
      <div className='section-content table-present'>
        <div
          className={`table-wrap ${totalItems >= 150 ? 'pagination-present' : 'pagination-not-present'} custom-scroll`}
        >
          <table>
            {TableHeader({ onHeaderClick: basePaginationService.handleTableSorting })}
            <tbody>
              {data &&
                data.length > 0 &&
                data.map((deviceData) =>
                  TableBody({
                    data: deviceData,
                    handleIconClick: handleIconClick,
                    permission: permission,
                  }),
                )}
            </tbody>
          </table>
        </div>

        <Pagination
          currentPage={queryParameters.pagination.page}
          limit={queryParameters.pagination.limit}
          totalItems={totalItems}
          onPageChange={basePaginationService.onPageSelect}
        />
      </div>
    </LinkedDevicesStyled>
  );
};

LinkedDevicesList.propTypes = propTypes;

LinkedDevicesList.defaultProps = defaultProps;

export default withAlert()(withErrorAndLoadingScreen(LinkedDevicesList))
