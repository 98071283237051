import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RosterDialog from './Dialog';
import RosterReplicationDialog from './Dialog/ReplicationDialog';
import { DialogContext } from './config';
import { CALENDAR_PREVIEW } from '../../../data/enums';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import DeleteConfirmationDialog from './Dialog/DeleteConfirmationDialog';
import RosterStyled from './RosterStyled';

const propTypes = {
  closeChildDialog: PropTypes.func.isRequired,
  onUserClick: PropTypes.func,
  userId: PropTypes.number,
  calendarPreview: PropTypes.string,
  displayAlert: PropTypes.func.isRequired,
  update: PropTypes.func,
  loadData: PropTypes.func,
  create: PropTypes.func,
  roleId: PropTypes.number,
  removeRosterLine: PropTypes.func,
  children: PropTypes.node,
  userList: PropTypes.arrayOf(Object),
  routeList: PropTypes.arrayOf(Object),
  displayReplicationDialog: PropTypes.bool,
  onReplicationDialogSubmit: PropTypes.func,
  onReplicationDialogCancel: PropTypes.func,
  routeVisitFrequencyList: PropTypes.arrayOf(Object),
};

const defaultProps = {
  onUserClick: () => null,
  userId: null,
  calendarPreview: '',
  update: () => null,
  loadData: () => null,
  create: () => null,
  roleId: null,
  removeRosterLine: () => null,
  children: undefined,
  userList: [],
  routeList: [],
  displayReplicationDialog: false,
  onReplicationDialogSubmit: () => null,
  onReplicationDialogCancel: () => null,
  routeVisitFrequencyList: [],
};

class RosterDialogProcessor extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      displayDialog: false,
      date: '2012-01-01',
      crudMode: EVENT_OPERATION.CREATE,
      rosterId: null,
      routeId: null,
      visitFrequencyId: null,
      lineId: null,
      repetition: true,
      selectedRouteList: [], // selected Routes in Weekly and Monthly Unit
    };
  }

  componentDidMount() {
    this.bindMethodToParent();
  }

  closeDialog = () => {
    this.setState({ displayDialog: false });
  };

  bindMethodToParent = () => {
    const { closeChildDialog } = this.props;

    closeChildDialog(this.closeDialog);
  };

  // UPDATE, EDIT and READ.
  handleRosterComponentClick = event => {
    const { onUserClick, loadRouteList,userId,loadDSEList,onResetDialog} = this.props;
    onResetDialog()
    if (event.userId) {
      onUserClick(event.userId);
      loadRouteList(event.userId);
      loadDSEList(event.userId);
    }else{
    loadRouteList(userId);
    loadDSEList(userId);
  }
    this.setState(
      {
        routeId:null,
        displayDialog: true,
        crudMode: EVENT_OPERATION.CREATE,
      },
      () => {
        this.setState({
          date: event.date,
        });
      },
    );
  };

  /**
   * date, repetition, visitFrequencyId and routeId from Dialog Component
   * userId: From the activated DSE.
   * @param data
   */
  handleFormSubmit = data => {
    const { userId: stateUserId, calendarPreview } = this.props;

    this.handleDialogClose();

    const { rosterId, crudMode } = this.state;

    if (crudMode === EVENT_OPERATION.CREATE) {
      const userId = calendarPreview === CALENDAR_PREVIEW.WEEKLY ? data.userId : stateUserId;
      this.createRoster(userId, data);
    } else {
      this.updateRoster(rosterId, data);
    }
  };

  updateRoster = (id, data) => {
    const { date } = this.state;
    const { update, displayAlert, loadData,calendarPreview } = this.props;
    const userId = calendarPreview === CALENDAR_PREVIEW.WEEKLY ? data.userId : stateUserId;

    update(
      {
        id,
        input: {
          repetition: data.repetition || false,
          lineId: data.lineId,
          visitFrequencyId: data.visitFrequencyId,
          routeId: data.routeId,
          rosterDate:date,
          userId,
        },
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Updated');
          loadData();
          this.handleDialogClose();
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  createRoster = (userId, data) => {
    const { date } = this.state;
    const { create, displayAlert } = this.props;
    const { loadData } = this.props;
    create(
      {
        input: {
          lineId: data.lineId,
          routeId: data.routeId,
          repetition: data.repetition,
          visitFrequencyId: data.visitFrequencyId,
          userId,
          rosterDate:date,
        },
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Created');
          loadData();
          this.handleDialogClose();
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  handleDialogClose = () => {
    const {onResetDialog} = this.props;
    this.setState({
      displayDialog: false,
      routeId: null,
      visitFrequencyId: null,
      repetition: true,
    });
    onResetDialog()
  };

  // Monthly View, user click: fetch All Data
  handleUserClick = (user, calendarPreview = CALENDAR_PREVIEW.MONTHLY) => {
    // load the necessary data.
    const { onUserClick } = this.props;
    this.handleDialogClose();
    const requireDataLoading = calendarPreview === CALENDAR_PREVIEW.MONTHLY;
    onUserClick(user.id, requireDataLoading);

    /** this.setState({
      userId: user.id,
      userTitle: user.title || userTitle,
    }, () => {
      this.handleDialogClose();
      loadData(user.id);
    }); */
  };

  removeRosterLine = callRosterId => {
    const { removeRosterLine, displayAlert, loadData } = this.props;

    const rosterLine = {
      ids: [callRosterId],
      type: 'SalesForceRoster',
      active: false,
      fieldName: 'active',
    };

    this.handleDialogClose();

    removeRosterLine(
      { ...rosterLine },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully Removed');
          loadData();
          this.handleDialogClose();
        },
        handleFailure: () => {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Failed');
          // this.handleDialogClose()
        },
      },
    );
  };

  /**
   * @param operation: EVENT_OPERATION.UPDATE or Remove
   * @param rosterLine
   */
  handleRosterLineIconClick = (operation, rosterLine) => {
    // if iconName: edit and weeklyEdit

    const { calendarPreview, onUserClick,loadRouteList } = this.props;

    if (calendarPreview === CALENDAR_PREVIEW.WEEKLY) {
      onUserClick(rosterLine.user.id);
    }

    const { date, routeId, visitFrequencyId, lineId,user } = rosterLine;

if (operation === 'edit') {
      loadRouteList(user.id);
      this.setState({
        crudMode: EVENT_OPERATION.UPDATE,
        displayDialog: true,
        rosterId: rosterLine.id || null,
        date,
        routeId,
        lineId,
        visitFrequencyId,
        repetition: !!rosterLine.creationIdentifier, // defines if repetition or not
        selectedRouteList: rosterLine.selectedRouteList,
      });
    }

    if (operation === 'delete') {
      this.setState({
        crudMode: EVENT_OPERATION.DELETE,
        displayDialog: true,
        rosterId: rosterLine.id || null,
        date,
        routeId,
        lineId: rosterLine.lineId,
        visitFrequencyId,
        selectedRouteList: rosterLine.selectedRouteList,
      });
    }
  };

  onDeleteDialogSubmit = () => {
    const { rosterId } = this.state;
    this.handleDialogClose();
    this.removeRosterLine(rosterId);
  };

  render() {
    const {
      children,
      userList,
      routeList,
      dseList,
      userId,
      displayReplicationDialog,
      onReplicationDialogSubmit,
      onReplicationDialogCancel,
      routeVisitFrequencyList,
      subDDetails,
      onDropDownClick,
      subDId,
      dseId,
    } = this.props;

    const { displayDialog, routeId, lineId, visitFrequencyId, date, crudMode, repetition, selectedRouteList } =
      this.state;

    const user = userList.find(usr => usr.id === userId);

    const userTitle = user ? `${user.fullName}` : '';
    const distributorList = user ? user.distributor : [];

    const route = routeList.find(rte => rte.id === routeId);

    const routeTitle = route ? route.title : '';

    const displayDeleteConfirmationDialog = crudMode === EVENT_OPERATION.DELETE && displayDialog;

    // filter the Route that has been selected
    const selectedRouteIdList = selectedRouteList.map(route => route.routeId);
    const filteredRouteList = routeList; // routeList.filter(route => !selectedRouteIdList.includes(route.id));
    const rosterComponent =
      displayDialog && crudMode !== EVENT_OPERATION.DELETE ? (
        <div className="roster-popup">
          <RosterDialog
            data={{
              userId,
              routeId,
              userTitle,
              repetition,
              visitFrequencyId,
              lineId,
              distributorList,
              subDId,
            }}
            crudMode={crudMode}
            routeList={routeList}
            displayDialog={displayDialog}
            onDialogSubmit={this.handleFormSubmit}
            onDialogClose={this.handleDialogClose}
            routeVisitFrequencyList={routeVisitFrequencyList}
            subDDetails={subDDetails}
            onDropDownClick={onDropDownClick}
            subDId={subDId}
            dseList={dseList}
            dseId={dseId}
          />
        </div>
      ) : null;

    return (
      <RosterStyled>
        <DialogContext.Provider
          value={{
            handleRosterComponentClick: this.handleRosterComponentClick,
            onCancelClick: this.handleDialogClose,
            onSubmit: this.handleFormSubmit,
            handleUserClick: this.handleUserClick,
            removeRosterLine: this.removeRosterLine,
            handleRosterLineIconClick: this.handleRosterLineIconClick,
            activeUser: userId,
            userList,
            activeDate: date,
            rosterComponent,
            displayDialog,
          }}
        >
          {children}
        </DialogContext.Provider>
        {displayReplicationDialog ? (
          <div className="modal-wrapper roster">
            <RosterReplicationDialog
              onDialogSubmit={onReplicationDialogSubmit}
              onDialogClose={onReplicationDialogCancel}
            />
          </div>
        ) : null}
        {displayDeleteConfirmationDialog ? (
          <DeleteConfirmationDialog
            userTitle={userTitle}
            routeTitle={routeTitle}
            onDialogCancel={this.handleDialogClose}
            onDialogSubmit={this.onDeleteDialogSubmit}
          />
        ) : null}
      </RosterStyled>
    );
  }
}

RosterDialogProcessor.propTypes = propTypes;

RosterDialogProcessor.defaultProps = defaultProps;

export default withAlert()(RosterDialogProcessor);
