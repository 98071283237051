import { CONFIRMATION_CONFIG, FORM_CONFIG } from '../../../../../data/enums/config';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { FILTER } from '../../../../../data/enums/filter';
import { OUTLET_TARGET_SUBD } from '../../../../../data/enums/Route';
import { refGenerator } from '../../../../../utils';
import { getFilterConfig } from '../../../../../utils/filter';
import { breadCrumbConfig } from '../config';
import moment from 'moment';

const Title = 'Amazon Distributor Patan';
const requiredList = ['distributorId', 'month', 'target'];
const filterConfig = getFilterConfig([FILTER.DSE_OUTLET, FILTER.CHANNEL]);

const formConfig = {
  [EVENT_OPERATION.READ]: {
    [FORM_CONFIG.MAPPER]: element => ({
      id: element.id,
      ulpo: element.ulpo,
      tls: element.tls,
      sbd: element.sbd,
      dgp: element.dgp,

      bill_cuts: element.bill_cuts,
      sales: element.sales,

      duration: {
        year: element.year,
        month: element.month,
      },
    }),
    [FORM_CONFIG.TITLE]: '',
    [EVENT_OPERATION.UPLOAD]: `${EVENT_OPERATION.READ} ${Title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['ulpo', 'duration']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: element => ({
      id: element.id,
      ulpo: element.ulpo,
      tls: element.tls,
      sbd: element.sbd,
      dgp: element.dgp,
      sales: element.sales,

      bill_cuts: element.bill_cuts,
      duration: {
        year: element.year,
        month: element.month,
      },
    }),
    [FORM_CONFIG.TITLE]: 'Edit',
    [EVENT_OPERATION.UPLOAD]: `${EVENT_OPERATION.UPDATE} ${Title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['ulpo', 'duration']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    buttonName: 'Update',
  },

  ...CONFIRMATION_CONFIG,
};

const get = key => {
  const value = localStorage.getItem(key);

  if (!value) {
    return;
  }

  return JSON.parse(value);
};

const set = (key, value) => {
  if (value) {
    localStorage?.setItem(key, JSON?.stringify(value));
  }
};
const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Sub D',
    path: `/${OUTLET_TARGET_SUBD}`,
    active: true,
  },
];

const dateFormat = _date => {
  const dateFormatter = moment(_date, 'YYYY/MM/DD');
  const formatDate = dateFormatter.format('ll');

  return formatDate;
};

export { formConfig, breadCrumb as breadCrumbConfig, Title as title, get, set, filterConfig, dateFormat };
