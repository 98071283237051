import React from 'react';
import { PropTypes } from 'prop-types';
import {
  getWeekAndMonthDay, getWeekDay, checkIfToday
} from '../date';

const propTypes = {
  /**
   * date string for header
   */
  date: PropTypes.string.isRequired,
  /**
   * @default []
   * weekEnds for given Sub D
   */
  weekEndList: PropTypes.instanceOf(Array),

  /**
   * @default []
   * holidayList
   */
  holidayList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  weekEndList: [],
  holidayList: [],
};

/**
 * @param date
 * @param weekEndList
 * @returns {string}
 */
const classNameImporter = (date, weekEndList, holiday) => {
  const weekDay = getWeekDay(date);

  const weekEndBackground = weekEndList.includes(weekDay) ? 'weekend' : '';

  const holidayBackground = holiday ? 'holiday' : '';

  return `calendar-week-title active ${weekEndBackground} ${holidayBackground}`;
};

const dateClassImporter = (date) => (checkIfToday(date) ? 'date active' : 'normal');

const Header = ({ date, weekEndList, holidayList }) => {
  const details = getWeekAndMonthDay(date);
  const holiday = holidayList.find((hol) => hol.date === date) || null;

  return (
    <div key={date} className={classNameImporter(date, weekEndList, holiday)}>
      <div>
        <span>{details.weekDayTitle}</span>
        <span className={dateClassImporter(date)}>{details.monthDay}</span>
        {holiday ? <span className='holiday-title'>{holiday.title}</span> : null}
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

Header.defaultProps = defaultProps;

export default Header;
