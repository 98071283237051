import React from 'react';
import PropTypes from 'prop-types';
import { formConfig as form, SHELF_LIFE_REQUIRED_TAGS } from './config';
import { Switch, Icon, CheckBox } from '../../../../v4/components';
import { Input, CustomSelect } from '../../../../components/index';
import { EMPTY, INVALID } from '../../../../data/enums/ErrorMessage';
import withLoading from '../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import FormContentStyled from '../../../../components/global-css/FormContentStyled';
import CreateSKUFormStyled from './SKUFormStyled';
import CustomCreateSelect from '../../../../components/CustomCreateSelect';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  crudMode: PropTypes.string,
  uomList: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {
  data: form.mapper({}),
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  crudMode: EVENT_OPERATION.READ,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
  handleDropdownChange: () => null,
};

const CreateSkuForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    handleDropDownChange,
    enableErrorDisplay,
    uomList,
    skuTagList,
    caseUnitList,
    vendorSbfList,
    line,
    routeSegmentationValidation,
  } = props;
  return (
    <CreateSKUFormStyled>
      <FormContentStyled>
        <h3 className="w-100">General Details</h3>
        <div className="form-input-wrap">
          <div className="scope">
            {data.discontinue_sku ? (
              <>
                <span>
                  <Icon iconName="info-panel-open" />
                  <b>Info:</b>
                  <p className="info-title">{'This SKU has been discontinued.'}</p>
                </span>
              </>
            ) : (
              <div />
            )}
          </div>
          <Input
            name="title"
            type="text"
            enableValidation
            value={data.title}
            labelContent="Name"
            placeholder="Name"
            ref={ref => (refsObj.title = ref)}
            enableErrorDisplay={enableErrorDisplay}
            onChange={event => handleInputChange(event)}
          />
          <Input
            type="text"
            name="description"
            placeholder="Manufacturer Description"
            enableValidation
            labelContent="Manufacturer Description"
            value={data.description}
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => (refsObj.manufactureDescription = ref)}
            onChange={event => handleInputChange(event)}
          />
          <Input
            type="text"
            name="shortDescription"
            placeholder="Short Description"
            labelContent="Short Description"
            value={data.shortDescription}
            onChange={event => handleInputChange(event)}
          />
          <Switch
            onToggle={(checked, event) => {
              handleInputChange({
                formattedValue: checked,
                target: { name: 'powerSku', type: 'boolean' },
              });
            }}
            checked={data.powerSku}
            name="powerSku"
            labelContent="PSKU"
          />
          <Switch
            onToggle={(checked, event) => {
              handleInputChange({
                formattedValue: checked,
                target: { name: 'free_sku', type: 'boolean' },
              });
            }}
            checked={data.free_sku}
            name="free_sku"
            labelContent="Free SKU"
          />
        </div>
      </FormContentStyled>
      <FormContentStyled>
        <h3 className="w-100">SKU Details</h3>
        <div className="form-input-wrap">
          {/* <Input
                ref={ref => refsObj.fpc = ref}
                value={data.details.fpc}
                name="fpc"
                type="text"
                labelContent="FPC"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event, 'details')}
              /> */}
          <Input
            ref={ref => (refsObj.upc = ref)}
            value={data.details.unitsPerCase}
            placeholder="Unit Per Case"
            name="unitsPerCase"
            type="number"
            rule="isInt"
            argument={{ min: 1, allow_leading_zeroes: false }}
            labelContent="UPC"
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            errorMessage={data.details.unitsPerCase > 0 ? INVALID : 'Must be greater than 0'}
            onChange={event => handleInputChange(event, 'details')}
          />
          <Input
            ref={ref => (refsObj.ean = ref)}
            value={data.details.ean}
            name="ean"
            type="number"
            rule="isInt"
            labelContent="EAN"
            errorMessage={INVALID}
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event, 'details')}
          />
          <Input
            ref={ref => (refsObj.gtin = ref)}
            value={data.details.gtin}
            name="gtin"
            type="number"
            rule="isInt"
            argument={{ min: 0, allow_leading_zeroes: false }}
            labelContent="GTIN"
            errorMessage={data.details.gtin > 0 ? INVALID : EMPTY}
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event, 'details')}
          />
          <Input
            value={data.details.skuCode}
            name="skuCode"
            type="text"
            placeholder="SKU Code"
            labelContent="SKU Code"
            onChange={event => handleInputChange(event, 'details')}
          />

          <Input
            ref={!data.free_sku ? ref => (refsObj.skuProductCode = ref) : null}
            value={data.details.skuProductCode}
            name="skuProductCode"
            type="text"
            placeholder="Vendor Code"
            labelContent="Vendor Code"
            enableErrorDisplay={!data.free_sku && enableErrorDisplay}
            enableValidation={!data.free_sku}
            onChange={event => handleInputChange(event, 'details')}
          />

          <Input
            value={data.details.erpCode}
            name="erpCode"
            type="text"
            placeholder="ERP Code"
            onChange={event => handleInputChange(event, 'details')}
            labelContent="ERP Code"
          />
          <Input
            value={data.details.hsCode}
            name="hsCode"
            type="text"
            placeholder="HS Code"
            labelContent="HS Code"
            onChange={event => handleInputChange(event, 'details')}
          />

          <Input
            value={data.details.standardUnit}
            name="standardUnit"
            type="number"
            decimalWithPrecedingZero
            argument={{ min: 0 }}
            labelContent="Standard Unit"
            onChange={event => handleInputChange(event, 'details')}
          />
          {/* <Input
                ref={ref => refsObj.packagingUnit = ref}
                value={data.details.packagingUnit}
                name="packagingUnit"
                type="text"
                placeholder="Packaging Unit"
                labelContent="Packaging Unit"
                enableErrorDisplay={enableErrorDisplay}
                enableValidation
                onChange={event => handleInputChange(event, 'details')}
              /> */}
          <Input
            value={data.details.minimumOrderQuantity}
            name="minimumOrderQuantity"
            type="number"
            rule="isInt"
            argument={{ min: 0, allow_leading_zeroes: false }}
            labelContent="Min. Order Qty"
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
            errorMessage={data.details.minimumOrderQuantity > 0 ? INVALID : EMPTY}
            onChange={event => handleInputChange(event, 'details')}
          />
          <Input
            type="number"
            rule="isInt"
            name="reorderMOQ"
            placeholder="Re-order MOQ"
            labelContent="Re-order MOQ (Pcs)"
            value={data.reorderMOQ}
            onChange={event => handleInputChange(event)}
          />
          <CustomSelect
            isMulti
            labelContent="Tags"
            className="custom-select"
            options={skuTagList}
            placeholder="Tags"
            getOptionValue={item => item}
            getOptionLabel={item => item}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
            ref={ref => (refsObj.skuTags = ref)}
            onChange={event => {
              const skuTags = event.map(d => d);
              handleDropDownChange(skuTags, ['skuTags']);
            }}
            value={skuTagList.filter(item => (data.skuTags ? data.skuTags.includes(item) : false))}
          />
          {data.skuTags.includes(SHELF_LIFE_REQUIRED_TAGS.NON_FOOD) && (
            <Input
              value={data.shelfLife}
              name="shelfLife"
              type="text"
              placeholder="Shelf Life (in Months)"
              onChange={event => handleInputChange(event)}
              labelContent="Shelf Life"
            />
          )}
          <CustomSelect
            labelContent="Case Unit"
            name="caseUnit"
            placeholder="Case Unit"
            className="custom-select"
            options={caseUnitList}
            getOptionValue={item => item}
            getOptionLabel={item => item}
            onChange={event => handleDropDownChange(event, ['caseUnit'])}
            value={caseUnitList.filter(label => label === data.caseUnit)}
          />
          <Input
            value={data.caseReportingUnit}
            name="caseReportingUnit"
            type="text"
            placeholder="UPC Reporting Unit"
            onChange={event => handleInputChange(event)}
            labelContent="UPC Reporting Unit"
          />
          <Switch
            onToggle={(checked, event) => {
              handleInputChange({
                formattedValue: checked,
                target: { name: 'oneTimePurchase', type: 'boolean' },
              });
            }}
            checked={data.oneTimePurchase}
            name="oneTimePurchase"
            labelContent="One Time Purchase"
          />
          <Input
            ref={data.oneTimePurchase ? ref => (refsObj.oneTimePurchaseQuantity = ref) : null}
            enableErrorDisplay={data.oneTimePurchase ? enableErrorDisplay : null}
            enableValidation
            rule="isFloat"
            argument={{ min: 1 }}
            value={data.oneTimePurchaseQuantity}
            name="oneTimePurchaseQuantity"
            type="number"
            placeholder="Purchase Quantity"
            disabled={!data.oneTimePurchase}
            onChange={event => handleInputChange(event)}
            labelContent="Purchase Quantity"
            errorMessage={data.oneTimePurchase ? 'Must be greater than 0' : ''}
          />
          <CustomCreateSelect
            labelContent="Vendor SBF"
            name="vendorSbdId"
            placeholder="Vendor SBF"
            className="custom-select"
            options={vendorSbfList}
            getOptionValue={item => item.value}
            getOptionLabel={item => item.label}
            onChange={event => handleDropDownChange(event.value, ['vendorSbfId'])}
            onCreateOption={event => handleInputChange({ target: { name: 'vendorSbfText' }, formattedValue: event })}
            value={vendorSbfList.filter(
              label => label.value === (data.vendorSbfId > 0 ? data.vendorSbfId : data.vendorSbfText),
            )}
          />
          <div className="placeholder" data-placeholder="(in KG)">
            <Input
              value={data.weight}
              name="weight"
              type="number"
              rule="isFloat"
              placeholder="Weight"
              argument={{ min: 0 }}
              onChange={event => handleInputChange(event)}
              labelContent="Weight"
            />
          </div>
          <CustomSelect
            labelContent="UOM"
            name="uom"
            placeholder="Search"
            className="custom-select"
            options={uomList}
            getOptionValue={({ label }) => label}
            getOptionLabel={({ title }) => title}
            onChange={event => handleDropDownChange(event.label, ['uom'])}
            value={uomList.filter(({ label }) => label === data.uom)}
          />
          <Input
            type="text"
            name="origin"
            placeholder="Origin"
            labelContent="Origin"
            value={data.origin}
            onChange={event => handleInputChange(event)}
          />
        </div>
      </FormContentStyled>
      <FormContentStyled>
        <h3 className="w-100">Inner Pack Details</h3>
        <div className="form-input-wrap">
          <Switch
            onToggle={(checked, event) => {
              handleInputChange({
                formattedValue: checked,
                target: { name: 'isInnerPackAvailable', type: 'boolean' },
              }, 'innerPackDetails');
            }}
            checked={data.innerPackDetails.isInnerPackAvailable}
            name="isInnerPackAvailable"
            labelContent="Is Inner Pack Available?"
          />

          {data.innerPackDetails.isInnerPackAvailable && (
            <>
              <Input
                ref={data.innerPackDetails.isInnerPackAvailable? ref => (refsObj.innerPackQuantity = ref) : null}
                enableErrorDisplay={data.innerPackDetails.isInnerPackAvailable? enableErrorDisplay : null}
                enableValidation
                rule="isFloat"
                argument={{ min: 1 }}
                name="innerPackQuantity"
                type="number"
                value={data.innerPackDetails.innerPackQuantity}
                placeholder="Inner Pack Quantity"
                labelContent="Inner Pack Quantity"
                onChange={event => handleInputChange(event,'innerPackDetails')}
                errorMessage={data.innerPackDetails.isInnerPackAvailable? 'Must be greater than 0' : ''}
              />
               <Input
                ref={data.innerPackDetails.isInnerPackAvailable? ref => (refsObj.unitperInnerPack  = ref) : null}
                enableErrorDisplay={data.innerPackDetails.isInnerPackAvailable? enableErrorDisplay : null}
                enableValidation
                rule="isFloat"
                argument={{ min: 1 }}
                name="unitperInnerPack"
                type="number"
                value={data.innerPackDetails.unitperInnerPack}
                placeholder="Unit Per Inner Pack"
                labelContent="Unit Per Inner Pack"
                onChange={event => handleInputChange(event,'innerPackDetails')}
                errorMessage={data.innerPackDetails.isInnerPackAvailable? 'Must be greater than 0' : ''}
              />
            </>
          )}
        </div>
      </FormContentStyled>
      <FormContentStyled>
        <h3 className="w-100">Dimensions</h3>
        <div className="form-input-wrap">
          <div className="placeholder" data-placeholder="(in MM)">
            <Input
              value={data.dimension.length}
              name="length"
              type="number"
              rule="isFloat"
              placeholder="Length of SKU"
              argument={{ min: 0 }}
              onChange={event => handleInputChange(event, 'dimension')}
              labelContent="Length (SKU)"
            />
          </div>
          <div className="placeholder" data-placeholder="(in MM)">
            <Input
              value={data.dimension.width}
              name="width"
              type="number"
              rule="isFloat"
              placeholder="Width of SKU"
              argument={{ min: 0 }}
              onChange={event => handleInputChange(event, 'dimension')}
              labelContent="Width (SKU)"
            />
          </div>
          <div className="placeholder" data-placeholder="(in MM)">
            <Input
              value={data.dimension.height}
              name="height"
              type="number"
              rule="isFloat"
              placeholder="Height of SKU"
              argument={{ min: 0 }}
              onChange={event => handleInputChange(event, 'dimension')}
              labelContent="Height (SKU)"
            />
          </div>
          <div className="placeholder" data-placeholder="(in MM)">
            <Input
              value={data.uomDimension.length}
              name="length"
              type="number"
              rule="isFloat"
              placeholder="Length of Case"
              argument={{ min: 0 }}
              onChange={event => handleInputChange(event, 'uomDimension')}
              labelContent="Length (Case)"
            />
          </div>
          <div className="placeholder" data-placeholder="(in MM)">
            <Input
              value={data.uomDimension.width}
              name="width"
              type="number"
              rule="isFloat"
              placeholder="Width of Case"
              argument={{ min: 0 }}
              onChange={event => handleInputChange(event, 'uomDimension')}
              labelContent="Width (Case)"
            />
          </div>
          <div className="placeholder" data-placeholder="(in MM)">
            <Input
              value={data.uomDimension.height}
              name="height"
              type="number"
              rule="isFloat"
              placeholder="Height of Case"
              argument={{ min: 0 }}
              onChange={event => handleInputChange(event, 'uomDimension')}
              labelContent="Height (Case)"
            />
          </div>
        </div>
      </FormContentStyled>
      <FormContentStyled>
        <h3 className="w-100">Advanced Options</h3>
        <div className="form-input-wrap">
          <Switch
            onToggle={(checked, event) => {
              handleInputChange(
                {
                  formattedValue: checked,
                  target: { name: 'addToSubDAssortment', type: 'boolean' },
                },
                'advancedOptions',
                ['advancedOptions', 'addToSubDAssortment'],
              );
            }}
            checked={data.advancedOptions.addToSubDAssortment}
            name="addToSubDAssortment"
            labelContent="Add SKU in SUB-D Assortment"
          />
          <Switch
            onToggle={(checked, event) => {
              handleInputChange(
                {
                  formattedValue: checked,
                  target: { name: 'addToChannelAssortment', type: 'boolean' },
                },
                'advancedOptions',
                ['advancedOptions', 'addToChannelAssortment'],
              );
            }}
            checked={data.advancedOptions.addToChannelAssortment}
            name="addToChannelAssortment"
            labelContent="Add SKU in Channel Assortment"
          />
          <Switch
            onToggle={(checked, event) => {
              handleInputChange(
                {
                  formattedValue: checked,
                  target: { name: 'addToPromotions', type: 'boolean' },
                },
                'advancedOptions',
                ['advancedOptions', 'addToPromotions'],
              );
            }}
            checked={data.advancedOptions.addToPromotions}
            name="addToPromotions"
            labelContent="Add SKU in Promotions"
          />
          <Switch
            onToggle={(checked, event) => {
              handleInputChange(
                {
                  formattedValue: checked,
                  target: { name: 'addToSbd', type: 'boolean' },
                },
                'advancedOptions',
                ['advancedOptions', 'addToSbd'],
              );
            }}
            checked={data.advancedOptions.addToSbd}
            name="addToSbd"
            labelContent="Add SKU in SBD"
          />
          <Switch
            onToggle={(checked, event) => {
              handleInputChange(
                {
                  formattedValue: checked,
                  target: { name: 'addToFocusedSku', type: 'boolean' },
                },
                'advancedOptions',
                ['advancedOptions', 'addToFocusedSku'],
              );
            }}
            checked={data.advancedOptions.addToFocusedSku}
            name="addToFocusedSku"
            labelContent="Add SKU in Focused SKU"
          />
          {routeSegmentationValidation && (
            <div className="line">
              <label>Add SKUs in Route Segmentation</label>
              <div className="line-checkbox-list">
                {line?.list?.map((d, index) => (
                  <CheckBox
                    key={d.id}
                    checked={data?.advancedOptions?.addToLineSegmentation?.includes(d.id)}
                    name={d.title}
                    className="checkbox"
                    labelText={d.title}
                    onCheckBoxClick={e => {
                      handleInputChange(
                        {
                          formattedValue: d.id,
                          target: {
                            name: 'addToLineSegmentation',
                            value: d.id,
                            checked: !!e.target.checked,
                          },
                        },
                        '',
                        ['advancedOptions', 'addToLineSegmentation'],
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </FormContentStyled>
    </CreateSKUFormStyled>
  );
};

CreateSkuForm.propTypes = propTypes;

CreateSkuForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(CreateSkuForm);

export default LoginViewWithErrorAndLoading;
