export const serverToClientDataMapper = data => {
  return data?.map(item => {
    return {
      id: item.id,
      title: item.title,
      start: item.startDate,
      end: item.endDate,
      line: { id: item.line_id },
      groupMasterTitle: item.SKUGroupMaster?.title
    };
  });
};
