import { compose } from 'recompose';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
    DOWNLOAD_USER_REPORT,
    mutation,
    query,
    UPDATE_USERS
} from './Repository/manageUsers.remote';
import ManageUsers from './View';
import withAlert from '../../../../utils/composition/withAlert';

const ComposedManageUsers = compose(
    UPDATE_USERS,
    DOWNLOAD_USER_REPORT,
    withRequestTracker({
        query,
        mutation,
    }),
)(ManageUsers);

export default withAlert()(ComposedManageUsers)
