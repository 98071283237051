import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../v4/components';
import { SORTING } from '../../data/enums';

const propTypes = {
  labelName: PropTypes.string,
  sortingOrder: PropTypes.string,
  handleClick: PropTypes.func,
};

const defaultProps = {
  labelName: '',
  sortingOrder: '',
  handleClick: () => null,
};

class Sorting extends Component {
  render() {
    const { sortingOrder, handleClick, labelName } = this.props;
    return (
      <span className='sorting-arrow' onClick={() => handleClick(labelName)}>
        {sortingOrder !== SORTING.NO_SORT ? (
          <span>
            {sortingOrder === SORTING.ASC ? (
              <Icon iconName='order-arrow-up' iconWidth='10px' iconHeight='10px' />
            ) : (
              <span>
                <Icon iconName='order-arrow-down' iconWidth='10px' iconHeight='10px' />
              </span>
            )}
          </span>
        ) : null}
      </span>
    );
  }
}

Sorting.propTypes = propTypes;

Sorting.defaultProps = defaultProps;

export default Sorting;
