import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { TOGGLE_STATE } from '../../../../common/GqlAPI';

const getSkuGroupTypeApi = gql`
  query SkuGroups($limit: Int, $offset: Int, $filter: FilterInput, $masterId: Int!) {
    skuGroups(limit: $limit, offset: $offset, filter: $filter, masterId: $masterId) {
      rows {
        id
        title
        active
        start_date
        startDate
        end_date
        endDate
        master_id
        line_id
        SKUGroupMaster {
          id
          title
        }
      }
      count
    }
  }
`;

const getRouteSegmentationList = gql`
  query getRouteSegmentation($offset: Int, $limit: Int, $filter: FilterInput) {
    lines(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        title
        active
      }
      count
    }
  }
`;

const createSKUGroup = graphql(
  gql`
    mutation CreateSKUGroup($input: SKUGroupInput) {
      createSKUGroup(input: $input) {
        id
        title
        active
        start_date
        end_date
        master_id
        line_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createSKUGroup: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const updateSKUGroup = graphql(
  gql`
    mutation UpdateSKUGroup($id: Int, $input: SKUGroupInput) {
      updateSKUGroup(id: $id, input: $input) {
        id
        title
        active
        start_date
        end_date
        master_id
        line_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateSKUGroup: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const replicateSKUGroup = graphql(
  gql`
    mutation ReplicateSKUGroup($replicateSkuGroupId: Int!, $input: SKUGroupInput!) {
      replicateSKUGroup(id: $replicateSkuGroupId, input: $input) {
        id
        title
        active
        start_date
        end_date
        master_id
        line_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      replicateSKUGroup: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const REPLICATE_SBD = graphql(
  gql`
    mutation ($input: SBDInput!, $id: Int!) {
      replicateSbd(input: $input, id: $id) {
        id
        title
        start
        end
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      replicateSbd: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getSkuGroupTypeApi,
  getRouteSegmentationList,
};

const mutation = {
  createSKUGroup,
  updateSKUGroup,
  replicateSKUGroup,
  replicateSbd: REPLICATE_SBD,
  toggleState: TOGGLE_STATE,
};

export { query, mutation, TOGGLE_STATE, createSKUGroup, updateSKUGroup, replicateSKUGroup, REPLICATE_SBD };
