import styled from 'styled-components';

const WarehouseReportStyled = styled.div`
.form-select-input{
  width:300px;
  margin-bottom:0;
}
.td-wrapper{
  padding-top:24px;
  background-color:#F1F2F5;
  table{
    border-top:1px solid #E7E7ED;
  }
}
.collapsible-body-tr .td-wrapper table tbody th {
    background: #f1f2f5 !important;
    color: #6b6c7e;
    font-weight: 600;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 1;
    border-top:1px solid #e7e7ed;
    border-bottom:1px solid #e7e7ed;
  }
.table-wrap{
  &.no-select{
    &.pagination{
      height: calc(100vh - 170px) !important;
    }
    &.no-pagination{
      height: calc(100vh - 116px) !important;
    }
  }
  &.pagination{
    height: calc(100vh - 265px) !important;
  }
  &.no-pagination{
    height: calc(100vh - 209px) !important;
  }
}
.table-wrap{
  table{
    thead tr,
    thead th{
      height:40px;
      padding-top:0;
      padding-bottom:0;
      white-space:nowrap;
      &.first{
        width:50%;
        padding-left: 75px;
      }
      &.third{
        width:10%;
      }
      &.fourth,
      &.second{
        width:12%;
      }
    }
    tbody {
      tr{
        height:40px;
        &.active:not(.no-collaspse){
          background: #f0f5ff;
          height: 52px;
          transition: all 0.4s ease-out;
          td{
            border-bottom: 1px solid #0c5fff;
          }
        }
        &.no-collaspse{
          cursor:unset;
          span:before{
            border:0 !important;
          }
        }
        &.no-collaspse + .collapsible-body-tr{
          display:none;
        }
      }
    }
      .collapsible-body-tr{
        height:40px;
        .collapsible-body-td{
          tbody{
            tr:first-child{
              border-bottom:1px solid #e7e7ed;
            }
            td:first-child{
              padding-left:75px;
            }
            .skuTitle{
              display: block;
              width: 100%;
              white-space: normal;
            }
          }
          tbody tr,
          tbody th{
            height:40px;
            border-top:0;
            border-bottom:0;
            &.one{
              width: 10.2%;
              &.first{
                padding-left:75px;
              }
              &.second{
                width: 21.1%;
              }
            }
            &.three{
              width: 10%;
            }
            &.four,
            &.sellable {
              width: 12.15%;
            }
        }
      }
  }
}
  .form-select-input {
    margin-top: 8px;
    margin-bottom: 0;
    width: 22%;
    /* width:242px; */
    label {
      display: none;
    }
  }

  table {
    border: 0;
    tr {
      &.collapsible-body-tr {
        tbody {
          tr {
            th,
            td {
              &:first-child {
                padding: 7px 12px 7px 48px;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        &.active {
          td:first-child > .caret:before {
            transform: rotate(180deg);
            transition: all 0.23s ease-in-out;
            border-top-color: #004ad7;
          }
        }
        td:first-child {
          > .caret {
            position: relative;
            padding-left: 26px;

            &:before {
              content: '';
              width: 0;
              height: 0;
              border-radius: 4px;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #6b6c7e;
              position: absolute;
              top: 5px;
              left: 0;
              transition: all 0.23s ease-in-out;
            }
          }
        }
      }
    }
    thead,tbody{th,td{
        &.border-left{
          border-left: 1px solid #e6e3e3;
        }
        &.border-right{
          border-right: 1px solid #e6e3e3;
        }
      }}
      .max-width{
        width: 33%;
      }
      .status-label, .discontinue-sku {
    padding: 4px;
    font-size: 8px;
    border-radius: 2px;
    min-width: 58px;
    height: 16px;
    margin-left: 6px;
    line-height:6px;
  }
  .discontinue-sku {
      background-color: #DA1414;
      color: #FFFFFF;
    }
  }

  .collapsible-body-tr {
    &:hover,
    &:focus {
      background: transparent;
    }

    table {
      box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
      border: none;
      .fixed-width{
        width: 14%;
      }
      .sellable{
        width: 10%;
      }
      tbody {    
        tr:last-child {
          border-top: 1px solid #e7e7ed;
        }
      }

      tr {
        th {
          background: transparent;
          width: auto;
        }
      }
    }
  }
`;

export default WarehouseReportStyled;
