import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';

const labelMappings = {
  skuId: 'skuId',
  skuTitle: 'skuTitle',
  subD: 'subD',
  openingBalance: 'openingBalance',
  inward: 'inward',
  outward: 'outward',
  adjustment: 'adjustment',
  closingBalance: 'closingBalance',
  damages: 'damages',
  shortages: 'shortages',
  expiry: 'expiry',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.skuId,
    title: 'SKU ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.skuTitle,
    title: 'SKU',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.subD,
    title: 'Sub D',
    show: false,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 4,
    label: labelMappings.openingBalance,
    title: 'Saleable',
    classNames: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right border-right border-left',
  },
  {
    id: 5,
    label: labelMappings.inward,
    classNames: 'text-right',
    title: 'Inward',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 6,
    label: labelMappings.outward,
    classNames: 'text-right',
    title: 'Outward',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 7,
    label: labelMappings.adjustment,
    classNames: 'text-right',
    title: 'Adjustment',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 8,
    label: labelMappings.closingBalance,
    classNames: 'text-right',
    title: 'Closing Balance',
    show: true,
    sortable: true,
    className: 'sorting text-right border-left',
  },
  {
    id: 9,
    label: labelMappings.damages,
    classNames: 'text-right',
    title: 'Damaged',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 10,
    label: labelMappings.shortages,
    classNames: 'text-right',
    title: 'Shortages',
    show: true,
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 10,
    label: labelMappings.expiry,
    classNames: 'text-right',
    title: 'Expired',
    show: true,
    sortable: false,
    className: 'text-right',
  },
];

const upperHeaderDetails = [
  {
    id: 1, label: '', title: '', className: 'text-center', columns: 2, sortable: false,
  },
  {
    id: 2, label: 'openingBalance', title: 'Opening Balance', className: 'text-center border-right border-left', columns: 1, sortable: false,
  },
  {
    id: 3, label: 'periodBalance', title: 'Period Balance', className: 'text-center border-right border-left', columns: 3, sortable: false,
  },
  {
    id: 4, label: 'closingBalance', title: 'Closing Balance', className: 'text-center', columns: 4, sortable: false,
  },
]

const TableHeaderConfig = ({ onHeaderClick }) => (
  <>
    <thead className='upperHeader'>
      {
        upperHeaderDetails.map((data) => (
          <th className={data.className} colSpan={data.columns}>
            {data.title}
          </th>
        ))
      }
    </thead>
    <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
  </>
);

const returnTableBody = (data, onRowClick) => {
  const table = {};
  table[labelMappings.skuId] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-skuId`}>
      <span>{data.skuId}</span>
    </td>
  );
  table[labelMappings.skuTitle] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-title`} className='max-width'>
      {data.SKU ? data.SKU.title : ''}
      {data?.sku_status||
        <b>
          <span className={'status-label passive'}>
            { 'INACTIVE'}
          </span>
        </b>
      }
      {data?.discontinue_sku &&
        <b>
          <span className={'discontinue-sku passive'}>
            {'DISCONTINUED'}
          </span>
        </b>
      }
    </td>
  );
  table[labelMappings.subD] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-distributor`}>
      {data.Distributor ? data.Distributor.title : ''}
    </td>
  );
  table[labelMappings.openingBalance] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-openingBalance`} className='text-right border-right border-left'>
      {data.openingBalance}
    </td>
  );
  table[labelMappings.inward] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-inward`} className='text-right'>
      {data.inward}
    </td>
  );
  table[labelMappings.outward] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-outward`} className='text-right'>
      {data.outward}
    </td>
  );
  table[labelMappings.adjustment] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-adjustment`} className='text-right'>
      {data.adjustment}
    </td>
  );
  table[labelMappings.closingBalance] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-closingBalance`} className='text-right border-left'>
      {data.closingBalance}
    </td>
  );
  table[labelMappings.damages] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-damages`} className='text-right'>
      {data.damages}
    </td>
  );
  table[labelMappings.shortages] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-shortages`} className='text-right'>
      {data.shortages}
    </td>
  );
  table[labelMappings.expiry] = (
    <td onClick={() => onRowClick(data.skuId)} key={`stockLedger-${data.skuId}-expiry`} className='text-right'>
      {data.expiry}
    </td>
  );

  return table;
};

const TableBody = ({ data, onRowClick }) => {
  const tableBody = returnTableBody(data, onRowClick);

  return labelConfig.map((label) => (label.show ? tableBody[label.label] : null));
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
};
export { TableHeaderConfig as TableHeader, TableBody };
