import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import withLoading from '../../../../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import {
  CustomSelect, Form, Input, SingleDatePicker
} from '../../../../../components';
import withAlert from '../../../../../utils/composition/withAlert';
import { discountTypeList } from './config';
import CustomAutoComplete from '../../../../../components/CustomAutoComplete';
import { fixedFloat } from '../../../../../utils/conversion';

const propTypes = {
  uploadCallBack: PropTypes.func,
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  skuList: PropTypes.instanceOf(Array),
  refsObj: PropTypes.instanceOf(Object),
  skuBatchList: PropTypes.instanceOf(Array),
  stockTypeList: PropTypes.arrayOf(Object),
  inputCallBack: PropTypes.func,
  invoiceNumberStatus: PropTypes.bool,
  distributorBatchFlag: PropTypes.bool,
  getState: PropTypes.func,
  batchCallBack: PropTypes.func,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  skuList: [],
  skuBatchList: [],
  enableErrorDisplay: true,
  uploadCallBack: () => null,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  handleInputChange: () => null,
  stockTypeList: [],
  inputCallBack: () => null,
  invoiceNumberStatus: false,
  distributorBatchFlag: false,
  getState: () => null,
  batchCallBack: () => null,
};

const SRNForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    skuList,
    getState,
    skuBatchList,
    stockTypeList,
    inputCallBack,
    batchCallBack,
    dropDownCallBack,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    invoiceNumberStatus,
    distributorBatchFlag,
  } = props;

  return (
    <Form>
      <Row>
        <Col md={12}>
          <CustomSelect
            enableValidation
            disabled={invoiceNumberStatus}
            options={skuList}
            labelContent='SKU'
            className='custom-select'
            placeholder='Select SKU'
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            ref={(ref) => (refsObj.skuId = ref)}
            enableErrorDisplay={enableErrorDisplay}
            onChange={(event) => handleDropDownChange(event.id, ['skuId'], dropDownCallBack)}
            value={skuList.filter(({ id }) => id === data.skuId)}
          />
        </Col>
        <Col md={4} className={skuBatchList?.filter((a)=>a.isBlocked)?.length>0?'audit-blocked':''}>
          <CustomAutoComplete
            name='batchName'
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
            onChange={(event) => handleInputChange(event, null, '', batchCallBack)}
            reflectOnOutputChange={getState().batchName === ''}
            localDataInputChange
            dropDownList={skuBatchList}
            labelContent='Batch'
            placeholder='Batch'
            dropDownValueKey='batchName'
            dropDownDisplayKey='batchName'
            searchText={data.batchName}
            disabled={!distributorBatchFlag || !data.skuId || invoiceNumberStatus || skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
            controlledInput={!distributorBatchFlag}
            refs={refsObj}
            onDropDownSelection={(event) => handleDropDownChange(event, ['skuBatchId'], dropDownCallBack)}
          />
        </Col>
        <Col md={4}>
          <div className='single-date-picker'>
            <label>Manufacturing (MFG) Date</label>
            <SingleDatePicker
              name='manufactureDate'
              date={data.manufactureDate}
              disabled={invoiceNumberStatus||data.skuBatchId!==0}
              onChange={(name, date) =>
                handleInputChange({
                  target: {
                    name: 'manufactureDate',
                  },
                  formattedValue: date,
                })
              }
              maxDate={data.expiryDate}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className='single-date-picker'>
            <label>Expiry (EXP) Date</label>
            <SingleDatePicker
              name='expiryDate'
              date={data.expiryDate}
              disabled={invoiceNumberStatus||data.skuBatchId!==0}
              onChange={(name, date) =>
                handleInputChange({
                  target: {
                    name: 'expiryDate',
                  },
                  formattedValue: date,
                })
              }
              minDate={data.manufactureDate}
            />
          </div>
        </Col>
      </Row>

      <Row className='mt-24'>
        <Col md={4}>
          <CustomSelect
            enableValidation
            options={stockTypeList}
            labelContent='Stock Type'
            className='custom-select'
            placeholder='Select'
            getOptionValue={({ value }) => value}
            getOptionLabel={({ title }) => title}
            ref={(ref) => (refsObj.stockType = ref)}
            enableErrorDisplay={enableErrorDisplay}
            onChange={(event) => handleDropDownChange(event.value, ['stockType'])}
            value={stockTypeList.filter(({ value }) => value === data.stockType)}
          />
        </Col>
        {!invoiceNumberStatus && <Col md={4}>
          <Input
            name='per_unit_rate'
            type='number'
            rule='isInt'
            labelContent='Per Unit Rate'
            enableValidation
            value={data.per_unit_rate}
            onChange={(event) => handleInputChange(event)}
          />
        </Col>
        }
      </Row>
      <Row>
        <Col md={4}>
          <Input
            name='returnQuantity'
            type='number'
            rule='isInt'
            labelContent='Return Quantity (QTY)'
            enableValidation
            value={data.returnQuantity}
            enableErrorDisplay={enableErrorDisplay}
            ref={(ref) => (refsObj.returnQuantity = ref)}
            onChange={(event) => handleInputChange(event, 'returnQuantity', ['returnQuantity'], inputCallBack)}
            argument={{
              min: 1,
              allow_leading_zeroes: false,
            }}
            errorMessage={data.returnQuantity ? INVALID : EMPTY}
          />
        </Col>
        <Col md={4}>
          <Input
            name='rate'
            type='number'
            rule='isFloat'
            labelContent='Rate'
            enableValidation
            argument={{ min: 1 }}
            value={data.updatedAmountDetails.rate}
            decimalWithPrecedingZero
            enableErrorDisplay={enableErrorDisplay}
            ref={(ref) => (refsObj.rate = ref)}
            disabled={invoiceNumberStatus||data?.validRate > 0}
            onChange={(event) =>
              handleInputChange(event, 'updatedAmountDetails', ['updatedAmountDetails', 'rate'], inputCallBack)
            }
            errorMessage={data.updatedAmountDetails.rate ? INVALID : EMPTY}
          />
        </Col>
      </Row>

      <Row>
        <Col className='mt-24' md={12}>
          <Row>
            <Col md={4}>
              <CustomSelect
                enableValidation
                options={discountTypeList}
                labelContent='Discount Type'
                className='custom-select'
                placeholder='Select Discount Type'
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                ref={(ref) => (refsObj.discountTypeId = ref)}
                enableErrorDisplay={enableErrorDisplay}
                disabled={invoiceNumberStatus}
                onChange={(event) =>
                  handleDropDownChange(event.id, ['updatedAmountDetails', 'discountTypeId'], inputCallBack)
                }
                value={discountTypeList.filter(({ id }) => id === data.updatedAmountDetails.discountTypeId)}
              />
            </Col>
            {!invoiceNumberStatus && (
              <Col md={4}>
                <Input
                  name='discountValue'
                  type='number'
                  rule='isFloat'
                  decimalWithPrecedingZero
                  placeholder='Discount'
                  labelContent={discountTypeList[data.updatedAmountDetails.discountTypeId - 1].label}
                  value={data.updatedAmountDetails.discountValue}
                  ref={(ref) => (refsObj.discountValue = ref)}
                  onChange={(event) =>
                    handleInputChange(
                      event,
                      'discountValue',
                      ['updatedAmountDetails', 'discountValue'],
                      inputCallBack,
                    )
                  }
                  disabled={invoiceNumberStatus}
                />
              </Col>
            )}
            <Col className='actual_discount' md={4}>
              <Input
                type='number'
                rule='isFloat'
                labelContent={`${invoiceNumberStatus ? 'Discount' : 'Actual Discount'}`}
                decimalWithPrecedingZero
                name='promotionDiscount'
                value={fixedFloat(
                  Number(data.updatedAmountDetails.promotionDiscount) +
                      Number(data.updatedAmountDetails.topUpDiscount),
                )}
                disabled
                placeholder='Amount'
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

SRNForm.propTypes = propTypes;

SRNForm.defaultProps = defaultProps;

const SRNFormWithErrorAndLoading = withLoading(SRNForm);
const SRNFormWithAlert = withAlert()(SRNFormWithErrorAndLoading);

export default SRNFormWithAlert;
