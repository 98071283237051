import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { PROMOTION_AREAS, SKU_CATALOG_LEVEL } from '../../../../data/enums';
import { PROMOTION } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { CATALOG_LEVEL } from '../../../../data/enums/Catalog';
import { promotionScopeBase } from './PromotionDetail';

const title = 'Promotion';

const breadCrumb = [
  ...breadCrumbConfig,

  {
    id: 1,
    title: 'Promotion',
    path: `/${PROMOTION}`,
    active: true,
  },
];

const PROMOTION_CRITERIA_OPERATION = [
  {
    id: 1,
    title: '> GREATER_THAN',
    value: 'GREATER_THAN',
  },
  {
    id: 2,
    title: '>= GREATER_THAN_EQUALS',
    value: 'GREATER_THAN_EQUALS',
  },
  {
    id: 3,
    title: '< LESS_THAN',
    value: 'LESS_THAN',
  },
  {
    id: 4,
    title: '<= LESS_THAN_EQUALS',
    value: 'LESS_THAN_EQUALS',
  },
  {
    id: 5,
    title: '= EQUALS',
    value: 'EQUALS',
  },
];

const PROMOTION_CRITERIA_DIMENSION = [
  {
    id: 1,
    title: 'Quantity',
    value: 'QUANTITY',
  },
  {
    id: 2,
    title: 'Amount',
    value: 'AMOUNT',
  },
];

const PROMOTION_TYPE = [
  {
    id: 1,
    title: 'Normal',
    value: 'NORMAL',
  },
  {
    id: 2,
    title: 'Current Bill',
    value: 'CURRENT_BILL',
  },
  {
    id: 3,
    title: 'Next Bill',
    value: 'NEXT_BILL',
  },
  {
    id: 4,
    title: 'Direct Disbursement',
    value: 'DIRECT_DISBURSEMENT',
  },
  {
    id: 5,
    title: 'Top Up',
    value: 'TOP_UP',
  },
];

const PROMOTION_CRITERIA = {
  DIMENSION: [
    {
      id: 'AMOUNT',
      value: 'AMOUNT',
    },
    {
      id: 'QUANTITY',
      value: 'QUANTITY',
    },
  ],
  OPERATOR: [
    {
      id: 'GREATER_THAN',
      value: 'GREATER_THAN',
    },
    {
      id: 'LESS_THAN',
      value: 'LESS_THAN',
    },
    {
      id: 'GREATER_THAN_EQUALS',
      value: 'GREATER_THAN_EQUALS',
    },
    {
      id: 'LESS_THAN_EQUALS',
      value: 'LESS_THAN_EQUALS',
    },
    {
      id: 'EQUALS',
      value: 'EQUALS',
    },
  ],
};

const PROMOTION_DISBURSEMENT = {
  TYPE: [
    {
      id: 'EQUALS',
      value: 'EQUALS',
    },
    {
      id: 'GREATER_THAN_EQUALS',
      value: 'GREATER_THAN_EQUALS',
    },
    {
      id: 'LESS_THAN_EQUALS',
      value: 'LESS_THAN_EQUALS',
    },
  ],
};

const PROMOTION_RELATED = {
  TYPE: [
    {
      id: 'SBD',
      value: 'SBD',
    },
    {
      id: 'FOCUSED_SKU',
      value: 'FOCUSED_SKU',
    },
  ],
};

const CUSTOM_SKU_GROUPS = {
  HEADER: 'customGroups',
  SKU: 'customGroupSKU',
};

const form = {
  mapper: (element) => ({
    id: element.id || '',
    name: element.name || '',
    displayName: element.displayName || '',
    UserGroup: {
      id: element.UserGroup ? element.UserGroup.id : '',
    },
  }),
  validationField: {
    info: ['title'],
    disbursement: ['value'],
    scope: ['relatedId'],
    productCatalog:['groupValue']
  },
  validationRequired: true,
};

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({
      name: data.name,
      displayName: data.displayName,
      userGrpId: data.UserGroup.id,
    }),
    responseName: 'createRole',
    message: `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => stateToRequestProcessor(data),
    responseName: 'updateRole',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'deleteSKU',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateSKU',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: title,
      active: !data.active,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};

const responseToStateAreaMapper = (promotionAreas) => {
  const townList = promotionAreas.filter((el) => el.domain === PROMOTION_AREAS.TOWN);
  const subDList = promotionAreas.filter((el) => el.domain === PROMOTION_AREAS.SUB_D);

  const townIdList = (townList && townList.map((town) => town.ref_id)) || [];
  const subDIdList = (subDList && subDList.map((subD) => subD.ref_id)) || [];

  return {
    townIdList,
    subDIdList,
  };
};

const responseToStateProcessor = (data, state) => {
  const { info, scope, criteria, disbursement, banner, ...remKeys } = state;
  info.promotionId = data.id;
  info.startDate = data.startDate;
  info.endDate = data.endDate;
  info.title = data.title;
  info.active = data.active;
  info.shortDescription = data.shortDescription;
  info.budget = data.budget;
  info.validCount = {
    status: data.validCount.status,
    count: data.validCount.count,
  };
  info.notification = data.notification;

  const channelCategoryInfo =
    data.channelCategoryInfo.length > 0
      ? data.channelCategoryInfo
      : [
        {
          channelId: null,
          categoryIds: [],
        },
      ];
  scope.showSelectedList = true;
  scope.channelCategoryInfo = channelCategoryInfo;
  scope.categoryIds = data.categoryIds || [];
  scope.relatedType = data.relatedType;
  scope.relatedId = data.relatedId;
  scope.customGroupStatus = data.customGroupStatus;
  scope.catalogGroupId = [];
  scope.platform = {
    retailer: data.platform.retailer,
    marketplace: data.platform.marketplace,
    sales: data.platform.sales,
  };
  scope.billing_status = data.billing_status;
  scope.billIssueStatus = data.billIssueStatus;
  scope.promotionCatalog = data.promotionCatalog.length ? data.promotionCatalog : promotionScopeBase.promotionCatalog;
  scope.promotionAreas = responseToStateAreaMapper(data.promotionAreas);

  banner.images = data.banner;
  scope.customGroupDetails = [];
  if (data.customGroupStatus) {
    data.promotionCatalog.forEach((promotion) => {
      scope.customGroupDetails.push({
        criteria: promotion.criteria,
        [promotion.catalogInfo.catalogId === SKU_CATALOG_LEVEL ? 'skus' : 'skuFamily']:
          promotion.catalogInfo.catalogDetails,
      });
      scope.catalogGroupId.push(promotion.catalogInfo.catalogId);
    });
  } else {
    data.promotionCatalog.forEach((promotion) => {
      if (promotion.catalogInfo.catalogId === CATALOG_LEVEL.SKU_FAMILY) {
        promotion.catalogInfo.skuFamily = [];
        promotion.catalogInfo.skuFamily.push(...promotion.catalogInfo.catalogDetails);
        if (promotion.catalogInfo.catalogDetails) {
          delete promotion.catalogInfo.catalogDetails;
        }
      }
    });
  }

  criteria.dimension = data.criteria.dimension;
  criteria.skuCount = data.criteria.skuCount;
  criteria.operation = data.criteria.operation;
  criteria.allowMultiple = data.criteria.allowMultiple;
  criteria.type = data.type;

  disbursement.dimension = data.disbursement.dimension;
  disbursement.value = data.disbursement.value;
  disbursement.allowMultiple = data.disbursement.allowMultiple;
  disbursement.freeSKUs = data.disbursement.freeSKUs.map((freeSKU) => freeSKU.skuId); // ? [data.freeSkuId] : [] || [];

  return {
    scope,
    info,
    criteria,
    disbursement,
    ...remKeys,
  };
};

const stateToRequestAreaMapper = (promArea) => {
  const promotionAreas = [];

  if (promArea.townIdList.length > 0) {
    promotionAreas.push({
      ref_ids: promArea.townIdList,
      domain: PROMOTION_AREAS.TOWN,
    });
  }

  if (promArea.subDIdList.length > 0) {
    promotionAreas.push({
      ref_ids: promArea.subDIdList,
      domain: PROMOTION_AREAS.SUB_D,
    });
  }

  return promotionAreas;
};

const stateToRequestProcessor = (state) => {
  const { info, scope, criteria, disbursement, banner } = state;

  const promotionAreas = stateToRequestAreaMapper(scope.promotionAreas);
  const object = {
    input: {
      title: info.title,
      type: criteria.type,
      startDate: info.startDate,
      endDate: info.endDate,
      active: info.active,
      scope: scope.customGroupStatus ? 'GROUP' : 'SINGLE',
      shortDescription: info.shortDescription,
      budget: info.budget ? info.budget : null,
      validCount: {
        status: info.validCount.status,
        count: info.validCount.count,
      },
      notification: info.notification,
      promotionAreas,
      areaStatus: !!promotionAreas.length,
      promotionCatalog: scope.promotionCatalog,
      channelCategoryInfo: scope.channelCategoryInfo.map((el) => el),
      customGroupStatus: scope.customGroupStatus,
      categoryIds: scope.categoryIds,
      platform: {
        retailer: scope.platform.retailer,
        marketplace: scope.platform.marketplace,
        sales: scope.platform.sales,
      },
      billing_status: scope.billing_status,
      billIssueStatus:scope.billIssueStatus,
      criteria: {
        dimension: criteria.dimension,
        skuCount: criteria.skuCount,
        allowMultiple: criteria.allowMultiple,
        operation: {
          max: {
            value: criteria.operation.max.value,
            operator: criteria.operation.max.operator,
          },
          min: {
            value: criteria.operation.max.value,
            operator: criteria.operation.max.operator,
          },
        },
      },
      disbursement: {
        value: disbursement.value,
        freeSKUs: disbursement.freeSKUs.map((skuId) => ({ skuId })),
        allowMultiple: disbursement.allowMultiple,
        dimension: disbursement.dimension,
      },
      bannerImage: banner.images,
    },
  };
  if (scope.promotionCatalog.length) {
    if (scope.customGroupStatus && scope.catalogGroupId.length) {
      object.input.promotionCatalog.length = 0;
      (scope.customGroupDetails[0].skus
        ? scope.customGroupDetails[0].skus[0].id
        : scope.customGroupDetails[0].skuFamily[0].id) &&
        scope.customGroupDetails.forEach((group, index) => {
          const groupExists = !group.skus ? group.skuFamily : group.skus;
          groupExists &&
            object.input.promotionCatalog.push({
              criteria: group.criteria,
              catalogInfo: {
                catalogId: scope.catalogGroupId[index],
                catalogDetails: !group.skus ? group.skuFamily : group.skus,
              },
            });
        });
    } else if (
      scope.promotionCatalog[0].catalogInfo.catalogDetails ||
      scope.promotionCatalog[0].catalogInfo.skuFamily
    ) {
      const catalogExits =
        (scope.promotionCatalog[0].catalogInfo.skuFamily
          ? scope.promotionCatalog[0].catalogInfo.skuFamily.length
          : null) ||
        (scope.promotionCatalog[0].catalogInfo.catalogDetails
          ? scope.promotionCatalog[0].catalogInfo.catalogDetails.length
          : null);
      if (catalogExits) {
        object.input.promotionCatalog = [
          {
            criteria: scope.promotionCatalog[0].criteria,
            catalogInfo: {
              catalogId: scope.promotionCatalog[0].catalogInfo.catalogId,
              catalogDetails:
                scope.promotionCatalog[0].catalogInfo.skuFamily || scope.promotionCatalog[0].catalogInfo.catalogDetails,
            },
          },
        ];
      } else {
        object.input.promotionCatalog = [];
      }
    } else if (!scope.promotionCatalog[0].catalogInfo.catalogDetails) {
      object.input.promotionCatalog = [];
    }
  }

  if (info.promotionId && info.promotionId !== 0) {
    object.id = info.promotionId;
  }

  if (scope.relatedType) {
    object.input.relatedType = scope.relatedType;
    object.input.relatedId = scope.relatedId;
  }
  return object;
};

export {
  crudRequest,
  breadCrumb as breadCrumbConfig,
  form,
  title,
  stateToRequestProcessor,
  responseToStateProcessor,
  PROMOTION_CRITERIA,
  PROMOTION_DISBURSEMENT,
  PROMOTION_RELATED,
  PROMOTION_CRITERIA_DIMENSION,
  PROMOTION_CRITERIA_OPERATION,
  PROMOTION_AREAS,
  PROMOTION_TYPE,
  CUSTOM_SKU_GROUPS,
};
