import { salesForce as salesForceRoute } from '../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Push Messages';

const breadCrumb = [salesForceRoute];
const filter = getFilterConfig([FILTER.STATUS, FILTER.MESSAGING_PLATFORM]);
const crudRequest = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      // title: data.title,
      type: 'Message',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: data.id,
      // title: data.title,
      type: 'Message',
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export {
  breadCrumb as breadCrumbConfig, title, crudRequest, crudRequest as crudSuccess, filter as filterConfig
};
