import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CreateSkuForm from './Form';
import CatalogSelect from './catalogSelect/index';
import GridDialog from './gridDialog/index';
import { BreadCrumb, Icon, Button } from '../../../../v4/components';
import { DialogFormWrapper } from '../../../common';
import { SKU } from '../../../../data/enums/Route';
import { refGenerator } from '../../../../utils/refGenerator';
import withAlert from '../../../../utils/composition/withAlert';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { clone } from '../../../../utils/arrayProcessor';
import {
  crudSuccess as crudRequestConfig,
  breadCrumbConfig,
  formConfig as form,
  title,
  MASTER_DATA_TYPES,
} from './config';
import { has } from '../../../../utils/objectPrototypes';
import { PanelStyled, PanelHeader } from '../../../common/configuration';
import { getPermissionForSKU } from '../../../base/permission';
import { addEdit } from '../../../../utils/utilities';
import SKUStyled from '../SKUStyled';
import { dropdownChange } from '../../../../utils/formHandlers';
import Image from './skuImage';
import { menuAction } from '../../../../utils/paginatedListUtils';
import confirmationGenerator from '../../../common/DialogConfirmation';
import { LINE_TYPE } from '../../../../data/enums/Status';
import { isError } from '../../../common/HelperFunctions';

const propTypes = {
  createSKU: PropTypes.func.isRequired,
  updateSKU: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getCatalogList: PropTypes.func.isRequired,
  getSkuDetail: PropTypes.func.isRequired,
  getCatalogDetail: PropTypes.func.isRequired,
  getBUData: PropTypes.func.isRequired,
  getMasterData: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const menuConfigList = [
  {
    title: 'Discontinue SKU',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
];

let line = [];

class CreateSku extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      withOutIcon: true,
      dialogConfirmationType: '',
      skuId: '',
      prepareData: '',
      data: form.mapper({}),
      backUpData: form.mapper({}),
      enableErrorDisplay: false,
      clearData: false,
      uomList: [],
      routeSegmentationValidation: {},
      skuId: has.call(props.match.params, 'id') ? parseInt(props.match.params.id, 10) : 0,
      crudMode: has.call(props.match.params, 'id') ? EVENT_OPERATION.READ : EVENT_OPERATION.CREATE,
      skuTagList: [],
      caseUnitList: [],
      vendorSbfList: [],
      lineSegmentationData: [],
      listMasterConfigurationData: [],
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createSKU,
      [EVENT_OPERATION.UPDATE]: props.updateSKU,
    };
    this.permission = getPermissionForSKU();
    this.onCRUDSuccess = this.responseProcessor();
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator(form.validationField);
    this.permission = getPermissionForSKU();
  }

  componentDidMount() {
    const { skuId } = this.state;
    if (skuId) {
      this.setState({ skuId }, () => this.getSkuDetail(skuId));
    }
    this.getSkuTagsAndCaseUnit();
    this.getUOMList();
    this.loadTableData();
    this.getMasterConfiguration();
  }

  loadTableData = () => {
    const { getRouteSegmentation } = this.props;
    const { lineSegmentationData } = this.state;

    getRouteSegmentation(
      {
        filter: {
          filters: [
            {
              column: 'active',
              value: ['true'],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          lineSegmentationData.list =
            (response.data.lines && response.data.lines.rows.filter(d => d.title !== 'Default')) || [];
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(lineSegmentationData);
        },
        handleError: error => this.onAPIRequestFailure(error),
      },
    );
  };

  getUOMList = () => {
    const { getBUData } = this.props;

    getBUData(
      {},
      {
        handleSuccess: response => {
          const { settings } = response.data;
          const uomList = settings ? settings.unitsOfMeasurement : [];
          const routeSegmentationValidation = settings ? settings.routeSegmentation : {};
          this.setState({ routeSegmentationValidation });
          this.setState({ uomList });
        },
        handleError: err => {
          this.onAPIRequestFailure(err);
        },
      },
    );
  };

  getSkuDetail = id => {
    const { getSkuDetail, displayAlert } = this.props;
    let data = {};
    getSkuDetail(
      { id },
      {
        handleSuccess: response => {
          const { findSKU = {} } = response.data;
          data = form.mapper(findSKU);
          // data.catalogDetails = data.catalogDetails.reverse();
          const backUpData = clone(data);
          this.setState({ data, backUpData });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getMasterConfiguration = () => {
    const { listMasterConfiguration, displayAlert } = this.props;

    listMasterConfiguration(
      {},
      {
        handleSuccess: response => {
          const { listMasterConfiguration = {} } = response?.data;
          const listMasterConfigurationData = listMasterConfiguration || [];
          this.setState({ listMasterConfigurationData });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getSkuTagsAndCaseUnit = () => {
    const { getMasterData, displayAlert, getVendorSBFs } = this.props;

    getMasterData(
      {},
      {
        handleSuccess: res => {
          const { listMasterData = [] } = res.data;
          const dataObj = {
            [MASTER_DATA_TYPES.SKU_TAGS]: [],
            [MASTER_DATA_TYPES.CASE_UNIT]: [],
          };
          if (listMasterData.length > 0) {
            listMasterData.forEach(item => {
              dataObj[item.type] = [...(item.list || [])];
            });
            this.setState({
              skuTagList: dataObj[MASTER_DATA_TYPES.SKU_TAGS],
              caseUnitList: dataObj[MASTER_DATA_TYPES.CASE_UNIT],
            });
          }
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );

    getVendorSBFs(
      {},
      {
        handleSuccess: response => {
          const { vendorSBFs } = response.data;
          this.setState({
            vendorSbfList: vendorSBFs.map(d => ({ label: d.title, value: d.id })),
          });
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  getFormValidationStatus = () => !Object.values(this.formReference)?.find(item => item?.getValidState() === false);

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data, vendorSbfList } = this.state;
    if (!event.target.checked && paramList?.[0] === 'advancedOptions' && typeof event.target.value === 'number') {
      data.advancedOptions.addToLineSegmentation = data.advancedOptions.addToLineSegmentation.filter(
        d => d !== event.formattedValue,
      );
      line = line.filter(d => d !== event.formattedValue);
      this.setState({
        data: {
          ...data,
        },
      });
    } else if (event.target.name === 'oneTimePurchase') {
      this.setState({
        data: { ...data, oneTimePurchaseQuantity: 0, oneTimePurchase: event.formattedValue },
      });
    } else if (event.target.name === 'innerPackAvailable') {
      this.setState({
        data: {
          ...data,
          innerPackDetails: {
            innerPackQuantity: 0,
            unitperInnerPack: 0,
            innerPackAvailable: event.formattedValue,
          },
        },
      });
    } else {
      if (paramList?.[0] === 'advancedOptions' && paramList?.[1] === 'addToLineSegmentation') {
        if (paramList?.[1] === 'addToLineSegmentation') {
          line.push(event.formattedValue);
          data.advancedOptions.addToLineSegmentation = line;
          this.setState({ data });
        }
      } else if (event.target.name === 'vendorSbfText') {
        this.setState(
          {
            vendorSbfList: [...vendorSbfList, { label: event.formattedValue, value: event.formattedValue }],
          },
          () => {
            this.setState({
              data: { ...data, ...{ vendorSbfId: 0, vendorSbfText: event.formattedValue } },
            });
          },
        );
      } else {
        if (firstParam) {
          if (paramList.length > 1) {
            paramList.reduce((acc, value, index, list) => {
              if (index === list.length - 1) {
                return (acc[value] = event.formattedValue);
              }
              return acc[value];
            }, data);
          } else {
            data[firstParam][event.target.name] = event.formattedValue;
          }
        } else {
          data[event.target.name] = event.formattedValue;
        }

        this.setState({ data });
      }
    }
  };

  handleDropDownChange = (value, parameterRef = [], callBack = () => null) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);

    const checkRefData = parameterRef.includes('vendorSbfId') ? { ...updatedData, vendorSbfText: '' } : updatedData;

    this.setState({ data: checkRefData });
  };

  checkUpcAndInnerPackMatch = (createData, callbackFunction) => {
    const upcValue = createData?.details.unitsPerCase;
    const innerPackValue =
      createData?.innerPackDetails.innerPackQuantity * createData?.innerPackDetails.unitperInnerPack;
    if (createData?.innerPackDetails.isInnerPackAvailable) {
      if (upcValue !== innerPackValue) {
        this.props.displayAlert(ALERT_TYPE.DANGER, {
          message: `UPC value of ${upcValue} doesn't match with Inner Pack value of ${innerPackValue}`,
        });
        return;
      }
    }
    callbackFunction();
  };

  onSubmit = () => {
    const valid = this.getValidationStatus();

    if (valid) {
      this.checkUpcAndInnerPackMatch(this.state.data, this.createSku);
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  handleButtonCancel = () => {
    const { crudMode, backUpData } = this.state;
    if (crudMode === EVENT_OPERATION.CREATE) {
      this.setState({
        data: form.mapper({}),
        clearData: true,
      });
    } else {
      this.setState({
        data: { ...backUpData },
        crudMode: EVENT_OPERATION.READ,
      });
    }
  };

  getValidationStatus = () => {
    const catalogSelectValidationStatus = this.getCatalogSelectValidationStatus();
    const formValidationStatus = this.getFormValidationStatus();

    return catalogSelectValidationStatus && formValidationStatus;
  };

  rateProcessor = (prevRates, newRates) => {
    const prevRatesLength = prevRates.length;
    return newRates.slice(prevRatesLength);
  };

  createSku = () => {
    const { data, crudMode } = this.state;
    const { displayAlert } = this.props;
    const { Rates, ...updatedData } = data;
    const newRatesFromDialog = this.getRates();
    updatedData.rates = this.rateProcessor(Rates, newRatesFromDialog);
    updatedData.catalogDetails = this.getSkuCatalogDetails();
    updatedData.images = this.getImages();
    if (crudMode === EVENT_OPERATION.CREATE && updatedData.rates.length === 0) {
      displayAlert(ALERT_TYPE.WARNING, 'Enter SKU batch and rate');
    } else if (crudMode === EVENT_OPERATION.CREATE && updatedData.catalogDetails[0].CatalogDetail.title === '') {
      displayAlert(ALERT_TYPE.WARNING, 'Enter SKU Catalog');
    } else {
      this.onFormSubmit(crudMode, updatedData);
    }
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  responseProcessor = () => {
    const onAPIRequestSuccess = type => () => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    };

    return onAPIRequestSuccess;
  };

  directToMainPage = () => {
    /** direct to SKU page */
    const { history } = this.props;
    history.push(`/${SKU}`);
  };

  onAddClick = () => {
    this.setState({ clearData: false });
  };

  onStatusChanges = (type, data) => {
    this.setState({
      skuId: data.id,
      dialogConfirmationType: type,
    });
  };

  onDialogSubmit = () => {
    const { data } = this.state;
    const { updateSKU, displayAlert } = this.props;
    const { Rates, ...updatedData } = data;
    const newRatesFromDialog = this.getRates();
    updatedData.rates = this.rateProcessor(Rates, newRatesFromDialog);
    updatedData.catalogDetails = this.getSkuCatalogDetails();
    const finalData = crudRequestConfig[EVENT_OPERATION.UPDATE].objectMapper(updatedData);
    const prepareData = {
      ...finalData.input,
      discontinue_sku: true,
    };

    updateSKU(
      {
        id: updatedData.id,
        input: prepareData,
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'SKU Discontinued Successfully');
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  render() {
    const {
      data,
      enableErrorDisplay,
      skuId,
      crudMode,
      clearData,
      uomList,
      skuTagList,
      routeSegmentationValidation,
      caseUnitList,
      vendorSbfList,
      dialogConfirmationType,
      withOutIcon,
      lineSegmentationData,
      listMasterConfigurationData,
    } = this.state;

    const { serverResponseWaiting, getCatalogDetail, displayAlert, getCatalogList } = this.props;
    const updateMode = crudMode === EVENT_OPERATION.UPDATE;
    const createMode = crudMode === EVENT_OPERATION.CREATE;

    return (
      <SKUStyled>
        <div className="section-header border-b">
          <PanelStyled>
            <BreadCrumb list={breadCrumbConfig} />
            <PanelHeader>
              <h2>{addEdit(updateMode, skuId ? data.title : title)}</h2>
              <div className="flex m-0">
                {(crudMode === EVENT_OPERATION.CREATE || updateMode) && (
                  <div>
                    <Button small secondary disabled={serverResponseWaiting} onClick={() => this.handleButtonCancel()}>
                      <span>Cancel</span>
                    </Button>
                    <Button small primary disabled={serverResponseWaiting} onClick={() => this.onSubmit()}>
                      <span>Save</span>
                    </Button>
                  </div>
                )}
                {!updateMode
                  ? this.permission.update &&
                    !createMode && (
                      <div>
                        {dialogConfirmationType && (
                          <DialogFormWrapper
                            formConfig={{ title: 'Discontinue SKU' }}
                            onDialogSubmit={() => this.onDialogSubmit()}
                            onDialogCancel={() => {
                              this.setState({ dialogConfirmationType: '' });
                            }}
                            type={dialogConfirmationType}
                            renderDialog={() => (
                              <>{confirmationGenerator(EVENT_OPERATION.DISCONTINUE, null, data.title)}</>
                            )}
                          />
                        )}
                        {this.permission.update && (
                          <>
                            <Button
                              secondary
                              iconBtnSmall
                              disabled={updateMode}
                              onClick={() => {
                                this.setState({
                                  crudMode: EVENT_OPERATION.UPDATE,
                                });
                              }}
                              className=" m-0 ml-16 "
                            >
                              <Icon iconName="pencil" />
                            </Button>
                            <Button className={'text-right simple-popup-actions '} onClick={e => e.stopPropagation()}>
                              {menuAction(
                                menuConfigList,
                                { menuIcon: 'ellipsis-v' },
                                this.onStatusChanges,
                                data,
                                this.permission,
                                withOutIcon,
                              )}
                            </Button>
                          </>
                        )}
                      </div>
                    )
                  : ''}
              </div>
            </PanelHeader>
          </PanelStyled>
        </div>
        <div className="section-content">
          <div className={` config-view-wrap  ${createMode || updateMode ? '' : 'disabled'}`}>
            <CreateSkuForm
              data={data}
              uomList={uomList}
              crudMode={crudMode}
              skuTagList={skuTagList}
              caseUnitList={caseUnitList}
              vendorSbfList={vendorSbfList}
              loading={serverResponseWaiting}
              enableErrorDisplay={enableErrorDisplay}
              handleInputChange={this.handleInputChange}
              handleDropDownChange={this.handleDropDownChange}
              refsObj={this.formReference}
              line={lineSegmentationData}
              routeSegmentationValidation={routeSegmentationValidation}
            />
            <div className="config-inner">
              <Image
                crudMode={crudMode}
                images={data.images}
                getImages={childMethod => (this.getImages = childMethod)}
              />
            </div>
            <div className="config-inner">
              <CatalogSelect
                crudMode={crudMode}
                skuId={skuId}
                clearData={clearData}
                displayAlert={displayAlert}
                getCatalogList={getCatalogList}
                skuCatalogDetails={clone(data.catalogDetails)}
                getCatalogDetail={getCatalogDetail}
                enableErrorDisplay={enableErrorDisplay}
                getStatus={childMethod => (this.getCatalogSelectValidationStatus = childMethod)}
                getSkuCatalogDetails={childMethod => (this.getSkuCatalogDetails = childMethod)}
                onAddClick={this.onAddClick}
              />
            </div>
            <div className="config-inner">
              <GridDialog
                rates={clone(data.Rates)}
                crudMode={crudMode}
                getRates={childMethod => (this.getRates = childMethod)}
                clearData={clearData}
                onAddClick={this.onAddClick}
                listMasterConfigurationData={listMasterConfigurationData}
                routeSegmentationValidation={routeSegmentationValidation}
              />
            </div>
          </div>
        </div>
      </SKUStyled>
    );
  }
}

CreateSku.propTypes = propTypes;

CreateSku.defaultProps = defaultProps;

export default withAlert()(CreateSku);
