import { FOCUSED_SKU } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Focused SKU',
    path: `/${FOCUSED_SKU}`,
    active: true,
  },
];

export { breadCrumb };
