import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../../utils/paginatedListUtils';
import { detailsLabelConfig, DetailTableBody } from './tableConfig';

const propTypes = {
  data: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: [],
};

const StockLedgerChildView = ({ data }) => (
  <Fragment>
    <tr>
      {detailsLabelConfig.length > 0 &&
        detailsLabelConfig.map((label) => (label.show ? tableData(label.title, label.align, label.classNames) : null))}
    </tr>
    {data.map((order) => (
      <tr>
        {DetailTableBody({
          data: order,
          order,
        })}
      </tr>
    ))}
  </Fragment>
);

StockLedgerChildView.propTypes = propTypes;

StockLedgerChildView.defaultProps = defaultProps;

export default StockLedgerChildView;
