import PropTypes from 'prop-types';
import React from 'react';
import { Collapsible, Search, } from '../../../../../../components';
import { Switch,Badge } from '../../../../../../v4/components';
import PermissionStyled from './PermissionStyled';

const propTypes = {
  assortmentCounts: PropTypes.instanceOf(Object),
  onSearchInputChange: PropTypes.func.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  skuFamilies: PropTypes.instanceOf(Array),
  searchText: PropTypes.string,
  selectedSkus: PropTypes.instanceOf(Array),
};

const defaultProps = {
  assortmentCounts: { skuFamilies: [] },
  skuFamilies: [],
  searchText: '',
  selectedSkus: [],
};

const SkuFamily = ({
  data,
  onSwitchChange,
}) => {
  return (
    <PermissionStyled>
      <div className='panel-section'>
        <div className='panel three'>
          <div className='panel-list'>
            <div className='div-thead'>
              <div className='entity'>
                <span>Entity</span>
              </div>
              <div className='permission-access'>
                <span className='permission'>Permissions</span>
                <div className='operations'>
                  <span className='access'>Menu</span>
                  <span className='access'>Read</span>
                  <span className='access'>Create</span>
                  <span className='access'>Update</span>
                  <span className='access'>Delete</span>
                </div>
              </div>
            </div>
            <div className='div-tbody'>
              {data?.list?.map((parent) => (
                <Collapsible
                  trigger={
                    <>
                      <span>
                        <span className='text'>{parent.module}</span>
                      </span>
                    </>
                  }
                >
                  <div className='collapsible-innt'>
                    {_.sortBy(parent.child,['program']).map((child,index) => (
                      <div className={`collapsible-inner ${index == 0 && 'zero'}`} key={child.id} onClick={(e) => e.stopPropagation()}>
                        <span className='permission'>{child.program}</span>
                        <div className='operations'>
                          <span>
                            <Switch checked={child?.operations?.menu} onToggle={(e) => onSwitchChange(e,child.program,'menu',child.operations)} />
                          </span>
                          <span>
                            <Switch checked={child?.operations?.read} onToggle={(e) => onSwitchChange(e,child.program,'read',child.operations)} />
                          </span>
                          <span>
                            <Switch checked={child?.operations?.create} onToggle={(e) => onSwitchChange(e,child.program,'create',child.operations)} />
                          </span>
                          <span>
                            <Switch checked={child?.operations?.update} onToggle={(e) => onSwitchChange(e,child.program,'update',child.operations)} />
                          </span>
                          <span>
                            <Switch checked={child?.operations?.delete} onToggle={(e) => onSwitchChange(e,child.program,'delete',child.operations)} />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Collapsible>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PermissionStyled>
  );
};

SkuFamily.propTypes = propTypes;

SkuFamily.defaultProps = defaultProps;

export default SkuFamily;
