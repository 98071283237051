import React from 'react';
import { CheckBox, Form } from '../../../../../../v4/components';
import { CustomSelect, Input } from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import {
  NOTIFICATION_ALERT_MESSAGE,
  periodType, week, weekDays
} from '../../../../../../data/enums/Notification';
import { EMPTY, INVALID } from '../../../../../../v4/constants/inputErrors';
import { ALERT_TYPE } from '../../../../../../v4/constants/AlertType';
import { EVENT_OPERATION } from '../../../../../../v4/constants/EventOperation';
import { FREQUENCY_TYPE } from '../../../../../../data/enums';

// Get the current date
const currentDate = new Date();

// Get the number of days in the current month
const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

const suffixes = ['st', 'nd', 'rd', 'th'];
// Create an array of the days in the current month
const daysArray = Array.from(Array(daysInMonth).keys()).map((day) => day + 1);

const daysWithSuffix = daysArray.map((day) => {
  const suffix =
    day % 10 === 1 && day !== 11
      ? suffixes[0]
      : day % 10 === 2 && day !== 12
        ? suffixes[1]
        : day % 10 === 3 && day !== 13
          ? suffixes[2]
          : suffixes[3];
  return ({
    title:`${day}${suffix}`,
    id: day
  })
}
);

const Dialog = ({ ...props }) => {
  const { dialogType, data, refsObj, handleInputChange, enableErrorDisplay, icoGroupList, frequencyType,handleDropdownChange,displayAlert } = props;
  const monthDaysValidation = (e) => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
    const lastDate = lastMonth.getDate()
    let events = e
    if(e.target.value) {
      const num = Number(e.target.value)
      if(num <1 ) {
        events = { ...e,target: { ...e.target, name:'date',  value: 1 } }
      } else if(num > Number(lastDate)) {
        events = { ...e,target: { ...e.target, name:'date', value: Number(lastDate) } }
      }
    }
    return events
  }
  const timeValidation = (e,param) => {
    let events = e

    if(param==='hour'){
      if (e.target.value > 12) {
        displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.HOUR_ALERT}`);
        events = { ...e,formattedValue:'00', target: { ...e.target, name:'hour',  formattedValue: '00' } }
      }}

    if(param==='minutes'){
      if (e.target.value > 59) {
        displayAlert(ALERT_TYPE.WARNING, `${NOTIFICATION_ALERT_MESSAGE.MINUTE_ALERT}`);
        events = { ...e,formattedValue:'00', target: { ...e.target, name:'minutes',  formattedValue: '00' } }
      }}
    return events;

  }
  return (
    <Form>
      <CustomSelect
        labelContent='ICO Group'
        className='custom-select'
        options={icoGroupList}
        placeholder='Select'
        enableValidation
        enableErrorDisplay={enableErrorDisplay}
        ref={(ref) => (refsObj.ico = ref)}
        getOptionLabel={({ title }) => title}
        getOptionValue={({ id }) => id}
        onChange={(e) => handleDropdownChange(e.id,['IcoGroupId'])}
        value={icoGroupList.filter((a) => (data.IcoGroupId===a.id))}
        disabled={dialogType===EVENT_OPERATION.UPDATE}
      />
      <Input
        labelContent='DOH (Days of Holding)'
        placeholder='0'
        type='number'
        value={data.doh}
        name='doh'
        onChange={(event) => handleInputChange(event)}
        enableValidation
        rule='isInt'
        argument={{ min: 1, allow_leading_zeroes: false }}
        enableErrorDisplay={enableErrorDisplay}
        errorMessage={data.doh > 0 ? INVALID : 'Must be greater than 0'}
        ref={(ref) => (refsObj.doh = ref)}
      />
      <CustomSelect
        labelContent='Frequency'
        className='custom-select'
        options={frequencyType}
        placeholder='Select'
        enableValidation
        enableErrorDisplay={enableErrorDisplay}
        ref={(ref) => (refsObj.frequency = ref)}
        getOptionLabel={({ label }) => label}
        getOptionValue={({ value }) => value}
        value={frequencyType.filter((a) => (data.frequency.type===a.value))}
        onChange={(e) => handleDropdownChange(e.value,['frequency','type'],'frequency')}
      />
      {data.frequency.type==='WEEKLY' &&
            (<div className='reapeat__days'>
              <label>Repeat Days</label>
              <div className='checkbox-list'>
                {week.map((d, index) => (
                  <CheckBox
                    key={d}
                    checked={data?.frequency?.day?.includes(weekDays[d])}
                    name={d}
                    className='checkbox'
                    labelText={d.slice(0, 3).toUpperCase()}
                    onCheckBoxClick={(e) => {
                      handleInputChange(
                        {
                          formattedValue: weekDays[d],
                          target: {
                            name: 'day',
                            value: weekDays[d],
                            checked: !!e.target.checked,
                          },
                        },
                        'frequency', ['frequency','day'],'checkbox'
                      );
                    }}
                  />
                ))}
              </div>
            </div>
            )}
      {data.frequency.type===FREQUENCY_TYPE.MONTHLY&&
          <div className='custom-date'>
            <Input
              className='month-input'
              value={data.frequency.date}
              name='date'
              type='number'
              rule='matches'
              argument={/^([1-9]|[0-2][0-9]|[3][0-1])$/}
              labelContent='Day of the Month'
              placeholder='0'
              enableValidation
              onChange={(e) => {
                const event = monthDaysValidation(e)
                handleInputChange({ ...event,target:{ ...event.target,name:'date' } },  'frequency', ['frequency', 'date'], 'monthly')}}
              ref={(ref) => data.frequency.type===FREQUENCY_TYPE.MONTHLY?(refsObj.date = ref):null}
              enableErrorDisplay={data.frequency.type===FREQUENCY_TYPE.MONTHLY?enableErrorDisplay:false}
            />
          </div>}
      {data.frequency.type === FREQUENCY_TYPE.FORTNIGHTLY && (
        <div className='custom-date'>
          <Input
            className='month-input'
            value={data.frequency.date?.[0]}
            argument={/^(1[0-5]|[1-9])$/}
            name='date'
            type='number'
            labelContent='Day of the Month'
            placeholder='0'
            enableValidation
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue<=15) {
                const event = monthDaysValidation(e);
                handleInputChange({ ...event,target:{ ...event.target,name:'date' } }, 'frequency', ['frequency', 'date'],'fortnightly');
              }
            }}
            ref={(ref) => (data.frequency.type === FREQUENCY_TYPE.FORTNIGHTLY ? (refsObj.date = ref) : null)}
            enableErrorDisplay={data.frequency.type === FREQUENCY_TYPE.FORTNIGHTLY ? enableErrorDisplay : false}
          />

          {data.frequency.date?.length>0?(<label>{data.frequency.date?.[1] && `The next occurrence: ${data.frequency.date?.[1]}`}</label>):''}

        </div>
      )}
      {data.frequency.type === FREQUENCY_TYPE.TRIMONTHLY && (
        <div className='custom-date'>
          <Input
            className='month-input'
            value={data.frequency.date?.[0]}
            argument={/^(1[0-5]|[1-9])$/}
            name='date'
            type='number'
            labelContent='Day of the Month'
            placeholder='0'
            enableValidation
            onChange={(e) => {
              const inputValue = e.target.value;
              if (inputValue<=15) {
                const event = monthDaysValidation(e);
                handleInputChange({ ...event,target:{ ...event.target,name:'date' } }, 'frequency', ['frequency', 'date'],'trimonthly');
              }
            }}
            ref={(ref) => (data.frequency.type === FREQUENCY_TYPE.TRIMONTHLY ? (refsObj.date = ref) : null)}
            enableErrorDisplay={data.frequency.type === FREQUENCY_TYPE.TRIMONTHLY ? enableErrorDisplay : false}
          />

          <label>{data.frequency.date?.[1] && `The next occurrence: ${data.frequency.date?.[1]}`}
            {data.frequency.date?.[2] && `, ${data.frequency.date?.[2]}`}
          </label>

        </div>
      )}
      {data.frequency.type ===FREQUENCY_TYPE.CUSTOM && <CustomSelect
        isMulti
        labelContent='Days of the Month'
        className='custom-select'
        options={daysWithSuffix}
        placeholder='Select'
        getOptionLabel={({ title }) => title}
        getOptionValue={({ id }) => id}
        onChange={(event) => {
          const customDays = event.map((d) => d.id);
          handleDropdownChange(customDays, ['frequency', 'date']);
        }}
        value={daysWithSuffix.filter((a) => (data.frequency ? data.frequency.date.includes(a.id) : false))}
        enableValidation
        ref={(ref) => data.frequency.type===FREQUENCY_TYPE.CUSTOM?(refsObj.date = ref):null}
        enableErrorDisplay={data.frequency.type===FREQUENCY_TYPE.CUSTOM?enableErrorDisplay:false}
      />}
      <label className='time-label'>Time</label>
      <div className='time'>
        <div className='time__hours'>
          <Input
            value={data?.frequency?.times?.hours}
            type='number'
            rule='isInt'
            onChange={(e) => {
              const event = timeValidation(e,'hour')
              handleInputChange(event, 'frequency', ['frequency','times', 'hours'])}}
            argument={{
              max: 12,
            }}
            enableValidation
            enableErrorDisplay={enableErrorDisplay}
            errorMessage={data?.frequency?.times?.hours ? INVALID : EMPTY}
          />
          <label>HH</label>
        </div>
        <div className='time__hours'>
          <Input
            value={data?.frequency?.times?.minutes}
            type='number'
            placeholder='MM'
            rule='isInt'
            onChange={(e) => {
              const event = timeValidation(e,'minutes')
              handleInputChange(event, 'frequency', ['frequency','times', 'minutes'])
            }}
            allowMultiZeroBeforeNumber
            argument={{
              max: 60,
            }}
          />
          <label>MM</label>
        </div>
        <div className='time__hours'>
          <CustomSelect
            name='period'
            className='custom-select'
            options={periodType}
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            value={periodType.filter(({ title }) => title === data?.frequency?.times?.period)}
            onChange={(event) => { handleInputChange({
              ...event, formattedValue :event.title, target: {
                name: 'period',
                value: event.title,
                checked: true,
              },
            }, 'frequency', ['frequency','times', 'period'])}}
          />
          <label>AM/PM</label>
        </div>
      </div>
    </Form>
  );
};

const DialogWithErrorAndLoading = withLoading(Dialog);

export default DialogWithErrorAndLoading;
