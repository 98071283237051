import PropTypes from 'prop-types';
import React from 'react';
import VerticalListWithCount from '../../../../../components/VerticalListWithCount';
import { Search } from '../../../../../../components';
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
import { PermissionContext } from '../config';
import AssortmentPanelStyled from '../../../../channelAssortment/details/AssortmentPanelStyled';
import { Switch } from '../../../../../../v4/components';

const propTypes = {
  getDistributorDetails: PropTypes.func.isRequired,
  groupCountTerritoryWise: PropTypes.instanceOf(Array),
  handleSearchInput: PropTypes.func.isRequired,
  subDs: PropTypes.instanceOf(Array),
  territories: PropTypes.instanceOf(Array),
  territoryId: PropTypes.number,
  selectedSubDs: PropTypes.arrayOf(Array),
  selectableSubDs: PropTypes.arrayOf(Array),
  onSwitchChange: PropTypes.func.isRequired,
};

const defaultProps = {
  groupCountTerritoryWise: [],
  subDs: [],
  territories: [],
  territoryId: 0,
  selectedSubDs: [],
  selectableSubDs: [],
};
const SubDGroupView = ({
  territories,
  groupCountTerritoryWise,
  getDistributorDetails,
  subDs,
  territoryId,
  selectedSubDs,
  onSwitchChange,
  handleSearchInput,
  selectableSubDs,
}) => {
  const getPrimarySwitchStatus = () => selectableSubDs.length !== 0 && selectableSubDs.length === selectedSubDs.length;

  return (
    <PermissionContext.Consumer>
      {() => (
        <AssortmentPanelStyled>
          <div className='panel-section create-ui-inner darkshade-bg'>
            <VerticalListWithCount
              list={territories}
              countList={groupCountTerritoryWise}
              onItemClick={getDistributorDetails}
              activeLine={territoryId}
              title='Zone'
            />
            {territoryId && (
              <>
                <div className='panel three'>
                  <div className='panel-title'>
                    <p className='p-title'>SUB D</p>
                    <Search handleSearchInput={handleSearchInput} />
                  </div>
                  <div className='table-wrap'>
                    <table>
                      <thead>
                        <tr>
                          <th>Sub D</th>
                          <th />
                          <th className={`text-right ${selectableSubDs.length>0?'switch':''} `}>
                            <span>Status</span>
                            {selectableSubDs.length>0&&
                            <Switch
                              checked={getPrimarySwitchStatus()}
                              onToggle={(e) => onSwitchChange(e, selectableSubDs)}
                            />
                            }
                            
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {subDs.map((subD) => (
                          <tr key={`${subD.distributorId}-subd`}>
                            <td key={`${subD.distributorId}-title`}>{subD.title}</td>
                            <td />
                            <td className='text-right' key={`${subD.distributorId}-switch`}>
                              <Switch
                                checked={selectedSubDs.includes(subD.distributorId)}
                                onToggle={(e) => onSwitchChange(e, [subD.distributorId])}
                                disabled={subD.used}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        </AssortmentPanelStyled>
      )}
    </PermissionContext.Consumer>
  );
};

SubDGroupView.propTypes = propTypes;

SubDGroupView.defaultProps = defaultProps;

const SubDGroupViewWithError = withErrorAndLoadingScreen(SubDGroupView);

export default SubDGroupViewWithError;
