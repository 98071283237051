import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { inventory as inventoryRoute } from '../../../data/enums/Route';

const breadCrumb = [inventoryRoute];

const Title = 'Warehouse Levels';

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
    title: element.title || '',
    levelIndication: element.levelIndication || '',
    parentId: element.parentId || null,
    dimensionLength: element.dimensionLength || 0,
    dimensionWidth: element.dimensionWidth || 0,
    dimensionHeight: element.dimensionHeight || 0,
  }),
  validationField: ['title', 'parentId', 'levelIndication', 'dimensionLength', 'dimensionHeight', 'dimensionWidth'],
  validationRequired: true,
};

const filter = getFilterConfig([FILTER.STATUS]);
const getInput = (data, type = EVENT_OPERATION.CREATE) => ({
  title: data.title,
  parentId: data.parentId,
  dimensionLength: data.dimensionLength,
  dimensionHeight: data.dimensionHeight,
  dimensionWidth: data.dimensionWidth,
  levelIndication: data.levelIndication,
});

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({ input: getInput(data, EVENT_OPERATION.CREATE) }),
    responseName: 'createWarehouseLevel',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      input: getInput(data, EVENT_OPERATION.UPDATE),
    }),
    responseName: 'editWarehouseLevel',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
    }),
    responseName: 'deleteWarehouseLevels',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
    }),
    responseName: 'deleteWarehouseLevels',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

const crudBulkDeleteRequestConfig = {
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (element) => ({
      ids: [...element.data],
      type: element.type,
      active: false,
    }),
    responseName: 'deleteWarehouseLevels',
    message: `${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export {
  crudRequest,
  breadCrumb as breadCrumbConfig,
  formConfig,
  filter as filterConfig,
  Title as title,
  crudBulkDeleteRequestConfig,
};
