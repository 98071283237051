import { compose } from 'recompose';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  CREATE_CALENDAR, UPDATE_CALENDAR, query, mutation
} from './API';
import HolidaysSection from './HolidaysSection';

const composedHolidaySection = compose(
  CREATE_CALENDAR,
  UPDATE_CALENDAR,
  withRequestTracker({
    query,
    mutation,
  }),
)(HolidaysSection);

export default composedHolidaySection;
