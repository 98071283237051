import { compose } from 'recompose';
import TargetCoverage from './Coverage';

import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  UPLOAD,
  DOWNLOAD_REPORT,
  CREATE_OUTLET_COVERAGE_TARGET,
  UPDATE_OUTLET_COVERAGE_TARGET,
} from './API';

const ComposedTargetCoverage = compose(
  UPLOAD,
  DOWNLOAD_REPORT,
  CREATE_OUTLET_COVERAGE_TARGET,
  UPDATE_OUTLET_COVERAGE_TARGET,
  withRequestTracker({
    query,
    mutation,
  }),
)(TargetCoverage);

export default ComposedTargetCoverage;
