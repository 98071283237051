import React from 'react';
import ModalStyled from './ModalStyled';

const Modal = ({ handleClose, handler, show, disable, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  const toggleButtonClass = disable ? 'disabled-btn' : 'blue';

  return (
    <ModalStyled>
      <div className={showHideClassName}>
        <section className='modal-main'>
          {children}
          <div className='m-footer'>
            <button className={toggleButtonClass} onClick={handler} disabled={disable}>
              Ok
            </button>
            <button className='m-close' onClick={handleClose}>
              Cancel
            </button>
          </div>
        </section>
      </div>
    </ModalStyled>
  );
};
export default Modal;
