import React, { Fragment } from 'react';
import { Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import CustomSelect from '../../../components/CustomSelect';
import { Pagination } from '../../../v4/components';
import { paginationProps } from '../../../data/enums/Component';
import OpeningBalanceStyled from './OpeningBalanceStyle';
import { TableHeader } from './tableConfig';
import Detail from './detail';

const propTypes = {
  componentDisplay: PropTypes.instanceOf(Object),
  distributorList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  pagination: paginationProps.value,
  inputData: PropTypes.instanceOf(Object),
  onPageChange: PropTypes.func,
  onDropDownChange: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool,
  onHeaderClick: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
  openingBlncDetail: PropTypes.instanceOf(Array),
};
const defaultProps = {
  componentDisplay: {},
  distributorList: [],
  openingBlncDetail: [],
  data: {},
  pagination: paginationProps.defaultValue,
  enableErrorDisplay: false,
  inputData: {},
  onHeaderClick: () => null,
  onPageChange: () => null,
};

const OpeningBalanceView = ({
  data,
  pagination,
  onPageChange,
  onDropDownChange,
  distributorList,
  enableErrorDisplay,
  componentDisplay,
  inputData,
  onHeaderClick,
  onRowClick,
  openingBlncDetail,
}) => (
  <Fragment>
    <OpeningBalanceStyled>
      <div className='distributor-selection'>
        {componentDisplay.distributorSelection && (
          <div>
            <Col md={3} className='mt-6'>
              <CustomSelect
                small
                enableValidation
                options={distributorList}
                className='custom-select'
                placeholder='Select Distributor'
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => onDropDownChange(event.id)}
                value={distributorList.filter(({ id }) => id === inputData.distributorId) || []}
              />
            </Col>
          </div>
        )}
      </div>
      {data.list.length > 0 ? (
        <Fragment>
          <div className={`table-wrap ${distributorList.length === 0 ? 'no-select' : ''} `}>
            <table>
              {TableHeader({ onHeaderClick })}
              <Detail data={data.list} onRowClick={onRowClick} openingBlncDetail={openingBlncDetail} />
            </table>
          </div>
          <Pagination
            currentPage={pagination.page}
            limit={pagination.limit}
            totalItems={data.total}
            onPageChange={onPageChange}
          />
        </Fragment>
      ) : (
        inputData.distributorId && (
          <div className='opening-balance-container'>
            <div className='text'> Please upload the opening balance.</div>
          </div>
        )
      )}
    </OpeningBalanceStyled>
  </Fragment>
);

OpeningBalanceView.propTypes = propTypes;

OpeningBalanceView.defaultProps = defaultProps;

const OpeningBalanceViewWithErrorAndLoading = withErrorAndLoadingScreen(OpeningBalanceView);

export default OpeningBalanceViewWithErrorAndLoading;
