import React from 'react';
import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';
import withLoading from '../../../../utils/composition/withLoading';
import { Pagination, BulkDelete } from '../../../../v4/components';
import { TableBody, TableHeader } from './tableconfig';

const View = (props) => {
  const { data, pagination, onPageChange, handleBulkDelete, onIconClick, permission } =props;
  return (
    <>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.DESIGNATION}
        handler={handleBulkDelete}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap'>
            <table>
              {TableHeader({
                dataListLength: data.list.length,
                checkedListLength: checkedList.length,
                primaryCheckboxHandler,
                permission,
              })}
              <tbody>
                {data.list.map((list) =>
                  TableBody({
                    data: list,
                    checkedList: checkedList,
                    secondaryCheckboxHandler,
                    permission,
                    onIconClick
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </>
  );
}

const ViewWithErrorAndLoading = withLoading(View);

export default ViewWithErrorAndLoading;
