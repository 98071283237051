import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import { maxDigitMessage } from '../config';
import { getTodaysDate } from '../../../../../utils/date';
import { EMPTY } from '../../../../../data/enums/ErrorMessage';
import withLoading from '../../../../../utils/composition/withLoading';
import {
  Form, Input, CustomSelect
} from '../../../../../components/index';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import SingleDatePicker from '../../../../../components/DatePicker/SingleDatePicker';

const propTypes = {
  handleInputChange: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  enableErrorDisplay: PropTypes.bool,
  update: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const DetailForm = ({ ...props }) => {
  const {
    data,
    update,
    refsObj,
    enableErrorDisplay,
    handleInputChange,
    fiscalYearList,
    fiscalYear,
    handleDropDownChange,
    dropDownCallBack,
  } = props;
  return (
    <Form className='campaign-form'>
      <Row>
        <Col className='mb-48' md={8}>
          <Input
            type='text'
            enableValidation
            name='title'
            value={data.title}
            placeholder='Title'
            labelContent='Title'
            ref={(ref) => (refsObj.title = ref)}
            enableErrorDisplay={enableErrorDisplay}
            onChange={(event) => handleInputChange(event, 'title', ['title'])}
          />
        </Col>
        <Col className='mb-24' md={4} />
        <Col className='mb-24' md={4}>
          <Input
            ref={(ref) => (refsObj.budget = ref)}
            value={data.budget > 0 ? data.budget : ''}
            name='budget'
            type='number'
            rule='matches'
            placeholder='Budget'
            labelContent='Budget'
            argument={/^[0-9.]{1,10}$/}
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={(event) => handleInputChange(event, 'budget', ['budget'])}
            errorMessage={data.budget ? maxDigitMessage : EMPTY}
          />
        </Col>
        <Col className='mb-24' md={4}>
          <CustomSelect
            enableValidation
            options={fiscalYearList}
            labelContent='Fiscal Year'
            className='custom-select'
            placeholder='Select Fiscal Year'
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            ref={(ref) => (refsObj.fiscalYearId = ref)}
            enableErrorDisplay={enableErrorDisplay}
            onChange={(event) => handleDropDownChange(event.id, ['fiscalYearId'], dropDownCallBack)}
            value={fiscalYearList.filter(({ id }) => id === data.fiscalYearId)}
          />
        </Col>
        <Col className='mb-24' md={4} />
        <Col md={4} className='mb-24'>
          <div className='single-date-picker'>
            <label>Start Date</label>
            <SingleDatePicker
              name='startDate'
              date={data.startDate || getTodaysDate()}
              onChange={(name, date) =>
                handleInputChange(
                  {
                    target: {
                      name: 'startDate',
                    },
                    formattedValue: date,
                  },
                  'startDate',
                  ['startDate'],
                )
              }
              minDate={fiscalYear.start}
              maxDate={fiscalYear.end}
            />
          </div>
        </Col>
        <Col md={4} className='mb-24'>
          <div className='single-date-picker'>
            <label>End Date</label>
            <SingleDatePicker
              name='end_date'
              date={data.endDate || getTodaysDate()}
              onChange={(name, date) =>
                handleInputChange(
                  {
                    target: {
                      name: 'endDate',
                    },
                    formattedValue: date,
                  },
                  'endDate',
                  ['endDate'],
                )
              }
              minDate={fiscalYear.start}
              maxDate={fiscalYear.end}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

DetailForm.propTypes = propTypes;

DetailForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(DetailForm);

export default LoginViewWithErrorAndLoading;
