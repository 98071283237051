// import { numberPresentor } from '../../../../utils/conversion';
import { numberPresentor } from '../../../../../utils/conversion';
import { tableData, Icon } from '../../../../../v4/components';
import { EVENT_OPERATION } from '../../../../../v4/constants/EventOperation';

const labelMappings = {
  outletName: 'outletName',

  action: 'action',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.outletName,
    title: 'Outlet Name',
    show: true,
  },

  {
    id: 2,
    label: labelMappings.action,
    title: '',
    show: true,
  },
];

const returnTableBody = (data, handleIconClick, displayEdit, permission) => {
  const table = {};
  table[labelMappings.outletName] = <td key={`${data.id}-subd`}>{data?.outlet}</td>;
  table[labelMappings.action] = (
    <td key={`${data.id}-action`} className='right-align'>
      <>
        {permission.read&&<span className='view' onClick={() => handleIconClick(EVENT_OPERATION.READ, data)}>
          <Icon iconName='view' />
          View
        </span>}
        {permission.update&&
          <span onClick={() => handleIconClick(EVENT_OPERATION.UPDATE, data)}>
            <Icon iconName='pencil' />
            Edit
          </span>
        }
      </>
    </td>
  );

  return table;
};
const TableHeader = () => (
  <thead>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

const TableBody = ({ data, handleIconClick, displayEdit, permission }) => {
  const tableBody = returnTableBody(data, handleIconClick, displayEdit, permission);

  return (
    <tr key={data.distributorId} className='cursor-pointer'>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

export { TableHeader, TableBody };
