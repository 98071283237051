import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { configuration as configurationRoute, icoGroup } from '../../../data/enums/Route';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { FORM_CONFIG } from '../../../data/enums/config';
import { presentor } from '../../../utils/date';

const breadCrumb = [configurationRoute];

const Title = 'BU';

const dateFormat = [
  {
    id: 'mm/dd/yy',
    title: 'MM/DD/YY',
  },
  {
    id: 'dd/mm/yy',
    title: 'DD/MM/YY',
  },
  {
    id: 'yy/mm/dd',
    title: 'YY/MM/DD',
  },
];

const dateType = [
  {
    id: 'NEPALI',
    title: 'BS',
  },
  {
    id: 'ENGLISH',
    title: 'AD',
  },
];

const responseToState = ({ ...response }) => {
  const {
    ico,
    currency,
    fiscalYear,
    unitsOfMeasurement,
    dateSettings,
    web_order,
    orderForMultipleDistributors,
    targetDimension,
    geoFencing,
    pfd,
    brandDistributionCoverageTarget,
    outletCoverageTarget,
    multipleDseInRoute,
    payment,
    srn,
    orderStatus,
    targets,
    sbd,
    focusedSKU,
    skuAssortment,
    routeSegmentation,
    stockMovementRule,
    sapIntegration,
    smsIntegration,
    smsforOrderIntegration,
    callRoster,
    brand_wise_order,
    fundamental_targets,
    targetDimensionMetric,
    rigoIntegration,
    orderTakingSequence,
    orderSequenceAttribute,
  } = response;

  const orderSequenceEnabled = orderTakingSequence?.orderTakingSequence ?? false;
  const orderSequenceGroupAndAttribute = [...(orderTakingSequence?.sequencing || []), ...orderSequenceAttribute];
  // Use an object to store unique aliases
  const uniqueAliases = {};

  // Filter out duplicates and add the "sequence" property
  const uniqueData = orderSequenceGroupAndAttribute.filter((item, index) => {
    if (!uniqueAliases[item.alias] && item.alias !== 'cvt') {
      uniqueAliases[item.alias] = true;
      item.sequence = index + 1;
      item.active = orderSequenceEnabled === false ? orderSequenceEnabled : item.active;
      return true;
    }
    return false;
  });

  const orderTakingSequenceHash = {};
  return {
    ico,
    currency,
    fiscalYear,
    unitsOfMeasurement,
    dateSettings,
    orderForMultipleDistributors,
    targetDimension,
    targetDimensionMetric,
    geoFencing,
    pfd,
    web_order,
    brandDistributionCoverageTarget,
    outletCoverageTarget,
    multipleDseInRoute,
    payment,
    srn,
    skuAssortment,
    // show menu if either of one is configured.
    skuAssortmentMenu: skuAssortment.channel || skuAssortment.distributorGroup,
    orderStatus: {
      received: orderStatus ? orderStatus.received : false,
      invoiced: orderStatus ? orderStatus.invoiced : false,
      dispatched: orderStatus ? orderStatus.dispatched : false,
      delivered: orderStatus ? orderStatus.delivered : false,
    },
    targets: {
      salesTeamWise: targets ? targets.salesTeamWise : false,
      subDWise: targets ? targets.subDWise : false,
      brandWise: targets ? targets.brandWise : false,
      automatedSalesTarget: targets ? targets.automatedSalesTarget : false,
      manualSalesTarget: targets ? targets.automatedSalesTarget === false : false,
    },
    sbd,
    focusedSKU,
    routeSegmentation,
    stockMovementRule,
    sapIntegration,
    smsIntegration,
    smsforOrderIntegration,
    callRoster,
    brand_wise_order: {
      status: brand_wise_order ? brand_wise_order.status : false,
      skipped_phone_numbers: brand_wise_order ? brand_wise_order.skipped_phone_numbers : [],
      distributor_ids: brand_wise_order ? brand_wise_order.distributor_ids : [],
    },
    fundamental_targets,
    rigoIntegration,
    orderTakingSequence: { orderTakingSequence: orderSequenceEnabled, sequencing: uniqueData },
    orderTakingSequenceFormattedData: uniqueData?.map((item, index) => {
      const id = index + 1;
      orderTakingSequenceHash[id] = item;
      // this format is used in other drag and drop component for BU
      // so we structured our data to this format below
      return {
        id: null,
        refId: null,
        sequence: null,
        alias: item?.alias,
        CallProcedure: {
          id,
          title: item.title,
        },
        active: item.active,
        type: null,
      };
    }),
    orderTakingSequenceHash,
  };
};

const targetDimension = ['VALUE', 'VOLUME'];

const formConfig = {
  mapper: element => ({
    id: element.id || '',
    title: element.title || '',
    territoryId: (element.Territory ? element.Territory.id : element.territoryId) || '',
  }),
  validationField: ['title', 'territoryId'],
  validationRequired: true,
};

const crudRequest = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: data => ({ title: data.title, territoryId: Number(data.territoryId) }),
    responseName: 'createTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
      territoryId: Number(data.territoryId),
    }),
    responseName: 'updateTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'deleteTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: data => ({
      id: data.id,
      title: data.title,
    }),
    responseName: 'updateTown',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.APPROVE]: {
    [FORM_CONFIG.TITLE]: 'APPROVE',
    [FORM_CONFIG.VALIDATION_REQUIRED]: false,
    [FORM_CONFIG.MAPPER]: (element = {}) => ({ id: element.id || '' }),
  },
};

export const deepCompare = (obj1 = {}, obj2 = {}) => {
  // Check if both arguments are objects
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    // Get the keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate over keys of obj1
    for (let key of keys1) {
      // Recursively compare values if they are objects or arrays
      if (!deepCompare(obj1[key], obj2[key])) {
        return false;
      }
    }

    // All properties are equal
    return true;
  } else {
    // If both are not objects, compare directly
    return obj1 === obj2;
  }
};

const initialServiceData = {
  geoFencing: 0,
  web_order: false,
  orderForMultipleDistributors: false,
  outletCoverageTarget: false,
  pfd: false,
  srn: false,
  focusedSKU: false,
  routeSegmentation: false,
  sapIntegration: false,
  callRoster: false,
  brand_wise_order: {
    status: false,
  },
  rigoIntegration: false,
  smsIntegration: false,
  smsforOrderIntegration: false,
};
const initialFyData = {
  fiscalYear: {
    start: presentor(new Date()),
    end: presentor(new Date()),
  },
};
const initialTargetsData = {
  targets: {
    salesTeamWise: false,
    subDWise: false,
    brandWise: false,
    automatedSalesTarget: false,
    manualSalesTarget: false,
  },
  targetDimensionMetric: '',
  targetDimension: '',
  target: { targetList: [], targetDimension: [], targetDimensionMetricList: [] },
  fundamental_targets: [],
};
const initialSkuAssortment = {
  skuAssortmentMenu: false,
  skuAssortment: {
    distributorGroup: false,
    channel: false,
  },
};
const initialStock = {
  stockMovementRule: [],
};
const initialIco = {
  ico: {
    sku: false,
    skuFamily: false,
  },
};
const initialUom = {
  unitsOfMeasurement: [],
};
const initialBcpData = {
  DSESequenceData: [],
  enableOrderTakingProcess: false,
};
const initialHolidayData = {};
export const initialDataObj = {
  serviceData: { ...initialServiceData },
  serviceDataCloned: { ...initialServiceData },
  fyData: { ...initialFyData },
  fyDataCloned: { ...initialFyData },
  targetsData: { ...initialTargetsData },
  targetsDataCloned: { ...initialTargetsData },
  skuAssortmentData: { ...initialSkuAssortment },
  skuAssortmentDataCloned: { ...initialSkuAssortment },
  stockData: { ...initialStock },
  stockDataCloned: { ...initialStock },
  icoData: { ...initialIco },
  icoDataCloned: { ...initialIco },
  uomData: { ...initialUom },
  uomDataCloned: { ...initialUom },
  bcpData: { ...initialBcpData },
  bcpDataCloned: { ...initialBcpData },
  holidayData: { ...initialHolidayData },
  holidayDataCloned: { ...initialHolidayData },
};
export const TAB_INDEX = {
  TAB_SERVICE: 0,
  TAB_FY: 1,
  TAB_TARGET: 2,
  TAB_UOM: 3,
  TAB_SKU: 4,
  TAB_STOCK: 5,
  TAB_BCP: 6,
  TAB_ORDER: 7,
  TAB_MERCHANDISE: 8,
  TAB_HOLIDAYS: 9,
  TAB_ICO: 10,
};

export {
  crudRequest,
  breadCrumb as breadCrumbConfig,
  formConfig,
  dateFormat,
  dateType,
  responseToState,
  targetDimension,
};
