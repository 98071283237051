import { DASHBOARD,TAS_DASHBOARD } from '../data/enums/Route';

const LOGIN_URL = '/login';

const LOGIN_SUCCESS_BASE_URL = DASHBOARD.toLowerCase(); // DASHBOARD;
const LOGIN_SUCCESS_BASE_URL_TAS = TAS_DASHBOARD.toLowerCase(); // DASHBOARD;

const ERROR_URL = '/error';

export {
  LOGIN_URL, ERROR_URL, LOGIN_SUCCESS_BASE_URL,LOGIN_SUCCESS_BASE_URL_TAS,
};
