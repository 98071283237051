import { getFilterConfig } from '../../../utils/filter';
import { inventory } from '../../../data/enums/Route';
import { FILTER } from '../../../data/enums/filter';
import { FORM_CONFIG } from '../../../data/enums/config';
import EVENT_OPERATION from '../../../data/enums/EventOperation';
import { refGenerator } from '../../../utils/refGenerator';

const breadCrumb = [inventory];

const formMapper = (element = {}) => ({
  id: element.id || 0,
  sku: element.sku || '',
  newBinId: element?.newBinId ?? null,
  saleable: element?.saleable ?? null,
  expired: element?.expired ?? null,
  damaged: element?.damaged ?? null,
  batchName: element?.batchName ?? '',
  skuBatchId: element?.skuBatchId ?? null,
  fromBinLocationId: element?.fromBinLocationId ?? null,
  newBinIdSaleable: element?.newBinIdSaleable ?? null,
  newBinIdDamaged: element?.newBinIdDamaged ?? null,
  newBinIdExpired: element?.newBinIdExpired ?? null,
});
const requiredList = ['sku', 'newBinId', 'saleable', 'damaged', 'expired'];
const filter = getFilterConfig([FILTER.SKU]);
const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Transfer Item to New Bin Location',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    [FORM_CONFIG.MAPPER]: element => formMapper(element),
    buttonName: 'Transfer',
  },
};
const crudRequest = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: element => {
      return {
        skuBatchId: element?.skuBatchId ?? null,
        fromBinLocationId: element?.fromBinLocationId ?? 0,
        transferQuantity: {
          ...(element?.newBinIdSaleable !== null
            ? { sellable: { quantity: Number(element?.saleable) ?? 0, toBinLocationId: element?.newBinIdSaleable } }
            : {}),
          ...(element?.newBinIdExpired !== null
            ? { expired: { quantity: Number(element?.expired) ?? 0, toBinLocationId: element?.newBinIdExpired } }
            : {}),
          ...(element?.newBinIdDamaged !== null
            ? { damaged: { quantity: Number(element?.damaged) ?? 0, toBinLocationId: element?.newBinIdDamaged } }
            : {}),
        },
      };
    },
  },
};

export { breadCrumb as breadCrumbConfig, filter as filterConfig, formConfig, crudRequest };
