import { compose } from 'recompose';
import CampaignsDetails from './Details';
import {
  query, mutation, CREATE_WEB_ORDER
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedCampaignsDetails = compose(
  CREATE_WEB_ORDER,
  withRequestTracker({
    query,
    mutation,
  }),
)(CampaignsDetails);

export default ComposedCampaignsDetails;
