import { compose } from 'recompose';
import WarehouseReport from './WarehouseReport';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { query, mutation, DOWNLOAD_REPORT } from './API';

const ComposedWarehouseReport = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(WarehouseReport);

export default ComposedWarehouseReport;
