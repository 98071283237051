import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { SKU_RATE_FRAGMENT } from '../../configuration/sku/API';

import {
  AMOUNT_DETAIL_FRAGMENT,
  CUSTOMER_FRAGMENT,
  DISTRIBUTOR_FRAGMENT,
  OUTLET_CATEGORY_FRAGMENT,
  ROUTE_TITLE_FRAGMENT,
  SKU_TITLE_FRAGMENT,
  USER_TITLE_FRAGMENT,
  PROMOTION_TITLE_FRAGMENT,
  CALL_ORDER_VALUE_FRAGMENT,
  BILL_DISCOUNT_DETAILS_FRAGMENT,
  OUTLET_TITLE_FRAGMENT,
} from '../../common/GqlFragments';
import { CRITERIA_FRAGMENT } from '../../salesForce/promotion/API';
import { CATEGORY_SUMMARY_FRAGMENT, CHANNEL_FRAGMENT } from '../../configuration/channel/API';
import { GET_PRIMARY_STOCK_FROM_SAP } from '../../inventory/ICO/API';

const INVOICE_AMOUNT_DETAILS = gql`
  fragment InvoiceAmountDetailsFragment on InvoiceAmountDetails {
    grossAmount
    netAmount
    taxAmount
    taxableAmount
    subTotal
    billDiscount
    tradeDiscount
    discountAmount
    promotionDiscount
    topUpDiscount
  }
`;

const ORDER_DETAIL_FRAGMENT = gql`
  fragment OrderDetailFragment on Line {
    id
    quantity
    skuId
    callId
    skuBatchId
    amountDetails {
      ...AmountDetailFragment
    }
    rateDetails {
      rlp
      rlpWithVat
      netPrice
      netPriceWithVat
    }
    SKU {
      ...SkuFragment
    }
    SKUBatch {
      batchName
      manufactureDate
      expiryDate
      id
      dlp
    }
    SKUBatchRate {
      ...SkuRateFragment
    }
    Promotion {
      id
      title
      type
      scope
      criteria {
        ...CriteriaFragment
      }
    }
    promotionId
    distributorId
  }
  ${SKU_TITLE_FRAGMENT}
  ${SKU_RATE_FRAGMENT}
  ${AMOUNT_DETAIL_FRAGMENT}
  ${CRITERIA_FRAGMENT}
`;

const PROMOTION_ORDER_FRAGMENT = gql`
  fragment PromotionOrderFragment on PromotionOrder {
    promotionId
    Promotion {
      ...PromotionTitleFragment
    }
    Lines {
      ...OrderDetailFragment
      callId
    }
  }
  ${PROMOTION_TITLE_FRAGMENT}
  ${ORDER_DETAIL_FRAGMENT}
`;

const CALL_FRAGMENT = gql`
  fragment CallFragment on Call {
    id
    RetailOutlet {
      townId
      ...OutletCategoryFragment
    }
    Route {
      ...RouteTitleFragment
      DeliveryType {
        id
        label
      }
    }
    User {
      ...UserTitleFragment
      phoneNumber
    }
    Distributor {
      ...DistributorFragment
    }
    callOrderValue {
      ...CallOrderValueFragment
    }
    billDiscountDetail {
      ...BillDisocuntDetailsFragment
    }
    topUpDiscountDetail {
      ...BillDisocuntDetailsFragment
    }
    callTime {
      callStart
    }
    notes
    totalNetAmount
    callDate
    totalOrderCount
    promotionOrderCount
  }
  ${OUTLET_CATEGORY_FRAGMENT}
  ${ROUTE_TITLE_FRAGMENT}
  ${USER_TITLE_FRAGMENT}
  ${DISTRIBUTOR_FRAGMENT}
  ${CALL_ORDER_VALUE_FRAGMENT}
  ${BILL_DISCOUNT_DETAILS_FRAGMENT}
`;

const INVOICE_FRAGMENT = gql`
  fragment InvoiceFragment on Invoice {
    invoiceNumber
    invoiceDate
    isAbbreviatedInvoice
    Distributor {
      ...DistributorFragment
      servicesUsed {
        logistic {
          status
        }
        delivery {
          status
        }
        billing {
          status
          url
          versionCode
        }
      }
    }
    User {
      ...UserTitleFragment
    }
    RetailOutlet {
      ...OutletTitleFragment
      townId
      Customers {
        ...CustomerFragment
      }
    }
    Route {
      ...RouteTitleFragment
    }
    Channel {
      ...ChannelFragment
    }
    Category {
      ...CategorySummaryFragment
    }
    orderIds
    amountDetails {
      ...InvoiceAmountDetailsFragment
    }
    Customer {
      ...CustomerFragment
    }
    billDiscountDetail {
      ...BillDisocuntDetailsFragment
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${DISTRIBUTOR_FRAGMENT}
  ${USER_TITLE_FRAGMENT}
  ${OUTLET_TITLE_FRAGMENT}
  ${INVOICE_AMOUNT_DETAILS}
  ${CHANNEL_FRAGMENT}
  ${ROUTE_TITLE_FRAGMENT}
  ${CATEGORY_SUMMARY_FRAGMENT}
  ${BILL_DISCOUNT_DETAILS_FRAGMENT}
`;

const STOCK_REPLACEMENT_FRAGMENT = gql`
  fragment StockReplacementFragment on StockReplacement {
    id
    notes
    quantity
    retailOutlet: RetailOutlet {
      ...OutletTitleFragment
    }
    route: Route {
      ...RouteTitleFragment
    }
    dse: User {
      ...UserTitleFragment
    }
    Distributor {
      ...DistributorFragment
    }
    replacedDate
    totalLineCount
  }
  ${USER_TITLE_FRAGMENT}
  ${OUTLET_TITLE_FRAGMENT}
  ${ROUTE_TITLE_FRAGMENT}
  ${DISTRIBUTOR_FRAGMENT}
`;

const GET_TABS_TOTAL = gql`
  query getTabsTotal($filter: FilterInput) {
    allOrderCount(filter: $filter) {
      received: receivedCount
      invoiced: invoicedCount
      dispatched: dispatchedCount
      delivered: deliveredCount
      replaced: replacedCount
      confirmed: confirmedCount
    }
  }
`;

/**  tabs paginated list  */
const GET_RECEIVED_LIST = gql`
  query getReceivedCalls($offset: Int, $limit: Int, $filter: FilterInput) {
    receivedCalls(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        deliveryFrom
        deliveryTo
        remarks
        RetailOutlet {
          contactInfo {
            phoneNumber
            name
          }
          panNumber
          address
        }
        ...CallFragment
        stockReplacement: StockReplacement {
          count: lineCount
          groupId: id
        }
        Distributor {
          ...DistributorFragment
          zeroDayInventory
          servicesUsed {
            logistic {
              status
            }
            delivery {
              status
            }
            billing {
              status
              url
              versionCode
            }
          }
        }
        isRetailerOrder
      }
      count
    }
  }
  ${CALL_FRAGMENT}
  ${DISTRIBUTOR_FRAGMENT}
`;

const GET_CONFIRMED_LIST = gql`
  query getConfirmedCalls($offset: Int, $limit: Int, $filter: FilterInput) {
    confirmedCalls(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        totalOrderCount
        createdAt
        status
        updatedAmount {
          ...CallOrderValueFragment
        }
        refId
        billDiscountDetail {
          ...BillDisocuntDetailsFragment
        }
        Call {
          id
          Route {
            ...RouteTitleFragment
          }
          User {
            ...UserTitleFragment
            phoneNumber
          }
          callOrderValue {
            ...CallOrderValueFragment
          }
          topUpDiscountDetail {
            ...BillDisocuntDetailsFragment
          }
          callTime {
            callStart
          }
          notes
          totalNetAmount
          callDate
          remarks
          promotionOrderCount
          RetailOutlet {
            townId
            ...OutletCategoryFragment
          }
          Distributor {
            ...DistributorFragment
            servicesUsed {
              logistic {
                status
              }
              delivery {
                status
              }
              billing {
                status
                url
                versionCode
              }
            }
          }
          isRetailerOrder
        }
      }
    }
  }
  ${BILL_DISCOUNT_DETAILS_FRAGMENT}
  ${OUTLET_CATEGORY_FRAGMENT}
  ${ROUTE_TITLE_FRAGMENT}
  ${USER_TITLE_FRAGMENT}
  ${CALL_ORDER_VALUE_FRAGMENT}
  ${DISTRIBUTOR_FRAGMENT}
`;

const GET_INVOICED_LIST = gql`
  query getInvoicedList($offset: Int, $limit: Int, $filter: FilterInput) {
    invoices(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        id
        ...InvoiceFragment
        invoiceDate
      }
      count
    }
  }
  ${INVOICE_FRAGMENT}
`;

const GET_DISPATCHED_LIST = gql`
  query getDispatchedList($offset: Int, $limit: Int, $filter: FilterInput) {
    dispatchedInvoices(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...InvoiceFragment
        invoiceId: id
        id: uniqueKey
        dispatchNumber
        invoiceDate
        orderType
        refId
      }
      count
    }
  }
  ${INVOICE_FRAGMENT}
`;

const GET_DELIVERED_LIST = gql`
  query getDeliveredList($offset: Int, $limit: Int, $filter: FilterInput) {
    deliveredInvoices(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...InvoiceFragment
        id: uniqueKey
        invoiceId: id
        dispatchNumber
        invoiceDate
        orderType
        refId
      }
      count
    }
  }
  ${INVOICE_FRAGMENT}
`;

const GET_STOCK_REPLACED_LIST = gql`
  query getStockReplacedList($offset: Int, $limit: Int, $filter: FilterInput) {
    replacementOrders(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...StockReplacementFragment
      }
      count
    }
  }
  ${STOCK_REPLACEMENT_FRAGMENT}
`;

/** detail list */
const GET_RECEIVED_DETAIL_LIST = gql`
  query getOrders($callId: Int!, $sellerId: Int!) {
    callOrders(callId: $callId, sellerId: $sellerId) {
      ...PromotionOrderFragment
      Lines {
        isBlocked
        inStock
        freeSku
        focusedSku
        stockBalance
        dcStatus
      }
    }
  }
  ${PROMOTION_ORDER_FRAGMENT}
`;

const GET_CONFIRMED_DETAIL_LIST = gql`
  query ($callId: Int!, $refId: Int!, $sellerId: Int!) {
    confirmedOrders(callId: $callId, refId: $refId, sellerId: $sellerId) {
      ...PromotionOrderFragment
      Lines {
        isBlocked
        inStock
        freeSku
        focusedSku
        stockBalance
        dcStatus
      }
    }
  }
  ${PROMOTION_ORDER_FRAGMENT}
`;

const GET_INVOICED_DETAIL_LIST = gql`
  query getInvoicedOrders($input: InvoiceOrderDetailInput!) {
    invoiceDetails(input: $input) {
      Orders {
        ...PromotionOrderFragment
        Lines {
          freeSku
          focusedSku
        }
      }
    }
  }
  ${PROMOTION_ORDER_FRAGMENT}
`;

const GET_REPLACED_DETAIL_LIST = gql`
  query getReplacedOrders($replacementId: Int!) {
    replacementOrderSummary(replacementId: $replacementId) {
      id
      sku: sku_title
      quantity
      images {
        thumbnail
        src: image
        created_at
      }
      replacedSku: replacement_sku_title
      replacedQuantity: replacement_sku_quantity
      reason
    }
  }
`;

const GET_PROMOTION_VALIDATION = gql`
  query getInvoiceValidation($orderIds: [Int]!, $callId: Int!) {
    validateInvoice(orderIds: $orderIds, callId: $callId)
  }
`;

const DISPATCH_INVOICE = graphql(
  gql`
    mutation ($input: DispatchInput!) {
      dispatchInvoice(input: $input) {
        id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      dispatchInvoice: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DISPATCH_REPLACEMENT_ORDERS = graphql(
  gql`
    mutation ($replacementId: Int!) {
      dispatchReplacementOrder(replacementId: $replacementId)
    }
  `,
  {
    props: ({ mutate }) => ({
      dispatchReplacementOrder: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DELIVER_INVOICE = graphql(
  gql`
    mutation ($input: DeliverInput!) {
      deliverInvoice(input: $input) {
        invoiceId: id
        refId
        id: uniqueKey
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      deliverInvoice: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const CONFIRMED_RECEIVED_ORDER = graphql(
  gql`
    mutation ConfirmReceivedOrder($callId: Int!, $distributorId: Int!, $orderIds: [Int!], $status: String) {
      confirmReceivedOrder(callId: $callId, distributorId: $distributorId, orderIds: $orderIds, status: $status) {
        orderIds
        callId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      confirmReceivedOrder: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getTabsTotal: GET_TABS_TOTAL,
  getReceivedList: GET_RECEIVED_LIST,
  getConfirmedList: GET_CONFIRMED_LIST,
  getInvoicedList: GET_INVOICED_LIST,
  getDeliveredList: GET_DELIVERED_LIST,
  getDispatchedList: GET_DISPATCHED_LIST,
  getStockReplacedList: GET_STOCK_REPLACED_LIST,
  getReceivedDetailList: GET_RECEIVED_DETAIL_LIST,
  getInvoicedDetailList: GET_INVOICED_DETAIL_LIST,
  getReplacedDetailList: GET_REPLACED_DETAIL_LIST,
  getConfirmedDetailList: GET_CONFIRMED_DETAIL_LIST,
  getPrimaryStockFromSAP: GET_PRIMARY_STOCK_FROM_SAP,
};

const mutation = {
  confirmReceivedOrder: CONFIRMED_RECEIVED_ORDER,
};

export {
  GET_RECEIVED_LIST,
  GET_RECEIVED_DETAIL_LIST,
  GET_CONFIRMED_LIST,
  ORDER_DETAIL_FRAGMENT,
  CALL_FRAGMENT,
  INVOICE_FRAGMENT,
  DISPATCH_INVOICE,
  DELIVER_INVOICE,
  PROMOTION_ORDER_FRAGMENT,
  GET_PROMOTION_VALIDATION,
  DISPATCH_REPLACEMENT_ORDERS,
  CONFIRMED_RECEIVED_ORDER,
  query,
  mutation,
};
