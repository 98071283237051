import { breadCrumb as breadCrumbConfig } from '../../sbd/config';
import { CATALOG } from '../../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';
import { CATALOG_LEVEL } from '../../../../data/enums/Catalog';

const title = 'Catalog';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Product Catalog',
    path: `/${CATALOG}`,
    active: true,
  },
];

const filter = getFilterConfig([FILTER.BRAND]);

const getFilterListBasedOnCatalogs = (catalogId) => {
  const filterList = [FILTER.STATUS];
  Object.keys(CATALOG_LEVEL).forEach((catalog) => {
    if (CATALOG_LEVEL[catalog] < catalogId) {
      const configuredItem = FILTER[catalog];
      filterList.push(configuredItem);
    }
  });

  return getFilterConfig(filterList);
};

const crudSuccess = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: 'CatalogDetail',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: 'CatalogDetail',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
};

export {
  breadCrumb, crudSuccess, filter as filterConfig, getFilterListBasedOnCatalogs
};
