import PropTypes from 'prop-types';
import { CheckBox } from '../../../../../../v4/components';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { menuAction, tableHeaderWithCheckBox } from '../../../../../../v4/components';
import { PERMISSION_OBJ } from '../../../../../../data/enums/Permission';
import { normalPresentor } from '../../../../../../utils/date';
import dragDots from '../../../../../../assets/images/dragDots.svg';

const labelMappings = {
  // checkbox: 'checkbox',
  dndUi: 'dndUi',
  metrics: 'metrics',
  achievementCriteria: 'achievementCriteria',
  options: 'options',
};

const labelConfig = [
  // {
  //   id: 1,
  //   label: labelMappings.checkbox,
  //   title: '',
  //   show: true,
  // },
  {
    id: 2,
    label: labelMappings.dndUi,
    title: '',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.metrics,
    title: 'Metrics',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.achievementCriteria,
    title: 'Achievement Criteria',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.options,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission, index) => {
  const table = {};
  // table[labelMappings.checkbox] = permission.delete && (
  //   <td onClick={e => e.stopPropagation()} key={`${data.id}- check`}>
  //     <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
  //   </td>
  // );
  table[labelMappings.dndUi] = (
    <td key={`${data.id}-dndUi`} className='drag-box-td'>
      <div className="drag-box">
        <p>
          <span>
            <img src={dragDots} alt="" />
          </span>
          <span className="badge">{index + 1}</span>
        </p>
      </div>
    </td>
  );
  table[labelMappings.metrics] = <td key={`${data.id}-metric`}>{data.metric?.title}</td>;
  table[labelMappings.achievementCriteria] = (
    <td key={`${data.id}-achievementCriteria`}>
      {data.achievementCriteriaValue} {data.achievementCriteriaType?.id === 1 && '%'}
    </td>
  );
  table[labelMappings.options] = (
    <td key={`${data.id}- action`} className="text-right simple-popup-actions" onClick={e => e.stopPropagation()}>
      {permission.delete && menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({
  data,
  checkedList,
  onIconClick,
  secondaryCheckboxHandler,
  permission,
  onViewClick,
  ref,
  draggableProps,
  dragHandleProps,
  style,
  index,
}) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission, index);

  return (
    <tr
      key={data.id}
      onClick={() => onViewClick(data.id)}
      ref={ref}
      {...draggableProps}
      {...dragHandleProps}
      style={style}
    >
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
