import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReportView from './View';
import PageHeader from '../../base/PageHeader';
import { isError, headerLabelConfig, getSortValueAfterValueCheck } from '../../common/HelperFunctions';
import { PanelStyled } from '../../common/configuration';
import * as queryService from '../../base/query.service';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../utils/api';
import { filterConfig } from './config';
import { breadCrumbConfig } from '../config';
import { USER_ROLE } from '../../../data/enums';
import { DSE_DETAIL } from '../../../data/enums/Route';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { clone } from '../../../utils/objectProcessor';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Report extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      queryParameters: {
        sort: queryService.baseQueryParameters.sort,
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        date: { ...queryService.baseQueryParameters.singleDate },
      },
      filterMenu: this.filterConfigMenu || {},
    };

    const { displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getFilterStateFromStorage();
  }
  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.DSE_REPORT,
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters} =this.state;
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.DSE_REPORT);
    if (!localData) {
      return this.loadTableData();
    }
    this.setState(
      {
        queryParameters: {
          ...queryParameters,
          filter: localData.filter,
          searchText: localData.searchText,
        },
        filterMenu:localData.filterMenu,
      },

      () => {
        this.loadTableData();
      },
    );
  };

  handleRowClick = (id, routeId) => {
    const { history } = this.props;
    const { queryParameters } = this.state;
    history.push(`/${DSE_DETAIL}/${id}/route-id/${routeId}`, { date: queryParameters.date });
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getReport } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getReport(
      {
        roleId: USER_ROLE.DSE,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.getUserStateDetailList && response.data.getUserStateDetailList.rows) || [];
          data.total = (response.data.getUserStateDetailList && response.data.getUserStateDetailList.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const { data, queryParameters, display,filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
                create: false,
                download: false,
                filter: true,
                search: true,
                date: false,
                singleDate: true,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleSingleDateRangeChange={this.basePaginationService.handleSingleDateRangeChange}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <ReportView
            data={data}
            pagination={queryParameters.pagination}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
            handleRowClick={this.handleRowClick}
            onHeaderClick={this.basePaginationService.handleTableSorting}
          />
        </div>
      </Fragment>
    );
  }
}

Report.propTypes = propTypes;

Report.defaultProps = defaultProps;

export default withAlert()(Report);
