/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';
import moment from 'moment';
import _ from 'lodash';
import { createScriptLoadMap } from '../../../../configuration/arc/services/googleMapScript';
import averageGeolocation from '../../services/coordAvg';
import PageHeader from '../../../../base/PageHeader';
import { PanelStyled } from '../../../../common/configuration';
import marker from '../../services/marker';
import infoWindow from '../../services/infoWindow';
import ARCFilter from '../../components/ARCFilter';
import { BreadCrumb ,Button} from '../../../../../v4/components';
import { SingleDatePicker } from '../../../../../components';
import { breadCrumb } from '../../config';
import ARCStyled from './ARCmapStyled';
import { getFilterConfig } from '../../../../../utils/filter';
import { FILTER } from '../../../../../data/enums/filter';
import circleRedMarker from '../../assets/initial-marker.svg';
import invoiceMarker from '../../assets/invoice-marker.svg';
import unSuccessfulMarker from '../../assets/unSuccessfulMarker.svg';
import purpleMarker from '../../assets/previously-assigned-marker.svg';
import goldenMarker from '../../assets/current-assigned-marker.svg';
import { invoice, callTypes } from '../../enums/invoice';
import gridStatic from '../../enums/grid';
import CustomSelect from '../../../../../components/CustomSelect';
import { initializeGridLines, showHide } from '../../services/GridLines';
import InitialInfoWindow from '../../components/InitialInfoWindow';
import { USER_ROLE } from '../../../../../data/enums';
import { OFFSET } from '../../../../../data/enums/GeneralConstants';
import ARCLoading from '../../components/ARCLoading';
import withAlert from '../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import { USER_ROLE_TITLE } from '../../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../../common/HelperFunctions';

const propTypes = {
  displayAlert: PropTypes.func.isRequired,
  getUserList: PropTypes.func.isRequired,
  setUserClusters: PropTypes.func.isRequired,
  getUserClusters: PropTypes.func.isRequired,
  getInvoiceOutlets: PropTypes.func.isRequired,
  updateUserClusters: PropTypes.func.isRequired,
};

const redCircleMarker = new google.maps.MarkerImage(circleRedMarker, null, null, null, new google.maps.Size(27, 27));

const contactInfoDetails = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
  {
    label: 'All',
    value: null,
  },
];

class ARCmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: {},
      outlets: [],
      outletsMarkers: [],
      typeMarkers: [],
      callTypeDay: [],
      grid: [],
      mapcenter: {},
      outletFilter: [],
      fetchOutletsLoading: false,
      infoWindowInst: {},
      dseList: [],
      selectedDse: [],
      date: new Date(),
      userClusters: [],
      userClustersMarkers: [],
      contactInfo: '',
      callType: '',
      // shos.filter(d => d.value === callTypewHideGridLines: true
    };
    this.filterConfig = getFilterConfig([FILTER.TOWN, FILTER.ROUTE, FILTER.CHANNEL, FILTER.CATEGORY, FILTER.DSE]);
    this.filterRef = React.createRef();
  }

  componentDidMount() {
    createScriptLoadMap().then(res => {
      this.setState({ map: res });
      this.fetchDseList();
    });
  }

  fetchDseList = () => {
    // load all the DSE List.
    const { getUserList } = this.props;

    getUserList(
      {
        offset: OFFSET,
        filter: {
          filters: [
            {
              column: 'role_id',
              value: [USER_ROLE.DSE.toString(), USER_ROLE.TAS.toString()],
            },
          ],
        },
      },
      {
        handleSuccess: response => {
          const dseArr = response.data.users.rows || [];
          this.setState({ dseList: dseArr });
        },
      },
    );
  };

  toggleGridLines = () => {
    const { showHideGridLines } = this.state;
    this.setState({ showHideGridLines: !showHideGridLines }, () => {
      showHide(showHideGridLines);
    });
  };

  setUserClusters = outletMarker => {
    const { setUserClusters } = this.props;
    const { selectedDse, date, userClusters, userClustersMarkers } = this.state;

    const { id, distributorId, routeId } = outletMarker;
    const assignedOutletsIds = userClustersMarkers.map(cluster => cluster.id);
    const unassign = assignedOutletsIds.includes(outletMarker.id);
    const assignedOutletDetails = {
      date,
      routeId,
      unassign,
      distributorId,
      outletId: id,
      userId: selectedDse.id,
    };
    setUserClusters(
      { input: assignedOutletDetails },
      {
        handleSuccess: () => {
          if (unassign) {
            const assignedMarkers = userClustersMarkers.filter(clusterMarker => clusterMarker.id !== id);
            this.setState({ userClustersMarkers: assignedMarkers }, () => {
              outletMarker.setIcon(redCircleMarker);
            });
            this.setState(prevState => ({
              userClustersMarkers: assignedMarkers,
            }));
          } else {
            this.setState(
              prevState => ({
                userClustersMarkers: [...prevState.userClustersMarkers, outletMarker],
              }),
              () => {
                outletMarker.setIcon(this.setMarkerImage(goldenMarker));
              },
            );
          }
        },
        handleError: () => {
          alert('Something went wrong.');
        },
      },
    );
  };

  handleAssignOutletsToDse = marker => {
    const { displayAlert } = this.props;
    const { selectedDse, infoWindowInst } = this.state;
    if (!_.isEmpty(selectedDse)) {
      this.setUserClusters(marker);
      infoWindowInst.close();
    } else if (_.isEmpty(selectedDse)) {
      displayAlert(ALERT_TYPE.INFO, `No ${headerLabelConfig(USER_ROLE_TITLE.DSE)} Selected`);
    }
  };

  updateUserClusters = outletMarker => {
    const { updateUserClusters, displayAlert } = this.props;
    const { selectedDse } = this.state;
    const assignedOutletUpdated = {
      userId: selectedDse.id,
      outletId: outletMarker.id,
    };
    updateUserClusters(
      {
        input: assignedOutletUpdated,
        id: outletMarker.clusterId,
      },
      {
        handleSuccess: () => {
          this.setState(
            prevState => ({
              userClustersMarkers: [...prevState.userClustersMarkers, outletMarker],
            }),
            () => {
              outletMarker.setIcon(this.setMarkerImage(goldenMarker));
            },
          );
        },
        handleError: () => {
          displayAlert(ALERT_TYPE.DANGER, 'Something went wrong.');
        },
      },
    );
  };

  fetchOutlets = () => {
    const { outletFilter, contactInfo } = this.state;
    const { getInvoiceOutlets, displayAlert } = this.props;
    if (outletFilter.length) {
      this.setState({ fetchOutletsLoading: true });
      const filters = contactInfo.value
        ? [...outletFilter, { column: 'contact_available', value: [contactInfo.value] }]
        : outletFilter;
      getInvoiceOutlets(
        {
          filter: {
            filters,
          },
        },
        {
          handleSuccess: response => {
            const { errors } = response;
            const { count, rows } = response.data.retailOutletDetails;
            const outlets = rows.filter(d => d.routeId !== null);
            if (errors) {
              alert(`${errors[0].message}`);
              this.setState({ fetchOutletsLoading: false });
            } else if (count === 0) {
              alert('No outlets for this filter!');
              this.setState({ fetchOutletsLoading: false });
            } else {
              const center = averageGeolocation(outlets);
              this.setState(
                {
                  mapcenter: center,
                  outlets,
                  fetchOutletsLoading: false,
                },
                () => {
                  const { outletsMarkers, mapcenter } = this.state;
                  if (outletsMarkers.length) {
                    outletsMarkers.forEach(marker => {
                      marker.setMap(null);
                    });
                  }
                  window.globalMap.setCenter(mapcenter);
                  const initialMarkers = outlets.map(outlet => {
                    const {
                      id,
                      title,
                      geoLocation,
                      lastTransactionDate,
                      categoryTitle,
                      userName,
                      distributorTitle,
                      routeTitle,
                      contactInfo,
                      lastCallDate,
                      callType,
                      unsuccessfullReason,
                      distributorId,
                      routeId,
                    } = outlet;
                    outlet.lng = parseFloat(geoLocation.longitude);
                    outlet.lat = parseFloat(geoLocation.latitude);
                    const initialMarker = marker.generateMarker(
                      outlet,
                      id,
                      title,
                      redCircleMarker,
                      null,
                      null,
                      title,
                      lastTransactionDate,
                      lastCallDate,
                      callType,
                      unsuccessfullReason,
                      distributorId,
                      routeId,
                    );

                    const infoWin = infoWindow.generateInfoWindow();

                    initialMarker.addListener('click', () => {
                      const { infoWindowInst, userClustersMarkers } = this.state;
                      if (!_.isEmpty(infoWindowInst)) {
                        infoWindowInst.close();
                      }
                      infoWin.addListener('domready', () => {
                        render(
                          <InitialInfoWindow
                            name={title}
                            id={id}
                            category={categoryTitle}
                            dse={userName}
                            subD={distributorTitle}
                            route={routeTitle}
                            contact={contactInfo[0].phoneNumber}
                            assignedTo={null}
                            lastCallDate={lastCallDate}
                            callType={callType}
                            reason={unsuccessfullReason}
                            userClustersMarkers={userClustersMarkers}
                            clickHandler={() => this.handleAssignOutletsToDse(initialMarker, outlet)}
                          />,
                          document.getElementById('infoWindow'),
                        );
                      });
                      infoWin.open(window.globalMap, initialMarker);
                      this.setState({ infoWindowInst: infoWin });
                    });
                    return initialMarker;
                  });
                  this.setState({ outletsMarkers: initialMarkers }, () => {
                    // hot fix
                    const { callTypeDay } = this.state;
                    if (callTypeDay.value) {
                      this.onDayChange(callTypeDay);
                    }
                    this.fetchOutletCluster();
                  });
                },
              );
            }
          },
          handleError: () => {
            this.setState({ fetchOutletsLoading: false });
            alert('Something went wrong.');
          },
        },
      );
    } else {
      displayAlert(ALERT_TYPE.INFO, 'Too many outlets! Please use filter!');
    }
  };

  handleFilterChange = filterData => {
    const outletFilter = Object.values(filterData)
      .filter(data => data.selectedIdList.length > 0)
      .map(newData => ({
        column: newData.value,
        value: newData.selectedIdList.map(String),
      }));
    this.setState({
      outletFilter,
    });
  };

  onApplyFilterClick = () => {
    this.fetchOutlets();
    const filterCard = document.getElementsByClassName('filter-dropdown-card');
    filterCard[0].style.display = 'none';
  };

  hideAllInitialMarkers = () => {
    const { outletsMarkers } = this.state;
    if (outletsMarkers.length) {
      outletsMarkers.forEach(marker => {
        marker.setVisible(!marker.getVisible());
      });
    }
  };

  handleReset = () => {
    this.setState({
      map: {},
      selectedPlaces: [],
      outlets: [],
      mapcenter: {},
      outletsMarkers: [],
      outletFilter: [],
      fetchOutletsLoading: false,
      callTypeDay: [],
      grid: [],
      selectedDse: [],
      date: new Date(),
      userClusters: [],
      userClustersMarkers: [],
      contactInfo: '',
      callType: '',
    });

    this.state.outletsMarkers.forEach(outlet => {
      // outlet.setVisible(true)
      // outlet.set('icon', circleRedMarker)
      outlet.setMap(null);
    });
    window.globalMap.setCenter({ lat: 27.6894, lng: 85.3227 });
    window.globalMap.setZoom(12);
    this.filterRef.current.handleFilterResetClick();
    // showHide('reset')
  };

  setMarkerImage = marker => {
    const scaledMarker = new google.maps.MarkerImage(marker, null, null, null, new google.maps.Size(50, 50));
    return scaledMarker;
  };

  onCallTypeChange = type => {
    const { value } = type;
    const { callTypeDay } = this.state;
    this.setState(
      {
        callType: value,
      },
      () => {
        if (callTypeDay.value > 0) {
          this.onDayChange(callTypeDay);
        }
      },
    );
  };

  onDayChange = day => {
    const { callType } = this.state;
    const typeMarker = callType === 'invoiced' ? invoiceMarker : unSuccessfulMarker;

    const { typeMarkers, outletsMarkers } = this.state;
    if (typeMarkers.length) {
      outletsMarkers.forEach(marker => {
        marker.setIcon(redCircleMarker);
      });
    }

    this.setState({ callTypeDay: day }, () => {
      const dayNumber = day.value;
      const currentDate = moment().format('YYYY-MM-DD');
      const selectedDaysAgo = moment().subtract(dayNumber, 'd').format('YYYY-MM-DD');

      const typeMarkers = outletsMarkers.filter(marker => {
        const invoiceDate = moment(
          callType === 'invoiced' ? marker.lastTransactionDate : marker.lastCallDate,
          'YYYY-MM-DD',
        ).format('YYYY-MM-DD');
        const checkBetweenDates = moment(invoiceDate).isBetween(selectedDaysAgo, currentDate);
        if (callType === 'unsuccessful') {
          return (
            marker.callType === callType.toUpperCase() &&
            (marker.reason === 'Authorized Person NA' ||
              marker.reason === 'Outlet Closed' ||
              marker.reason === 'outlet close') &&
            checkBetweenDates
          );
        }
        return checkBetweenDates;
      });

      if (typeMarkers.length) {
        this.setState({ typeMarkers }, () => {
          const invoiceMarkerIcon = this.setMarkerImage(typeMarker);
          typeMarkers.forEach(marker => {
            marker.setIcon(invoiceMarkerIcon);
            marker[callType] = true;
          });
        });
      }
    });
  };

  onGridValueChange = size => {
    this.setState({ grid: size }, () => {
      initializeGridLines(window.globalMap, size.value);
    });
  };

  onDseValueChange = dse => {
    this.setState({ selectedDse: dse }, () => {
      this.fetchOutletCluster();
    });
  };

  onContactInfoChange = event => {
    const { value } = event.target;
    const { outletFilter } = this.state;
    this.setState(
      {
        contactInfo: {
          label: value,
          value: contactInfoDetails.find(d => d.label === value).value,
        },
      },
      () => {
        if (outletFilter.length) {
          this.fetchOutlets();
        }
      },
    );
  };

  fetchOutletCluster = () => {
    const { getUserClusters, displayAlert } = this.props;
    const { selectedDse, date, userClustersMarkers, callType } = this.state;
    if (userClustersMarkers.length) {
      userClustersMarkers.forEach(marker => {
        marker.invoiced || marker.unsuccessful
          ? marker.setIcon(this.setMarkerImage(callType === 'invoiced' ? invoiceMarker : unSuccessfulMarker))
          : marker.setIcon(redCircleMarker);
      });
      this.setState({ userClustersMarkers: [] });
    }

    const userId = `${selectedDse.id}`;
    const userFilter =
      userId !== 'undefined'
        ? {
            filters: [
              {
                column: 'user_id',
                value: [userId],
              },
            ],
          }
        : {};
    getUserClusters(
      {
        offset: OFFSET,
        filter: {
          dateRange: {
            start: date,
            end: date,
            filterBy: 'date',
          },
          ...userFilter,
        },
      },
      {
        handleSuccess: response => {
          if (response.data.userClusters.rows.length) {
            this.setState({ userClusters: response.data.userClusters.rows }, () => {
              this.changeUserClustersMarkers();
            });
          } else {
            displayAlert(
              ALERT_TYPE.INFO,
              `'No previously assigned outlets for this ${
                headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'
              } at that date`,
            );
          }
        },
      },
    );
  };

  changeUserClustersMarkers = () => {
    const { outletsMarkers, userClusters } = this.state;
    const dseAssignedOutletsMarkers = [];
    const dseAssignedMarkerIcon = this.setMarkerImage(purpleMarker);
    userClusters.forEach(clusterMarker => {
      const assignedOutletMarker = outletsMarkers.find(marker => marker.id === clusterMarker.RetailOutlet.id);
      if (!_.isEmpty(assignedOutletMarker)) {
        assignedOutletMarker.clusterId = clusterMarker.id;
        dseAssignedOutletsMarkers.push(assignedOutletMarker);
      }
    });
    this.setState({ userClustersMarkers: dseAssignedOutletsMarkers }, () => {
      if (dseAssignedOutletsMarkers.length) {
        dseAssignedOutletsMarkers.forEach(marker => {
          marker.setIcon(dseAssignedMarkerIcon);
        });
      }
    });
  };

  onDateRangeChange = (name, date) => {
    this.setState({ date }, () => {
      this.fetchOutletCluster();
    });
  };

  render() {
    const { callTypeDay, grid, selectedDse, dseList, date, contactInfo, callType, fetchOutletsLoading } = this.state;

    return (
      <ARCStyled>
        <div className="section-panel">
          <div className="section-header">
            <PanelStyled>
              <BreadCrumb list={breadCrumb} />
              <div className="arc-title-btn">
                <PageHeader
                  config={{
                    title: 'Cluster Target Tool',
                  }}
                />
                <Button secondary iconBtnSmall title="Reset" onClick={() => this.handleReset()} />
              </div>
            </PanelStyled>
          </div>

          <div className="section-content">
            <div className="flex-container">
              <div className="mapParam">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="parameters">
                      <h4>Filter</h4>
                      <div className="left-form-grp">
                        <div className="card-in">
                          <CustomSelect
                            options={callTypes}
                            value={callTypes.filter(d => d.value === callType)}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ value }) => value}
                            onChange={value => {
                              this.onCallTypeChange(value);
                            }}
                            placeholder="Select Call Type"
                            isSearchable={false}
                            labelContent="Calls"
                          />
                          {callType && (
                            <CustomSelect
                              options={invoice}
                              value={callTypeDay}
                              getOptionLabel={({ label }) => label}
                              getOptionValue={({ value }) => value}
                              onChange={value => {
                                this.onDayChange(value);
                              }}
                              placeholder="Select Days"
                              isSearchable={false}
                              labelContent={`Last ${callTypes.find(d => d.value === callType).label}`}
                            />
                          )}
                          <div className="form-radio">
                            <label>Contact Number</label>
                            <div className="form-radio-list">
                              <label className="radio-list">
                                <input
                                  type="radio"
                                  name="contact_info"
                                  value="Yes"
                                  onChange={e => this.onContactInfoChange(e)}
                                  checked={contactInfo.label === 'Yes'}
                                />
                                <span className="text">Yes</span>
                              </label>
                              <label className="radio-list">
                                <input
                                  type="radio"
                                  name="contact_info"
                                  value="No"
                                  onChange={e => this.onContactInfoChange(e)}
                                  checked={contactInfo.label === 'No'}
                                />
                                <span className="text">No</span>
                              </label>
                              <label className="radio-list">
                                <input
                                  type="radio"
                                  name="contact_info"
                                  value="All"
                                  onChange={e => this.onContactInfoChange(e)}
                                  checked={contactInfo.label === 'All'}
                                />
                                <span className="text">All</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="card-in">
                          <CustomSelect
                            options={gridStatic}
                            value={grid}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ value }) => value}
                            onChange={value => this.onGridValueChange(value)}
                            placeholder="Select Grid Size"
                            isSearchable={false}
                            labelContent="Grid Size(in metres)"
                          />
                        </div>
                        <div className="card-in">
                          <CustomSelect
                            options={dseList}
                            value={selectedDse}
                            getOptionLabel={({ fullName }) => fullName}
                            getOptionValue={({ id }) => id}
                            onChange={value => {
                              this.onDseValueChange(value);
                            }}
                            placeholder={`Select ${headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'}`}
                            isSearchable
                            labelContent={headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'}
                          />
                          <div className="arc-date">
                            <label>Schedule Date</label>
                            <SingleDatePicker
                              date={date}
                              name="start"
                              onChange={(name, date) => {
                                this.onDateRangeChange(name, date);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="map-wrapper-inner relative">
                      <div className="absolute" style={{ top: '70px', backgroundColor: 'white' }}>
                        <ARCFilter
                          ref={this.filterRef}
                          menuList={this.filterConfig.menu}
                          onApplyFilterClick={this.onApplyFilterClick}
                          onFilterChange={e => this.handleFilterChange(e)}
                        />
                      </div>
                      {fetchOutletsLoading && <ARCLoading title="Fetching Outlets..." />}
                      {/* <div className="absolute-eyes"
                                        style={{ backgroundColor: 'white', height: '24px', width: '19px', cursor: 'pointer' }}
                                        onClick={() => this.toggleGridLines()}
                                    >
                                        <Icon
                                            iconName="hidden"
                                        />
                                    </div> */}
                      <div id="map" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ARCStyled>
    );
  }
}

ARCmap.propTypes = propTypes;

export default withAlert()(ARCmap);
