import React, { useCallback, useState } from 'react';
import { Calendar } from 'react-date-range';
import { CustomSelect, Input } from '../../../../../components';
import { periodType } from '../../../../../data/enums/Notification';
import { CALL_PLAN } from '../../../../../data/enums/Route';
import { formatter, getCurrentDay, normalPresentor, presentor, setDateTime } from '../../../../../utils/date';
import history from '../../../../../utils/history';
import { Button, Menu } from '../../../../../v4/components';
import DatePickerStyled from '../../../../../v4/components/DatePicker/DatePickerStyled';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { DialogFormWrapper } from '../../../../common';
import { isError } from '../../../../common/HelperFunctions';
import backButton from '../../../../configuration/arc/assets/back-button.svg';
import CallAnalysis from '../CallAnalysis';
import { formConfig, OPERATION } from '../config';
import { BillingDeliveryPanelContainer } from './billingDelivery.styled';

const Summary = React.memo(function SummarySubComp({
  notes,
  editNotes,
  date,
  handleDateChange,
  callPlanTime,
  handleCallTimeChange,
  handleAmPmChange,
}) {
  const dateHeader = (
    <div className="date-input">
      <div className="moment">
        {date ? presentor(date) : 'Select Delivery Date'}
        <span className="ic-date-icon" />
      </div>
    </div>
  );

  return (
    <>
      <div className="billing-delivery-form container-fluid">
        <div className="billing-notes">
          <label>Billing Notes</label>
          <textarea rows={15} value={notes} onChange={editNotes} />
        </div>
        <div className="delivery-date">
          <label>Preferred Delivery Date</label>
          <DatePickerStyled className="date-picker">
            <div className="datepicker-wrapper date-picker-single">
              <Menu header={dateHeader} hideOnClick>
                <div className="datepicker-block">
                  <Calendar
                    date={date ? formatter(date).toDate() : formatter(getCurrentDay()).toDate()}
                    maxDate={formatter('2050-01-01').toDate()}
                    minDate={formatter(getCurrentDay()).toDate()}
                    onChange={selectedDate => handleDateChange(selectedDate)}
                  />{' '}
                </div>
              </Menu>
            </div>
          </DatePickerStyled>
        </div>
        <div className="delivery-time">
          <label>Preferred Delivery Time</label>
          <div className="time-selector row">
            <div className="time col-xs-12 col-md-4">
              <Input
                value={callPlanTime.hours}
                placeholder="HH"
                type="number"
                onChange={event => handleCallTimeChange(event.target.value, 'hours')}
                argument={{
                  max: 12,
                }}
              />
              <label>HH</label>
            </div>
            <div className="time col-xs-12 col-md-4">
              <Input
                value={callPlanTime.minutes}
                type="number"
                placeholder="MM"
                onChange={event => handleCallTimeChange(event.target.value, 'minutes')}
                allowMultiZeroBeforeNumber
                argument={{
                  max: 60,
                }}
              />
              <label>MM</label>
            </div>
            <div className="time col-xs-12 col-md-4">
              <CustomSelect
                name="period"
                className="custom-select"
                placeholder="AM/PM"
                options={periodType}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                value={periodType.filter(({ title }) => title === callPlanTime.AM_PM)}
                onChange={event => handleAmPmChange(event.value)}
              />
              <label>AM/PM</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

const BillingDeliveryPanel = ({
  showSummaryOverlay,
  closeOverlay,
  timer,
  callSummary,
  summary,
  stopTimer,
  orderSummary,
  callPlanDetails,
  ...props
}) => {
  const [callPlanTime, setCallPlanTime] = useState({
    hours: '',
    minutes: '',
    AM_PM: '',
  });
  const [deliveryTo, setDeliveryTo] = useState('');
  const [date, setDate] = useState('');
  const [billingNotes, setBillingNotes] = useState('');

  const brands = [];
  const brandCount = orderSummary.reduce((_acc, curr) => {
    curr.skus
      ?.filter(item => item?.quantity > 0)
      .map(sku => {
        if (!brands.includes(sku.brand.id)) {
          brands.push(sku.brand.id);
        }
      });

    return brands.length;
  }, 0);

  const handleCallTimeChange = useCallback(
    (value, label) => {
      if (label === 'hours') {
        callPlanTime.hours = value < 10 && value >= 1 ? `0${value}` : value > 12 ? 12 : value;
      }

      if (label === 'minutes') {
        callPlanTime.minutes = value < 10 && value >= 1 ? `0${value}` : value > 59 ? 0 : value;
      }

      setCallPlanTime({ ...callPlanTime });
    },
    [callPlanTime.hours, callPlanTime.minutes],
  );

  const handleAmPmChange = useCallback(
    value => {
      setCallPlanTime({ ...callPlanTime, AM_PM: value });
    },
    [callPlanTime.AM_PM],
  );

  const handleDateChange = useCallback(
    date => {
      setDate(normalPresentor(date));
    },
    [date],
  );

  const editNotes = useCallback(
    event => {
      setBillingNotes(event.target.value);
    },
    [billingNotes],
  );

  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });

  const handleOrderComplete = (type, element = {}) => {
    stopTimer();
    let hours = 0;
    if (callPlanTime.hours && callPlanTime.minutes && callPlanTime.AM_PM) {
      if (callPlanTime.AM_PM === 'PM') {
        hours = callPlanTime.hours > 12 ? callPlanTime.hours : 12 + Number(callPlanTime.hours);
      } else {
        hours = callPlanTime.hours > 12 ? Number(callPlanTime.hours) - 12 : callPlanTime.hours;
      }
    }

    if (date) {
      setDeliveryTo(setDateTime(new Date(date), { hours, minutes: callPlanTime.minutes || 0 }).format());
    }

    setDialog({
      type: OPERATION.CALL_ANALYSIS,
      element: element,
    });
  };

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
  };

  const handleSubmit = () => {
    const { saveTasSuccessfulCalls, displayAlert, noOrder, callNotes } = props;
    resetDialog();
    closeOverlay();

    saveTasSuccessfulCalls(
      {
        input: {
          retailOutletId: callPlanDetails.retailOutletId,
          assignedUserId: callPlanDetails.assignedUserId,
          tas_user_call_plan_id: callPlanDetails.tas_user_call_plan_id,
          tasReminder: '',
          callTime: {
            callStart: callSummary.start,
            callEnd: callSummary.end,
          },
          notes: callNotes,
          billing_notes: billingNotes,
          remarks: '',
          type: 'SUCCESSFUL',
          orderCount: {
            lineCount: summary.totalLines || 0,
            brandCount: brandCount,
          },
          callOrderValue: summary.callOrderValue,
          orders: summary.orders
            .filter(order => !noOrder.some(no => no.sku_id === order.skuId))
            .map(order => {
              const { netSaving, ...amountDetails } = order.amountDetails;
              return {
                ...order,
                promotions: order.promotions.map(({ promotionScope, ...p }) => p),
                amountDetails,
              };
            }),
          noOrder: noOrder.map(item => ({
            sku_id: item?.sku_id,
            rlp: item?.rlp,
            reason: item?.reasonsList,
            brand_id: item?.brandId,
          })),
          deliveryFrom: new Date(),
          deliveryTo: deliveryTo,
          tasStatus: true,
        },
      },
      {
        handleSuccess: response => {
          if (response.data) {
            displayAlert(ALERT_TYPE.SUCCESS, 'Order Completed!');
            setTimeout(() => {
              history.push({
                pathname: `/${CALL_PLAN}`,
                state: { plannedTab: 2 },
              });
            }, 1200);
          }
          if (isError(response)) {
            return displayAlert(ALERT_TYPE.DANGER, response?.errors?.[0]);
          }
        },
        handleError: err => displayAlert(ALERT_TYPE.DANGER, err),
      },
    );
  };

  return (
    <BillingDeliveryPanelContainer>
      {dialog.type ? (
        <div className="dialog-wrap">
          <DialogFormWrapper
            formConfig={formConfig[dialog.type]}
            dialogElement={dialog.element}
            onDialogSubmit={handleSubmit}
            onDialogCancel={resetDialog}
            type={dialog.type}
            withOutPadding
            renderDialog={() => <CallAnalysis orderSummary={orderSummary} summary={summary} />}
          />
        </div>
      ) : null}
      {showSummaryOverlay ? <div className="overlay" /> : null}
      <div className={`drawer-container${showSummaryOverlay ? ' active' : ''}`}>
        <div className="billing-delivery-title container-fluid">
          <img src={backButton} alt="back-btn" onClick={closeOverlay} />
          <h3>Billing & Delivery</h3>
          <div className="time-counter">{timer}</div>
          <Button iconName="cross" onClick={closeOverlay} />
        </div>
        {showSummaryOverlay ? (
          <Summary
            notes={billingNotes}
            editNotes={editNotes}
            date={date}
            handleDateChange={handleDateChange}
            callPlanTime={callPlanTime}
            handleCallTimeChange={handleCallTimeChange}
            handleAmPmChange={handleAmPmChange}
          />
        ) : null}
        <div className="order-summary-action-btns">
          {showSummaryOverlay ? (
            <>
              <Button secondary title="Back" onClick={closeOverlay} />
              <Button primary title="Complete Call" onClick={handleOrderComplete} />
            </>
          ) : null}
        </div>
      </div>
    </BillingDeliveryPanelContainer>
  );
};

BillingDeliveryPanel.defalutProps = {
  orderSummary: [],
  stopTimer: () => {},
};

export default BillingDeliveryPanel;
