import { refGenerator } from '../../../utils';
import { EVENT_OPERATION } from '../../../v4/constants/EventOperation';
import { FORM_CONFIG } from '../../../v4/constants/formConfig';
import { inventory } from '../../../data/enums/Route';
import { FILTER } from '../../../data/enums/filter';
import { getFilterMenu } from '../../../utils/filter';

const breadCrumb = [inventory];

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create',
    [FORM_CONFIG.REFS_OBJ]: refGenerator([]),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
};

const getFilterConfig = (filterList) => {
  const labelList = filterList.map((element) => element.LABEL);

  const menu = getFilterMenu(labelList);

  const instance = {};

  filterList.forEach((element) => (instance[element.VALUE] = {}));

  return {
    menu,
    instance,
  };
};


const filterConfig = getFilterConfig([FILTER.SUB_D]);

export { breadCrumb as breadCrumbConfig, formConfig, filterConfig };
