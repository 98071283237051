import { refGenerator } from '../../../../utils';
import { CHANNEL } from '../../../../data/enums/Route';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { breadCrumbConfig, channelTypeList } from '../config';
import { channelCategory } from '../../../common/DomainConfig';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Channel',
    path: `/${CHANNEL}`,
    active: true,
  },
];

const getCategoriesObj = (element) => ({
  id: element.id || 0,
  title: element.title || '',
  status: element.status || element.active || true,
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['title']),
  displayError: false,
});

const formConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    id: element.id || 0,
    title: element.title || '',
    status: element.status || false,
    type: element.type || channelTypeList[0].label || '',
    categories:
      element.categories && element.categories.length > 0
        ? element.categories.map((category) => getCategoriesObj(category))
        : [],
  }),
  [FORM_CONFIG.TITLE]: 'Channel',
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['title', 'type']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const payloadFormattedCategories = (category) => {
  const { refsObj, displayError, ...modifiedCategories } = category;
  return modifiedCategories;
};

const getRefinedCategories = (data, updatedCategoryIds) =>
  data.categories.reduce((acc, item) => {
    if (updatedCategoryIds.includes(item.id) || item.id === 0) {
      acc.push(payloadFormattedCategories(item));
    }
    return acc;
  }, []);

const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: ({ data }) => {
      const input = {
        type: data.type,
        title: data.title,
        active: data.status,
        categories: data.categories.map((category) => ({ title: category.title })) || [],
      };
      return { input };
    },
    responseName: 'createChannel',
    message: `${channelCategory.title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: ({ data, updatedCategoryIds }) => {
      const input = {
        id: data.id,
        type: data.type,
        title: data.title,
        active: data.status,
        categories: getRefinedCategories(data, updatedCategoryIds) || [],
      };

      return { input };
    },
    responseName: 'updateChannel',
    message: `${channelCategory.title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};

export {
  formConfig, getCategoriesObj, breadCrumb as breadCrumbConfig, crudSuccess as crudRequestConfig
};
