import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { CHANGE_PAYMENT_STATUS } from '../BulkSelect/API';
import { GET_OUTSTANDING_VALUE } from '../../sales/orderProcessing/received/salesInvoice/API';

const LEDGER_FRAGMENT = gql`
  fragment LedgerFragment on Customer {
    customerId
    title
    outstandingAmount(distributorId: $distributorId, retailOutletId: $retailOutletId)
    billingLedgerId
  }
`;

const GET_LEDGERS = gql`
  query getLedgers($offset: Int, $limit: Int, $filter: FilterInput, $retailOutletId: Int, $distributorId: Int) {
    customers(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        ...LedgerFragment
      }
      count
    }
  }
  ${LEDGER_FRAGMENT}
`;

const SPLIT_PAYMENT = graphql(
  gql`
    mutation ($id: Int!, $input: [PaymentSplitInput]!) {
      splitPayment(id: $id, input: $input) {
        id
        outletId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      splitPayment: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_PAYMENT = graphql(
  gql`
    mutation ($paymentId: Int!, $amount: Float, $remarks: String, $date: String) {
      updatePayment(paymentId: $paymentId, amount: $amount, remarks: $remarks, date: $date) {
        id
        amount
        remarks
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updatePayment: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const GET_BILLING_LEDGERS = gql`
  query BillingLedgers($distributorId: Int!, $filter: FilterInput) {
    billingLedgers(distributorId: $distributorId, filter: $filter) {
      rows {
        id
        status
        title
        typeId
        type
        creditLimit
        creditDay
        panNo
        address
        city
        state
        country
        phoneNumber
        createdBy
        openingBalance
        openingBalanceType
        customerId
        distributorId
        externalCode
        cashBankStatus
      }
      count
    }
  }
`;

const query = {
  getLedgers: GET_LEDGERS,
  getBillingLedgers: GET_BILLING_LEDGERS,
  getOutstandingValue: GET_OUTSTANDING_VALUE,
};

const mutation = {
  splitPayment: SPLIT_PAYMENT,
  changePaymentStatus: CHANGE_PAYMENT_STATUS,
  updatePayment: UPDATE_PAYMENT,
};

export { query, GET_LEDGERS, mutation, SPLIT_PAYMENT, UPDATE_PAYMENT };
