import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import TerritoryView from './View';
import TerritoryForm from './Form';
import { DialogWrapper } from '../../common';
import PageHeader from '../../base/PageHeader';
import { territory } from '../../common/DomainConfig';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { refGenerator } from '../../../utils/refGenerator';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { insertUserLabel } from '../../../data/services/userLabel';
import { getPermissionForTerritory } from '../../base/permission';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { crudSuccess as crudRequestConfig, breadCrumbConfig, formConfig, filterConfig } from './config';
import { isError } from '../../common/HelperFunctions';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { clone } from '../../../utils/objectProcessor';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  createTerritory: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  updateTerritory: PropTypes.func.isRequired,
  getTerritoryList: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Territory extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
      filterMenu: this.filterConfigMenu || {},
    };

    this.permission = getPermissionForTerritory();
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.displayAlert,
    );

    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createTerritory,
      [EVENT_OPERATION.UPDATE]: props.updateTerritory,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { displayAlert } = this.props;
        this.setState(data);
        displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      },
      this.getData,
      ['title'],
    );
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.formReference = refGenerator(formConfig.validationField);
  }
  componentDidMount() {
    insertUserLabel();
    this.getFilterStateFromStorage();
  }
  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.TERRITORY
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.TERRITORY);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getTerritoryList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getTerritoryList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.territories && response.data.territories.rows) || [];
          data.total = (response.data.territories && response.data.territories.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleBulkDelete = (type, response) => this.onCRUDSuccess(type)(response);

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  render() {
    const { data, queryParameters, display,filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div>
          <DialogWrapper
            onDialogSubmit={this.onFormSubmit}
            formConfig={formConfig}
            refsObj={this.formReference}
            renderDialog={({ onDialogSubmit, handleDialogInputChange, dialogData, enableErrorDisplay }) => (
              <Fragment>
                <TerritoryForm
                  show
                  data={dialogData}
                  onFormSubmit={onDialogSubmit}
                  loading={serverResponseWaiting}
                  enableErrorDisplay={enableErrorDisplay}
                  handleInputChange={handleDialogInputChange}
                  refsObj={this.formReference}
                />
              </Fragment>
            )}
            render={({ onDialogItemClick }) => (
              <Fragment>
                <div className="section-header">
                  <PanelStyled>
                    <PageHeader
                      display={display}
                      breadCrumb={breadCrumbConfig}
                      config={{
                        title: territory.title,
                        create: this.permission.create,
                        download: false,
                        filter: true,
                        search: true,
                      }}
                      filter={{
                        date: queryParameters.date,
                        menuList:filterMenu,
                        onFilterChange: this.basePaginationService.handleFilterChange,
                      }}
                      queryParameters={queryParameters}
                      controlDisplay={this.controlDisplay}
                      handleCreateClick={onDialogItemClick}
                      resetFilter={this.basePaginationService.resetFilter}
                      clearSearchText={this.basePaginationService.clearSearchText}
                      handleSearchChange={this.basePaginationService.handleSearchInputChange}
                      handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                      handleDownloadClick={this.basePaginationService.handleDownloadClick}
                    />
                  </PanelStyled>
                </div>
                <div className="section-content table-present">
                  <TerritoryView
                    data={data}
                    pagination={queryParameters.pagination}
                    onIconClick={onDialogItemClick}
                    loading={serverResponseWaiting}
                    onPageChange={this.basePaginationService.onPageSelect}
                    onBulkDelete={this.handleBulkDelete}
                    permission={this.permission}
                  />
                </div>
              </Fragment>
            )}
          />
        </div>
      </Fragment>
    );
  }
}

Territory.propTypes = propTypes;

Territory.defaultProps = defaultProps;

export default withAlert()(Territory);
