import { TableBody, TableHeader } from './tableConfig';
import withLoading from '../../../../../utils/composition/withLoading';
import BulkDelete from '../../../../../v4/components/BulkDelete/BulkDelete';

const PurchaseOrderTable = props => {
  const { data, permission, handleRowClick} = props;

  return (
    <>
      <BulkDelete
        data={data.list}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {TableHeader({
                dataListLength: data?.list?.length,
                checkedListLength: checkedList?.length,
                primaryCheckboxHandler,
                permission,
              })}
              <tbody>
                {data?.list?.map(subD =>
                  TableBody({
                    data: subD,
                    checkedList: checkedList,
                    onViewClick: handleRowClick,
                    onIconClick: () => {},
                    secondaryCheckboxHandler,
                    permission,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
    </>
  );
};

const PurchaseOrderTableWithLoading = withLoading(PurchaseOrderTable);

export default PurchaseOrderTableWithLoading;
