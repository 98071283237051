import styled from 'styled-components';

const STATUS_BUTTON_CONFIG = {
  ACTIVE: {
    color: '#287D3C',
    border: '1px solid #5ACA75',
  },
  BLOCKED: {
    color: '#DA1414',
    border: '1px solid #F48989',
  },
  DEFAULT: {
    color: '#6B6C7E',
    border: '1px solid #CDCED9',
  },
};

export const LinkedDevicesStyled = styled.div`
  width: 100%;

  & > div:not(.modal-wrapper):not(.section-content) {
    margin-top: 0;
    padding: 16px 48px 17px;
    height: 65px;
  }

  .filter-wrap {
    display: flex;
    gap: 16px;
  }

  .filter-wrap > div {
    margin: unset;
  }

  .qr-code-button {
    cursor: pointer;
    .icon {
      width: 16px !important;
      height: 16px !important;
    }
  }

  .pagination-present {
    height: calc(100vh - 123px) !important;
    overflow: scroll;
  }

  .pagination-not-present {
    height: calc(100vh - 65px) !important;
    overflow: scroll;
  }

  .lock-unlock-buttons,
  .delete-button {
    width: 32px;
  }

  .lock-unlock-buttons {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .lock-button {
    color: #da1414;
    cursor: pointer;
  }

  .unlock-button {
    color: #287d3c;
    cursor: pointer;
  }

  .inactive-button {
    color: #cdced9;
    user-select: none;
  }

  table {
    tr {
      height: 40px !important;
    }

    th {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      padding-top: unset !important;
      padding-bottom: unset !important;
    }

    th > span.sorting-arrow {
      cursor: pointer;
    }

    td {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    th:first-child,
    td:first-child {
      padding-left: 48px !important;
    }

    th:last-child,
    td:last-child {
      padding-right: 56px !important;
      padding-left: 8px !important;
      text-align: end;
    }

    td.lock-button {
    }

    th,
    td {
      padding-left: 12px !important;
    }
  }
`;

export const StatusButton = styled.span`
  display: inline-block;
  width: 131.67px;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  padding: 3px 4px;
  border-radius: 2px;
  color: ${props =>
    STATUS_BUTTON_CONFIG[props?.type] ? STATUS_BUTTON_CONFIG[props?.type]?.color : STATUS_BUTTON_CONFIG.DEFAULT.color};
  border: ${props =>
    STATUS_BUTTON_CONFIG[props?.type]
      ? STATUS_BUTTON_CONFIG[props?.type]?.border
      : STATUS_BUTTON_CONFIG.DEFAULT.border};
`;

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 66px);
  overflow: scroll;

  .title-error {
    align-self: center;
    padding-bottom: 24px;
  }

  .master-setting {
    width: 131.67px;
    align-self: center;
  }

  .wrapper {
    width: 50%;
    background: rgb(255, 255, 255);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding: 48px;
    margin-top: 48px;
    margin-bottom: 98px;

    .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      color: #e02386;
      margin: 0;
    }

    .title-second {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      color: #272833;
      margin: 32px 0 0 0;
    }

    .qr-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin: 24px 0px;
      color: #6b6c7e;
    }

    .qr-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      border: 0.5px solid #e8e9f1;
      padding: 18.5px 0px;
    }

    .copyright {
      padding-top: 24px;
      color: #272833;
      display: flex;
      align-items: center;

      .copyright-desc {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        /* margin-left: 16px; */
        margin: 0 0 0 16px;
      }
    }
  }
`;
