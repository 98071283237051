import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import { formConfig, crudRequestConfig } from './config';
import Confirmation from '../../../../../common/DialogConfirmation';
import withAlert from '../../../../../../utils/composition/withAlert';
import { getFromLocalStorage, isConfirmationType } from '../../../../../common/HelperFunctions';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { handleFormSubmit, responseInterpreter } from '../../../../../../utils/crudResponseProcessor';
import { DialogFormWrapper } from '../../../../../common';
import { JWT } from '../../../../../../environment';
import styled from 'styled-components';
import { ALERT_TYPE } from '../../../../../../v4/constants/AlertType';

const propTypes = {
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateSKUGroup: PropTypes.func.isRequired,
  createSKUGroup: PropTypes.func.isRequired,
  element: PropTypes.instanceOf(Object),
  serverResponseWaiting: PropTypes.bool,
  toggleState: PropTypes.func.isRequired,
  replicateSKUGroup: PropTypes.func.isRequired,
  onApiFailure: PropTypes.func.isRequired,
};

const defaultProps = {
  element: {},
  serverResponseWaiting: false,
  type: EVENT_OPERATION.CREATE,
};

class Dialog extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    const routeSegmentation =
      getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS)?.routeSegmentation;
    this.state = {
        menu: { lineList: [] },
        enableRouteSegmentation: routeSegmentation,
    };
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createSKUGroup,
      [EVENT_OPERATION.UPDATE]: props.updateSKUGroup,
      [EVENT_OPERATION.REPLICATE]: props.replicateSKUGroup,
      [EVENT_OPERATION.DELETE]: props.toggleState,
    };
    this.onCRUDSuccess = responseInterpreter(this.handleSuccessResponse);
    this.onFormSubmit = handleFormSubmit(
      this.onCRUDSuccess,
      props.onApiFailure,
      crudRequestConfig,
      serverCall,
    );
  }

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleSuccessResponse = (type, response) => {
    const { onClose, onSubmit } = this.props;
    onClose();
    onSubmit(type, response);
  };

  loadLineList = () => {
    const { getLineDetails } = this.props;
    getLineDetails(
      { offset: 0, filter: { filters: [], queryString: '' } },
      {
        handleSuccess: res => {
          const { menu } = this.state;
          menu.lineList = res.data.lines.rows.filter(rowItem => rowItem.active === true) || [];
          this.setState({ menu });
        },
        handleError: err => this.onAPIRequestFailure(err),
      },
    );
  };

  componentDidMount() {
    const { enableRouteSegmentation } = this.state;
    if (enableRouteSegmentation) {
      this.loadLineList();
    }
  }

  render() {
    const { type, element, onClose, serverResponseWaiting } = this.props;
    const { menu, enableRouteSegmentation } = this.state;
    const formTitle = element?.SKUGroupMaster?.title||'';

    return (
      <DialogFormWrapper
        formConfig={formConfig[type]}
        dialogElement={element}
        onDialogSubmit={this.onFormSubmit}
        onDialogCancel={onClose}
        type={type}
        formTitle={type!==EVENT_OPERATION.DELETE?formTitle:''}
        disableDialogClose
        renderDialog={({ refsObj, dialogData, handleInputChange, enableErrorDisplay, handleDropDownChange }) => (
          <DialogStyle>
            {(type === EVENT_OPERATION.UPDATE ||
              type === EVENT_OPERATION.CREATE ||
              type === EVENT_OPERATION.REPLICATE) && (
              <Form
              show
              type={type}
              refsObj={refsObj}
              data={dialogData}
              loading={serverResponseWaiting}
              handleInputChange={handleInputChange}
              enableErrorDisplay={enableErrorDisplay}
              handleDropDownChange={handleDropDownChange}
              options={menu.lineList}
              enableRouteSegmentation={enableRouteSegmentation}
              />
            )}
            {isConfirmationType(type) && Confirmation(type)}
          </DialogStyle>
        )}
      />
    );
  }
}

Dialog.propTypes = propTypes;

Dialog.defaultProps = defaultProps;

export default withAlert()(Dialog);


const DialogStyle = styled.div`
  .filter-dropdown-card {
    top: -365px;
  }
`;