/* global google */
import React, { Component } from 'react';
import marker from '../../../../configuration/arc/services/marker';
import { createScriptLoadMap } from '../../../../configuration/arc/services/googleMapScript';
import { redMarkerColor, blueMarkerColor, googleMapPoint1, googleMapPoint2, markerIconPath } from './config';

class GeolocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    createScriptLoadMap().then(() => {
      this.renderMarkers();
    });
  }

  renderMarkers = () => {
    const { correctLocation, proposedLocation } = this.props;
    marker.generateMarker(
      { lat: correctLocation.latitude, lng: correctLocation.longitude },
      null,
      null,
      this.getIconAttributes(redMarkerColor),
    );
    marker.generateMarker(
      { lat: proposedLocation.latitude, lng: proposedLocation.longitude },
      null,
      null,
      this.getIconAttributes(blueMarkerColor),
    );
    window.globalMap.setCenter({ lat: proposedLocation.latitude, lng: proposedLocation.longitude });
    window.globalMap.setZoom(15);
  };

  getIconAttributes = iconColor => ({
    path: markerIconPath,
    fillColor: iconColor,
    fillOpacity: 0.8,
    strokeWeight: 2,
    anchor: new google.maps.Point(googleMapPoint1, googleMapPoint2),
  });

  render() {
    const { currentData } = this.props;
    return (
      <>
        <div id="map" />
        <ul className="custom_legend">
          <li className="correct_location">
            <span />
            <p>Correct Location</p>
          </li>
          <li className="proposed_location">
            <span />
            <p>Proposed Outlet Location </p>
          </li>
        </ul>
      </>
    );
  }
}

export default GeolocationMap;
