import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_ROUTE_SEGMENTATION_LIST } from '../../../salesForce/routeSegmentation/API';

const GET_BASIC_DETAIL = gql`
query getBasicDetail($masterId: Int!, $value: String) {
  skuGroups(masterId: $masterId, filter: { filters: [{ column: "id", value: [$value] }, { column: "active", value: ["true", "false"] }] }) {
    count
    rows {
      id
      title
      active
      start_date
      end_date
      start:startDate
      end:endDate
      master_id
      lineId:line_id
      SKUGroupDetails {
        id
        channelId
        categoryId
        brandId
        skus
        sku_family
      }
      SKUGroupMaster {
        id
        title
      }
    }
  }
}
`;

const GET_BRAND_DETAILS = gql`
  query getBrandDetails($catalogId: Int, $limit: Int) {
    catalogDetails(catalogId: $catalogId, limit: $limit) {
      rows {
        id
        title
        SKUs {
          id
          title
        }
      }
    }
  }
`;

const GET_SKU_DETAILS = gql`
  query getBrandsSkus($value: Int) {
    catalogLevelDetails(catalogId: 3, catalogDetailId: $value) {
      count
      rows {
        id
        title
        SKUs {
          id
          title
        }
        SkuFamilies {
          catalogId
          id: catalogDetailId
          title
          skuCount
        }
      }
    }
  }
`;

const GET_SKUS_COUNT = gql`
  query getCount($skuGroupId: Int, $channelId: Int, $categoryId: Int, $brandId: Int) {
    getSkuGroupDetails(skuGroupId: $skuGroupId, channelId: $channelId, categoryId: $categoryId, brandId: $brandId) {
      count
      rows {
        skuGroupId
        Channel {
          id
          count
        }
        Category {
          id
          count
        }
        Brand {
          id
          count
        }
        SkuGroup {
          id 
          skus
          count 
        }
        SkuFamily {
          id
          count
          skus: skuFamilies
        }
      }
    }
  }
`;

const GET_SKUS_List = gql`
query catalogLevelDetails($catalogId:Int, $catalogDetailId:Int){
  catalogLevelDetails(catalogId:$catalogId,catalogDetailId:$catalogDetailId){
    rows {
      title
      SKUs{
        id
        title
      }
    }
  }
  }
  `;

const GET_SKUS_LIST = gql`
query getSKUFilteredByFamilyId($familyId: Int) {
  getSKUFilteredByFamilyId(familyId: $familyId) {
    id
    title
  }
}
  `;

const CREATE_GROUP_SKUS = graphql(
  gql`
  mutation insertSKUGroupDetails($input: SKUGroupDetailsInput!) {
    insertSKUGroupDetails(input: $input) {
      brandId
      categoryId
      channelId
      id
      sku_family
      skus
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      insertSKUGroupDetails: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const DELETE_GROUP_DETAILS_SKU = graphql(
  gql`
  mutation deleteSKUGroupDetails($id: Int!) {
    deleteSKUGroupDetails(id: $id) {
      id
      channelId
      categoryId
      brandId
      skus
      sku_family
    }
  }
  `,
  {
    props: ({ mutate }) => ({
      deleteSKUGroupDetails: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);



const query = {
  getBrandDetails: GET_BRAND_DETAILS,
  getSkuDetails: GET_SKU_DETAILS,
  getBasicDetail: GET_BASIC_DETAIL,
  getCount: GET_SKUS_COUNT,
  getSKUList: GET_SKUS_List,
  getSKUFilteredByFamilyId: GET_SKUS_LIST,
  getLineDetails: GET_ROUTE_SEGMENTATION_LIST,
};

const mutation = {
  insertSKUGroupDetails: CREATE_GROUP_SKUS,
  deleteSKUGroupDetails: DELETE_GROUP_DETAILS_SKU,
};

export {
  query, mutation, CREATE_GROUP_SKUS, DELETE_GROUP_DETAILS_SKU
};
