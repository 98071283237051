import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  breadCrumbConfig,
  crudSuccess as crudRequestConfig,
  formattedLines,
  formMapper,
  totalPriceMapper,
} from './config';
import { PRN } from '../../../../data/enums/Route';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { handleFormSubmit } from '../../../../utils/crudResponseProcessor';
import CreatePrnView from './View';
import { summaryDetailsFormConfig } from './summaryDetails/config';
import { getSubdUserStatusAndId, handlePrint } from '../../../common/HelperFunctions';
import { DOMAIN } from '../../../../data/enums/config';

const propTypes = {
  getSkus: PropTypes.func.isRequired,
  createPrn: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  getVendors: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  getUpdatedGrnDetail: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class CreatePrn extends Component {
  constructor(props) {
    super(props);
    const { id = 0, exist = false } = getSubdUserStatusAndId();
    this.distributorId = id;
    this.subDUser = exist;
    this.state = {
      skus: [],
      data: formMapper({}, this.distributorId),
      distributorId: this.distributorId,
      vendorList: [],
      distributorList: [],
      enableErrorDisplay: false,
      invoiceNumberStatus: false,
      exciseInLine: false,
      distributorServices: {
        billing: {
          status: false,
          url: '',
        },
      },
    }
    const serverCall = {
      [EVENT_OPERATION.CREATE]: props.createPrn,
    };
    this.onCRUDSuccess = this.responseProcessor(this.handlePrnSuccess);
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
    this.permission = this.getUserPermission();
  }
  handlePrnSuccess = (response, type) => {
    const { distributorServices, data } = this.state;
    const { displayAlert } = this.props;
    if (distributorServices.billing.status) {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      handlePrint({
        distributorServices,
        invoiceNumber: response.prnInvoiceNumber,
        module: distributorServices.billing.versionCode===2?DOMAIN.DBS_PURCHASE_RETURN:DOMAIN.PURCHASE_RETURN,
        newVersionCode: distributorServices?.billing.versionCode === 2 ? true : false,
        distributorId: data.distributorId,
      },
      this.directToMainPage);
    } else {
      displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message, this.directToMainPage);
    }
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    this.getSKUs();
    this.getVendorsList();
     this.getDistributorsList();
     this.getlistMasterConfiguration();
  }

  getlistMasterConfiguration = () => {
    const { listMasterConfiguration, displayAlert } = this.props;
    listMasterConfiguration(
      { type: 'GRN_CONFIG' },
      {
        handleSuccess: res => {
          const grnValidationConfig = res.data.listMasterConfiguration.filter(a => a.type === 'GRN_CONFIG');
          this.setState({
            exciseInLine: grnValidationConfig.length > 0 && grnValidationConfig[0].configuration?.exciseInLine,
          });
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  getUserPermission = () => ({ PRN: { BILLING: true } });
  // todo fetch user permission here

  getVendorsList = () => {
    const { getVendors, displayAlert } = this.props;
    getVendors({}, {
      handleSuccess: (response) => {
        this.setState({ vendorList: response.data.vendors.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };
  setDistributorServices = (distributor) => {
    const { distributorServices } = this.state;
    this.setState({
      distributorServices: distributor.servicesUsed || distributorServices,
    });
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors({}, {
      handleSuccess: (response) => {
        const distributorList = response.data.distributors
        ? response.data.distributors.rows || [] : [];
        this.setState({ distributorList }, () => {
          if (this.subDUser && distributorList.length > 0) {
            this.setDistributorServices(distributorList[0]);
          }
        },);
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getSKUs = () => {
    const { getSkus, displayAlert } = this.props;
    getSkus({
      filter: {
        filters: [
          {
            column: 'active',
            value: ["true", "false"]
          },
        ],

      },
    }, {
      handleSuccess: (response) => {
        this.setState({ skus: response.data.skus.rows });
      },
      handleError: (error) => {
        displayAlert(ALERT_TYPE.DANGER, error);
      },
    });
  };

  getUpdatedValue = (field, value) => {
    this.setState({ [field]: value });
  };

  handleInvoiceNumberChange = (invoiceNumber, exactlyMatchedInvoiceNumber) => {
    const { data } = this.state;
    const { getUpdatedGrnDetail, displayAlert } = this.props;
    if (exactlyMatchedInvoiceNumber) {
      getUpdatedGrnDetail({ invoiceNumber,distributorId:this.distributorId }, {  
        handleSuccess: (response) => {
          const invoiceDetail = response.data.findGRNInvoice || formMapper({}, this.distributorId);
          if (invoiceDetail.lines.length > 0) {
            invoiceDetail.lines = formattedLines(invoiceDetail.lines);
          }
          invoiceDetail.inputInvoiceNumber = invoiceNumber;
          this.setState({
            data: invoiceDetail,
            distributorId: invoiceDetail.distributorId,
            invoiceNumberStatus: exactlyMatchedInvoiceNumber,
          });
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      });
    } else if (data.lines) {
      data.lines = [];
      data.updatedAmount = totalPriceMapper({});
      data.amount = totalPriceMapper({});
      data.inputInvoiceNumber = invoiceNumber;
      this.setState({
        distributorId: this.distributorId,
        data,
        invoiceNumberStatus: exactlyMatchedInvoiceNumber,
      });
    }
  };

  onSubmit = () => {
    const valid = this.getValidationStatus();

    if (valid) {
      this.createPrn();
    } else {
      this.setState({ enableErrorDisplay: true });
    }
  };

  getValidationStatus = () => {
    const detailsStatus = this.getDetailsValidationStatus();
    const tableStatus = this.getTableValidationStatus();

    return (detailsStatus && tableStatus);
  };

  createPrn = () => {
    const { invoiceNumberStatus } = this.state;
    let updatedData = {};
    const tableData = this.getTableDetails();
    updatedData = { ...this.getDetails() };
    updatedData.lines = tableData.lines;
    updatedData.amount = tableData.amount;
    updatedData.oldBillStatus = !invoiceNumberStatus;
    updatedData.prnType = tableData.prnType;

    this.onFormSubmit(EVENT_OPERATION.CREATE, updatedData);
  };

  onAPIRequestFailure = (error) => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
    console.log(error);
  };

  responseProcessor = callBack => type => (response) => {
    callBack(
      response,
      type,
    );
  };

  directToMainPage = () => {
    /** direct to Srn page */
    const { history } = this.props;
    history.push(`/${PRN}`);
  };

  handlePrnCancel = () => {
    this.setState({ data: formMapper({},this.distributorId) });
  };

  render() {
    const {
      data,
      skus,
      vendorList,
      distributorId,
      distributorList,
      enableErrorDisplay,
      invoiceNumberStatus,
      exciseInLine,
    } = this.state;
    const {
      displayAlert,
      serverResponseWaiting,
      getSKUBatchDetail,
    } = this.props;

    return (
      <CreatePrnView
        data={data}
        skus={skus}
        vendorList={vendorList}
        subDUser={this.subDUser}
        displayAlert={displayAlert}
        distributorId={distributorId}
        permission={this.permission}
        breadCrumb={breadCrumbConfig}
        handlePrnSubmit={this.onSubmit}
        distributorList={distributorList}
        handlePrnCancel={this.handlePrnCancel}
        enableErrorDisplay={enableErrorDisplay}
        invoiceNumberStatus={invoiceNumberStatus}
        detailsFormConfig={summaryDetailsFormConfig}
        loading={serverResponseWaiting}
        onInvoiceNumberChange={this.handleInvoiceNumberChange}
        handleValueUpdate={this.getUpdatedValue}
        getSummaryDetails={childMethod => (this.getDetails = childMethod)}
        getTableDetails={childMethod => (this.getTableDetails = childMethod)}
        getTableValidationStatus={childMethod => (this.getTableValidationStatus = childMethod)}
        getDetailsValidationStatus={childMethod => (this.getDetailsValidationStatus = childMethod)}
        getSKUBatchDetail={getSKUBatchDetail}
        exciseInLine={exciseInLine}
      />
    );
  }
}

CreatePrn.propTypes = propTypes;

CreatePrn.defaultProps = defaultProps;

export default withAlert()(CreatePrn);
