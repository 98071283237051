import { refGenerator } from '../../../../utils';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { getFilterConfig } from '../../../../utils/filter';
import { FORM_CONFIG } from '../../../../v4/constants/formConfig';
import { configuration, skuGroup } from '../../../../data/enums/Route';
import { FILTER } from '../../../../data/enums/filter';

const breadCrumb = [configuration, skuGroup];
const requiredList = ['distributorId', 'month', 'target'];
const filter = getFilterConfig([FILTER.SUB_D]);

const crudSuccess = {
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: Title,
      active: false,
    }),
    responseName: 'toggleState',
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: data.ids,
      type: Title,
      active: false,
    }),
    responseName: 'toggleState',
  },
};

const formConfig = {
  [EVENT_OPERATION.CREATE]: {
    [FORM_CONFIG.TITLE]: 'Create',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Edit',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.REPLICATE]: {
    [FORM_CONFIG.TITLE]: 'Replicate',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Confirm',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
  },
};

export {
  breadCrumb as breadCrumbConfig, formConfig, filter as filterConfig, crudSuccess
};
