import styled from 'styled-components';

const DetailStyled = styled.div`
  .section-content .table-wrap {
    height: calc(100vh - 120px) !important;
  }
  table {
    border: 0;
    tr {
      &.collapsible-body-tr {
        tbody {
          tr {
            th,
            td {
              &:first-child {
                padding: 7px 12px 7px 70px;
                width: 32% !important;
                white-space: normal !important;
              }
              &:nth-child(2) {
                width: 11% !important;
              }
              /* &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                width: 100px !important;
              } */
            }
          }
        }
      }
    }
    tbody {
      tr {
        td:last-child {
          & > div {
            display: flex;
          }
        }
        &.active {
          td {
            &:first-child > span:before {
              transform: rotate(180deg);
              transition: all 0.23s ease-in-out;
              border-top-color: #004ad7;
            }
          }
        }
        .input-section {
          width: 180px;
        }
        td {
          &:first-child {
            width: 38%;
            > span {
              position: relative;
              padding-left: 26px;

              &:before {
                content: '';
                width: 0;
                height: 0;
                border-radius: 4px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #6b6c7e;
                position: absolute;
                top: 5px;
                left: 0;
                transition: all 0.23s ease-in-out;
              }
            }
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(5) {
            width: 11%;
          }

          &:nth-child(4),
          &:last-child {
            width: 12%;
          }
        }
      }
    }
  }
  .upload-text {
    position: absolute;
    left: 50%;
    top: 300%;
    transform: translate(-50%, -50%);
  }
`;

export default DetailStyled;
