import styled from 'styled-components';

const ReplenishmentModelWrapper = styled.div`
.top-section{
  display: flex;
  justify-content: space-between;
  padding: 8px 48px;
}
.top-right{
  display: flex;
  .add-btn{
    margin-left: 8px;
    border: 1px solid var(--primary-primary, #0B5FFF);
    background: var(--light-white, #FFF);
    color: #0B5FFF;
  }
  .icon {
    color: #0B5FFF;
}
}
.evolve-dialog__body{
  max-height: 70vh;
  overflow: auto;
  .form-input{
    margin-bottom: 24px;
  }
  .custom-date{
    .form-input{
      margin-bottom: 0px;
    }
    margin-bottom: 24px;
  }
  .reapeat__days{
    > label {
          width: calc(17.57% - 7px);
          color: #272833;
          font-size: 14px;
          font-weight: 500;
          margin-top: 10px;
          padding-left: 4px;
    }
    .checkbox-list {
      margin-bottom: 24px;
      margin-top: 8px;
      > div {
        > label{
          margin-right: 10px;
          font-weight : 600;
        }
      }
    }
  }
  .time-label{
    margin-bottom: 4px;
    color: #272833;
    font-size: 14px;
    font-weight: 600;
  }
  .time {
    display : flex;
    width: 100%;
    .form-select-input{
       margin-bottom: 0px;
       >label{
        display: none;
       }
    }
    .form-input{
      margin-right: 10px;
      margin-bottom: 0;
    }
     .time__hours{
        width: 33.3%;
        > label {
          font-size: 14px;
          color: #6B6C7E;
          margin-top: 6px;
          margin-left: 16px;
          line-height: 21px;
     }
     }
  }
}
`;

export default ReplenishmentModelWrapper;
