import styled from 'styled-components';
import { IMAGE_STATUS_MAPPER } from './config';

const UploadStyled = styled.div`
  .modal-inner.image {
    width: 580px;
    max-width: unset;
    &.sync-progress {
      width: 395px;
      .progress-card {
        padding: 24px !important;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
        background-color: #ffffff;
        border-bottom: unset;
        .count-total {
          font-size: 16px;
          line-height: 21px;
          color: #272833;
          margin: 0;
          margin-bottom: 4px;
        }
      }
      .progress-bar-styled-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        .progress-wrap {
          width: 90%;
          height: 8px;
          margin-right: 4px;
          .progress-bar {
            margin: 0;
            background-color: #cfd0da;
            & > div {
              background-color: #5aca75 !important;
            }
          }
        }
        span {
          position: relative;
          top: -2px;
          color: #287d3c;
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
        }
      }
      .failed,
      .sucess {
        display: none !important;
      }
    }
    .evolve-dialog__header {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      h2 {
        font-size: 20px;
        font-weight: bold;
        color: rgb(39, 40, 51);
        margin: 0px;
        border: 0;
        padding: 0;
      }
    }
    .evolve-dialog__body {
      background-color: #f1f2f5;
    }
    .img-upload-wrap {
      display: inline-block;
      width: 100%;
      .img-icon img {
        height: 32px;
        width: 32px;
        border-radius: 2px;
        margin-right: 16px;
        background-color: #ffffff;
        margin-top: 8px;
      }
      h3 {
        font-size: 14px;
        line-height: 21px;
        color: #272833;
        font-weight: 500;
        margin: 0;
      }
      .img-progress-info {
        display: flex;
        justify-content: space-between;
        span {
          font-size: 10px;
          line-height: 10px;
          color: #6b6c7e;
        }
      }
      .progress-bar-wrap {
        flex: 1;
      }
      .progress-bar {
        width: 456px;
        width: 100%;
        height: 8px;
        border-radius: 4px;
        margin: 4px 0;
      }
      .failed,
      .sucess {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .icon {
          margin-right: 5px;
          height: 14px;
          width: 14px;
        }
      }
      .sucess {
        color: #287d3c;
      }
      .failed {
        color: #da1414;
      }
    }
  }

  .modal-wrapper .modal-inner.image input.input-file-upload {
    position: relative;
    right: -16%;
  }
`;

const ProgressBarStyled = styled.div`
  ${(props) =>
    props.status === IMAGE_STATUS_MAPPER.PENDING && {
      backgroundColor: '#E7E7ED',
      borderRadius: '4px',
    }}
  ${(props) =>
    props.status === IMAGE_STATUS_MAPPER.SUCCESS && {
      backgroundColor: '#048B57',
      borderRadius: '4px',
      // display: 'none',
    }}
  ${(props) =>
    props.status === IMAGE_STATUS_MAPPER.DANGER && {
      backgroundColor: '#E44E58',
      borderRadius: '4px',
    }}
`;
const ProgressCardStyled = styled.div`
  ${(props) =>
    props.status === IMAGE_STATUS_MAPPER.PENDING && {
      backgroundColor: '#FFFFFF',
      borderRadius: '1px solid #e7e7ed',
    }}
  ${(props) =>
    props.status === IMAGE_STATUS_MAPPER.SUCCESS && {
      backgroundColor: '#EDF9F0',
      borderBottom: '1px solid #5ACA75',
    }}
   ${(props) =>
    props.status === IMAGE_STATUS_MAPPER.DANGER && {
      backgroundColor: '#FEEFEF',
      borderBottom: '1px solid #F48989',
    }}
`;
export {
  UploadStyled, ProgressBarStyled, ProgressCardStyled
};
