import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DOWNLOAD_REPORT, UPLOAD } from '../../../common/GqlAPI';
import { GET_CATALOG_DETAIL_LIST } from '../details/API';

const GET_BRAND_COVERAGE_TARGETS = gql`
query getbrandCoverageTagetsList($filter: FilterInput, $limit: Int, $offset: Int) {
  brandCoverageTargetsList(filter: $filter, limit: $limit, offset: $offset) {
      rows {
        id
        title
        startDate
        endDate
        brandId
        brandGroup
        target
        ids
        brandIds
      }
      count
    }
  }
`;

const BRAND_COVERAGE_TARGETS = gql`
  query getbrandCoverageTagets($startDate: String!, $endDate: String!) {
    brandCoverageTargets(startDate: $startDate, endDate: $endDate) {
      id
      title
      startDate
      endDate
      target
      brandId
      brandName: brandGroup
    }
  }
`;

const CREATE_BRAND_COVERAGE_TARGET = graphql(
  gql`
    mutation createBrandCoverageTarget($input: BrandCoverageTargetInput!) {
      createBrandCoverageTarget(input: $input) {
        id
        title
        startDate
        endDate
        brandId
        target
        brandGroup
        ids
        brandIds
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createBrandCoverageTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const UPDATE_BRAND_COVERAGE_TARGET = graphql(
  gql`
    mutation updateBrandCoverageTarget($startDate: String!, $endDate: String!, $input: [BrandTargetUpdateInput]!) {
      updateBrandCoverageTarget(startDate: $startDate, endDate: $endDate, input: $input) {
        id
        title
        startDate
        endDate
        brandId
        target
        brandGroup
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateBrandCoverageTarget: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getbrandCoverageTagets: BRAND_COVERAGE_TARGETS,
  getbrandCoverageTagetsList: GET_BRAND_COVERAGE_TARGETS,
  getCatalogDetailList: GET_CATALOG_DETAIL_LIST,
};

const mutation = {
  upload: UPLOAD,
  downloadReport: DOWNLOAD_REPORT,
  createBrandCoverageTarget: CREATE_BRAND_COVERAGE_TARGET,
  updateBrandCoverageTarget: UPDATE_BRAND_COVERAGE_TARGET,
};

export {
  query, mutation, UPLOAD, DOWNLOAD_REPORT, CREATE_BRAND_COVERAGE_TARGET, UPDATE_BRAND_COVERAGE_TARGET
};
