import { breadCrumbConfig } from '../config';
import { FILTER } from '../../../../data/enums/filter';
import { getFilterConfig } from '../../../../utils/filter';

const breadCrumb = [...breadCrumbConfig];

const createTitle = 'Create Route Segmentation';

const filter = getFilterConfig([
  FILTER.BRAND,
  // FILTER.STATUS
]);

const formConfig = {
  mapper: (element) => ({
    id: element.id || 0,
    title: element.title || '',
    lineBrands: {
      id: element.lineBrands ? element.lineBrands.id || 0 : 0,
      title: element.lineBrands ? element.lineBrands.title : '',
      status: element.lineBrands ? element.lineBrands.status : '',
      lineSkus: {
        id: element.lineBrands.lineSkus.id,
        title: element.lineBrands.lineSkus.title,
        status: element.lineBrands.lineSkus.status,
      },
    },
  }),
};

export {
  breadCrumb as breadCrumbConfig, createTitle, filter as filterConfig, formConfig
};
