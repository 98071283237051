import PushMessage from './PushMessage';
import { compose } from 'recompose';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, TOGGLE_STATE
} from './API';

const ComposedPushMessage = compose(
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(PushMessage);
export default ComposedPushMessage;
