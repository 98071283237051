import { breadCrumbConfig } from '../config';
import { PUSH_MESSAGE } from '../../../../data/enums/Route';
import { getCurrentDay } from '../../../../utils/date';

const title = 'Push Messages';

const dialogTitle = 'Confirm & Send';

const breadCrumb = [
  ...breadCrumbConfig,

  {
    id: 2,
    title: 'Push Messages',
    path: `/${PUSH_MESSAGE}`,
    active: true,
  },
];

const channelCategoryBase = {
  channelList: {
    channelId: 0,
    categoryIds: [],
  },
};

const checkRepeatTime = (repeatedTimes) => {
  if (repeatedTimes.daily) {
    return 'DAILY';
  }
  if (repeatedTimes.weekly) {
    return 'WEEKLY';
  }
  if (repeatedTimes.monthly) {
    return 'MONTHLY';
  }
  return null;
};

const checkOutletStatus = (outlet) => {
  if (outlet.active) {
    return 'ACTIVE';
  }
  if (outlet.verified) {
    return 'VERIFIED';
  }
  if (outlet.both) {
    return 'BOTH';
  }
  return null;
};

const messagePlatform = [
  { title: 'SMS', id: 1, value: 'SMS' },
  { title: 'NOTIFICATION', id: 2, value: 'NOTIFICATION' },
  { title: 'VIBER', id: 3, value: 'VIBER' },
];

const targetAudience = [
  { title: 'User', id: 1, value: 'USER' },
  { title: 'Outlet', id: 2, value: 'OUTLET' },
];

const periodType = [
  {
    id: 1,
    title: 'AM',
    value: 'AM',
  },
  {
    id: 2,
    title: 'PM',
    value: 'PM',
  },
];

const weekDays = {
  sunday: 7,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

const formConfig = {
  mapper: (element) => ({
    id: element.id || 0,
    title: element.title || '',
    message: {
      title: (element.message && element.message.title) || '',
      link: (element.message && element.message.link) || '',
      description: (element.message && element.message.description) || '',
      message: (element.message && element.message.message) || '',
    },
    campaign: element.campaign || '',
    category: element.category || '',
    audience: element.targetAudience ? targetAudience.find((d) => d.id === element.targetAudience).value : '',
    targetAudience: element.targetAudience ? element.targetAudience : '',
    userGroup: (element.userGroup && element.userGroup) || [],
    invoiced: element.invoiced || '',
    outlet: {
      active: (element.outlet && element.outlet.active) || false,
      verified: (element.outlet && element.outlet.verified) || false,
      both: (element.outlet && element.outlet.both) || false,
    },
    audienceFilter: {
      userRole: typeof element.userGroup === 'object' ? element.userGroup : element.userGroup || [],
      outletStatus: (element.outlet && checkOutletStatus(element.outlet)) || null,
      lastInvoiced: Number(element.invoiced) || 0,
      zoneId: element.territoryList || [],
      townId: element.townList || [],
      distributorId: element.distributorList || [],
      routeId: element.routeList || [],
      channelId:
        (element.channelList &&
          (element.channelList.map((d) => d.channelId).includes(0) ? [] : element.channelList.map((d) => d.channelId))) ||
        [],
      categoryId: (element.channelList && element.channelList.map((d) => [...d.categoryIds]).flat()) || [],
      channelCategory: (element.channelList && element.channelList) || [],
    },
    location: {
      zone: 'ZONE',
      town: 'TOWN',
      route: 'ROUTE',
    },
    territoryList: element.territoryList || [],
    townList: element.townList || [],
    routeList: element.routeList || [],
    channelList: [
      {
        ...channelCategoryBase.channelList,
      },
    ],
    categoryList: element.categoryList || [],
    platform: element.platform ? messagePlatform.find((d) => d.id === element.platform).value : '',
    repeatedTimes: {
      daily: (element.repeatedTimes && element.repeatedTimes.daily) || false,
      weekly: (element.repeatedTimes && element.repeatedTimes.weekly) || false,
      monthly: (element.repeatedTimes && element.repeatedTimes.monthly) || false,
    },
    repeatTimes: element.repeatedTimes && checkRepeatTime(element.repeatedTimes),
    isScheduled: element.isScheduled || false,
    isRepeated: element.isRepeated || false,
    startDate: element.startDate || getCurrentDay(),
    endDate: element.endDate || getCurrentDay(),
    times: {
      hours: (element.times && element.times.hours) || '00',
      minutes: (element.times && element.times.minutes) || '00',
      period: (element.times && element.times.period) || 'AM',
    },
    repeatDays: element.repeatDays || [],
    time: element.times && `${element.times.hours}:${element.times.minutes} ${element.times.period}`,
  }),
  validationField: ['title', 'platform', 'targetAudience', 'messageTitle', 'userGroup', 'message', 'description'],
  validationRequired: true,
};

const transformData = (data) => {
  if (data.audienceFilter.channelCategory.length && !data.audienceFilter.channelCategory[0].channelId) {
    data.audienceFilter.channelCategory = [];
  }
  return {
    title: data.title,
    campaign: data.campaign,
    category: data.category,
    active: true,
    platform: data.platform,
    message: data.message,
    audience: data.audience,
    audienceFilter: data.audienceFilter,
    isScheduled: data.isScheduled,
    isRepeated: data.isRepeated,
    repeatTimes: data.repeatTimes,
    repeatDays: data.repeatDays,
    startDate: data.startDate,
    endDate: data.endDate,
    time: data.time,
  };
};

const reponseToStateTransformer = (data) => ({
  title: data.title,
  campaign: data.campaign,
  category: data.category,
  targetAudience: data.messageDetail.length && targetAudience.find((d) => d.value === data.messageDetail[0].audience).id,
  platform: data.messageDetail.length && messagePlatform.find((d) => d.value === data.messageDetail[0].platform).id,
  outlet: {
    active: (data.messageDetail.length && data.messageDetail[0].audienceFilter.outletStatus === 'ACTIVE') || false,
    verified: (data.messageDetail.length && data.messageDetail[0].audienceFilter.outletStatus === 'VERIFIED') || false,
    both: (data.messageDetail.length && data.messageDetail[0].audienceFilter.outletStatus === 'BOTH') || false,
  },
  message: {
    title: (data.messageDetail.length && data.messageDetail[0].message && data.messageDetail[0].message.title) || '',
    link: (data.messageDetail.length && data.messageDetail[0].message && data.messageDetail[0].message.link) || '',
    description:
      (data.messageDetail.length && data.messageDetail[0].message && data.messageDetail[0].message.description) || '',
    message:
      (data.messageDetail.length && data.messageDetail[0].message && data.messageDetail[0].message.message) || '',
  },
  isScheduled: data.messageDetail.length && data.messageDetail[0].isScheduled,
  isRepeated: data.messageSchedule ? data.messageSchedule.isRepeated : false,
  repeatedTimes: {
    daily: (data.messageSchedule && data.messageSchedule.repeatTimes === 'DAILY') || false,
    weekly: (data.messageSchedule && data.messageSchedule.repeatTimes === 'WEEKLY') || false,
    monthly: (data.messageSchedule && data.messageSchedule.repeatTimes === 'MONTHLY') || false,
  },
  repeatDays: data.messageSchedule ? data.messageSchedule.repeatDays : [],
  startDate: data.messageSchedule ? data.messageSchedule.startDate : getCurrentDay(),
  endDate: data.messageSchedule ? data.messageSchedule.endDate : getCurrentDay(),
  invoiced: (data.messageDetail.length && data.messageDetail[0].audienceFilter.lastInvoiced) || [],
  territoryList: (data.messageDetail.length && data.messageDetail[0].audienceFilter.zoneId) || [],
  townList: (data.messageDetail.length && data.messageDetail[0].audienceFilter.townId) || [],
  routeList: (data.messageDetail.length && data.messageDetail[0].audienceFilter.routeId) || [],
  userGroup: (data.messageDetail.length && data.messageDetail[0].audienceFilter.userRole) || [],
  channelList:
    data.messageDetail.length &&
    data.messageDetail[0].audienceFilter &&
    data.messageDetail[0].audienceFilter.channelCategory,
  times: data.messageSchedule
    ? {
      hours: data.messageSchedule.time.substr(0, data.messageSchedule.time.indexOf(':')),
      minutes: data.messageSchedule.time.substr(data.messageSchedule.time.indexOf(':') + 1, 3).trim(''),
      period: data.messageSchedule.time.substr(5).trim(' '),
    }
    : formConfig.mapper({}).times,
  status: data.status,
  location: {
    zone: 'ZONE',
    town: 'TOWN',
    route: 'ROUTE',
  },
});

export {
  breadCrumb as breadCrumbConfig,
  formConfig,
  title,
  channelCategoryBase,
  messagePlatform,
  targetAudience,
  periodType,
  weekDays,
  dialogTitle,
  checkOutletStatus,
  transformData,
  reponseToStateTransformer,
};
