import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Order Summary Report';

const breadCrumb = [reportsRoute];

const filter = getFilterConfig([
  FILTER.OSR_STATUS,
  FILTER.CHANNEL,
  FILTER.CATEGORY,
  FILTER.ROUTE,
  FILTER.SUB_D,
  FILTER.TOWN,
  FILTER.BRAND,
  FILTER.DSE,
  FILTER.ASM,
]);

export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, title
};
