import React, { useState } from 'react';
import { AccordionTable } from '../../../../components/CollapsibleTable';
import { TableHeader, TableBody } from './tableConfig';
import SubDTargetDetailChildView from './childView';
import withLoading from '../../../../utils/composition/withLoading';

const View = (props) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const { data, onRowClick, onIconClick, onCancelClick, onSaveClick, button, onInputChange, sku,permission } = props;
  return (
    <div className='table-wrap'>
      <table>
        {TableHeader(sku)}
        {data.list.length > 0 ? (
          <tbody>
            {
              <AccordionTable setIsAccordionOpen={setIsAccordionOpen}>
                {data.list.map((detail) => (
                  <tr
                    key={detail.brand_id}
                    trigger={TableBody({
                      data: detail,
                      onRowClick,
                      onIconClick,
                      onCancelClick,
                      onSaveClick,
                      button,
                      isAccordionOpen,
                      permission
                    })}
                  >
                    {SubDTargetDetailChildView({
                      data: button.buttonData.details || [],
                      onInputChange,
                      button,
                    })}
                  </tr>
                ))}
              </AccordionTable>
            }
          </tbody>
        ) : <div className='upload-text'>{'No Data. Please upload targets .'}</div>}
      </table>
    </div>
  )
}
const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
