import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_MASTER_DATA } from '../../../../common/GqlAPI';
import { getSkuGroupApi } from '../../../SKUGroup/SKUGroupMaster/Repository/API';

const getPerfectCallListApi = gql`
  query PerfectCalls($limit: Int, $offset: Int, $filter: FilterInput) {
    perfectCalls(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        title
        alias
        criteria_type
        criteria_value
        sequence
        active
      }
      count
    }
  }
`;

const createPerfectCalls = graphql(
  gql`
    mutation CreatePerfectCalls($input: PerfectCallInput!) {
      createPerfectCalls(input: $input) {
        id
        title
        alias
        criteria_type
        criteria_value
        sequence
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createPerfectCalls: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const updatePerfectCalls = graphql(
  gql`
    mutation UpdatePerfectCalls($input: PerfectCallInput!, $id: Int) {
      updatePerfectCalls(input: $input, id: $id) {
        id
        title
        alias
        criteria_type
        criteria_value
        sequence
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updatePerfectCalls: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const updatePerfectCallSequence = graphql(
  gql`
    mutation UpdatePerfectCallSequence($input: [PerfectCallSequence]) {
      updatePerfectCallSequence(input: $input) {
        id
        title
        alias
        criteria_type
        criteria_value
        sequence
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updatePerfectCallSequence: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const deletePerfectCall = graphql(
  gql`
    mutation DeletePerfectCall($id: Int) {
      deletePerfectCall(id: $id) {
        id
        title
        alias
        criteria_type
        criteria_value
        sequence
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      deletePerfectCall: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

// const getBuData = GET_BU_DATA;

const query = {
  getPerfectCallListApi,
  getSkuGroupApi: getSkuGroupApi,
  getMasterData: GET_MASTER_DATA,
};

const mutation = {
  createPerfectCalls,
  updatePerfectCalls,
  deletePerfectCall,
  updatePerfectCallSequence,
};

export { query, mutation, createPerfectCalls, updatePerfectCalls, updatePerfectCallSequence, deletePerfectCall };
