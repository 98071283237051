import styled from 'styled-components';

const TableStyled = styled.div`
.return-create{
  .modal-wrapper{
    .modal-inner{
      .evolve-dialog__body{
        max-height:70vh;
        overflow:auto;
        .actual_discount>div{
          cursor:not-allowed;
        }
        .audit-blocked {
          &:after {
            content: "Audit is still pending for this SKU.";
            top: 85%;
            left: 3%;
          }
        }
      }
      .single-date-picker{
        margin:0;
      }
    }
  }
}
  .selected-status .content-center {
    padding: 14px 25px;
  }
  .control-label {
    &::before {
      position: absolute;
    }
  }

  .audit-blocked {
    &:after {
      content: "Audit is still pending. You can't select this SKU line.";
      top: 85%;
      left: 50%;
    }
  }
`;
export default TableStyled;
