import React from 'react';
import { Button } from '../../v4/components';
import { Input, Loading } from '../../components/index';
import PageHeader from '../base/PageHeader.js';
import { twoFaList, invalid_error_message, empty_error_message } from './config.js';
import { PanelStyled, TwoFactorContainer } from './TwoFactor.Styled';
import WithAlert from '../../utils/composition/withAlert';
import withLoading from '../../utils/composition/withLoading';
import { ALERT_TYPE } from '../../data/enums/AlertType';
import history from '../../utils/history';
import { JWT,LOGIN_SUCCESS_BASE_URL} from '../../environment';
import { LOGIN_SUCCESS_BASE_URL_TAS } from '../../environment/route';
import { userRoleForTASDashboard } from '../../views/login/config'
import {getUser} from '../../data/dao/user'

class TwoFactorAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = { qrToken: '', twoFactorQr: '', isLoading: false, errorMessage: null };
  }

  handleClose = () => {
    this.setState({ qrToken: '', errorMessage: null });
  };

  handleInputChange = e => {
    const value = e.target.value;

    if (!value) {
      this.setState({ qrToken: value, errorMessage: empty_error_message });
    } else {
      if (value.length > 6) {
        this.setState({ qrToken: value, errorMessage: invalid_error_message });
      } else {
        this.setState({ qrToken: value, errorMessage: null });
      }
    }
  };

  handleTwoFaSubmit = () => {
    const { qrToken } = this.state;
    const { verifyQrCode, displayAlert } = this.props;

    if (qrToken !== '') {
      if (qrToken.length > 6 || qrToken.length < 6) {
        this.setState({ qrToken, errorMessage: invalid_error_message });
      } else {
        verifyQrCode(
          {
            token: parseInt(qrToken),
          },
          {
            handleSuccess: response => {
              displayAlert(ALERT_TYPE.SUCCESS, response?.data?.verifyQrCode);
              localStorage.setItem('isTwoFaVerified',true);
              const userDetails = getUser();
              const isTASUser = Object.values(userRoleForTASDashboard).includes(userDetails.roleId);
              if(isTASUser ) {
                history.push(LOGIN_SUCCESS_BASE_URL_TAS) 
              }
              else{
                history.push(LOGIN_SUCCESS_BASE_URL) 
              }
            },
            handleError: error => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      }
    } else {
      this.setState({ qrToken: '', errorMessage: empty_error_message });
    }
  };

  handleLogout = e => {
    e.stopPropagation();
    const { logout, displayAlert } = this.props;
    logout(
      {},
      {
        handleSuccess: () => {
          history.push('/login');
          localStorage.clear();
          sessionStorage.clear();
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const { generateQrCode } = this.props;

      const User = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.APP_STORAGE.NAME));

      if (User && !User.googleAuthStatus) {
        generateQrCode(
          {
            data: '',
          },
          {
            handleSuccess: response => {
              const qrCode = response?.data?.generateQrCode;
              this.setState({ twoFactorQr: qrCode });
            },
            handleError: error => {
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { qrToken, twoFactorQr, isLoading, errorMessage } = this.state;

    return (
      <React.Fragment>
        {isLoading && <Loading title="Loading..." />}

        <PanelStyled>
          <PageHeader
            config={{
              title: twoFactorQr ? '2-Step Verification' : 'Two-Factor (2FA) Authentication',
              parentBtn: true,
            }}
            // eslint-disable-next-line react/no-children-prop
            children={<Button secondary small title="Log Out" onClick={this.handleLogout} />}
          />
        </PanelStyled>

        <TwoFactorContainer>
          {twoFactorQr ? (
            <div className="wrapper">
              <h1 className="title">You have enabled 2-Step verification using Google Authenticator App</h1>

              <ol className="list-container">
                {twoFaList.map(list => (
                  <li className="list-items" key={list.id}>
                    {list.title}
                  </li>
                ))}
              </ol>

              <div className="image-container">
                <img src={twoFactorQr} alt="two-factor-qr" className="two-fa-qr" />

                <Input
                  value={qrToken}
                  name="qrToken"
                  type="text"
                  labelContent="Enter Code"
                  placeholder="Enter Code"
                  onChange={this.handleInputChange}
                  className="qr-input"
                  argument={{ max: 6 }}
                  rule="isInt"
                  enableErrorDisplay={!!errorMessage}
                  enableValidation
                  errorMessage={errorMessage ? errorMessage : ''}
                  externalValidation={!!errorMessage}
                />
              </div>

              <div className="action-buttons">
                <Button title="Cancel" secondary onClick={this.handleClose} />
                <Button title="Continue" primary onClick={this.handleTwoFaSubmit} />
              </div>
            </div>
          ) : (
            <div className="enter-code-wrapper">
              <div className="header">
                <img src="/image/twoFactorSecurity.svg" alt="security" className="security-logo" />

                <div className="text-messages">
                  <h1 className="header-text">Enter the code from the Google Authenticator App.</h1>

                  <p className="reset-device">
                    To reset your device, please contact your administrator for further instructions.
                  </p>
                </div>
              </div>

              <div className="code-body">
                <Input
                  value={qrToken}
                  name="qrToken"
                  type="text"
                  labelContent="Enter Code"
                  placeholder="Enter Code"
                  onChange={this.handleInputChange}
                  className="qr-input"
                  argument={{ max: 6 }}
                  rule="isInt"
                  enableErrorDisplay={!!errorMessage}
                  enableValidation
                  errorMessage={errorMessage ? errorMessage : ''}
                  externalValidation={!!errorMessage}
                />

                <Button title="Verify" primary className="submit-button" onClick={this.handleTwoFaSubmit} />
              </div>
            </div>
          )}
        </TwoFactorContainer>
      </React.Fragment>
    );
  }
}

export default WithAlert()(withLoading(TwoFactorAuth));
