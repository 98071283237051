import styled from 'styled-components';

export const SKUGroupTypeStyled = styled.div`
  .single-date-picker {
    .filter-dropdown-card {
      top: 0;
      transform: translateY(calc(-100% - 8px));
    }
  }
`;
