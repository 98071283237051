import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { TableHeader, TableBody } from './tableConfig';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import ReceiptWrapper from './ReceiptWrapper';
import { Pagination } from '../../../../v4/components';
import { dataProps, paginationProps } from '../../../../data/enums/Component';

const propTypes = {
  data: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const ReceiptView = ({ ...props }) => {
  const { handleSliderOpen, tableConfig, pagination, onPageChange, data, onIconClick, permission } = props;
  const tablewithAction = [
    ...tableConfig,
    {
      id: 15,
      label: 'actionHolder',
      title: '',
      show: true,
    },
  ];
  return (
    <Fragment>
      <ReceiptWrapper>
        <div className='table-wrap'>
          <table>
            {TableHeader({ tableConfig: tablewithAction })}
            <tbody>
              {data.list.map((receipts, key) =>
                TableBody({
                  data: receipts,
                  handleSliderOpen,
                  tableConfig: tablewithAction,
                  index: key,
                  onIconClick,
                  permission,
                }),
              )}
            </tbody>
          </table>
        </div>
      </ReceiptWrapper>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};
ReceiptView.propTypes = propTypes;

ReceiptView.defaultProps = defaultProps;

const ReceiptViewWithErrorAndLoading = withErrorAndLoadingScreen(ReceiptView);

const ReceiptViewWithImageSlider = withImageSlider(ReceiptViewWithErrorAndLoading);

export default ReceiptViewWithImageSlider;
