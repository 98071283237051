import React from 'react';
import PropTypes from 'prop-types';
import StockAdjustmentChildView from './childView';
import { TableBody } from '../tableConfig';
import { AccordionTable } from '../../../../components/CollapsibleTable';
import ledger from '../../stock-ledger/detail/data';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func.isRequired,
  onIconClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: [],
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
};

const StockLedgerView = ({ ...props }) => {
  const { data, onRowClick, onIconClick, permission, stockAdjDetail } = props;

  return (
    <tbody>
      {
        <AccordionTable>
          {data.map((stockAdjustment) => (
            <tr
              key={stockAdjustment.skuId}
              trigger={TableBody({
                data: stockAdjustment,
                onRowClick,
                onIconClick,
                permission,
              })}
            >
              {StockAdjustmentChildView({ data: stockAdjDetail || [], onIconClick, permission })}
            </tr>
          ))}
        </AccordionTable>
      }
    </tbody>
  );
};

StockLedgerView.propTypes = propTypes;

StockLedgerView.defaultProps = defaultProps;

export default StockLedgerView;
