export const colSize = {
  xxl: 12,
  xl: 10,
  l: 8,
  md: 6,
  qtr: 4,
  tri: 3,
  sm: 2,
  t: 1,
};
