
const userStatusMapper = (data = {}) => ({
  active: data.active || 0,
  inactive: data.inactive || 0,
  leave: data.leave || 0,
});

const roleBasedUserStatus = (data = {}, roleId) => {
  const userStatus ={
    delivery :{
      orders:data?.userStatus?.delivery?.countOrders || 0,
      invoiced:data?.userStatus?.delivery?.countInvoiced || 0,
      returns:data?.userStatus?.delivery?.countReturn || 0,
    },
    calls :{
      lpc:data?.userStatus?.calls?.lpc || 0,
      bpc:data?.userStatus?.calls?.bpc || 0,
      // fskupc:data?.userStatus?.calls?.focusedskupercall || 0,
      // sbdpc:data?.userStatus?.calls?.sbdskupercall || 0,
    },
    tasUsers :{
      active:data?.userStatus?.tasUsers?.active || 0,
      inactive:data?.userStatus?.tasUsers?.inactive || 0,
      leave:data?.userStatus?.tasUsers?.leave || 0,
    },
  }
  return userStatus;
}

const userSpecificHeaderTitle = (user) => {
  switch (user) {
  case 'ap':
    return 'Allocated Point ' || user;
  case 'gp':
    return 'Golden Point ' || user;
  case 'SBD':
    return 'SBD % ' || user;
  case 'ach':
    return 'Ach %' || user;
  case 'target':
    return 'Target' || user;
  default:
    return user;
  }
};
const dataMapper = (data = {}, roleId=1) => {
  return ({
    sales: data.salesDetail || [],
    callage: data.callageDetail || [],
    user: { title: data.User ? `${data.User.firstName} ${data.User.lastName}` || '' : '' },
    monthlyDetail: data.monthlyDetail || {},
    brandDistributor:data.brandDistributor || [],
    sbdPoints:data.sbdPoints || {},
    userStatus: roleBasedUserStatus(data),
  } || {})};

const USER_ROLE={
  TAS: 18,
  TAS_TEAM_LEAD:14
}
const getDashboardVisibleComponent = (tas = true, visibility = false) => ({
  userDetail: tas || visibility,
  monthDetailView: tas || visibility,
  userStatus: tas ,
  userStateDetails :tas,
  callage: tas || visibility,
  sales: tas || visibility,
  tableDetail:  tas || visibility,
});

const isTASUser = (roleId) => {
  return  USER_ROLE.TAS === roleId;
};
const randomColor = () => {
  const symbols = 'ABCDEF0123456789';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += symbols[Math.floor(Math.random() * 16)];
  }
  return color;
};

const isTasTeamLead = (roleId) => {
  return  USER_ROLE.TAS_TEAM_LEAD === roleId;
}

const getUserWiseView = (roleId) => {
  let tas = false;
  tas = isTASUser(roleId);
  return getDashboardVisibleComponent(tas, !tas);
};
const MODULES = {
  TARGET_ACHIEVEMENT: 'TARGET_ACHIEVEMENT',
  CALLAGE: 'CALLAGE',
  SALES: 'SALES',
  PFD_SALES_TARGET: 'target',
  PRODUCTIVE_COVERAGE: 'productive_coverage',
};

export {
  dataMapper,
  roleBasedUserStatus,
  userStatusMapper,
  userSpecificHeaderTitle,
  MODULES,
  getUserWiseView,
  isTASUser,
  isTasTeamLead,
  randomColor,
};
