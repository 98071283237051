import gql from 'graphql-tag';
import { persistentClient as client } from '../../apiClient/apollo';
import { PERMISSION_OBJ } from '../enums/Permission';
import { JWT } from '../../environment';
// TODO: handle Error in Data Fetch

const query = gql`
  query getPermission {
    permission {
      SALES
      TARGET
      GRN
    }
  }
`;

const insertPermission = (data) => {
  client.then((bibek) =>
    bibek.writeQuery({
      query,
      data: {
        permission: data,
      },
    }),
  );
};

/** const permission = {
  SALES: {
    BILLING: {
      enabled: true,
      __typename: 'sales',
    },
    RETURN: { enabled: true },
    __typename: 'sales',
  },
  TARGET: {
    CREATE: true,
  },
  GRN: {
    APPROVAL: true,
    UPDATE: true,
  },
}; */

const getAppPermissionData = [
  {
    program: 'BU Settings',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Town',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Territory',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Route',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Outlet',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Outlet Recomendation',
    permission: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
  },
  {
    program: 'Channel/Category',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Sub-D',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Sub-D Create Users',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Sub-D Permissions',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Insert Vehicle Information',
    permission: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
  },
  {
    program: 'Sub-D Calendar',
    permission: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
  },
  {
    program: 'Create/Upload SKU',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Create Catalog',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
  {
    program: 'Create User Group',
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
  },
];

const getLoggedInUserPermission = () => {
  const userPermission = JSON.parse(localStorage.getItem(JWT.LOCAL_STORAGE.PERMISSION.NAME) || []);

  return userPermission || [];
};

const filterModuleWisePermission = (program) => {
  const userPermission = getLoggedInUserPermission();
  const permissionObj = userPermission.find((permission) => permission.program === program) || {
    permission: PERMISSION_OBJ,
  };

  return permissionObj ? permissionObj.permission || PERMISSION_OBJ : PERMISSION_OBJ;
};

const permission = {
  SALES: {
    BILLING: {
      enabled: true,
      __typename: 'salesBilling',
    },
    RETURN: {
      enabled: true,
      __typename: 'salesReturn',
    },
    __typename: 'sales',
  },
  GRN: {
    APPROVAL: true,
    RETURN: true,
    __typename: 'grn',
  },
  TARGET: {
    CREATE: true,
    __typename: 'target',
  },
  __typename: 'permission',
};

// const getPermission = () => client.readQuery({ query });
const getPermission = async () => client.then((bibek) => bibek.readQuery({ query })).then((response) => response);

// TODO value has been neglected over here.
const setPermission = async (value) => {
  insertPermission(permission);
};

export {
  setPermission, getPermission, getLoggedInUserPermission, filterModuleWisePermission
};
