import styled from 'styled-components';

const DetailsStyled = styled.div`
.grn-sku-update{
  border-top: 1px solid #e7e7ed;
  padding: 24px 8px 0 32px;
}
.grn-sku-update-total{
  padding: 24px;
}
.moment,
.zindex-2__control{
  background: #fafbfc;
  color: #030913;
}
  .enable-cursor td {
    cursor: pointer;
  }
  .campaign-form {
    .single-date-picker {
      margin-top: 0;
    }
    .form-select-input .zindex-2__control {
      background: #fafbfc;
      border: 1px solid #c1c7d0;
      height: 40px;
      min-width: 0;
      font-size: 1rem;
      line-height: 1.5;
      color: #030913;
      .zindex-2__single-value {
        font-weight: 400;
      }
    }
  }
  .sub-campaigns.config-panel-card .config-panel-contents {
    padding: 0;
  }
  .config-panel-contents {
    .modal-inner.delete .with-out-padding {
      padding: 24px;
    }
  }
  .update,
.create{
 .config-panel-card .config-panel-contents{
    padding:24px;
  }
  .delivery-form-wrap {
    display: flex;
    .form-select-input,
    .form-input {
      width: 94px;
      margin-top: 3.5px;
      .zindex-2__control,
      width:100%;
      input {
        border-radius: 0;
        border-right: 0;
        border-left: 0;
        padding: 0;
      }
      input{
        border-left: 1px solid #cdced9;
        padding:0 12px;
      }
      .zindex-2__control {
        height: 40px;
        border-right: 1px solid #cdced9;
        border-radius: 0 4px 4px 0;
        .zindex-2__indicator {
          padding: 4px;
        }
        .zindex-2__indicator-separator{
          display:none;
        }
      }
    }
    .single-date-picker {
      flex: 1;
      margin: 0;
      .moment {
        border-radius: 4px 0 0 4px;
        border-right: 0;
      }
    }
  }
  .product-table{
    .config-panel-title{
      border-bottom: 1px solid #E7E7ED;
    }
    .config-panel-contents{
      padding:0;
      .table-wrap{
        margin:0;
        overflow:auto;
        thead tr{
          background:#F1F2F5;
          height:40px;
          th{
            :first-child{
              padding-left:24px;
            }
            :last-child{
              padding-right:24px;
            }
          }
        }
        tbody tr{
          height:40px;
          td{
            :first-child{
              padding-left:24px;
            }
            :last-child{
              padding-right:24px;
            }
          }
          .header-dom{
            button{
              padding:0;
            }
          }
        }
      }
      .product-add{
        padding:24px;
      }
    }
  }
}
`;
export default DetailsStyled;
