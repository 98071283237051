import { Link, useLocation } from 'react-router-dom';
import BreadCrumbStyled, { BreadCrumbUlStyled } from './BreadCrumbStyled';

interface BreadCrumbProps {
  list: any[];
}

const BreadCrumb = ({ list = [] }: BreadCrumbProps) => {
  return (
    <BreadCrumbUlStyled>
      {list.map(item => (
        <BreadCrumbStyled key={item.id} className={item.active ? 'active' : ''}>
          <Link to={item.path}>{item.title}</Link>
        </BreadCrumbStyled>
      ))}
    </BreadCrumbUlStyled>
  );
};

export default BreadCrumb;
