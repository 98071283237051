import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';

const detailLabelMappings = {
  id: 'id',
  sku: 'sku',
  batch: 'batch',
  quantity: 'quantity',
  rate: 'rate',
  promotion: 'promotion',
  value: 'value',
  checkbox: 'checkbox',
  menu: 'menu',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.checkbox,
    title: '',
    show: false,
    action: false,
  },
  {
    id: 2,
    label: detailLabelMappings.id,
    title: 'Order ID',
    show: true,
  },
  {
    id: 3,
    label: detailLabelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 4,
    label: detailLabelMappings.batch,
    title: 'Batch',
    show: true,
  },
  {
    id: 5,
    label: detailLabelMappings.quantity,
    title: 'Quantity',
    align: true,
    show: true,
  },
  {
    id: 6,
    label: detailLabelMappings.rate,
    title: 'Rate',
    align: true,
    show: true,
  },
  {
    id: 7,
    label: detailLabelMappings.promotion,
    title: 'Promotion',
    classNames: 'text-center',
    show: true,
  },
  {
    id: 8,
    label: detailLabelMappings.value,
    title: 'Value',
    align: true,
    show: true,
  },
  {
    id: 9,
    label: detailLabelMappings.menu,
    title: '',
    align: true,
    show: false,
  },
];

const returnDetailTableBody = (
  data,
  index,
  checkBoxStatus,
  onSecondaryCheckBoxClick,
  promotionId,
  onIconClick,
  order,
) => {
  const table = {};
  table[detailLabelMappings.id] = <td key={`${data.id}-id`}>{data.id}</td>;
  table[detailLabelMappings.sku] = (
    <td key={`${data.id}-sku`}>
      {data.billDiscountSku && <span className='bill-discount-indicator' />}
      {data.freeSku && <span className='free-sku-indicator' />}
      {data.SKU ? data.SKU.title : ''}
      {data.focusedSku && <span className='focused-sku-indicator' />}
    </td>
  );
  table[detailLabelMappings.batch] = <td key={`${data.id}-batch`}>{data.SKUBatch ? data.SKUBatch.batchName : ''}</td>;
  table[detailLabelMappings.quantity] = (
    <td key={`${data.id}-quantity`} className='text-right'>
      {data.quantity}
    </td>
  );
  table[detailLabelMappings.rate] = (
    <td key={`${data.id}-rate`} className='text-right'>
      {data.rateDetails.rlp}
    </td>
  );
  table[detailLabelMappings.promotion] =
    index === 0 && promotionId ? (
      <td key={`${data.id}-promotion`} className='text-center'>
        <img
          src='/image/icons/Promotion.svg'
          style={{ cursor: 'pointer' }}
          onClick={() => onIconClick(EVENT_OPERATION.READ, order)}
        />
      </td>
    ) : (
      <td key={`${data.id}-promotion`} />
    );
  table[detailLabelMappings.value] = (
    <td key={`${data.id}-value`} className='text-right'>
      {fixedFloatAndCommas(data.amountDetails.subTotal)}
    </td>
  );
  return table;
};

const DetailTableBody = ({
  data,
  index,
  checkBoxStatus,
  onSecondaryCheckBoxClick,
  promotionId,
  onIconClick,
  order,
}) => {
  const tableBody = returnDetailTableBody(
    data,
    index,
    checkBoxStatus,
    onSecondaryCheckBoxClick,
    promotionId,
    onIconClick,
    order,
  );

  return <Fragment>{detailsLabelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};

DetailTableBody.propTypes = {
  onSecondaryCheckBoxClick: PropTypes.func,
  data: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  order: PropTypes.instanceOf(Object),
  checkBoxStatus: PropTypes.func,
  onIconClick: PropTypes.func,
  promotionId: PropTypes.number.isRequired,
};

DetailTableBody.defaultProps = {
  onSecondaryCheckBoxClick: () => null,
  data: {},
  checkBoxStatus: () => null,
  order: {},
  onIconClick: () => null,
};

export { DetailTableBody, detailsLabelConfig };
