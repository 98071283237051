import React from 'react';
import styled from 'styled-components';
import { fixedFloatAndCommas } from '../../../../../utils/conversion';

const OutstandingOverdue = ({ outStandingOverDue }) => {
  return <OutstandingOverdueContainer>
    <div className='outstanding-overdue'>
      <h3>Outstanding and Overdue</h3>
      <div className='call-history-details'>
        <div className='with-border'>
          <label>Credit Limit</label>
          <div className='call-status'>{outStandingOverDue?.creditLimit ? fixedFloatAndCommas(outStandingOverDue?.creditLimit) : '-'}</div>
        </div>
        <div className='with-border'>
          <label>Outstanding Amount</label>
          <div className='call-status'>{outStandingOverDue?.outStandingAmount ? fixedFloatAndCommas(outStandingOverDue.outStandingAmount) : '-'}</div>
        </div>
      </div>
      <div className='invoice-details with-border'>
        <div className='heading'>
          <label>Invoice ID</label>
          <label>Amount</label>
          <label>Days</label>
        </div>
        {outStandingOverDue && outStandingOverDue.RetailOutletLedgerBillDetails.length > 0 &&
          outStandingOverDue.RetailOutletLedgerBillDetails.map((bill, idx) => (
            idx < 5 ?
              <div key={bill.bill_number} className='invoice-details-row'>
                <div className='item-value'>{bill.bill_number}</div>
                <div className='item-value'>{fixedFloatAndCommas(bill.bill_outstanding_amount)}</div>
                <div className='item-value days'>{bill.credit_days}</div>
              </div>
              : null
          ))}
      </div>
    </div>
  </OutstandingOverdueContainer>;
};

const OutstandingOverdueContainer = styled.div`
  .call-history-details {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(40%, 1fr));
    row-gap: .5rem;
    column-gap: 1rem;
    margin-bottom: .5rem;

      label {
        text-transform: capitalize;
        display: block;
        color: #A7A9BC;
        font-size: 12px;
        margin-bottom: .5rem;
      }

      .call-status {
        font-weight: 500;
        font-size: 14px;
        padding-bottom: 4px;
      }

      .call-details {
        font-size: 12px;
      }
  }


  .invoice-details {
    .heading, .invoice-details-row {
      display: grid;
      grid-template-columns: minmax(40%, 3fr) 1fr .5fr;
      grid-gap: 1rem;

      .item-value {
        display: block;
        font-weight: 500;
        font-size: 14px;
        padding: .5rem 0;
        &.days{
          color:#DA1414;
          font-weight:600;
        }
      }
    }

    .heading label {
      text-transform: capitalize;
      display: inline-block;
      color: #A7A9BC;
      font-size: 12px;
      margin-bottom: .5rem;
    }
  }
`

export default OutstandingOverdue;
