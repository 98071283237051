import React from 'react';
import { Form, SingleDatePicker } from '../../../v4/components';
import { CustomSelect } from '../../../components';
import { getTodaysDate } from '../../../utils/date';
const OutletForm = (props) => {
  const { data, tasList, handleDropDownChange, handleDateChange, enableErrorDisplay, refsObj } = props;
  return (
    <Form>
      <div className='select-team'>
        <CustomSelect
          labelContent='TAS'
          placeholder='Select'
          name='user_id'
          getOptionLabel={({ title }) => title}
          getOptionValue={({ id }) => id}
          options={tasList}
          onChange={(e) => handleDropDownChange(e.id,['user_id'])}
          value={tasList.filter((d) => d.id === data.user_id)}
          enableValidation
          enableErrorDisplay={enableErrorDisplay}
          ref={(ref) => (refsObj.user_id = ref)}
        />
        <div className='single-date-picker'>
          <label>Date</label>
          <SingleDatePicker
            name='date'
            date={data.date || getTodaysDate()}
            onChange={(name, date) => handleDateChange(date, 'date')}
          />
        </div>
      </div>
    </Form>
  );
};

export default OutletForm;
