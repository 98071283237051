import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form,
} from '../../../../components/index';
import { CheckBox } from '../../../../v4/components'
import withLoading from '../../../../utils/composition/withLoading';
import { hyphenPresentor } from '../../../../utils/date';

const propTypes = {
  data: PropTypes.shape({
    usageDate: PropTypes.instanceOf(Object),
    priceDetails: PropTypes.instanceOf(Object),
    batchDetails: PropTypes.instanceOf(Object),
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: {
    usageDate: {
      batchNumber: '',
    },
    priceDetails: {
      rlp: 0,
      vatPercentage: 0,
    },
    batchDetails: {
      manufacture: hyphenPresentor(new Date().toDateString()),
      expiry: hyphenPresentor(new Date().toDateString()),
    },
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
};

const CreateSkuForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay,listMasterConfigurationData } = props;

  const srpValidation = listMasterConfigurationData.find(element => element.type==='SKU_SRP_CONFIG');
  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.rlp = ref)}
        value={data.priceDetails.rlp}
        name='rlp'
        type='number'
        decimalWithPrecedingZero
        placeholder='RLP'
        argument={{ min: 0 }}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event, 'priceDetails')}
        labelContent='RLP (exclusive of VAT)'
      />
      <CheckBox
        checked={data.priceDetails.mrpStatus}
        labelText='MRP'
        onCheckBoxClick={() =>
          handleInputChange(
            {
              target: {
                name: 'mrpStatus',
                value: !data.priceDetails.mrpStatus,
              },
              formattedValue: !data.priceDetails.mrpStatus,
            },
            'priceDetails',
          )
        }
      />
      <Input
        value={data.priceDetails.mrp}
        name='mrp'
        type='number'
        decimalWithPrecedingZero
        placeholder='MRP'
        argument={{ min: 0 }}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event, 'priceDetails')}
      />
      <Input
        ref={srpValidation?.configuration?.srpValidation?(ref)=>(refsObj.srp=ref):null}
        value={data.priceDetails.srp}
        name='srp'
        type='number'
        decimalWithPrecedingZero
        placeholder='SRP'
        rule = "isFloat"
        argument={srpValidation?.configuration?.srpValidation?{ min: 1 }:{min:0}}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event, 'priceDetails')}
        labelContent='SRP (exclusive of VAT)'
      />
      {srpValidation?.configuration?.enableB2BRate&&
      <Input
      value={data?.priceDetails?.b2bRate}
      name='b2bRate'
      type='number'
      rule = "isFloat"
      placeholder='B2B'
      enableErrorDisplay={enableErrorDisplay}
      enableValidation
      onChange={(event) => handleInputChange(event, 'priceDetails')}
      labelContent='B2B'
    />
      }
       
      {/* <Input
        ref={ref => refsObj.vatPercentage = ref}
        value={data.priceDetails.vatPercentage}
        name="vatPercentage"
        type="number"
        rule="isFloat"
        placeholder="VAT"
        argument={{ min: 0}}
        disabled={true}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={event => handleInputChange(event, 'priceDetails')}
        labelContent='VAT'
      /> */}
    </Form>
  );
};

CreateSkuForm.propTypes = propTypes;

CreateSkuForm.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withLoading(CreateSkuForm);

export default LoginViewWithErrorAndLoading;
