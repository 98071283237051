import { compose } from 'recompose';
import Details from './Details';
import { query, mutation, DOWNLOAD_REPORT } from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { TOGGLE_STATE } from '../../../common/GqlAPI';

const composedCatalogDetailList = compose(
  DOWNLOAD_REPORT,
  TOGGLE_STATE,
  withRequestTracker({ mutation, query }),
)(Details);

export default composedCatalogDetailList;
