import styled from 'styled-components';

const SystemAlertWrapper = styled.div`
.create-ui-inner {
  overflow-x: hidden;
  h3{
    width: 60%;
  }
}
.table-wrap{
  tbody{
    tr{
      cursor:pointer;
    }
  }
  td{
    .light{
      min-width: 72px;
      padding: 3px 4px;
      text-align:center;
      line-height:10px;
      font-size:10px;
      font-weight:600;
      white-space:nowrap;
      border-radius: 2px;
    }
    .complete{
        .light{
           border: 1px solid #47C464;
          color: #287D3C
        }
      }
      .active{
        .light{
          border: 1px solid rgba(46,90,172,0.4);
          color: #2E5AAC;
        }
      }
}
}
// Detail-style

.form-input-wrap{
  width: 60%;
  .form-input, .form-select-input{ 
   > label {
          width: calc(35.57% - 10px);
          padding-left: 4px;
      }
   }
   &.custom-date{
     display:flex;
    > label {
      width: calc(37.57% - 10px);
      font-size: 14px;
      line-height: 21px;
      color: #272833;
      font-weight: 500;
      margin-top: 10px;
      padding-left: 4px;
  }
    > div { 
      width: 75%;
      .datepicker-wrapper.date-picker-single { 
          .filter-item {
              width: 75%;
          }
      }
    }
    }
}
//radio-btn
.permission-choose{
  display: flex;
   > label{
         font-size: 14px;
         line-height: 21px;
         color: #272833;
         font-weight: 500;
         width: calc(20.77% - 4px);
         margin: 0;
         margin-right: 16px;
         margin-top: 2px;
         padding-left: 4px;
        
    }
    > div{
           margin-bottom: 24px;
           .radio-list{
            display: flex;
            margin-right: 24px;
            cursor: pointer;
            align-items: center;
              input{
                margin: 0px;
                height: 16px;
                width: 16px;
                cursor: pointer;
              }
              .text{
                font-weight: 500;
                line-height: 21px;
              }
           }
      }
}

.audience-wrap{
  padding-left: 4px;
}
.audience{
  .audience-info{
    display:flex;
    flex-direction: column;
    .audience-info-wrap{
      display:flex;
      .audience-info-card{
        width: 60%;
        background: white;
        border: 1px solid #E7E7ED;
        border-radius: 4px;
        margin: 20px 0px;
        padding: 0px 16px;

        .zindex-2__value-container{
          padding: 2px 0px 2px 8px;
        }
        
        .audience-medium{
          margin-top: 24px;
          padding-bottom: 15px;
          border-bottom: 1px solid #CDCED9;

          .checkbox{
            margin-right: 16px;
            font-size: 14px;

            >label {
              line-height: 16px;
              color: #272833;
              font-weight: 500;
            }

          }

          .form-error{
            text-align: left;
            padding-left: 98px;
          }
        }

        .suggested-autocomplete-list-wrap{
          max-height:250px;
          overflow-y: auto;
          padding-bottom:24px;
          }
            .autocomplete-list {
                > div {
                  margin-bottom: 5px;
                  cursor: pointer;
                  font-weight: 600;
                  color: #6b6c7e;
                  font-size: 14px;
                  transition: 0.2s linear;
                  
                  &:hover, &:focus {
                    color: #272833;
                  }
                  
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
              
              .suggested-autocomplete-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-top: 4px;
                .text {
                  padding: 8px 16px;
                  border-radius: 4px;
                  background: #e7e7ed;
                  border: 1px solid #e7e7ed;
                  font-size: 14px;
                  flex: 1;
                }   
                .ic-close {
                  cursor: pointer;
                  display: inline-block;
                  width: 12px;
                  height: 12px;
                  overflow: hidden;
                  text-indent: -999px;
                  background: url("/image/icons/ic_close.svg");
                  background-size: 12px;
                  margin-left: 18px;
                }
              }
      }
      .config-icon{
        margin-top: 16px;
        margin-left: 20px;
        cursor: pointer;
      }
  }
}
  button{
    margin-top:16px;
  }
  .form-error {
        width: 100%;
        text-align: right;
  }
}
.threshold-wrap{
  display: flex;
  > label{
    font-size: 14px;
    line-height: 21px;
    color: #272833;
    font-weight: 500;
    width: calc(110.77% - 10px);
    margin: 0;
    margin-right: 2px;
    margin-top: 10px;
    padding-left: 4px;
    }
    .form-select-input {
      width: 50%;
      padding-right: 10px;
      label {
        margin: 0px;
        width: 0px;
      }
    }
    .form-input{
      &:last-child {
        margin-bottom: 24px;
      }
    }
}

.reapeat__days{
  display:flex;
  width: 126%;
  > label {
        width: calc(17.57% - 7px);
        color: #272833;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        padding-left: 4px;
  }
  .checkbox-list {
    margin-bottom: 24px;
    margin-top: 8px;
    > div {
      > label{
        margin-right: 10px;
        font-weight : 600;
      }
    }
  }
}

.time {
  display : flex;
  width: 60%;
  margin-bottom: 32px;
  > label {
    width: calc(166.57% - 10px);
    color: #272833;
    font-size: 14px;
    font-weight: 500;
    margin-top: 13px;
    padding-left: 4px;
  }
  .form-input{
    width:50%
    margin-bottom: 0px;
  }
  .form-select-input{
    > label {
      display: none;
    }
    > div {
          width: 100%;
          margin-bottom: 0px;
    }
    }
    .time__hours{
        display:flex;
        flex-direction: column;
         width:100%;
       .form-input{
          width:calc(100% - 10px);
          margin-right: 10px;
          margin-bottom: 0px;
        }
        .form-select-input{
           margin-bottom: 0px;
           .zindex-2__value-container{
            padding: 2px 13px;
           }
        }
        > label {
          font-size: 10px;
          color: #A7A9BC;
          margin-top: 6px;
          margin-left: 16px;
          line-height: 10px;
        }
    }
}
`;

export default SystemAlertWrapper;
