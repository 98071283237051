import React from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components';
const PrintDialog = ({ data }) => {
  return (
    data?.map((a, index)=>(
      <PrintDialogStyle key={index}>
        <div className='qr-code'>
          <QRCode
            size={100}
            bgColor='white'
            fgColor='black'
            value={a?.binCode}
          />
        </div>
        <div className='info-section'>
          {a?.hierarchy?.map((list,listIndex)=>
            <>
              <div className='info-1'>
                <p className='level'>{list?.level?.toUpperCase()}</p>
                <p className={`indication ${listIndex === a?.hierarchy?.length - 1 ?'last-level':''}`}>{list?.indication}</p>
              </div>
              {listIndex !== a?.hierarchy?.length - 1 &&(
                <div className='seperator'>
            -
                </div>)}
            </>
          )}
        </div>
      </PrintDialogStyle>
    ))
  )
}

export default PrintDialog

const PrintDialogStyle = styled.div`
align-items: center;
border: 0.275px solid #000;
display: flex;
flex-direction: row;
justify-content: center;
border-radius: 2.2px;
.qr-code{
  padding: 16px;
  height: 138.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.275px solid #000;;
}
.info-section{
  display: flex;
  padding: 16px;
  align-items: center;
  align-self: stretch;
  p{
    margin: 0;
    font-weight: 600;
    text-align: center;
  }
  .level{
    margin-bottom: 4px;
    font-size: 7px;
    line-height: 150%;
    white-space: nowrap;
  }
  .indication{
    font-weight: 700;
    font-size: 48px;
    line-height: 125%;
    padding: 0 4px;
  }
  .last-level{
    background: #252525;
    border-radius: 2.2px;
    color: #FFFFFF;
  }
  .seperator{
    font-size: 48px;
    font-weight: 700;
    margin-top: 8px;
    align-items: center;
    align-content: center;
  }
}
`;
