import { FREQUENCY_TYPE } from '../../../../../../data/enums';
import { EVENT_OPERATION } from '../../../../../../v4/constants/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../v4/constants/successMessage';

export const Title= 'Replenishment Model';

export const crudSuccess = {
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: data.ids,
      type: 'Confirm',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.BULK_DELETE}`,
  },
};

export const frequencyType=[
  {
    label:'Daily',
    value:FREQUENCY_TYPE.DAILY,
  },{
    label:'Weekly',
    value:FREQUENCY_TYPE.WEEKLY,
  },{
    label:'Fortnightly',
    value:FREQUENCY_TYPE.FORTNIGHTLY,
  },{
    label:'Trimonthly',
    value:FREQUENCY_TYPE.TRIMONTHLY,
  },{
    label:'Monthly',
    value:FREQUENCY_TYPE.MONTHLY,
  },{
    label:'Custom',
    value:FREQUENCY_TYPE.CUSTOM,
  }
];
