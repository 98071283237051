import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DetailView from './View';
import PageHeader from '../../../base/PageHeader';
import * as queryService from '../../../base/query.service';
import { PanelStyled } from '../../../common/configuration';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import withAlert from '../../../../utils/composition/withAlert';
import { insertUserLabel } from '../../../../data/services/userLabel';
import { breadCrumbConfig } from './config';
import { isError } from '../../../common/HelperFunctions';
import AssortmentDetailStyled from './AssortmentDetailStyled';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getCategoryList: PropTypes.func.isRequired,
  getAssortmentCategoryCount: PropTypes.func.isRequired,
  getProductGroupDetails: PropTypes.func.isRequired,
  getAssortmentCountByProductHierarchy: PropTypes.func.isRequired,
  getAssortedSKUList: PropTypes.func.isRequired,
  toggleCategorySKUAssortment: PropTypes.func.isRequired,
  getSKUFamilyDetails: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.objectOf(Object).isRequired,
  getBrandDetails: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class Details extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    const params = props.match.params ? props.match.params : {};
    const { id } = params;
    this.state = {
      data: {
        categoryId: '',
        productCategoryId: '',
        brandId: '',
        skuId: '',
      },
      categoryList: [
        {
          title: '',
          categories: [],
        },
      ],
      id: parseInt(id, 10),
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
      },
      productCategoryList: [],
      brandList: [],
      filteredBrandList: [],
      skuFamilyList: [],
      count: {
        category: [],
        productCategories: [],
        brands: [],
        skuFamilies: [],
      },
      skuAssortedList: [],
      searchText: '',
      SKUFamilyList: [],
    };

    const { downloadReport } = this.props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      props.displayAlert,
    );
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    insertUserLabel();
    // this.loadTableData();
    this.getCategoryList();
    this.getProductGroupList();
    this.getBrandList();
  }

  getCategoryList = () => {
    const { id, count } = this.state;
    const { getCategoryList, getAssortmentCategoryCount } = this.props;

    getCategoryList(
      {
        filter: {
          filters: [{ column: 'id', value: [id.toString()] }],
        },
      },
      {
        handleSuccess: response => {
          const categoryList = (response.data.channels && response.data.channels.rows) || [];
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ categoryList });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );

    getAssortmentCategoryCount(
      {
        channelId: id,
      },
      {
        handleSuccess: response => {
          count.category =
            (response.data.categoriesAssortmentCount && response.data.categoriesAssortmentCount.rows) || [];
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(count);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getProductGroupList = () => {
    const { getProductGroupDetails } = this.props;
    getProductGroupDetails(
      {},
      {
        handleSuccess: response => {
          const productCategoryList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ productCategoryList });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getBrandList = () => {
    const { getBrandDetails } = this.props;
    getBrandDetails(
      {},
      {
        handleSuccess: response => {
          const brandList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ brandList });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getSKUFamilyList = id => {
    const { getSKUFamilyDetails } = this.props;
    getSKUFamilyDetails(
      {
        parentCatalogDetailId: id,
      },
      {
        handleSuccess: response => {
          const skuList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          const filteredSkuList = skuList.filter(i => i.skus && i.skus.length > 0);
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({
            skuFamilyList: filteredSkuList,
            SKUFamilyList: filteredSkuList,
          });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleListClick = id => {
    const { history } = this.props;
    history.push(`channel-assortment/details/${id}`);
  };

  handleCategoryChange = id => {
    const { data, count } = this.state;
    data.categoryId = id;
    const { getAssortmentCountByProductHierarchy, getAssortedSKUList } = this.props;
    getAssortmentCountByProductHierarchy(
      {
        categoryId: id,
      },
      {
        handleSuccess: response => {
          count.brands = response.data.productHierarchyAssortmentCount.brands;
          count.productCategories = response.data.productHierarchyAssortmentCount.productCategories;
          count.skuFamilies = response.data.productHierarchyAssortmentCount.skuFamilies;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(count);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
    getAssortedSKUList(
      {
        categoryId: id,
      },
      {
        handleSuccess: response => {
          const skuAssortedList =
            (response.data.appliedSKUSForCategoryAssortment && response.data.appliedSKUSForCategoryAssortment.rows) ||
            [];
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState({ skuAssortedList });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
    this.setState(data);
  };

  handleProductCategoryClick = groupId => {
    const { data, brandList } = this.state;
    data.productCategoryId = groupId;
    data.brandId = '';
    const newBrandList = brandList.filter(brand => parseInt(brand.parentCatalogDetailId, 10) === groupId);
    this.setState({ data, filteredBrandList: newBrandList });
  };

  handleBrandClick = brandId => {
    const { data } = this.state;
    data.brandId = brandId;
    this.getSKUFamilyList(brandId);
    this.setState({ data });
  };

  handleToggleState = (status, ids) => {
    const { toggleCategorySKUAssortment, displayAlert } = this.props;
    const { data, count, skuAssortedList } = this.state;
    toggleCategorySKUAssortment(
      {
        categoryId: data.categoryId,
        skuIds: ids,
        status,
      },
      {
        handleSuccess: response => {
          const skuList = response.data.createCategorySKUAssortment.rows;
          count.brands = response.data.createCategorySKUAssortment.count.brands;
          count.productCategories = response.data.createCategorySKUAssortment.count.productCategories;
          count.skuFamilies = response.data.createCategorySKUAssortment.count.skuFamilies;
          count.category = response.data.createCategorySKUAssortment.count.categories;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(
            {
              count,
            },
            () => {
              if (status) {
                const skusSet = new Set([...skuAssortedList, ...skuList]);
                this.setState({ skuAssortedList: [...(Array.from(skusSet) || [])] });
              } else {
                this.setState({ skuAssortedList: skuAssortedList.filter(id => !ids.includes(id)) });
              }
            },
          );
          displayAlert(ALERT_TYPE.SUCCESS, 'SKU Assortment updated successfully');
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  handleSearchInput = text => {
    const { SKUFamilyList } = this.state;
    const regex = new RegExp(text, 'i');
    const bySkus = SKUFamilyList.filter(i => i.skus.filter(s => s.title.search(regex) > -1).length > 0);
    const bySKUFamilies = SKUFamilyList.filter(p => p.title.search(regex) > -1);
    const union = [...bySKUFamilies, ...bySkus];
    const result = union.filter((item, index) => union.indexOf(item) === index);

    this.setState({ skuFamilyList: result, searchText: text });
  };

  render() {
    const {
      data,
      queryParameters,
      display,
      categoryList,
      productCategoryList,
      filteredBrandList,
      skuFamilyList,
      count,
      skuAssortedList,
      searchText,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <AssortmentDetailStyled>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: categoryList[0].title,
                create: false,
                download: false,
                filter: false,
                search: false,
              }}
              queryParameters={queryParameters}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <DetailView
            data={data}
            loading={serverResponseWaiting}
            onHandleClick={this.handleListClick}
            categoryList={categoryList[0].categories}
            onHandleCategoryChange={this.handleCategoryChange}
            productCategoryList={productCategoryList}
            onProductCategoryClick={this.handleProductCategoryClick}
            brandList={filteredBrandList}
            onBrandClick={this.handleBrandClick}
            SKUFamilyList={skuFamilyList}
            count={count}
            skuAssortedList={skuAssortedList}
            onToggle={this.handleToggleState}
            onHandleSearchInput={this.handleSearchInput}
            searchText={searchText}
          />
        </div>
      </AssortmentDetailStyled>
    );
  }
}

Details.propTypes = propTypes;

Details.defaultProps = defaultProps;

export default withAlert()(Details);
