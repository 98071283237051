import { compose } from 'recompose';
import LedgerInfo from './LedgerInfo';
import {
  TOGGLE_STATE, query, mutation
} from './API';
import withRequestTracker from '../../../../../../utils/composition/withRequestTracker';

const composeLedgerInfo = compose(
  TOGGLE_STATE,
  withRequestTracker({
    query,
    mutation,
  }),
)(LedgerInfo);

export default composeLedgerInfo;
