import { compose } from 'recompose';
import Detail from './Detail';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_USER, UPDATE_USER, TOGGLE_STATE, RESET_PASSWORD, REPLACE_USER,
  RESET_TWO_FACTOR_AUTH,
} from '../API';

const ComposedUserAccountDetail = compose(
  CREATE_USER,
  UPDATE_USER,
  TOGGLE_STATE,
  REPLACE_USER,
  RESET_PASSWORD,
  RESET_TWO_FACTOR_AUTH,
  withRequestTracker({
    query,
    mutation,
  }),
)(Detail);

export default ComposedUserAccountDetail;
