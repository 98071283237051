import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withAlert from '../../../utils/composition/withAlert';
import PresentationStyle from './PresentationStyle';
import View from './View';
import { PanelStyled } from '../../common/configuration';
import PageHeader from '../../base/PageHeader';
import { breadCrumbConfig, filterConfig, formConfig, title } from './config';
import * as queryService from '../../base/query.service';
import { isConfirmationType } from '../../common/HelperFunctions';
import Confirmation from '../../common/DialogConfirmation';
import { DialogFormWrapper } from '../../common';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { clone } from '../../../utils/objectProcessor';
import UploadWithInput from '../../components/uploadWithInput';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { CustomSelect, Input } from '../../../components';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { crudResponseProcessor } from '../../../utils/crudResponseProcessor';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { DOMAIN } from '../../../data/enums/config';
import { fetchFromLocalStorage, isError, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { newWindow } from '../../../utils/newWindow';
import { getPermissionForPresentation } from '../../base/permission';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  getBrandDetails: PropTypes.func.isRequired,
  toggleState: PropTypes.func.isRequired,
  getPresentation: PropTypes.func.isRequired,
  createPresentation: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};
class Presentation extends Component {
  constructor(props) {
    super(props);
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      dialog: {
        element: {},
        type: '',
      },
      brandList: [],
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
    };
    const { displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      displayAlert,
    );
    this.permission = getPermissionForPresentation();
  }

  componentDidMount() {
    this.getBrandList();
    this.getFilterStateFromStorage();
  }
  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.PRESENTATION,
    );
  };
  getFilterStateFromStorage = () => {
    const {queryParameters} =this.state;
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.PRESENTATION);
    if (!localData) {
      return this.loadTableData();
    }
    this.setState(
      {
        queryParameters: {
          ...queryParameters,
          filter: localData.filter,
          searchText: localData.searchText,
        },
        filterMenu:localData.filterMenu,
      },

      () => {
        this.loadTableData();
      },
    );
  };

  getBrandList = () => {
    const { getBrandDetails, displayAlert } = this.props;
    getBrandDetails(
      {},
      {
        handleSuccess: response => {
          const brandList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          this.setState({ brandList });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  loadTableData = () => {
    const { getPresentation, displayAlert } = this.props;
    const { data, queryParameters } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getPresentation(
      {
        limit: queryParameters.pagination.limit,
        offset,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = (response.data.getPresentation && response.data.getPresentation.rows) || [];
          data.total = (response.data.getPresentation && response.data.getPresentation.count) || 0;
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleIconClick = (type, element = {}) => {
    if (type === EVENT_OPERATION.DOWNLOAD) {
      this.handleDownloadClick(element);
    } else {
      this.setState({
        dialog: {
          type,
          element,
        },
      });
    }
  };

  handleFormSubmit = (type, dialogData) => {
    const { displayAlert, toggleState } = this.props;
    if (type === EVENT_OPERATION.UPLOAD) {
      const uploadData = this.getUploadDetails();

      const { fileServerUrl, fileType } = uploadData;
      if (fileServerUrl) {
        const input = {
          type: fileType.toLowerCase(),
          title: dialogData.title,
          brandId: dialogData.brandId,
          url: [{ url: fileServerUrl }],
        };
        this.uploadPresentation(input);
        this.resetDialog();
      } else {
        displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Select a File');
      }
    } else {
      toggleState(
        {
          ids: [dialogData.id],
          type: STATEFUL_ENTITIES.PRESENTATION,
          active: false,
        },
        {
          handleSuccess: () => {
            this.loadTableData();
          },
          handleError: err => {
            displayAlert(ALERT_TYPE.DANGER, err);
          },
        },
      );
    }
  };

  uploadPresentation = input => {
    const { createPresentation, displayAlert } = this.props;
    createPresentation(
      {
        input,
      },
      {
        handleSuccess: () => {
          this.loadTableData();
          displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.CREATE}`);
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  resetDialog = () => {
    this.setState({
      dialog: {
        type: '',
        element: '',
      },
    });
  };

  handleBulkDelete = (type, response) => {
    const { displayAlert } = this.props;
    const { data } = this.state;
    displayAlert(ALERT_TYPE.SUCCESS, `${title} ${MESSAGE_EVENT_OPERATION.DELETE}`);
    crudResponseProcessor(
      modifiedData => {
        this.setState({ data: modifiedData });
      },
      () => data,
    )(type)(response);
  };

  handleDownloadClick = element => {
    fetch(element.url.length > 0 && element.url[0].url, {
      method: 'GET',
      headers: {},
    })
      .then(res => {
        res.arrayBuffer().then(buffer => {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${element.title}.${element.type}`);
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const {
      data,
      queryParameters,
      dialog: { element, type },
      brandList,
      filterMenu,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <PresentationStyle>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              breadCrumb={breadCrumbConfig}
              config={{
                title,
                filter: true,
                search: true,
                upload: true,
              }}
              downloadConfig={{
                domain: 'PRODUCT_SALES_REPORT',
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleUploadClick={this.handleIconClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content table-present">
          {type && (
          <DialogFormWrapper
            formConfig={formConfig[type]}
            dialogElement={element}
            onDialogSubmit={this.handleFormSubmit}
            onDialogCancel={this.resetDialog}
            withOutPadding={type === EVENT_OPERATION.UPLOAD}
            type={type}
            disableDialogClose
            renderDialog={({
              refsObj,
              dialogData,
              enableErrorDisplay,
              handleDropDownChange,
              handleInputChange,
              handleFileUpload,
            }) => (
              <>
                {
                    type === EVENT_OPERATION.UPLOAD && (
                    <UploadWithInput
                      id="uploadCSV"
                      show
                      data={dialogData}
                      domain="presentation"
                      loading={serverResponseWaiting}
                      brandList={brandList}
                      onDropDownChange={handleDropDownChange}
                      onInputChange={handleInputChange}
                      handleFileUpload={handleFileUpload}
                      getDetails={childMethod => this.getUploadDetails = childMethod}
                      inputSection={(
                        <>
                          <Input
                            name="title"
                            type="text"
                            value={dialogData.title}
                            labelContent="Title"
                            placeholder="Title"
                            onChange={e => handleInputChange(e)}
                            enableValidation
                            ref={ref => (refsObj.title = ref)}
                            enableErrorDisplay={enableErrorDisplay}
                          />
                          <CustomSelect
                            name="brandId"
                            options={brandList}
                            value={brandList.filter(l => l.id === dialogData.brandId)}
                            labelContent="Brand"
                            getOptionLabel={({ title }) => title}
                            getOptionValue={({ id }) => id}
                            onChange={e => handleDropDownChange(e.id, ['brandId'])}
                            placeholder="Select Brand"
                            enableValidation
                            ref={ref => (refsObj.brandId = ref)}
                            enableErrorDisplay={enableErrorDisplay}
                          />
                        </>
                      )}
                    />
                  )}
                  {isConfirmationType(type) && Confirmation(type, 1)}
                </>
              )}
            />
          )}
          <View
            data={data}
            pagination={queryParameters.pagination}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
            onIconClick={this.handleIconClick}
            onBulkDelete={this.handleBulkDelete}
            permission={this.permission}
          />
        </div>
      </PresentationStyle>
    );
  }
}

Presentation.propTypes = propTypes;

Presentation.defaultProps = defaultProps;

export default withAlert()(Presentation);
