import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckboxWrapper } from './CheckBoxStyledIndex';

const propTypes = {
  /**
   * @default null
   * children passed within button
   */
  children: PropTypes.node,
  /**
   * weather checkbox is checked
   */
  checked: PropTypes.bool,
  /**
   * @default ''
   * children passed along with the button
   */
  className: PropTypes.string,
  /**
   * @default false
   * active state for checkbox.
   */
  active: PropTypes.bool,
  /**
   * @default false
   * disabled state for checkbox
   */
  disabled: PropTypes.bool,
  /**
   * @default false
   * multiple/intermediate state for checkbox
   */
  multiple: PropTypes.bool,
  name: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  className: '',
  active: false,
  disabled: false,
  multiple: false,
  checked: false,
  name: 'test',
};

const CheckBox = ({
  checked,
  className,
  active,
  disabled,
  multiple,
  labelText,
  onCheckBoxClick,
  name,
  ...otherProps
}) => {
  const classes = classNames(
    {
      active,
      disabled,
      multiple,
    },
    className,
  );

  useEffect(() => {
    if (document.getElementsByName(name)[0].parentNode.parentNode.parentElement.tagName === 'TH') {
      document.getElementsByName(name)[0].parentNode.parentNode.parentElement.classList.add('checks');
    }
  }, []);

  return (
    <Fragment>
      <CheckboxWrapper className={classes} {...otherProps}>
        <label>
          <input
            readOnly
            type='checkbox'
            name={name}
            checked={checked}
            onChange={(e) => onCheckBoxClick(e)}
            disabled={disabled}
          />
          <span className='control-label'>
            {labelText !== undefined ? <span className='custom-control-label-text'>{labelText}</span> : ''}
          </span>
        </label>
      </CheckboxWrapper>
    </Fragment>
  );
};

CheckBox.propTypes = propTypes;

CheckBox.defaultProps = defaultProps;

export default CheckBox;
