import PropTypes from 'prop-types';
import { tableData } from '../../../v4/components';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { headerLabelConfig, getUpdatedLabel } from '../../common/HelperFunctions';

const labelMappings = {
  salesTransactionDate: 'salesTransactionDate',
  distributor: 'distributor',
  route: 'route',
  outlet: 'outlet',
  channel: 'channel',
  category: 'category',
  salesInvoiceNumber: 'salesInvoiceNumber',
  dse: 'dse',
  sku: 'sku',
  brand: 'brand',
  skuFamily: 'skuFamily',
  rate: 'rate',
  salesQuantity: 'salesQuantity',
  salesAmount: 'salesAmount',
  salesReturnQuantity: 'salesReturnQuantity',
  salesReturnAmt: 'salesReturnAmt',
  salesReturnInvoiceNo: 'salesReturnInvoiceNo',
  salesReturnDate: 'salesReturnDate',
  netSalesQuantity: 'netSalesQuantity',
  netSalesAmt: 'netSalesAmt',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.salesTransactionDate,
    title: 'Sales Transaction Date',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.outlet,
    title: 'Outlet',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.channel,
    title: 'Channel',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.category,
    title: 'Category',
    show: true,
  },
  {
    id: 7,
    label: labelMappings.salesInvoiceNumber,
    title: 'Sales Invoice Number',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: true,
    isDynamic: true,
  },
  {
    id: 9,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 10,
    label: labelMappings.brand,
    title: 'Brand',
    show: true,
  },
  {
    id: 11,
    label: labelMappings.skuFamily,
    title: 'SKU Family',
    show: true,
  },
  {
    id: 12,
    label: labelMappings.rate,
    title: 'Rate',
    show: true,
  },
  {
    id: 13,
    label: labelMappings.salesQuantity,
    title: 'Sales Quantity',
    show: true,
  },
  {
    id: 14,
    label: labelMappings.salesAmount,
    title: 'Sales Amount',
    show: true,
  },
  {
    id: 15,
    label: labelMappings.salesReturnQuantity,
    title: 'Sales Return Quantity',
    show: true,
  },
  {
    id: 16,
    label: labelMappings.salesReturnAmt,
    title: 'Sales Return Amount',
    show: true,
  },
  {
    id: 17,
    label: labelMappings.salesReturnInvoiceNo,
    title: 'Sales Return Invoice No.',
    show: true,
  },
  {
    id: 18,
    label: labelMappings.salesReturnDate,
    title: 'Sales Return Date',
    show: true,
  },
  {
    id: 19,
    label: labelMappings.netSalesQuantity,
    title: 'Net Sales Quantity',
    show: true,
  },
  {
    id: 20,
    label: labelMappings.netSalesAmt,
    title: 'Net Sales Amount',
    show: true,
  },
];

const TableHeader = (dynamicHeader = false) => (
  <thead className="">
    <tr>
      {(dynamicHeader ? getUpdatedLabel(labelConfig) : labelConfig).map(label =>
        label.show ? tableData(label) : null,
      )}
    </tr>
  </thead>
);

const returnTableBody = data => {
  const table = {};

  table[labelMappings.salesTransactionDate] = (
    <td key={`${data.id}-salesTransactionDate`}>{data?.sales_transaction_date || '-'}</td>
  );
  table[labelMappings.distributor] = <td key={`${data.id}-distributor`}>{data?.distributor}</td>;
  table[labelMappings.route] = <td key={`${data.id}-route`}>{data?.route}</td>;
  table[labelMappings.outlet] = <td key={`${data.id}-outlet`}>{data?.outlet}</td>;
  table[labelMappings.channel] = <td key={`${data.id}-channel`}>{data?.channel}</td>;
  table[labelMappings.category] = <td key={`${data.id}-category`}>{data?.category}</td>;
  table[labelMappings.salesInvoiceNumber] = <td key={`${data.id}-salesInvoiceNumber`}>{data?.sales_invoice_number}</td>;
  table[labelMappings.dse] = <td key={`${data.id}-dse`}>{data?.dse}</td>;
  table[labelMappings.sku] = <td key={`${data.id}-sku`}>{data?.sku}</td>;
  table[labelMappings.brand] = <td key={`${data.id}-brand`}>{data?.brand}</td>;
  table[labelMappings.skuFamily] = <td key={`${data.id}-skuFamily`}>{data?.sku_family}</td>;
  table[labelMappings.rate] = <td key={`${data.id}-rate`}>{data?.rate}</td>;
  table[labelMappings.salesQuantity] = <td key={`${data.id}-salesQuantity`}>{data?.sales_quantity}</td>;
  table[labelMappings.salesAmount] = <td key={`${data.id}-salesAmount`}>{fixedFloatAndCommas(data?.sales_amount)}</td>;
  table[labelMappings.salesReturnQuantity] = (
    <td key={`${data.id}-salesReturnQuantity`}>{data?.sales_return_quantity}</td>
  );
  table[labelMappings.salesReturnAmt] = (
    <td key={`${data.id}-salesReturnAmt`}>{fixedFloatAndCommas(data?.sales_return_amount)}</td>
  );
  table[labelMappings.salesReturnInvoiceNo] = (
    <td key={`${data.id}-salesReturnInvoiceNo`} className="modal-wrap">
      <span class="tooltiptext">
        {data?.sales_return_invoice_number?.split(',')?.length > 1 ? (
          <>{data?.sales_return_invoice_number?.slice(0, 28)}...</>
        ) : (
          <> {data?.sales_return_invoice_number}</>
        )}
      </span>
      {data?.sales_return_invoice_number !== '-' && (
        <div className="tooltip-wrap">
          {data?.sales_return_invoice_number?.split(',')?.map(item => (
            <span>{item}</span>
          ))}
        </div>
      )}
    </td>
  );
  table[labelMappings.salesReturnDate] = <td key={`${data.id}-salesReturnDate`}>{data?.sales_returned_date || '-'}</td>;
  table[labelMappings.netSalesQuantity] = <td key={`${data.id}-netSalesQuantity`}>{data?.net_sales_quantity}</td>;
  table[labelMappings.netSalesAmt] = (
    <td key={`${data.id}-netSalesAmt`}>{fixedFloatAndCommas(data?.net_sales_amount)}</td>
  );

  return table;
};

const TableBody = ({ data, index }) => {
  const tableBody = returnTableBody(data);

  return <tr key={`psr-${index}`}>{labelConfig.map(label => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  index: PropTypes.number,
};

TableBody.defaultProps = {
  data: {},
  index: null,
};

export { TableHeader, TableBody };
