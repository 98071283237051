import { compose } from 'recompose';
import TargetAchievement from './TargetDetail';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query,
  mutation,
  CREATE_USER_TARGET,
  UPDATE_USER_TARGET,
  CREATE_EDIT_USER_TARGET,
  CREATE_EDIT_USER_BRAND_TARGET,
  DOWNLOAD_REPORT
} from './API';

const ComposedTargetDetails = compose(
  CREATE_USER_TARGET,
  UPDATE_USER_TARGET,
  CREATE_EDIT_USER_BRAND_TARGET,
  CREATE_EDIT_USER_TARGET,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(TargetAchievement);

export default ComposedTargetDetails;
