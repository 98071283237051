import styled from 'styled-components';

const SubCampaignStyled = styled.div`
  .selected-status .content-center{
    padding:14px 24px;
  }
.table-wrap{
  max-height:300px;
  overflow:auto;
  margin-top:16px;
  table{
    thead{
      tr{
        height:32px;
      th{
        background:unset;
        font-size:12px;
        font-weight:600;
        color:#6B6C7E;
        line-height:15px;
        padding:4px 8px;
        position:unset !important;
      }
      th:first-child{
        padding: 4px 8px 4px 24px;
      }
      th:last-child{
        padding: 4px 24px 4px 8px;
      }
      .control-label:after{
        height:16px;
        width:16px;
      }
      }
    }
    tbody{
      td{
        vertical-align: top;
        font-size:12px;
        line-height:15px;
        color:#272833;
        white-space:nowrap;
        padding-top:20px;
        &.disabled{
              cursor: not-allowed;
            }
        div{
          margin-bottom:10px;
          p{
          margin:0;
          font-size:8px;
          line-height:10px;
          color:#272833;
          white-space:nowrap;
        }
        }
      }
      td:first-child{
        padding:20px 12px 9px 24px;
      }
      td:last-child{
        padding: 10px 24px 9px 12px;
      }
      .control-label:after{
        height:16px;
        width:16px;
      }
    }
    .target{
      padding-right:5%;
    }
  }
}
.sub-campaign-add{
  padding:24px;
  button{
    height:40px;
    width:40px;
    line-height:0;
  }
}

/* Form Styling */
.modal-wrapper{
  .modal-inner{
    max-width:750px
  }
}
.body_wrap{
  height:unset;
  min-height: 10vh;
  max-height: 70vh;
  overflow:overlay;
}
.row-custom{
  padding:24px;
  border-bottom:1px solid #E7E7ED;
  padding-bottom:32px;
  &:last-child{
    border-bottom:0;
  }
  .custom-control-label-text{
    .form-select-input{
      &>label{
        color:#6B6C7E;
        font-weight:600;
      }
    }
    .r2 .zindex-2__option--is-selected{
      display:none;
    }
    .zindex-2__control{
      border:1px solid #E7E7ED;
    }
  }
  .custom-control-label-number{
    input{
      background-color:#F1F2F5;
      border:1px solid #E7E7ED;
    }
  }
  .single-date-picker{
    margin:0;
    &.end-date{
      .filter-dropdown-card{
        left:-70px;
      }
    }
  } 
}
.r3,
.r2{
  &>label{
    font-size:16px;
    font-weight:500;
    color:#272833;
    line-height:24px;
    margin-bottom:10px
    display: block;
  }
  .form-input-wrap{
    .list-content{
      display: flex;
    align-items: flex-start;
    margin-bottom:10px;
    position:relative;
    .custom-control-label-text{
      width: 192px;
    margin-right: 8px;
    &.select-value{
      width:105px;
      position:relative;
      .error-message{
        width: 120px;
        right: 7px;
      }
    }
    }
  .zindex-2__control .zindex-2__placeholder,  
  .zindex-2__control .zindex-2__single-value {
    font-size: 15px;
    }

    .error-message{
      position: absolute;
      top: 40px;
      right: 35px;
      font-size: 12px;
      width: 40%;
      text-align: right;
      line-height: 13px;
      svg{
        width:10px;
        height:10px;
      }
    }
    &>span{
      cursor:pointer;
      position:relative;
      top:8px;
    }
    .custom-control-label-number{
      flex:1;
      margin-right:18px;
    }
    }
}
  .form-select-input {
    position:relative;
    margin-bottom:0;
    label{
      display:none;
    }
  }
  .flex-wrap{
    display: flex;
    >div{
      &:first-child {
        margin-right: 10px;
        width: 105px;
        margin-bottom:0;
    }
    &:last-child {
      width: 225px;
}
    }
  }
  .form-input-wrap .list-content .target-select .error-message,
 .form-input-wrap .list-content .budget-select .error-message{
    display:block;
    width: 100%;
    right: 0;
  }
}

`;

export default SubCampaignStyled;
