import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { fixedFloatAndComma } from '../../../utils/conversion';

const labelMappings = {
  skuId: 'skuId',
  sku: 'sku',
  quantity: 'quantity',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.skuId,
    title: 'SKU ID',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 2,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
    sortable: true,
    className: 'sorting',
  },
  {
    id: 3,
    label: labelMappings.quantity,
    title: 'Quantity',
    show: true,
    sortable: true,
    className: 'sorting',
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
);

const returnTableBody = (data, onRowClick) => {
  const table = {};

  table[labelMappings.skuId] = (
    <td onClick={() => onRowClick(data.SKU && data.SKU.id)} key={`${data.id}-id`}>
      <span>{(data.SKU && data.SKU.id) || ''}</span>
    </td>
  );
  table[labelMappings.sku] = (
    <td onClick={() => onRowClick(data.SKU && data.SKU.id)} key={`${data.id}-sku`}>
      {data.SKU ? data.SKU.title : ''}
    </td>
  );
  table[labelMappings.quantity] = (
    <td onClick={() => onRowClick(data.SKU && data.SKU.id)} className='text-right' key={`${data.id}-quantity`}>
      {fixedFloatAndComma(data.balance)}
    </td>
  );

  return table;
};

const TableBody = ({ data, onRowClick }) => {
  const tableBody = returnTableBody(data, onRowClick);
  return (
    <Fragment key={`openingBalance-${data.id}`}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </Fragment>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeaderConfig as TableHeader, TableBody, labelConfig
};
