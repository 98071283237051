import styled from 'styled-components';

const OutletWrapStyled = styled.div`
  .filter-wrap {
    .filter-dropdown-card.filter {
      min-width: 500px;
      .filter-content {
        padding: 20px 16px 16px;
      }
    }
    .filter-dropdown-card {
      min-width: 166px;
      border-radius: 4px;
      .filter-content {
        padding: 0;
      }
      ul {
        padding: 8px 0;
        li {
          padding: 9px 16px;
          font-size: 14px;
          line-height: 21px;
          color: #272833;
          white-space: nowrap;
          cursor: pointer;
          text-transform: capitalize;
          span {
            margin-right: 18px;
            svg {
              color: #6b6c7e;
            }
          }
          :hover {
            background: #f0f5ff;
          }
        }
      }
    }
  }
  .table-wrap {
    tbody,
    thead {
      td,
      th {
        &:nth-last-child(2),
        &:last-child,
        &:nth-child(2) {
          width: unset;
          min-width: 30px;
          white-space: nowrap;
        }
      }
    }
  }
`;

export default OutletWrapStyled;
