const colors = [
  '#CFFF73',
  '#5FC8FF',
  '#50D2A0',
  '#835FFF',
  '#FFA2A2',
  '#FFFF00',
  '#6EAC42',
  '#FFB46E',
  '#D0D250',
  '#AF78FF',
  '#D9A51D',
  '#FF73C3',
  '#4B9BFF',
  '#812B8F',
  '#FFA500',
  '#00FF00',
];

export default colors;
