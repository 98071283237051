import { Sequence } from './Sequence';

export const BCPSequence = ({ ...props }) => {
  const { onInputChange, sequenceData, onSequenceChange, sequenceTitle } = props;
  return (
    <>
      <Sequence
        sequenceData={sequenceData}
        onInputChange={onInputChange}
        onSequenceChange={onSequenceChange}
        sequenceTitle={sequenceTitle}
      />
    </>
  );
};
