import React from 'react';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { sales as salesRoute } from '../../../../data/enums/Route';
import { FORM_CONFIG } from '../../../../data/enums/config';
import { refGenerator } from '../../../../utils';
import { fileParser } from '../../../../utils/parser';
import { UPLOADABLES } from '../../../../data/enums/GraphQL';
import { getFilterConfig } from '../../../../utils/filter';
import { FILTER } from '../../../../data/enums/filter';

const breadCrumb = [salesRoute];

const Title = 'Outlet';

const formConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    file: element.file || {},
  }),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${Title}`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const crudRequestConfig = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => ({
      title: data.title,
      channelType: data.channelType,
    }),
    responseName: 'createRetailOutlet',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      title: data.title,
      channelType: data.channelType,
    }),
    responseName: 'updateRetailOutlet',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: 'RetailOutlet',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: (data) => ({
      ids: [data.id],
      type: 'RetailOutlet',
      active: false,
    }),
    responseName: 'toggleState',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.DELETE}`,
  },
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.OUTLET,
        },
      }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
  [EVENT_OPERATION.UPLOADED]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.OUTLET_CATEGORY,
        },
      }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
  [EVENT_OPERATION.DEBOARD_OUTLET]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.DEBOARD_OUTLET,
        },
      }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${Title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

const OutletPermissionContext = React.createContext({});

const filter = getFilterConfig([FILTER.CHANNEL, FILTER.CATEGORY, FILTER.TOWN, FILTER.STATUS]);

const menuConfigList = [
  {
    title: 'Upload Outlet',
    icon: 'upload',
    permissionDerivedBy: 'create',
    type: EVENT_OPERATION.UPLOAD,
  },
  {
    title: 'Town/Category Change',
    icon: 'upload',
    permissionDerivedBy: 'create',
    type: EVENT_OPERATION.UPLOADED,
  },
  {
    title: 'Deboard Outlet',
    icon: 'upload',
    permissionDerivedBy: 'create',
    type: EVENT_OPERATION.DEBOARD_OUTLET,
  },
];

export {
  crudRequestConfig,
  breadCrumb as breadCrumbConfig,
  formConfig,
  filter as filterConfig,
  OutletPermissionContext,
  menuConfigList,
};
