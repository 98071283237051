import { compose } from 'recompose';
import Details from './Details';
import {
  query, mutation, CREATE_FOCUSED_SKUS, DELETE_FOCUSED_SKU
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedFocusedSKU = compose(
  CREATE_FOCUSED_SKUS,
  DELETE_FOCUSED_SKU,
  withRequestTracker({
    query,
    mutation,
  }),
)(Details);

export default composedFocusedSKU;
