import styled from 'styled-components';

const BrandDialogWrapper = styled.div`
  .single-date-picker {
    &.one {
      .filter-dropdown-card {
        top: -204px;
        left: 324px;
      }
    }
    &.two {
      .filter-dropdown-card {
        top: -237px;
        left: 337px;
      }
    }
    .modal-wrapper {
      .modal-inner {
        width: 580px;
        max-width: unset;
        .with-out-padding {
          padding: 0;
          &.evolve-dialog__body {
            padding-bottom: 24px;
            border-radius: 0 0 4px 4px;
            max-height: 70vh;
            overflow: auto;
            .detail-wrap {
              display: flex;
              .single-date-picker {
                pointer-events: none;
                width: 50%;
                margin: 24px;

                &:last-child {
                  margin-left: 0;
                }
              }
            }

            .month-wrap {
              pointer-events: none;
              width: 50%;
              padding: 24px;
              label {
                display: block;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 4px;

                + div {
                  width: 100%;
                  background: #f1f2f5;
                  border: 1px solid #e7e7ed;
                  border-radius: 4px;
                  padding: 0 16px;

                  > div {
                    margin: 0;
                    display: block;
                  }
                }
              }
              .month-view {
                position: relative;

                .month-icon {
                  position: absolute;
                  right: 0;
                  margin: 0;
                }

                .ic-dropdown {
                  display: none;
                }
              }
            }
            .form-input {
              padding: 24px;
              margin: 0;
              padding-left: 0px;
              label {
                font-size: 14px;
                line-height: 21px;
              }
              input {
                width: 262px;
                border: 1px solid #e7e7ed;
              }
            }
          }
          .table-wrap {
            /* height:400px;
            */
            height: 288px;
            overflow: auto;
            table {
              border-radius: 0 0 4px 4px;
              position: relative;
              tr {
                height: 40px;
                &:hover {
                  transition: unset;
                  border: 0;
                }
                &:hover td {
                  .form-input input:disabled,
                  .form-input input {
                    background: #f0f5ff;
                    border: 0;
                    transition: unset;
                  }
                }
                th,
                td {
                  width: 50%;
                  padding: 0;
                  &:first-child {
                    padding: 0 12px 0 24px;
                  }
                  &:last-child {
                    text-align: center;
                  }
                  .form-input {
                    padding: 4px;
                    margin: 0;
                    label {
                      font-size: 14px;
                      line-height: 21px;
                    }
                    input {
                      /* width: 172px; */
                      text-align: center;
                      transition: unset;
                      border: 0;
                      font-size: 14px;
                      position: relative;
                      left: 5px;
                      :disabled {
                        background: white;
                        color: #272833;
                        transition: unset;
                      }
                    }
                  }
                  th {
                    position: sticky;
                    top: 0;
                  }
                }
              }
            }
          }
        }
      }
      .modal-inner {
        &.create,
        &.read,
        &.update {
          /* width:361px; */
          width: 580px;
          /* height:580px; */
          .month-wrap {
            margin-bottom: 24px;
            label {
              display: block;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              margin-bottom: 4px;

              + div {
                width: 100%;
                background: #f1f2f5;
                border: 1px solid #e7e7ed;
                border-radius: 4px;
                padding: 0 16px;

                > div {
                  margin: 0;
                  display: block;
                }
              }
            }
            .month-view {
              position: relative;

              .month-icon {
                position: absolute;
                right: 0;
                margin: 0;
              }

              .ic-dropdown {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .targets-wrapper {
    .modal-wrapper {
      .modal-inner {
        &.create,
        &.read,
        &.update {
          width: 361px;
          /* width:580px; */
          /* height:580px; */

          .single-date-picker {
            margin-top: 0px;
          }

          .month-wrap {
            margin-bottom: 24px;
            label {
              display: block;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              margin-bottom: 4px;

              + div {
                width: 100%;
                background: #f1f2f5;
                border: 1px solid #e7e7ed;
                border-radius: 4px;
                padding: 0 16px;

                > div {
                  margin: 0;
                  display: block;
                }
              }
            }
            .month-view {
              position: relative;

              .month-icon {
                position: absolute;
                right: 0;
                margin: 0;
              }

              .ic-dropdown {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`;

export default BrandDialogWrapper;
