import PropTypes from 'prop-types';
import { CheckBox } from '../../../../v4/components';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { menuAction, tableData } from '../../../../v4/components';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

// todo update the configurations.
const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  frequency: 'frequency',
  noOfSku: 'noOfSku',
  assignedTo: 'assignedTo',
  actionHolders: 'actionHolders',
};

const menuConfigList = [
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: false,
  },
  {
    id: 2,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.frequency,
    title: 'Frequency',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.noOfSku,
    title: 'No. of SKUs',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.assignedTo,
    title: 'Assigned to',
    show: true,
  },
  {
    id: 6,
    label: labelMappings.actionHolders,
    title: '',
    show: false,
  },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td key={`${data.id}-checkbox`} onClick={(e) => e.stopPropagation()}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.title] = <td key={`${data.id}-title`}>{data.title}</td>;
  table[labelMappings.frequency] = <td key={`${data.id}-frequency`}>{data?.frequency_type}</td>;
  table[labelMappings.noOfSku] = <td key={`${data.id}-noOfSku`}>{data?.count_skus}</td>;
  table[labelMappings.assignedTo] = <td key={`${data.id}-assignedTo`}>{data?.assigned_to}</td>;
  table[labelMappings.actionHolders] = (
    <td key={`${data.id}-actions`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {(permission.delete) &&
        menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr key={data.id} onClick={() => onIconClick(EVENT_OPERATION.DETAILS, data)} className='cursor-pointer'>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) => (
  <thead className=''>
    <tr>
      {labelConfig.map((label) =>
        label.show
          ? label.label === labelMappings.checkbox
            ? dataListLength > 0 &&
              permission.delete && (
              <th>
                <CheckBox
                  checked={dataListLength === checkedListLength}
                  onCheckBoxClick={(e) => primaryCheckboxHandler(e)}
                />
              </th>
            )
            : tableData(label)
          : null,
      )}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
