import { useState } from 'react';
import { Icon } from '..';
import SelectPageStyled from './SelectPageStyled';

interface SelectPageProps {
  orientation?: string;
  onChange: (value: any) => void;
  list: any;
  name: string;
  showTotal: boolean;
  defaultValue: number;
  limit:number;
}

const SelectPage = ({ ...props }: SelectPageProps) => {
  const { list, onChange, orientation = 'bottom', name, showTotal, defaultValue, limit } = props;
  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleClick = (itemValue: any) => {
    setShowOptions(false);
    setValue(itemValue);

    onChange(itemValue);
  };

  return (
    <SelectPageStyled>
      <div
        tabIndex={0}
        className="custom-select-wrapper"
        onClick={() => setShowOptions(!showOptions)}
        onBlur={() => setShowOptions(false)}
      >
        {showTotal && (
          <>
            <div className={`custom-select ${showOptions ? 'pg-dropdown-active' : ''}`}>
              <div className="custom-select-option">
                <span className="pagination-display-label">
                  {name === 'pagination-select' ? 'Show' : ''} {limit}
                </span>
                <span
                  style={{
                    display: 'inline-block',
                    marginLeft: 10,
                  }}
                >
                  <Icon iconName="caret-bottom" />
                </span>
              </div>
            </div>

            {showOptions && (
              <ul className={orientation === 'top' ? 'custom-options-list-top' : 'custom-options-list-bottom'}>
                {list &&
                  list.map((each: any) => (
                    <li key={each.value} onClick={() => handleClick(each.value)}>
                      {each.label}
                    </li>
                  ))}
              </ul>
            )}
          </>
        )}
      </div>
    </SelectPageStyled>
  );
};

export default SelectPage;
