import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';

const orderFormatter = (order) => ({
  id: order.id,
  quantity: order.quantity,
});

const crudSuccess = {
  [EVENT_OPERATION.DISPATCHED]: {
    objectMapper: (invoice) => ({
      input: {
        invoiceId: invoice.invoiceId,
        orderDetails: invoice.orderDetails,
      },
    }),
    responseName: 'dispatchInvoice',
    message: 'Orders dispatched successfully.',
  },

  [EVENT_OPERATION.DELIVERED]: {
    objectMapper: (details) => ({
      input: {
        dispatchNumber: details.dispatchNumber,
        invoiceId: details.invoiceId,
      },
    }),
    responseName: 'deliverInvoice',
    message: 'Orders delivered successfully.',
  },
};

export { crudSuccess, orderFormatter };
