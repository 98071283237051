import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { normalPresentor } from '../../../../utils/date';

const tableHeaderConfig = [
  { title: 'S.No.' },
  { title: 'SKU' },
  { title: 'Batch' },
  { title: 'Stock Type' },
  {
    title: 'MFG Date',
    classNames: 'text-center',
  },
  {
    title: 'EXP Date',
    classNames: 'text-center',
  },
  {
    title: 'Quantity',
    classNames: 'text-right',
  },
  {
    title: 'Rate',
    classNames: 'text-right',
  },
  {
    title: 'Amount',
    classNames: 'text-right',
  },
  {
    title: 'Discount',
    classNames: 'text-right',
  },
  {
    title: 'Net Amount',
    classNames: 'text-right',
  },
];

const propTypes = {
  handleSliderOpen: PropTypes.func.isRequired,
  skuLines: PropTypes.arrayOf(Object),
};

const defaultProps = {
  skuLines: [],
};

const TableView = ({ skuLines }) => (
  <Fragment>
    <div className='table-wrap srn-details'>
      <table>
        <thead>
          <tr>
            {tableHeaderConfig.map((header, key) => (
              <th key={key} className={header.classNames || ''}>
                {header.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {skuLines.map((order, index) => (
            <tr key={order.id || index}>
              <td>{index + 1}</td>
              <td>{order.SKU.title}</td>
              <td>{order.SKUBatch ? order.SKUBatch.batchName : ''}</td>
              <td>{order.stockType}</td>
              <td className='text-center'>
                {order.SKUBatch
                  ? order.SKUBatch.manufactureDate
                    ? normalPresentor(order.SKUBatch.manufactureDate) === 'Invalid date'
                      ? '-'
                      : normalPresentor(order.SKUBatch.manufactureDate)
                    : null
                  : null}
              </td>
              <td className='text-center'>
                {order.SKUBatch
                  ? order.SKUBatch.expiryDate
                    ? normalPresentor(order.SKUBatch.expiryDate) === 'Invalid date'
                      ? '-'
                      : normalPresentor(order.SKUBatch.expiryDate)
                    : null
                  : null}
              </td>
              <td className='text-right'>{order.quantity}</td>
              <td className='text-right'>{order.amountDetails ? order.amountDetails.rate : 0}</td>
              <td className='text-right'>
                {fixedFloatAndCommas(order.amountDetails ? order.amountDetails.rate * order.quantity : 0)}
              </td>
              <td className='text-right'>
                {fixedFloatAndCommas(
                  order.amountDetails
                    ? order.amountDetails.promotionDiscount + (order.amountDetails.topUpDiscount || 0)
                    : 0,
                )}
              </td>
              <td className='text-right'>
                {fixedFloatAndCommas(order.amountDetails ? order.amountDetails.subTotal : 0)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Fragment>
);

TableView.propTypes = propTypes;

TableView.defaultProps = defaultProps;

const TableViewWithErrorAndLoading = withErrorAndLoadingScreen(TableView);
export default TableViewWithErrorAndLoading;
