import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { tableData, Icon } from '../../../v4/components';
import { normalPresentor } from '../../../utils/date';

// todo update the configurations.
const labelMappings = {
  id: 'id',
  title: 'title',
  startDate: 'startDate',
  endDate: 'endDate',
  unAllocated: 'unAllocated',
  actionHolders: 'actionHolders',
};

const returnTableBody = (data, handleIconClick) => {
  const table = {};
  table[labelMappings.title] = <td key={`${data.id}-monthName`}>{`${data.title}`}</td>;

  table[labelMappings.startDate] = <td key={`${data.id}-monthName`}>{`${normalPresentor(data.start_date)}`}</td>;

  table[labelMappings.endDate] = <td key={`${data.id}-monthName`}>{`${normalPresentor(data.end_date)}`}</td>;

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.startDate,
    title: 'Start Date',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.endDate,
    title: 'End Date',
    show: true,
  },
];

const TableBody = ({ data, onTableBodyClick, handleIconClick }) => {
  const tableBody = returnTableBody(data, handleIconClick);

  return (
    <tr key={data.id} className="cursor-pointer" onClick={() => onTableBodyClick(data)}>
      {labelConfig.map(label => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeader = () => (
  <thead className="">
    <tr>{labelConfig.map(label => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

TableBody.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
  onTableBodyClick: PropTypes.func.isRequired,
  handleIconClick: PropTypes.func.isRequired,
};

export { TableHeader, TableBody };
