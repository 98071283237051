import React from 'react';
import styled from 'styled-components';
import { getUserRole } from '../../../../../data/dao';
import { USER_ROLE } from '../../../../../data/enums/UserRole';
import { OUTLET_DETAILS } from '../../../../../data/enums/Route';
import { parseDateByFormat, timeAgo } from '../../../../../utils/date';
import { Button } from '../../../../../v4/components';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { fixedFloatAndComma } from '../../../../../utils/conversion';

const CallHistory = ({ outletBasic, callHistory, ULPBLP, ...props }) => {
  const userRole = getUserRole();
  const isAllowedUser = [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.TAS, USER_ROLE.VISIBILITY_TEAM_LEADER].includes(userRole);

  const lastSuccessfulCallTime = callHistory?.lastSuccessfulCall?.callTime;
  const lastSuccessfulCallDate = callHistory?.lastSuccessfulCall?.callDate;

  const lastCallType = callHistory?.lastCall?.callType;
  const lastCallDate = callHistory?.lastCall?.callDate;
  const lastCallTime = callHistory?.lastCall?.callTime;
  const lastCallNote = callHistory?.lastCall?.callNote;

  const lastCallStatus = lastCallType && typeof lastCallType === 'string' && lastCallType.toLowerCase();
  const lastCallIsSuccessful = lastCallStatus === 'successful';
  const redirect = (label) => {
    if (!isAllowedUser) {
      const { displayAlert } = props;

      return displayAlert(ALERT_TYPE.WARNING, 'You are not authorized!');
    }

    const tabValue = label === 'calls' ? 1 : 2;
    const win = window.open(`/${OUTLET_DETAILS}/${outletBasic?.id}`);
    localStorage.setItem('outletActiveTab', tabValue);
    win.focus();
  }

  return <CallHistoryContainer>
    <h3>Call History</h3>
    <div className='call-history-details'>
      <div className='with-border'>
        <label>Last Successful</label>
        <div className={`call-status${callHistory?.lastSuccessfulCall ? ' successful' : ''}`}>{callHistory?.lastSuccessfulCall ? timeAgo(`${lastSuccessfulCallDate} ${lastSuccessfulCallTime}`) : '-'}</div>
        {lastSuccessfulCallTime && lastSuccessfulCallDate ?
          <div className='call-details'>{`${parseDateByFormat(lastSuccessfulCallDate, 'MMM DD, YYYY')} / ${lastSuccessfulCallTime}`}</div>
          : null
        }
      </div>
      <div className='with-border'>
        <label>Last Call - {lastCallDate ? timeAgo(`${lastCallDate} ${lastCallTime}`) : null} </label>
        <div className={`call-status${lastCallIsSuccessful ? ' successful' : ' unsuccessful'}`}>{lastCallStatus}</div>
        <div className='call-details'>{lastCallNote}</div>
      </div>
      <div className='with-border'>
        <label>ULPO</label>
        <div className='call-status'>{ULPBLP && ULPBLP.ulpo}</div>
      </div>
      <div className='with-border'>
        <label>UBPO</label>
        <div className='call-status'>{ULPBLP && ULPBLP.ubpo}</div>
      </div>
    </div>
    <div  className ='last-successful-date'>

      {callHistory?.lastThereeSuccessfulCall.map((data,index) =>
        (<div className='wrap'>
          <div className='action-button'>
            {index=== 0 && <label>Last Successful Call Date</label>}
            <p>{parseDateByFormat(data?.lastsuccessfulcalldate, 'MMM DD, YYYY')}</p>
          </div>
          <div className='action-button'>
            {index=== 0 && <label>Amount</label>}
            <p>{fixedFloatAndComma(data?.amount)}</p>
          </div>
          <div className='action-button'>
            {index=== 0 && <label>Status</label>}
            <p>{data?.state}</p>
          </div>
        </div>
        ))}
    </div>
    <div className='actions with-border'>
      <div className='action-button'>
        <label>Calls</label>
        <Button iconName='time' onClick={() => redirect('calls')} />
      </div>
      <div className='action-button'>
        <label>Orders</label>
        <Button iconName='clipboard-list' onClick={() => redirect('orders')} />
      </div>
      <div className='action-button'>
        <label>Plans</label>
        <Button iconName='diary' disabled />
      </div>
    </div>
  </CallHistoryContainer>;
};

const CallHistoryContainer = styled.div`
.last-successful-date{
    border: 1px solid #E7E7ED;
    border-radius: 8px;
    margin: 16px 0;
    padding: 16px;
.wrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .action-button{
    flex:1;
    text-align:left;
    &:first-child{
      flex:unset;
      flex-basis: 150px;
    }
    label{
      font-size:12px;
      color:#6B6C7E;
      margin-bottom:7px;
    }
    p{
      margin:0;
      font-size:14px;
      color:#272833;
      margin-top:8px;
    }
  }
}
}

    .call-history-details {
      display: grid;
      grid-template-columns: repeat(auto-fill,minmax(40%, 1fr));
      row-gap: .5rem;
      column-gap: 1rem;
      margin-bottom: .5rem;

        label {
          text-transform: capitalize;
          display: block;
          color: #A7A9BC;
          font-size: 12px;
          margin-bottom: .5rem;
        }

        .call-status {
          text-transform: capitalize;
          font-weight: 500;
          font-size: 14px;
          padding-bottom: 4px;

          &.successful {
            color: #287D3C;
          }

          &.unsuccessful {
            color: #DA1414;
          }
        }

        .call-details {
          font-size: 12px;
        }
    }

    .actions{
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: center;

      .action-button {
        label {
          text-transform: capitalize;
          display: block;
          color: #A7A9BC;
          font-size: 12px;
          margin-bottom: .5rem;
          text-align: center;
        }

        button {
          padding: 0;
          border: 1px solid #E7E7ED;
          border-radius: 50%;

          .icon {
            height: 42px;
            width: 42px;
            padding: 12px;
            color: #0B5FFF;
          }
        }
      }
    }
`;

export default CallHistory;
