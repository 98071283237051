import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { PROGRAM } from '../../../../../data/enums/Permission';
import withErrorAndLoadingScreen from '../../../../../utils/composition/withLoading';
import { Pagination } from '../../../../../v4/components';
import PageHeader from '../../../../base/PageHeader';
import { DialogFormWrapper } from '../../../../common';
import { PanelStyled } from '../../../../common/configuration';
import { breadCrumbConfig } from '../../userAccount/config';
import { useManageUsersController } from '../Controllers/useManageUsers.controller';
import { formConfig } from './config';
import { ManageUserStyled } from './ManageUsers.style';
import { TableBody, TableHeader } from './tableConfig';

const ManageUsers = ({ ...props }) => {
  const {
    handleIconClick,
    dialog,
    onDialogSubmit,
    onCloseDialog,
    filterMenu,
    handleFilterChange,
    handleSearchInputChange,
    queryString,
    handleTableSorting,
    handlePageChange,
    sort,
    handleDownloadClick,
    pagination,
    users,
    totalCount,
    permission,
  } = useManageUsersController({ ...props });

  return (
    <ManageUserStyled>
      {(dialog.type === EVENT_OPERATION.ACTIVATE || dialog.type === EVENT_OPERATION.SUSPEND) && (
        <DialogFormWrapper
          type={dialog.type}
          dialogElement={dialog.element}
          onDialogSubmit={onDialogSubmit}
          formConfig={formConfig[dialog?.type]}
          onDialogCancel={onCloseDialog}
          activeStatus
          renderDialog={({}) => (
            <>
              Are you sure you want to {dialog?.type?.toLocaleLowerCase()} <b> {dialog.element.name}</b>?
            </>
          )}
        />
      )}
      <PanelStyled>
        <PageHeader
          breadCrumb={breadCrumbConfig}
          config={{
            title: PROGRAM.MANAGE_USERS,
            create: false,
            download: true,
            filter: true,
            search: true,
          }}
          downloadConfig={{ domain: ['USER_MANAGEMENT_REPORT'] }}
          filter={{
            menuList: filterMenu,
            onFilterChange: handleFilterChange,
          }}
          handleDownloadClick={handleDownloadClick}
          queryParameters={{ search: queryString }}
          handleSearchChange={handleSearchInputChange}
        />
      </PanelStyled>
      <div className="section-content table-present ">
        <div className=" table-wrap ">
          <table>
            {TableHeader(handleTableSorting, sort)}
            <tbody>
              {users?.map(user =>
                TableBody({
                  data: user,
                  handleIconClick,
                  permission,
                }),
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={pagination?.page}
          limit={pagination?.limit}
          totalItems={totalCount}
          onPageChange={handlePageChange}
        />
      </div>
    </ManageUserStyled>
  );
};
const viewWithErrorAndLoading = withErrorAndLoadingScreen(ManageUsers);
export default viewWithErrorAndLoading;
