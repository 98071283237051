import { compose } from 'recompose';
import Route from './Route';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { UPLOAD } from '../../common/GqlAPI';

import {
  query, mutation, CREATE_ROUTE, UPDATE_ROUTE, DOWNLOAD_REPORT
} from './API';

const ComposedRoute = compose(
  CREATE_ROUTE,
  UPDATE_ROUTE,
  DOWNLOAD_REPORT,
  UPLOAD,
  withRequestTracker({
    query,
    mutation,
  }),
)(Route);

export default ComposedRoute;
