import { compose } from 'recompose';
import { query, mutation } from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import Return from './Return';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const ComposedReturn = compose(
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(Return);

export default ComposedReturn;
