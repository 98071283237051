import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ImageColumn } from '../../../../../utils/tableUtils';

const detailLabelMappings = {
  sku: 'sku',
  quantity: 'quantity',
  type: 'reason',
  images: 'images',
  replacedQuantity: 'replacedQuantity',
  replacedSku: 'replacedSku',
  checkbox: 'checkbox',
  menu: 'menu',
};

const detailsLabelConfig = [
  {
    id: 1,
    label: detailLabelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 2,
    label: detailLabelMappings.quantity,
    title: 'Quantity',
    align: true,
    show: true,
  },
  {
    id: 3,
    label: detailLabelMappings.type,
    title: 'Type',
    show: true,
  },
  {
    id: 6,
    label: detailLabelMappings.images,
    title: 'Images',
    show: true,
  },
  {
    id: 7,
    label: detailLabelMappings.replacedQuantity,
    title: 'Replaced Quantity',
    align: true,
    show: true,
  },
  {
    id: 9,
    label: detailLabelMappings.replacedSku,
    title: 'Replaced With',
    show: true,
  },
];

const returnDetailTableBody = (data, handleSliderOpen) => {
  const table = {};
  table[detailLabelMappings.sku] = <td key={`${data.id}-sku`}>{data.sku}</td>;
  table[detailLabelMappings.quantity] = (
    <td key={`${data.id}-quantity`} className='text-right'>
      {data.quantity}
    </td>
  );
  table[detailLabelMappings.type] = <td key={`${data.id}-type`}>{data.reason}</td>;
  table[detailLabelMappings.images] = (
    <ImageColumn
      keyTitle={`rp-image-${data.id}`}
      imageList={data.images}
      onImageClick={handleSliderOpen}
      key={`rp-image-${data.id}`}
    />
  );
  table[detailLabelMappings.replacedQuantity] = (
    <td key={`${data.id}-replaced`} className='text-right'>
      {data.replacedQuantity}
    </td>
  );
  table[detailLabelMappings.replacedSku] = <td key={`${data.id}-replacedSku`}>{data.replacedSku}</td>;
  return table;
};

const DetailTableBody = ({ data, handleSliderOpen }) => {
  const tableBody = returnDetailTableBody(data, handleSliderOpen);

  return <Fragment>{detailsLabelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</Fragment>;
};
DetailTableBody.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
};
export { DetailTableBody as ReplacedDetailTableBody, detailsLabelConfig as replacedDetailsLabelConfig };
export { DetailTableBody, detailsLabelConfig };
