import EVENT_OPERATION from "../../../../data/enums/EventOperation";

const productHierarchyCountMapper = (element = {}) => ({
  brands: element.brands || [],
  skuFamilies: element.skuFamilies || [],
  productGroups: element.productGroups || element.productCategories || [],
});

const crudRequestConfig = {
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: ({ icoGroupId, skuIdList, status }) => ({ icoGroupId, skuIdList, status }),
    responseName: 'toggleSkuInICOGroupDetail',
    message: 'ICO Group updated successfully',
  },
};
export { crudRequestConfig };

export { productHierarchyCountMapper };
