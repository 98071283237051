import styled from 'styled-components';

const CTAccordionStyled = styled.div`
table{
tr{
  transition: all 0.4s ease-in;
  &.active{
     height:64px;
       transition: all 0.4s ease-out;
   }

   &.collapsible-body-tr{
tbody tr{
  border-bottom:1px solid #F1F2F5;
  height:48px;
  td,
th{
padding: 7px 16px;
width:auto;
white-space:nowrap;
&:first-child{
padding: 7px 12px 7px 48px;
}
&:last-child{
  padding: 7px 48px 7px 16px;
}
}
  &:hover{
    background-color:#F7F8F9;
}
&:first-child,
&:last-child{
&:hover{
  background-color:unset;
}
}
&:last-child{
height:65px;
}
    }
}
 }
}
}

  `;

export default CTAccordionStyled;
