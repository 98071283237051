import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_MASTER_DATA, TOGGLE_STATE } from '../../../../common/GqlAPI';

export const getSkuGroupApi = gql`
  query SkuGroupMasters($limit: Int, $offset: Int, $filter: FilterInput) {
    skuGroupMasters(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        title
        alias
        active
      }
      count
    }
  }
`;

const createSKUGroupMaster = graphql(
  gql`
    mutation createSKUGroupMaster($input: SKUGroupMasterInput!) {
      createSKUGroupMaster(input: $input) {
        id
        title
        alias
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createSKUGroupMaster: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const updateSKUGroupMaster = graphql(
  gql`
    mutation updateSKUGroupMaster($input: SKUGroupMasterInput!, $id: Int) {
      updateSKUGroupMaster(input: $input, id: $id) {
        id
        title
        alias
        active
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateSKUGroupMaster: variables =>
        mutate({
          variables,
        }),
    }),
  },
);


const query = {
  getSkuGroupApi,
  getMasterData: GET_MASTER_DATA,
};

const mutation = {
  createSKUGroupMaster,
  updateSKUGroupMaster,
  toggleState: TOGGLE_STATE,
};

export { query, mutation, TOGGLE_STATE, createSKUGroupMaster, updateSKUGroupMaster };
