import React from 'react';
import { PropTypes } from 'prop-types';
import RouteView from './RouteView';
import LeaveView from './LeaveView';
import {
  getWeekDay, getDayFromDate, checkIfToday, getMonth, checkIfTodayOrGreater
} from '../date';
import { DialogContext } from '../config';
import { Button } from '../../../../v4/components';
import { CALENDAR_PREVIEW } from '../../../../data/enums';
import HolidayView from './HolidayView';

const propTypes = {
  date: PropTypes.string.isRequired,
  activeMonth: PropTypes.number,
  weekEndList: PropTypes.instanceOf(Array),
  event: PropTypes.shape({
    routeList: PropTypes.instanceOf(Array),
    leave: PropTypes.shape({
      status: PropTypes.string,
    }),
    holiday: PropTypes.instanceOf(Array),
  }),
  dseSelected: PropTypes.bool,
};

const defaultProps = {
  event: {
    routeList: [],
    leave: {
      status: 'PENDING',
    },
    holiday: [],
  },
  activeMonth: 1,
  weekEndList: [],
  dseSelected: false,
};

const classNameImporter = (date, activeMonth, holiday, weekEndList = [], { displayDialog, activeDate }) => {
  const weekDay = getWeekDay(date);

  const nonActiveMonthBackGround = activeMonth !== getMonth(date) ? 'prev-next' : '';

  const holidayBackground = holiday ? 'holiday' : '';

  const weekEndBackground = weekEndList.includes(weekDay) ? 'weekend' : '';

  const activeBorder = date === activeDate && displayDialog ? 'active-border' : '';

  return `calendar-day ${holidayBackground} ${weekEndBackground} ${nonActiveMonthBackGround} ${activeBorder}`;
};

const classNameImporterForDate = (datePram) => (checkIfToday(datePram) ? 'calendar-date active' : 'calendar-date');

const getButtonClassName = (date, { displayDialog, activeDate }) => {
  const active = date === activeDate && displayDialog;

  return active ? 'active-button' : '';
};

const displayAddButton = (dseSelected, date) => checkIfTodayOrGreater(date) && dseSelected;

function MonthlyUnit({
  date,
  activeMonth,
  weekEndList,
  dseSelected,
  event = {
    routeList: [],
    leave: null,
    holiday: null,
  },
  callRosterPermission
}) {
  return (
    <DialogContext.Consumer>
      {(value) => (
        <div
          className={classNameImporter(date, activeMonth, event.holiday, weekEndList, {
            activeDate: value.activeDate,
            displayDialog: value.displayDialog,
          })}
        >
          <span className={classNameImporterForDate(date)}>{getDayFromDate(date)}</span>
          <HolidayView holiday={event.holiday || {}} />
          <LeaveView leaveInfo={event.leave || {}} />
          <RouteView
            name={CALENDAR_PREVIEW.MONTHLY}
            date={date}
            routeList={event.routeList || []}
            removeRosterLine={value.removeRosterLine}
            onEditIconClick={value.handleRosterLineIconClick}
            callRosterPermission={callRosterPermission}
          />
          {(displayAddButton(dseSelected, date) && callRosterPermission?.create)? (
            <Button
              primary
              className={getButtonClassName(date, {
                activeDate: value.activeDate,
                displayDialog: value.displayDialog,
              })}
              iconName='plus'
              onClick={() =>
                value.handleRosterComponentClick({
                  date,
                  selectedRouteList: event.routeList || [],
                })
              }
            />
          ) : null}
          {date === value.activeDate ? value.rosterComponent : null}
        </div>
      )}
    </DialogContext.Consumer>
  );
}

MonthlyUnit.propTypes = propTypes;

MonthlyUnit.defaultProps = defaultProps;

export default MonthlyUnit;
