import React from 'react';
import PropTypes from 'prop-types';
import SystemAlertWrapper from '../SystemAlertWrapper';
import {
  NOTIFICATION_ALERT_MESSAGE, PLATFORM_TYPE, week, weekDays
} from '../../../../data/enums/Notification';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import {
  AutoComplete,
  Col,
  CustomSelect,
  Input,
  Row,
  SingleDatePicker,
} from '../../../../components';
import {
  Icon, Button, CheckBox
} from '../../../../v4/components';
import FormContentStyled from '../../../../components/global-css/FormContentStyled';
import withErrorAndLoading from '../../../../utils/composition/withLoading';
import { MessageStyled } from '../../../../components/Input/TextStyled';
import { EMPTY, INVALID } from '../../../../data/enums/ErrorMessage';
import { MESSAGE_PLATFORM } from '../../../salesForce/pushMessage/detail/enums';

const propTypes = {
  enableErrorDisplay: PropTypes.bool,
  crudMode: PropTypes.string.isRequired,
  refsObj: PropTypes.shape(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  menu: PropTypes.instanceOf(Object).isRequired,
  operatorList: PropTypes.instanceOf(Array).isRequired,
  attributeList: PropTypes.instanceOf(Array).isRequired,
  dimensionList: PropTypes.instanceOf(Array).isRequired,
  onAudienceChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onRadioButtonChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  onAudienceIconClick: PropTypes.func.isRequired,
  dataDictionaryList: PropTypes.instanceOf(Array).isRequired,
};

const defaultProps = {
  enableErrorDisplay: false,
};

const View = ({ ...props }) => {
  const {
    data,
    menu,
    crudMode,
    attributeList,
    operatorList,
    dimensionList,
    onInputChange,
    onDropDownChange,
    handleInputChange,
    enableErrorDisplay,
    refsObj,
    onRadioButtonChange,
    onAudienceIconClick,
    onAudienceChange,
    dataDictionaryList,
  } = props;

  const roleIdList = data.audience && data.audience.info && data.audience.info.map((role) => role.userRole);
  const selectedRoleIdList = roleIdList.map((element) => {
    const list = element.map((item) => item);
    return list[0];
  });
  const getDropDownForRoleList = (userRole) =>
    menu.roleList.filter((role) => role.id === userRole || !selectedRoleIdList.includes(role.id));

  const getUserListUnderRole = (userRole, selectedList) => {
    const userList = menu.userList.filter((user) => user.roleId === userRole);
    const roleLabel = menu.roleList.filter((user) => user.id === userRole);
    if (selectedList.length !== userList.length) {
      const userListwithAll = { id: 0, roleId: userRole, title: `All ${roleLabel.length > 0 && roleLabel[0].name}` };
      userList.splice(0, 0, userListwithAll);
    }
    return userList;
  };

  const handleAutoCompleteChange = (index) => (value, fieldName) => onAudienceChange(value, index, fieldName);

  return (
    <SystemAlertWrapper>
      <div className={`create-ui-inner tab-less-height ${crudMode === EVENT_OPERATION.READ ? 'disabled' : ''}`}>
        <FormContentStyled className='push-message-compose'>
          <h3>Title</h3>
          <div className='form-input-wrap'>
            {
              <Input
                name='title'
                type='text'
                enableValidation
                value={data.title}
                labelContent='Title'
                placeholder='Title'
                ref={(ref) => (refsObj.title = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleInputChange(event)}
              />
            }
          </div>
        </FormContentStyled>

        <FormContentStyled>
          <h3>Audience</h3>
          <div className='audience-wrap'>
            <div className='audience'>
              <div className='audience-info'>
                {data.audience &&
                  data.audience.info &&
                  data.audience.info.map((audienceInfo, index) => (
                    <div className='audience-info-wrap'>
                      <div className='audience-info-card' key={index}>
                        <div className='audience-medium'>
                          <CheckBox
                            className='checkbox'
                            labelText='SMS'
                            onCheckBoxClick={(e) => {
                              onAudienceChange(PLATFORM_TYPE.SMS, index, 'platform');
                            }}
                            checked={
                              data.audience &&
                              data.audience.info &&
                              data.audience.info[index].platform.includes(PLATFORM_TYPE.SMS)
                            }
                          />
                          <CheckBox
                            className='checkbox'
                            labelText='Email'
                            onCheckBoxClick={(e) => {
                              onAudienceChange(PLATFORM_TYPE.EMAIL, index, 'platform');
                            }}
                            checked={
                              data.audience &&
                              data.audience.info &&
                              data.audience.info[index].platform.includes(PLATFORM_TYPE.EMAIL)
                            }
                          />
                          <CheckBox
                            className='checkbox'
                            labelText='Mobile Notification'
                            onCheckBoxClick={(e) => {
                              onAudienceChange(PLATFORM_TYPE.IN_APP_NOTIFICATION, index, 'platform');
                            }}
                            checked={
                              data.audience &&
                              data.audience.info &&
                              data.audience.info[index].platform.includes(PLATFORM_TYPE.IN_APP_NOTIFICATION)
                            }
                          />
                          {enableErrorDisplay &&
                            data.audience.info[index].platform &&
                            data.audience.info[index].platform.length === 0 && (
                            <MessageStyled className='form-error'>
                              <span className='error-message'>
                                <Icon iconName='exclamation-full' />
                                {NOTIFICATION_ALERT_MESSAGE.PLATFORM}
                              </span>
                            </MessageStyled>
                          )}
                        </div>
                        <div className='config-role-wrap'>
                          <Row key={`audience-role-${audienceInfo.userRole}`}>
                            <Col md={4} className='mb-24'>
                              <CustomSelect
                                enableValidation
                                className='custom-select'
                                placeholder='Select Role'
                                options={getDropDownForRoleList(audienceInfo.userRole)}
                                getOptionValue={({ id }) => id}
                                getOptionLabel={({ name }) => name}
                                value={menu.roleList.filter(({ id }) => id === audienceInfo.userRole[0])}
                                onChange={(event) => onAudienceChange(event.id, index, 'userRole')}
                                ref={(ref) => (refsObj.role = ref)}
                                enableErrorDisplay={enableErrorDisplay}
                              />
                            </Col>
                            <Col md={8} className={audienceInfo.userRole[0] ? 'mb-24' : 'mb-24 disabled'}>
                              <AutoComplete
                                name='userIds'
                                dropDownList={getUserListUnderRole(audienceInfo.userRole[0], [...audienceInfo.userIds])}
                                placeholder='Select User'
                                selectedDropDowns={[...audienceInfo.userIds]}
                                onDropDownSelection={handleAutoCompleteChange(index)}
                                enableValidation
                                ref={(ref) => (refsObj.userIds = ref)}
                                enableErrorDisplay={enableErrorDisplay}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      {index > 0 && (
                        <div className='config-icon'>
                          <div className='flex m-0'>
                            <Icon
                              iconName='times'
                              primary
                              onClick={() => onAudienceIconClick('remove', 'info', index)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <Button medium secondary iconName='plus' onClick={() => onAudienceIconClick('add', 'info')} />
          </div>
        </FormContentStyled>
        <FormContentStyled>
          <h3>Notification Measure</h3>
          <div className='form-input-wrap'>
            <CustomSelect
              name='attribute'
              labelContent='Measure'
              placeholder='Select Measure'
              options={attributeList}
              getOptionValue={(item) => item}
              getOptionLabel={(item) => item}
              enableValidation
              ref={(ref) => (refsObj.attribute = ref)}
              enableErrorDisplay={enableErrorDisplay}
              onChange={(event) => onDropDownChange(event, ['attribute'])}
              value={attributeList.filter((label) => label === data.attribute)}
            />
            {data.attribute ==='DSE ATTENDANCE' ||
            <div className='threshold-wrap'>
              <label>Threshold</label>
              <CustomSelect
                name='operator'
                placeholder='Select'
                options={operatorList}
                getOptionValue={(item) => item}
                getOptionLabel={(item) => item}
                onChange={(event) => onDropDownChange(event, ['threshold', 'operator'])}
                value={operatorList.filter((label) => label === (data.threshold && data.threshold.operator))}
              />
              <CustomSelect
                name='attribute'
                placeholder='Select'
                options={dimensionList}
                getOptionValue={(item) => item}
                getOptionLabel={(item) => item}
                onChange={(event) => onDropDownChange(event, ['threshold', 'dimension'])}
                value={dimensionList.filter((label) => label === (data.threshold && data.threshold.dimension))}
              />
              <Input
                name='value'
                placeholder='Value'
                onChange={(event) => onInputChange(event, 'threshold', ['threshold', 'value'])}
                rule='isFloat'
                argument={{
                  min: 1,
                }}
                value={data.threshold && data.threshold.value}
                type='number'
                enableValidation
                ref={(ref) => (refsObj.value = ref)}
                enableErrorDisplay={enableErrorDisplay}
                errorMessage={data.threshold && data.threshold.value < 0 ? INVALID : EMPTY}
              />
            </div>}
          </div>
        </FormContentStyled>
        <FormContentStyled className='push-message-compose'>
          <h3>Compose</h3>
          <div className='form-input-wrap'>
            {
              <>
                <>
                  <Input
                    name='title'
                    type='text'
                    enableValidation
                    value={data.message.title}
                    labelContent='Subject'
                    placeholder='Subject'
                    ref={(ref) => (refsObj.messageTitle = ref)}
                    enableErrorDisplay={enableErrorDisplay}
                    onChange={(event) => handleInputChange(event, 'message')}
                  />
                  <div className='form-input text-area'>
                    <label htmlFor=''>Message</label>
                    <textarea
                      name='message'
                      type='text'
                      id='message'
                      placeholder='Message'
                      ref={(ref) => (refsObj.message = ref)}
                      value={data.message.message}
                      onChange={(event) => handleInputChange(event, 'message')}
                      required
                    />
                    {enableErrorDisplay && data.message.message === '' ? (
                      <MessageStyled className='form-error'>
                        <span className='error-message'>
                          <Icon iconName='exclamation-full' />
                          {'Must not be empty'}
                        </span>
                      </MessageStyled>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              </>
            }
          </div>
          <>
            {dataDictionaryList.length ? (
              <span className={data.platform === MESSAGE_PLATFORM.NOTIFICATION && 'notification-span'}>
                <p>
                  <Icon iconName='info-circle-open' />
                  <span>
                    <b>{`Use ${'{attribute}'} to add attributes to your message. Some of the attributes are listed below:`}</b>
                    <strong>{dataDictionaryList.map((dictData) => dictData.attribute).join(', ')}</strong>
                  </span>
                </p>
              </span>
            ) : null}
          </>
        </FormContentStyled>

        <FormContentStyled>
          <h3>Time Schedule</h3>
          <div className='form-input-wrap custom-date'>
            <label>Start Date</label>
            <SingleDatePicker
              date={data.startDate}
              name='date'
              onChange={(name, date) =>
                onInputChange({
                  target: {
                    name: 'startDate',
                    value: date,
                  },
                  value: date,
                })
              }
              minDate={new Date()}
            />
          </div>
          <div className='form-input-wrap custom-date'>
            <label>End Date</label>
            <SingleDatePicker
              date={data.endDate}
              name='endDate'
              onChange={(name, date) =>
                onInputChange({
                  target: {
                    name: 'endDate',
                    value: date,
                  },
                  value: date,
                })
              }
              minDate={data.startDate}
            />
          </div>
          <div className='permission-choose'>
            <label>Repeat Times</label>
            <div>
              <label className='radio-list'>
                <input
                  type='radio'
                  name='repeatedTimes'
                  value='Daily'
                  onChange={(e) => onRadioButtonChange(e)}
                  checked={data.repeatedTimes ? data.repeatedTimes.daily : false}
                />
                <span className='text'>Daily</span>
              </label>
              <label className='radio-list'>
                <input
                  type='radio'
                  name='repeatedTimes'
                  value='Weekly'
                  onChange={(e) => onRadioButtonChange(e)}
                  checked={data.repeatedTimes ? data.repeatedTimes.weekly : false}
                />
                <span className='text'>Weekly</span>
              </label>
            </div>
          </div>
          {data.repeatedTimes && !data.repeatedTimes.daily && (
            <div className='reapeat__days'>
              <label>Repeat Days</label>
              <div className='checkbox-list'>
                {week.map((d, index) => (
                  <CheckBox
                    key={d}
                    checked={data.repeatDays.includes(weekDays[d])}
                    name={d}
                    className='checkbox'
                    labelText={d.slice(0, 3).toUpperCase()}
                    onCheckBoxClick={(e) => {
                      onInputChange(
                        {
                          formattedValue: weekDays[d],
                          target: {
                            name: parseInt(data.repeatDays.length, 10),
                            value: weekDays[d],
                            checked: !!e.target.checked,
                          },
                        },
                        'repeatDays',
                        [],
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className='time'>
            <label>Time</label>
            <div className='time__hours'>
              <Input
                value={data.times.hours}
                type='number'
                onChange={(event) => onInputChange(event, 'times', ['times', 'hours'])}
                argument={{
                  max: 12,
                }}
              />
              <label>HH</label>
            </div>
            <div className='time__hours'>
              <Input
                value={data.times.minutes}
                type='number'
                placeholder='MM'
                onChange={(event) => onInputChange(event, 'times', ['times', 'minutes'])}
                allowMultiZeroBeforeNumber
                argument={{
                  max: 60,
                }}
              />
              <label>MM</label>
            </div>
            <div className='time__hours'>
              <CustomSelect
                name='period'
                className='custom-select'
                options={menu.periodType}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ title }) => title}
                value={menu.periodType.filter(({ title }) => title === data.times.period)}
                onChange={(event) => onDropDownChange(event.title, ['times', 'period'])}
              />
              <label>AM/PM</label>
            </div>
          </div>
        </FormContentStyled>
      </div>
    </SystemAlertWrapper>
  );
};

View.propTypes = propTypes;
View.defaultProps = defaultProps;

export default withErrorAndLoading(View);
