import styled from 'styled-components';

const CoverageWrapper = styled.div`
  .month-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: -8px;
    margin-top: 18px;
  }

  .form-input {
    width: 250px;
  }

  .month-wrap {
    margin-bottom: 24px;
    width: 47.5%;
    label {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 4px;

      + div {
        width: 100%;
        background: #f1f2f5;
        border: 1px solid #e7e7ed;
        border-radius: 4px;
        padding: 0 16px;

        > div {
          margin: 0;
          display: block;
        }
      }
    }
    .month-view {
      position: relative;

      .month-icon {
        position: absolute;
        right: 0;
        margin: 0;
      }

      .ic-dropdown {
        display: none;
      }
    }
  }

  table {
    td {
      width: auto;
      white-space: nowrap;
      &:first-child {
        width: 30%;
      }
      &:last-child {
        .view {
          margin-right: 40px;
        }
        span {
          cursor: pointer;
          color: #6b6c7e;
          font-size: 14px;
        }
        svg.icon {
          height: 14px;
          width: 14px;
          margin-right: 9px;
        }
      }
    }
    td,
    th {
      &:last-child {
        text-align: right;
      }
    }
  }
  .single-date-picker {
    width: 252px;
  }
  .targets-wrapper {
    .modal-wrapper {
      .modal-inner {
        width: 580px;
        .evolve-dialog__body {
          .single-date-picker {
            margin-top: 0;
          }
          .form-input {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .modal-wrapper {
    .modal-inner {
      width: 580px;
      max-width: unset;
      .pad-24 {
        padding: 0 24px 24px 24px;
      }
      .evolve-dialog__body {
        .form-input {
          margin: 5px 0px;
        }
        .coverage-detail-wrap {
          display: flex;
          .single-date-picker {
            width: 50%;
            margin: 0;
            margin-right: 24px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export default CoverageWrapper;
