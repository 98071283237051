import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { sales as salesRoute } from '../../../data/enums/Route';

const breadCrumb = [salesRoute];

const filter = getFilterConfig([FILTER.SUB_D]);

const RETURN_TABS = {
  PENDING: 'pending',
  RECONCILED: 'reconciled',
};

const tabMapper = {
  0: RETURN_TABS.PENDING,
  1: RETURN_TABS.RECONCILED,
};

export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, RETURN_TABS, tabMapper
};
