import moment from 'moment';

interface DateToRenderProps {
  date: {
    start: string;
    end: string;
  };
}

const DateToRender = ({ date }: DateToRenderProps) => {
  const startDate = moment(date.start);
  const endDate = moment(date.end);
  const today = moment(new Date());
  let dateToRender;

  if (startDate.isSame(endDate)) {
    if (startDate.year() === today.year() && startDate.month() === today.month() && startDate.day() === today.day()) {
      dateToRender = (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Today <span className="ic-dropdown">*Dropdown</span>
        </span>
      );
    } else if (startDate.year() === today.year()) {
      dateToRender = (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {' '}
          {startDate.format('D MMM')}
          <span className="ic-dropdown">*Dropdown</span>{' '}
        </span>
      );
    } else {
      dateToRender = (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {' '}
          {startDate.format('D MMM YYYY')}
          <span className="ic-dropdown">*Dropdown</span>{' '}
        </span>
      );
    }
  } else if (startDate.year() === endDate.year() && endDate.year() === today.year()) {
    dateToRender = (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {' '}
        {startDate.format('D MMM')} - {endDate.format('D MMM')}
        <span className="ic-dropdown">*Dropdown</span>{' '}
      </span>
    );
  } else {
    dateToRender = (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {' '}
        {startDate.format('D MMM YYYY')} - {endDate.format('D MMM YYYY')}
        <span className="ic-dropdown">*Dropdown</span>{' '}
      </span>
    );
  }
  return dateToRender;
};

export default DateToRender;
