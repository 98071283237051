import styled from 'styled-components';

const DownloadStyled = styled.div`
  .filter-dropdown-card {
    min-width: 166px;
    border-radius: 4px;
    ul {
      padding: 8px 0;
      li {
        padding: 9px 16px;
        font-size: 14px;
        line-height: 21px;
        color: #272833;
        white-space: nowrap;
        cursor: pointer;
        text-transform: capitalize;
        span {
          margin-right: 18px;
          svg {
            color: #6b6c7e;
          }
        }
        :hover {
          background: #f0f5ff;
        }
      }
    }
  }
`;

export default DownloadStyled;
