import { compose } from 'recompose';
import gql from 'graphql-tag';
import SummaryDetails from './SummaryDetails';
import { GET_CUSTOMERS } from '../../../../common/GqlAPI';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';
import { GET_INVOICE_NUMBER_LIST, GET_LINES_BY_OUTLET } from '../API';

const query = {
  getCustomers: GET_CUSTOMERS,
  getInvoiceNumberList: GET_INVOICE_NUMBER_LIST,
  getLinesByOutlet: GET_LINES_BY_OUTLET,
};

const ComposedCreateSrnSummaryDetails = compose(
  withRequestTracker({
    query,
    mutation: {},
  }),
)(SummaryDetails);

export default ComposedCreateSrnSummaryDetails;
