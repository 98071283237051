import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { getUpdatedLabel, headerLabelConfig } from '../../common/HelperFunctions';

const labelMappings = {
  dse: 'dse',
  distributor: 'distributor',
  route: 'route',
  town: 'town',
  zone: 'zone',
  scheduled: 'scheduled',
  calls_made: 'calls_made',
  successful: 'successful',
  unsuccessful: 'unsuccessful',
  productivity: 'productivity',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.dse,
    title: `${headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE'} Name`,
    show: true,
    className: 'sorting',
    sortable: true,
    isDynamic: true,
  },
  {
    id: 2,
    label: labelMappings.distributor,
    title: 'Distributor',
    show: true,
    className: 'sorting',
    sortable: true,
  },
  {
    id: 3,
    label: labelMappings.route,
    title: 'Route',
    show: false,
  },
  {
    id: 4,
    label: labelMappings.town,
    title: 'Town',
    show: false,
  },
  {
    id: 5,
    label: labelMappings.zone,
    title: 'Zone',
    show: false,
  },
  {
    id: 6,
    label: labelMappings.scheduled,
    title: 'Scheduled',
    show: true,
    className: 'sorting text-right',
    sortable: true,
  },
  {
    id: 7,
    label: labelMappings.calls_made,
    title: 'Call Made',
    show: true,
    className: 'sorting text-right',
    sortable: true,
  },
  {
    id: 8,
    label: labelMappings.successful,
    title: 'Successful',
    show: true,
    className: 'sorting text-right',
    sortable: true,
  },
  {
    id: 9,
    label: labelMappings.unsuccessful,
    title: 'Unsuccessful',
    show: true,
    className: 'sorting text-right',
    sortable: true,
  },
  {
    id: 10,
    label: labelMappings.productivity,
    title: 'Productivity(%)',
    className: 'text-right sorting',
    show: true,
    sortable: true,
  },
];

const TableHeaderConfig = ({ onHeaderClick, dynamicHeader }) => (
  <TableHeader
    headerDetails={dynamicHeader ? getUpdatedLabel(labelConfig, 'Name') : labelConfig}
    onHeaderClick={onHeaderClick}
  />
);

const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.dse] = <td key={`productivity-${data.dse_id}-dse`}>{data.dse}</td>;
  table[labelMappings.distributor] = (
    <td key={`productivity-${data.dse_id}-${data.distributor_id}-distributor`}>{data.distributor}</td>
  );
  table[labelMappings.route] = <td key={`productivity-${data.dse_id}-${data.distributor_id}-route`}>{data.route}</td>;
  table[labelMappings.town] = <td key={`productivity-${data.dse_id}-${data.distributor_id}-town`}>{data.town}</td>;
  table[labelMappings.zone] = <td key={`productivity-${data.dse_id}-${data.distributor_id}-zone`}>{data.zone}</td>;
  table[labelMappings.scheduled] = (
    <td key={`productivity-${data.dse_id}-${data.distributor_id}-scheduled`} className='text-right'>
      {fixedFloatAndCommas(data.scheduled)}
    </td>
  );
  table[labelMappings.calls_made] = (
    <td key={`productivity-${data.dse_id}-${data.distributor_id}-calls_made`} className='text-right'>
      {fixedFloatAndCommas(data.calls_made)}
    </td>
  );
  table[labelMappings.successful] = (
    <td key={`productivity-${data.dse_id}-${data.distributor_id}-successful`} className='text-right'>
      {fixedFloatAndCommas(data.successful)}
    </td>
  );
  table[labelMappings.unsuccessful] = (
    <td key={`productivity-${data.dse_id}-${data.distributor_id}-unsuccessful`} className='text-right'>
      {fixedFloatAndCommas(data.unsuccessful)}
    </td>
  );
  table[labelMappings.productivity] = (
    <td key={`productivity-${data.dse_id}-${data.distributor_id}-productivity%`} className='text-right'>
      {fixedFloatAndCommas(data.productivity)}
    </td>
  );

  return table;
};

const TableBody = ({ data }) => {
  const tableBody = returnTableBody(data);

  return (
    <tr key={`productivity-${data.dse_id}-${data.distributor_id}-details`}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
  dynamicHeader: PropTypes.bool,
};

TableHeaderConfig.defaultProps = {
  dynamicHeader: false,
};

TableBody.defaultProps = {
  data: {},
};

export {
  TableHeaderConfig as TableHeader, TableBody, labelConfig
};
