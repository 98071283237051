import React from 'react'
import { BulkDelete, Pagination } from '../../../../v4/components'
import { TableBody, TableHeader } from './tableConfig';
import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';

const View = ({ ...props }) => {
  const { data, onPageChange, pagination, onIconClick, permission, onBulkDelete } = props;
  return (
    <>
      <BulkDelete
        data={data?.list}
        type={STATEFUL_ENTITIES.TARGET_DISTRIBUTION}
        handler={onBulkDelete}
        render={(state, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className='table-wrap prn'>
            <table>
              {TableHeader({
                dataListLength: data?.list?.length,
                checkedListLength: state?.checkedList?.length,
                primaryCheckboxHandler,
                permission,
              })}

              <tbody>
                {data?.list?.map((srn) =>
                  TableBody({
                    data: srn,
                    checkedList: state.checkedList,
                    onIconClick,
                    secondaryCheckboxHandler,
                    permission,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      <Pagination
        currentPage={pagination?.page}
        limit={pagination?.limit}
        totalItems={data?.total}
        onPageChange={onPageChange}
      />
    </>
  )
}

export default View
