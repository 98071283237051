import gql from 'graphql-tag';
import { TOGGLE_STATE } from '../../../common/GqlAPI';

const GET_TARGETS_LIST = gql`
query getAutomatedSbdTargetList($limit: Int, $offset: Int, $filter: FilterInput) {
  getAutomatedSbdTargetList(limit: $limit, offset: $offset, filter: $filter) {
    rows {
      id
      month
      monthName
      year
      national_target
    }
    count
  }
}
`;

const query = {
  getSbdTargetList: GET_TARGETS_LIST,
};

const mutation = {
  toggleState: TOGGLE_STATE,
};

export { GET_TARGETS_LIST, TOGGLE_STATE };

export { query, mutation };
