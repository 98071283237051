import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Tab, TabList, Tabs, TabPanel } from '../../../v4/components';
import { breadCrumbConfig, filterConfig, RETURN_TABS, tabMapper } from './config';
import PageHeader from '../../base/PageHeader';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { getPermissionForReturns } from '../../base/permission';
import withAlert from '../../../utils/composition/withAlert';
import { RETURN_CREATE, RETURN_PENDING, RETURN_RECONCILED } from '../../../data/enums/Route';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import {
  fetchFromLocalStorage,
  getSortValueAfterValueCheck,
  imageSliderListFormatter,
  isError,
  storeInLocalStorage,
} from '../../common/HelperFunctions';
import Pending from './pending';
import Reconciled from './reconciled';
import { clone } from '../../../utils/objectProcessor';
import { storageKeys } from '../orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import { getUser } from '../../../data/services';
import { USER_ROLE } from '../../../data/enums';

const propTypes = {
  getReturnRequestsList: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  history: PropTypes.instanceOf(Object).isRequired,
  downloadReport: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null,
};
class Return extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    const distributorData = getUser()?.Distributor?.[0];
    const enableDnEApproval = distributorData?.enableDnEApproval || false;
    console.log(enableDnEApproval, 'enableDnEApproval');
    const logisticEnabled = distributorData?.servicesUsed?.logistic?.status || false;
    const roleId = getUser()?.Role?.id;
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: {},
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      enableDnEApproval,
      logisticEnabled,
      roleId,
      activeTab:
        roleId === USER_ROLE.DISTRIBUTOR_ADMIN || roleId === USER_ROLE.BILLING_USER
          ? !enableDnEApproval && !logisticEnabled
            ? 0
            : 1
          : 0,
    };
    this.permission = getPermissionForReturns();

    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );

    this.tabListGetterMapper = {
      [RETURN_TABS.PENDING]: this.loadTableData,
      [RETURN_TABS.RECONCILED]: this.loadTableData,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  setFilterStateInStorage = () => {
    const {
      filterMenu,
      queryParameters: { filter, date, search },
    } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.RETURN,
    );
  };

  componentDidMount() {
    this.getFilterStateFromStorage();
  }

  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.RETURN);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      }),
    };

    if (!localData) {
      return this.getInitialData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
      },
      () => {
        this.getInitialData();
      },
    );
  };

  getInitialData = () => {
    this.loadTableData();
    const { activeTab } = this.state;
    this.fetchActiveTabPaginatedList(activeTab);
  };

  fetchActiveTabPaginatedList = tab => {
    const type = tabMapper[tab];
    this.tabListGetterMapper[type]();
  };

  loadTableData = () => {
    const { data, queryParameters, activeTab } = this.state;
    const { getReturnRequestsList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getReturnRequestsList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            {
              column: 'status',
              value: activeTab === 0 ? 'PENDING_REQUEST' : 'RECONCILED',
            },
          ],
          dateRange: { ...queryParameters.date, ...(activeTab === 1 && { filterBy: 'return_date' }) },
          queryString: queryParameters.search,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      },
      {
        handleSuccess: response => {
          const dataList = response.data.salesReturnNotes ? response.data.salesReturnNotes.rows : [];
          data.total = response.data.salesReturnNotes ? response.data.salesReturnNotes.count : 1;
          data.list = dataList.map(srn => {
            if (srn.image) {
              srn.image = [...(imageSliderListFormatter(srn.image) || [])];
            }
            return srn;
          });
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type, id) => {
    const { history } = this.props;

    switch (type) {
      case EVENT_OPERATION.CREATE:
        history.push(`/${RETURN_CREATE}`);
        break;
      case EVENT_OPERATION.APPROVE:
        history.push(`/${RETURN_PENDING}/${id}`);
        break;
      default:
        history.push(`/${RETURN_RECONCILED}/${id}`);
    }
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  handleTabChange = tab => {
    this.setState(
      {
        activeTab: tab,
      },
      () => this.fetchActiveTabPaginatedList(tab),
    );
  };

  handleDownloadClick = (reportType, domain) => {
    const { queryParameters, activeTab } = this.state;

    const filter = {
      filters: [
        ...apiFilterProcessor(queryParameters.filter),
        {
          column: 'status',
          value: activeTab === 0 ? 'PENDING_REQUEST' : 'RECONCILED',
        },
      ],
      queryString: queryParameters.search,
      dateRange: queryParameters.date,
    };

    this.basePaginationService.handleDownloadClickWithCustomFilter('csv', domain, filter);
  };

  render() {
    const { data, queryParameters, display, activeTab, filterMenu, enableDnEApproval, roleId, logisticEnabled } =
      this.state;
    const { serverResponseWaiting } = this.props;

    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: 'D&E Return',
                create: !logisticEnabled && this.permission.create,
                filter: true,
                date: true,
                search: true,
                download: true,
              }}
              downloadConfig={{
                domain: DOWNLOAD_DOMAIN.DE_RETURN_REPORT,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.handleIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div
          className={`section-content ${
            roleId === USER_ROLE.DISTRIBUTOR_ADMIN || roleId === USER_ROLE.BILLING_USER
              ? !enableDnEApproval && !logisticEnabled
                ? ''
                : 'section-tab'
              : 'section-tab'
          }`}
        >
          <Tabs selectedIndex={activeTab} onSelect={tabIndex => this.handleTabChange(tabIndex)}>
            {roleId === USER_ROLE.DISTRIBUTOR_ADMIN || roleId === USER_ROLE.BILLING_USER ? (
              !enableDnEApproval && !logisticEnabled ? null : (
                <TabList>
                  <Tab>
                    <span className="tab-label">Pending</span>
                  </Tab>
                  <Tab>
                    <span className="tab-label">Reconciled</span>
                  </Tab>
                </TabList>
              )
            ) : (
              <TabList>
                <Tab>
                  <span className="tab-label">Pending</span>
                </Tab>
                <Tab>
                  <span className="tab-label">Reconciled</span>
                </Tab>
              </TabList>
            )}
            <div className="tab-container">
              <TabPanel>
                <section id="pending-tab">
                  <div id="detail-tab">
                    <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                      <Pending
                        data={data}
                        loading={serverResponseWaiting}
                        pagination={queryParameters.pagination}
                        onRowClick={this.handleIconClick}
                        onPageChange={this.basePaginationService.onPageSelect}
                      />
                    </div>
                  </div>
                </section>
              </TabPanel>
              <TabPanel>
                <section id="received-tab">
                  <div id="detail-tab">
                    <div className="create-ui-inner tab-less-height" style={{ padding: '0' }}>
                      <Reconciled
                        data={data}
                        loading={serverResponseWaiting}
                        pagination={queryParameters.pagination}
                        onRowClick={this.handleIconClick}
                        onPageChange={this.basePaginationService.onPageSelect}
                        onHeaderClick={this.basePaginationService.handleTableSorting}
                      />
                    </div>
                  </div>
                </section>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

Return.propTypes = propTypes;

Return.defaultProps = defaultProps;

export default withAlert()(Return);
