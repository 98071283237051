import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_MERCHANDISE_LIST = gql`
  query ($limit: Int, $offset: Int, $filter: FilterInput) {
    getMerchandiseReport(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        date
        outlet
        device
        merchandise_images {
          src: image
          thumbnail
          created_at
        }
        category
        dse
        stl
        asm
        distributor
        town
        territory
      }
      count
    }
  }
`;

const query = {
  getMerchandiseReportList: GET_MERCHANDISE_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
