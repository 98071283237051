import StockCountDetail from './StockCountDetail';
import { compose } from 'recompose';
import {
  query, mutation, CREATE_STOCK_COUNT, DELETE_MT_SKU, TOGGLE_CHANNEL_CATEGORY_SKUS, UPDATE_STOCK_COUNT
} from '../Common/API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';


const composedMT = compose(
  CREATE_STOCK_COUNT,
  UPDATE_STOCK_COUNT,
  DELETE_MT_SKU,
  TOGGLE_CHANNEL_CATEGORY_SKUS,
  withRequestTracker({
    query,
    mutation,
  }),
)(StockCountDetail);

export default composedMT;

