import { compose } from 'recompose';
import LedgerAdd from './LedgerAdd';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  mutation, CREATE_LEDGER, EDIT_LEDGER, query
} from './API';

const composeLedgerAdd = compose(
  CREATE_LEDGER,
  EDIT_LEDGER,
  withRequestTracker({
    query,
    mutation,
  }),
)(LedgerAdd);

export default composeLedgerAdd;
