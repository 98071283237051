import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import {
  TableHeader, TableBody, TableBodyForAccordion,TableHeaderForAccordion,
} from './tableConfig';
import withImageSlider from '../../../../utils/composition/withImageSlider';
import Wrapper from './TripWise.Styled';
import { Pagination } from '../../../../v4/components';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import {
  collapsibleMainHeader,collapsibleSecondaryHeader,
} from './config';
import AccordionTable from '../../../../components/CollapsibleTable/AccordionTable';

const propTypes = {
  data: dataProps.value,
  tableConfig: PropTypes.arrayOf(Object).isRequired,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const TripWiseView = ({ ...props }) => {
  const { handleSliderOpen,tableConfig, pagination, onPageChange, data, handleIconClick, permission,handleRowClick } = props;
  const tablewithAction = [
    ...tableConfig,
  ];
  const getCollapsibleComponent = (
    dataList,
  ) => {
    return (
      <AccordionTable allowMultipleOpen={true}>
        {dataList &&
          dataList?.length > 0 &&
          dataList?.map((bodyData,key) => (
            <tr
              key={`${bodyData.id}-${key}`}
              trigger={
                TableBody({
                  data: bodyData,
                  tableConfig: tablewithAction,
                  handleIconClick,
                  handleRowClick,
                  permission,
                })
              }
            >
              {
                <>
                  {TableHeaderForAccordion({ tableConfig: collapsibleMainHeader(bodyData?.total) })}

                  {TableHeaderForAccordion({ tableConfig: collapsibleSecondaryHeader })}

                  {bodyData?.details &&
                    bodyData?.details?.length > 0 &&
                    bodyData?.details?.map((detail,index) =>
                      TableBodyForAccordion({
                        data: detail,
                        tableConfig: collapsibleSecondaryHeader,
                        index,
                        onImageClick:handleSliderOpen,
                        permission,
                      }),
                    )}
                </>
              }
            </tr>
          ))}
      </AccordionTable>
    );
  };


  return (
    <Fragment>
      <Wrapper>
        <div className='table-wrap'>
          <table>
            {TableHeader({ tableConfig: tablewithAction })}
            <tbody>
              { data?.list?.length > 0 ? getCollapsibleComponent(data?.list) : '' }
            </tbody>
          </table>
        </div>
      </Wrapper>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};
TripWiseView.propTypes = propTypes;

TripWiseView.defaultProps = defaultProps;

const TripWiseViewWithErrorAndLoading = withErrorAndLoadingScreen(TripWiseView);

const TripWiseViewWithImageSlider = withImageSlider(TripWiseViewWithErrorAndLoading);

export default TripWiseViewWithImageSlider;
