import gql from 'graphql-tag';

const USER_TARGET_ACHIEVEMENT_FRAGMENT = gql`
  fragment UserTargetAchievementFragment on UserTargetPerMonth {
    id
    name
    target
    achievement
    role
    isChildPresent
    active
  }
`;

const FULL_USER_TARGET_ACHIEVEMENT_FRAGMENT = gql`
  fragment FullUserTargetAchievementFragment on UserTargetPerMonth {
    ...UserTargetAchievementFragment
    childUsers: ChildUsers {
      ...UserTargetAchievementFragment
      childUsers: ChildUsers {
        ...UserTargetAchievementFragment
        childUsers: ChildUsers {
          ...UserTargetAchievementFragment
          childUsers: ChildUsers {
            ...UserTargetAchievementFragment
            childUsers: ChildUsers {
              ...UserTargetAchievementFragment
              childUsers: ChildUsers {
                ...UserTargetAchievementFragment
              }
            }
          }
        }
      }
    }
  }
  ${USER_TARGET_ACHIEVEMENT_FRAGMENT}
`;

const GET_USER_TARGET_ACHIEVEMENT = gql`
  query getUserWiseTargetAchievementV2($filter: FilterInput) {
    getUserWiseTargetAchievementV2(filter: $filter) {
      principal_id
      principal
      target
      achievement
      dimension
      dimensionMetric
      users {
        id
        name
        role
        active
        target
        achievement
        dimension
        dimensionMetric
        isChildPresent
        ChildUsers {
          id
          name
          role
          active
          target
          achievement
          dimension
          dimensionMetric
          isChildPresent
          ChildUsers {
            id
            name
            role
            active
            target
            achievement
            dimension
            dimensionMetric
            isChildPresent
            ChildUsers {
              id
              name
              role
              active
              target
              achievement
              dimension
              dimensionMetric
              isChildPresent
              ChildUsers {
                id
                name
                role
                active
                target
                achievement
                dimension
                dimensionMetric
                isChildPresent
                ChildUsers {
                  id
                  name
                  role
                  active
                  target
                  achievement
                  dimension
                  dimensionMetric
                  isChildPresent
                  ChildUsers {
                    id
                    name
                    role
                    active
                    target
                    achievement
                    dimension
                    dimensionMetric
                    isChildPresent
                    ChildUsers {
                      id
                      name
                      role
                      active
                      target
                      achievement
                      dimension
                      dimensionMetric
                      isChildPresent
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GET_SUB_D_TARGET_ACHIEVEMENT = gql`
  query GetSubDUserTargetAchievementV2($filter: FilterInput) {
    getSubDUserTargetAchievementV2(filter: $filter) {
      achievement
      principal
      principal_id
      target
      distributors {
        achievement
        id
        name
        target
        users {
          id
          name
          outlets {
            achievement
            category
            category_id
            channel
            channel_id
            id
            name
            town
            town_id
          }
          target
          active
          achievement
        }
      }
    }
  }
`;

const query = {
  getUserTarget: GET_USER_TARGET_ACHIEVEMENT,
  getSubDTarget: GET_SUB_D_TARGET_ACHIEVEMENT,
};

export { query };
