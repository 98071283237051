import { compose } from 'recompose';

import TripWiseCollection from './TripWiseCollection';
import { query,RECEIVE_COLLECTION_BY_TRIP_ID,mutation } from './API';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const composedReceipt = compose(
  RECEIVE_COLLECTION_BY_TRIP_ID,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(TripWiseCollection);

export default composedReceipt;
