import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_ATTENDANCE_LIST = gql`
  query listAttendances($limit: Int, $offset: Int, $filter: FilterInput) {
    listAttendances(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        id
        userId
        User {
          fullName
          phoneNumber
          Role {
            name
            id
          }
        }
        checkinTime
        checkoutTime
        checkinLocation {
          latitude
          longitude
        }
        checkoutLocation {
          latitude
          longitude
        }
        checkinAddress
        checkoutAddress
        logDate
        checkinImages {
          thumbnail: image
          src: image
        }
        checkoutImages {
          thumbnail: image
          src: image
        }
        Agendas {
          id
          attendanceId
          userId
          logDate
          agenda
          isCompleted
        }
      }
      count
    }
  }
`;
const query = {
  getAttendanceList: GET_ATTENDANCE_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
