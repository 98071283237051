import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, SingleDatePicker
} from '../../../../components';
import withLoading from '../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    target: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    target: '',
    startDate: '',
    endDate: '',
  },
  refsObj: {
    target: '',
    startDate: '',
    endDate: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  type: '',
};

const BrandCoverageTargetForm = ({ ...props }) => {
  const { data, handleInputChange, enableErrorDisplay, type } = props;

  return (
    <Form>
      <>
        <div className='brand-detail-wrap'>
          <div className='single-date-picker'>
            <label>Start Date</label>
            <SingleDatePicker
              date={data.startDate}
              maxDate={data.endDate}
              disabled={type === EVENT_OPERATION.READ}
              name='startDate'
              placeholder='Start Date'
              onChange={(name, date) =>
                handleInputChange(
                  {
                    target: {
                      name: 'startDate',
                      value: date,
                    },
                    formattedValue: date,
                  },
                  'date',
                )
              }
              labelContent='Start Date'
            />
          </div>

          <div className='single-date-picker'>
            <label>End Date</label>
            <SingleDatePicker
              date={data.endDate}
              minDate={data.startDate}
              disabled={type === EVENT_OPERATION.READ}
              name='endDate'
              placeholder='End Date'
              onChange={(name, date) =>
                handleInputChange(
                  {
                    target: {
                      name: 'endDate',
                      value: date,
                    },
                    formattedValue: date,
                  },
                  'date',
                )
              }
              labelContent='End Date'
            />
          </div>
        </div>
        <div className='table-wrap'>
          <table>
            <thead>
              <tr>
                <th>Brand</th>
                <th>Brand Target</th>
              </tr>
            </thead>
            <tbody>
              {data.brandNameList.map((brand, i) => (
                <tr style={{ height: 41 }}>
                  <td>{brand.brandName}</td>
                  <td>
                    <Input
                      value={brand.target}
                      name='target'
                      type='number'
                      rule='isFloat'
                      argument={{ min: 0 }}
                      disabled={type === EVENT_OPERATION.READ}
                      onChange={(event) => handleInputChange(event, 'target', i)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    </Form>
  );
};

BrandCoverageTargetForm.propTypes = propTypes;

BrandCoverageTargetForm.defaultProps = defaultProps;

const BrandCoverageTargetFormWithErrorAndLoading = withLoading(BrandCoverageTargetForm);

export default BrandCoverageTargetFormWithErrorAndLoading;
