import React from 'react';
import PropTypes from 'prop-types';
import DetailView from './View';
import withAlert from '../../../../utils/composition/withAlert';

const propTypes = {
  data: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func.isRequired,
  ledgerDetail: PropTypes.instanceOf(Array),
};

const defaultProps = {
  data: [],
  ledgerDetail: [],
};

const Detail = ({ ...props }) => {
  const { data, onRowClick, ledgerDetail, handleTransferButton, closeAccordion } = props;

  return (
    <DetailView
      data={data}
      onRowClick={onRowClick}
      ledgerDetail={ledgerDetail}
      handleTransferButton={handleTransferButton}
      closeAccordion={closeAccordion}
    />
  );
};

Detail.propTypes = propTypes;

Detail.defaultProps = defaultProps;

export default withAlert()(Detail);
