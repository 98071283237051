import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../../v4/components';
import {
  formatter, getCurrentDay, routine
} from '../../../utils/date';
import SectionStyled from '../components/Section/SectionStyled';
import DetailWrap from './DetailWrap';

const propTypes = {
  title: PropTypes.string.isRequired,
  syncTime: PropTypes.string,
};

const defaultProps = {
  syncTime: '',
};

const UserDetails = ({ title, syncTime }) => {
  const [fullScreen, setFullScreen] = useState(false);
  const doc = window.document;
  const docEl = document.getElementsByClassName('section-panel')[0];
  const cancelFullScreen =
    doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

  const fullScreenClick = () => {
    const requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
      setFullScreen(true);
    } else {
      setFullScreen(false);
      cancelFullScreen.call(doc);
    }
  };

  useEffect(() => {
    doc.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement === null) {
        setFullScreen(false);
      }
    });
    doc.addEventListener('webkitfullscreenchange', () => {
      if (document.webkitFullscreenElement === null) {
        setFullScreen(false);
      }
    });
  }, []);
  return (
    <DetailWrap id='detail'>
      <SectionStyled className='custom-display user-detail'>
        <img
          src={`/image/icons/${fullScreen ? 'exit-fullscreen' : 'enter-fullscreen'}.svg`}
          className='full-screen'
          onClick={fullScreenClick}
          alt='image'
        />
        <div className='greet'>
          <h3>{`Good ${routine()} !`}</h3>
          <h1>{title.toString().length !== 1 ? title : <span style={{ color: '#fff' }}>XXXXX XXXXX</span>}</h1>
        </div>
        <div className='text-holder'>
          <div className='sm-card date'>
            <div className='lft'>
              <Icon iconName='calendar' />
              <span className='rt'>Date</span>
            </div>
            <span className='rt'>{formatter(getCurrentDay()).format('ll')}</span>
          </div>
        </div>
      </SectionStyled>
    </DetailWrap>
  );
};

UserDetails.propTypes = propTypes;

UserDetails.defaultProps = defaultProps;

export default UserDetails;
