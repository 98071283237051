import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { GET_OUTLETS_FOR_SRN, GET_SKU_WITH_BATCH, GET_DISTRIBUTORS_SERVICES } from '../../../common/GqlAPI';

const CREATE_WEB_ORDER = graphql(
  gql`
    mutation createWebOrder($input: WebOrderInput!) {
      createWebOrder(input: $input) 
    }
  `,
  {
    props: ({ mutate }) => ({
      createWebOrder: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getOutlets: GET_OUTLETS_FOR_SRN,
  getSkus: GET_SKU_WITH_BATCH,
  getDistributors: GET_DISTRIBUTORS_SERVICES,
};

const mutation = {
  createWebOrder: CREATE_WEB_ORDER,
};

export { CREATE_WEB_ORDER };

export { query, mutation };
