import styled from 'styled-components';

const TitleTextWrap = styled.div`
  svg {
    g {
      text {
        color: #6b6c7e;
        font-weight: 400;
        fill: #6b6c7e;
        &:last-child {
          fill: #272833;
        }
      }
    }
  }
`;

export default TitleTextWrap;
