import styled from 'styled-components';

export const CallPlanDrawerContainer = styled.section`
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1040;
    transition: opacity 0.3s ease 0s;
    background-color: rgba(57, 58, 74, .8);
  }

  .drawer-container {
    position: fixed;
    border-top: 1px solid rgb(231, 231, 237); 
    bottom: 0;
    width: calc(75% - (4rem + 56px));
    padding: 0;
    transition: top 0.5s ease-in-out;
    top: calc(100% - 5rem);
    &.active {
      box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px 0px;
      z-index: 1045;
      background: #fff;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .call-target-title{
      padding:16px;
      .icon{
       cursor:pointer;
      }
    }
  }

  .call-plans-details-summary {
    max-height: calc(100% - 7rem);
    overflow-y: auto;
    &>table{
      &>tbody tr,
      &>thead tr{
        td,
        th{
          &:first-child{
            padding-left:24px !important;
          }
          &:last-child{
            padding-right:24px !important;
          }
        }
      }
    }
  }

  .save-call-plan-btn {
    display: flex;
    position: fixed;
    align-items: center;
    bottom: 1rem;
    right: 1rem;
    justify-content: flex-end;
  }

  .call-target-title {
    display: flex;
    align-items: center;
    gap: 1rem;

    .icon {
      color: #0B4FFF;
    }

    h3 {
      margin: 0;
      font-weight: 400;
    }

    .call-target-summary {
      margin-left: 3rem;
      gap: 3rem;
      display: flex;
      flex-direction: row;
        label {
          text-transform: capitalize;
          display: block;
          font-size: 12px;
          margin-bottom: 6px;
        }

        .call-target-value {
          color: #A7A9BC;
          font-size: 16px;
          font-weight: 500;
          &.primary {
            color: #0B4FFF;
          }
        }
    }

    button {
      margin-left: auto;

      .icon {
        color: rgb(107, 108, 126);
      }
    }
  }

  table {
    border: none !important;
    thead > tr > th.left {
      &:first-child {
        padding-left: 0;
      }
    }
    tbody{
      tr > td > .form-input {
        input {
          max-width: 6rem;
          padding: 8px;
          height: 2rem;
        }
      }

     tr > td.remove > span > svg.icon {
        color: #DA1414
      }

      td:first-child {
        padding: 0 !important;
      }
    }
  }

  table th:first-child, table td {
    border-left: none !important;
    border-right: none !important;
  }

  #sku-title {
    padding: 2rem 24px 0 24px !important;
    font-size: 16px;
    font-weight: 500;
    border: none !important;
  } 
`;

