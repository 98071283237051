import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { FILTER } from '../../../data/enums/filter';
import { inventory } from '../../../data/enums/Route';
import { refGenerator } from '../../../utils';
import { getFilterConfig } from '../../../utils/filter';
import { ico } from '../../common/DomainConfig';

const breadCrumb = [
  inventory,
];

const filter = getFilterConfig([FILTER.BRAND, FILTER.SKU_FAMILY, FILTER.POWER_SKU]);

const title = `${ico.title} (Inventory Control Objective)`;

const formConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.MAPPER]: element => ({
      file: element.file || {},
    }),
    [FORM_CONFIG.TITLE]: 'File Upload',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.REQUEST_ORDER]: {
    [FORM_CONFIG.TITLE]: 'Confirm',
  },
};

export {
  breadCrumb, filter, title, formConfig,
};
