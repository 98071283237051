import React, { Component } from 'react';
import RouteActions from '../../components/RouteActions/RouteActions';

class RouteCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      multiType: '',
    };
  }

  render() {
    const { routesList, routeClick, showRenameModal, showDeleteModal } = this.props;
    return (
      <div className='route-li-wrap'>
        {routesList.map((route, i) => (
          <div
            className="routeRow"
            key={route.dse}
            style={{
              borderLeft: `4px solid ${route.color}`,
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
            }}
          >
            {/* <CheckBox
                            onCheckBoxClick={(e) => this.secondaryCheckBoxClick(e)}
                            checked
                        /> */}
            {/* Might need later */}

            <div className='info-li' onClick={(e) => routeClick(route.dse, i)} style={{ cursor: 'pointer' }}>
              <p className='routeText'>{`${route.name}`}</p>
              <span className='route-details'>
                <p className='outletText'>{`${route.travel.length} outlets`}</p>
                <p className='outletText'>{`${(route.distance / 1000).toFixed(2)} km`}</p>
              </span>
            </div>
            <RouteActions
              showRenameModal={showRenameModal}
              showDeleteModal={showDeleteModal}
              routeIndex={route.dse}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default RouteCards;
