import styled from 'styled-components';

const SelectPageStyled = styled.div`
  .custom-select-wrapper {
    margin-right: 16px;
    position: relative;
    outline: none;
  }

  .pagination-display-label {
    cursor: pointer;
  }

  .custom-select-option {
    margin-bottom: 0;
    min-height: auto;
    cursor: pointer;
    background-color: transparent;
  }
  .custom-select {
    padding: 6px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    min-width: 106px;
    font-weight: 600;
    color: #6b6c7e;
    transition: all 0.3s ease-in;
    &.pg-dropdown-active {
      border: 1px solid #cdced9;
      color: #272833;
      background: #f1f2f5;
      transition: all 0.3s ease-out;
    }
  }
  .custom-options-list-bottom {
    position: absolute;
    bottom: 110%;
    background: #fff;
    border-radius: 4px;
    min-width: 110px;
    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    li {
      padding: 10px 14px;
      font-size: 12px;
      cursor: pointer;
      font-weight: 600;
      color: #6b6c7e;
      &:before,
      &:after {
        color: #0056e0;
      }
      &:hover {
        color: #272833;
        font-weight: 600;
      }
    }
  }
`;

export default SelectPageStyled;
