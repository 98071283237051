import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { TableHeader, TableBody } from './tableConfig';
import { BulkDelete, Pagination } from '../../../v4/components';
import { STATEFUL_ENTITIES } from '../../../data/enums/GraphQL';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import PushMessageWrapper from './PushMessageWrapper';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  checkedList: PropTypes.instanceOf(Array),
  onBulkDelete: PropTypes.func.isRequired,
  onReplicateTypeClick: PropTypes.func,
  permission: PropTypes.instanceOf(Object),
  showBulkSelect: PropTypes.bool,
  getCheckedListData: PropTypes.func,
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onReplicateTypeClick: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  checkedList: [],
  permission: PERMISSION_OBJ,
  showBulkSelect: false,
  getCheckedListData: () => null,
};

function View({ ...props }) {
  const {
    data,
    pagination,
    permission,
    onIconClick,
    onPageChange,
    onBulkDelete,
    onReplicateTypeClick,
    getCheckedListData,
    showBulkSelect,
    dataDictionaryList,
  } = props;
  return (
    <Fragment>
      <PushMessageWrapper>
        <BulkDelete
          data={data.list}
          type={STATEFUL_ENTITIES.MESSAGE}
          handler={onBulkDelete}
          showBulkSelect={showBulkSelect}
          getCheckedListData={getCheckedListData}
          onReplicateClick={onIconClick}
          onReplicateTypeClick={onReplicateTypeClick}
          render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
            <div className='table-wrap'>
              <table>
                {TableHeader({
                  dataListLength: data.list.length,
                  checkedListLength: checkedList.length,
                  primaryCheckboxHandler,
                  permission,
                })}

                <tbody>
                  {data.list.map((pushMessage) =>
                    TableBody({
                      data: pushMessage,
                      checkedList: checkedList,
                      dataDictionaryList,
                      secondaryCheckboxHandler,
                      permission,
                      onIconClick,
                    }),
                  )}
                </tbody>
              </table>
            </div>
          )}
        />
      </PushMessageWrapper>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
}
View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
