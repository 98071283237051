import gql from 'graphql-tag';
import { GET_SUB_D_LIST } from '../../views/configuration/subD/API';
import {
  clientResolver, apolloClient, DEFAULT_QUERY_VARIABLES, errorProcessor
} from '../services/common';

const TABLE = {
  SUB_D: 'sub_d',
};

const getSubDListFromServer = () =>
  apolloClient
    .query({
      query: GET_SUB_D_LIST,
      variables: {
        ...DEFAULT_QUERY_VARIABLES,
      },
    })
    .then((response) => response.data.distributors.rows)
    .catch((err) => errorProcessor(err));

const query = gql(`
    query getSubDList {
        ${TABLE.SUB_D} {
            id
            title
        }
    }
`);

const insertSubDList = (data) => {
  clientResolver.then((client) =>
    client.writeQuery({
      query,
      data: {
        [TABLE.SUB_D]: data,
      },
    }),
  );
};

const fetchAndSetSubDList = async () => {
  const subDList = await getSubDListFromServer();
  insertSubDList(subDList);
};

const getSubDList = async () =>
  clientResolver.then((client) => client.readQuery({ query })).then((response) => response[TABLE.SUB_D] || []);

export {
  TABLE, insertSubDList, fetchAndSetSubDList, getSubDList
};
