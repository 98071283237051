import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TableHeader from '../../../base/TableHeader';
import { status } from './config';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { TAS_USERDETAIL_DASHBOARD } from '../../../../data/enums/Route';

const labelMappings = {
  name: 'name',
  status: 'status',
  scheduleCall: 'scheduled',
  successfulCall: 'successful',
  unsuccessfulCall: 'unsuccessful',
  remainingCall: 'remaining',
  productivity: 'productivity',
  target: 'target',
  salesOrderReceived: 'achievementValue',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.name,
    title: 'Name',
    show: true,
    sortable: true,
  },
  {
    id: 2,
    label: labelMappings.status,
    title: 'Status',
    show: true,
    classNames: 'text-center',
    sortable: true,
  },
 
  {
    id: 3,
    label: labelMappings.scheduleCall,
    title: 'Schedule call',
    show: true,
    classNames: 'text-right',
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 4,
    label: labelMappings.successfulCall,
    title: 'Successful Call',
    show: true,
    classNames: 'text-right',
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 5,
    label: labelMappings.unsuccessfulCall,
    title: 'Unsuccessful call',
    show: true,
    classNames: 'text-right',
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 6,
    label: labelMappings.remainingCall,
    title: 'Remaining call',
    show: true,
    classNames: 'text-right',
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 7,
    label: labelMappings.productivity,
    title: 'Productivity(%)',
    show: true,
    classNames: 'text-right',
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 8,
    label: labelMappings.target,
    title: 'Target',
    show: true,
    classNames: 'text-right',
    sortable: true,
    className: 'sorting text-right',
  },
  {
    id: 9,
    label: labelMappings.salesOrderReceived,
    title: 'Sales order received',
    show: true,
    classNames: 'text-right',
    sortable: true,
    className: 'sorting text-right',
  },
];
const TableHeaderConfig = ({ onHeaderClick }) => (
  <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
);

const returnTableBody = (data, index) => {
  const table = {};
  table[labelMappings.name] = (
    <Link to={`../${TAS_USERDETAIL_DASHBOARD}/${data.user_id}`}>
      <td key={`${data.id}-${index}-name`} className='pad-l-48'>
        {data.name}
      </td>
    </Link>
  );
  table[labelMappings.status] = (
    <td key={`${data.id}-${index}-status`} className='text-center'>
      <span className={`status-label ${parseInt(data.status, 10) === 1 ? 'active' : 'passive'}`}>
        {status[data.status]}
      </span>
    </td>
  );
  table[labelMappings.scheduleCall] = (
    <td key={`${data.id}-${index}-scheduleCall`} className='text-right'>
      {fixedFloatAndCommas(data.scheduled)}
    </td>
  );
  table[labelMappings.successfulCall] = (
    <td key={`${data.id}-${index}-successfulCall`} className='text-right'>
      {fixedFloatAndCommas(data.successful)}
    </td>
  );
  table[labelMappings.unsuccessfulCall] = (
    <td key={`${data.id}-${index}-unsuccessfulCall`} className='text-right'>
      {fixedFloatAndCommas(data.unsuccessful)}
    </td>
  );
  table[labelMappings.remainingCall] = (
    <td key={`${data.id}-${index}-remainingCall`} className='text-right'>
      {fixedFloatAndCommas(data.remaining)}
    </td>
  );
  table[labelMappings.productivity] = (
    <td key={`${data.id}-${index}-productivity`} className='text-right'>
      {`${fixedFloatAndCommas(data.productivity)}%`}
    </td>
  );
  table[labelMappings.target] = (
    <td key={`${data.id}-${index}-target`} className='text-right'>
      {fixedFloatAndCommas(data.target)}
    </td>
  );
  table[labelMappings.salesOrderReceived] = (
    <td key={`${data.id}-${index}-salesOrderReceived`} className='text-right'>
      {fixedFloatAndCommas(data.sales_order_received)}
    </td>
  );

  return table;
};
const TableBody = ({ data, index, handleRowClick }) => {
  const tableBody = returnTableBody(data, index);

  return (
    <tr
      key={`${data.id}-${index}-${data.route_id}`}
      onClick={() => handleRowClick(data.user_id)}
      className='action-row'
    >
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  handleRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func,
};

TableHeaderConfig.defaultProps = {
  onHeaderClick: () => null,
};

export { TableHeaderConfig as TableHeader, TableBody };
