import React, { Component } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { getDisplayName } from './component';
import apolloClient from '../../apiClient/apollo';
import { REQUEST } from '../../data/enums/GraphQL';

// TODO : generate single request tracker for multiple requests avoiding the state.
// See: https://facebook.github.io/react/docs/higher-order-components.html
export default function withRequestTracker({ query = {}, mutation = {} } = {}) {
  return SourceComponent => {
    class RequestTracker extends Component {
      state = {
        loadingCounter: 0,
        error: '',
      };

      interceptRequest = (requestName, requestType = REQUEST.MUTATION) => {
        const apiRequest = (variables, { handleSuccess, handleError } = {}, event = null) => {
          if (event) {
            event.preventDefault();
          }

          if (!requestName) {
            return;
          }

          const { props } = this;

          this.setState(state => ({
            loadingCounter: state.loadingCounter + 1,
            error: '',
          }));

          if (requestType === REQUEST.MUTATION) {
            props[requestName](variables)
              .then(response => {
                this.setState(state => ({
                  loadingCounter: state.loadingCounter - 1,
                }));
                if (handleSuccess) {
                  handleSuccess(response);
                }
              })
              .catch(error => {
                this.setState(state => ({
                  loadingCounter: state.loadingCounter - 1,
                  error: error.message,
                }));
                if (handleError) {
                  handleError(error);
                }
              });
          } else {
            apolloClient
              .query({
                query: query[requestName],
                variables,
              })
              .then(response => {
                this.setState(state => ({
                  loadingCounter: state.loadingCounter - 1,
                }));
                if (handleSuccess) {
                  handleSuccess(response);
                }
              })
              .catch(error => {
                this.setState(state => ({
                  loadingCounter: state.loadingCounter - 1,
                  error: error.message,
                }));
                if (handleError) {
                  handleError(error);
                }
              });
          }
        };

        return apiRequest;
      };

      updateState = (loadingCounter = 0, error = '') => {
        this.setState({
          loadingCounter,
          error,
        });
      };

      getRequestObjectWithTracker = () => {
        const request = {};
        Object.keys(mutation).forEach(requestName => {
          request[requestName] = this.interceptRequest(requestName, REQUEST.MUTATION);
        });
        Object.keys(query).forEach(requestName => {
          request[requestName] = this.interceptRequest(requestName, REQUEST.QUERY);
        });
        return request;
      };

      removeObjectProperty = (object, propertyList) => {
        const newObject = {};
        const attributeList = Object.keys(object);
        attributeList.forEach(attribute => {
          if (propertyList.indexOf(attribute) < 0) {
            newObject[attribute] = object[attribute];
          }
        });
        return newObject;
      };

      render() {
        const { loadingCounter, error } = this.state;
        const { ...oldProps } = this.props;
        // const oldPropsWithOutRequestList = this.removeObjectProperty(oldProps, requestList);
        const requestObjectWithTracker = this.getRequestObjectWithTracker();
        const newProps = {
          serverResponseWaiting: loadingCounter >= 1,
          serverResponseError: error,
          loading: loadingCounter >= 1,
          ...requestObjectWithTracker,
        };
        return <SourceComponent {...oldProps} {...newProps} />;
      }
    }

    RequestTracker.displayName = `LoadingAndError(${getDisplayName(SourceComponent)})`;
    hoistNonReactStatic(RequestTracker, SourceComponent);
    return RequestTracker;
  };
}
