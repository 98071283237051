import React from 'react';
import { CustomSelect } from '../../../components';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import ViewTypeStyled from './ViewTypeStyled';

interface ViewTypeProps {
  value: string;
  list: [];
  displayLabel: boolean;
  label: string;
  permission: any;
  valueKey: string;
  labelKey: string;
  handleDropDownChange: (type: string, e: any) => void;
}

const ViewType = ({ value, list, displayLabel, label, permission, valueKey, labelKey, handleDropDownChange }: ViewTypeProps) => (
  <ViewTypeStyled>
    <label htmlFor=''>{displayLabel ? `${label}` : null}</label>
    <CustomSelect
      disabled={!permission.read}
      options={list}
      className='custom-select'
      getOptionValue={(item: any) => item[valueKey].toLowerCase()}
      getOptionLabel={(item: any) => item[labelKey].toLowerCase()}
      onChange={(event: any) => handleDropDownChange('type', event[valueKey])}
      value={list.filter((item: any) => item[valueKey].toLowerCase() === value.toLowerCase())}
    />
  </ViewTypeStyled>
);

ViewType.defaultProps = {
  displayLabel: true,
  label: 'View By:',
  labelKey: 'label',
  list: [],
  permission: PERMISSION_OBJ,
  valueKey: 'label',
  value: '',
};

export default ViewType;
