import { totalPriceMapper } from '../create/config';
import { colSize } from '../../../../data/enums/Styles';
import { RETURN } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';
import { PAYMENT_MODE } from '../../orderProcessing/received/salesInvoice/customerDetail/config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'D&E Return',
    path: `/${RETURN}`,
    active: true,
  },
];

const summaryDetailsMapper = data => {
  const summaryList = [
    {
      title: 'SRN Invoice Number',
      value: data.SRNInvoiceNumber || '',
      colSpace: colSize.qtr,
    },
    {
      title: 'Sales Invoice Number',
      value: data.invoiceNumber || '',
      colSpace: colSize.qtr,
    },
    {
      title: 'Outlet',
      value: data.RetailOutlet ? data.RetailOutlet.title : '',
      colSpace: colSize.qtr,
    },
    ...(data?.Distributor?.servicesUsed?.billing?.status
      ? [
          {
            title: 'Payment Mode',
            value:
              data?.paymentMode === PAYMENT_MODE?.CREDIT_ROSIA ? PAYMENT_MODE?.CREDIT_BILLING : data?.paymentMode || '',
            colSpace: colSize.tri,
          },
        ]
      : []),
    {
      title: 'Ledger',
      value: data.Customer ? data.Customer.name || '' : '',
      colSpace: 4,
    },
    {
      title: 'Bill Name',
      value: data.customerName ? data.customerName : '',
      colSpace: 4,
    },
    {
      title: 'Address',
      value: data.Customer ? data.Customer.address || '' : '',
      colSpace: 4,
    },
    {
      title: 'PAN',
      value: data.Customer ? data.Customer.panNumber || '' : '',
      colSpace: 4,
    },
    {
      title: 'Remarks',
      value: data.reason || '',
      colSpace: colSize.l,
    },
    {
      title: 'SRN Date',
      value: data.srnDate ? data.srnDate : '',
      colSpace: colSize.qtr,
    },
  ];
  return summaryList;
};

const dataMapper = (data = {}) => ({
  srnInvoiceNumber: data.SRNInvoiceNumber || '',
  summary: summaryDetailsMapper(data),
  lines: data.SRNDetails || [],
  srnAmount: data.srnAmount || totalPriceMapper({}),
  Distributor: data.Distributor || { serviceUsed: {} },
  paymentMode: data?.paymentMode ?? PAYMENT_MODE?.CASH,
  customerName: data.customerName,
});

export { dataMapper as detailDataMapper, breadCrumb };
