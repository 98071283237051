import { envUrl } from '../services/ApiRest'
function initMap() {
  return new Promise((resolve, reject) => {
    let googleMap = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 27.6894, lng: 85.3227 },
      zoom: 12,
      mapTypeControl: false,
      streetViewControl: false,
    });
    window.globalMap = googleMap;
  });
}
const getBaseUrl = async () => {
  let baseUrl = '';
  await fetch(`${envUrl.http}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: `
      query Query {
        getGoogleMapsKey
      }`,
    }),
  })
    .then((res) => res.json())
    .then((res) =>{
      return baseUrl = res.data.getGoogleMapsKey.key}
    );
  return baseUrl;
};

async function createScriptLoadMap() {
  const key  =  await getBaseUrl();
  return new Promise((resolve, reject) => {
    if (!window.initMap) {
      let index = window.document.getElementsByTagName('script')[0];
      window.initMap = initMap;
      let script = window.document.createElement('script');
      const httpMap ='https://maps.';
      script.src =`${httpMap}googleapis.com/maps/api/js?key=${key}&libraries=drawing,places&callback=initMap`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        return resolve(window.globalMap);
      };
      index.parentNode.insertBefore(script, index);
      // initMap()
    } else {
      initMap();
      return resolve(window.globalMap);
    }
  });
}

export { initMap, createScriptLoadMap };
