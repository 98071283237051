import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { CONFIRMATION_CONFIG } from '../../../data/enums/config';

const formConfig = {
  ...CONFIRMATION_CONFIG,
};

const crudRequestConfig = {
  [EVENT_OPERATION.CHANGE_STATUS]: {
    objectMapper: (element) => ({
      ids: [...element.data],
      status: element.status,
    }),
    responseName: 'changePaymentStatus',
    message: `${MESSAGE_EVENT_OPERATION.UPDATE_STATUS}`,
  },
};

export { crudRequestConfig, formConfig };
