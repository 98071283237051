import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import DetailForm from './Form';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import withLoading from '../../../../../utils/composition/withLoading';
import { debouncer, dropdownChange, inputChange } from '../../../../../utils/formHandlers';
import { ALERT_TYPE } from '../../../../../data/enums/AlertType';
import withAlert from '../../../../../utils/composition/withAlert';
import { compareList } from '../../../../../utils/arrayProcessor';
import { refValidator } from '../../../../../utils/refGenerator';
import { summaryDetailsMapper } from './config';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  getDetails: PropTypes.func,
  getStatus: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  formConfig: PropTypes.shape({
    mapper: PropTypes.func,
    refsObj: PropTypes.instanceOf(Object),
    title: PropTypes.string,
    validationRequired: PropTypes.bool,
  }),
  update: PropTypes.instanceOf(Object),
  vendorList: PropTypes.instanceOf(Array),
  distributorList: PropTypes.instanceOf(Array),
  permission: PropTypes.instanceOf(Object),
  handleValueUpdate: PropTypes.func,
  invoiceNumberStatus: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  subDUser: PropTypes.bool,
  onInvoiceNumberChange: PropTypes.func,
  getInvoiceNumberList: PropTypes.func,
};

const defaultProps = {
  data: {},
  update: {
    type: '',
    status: false,
  },
  vendorList: [],
  distributorList: [],
  getStatus: () => null,
  getDetails: () => null,
  enableErrorDisplay: false,
  formConfig: {
    mapper: () => ({}),
    refsObj: {},
    title: '',
    validationRequired: true,
  },
  permission: PERMISSION_OBJ,
  handleValueUpdate: () => null,
  invoiceNumberStatus: false,
  subDUser: false,
  onInvoiceNumberChange: () => null,
  getInvoiceNumberList: () => null,
};

class SummaryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backupData: { ...props.data },
      data: props.data,
      invoiceNumberList: [],
      loadingGrnStatus: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps;
    const { backupData } = prevState;
    if (!compareList(data, backupData)) {
      return {
        data: summaryDetailsMapper(nextProps.data),
        backupData: summaryDetailsMapper(nextProps.data),
      };
    }
    return data;
  }

  componentDidMount() {
    const { getStatus, getDetails } = this.props;
    getStatus(this.getValidationStatus);
    getDetails(this.exportData);
  }

  getInvoiceNumberList = () => {
    const { data, invoiceNumberList } = this.state;
    const { onInvoiceNumberChange, getInvoiceNumberList, displayAlert } = this.props;
    if (!data.distributorId) {
      displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Distributor is not selected');
      return 0;
    }

    if (data.grnInvoiceNumber.length >= 3) {
      let exactlyMatched = invoiceNumberList.indexOf(data.grnInvoiceNumber) > -1;
      if (!exactlyMatched) {
        this.setState({ loadingGrnStatus: true });
        getInvoiceNumberList(
          {
            invoiceString: data.grnInvoiceNumber,
            distributorId: data.distributorId,
          },
          {
            handleSuccess: response => {
              const grnData = response.data.searchGRNInvoiceNumber;
              exactlyMatched = grnData ? grnData.matched || false : false;
              this.setState(
                {
                  invoiceNumberList: grnData ? grnData.invoiceNumbers || [] : [],
                  loadingGrnStatus: false,
                },
                () => onInvoiceNumberChange(data.grnInvoiceNumber, exactlyMatched),
              );
            },
            handleError: error => {
              this.setState({ invoiceNumberList: [], loadingGrnStatus: false }, () =>
                onInvoiceNumberChange(data.grnInvoiceNumber, exactlyMatched),
              );
              displayAlert(ALERT_TYPE.DANGER, error);
            },
          },
        );
      } else {
        onInvoiceNumberChange(data.grnInvoiceNumber, exactlyMatched);
      }
    } else {
      this.setState({ invoiceNumberList: [], loadingGrnStatus: false }, () =>
        onInvoiceNumberChange(data.grnInvoiceNumber, false),
      );
    }
    this.resetData();
  };

  resetData = () => {
    const { data } = this.state;
    const { formConfig } = this.props;
    const refreshedData = formConfig[FORM_CONFIG.MAPPER]({
      invoiceNumber: data.grnInvoiceNumber,
      distributorId: data.distributorId,
    });
    this.setState({ data: refreshedData });
  };

  handleInputChange = (event, firstParam = '', paramList = []) => {
    const { data } = this.state;
    const updatedData = inputChange(data, event, firstParam, paramList);
    this.handleValueUpdateEffect(event.target.name, updatedData);
  };

  handleValueUpdateEffect = (field, updatedData) => {
    const { handleValueUpdate } = this.props;
    switch (field) {
      case 'grnInvoiceNumber':
        this.setState({ data: updatedData }, () => debouncer(this.getInvoiceNumberList, 300)());
        break;
      case 'distributorId':
        this.setState({ data: updatedData }, () => handleValueUpdate(field, updatedData.distributorId));
        break;
      default:
        this.setState({ data: updatedData });
        break;
    }
  };

  handleDropDownChange = (value, parameterRef = []) => {
    const { data } = this.state;
    const updatedData = dropdownChange(data, parameterRef, value);
    this.handleValueUpdateEffect(parameterRef[0], updatedData);
    // this.setState({ data: updatedData });
  };

  getValidationStatus = () => {
    const { formConfig, subDUser } = this.props;
    const ref = formConfig[FORM_CONFIG.REFS_OBJ];
    if (subDUser) {
      delete ref.distributorId;
    }
    return refValidator(ref);
  };

  exportData = () => {
    const { data } = this.state;
    return data;
  };

  render() {
    const {
      update,
      subDUser,
      formConfig,
      permission,
      vendorList,
      enableErrorDisplay,
      distributorList,
      invoiceNumberStatus,
    } = this.props;
    const { data, loadingGrnStatus, invoiceNumberList } = this.state;

    return (
      <Fragment>
        <DetailForm
          show
          update={update}
          data={data}
          subDUser={subDUser}
          enableErrorDisplay={enableErrorDisplay}
          handleInputChange={this.handleInputChange}
          handleDropDownChange={this.handleDropDownChange}
          vendorList={vendorList}
          distributorList={distributorList}
          refsObj={formConfig[FORM_CONFIG.REFS_OBJ]}
          invoiceNumberList={invoiceNumberList}
          permission={permission.PRN}
          loadingGrnStatus={loadingGrnStatus}
          invoiceNumberStatus={invoiceNumberStatus}
        />
      </Fragment>
    );
  }
}

SummaryDetails.propTypes = propTypes;

SummaryDetails.defaultProps = defaultProps;

export default withAlert()(withLoading(SummaryDetails));
