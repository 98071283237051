/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-children-prop */
import React from 'react';
import QRCode from 'react-qr-code';
import { Button } from '../../../../../../components';
import PageHeader from '../../../../../base/PageHeader';
import { QrCodeContainer } from '../LinkedDevicesStyled.jsx';
import useScanDevicesController from '../../Controllers/useScanDevicesController';
import { LINKED_DEVICE } from '../../../../../../data/enums/Route';
import history from '../../../../../../utils/history';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withErrorAndLoadingScreen from '../../../../../../utils/composition/withLoading';
import withAlert from '../../../../../../utils/composition/withAlert';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  serverResponseWaiting: false,
};

const AddLinkedDevice = (props) => {
  const { serverResponseWaiting, encryptedApiUrl } = useScanDevicesController(props);
  const parentBtn = () => <Button iconName='times' title='' onClick={() => history.push(`/${LINKED_DEVICE}`)} />;

  return (
    <ParentContainer>

      <PageHeader config={{ title: 'Scan Code' }} children={parentBtn()} />

      <QrCodeContainer>
        {!serverResponseWaiting && encryptedApiUrl ? (
          <div className='wrapper'>
            <h1 className='title'>ROSIA</h1>

            <h2 className='title-second'>Scan this QR code to link your device to ROSIA DMS</h2>

            <p className='qr-description'>
              Please open the ROSIA DMS App on your device. Once the 'Link Device with Code Scanner' option appears,
              please scan the QR Code below:
            </p>

            <div className='qr-wrapper'>
              <QRCode
                size={291}
                style={{ width: '291px', height: '291px' }}
                value={encryptedApiUrl}
                viewBox={'0 0 291 291'}
              />
            </div>

            <div className='copyright'>
              <img src={'/image/evolve-logo-dark.svg'} alt='Evolve logo' />
              <p className='copyright-desc'>Evolve Pvt. Ltd. 2023</p>
            </div>
          </div>
        ) : (
          ''
        )}
      </QrCodeContainer>
    </ParentContainer>
  );
};

AddLinkedDevice.propTypes = propTypes;

AddLinkedDevice.defaultProps = defaultProps;

export default withAlert()(withErrorAndLoadingScreen(AddLinkedDevice))


const ParentContainer = styled.div`
  height: 100%;
  width: 100%;

  & > div:first-child {
    margin-top: unset;
    padding: 16px 48px 17px;
    border-bottom: 1px solid rgb(231, 231, 237);
  }
`;
