import { reports as reportsRoute } from '../../../data/enums/Route';
import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';

const title = 'Distribution Coverage';

const breadCrumb = [reportsRoute];

const filter = getFilterConfig([FILTER.ROUTE, FILTER.SUB_D, FILTER.TOWN]);

export {
  breadCrumb as breadCrumbConfig, filter as filterConfig, title
};
