import { compose } from 'recompose';
import BulkSelect from './BulkSelect';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import { CHANGE_PAYMENT_STATUS } from './API';

const ComposedBulkSelect = compose(
  CHANGE_PAYMENT_STATUS,
  withRequestTracker({
    mutation: {
      changePaymentStatus: CHANGE_PAYMENT_STATUS,
    },
  }),
)(BulkSelect);

export default ComposedBulkSelect;