import React from 'react';
import PropTypes from 'prop-types';
import { Input, Form } from '../../../components';
import withLoading from '../../../utils/composition/withLoading';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    territoryId: PropTypes.number,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  handleDropDownChange: PropTypes.func,
  refsObj: PropTypes.instanceOf(Object),
  territories: PropTypes.instanceOf(Array),
};

const defaultProps = {
  handleInputChange: () => null,
  enableErrorDisplay: true,
  data: {
    name: '',
    territoryId: 0,
  },
  refsObj: {},
  territories: [],
  handleDropDownChange: () => null,
};

const TownForm = ({ ...props }) => {
  const { data, enableErrorDisplay, handleInputChange, refsObj } = props;
  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Name'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
      />
    </Form>
  );
};

TownForm.propTypes = propTypes;

TownForm.defaultProps = defaultProps;

const TownViewWithErrorAndLoading = withLoading(TownForm);

export default TownViewWithErrorAndLoading;
