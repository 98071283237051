import React from 'react';
import { refGenerator } from '../../../utils';
import { FILTER } from '../../../data/enums/filter';
import { getFilterConfig } from '../../../utils/filter';
import { FORM_CONFIG } from '../../../data/enums/config';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { inventory as inventoryRoute } from '../../../data/enums/Route';
import { fileParser } from '../../../utils/parser';
import { UPLOADABLES } from '../../../data/enums/GraphQL';
import { MESSAGE_EVENT_OPERATION } from '../../../data/enums/SuccessMessage';
import { Button, Icon } from '../../../v4/components';

const breadCrumb = [inventoryRoute];

const title = 'Stock Adjustment';
const formConfig = {
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      distributorId: element.distributorId || '',
      skuId: element.skuId || 0,
      skuTitle: element.skuTitle || '',
      skuBatchId: element.SKUBatch ? element.SKUBatch.id : '',
      reason: '',
      quantity:  element.has_warehouse ? element?.binWiseStock?.[0]?.balance : element.quantity || 0,
      damages:  element.has_warehouse ? element?.binWiseStock?.[0]?.damagedStock : 0,
      expiry: element.has_warehouse ? element?.binWiseStock?.[0]?.expiredStock : 0,
      shortages: 0,
      binWiseStock: element.binWiseStock,
      has_warehouse: element.has_warehouse || false,
      bin_location_id: element.has_warehouse ? element?.binWiseStock?.[0]?.bin_location_id : null,
      balance: element.has_warehouse ? element?.binWiseStock?.[0]?.balance : element.balance || 0,
      damagedStock: element.has_warehouse ? element?.binWiseStock?.[0]?.damagedStock : element.damagedStock,
      expiredStock: element.has_warehouse ? element?.binWiseStock?.[0]?.expiredStock : element.expiredStock,
      shortageStock: element.has_warehouse ? element?.binWiseStock?.[0]?.shortageStock : element.shortageStock,
      adjustment_type: element.adjustment_type || '',
      per_unit_rate: element.per_unit_rate || 0,
      batch_name: element.SKUBatch ? element.SKUBatch.batchName : '',
    }),
    [FORM_CONFIG.TITLE]: 'Stock Adjust',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['reason', 'quantity', 'skuTitle','adjustment_type']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    buttonName: 'Adjust'
  },
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      file: element.file || {},
    }),
    [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPLOAD} ${title}`,
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.BIN_CONFIRM]: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      ...element,
    }),
    [FORM_CONFIG.TITLE]: 'Confirmation',
    getExtraFooterElements: (callBack) => (
      <Button secondary onClick={(e) => callBack()}>
        <span>Back</span>
      </Button>
    ),
    getExtraHeaderElements: (callBack) => (
      <Button>
        <Icon iconName='times' onClick={(e) => callBack()} />
      </Button>
    ),
    buttonName: 'Confirm'
  },
};

const crudRequestConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.STOCKADJUSTMENT,
        },
      }));

      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

const filter = getFilterConfig([FILTER.BRAND, FILTER.SUB_BRAND_FORM]);

const adjustmentTypeList =[
  { title: 'Audit Correction', type: 'AC' },
  { title: 'Stock Transfer', type: 'ST' },
  { title: 'Claim Reconciliation', type: 'CR' },
]
export {
  formConfig, crudRequestConfig, breadCrumb as breadCrumbConfig, filter as filterConfig,adjustmentTypeList
};
