import React from 'react';
import PropTypes from 'prop-types';
import {
  CustomSelect, Form, Input
} from '../../../../../../components';
import { SingleDatePicker } from '../../../../../../v4/components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { getStartOfCurrentMonth } from '../../../../../../utils/date';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  enableRouteSegmentation: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: {
    name: '',
    description: '',
  },
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  handleDropDownChange: () => null,
  options: {},
};

const SkuGroupForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay,handleDropDownChange, options, enableRouteSegmentation } = props;

  return (
    <Form>
      <Input
        ref={(ref) => (refsObj.title = ref)}
        value={data.title}
        name='title'
        type='text'
        placeholder='Description'
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
        onChange={(event) => handleInputChange(event)}
        labelContent='Description'
      />
      {enableRouteSegmentation && (
        <CustomSelect
          labelContent='Line'
          placeholder='Select'
          getOptionValue={({ id }) => id}
          getOptionLabel={({ title }) => title}
          options={options}
          name='Line'
          value={options.filter(({ id }) => id === data.lineId)}
          onChange={(event) => handleDropDownChange(event.id, ['lineId'])}
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          ref={(ref) => (refsObj.lineId = ref)}
        />
      )}
      <div className='single-date-picker'>
        <label>Start Date</label>
        <SingleDatePicker
          date={data.start}
          name='start'
          placeholder='Start Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name: 'start',
                },
                formattedValue: date,
              },
              '',
            )
          }
          labelContent='Start Date'
          maxDate={data.end}
          minDate={getStartOfCurrentMonth()}
        />
      </div>
      <div className='single-date-picker'>
        <label>End Date</label>
        <SingleDatePicker
          date={data.end}
          name='end'
          placeholder='End Date'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(name, date) =>
            handleInputChange(
              {
                target: {
                  name,
                },
                formattedValue: date,
              },
              '',
            )
          }
          labelContent='End Date'
          minDate={data.start}
        />
      </div>
    </Form>
  );
};

SkuGroupForm.propTypes = propTypes;

SkuGroupForm.defaultProps = defaultProps;

const ComposedSkuGroupForm = withLoading(SkuGroupForm);

export default ComposedSkuGroupForm;
