import React, { Component } from 'react';
import { Input, Form } from '../../components';
import { CheckBox } from '../../v4/components'

class Dummy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ageNumber: 5,
      rollNumber: 12,
      textField: '',
      checkBoxStatus: false,
    };
  }

  handleCheckBoxClick = () => {
    alert('CheckBox has been clicked');
    const { checkBoxStatus } = this.state;
    // this.setState({ checkBoxStatus: !checkBoxStatus });
    this.setState({ checkBoxStatus: !checkBoxStatus });
  };

  handleInputChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { ageNumber, textField, rollNumber, checkBoxStatus } = this.state;
    return (
      <Form>
        <Input
          value={textField}
          name="textField"
          type="text"
          enableErrorDisplay
          onChange={event => this.handleInputChange(event)}
        />
        <Input
          value={ageNumber}
          name="ageNumber"
          type="number"
          placeholder="Number"
          enableErrorDisplay
          onChange={event => this.handleInputChange(event)}
        />
        <Input
          value={rollNumber}
          name="rollNumber"
          type="number"
          placeholder="Number"
          enableErrorDisplay
          onChange={event => this.handleInputChange(event)}
        />
        <CheckBox checked={checkBoxStatus} multiple onClick={() => this.handleCheckBoxClick()} />
      </Form>
    );
  }
}

export default Dummy;
