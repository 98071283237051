import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { USER_ROLE } from '../../../../data/enums';
import { getUserRole } from '../../../../data/dao';
import { FORM_CONFIG } from '../../../../v4/constants/formConfig';

const getPermissionToViewStockInfo = () => {
  const userRole = getUserRole();
  const permittedUserRole = [USER_ROLE.DISTRIBUTOR_ADMIN, USER_ROLE.BILLING_USER];
  return permittedUserRole.includes(userRole);
};

const crudRequest = {
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => ({
      id: data.id,
      quantity: data.quantity,
    }),
    responseName: 'updateOrder',
    message: 'Order updated successfully.',
  },

  [EVENT_OPERATION.DELETE]: {
    objectMapper: (data) => ({
      orderIds: [data.id],
      reason: data.reason,
    }),
    responseName: 'cancelOrders',
    message: 'Order updated successfully.',
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper1: (data) => ({
      orderIds: data.ids,
      reason: data.reason,
    }),
    objectMapper: (data) => ({
      orderIds: data.ids,
      reason: data.reason,
    }),
    responseName: 'cancelOrders',
    message: 'Orders cancelled successfully.',
  },
  [EVENT_OPERATION.SPLIT]: {
    objectMapper: (data) => ({
      quantity: data.splitList.map((item) => item.quantity),
      id: data.id,
    }),
    responseName: 'splitOrder',
    message: 'Orders splitted successfully.',
  },
};

const editReasonsList = [
  { reason: 'Out of Stock' },
  { reason: 'Insufficient Stock' },
  { reason: 'Wrong Order Punched' },
];

const formConfig = {
  ['CONFIRMATION']: {
    [FORM_CONFIG.MAPPER]: (element) => ({
      ...element,
    }),
    [FORM_CONFIG.TITLE]: 'Confirm GID',
  },
};

export {
  crudRequest, getPermissionToViewStockInfo, editReasonsList, formConfig
};

export default crudRequest;
