import styled from 'styled-components';

const FormContentStyled = styled.div`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  & > h3,
  .inner-h3-header {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #6b6c7e;
    padding: 4px;
    border-bottom: 1px solid #cdced9;
    /* width:524px; */
    width: 48%;

    &.w-100 {
      width: 100%;
      padding-top: 8px;
    }

    &.w-60 {
      width: 740px;
      width: 67%;
    }
  }

  .inner-h3-header {
    width: 100%;
  }

  .form-input-wrap {
    width: 48%;
    padding: 24px 0;

    &.no-line-segmented {
      padding: 0;
    }
    .line-checkbox {
      margin-right: 55px;
    }

    &.padding-top-zero {
      padding-top: 0;
    }

    &.padding-bottom-zero {
      padding-bottom: 0;
    }

    .margin-bottom-zero {
      margin-bottom: 0 !important;
    }

    /* holiday-status-checkbox start */

    &.w-60 {
      width: 740px;
      width: 67%;

      .checkbox-list {
        .checkbox {
          margin-right: 12px;

          label {
            margin: 0;
            display: flex;
            flex-wrap: wrap;

            & > span {
              padding-left: 0;

              & > span {
                margin-left: 7px;
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    /* w-100 is 100% styling  */

    &.w-100 {
      width: 100%;
      padding-bottom: 0;

      .form-select-input,
      .form-input {
        label {
          width: calc(166px - 10px);
          width: calc(31% - 10px);
          margin-right: 10px;
        }

        .select-css,
        input {
          width: calc(100% - 166px);
          width: calc(100% - 31%);
          flex: 1;
        }
      }

      /* channel categories list only styling */

      .category-wrap {
        display: flex;
        flex-wrap: wrap;

        .form-input {
          width: 48.57%;
          flex: unset;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      /* channel categories list only styling */
    }

    /* for swtich only styling */

    .form-select-input,
    .form-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 24px;

      &.text-area {
        align-items: flex-start;
        label {
          margin-top: 8px;
          line-height: 24px;
        }
        textarea {
          flex: 1;
          border: 1px solid #e7e7ed;
          border-radius: 4px;
          background-color: #f1f2f5;
          color: #272833;
          font-size: 16px;
          line-height: 24px;
          height: 115px;
          overflow: auto;
          resize: none;
          font-family: inherit;
          padding: 8px 16px;
          &:focus {
            border: 1px solid #e7e7ed;
            outline: none;
          }
        }
      }
      &.switch {
        justify-content: unset;

        & + span {
          font-size: 10px;
          color: #6b6c7e;
          line-height: 10px;
          margin-top: -20px;
          display: block;
          width: 32%;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .form-error {
        width: 100%;
        text-align: right;
      }

      label {
        font-size: 14px;
        line-height: 21px;
        color: #272833;
        font-weight: 500;
        width: 262px;
        width: calc(48.57% - 10px);
        margin: 0;
        margin-right: 10px;
      }

      /* checkbox only  start */

      .checkbox {
        width: calc(100% - 48.57%);
        flex: 1;
        display: flex;

        label {
          min-width: unset;
          width: unset;
          margin-right: 24px;

          input {
            width: unset;
            margin: 8px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      /* checkbox only  end*/

      .select-css,
      input {
        width: calc(100% - 48.57%);
        flex: 1;
        align-self: end;
        border: 1px solid #e7e7ed;
        background-color: #f1f2f5;
        border-radius: 4px;

        .zindex-2__control {
          border: 0;
          background-color: #f1f2f5;
        }
      }

      /* for upload section only  */

      .upload-input {
        border-radius: 4px;
        width: 51%;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        border-radius: 4px;
        background-color: #f1f2f5;

        button {
          color: #6b6c7e;
          display: flex;
          align-items: center;
          font-weight: 500;
          background-color: #ffffff;
          border: 1px solid #cdced9;
          border-right: 0;
          border-radius: 4px 0 0 4px;

          span {
            margin-right: 14px;
          }
        }

        .no-file {
          padding-left: 16px;
          height: 40px;
          line-height: 40px;
          flex: 1;
          color: #272833;
          border: 1px solid #e7e7ed;
          border-radius: 0 4px 4px 0;
          display: flex;
          align-items: center;

          .file-name {
            margin: 8px 16px 8px 0;
            padding: 4px 8px;
            background-color: #ffffff;
            border: 1px solid #cdced9;
            font-size: 12px;
            line-height: 15px;
            color: #6b6c7e;
            flex: 1;
            text-transform: uppercase;
            font-weight: 500;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg{
              height: 12px;
              width: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  /* push message compose section only  */
  &.push-message-compose {
    & > span,
    .form-input-wrap {
      display: inline-block;
      width: 60%;
    }
    & > span {
      margin-left: 15px;
      border: 1px solid #e7e7ed;
      border-radius: 4px;
      padding: 12px 12px;
      font-size: 10px;
      line-height: 16px;
      color: #272833;
      background-color: #ffffff;
      width: 37%;
      p {
        display: flex;
        margin: 0;
        .icon {
          height: 10px;
          width: 10px;
          color: #6b6c7e;
          margin-right: 8px;
          position: relative;
          top: -1.5px;
        }
        b {
          color: #6b6c7e;
          margin-bottom: 8px;
          font-weight: 400;
          display: block;
          line-height: 12px;
        }
        strong {
          display: block;
          width: 100%;
          font-weight: 400;
        }
      }
    }
  }
`;
export default FormContentStyled;
