import gql from 'graphql-tag';

const LAST_SYNC_TIME = gql`
  query getLastSyncTime {
    getLastSyncTime
  }
`;

const DASHBOARD_TAS = gql`
query User {
  tasDashboard {
    user:User {
      id
      firstName
      lastName
      fullName
    }
    monthlyDetail {
      target
      achievement
      coverage
      active
      sbd
      ach
      productivity
    }
    callageDetail {
      scheduled
      successful
      unsuccessful
      remaining
      productivity
    }
    salesDetail {
      target
      received
      achievement
      achievementpercent
      lpc
      bpc
      invoiced_amount
      dispatched_amount
      offroute_amount
    }
    brandDistributor {
      brand
      target
      achievement
      ach
    }
    userStatus {
      calls {
        lpc
        bpc
        focusedskupercall
        sbdskupercall
      }
      delivery {
        countInvoiced
        countOrders
        countReturn
      }
      tasUsers {
        active
        inactive
        leave
      }
    }
  }
}
`;
const SBD_POINTS = gql`
query GetBrandWiseSBDForTAS {
  getBrandWiseSBDForTAS {
    brand_id
    Brand:brand
    ap
    gp
    SBD:sbd
  }
}
`;
const DASHBOARD_TAS_USER_DETAILS = gql`
query GetTasUserStateDetailList($filter: FilterInput) {
  getTasUserStateDetailList(filter: $filter) {
    rows {
      user_id
      name
      distributor_id
      distributor
      status
      scheduled
      successful
      unsuccessful
      remaining
      productivity
      target
      received:sales_order_received
    }
  }
}
`;

const query = {
  getLastSyncTime: LAST_SYNC_TIME,
  getDashboardInfo: DASHBOARD_TAS,
  getSBDPoints :SBD_POINTS,
  getTasUserStateDetailList:DASHBOARD_TAS_USER_DETAILS,
};
const mutation = {};

export { query, mutation };
