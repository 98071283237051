import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Pagination } from '../../../v4/components';
import { TableHeader, TableBody } from './tableConfig';
import withImageSlider from '../../../utils/composition/withImageSlider';
import { dataProps, paginationProps } from '../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  handleData: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  handleSliderOpen: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  onPageChange: () => null,
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
};

const DetailView = ({ ...props }) => {
  const { data, onPageChange, pagination, handleData, onIconClick, permission, handleSliderOpen } = props;

  return (
    <Fragment>
      <div className='table-wrap'>
        <table>
          {TableHeader({
            dataListLength: data.list.length,
            permission,
          })}

          <tbody>
            {data.list.map((attendance) =>
              TableBody({
                data: attendance,
                handleSliderOpen,
                handleIconClick: onIconClick,
                permission,
                handleData,
              }),
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    </Fragment>
  );
};

DetailView.propTypes = propTypes;

DetailView.defaultProps = defaultProps;

const DetailViewWithErrorAndLoading = withErrorAndLoadingScreen(DetailView);

const DetailViewWithImageSlider = withImageSlider(DetailViewWithErrorAndLoading);

export default DetailViewWithImageSlider;
