import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { PanelHeader } from '../../configuration';
import { Filter } from '../../../../components';
import {
  BreadCrumb, Icon,Button
} from '../../../../v4/components';
import { WIDGETS } from '../../../../data/enums/config';
import PageHeaderStyled from './PageHeaderStyled';

const propTypes = {
  printEnableStatus:PropTypes.bool,
};

const defaultProps = {
  actions: {
    [WIDGETS.SEARCH]: false,
    [WIDGETS.FILTER]: false,
    [WIDGETS.DOWNLOAD]: false,
    [WIDGETS.UPLOAD]: false,
    [WIDGETS.DELETE]: false,
    [WIDGETS.UPDATE]: false,
    [WIDGETS.CREATE]: false,
    [WIDGETS.MANUAL]: false,
    [WIDGETS.PRINT]: false,
    printEnableStatus:true,
  },
  notification: {
    status: true,
  },
  getActionConfig: () => ({}),
};
class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionConfigs: {},
    };
  }

  componentDidMount() {
    this.configureActionConfigs();
  }

  fetchActionConfig = type => {
    const { getActionConfig } = this.props;
    const config = getActionConfig(type);

    return config;
  };

  configureActionConfigs = () => {
    const { actions } = this.props;
    const actionConfigs = {};
    Object.keys(actions).forEach((key, index) => {
      actionConfigs[key] = this.fetchActionConfig(key);
    });
    this.setState({ actionConfigs });
  };

  render() {
    const { breadCrumb, title, actions, getActionConfig, notification ,printEnableStatus} = this.props;
    const { actionConfigs } = this.state;
    return (
      <>
        {breadCrumb.length > 0 && <BreadCrumb list={breadCrumb} />}
        <PageHeaderStyled>
          <PanelHeader>
            <h2>{title}</h2>
            <div className="filter-wrap ">
              {actions[WIDGETS.SEARCH] && <span> this is search</span>}
              {actions[WIDGETS.PRINT] && printEnableStatus && (
                <span className="icon-wrapper">
                  <Icon iconName="print" onClick={() => actionConfigs[WIDGETS.PRINT].handler()} />
                </span>
              )}
              {actions[WIDGETS.FILTER] && (
                <Filter
                  metaData={actionConfigs[WIDGETS.FILTER].data ? actionConfigs[WIDGETS.FILTER].data.filter : () => null}
                  menuList={actionConfigs[WIDGETS.FILTER].data.menuList || []}
                  onFilterChange={actionConfigs[WIDGETS.FILTER].handler() || null}
                />
              )}
              {actions[WIDGETS.DOWNLOAD] && (<span className="icon-wrapper" secondary onClick={() => actionConfigs?.[WIDGETS.DOWNLOAD]?.handler(WIDGETS.DOWNLOAD, {})}>
                  <Icon iconName="download" />
              </span>
              )}
              {actions[WIDGETS.UPLOAD] && (
                <span className="icon-wrapper" onClick={() => actionConfigs[WIDGETS.UPLOAD].handler(WIDGETS.UPLOAD, {})}>
                  <Icon iconName="upload" />
                </span>
              )}
              {actions[WIDGETS.DELETE] && <span> this is delete</span>}
              {actions[WIDGETS.UPDATE] && (
                <Button secondary onClick={actionConfigs[WIDGETS.UPDATE].handler()}>
                  <Icon iconName="pencil" />
                </Button>
              )}
              {actions[WIDGETS.MANUAL] && <span />}
              {actions[WIDGETS.CREATE] && (
                <Button
                  outlined
                  iconName="plus"
                  onClick={() => actionConfigs[WIDGETS.CREATE].handler(EVENT_OPERATION.CREATE, {})}
                >
                  <span>{EVENT_OPERATION.CREATE.toLowerCase()}</span>
                </Button>
              )}
            </div>
          </PanelHeader>
        </PageHeaderStyled>
      </>
    );
  }
}

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default PageHeader;