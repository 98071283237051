import { compose } from 'recompose';
import Detail from './Detail';
import { UPLOAD, DOWNLOAD_REPORT } from '../../../common/GqlAPI';
import {
  query,
  mutation,
  CREATE_BRANDWISE_DISTRIBUTION_TARGET,
} from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedDetail = compose(
  CREATE_BRANDWISE_DISTRIBUTION_TARGET,
  UPLOAD,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  })
)(Detail);


export default ComposedDetail;
