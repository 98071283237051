import { GET_REPLENISHMENT_GROUPS } from '../API';
import { DOWNLOAD_REPORT } from '../../../../common/GqlAPI';

const query = {
  getSubDAssortmentDetails: GET_REPLENISHMENT_GROUPS,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { query, mutation };
