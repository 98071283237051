import { getFilterConfig } from '../../../utils/filter';
import { FILTER } from '../../../data/enums/filter';
import { support } from '../../../data/enums/Route';

const breadCrumbConfig = [support];

const title = 'De-Board Outlet Request';
const filter = getFilterConfig([FILTER.REQUESTS_STATUS, FILTER.STL, FILTER.ASM, FILTER.TOWN, FILTER.SUB_D]);

export {
  breadCrumbConfig, title, filter as filterConfig
};
