import React from 'react';
import PropTypes from 'prop-types';
import TitleText from '../../../components/Chart/TitleText';
import { getDataItem, WORKING_STATUS } from '../config';

const propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  dimensions: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  withoutUserLabel: PropTypes.bool,
};

const defaultProps = {
  dimensions: {},
  id: '',
  withoutUserLabel:false,
};

const UserStatus = ({ data = {}, id, dimensions ,withoutUserLabel }) => (
  <div className='status-wrap'>
    {Object.keys(data).map((d, i) => (
      // <div style={{ display: 'auto' }} key={d}>
      <TitleText
        id={`${id}-i`}
        dimensions={dimensions}
        styleAdjustment={{ marginTop: 5 }}
        data={getDataItem(data[d], withoutUserLabel ? d :WORKING_STATUS[d])}
        key={i}
      />
    ))}
  </div>
);

UserStatus.propTypes = propTypes;

UserStatus.defaultProps = defaultProps;

export default UserStatus;
