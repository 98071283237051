import { fixedFloatAndCommas } from '../../../../utils/conversion';

const getMiscellaneousList = (data = {}, sbdStatus) => {
  const miscellaneousList = [
    {
      title: 'No. of Calls Scheduled',
      value: fixedFloatAndCommas(data?.countTotalCAll || 0,0) || 0,
      class:'planned'
    },
    {
      title: 'Call Plan Target',
      value: fixedFloatAndCommas(data?.total_call_plan_target || 0,0) || 0,
      class:''
    },
    {
      title: 'No. of Calls Planned',
      value: fixedFloatAndCommas(data?.total_call_planned || 0,0) || 0,
      class:''
    },
    {
      title: 'Productivity',
      value: `${fixedFloatAndCommas(data?.productivity || 0,0 )} %`|| 0,
      class:''
    },
    {
      title: 'No. of Calls Completed',
      value: fixedFloatAndCommas(data?.total_call_completed || 0,0) || 0,
      class:''
    },
    {
      title: 'SBD Count',
      value: `${fixedFloatAndCommas(data?.sbdCount || 0,0 )}`|| 0,
      class:''
    },
  ];

  return sbdStatus ? miscellaneousList : miscellaneousList.filter((list)=>list.title!=='SBD Count');
};

const getMainData = (receivedOrder=0,totalDuration={}) => {
  const mainDataList = [
    {
      title: 'Total Call Duration',
      value: `${totalDuration && totalDuration.minutes || 0}m ${totalDuration && totalDuration.seconds || 0}s`,
      class:'',
    },
    {
      title: 'Received Order Value',
      value: `NPR ${fixedFloatAndCommas(receivedOrder || 0,0 )}`,
      class:'received',
    },
  ] || [];

  return mainDataList || [];
};

export { getMiscellaneousList,getMainData, };
