import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import StockAdjustmentView from './View';
import { getUser } from '../../../data/services';
import PageHeader from '../../base/PageHeader';
import { DialogFormWrapper } from '../../common';
import { isError, getSortValueAfterValueCheck } from '../../common/HelperFunctions';
import StockAdjustmentForm from './StockAdjustmentForm';
import * as queryService from '../../base/query.service';
import * as downloadService from '../../base/download.service';
import { PanelStyled } from '../../common/configuration';
import { FORM_CONFIG } from '../../../data/enums/config';
import { stockAdjustment } from '../../common/DomainConfig';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { getPermissionForStockAdjustment } from '../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { formConfig, crudRequestConfig, breadCrumbConfig, filterConfig, adjustmentTypeList } from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { checkAndResetPaginatedData } from '../../../utils/paginatedListUtils';
import { getCurrentDay } from '../../../utils/date';
import { CSVUploadForm, Icon } from '../../../v4/components';
import { clone } from '../../../utils/objectProcessor';
import { PAGINATION } from '../../../data/enums';
import { StockAdjusStyled } from './StockAdjusStyled';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  upload: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  createStockAdjustment: PropTypes.func.isRequired,
  getStockAdjustmentList: PropTypes.func.isRequired,
  getStockAdjustmentBatchDetail: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  getStockAdjustmentBatchDetail: () => null,
};

class StockAdjustment extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.userInfo = getUser();
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
        upload: true,
      },
      distributorList: [],
      sku: {},
      dialog: {
        type: '',
        element: formConfig[EVENT_OPERATION.UPDATE][FORM_CONFIG.MAPPER]({
          distributorId: this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0,
          has_warehouse: this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].has_warehouse : false,
        }),
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: {},
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      stockAdjDetail: [],
      binList: [],
    };
    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
      [EVENT_OPERATION.UPDATE]: props.createStockAdjustment,
    };
    this.permission = getPermissionForStockAdjustment();
    this.componentConfig = stockAdjustment.componentsDisplay(this.permission);
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
    this.setData = data => {
      this.setState({ data });
    };
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
    this.onCRUDSuccess = crudResponseProcessor(
      (data, type) => {
        const { sku } = this.state;
        const { displayAlert } = this.props;
        let message = crudRequestConfig[type].message;
        if (type === EVENT_OPERATION.UPLOAD) {
          message = data;
          this.loadTableData();
          this.resetDialog();
        } else {
          this.setState(data);
          this.handleRowClick(sku.skuId);
        }
        displayAlert(ALERT_TYPE.SUCCESS, crudRequestConfig[type].message);
      },
      this.getData,
      ['balance', 'lastAdjustment', 'lastDate', 'reason', 'negative'],
      'skuId',
    );
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    const { dialog } = this.state;
    if (
      ADMIN_ROLE_GROUP.includes(this.userInfo.roleId) ||
      DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId)
    ) {
      this.getDistributorsList();
      this.getFilterStateFromStorage();
    }
    if (dialog.element.distributorId) this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const { dialog } = this.state;
    const {
      filterMenu,
      queryParameters: { filter, date, search },
    } = this.state;
    storeInLocalStorage('DIALOG', dialog, DOMAIN.STOCK_ADJUSTMENT);
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.STOCK_ADJUSTMENT,
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.STOCK_ADJUSTMENT);
    const localDialog = fetchFromLocalStorage('DIALOG', DOMAIN.STOCK_ADJUSTMENT);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      }),
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
        dialog: localDialog || dialog,
      },
      () => {
        this.loadTableData();
      },
    );
  };

  getBinList = async distributorId => {
    const { getLastLevelWarehouseDetails, displayAlert } = this.props;
    const { queryParameters } = this.state;
    getLastLevelWarehouseDetails(
      { distributorId },
      {
        handleSuccess: response => {
          this.setState({ binList: response?.data?.getLastLevelWarehouseDetails ?? [] });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          this.setState({ distributorList: response.data.distributors.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  loadTableData = () => {
    const { data, queryParameters, dialog } = this.state;
    const { getStockAdjustmentList, displayAlert } = this.props;
    if (dialog.element.distributorId) {
      const offset = getOffsetFromPagination(queryParameters.pagination);
      this.setFilterStateInStorage();
      getStockAdjustmentList(
        {
          offset,
          limit: queryParameters.pagination.limit,
          filter: {
            filters: [
              ...apiFilterProcessor(queryParameters.filter),
              { column: 'distributor_id', value: [dialog.element.distributorId.toString() || ''] },
            ],
            // dateRange: queryParameters.date,
            queryString: queryParameters.search,
            sort: getSortValueAfterValueCheck(queryParameters.sort),
          },
        },
        {
          handleSuccess: response => {
            data.list = response.data.stockAdjustments ? response.data.stockAdjustments.rows : [];
            data.total = response.data.stockAdjustments ? response.data.stockAdjustments.count : 1;
            if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
            this.setState(data);
          },
          handleError: error => {
            checkAndResetPaginatedData(data, this.setData);
            displayAlert(ALERT_TYPE.DANGER, error);
          },
        },
      );
    }
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type = '', element) => {
    const { sku, dialog } = this.state;
    this.setState({
      dialog: {
        type,
        element: {
          ...element,
          distributorId: dialog.element.distributorId,
          has_warehouse: dialog.element.has_warehouse,
          skuTitle: sku.skuTitle,
          skuId: sku.skuId,
        },
      },
    });
    if (dialog?.element?.has_warehouse) {
      this.getBinList(dialog.element.distributorId);
    }
  };

  handleDistributorSelect = distributor => {
    const { dialog, queryParameters } = this.state;
    dialog.element.distributorId = distributor.id;
    dialog.element.has_warehouse = distributor?.has_warehouse || false;
    queryParameters.pagination = {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    };
    this.setState(
      {
        dialog,
        queryParameters,
      },
      () => this.loadTableData(),
    );
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  resetDialog = () => {
    const { dialog } = this.state;
    this.setState({
      dialog: {
        element: {
          distributorId: dialog.element.distributorId,
          has_warehouse: dialog.element.has_warehouse,
        },
        type: '',
      },
    });
  };

  handleHeaderClick = () => {};

  handleRowClick = skuId => {
    const { data } = this.state;
    const filteredSku = data.list.filter(sku => sku.skuId === skuId);
    const { getStockAdjustmentBatchDetail, displayAlert } = this.props;
    const { dialog } = this.state;
    getStockAdjustmentBatchDetail(
      {
        distributorId: dialog.element.distributorId,
        skuId,
        filter: {
          dateRange: { start: getCurrentDay(), end: getCurrentDay() },
        },
      },
      {
        handleSuccess: response => {
          this.setState({
            stockAdjDetail: response.data.getStockAdjustmentBatchDetail || [],
            sku: { skuId, skuTitle: filteredSku[0].sku.title },
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleDownloadClick = () => {
    const { dialog } = this.state;
    const { downloadReport, displayAlert } = this.props;
    downloadReport(
      {
        input: {
          domain: 'STOCK_ADJUSTMENT',
          filter: {
            filters: [
              {
                column: 'distributor_id',
                value: [dialog.element.distributorId.toString()],
              },
            ],
          },
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          downloadService.resolver(response.data.downloadReport.file);
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Cannot download file');
        },
      },
    );
  };

  updateTransferStockAdjusment = (type, data) => {
    const { skuTitle, balance, damagedStock, expiredStock, shortageStock, binWiseStock, has_warehouse, ...payload } =
      data;
    const { createStockAdjustment, displayAlert } = this.props;

    createStockAdjustment(
      {
        input: {
          ...payload,
          sellableBinId: data.sellableBinId === 0 ? null : data.sellableBinId,
          damagedBinId: data.damagedBinId === 0 ? null : data.damagedBinId,
          expiredBinId: data.expiredBinId === 0 ? null : data.expiredBinId,
        },
      },
      {
        handleSuccess: response => {
          if (response) {
            this.resetDialog();
            this.handleRowClick(data.skuId);
            displayAlert(ALERT_TYPE.SUCCESS, 'Stock adjusted successfully!');
          }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleUpdateSubmit = (type, data) => {
    const { dialog } = this.state;
    if (data.has_warehouse) {
      if (!data.damagedBinId || !data.sellableBinId || !data.expiredBinId) {
        dialog.type = EVENT_OPERATION.BIN_CONFIRM;
        dialog.element = data;
        this.setState(dialog);
        return;
      } else {
        this.updateTransferStockAdjusment(type, data);
      }
    } else {
      this.updateTransferStockAdjusment(type, data);
    }
  };

  confirmReset = () => {
    const { dialog } = this.state;
    const { type, element } = dialog;
    this.handleIconClick(EVENT_OPERATION.UPDATE, element);
  };

  handleSubmit = (type, data) => {
    switch (type) {
      case EVENT_OPERATION.UPDATE:
        this.handleUpdateSubmit(type, data);
        break;
      case EVENT_OPERATION.BIN_CONFIRM:
        this.updateTransferStockAdjusment(type, data);
        break;
      default:
        this.onFormSubmit(type, data);
        break;
    }
  };

  onDropDownChange = (item, value, state, stateUpdater) => {
    if (item === 'bin_location_id') {
      const selectedBin = state.binWiseStock?.find(a => a.bin_location_id === state.bin_location_id);
      state.balance = selectedBin?.balance;
      state.quantity = selectedBin?.balance;
      state.damagedStock = selectedBin?.damagedStock;
      state.damages = selectedBin?.damagedStock;
      state.expiredStock = selectedBin?.expiredStock;
      state.expiry = selectedBin?.expiredStock;
      state.shortageStock = selectedBin?.shortageStock;
      state.shortages = selectedBin?.shortageStock;

      stateUpdater(state);
    }
  };

  render() {
    const { data, queryParameters, display, dialog, distributorList, stockAdjDetail, binList, filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    const { type } = dialog;
    const { element } = dialog;

    return (
      <StockAdjusStyled>
        {type && (
          <DialogFormWrapper
            onDialogSubmit={this.handleSubmit}
            formConfig={formConfig[type]}
            disableDialogClose
            dialogElement={element}
            onDialogCancel={this.resetDialog}
            extraFooterCallBack={this.confirmReset}
            extraHeaderCallBack={this.confirmReset}
            type={type}
            withOutPadding={type === EVENT_OPERATION.UPDATE}
            renderDialog={({
              dialogData,
              enableErrorDisplay,
              refsObj,
              handleInputChange,
              handleDropDownChange,
              handleFileUpload,
            }) => (
              <>
                {type === EVENT_OPERATION.UPLOAD && (
                  <Fragment>
                    <CSVUploadForm
                      show
                      data={dialogData}
                      loading={serverResponseWaiting}
                      handleFileUpload={handleFileUpload}
                      enableErrorDisplay={enableErrorDisplay}
                      refsObj={refsObj}
                    />
                    <a onClick={this.handleDownloadClick}>
                      <span className="sample-csv">
                        <Icon iconName="download" />
                        Sample CSV
                      </span>
                    </a>
                  </Fragment>
                )}
                {type === EVENT_OPERATION.UPDATE && (
                  <StockAdjustmentForm
                    data={dialogData}
                    loading={serverResponseWaiting}
                    type={type}
                    handleInputChange={handleInputChange}
                    enableErrorDisplay={enableErrorDisplay}
                    handleDropDownChange={handleDropDownChange}
                    refsObj={refsObj}
                    adjustmentTypeList={adjustmentTypeList}
                    binList={binList}
                    dropDownCallBack={this.onDropDownChange}
                  />
                )}
                {type === EVENT_OPERATION.BIN_CONFIRM && (
                  <span>
                    Are you sure you want to locate adjusted stock to the same bin
                    <b>{` (${
                      element?.binWiseStock?.find(a => a.bin_location_id === element?.bin_location_id)
                        ?.bin_location_number
                    })`}</b>
                  </span>
                )}
              </>
            )}
          />
        )}
        <Fragment>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                display={display}
                breadCrumb={breadCrumbConfig}
                config={{
                  title: stockAdjustment.title,
                  filter: true,
                  search: true,
                  upload: this.permission.create,
                }}
                filter={{
                  // date: queryParameters.date,
                  menuList: filterMenu,
                  onFilterChange: this.basePaginationService.handleFilterChange,
                }}
                queryParameters={queryParameters}
                controlDisplay={this.controlDisplay}
                handleCreateClick={this.handleIconClick}
                resetFilter={this.basePaginationService.resetFilter}
                clearSearchText={this.basePaginationService.clearSearchText}
                handleSearchChange={this.basePaginationService.handleSearchInputChange}
                handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
                handleUploadClick={this.handleIconClick}
              />
            </PanelStyled>
          </div>
          <div className="section-content table-present section-select">
            <StockAdjustmentView
              data={data}
              inputData={element}
              loading={serverResponseWaiting}
              distributorList={distributorList}
              pagination={queryParameters.pagination}
              componentDisplay={this.componentConfig}
              permission={this.permission}
              onIconClick={this.handleIconClick}
              onDropDownChange={this.handleDistributorSelect}
              onPageChange={this.basePaginationService.onPageSelect}
              onHeaderClick={this.basePaginationService.handleTableSorting}
              onRowClick={this.handleRowClick}
              stockAdjDetail={stockAdjDetail}
            />
          </div>
        </Fragment>
      </StockAdjusStyled>
    );
  }
}

StockAdjustment.propTypes = propTypes;

StockAdjustment.defaultProps = defaultProps;

export default withAlert()(StockAdjustment);
