/**
 * Combine Monthly Header, Distributor Title, DSE List and WeeklyPresentor
 * Loads data through Context API.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MonthWeekHeader from './MonthWeekHeader';
import WeeklyPresenter from './WeeklyPresentor';
import { WeeklyStyled } from './MonthlyViewStyled';
import {
  getWeeksInMonth, getMonth, WEEK_DAYS
} from '../date';
import { DialogContext } from '../config';
import * as rosterService from '../Common/roster.service';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { headerLabelConfig } from '../../../common/HelperFunctions';

const propTypes = {
  header: PropTypes.node,
  month: PropTypes.instanceOf(Object),
  weekStartDay: PropTypes.number,
  weekEndList: PropTypes.instanceOf(Array),
  userList: PropTypes.arrayOf(Object),
  holidayList: PropTypes.instanceOf(Array),
  leaveList: PropTypes.arrayOf(Object),
  data: PropTypes.instanceOf(Array),
};

const defaultProps = {
  header: <span>DSE Horizon Distributors</span>,
  month: {
    start: '2019-01-01',
    end: '2019-01-31',
  },
  weekStartDay: WEEK_DAYS.FRIDAY,
  weekEndList: [],
  userList: [],
  holidayList: [],
  leaveList: [],
  data: [],
};

class MonthlyView extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.month;
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      // weekStartDay: props.weekStartDay,
    };
  }

  componentDidMount() {}

  render() {
    const { header, month, weekEndList, holidayList, data, userList, leaveList, weekStartDay, subDDetails, callRosterPermission } = this.props;

    const weekList = getWeeksInMonth(month.start, month.end, weekStartDay);

    const activeMonth = getMonth(month.start);

    const eventList = rosterService.eventCombiner(data, holidayList, leaveList);
    return (
      <DialogContext.Consumer>
        {(value) => (
          <WeeklyStyled>
            <div className='roster-calendar-wrap'>
              <div className='calendar-top-header'>
                <div className='title'>
                  <span>{subDDetails.title}</span>
                  <span className='label'>{header}</span>
                </div>
                <div className='calendar-week-header'>
                  <MonthWeekHeader weekStartDay={weekStartDay} weekEndList={weekEndList} />
                </div>
              </div>
              <div className='calendar-bottom-content'>
                <div className='title'>
                  {userList.map((dse) => (
                    <div key={dse.id} className='title-list' onClick={() => value.handleUserClick(dse)}>
                      <span className={value.activeUser === dse.id ? 'active' : 'not-active'}>
                        {dse.fullName}
                      </span>
                    </div>
                  ))}
                </div>
                <div className='calendar-days'>
                  {weekList.map((dateDetails, i) => (
                    <WeeklyPresenter
                      dseSelected={!!value.activeUser}
                      date={dateDetails}
                      activeMonth={activeMonth}
                      events={eventList}
                      weekEndList={weekEndList}
                      key={i}
                      callRosterPermission={callRosterPermission}
                    />
                  ))}
                </div>
              </div>
            </div>
          </WeeklyStyled>
        )}
      </DialogContext.Consumer>
    );
  }
}

MonthlyView.propTypes = propTypes;

MonthlyView.defaultProps = defaultProps;

export default MonthlyView;
