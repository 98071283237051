import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { ALERT_TYPE } from '../../../../data/enums/AlertType';
import { DOMAIN } from '../../../../data/enums/config';
import { PROGRAM } from '../../../../data/enums/Permission';
import { USER_ACCOUNT, USER_CREATE } from '../../../../data/enums/Route';
import { USER_ROLE_TITLE } from '../../../../data/enums/UserRole';
import { insertUserLabel } from '../../../../data/services/userLabel';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../../utils/api';
import withAlert from '../../../../utils/composition/withAlert';
import history from '../../../../utils/history';
import PageHeader from '../../../base/PageHeader';
import { clone } from '../../../../utils/objectProcessor';
import { getPermissionForUserAccount } from '../../../base/permission';
import * as queryService from '../../../base/query.service';
import { PanelStyled } from '../../../common/configuration';
import { userAccount } from '../../../common/DomainConfig';
import { fetchFromLocalStorage, isError, storeInLocalStorage } from '../../../common/HelperFunctions';
import { storageKeys } from '../../../sales/orderProcessing/config';
import { breadCrumbConfig, filterConfig } from './config';
import UserAccountView from './View';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  getUserList: PropTypes.func.isRequired,
  toggleUser: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  downloadReport: PropTypes.func,
  displayAlert: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
  downloadReport: () => null
};

class UserAccount extends Component {
  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
    };
    this.permission = getPermissionForUserAccount();
    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,


    );
    this.getData = () => {
      const { data } = this.state;
      return data;
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  setFilterStateInStorage = () => {
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;

    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.USER_ACCOUNT,
    );
  };

  componentDidMount() {
    insertUserLabel();
    this.getFilterStateFromStorage();
  }

  getFilterStateFromStorage = () => {
    const {queryParameters} =this.state;
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.USER_ACCOUNT);
    if (!localData) {
      return this.loadTableData();
    }
    this.setState(
      {
        queryParameters: {
          ...queryParameters,
          filter: localData.filter,
          searchText: localData.searchText,
        },
        filterMenu:localData.filterMenu,
      },

      () => {
        this.loadTableData();
      },
    );
  };


  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getUserList } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    getUserList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          const filteredArray = response.data.users.rows.filter(item => {
            return item.role.name != USER_ROLE_TITLE.SUPER_ADMIN;
          });
          data.list = (response.data.users && filteredArray) || [];
          data.total = (response.data.users && response.data.users.count) || 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  fetchDialogData = data => {
    this.getParentUsers(data.roleId);
  };

  onIconClick = () => {
    history.push(`/${USER_CREATE}`);
  };

  onTableRowClick = userId => {
    history.push(`/${USER_ACCOUNT}/${userId}`);
  };

  onTableDataClick = e => {
    e.stopPropagation();
  };

  onToggleActiveStatus = (status, e, id) => {
    e.stopPropagation();
    const { toggleUser, displayAlert } = this.props;

    toggleUser(
      {
        id: parseInt(id, 10),
        active: status,
      },
      {
        handleSuccess: () => {
          this.loadTableData();
        },
        handleError: err => {
          displayAlert(ALERT_TYPE.DANGER, err);
        },
      },
    );
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const { data, display, queryParameters, filterMenu } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: userAccount.title,
                create: this.permission.create,
                download: true,
                filter: true,
                search: true,
              }}
              downloadConfig={{ domain: [PROGRAM.DOWNLOAD_USER_ACCOUNT, PROGRAM.DOWNLOAD_USER_HIERARCHY] }
              }
              filter={{
                date: queryParameters.date,
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.onIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleDownloadClick={this.basePaginationService.handleDownloadClick}
            />
          </PanelStyled>
        </div>

        <div className="section-content table-present">
          <UserAccountView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            onIconClick={this.onIconClick}
            loading={serverResponseWaiting}
            onTableRowClick={this.onTableRowClick}
            fetchDialogData={this.fetchDialogData}
            onPageChange={this.basePaginationService.onPageSelect}
            onTableDataClick={this.onTableDataClick}
            onToggleActiveStatus={this.onToggleActiveStatus}
          />
        </div>
      </Fragment>
    );
  }
}

UserAccount.propTypes = propTypes;

UserAccount.defaultProps = defaultProps;

export default withAlert()(UserAccount);
