import { compose } from 'recompose';
import StockAdjustment from './StockAdjustment';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, ADJUST_STOCK
} from './API';
import { DOWNLOAD_REPORT, UPLOAD } from '../../common/GqlAPI';

const composedStockAdjustment = compose(
  ADJUST_STOCK,
  UPLOAD,
  DOWNLOAD_REPORT,
  withRequestTracker({ query, mutation }),
)(StockAdjustment);

export default composedStockAdjustment;
