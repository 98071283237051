import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-photoswipe/lib/photoswipe.css';

import { getImageObj } from './config';
import { Icon } from '../../../../../v4/components';
import ImageUpload from '../../../../components/ImageUpload';
import { Thumbnail } from '../../../../../components/ImageViewer';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { IMAGE_UPLOAD_DOMAIN } from '../../../../../data/enums/GraphQL';
import { EMPTY } from '../../../../../data/enums/ErrorMessage';
import { MessageStyled } from '../../../../../components/Input/TextStyled';

const propTypes = {
  images: PropTypes.arrayOf(Object).isRequired,
  getImages: PropTypes.func.isRequired,
  crudMode: PropTypes.string.isRequired,
  enableErrorDisplay: PropTypes.bool,
  enableValidation: PropTypes.bool,
};

const defaultProps = {
  enableErrorDisplay: false,
  enableValidation: false,
};
export class Image extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      serverImages: props.images,
      images: props.images || [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { serverImages, images } = prevState;

    if (nextProps.images.length !== serverImages.length) {
      return {
        images: [...nextProps.images],
        serverImages: [...nextProps.images],
      };
    }

    return { images, serverImages };
  }

  componentDidMount() {
    const { getImages } = this.props;
    getImages(this.exportData);
  }

  toggleShow = (state = false, e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ show: state });
  };

  handleSubmit = (e, url) => {
    if (url) {
      const imageObj = getImageObj(url);
      this.setState(({ images }) => ({ images: [...images, imageObj] }));
    }

    this.toggleShow(false);
  };

  removeImage = index => {
    const { images } = this.state;
    this.setState({ images: images.filter((img, i) => i !== index) });
  };

  exportData = () => {
    const { images } = this.state;
    return images;
  };

  getValidState = () => {
    const { images } = this.state;
    return images.length > 0;
  };

  render() {
    const { show, images } = this.state;
    const { crudMode, enableErrorDisplay, enableValidation, onImageClick } = this.props;
    const valid = this.getValidState();
    const displayError = enableValidation && enableErrorDisplay && !valid;

    return (
      <div>
        <div className="config-panel-header">
          <label>Shipment Receipt Number Image</label>
        </div>
        {show && (
          <ImageUpload
            onSubmit={(e, url) => this.handleSubmit(e, url)}
            onClose={this.toggleShow}
            domain={IMAGE_UPLOAD_DOMAIN.GRN}
          />
        )}
        {
          <div className="grnImage">
            <div className="image-holder">
              <Icon iconName="picture" onClick={e => this.toggleShow(true, e)} />
            </div>

            {images.map((imageObj, index) => (
              <div className="attachment-item">
                {crudMode !== EVENT_OPERATION.READ && (
                  <span onClick={() => this.removeImage(index)} className="del-icon">
                    x
                  </span>
                )}

                <Thumbnail
                  onImageClick={() => {
                    onImageClick(0, [{ ...imageObj, src: imageObj.image, h: 1100, w: 3200 }]);
                  }}
                  image={imageObj.thumbnail}
                  title="dummy"
                  className=""
                />
              </div>
            ))}
          </div>
        }
        {displayError && (
          <MessageStyled className="form-error">
            <span className="error-message">
              {<Icon iconName="exclamation-full" />}
              {EMPTY}
            </span>
          </MessageStyled>
        )}
      </div>
    );
  }
}

Image.propTypes = propTypes;

Image.defaultProps = defaultProps;

export default Image;
