export const title = 'Designation';
import { configuration as configurationRoute } from '../../../../data/enums/Route';
import { refGenerator } from '../../../../utils';
import { EVENT_OPERATION } from '../../../../v4/constants/EventOperation';
import { FORM_CONFIG } from '../../../../v4/constants/formConfig';

export const breadCrumbConfig = [configurationRoute];
const requiredList = ['title'];

export const formConfig = {
  [EVENT_OPERATION.CREATE]:
    {
      [FORM_CONFIG.TITLE]: 'Create Designation',
      [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
      [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    },
  [EVENT_OPERATION.UPDATE]: {
    [FORM_CONFIG.TITLE]: 'Edit Designation',
    [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.DELETE]: {
    [FORM_CONFIG.TITLE]: 'Confirm',
  },
}
