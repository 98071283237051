import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import OpeningBalanceView from './View';
import { getUser } from '../../../data/services';
import PageHeader from '../../base/PageHeader';
import { DialogFormWrapper } from '../../common';
import { CSVUploadForm, Icon } from '../../../v4/components';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { FORM_CONFIG } from '../../../data/enums/config';
import { openingBalance } from '../../common/DomainConfig';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import withAlert from '../../../utils/composition/withAlert';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../../data/enums/UserRole';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { getPermissionForOpeningBalance } from '../../base/permission';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { formConfig, crudRequestConfig, breadCrumbConfig, filterConfig } from './config';
import { crudResponseProcessor, handleFormSubmit } from '../../../utils/crudResponseProcessor';
import { isError, getSortValueAfterValueCheck } from '../../common/HelperFunctions';
import OpeningBalanceStyled from './OpeningBalanceStyle';
import { checkAndResetPaginatedData } from '../../../utils/paginatedListUtils';
import { PROGRAM } from '../../../data/enums/Permission';
import { DOWNLOAD_DOMAIN } from '../../../data/enums/GraphQL';
import * as downloadService from '../../base/download.service';
import { clone } from '../../../utils/objectProcessor';
import { PAGINATION } from '../../../data/enums';
import { fetchFromLocalStorage, storeInLocalStorage } from '../../common/HelperFunctions';
import { storageKeys } from '../../sales/orderProcessing/config';
import { DOMAIN } from '../../../data/enums/config';

const propTypes = {
  upload: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  getOpeningBalanceList: PropTypes.func.isRequired,
  getOpeningBalanceBatchDetail: PropTypes.func,
};

const defaultProps = {
  serverResponseWaiting: false,
  getOpeningBalanceBatchDetail: () => null,
};

class OpeningBalance extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.userInfo = getUser();
    queryService.resetBaseQueryParameters();
    this.filterConfigMenu = clone(filterConfig.menu);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
        upload: true,
      },
      distributorList: [],
      dialog: {
        type: '',
        element: formConfig[FORM_CONFIG.MAPPER]({
          distributorId: this.userInfo.Distributor.length > 0 ? this.userInfo.Distributor[0].id : 0,
        }),
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: {},
        filter: queryService.baseQueryParameters.filter,
        date: { ...queryService.baseQueryParameters.date },
      },
      filterMenu: this.filterConfigMenu || {},
      openingBlncDetail: [],
    };
    const serverCall = {
      [EVENT_OPERATION.UPLOAD]: props.upload,
    };
    this.setData = data => {
      this.setState({ data });
    };
    this.permission = getPermissionForOpeningBalance();
    this.componentConfig = openingBalance.componentsDisplay(this.permission);
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      props.downloadReport,
      props.displayAlert,
    );
    this.onCRUDSuccess = crudResponseProcessor((data, type) => {
      const { displayAlert } = this.props;
      displayAlert(ALERT_TYPE.SUCCESS, data);
      this.loadTableData();
    });
    this.onFormSubmit = handleFormSubmit(this.onCRUDSuccess, this.onAPIRequestFailure, crudRequestConfig, serverCall);
  }

  componentDidMount() {
    const { dialog } = this.state;
    if (
      ADMIN_ROLE_GROUP.includes(this.userInfo.roleId) ||
      DISTRIBUTOR_ASSOCIATED_ROLES.includes(this.userInfo.roleId)
    ) {
      this.getDistributorsList();
      this.getFilterStateFromStorage()
    }
    if (dialog.element.distributorId)  this.getFilterStateFromStorage();
  }

  setFilterStateInStorage = () => {
    const {dialog} =this.state;
    const { filterMenu, queryParameters: { filter, date, search } } = this.state;
    storeInLocalStorage(
      'DIALOG',
      dialog,
      DOMAIN.OPENING_BALANCE
    );
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.OPENING_BALANCE
    );
  };
  getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.OPENING_BALANCE);
    const localDialog = fetchFromLocalStorage('DIALOG', DOMAIN.OPENING_BALANCE);
    const newFilterConfig = {
      ...localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      },
    };

    if (!localData) {
      return this.loadTableData();
    }

    this.setState(
      {
        ...this.state,
        queryParameters: {
          ...this.state.queryParameters,
          filter: newFilterConfig.filter,
          searchText: newFilterConfig.searchText,
          date: newFilterConfig.date,
        },
        filterMenu: newFilterConfig.filterMenu,
        dialog:localDialog || dialog,
      },
      () => {
        this.loadTableData();
      },
    );
  };


  getDistributorsList = () => {
    const { getDistributors, displayAlert } = this.props;
    getDistributors(
      {},
      {
        handleSuccess: response => {
          this.setState({ distributorList: response.data.distributors.rows });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  loadTableData = () => {
    const { data, queryParameters, dialog } = this.state;
    const { getOpeningBalanceList, displayAlert } = this.props;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    this.setFilterStateInStorage();
    if (!dialog.element.distributorId) {
      return;
    }
    getOpeningBalanceList(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            {
              column: 'distributor_id',
              value: [dialog.element.distributorId.toString() || ''],
            },
          ],
          sort: getSortValueAfterValueCheck(queryParameters.sort),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
      },
      {
        handleSuccess: response => {
          data.list = response.data.openingBalance ? response.data.openingBalance.rows : [];
          data.total = response.data.openingBalance ? response.data.openingBalance.count : 1;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          checkAndResetPaginatedData(data, this.setData);
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleIconClick = (type = '', element) => {
    const { dialog } = this.state;
    this.setState({
      dialog: {
        type,
        element: dialog.element,
      },
    });
  };

  handleDistributorSelect = distributorId => {
    const { dialog, queryParameters } = this.state;
    dialog.element.distributorId = distributorId;
    queryParameters.pagination = {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    };
    this.setState({ dialog, queryParameters }, () => this.loadTableData());
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  resetDialog = () => {
    const { dialog } = this.state;
    this.setState({
      dialog: {
        element: { distributorId: dialog.element.distributorId },
        type: '',
      },
    });
  };

  handleRowClick = skuId => {
    const { getOpeningBalanceBatchDetail, displayAlert } = this.props;
    const { dialog } = this.state;
    getOpeningBalanceBatchDetail(
      {
        distributorId: dialog.element.distributorId,
        skuId,
      },
      {
        handleSuccess: response => {
          this.setState({ openingBlncDetail: response.data.getOpeningBalanceBatchDetail || [] });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleDownloadClick = (program) => {
    const { dialog } = this.state;
    const { downloadReport, displayAlert } = this.props;
    downloadReport(
      {
        input: {
          domain: program ? PROGRAM.OPENING_BALANCE : DOWNLOAD_DOMAIN.BILLINGSKU,
          filter: {
            filters: [
              {
                column: program ? 'distributor_id' : 'distributorid',
                value: [dialog.element.distributorId.toString()],
              },
            ],
          },
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          downloadService.resolver(response.data.downloadReport.file);
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Cannot download file');
        },
      },
    );
  };

  render() {
    const { data, queryParameters, display, dialog, distributorList, openingBlncDetail ,filterMenu} = this.state;
    const { serverResponseWaiting } = this.props;
    const { type } = dialog;
    const { element } = dialog;

    return (
      <Fragment>
        <div className="section-header">
          <OpeningBalanceStyled>
            {type && (
              <DialogFormWrapper
                onDialogSubmit={this.onFormSubmit}
                formConfig={formConfig}
                dialogElement={element}
                onDialogCancel={this.resetDialog}
                type={type}
                renderDialog={({ dialogData, enableErrorDisplay, handleFileUpload, refsObj }) => (
                  <Fragment>
                    <CSVUploadForm
                      show
                      data={dialogData}
                      loading={serverResponseWaiting}
                      handleFileUpload={handleFileUpload}
                      enableErrorDisplay={enableErrorDisplay}
                      refsObj={refsObj}
                    />
                    <a onClick={() => this.handleDownloadClick(PROGRAM.OPENING_BALANCE)}>
                      <span className="sample-csv">
                        <Icon iconName="download" />
                        Sample CSV
                      </span>
                    </a>
                  </Fragment>
                )}
              />
            )}
          </OpeningBalanceStyled>
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: openingBalance.title,
                search: true,
                filter: true,
                download: dialog.element.distributorId && true,
                upload: this.permission.create,
              }}
              filter={{
                menuList: filterMenu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleCreateClick={this.handleIconClick}
              resetFilter={this.basePaginationService.resetFilter}
              clearSearchText={this.basePaginationService.clearSearchText}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
              handleDateRangeChange={this.basePaginationService.handleDateRangeChange}
              handleUploadClick={this.handleIconClick}
              handleDownloadClick={this.handleDownloadClick}
            />
          </PanelStyled>
        </div>
        <div className="section-content section-select table-present">
          <OpeningBalanceView
            data={data}
            inputData={element}
            loading={serverResponseWaiting}
            distributorList={distributorList}
            pagination={queryParameters.pagination}
            componentDisplay={this.componentConfig}
            onDropDownChange={this.handleDistributorSelect}
            onPageChange={this.basePaginationService.onPageSelect}
            onHeaderClick={this.basePaginationService.handleTableSorting}
            onRowClick={this.handleRowClick}
            openingBlncDetail={openingBlncDetail}
          />
        </div>
      </Fragment>
    );
  }
}

OpeningBalance.propTypes = propTypes;

OpeningBalance.defaultProps = defaultProps;

export default withAlert()(OpeningBalance);
