import styled from 'styled-components';

const TargetWrapper = styled.div`
  .filter-wrap {
    display: inline-block;
    margin-right: 30px;
  }
  table {
    td {
      width: auto;
      white-space: nowrap;
      &:first-child {
        width: 30%;
      }
      &:last-child {
        .view {
          margin-right: 40px;
        }
        span {
          cursor: pointer;
          color: #6b6c7e;
          font-size: 14px;
        }
        svg.icon {
          height: 14px;
          width: 14px;
          margin-right: 9px;
        }
      }
    }
    td,
    th {
      &:last-child {
        text-align: right;
      }
      &:nth-child(2) {
        text-align: right;
      }
    }
  }
`;

export default TargetWrapper;
