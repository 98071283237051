import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import SwiperCore, { Navigation, Pagination, Keyboard } from 'swiper';
import styled from 'styled-components';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Keyboard]);

interface ImageSliderProps {
  images: string[];
}

interface ImageSliderStyledProps {
  height?: string;
  width?: string;
  maxHeight?: string;
  maxWidth?: string;
}

const ImageSlider = ({ images, height, maxHeight, width, maxWidth }: ImageSliderProps & ImageSliderStyledProps) => {
  return images.length > 0 ? (
    <ImageSliderContainer height={height} maxHeight={maxHeight} width={width} maxWidth={maxWidth}>
      <Swiper tag="section" cssMode navigation pagination keyboard spaceBetween={0}>
        {images.map((src, idx) => (
          <SwiperSlide key={idx}>
            <img src={src} alt="outlet images" />
          </SwiperSlide>
        ))}
      </Swiper>
    </ImageSliderContainer>
  ) : null;
};

export const ImageSliderContainer = styled.div<ImageSliderStyledProps>`
  height: ${({ height }) => height || '200px'};
  max-height: ${({ maxHeight }) => maxHeight || '200px'};
  margin-bottom: 1rem;

  --swiper-theme-color: #ffffff;
  --swiper-navigation-size: 10px;
  --swiper-pagination-bullet-inactive-color: #6b6c7e;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper {
    height: 100%;
  }

  .swiper-slide {
    background-color: rgb(231, 231, 237);
    border-radius: 10px;
    width: 100% !important;
  }

  .swiper-slide img {
    max-width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
    margin: 0 auto;
    /* box-shadow: 0 -33px 13px -15px rgba(39, 40, 51, 0.50) inset; */
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 20px;
    width: 20px;
    background-color: #6b6c7e;
    border-radius: 50%;
    font-weight: 800;
  }

  .swiper-button-next {
    right: 20px;
  }

  .swiper-button-prev {
    left: 20px;
  }

  .swiper-pagination {
    --swiper-theme-color: #0b5fff;
  }
`;

export default ImageSlider;
