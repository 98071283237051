import { compose } from 'recompose';
import DeBoardOutlet from './DeBoardOutlet';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

import {
  query, mutation, BULK_UPDATE_APPROVAL_REQUEST, DOWNLOAD_REPORT
} from './API';

const ComposedDeBoardOutlet = compose(
  BULK_UPDATE_APPROVAL_REQUEST,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(DeBoardOutlet);

export default ComposedDeBoardOutlet;
