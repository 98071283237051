import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../../data/enums/SuccessMessage';
import { USER_ROLE } from '../../../../../data/enums/Route';
import { FORM_CONFIG } from '../../../../../data/enums/config';
import { refGenerator } from '../../../../../utils';
import { breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'User',
    path: `/${USER_ROLE}`,
    active: true,
  },
];
const title = 'UserRole';
const formConfig = {
  [FORM_CONFIG.MAPPER]: (element) => ({
    name: element.name || '',
    userGrpId: element.userGrpId || null,
    displayName: element.displayName || '',
    enableGoogleAuth:element.enableGoogleAuth || false,
  }),
  [FORM_CONFIG.TITLE]: `${EVENT_OPERATION.UPDATE} ${title}`,
  [FORM_CONFIG.REFS_OBJ]: refGenerator([
    'name', 'userGrpId','displayName'
  ]),
  [FORM_CONFIG.VALIDATION_REQUIRED]: true,
};

const USER_ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
}

const crudRequestConfig = {
  [EVENT_OPERATION.UPDATE_STATUS]: {
    objectMapper: ({ roleId,input }) => ({ roleId,input }),
    responseName: 'updatePermission',
    message: 'Permission Updated SucessFully',
  },
};

export {
  crudRequestConfig, breadCrumb as breadCrumbConfig, formConfig,USER_ROLES
};
