import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { UPLOAD , DOWNLOAD_REPORT} from '../../../../common/GqlAPI';


const DISTRIBUTORS = gql`
  query distributors($offset: Int, $limit: Int, $filter: FilterInput) {
    distributors(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        title
        id
      }
      count
    }
  }
`;

const GET_OUTLET_TARGET_DETAILS = gql`
  query GetOutletTargetDetails(
    $getOutletTargetDetailsId: Int
    $distributorId: Int
    $offset: Int
    $limit: Int
    $filter: FilterInput
  ) {
    getOutletTargetDetails(
      id: $getOutletTargetDetailsId
      distributorId: $distributorId
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      rows {
        id
        outlet
        ulpo
        tls
        sbd
        dgp
        bill_cuts
        sales
        line_id
        outlet_id
      }
      count
    }
  }
`;

const UPDATE_OUTLET_FUNDAMENTAL_TARGET_DETAILS = graphql(
  gql`
    mutation UpdateOutletFundamentalTargetDetail($input: UpdateOutletFundamentalTargetInput!) {
      updateOutletFundamentalTargetDetail(input: $input) {
        ulpo
        tls
        sbd
        dgp
        billCuts
        outletId
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateOutletFundamentalTargetDetail: variables =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  distributors: DISTRIBUTORS,
  getOutletTargetDetails: GET_OUTLET_TARGET_DETAILS,
};

const mutation = {
  upload: UPLOAD,
  updateOutletFundamentalTargetDetail: UPDATE_OUTLET_FUNDAMENTAL_TARGET_DETAILS,
  downloadReport: DOWNLOAD_REPORT,
};

export { query, mutation, GET_OUTLET_TARGET_DETAILS, UPDATE_OUTLET_FUNDAMENTAL_TARGET_DETAILS, DOWNLOAD_REPORT };
