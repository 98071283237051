import styled from 'styled-components';

const AssortmentPanelStyled = styled.div`
  .status {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .form-input {
      margin-left: 12px;
    }
  }
  .panel-section {
    display: flex;
    .panel {
      background-color: #ffffff;
      border-radius: 4px;
      .p-title {
        margin: 0;
        padding: 24px;
        font-size: 10px;
        line-height: 10px;
        color: #6b6c7e;
        text-transform: uppercase;
      }
      ul {
        height: calc(100vh - 285px);
        overflow-y: auto;
        padding: 0 12px;
        li {
          padding: 9px 12px;
          font-size: 12px;
          line-height: 15px;
          font-weight: 500;
          color: #6b6c7e;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;
          text-transform: capitalize;
          p {
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 125px;
          }
          .light {
            margin-right: 16px;
          }

          &.active {
            background-color: #f7f8f9;
            color: #272833;
            border-radius: 4px;
            position: relative;
            &:after {
              content: '';
              position: absolute;
              top: 13px;
              right: 12px;
              display: block;
              width: 12px;
              height: 12px;
              border-top: 2px solid #0b5fff;
              border-left: 2px solid #0b5fff;
              -webkit-transform: rotate(135deg);
              -ms-transform: rotate(135deg);
              transform: rotate(135deg);
              border-radius: 2px 2px 0 2px;
            }
          }
          &:hover {
            cursor: pointer;
          }
          &.inactive {
            &:hover {
              color: #0b5fff;
            }
          }
        }
      }
      &.two,
      &.one {
        width: 215px;
        display: inline-block;
        margin-right: 4px;
      }

      /* third panel styling only */
      &.three {
        flex: 1;
        .panel-title {
          padding: 14px 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .p-title {
            margin: 0;
            padding: 0;
          }
        }
        .div-tbody .collapsible-inner,
        .div-tbody .header-wrap,
        .div-thead {
          border-bottom: 1px solid #e7e7ed;
          display: flex;
          padding: 12px 24px;
          align-items: center;
          justify-content: space-between;
          background-color: #f1f2f5;
          font-size: 12px;
          line-height: 15px;
          color: #6b6c7e;
          font-weight: 500;
          .status {
            display: flex;
            align-items: center;
            .form-input {
              margin-left: 12px;
            }
          }
        }

        .div-tbody {
          height: calc(100vh - 358px);
          &.full-height {
            height: calc(100vh - 338px);
            .collapsible-inner {
              background-color: #ffffff;
            }
          }
          overflow-y: auto;
          background-color: #fbfbfc;
          .header {
            .collapsible-inner,
            .header-wrap {
              background-color: #ffffff;
              padding: 15px 24px;
            }
            .header-wrap {
              cursor: pointer;
              &:hover {
                background: #f0f5ff;
              }
              & > span {
                position: relative;
                &::before {
                  content: '';
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: 6px solid #6b6c7e;
                  transform: rotate(0deg);
                  transition: all 0.23s ease-in-out;
                  position: absolute;
                  top: 6px;
                  left: 0;
                }
                .text {
                  margin-left: 30px;
                }
                .badge {
                  margin-left: 8px;
                }
              }
            }

            &.active {
              margin: 16px 0;
              transition: all 0.4s ease-out;
              box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
              .header-wrap {
                background-color: #f0f5ff;
                border-bottom: 1px solid #0b5fff;
                span::before {
                  transform: rotate(180deg);
                  transition: all 0.23s ease-in-out;
                  border-top: 6px solid #0b5fff;
                  top: 5px;
                }
              }
              .collapsible-inner {
                & > span {
                  margin-left: 30px;
                }
                /* &:hover{
                    background: #F0F5FF;
                  } */
              }
            }

            &.inactive {
              margin: 0;
              transition: all 0.4s ease-in;
              top: 28px;
            }
          }
        }
        /* only for table inside third panel */
        .table-wrap {
          max-height: unset;
          height: calc(100vh - 286px);
          overflow-y: auto;
          border-radius: 0 0 4px 4px;
          table {
            border: 0;
            td,
            th {
              &:first-child {
                padding: 7px 12px 7px 24px;
                border-left: 0;
              }
              &:last-child {
                padding: 7px 24px 7px 12px;
                border-right: 0;
              }
            }
            .switch {
              span {
                position: relative;
                top: -7px;
              }
              & > div {
                display: inline-block;
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }
`;

export default AssortmentPanelStyled;
