/* eslint-disable jsx-quotes */
/* eslint-disable indent */
/* eslint-disable no-case-declarations */
import React, {
 useCallback, useEffect, useRef, useState
} from 'react';
import { Input } from '../../../../../components';
import { SORTING } from '../../../../../data/enums';
import withAlert from '../../../../../utils/composition/withAlert';
import withLoading from '../../../../../utils/composition/withLoading';
import { Button } from '../../../../../v4/components';
import { splitFilteredArray } from '../../../../configuration/arc/utils/arrayUtil';
import { labelMappings } from './config';
import { TableBody, TableHeader } from './tableConfig';
import SKUContainer from './tabs.styled';

const SKUTab = ({ data, handleCallTarget, skuFilterCondition, isPlanned, handleRowClick, planstatus, startCall, showSearch, showSBDSkuTag, lastInvoiceDate }) => {
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState('');
  const sortingRef = useRef({
    label: '',
    sortOrder: ''
  })

  const handleCallTargetChange = (event, id) => {
    handleCallTarget(id, event.target.value);
  }

  const filterData = (dataToFilter, filterBy) => {
    return dataToFilter.filter((item) =>
      item?.title?.toUpperCase()?.includes(filterBy.toUpperCase())
    );
  }

  useEffect(() => {
    const filteredTableData = skuFilterCondition ? splitFilteredArray(data, skuFilterCondition).filtered : data;
    /**
     * if previously sorted, keep the sorting order intact.
     */
    let sortedData = [];
    if (sortingRef.current.sortOrder !== SORTING.NO_SORT) {
      sortedData = sortData(sortingRef.current.label, sortingRef.current.sortOrder, filteredTableData)
    }

    /**
     * if search value, filter the sorted data by search;
     */
    const filteredData = filterData(sortedData.length ? sortedData : filteredTableData, search);
    setTableData(filteredData)
  }, [data])

  const handleInputChange = useCallback((value) => {
    setSearch(value);
    if (!value) {
      setTableData(skuFilterCondition ? splitFilteredArray(data, skuFilterCondition).filtered : data);
      return;
    }
    const filteredData = filterData(data, value);
    setTableData(filteredData)
  }, [search, tableData])

  const sortData = (label, sortOrder, dataToSort) => {
    if (sortOrder === SORTING.NO_SORT) {
      const filteredTableData = skuFilterCondition ? splitFilteredArray(data, skuFilterCondition).filtered : data;
      const filteredData = filterData(filteredTableData, search);
      return filteredData;
    }

    switch (label) {
      case labelMappings.brand:
        dataToSort.sort((a, b) => {
          if (sortOrder === 'ASC') {
            return (a.brand.title > b.brand.title) ? 1 : (b.brand.title > a.brand.title) ? -1 : 0
          }
          return (a.brand.title < b.brand.title) ? 1 : (b.brand.title < a.brand.title) ? -1 : 0
        });
        break;

      case labelMappings.sku:
        dataToSort.sort((a, b) => {
          if (sortOrder === 'ASC') {
            return (a.title > b.title) ? 1 : (b.title > a.title) ? -1 : 0;
          }
          return (a.title < b.title) ? 1 : (b.title < a.title) ? -1 : 0
        })
        break;

      case labelMappings.promotion:
        dataToSort.sort((a, b) => {
          if (sortOrder === 'ASC') {
            return Number(b.ispromotionavailable) - Number(a.ispromotionavailable)
          }
          return Number(a.ispromotionavailable) - Number(b.ispromotionavailable);
        })
        break;

      default:
        break;
    }

    return dataToSort;
  }

  const onHeaderClick = (label, sortOrder) => {
    const sortedData = sortData(label, sortOrder, tableData);
    sortingRef.current = {
      label,
      sortOrder
    }
    setTableData([...sortedData]);
  }

  return <SKUContainer id='sku-tabs'>
    {showSearch ?
      <div className="search-wrapper">
        <div>
          <Button iconBtnSmall search iconName="search" />
        </div>
        <Input
          type='text'
          name='search'
          value={search}
          placeholder='Search'
          onChange={(e) => handleInputChange(e.target.value)}
          autoFocus
        />
      </div>
      : null}
    <div className='table-wrap'>
      <table>
        {TableHeader({ onHeaderClick })}
        <tbody className={planstatus === 'PLANNED' ? startCall ? '' : 'disabled' : ''}>
          {tableData?.map((sku) =>
            TableBody({
              data: sku,
              handleCallTargetChange: handleCallTargetChange,
              isPlanned,
              handleRowClick,
              showSBDSkuTag,
              lastInvoiceDate
            }))}
        </tbody>
      </table>
    </div>
  </SKUContainer>;
};

SKUTab.defaultProps = {
  data: [],
  handleCallTarget: () => { }
}

const memonizedSKUTab = React.memo(SKUTab);

export default withAlert()(withLoading(memonizedSKUTab));
