import { compose, pure } from 'recompose';
import SuperHOC from './SuperHOC';
import Pagination from './SamplePagination';
import loginAPI from './SamplePaginationAPI';
import withRequestTracker from '../../utils/composition/withRequestTracker';
// import { trackPromise } from './trackPromise';

// const trackMakeRequest = trackPromise('loginWithServer');

const ComposedPagination = compose(
  loginAPI,
  withRequestTracker({ requestName: 'loginWithServer' }),
  SuperHOC,
  pure,
)(Pagination);

export default ComposedPagination;
