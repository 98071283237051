import styled from 'styled-components';

const DetailsStyled = styled.div`
  .enable-cursor td {
    cursor: pointer;
  }
  .campaign-form {
    .single-date-picker {
      margin-top: 0;
    }
    .form-select-input .zindex-2__control {
      background: #fafbfc;
      border: 1px solid #c1c7d0;
      height: 40px;
      min-width: 0;
      font-size: 1rem;
      line-height: 1.5;
      color: #030913;
      .zindex-2__single-value {
        font-weight: 400;
      }
    }
  }
  .sub-campaigns.config-panel-card .config-panel-contents {
    padding: 0;
  }
  .config-panel-contents {
    .modal-inner.delete .with-out-padding {
      padding: 24px;
    }
  }
`;
export default DetailsStyled;
