import { FORM_CONFIG } from '../../../../data/enums/config';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { UPLOADABLES } from '../../../../data/enums/GraphQL';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { refGenerator } from '../../../../utils';
import { fileParser } from '../../../../utils/parser';

const title = ' Brand Distribution Target';

const formConfig = {
  [EVENT_OPERATION.UPLOAD]: {
    [FORM_CONFIG.TITLE]: 'File Upload',
    [FORM_CONFIG.MAPPER]: (element) => ({
      name: element.name,
      file: element.file,
    }),
    [FORM_CONFIG.REFS_OBJ]: refGenerator(['uploadEntity']),
    [FORM_CONFIG.VALIDATION_REQUIRED]: true,
  },
  [EVENT_OPERATION.REPLACE]: {
    [FORM_CONFIG.TITLE]: 'Replace Brand Distribution Target',
  },
};

const crudSuccess = {
  [EVENT_OPERATION.UPLOAD]: {
    objectMapper: async (data) => {
      const fileData = await fileParser(data.file).then((file) => ({
        input: {
          file,
          type: UPLOADABLES.BRANDCOVERAGETARGET,
        },
      }));
      return fileData;
    },
    responseName: 'importCSV',
    message: `${title} ${MESSAGE_EVENT_OPERATION.UPLOAD}`,
  },
};

export {
  crudSuccess, formConfig, title
};
