import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SectionWithTitle from '../components/SectionWithTitle';
import DSETableStyled from './DSETableStyled';
import { fixedFloatAndComma, fixedFloatAndCommas } from '../../../utils/conversion';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';
import { headerLabelConfig } from '../../common/HelperFunctions';
import { Badge } from '../../../v4/components';
import { DSE_DETAIL } from '../../../data/enums/Route';

const propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
    total: PropTypes.objectOf(PropTypes.instanceOf(Object)).isRequired,
  }).isRequired,
  displayGoldenPoint: PropTypes.bool.isRequired,
  tasUser:PropTypes.bool,
};
const defaultProps = {
  tasUser: false,
};


const DseDetails = ({ data, displayGoldenPoint,tasUser,title }) => {
  const { rows, total } = data;
  return (
    <SectionWithTitle title={title || headerLabelConfig(USER_ROLE_TITLE.DSE) ||  'DSE'} id='dse-detail'>
      <DSETableStyled>
        <div className='dse-table-wrap'>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                { tasUser || <th>Route</th>}
                <th className ='schedule'>Scheduled</th>
                <th>Successful</th>
                <th>Unsuccessful</th>
                <th>Remaining</th>
                <th>Productivity</th>
                {displayGoldenPoint && <th>Golden Point</th>}
                <th>{`${tasUser ? 'Target' :'PFD Target'}`}</th>
                <th>{`${tasUser ? 'Achievement' :'Received'}`}</th>
              </tr>
            </thead>
            <tbody>
              {data?.rows?.length !== 0 ? (
                data && data.rows && data.rows.map((dse) => (
                  <tr key={dse.id}>
                    <td
                      style={{ borderLeft: `4px solid ${dse.active || dse.successful +dse.unsuccessful !== 0  ? dse.color : '#CDCED9'}` }}
                      className={dse.active || dse.successful +dse.unsuccessful !== 0  ? '' : 'dse-disabled'}
                    >
                      <Link to={tasUser ? '#':`/${DSE_DETAIL}/${dse.id}/route-id/${dse.routeid}`}>
                        <div className='flex-display '>
                          {dse.profileImage ? (
                            <img src={dse.profileImage[0].image} alt='user-image' />
                          ) : (
                            <img className='user-image' src='/image/icons/user.svg' alt='user-image' />
                          )}

                          <div className='user-name'>
                            <span className={dse.active || dse.successful +dse.unsuccessful !== 0   ? '' : 'dse-disabled'}>{dse.name}</span>
                            { tasUser ||
                            <span className={Number(dse.status) === 0 ? 'inactive' : Number(dse.status) === 2 ? 'onleave' : 'active'}>
                              <span className='badge_border'>
                                <Badge light>
                                  {Number(dse.status) === 0 ? 'INACTIVE' : Number(dse.status)=== 2 ? 'ON LEAVE' : 'ACTIVE'}
                                </Badge>
                              </span>
                            </span>
                            }
                          </div>
                        </div>
                      </Link>
                    </td>
                    {tasUser ||<td className={dse.active || dse.successful +dse.unsuccessful !== 0   ? '' : 'dse-disabled'}>{dse.route || 0}</td>}
                    <td className={`schedule ${dse.active || dse.successful +dse.unsuccessful !== 0  ? '' : 'dse-disabled'}`}>{dse.scheduled || 0}</td>
                    <td className={dse.active ||dse.successful +dse.unsuccessful !== 0  ? '' : 'dse-disabled'}>{dse.successful || 0}</td>
                    <td className={dse.active || dse.successful +dse.unsuccessful !== 0   ? '' : 'dse-disabled'}>{dse.unsuccessful || 0}</td>
                    <td className={dse.active || dse.successful +dse.unsuccessful !== 0   ? '' : 'dse-disabled'}>{dse.remaining || 0}</td>
                    <td className={dse.active || dse.successful +dse.unsuccessful !== 0   ? '' : 'dse-disabled'}>
                      {dse?.productivity ? `${fixedFloatAndComma(dse?.productivity)}%` : '0%' }
                    </td>
                    {displayGoldenPoint && (
                      <td className={dse.active || dse.successful +dse.unsuccessful !== 0  ? '' : 'dse-disabled'}>{fixedFloatAndCommas(dse.goldenpoint) || 0}</td>
                    )}
                    <td className={dse.active || dse.successful +dse.unsuccessful !== 0  ? '' : 'dse-disabled'}>{fixedFloatAndCommas(dse.target) || 0}</td>
                    <td className={dse.active || dse.successful +dse.unsuccessful !== 0   ? '' : 'dse-disabled'}>{fixedFloatAndCommas(dse.received) || 0}</td>
                  </tr>
                ))
              ) : (
                <tr className='loading' />
              )}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                {tasUser || <td />}
                <td className='schedule'>{total.scheduled || 0}</td>
                <td>{total.successful || 0}</td>
                <td>{total.unsuccessful || 0}</td>
                <td>{total.remaining || 0}</td>
                <td>{`${fixedFloatAndComma(total.productivity)}%` || '0%' }</td>
                {displayGoldenPoint && <td>{fixedFloatAndCommas(total.goldenpoint) || 0 }</td>}
                <td>{fixedFloatAndCommas(total.target) || 0}</td>
                <td>{fixedFloatAndCommas(total.received) || 0}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </DSETableStyled>
    </SectionWithTitle>
  );
};

DseDetails.propTypes = propTypes;
DseDetails.defaultProps = defaultProps;

export default DseDetails;
