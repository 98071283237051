import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Map from '../../../../../common/Map';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import withErrorAndLoading from '../../../../../../utils/composition/withLoading';
import { CustomSelect, Input } from '../../../../../../components';
import { OutletPermissionContext } from '../../config';
import { EMPTY, INVALID } from '../../../../../../data/enums/ErrorMessage';
import FormContentStyled from '../../../../../../components/global-css/FormContentStyled';
import { Switch } from '../../../../../../v4/components';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string.isRequired,
    rMapId: PropTypes.number,
    image: PropTypes.string,
    address: PropTypes.string,
    panNumber: PropTypes.string,
    geoLocation: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }).isRequired,
  menu: PropTypes.shape({
    channelList: PropTypes.instanceOf(Array),
    categoryList: PropTypes.instanceOf(Array),
    routeList: PropTypes.instanceOf(Array),
    townList: PropTypes.instanceOf(Array),
  }).isRequired,
  enableErrorDisplay: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onDropDownChange: PropTypes.func.isRequired,
  refsObj: PropTypes.instanceOf(Object).isRequired,
  crudMode: PropTypes.string.isRequired,
  onIconClick: PropTypes.func,
  getGeoLocation: PropTypes.func.isRequired,
  getRetailOutletHistory: PropTypes.func,
  id: PropTypes.number,
};

const defaultProps = {
  enableErrorDisplay: false,
  onIconClick: () => null,
  getRetailOutletHistory: () => null,
  id: null,
};

const View = ({ ...props }) => {
  const {
    menu,
    data,
    id,
    refsObj,
    crudMode,
    onInputChange,
    onDropDownChange,
    enableErrorDisplay,
    getGeoLocation,
    onIconClick,
    getRetailOutletHistory,
  } = props;

  const channelId = data.Channel.id;

  const filteredCategoryList = channelId
    ? menu.categoryList.filter((el) => el.Channel.id === channelId)
    : menu.categoryList;

  const filteredTownList = menu.townList.filter(({ id }) => id === (data.Town && data.Town.id));

  // eslint-disable-next-line max-len
  const showMap = true; // crudMode !== EVENT_OPERATION.UPDATE && crudMode !== EVENT_OPERATION.CREATE;

  const checkLength = (data) => data && data.toString().length > 1;

  return (
    <OutletPermissionContext.Consumer>
      {({ permission }) => (
        <div className='create-ui tab-less-height'>
          <div className='create-ui-inner'>
            <div className='outlet-wrap'>
              <div className='flex'>
                <div className='w-half'>
                  <FormContentStyled>
                    <h3 className='w-100'>General Details</h3>
                    <div className={crudMode === EVENT_OPERATION.READ ? 'disabled-section' : ''}>
                      <div className='form-input-wrap w-100'>
                        {data.id > 0 && (
                          <Input
                            name='id'
                            type='number'
                            disabled
                            value={data.id.toString()}
                            labelContent='ID'
                            enableErrorDisplay={false}
                            onChange={(event) => onInputChange(event, 'id', ['id'])}
                          />
                        )}
                        <Input
                          name='title'
                          type='text'
                          enableValidation
                          placeholder='Outlet Name'
                          value={data.title}
                          labelContent='Outlet Name'
                          ref={(ref) => (refsObj.title = ref)}
                          enableErrorDisplay={enableErrorDisplay}
                          onChange={(event) => onInputChange(event, 'title', ['title'])}
                        />
                        <CustomSelect
                          enableValidation
                          labelContent='Channel'
                          className='custom-select'
                          options={menu.channelList}
                          placeholder='Select Channel'
                          getOptionValue={({ id }) => id}
                          getOptionLabel={({ title }) => title}
                          enableErrorDisplay={enableErrorDisplay}
                          onChange={(event) => onDropDownChange(event.id, ['Channel', 'id'])}
                          value={menu.channelList.filter(({ id }) => id === channelId)}
                          ref={(ref) => (refsObj.channel = ref)}
                        />
                        <CustomSelect
                          enableValidation
                          labelContent='Category'
                          className='custom-select'
                          placeholder='Select Category'
                          options={filteredCategoryList}
                          getOptionValue={({ id }) => id}
                          getOptionLabel={({ title }) => title}
                          enableErrorDisplay={enableErrorDisplay}
                          onChange={(event) => onDropDownChange(event.id, ['Category', 'id'])}
                          value={(channelId
                            ? menu.categoryList.filter((el) => el.Channel.id === channelId)
                            : menu.categoryList
                          ).filter(({ id }) => id === data.Category.id)}
                          ref={(ref) => (refsObj.category = ref)}
                        />
                        <CustomSelect
                          enableValidation
                          labelContent='Town'
                          className='custom-select'
                          placeholder='Select Town'
                          options={menu.townList}
                          getOptionValue={({ id }) => id}
                          getOptionLabel={({ title }) => title}
                          enableErrorDisplay={enableErrorDisplay}
                          onChange={(event) => onDropDownChange(event.id, ['Town', 'id'])}
                          value={filteredTownList.length > 0 ? filteredTownList : [data.Town]}
                          ref={(ref) => (refsObj.town = ref)}
                        />
                        <Input
                          type='text'
                          name='Address'
                          value={data.additionalInfo?.outletCode}
                          labelContent='Outlet Code'
                          placeholder='Outlet Code'
                          onChange={(event) => onInputChange(event, 'additionalInfo', ['additionalInfo', 'outletCode'])}
                        />
                        <Input
                          type='text'
                          name='address'
                          value={data.address}
                          labelContent='Address'
                          placeholder='Address'
                          onChange={(event) => onInputChange(event, 'address', ['address',])}
                        />
                        <Input
                          type='text'
                          enableValidation
                          name='owner'
                          value={data.contactInfo.name}
                          labelContent='Owner'
                          placeholder='Owner Name'
                          enableErrorDisplay={enableErrorDisplay}
                          onChange={(event) => onInputChange(event, 'contactInfo', ['contactInfo', 'name'])}
                          ref={(ref) => (refsObj.contactName = ref)}
                        />
                        <div className='contact'>
                          <Input
                            type='number'
                            name='contact'
                            labelContent='Contact'
                            placeholder='Contact Number'
                            value={data.contactInfo.phoneNumber}
                            onChange={(event) => onInputChange(event, 'contactInfo', ['contactInfo', 'phoneNumber'])}
                            allowMultiZeroBeforeNumber
                          />
                          {data.phoneNumberVerified === true ? (
                            <div className='eye'>
                              <img
                                src='/image/icons/VerifiedIcon.svg'
                                style={{ cursor: 'pointer' }}
                                onClick={() => onIconClick('', {}, getRetailOutletHistory(id))}
                              />
                            </div>
                          ) : (
                              <div />
                            )}
                        </div>
                        <Input
                          type='number'
                          enableValidation
                          name='landline'
                          labelContent='Landline'
                          rule='matches'
                          argument='^([0-9]{9,10})$' /* matches digit of length min 9 and max 10 */
                          placeholder='Landline Number'
                          value={data.socialContacts.landline}
                          enableErrorDisplay={enableErrorDisplay}
                          displayMessage={checkLength(data.socialContacts.landline)}
                          errorMessage={data.socialContacts.landline ? INVALID : EMPTY}
                          onChange={(event) => onInputChange(event, 'socialContacts', ['socialContacts', 'landline'])}
                          allowMultiZeroBeforeNumber
                        />
                        <Input
                          type='number'
                          enableValidation
                          name='whatsapp'
                          labelContent='Whats App'
                          rule='matches'
                          argument='^((?!(0))[0-9]{10})$' /* matches digit of length 10 and doesn't start with 0 */
                          placeholder='Whats App Number'
                          value={data.socialContacts.whatsapp}
                          enableErrorDisplay={enableErrorDisplay}
                          displayMessage={checkLength(data.socialContacts.whatsapp)}
                          errorMessage={data.socialContacts.whatsapp ? INVALID : EMPTY}
                          onChange={(event) => onInputChange(event, 'socialContacts', ['socialContacts', 'whatsapp'])}
                        />
                        <Input
                          type='number'
                          enableValidation
                          name='viber'
                          labelContent='Viber'
                          rule='matches'
                          argument='^((?!(0))[0-9]{10})$' /* matches digit of length 10 and doesn't start with 0 */
                          placeholder='Viber Number'
                          value={data.socialContacts.viber}
                          enableErrorDisplay={enableErrorDisplay}
                          displayMessage={checkLength(data.socialContacts.viber)}
                          errorMessage={data.socialContacts.viber ? INVALID : EMPTY}
                          onChange={(event) => onInputChange(event, 'socialContacts', ['socialContacts', 'viber'])}
                        />
                        <Input
                          type='number'
                          placeholder='PAN'
                          labelContent='PAN'
                          name='panNumber'
                          rule='matches'
                          argument='^((?!(0))[0-9]{9})$' /* matches digit of length 9 and doesn't start with 0 */
                          onChange={(e) => onInputChange(e)}
                          value={data.panNumber}
                          enableValidation
                          errorMessage={data.panNumber ? INVALID : ''}
                          enableErrorDisplay={enableErrorDisplay}
                          ref={(ref) => (refsObj.panNumber = data.panNumber ? ref : '')}
                        />
                        <Switch
                          onToggle={(checked, event) => {
                            onInputChange({
                              formattedValue: !!checked,
                              target: {
                                name: 'active',
                                type: 'boolean',
                                value: !!checked,
                              },
                            });
                          }}
                          checked={data.active}
                          name='active'
                          labelContent='Status'
                        />
                      </div>
                    </div>
                  </FormContentStyled>
                </div>

                <div className='w-half'>
                  <FormContentStyled>
                    <h3 className='w-100'>Map</h3>
                    <div className={crudMode === EVENT_OPERATION.READ ? 'disabled-section' : ''}>
                      <div className='form-input-wrap w-100'>
                        <Input
                          type='number'
                          name='longitude'
                          enableValidation
                          labelContent='Longitude'
                          value={data.geoLocation.longitude}
                          enableErrorDisplay={enableErrorDisplay}
                          ref={(ref) => (refsObj.longitude = ref)}
                          onChange={(event) => onInputChange(event, 'geoLocation', ['geoLocation', 'longitude'])}
                        />
                        <Input
                          name='latitude'
                          type='number'
                          enableValidation
                          labelContent='Latitude'
                          value={data.geoLocation.latitude}
                          enableErrorDisplay={enableErrorDisplay}
                          ref={(ref) => (refsObj.latitude = ref)}
                          onChange={(event) => onInputChange(event, 'geoLocation', ['geoLocation', 'latitude'])}
                        />
                        {showMap && (
                          <div className='map-wrapper'>
                            <Map data={data.geoLocation} draggable onDrag={getGeoLocation} zoom={17} showSearchInput />
                          </div>
                        )}
                      </div>
                    </div>
                  </FormContentStyled>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </OutletPermissionContext.Consumer>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const GeneralInfo = withErrorAndLoading(View);

export default memo(GeneralInfo);
