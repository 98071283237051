import { compose } from 'recompose';
import CreateGrn from './CreateGrn';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_GRN, UPDATE_GRN, APPROVE_GRN, CREATE_PROOF_OF_DELIVERY
} from './API';
import { UPLOAD } from '../../../common/GqlAPI';
// import { grnImageUploader } from '../../../../utils/image';

const ComposedCreateGrn = compose(
  UPLOAD,
  CREATE_GRN,
  UPDATE_GRN,
  APPROVE_GRN,
  CREATE_PROOF_OF_DELIVERY,
  withRequestTracker({
    query,
    mutation,
  }),
  // grnImageUploader,
)(CreateGrn);

export default ComposedCreateGrn;
