const SKU_CATALOG_LEVEL = 8;

const CATALOG_LEVEL = {
  PRINCIPAL: 1,
  PRODUCT_GROUP: 2,
  BRAND: 3,
  MARKET_CATALOG: 4,
  SUB_BRAND: 5,
  SUB_BRAND_FORM: 6,
  SKU_FAMILY: 7,
  SKU_CATALOG_LEVEL,
};

const CATALOG_TITLE_ID_MAPPER = {
  [CATALOG_LEVEL.PRINCIPAL]: 'Principal',
  [CATALOG_LEVEL.PRODUCT_GROUP]: 'Product Group',
  [CATALOG_LEVEL.BRAND]: 'Brand',
  [CATALOG_LEVEL.MARKET_CATALOG]: 'Market Catalog',
  [CATALOG_LEVEL.SUB_BRAND]: 'Sub Brand',
  [CATALOG_LEVEL.SUB_BRAND_FORM]: 'Sub Brand Form',
  [CATALOG_LEVEL.SKU_FAMILY]: 'SKU Family',
};

export {
  SKU_CATALOG_LEVEL, CATALOG_LEVEL, CATALOG_TITLE_ID_MAPPER
};
