import React, { Component, Fragment } from 'react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';

import { getUser } from '../../../data/dao';
import PFDTargetView from './View';
import PageHeader from '../../base/PageHeader';
import { apiFilterProcessor } from '../../../utils/api';
import { isError, getFromLocalStorage, getSortValueAfterValueCheck } from '../../common/HelperFunctions';
import * as queryService from '../../base/query.service';
import { PanelStyled } from '../../common/configuration';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { breadCrumbConfig, filterConfig, TableHeader } from './tableConfig';
import withAlert from '../../../utils/composition/withAlert';
import { getCurrentDay } from '../../../utils/date';
import { JWT } from '../../../environment';

const propTypes = {
  downloadReport: PropTypes.func,
  getPfdSalesTarget: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  downloadReport: () => null,
  serverResponseWaiting: false,
};

class PFDSalesTarget extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    queryService.resetBaseQueryParameters();
    this.userInfo = getUser();
    const { sbd, focusedSKU } = getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS);
    this.state = {
      data: {
        list: [],
        total: 0,
      },
      display: {
        searchBox: false,
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        search: queryService.baseQueryParameters.search,
        sort: queryService.baseQueryParameters.sort,
        filter: queryService.baseQueryParameters.filter,
        date: { start: getCurrentDay(), end: getCurrentDay() },
      },
      sbd,
      focusedSKU,
    };
    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.loadTableData,
      downloadReport,
      displayAlert,
    );
    // this.filter = getFilterListBasedOnUserRole(this.userInfo.roleId);
  }

  componentDidMount() {
    const { sbd, focusedSKU } = this.state;
    this.loadTableData();
    TableHeader({
      config: { sbd, focusedSKU },
      onHeaderClick: this.basePaginationService.handleTableSorting,
      dynamicHeader: true,
    });
  }

  loadTableData = () => {
    const { data, queryParameters } = this.state;
    const { getPfdSalesTarget } = this.props;
    getPfdSalesTarget(
      {
        filter: {
          filters: apiFilterProcessor(queryParameters.filter),
          queryString: queryParameters.search,
          dateRange: queryParameters.date,
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      },
      {
        handleSuccess: response => {
          const { getUserWisePfdTarget = [] } = response.data;
          data.list = getUserWisePfdTarget;
          data.total = getUserWisePfdTarget && getUserWisePfdTarget.length ? getUserWisePfdTarget.length : 0;
          if (isError(response)) this.onAPIRequestFailure(response.errors[0]);
          this.setState(data);
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;

    return queryParameters;
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  controlDisplay = (label, value) => {
    const { display } = this.state;
    display[label] = value;
    this.setState(display);
  };

  render() {
    const { data, queryParameters, display, sbd, focusedSKU } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <Fragment>
        <div className="section-header">
          <PanelStyled>
            <PageHeader
              display={display}
              breadCrumb={breadCrumbConfig}
              config={{
                title: 'PFD Sales Target',
                create: false,
                download: false,
                filter: true,
                search: true,
                date: false,
                singleDate: true,
              }}
              filter={{
                date: queryParameters.date,
                menuList: filterConfig.menu,
                onFilterChange: this.basePaginationService.handleFilterChange,
              }}
              queryParameters={queryParameters}
              controlDisplay={this.controlDisplay}
              handleSingleDateRangeChange={this.basePaginationService.handleSingleDateRangeChange}
              handleSearchChange={this.basePaginationService.handleSearchInputChange}
            />
          </PanelStyled>
        </div>
        <div className="section-content">
          <PFDTargetView
            data={data}
            permission={this.permission}
            pagination={queryParameters.pagination}
            onIconClick={() => {}}
            loading={serverResponseWaiting}
            onPageChange={this.basePaginationService.onPageSelect}
            config={{ sbd, focusedSKU }}
            onHeaderClick={this.basePaginationService.handleTableSorting}
          />
        </div>
      </Fragment>
    );
  }
}

PFDSalesTarget.propTypes = propTypes;

PFDSalesTarget.defaultProps = defaultProps;

export default withAlert()(PFDSalesTarget);
