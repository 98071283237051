import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { dataProps, paginationProps } from '../../../../data/enums/Component';
import withErrorAndLoadingScreen from '../../../../utils/composition/withLoading';
import { Pagination, BulkDelete } from '../../../../v4/components';
import { STATEFUL_ENTITIES } from '../../../../data/enums/GraphQL';
import { TableBody, TableHeader } from './tableConfig';
import { PERMISSION_OBJ } from '../../../../data/enums/Permission';

const propTypes = {
  data: dataProps.value,
  pagination: paginationProps.value,
  onPageChange: PropTypes.func,
  onCheckBoxClick: PropTypes.func,
  onIconClick: PropTypes.func,
  onBulkDelete: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
};

const defaultProps = {
  data: dataProps.defaultValue,
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  onCheckBoxClick: () => null,
  onIconClick: () => null,
  permission: PERMISSION_OBJ,
};

const View = ({ ...props }) => {
  const { data, onPageChange, onIconClick, pagination, onBulkDelete, permission,onTableRowClick,options } = props;
  return (
    <Fragment>
      <BulkDelete
        data={data.list}
        type={STATEFUL_ENTITIES.TOWN}
        handler={onBulkDelete}
        render={() => (
          <div className='table-wrap'>
            <table>
              {TableHeader()}
              <tbody>
                {data.list.map((role) =>
                  TableBody({
                    data: role,
                    onIconClick,
                    onRowClick:onTableRowClick,
                    options:options,
                    permission,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
      {data.list.length > 0 ? (
        <Pagination
          totalItems={data.total}
          limit={pagination.limit}
          onPageChange={onPageChange}
          currentPage={pagination.page}
        />
      ) : null}
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const LoginViewWithErrorAndLoading = withErrorAndLoadingScreen(View);

export default LoginViewWithErrorAndLoading;
