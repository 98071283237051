import { Col, CustomSelect, Input, MonthPicker, Row } from '../../../../components';
import { AVERAGE_BASE, dimensionList } from './config';

export const Summary = props => {
  const {
    principalList,
    enableErrorDisplay,
    refsObj,
    update,
    read,
    onSelectChange,
    handleDateChange,
    data,
    handleInputChange,
    calculateClicked,
    listData,
  } = props;
  return (
    <>
      {read ? (
        <List listData={listData} />
      ) : (
        <>
          {principalList?.length > 1 && (
            <Col lg={3}>
              <CustomSelect
                labelContent="Principal"
                placeholder="Select principal"
                name="principalId"
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id}
                options={principalList}
                onChange={e => onSelectChange(e, 'principalId')}
                value={principalList.filter(({ id }) => id === data.principalId)}
                enableValidation
                enableErrorDisplay={enableErrorDisplay}
                ref={ref => (refsObj.principalId = ref)}
                isClearable
                disabled={update || calculateClicked}
              />
            </Col>
          )}
          <Col lg={3}>
            <div className={update || calculateClicked ? 'month-wrap disabled' : 'month-wrap'}>
              <label>Month</label>
              <MonthPicker isDisabledReq value={data?.duration} onSelect={e => handleDateChange(e, 'duration')} />
            </div>
          </Col>
          <Col lg={3}>
            <Input
              ref={ref => (refsObj.target = ref)}
              value={data.target}
              name="target"
              type="number"
              argument={{ min: 0 }}
              placeholder="0"
              enableErrorDisplay={enableErrorDisplay}
              enableValidation
              onChange={event => handleInputChange(event, 'num')}
              labelContent="National Target"
              decimalWithPrecedingZero
            />
          </Col>
          {dimensionList?.length > 1 && (
            <Col lg={3}>
              <CustomSelect
                labelContent="Dimension"
                placeholder="Select dimension"
                name="dimensionId"
                getOptionLabel={({ title }) => title}
                getOptionValue={({ id }) => id}
                options={dimensionList}
                onChange={e => onSelectChange(e, 'dimensionId')}
                value={dimensionList?.filter(({ id }) => id === data.dimensionId)}
                enableValidation
                enableErrorDisplay={enableErrorDisplay}
                ref={ref => (refsObj.dimensionId = ref)}
                disabled={update || calculateClicked}
                isClearable
              />
            </Col>
          )}
          <Col lg={3}>
            <div className={`permission-choose ${update || calculateClicked ? 'disabled' : ''}`}>
              <label>Average Base (Last Year)</label>
              <div>
                <label className="radio-list">
                  <input
                    type="radio"
                    name="averageBase"
                    value={AVERAGE_BASE.SAME_QUARTER}
                    onChange={e => handleInputChange(e, 'averageBase')}
                    checked={data.averageBase === AVERAGE_BASE.SAME_QUARTER}
                  />
                  <span className="text">Same Quarter</span>
                </label>
                <label className="radio-list">
                  <input
                    type="radio"
                    name="averageBase"
                    value={AVERAGE_BASE.PREVIOUS_QUARTER}
                    onChange={e => handleInputChange(e, 'averageBase')}
                    checked={data.averageBase === AVERAGE_BASE.PREVIOUS_QUARTER}
                  />
                  <span className="text">Previous Quarter</span>
                </label>
              </div>
            </div>
          </Col>
        </>
      )}
    </>
  );
};

export const List = ({ ...props }) => {
  const { listData } = props;
  return (
    <>
      {listData?.map(item => {
        return (
          <>
            <Col lg={3} className={`form-wrapper list-item ${item?.class}`}>
              <label htmlFor="">{item?.title}</label>
              <div className="value-div">
                <p className="as-input">{item?.value}</p>
              </div>
            </Col>
          </>
        );
      })}
    </>
  );
};
