import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { MESSAGE_EVENT_OPERATION } from '../../../../data/enums/SuccessMessage';
import { MERCHANDISE } from '../../../../data/enums/Route';
import { breadCrumbConfig } from '../config';

const breadCrumb = [
  ...breadCrumbConfig,
  {
    id: 1,
    title: 'Merchandise',
    path: `/${MERCHANDISE}`,
    active: true,
  },
];
const title = 'POSMs';

const getPayload = (data, type = EVENT_OPERATION.CREATE) => ({
  updateMerchandiseDeviceId: data?.id || 0,
  title: data?.title || '',
  input:data?.brandsCategoryInfo || '',
});
const crudSuccess = {
  [EVENT_OPERATION.CREATE]: {
    objectMapper: (data) => {
      const formattedData =getPayload(data, EVENT_OPERATION.CREATE);
      const { updateMerchandiseDeviceId, ...structuredData } = formattedData;
      return structuredData ;
    },
    responseName: 'createMerchandiseDevice',
    message: `POSMs ${MESSAGE_EVENT_OPERATION.CREATE}`,
  },
  [EVENT_OPERATION.UPDATE]: {
    objectMapper: (data) => {
      return getPayload(data, EVENT_OPERATION.UPDATE);
    },
    responseName: 'updateMerchandiseDevice',
    message: `POSMs ${MESSAGE_EVENT_OPERATION.UPDATE}`,
  },
};
const getbrandsCategory = (data = []) => {
  const brandsCategoryInfo =[
    {
      categoryId:null,
      brandIds:[],
    }
  ]
  return brandsCategoryInfo ;
}
const formMapper = (element) => ({
  id:element.id || 0,
  title: element?.title || '',
  brandsCategoryInfo:getbrandsCategory(element.brandsCategoryInfo) || []
});

export {
  crudSuccess, breadCrumb as breadCrumbConfig,formMapper,title
};
