import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '../../../v4/components';
import { paginationProps } from '../../../data/enums/Component';
import CustomSelect from '../../../components/CustomSelect';
import withErrorAndLoadingScreen from '../../../utils/composition/withLoading';
import { PanelStyled } from '../../common/configuration';
import { StockAdjusStyled } from './StockAdjusStyled';
import { TableHeader } from './tableConfig';
import { PERMISSION_OBJ } from '../../../data/enums/Permission';
import Detail from './detail';

const propTypes = {
  componentDisplay: PropTypes.instanceOf(Object),
  distributorList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  pagination: paginationProps.value,
  inputData: PropTypes.instanceOf(Object),
  permission: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func,
  onPageChange: PropTypes.func,
  onDropDownChange: PropTypes.func.isRequired,
  enableErrorDisplay: PropTypes.bool,
  onHeaderClick: PropTypes.func,
  onRowClick: PropTypes.func.isRequired,
};
const defaultProps = {
  data: {},
  componentDisplay: {},
  onIconClick: () => null,
  pagination: paginationProps.defaultValue,
  distributorList: [],
  enableErrorDisplay: false,
  inputData: {},
  onHeaderClick: () => null,
  permission: PERMISSION_OBJ,
  onPageChange: () => null,
};

const StockAdjustmentView = ({
  data,
  inputData,
  pagination,
  permission,
  onIconClick,
  onPageChange,
  distributorList,
  componentDisplay,
  onDropDownChange,
  enableErrorDisplay,
  onHeaderClick,
  onRowClick,
  stockAdjDetail,
}) => (
  <StockAdjusStyled>
    {componentDisplay.distributorSelection && (
      <PanelStyled>
        <div>
          <CustomSelect
            small
            enableValidation
            options={distributorList}
            className='custom-select'
            placeholder='Select Distributor'
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            enableErrorDisplay={enableErrorDisplay}
            onChange={(event) => onDropDownChange(event)}
            value={distributorList.filter(({ id }) => id === inputData.distributorId) || []}
          />
        </div>
      </PanelStyled>
    )}
    <Fragment>
      {data.list.length > 0 ? (
        <div className={`table-wrap ${distributorList.length === 0 ? 'no-select' : ''} `}>
          <table>
            {TableHeader({ onHeaderClick })}
            <Detail
              data={data.list}
              onRowClick={onRowClick}
              onIconClick={onIconClick}
              permission={permission}
              stockAdjDetail={stockAdjDetail}
            />
          </table>
        </div>
      ) : null}
    </Fragment>
    {data.list.length > 0 && (
      <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      />
    )}
  </StockAdjusStyled>
);

StockAdjustmentView.propTypes = propTypes;

StockAdjustmentView.defaultProps = defaultProps;

const StockAdjustmentViewWithErrorAndLoading = withErrorAndLoadingScreen(StockAdjustmentView);

export default StockAdjustmentViewWithErrorAndLoading;
