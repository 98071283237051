import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { CustomSelect, Input } from '../../../../../../components';
import { Icon ,Button } from '../../../../../../v4/components';
import { budgetMessage } from '../config';
import { EMPTY, INVALID } from '../../../../../../data/enums/ErrorMessage';
import withLoading from '../../../../../../utils/composition/withLoading';
import withFormWrapper from '../../../../../../utils/composition/withFormWrapper';

const propTypes = {
  disableAdd: PropTypes.bool,
};
const defaultProps = {
  disableAdd: false,
};
const TargetSelect = ({ ...props }) => {
  const {
    data,
    title,
    onHandleInputChange,
    enableErrorDisplay,
    refsObj,
    subCampaignTypeList,
    handleAdd,
    handleCancel,
    handleDropDownChange,
    handleDimensionChange,
    dimensionList,
    disableAdd,
  } = props;
  const subCampaginListed = data.subCampaignTarget.map((item) => item.targetType);
  const targetTypeList = subCampaignTypeList.map((item) => item.targetType).flat();
  const targetListFiltered = targetTypeList.filter((val) => !subCampaginListed.includes(val));
  const DimensionFiltered = dimensionList.filter((i) => i.value === 'VALUE');
  return (
    <Fragment>
      <label htmlFor=''>Target</label>
      <div className='form-input-wrap'>
        {data.subCampaignTarget.map((subdata, index) => (
          <div className='select-wrap' key={index}>
            <div className='list-content'>
              <label className='large custom-control-label-text target-select'>
                <CustomSelect
                  labelContent='Sub Campaign Target'
                  className='custom-select'
                  options={targetListFiltered}
                  placeholder='Select'
                  getOptionValue={(item) => item}
                  getOptionLabel={(item) => item}
                  enableValidation
                  enableErrorDisplay={enableErrorDisplay}
                  ref={(ref) => (ref === null ? (refsObj.targetType = ' ') : (refsObj.targetType = ref))}
                  onChange={(event) => {
                    handleDropDownChange(event, ['subCampaignTarget', [index], 'targetType']);
                  }}
                  value={targetTypeList.filter((item) => item === subdata.targetType)}
                />
              </label>
              {subdata.targetType === 'COVERAGE' || (
                <label className='large custom-control-label-text select-value'>
                  <CustomSelect
                    enableValidation
                    options={DimensionFiltered}
                    labelContent='Target Value Type'
                    className='custom-select'
                    placeholder='Select'
                    getOptionValue={({ value }) => value}
                    getOptionLabel={({ label }) => label}
                    ref={(ref) => (ref === null ? (refsObj.dimension = ' ') : (refsObj.dimension = ref))}
                    enableErrorDisplay={enableErrorDisplay}
                    onChange={(event) =>
                      handleDimensionChange(data, event.value, ['subCampaignTarget', [index], 'dimension'])
                    }
                    value={DimensionFiltered.filter(({ value }) => value === subdata.dimension)}
                  />
                </label>
              )}

              <label className='custom-control-label-number'>
                <Input
                  type='number'
                  name='targetValue'
                  placeholder='Value'
                  value={subdata.value > 0 ? subdata.value : ''}
                  ref={(ref) => {
                    return ref === null ? (refsObj.targetValue = ' ') : (refsObj.targetValue = ref);
                  }}
                  rule='isFloat'
                  decimalWithPrecedingZero
                  enableErrorDisplay={enableErrorDisplay}
                  onChange={(event) =>
                    onHandleInputChange(data, event, 'subCampaignTarget', ['subCampaignTarget', [index], 'value'])
                  }
                />
              </label>
              <Icon iconName='times' onClick={() => handleCancel(subdata.targetType, 'targetType', data)} />
            </div>
          </div>
        ))}
        {targetListFiltered.length > 0 && (
          <Button small secondary onClick={() => handleAdd('Target', data)} className={disableAdd ? 'disabled' : ''}>
            <Icon iconName='plus' />
          </Button>
        )}
      </div>
    </Fragment>
  );
};

TargetSelect.propTypes = propTypes;

TargetSelect.defaultProps = defaultProps;

export default withLoading(withFormWrapper(TargetSelect));
