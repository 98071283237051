import styled from 'styled-components';

const ReporTableStyled = styled.div`
  .table-wrap {
    td {
      white-space: nowrap;
      max-width: 500px;
      text-overflow: ellipsis;
    }

    .action-row {
      cursor: pointer;
    }
  }

  .modal-wrap {
    position: relative;
  }

  .tooltip-wrap {
    bottom: 20px;
    left: 50px;
    opacity: 0;
    z-index: 99;
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
    -webkit-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    transition: all 150ms linear;
    font-weight: 500;
    visibility: hidden;
    pointer-events: none;
    background-color: #ffffff;
    position: absolute;
    min-width: 10vw;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 7px 10px;
    span {
      padding: 2px 0;
      font-size: 11px;
    }
  }

  .tooltiptext:hover + .tooltip-wrap {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
`;
export default ReporTableStyled;
