import { DOMAIN } from "../../../data/enums/config";
import { FILTER } from "../../../data/enums/filter";
import { getFilterConfig } from "../../../utils/filter";


export const filterConfigProvider = (route: string, list?: any) => {
    switch (route) {
        case DOMAIN.MANAGE_USERS:
            return getFilterConfig([FILTER.STATUS]);
        default:
            return getFilterConfig([]);
    }
};