import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';
import { fixedFloatAndCommas } from '../../../../utils/conversion';
import { tableData } from '../../../../v4/components';

const labelMappings = {
  category: 'category',
  monthTarget: 'monthTarget',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.category,
    title: 'Category',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.monthTarget,
    title: 'Month Target',
    show: true,
  },
];

const returnTableBody = (data) => {
  const table = {};
  table[labelMappings.category] = <td key={`${data.channelId}-category`}>{data?.category}</td>;
  table[labelMappings.monthTarget] = <td key={`${data.channelId}-monthTarget`}>{fixedFloatAndCommas(data?.outletTarget)}</td>;
  return table;
};

const TableBody = ({ data }) => {
  const tableBody = returnTableBody(data);

  return (
    <tr key={data.channelId} 
    // onClick={() => onIconClick(EVENT_OPERATION.DETAILS, data)}
     className='cursor-pointer'>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

export { TableHeader, TableBody };
