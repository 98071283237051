import React from 'react';
import FormContent from '../../../../components/FormContent';
import { Input } from '../../../../../components';
import CustomSelect from '../../../../../components/CustomSelect';
import { SingleDatePicker } from '../../../../../components/DatePicker';
import ImageUploadSection from '../../../../components/ImageUploadSection';
import { IMAGE_UPLOAD_DOMAIN } from '../../../../../data/enums/GraphQL';
import { INVALID, EMPTY } from '../../../../../data/enums/ErrorMessage';
import { getStartOfLastYear } from '../../../../../utils/date';
import { isObjectEmpty } from '../../../../../utils/objectPrototypes';

const View = ({ ...props }) => {
  const {
    data,
    onDropDownChange,
    onInputChange,
    handleImagesFetch,
    enableErrorDisplay,
    menu,
    refsObj,
    distributorExist,
  } = props;
  return (
    <>
      <FormContent title='General Details'>
        <CustomSelect
          name='collection'
          onChange={(event) => {
            onDropDownChange(event.value, ['collectionType']);
          }}
          inputCallBack
          options={menu.collectionType}
          labelContent='Collection Type'
          placeholder='Collection Type'
          getOptionValue={({ value }) => value}
          getOptionLabel={({ title }) => title}
          value={menu.collectionType.filter(({ value }) => value === data.collectionType)}
          enableValidation
          ref={(ref) => (refsObj.collectionType = ref)}
          enableErrorDisplay={enableErrorDisplay}
        />
        <div className='form-input custom-date'>
          <label>Date</label>
          <SingleDatePicker
            date={data.date}
            name='date'
            onChange={(name, date) =>
              onInputChange({
                target: {
                  name: 'date',
                },
                formattedValue: date,
              })
            }
            maxDate={new Date()}
            minDate={getStartOfLastYear()}
            enableValidation
            ref={(ref) => (refsObj.createdAt = ref)}
            enableErrorDisplay={enableErrorDisplay}
          />
        </div>
        <Input
          value={data.amount}
          name='amount'
          type='number'
          rule='isFloat'
          argument={{
            min: 1,
          }}
          labelContent='Amount'
          placeholder='Amount'
          onChange={(event) => onInputChange(event)}
          enableValidation
          ref={(ref) => (refsObj.amount = ref)}
          enableErrorDisplay={enableErrorDisplay}
        />
        {data.collectionType === 'POST_DATED_CHEQUE' ? (
          <div className='form-input custom-date'>
            <label>Valued Date</label>
            <SingleDatePicker
              date={data.details.valuedDate}
              name='valuedDate'
              onChange={(name, date) =>
                onInputChange(
                  {
                    target: {
                      name: 'valuedDate',
                    },
                    formattedValue: date,
                  },
                  'details',
                )
              }
              minDate={new Date()}
            />
          </div>
        ) : (
          ''
        )}
        {data.collectionType === 'CHEQUE' || data.collectionType === 'POST_DATED_CHEQUE' ? (
          <>
            {/* <CustomSelect
              name='bank'
              inputCallBack
              options={menu.bankList}
              labelContent='Bank'
              placeholder='Select Bank'
              getOptionValue={({ title }) => title}
              getOptionLabel={({ title }) => title}
              onChange={(event) =>
                onDropDownChange(event.title, ['details', 'bank'], 'bank')
              }
              value={menu.bankList.filter(
                ({ title }) => title === data.details.bank
              )}
            /> */}
            <Input
              value={data.details.chequeNumber}
              name='chequeNumber'
              type='number'
              allowMultiZeroBeforeNumber
              labelContent='Cheque Number'
              placeholder='Cheque Number'
              enableValidation
              ref={data.collectionType !== 'CASH' ? (ref) => (refsObj.chequeNumber = ref) : null}
              enableErrorDisplay={enableErrorDisplay}
              onChange={(event) => onInputChange(event, 'details', ['details', 'chequeNumber'])}
            />
          </>
        ) : (
          ''
        )}
        <div className='collector-wrap'>
          <CustomSelect
            name='role'
            inputCallBack
            options={menu.roleList}
            labelContent='Collector'
            placeholder='Title'
            getOptionValue={({ id }) => id}
            getOptionLabel={({ name }) => name}
            onChange={(event) => onDropDownChange(event.id, ['Role'], 'role')}
            value={menu.roleList.filter(({ id }) => id === data.Role)}
            enableValidation
            ref={(ref) => (refsObj.Role = ref)}
            enableErrorDisplay={enableErrorDisplay}
          />
          <div className='user-wrap'>
            <CustomSelect
              name='user'
              inputCallBack
              options={menu.userList}
              // labelContent="Collector"
              placeholder='Select User'
              getOptionValue={({ id }) => id}
              getOptionLabel={({ fullName }) => fullName}
              onChange={(event) => onDropDownChange(event.id, ['User'])}
              value={menu.userList.filter(({ id }) => id === data.User)}
              enableValidation
              ref={(ref) => (refsObj.User = ref)}
              enableErrorDisplay={enableErrorDisplay}
            />
          </div>
        </div>
        <CustomSelect
          name='route'
          inputCallBack
          options={menu.routeList}
          labelContent='Route'
          placeholder='Route'
          getOptionValue={({ id }) => id}
          getOptionLabel={({ title }) => title}
          onChange={(event) => onDropDownChange(event.id, ['routeId'], 'route')}
          value={menu.routeList.filter(({ id }) => id === data.routeId)}
          enableValidation
          ref={(ref) => (refsObj.route = ref)}
          enableErrorDisplay={enableErrorDisplay}
        />
        <CustomSelect
          labelContent='Outlet'
          placeholder='Outlet'
          className='custom-select'
          options={menu.outletList}
          getOptionValue={({ id }) => id}
          getOptionLabel={({ title }) => title}
          onChange={(event) => {
            onDropDownChange(event.id, ['outletId'], 'outlet');
          }}
          value={menu.outletList.filter(({ id }) => id === data.outletId)}
          enableValidation
          ref={(ref) => (refsObj.outletId = ref)}
          enableErrorDisplay={enableErrorDisplay}
        />
        {!distributorExist && (
          <CustomSelect
            labelContent='Distributor'
            placeholder='Distributor'
            className='custom-select'
            options={menu.distributorList}
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            onChange={(event) => onDropDownChange(event.id, ['Distributor'])}
            value={menu.distributorList.filter(({ id }) => id === data.Distributor)}
            enableValidation
            ref={(ref) => (refsObj.distributorId = ref)}
            enableErrorDisplay={enableErrorDisplay}
          />
        )}

        <Input
          value={data.remarks}
          name='remarks'
          type='text'
          labelContent='Remarks'
          placeholder='Remarks'
          onChange={(event) => onInputChange(event)}
        />
      </FormContent>
      {data.collectionType === 'CHEQUE' || data.collectionType === 'POST_DATED_CHEQUE' ? (
        <FormContent title='Images'>
          <ImageUploadSection
            key={`${data.outletId}-images`}
            getImages={(images) => {
              handleImagesFetch(images);
            }}
            images={data.details.image}
            domain={IMAGE_UPLOAD_DOMAIN.RECEIPTS}
            errorMessage='Image is Required'
            enableValidation
            ref={(ref) => (refsObj.image = ref)}
            enableErrorDisplay={enableErrorDisplay}
          />
        </FormContent>
      ) : (
        ''
      )}
    </>
  );
};

export default View;
