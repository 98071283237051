import { compose } from 'recompose';
import RouteSegmentation from './RouteSegmentation';
import { query, mutation, DOWNLOAD_REPORT } from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const ComposedRouteSegmentation = compose(
    DOWNLOAD_REPORT,
    withRequestTracker({ 
        query, 
        mutation 
    }),
    )(RouteSegmentation);

export default ComposedRouteSegmentation;
