import { compose } from 'recompose';
import TargetAchievement from './TargetAchievement';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, CREATE_OUTLET_TARGET, UPDATE_BU_TARGET, UPLOAD, DOWNLOAD_REPORT
} from './API';

const ComposedTarget = compose(
  CREATE_OUTLET_TARGET,
  UPDATE_BU_TARGET,
  UPLOAD,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(TargetAchievement);

export default ComposedTarget;
