import React, { Component } from 'react';
import { Button } from '../../../v4/components';
import ImageUpload from './index';

const propTypes = {};

const defaultProps = {};

class UploadImageTestPage extends Component {
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      imageUrl: '',
    };
  }

  componentDidMount() {}

  toggleShow = (state = false) => this.setState({ show: state });

  render() {
    const { show, imageUrl } = this.state;
    return (
      <div style={{ padding: '20px' }}>
        <div style={{ padding: '20px' }}>Image Upload test page</div>
        {show && (
          <ImageUpload
            onSubmit={(type, url) => {
              this.toggleShow(false);
              this.setState({ imageUrl: url }, () => window.open(url));
            }}
            onClose={this.toggleShow}
          />
        )}
        <Button secondary iconBtnSmall iconName="upload" onClick={() => this.toggleShow(true)}>
          Upload Image Click here!{' '}
        </Button>
        <div style={{ padding: '10px' }}>Image url :{imageUrl}</div>
      </div>
    );
  }
}

UploadImageTestPage.propTypes = propTypes;

UploadImageTestPage.defaultProps = defaultProps;

export default UploadImageTestPage;
