import React from 'react';
import { Form } from '../../../../../../v4/components';
import { Col, CustomSelect, Input, Row } from '../../../../../../components';
import withLoading from '../../../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../../../v4/constants/EventOperation';

const PerfectCallCreateUpdateDialog = ({ ...props }) => {
  const {
    data,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
    metricOptions,
    unusedMetricOptions,
    achievementCriteriaTypeOptions,
    handleDropDownChange,
    dialog,
  } = props;

  const unusedMetricOptionsAndSelectedOption =
    dialog?.type === EVENT_OPERATION.CREATE ? unusedMetricOptions : [...unusedMetricOptions, dialog?.element?.metric];

  return (
    <Form>
      <CustomSelect
        ref={ref => (refsObj.metric = ref)}
        value={metricOptions.filter(({ id }) => id === data.metric.id)}
        labelContent="Metrics"
        placeholder="Select"
        getOptionValue={({ id }) => id}
        getOptionLabel={({ title }) => title}
        options={unusedMetricOptionsAndSelectedOption}
        name="metric"
        onChange={event => handleDropDownChange(event, 'metric')}
        enableErrorDisplay={enableErrorDisplay}
        enableValidation
      />
      <p className="form-label">Achievement Criteria</p>
      <div className="criteria">
        <Col lg={4}>
          <CustomSelect
            ref={ref => (refsObj.achievementCriteriaType = ref)}
            value={achievementCriteriaTypeOptions.filter(({ id }) => id === data.achievementCriteriaType.id)}
            labelContent={undefined}
            placeholder="Select"
            getOptionValue={({ id }) => id}
            getOptionLabel={({ title }) => title}
            options={achievementCriteriaTypeOptions}
            name="achievementCriteriaType"
            onChange={event => handleDropDownChange(event, 'achievementCriteriaType')}
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
          />
        </Col>
        <Col lg={8}>
          <Input
            ref={ref => (refsObj.achievementCriteriaValue = ref)}
            value={data.achievementCriteriaValue}
            rule="isFloat"
            argument={{
              min: 1,
              ...(data.achievementCriteriaType.id === 1 && { max: 100 }),
            }}
            errorMessage={
              data.achievementCriteriaType.id === 1 && data.achievementCriteriaValue > 100
                ? 'Must be less than 100'
                : 'Must be greater than 0'
            }
            name="achievementCriteriaValue"
            type="number"
            placeholder=""
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={event => handleInputChange(event)}
            labelContent={undefined}
          />
        </Col>
      </div>
    </Form>
  );
};

const PerfectCallCreateUpdateDialogWithErrorAndLoading = withLoading(PerfectCallCreateUpdateDialog);

export default PerfectCallCreateUpdateDialogWithErrorAndLoading;
