import { useState, useEffect } from 'react';
import * as queryService from '../../../../base/query.service';
import { get, set } from '../../../../salesForce/fundamentalTargets/distributorSalesTarget/sub-D-Details/config';
import { localStorageKey } from '../config/config';
import { linkedDeviceListMapper } from '../Mapper/dataMapper.js';
import EVENT_OPERATION from '../../../../../data/enums/EventOperation';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { getPermissionForLinkedDevice } from '../../../../base/permission';
import { apiFilterProcessor } from '../../../../../utils/api';
import { isError } from '../../../../common/HelperFunctions';
import { labelConfig } from '../View/tableConfig.view';

const initialdialog = {
  type: '',
  element: {},
};

const defaultSort = { order: 'DESC', label: 'id' };

const sortingLabels = Object.assign(
  {},
  ...[
    ...labelConfig.map((i) => ({
      [i?.label]: i?.apiDataKey,
    })),
    { id: 'id' },
  ],
);

const useLinkedDevicesListController = (props) => {
  const localStorage = get(localStorageKey);
  const { displayAlert, serverResponseWaiting, getLinkedDevices, toggleLinkedDevice, destroyLinkedDevice } = props;
  const { search: localSearchText } = localStorage || {};
  const [dateChanged, setDateChanged] = useState(false);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [queryParameters, setQueryParameters] = useState({
    pagination: { page: 1, limit: 150 },
    filter: queryService.baseQueryParameters.filter,
    date: { ...queryService.baseQueryParameters.date },
    search: localSearchText ?? queryService.baseQueryParameters.search,
    sort: defaultSort,
  });

  const [dialog, setDialog] = useState(initialdialog);

  const permission = getPermissionForLinkedDevice();

  const enableDateParamOnlyOnManualDateChange = () => {
    if (dateChanged) return;
    setDateChanged(true);
  };

  const loadTableData = () => {
    const offset = (queryParameters.pagination.page - 1) * queryParameters.pagination.limit;
    const sort = queryParameters?.sort;
    const isNoSort = sort?.order === 'NO_SORT';
    const sortPayload = isNoSort ?
      {
        order: 'DESC',
        sortBy: 'id'
      } :
      {
        order: sort?.order,
        sortBy: sortingLabels[sort?.label] || ''
      }
    getLinkedDevices(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          // filters: apiFilterProcessor(queryParameters.filter),
          ...(dateChanged ? { dateRange: queryParameters.date } : {}),
          queryString: queryParameters.search,
          sort: sortPayload,
        },
      },
      {
        handleSuccess: (response) => {
          if (isError(response)) onAPIRequestFailure(response?.errors[0]);
          const linkedDevices = response?.data?.getLinkedDevices;
          const deviceListMapped = linkedDeviceListMapper(linkedDevices?.rows);
          setData(deviceListMapped);
          setTotalItems(linkedDevices?.count);
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const setParameters = (queryParams) => {
    const { search } = queryParams;
    set(localStorageKey, {
      search,
      //   filterData,
    });
    setQueryParameters({ ...queryParams });
  };
  const basePaginationService = new queryService.QueryClass(setParameters, () => queryParameters, loadTableData);

  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const handleFormSubmit = async (type, element) => {
    switch (type) {
    case EVENT_OPERATION.DELETE:
      destroyLinkedDevice(
        {
          ids: [element?.data.id],
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Device removed successfully');
            loadTableData();
            handleDialogClose();
          },
          handleError: (err) => {
            onAPIRequestFailure(err);
          },
        },
      );
      break;
    case EVENT_OPERATION.BLOCK_DEVICE:
      toggleLinkedDevice(
        {
          status: 'blocked',
          toggleLinkedDeviceId: element?.data.id,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Device Blocked Successfully');
            loadTableData();
            handleDialogClose();
          },
          handleError: (err) => {
            onAPIRequestFailure(err);
          },
        },
      );
      break;

    case EVENT_OPERATION.UNBLOCK_DEVICE:
      toggleLinkedDevice(
        {
          status: 'active',
          toggleLinkedDeviceId: element?.data.id,
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, 'Device Unblocked Successfully');
            loadTableData();
            handleDialogClose();
          },
          handleError: (err) => {
            onAPIRequestFailure(err);
          },
        },
      );
      break;
    default:
      '';
    }
  };

  const handleIconClick = (type, data) => {
    setDialog({
      type,
      element: { data },
    });
  };

  const handleDialogClose = () => {
    setDialog(initialdialog);
  };

  useEffect(() => {
    loadTableData();
  }, [queryParameters]);

  return {
    data,
    queryParameters,
    dialog,
    handleFormSubmit,
    handleDialogClose,
    totalItems,
    handleIconClick,
    basePaginationService,
    serverResponseWaiting,
    enableDateParamOnlyOnManualDateChange,
    permission,
  };
};

export default useLinkedDevicesListController;
