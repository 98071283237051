import { achievementCriteriaTypeOptions } from '../config';

export const serverToClientDataMapper = data => {
  return data?.map(item => {
    return {
      id: item.id,
      metric: {
        id: item.alias,
        title: item.title,
      },
      achievementCriteriaType:
        item.criteria_type &&
        achievementCriteriaTypeOptions?.find(criteriaType => criteriaType.value === item.criteria_type),
      achievementCriteriaValue: item.criteria_value,
      active: item.active,
      sequence: item.sequence,
    };
  })?.sort((firstItem, secondItem) => {
    if (firstItem?.sequence < secondItem?.sequence) {
      return -1;
    }
    if (firstItem?.sequence > secondItem?.sequence) {
      return 1;
    }
  });
};

export const sequenceFormattedData = data => {
  return data?.map((item, index) => {
    return {
      id: item.id,
      sequence: index + 1,
    };
  });
};
