import { compose } from 'recompose';
import Grn from './GRN';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation ,CANCEL_GRN
} from './API';
import {
  TOGGLE_STATE, UPLOAD, DOWNLOAD_REPORT
} from '../../common/GqlAPI';

const ComposedGrn = compose(
  UPLOAD,
  TOGGLE_STATE,
  DOWNLOAD_REPORT,
  CANCEL_GRN,
  withRequestTracker({
    query,
    mutation,
  }),
)(Grn);

export default ComposedGrn;
