import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import {
  CustomSelect, Input, SingleDatePicker
} from '../../../components';
// import SubCampaignStyled from '../..';
import { BudgetSelect, TargetSelect } from '../campaign/details/subCampaign/BudgetSelect';
import withLoading from '../../../utils/composition/withLoading';
import SubCampaignStyled from '../campaign/details/subCampaign/SubCampaignStyled';
import { dimensionListConfig } from './config';

const propTypes = {
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  refsObj: PropTypes.instanceOf(Object),
  getState: PropTypes.func,
  updateState: PropTypes.func,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  enableErrorDisplay: true,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  getState: () => null,
  updateState: () => null,
  handleInputChange: () => null,
};

const TopForm = ({ ...props }) => {
  const {
    data,
    totalBudget,
    refsObj,
    handleInputChange,
    enableErrorDisplay,
    handleDropDownChange,
    handleCancel,
    subCampaignTypeList,
    onDropDownChangeTarget,
    handleAdd,
    budgetOverflowFlag,
    targetOverflowFlag,
    onHandleInputChange,
    campaignDataObj,
    topData,
    handleDimensionChange,
  } = props;

  return (
    <SubCampaignStyled>
      <div className='body_wrap'>
        <div className='row-custom r1'>
          <Row>
            <Col md={12}>
              <Input
                type='text'
                enableValidation
                labelContent='Title'
                name='title'
                value={data.title}
                placeholder='Title'
                labelContent='Title'
                ref={(ref) => (refsObj.title = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleInputChange(event)}
              />
              <CustomSelect
                enableValidation
                options={subCampaignTypeList}
                labelContent='Sub Campaign Type'
                className='custom-select'
                placeholder='Select Sub Campaign Type'
                getOptionValue={({ type }) => type}
                getOptionLabel={({ type }) => type}
                ref={(ref) => (refsObj.subCampaignType = ref)}
                enableErrorDisplay={enableErrorDisplay}
                onChange={(event) => handleDropDownChange(event.type, ['subCampaignType'])}
                value={subCampaignTypeList.filter(({ type }) => type === data.subCampaignType)}
              />
            </Col>
            <Col md={6}>
              <div className='single-date-picker'>
                <label>Start Date</label>
                <SingleDatePicker
                  name='startDate'
                  date={data.startDate}
                  onChange={(name, date) =>
                    handleInputChange({
                      target: {
                        name: 'startDate',
                      },
                      formattedValue: date,
                    })
                  }
                  minDate={topData.campaign.startDate}
                  // maxDate={topData.campaign.endDate}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className='single-date-picker end-date'>
                <label>End Date</label>
                <SingleDatePicker
                  name='endDate'
                  date={data.endDate}
                  onChange={(name, date) =>
                    handleInputChange({
                      target: {
                        name: 'endDate',
                      },
                      formattedValue: date,
                    })
                  }
                  // minDate={topData.campaign.startDate}
                  maxDate={topData.campaign.endDate}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className='r2 row-custom'>
          <BudgetSelect
            data={data}
            title='Budget'
            onHandleInputChange={onHandleInputChange}
            handleDropDownChange={handleDropDownChange}
            refsObj={refsObj}
            enableErrorDisplay={enableErrorDisplay}
            totalBudget={totalBudget}
            handleAdd={handleAdd}
            handleCancel={handleCancel}
            campaignDataObj={campaignDataObj}
            subCampaignTypeList={subCampaignTypeList}
            budgetOverflowFlag={budgetOverflowFlag}
            dimensionList={dimensionListConfig}
            handleDimensionChange={handleDimensionChange}
          />
        </div>
        <div className='r3 row-custom'>
          <TargetSelect
            data={data}
            title='Target'
            onHandleInputChange={onHandleInputChange}
            handleDropDownChange={handleDropDownChange}
            refsObj={refsObj}
            enableErrorDisplay={enableErrorDisplay}
            onDropDownChangeTarget={onDropDownChangeTarget}
            handleAdd={handleAdd}
            handleCancel={handleCancel}
            subCampaignTypeList={subCampaignTypeList}
            dimensionList={dimensionListConfig}
            handleDimensionChange={handleDimensionChange}
          />
        </div>
      </div>
    </SubCampaignStyled>
  );
};

TopForm.propTypes = propTypes;

TopForm.defaultProps = defaultProps;

const TopFormWithErrorAndLoading = withLoading(TopForm);

export default TopFormWithErrorAndLoading;
