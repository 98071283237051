import PropTypes from 'prop-types';
import React from 'react';
import { PERMISSION_OBJ } from '../../../../../data/enums/Permission';
import { Icon, tableData } from '../../../../../v4/components';

const labelMappings = {
  sku: 'SKU',
  target: 'Target',
  order: 'Order',
  status: 'status'
};

const generateIconAndColor = (order = 0, target = 0) => {
  if (!order || order === 0) {
    return {
      icon: 'times-circle-full',
      color: '#DA1414'
    };
  }

  if (order > 0 && order < target) {
    return {
      icon: 'times-circle',
      color: '#FF8F39'
    };
  }

  return {
    icon: 'check-circle-full',
    color: '#287D3C'
  };
}

const returnTableBody = (data) => {
  const { icon, color } = generateIconAndColor(data?.quantity, data?.pfdtarget);
  const table = {};
  table[labelMappings.sku] = <td key={`${data.id}- sku`} >{data.title}</td>;
  table[labelMappings.target] = (
    <td key={`${data.id}- target`} className='text-right' onClick={(e) => e.stopPropagation()}>
      {data?.pfdtarget || '-'}
    </td>
  );
  table[labelMappings.order] = <td className='text-right' key={`${data.id}- order`}>{data?.quantity || 0}</td>;
  table[labelMappings.status] = (
    <td key={`${data.id}- status`} className='text-right'>
      <Icon iconName={icon} style={{ color }} />
    </td>
  )

  return table;
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.sku,
    title: 'SKU',
    show: true,
  },
  {
    id: 2,
    label: labelMappings.target,
    title: 'Target',
    show: true,
    classNames: 'text-right'
  },
  {
    id: 3,
    label: labelMappings.order,
    title: 'Order',
    show: true,
    classNames: 'text-right'
  },
  {
    id: 4,
    label: labelMappings.status,
    title: '',
    show: true,
    classNames: 'text-right'
  }
];

const TableBody = ({ data }) => {
  const tableBody = returnTableBody(data);

  return (
    <tr key={data.id} style={{ cursor: 'pointer' }}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  permission: PropTypes.instanceOf(Object),
};

TableBody.defaultProps = {
  data: {},
  permission: PERMISSION_OBJ,
};

const TableHeader = () => (
  <thead className=''>
    <tr>{labelConfig.map((label) => (label.show ? tableData(label) : null))}</tr>
  </thead>
);

TableHeader.propTypes = {
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
};

TableHeader.defaultProps = {
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
