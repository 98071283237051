import React, { useState } from 'react'
import FormContentStyled from '../../components/global-css/FormContentStyled';
import withAlert from '../../utils/composition/withAlert';
import { Button, Icon } from '../../v4/components'
import { MessageStyled } from '../../v4/components/Input/InputStyled';
import { ALERT_TYPE } from '../../v4/constants/AlertType';
import { PanelHeader, PanelStyled } from '../common/configuration'
import DataMaskStyled from './DataMaskStyled';

const DataMask = (props) => {
  const [data, setData] = useState({ invoiceId: '' });
  const [enableValidation, setEnableValidation] = useState(false);

  const handleButtonCancel = () => {
    setData({ invoiceId: '' });
  };

  const handleButtonSubmit = () =>{
    const { maskInvoices, displayAlert } = props;
    const input = data.invoiceId.trim().split(',').map((a)=>Number(a)).filter((i)=>Number(i));
    if (data.invoiceId==='' || input?.length===0) {
      setEnableValidation(true);
    } else {
      maskInvoices({
        invoiceId: input
      },{
        handleSuccess: (response) =>{
          displayAlert(ALERT_TYPE.SUCCESS, response.data.maskInvoices);
        },
        handleError: (error) => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      })
    }
  }

  const handleInputChange = (e) => {
    setData( { ...data, [e.target.name]:e.target.value });
  }

  return (
    <div className='section-header'>
      <PanelStyled>
        <PanelHeader>
          <h2>Data Mask</h2>
          <div className='flex m-0'>
            <div>
              <Button
                small
                secondary
                onClick={() => handleButtonCancel()}
              >
                <span>Cancel</span>
              </Button>
              <Button
                small
                primary
                onClick={() => handleButtonSubmit()}
              >
                <span>Submit</span>
              </Button>
            </div>
          </div>
        </PanelHeader>
      </PanelStyled>
      <div className='section-content'>
        <DataMaskStyled>
          <FormContentStyled>
            <h3 className='w-100'>Enter Invoice Id</h3>
            <textarea
              name='invoiceId'
              type='text'
              id='invoiceId'
              placeholder='Invoice Id'
              onChange={(event) => handleInputChange(event)}
              value={data.invoiceId}
            />
            {enableValidation && data.invoiceId === '' ? (
              <MessageStyled className='form-error'>
                <span className='error-message'>
                  <Icon iconName='exclamation-full' />
                  {'Must not be empty'}
                </span>
              </MessageStyled>
            ) : (
              ''
            )}
          </FormContentStyled>
        </DataMaskStyled>
      </div>
    </div>
  )
}

export default withAlert()(DataMask);
