import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import View from './View';
import withAlert from '../../../../../../utils/composition/withAlert';
import { getPermissionForSubDReplenishment } from '../../../../../base/permission';
import { DialogFormWrapper } from '../../../../../common';
import { EVENT_OPERATION } from '../../../../../../v4/constants/EventOperation';
import confirmationGenerator from '../../../../../common/DialogConfirmation';
import {
  Title, crudSuccess, frequencyType
} from './config';
import Dialog from './Dialog';
import { ALERT_TYPE } from '../../../../../../v4/constants/AlertType';
import { isError } from '../../../../../common/HelperFunctions';
import { dropdownChange, inputChange } from '../../../../../../utils/formHandlers';
import ReplenishmentModelWrapper from './replenishmentModelWrapper';
import { MESSAGE_EVENT_OPERATION } from '../../../../../../v4/constants/successMessage';
import { STATEFUL_ENTITIES } from '../../../../../../data/enums/GraphQL';
import BulkDelete from '../../../../../../v4/components/BulkDelete/BulkDelete';
import { refGenerator } from '../../../../../../utils';
import { FORM_CONFIG } from '../../../../../../v4/constants/formConfig';
import { FREQUENCY_TYPE } from '../../../../../../data/enums';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  getReplenishmentGroupDetail: PropTypes.func.isRequired,
  updateReplenishmentModel: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};
const initialDialogState={
  IcoGroupId: '',
  doh: '',
  frequency: {
    date: '',
    day: [],
    times: {
      hours: '00',
      minutes: '00',
      period: 'AM',
    },
    type: '',
  },
  stockGroupId: '',
}

const ReplenishmentModel = (props) => {
  const { groupId, serverResponseWaiting, displayAlert, getICOGroupList, icoStockGroupsList, createICOStockGroups, updateICOStockGroups, toggleState }=props;
  const [data, setdata] = useState([])
  const [searchText, setSearchText] = useState('')
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [icoIds, seticoIds] = useState([])
  const [dialogData, setDialogData] = useState({
    ...initialDialogState,
  });
  const [icoGroupList, setICOGroupList] = useState([])
  const permission = getPermissionForSubDReplenishment()
  const typeList = [FREQUENCY_TYPE.MONTHLY,FREQUENCY_TYPE.TRIMONTHLY,FREQUENCY_TYPE.FORTNIGHTLY,FREQUENCY_TYPE.CUSTOM]
  const requiredList = typeList?.includes(data?.frequency?.type)?['ico','doh','frequency','date']:['ico','frequency','doh']

  const formConfig = {
    [EVENT_OPERATION.CREATE]: {
      [FORM_CONFIG.TITLE]: `Add ${Title}`,
      [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
      [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    },
    [EVENT_OPERATION.UPDATE]: {
      [FORM_CONFIG.TITLE]: `Edit ${Title}`,

      [FORM_CONFIG.REFS_OBJ]: refGenerator(requiredList),
      [FORM_CONFIG.VALIDATION_REQUIRED]: true,
    },
    [EVENT_OPERATION.DELETE]: {
      [FORM_CONFIG.TITLE]: `Delete ${Title}`,
      [FORM_CONFIG.MAPPER]: (element) => ({
        id: element.id || '',
      }),
    },
  };
  const handleSearchInput=(text)=>{
    setSearchText(text)
  }

  const handleIconClick = (type, element = {}) => {
    setDialog({
      type,
      element,
    });
    if(type===EVENT_OPERATION.CREATE){
      getICOGroup(icoIds)
    }
    if (type !== EVENT_OPERATION.CREATE) {
      setDialogData({
        id: element.id,
        IcoGroupId: element.icoGroups?.id||0,
        doh: element.doh,
        frequency: {
          date: element.frequency.date,
          day: element.frequency.day,
          times: {
            hours: element.frequency.time.split(':')[0],
            minutes: (element.frequency.time.split(':')[1]).split(' ')[0],
            period: (element.frequency.time.split(':')[1]).split(' ')[1],
          },
          type: element.frequency.type,
        },
        stockGroupId: groupId,
      })
      setICOGroupList([element.icoGroups]||[]);
    }
  }
  const handleInputChange=(e, firstParam = '',parameterRef = [],param)=>{
    if(param === 'checkbox'){
      // changes for checkbox when frequency is WEEKLY
      const day = dialogData?.frequency?.day
      if (e.target.checked) {
        day.push(e.formattedValue)
        const updatedDetails = inputChange(dialogData, { ...e,formattedValue:day },firstParam, parameterRef);
        setDialogData(updatedDetails);
      }else{
        dialogData.frequency.day = dialogData.frequency.day.filter((d) => d !== e.formattedValue).filter((d) => d !== null)
        const updatedDetails = inputChange(dialogData, { ...e,formattedValue:dialogData.frequency.day },firstParam, parameterRef);
        setDialogData(updatedDetails);

      }
    }else if(param === 'monthly'){
      let repeatDates = [];
      repeatDates.push(parseInt(e.target.value));
      const updatedDetails = inputChange(dialogData, { ...e,formattedValue: repeatDates },firstParam, parameterRef);
      setDialogData(updatedDetails);

    }else if(param === 'fortnightly'){
      let repeatDates = [];
      const todayDate = new Date();
      let [year, month, day] = [todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate()];
      const lastDayOfMonth = new Date(year, month, 0);
      if(e.target.value!==''){
        day = parseInt(e.target.value);
        if (day + 14 > lastDayOfMonth) {
          repeatDates.push(day);
        } else {
          repeatDates.push(day);
          if (day <= 15) {
            repeatDates.push(day + 14);
          }
        }}
      const updatedDetails = inputChange(dialogData, { ...e,formattedValue: repeatDates },firstParam, parameterRef);
      setDialogData(updatedDetails);
    }else if(param === 'trimonthly'){
      let repeatDates = [];
      const todayDate = new Date();
      let [year, month, day] = [todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate()];
      const lastDayOfMonth = new Date(year, month, 0);
      const lastDate = lastDayOfMonth.getDate()
      if(e.target.value!==''){
        day = parseInt(e.target.value);
        if(day+10 > lastDate) {
          repeatDates.push(day);
        } else if(day + 20 > lastDate) {
          repeatDates.push(day);
          repeatDates.push(day +10);
        } else {
          repeatDates.push(day);
          repeatDates.push(day + 10);
          repeatDates.push(day + 20);
        }}
      const updatedDetails = inputChange(dialogData, { ...e,formattedValue: repeatDates },firstParam, parameterRef);
      setDialogData(updatedDetails);
    } else{
      const updatedDetails = inputChange(dialogData, e,firstParam, parameterRef);
      setDialogData(updatedDetails);
    }
  }

  const handleDropDownChange = (value, parameterRef = [],param) => {
    if(param==='frequency'){
      dialogData.frequency={
        ...dialogData.frequency,
        date:'',
        day:[],
        type:value,
      }
      setDialogData({ ...dialogData,frequency:dialogData.frequency });

    }else{
      const updatedDetails = dropdownChange(dialogData, parameterRef, value);
      setDialogData(updatedDetails);
    }  };

  const handleDialogSubmit=(type)=>{
    const frequency = dialogData.frequency;
    if(frequency?.type===FREQUENCY_TYPE.WEEKLY){
      // repeat days validation
      if(frequency.day?.length===0){
        displayAlert(ALERT_TYPE.WARNING,'Select repeat days');
        return;
      }
    }
    const { hours, minutes, period } = frequency.times;
    const frequencyData = {
      date: frequency.date||null,
      day: frequency.day?.length>0 ? frequency.day : null,
      time: `${hours}:${minutes} ${period}`,
      type : frequency.type,
    }
    switch (type) {
    case EVENT_OPERATION.CREATE:
      createICOStockGroups({
        input: {
          IcoGroupId: dialogData.IcoGroupId||null,
          doh: dialogData.doh||null,
          frequency: frequencyData,
          stockGroupId: groupId,
        }
      },{
        handleSuccess: (res) => {
          if (res){
            displayAlert(ALERT_TYPE.SUCCESS, `${Title} added sucessfully`);
          }
          resetDialog();
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      })
      break;
    case EVENT_OPERATION.UPDATE:
      updateICOStockGroups({
        id:dialogData.id,
        input: {
          IcoGroupId: dialogData.IcoGroupId||null,
          doh: dialogData.doh||null,
          frequency: frequencyData,
          stockGroupId: groupId,
        }
      },{
        handleSuccess: (res) => {
          if (res){
            displayAlert(ALERT_TYPE.SUCCESS, `${Title} updated sucessfully`);
          }
          resetDialog()
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      })
      break;
    default:
      toggleState({ ids: [dialogData?.id], active: false, type: STATEFUL_ENTITIES.StockIcoGroupDetail });
      displayAlert(ALERT_TYPE.SUCCESS, `SKU Group ${MESSAGE_EVENT_OPERATION.DELETE}`);
      loadTableData();
      break;
    }
  }
  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
    setDialogData(initialDialogState);
    loadTableData();
  }
  const getICOGroup = (icoId) => {
    getICOGroupList(
      {
        offset:0,
        limit: 2000,
        filter: {},
      },
      {
        handleSuccess: (res) => {
          if (isError(res)) onAPIRequestFailure(res.errors[0]);
          const response = res?.data?.icoGroups?.rows || [];
          const filteredICOList = response?.filter((row) => !icoId.includes(row.id));
          setICOGroupList(filteredICOList||[]);
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      },
    );
  }

  const loadTableData = () => {
    icoStockGroupsList(
      {
        offset:0,
        limit: 2000,
        filter: {
          queryString: searchText,
          filters: [
            {
              column: 'stock_group_id',
              value: groupId?.toString()
            }
          ]
        },
      },
      {
        handleSuccess: (res) => {
          const response = res?.data?.icoStockGroupsList?.rows||[]
          seticoIds(response?.map((a)=>a.icoGroups?.id))
          setdata(response)
        },
        handleError: (err) => {
          onAPIRequestFailure(err);
        },
      },
    );
  }
  const onAPIRequestFailure = (error) => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };
  const handleBulkDelete = (type) => {
    displayAlert(ALERT_TYPE.SUCCESS, crudSuccess[type].message);
    loadTableData();
  };

  useEffect(() => {
    loadTableData();
  }, [])


  return (
    <ReplenishmentModelWrapper>
      {dialog.type && (
        <DialogFormWrapper
          formConfig={formConfig[dialog.type]}
          dialogElement={dialog.element}
          onDialogSubmit={handleDialogSubmit}
          onDialogCancel={resetDialog}
          type={dialog.type}
          disableDialogClose
          renderDialog={({ refsObj, enableErrorDisplay }) => (
            <>
              {dialog.type === EVENT_OPERATION.DELETE &&
                confirmationGenerator(EVENT_OPERATION.DELETE, null, data.title)}
              {(dialog.type === EVENT_OPERATION.CREATE || dialog.type === EVENT_OPERATION.UPDATE) && (
                <Dialog
                  dialogType={dialog.type}
                  data={dialogData}
                  refsObj={refsObj}
                  loading={serverResponseWaiting}
                  icoGroupList={icoGroupList}
                  frequencyType={frequencyType}
                  handleInputChange={handleInputChange}
                  enableErrorDisplay={enableErrorDisplay}
                  handleDropdownChange={handleDropDownChange}
                  displayAlert={displayAlert}
                />
              )}
            </>
          )}
        />
      )}
      <BulkDelete
        data={data}
        type={STATEFUL_ENTITIES.StockIcoGroupDetail}
        handler={handleBulkDelete}
        toggleState = {toggleState}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <View
            data={data}
            handleSearchInput={handleSearchInput}
            checkedList={checkedList}
            permission={permission}
            primaryCheckboxHandler={primaryCheckboxHandler}
            secondaryCheckboxHandler={secondaryCheckboxHandler}
            onIconClick={handleIconClick}
          />)}
      />
    </ReplenishmentModelWrapper>
  )
}
ReplenishmentModel.propTypes = propTypes;
ReplenishmentModel.defaultProps = defaultProps;

export default withAlert()(ReplenishmentModel);
