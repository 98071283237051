import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Form, SingleDatePicker
} from '../../../../components';
import withLoading from '../../../../utils/composition/withLoading';
import { EVENT_OPERATION } from '../../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    target: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  refsObj: PropTypes.instanceOf(Object),
  type: PropTypes.string,
};

const defaultProps = {
  data: {
    target: '',
    startDate: '',
    endDate: '',
  },
  refsObj: {
    target: '',
    startDate: '',
    endDate: '',
  },
  enableErrorDisplay: true,
  handleInputChange: () => null,
  type: '',
};

const OutletCoverageTargetForm = ({ ...props }) => {
  const { data, refsObj, handleInputChange, enableErrorDisplay, type } = props;
  return (
    <Form>
      <>
        <div className='single-date-picker'>
          <label>Start Date</label>
          <SingleDatePicker
            date={data.startDate}
            maxDate={data.endDate}
            disabled={type === EVENT_OPERATION.READ}
            name='startDate'
            placeholder='Start Date'
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={(name, date) =>
              handleInputChange(
                {
                  target: {
                    name: 'startDate',
                    value: date,
                  },
                  formattedValue: date,
                },
                '',
              )
            }
            labelContent='Start Date'
          />
        </div>

        <div className='single-date-picker'>
          <label>End Date</label>
          <SingleDatePicker
            date={data.endDate}
            minDate={data.startDate}
            disabled={type === EVENT_OPERATION.READ}
            name='endDate'
            placeholder='End Date'
            enableErrorDisplay={enableErrorDisplay}
            enableValidation
            onChange={(name, date) =>
              handleInputChange(
                {
                  target: {
                    name: 'endDate',
                    value: date,
                  },
                  formattedValue: date,
                },
                '',
              )
            }
            labelContent='End Date'
          />
        </div>

        <Input
          ref={(ref) => (refsObj.target = ref)}
          value={data.target}
          name='target'
          type='number'
          argument={{ min: 0 }}
          placeholder='Coverage Target'
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          onChange={(event) => handleInputChange(event)}
          labelContent='Coverage Target'
          disabled={type === EVENT_OPERATION.READ}
          decimalWithPrecedingZero
        />
      </>
    </Form>
  );
};

OutletCoverageTargetForm.propTypes = propTypes;

OutletCoverageTargetForm.defaultProps = defaultProps;

const OutletCoverageTargetFormWithErrorAndLoading = withLoading(OutletCoverageTargetForm);

export default OutletCoverageTargetFormWithErrorAndLoading;
