import React from 'react';
import PropTypes from 'prop-types';
import TitleTextWrap from './TitleTextWrap';
import { fixedFloatAndComma, fixedFloatAndCommas } from '../../../utils/conversion';

const TitleText = ({ data, id, dimensions, styleAdjustment ,TASSalesChart }) => (
  <TitleTextWrap>
    <svg
      id={`${id}-title-text`}
      width={dimensions.width + 10}
      height={60 + (styleAdjustment.marginTop || 0) + (styleAdjustment.marginBottom || 0)}
    >
      <g transform={`translate(${0}, ${styleAdjustment.marginTop || 0})`}>
        <text className='value' x='0' y='5' textAnchor='left' color='#6B6C7E' style={{ fontSize: '12px' }}>
          { TASSalesChart ?
            (data.label === 'target'
              ?  'CALL TARGET'
              : data.label === 'received'
                ?  'RECEIVED'
                : data.label.toUpperCase())
            :
            (data.label === 'target'
              ? 'PFD TARGET'
              : data.label === 'achievement'
                ?  'RECEIVED'
                : data.label.toUpperCase())
          }
        </text>

        {(data.value || data.value === 0) && (
          <text className='value' x='0' y='34' textAnchor='left' color='#272833' style={{ fontSize: '24px' }}>
            {id === 'Callage' ? fixedFloatAndComma(data.value) : fixedFloatAndCommas(data.value) || 0}
          </text>
        )}
      </g>
    </svg>
  </TitleTextWrap>
);

TitleText.Prototypes = {
  styleAdjustment: PropTypes.String,
  TASSalesChart:PropTypes.bool,
};

TitleText.defaultProps = {
  styleAdjustment: {},
  TASSalesChart:false,

};
export default TitleText;
