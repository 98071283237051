import { compose } from 'recompose';
import PrnDetails from './Detail';
import { query } from './API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';

const ComposedPrnDetails = compose(
  withRequestTracker({
    query,
  }),
)(PrnDetails);

export default ComposedPrnDetails;
