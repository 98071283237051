import { compose } from 'recompose';
import SplitPayment from './SplitPayment';
import withRequestTracker from '../../../utils/composition/withRequestTracker';
import {
  query, mutation, SPLIT_PAYMENT, UPDATE_PAYMENT
} from './API';
import { CHANGE_PAYMENT_STATUS } from '../BulkSelect/API';

const composeSplitPayment = compose(
  SPLIT_PAYMENT,
  CHANGE_PAYMENT_STATUS,
  UPDATE_PAYMENT,
  withRequestTracker({
    query,
    mutation,
  }),
)(SplitPayment);

export default composeSplitPayment;
