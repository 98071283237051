import styled from 'styled-components';
import { IMAGE_STATUS_MAPPER } from './config';

const UploadWithInputStyled = styled.div`
  .top-section {
    display: flex;
    padding: 24px;
    background: #ffffff;
    .form-input {
      width: 254px;
      margin-right: 24px;
      margin-bottom: 0;
    }
    .form-select-input {
      width: 254px;
      margin-bottom: 0;
    }
  }

  .upload-section {
    width: 580px;
    max-width: unset;
    &.sync-progress {
      width: 395px;
      .progress-card {
        padding: 24px !important;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
        background-color: #ffffff;
        border-bottom: unset;
        .count-total {
          font-size: 16px;
          line-height: 21px;
          color: #272833;
          margin: 0;
          margin-bottom: 4px;
        }
      }
      .progress-bar-styled-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        .progress-wrap {
          width: 90%;
          height: 8px;
          margin-right: 4px;
          .progress-bar {
            margin: 0;
            background-color: #cfd0da;
            & > div {
              background-color: #5aca75 !important;
            }
          }
        }
        span {
          position: relative;
          top: -2px;
          color: #287d3c;
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
        }
      }
      .failed,
      .sucess {
        display: none !important;
      }
    }
    .img-upload-wrap {
      display: inline-block;
      width: 100%;
      .img-icon img {
        height: 32px;
        width: 32px;
        border-radius: 2px;
        margin-right: 16px;
        background-color: #ffffff;
        margin-top: 8px;
      }
      h3 {
        font-size: 14px;
        line-height: 21px;
        color: #272833;
        font-weight: 500;
        margin: 0;
      }
      .img-progress-info {
        display: flex;
        justify-content: space-between;
        span {
          font-size: 10px;
          line-height: 10px;
          color: #6b6c7e;
        }
      }
      .progress-bar-wrap {
        flex: 1;
      }
      .progress-bar {
        width: 456px;
        width: 100%;
        height: 8px;
        border-radius: 4px;
        margin: 4px 0;
      }
      .failed,
      .sucess {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .icon {
          margin-right: 5px;
          height: 14px;
          width: 14px;
        }
      }
      .sucess {
        color: #287d3c;
      }
      .failed {
        color: #da1414;
      }
    }
  }
`;

export default UploadWithInputStyled;
