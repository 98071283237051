import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '../../v4/components';
import ButtonStyled from './ButtonStyled';

const propTypes = {
  /**
   * @default null
   * children passed within button
   */
  children: PropTypes.node,
  /**
   * @default ''
   * className passed along with the button
   */
  className: PropTypes.string,
  /**
   * @default ''
   * title to be passed along with the string
   */
  title: PropTypes.string,
  /**
   * @default false
   * button is primary or not.
   */
  primary: PropTypes.bool,
  /**
   * @default false
   * button is secondary
   */
  secondary: PropTypes.bool,
  /**
   * @default false
   * button is small
   */
  small: PropTypes.bool,
  /**
   * @default false
   * small
   */
  active: PropTypes.bool,
  /**
   * @default false
   * disabled
   */
  disabled: PropTypes.bool,
  /**
   * @default false
   * disabled
   */
  iconBtnSmall: PropTypes.bool,
};

const defaultProps = {
  children: undefined,
  className: '',
  primary: false,
  secondary: false,
  small: false,
  active: false,
  disabled: false,
  title: null,
  iconBtnSmall: false,
};

const Button = ({
  children,
  className,
  primary,
  secondary,
  outlined,
  small,
  medium,
  active,
  link,
  disabled,
  iconName,
  title,
  iconBtnSmall,
  search,
  borderless,
  big,
  rightIcon,
  ...otherProps
}) => {
  const classes = classNames(
    {
      primary,
      secondary,
      outlined,
      small,
      medium,
      big,
      active,
      link,
      disabled,
      iconBtnSmall,
      search,
      borderless,
    },
    className,
  );
  return (
    <Fragment>
      <ButtonStyled className={classes} iconName={iconName} {...otherProps}>
        {iconName ? <Icon iconName={iconName} /> : null}
        {title ? <span>{title}</span> : null}
        {rightIcon ? <Icon iconName={rightIcon} /> : null}
        {children}
      </ButtonStyled>
    </Fragment>
  );
};

Button.propTypes = propTypes;

Button.defaultProps = defaultProps;

export default Button;
