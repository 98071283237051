import styled from 'styled-components';

const TargetAchieveStyled = styled.div`
  .section-contents {
    height: unset !important;
    overflow: hidden;
    .width-200 {
      width: 200px !important;
    }
    .width-150 {
      width: 150 !important;
    }
    .tr-head {
      position: sticky;
      z-index: 1;
      top: 0;
      background: #f1f2f5;
      color: #6b6c7e;
      font-weight: 600;
      height: 48px;
    }
    & > div > .table-wrap {
      height: calc(100vh - 115px) !important;
      overflow: auto !important;
      & > table {
        border-bottom: 0;
        thead th {
          span {
            text-transform: uppercase;
          }
        }
      }
    }
    .principle-disable {
      /* &.table-wrap{
      height:unset !important;
      overflow:unset !important;
    } */
      table {
        border: 0;
      }
      .table-wrap-second table thead tr th:first-child {
        padding-left: 48px;
      }
    }
    .table-wrap-second {
      table {
        thead {
          tr {
            height: 48px;
            th {
              background: #f1f2f5;
              color: #6b6c7e;
              font-weight: 600;
              &:first-child {
                padding-left: calc(48px + 32px);
              }
            }
          }
        }
      }
    }
  }
  .filter-wrap {
    .form-select-input {
      margin: 0;
      label {
        display: none;
      }
      .select-css {
        .zindex-2__control {
          width: 120px;
          min-height: 32px;
          height: 32px;
          .zindex-2__single-value {
            font-size: 14px;
            color: #6b6c7e;
            top: 42%;
          }
          .zindex-2__indicators {
            .icon {
              color: #6b6c7e;
              position: relative;
              top: -1px;
            }
          }
        }
      }
    }
  }
  .status-label {
    padding: 4px;
    font-size: 8px;
    border-radius: 2px;
    min-width: 58px;
    height: 16px;
    margin-left: 6px;
    line-height: 6px;
  }

  tr {
    th,
    td {
      width: auto;
      white-space: nowrap;
      /* &:nth-last-child(3),
        &:nth-last-child(2),
        &:nth-last-child(1) {
          width: 80px !important;
        } */

      &:first-child {
        width: 30% !important;
      }

      &:nth-child(2) {
        width: 15% !important;
      }
      /* &:nth-child(4),
        &:nth-child(3) {
          text-align: right;
          width: 20%;
        } */
    }
  }
  .table-wrap > table > tbody {
  }
  .tr-head ~ tr {
    td {
      &:last-child {
        text-align: right;
      }
    }
  }
  .tr-head {
    height: 64px;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: #f0f5ff;
    th {
      border-bottom: 1px solid #0c5fff !important;
    }
  }
`;

export default TargetAchieveStyled;
