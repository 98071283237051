import { compose } from 'recompose';
import SKUGroupType from './SKUGroupType';
import { query, mutation, TOGGLE_STATE, createSKUGroup, updateSKUGroup, replicateSKUGroup } from '../Repository/API';
import withRequestTracker from '../../../../../utils/composition/withRequestTracker';

const composedSKUGroup = compose(
  TOGGLE_STATE,
  createSKUGroup,
  updateSKUGroup,
  replicateSKUGroup,
  withRequestTracker({
    query,
    mutation,
  }),
)(SKUGroupType);

export default composedSKUGroup;
