import React, { Component } from 'react';
import {
  Redirect, Router, Route, Switch
} from 'react-router-dom';
import history from './utils/history';
import { Login, Base } from './views';
import { errorPage as Error } from './styleguide';
import ScrollToTop from './views/common/ScrollToTop';
import { ErrorPage } from './v4/components';
import ForgotPassword from './views/login/resetPassword';
import { checkAuthentication } from './utils/authUtils';
import AlertBaseComponent from './v4/components/Alert/AlertBaseComponent';
import TwoFactorAuth from './views/twoFactorAuth/index.js';

const PrivateRoute = ({ component: Component, ...rest }) => {
  if(checkAuthentication()){
    const User = JSON.parse(localStorage.getItem('rosia-detail'));
    const enableGoogleAuth = User && User.Role.enableGoogleAuth;
    const isTwoFaVerified = JSON.parse(localStorage.getItem('isTwoFaVerified'));
    if(enableGoogleAuth){
      if(isTwoFaVerified){
        return(
          <Route
            {...rest}
            render={(props) =><Component {...props} />}
          />
        )
      }
      else{
        return(
          <Route
            {...rest}
            render={(props) => <Redirect
              to='/two-factor-auth'
            />
            }
          />
        )
      }
    }
    return(
      <Route
        {...rest}
        render={(props) =><Component {...props} />}
      />
    )
  }

  else{
    return(
      <Route
        {...rest}
        render={(props) =><Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
        }
      />
    )
  }
};

class App extends Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            <Route exact path='/' render={() => <Redirect to='/login' />} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot-password/:token' component={ForgotPassword} />
            <Route exact path='/two-factor-auth' component={TwoFactorAuth} />
            <ScrollToTop>
              <PrivateRoute path='/' component={Base}/>
            </ScrollToTop>
            <Route exact path='`/new/:page`' component={Error} />
            <Route component={ErrorPage} />
          </Switch>
        </Router>
        <AlertBaseComponent/>
      </div>
    );
  }
}

export default App;
