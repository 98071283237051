import styled from 'styled-components';
import Colors from '../../utils/theme/color';

const ButtonStyled = styled.button`
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  letter-spacing: 0;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 4px;
  outline: none;
  margin-right: 16px;
  text-transform: capitalize;
  background: white;

  &:last-of-type {
    margin-right: 0;
  }

  ${(props) =>
    props.className.includes('small') && {
      padding: '4px 12px',
      fontSize: '14px',
    }}

  ${(props) =>
    props.className.includes('iconBtnSmall') && {
      padding: '4px 7px',
    }}

  ${(props) =>
    props.className.includes('medium') && {
      padding: '12px',
      lineHeight: '16px',
    }}
  
   ${(props) =>
    props.className.includes('big') && {
      height: '64px',
      width: '64px',
    }}
  

   ${(props) =>
    props.className.includes('search') && {
      padding: '4px 7px',
      backgroundColor: Colors.grey.G100,
      borderColor: Colors.grey.G500,
    }}
  
  ${(props) =>
    props.className.includes('primary') && {
      backgroundColor: Colors.primary.P500,
      color: Colors.grey.G050,
      borderColor: Colors.primary.P500,
    }}
  
  &:hover {
    ${(props) =>
    props.className.includes('primary') && {
      backgroundColor: '#0053F0',
    }}
  }
  &:active,
  &:focus {
    ${(props) =>
    props.className.includes('primary') && {
      backgroundColor: '#004AD7',
      borderColor: Colors.primary.P600,
    }}
  }

  ${(props) =>
    props.className.includes('secondary') && {
      backgroundColor: Colors.grey.G050,
      color: '#6B6C7E',
      borderColor: '#CDCED9',
    }}

  &:hover {
    ${(props) =>
    props.className.includes('secondary') && {
      backgroundColor: ' #F7F8F9',
      color: '#272833',
    }}
  }

  &:active,
  &:focus {
    ${(props) =>
    props.className.includes('secondary') && {
      backgroundColor: ' #F1F2F5',
      color: '#272833',
    }}
  }

  ${(props) =>
    props.className.includes('borderless') && {
      backgroundColor: '#FFFFFF',
      color: '#0B5FFF',
    }}

  &:hover {
    ${(props) =>
    props.className.includes('borderless') && {
      backgroundColor: ' #FFFFFF',
      color: '#0B5FFF',
    }}
  }
  &:active,
  &:focus {
    ${(props) =>
    props.className.includes('borderless') && {
      backgroundColor: ' #F1F2F5',
      color: '#272833',
    }}
  }

  ${(props) =>
    props.className.includes('outlined') && {
      backgroundColor: Colors.grey.G100,
      color: '#6B6C7E',
      borderColor: Colors.grey.G500,
    }}

  &:hover, &:focus {
    ${(props) =>
    props.className.includes('outlined') && {
      backgroundColor: Colors.primary.P600,
      borderColor: Colors.primary.P600,
      color: Colors.grey.G050,
    }}
  }

  &.disabled {
    opacity: 0.4;
    cursor: no-drop;
    pointer-events: none;

    &.primary {
      background: #b3cdff;
      border-color: #b3cdff;
      color: #fff;
    }

    &.secondary {
      background: #ffffff;
      border-color: #e7e7ed;
      color: #a7a9bc;
    }

    &.borderless {
      background: #ffffff;
      color: #a7a9bc;
    }

    &.outlined {
      background: #b3cdff;
      border-color: #b3cdff;
      color: #a7a9bc;
    }
  }

  &.link {
    padding: 0;
    color: #0e69ff;
    text-decoration: underline;
    background: transparent;

    &:hover,
    &:focus {
      color: #0056e0;
      text-decoration: none;
    }
  }

  > span {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  .icon {
    color: #6b6c7e;
  }

  &.iconBtnSmall.outlined {
    &:hover,
    &:focus {
      .icon {
        color: #fff;
      }
    }
  }

  &.iconBtnSmall.primary {
    .icon {
      color: #fff;
    }
  }

  &.iconBtnSmall.secondary {
    &:hover,
    &:focus {
      .icon {
        color: #272833;
      }
    }
  }

  &.iconBtnSmall.borderless {
    &:hover,
    &:focus {
      .icon {
        color: #272833;
      }
    }
  }

  &.small.primary {
    .icon {
      color: #fff;
    }
    &:hover,
    &:focus {
      .icon {
        color: #fff;
      }
    }
  }

  &.small.secondary {
    .icon {
      color: #6b6c7e;
    }
    &:hover,
    &:focus {
      .icon {
        color: #272833;
      }
    }
  }

  &.small.borderless {
    .icon {
      color: #6b6c7e;
    }
    &:hover,
    &:focus {
      .icon {
        color: #272833;
      }
    }
  }

  &.small.outlined {
    &:hover,
    &:focus {
      .icon {
        color: #fff !important;
      }
    }
  }

  &.search {
    position: relative;
    &:after {
      content: 'Search';
      position: absolute;
      opacity: 0;
      pointer-events: none;
      top: 150%;
      left: -50%;
      -webkit-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      background: #333;
      height: 24px;
      padding: 0 16px;
      border-radius: 4px;
      z-index: 99;
      white-space: nowrap;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
    }

    &:hover {
      &:after {
        opacity: 1;
        pointer-events: auto;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
  }
`;

export default ButtonStyled;
