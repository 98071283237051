import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableHeader from '../../base/TableHeader';
import { Badge } from '../../../v4/components';

const labelMappings = {
  binCode: 'binCode',
  openingBalance: 'openingBalance',
  inward: 'inward',
  outward: 'outward',
  adjustment: 'adjustment',
  closingBalance: 'closingBalance',
  damages: 'damages',
  shortages: 'shortages',
  expiry: 'expiry',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.binCode,
    title: 'Bin Code',
    show: true,
    sortable: true,
    className: 'sorting first',
  },
  {
    id: 4,
    label: labelMappings.openingBalance,
    title: 'Saleable',
    classNames: 'text-right',
    show: true,
    sortable: true,
    className: 'sorting text-right border-right border-left second',
  },
  {
    id: 5,
    label: labelMappings.inward,
    classNames: 'text-right',
    title: 'Inward',
    show: true,
    sortable: true,
    className: 'sorting text-right third',
  },
  {
    id: 6,
    label: labelMappings.outward,
    classNames: 'text-right',
    title: 'Outward',
    show: true,
    sortable: true,
    className: 'sorting text-right third',
  },
  {
    id: 7,
    label: labelMappings.damages,
    classNames: 'text-right',
    title: 'Damage',
    show: true,
    sortable: true,
    className: 'sorting text-right third',
  },
  {
    id: 8,
    label: labelMappings.expiry,
    classNames: 'text-right',
    title: 'Expiry',
    show: true,
    sortable: true,
    className: 'sorting text-right third',
  },
  {
    id: 9,
    label: labelMappings.adjustment,
    classNames: 'text-right',
    title: 'Adjustment',
    show: true,
    sortable: true,
    className: 'sorting text-right third',
  },
  {
    id: 10,
    label: labelMappings.closingBalance,
    classNames: 'text-right',
    title: 'Saleable',
    show: true,
    sortable: true,
    className: 'sorting text-right border-left fourth',
  },
];

const upperHeaderDetails = [
  {
    id: 1,
    label: '',
    title: '',
    className: 'text-center',
    columns: 1,
    sortable: false,
  },
  {
    id: 2,
    label: 'openingBalance',
    title: 'Opening Balance',
    className: 'text-center border-right border-left',
    columns: 1,
    sortable: false,
  },
  {
    id: 3,
    label: 'periodBalance',
    title: 'Period Balance',
    className: 'text-center border-right border-left',
    columns: 5,
    sortable: false,
  },
  {
    id: 4,
    label: 'closingBalance',
    title: 'Closing Balance',
    className: 'text-center',
    columns: 1,
    sortable: false,
  },
];

const TableHeaderConfig = ({ onHeaderClick }) => (
  <>
    <thead className="upperHeader">
      {upperHeaderDetails.map(data => (
        <th className={data.className} colSpan={data.columns}>
          {data.title}
        </th>
      ))}
    </thead>
    <TableHeader headerDetails={labelConfig} onHeaderClick={onHeaderClick} />
  </>
);

const returnTableBody = (data, onRowClick) => {
  const table = {};
  table[labelMappings.binCode] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-binCode`}>
      <span className="caret">
        {data?.bin_code} {!!data?.count && <Badge className="light" label={data?.count ?? 0} />}
      </span>
    </td>
  );
  table[labelMappings.openingBalance] = (
    <td
      onClick={() => onRowClick(data)}
      key={`warehouseReport-${data.binCode}-openingBalance`}
      className="text-right border-right border-left"
    >
      {data?.opening_sellable}
    </td>
  );
  table[labelMappings.inward] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-inward`} className="text-right">
      {data?.period_inward}
    </td>
  );
  table[labelMappings.outward] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-outward`} className="text-right">
      {data?.period_outward}
    </td>
  );
  table[labelMappings.damages] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-damages`} className="text-right">
      {data?.period_damages}
    </td>
  );
  table[labelMappings.expiry] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-expiry`} className="text-right">
      {data?.period_expiry}
    </td>
  );
  table[labelMappings.adjustment] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-adjustment`} className="text-right">
      {data?.period_adjustment}
    </td>
  );
  table[labelMappings.closingBalance] = (
    <td
      onClick={() => onRowClick(data)}
      key={`warehouseReport-${data.binCode}-closingBalance`}
      className="text-right border-left"
    >
      {data?.closing_sellable}
    </td>
  );
  table[labelMappings.damages] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-damages`} className="text-right">
      {data?.period_damages}
    </td>
  );
  table[labelMappings.expiry] = (
    <td onClick={() => onRowClick(data)} key={`warehouseReport-${data.binCode}-expiry`} className="text-right">
      {data?.period_expiry}
    </td>
  );

  return table;
};

const TableBody = ({ data, onRowClick }) => {
  const tableBody = returnTableBody(data, onRowClick);

  return labelConfig.map(label => (label.show ? tableBody[label.label] : null));
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  onRowClick: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
};

TableHeaderConfig.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
};
export { TableHeaderConfig as TableHeader, TableBody };
