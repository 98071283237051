import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import withLoading from '../../../../../utils/composition/withLoading';
import { EMPTY, INVALID } from '../../../../../data/enums/ErrorMessage';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';
import {
  CustomSelect, Form, Input, SingleDatePicker
} from '../../../../../components';
import FormStyled from './FormStyled';

const propTypes = {
  dropDownCallBack: PropTypes.func,
  handleInputChange: PropTypes.func,
  update: PropTypes.instanceOf(Object),
  handleDropDownChange: PropTypes.func,
  enableErrorDisplay: PropTypes.bool,
  data: PropTypes.instanceOf(Object),
  skuList: PropTypes.instanceOf(Array),
  refsObj: PropTypes.instanceOf(Object),
  skuBatchList: PropTypes.instanceOf(Array),
  inputCallBack: PropTypes.func,
  invoiceNumberStatus: PropTypes.bool,
  sellableValidation: PropTypes.bool,
  damagesValidation: PropTypes.bool,
  shortagesValidation: PropTypes.bool,
};

const defaultProps = {
  data: {},
  refsObj: {
    title: '',
  },
  skuList: [],
  skuBatchList: [],
  enableErrorDisplay: true,
  dropDownCallBack: () => null,
  handleDropDownChange: () => null,
  update: {
    type: EVENT_OPERATION.CREATE,
    status: false,
  },
  inputCallBack: () => null,
  /**
   * agruments:
   * first param: input event (required),
   * second param: data's first depth targeted key,
   * third param:
   *  only if object depth greater than one,
   *  should contain list of object keys in accordance of depth upto end i.e target key
   */
  handleInputChange: () => null,
  invoiceNumberStatus: false,
  sellableValidation: false,
  damagesValidation: false,
  shortagesValidation: false,
};

const GRNForm = ({ ...props }) => {
  const {
    data,
    refsObj,
    skuList,
    skuBatchList,
    inputCallBack,
    dropDownCallBack,
    handleInputChange,
    enableErrorDisplay,
    invoiceNumberStatus,
    handleDropDownChange,
    sellableValidation,
    damagesValidation,
    shortagesValidation,
    exciseInLine,
  } = props;

  return (
    <FormStyled>
      <Form>
        {/* <div className="scrollable-body-vertical"> */}
        <Row>
          <Col md={12}>
            <CustomSelect
              enableValidation
              disabled={invoiceNumberStatus}
              options={skuList}
              labelContent='SKU'
              className='custom-select'
              placeholder='Select SKU'
              getOptionValue={({ id }) => id}
              getOptionLabel={({ title }) => title}
              ref={(ref) => refsObj.skuId = ref}
              enableErrorDisplay={enableErrorDisplay}
              onChange={(event) => handleDropDownChange(event.id, ['skuId'], dropDownCallBack)}
              value={skuList.filter(({ id }) => id === data.skuId)}
            />
          </Col>
          <Col md={4} className={skuBatchList?.filter((a)=>a.isBlocked)?.length>0?'audit-blocked':''}>
            <CustomSelect
              name='batchName'
              disabled={!data.skuId || invoiceNumberStatus || skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
              enableValidation
              labelContent='Batch'
              className='custom-select'
              placeholder='Select Batch'
              options={skuBatchList}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ batchName }) => batchName}
              refs={(ref) => refsObj.skuBatchId = ref}
              enableErrorDisplay={enableErrorDisplay}
              defaultValue={skuBatchList[0]}
              onChange={(event) => handleDropDownChange(event.id, ['skuBatchId'], dropDownCallBack)}
              value={skuBatchList.filter(({ id }) => id === data.skuBatchId)}
            />
          </Col>
          <Col md={4}>
            <div className='single-date-picker'>
              <label>Manufacturing (MFG) Date</label>
              <SingleDatePicker
                name='manufactureDate'
                date={data.manufactureDate}
                disabled={invoiceNumberStatus|| skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
                onChange={(name, date) => (
                  handleInputChange({
                    target: {
                      name: 'manufactureDate',
                    },
                    formattedValue: date,
                  })
                )}
                maxDate={data.expiryDate}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className='single-date-picker'>
              <label>Expiry (EXP) Date</label>
              <SingleDatePicker
                name='expiryDate'
                date={data.expiryDate}
                disabled={invoiceNumberStatus|| skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
                onChange={(name, date) => (
                  handleInputChange({
                    target: {
                      name: 'expiryDate',
                    },
                    formattedValue: date,
                  })
                )}
                minDate={data.manufactureDate}
              />
            </div>
          </Col>
          <Col md={4}>
            <Input
              name='sellable'
              type='number'
              rule='isInt'
              labelContent='Sellable Quantity'
              enableValidation
              externalValidation={!sellableValidation}
              value={data.sellable}
              enableErrorDisplay={sellableValidation}
              onChange={(event) => handleInputChange(event, '', ['sellable'], inputCallBack)}
              argument={{
                min: 1,
                allow_leading_zeroes: false,
              }}
              errorMessage={data.sellable ? INVALID : EMPTY}
              disabled={skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
            />
            {!invoiceNumberStatus && <span className='available-qty'>{`Available Quantity: ${data.sellableStock}`}</span>}
          </Col>
          <Col md={4}>
            <Input
              name='rate'
              type='number'
              rule='isFloat'
              labelContent='Rate'
              enableValidation
              argument={{ min: 1 }}
              decimalWithPrecedingZero
              value={data.updatedPriceDetails.rate}
              enableErrorDisplay={enableErrorDisplay}
              ref={(ref) => refsObj.rate = ref}
              disabled={invoiceNumberStatus|| skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
              onChange={(event) => handleInputChange(event, 'updatedPriceDetails')}
              errorMessage={data.updatedPriceDetails.rate ? INVALID : EMPTY}
            />
          </Col>
          <Col md={4}>
            <Input
              name='discount'
              type='number'
              labelContent='Discount'
              enableValidation
              rule='isFloat'
              argument={{
                min: 0,
                max: data.updatedPriceDetails.rate * data.updatedQuantity,
              }}
              decimalWithPrecedingZero
              value={data.updatedPriceDetails.discount}
              enableErrorDisplay={enableErrorDisplay}
              ref={(ref) => refsObj.discount = ref}
              disabled={invoiceNumberStatus|| skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
              onChange={(event) => handleInputChange(event, 'updatedPriceDetails')}
              errorMessage={data.updatedPriceDetails.discount ? INVALID : EMPTY}
            />
          </Col>
          <Col className='mt-24' md={4}>
            <Input
              name='damages'
              type='number'
              rule='isInt'
              labelContent='Damage Quantity'
              enableValidation
              externalValidation={!damagesValidation}
              enableErrorDisplay={damagesValidation}
              value={data.damages}
              onChange={(event) => handleInputChange(event, '', ['damages'], inputCallBack)}
              errorMessage={damagesValidation ? 'Must not be greater than damages quantity' : ''}
              disabled={skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
            />
            {!invoiceNumberStatus && <span className='available-qty'>{`Damage Stock: ${data.damagedStock}`}</span>}
          </Col>
          <Col className='mt-24' md={4}>
            <Input
              name='shortages'
              type='number'
              rule='isInt'
              labelContent='Shortage Quantity'
              enableValidation
              externalValidation={!shortagesValidation}
              enableErrorDisplay={shortagesValidation}
              errorMessage={shortagesValidation ? 'Must not be greater than shortage quantity' : ''}
              value={data.shortages}
              onChange={(event) => handleInputChange(event, '', ['shortages'], inputCallBack)}
              disabled={skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
            />
            {!invoiceNumberStatus && <span className='available-qty'>{`Shortage Stock: ${data.shortageStock}`}</span>}
          </Col>
          {!invoiceNumberStatus && (
            <Col className='mt-24' md={4}>
              <Input
                name='expiry'
                type='number'
                rule='isInt'
                labelContent='Expire Quantity'
                enableValidation
                value={data.expiry}
                onChange={(event) => handleInputChange(event, '', ['expiry'], inputCallBack)}
                disabled={skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
              />
              {!invoiceNumberStatus && <span className='available-qty'>{`Expire Stock: ${data.expiredStock}`}</span>}
            </Col>
          )}
          {(exciseInLine&&invoiceNumberStatus)&&<Col md={4} className='mt-24'>
            <Input
              name='exciseAmount'
              type='number'
              labelContent='Excise'
              rule='isFloat'
              decimalWithPrecedingZero
              value={data.updatedPriceDetails.exciseAmount}
              disabled={invoiceNumberStatus|| skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
              onChange={(event) => handleInputChange(event, 'updatedPriceDetails')}
            />
          </Col>}
          {!invoiceNumberStatus && <Col className='mt-24' md={4}>
            <Input
              name='per_unit_rate'
              type='number'
              rule='isInt'
              labelContent='Per Unit Rate'
              enableValidation
              value={data.per_unit_rate}
              onChange={(event) => handleInputChange(event)}
              disabled={skuBatchList?.filter((a)=>a.isBlocked)?.length>0}
            />
          </Col>
          }
        </Row>
        {/* </div> */}
      </Form>
    </FormStyled>
  );
};

GRNForm.propTypes = propTypes;

GRNForm.defaultProps = defaultProps;

const GRNFormWithErrorAndLoading = withLoading(GRNForm);

export default GRNFormWithErrorAndLoading;
