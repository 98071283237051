import React from 'react';
import PropTypes from 'prop-types';
import { tableData } from '../../../v4/components';
import { fixedFloatAndCommas } from '../../../utils/conversion';
import { getUpdatedLabel, headerLabelConfig } from '../../common/HelperFunctions';
import { USER_ROLE_TITLE } from '../../../data/enums/UserRole';

const labelMappings = {
  dse: 'dse',
  sub_d: 'sub_d',
  stl: 'stl',
  asm: 'asm',
  total_outlet_visited: 'total_outlet_visited',
  total_time_spent: 'total_time_spent',
  avg_call_time: 'avg_call_time',
  avg_commute_time: 'avg_commute_time',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.dse,
    title: headerLabelConfig(USER_ROLE_TITLE.DSE) || 'DSE',
    show: true,
    isDynamic: true,
  },
  {
    id: 2,
    label: labelMappings.sub_d,
    title: 'Distributor',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.stl,
    title: headerLabelConfig(USER_ROLE_TITLE.STL) || 'STL',
    show: true,
    isDynamic: true,
  },
  {
    id: 4,
    label: labelMappings.asm,
    title: headerLabelConfig(USER_ROLE_TITLE.ASM) || 'ASM',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.total_outlet_visited,
    title: 'Total Outlet Visited',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 6,
    label: labelMappings.total_time_spent,
    title: 'Total Time Spent (Min.)',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 7,
    label: labelMappings.avg_call_time,
    title: 'Average Call Time (Min.)',
    show: true,
    classNames: 'text-right',
  },
  {
    id: 8,
    label: labelMappings.avg_commute_time,
    title: 'Average Commute Time (Min.)',
    show: true,
    classNames: 'text-right',
  },
];

const TableHeader = (dynamicHeader = false) => (
  <thead className=''>
    <tr>
      {(dynamicHeader ? getUpdatedLabel(labelConfig) : labelConfig).map((label) =>
        label.show ? tableData(label) : null,
      )}
    </tr>
  </thead>
);

const returnTableBody = (data) => {
  const table = {};

  table[labelMappings.dse] = <td key={`${data.id}-dse`}>{data.dse}</td>;
  table[labelMappings.sub_d] = <td key={`${data.id}-sub_d`}>{data.distributor}</td>;
  table[labelMappings.stl] = <td key={`${data.id}-stl`}>{data.stl}</td>;
  table[labelMappings.asm] = <td key={`${data.id}-asm`}>{data.asm}</td>;
  table[labelMappings.total_outlet_visited] = (
    <td key={`${data.id}-total_outlet_visited`} className='text-right'>
      {fixedFloatAndCommas(data.total_outlet_visited)}
    </td>
  );
  table[labelMappings.total_time_spent] = (
    <td key={`${data.id}-total_time_spent`} className='text-right'>
      {fixedFloatAndCommas(data.total_time_spent)}
    </td>
  );
  table[labelMappings.avg_call_time] = (
    <td key={`${data.id}-avg_call_time`} className='text-right'>
      {fixedFloatAndCommas(data.average_call_time)}
    </td>
  );
  table[labelMappings.avg_commute_time] = (
    <td key={`${data.id}-avg_commute_time`} className='text-right'>
      {fixedFloatAndCommas(data.average_commute_time)}
    </td>
  );
  return table;
};

const TableBody = ({ data, index }) => {
  const tableBody = returnTableBody(data);

  return <tr key={`osr-${index}`}>{labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}</tr>;
};

TableBody.propTypes = {
  data: PropTypes.instanceOf(Object),
  index: PropTypes.number,
};

TableBody.defaultProps = {
  data: {},
  index: null,
};

export { TableHeader, TableBody };
