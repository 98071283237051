import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { DISTRIBUTOR_SERVICES_FRAGMENT } from '../common/GqlFragments';
import { GET_BU_DATA } from '../configuration/bu/API';

const CRUD_FRAGMENT = gql`
  fragment CrudFragment on CRUD {
    read
    create
    update
    delete
  }
`;

const LoginAPI = graphql(
  gql`
    mutation ($username: String!, $password: String!) {
      login(username: $username, password: $password) {
        token
        businessUnit
        User {
          id
          firstName
          lastName
          fullName
          email
          phoneNumber
          hasLoggedInOnce
          roleId
          googleAuthStatus
          Distributor {
            ...DistributorServicesFragment
          }
          Role {
            id
            name
            displayName
            enableGoogleAuth
          }
          billingCredentials {
            userId: user_id
            username
          }
        }
        appPermissionData {
          program
          permission {
            ...CrudFragment
          }
        }
        page {
          id
          title
          icon
          url
          active
          children {
            id
            title
            icon
            url
            active
            children {
              id
              title
              icon
              url
              active
            }
          }
        }
      }
    }
    ${CRUD_FRAGMENT}
    ${DISTRIBUTOR_SERVICES_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      loginWithServer: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const ResetAPI = graphql(
  gql`
    mutation ($email: String!) {
      forgotPassword(email: $email)
    }
  `,
  {
    props: ({ mutate }) => ({
      forgotPassword: (variables) =>
        mutate({
          variables,
        }),
    }),
  },
);

const query = {
  getBuData: GET_BU_DATA,
};

const mutation = {
  loginWithServer: LoginAPI,
  forgotPassword: ResetAPI,
};

export {
  query, mutation, LoginAPI, ResetAPI
};
