import styled from 'styled-components';

const PermissionStyled = styled.div`
  .status-switch {
    padding: 14px 24px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px 0 rgba(39, 40, 51, 0.12);
    border-radius: 4px;
    background: #fff;

    .form-input:nth-child(2) {
      display: block;
      position: relative;
      margin-top: 18px;

      &:after {
        content: '/';
        width: 42px;
        height: 40px;
        color: #6b6c7e;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #e7e7ed;
        border-radius: 4px 0 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e7e7ed;
      }

      label {
        display: block;
        font-size: 10px;
        font-weight: normal;
        color: #6b6c7e;
        line-height: 10px;
      }

      input {
        width: calc(100% - 42px);
        border-radius: 0 4px 4px 0;
        margin-left: 42px;
      }
    }
  }

  .input-error-info {
    margin: 0;
    font-size: 10px;
    color: #ff5f5f;

    .text {
      margin-left: 6px;
    }
  }

  .billing-input {
    margin-top: 32px;

    .form-select-input {
      margin-bottom: 10px !important;
    }

    &.disabled {
      .form-select-input {
        opacity: 0.4;
      }
    }

    label {
      width: 100%;
    }
  }

  .status-switch .form-input {
    display: flex;
    margin-bottom: 0 !important;
    align-items: center;
    justify-content: space-between;
  }

  .status-switch .input-url-wrap {
    display: flex;
    align-items: flex-start;
    margin-top: 18px;
    &.logistic input {
      border-radius: 0 4px 4px 0 !important;
    }

    .form-input {
      flex: 1;
    }

    button.outlined {
      height: 40px;
      border-color: #e7e7ed;
      background: #f1f2f5;
      border-radius: 0 4px 4px 0;
      border-left: 0;
      color: #a7a9bc;
      margin-top: 14px;

      &.active {
        background: #50d2a0;
        border-color: #50d2a0;
        color: #fff;
      }
    }

    .form-input > span {
      text-align: left;

      .error-message {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
    }
  }

  .status-switch .input-url-wrap {
    .form-input {
      display: block;
      position: relative;

      &:after {
        content: '/';
        width: 42px;
        height: 40px;
        color: #6b6c7e;
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        left: 0;
        top: 14px;
        background: #e7e7ed;
        border-radius: 4px 0 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e7e7ed;
      }

      label {
        display: block;
        font-size: 10px;
        font-weight: normal;
        color: #6b6c7e;
        line-height: 10px;
      }

      input {
        width: calc(100% - 42px);
        border-radius: 0;
        margin-left: 42px;
      }
    }
  }

  .status-switch .custom-control-label-text + div {
    left: 6px;
  }

  .permission-choose {
    margin-top: 18px;

    > label {
      display: block;
      font-size: 10px;
      font-weight: normal;
      color: #6b6c7e;
      line-height: 10px;
      margin-bottom: 4px;
    }

    > div {
      display: flex;
    }

    .radio-list {
      margin-right: 24px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    .text {
      font-size: 14px;
      font-weight: 600;
      color: #272833;
      display: inline-block;
    }
  }
  .sync-btn-wrap {
    display: flex;
    justify-content: space-between;
    .sync-btn {
      font-size: 14px;
      line-height: 21px;
      color: #6b6c7e;
      padding: 6px 12px;
      border: 1px solid #cdced9;
      border-radius: 4px;
      margin-top: 20px;
      margin-bottom: 16px;
      .icon {
        height: 14px;
        width: 14px;
        fill: #6b6c7e;
      }
    }
  }
  .bin {
    margin-top: 24px;
    .form-select-input {
      display: unset;
      width: 50%;
    }
  }
`;

export default PermissionStyled;
