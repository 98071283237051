import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const POSM_DETAILS  = gql`
query MerchandiseDeviceDetails($merchandiseDeviceId: Int!) {
  merchandiseDeviceDetails(merchandiseDeviceId: $merchandiseDeviceId) {
    id
    title
    brandsCategoryInfo {
      categoryId
      brandIds
    }
  }
}
`;

const MERCHANDISE_DEVICE_FRAGMENT = gql`
  fragment MerchandiseDeviceFragment on MerchandiseDevice {
    id
    title
    active
    categoryIds
    Categories {
      id
      title
      channelId
      Channel {
        id
        title
      }
      active
    }
  }
`;
const CREATE_MERCHANDISE_DEVICE = graphql(
  gql`
  mutation CreateMerchandiseDevice($title: String!, $input: [CategoriesInput]) {
    createMerchandiseDevice(title: $title, input: $input) {
           ...MerchandiseDeviceFragment 
        }
    }
    ${MERCHANDISE_DEVICE_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      createMerchandiseDevice: (variables) => mutate({
        variables,
      }),
    }),
  },
);

const UPDATE_MERCHANDISE_DEVICE = graphql(
  gql`
  mutation UpdateMerchandiseDevice($updateMerchandiseDeviceId: Int!, $title: String!, $input: [CategoriesInput]) {
    updateMerchandiseDevice(id: $updateMerchandiseDeviceId, title: $title, input: $input) {
              ...MerchandiseDeviceFragment
          }
      }
      ${MERCHANDISE_DEVICE_FRAGMENT}
  `,
  {
    // name: 'loginWithServer',
    props: ({ mutate }) => ({
      updateMerchandiseDevice: (variables) => mutate({
        variables,
      }),
    }),
  },
);

const query = {
  getPOSMsDetails:POSM_DETAILS,
};
const mutation = {
  createMerchandiseDevice: CREATE_MERCHANDISE_DEVICE,
  updateMerchandiseDevice: UPDATE_MERCHANDISE_DEVICE,
};

export {
  query, mutation,POSM_DETAILS,CREATE_MERCHANDISE_DEVICE,
  UPDATE_MERCHANDISE_DEVICE,
};
