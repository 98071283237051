import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as queryService from '../../base/query.service';
import * as downloadService from '../../base/download.service';
import withAlert from '../../../utils/composition/withAlert';
import UploadSection from './UploadSection';
import { DialogFormWrapper } from '../../common';
import { breadCrumb, title, formConfig, filter as filterConfig } from './config';
import { PanelStyled } from '../../common/configuration';
import PageHeader from '../../base/PageHeader';
import View from './View';
import { EVENT_OPERATION } from '../../../data/enums/EventOperation';
import { getUser } from '../../../data/services';
import { ALERT_TYPE } from '../../../data/enums/AlertType';
import { UPLOADABLES } from '../../../data/enums/GraphQL';
import { fileParser } from '../../../utils/parser';
import ICOStyled from './ICOStyled';
import { Button } from '../../../v4/components';
import { PAGINATION } from '../../../data/enums';
import DialogEntities from './DialogEntities';
import { DOMAIN } from '../../../data/enums/config';
import { apiFilterProcessor, getOffsetFromPagination } from '../../../utils/api';
import { getSortValueAfterValueCheck } from '../../common/HelperFunctions';
import { getPermissionForICO } from '../../base/permission';

const propTypes = {
  serverResponseWaiting: PropTypes.bool,
  displayAlert: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired,
  getDistributors: PropTypes.func.isRequired,
  getPrimaryStockFromSAP: PropTypes.func.isRequired,
  getIco: PropTypes.func.isRequired,
  createRequestOrder: PropTypes.func.isRequired,
};

const defaultProps = {
  serverResponseWaiting: false,
};

class ICO extends Component {
  constructor(props) {
    super(props);
    this.userInfo = getUser();
    this.state = {
      alternateLoadingTitle: '',
      showPaginatedList: false,
      fileName: '',
      data: {
        list: [],
        total: 0,
      },
      dialog: {
        type: '',
        element: '',
      },
      distributorList: [],
      brandList: [],
      distributor: {
        id: this.userInfo.Distributor.length === 1 ? this.userInfo.Distributor[0].id : '',
        title: this.userInfo.Distributor.length === 1 ? this.userInfo.Distributor[0].title : '',
      },
      brand: {
        ids: [],
      },
      queryParameters: {
        pagination: queryService.baseQueryParameters.pagination,
        filter: queryService.baseQueryParameters.filter,
        sort: {},
      },
      filter: {
        ...filterConfig.instance,
      },
    };
    const { downloadReport, displayAlert } = props;
    this.basePaginationService = new queryService.QueryClass(
      this.setQueryParameters,
      this.getQueryParameters,
      this.getICOList,
      downloadReport,
      displayAlert,
    );
    this.permission = getPermissionForICO();
  }

  componentDidMount() {
    this.setState({
      alternateLoadingTitle: 'Loading data... Please wait!',
    });

    this.loadTableData();
    this.getBrandList();
    this.getPrimaryStockSAP();
  }

  setQueryParameters = (queryParams, callBack = () => null) =>
    this.setState({ queryParameters: queryParams }, callBack);

  getQueryParameters = () => {
    const { queryParameters } = this.state;
    return queryParameters;
  };

  getPrimaryStockSAP = () => {
    const { displayAlert, getPrimaryStockFromSAP } = this.props;
    getPrimaryStockFromSAP(
      {},
      {
        handleSuccess: res => {
          if (res.errors) {
            displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Error while loading stock from SAP. Please try again');
          } else {
            displayAlert(ALERT_TYPE.SUCCESS, 'Stock loaded from SAP successfully.');
          }
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  loadTableData = () => {
    const { getDistributors } = this.props;
    getDistributors(
      { limit: 1000 },
      {
        handleSuccess: response => {
          this.setState({ distributorList: response.data.distributors.rows });
        },
        handleError: error => {
          this.onAPIRequestFailure(error);
        },
      },
    );
  };

  getBrandList = () => {
    const { getBrandDetails, displayAlert } = this.props;
    getBrandDetails(
      {},
      {
        handleSuccess: response => {
          const brandList = (response.data.catalogDetails && response.data.catalogDetails.rows) || [];
          this.setState({ brandList });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  setStateDialog = (type, element) => {
    this.setState({
      dialog: {
        type,
        element,
      },
    });
  };

  updateDialog = () => {
    this.setStateDialog(EVENT_OPERATION.UPLOAD, '');
  };

  resetDialog = () => {
    this.setStateDialog('', '');
  };

  onAPIRequestFailure = error => {
    const { displayAlert } = this.props;
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  handleDistributorSelect = event => {
    const { queryParameters } = this.state;
    queryParameters.pagination = {
      page: PAGINATION.PAGE,
      limit: PAGINATION.LIMIT,
    };
    this.setState({
      distributor: {
        id: event.id,
        title: event.title,
      },
      queryParameters,
    });
  };

  getFileData = async data => {
    const { distributor } = this.state;
    const fileData = await fileParser(data.file).then(file => ({
      input: {
        file,
        type: UPLOADABLES.ICO,
        references: {
          type: 'distributor_id',
          id: distributor.id,
        },
      },
    }));
    return fileData;
  };

  onFormSubmit = async (type, list) => {
    this.setState({
      alternateLoadingTitle: 'Loading data... Please wait!',
    });
    const { upload, displayAlert } = this.props;
    if (type === EVENT_OPERATION.UPLOAD) {
      const {
        file: { name },
      } = list;
      const inputData = await this.getFileData(list);
      upload(
        {
          ...inputData,
        },
        {
          handleSuccess: () => {
            this.setState({
              fileName: name,
              alternateLoadingTitle: 'Fetching Inventory from CWH. Please wait',
            });
            displayAlert(ALERT_TYPE.SUCCESS, 'File uploaded successfully');
          },
          handleError: err => {
            displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Invalid file format!');
            this.setState({
              fileName: '',
            });
          },
        },
      );
    } else {
      this.createICORequestOrder();
    }
  };

  generateICOReport = () => {
    this.getICOList();
    this.setState({
      showPaginatedList: true,
    });
  };

  autoGenerateICOReport = () => {
    this.triggerAutoGenerateApi();
  };

  createICORequestOrder = () => {
    const { createRequestOrder, displayAlert } = this.props;
    const { distributor } = this.state;
    createRequestOrder(
      {
        input: {
          distributorId: distributor.id,
        },
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'ICO generated successfully!');
          this.setState({
            showPaginatedList: false,
            fileName: '',
            distributor: {
              id: '',
              title: '',
            },
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handleFileCancel = () => {
    this.setState({
      fileName: '',
      data: {
        list: [],
        total: 0,
      },
    });
  };

  handleDownloadClick = domain => {
    const { distributor, brand } = this.state;
    const { downloadReport, displayAlert } = this.props;
    this.setState({
      alternateLoadingTitle: 'Loading data... Please wait!',
    });
    const brandFilter = { column: 'brand_id', value: brand.ids.map(a => a.toString()) };
    const distributorFilter = { column: 'distributor_id', value: [distributor.id.toString()] };
    downloadReport(
      {
        input: {
          domain,
          filter: {
            filters: brand.ids.length > 0 ? [distributorFilter, brandFilter] : [distributorFilter],
          },
          reportType: 'csv',
        },
      },
      {
        handleSuccess: response => {
          downloadService.resolver(response.data.downloadReport.file);
          displayAlert(ALERT_TYPE.SUCCESS, 'Successfully downloaded file');
        },
        handleError: () => {
          displayAlert(ALERT_TYPE.CUSTOM_DANGER, 'Cannot download file');
        },
      },
    );
  };

  handleAutoCompleteChange = (value, feildName) => {
    const { brand } = this.state;
    brand[feildName] = value;
    this.setState(brand);
  };

  getICOList = () => {
    const { getIco, displayAlert } = this.props;
    const { data, queryParameters, distributor, brand } = this.state;
    const offset = getOffsetFromPagination(queryParameters.pagination);
    const brandFilter = [{ column: 'brand_id', value: brand.ids.map(a => a.toString()) }];
    getIco(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          filters: [
            ...apiFilterProcessor(queryParameters.filter),
            ...(brand?.ids?.length > 0 ? brandFilter : []),
            { column: 'distributor_id', value: [distributor.id.toString() || ''] },
          ],
          sort: getSortValueAfterValueCheck(queryParameters.sort),
        },
      },
      {
        handleSuccess: response => {
          data.list = response.data.getIco ? response.data.getIco.rows : [];
          data.total = response.data.getIco ? response.data.getIco.count : 0;
          this.setState(data);
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  triggerAutoGenerateApi = async () => {
    const { automatedIcoGeneration, displayAlert } = this.props;
    const { distributor, brand } = this.state;
    const brandFilter = { column: 'brand_id', value: brand.ids.map(a => a.toString()) };
    const distributorFilter = { column: 'distributor_id', value: [distributor.id.toString()] };
    automatedIcoGeneration(
      {
        filter: {
          filters: brand.ids.length > 0 ? [distributorFilter, brandFilter] : [distributorFilter],
        },
      },
      {
        handleSuccess: () => {
          displayAlert(ALERT_TYPE.SUCCESS, 'ICO generated successfully');
          this.getICOList();
          this.setState({
            showPaginatedList: true,
          });
        },
        handleError: error => {
          displayAlert(ALERT_TYPE.DANGER, error);
          return;
        },
      },
    );
  };

  render() {
    const {
      data,
      distributorList,
      distributor,
      dialog: { type, element },
      fileName,
      showPaginatedList,
      filter,
      queryParameters,
      brandList,
      brand,
      alternateLoadingTitle,
    } = this.state;
    const { serverResponseWaiting } = this.props;
    return (
      <ICOStyled>
        {type && (
          <DialogFormWrapper
            onDialogSubmit={this.onFormSubmit}
            formConfig={formConfig[type]}
            dialogElement={element}
            onDialogCancel={this.resetDialog}
            activeStatus
            type={type}
            renderDialog={({ dialogData, enableErrorDisplay, handleFileUpload, refsObj }) => (
              <DialogEntities
                type={type}
                dialogData={dialogData}
                serverResponseWaiting={serverResponseWaiting}
                handleFileUpload={handleFileUpload}
                enableErrorDisplay={enableErrorDisplay}
                refsObj={refsObj}
                onDownloadClick={() => this.handleDownloadClick(DOMAIN.ICO)}
              />
            )}
          />
        )}
        <>
          <div className="section-header">
            <PanelStyled>
              <PageHeader
                breadCrumb={breadCrumb}
                config={{
                  title,
                }}
              >
                {!showPaginatedList && this.permission.create && (
                  <Button primary small disabled={fileName === ''} onClick={() => this.generateICOReport()}>
                    <span>Generate</span>
                  </Button>
                )}
                {!showPaginatedList && this.permission.create && (
                  <Button
                    secondary
                    small
                    disabled={fileName || !distributor?.id}
                    onClick={() => this.autoGenerateICOReport()}
                  >
                    <span>Auto Generate</span>
                  </Button>
                )}
                {showPaginatedList && (
                  <>
                    {/* <Button
                        secondary
                        small
                        onClick={() => this.generateICOReport()}
                      >
                        <span>Re-Generate</span>
                      </Button> */}
                    {/* <Button
                      primary
                      small
                      onClick={() => this.setStateDialog(EVENT_OPERATION.REQUEST_ORDER)}
                      disabled={data.total === 0}
                    >
                      <span>Request Order</span>
                    </Button> */}
                  </>
                )}
              </PageHeader>
            </PanelStyled>
          </div>
          <div className="section-content table-present section-select">
            <UploadSection
              distributorList={distributorList}
              distributor={distributor}
              brandList={brandList}
              brand={brand}
              showPaginatedList={showPaginatedList}
              updateDialog={this.updateDialog}
              fileName={fileName}
              handleDistributorSelect={this.handleDistributorSelect}
              handleFileCancel={this.handleFileCancel}
              handleAutoCompleteChange={this.handleAutoCompleteChange}
              loading={serverResponseWaiting}
              title={alternateLoadingTitle}
            />
            {showPaginatedList && (
              <View
                data={data}
                filter={filter}
                distributor={distributor}
                loading={serverResponseWaiting}
                pagination={queryParameters.pagination}
                onFilterChange={this.basePaginationService.handleFilterChange}
                onDownloadClick={this.handleDownloadClick}
                onPageChange={this.basePaginationService.onPageSelect}
                onHeaderClick={this.basePaginationService.handleTableSorting}
              />
            )}
          </div>
        </>
      </ICOStyled>
    );
  }
}

ICO.propTypes = propTypes;
ICO.defaultProps = defaultProps;

export default withAlert()(ICO);
