import styled from 'styled-components';

const ReceiptWrapper = styled.div`
  .table-wrap {
    td {
      .light {
        min-width: 90px;
        padding: 3px 4px;
        text-align: center;
        line-height: 10px;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;
        border-radius: 2px;
      }
      .pending {
        border: 0;
        padding: 0;
        .light {
          border: 1px solid #ff8f39;
          color: #b95000;
        }
      }
      .received {
        .light {
          border: 1px solid #47c464;
          color: #287d3c;
        }
      }
      .cheque_received {
        .light {
          border: 1px solid #47c464;
          color: #287d3c;
        }
      }
      .dishonored {
        .light {
          border: 1px solid rgba(218, 20, 20, 0.4);
          color: #da1414;
        }
      }
      .reconciled {
        .light {
          border: 1px solid rgba(46, 90, 172, 0.4);
          color: #2e5aac;
        }
      }
    }
    .filter-dropdown-card {
      .icon {
        display: none;
      }
    }
  }

  .collector-wrap {
    display: flex;
    .form-select-input {
      width: 57.5%;
      label {
        margin-right: 27%;
      }
    }

    .form-input {
      width: 25%;
      margin-left: 8px;
    }
    .user-wrap {
      width: 41%;
      .form-select-input {
        width: 100%;
        margin-left: 8px;
        label {
          margin: 0px;
          width: 0px;
        }
      }
    }
  }

  .form-input-wrap {
    .form-input,
    .form-select-input {
      label {
        width: calc(33.57% - 10px);
      }
    }
  }
`;

export default ReceiptWrapper;
