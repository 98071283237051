import { useState, useEffect } from 'react';
import * as queryService from '../../../../base/query.service';
import { crudSuccess, filterConfig } from '../config';
import { EVENT_OPERATION } from '../../../../../v4/constants/EventOperation';
import { SKU_GROUP } from '../../../../../data/enums/Route';
import history from '../../../../../utils/history';
import { ALERT_TYPE } from '../../../../../v4/constants/AlertType';
import { getOffsetFromPagination, apiFilterProcessor } from '../../../../../utils/api';
import {
  fetchFromLocalStorage,
  getFromLocalStorage,
  isError,
  storeInLocalStorage,
} from '../../../../common/HelperFunctions';
import { clone } from '../../../../../utils/objectProcessor';
import { storageKeys } from '../../../../sales/orderProcessing/config';
import { DOMAIN } from '../../../../../data/enums/config';
import { getPermissionForSkuGroup } from '../../../../base/permission';
import { normalPresentor } from '../../../../../utils/date';
import { MESSAGE_EVENT_OPERATION } from '../../../../../v4/constants/successMessage';
import { serverToClientDataMapper } from '../DataMapper/dataMapper';
import { FOCUSED_SKU_DETAILS } from '../../../../../data/enums/Route';
import { JWT } from '../../../../../environment';

const initialDialogState = {
  id: '',
  title: '',
  line: {
    id: '',
    title: '',
  },
  start: normalPresentor(),
  end: normalPresentor(),
};

const useSkuGroupType = props => {
  const filterConfigMenu = clone(filterConfig.menu);
  const {
    displayAlert,
    serverResponseWaiting,
    getSkuGroupTypeApi,
    getRouteSegmentationList,
    createSKUGroup,
    updateSKUGroup,
    replicateSKUGroup,
    toggleState,
    location,
  } = props;
  const masterId = Number(location?.pathname?.split('/')?.[3]);
  const enableRouteSegmentation = getFromLocalStorage(JWT.LOCAL_STORAGE.BU_SETTINGS)?.routeSegmentation;

  const [data, setData] = useState({ list: [], total: 0 });
  const [routeSegmentationList, setRouteSegmentationList] = useState({ list: [], total: 0 });
  const [queryParameters, setQueryParameters] = useState({
    pagination: queryService.baseQueryParameters.pagination,
    filter: queryService.baseQueryParameters.filter,
    date: { ...queryService.baseQueryParameters.date },
    search: queryService.baseQueryParameters.search,
  });

  const [filterMenu, setFilterMenu] = useState(filterConfigMenu);
  const [dialog, setDialog] = useState({
    type: '',
    element: '',
  });
  const [dialogData, setDialogData] = useState({
    ...initialDialogState,
  });
  const lineOptions = routeSegmentationList.list;
  const groupMasterTitle = data?.list?.[0]?.groupMasterTitle || '';

  const loadTableData = () => {
    const offset = getOffsetFromPagination(queryParameters.pagination);
    setFilterStateInStorage();
    getSkuGroupTypeApi(
      {
        offset,
        limit: queryParameters.pagination.limit,
        filter: {
          // filters: apiFilterProcessor(queryParameters.filter),
          // dateRange: queryParameters.date,
          queryString: queryParameters.search,
        },
        masterId,
      },
      {
        handleSuccess: res => {
          if (isError(res)) onAPIRequestFailure(res.errors[0]);
          const response = res.data.skuGroups;
          setData({
            list: response ? serverToClientDataMapper(response.rows) : [],
            total: response ? response.count : 0,
          });
        },
        handleError: err => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const setParameters = queryParams => setQueryParameters({ ...queryParams });

  const basePaginationService = new queryService.QueryClass(setParameters, () => queryParameters, loadTableData);

  const onAPIRequestFailure = error => {
    displayAlert(ALERT_TYPE.DANGER, error);
  };

  const resetDialog = () => {
    setDialog({
      type: '',
      element: '',
    });
    setDialogData(initialDialogState);
  };

  const handleIconClick = (type, element = {}) => {
    setDialog({
      type,
      element,
    });
    if (type !== EVENT_OPERATION.CREATE) {
      setDialogData({
        id: element.id,
        title: element.title,
        line: element.line,
        start: element.start,
        end: element.end,
      });
    }
  };

  const handleInputChange = e => {
    setDialogData({ ...dialogData, [e.target.name]: e.target.value });
  };

  const handleDialogSubmit = type => {
    if (type === EVENT_OPERATION.DELETE) {
      toggleState({ ids: [dialogData?.id], active: false, type: 'SKUGroup' });
      displayAlert(ALERT_TYPE.SUCCESS, `${groupMasterTitle} ${MESSAGE_EVENT_OPERATION.DELETE}`);
      loadTableData();
      return;
    }

    if (type === EVENT_OPERATION.UPDATE) {
      updateSKUGroup(
        {
          id: dialogData.id,
          input: {
            title: dialogData.title,
            master_id: masterId,
            end_date: dialogData.end,
            start_date: dialogData.start,
            line_id: dialogData.line.id,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${groupMasterTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            loadTableData();
            resetDialog();
          },
          handleError: err => {
            onAPIRequestFailure(err);
          },
        },
      );
    }

    if (type === EVENT_OPERATION.REPLICATE) {
      replicateSKUGroup(
        {
          replicateSkuGroupId: dialogData.id,
          input: {
            title: dialogData.title,
            master_id: masterId,
            end_date: dialogData.end,
            start_date: dialogData.start,
            line_id: dialogData.line.id,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${groupMasterTitle} ${MESSAGE_EVENT_OPERATION.UPDATE}`);
            loadTableData();
            resetDialog();
          },
          handleError: err => {
            onAPIRequestFailure(err);
          },
        },
      );
    }

    if (type === EVENT_OPERATION.CREATE) {
      createSKUGroup(
        {
          input: {
            title: dialogData.title,
            master_id: masterId,
            end_date: dialogData.end,
            start_date: dialogData.start,
            line_id: dialogData.line.id || 1,
          },
        },
        {
          handleSuccess: () => {
            displayAlert(ALERT_TYPE.SUCCESS, `${groupMasterTitle} ${MESSAGE_EVENT_OPERATION[type]}`);
            loadTableData();
            resetDialog();
          },
          handleError: err => {
            onAPIRequestFailure(err);
          },
        },
      );
    }
  };

  const handleTableRowClick = id => {
    history.push(`/${SKU_GROUP}/${masterId}/details/${id}`);
  };

  const setFilterStateInStorage = () => {
    const { filter, date, search } = queryParameters;
    storeInLocalStorage(
      storageKeys.FILTER,
      {
        filter,
        filterMenu,
        date,
        search,
      },
      DOMAIN.SKU_GROUP,
    );
  };

  const getFilterStateFromStorage = () => {
    const localData = fetchFromLocalStorage(storageKeys.FILTER, DOMAIN.SKU_GROUP);
    const newFilterConfig = {
      ...(localData || {
        filter: { ...filterConfig.instance },
        filterMenu: { ...filterConfig.menu },
        searchText: '',
      }),
    };
    if (!localData) {
      return loadTableData();
    }
    setFilterMenu(newFilterConfig.filterMenu);
    setQueryParameters({
      ...queryParameters,
      filter: newFilterConfig.filter,
      searchText: newFilterConfig.searchText,
      date: newFilterConfig.date,
    });
    loadTableData();
  };

  const handleBulkDelete = (type, response) => {
    displayAlert(ALERT_TYPE.SUCCESS, crudSuccess[type].message);
    loadTableData();
  };

  const permission = getPermissionForSkuGroup();

  const handleDropDownChange = event => {
    setDialogData({ ...dialogData, line: event });
  };

  const fetchRouteSegmentationData = () => {
    getRouteSegmentationList(
      {
        offset: 0,
        filter: {
          filters: [],
          queryString: '',
        },
      },
      {
        handleSuccess: res => {
          if (isError(res)) onAPIRequestFailure(res.errors[0]);
          const response = res.data.lines;
          setRouteSegmentationList({
            list: response ? response.rows.filter(rowItem => rowItem.active === true) : [],
            total: response ? response.count : 0,
          });
        },
        handleError: err => {
          onAPIRequestFailure(err);
        },
      },
    );
  };

  const generateFormTitle = (type, data) => {
    if (type === EVENT_OPERATION.REPLICATE || type === EVENT_OPERATION.UPDATE) {
      return data.title;
    }
    if (type === EVENT_OPERATION.DELETE) {
      return '';
    } else {
      return groupMasterTitle;
    }
  };

  useEffect(() => {
    getFilterStateFromStorage();
    fetchRouteSegmentationData();
  }, []);

  useEffect(() => {
    setData({
      list: [],
      total: 0,
    });

    loadTableData();
  }, [queryParameters]);

  return {
    dialog,
    data,
    dialogData,
    serverResponseWaiting,
    permission,
    queryParameters,
    filterMenu,
    basePaginationService,
    toggleState,
    lineOptions,
    groupMasterTitle,
    enableRouteSegmentation,
    handleDialogSubmit,
    resetDialog,
    handleInputChange,
    handleIconClick,
    handleTableRowClick,
    handleBulkDelete,
    handleDropDownChange,
    generateFormTitle,
  };
};

export default useSkuGroupType;
