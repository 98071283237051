import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_ORDER_ANALYSIS_REPORT_LIST = gql`
  query getOrderAnalysisReport($limit: Int, $offset: Int, $filter: FilterInput) {
    getOrderAnalysisReport(limit: $limit, offset: $offset, filter: $filter) {
      rows {
        distributor
        zone
        pending
        one_day_pending
        two_day_pending
        three_day_pending
        three_day_above_pending
        total_invoiced
        cancelled
        order_value
      }
      count
    }
  }
`;

const query = {
  getOrderAnalysisList: GET_ORDER_ANALYSIS_REPORT_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
