import styled from 'styled-components';

const StockAdjusStyled = styled.div`
  .form-select-input{
    margin-top:8px;
    margin-bottom:0;
    width:22%;
    /* width:242px; */
    label {
      display: none;
    }
  }

  table {
    border: 0;
    tr {
      &.collapsible-body-tr {
        tbody {
          tr {
            th,td {
              &:first-child {
                padding: 7px 12px 7px 70px;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        &.active {
          td:first-child > span:before {
            transform: rotate(180deg);
            transition: all 0.23s ease-in-out;
            border-top-color: #004ad7;
          }
        }
        td:first-child {
          > span {
            position: relative;
            padding-left: 26px;

            &:before {
              content: '';
              width: 0;
              height: 0;
              border-radius: 4px;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #6b6c7e;
              position: absolute;
              top: 5px;
              left: 0;
              transition: all 0.23s ease-in-out;
            }
          }
        }
      }
    }
  }

  .collapsible-body-tr {
    &:hover, &:focus {
      background: transparent;
    }

    table {
      box-shadow: 0 4px 8px 0 rgba(39, 40, 51, 0.12);
      border: none;

      tbody {
        tr:last-child {
          border-top: 1px solid #e7e7ed;
        }
      }

      tr {
        th {
          background: transparent;
          width: auto;
         }
        }
      }
    }
    .modal-inner{
      max-width: 862px;
      .row{
        padding: 24px;
      }
      .qty-bin{
        border: 1px solid #E7E7ED;
        border-radius: 4px;
        padding: 19px;
        margin: 0px 8px;
        .form-select-input{
          margin-top: 24px;
        }
      }
      .border-style{
        border-top: 1px solid #E7E7ED;
        border-bottom: 1px solid #E7E7ED;
      }
      .form-select-input{
        width: auto;
        margin-top: 0;
        label{
          display: inline-block;
        }
      }
      .form-input{
        margin-bottom: 0;
      }
      .available-qty{
        font-size: 14px;
        color: #6B6C7E;
      }
    }
    .bin_confirm{
      max-width: 450px;
    }
    .audit-blocked {
    &:after {
        content: "Audit is still pending for this SKU.";
    }
}
`;

export { StockAdjusStyled };
