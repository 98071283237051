import { TableBody, TableHeader } from './tableConfig';
import withLoading from '../../../../../utils/composition/withLoading';
import BulkDelete from '../../../../../v4/components/BulkDelete/BulkDelete';

const SuccessPurchaseOrderTable = props => {
  const { data, permission } = props;

  return (
    <>
      <BulkDelete
        data={data?.list}
        render={(checkedList, primaryCheckboxHandler, secondaryCheckboxHandler) => (
          <div className="table-wrap">
            <table>
              {TableHeader({
                dataListLength: data?.list?.length,
                checkedListLength: checkedList?.length,
                primaryCheckboxHandler,
                permission,
              })}
              <tbody>
                {data?.list?.map(subD =>
                  TableBody({
                    data: subD,
                    checkedList: checkedList,
                    onViewClick: () => {},
                    onIconClick: () => {},
                    secondaryCheckboxHandler,
                    permission,
                  }),
                )}
              </tbody>
            </table>
          </div>
        )}
      />
    </>
  );
};

const SuccessPurchaseOrderTableWithLoading = withLoading(SuccessPurchaseOrderTable);

export default SuccessPurchaseOrderTableWithLoading;
