import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReplacementForm from './ReplaceStockForm';
import { FORM_CONFIG } from '../../../../../../data/enums/config';
import withAlert from '../../../../../../utils/composition/withAlert';
import { ALERT_TYPE } from '../../../../../../data/enums/AlertType';
import { refGenerator } from '../../../../../../utils';
import { getAllIds, imageSliderListFormatter } from '../../../../../common/HelperFunctions';
import { SERVER_ORDER_STATUS } from '../../../config';
import { NewDialogWrapper } from '../../../../../common';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';

const propTypes = {
  formConfig: PropTypes.instanceOf(Object),
  dialogElement: PropTypes.instanceOf(Object),
  onDialogCancel: PropTypes.func,
  onDialogSubmit: PropTypes.func,
  displayMsg: PropTypes.func.isRequired,
  serverResponseWaiting: PropTypes.bool,
};

const defaultProps = {
  formConfig: {},
  dialogElement: {},
  onDialogCancel: () => null,
  onDialogSubmit: () => null,
  serverResponseWaiting: false,
};

class ReplaceStock extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      note: '',
      stockList: [],
      selectedStockIds: [],
    };
    this.data = props.formConfig[FORM_CONFIG.MAPPER](props.dialogElement);
  }

  componentDidMount() {
    this.fetchReplacementData();
  }

  fetchReplacementData = async () => {
    const { getReplacedStockDetails, displayMsg } = this.props;
    getReplacedStockDetails(
      {
        stockReplacementId: this.data.replacementGroupId,
      },
      {
        handleSuccess: response => {
          const replacementData = response.data.stockReplacementReceivedDetails || {};
          const modifiedStocks =
            replacementData.stocks &&
            replacementData.stocks.map(stock => {
              stock.images = [...(imageSliderListFormatter([...stock.images]) || [])];
              stock.refsObj = refGenerator(['replacedQuantity, replacedSkuId']);
              stock.enableErrorDisplay = false;
              stock.skus = stock.skus ? stock.skus : [];
              return stock;
            });
          this.setState({ note: replacementData.notes, stockList: modifiedStocks || [] });
        },
        handleError: error => {
          displayMsg(ALERT_TYPE.DANGER, error);
        },
      },
    );
  };

  handlePrimaryCheckBoxClick = checked => {
    const { stockList } = this.state;
    if (checked) {
      this.setState({
        selectedStockIds: [...(getAllIds(stockList) || [])],
      });
    } else {
      this.setState({ selectedStockIds: [] });
    }
  };

  handleSecondaryCheckBoxClick = id => {
    const { selectedStockIds } = this.state;
    if (selectedStockIds.includes(id)) {
      this.setState({ selectedStockIds: selectedStockIds.filter(item => item !== id) });
    } else {
      this.setState({ selectedStockIds: [...selectedStockIds, id] });
    }
  };

  handleInputChange = (e, index, obj) => {
    const { stockList } = this.state;
    stockList[index][e.target.name] = e.formattedValue;
    if (e.target.name === 'replacedSkuId') {
      stockList[index].replacedSkuBatchId = obj.batchId;
    }
    this.setState({
      stockList: [...stockList],
    });
  };

  handleDialogSubmit = () => {
    const { stockList, selectedStockIds } = this.state;
    const { onDialogSubmit, onDialogCancel } = this.props;
    const selectedStockList = stockList.filter(stock => selectedStockIds.includes(stock.id));
    const valid = this.getFormValidation();
    if (valid) {
      onDialogSubmit(EVENT_OPERATION.REPLACED, { list: selectedStockList });
      onDialogCancel();
    }
  };

  handleStockCancel = () => {
    const { stockList, selectedStockIds } = this.state;
    const { onDialogSubmit, onDialogCancel } = this.props;
    const selectedStockList = stockList.filter(stock => selectedStockIds.includes(stock.id));
    const valid = this.getStockCancelValidation();
    if (valid) {
      onDialogSubmit(EVENT_OPERATION.REPLACED, {
        list: selectedStockList,
        type: SERVER_ORDER_STATUS.cancelled,
      });
      onDialogCancel();
    }
  };

  getStockCancelValidation = () => {
    const { selectedStockIds, stockList } = this.state;
    const { displayMsg } = this.props;
    let status = true;
    if (selectedStockIds.length === 0) {
      status = false;
      displayMsg(ALERT_TYPE.CUSTOM_DANGER, 'No order selected');
    }

    return status;
  };

  getFormValidation = () => {
    const { selectedStockIds, stockList } = this.state;
    const { displayMsg } = this.props;
    let status = false;
    if (selectedStockIds.length > 0) {
      const selectedStocksRefs = stockList
        .reduce((acc, stock) => {
          if (selectedStockIds.includes(stock.id)) {
            acc.push(Object.values(stock.refsObj) || []);
          }
          return acc;
        }, [])
        .flat();
      status = !selectedStocksRefs.some(item => {
        if (typeof item !== 'string') return item.getValidState() === false;
      });
      if (status === false) {
        displayMsg(ALERT_TYPE.CUSTOM_DANGER, '!Invalid Values');
      }
      return status;
    }

    displayMsg(ALERT_TYPE.CUSTOM_DANGER, 'No order selected');
    return status;
  };

  render() {
    const { stockList, note, selectedStockIds } = this.state;
    const { serverResponseWaiting, onDialogCancel, formConfig } = this.props;
    return (
      <>
        <NewDialogWrapper
          onDialogCancel={onDialogCancel}
          onDialogSubmit={this.handleDialogSubmit}
          formConfig={formConfig}
          enableReset={false}
          extraFooterCallBack={this.handleStockCancel}
          renderDialog={({}) => (
            <div>
              <ReplacementForm
                note={note}
                data={this.data}
                stockList={stockList}
                selectedIds={selectedStockIds}
                loading={serverResponseWaiting}
                onInputChange={this.handleInputChange}
                onPrimaryCheckBoxClick={this.handlePrimaryCheckBoxClick}
                onSecondaryCheckBoxClick={this.handleSecondaryCheckBoxClick}
              />
            </div>
          )}
        />
      </>
    );
  }
}

ReplaceStock.propTypes = propTypes;

ReplaceStock.defaultProps = defaultProps;

export default withAlert()(ReplaceStock);
