import PropTypes from 'prop-types';
import { CheckBox } from '../../../../../../v4/components';
import { EVENT_OPERATION } from '../../../../../../data/enums/EventOperation';
import { menuAction, tableHeaderWithCheckBox } from '../../../../../../v4/components';
import { PERMISSION_OBJ } from '../../../../../../data/enums/Permission';
import { normalPresentor } from '../../../../../../utils/date';

const labelMappings = {
  checkbox: 'checkbox',
  title: 'title',
  startDate: 'startDate',
  endDate: 'endDate',
  options: 'options',
};

const labelConfig = [
  {
    id: 1,
    label: labelMappings.checkbox,
    title: '',
    show: true,
  },
  {
    id: 3,
    label: labelMappings.title,
    title: 'Title',
    show: true,
  },
  {
    id: 4,
    label: labelMappings.startDate,
    title: 'Start Date',
    show: true,
  },
  {
    id: 5,
    label: labelMappings.endDate,
    title: 'End Date',
    show: true,
  },
  {
    id: 8,
    label: labelMappings.options,
    title: '',
    show: true,
  },
];

const menuConfigList = [
  {
    title: 'Edit',
    icon: 'pencil',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.UPDATE,
  },
  {
    title: 'Replicate',
    icon: 'copy',
    permissionDerivedBy: 'update',
    type: EVENT_OPERATION.REPLICATE,
  },
  {
    title: 'Delete',
    icon: 'trash',
    permissionDerivedBy: 'delete',
    type: EVENT_OPERATION.DELETE,
  },
];

const returnTableBody = (data, checkedList, onIconClick, onSecondaryCheckBoxClick, permission) => {
  const table = {};
  table[labelMappings.checkbox] = permission.delete && (
    <td onClick={(e) => e.stopPropagation()} key={`${data.id}- check`}>
      <CheckBox checked={checkedList.includes(data.id)} onCheckBoxClick={() => onSecondaryCheckBoxClick(data.id)} />
    </td>
  );
  table[labelMappings.title] = <td key={`${data.id}- title`}>{data.title}</td>;
  table[labelMappings.startDate] = <td key={`${data.id}- startDate`}>{normalPresentor(data.start)}</td>;
  table[labelMappings.endDate] = <td key={`${data.id}- endDate`}>{normalPresentor(data.end)}</td>;
  table[labelMappings.options] = (
    <td key={`${data.id}- action`} className='text-right simple-popup-actions' onClick={(e) => e.stopPropagation()}>
      {(permission.update||permission.delete) && menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, onIconClick, data, permission)}
    </td>
  );

  return table;
};

const TableBody = ({ data, checkedList, onIconClick, secondaryCheckboxHandler, permission, onViewClick }) => {
  const tableBody = returnTableBody(data, checkedList, onIconClick, secondaryCheckboxHandler, permission);

  return (
    <tr key={data.id} style={{ cursor: 'pointer' }} onClick={() => onViewClick(data.id)}>
      {labelConfig.map((label) => (label.show ? tableBody[label.label] : null))}
    </tr>
  );
};

TableBody.propTypes = {
  checkedList: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Object),
  onIconClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  permission: PropTypes.instanceOf(Object),
  secondaryCheckboxHandler: PropTypes.func.isRequired,
};

TableBody.defaultProps = {
  data: {},
  checkedList: [],
  permission: PERMISSION_OBJ,
};

const TableHeader = ({ dataListLength, checkedListLength, primaryCheckboxHandler, permission }) =>
  tableHeaderWithCheckBox(
    dataListLength,
    checkedListLength,
    primaryCheckboxHandler,
    permission,
    labelConfig,
    labelMappings,
  );

TableHeader.propTypes = {
  checkedListLength: PropTypes.number,
  dataListLength: PropTypes.number,
  permission: PropTypes.instanceOf(Object),
  primaryCheckboxHandler: PropTypes.func.isRequired,
};

TableHeader.defaultProps = {
  checkedListLength: 0,
  dataListLength: 0,
  permission: PERMISSION_OBJ,
};

export { TableHeader, TableBody };
