import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LabelStyled } from '../Input/TextStyled';
import { Input } from '../index';
import { checkIfObject } from '../../utils/objectPrototypes';
import { debouncer } from '../../utils/formHandlers';
import { EMPTY as EMPTY_ERROR } from '../../data/enums/ErrorMessage';
import CustomStyle from './CustomAutoCompleteStyle';
import { Icon, Menu } from '../../v4/components';

const propTypes = {
  placeholder: PropTypes.string,
  searchText: PropTypes.string,
  labelContent: PropTypes.string,
  errorMessage: PropTypes.string,
  enableValidation: PropTypes.bool,
  name: PropTypes.string.isRequired,
  enableErrorDisplay: PropTypes.bool,
  disabled: PropTypes.bool,
  controlledInput: PropTypes.bool,
  dropDownValueKey: PropTypes.string,
  onDropDownSelection: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  dropDownDisplayKey: PropTypes.string,
  dropDownList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  searchText: '',
  placeholder: '',
  dropDownList: [],
  labelContent: '',
  disabled: false,
  dropDownValueKey: 'id',
  controlledInput: false,
  dropDownDisplayKey: 'title',
  enableValidation: false,
  enableErrorDisplay: false,
  errorMessage: EMPTY_ERROR,
  onDropDownSelection: () => null,
};

class CustomAutoComplete extends Component {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.searchText && !state.searchText) {
      return { searchText: props.searchText };
    }
    if (props.searchText && props.controlledInput) {
      return { searchText: props.searchText };
    }
    if (state.searchText && props.reflectOnOutputChange) {
      return { searchText: '' };
    }
    return { searchText: state.searchText };
  }

  constructor(props) {
    super(props);
    this.state = {
      searchText: props.searchText,
      onClose: false,
    };
    this.reference = {
      autoComplete: React.createRef(),
      autoCompleteInput: React.createRef(),
    };
  }

  componentDidMount() {}

  handleInputChange = event => {
    const { onChange, localDataInputChange } = this.props;
    const eventObj = { target: event.target, formattedValue: event.formattedValue };
    this.setState({ searchText: event.target.value }, () => {
      if (localDataInputChange) {
        onChange(eventObj);
      } else {
        debouncer(onChange, 300)(eventObj);
      }
    });
  };

  handleDropDownSelection = (dropDown, fieldName) => {
    const { onDropDownSelection, dropDownValueKey } = this.props;
    const searchText = dropDownValueKey ? dropDown[dropDownValueKey] : dropDown;
    this.setState({ searchText, onClose: true }, () => onDropDownSelection(dropDown, fieldName));
  };

  getValidState = () =>
    (this.reference.autoCompleteInput.current && this.reference.autoCompleteInput.current.getValidState()) || false;

  openMenu = () => {
    this.setState({ onClose: false });
  };

  render() {
    const { searchText, onClose } = this.state;
    const {
      labelContent,
      placeholder,
      dropDownDisplayKey,
      name,
      enableValidation,
      enableErrorDisplay,
      errorMessage,
      disabled,
      dropDownList,
      refs,
    } = this.props;

    const menuHeader = (
      <CustomStyle>
        {labelContent !== undefined ? <LabelStyled>{labelContent}</LabelStyled> : ''}
        <Input
          name={name}
          type="text"
          value={searchText}
          placeholder={placeholder}
          disabled={disabled}
          ref={ref => (refs ? (refs.batchName = ref) : this.reference.autoCompleteInput)}
          enableValidation={enableValidation}
          enableErrorDisplay={enableErrorDisplay}
          errorMessage={errorMessage}
          onChange={event => this.handleInputChange(event, 'searchText')}
        />
        {dropDownList.length > 0 && (
          <span className="dropdown">
            <Icon iconName="caret-bottom" />
          </span>
        )}
      </CustomStyle>
    );
    return (
      <div className="auto-complete-pop-up">
        <Menu
          block
          header={menuHeader}
          hideOnClick
          disabled={disabled}
          disableBody={disabled}
          ref={this.reference.autoComplete}
          onClose={onClose}
          openMenu={this.openMenu}
        >
          {dropDownList.length > 0 && (
            <div className="autocomplete-list " style={{ left: 0 }}>
              {dropDownList.map((dropDown, index) => (
                <div
                  key={`selection-${dropDownDisplayKey ? dropDown[dropDownDisplayKey] : index}`}
                  onClick={e => this.handleDropDownSelection(dropDown, name)}
                >
                  <span>{checkIfObject(dropDown) ? dropDown[dropDownDisplayKey] : dropDown}</span>
                </div>
              ))}
            </div>
          )}
        </Menu>
      </div>
    );
  }
}

CustomAutoComplete.propTypes = propTypes;

CustomAutoComplete.defaultProps = defaultProps;

export default CustomAutoComplete;
