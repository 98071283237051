import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withLoading from '../../../../../../utils/composition/withLoading';
import { paginationProps } from '../../../../../../data/enums/Component';
import { menuAction, defaultMenuConfigList } from '../../../../../../v4/components';
import EVENT_OPERATION from '../../../../../../data/enums/EventOperation';

const propTypes = {
  data: PropTypes.instanceOf(Object),
  onPageChange: PropTypes.func,
  pagination: paginationProps.value,
  breadCrumb: PropTypes.instanceOf(Array),
  enableOutletCreation: PropTypes.bool,
  permission: PropTypes.instanceOf(Object).isRequired,
  billingUser: PropTypes.bool.isRequired,
};

const defaultProps = {
  data: {},
  pagination: paginationProps.defaultValue,
  onPageChange: () => null,
  breadCrumb: [],
  enableOutletCreation: false,
};

const View = ({ ...props }) => {
  const { data, billingUser, permission, handleTableIconClick } = props;
  const menuConfigList = [defaultMenuConfigList[EVENT_OPERATION.UPDATE], defaultMenuConfigList[EVENT_OPERATION.DELETE]];

  return (
    <Fragment>
      <div className='table-wrap'>
        <table>
          <thead>
            <tr>
              <th>Ledger</th>
              <th>Distributor</th>
              <th>Address</th>
              <th>PAN</th>
              {billingUser && <th>Ledger Type</th>}
              {billingUser && <th>Outstanding Amount</th>}
              {billingUser && <th />}
            </tr>
          </thead>
          <tbody>
            {data.map((ledger) => (
              <tr key={ledger.id} style={{ cursor: 'pointer' }}>
                <td>{ledger.title}</td>
                <td>{ledger.distributor ? ledger.distributor.title : ''}</td>
                <td>{ledger.address}</td>
                <td>{ledger.panNumber}</td>
                {billingUser && <td>{ledger.type}</td>}
                {billingUser && <td>{ledger.outstandingAmount}</td>}
                {/* {
                  billingUser && (
                  <td onClick={() => handleTableIconClick('delete', ledger)}>
                    <Icon iconName="trash" />
                  </td>
                  )
                } */}
                {billingUser && (
                  <td
                    key={`${ledger.id}-actions`}
                    className='text-right simple-popup-actions'
                    onClick={(e) => e.stopPropagation()}
                  >
                    {(permission.delete || permission.update) &&
                      menuAction(menuConfigList, { menuIcon: 'ellipsis-v' }, handleTableIconClick, ledger, permission)}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination
        currentPage={pagination.page}
        limit={pagination.limit}
        totalItems={data.total}
        onPageChange={onPageChange}
      /> */}
    </Fragment>
  );
};

View.propTypes = propTypes;

View.defaultProps = defaultProps;

const ViewWithLoading = withLoading(View);

export default ViewWithLoading;
