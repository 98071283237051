import gql from 'graphql-tag';
import { DOWNLOAD_REPORT } from '../../common/GqlAPI';

const GET_NSR_LIST = gql`
  query getNetSalesReport($offset: Int, $limit: Int, $filter: FilterInput) {
    getNetSalesReport(offset: $offset, limit: $limit, filter: $filter) {
      rows {
        sales_transaction_date
        distributor
        route
        outlet
        channel
        category
        sales_invoice_number
        dse
        sku
        brand
        sku_family
        rate
        sales_quantity
        sales_amount
        sales_return_quantity
        sales_return_amount
        sales_return_invoice_number
        sales_returned_date
        net_sales_quantity
        net_sales_amount
      }
      count
    }
  }
`;

const query = {
  getNSRList: GET_NSR_LIST,
};

const mutation = {
  downloadReport: DOWNLOAD_REPORT,
};

export { DOWNLOAD_REPORT };

export { query, mutation };
