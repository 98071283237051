import { getFilterMenu } from '../../../../../utils/filter';
import { FILTER } from '../../../../../data/enums/filter';
import { EVENT_OPERATION } from '../../../../../data/enums/EventOperation';

const OUTLET_TYPES = {
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
  OTHER: 'outletsInAnotherRoute',
};

const mapDataFormatter = (data = {}, type = OUTLET_TYPES.ASSIGNED) => ({
  id: data.id || '',
  latitude: data.geoLocation ? data.geoLocation.latitude : null,
  longitude: data.geoLocation ? data.geoLocation.longitude : null,
  category: data.Category.title,
  title: data.title,
  type,
});

const serverDataMapper = (data = {}) => ({
  id: data.id,
  title: data.title,
  townId: data.townId,
  ...data.RetailOutletsInTown,
});

const formConfig = {
  mapper: (element) => ({
    id: element.id || '',
    title: element.title || '',
  }),
  validationField: ['title', 'id'],
  validationRequired: true,
};

const getFilterConfig = (filterList) => {
  const instance = {};
  const labelList = filterList.map((element) => element.LABEL);
  const menu = getFilterMenu(labelList);
  filterList.forEach((element) => (instance[element.VALUE] = {}));
  return {
    menu,
    instance,
  };
};

const filter = getFilterConfig([FILTER.CATEGORY]);

const filterFieldMapper = {
  town_id: 'townId',
  category: 'categoryId',
};

const crudSuccess = {
  [EVENT_OPERATION.ASSIGN]: {
    objectMapper: ({ id, outlets }) => ({
      input: {
        destRouteId: id,
        sourceDetails: [
          ...(outlets[OUTLET_TYPES.UNASSIGNED] || []),
          ...(outlets[OUTLET_TYPES.OTHER] || []),
          ...(outlets[OUTLET_TYPES.ASSIGNED] || []),
        ].map((outlet) => ({
          retailOutletId: parseInt(outlet.id, 10),
          routeId: parseInt(outlet.routeId, 10),
        })),
        type: 'TRANSFER',
      },
    }),
    responseName: 'assignRetailOutletsToRoute',
    message: 'Outlets successfully assigned',
  },
  [EVENT_OPERATION.BULK_DELETE]: {
    objectMapper: ({ id, outletIds }) => ({
      input: {
        sourceDetails: outletIds.map((outlet) => ({
          retailOutletId: parseInt(outlet.id, 10),
          routeId: parseInt(id, 10),
        })),
        destRouteId: 0,
        type: 'TRANSFER',
      },
    }),
    responseName: 'assignRetailOutletsToRoute',
    message: 'Outlets successfully unassigned',
  },
};

export {
  filterFieldMapper,
  mapDataFormatter,
  OUTLET_TYPES,
  filter as filterConfig,
  crudSuccess as crudRequestConfig,
  formConfig,
  serverDataMapper,
};
