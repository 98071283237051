import { compose } from 'recompose';
import SubDAssortment from './SubDAssortment';
import {
  query, mutation, DOWNLOAD_REPORT
} from './API';
import withRequestTracker from '../../../utils/composition/withRequestTracker';

const composedSubDAssortment = compose(DOWNLOAD_REPORT, withRequestTracker({ query, mutation }))(SubDAssortment);

export default composedSubDAssortment;
