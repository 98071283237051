import { compose } from 'recompose';
import PurchaseOrderList from './PurchaseOrderList';
import { query, mutation, createPurchaseOrder } from '../Repository/API';
import withRequestTracker from '../../../../utils/composition/withRequestTracker';
import { DOWNLOAD_REPORT } from '../../../common/GqlAPI';
const composedPurchaseOrderList = compose(
  createPurchaseOrder,
  DOWNLOAD_REPORT,
  withRequestTracker({
    query,
    mutation,
  }),
)(PurchaseOrderList);

export default composedPurchaseOrderList;
