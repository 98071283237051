import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Badge, Button } from '../../v4/components';
import FilterMenuStyled from './MenuStyled';

/**
 * @type {{onDialogCancel: shim, onDialogOkay: shim, name: shim, presentationType: shim}}
 */
const propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  header: PropTypes.object,
  body: PropTypes.object,
  onClose: PropTypes.bool,
  disabled: PropTypes.bool,
  disableBody: PropTypes.bool,
  hideOnClick: PropTypes.bool,
  hideOnClickOutside: PropTypes.bool,
  openMenu: PropTypes.func,
};

const defaultProps = {
  label: '',
  value: 0,
  onClose: false,
  disabled: false,
  disableBody: false,
  hideOnClick: false,
  hideOnClickOutside: true,
  openMenu: () => null,
};

// todo adjust the class name accordingly
const getMenuConfig = (label, value) => {
  switch (label) {
    case 'filter':
      return (
        <div className={value > 0 ? 'filter-icon active' : 'filter-icon'}>
          <Button iconBtnSmall secondary iconName="filter" />
          {
            value > 0 ? <Badge className="primary" label={value} /> : null}
        </div>
      );
    case 'sort':
      return (
        <div className={value !== '' ? 'filter-icon active' : 'filter-icon'}>
          <Button iconBtnSmall secondary iconName="filter" />
          {
            value !== ''
              ? <Badge small primary label={value} /> : null
          }
        </div>
      );
    case 'column':
      return (
        <div className="filter-icon">
          <Button iconBtnSmall secondary iconName="column" />
        </div>
      );
    default:
      break;
  }
};

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBody: false,
    };
    // this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    if (node) {
      this.wrapperRef = node;
    }
  };

  handleHeaderClick = (e) => {
    const { openMenu } = this.props;
    e.preventDefault();
    this.setState({ showBody: true });
    openMenu();
    // else it will be changed by event listener
  };

  handleClickOutside = (event) => {
    const { hideOnClickOutside, hideOnClick } = this.props;
    const dropDownEvent = event.target.id.includes('react-select'); // todo quick fix
    if (hideOnClick) {
      // this.setState({ showBody: false })
    }

    if (hideOnClickOutside && this.wrapperRef
      && !this.wrapperRef.contains(event.target) && !dropDownEvent) {
      this.setState({ showBody: false });
    }
  };

  close = () => {
    this.setState({ showBody: false });
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { showBody } = this.state;
    const {
      label, value, body, children, header, disabled, disableBody, onClose,
    } = this.props;
    const headerDom = label === '' ? header : getMenuConfig(label, value);

    return (
      <FilterMenuStyled>
        <div className={`filter-item ${disabled ? 'disabled-btn' : ''}`}>
          <span className={showBody ? 'filter-background' : ''}>
            <span
              className="header-dom"
              onClick={e => this.handleHeaderClick(e)}
              onFocus={e => this.handleHeaderClick(e)}
            >
              {headerDom}
            </span>
          </span>
        </div>
        {
          (showBody && !disableBody) && !onClose
          && (
            <div className={`filter-dropdown-card ${label}`} ref={node => this.setWrapperRef(node)}>
              {body}
              {children}
            </div>)
        }
      </FilterMenuStyled>
    );
  }
}

Menu.propTypes = propTypes;

Menu.defaultProps = defaultProps;

export default Menu;
