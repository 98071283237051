import styled from 'styled-components';

const Wrapper = styled.div`
  .eds-button--raised {
    margin-bottom: 20px;
    background-color: red;
  }
`;

export default Wrapper;
