import styled from 'styled-components';

export const ManageUserStyled = styled.div`
  .section-content .table-wrap table {
    thead th nth:child(1) {
      min-width: 150px;
    }
    tbody td:last-child {
      min-width: 100px;
      text-align: right;
    }
    tbody tr td:nth-child(3) {
      min-width: 150px;
    }

    tbody tr .status {
      min-width: 150px;
    }
    tbody tr .last-active {
      text-align: right;
    }
    tbody tr td.active-days {
      text-align: right;
    }
  }
  .filter-wrap > * {
    margin-right: 16px;
  }
`;
